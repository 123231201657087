import {
	type Comment,
	type ComposerPluginConfig,
	type ComposerState,
	MessageFormContext,
	MessageTitle,
	type MessageTitleProps,
	type OnPostingEvent,
	type PostMessageMutation,
	useComposerPlugins,
	usePluginFileAttachments,
	usePluginMessageOptions
} from '@components/Message';
import type { ResourceLengthAwarePaginatorType } from '@components/Pagination';
import type { UploadFile } from '@components/Upload';
import { ValidationField } from '@convoflo/ui';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import useIsDirty from '@hooks/useIsDirty';
import Folder from '@models/Folder';
import type { IUser } from '@models/User';
import type { Fileable } from '@types';
import { RichTextEditor, type RichTextEditorProps, type RichTextEditorRef } from '@ui/RichTextEditor';
import classNames from 'classnames';
import { forwardRef, type ForwardRefRenderFunction, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { type InfiniteData, useMutation } from 'react-query';
import type { WretchError } from 'wretch';

interface ApiResponse {
	status: number;
	message: string;
}

type ComposerProps = {
	fileable: Fileable;
	plugins?: {
		attachments?: ComposerPluginConfig<{
			folder?: Folder;
			enableFolderSelection?: boolean;
			onFilesChanged?: (files: UploadFile[]) => void;
		}>;
		options: ComposerPluginConfig;
	};
	inputProps?: RichTextEditorProps;
	disabled?: boolean;
	source?: string;
	titleInputProps?: MessageTitleProps;
	className?: string;
	onPosted?: () => void;
	postError?: (message: Error) => void;
	onStateChanged?: (state: ComposerState) => void;
	onBeforePosting?: (event: OnPostingEvent) => Promise<Folder | undefined>;
	secureSpaceIds?: number[];
};

export type ComposerRef = {
	post: () => void;
	discard: () => void;
};

const ComposerRenderFunction: ForwardRefRenderFunction<ComposerRef, ComposerProps> = (
	{ fileable, disabled = false, plugins: pluginsConfig, inputProps = {}, titleInputProps = {}, source, className, onPosted = () => undefined, secureSpaceIds, onStateChanged },
	ref
) => {
	const { client, validation } = useClient();
	const { account } = useAccount();
	const { formatMessage, locale } = useIntl();
	const [title, setTitle] = useState('');
	const editor = useRef<RichTextEditorRef>(null);

	const [characterCount, setCharacterCount] = useState(0);
	const { isDirty, setIsDirty } = useIsDirty([characterCount, title]);

	const plugins = useComposerPlugins([
		usePluginFileAttachments(
			{
				fileable,
				disabled
			},
			pluginsConfig?.attachments ?? {}
		),

		usePluginMessageOptions(
			{
				fileable,
				disabled
			},
			pluginsConfig?.options ?? {}
		)
	]);

	const {
		mutate: post,
		isLoading: isPosting,
		isSuccess: isPosted
	} = useMutation<ApiResponse, WretchError, PostMessageMutation, InfiniteData<ResourceLengthAwarePaginatorType<Comment>>>(
		async ({ body, title }) => {
			await plugins.onBeforePosting({ message: body });
			const data = plugins.postData();

			const params = {
				comment: body,
				title,
				source,
				secureSpaces: secureSpaceIds,
				...data
			};

			return await client.url('messages').json(params).post().json<ApiResponse>();
		},
		{
			onSuccess: () => {
				onPosted();
			}
		}
	);

	const reset = async () => {
		await plugins.reset({});
		setTitle('');
		setCharacterCount(0);
		editor.current?.clear();
	};

	const defaultSubject = useMemo(() => {
		let _defaultSubject = formatMessage({ id: 'comments.subject_default.root' });

		if (!account!.business.Defaults?.message?.subject) {
			return _defaultSubject;
		}

		const _subject = JSON.parse(account!.business.Defaults?.message?.subject);

		if (_subject !== null) {
			const appLocale = locale === 'fr' ? 'fr_CA' : 'en_US';

			if (_subject[appLocale]) {
				_defaultSubject = _subject[appLocale];
			}
		}

		return _defaultSubject;
	}, [account, formatMessage, locale]);

	const isDisabled = plugins.isDisabled || disabled || isPosting;

	useImperativeHandle(ref, () => ({
		post: async () => {
			post({
				title,
				body: JSON.stringify(editor.current!.json())
			});
		},
		discard: reset
	}));

	useEffect(() => {
		onStateChanged?.({
			isDirty,
			isDisabled,
			isPosted,
			isPosting,
			isPostable: plugins.isPostable && characterCount > 0,
			hasDraft: false
		});
	}, [onStateChanged, isDirty, isDisabled, isPosted, isPosting, plugins.isPostable, characterCount]);

	return (
		<MessageFormContext.Provider value={{ fileable, disabled: isDisabled, pluginData: plugins.data }}>
			{plugins.components.before.length > 0 && <>{plugins.components.before}</>}
			<ValidationField validation={validation} fieldName="message">
				<div className={classNames(className)} id="step-text-zone">
					<MessageTitle disabled={isDisabled} placeholder={defaultSubject} value={title} onChange={e => setTitle(e.target.value)} {...titleInputProps} />
					<RichTextEditor<IUser> ref={editor} {...inputProps} disabled={isDisabled} onCharacterCountChange={setCharacterCount}>
						{plugins.components.after.length > 0 && <>{plugins.components.after}</>}
					</RichTextEditor>
				</div>
			</ValidationField>
			{plugins.components.buttons.length > 0 && <div className="flex items-center space-x-1.5 p-3">{plugins.components.buttons}</div>}
		</MessageFormContext.Provider>
	);
};

export const RootComposer = forwardRef(ComposerRenderFunction);
