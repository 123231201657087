import AppContext from '@contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import messagesEn from '@translations/en.json';
import messagesFr from '@translations/fr.json';
import { type FC, type PropsWithChildren, type ReactNode, useCallback, useState } from 'react';
import { toast, ToastBar, Toaster } from 'react-hot-toast';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { defaultLocale } from './constants';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false
		}
	}
});

type Message = {
	[key: string]: string | Message;
};

const messages: Message = {
	fr: messagesFr,
	en: messagesEn
};

export const getSupportedLocale = (locale: string) => {
	if (!locale) {
		return defaultLocale;
	}
	const [language] = locale.split(/[-_]{1}/);
	if (language && language in messages) {
		return language;
	}
	return defaultLocale;
};

const AppProviders: FC<PropsWithChildren> = ({ children }) => {
	const [locale, setLocale] = useState(getSupportedLocale(new URLSearchParams(window.location.search).get('locale') ?? window.navigator.language));
	const [pageTitle, setPageTitle] = useState<string | null>(null);
	const [logo, setLogo] = useState<string | null>(null);
	const [showGlobalSearch, setShowGlobalSearch] = useState(false);
	const [showDrawer, setShowDrawer] = useState(false);
	const [pageHeader, setPageHeader] = useState<ReactNode>();
	const [name, setName] = useState('Convoflo');

	const sharedSetLocale = useCallback((locale: string) => {
		setLocale(getSupportedLocale(locale));
	}, []);

	return (
		<AppContext.Provider
			value={{
				pageTitle,
				setPageTitle,
				logo,
				setLogo,
				setLocale: sharedSetLocale,
				showGlobalSearch,
				setShowGlobalSearch,
				showDrawer,
				setShowDrawer,
				pageHeader,
				setPageHeader,
				name,
				setName
			}}>
			<QueryClientProvider client={queryClient}>
				<ReactQueryDevtools initialIsOpen={false} />
				<IntlProvider locale={locale} messages={messages[locale] as Record<string, string>}>
					<Toaster
						position="top-right"
						toastOptions={{
							success: {
								duration: 5000,
								className: '!rounded !shadow-lg !max-w-xs !w-auto !text-sm !py-2 !px-3 !bg-green-50 text-green-900 border-l-4 border-green-500',
								icon: <FontAwesomeIcon icon="check-circle" className="text-green-500" size="lg" />
							},
							error: {
								duration: 5000,
								className: '!rounded !shadow-lg !max-w-xs !w-auto !text-sm !py-2 !px-3 !bg-red-50 text-red-900 border-l-4 border-red-500',
								icon: <FontAwesomeIcon icon="exclamation-circle" className="text-red-500" size="lg" />
							},
							loading: {
								className: '!rounded !shadow-lg !max-w-xs !w-auto !text-sm !py-2 !px-3 text-gray-500 italic border-l-4 border-gray-300',
								icon: <FontAwesomeIcon icon="spinner" className="text-gray-500" pulse size="lg" />
							}
						}}>
						{t => (
							<ToastBar toast={t}>
								{({ icon, message }) => (
									<div className="flex overflow-auto max-h-3/4-screen">
										<div className="self-start mt-1">{icon}</div>
										<div className="self-start">{message}</div>
										<div className="self-start ml-3">
											<button onClick={() => toast.dismiss(t.id)} className="inline-flex p-1.5 text-black/25 transition-colors hover:text-black/100">
												<FontAwesomeIcon icon="times" fixedWidth />
											</button>
										</div>
									</div>
								)}
							</ToastBar>
						)}
					</Toaster>
					{children}
				</IntlProvider>
			</QueryClientProvider>
		</AppContext.Provider>
	);
};
export default AppProviders;
