import type { ComposerPlugin, OnPostedEvent, OnPostingEvent } from '@components/Message';
import { Dropzone, UploadButton, type UploadButtonHandles, UploadFile } from '@components/Upload';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Folder from '@models/Folder';
import { Tooltip } from '@ui/Tooltip';
import isEmpty from 'lodash.isempty';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { FileAttachments, type FileAttachmentsRef } from './FileAttachments';

const attachmentReminderRegex =
	/see attached|see attachment|see included|is attached|attached is|are attached|attached are|attached to this email|attached to this message|I’m attaching|I am attaching|I’ve attached|I have attached|I attach|I attached|find attached|find the attached|find included|find the included|attached file|see the attached|see attachments|attached files|see the attachment|voir ci-joint|voir en annexe|voir annexe|voir en pièce jointe|voir pièce jointe|est jointe|est annexé|sont jointes|sont joints|sont attaché|ci-joint sont|ci-joints sont|jointe à ce message|joint à ce message|annexé à ce message|jointe au présent message|je joins|j'ai joint|j’ai joint|je joins|j’ai annexé|j'ai annexé|trouver ci-joint|trouver en pièce jointe|trouver en pièce-jointe|trouver en annexe|trouver l'annexe|trouver l’annexe|trouvez l'annexe|trouvez l’annexe|fichier joint|fichier attaché|dossier joint|dossier attaché|voir le document ci-joint|voir les documents ci-joints|voir le fichier ci-joint|voir les fichiers ci-joints|voir la pièce jointe|voir l'annexe|voir l’annexe|voir ma pièce jointe|voir la pièce jointe|voir ma pièce-jointe|voir la pièce-jointe/i;

export const usePluginFileAttachments: ComposerPlugin<{ enableFolderSelection?: boolean; folder?: Folder; onFilesChanged?: (files: UploadFile[]) => void }> = (
	{ fileable, disabled },
	{ enabled = false, enableFolderSelection = true, folder: defaultFolder, onFilesChanged } = {},
	data
) => {
	const { formatMessage } = useIntl();
	const queryClient = useQueryClient();

	// Plugin data
	const [attachmentsFolder, setAttachmentsFolder] = useState(defaultFolder);

	const [isUploading, setIsUploading] = useState(false);
	const [, setForceRender] = useState(0);

	const fileAttachmentsRef = useRef<FileAttachmentsRef>(null);
	const uploader = useRef<UploadButtonHandles>(null);
	const listenForDraft = useRef(true);

	const onFilesSelected = (files: globalThis.File[]) => {
		fileAttachmentsRef.current?.add(files.map(file => UploadFile.fromFile(file, fileable)).filter(file => file.file));
		listenForDraft.current = false;
		setForceRender(Math.random());
	};

	const _onPaste = useCallback(
		(event: ClipboardEvent) => {
			if (!event.clipboardData) {
				return;
			}

			const _items = Array.from(event.clipboardData.items);
			const files = _items
				.filter(item => item.kind === 'file' && item.type.indexOf('image') === 0)
				.map<UploadFile>((file: DataTransferItem) => UploadFile.fromFile(file.getAsFile(), fileable))
				.filter((file: UploadFile) => file.file !== null);

			fileAttachmentsRef.current?.add(files);
			setForceRender(Math.random());

			listenForDraft.current = false;
		},
		[fileable]
	);

	// From draft
	useEffect(() => {
		if (!listenForDraft.current) {
			return;
		}

		if (isEmpty(data?.['files'])) {
			return;
		}

		if (data['files'].files.length > 0) {
			fileAttachmentsRef.current?.add(data['files'].files.map((file: UploadFile) => UploadFile.hydrate(file)).filter((file: UploadFile) => file.file));
			setForceRender(Math.random());
		}

		if (data['files'].attachmentsFolder) {
			setAttachmentsFolder(new Folder(data['files'].attachmentsFolder));
		}

		listenForDraft.current = false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.['files']]);

	useLayoutEffect(() => {
		if (enabled) {
			document.addEventListener('paste', _onPaste);
		} else {
			document.removeEventListener('paste', _onPaste);
		}
		return () => {
			document.removeEventListener('paste', _onPaste);
		};
	}, [_onPaste, enabled]);

	const onBeforePosting = async ({ message }: OnPostingEvent) => {
		if (!enabled) {
			return;
		}

		if (fileAttachmentsRef?.current?.files.length === 0) {
			let sequence = attachmentReminderRegex.exec(message ?? '');

			if (sequence?.[0] && !window.confirm(formatMessage({ id: 'comments.forgotten_attachments' }, { match: sequence?.[0] }))) {
				throw new Error('User cancelled');
			}
		}

		await fileAttachmentsRef.current?.upload();
		setForceRender(Math.random());
	};

	const onError = async () => {
		// Delete files?
		setIsUploading(false);
	};

	const onReset = async () => {
		fileAttachmentsRef.current?.reset();
		setAttachmentsFolder(undefined);
		setIsUploading(false);
		listenForDraft.current = true;
		setForceRender(Math.random());
	};

	const onSuccess = async ({ message }: OnPostedEvent) => {
		setIsUploading(false);
		if (message.attachments.some(attachment => attachment.item['@type'] === 'Document')) {
			queryClient.invalidateQueries('files');
		}
	};

	return {
		id: 'files',
		postData: () => {
			if (fileAttachmentsRef.current?.files?.length && enabled) {
				return { files: fileAttachmentsRef.current?.files?.map(file => file.model?.Id).filter(file => file) ?? [] };
			}

			return {};
		},
		onReset,
		onError,
		onSuccess,
		onBeforePosting,
		data: {
			attachmentsFolder,
			files: fileAttachmentsRef.current?.files ?? []
		},
		isDirty: (fileAttachmentsRef.current?.files ?? []).length !== 0,
		components: {
			button: enabled && (
				<div id="step-message-attachements">
					<Tooltip tip={formatMessage({ id: 'comments.add-attachments' })}>
						<Button size={Size.sm} disabled={disabled || isUploading} onClick={uploader.current?.chooseFiles} circle variant={Variant.light} intent={Intent.secondary} type="button">
							<FontAwesomeIcon icon="paperclip" />
						</Button>
					</Tooltip>
				</div>
			),
			before: enabled && (
				<>
					<UploadButton ref={uploader} multiple onFilesSelected={onFilesSelected} />
					<Dropzone onFilesDropped={onFilesSelected} />
				</>
			),
			after: (
				<FileAttachments ref={fileAttachmentsRef} folderSelection={enableFolderSelection} folder={attachmentsFolder} onFolderSelected={setAttachmentsFolder} onFilesChanged={onFilesChanged} />
			)
		}
	};
};
