import { DateTimeDisplay } from '@components/DateTime';
import { DialogDeletePaymentRequest, type PaymentRequest } from '@components/PaymentRequest';
import { Badge, Button, Intent, Size, Variant } from '@convoflo/ui';
import useClient from '@hooks/useClient';
import Folder from '@models/Folder';
import { type FC, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { defaultCurrency } from '../../constants';

type PaymentRequestItemProps = {
	paymentRequest: PaymentRequest;
	showCreator?: boolean;
};

export const PaymentRequestItem: FC<PaymentRequestItemProps> = ({ paymentRequest, showCreator = false }) => {
	const { formatList } = useIntl();
	const { client } = useClient();
	const queryClient = useQueryClient();

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const { mutate: archive, isLoading: isArchiving } = useMutation(async () => client.url(`payment-requests/${paymentRequest.Id}`).json({ archive: true }).delete().res(), {
		onSuccess: () => {
			toast.success(<FormattedMessage id="payment-requests.archived" />);
			queryClient.invalidateQueries(['payment-requests']);
		}
	});

	const { mutate: refund, isLoading: isRefunding } = useMutation(async () => client.url(`payment-requests/${paymentRequest.Id}/refund`).post().res(), {
		onSuccess: () => {
			toast.success(<FormattedMessage id="payment-requests.payment_refunded" />);
			queryClient.invalidateQueries(['payment-requests']);
		}
	});

	return (
		<>
			<div className="px-6 py-4">
				<div className="flex items-center justify-between">
					{paymentRequest.fileable instanceof Folder && (
						<h2 className="text-xl text-gray-600">
							<FormattedMessage
								id="payment-requests.title"
								values={{
									strong: msg => <span className="font-bold text-gray-800">{msg}</span>,
									amount: <FormattedNumber value={paymentRequest.Amount} currencyDisplay="symbol" currency={defaultCurrency} style="currency" />,
									secureSpace: paymentRequest.fileable?.getName() ?? (
										<span className="italic text-gray-400">
											<FormattedMessage id="_deleted" />
										</span>
									),
									link: msg =>
										paymentRequest.Link ? (
											<Link to={paymentRequest.Link} className="font-bold text-theme-primary hover:underline">
												{msg}
											</Link>
										) : (
											<>{msg}</>
										)
								}}
							/>
						</h2>
					)}
					{showCreator && (
						<span className="text-xs text-gray-400">
							<FormattedMessage id="payment-requests.created_by" values={{ user: paymentRequest.creator.Name }} />
						</span>
					)}
				</div>
				<div className="mb-2">
					<StatusBadge paymentRequest={paymentRequest} />
				</div>
				{paymentRequest.payed_at ? (
					<div className="inline-flex items-center">
						<span className="text-xs text-gray-500">
							{paymentRequest.payed_by ? (
								<FormattedMessage
									id="payment-requests.payment_made_by_user"
									values={{ date: <DateTimeDisplay value={paymentRequest.payed_at} force defaultFormat="long" />, user: paymentRequest.payed_by.Name }}
								/>
							) : (
								<FormattedMessage id="payment-requests.payment_made" values={{ date: <DateTimeDisplay force defaultFormat="long" value={paymentRequest.payed_at} /> }} />
							)}
						</span>
					</div>
				) : (
					<div className="inline-flex items-center">
						<span className="text-xs text-gray-500">
							<FormattedMessage
								id="payment-requests.sent_on"
								values={{ date: <DateTimeDisplay value={paymentRequest.created_at} force defaultFormat="long" />, users: formatList(paymentRequest.users!.map(user => user.Name)) }}
							/>
						</span>
					</div>
				)}
				<div className="flex mt-4 space-x-3">
					{paymentRequest.payed_at === null ? (
						<Button variant={Variant.danger} intent={Intent.secondary} size={Size.sm} icon="ban" onClick={() => setShowDeleteModal(true)}>
							<FormattedMessage id="cancel" />
						</Button>
					) : (
						<>
							{paymentRequest.Refundable && (
								<Button loading={isRefunding} disabled={isRefunding} variant={Variant.warning} intent={Intent.secondary} size={Size.sm} icon="undo" onClick={() => refund()}>
									<FormattedMessage id="payment-requests.refund" />
								</Button>
							)}
							{!paymentRequest.archived_at && (
								<Button variant={Variant.light} disabled={isArchiving} intent={Intent.secondary} size={Size.sm} icon="inbox-in" onClick={() => archive()}>
									{isArchiving ? <FormattedMessage id="archiving" /> : <FormattedMessage id="archive" />}
								</Button>
							)}
						</>
					)}
				</div>
			</div>
			{showDeleteModal && <DialogDeletePaymentRequest paymentRequest={paymentRequest} onAfterClose={() => setShowDeleteModal(false)} />}
		</>
	);
};

const StatusBadge: FC<{ paymentRequest: PaymentRequest }> = ({ paymentRequest }) => {
	if (paymentRequest.archived_at !== null) {
		return (
			<Badge variant={Variant.light}>
				<FormattedMessage id="payment-requests.item_archived" />
			</Badge>
		);
	}

	if (paymentRequest.Status === 'processing') {
		return (
			<Badge variant={Variant.warning}>
				<FormattedMessage id="payment-requests.item_processing" />
			</Badge>
		);
	}

	if (paymentRequest.refunded_at !== null) {
		return (
			<Badge variant={Variant.warning}>
				<FormattedMessage id="payment-requests.item_refunded" />
			</Badge>
		);
	}

	if (paymentRequest.payed_at !== null) {
		return (
			<Badge variant={Variant.success}>
				<FormattedMessage id="payment-requests.item_payed" />
			</Badge>
		);
	}

	if (paymentRequest.payed_at === null) {
		return (
			<Badge variant={Variant.primary}>
				<FormattedMessage id="payment-requests.item_sent" />
			</Badge>
		);
	}

	return null;
};
