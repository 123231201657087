import { Alert, Button, InputBlock, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, ModalSize, Row, ValidationField, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import useIsDirty from '@hooks/useIsDirty';
import { useOrganizationLogoDeleteMutation, useOrganizationUpdateMutation } from '@state/queries/organization';
import { Module } from '@types';
import Card from '@ui/Card';
import { convertFileToDataUrl } from '@utils/FileReaderUtils';
import classNames from 'classnames';
import { type FC, type FormEvent, useCallback, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { Prompt } from 'react-router-dom';

export const OrganizationBranding = () => {
	const { formatMessage } = useIntl();
	const { account } = useAccount();
	const { validation } = useClient();

	const [name, setName] = useState(account!.business.Name ?? '');
	const [logoLightBlob, setLogoLightBlob] = useState<globalThis.File>();
	const [logoDarkBlob, setLogoDarkBlob] = useState<globalThis.File>();

	const { isDirty, setIsDirty } = useIsDirty([name, logoLightBlob, logoDarkBlob]);

	const { mutateAsync: save, isLoading: isSaving } = useOrganizationUpdateMutation();

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		try {
			await save({
				name,
				logoLight: logoLightBlob,
				logoDark: logoDarkBlob
			});
			setIsDirty(false);
			toast.success(<FormattedMessage id="organization-settings.saved" />);
		} catch {
			// TODO: Add error message in toast
		}
	};

	return (
		<>
			<Prompt when={isDirty} message={formatMessage({ id: 'unsaved_changes' })} />
			<Card onSubmit={onSubmit}>
				<Row>
					<Label htmlFor="name-field">
						<FormattedMessage id="name" />
					</Label>
					<ValidationField fieldName="name" validation={validation}>
						<InputBlock onChange={e => setName(e.target.value)} value={name} id="name-field" type="text" required />
					</ValidationField>
				</Row>
				{account!.business.hasModule(Module.CustomLogo) && (
					<>
						<Row className="grid grid-cols-none md:grid-cols-2 gap-x-3">
							<div>
								<Label>
									<FormattedMessage id="organization-settings.logo_light" />
								</Label>
								<OrganizationLogoUpload key={account?.business.LogoLight} theme="light" onUpdated={setLogoLightBlob} />
							</div>

							<div>
								<Label>
									<FormattedMessage id="organization-settings.logo_dark" />
								</Label>
								<OrganizationLogoUpload key={account?.business.LogoDark} theme="dark" onUpdated={setLogoDarkBlob} />
							</div>
							<Alert variant={Variant.info} className="col-span-2 mt-2">
								<p>
									<FormattedMessage id="organization-settings.tip.aspect_ratio" />
								</p>
							</Alert>
						</Row>
					</>
				)}

				<Button variant={Variant.primary} type="submit" disabled={isSaving || !isDirty} loading={isSaving} icon="save">
					<FormattedMessage id="save" />
				</Button>
			</Card>
		</>
	);
};

type OrganizationLogoUploadProps = {
	theme: 'dark' | 'light';
	onUpdated?: (blob?: globalThis.File) => void;
};

const OrganizationLogoUpload: FC<OrganizationLogoUploadProps> = ({ theme, onUpdated = () => undefined }) => {
	const { account } = useAccount();
	const { validation } = useClient();

	const [logoBlob, setLogoBlob] = useState<globalThis.File>();
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);

	const onDrop = useCallback(async (files: File[]) => setLogoBlob(files[0]), []);

	const { data: logoUrl } = useQuery({
		queryKey: ['organization-logo', account, theme, logoBlob],
		queryFn: () => {
			if (logoBlob) {
				return convertFileToDataUrl(logoBlob);
			}
			return theme === 'dark' ? account!.business.LogoDark : account!.business.LogoLight;
		},
		enabled: !!logoBlob,
		initialData: theme === 'dark' ? account!.business.LogoDark : account!.business.LogoLight,
		placeholderData: theme === 'dark' ? account!.business.LogoDark : account!.business.LogoLight
	});

	useEffect(() => {
		onUpdated(logoBlob);
	}, [logoBlob]);

	if (logoUrl) {
		return (
			<>
				{showDeleteDialog && <DialogOrganizationLogo theme={theme} onAfterClose={() => setShowDeleteDialog(false)} />}
				<ValidationField validation={validation} fieldName={`logo_${theme}`}>
					<div
						className={classNames('relative h-32 bg-center bg-no-repeat bg-contain', {
							'bg-gray-200': theme === 'light',
							'bg-gray-700': theme === 'dark'
						})}
						style={{ backgroundImage: `url('${logoUrl}')` }}>
						<div className="absolute inset-0 flex items-center justify-center space-x-4 transition-opacity bg-gray-900 bg-opacity-50 opacity-100 md:opacity-0 hover:opacity-100">
							<Button type="button" shadow circle intent={Intent.secondary} variant={Variant.danger} icon="trash" onClick={() => setShowDeleteDialog(true)} />
						</div>
					</div>
				</ValidationField>
			</>
		);
	}

	return (
		<Dropzone onDrop={onDrop} multiple={false} accept={{ 'image/png': ['.png'], 'image/jpeg': ['.jpeg', 'jpg'] }} noKeyboard>
			{({ getRootProps, getInputProps }) => (
				<>
					<div
						{...getRootProps({
							className: classNames('h-32 cursor-pointer rounded flex justify-center flex-col text-center items-center p-4 border-4 border-dashed', {
								'border-gray-400 text-gray-600 bg-gray-200': theme === 'light',
								'border-gray-600 text-white/75 bg-gray-800': theme === 'dark'
							})
						})}>
						<FontAwesomeIcon icon="upload" size="lg" className="mb-2" />
						<p>
							<em>
								<FormattedMessage id="organization-settings.drag_n_drop" />
							</em>
						</p>
					</div>
					<input {...getInputProps()} />
				</>
			)}
		</Dropzone>
	);
};

type DialogOrganizationLogoProps = Omit<ModalProps, 'isOpen'> & {
	theme: 'dark' | 'light';
};

const DialogOrganizationLogo: FC<DialogOrganizationLogoProps> = ({ theme, ...modalProps }) => {
	const [isOpen, setIsOpen] = useState(true);

	const { mutateAsync: deleteLogoMutation, isLoading: isDeleting } = useOrganizationLogoDeleteMutation();

	const deleteLogo = async () => {
		try {
			await deleteLogoMutation(theme);
			toast.success(<FormattedMessage id="organization-settings.logo_removed" />);
			setIsOpen(false);
		} catch {
			// TODO: Add error toast
		}
	};

	return (
		<Modal isOpen={isOpen} {...modalProps} size={ModalSize.XSmall}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="organization-settings.remove_logo_confirmation" />
			</ModalHeaderOnlyTitle>
			<ModalBody className="prose-sm prose max-w-none">
				<p>
					<FormattedMessage id="organization-settings.remove_logo_body" />
				</p>
			</ModalBody>

			<ModalFooter>
				<Button variant={Variant.danger} disabled={isDeleting} onClick={deleteLogo}>
					<FormattedMessage id="remove" />
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} disabled={isDeleting} onClick={() => setIsOpen(false)}>
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
