import DialogAccountPassword from '@components/DialogAccountPassword';
import { Button, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Variant } from '@convoflo/ui';
import useClient from '@hooks/useClient';
import type { ValidationErrors } from '@types';
import { type FC, type FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import type { WretchError } from 'wretch';
import type { OrganizationUser } from './types';

type DeleteUserMutation = {
	accountPassword?: string;
};

type DialogTeamMemberDeleteProps = Omit<ModalProps, 'isOpen'> & {
	user: OrganizationUser;
};

export const DialogTeamMemberDelete: FC<DialogTeamMemberDeleteProps> = ({ user, ...modalProps }) => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	const [isOpen, setIsOpen] = useState(true);
	const [validation, setValidation] = useState<ValidationErrors>({});

	const { mutate: _delete, isLoading: isDeleting } = useMutation<OrganizationUser, WretchError, DeleteUserMutation>(
		async ({ accountPassword }) => await client.url(`org/users/${user.ID}`).json({ account_password: accountPassword }).delete().json<OrganizationUser>(),
		{
			onMutate: () => {
				setValidation({});
			},
			onError: error => {
				error.status === 422 && setValidation(error.json.errors);
			},
			onSuccess: user => {
				setIsOpen(false);
				queryClient.invalidateQueries('users');
				toast.success(<FormattedMessage id="user-manager.removed" values={{ name: user.Name }} />);
			}
		}
	);

	const onSubmitDelete = (e: FormEvent) => {
		e.preventDefault();
		_delete({});
	};

	const onAccountPasswordSubmitted = (accountPassword: string) => {
		_delete({ accountPassword });
	};

	return (
		<>
			<Modal isOpen={isOpen} onSubmit={onSubmitDelete} {...modalProps}>
				<ModalHeaderOnlyTitle>
					<FormattedMessage id="user-manager.delete-title" />
				</ModalHeaderOnlyTitle>
				<ModalBody>
					<p>
						<FormattedMessage id="user-manager.delete-intro" values={{ user: user.Name, strong: msg => <strong>{msg}</strong> }} />
					</p>
				</ModalBody>
				<ModalFooter>
					<Button disabled={isDeleting} type="submit" className="mr-2" variant={Variant.danger}>
						{isDeleting ? <FormattedMessage id="deleting" /> : <FormattedMessage id="delete" />}
					</Button>
					<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
						<FormattedMessage id="cancel" />
					</Button>
				</ModalFooter>
			</Modal>
			{'account_password' in validation && <DialogAccountPassword onAccountPasswordSubmitted={onAccountPasswordSubmitted} />}
		</>
	);
};
