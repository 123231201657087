import { createLengthAwarePagination } from '@components/Pagination';
import differenceWith from 'lodash.differencewith';
import File from '@models/File';
import Folder from '@models/Folder';
import type { InfiniteData } from 'react-query';
import type { Fileable } from '@types';
import type { FileManagerResults } from './FileList';

export const addFileables = (newFileables: Fileable[], existingFileables: InfiniteData<FileManagerResults>, options: { limit?: number } = { limit: 50 }) => {
	existingFileables = { ...existingFileables };

	newFileables.forEach(fileable => {
		if (fileable instanceof Folder && existingFileables.pages[0].folders?.data !== undefined) {
			existingFileables.pages[0].folders.data = [fileable].concat(existingFileables.pages[0].folders.data);
			return;
		}

		if (fileable instanceof File && existingFileables.pages[0].files?.data === undefined) {
			existingFileables.pages[0].files = createLengthAwarePagination<File>({
				data: [fileable],
				current_page: 1,
				first_page_url: null,
				from: 1,
				last_page: 1,
				last_page_url: null,
				next_page_url: null,
				path: null,
				per_page: options.limit ?? 50,
				to: 1,
				total: 1,
				prev_page_url: null
			});
			return;
		}

		if (fileable instanceof File && existingFileables.pages[0].files?.data !== undefined) {
			existingFileables.pages[0].files.data = [fileable].concat(existingFileables.pages[0].files.data);
			return;
		}
	});

	return existingFileables;
};

export const replaceFileable = (fileable: Fileable, existingFileables: InfiniteData<FileManagerResults>) => {
	existingFileables = { ...existingFileables };

	existingFileables.pages = existingFileables.pages.map(group => {
		if (group.files) {
			group.files.data = group.files.data.map(file => (file.getKey() === fileable.getKey() && fileable['@type'] === 'Document' ? (fileable as File) : file));
		}

		if (group.folders) {
			group.folders.data = group.folders.data.map(folder => (folder.getKey() === fileable.getKey() && fileable['@type'] === 'Folder' ? (fileable as Folder) : folder));
		}
		return group;
	});

	return existingFileables;
};

export const removeFileables = (fileables: Fileable[], existingFileables: InfiniteData<FileManagerResults>) => {
	existingFileables = { ...existingFileables };
	existingFileables.pages = existingFileables.pages.map(group => {
		if (group.files) {
			group.files.data = differenceWith(group.files.data, fileables, (a, b) => a.getKey() === b.getKey() && a['@type'] === 'Document' && b['@type'] === 'Document');
		}
		if (group.folders) {
			group.folders.data = differenceWith(group.folders.data, fileables, (a, b) => a.getKey() === b.getKey() && a['@type'] === 'Folder' && b['@type'] === 'Folder');
		}
		return group;
	});

	return existingFileables;
};

export const getIcon = (fileable: Fileable, ...iconProps: any[]) => {
	return fileable['@type'] === 'Document' ? new File(fileable).icon(...iconProps) : fileable['@type'] === 'Folder' ? new Folder(fileable).icon(...iconProps) : null;
};

export const getName = (fileable: Fileable) => {
	return fileable['@type'] === 'Document' ? new File(fileable).getName() : fileable['@type'] === 'Folder' ? new Folder(fileable).getName() : null;
};
