import * as React from "react";
const SvgEmailId = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 500 500", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M181.61 109.87c-27.62 6.17-54.45 18-75.24 37.21C70.79 180 56.7 234.18 71.76 280.22c5.25 16 14.16 31.48 28.09 41C112.78 330 129 333 144.65 332.13s30.82-5.28 45.68-10.17c11.24-3.71 22.73-7.76 34.55-7.11 13.89.77 26.49 7.9 38.93 14.15a354.94 354.94 0 0 0 74.9 27.58c20.46 5 43.34 7.86 61.53-2.75 16.69-9.74 25.68-28.85 30.12-47.66 14.2-60.12-11.79-127.7-62.61-162.83-26.12-18-56.86-27.48-87.25-35.52-31.45-8.33-67.22-5.03-98.89 2.05Z", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { d: "M181.61 109.87c-27.62 6.17-54.45 18-75.24 37.21C70.79 180 56.7 234.18 71.76 280.22c5.25 16 14.16 31.48 28.09 41C112.78 330 129 333 144.65 332.13s30.82-5.28 45.68-10.17c11.24-3.71 22.73-7.76 34.55-7.11 13.89.77 26.49 7.9 38.93 14.15a354.94 354.94 0 0 0 74.9 27.58c20.46 5 43.34 7.86 61.53-2.75 16.69-9.74 25.68-28.85 30.12-47.66 14.2-60.12-11.79-127.7-62.61-162.83-26.12-18-56.86-27.48-87.25-35.52-31.45-8.33-67.22-5.03-98.89 2.05Z", style: {
  fill: "#fff",
  opacity: 0.8
} }), /* @__PURE__ */ React.createElement("ellipse", { cx: 250, cy: 415.69, rx: 193.89, ry: 11.32, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#fff"
}, d: "M227.95 86.19h203.23v220.38H227.95z" }), /* @__PURE__ */ React.createElement("path", { d: "M429.06 90v216.36H229.83V90h199.23m2-2H227.83v220.36h203.23V88Z", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  stroke: "#1b9ff1",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, d: "M271.69 314.37h9.66M263.83 314.37h4.61M227.83 314.37h31.68M390.34 73.98h-4.6M426.34 73.98h-31.68" }), /* @__PURE__ */ React.createElement("path", { d: "M232.83 79.78h193.24a5 5 0 0 1 5 5v13.78H227.83V84.78a5 5 0 0 1 5-5Z", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#fff"
}, d: "M235.59 85.6h29.45v6.19h-29.45zM380.22 91.32h6.58v1h-6.58z" }), /* @__PURE__ */ React.createElement("path", { transform: "rotate(-45 420.09 89.183)", style: {
  fill: "#fff"
}, d: "M419.56 85.9h1v6.58h-1z" }), /* @__PURE__ */ React.createElement("path", { transform: "rotate(-45 420.062 89.185)", style: {
  fill: "#fff"
}, d: "M416.77 88.68h6.58v1h-6.58z" }), /* @__PURE__ */ React.createElement("path", { transform: "rotate(-45 402.774 89.336)", style: {
  fill: "#fff"
}, d: "M399.27 88.84h7.02v1h-7.02z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#fff"
}, d: "M401.71 92.61h-2.2v-2.2l2.2 2.2zM403.68 86.24h2.2v2.2l-2.2-2.2z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#e0e0e0"
}, d: "M236.55 111.01h186.02v1H236.55zM236.55 124.22h186.02v1H236.55z" }), /* @__PURE__ */ React.createElement("rect", { x: 234.74, y: 286.07, width: 42.26, height: 15.06, rx: 1.24, style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#263238"
}, d: "M236.34 116.13h74.45v3.99h-74.45z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#e0e0e0"
}, d: "M236.34 116.13h74.45v3.99h-74.45zM236.34 129.9h29.6v3.99h-29.6zM278.32 145.53H409v3.99H278.32zM248.44 152.23H409v3.99H248.44zM248.44 158.93h47.63v3.99h-47.63z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#263238"
}, d: "M236.34 103.05h20.17v3.99h-20.17z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#e0e0e0"
}, d: "M236.34 103.05h20.17v3.99h-20.17z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#fff"
}, d: "m274.42 292.24-2.73 2.72-2.73-2.72h5.46zM265.44 288.31h1v10.58h-1z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#263238"
}, d: "M283.44 292.88h6.71v6.71h-6.71zM292.84 292.88h6.71v6.71h-6.71zM302.24 292.88h6.71v6.71h-6.71zM311.65 292.88h6.71v6.71h-6.71zM321.05 292.88h6.71v6.71h-6.71zM330.45 292.88h6.71v6.71h-6.71zM339.85 292.88h6.71v6.71h-6.71zM349.25 292.88h6.71v6.71h-6.71z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#fff",
  opacity: 0.5
}, d: "M283.44 292.88h6.71v6.71h-6.71zM292.84 292.88h6.71v6.71h-6.71zM302.24 292.88h6.71v6.71h-6.71zM311.65 292.88h6.71v6.71h-6.71zM321.05 292.88h6.71v6.71h-6.71zM330.45 292.88h6.71v6.71h-6.71zM339.85 292.88h6.71v6.71h-6.71zM349.25 292.88h6.71v6.71h-6.71z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#263238"
}, d: "M416.2 292.88h6.71v6.71h-6.71z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#fff",
  opacity: 0.5
}, d: "M416.2 292.88h6.71v6.71h-6.71z" }), /* @__PURE__ */ React.createElement("path", { d: "M409 293.13a1.13 1.13 0 1 1-1.12-1.13 1.12 1.12 0 0 1 1.12 1.13ZM409 296.52a1.13 1.13 0 1 1-1.12-1.12 1.13 1.13 0 0 1 1.12 1.12ZM409 299.92a1.13 1.13 0 1 1-1.12-1.13 1.12 1.12 0 0 1 1.12 1.13Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.5
} }, /* @__PURE__ */ React.createElement("path", { d: "M409 293.13a1.13 1.13 0 1 1-1.12-1.13 1.12 1.12 0 0 1 1.12 1.13ZM409 296.52a1.13 1.13 0 1 1-1.12-1.12 1.13 1.13 0 0 1 1.12 1.12ZM409 299.92a1.13 1.13 0 1 1-1.12-1.13 1.12 1.12 0 0 1 1.12 1.13Z", style: {
  fill: "#fff"
} })), /* @__PURE__ */ React.createElement("path", { d: "m239.79 295.91.46-1a3.4 3.4 0 0 0 1.9.6c.79 0 1.11-.26 1.11-.62 0-1.08-3.35-.34-3.35-2.47 0-1 .79-1.8 2.43-1.8a3.8 3.8 0 0 1 2 .51l-.41 1a3.26 3.26 0 0 0-1.59-.45c-.79 0-1.1.3-1.1.66 0 1.06 3.35.33 3.35 2.44 0 1-.8 1.79-2.44 1.79a4.09 4.09 0 0 1-2.36-.66ZM249.72 294.63h-3.35a1.18 1.18 0 0 0 1.27.89 1.5 1.5 0 0 0 1.11-.43l.68.74a2.31 2.31 0 0 1-1.83.71 2.29 2.29 0 0 1-2.52-2.27 2.25 2.25 0 0 1 2.36-2.28 2.19 2.19 0 0 1 2.3 2.29c0 .1-.01.24-.02.35Zm-3.37-.75h2.18a1.11 1.11 0 0 0-2.18 0ZM255.12 294v2.53h-1.28v-2.34c0-.71-.33-1-.9-1s-1.06.38-1.06 1.19v2.19h-1.28v-4.43h1.23v.52a1.91 1.91 0 0 1 1.46-.58 1.73 1.73 0 0 1 1.83 1.92ZM260.84 290.38v6.1h-1.22V296a1.69 1.69 0 0 1-1.38.57 2.28 2.28 0 0 1 0-4.55 1.7 1.7 0 0 1 1.32.53v-2.14Zm-1.25 3.89a1.14 1.14 0 1 0-1.13 1.22 1.13 1.13 0 0 0 1.13-1.22Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M398.5 104.9a2.61 2.61 0 0 1 4.53-1.61l-.7.65a1.53 1.53 0 0 0-1.2-.56 1.52 1.52 0 0 0 0 3 1.51 1.51 0 0 0 1.2-.57l.7.65a2.61 2.61 0 0 1-4.53-1.6ZM403.3 105.45a1.88 1.88 0 0 1 2-1.88 1.67 1.67 0 0 1 1.63.93l-.82.44a.91.91 0 0 0-.82-.5 1 1 0 0 0 0 2 .91.91 0 0 0 .82-.5l.82.44a1.69 1.69 0 0 1-1.63.92 1.88 1.88 0 0 1-2-1.85ZM414.76 106c0 .82-.65 1.29-1.9 1.29h-2.45v-4.74h2.32c1.18 0 1.79.49 1.79 1.23a1.13 1.13 0 0 1-.63 1 1.18 1.18 0 0 1 .87 1.22Zm-3.26-2.63v1.12h1.09c.53 0 .83-.19.83-.56s-.3-.56-.83-.56Zm2.15 2.51c0-.4-.3-.59-.87-.59h-1.28v1.17h1.28c.57-.02.87-.19.87-.6ZM415.25 105.45a1.88 1.88 0 0 1 2-1.88 1.66 1.66 0 0 1 1.63.93l-.82.44a.89.89 0 0 0-.82-.5 1 1 0 0 0 0 2 .89.89 0 0 0 .82-.5l.82.44a1.68 1.68 0 0 1-1.63.92 1.88 1.88 0 0 1-2-1.85ZM419.21 105.45a1.88 1.88 0 0 1 2-1.88 1.67 1.67 0 0 1 1.63.93l-.82.44a.91.91 0 0 0-.82-.5 1 1 0 0 0 0 2 .91.91 0 0 0 .82-.5l.82.44a1.69 1.69 0 0 1-1.63.92 1.88 1.88 0 0 1-2-1.85Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M239.56 224s-17.44 1.9-25.35.3-15.42-14.73-15.42-14.73l8.53-5.49 9.68 12.51 22.17 2 6-4.91 6.31 1.52s.14 7.85-2.55 8.27-9.37.53-9.37.53Z", style: {
  fill: "#ffc3bd"
} }), /* @__PURE__ */ React.createElement("path", { d: "M196.62 190.16s5.25-.12 10.83 8.71a31.41 31.41 0 0 1 3.15 6.23c.75 2-3.06 4.37-5.8 6.14-2.13 1.37-4.39 2.84-5.72 2.63a20.3 20.3 0 0 1-2.66-4.56c-2.31-5.5-4.73-19.38.2-19.15Z", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { d: "m195.43 206.8 2.41-5.92 1.76-1.43s3.8 7.3 3.52 12.92c-3 1.81-4.75 1.61-6.7-3.06-.3-.71-.64-1.56-.99-2.51Z", style: {
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("path", { d: "M200 191.25s-.18 8.09-.68 12.21c-.62 5.17-2.05 9.6-6.18 17-4.93 8.85-10.44 18.85-10.44 18.85l-31.65-13.11a219.77 219.77 0 0 0 8.49-24.51 68.42 68.42 0 0 0 2.19-11.34 21 21 0 0 1 1.19-5.7c2.51-6.44 8.18-6.28 13.84-4.48a2.29 2.29 0 0 0 0 .37c.12 4.63 5.48 12.52 7 13.11 1.89.75 7.79-2.49 7.86-6.89a2.44 2.44 0 0 0 0-.27c3.48 1.69 8.38 4.76 8.38 4.76Z", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#fff",
  opacity: 0.9
}, d: "m180.47 179.36-6.45.24-4.1 7.11 5.96.94 7.18 7.69 9.57-1.98 4.28 1.04-1.45-6.65-6.83-5.14-8.16-3.25z" }), /* @__PURE__ */ React.createElement("path", { d: "M191.63 186.77c-.07 4.4-6 7.64-7.86 6.89-1.51-.59-6.87-8.48-7-13.11a3.74 3.74 0 0 0 3.23-1.15c2.66-3.16 4.31-8.85 5.05-12.43l5.2 10.45a7.32 7.32 0 0 0-1.25 6.28c.06.08.26 1.58 2.63 3.07Z", style: {
  fill: "#ffc3bd"
} }), /* @__PURE__ */ React.createElement("path", { d: "m189 174.86 1.27 2.56a7.92 7.92 0 0 0-1.43 3.64c-3.2-3.3-3.92-8.94-3.92-8.94Z", style: {
  opacity: 0.1
} }), /* @__PURE__ */ React.createElement("path", { d: "M168.94 187.67a67.34 67.34 0 0 1-9.38 14 68.42 68.42 0 0 0 2.19-11.34 21 21 0 0 1 1.19-5.7l3.26-.77Z", style: {
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("path", { d: "M152 186.38s-10.29 8.11-13.39 11.18c-4.62 4.57-16.89 19.75-16.89 19.75l-7.8 5 .34 5.12 4 2.39a2.58 2.58 0 0 0 3.56-.93l4.42-7.67s11.38-11.91 15.63-15.37c3.59-2.93 15.39-10.32 15.39-10.32Z", style: {
  fill: "#ffc3bd"
} }), /* @__PURE__ */ React.createElement("path", { d: "M171 179.11s-6.64-3.14-12.36.39-9 4.21-7.37 9.16c1.17 3.63 5.66 10.87 5.66 10.87 3.78-1.53 16.77-15.35 14.07-20.42Z", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { d: "M154.67 391.36c.18 6.8.34 12.18.34 12.18l7.59.35s.41-4.63 1-11.73Z", style: {
  fill: "#ffc3bd"
} }), /* @__PURE__ */ React.createElement("path", { d: "M164.69 402.81a.23.23 0 0 1-.18-.16.22.22 0 0 1 .11-.21c.14-.07 3.31-1.75 4.82-1.38a1.1 1.1 0 0 1 .68.45.69.69 0 0 1 0 .85 4.14 4.14 0 0 1-2.67.66 18.26 18.26 0 0 1-2.76-.21Zm.69-.27c1.69.17 4 .14 4.4-.4.06-.07.12-.19 0-.43a.56.56 0 0 0-.42-.27 1.44 1.44 0 0 0-.41-.05 10.85 10.85 0 0 0-3.57 1.15Z", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { d: "M164.57 402.8a.19.19 0 0 1-.09-.18c0-.1.26-2.34 1.41-3.22a1.71 1.71 0 0 1 1.2-.34c.59.05.78.32.8.54.19.93-2 2.75-3.08 3.2h-.09a.26.26 0 0 1-.15 0Zm2.38-3.37a1.23 1.23 0 0 0-.75.25 4.53 4.53 0 0 0-1.21 2.56c1.13-.62 2.56-2 2.51-2.6 0-.05 0-.19-.43-.22Z", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { d: "m163.76 401.66-9-.07a.79.79 0 0 0-.78.58l-1.81 7.37a1.19 1.19 0 0 0 1 1.44 1.27 1.27 0 0 0 .28 0c3.57-.09 5.88-.14 10.32-.17 5.25 0 3.91.53 10.08.56 3.72 0 4.4-3.86 2.83-4-7.25-.76-12.14-5.75-12.92-5.71Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "m148.44 231.13 2-5c.13-.64.42-1 1-.79l31.63 13.36c.58.24.66.54.34 1.08l-1.72 5.8s-4.29 54.79-6.38 73c-2.13 18.61-10.49 74.41-10.49 74.41a.77.77 0 0 1-.83.67l-9.45-.89a.75.75 0 0 1-.69-.69c-.43-4.68-2.36-34.31-.79-55.47.56-7.56 2.68-13.73 3.25-17.91.64-4.62-1-7.57-2.35-18.49-2.49-20.5-5.96-52.94-5.52-69.08Z", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { d: "m148.44 231.13 2-5c.13-.64.42-1 1-.79l31.63 13.36c.58.24.66.54.34 1.08l-1.72 5.8s-4.29 54.79-6.38 73c-2.13 18.61-10.49 74.41-10.49 74.41a.77.77 0 0 1-.83.67l-9.45-.89a.75.75 0 0 1-.69-.69c-.43-4.68-2.36-34.31-.79-55.47.56-7.56 2.68-13.73 3.25-17.91.64-4.62-1-7.57-2.35-18.49-2.49-20.5-5.96-52.94-5.52-69.08Z", style: {
  opacity: 0.7000000000000001
} }), /* @__PURE__ */ React.createElement("path", { d: "M154 300.27c-.82-6.88-1.77-15.08-2.64-23.56l8.25-16.32 3.52-9.55 4.28 5.07s-5 29.64-12.82 48.59a40.36 40.36 0 0 1-.59-4.23Z", style: {
  opacity: 0.5
} }), /* @__PURE__ */ React.createElement("path", { d: "M87.73 369.83c-3.53 5.81-5.82 9.62-5.82 9.62l6.19 4.4s2.85-3.67 7.21-9.31Z", style: {
  fill: "#ffc3bd"
} }), /* @__PURE__ */ React.createElement("path", { d: "M90.74 382.62a.23.23 0 0 1 0-.24.22.22 0 0 1 .21-.13c.16 0 3.76.18 4.81 1.34a1.12 1.12 0 0 1 .3.78.81.81 0 0 1-.49.79c-.57.21-1.65-.24-2.6-.84a16.17 16.17 0 0 1-2.23-1.7Zm.74.13c1.3 1.08 3.22 2.29 3.88 2 .09 0 .21-.11.22-.42a.59.59 0 0 0-.19-.47 1.35 1.35 0 0 0-.32-.27 9.89 9.89 0 0 0-3.59-.84Z", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { d: "M90.65 382.55a.2.2 0 0 1 0-.21c.07-.09 1.57-2 3-2.19a1.6 1.6 0 0 1 1.19.34c.47.36.46.71.35.93-.38.95-3.26 1.43-4.42 1.26a.2.2 0 0 1-.08 0 .27.27 0 0 1-.04-.13Zm3.94-1.8a1.1 1.1 0 0 0-.77-.17 5 5 0 0 0-2.49 1.68c1.29 0 3.31-.48 3.59-1 0-.06.08-.2-.23-.43l-.1-.06Z", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { d: "m90.1 380.83-6.54-4.26a.79.79 0 0 0-1 .1l-5.8 5.78a1.32 1.32 0 0 0 0 1.86 1.18 1.18 0 0 0 .21.18c3 1.85 4 2.36 7.75 4.73 4.38 2.8 2.93 2.62 8 6 3.08 2 5.9-1.14 4.7-2.16-5.5-4.62-6.65-11.84-7.32-12.23Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M172.83 242.63s-20.75 58.3-31.22 76c-9.29 15.68-45.87 56.69-45.87 56.69a1.66 1.66 0 0 1-2.28.29l-5.74-4.25a1.66 1.66 0 0 1-.49-2.1c3-5.66 15.51-29.23 26.85-44.78 4.52-6.2 9-10.82 11.07-14.21 2.78-4.48 1.83-7.86 3.46-19.89 3-22.36 9.63-55.4 22.64-61.5Z", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { d: "M172.83 242.63s-20.75 58.3-31.22 76c-9.29 15.68-45.48 56.93-45.48 56.93a1.65 1.65 0 0 1-2.27.29l-6.51-4.76a1.11 1.11 0 0 1-.33-1.42c2.52-4.84 15.42-29.23 27.06-45.19 4.52-6.2 9-10.82 11.07-14.21 2.78-4.48 1.83-7.86 3.46-19.89 3-22.36 9.63-55.4 22.64-61.5Z", style: {
  opacity: 0.7000000000000001
} }), /* @__PURE__ */ React.createElement("path", { d: "M197.2 149.2c.43 2.82 3.78 4.37 5.33 7.57 0 0 4.42-3.81 1.32-6.68s-7.31-5.31-6.65-.89Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M202.61 152.49c3.4 1.42 5.15 8.13 1 10.62.06.36.31.59.53.46 4.73-2.73 3-11.29-1.16-14.25ZM194.47 155.2a17 17 0 0 0-5.13 11.8c-.07 4.39-2.14 7.29.91 10.45 10.56 2.83 10.64 4.12 12.16 4.82.68-2.14.87-8.28-1.88-10.37-2-1.53-3.91-3.22-4.53-5.67a30.63 30.63 0 0 1-.84-10Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M182.06 160.26c.62 7.64.71 10.88 4.68 14.74 5.92 5.79 15.19 3.38 16.83-4.29 1.52-6.89.26-18.2-7.3-21.12a10.43 10.43 0 0 0-13.53 5.91 10.31 10.31 0 0 0-.68 4.76Z", style: {
  fill: "#ffc3bd"
} }), /* @__PURE__ */ React.createElement("path", { d: "M199.29 163.05a16.17 16.17 0 0 0 2.1 4 2.68 2.68 0 0 1-2.23.35Z", style: {
  fill: "#ed893e"
} }), /* @__PURE__ */ React.createElement("path", { d: "M196.65 170.87a5.31 5.31 0 0 0 .89 0 .18.18 0 0 0 .17-.18.19.19 0 0 0-.18-.18 4.88 4.88 0 0 1-4.05-1.85.2.2 0 0 0-.26-.05.19.19 0 0 0 0 .26 5.12 5.12 0 0 0 3.43 2ZM183.29 147.36c-5.66 4.13-10.93 18 1.23 24.95 2.59-1.23.89-4 .31-8.35-.38-2.84 4.61-7.73 7-9.28s4.85-4 7.49-2.94a3.93 3.93 0 0 0 2.14.56c2.4-.49 1.65-2 .76-3.2a11.54 11.54 0 0 0-5.43-3.53 14.92 14.92 0 0 0-13.5 1.79Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M190.22 155.79a8.74 8.74 0 0 1-1.68 4.13 6 6 0 0 1-4 2.07 18 18 0 0 1-4.63-.27 9.1 9.1 0 0 0 12.54-3.86c.55-1.08.87-2.25 1.35-3.37a6 6 0 0 1 2.16-2.84 2.93 2.93 0 0 1 3.39.09 1.12 1.12 0 0 0 .75-1.91c-1.45-.13-8.83-1.98-9.88 5.96Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M180.55 150.2c-3.16 3-6.17 8.82-6.25 13.21a17 17 0 0 0 4.7 11.94 21.93 21.93 0 0 1 10.23 4.38c1.19-3.24-1-9.34-3.76-11.42-2-1.54-3.91-3.23-4.53-5.68a30.87 30.87 0 0 1-.85-10Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M179.9 166.06a5.55 5.55 0 0 0 3 3.07c1.81.77 2.93-.79 2.67-2.62-.27-1.65-1.52-4-3.44-4a2.51 2.51 0 0 0-2.2 3.58Z", style: {
  fill: "#ffc3bd"
} }), /* @__PURE__ */ React.createElement("path", { d: "M191.61 159.42a.36.36 0 0 0 .4-.12 4.21 4.21 0 0 1 3.22-1.29.37.37 0 0 0 0-.73 5 5 0 0 0-3.84 1.59.37.37 0 0 0 .06.51ZM203.24 158.84a.37.37 0 0 0 .35-.09.35.35 0 0 0 0-.51 3.68 3.68 0 0 0-3.13-1.15.38.38 0 0 0-.3.43.36.36 0 0 0 .43.29 2.89 2.89 0 0 1 2.46.94.59.59 0 0 0 .19.09ZM202.25 161.61c.42 0 .74.57.7 1.21s-.4 1.17-.82 1.14-.74-.57-.71-1.21.41-1.16.83-1.14Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "m203.64 161.28-1.58.37c.71.8 1.58-.37 1.58-.37ZM194.88 161.43c.42 0 .74.57.7 1.22s-.4 1.16-.82 1.14-.74-.57-.71-1.22.41-1.15.83-1.14Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "m196.27 161.11-1.59.37c.72.8 1.59-.37 1.59-.37Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#1b9ff1"
}, d: "m150.94 228.17 6.05 2.52-.89 2.11-6.02-2.53.86-2.1z" }), /* @__PURE__ */ React.createElement("path", { transform: "rotate(-67.34 161.141 233.656)", style: {
  fill: "#1b9ff1"
}, d: "M159.99 230.43h2.28v6.45h-2.28z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#1b9ff1"
}, d: "m174.49 237.99 4.04 1.68-.87 2.11-4.05-1.69.88-2.1z" }), /* @__PURE__ */ React.createElement("path", { transform: "rotate(-67.3 180.583 241.763)", style: {
  fill: "#1b9ff1"
}, d: "M179.45 240.8h2.28v1.92h-2.28z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { d: "M256.56 208.78a9.18 9.18 0 0 1-9.16-9.17v-17.76a1 1 0 0 1 1.94 0v17.76a7.23 7.23 0 1 0 14.45 0v-18.68a1 1 0 1 1 1.94 0v18.68a9.18 9.18 0 0 1-9.17 9.17Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("rect", { x: 246.23, y: 180.1, width: 52.88, height: 73.28, rx: 4.35, transform: "rotate(-8.88 272.801 216.822)", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("rect", { x: 246.23, y: 180.1, width: 52.88, height: 73.28, rx: 4.35, transform: "rotate(-8.88 272.801 216.822)", style: {
  fill: "#fff",
  opacity: 0.6000000000000001
} }), /* @__PURE__ */ React.createElement("rect", { x: 254.4, y: 214.08, width: 37.45, height: 2.32, rx: 1, transform: "matrix(.99 -.16 .16 .99 -30.78 46.22)", style: {
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("rect", { x: 255.9, y: 223.44, width: 37.45, height: 2.32, rx: 1, transform: "rotate(-9.16 274.612 224.584)", style: {
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("rect", { x: 257.41, y: 232.81, width: 37.45, height: 2.32, rx: 1, transform: "rotate(-9.16 276.124 233.94)", style: {
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("rect", { x: 243.73, y: 181.68, width: 52.88, height: 73.28, rx: 4.35, transform: "rotate(-6.59 270.35 218.503)", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("rect", { x: 243.73, y: 181.68, width: 52.88, height: 73.28, rx: 4.35, transform: "rotate(-6.59 270.35 218.503)", style: {
  fill: "#fff",
  opacity: 0.7000000000000001
} }), /* @__PURE__ */ React.createElement("rect", { x: 251.96, y: 215.68, width: 37.45, height: 2.32, rx: 1, transform: "rotate(-6.87 270.725 216.807)", style: {
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("rect", { x: 253.09, y: 225.1, width: 37.45, height: 2.32, rx: 1, transform: "rotate(-6.87 271.831 226.236)", style: {
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("rect", { x: 254.23, y: 234.51, width: 37.45, height: 2.32, rx: 1, transform: "rotate(-6.87 272.937 235.666)", style: {
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("path", { d: "m296.53 199.29.1 54.31a1.27 1.27 0 0 1-1.27 1.27l-50.22.09a1.26 1.26 0 0 1-1.27-1.26l-.12-70.65a1.26 1.26 0 0 1 1.26-1.27l33.84-.06Z", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.8
} }, /* @__PURE__ */ React.createElement("path", { d: "m296.53 199.29.1 54.31a1.27 1.27 0 0 1-1.27 1.27l-50.22.09a1.26 1.26 0 0 1-1.27-1.26l-.12-70.65a1.26 1.26 0 0 1 1.26-1.27l33.84-.06Z", style: {
  fill: "#fff"
} })), /* @__PURE__ */ React.createElement("rect", { x: 251.45, y: 215.75, width: 37.45, height: 2.32, rx: 1, transform: "rotate(-.28 265.496 213.462)", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("rect", { x: 251.49, y: 225.23, width: 37.45, height: 2.32, rx: 1, transform: "rotate(-.27 275.328 229.833)", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("rect", { x: 251.54, y: 234.72, width: 37.45, height: 2.32, rx: 1, transform: "rotate(-.28 265.45 231.879)", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M278.88 181.68v16.38a1.27 1.27 0 0 0 1.26 1.27h16.34Z", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { d: "M278.88 181.68v16.38a1.27 1.27 0 0 0 1.26 1.27h16.34Z", style: {
  fill: "#fff",
  opacity: 0.9
} }), /* @__PURE__ */ React.createElement("rect", { x: 251.45, y: 215.75, width: 37.45, height: 2.32, rx: 1, transform: "rotate(-.28 265.496 213.462)", style: {
  fill: "#fff",
  opacity: 0.5
} }), /* @__PURE__ */ React.createElement("rect", { x: 251.49, y: 225.23, width: 37.45, height: 2.32, rx: 1, transform: "rotate(-.27 275.328 229.833)", style: {
  fill: "#fff",
  opacity: 0.5
} }), /* @__PURE__ */ React.createElement("rect", { x: 251.54, y: 234.72, width: 37.45, height: 2.32, rx: 1, transform: "rotate(-.28 265.45 231.879)", style: {
  fill: "#fff",
  opacity: 0.5
} }), /* @__PURE__ */ React.createElement("path", { d: "M260.67 182.84h-1.94v-3.33a4.7 4.7 0 0 0-9.39 0v2.36h-1.94v-2.36a6.64 6.64 0 0 1 13.27 0Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M256.51 200.47a4.16 4.16 0 0 1-4.15-4.16V181a1 1 0 1 1 1.94 0v15.36a2.22 2.22 0 1 0 4.43 0v-14.49a1 1 0 1 1 1.94 0v14.44a4.16 4.16 0 0 1-4.16 4.16Z", style: {
  fill: "#263238"
} })));
export default SvgEmailId;
