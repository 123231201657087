import ImagePermissionsApply from '@assets/images/permissions-apply.svg?react';
import ImagePermissionsDontApply from '@assets/images/permissions-dont-apply.svg?react';
import { ComboBox, KeyValueDescriptionOption, type KeyValueDescriptionOptionType } from '@components/ComboBox';
import { DateTimeDisplay } from '@components/DateTime';
import { type MemberType, type NullablePivotMemberType, useFileableMembers } from '@components/FileManager';
import { Alert, Button, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, ModalSize, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useIsDirty from '@hooks/useIsDirty';
import Folder from '@models/Folder';
import { BigButton } from '@pages/trial-expired';
import { type Fileable, Permissions } from '@types';
import { CopyToClipboard } from '@ui/CopyToClipboard';
import FontAwesomeSvg from '@ui/FontAwesomeSvg';
import { Tooltip } from '@ui/Tooltip';
import UserAvatar from '@ui/UserAvatar';
import classNames from 'classnames';
import { type FC, type FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import type { OnChangeValue } from 'react-select';

type DialogPermissionsProps = Omit<ModalProps, 'isOpen'> & {
	item: Fileable;
	onSaved?: (filable: Fileable) => void;
};

export const DialogPermissions: FC<DialogPermissionsProps> = ({ item, onSaved = () => undefined, ...modalProps }) => {
	const { members: membersFromHelper = [], suggestions: suggestionsFromHelper = [], isLoading, update, isUpdating } = useFileableMembers(item, { loadSuggestions: true });
	const { account } = useAccount();

	const [isOpen, setIsOpen] = useState(true);
	const [members, setMembers] = useState<MemberType[]>([]);
	const [suggestions, setSuggestions] = useState<NullablePivotMemberType[]>([]);
	const [copied, setCopied] = useState(false);
	const [applyToAll, setApplyToAll] = useState(true);
	const [showConfirmation, setShowConfirmation] = useState(false);

	const { isDirty, setIsDirty } = useIsDirty([members]);

	useEffect(() => {
		setMembers(membersFromHelper);
	}, [membersFromHelper]);

	useEffect(() => {
		setSuggestions(suggestionsFromHelper);
	}, [suggestionsFromHelper]);

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();

		if (item instanceof Document) {
			updateMembers(members.filter(member => member.pivot) as MemberType[], true);
			return;
		}

		setShowConfirmation(true);
	};

	const handleConfirmationSubmit = async (e: FormEvent) => {
		e.preventDefault();

		if (applyToAll === undefined) {
			return;
		}

		setShowConfirmation(false);
		updateMembers(members.filter(member => member.pivot) as MemberType[], applyToAll);
	};

	const onUserAdded = (member: NullablePivotMemberType) => {
		const pivot = {
			Permissions: DEFAULT_PERMISSION,
			Notify: DEFAULT_NOTIFY,
			Favorite: false,
			Viewed: false
		};

		setMembers(members => members.concat([{ ...member, pivot }]));
		setSuggestions(suggestions => suggestions.filter(user => user.ID !== member.ID));
	};

	const onUserChanged = (member: MemberType) => {
		let membersList = [...members];

		membersList = membersList.map(m => {
			if (m.ID === member.ID) {
				m = member;
			} else if (m.pivot.Permissions === Permissions.Owner && member.pivot.Permissions === Permissions.Owner) {
				m.pivot.Permissions = Permissions.ReadWrite;
			}
			return m;
		});

		setMembers(membersList);
	};

	const onUserRemoved = (member: MemberType) => {
		setMembers(members => members.filter(_member => _member.ID !== member.ID));
		setSuggestions(suggestions => suggestions.concat([member]));
	};

	const internalMembers = members.filter(member => member.Scope === 'internal' && member.pivot);
	const externalMembers = members.filter(member => member.Scope === 'external' && member.pivot);

	const updateMembers = useCallback(
		async (members: MemberType[], recursive: boolean) => {
			await update(members, { recursive: recursive! });
			setIsOpen(false);
			setIsDirty(false);
			toast.success(<FormattedMessage id="file-manager.permissions_updated" values={{ item: item.getName() }} />);
		},
		[item, setIsDirty, update]
	);

	return (
		<>
			<Modal size={ModalSize.XLarge} isOpen={isOpen} onSubmit={handleSubmit} {...modalProps}>
				<ModalBody>
					<FontAwesomeIcon icon="shield-check" symbol="fa-shield-check" fixedWidth />
					<FontAwesomeIcon icon="shield" symbol="fa-shield" fixedWidth />
					<FontAwesomeIcon icon="bell" symbol="fa-bell" fixedWidth />
					<FontAwesomeIcon icon="bell-slash" symbol="fa-bell-slash" fixedWidth />
					<FontAwesomeIcon icon="times" symbol="fa-times" fixedWidth />

					{isLoading && (
						<p>
							<FormattedMessage id="loading" />
						</p>
					)}

					<div className="my-3">
						{internalMembers.length > 0 && (
							<>
								<div className="flex items-center">
									<h4 className="inline-flex items-center pr-4 text-sm font-medium text-gray-500">
										<FontAwesomeIcon icon="building" className="mr-2 text-gray-300" />
										<FormattedMessage id="share.internal_user" values={{ n: internalMembers.length }} />
									</h4>
									<div className="flex-1 w-full h-px bg-gray-200" />
								</div>
								<div className="mt-5">
									{internalMembers.map(member => (
										<div key={member.ID} className="my-3">
											<UserPermissionItem
												key={member.ID}
												item={item}
												user={member as MemberType}
												onChange={onUserChanged}
												onRemove={onUserRemoved}
												canChangeOwner={item.creator.ID === account?.ID}
											/>
										</div>
									))}
								</div>
							</>
						)}
					</div>
					<div className="my-3">
						{externalMembers.length > 0 && (
							<div className="mt-8">
								<div className="flex items-center mt-4">
									<h4 className="inline-flex items-center pr-4 text-sm font-medium text-gray-500">
										<FontAwesomeIcon icon="globe-americas" className="mr-2 text-gray-300" />
										<FormattedMessage id="share.external_user" values={{ n: externalMembers.length }} />
									</h4>
									<div className="flex-1 w-full h-px bg-gray-200" />
								</div>
								<div className="mt-5">
									{externalMembers.map(member => (
										<div key={member.ID} className="my-3">
											<UserPermissionItem key={member.ID} item={item} user={member as MemberType} onChange={onUserChanged} onRemove={onUserRemoved} />
										</div>
									))}
								</div>
							</div>
						)}
					</div>

					{suggestions.length > 0 && (
						<div className="mt-8">
							<h6 className="font-semibold">
								<FormattedMessage id="share.users_in_organization" />
							</h6>
							<p className="mb-4 text-xs text-gray-400">
								<FormattedMessage id="share.tap_to_add_permissions" />
							</p>
							<div className="flex overflow-x-auto">
								{suggestions.map(user => (
									<UserOrganizationItem key={user.ID} user={user} onAdded={onUserAdded} />
								))}
							</div>
						</div>
					)}
				</ModalBody>
				<ModalFooter className="flex items-center justify-between gap-3">
					<div className="flex items-center gap-3">
						<Button variant={Variant.primary} type="submit" disabled={!isDirty}>
							{item instanceof Document ? <FormattedMessage id="apply" /> : <FormattedMessage id="continue" />}
						</Button>
						<Button intent={Intent.secondary} variant={Variant.light} type="button" onClick={() => setIsOpen(false)} className="ml-2">
							<FormattedMessage id="close" />
						</Button>
					</div>
					<Tooltip tip={<FormattedMessage id="tap_to_copy_to_clipboard" />}>
						<button type="button" className="flex items-center gap-2 px-2 py-1 text-xs text-gray-500 transition-colors bg-white border rounded hover:text-inherit hover:border-gray-300">
							<CopyToClipboard text={window.location.origin + item.getUrl()} onCopy={() => setCopied(true)}>
								<span>{window.location.origin + item.getUrl()}</span>
							</CopyToClipboard>
							<FontAwesomeIcon size="sm" className={classNames('transition-colors', { 'text-green-600': copied })} icon={copied ? 'check' : 'clipboard'} />
						</button>
					</Tooltip>
				</ModalFooter>
			</Modal>

			{/* Apply to all sub items confirmation */}
			<Modal size={ModalSize.Small} isOpen={showConfirmation} onSubmit={handleConfirmationSubmit} closeable onAfterClose={() => setShowConfirmation(false)}>
				<ModalHeaderOnlyTitle>
					<FormattedMessage id="share.apply_to_sub" values={{ folder: item.getName() }} />
				</ModalHeaderOnlyTitle>
				<ModalBody>
					<div className="grid grid-cols-2 gap-6">
						<BigButton type="button" onClick={() => setApplyToAll(true)} selected={applyToAll === true}>
							<div className="flex flex-col justify-between h-full">
								<ImagePermissionsApply className="w-full" />
								<p className="mt-3 text-xs">
									<FormattedMessage id="share.propogate_folder" />
								</p>
							</div>
						</BigButton>
						<BigButton type="button" onClick={() => setApplyToAll(false)} selected={applyToAll === false}>
							<div className="flex flex-col justify-between h-full">
								<ImagePermissionsDontApply className="w-full" />
								<p className="mt-3 text-xs">
									<FormattedMessage id="share.dont_propagate_folder" />
								</p>
							</div>
						</BigButton>
					</div>
					{applyToAll && (
						<Alert variant={Variant.warning} className="mt-4">
							<FormattedMessage id="share.warning_propagate" values={{ strong: msg => <strong className="font-bold">{msg}</strong> }} />
						</Alert>
					)}
				</ModalBody>
				<ModalFooter>
					<Button variant={Variant.primary} type="submit">
						<FormattedMessage id="apply" />
					</Button>
				</ModalFooter>
			</Modal>

			{/* Updaing modal */}
			<Modal size={ModalSize.Small} isOpen={isUpdating}>
				<ModalBody>
					<div className="py-5 text-center text-gray-400">
						<FontAwesomeIcon icon="cog" className="mb-3" size="4x" spin />
						<h6 className="mb-3">
							<FormattedMessage id="share.saving-permissions" />
						</h6>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

type UserPermissionItemProps = {
	item: Fileable;
	user: MemberType;
	onChange?: (user: MemberType) => void;
	onRemove?: (user: MemberType) => void;
	disabled?: boolean;
	canChangeOwner?: boolean;
};

const UserPermissionItem: FC<UserPermissionItemProps> = ({ item, user, onChange = () => undefined, onRemove = () => undefined, disabled = false, canChangeOwner = false }) => {
	const changePermissions = (option: OnChangeValue<KeyValueDescriptionOptionType, false>) => {
		if (!option) {
			return;
		}

		onChange({ ...user, pivot: { ...user.pivot, Permissions: option.value as Permissions } });
	};

	const toggleNotifications = () => {
		onChange({ ...user, pivot: { ...user.pivot, Notify: !user.pivot.Notify } });
	};

	const showActivationIcons = item instanceof Folder && item.SecuredSpace && item.SecuredSpace;
	const isActivated = item instanceof Folder && item.SecuredSpace && !!user.pivot.secure_space_shared_at && !!user.pivot.secure_space_activated_at;
	const notActivated = item instanceof Folder && item.SecuredSpace && !!user.pivot.secure_space_shared_at && !user.pivot.secure_space_activated_at;

	const options = useMemo(() => {
		let _options: KeyValueDescriptionOptionType<Permissions>[] = [
			{
				value: Permissions.ReadOnly,
				label: <FormattedMessage id="permission" values={{ permission: Permissions.ReadOnly }} />,
				description: <FormattedMessage id="permission-description" values={{ permission: Permissions.ReadOnly }} />
			},
			{
				value: Permissions.ReadWrite,
				label: <FormattedMessage id="permission" values={{ permission: Permissions.ReadWrite }} />,
				description: <FormattedMessage id="permission-description" values={{ permission: Permissions.ReadWrite }} />
			}
		];

		if (canChangeOwner) {
			_options.push({
				value: Permissions.Owner,
				label: <FormattedMessage id="permission" values={{ permission: Permissions.Owner }} />,
				description: <FormattedMessage id="permission-description" values={{ permission: Permissions.Owner }} />
			});
		}
		if (item.creator.ID === user.ID) {
			_options = [{ value: Permissions.ReadWrite, label: <FormattedMessage id="permission" values={{ permission: 'creator' }} />, description: undefined }];
		}

		return _options;
	}, [canChangeOwner, item.creator.ID, user.ID]);

	let value = options.find(opt => opt.value === user.pivot.Permissions);

	if (item.creator.ID === user.ID) {
		value = options[0];
	}

	return (
		<>
			<div className="flex items-center space-x-4">
				<div className="grid content-center flex-1 grid-cols-12 gap-x-3">
					<div className="flex items-center col-span-12 sm:col-span-7">
						<div className="flex items-center">
							<div className="mr-3">
								{isActivated ? (
									<Tooltip
										tip={
											<FormattedMessage
												id="share.secure_space_configured"
												values={{ time: <DateTimeDisplay force defaultFormat="full" showTooltip={false} value={user.pivot.secure_space_activated_at!} /> }}
											/>
										}>
										<span>
											<FontAwesomeSvg id="fa-shield-check" className="text-green-500" />
										</span>
									</Tooltip>
								) : notActivated ? (
									<Tooltip
										tip={
											<FormattedMessage
												id="share.secure_space_not_configured"
												values={{ time: <DateTimeDisplay force defaultFormat="full" showTooltip={false} value={user.pivot.secure_space_shared_at!} /> }}
											/>
										}>
										<span>
											<FontAwesomeSvg id="fa-shield" className="text-gray-300" />
										</span>
									</Tooltip>
								) : showActivationIcons ? (
									<div className="w-4 h-4" />
								) : null}
							</div>

							<UserAvatar user={user} size={Size.sm} className="mr-3" />

							<div className="flex-1 min-w-0 truncate">
								<p className="font-medium">{user.Name}</p>
								<p className="text-xs text-gray-500">{user.Email}</p>
							</div>
						</div>
					</div>
					<div className="flex flex-col justify-center col-span-12 mt-2 ml-16 sm:ml-0 sm:col-span-5 sm:mt-0">
						<ComboBox
							value={value}
							components={{
								Option: KeyValueDescriptionOption
							}}
							isSearchable={false}
							isDisabled={disabled || item.creator.ID === user.ID}
							getOptionValue={({ value }) => value}
							options={options}
							onChange={changePermissions}
						/>
					</div>
				</div>
				<div className="flex items-center space-x-4">
					<Tooltip tip={user.pivot.Notify ? <FormattedMessage id="share.tap_turn_off_alerts" /> : <FormattedMessage id="share.tap_turn_on_alerts" />}>
						<Button size={Size.sm} circle type="button" disabled={disabled} onClick={toggleNotifications}>
							{user.pivot.Notify ? <FontAwesomeSvg id="fa-bell" className="text-green-500" /> : <FontAwesomeSvg id="fa-bell-slash" className="text-gray-500" />}
						</Button>
					</Tooltip>
					<Tooltip tip={<FormattedMessage id="share.remove_permissions" />}>
						<Button size={Size.sm} circle disabled={disabled || item.creator.ID === user.ID} type="button" onClick={() => onRemove(user)}>
							<FontAwesomeSvg id="fa-times" />
						</Button>
					</Tooltip>
				</div>
			</div>
		</>
	);
};

type UserOrganizationItemProps = {
	user: NullablePivotMemberType;
	onAdded?: (member: NullablePivotMemberType) => void;
};

const UserOrganizationItem: FC<UserOrganizationItemProps> = ({ user, onAdded = () => undefined }) => (
	<button type="button" onClick={() => onAdded(user)} className="relative flex flex-col mr-4 overflow-hidden rounded-lg cursor-pointer min-w-20 min-h-20 max-h-20 max-w-20 last:mr-0">
		<UserAvatar size={null} rounded={false} className="w-full h-full shrink-0" user={user} />
		<div className="absolute inset-x-0 bottom-0 top-auto p-1 rounded-t from-black/70 bg-gradient-to-t to-black/20">
			<h5 className="text-xs leading-none text-white">{user.Name}</h5>
		</div>
	</button>
);

const DEFAULT_PERMISSION = Permissions.ReadOnly;
const DEFAULT_NOTIFY = true;
