import CharacterCount from '@tiptap/extension-character-count';
import Dropcursor from '@tiptap/extension-dropcursor';
import Gapcursor from '@tiptap/extension-gapcursor';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import { type Content, Editor, EditorProvider, type Extensions, type HTMLContent, type JSONContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { CharacterLimitDisplay } from '@ui/RichTextEditor/CharacterLimitDisplay';
import { Toolbar } from '@ui/RichTextEditor/Toolbar';
import classNames from 'classnames';
import { type ForwardedRef, forwardRef, type PropsWithChildren, type ReactNode, useImperativeHandle, useMemo, useRef } from 'react';
import { createSuggestionMenu, type CreateSuggestionMenuOptions } from './SuggestionMenu';

export type RichTextEditorRef = {
	json(): JSONContent;
	html(): string;
	text(): string;
	clear(): void;
	setJsonContent(content: JSONContent): void;
	setHtmlContent(string: HTMLContent): void;
	isEmpty(): boolean;
	focusStart(): void;
};

export type RichTextEditorProps<S = any> = PropsWithChildren<{
	characterLimit?: number | null;
	placeholder?: string;
	value?: Content;
	outline?: boolean;
	disabled?: boolean;
	showCannedMessages?: boolean;
	className?: string;
	autoFocus?: boolean;
	suggestion?: CreateSuggestionMenuOptions<S>;
	onCharacterCountChange?: (count: number) => void;
	onUpdate?: () => void;
	onBlur?: () => void;
}>;

const RichTextEditorRenderFunction = <S,>(
	{
		characterLimit = null,
		placeholder,
		value,
		disabled = false,
		onCharacterCountChange = () => undefined,
		onUpdate = () => undefined,
		onBlur = () => undefined,
		autoFocus = false,
		showCannedMessages = true,
		suggestion,
		className,
		children
	}: RichTextEditorProps<S>,
	ref: ForwardedRef<RichTextEditorRef>
) => {
	const editor = useRef<Editor>();

	useImperativeHandle(ref, () => ({
		json: () => editor.current?.getJSON() ?? {},
		html: () => editor.current?.getHTML() ?? '',
		text: () => editor.current?.getText() ?? '',
		clear: () => editor.current?.commands.clearContent(),
		setJsonContent: (content: JSONContent) => editor.current?.commands.setContent(content),
		setHtmlContent: (content: HTMLContent) => editor.current?.commands.setContent(content),
		isEmpty: () => editor.current?.isEmpty ?? true,
		focusStart: () => editor.current?.commands.focus('start')
	}));

	const extensions = useMemo(() => {
		const extensions: Extensions = [
			StarterKit,
			TaskList.configure({
				HTMLAttributes: {
					class: 'not-prose'
				}
			}),
			TaskItem.configure({
				nested: true
			}),
			Link.configure({
				openOnClick: 'whenNotEditable',
				protocols: ['http', 'https', { scheme: 'mailto', optionalSlashes: true }, { scheme: 'tel', optionalSlashes: true }]
			}),
			Image.configure({
				inline: true,
				allowBase64: true
			}),
			CharacterCount.configure({
				limit: characterLimit
			}),
			Placeholder.configure({
				placeholder,
				emptyEditorClass: 'is-editor-empty'
			}),
			Dropcursor,
			Gapcursor
		];

		if (suggestion) {
			extensions.push(
				Mention.configure({
					HTMLAttributes: {
						class: 'px-1 py-0.5 rounded bg-blue-100 text-blue-600'
					},
					suggestion: createSuggestionMenu(suggestion)
				})
			);
		}

		return extensions;
	}, [characterLimit, suggestion, placeholder]);

	const pluginsToolbarContainer = classNames('flex flex-col sm:flex-row sm:items-center', {
		'justify-between': characterLimit !== null || children,
		'justify-end': characterLimit === null && !!children
	});

	const containerClassName = classNames(className, 'overflow-hidden border border-gray-300 rounded', {
		'bg-gray-100': disabled,
		'bg-white': !disabled
	});

	return (
		<div className={containerClassName}>
			<EditorProvider
				editorProps={{
					attributes: {
						style: 'word-break: break-word',
						class: 'prose prose-sm prose-theme max-w-none break-words !min-h-36 px-4 py-3'
					}
				}}
				onBeforeCreate={({ editor: _editor }) => {
					editor.current = _editor;
				}}
				onUpdate={({ editor }) => {
					onCharacterCountChange(editor.storage.characterCount.characters());
					onUpdate?.();
				}}
				onBlur={onBlur}
				editable={!disabled}
				autofocus={autoFocus}
				content={value}
				extensions={extensions}
				slotBefore={<Toolbar showCannedMessages={showCannedMessages} />}>
				<div className={pluginsToolbarContainer}>
					{children}
					<CharacterLimitDisplay limit={characterLimit ?? undefined} />
				</div>
			</EditorProvider>
		</div>
	);
};

export const RichTextEditor = forwardRef(RichTextEditorRenderFunction) as <S = any>(props: RichTextEditorProps<S> & { ref?: ForwardedRef<RichTextEditorRef> }) => ReactNode;
