import { createLengthAwarePagination, LengthAwarePaginator } from '@components/Pagination';
import type { PaymentRequest } from '@components/PaymentRequest';
import { PaymentRequestItem, PaymentRequestsPromo, type PaymentRequestsResponse } from '@components/PaymentRequest';
import { Button, Intent, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { useSearchParams } from '@hooks/useSearchParams';
import Folder from '@models/Folder';
import { Module } from '@types';
import Card from '@ui/Card';
import EmptyState from '@ui/EmptyState';
import { type FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { Link, type LinkProps } from 'react-router-dom';

type PaymentRequestDashboardProps = {
	view?: Folder | 'organization' | 'user';
	onCreate?: () => void;
	showTabs?: boolean;
};

export const PaymentRequestDashboard: FC<PaymentRequestDashboardProps> = ({ view = 'user', showTabs = true, onCreate }) => {
	const { client } = useClient();
	const { account } = useAccount();
	const [{ filter = '' }] = useSearchParams();

	const [page, setPage] = useState(1);

	const { data: paymentRequests, isLoading } = useQuery(
		['payment-requests', view, filter, page],
		async () => {
			let url = 'payment-requests';

			if (view instanceof Folder) {
				url = `folders/${view.getKey()}/payment-requests`;
			} else if (view === 'organization') {
				url = 'organization/payment-requests';
			}
			const payload = await client.url(url).query({ filter, page }).get().json<PaymentRequestsResponse>();

			const paginator = createLengthAwarePagination<PaymentRequest>(payload.data);

			paginator.data = paginator.data.map(paymentRequest => ({ ...paymentRequest, fileable: new Folder(paymentRequest.fileable) }));

			return { ...payload, data: paginator };
		},
		{
			enabled: account!.business.hasModule(Module.Payments)
		}
	);

	if (!account!.business.hasModule(Module.Payments)) {
		return <PaymentRequestsPromo />;
	}

	return (
		<>
			<div className="flex items-center mb-4 space-x-4">
				<h3 className="text-base font-medium text-gray-400 md:text-xs">
					<FormattedMessage id="payment-requests" />
				</h3>
				<div className="flex-1 h-px bg-gray-300" />
				{onCreate !== undefined && (
					<Button shadow onClick={onCreate} variant={Variant.primary} intent={Intent.primary} iconStart="paper-plane">
						<FormattedMessage id="payment-requests.request" />
					</Button>
				)}
			</div>

			<Card size={null}>
				{showTabs && (
					<div className="px-6 space-x-8 bg-gray-100 border-b border-gray-100">
						<FilterLink count={paymentRequests?.totals.all ?? 0}>
							<FormattedMessage id="payment-requests.all" />
						</FilterLink>
						<FilterLink count={paymentRequests?.totals.payed ?? 0} value="payed">
							<FormattedMessage id="payment-requests.payed" />
						</FilterLink>
						<FilterLink count={paymentRequests?.totals.unpayed ?? 0} value="unpayed">
							<FormattedMessage id="payment-requests.unpayed" />
						</FilterLink>
						<FilterLink count={paymentRequests?.totals.refunded ?? 0} value="refunded">
							<FormattedMessage id="payment-requests.refunded" />
						</FilterLink>
						<FilterLink count={paymentRequests?.totals.archived ?? 0} value="archived">
							<FormattedMessage id="payment-requests.archived" />
						</FilterLink>
					</div>
				)}
				<div className="divide-y divide-gray-200">
					{paymentRequests !== undefined && paymentRequests.data.data.length === 0 && !isLoading && (
						<EmptyState icon="dollar-sign" title={<FormattedMessage id="payment-requests.no_results" />} />
					)}

					{paymentRequests?.data.data.map(paymentRequest => (
						<PaymentRequestItem key={paymentRequest.Id} paymentRequest={paymentRequest} showCreator={view === 'organization'} />
					))}

					{isLoading && (
						<div className="flex items-center justify-center h-48">
							<FontAwesomeIcon icon="spinner-third" spin size="3x" className="text-gray-500" />
						</div>
					)}
				</div>
			</Card>

			{paymentRequests !== undefined && (
				<div className="mt-8">
					<LengthAwarePaginator {...paymentRequests.data} onPage={setPage} />
				</div>
			)}
		</>
	);
};

type FilterLinkProps = Omit<LinkProps, 'to'> & {
	name?: string;
	value?: string;
	count?: number;
};

export const FilterLink: FC<FilterLinkProps> = ({ value, count, className = '', children, name = 'filter', ...linkProps }) => {
	const [searchParam, setSearchParams] = useSearchParams();

	const currentValue = searchParam[name];

	return (
		<Link
			onClick={e => {
				e.preventDefault();
				setSearchParams({ [name]: value });
			}}
			to={{ search: `${name}=${value}` }}
			className={`inline-flex transition duration-300 items-center px-2 pt-4 pb-2 font-semibold ${
				currentValue === value ? 'text-gray-600 border-b-2 border-gray-700 -mb-px' : 'opacity-40'
			} ${className}`}
			{...linkProps}>
			{children}
			{count !== undefined && <span className="inline-flex items-center justify-center px-2 py-px ml-3 text-xs font-normal text-gray-600 bg-gray-200 rounded-full">{count}</span>}
		</Link>
	);
};
