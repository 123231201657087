import { DialogUserSelection } from '@components/DialogUserSelection';
import { useFileableMembers } from '@components/FileManager';
import { MessageListContext } from '@components/Message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import type { IUser } from '@models/User';
import { downloadResponse } from '@service/Client';
import { Button, Checkbox, InputGroup, Intent, Size, Variant } from '@convoflo/ui';
import { Permissions } from '@types';
import Dropdown, { DropdownGroup, DropdownItem } from '@ui/Dropdown';
import { Tooltip } from '@ui/Tooltip';
import { formatDate, formatLocalDateToUTC } from '@utils/DateUtils';
import isEmpty from 'lodash.isempty';
import { stringify } from 'qs';
import { type FC, useContext, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useMutation } from 'react-query';
import type { WretchError, WretchResponse } from 'wretch';

type MessageListStatusBarProps = {
	participants?: number;
	total?: number;
	onSort?: (sort: string) => void;
};

export const MessageListStatusBar: FC<MessageListStatusBarProps> = ({ participants = 0, total = 0 }) => {
	const { client } = useClient();
	const { account } = useAccount();
	const { fileable, order, filters, setFilters, onOrderChanged } = useContext(MessageListContext);

	const { members = [] } = useFileableMembers(fileable!);

	const [showUserSelection, setShowUserSelection] = useState(false);

	const { mutate: _export, isLoading: isExporting } = useMutation<WretchResponse, WretchError, { format: string }>(
		async ({ format }) => {
			return await client
				.url(`${fileable!.getRoute('comments')}.${format}`)
				.query(
					stringify(
						{
							order,
							filters: {
								ids: filters.ids ?? [],
								archived: filters.archived ? 1 : undefined,
								users: filters.users?.map(u => u.ID) ?? [],
								start_date: filters.startDate ? formatLocalDateToUTC(filters.startDate).toISOString() : undefined,
								end_date: filters.endDate ? formatLocalDateToUTC(filters.endDate).toISOString() : undefined
							}
						},
						{ arrayFormat: 'brackets' }
					)
				)
				.get()
				.res();
		},
		{
			onError: console.error,
			onSuccess: response => {
				downloadResponse(`convoflo-messages-export-${formatDate(null, null, 'yMMddHHmmss')}.pdf`, 'application/pdf')(response);
			}
		}
	);

	let audience = members.filter(m => m.ID !== account!.ID) as IUser[];

	if ((filters.users ?? []).length > 0) {
		audience = filters.users ?? [];
	}

	const onUsersSelected = (users: IUser[]) => {
		setShowUserSelection(false);
		setFilters(filters => ({ ...filters, users }));
	};

	return (
		<>
			<div className="px-6 my-3 space-y-3 sm:px-0">
				<div className="flex items-center justify-between">
					<div className="space-x-3 group">
						<Tooltip tip={<FormattedMessage id="comments.total_comments" values={{ n: total }} />}>
							<span className="text-sm font-medium text-gray-400">
								<FontAwesomeIcon icon="comment" className="mr-2" />
								<FormattedNumber value={total} />
							</span>
						</Tooltip>
						<Tooltip tip={<FormattedMessage id="comments.total_participants" values={{ n: participants }} />}>
							<span className="text-sm font-medium text-gray-400">
								<FontAwesomeIcon icon="user" className="mr-2" />
								<FormattedNumber value={participants} />
							</span>
						</Tooltip>
					</div>
					<div>
						<Dropdown>
							<Button size={Size.sm} variant={Variant.light} intent={Intent.tertiary} icon="sort">
								<FormattedMessage id="comments.options.sort" /> <FontAwesomeIcon icon="caret-down" className="ml-2" />
							</Button>
							<DropdownGroup>
								<DropdownItem active={order === 'newest'} onClick={() => onOrderChanged('newest')}>
									<FormattedMessage id="comments.newest" />
								</DropdownItem>
								<DropdownItem active={order === 'oldest'} onClick={() => onOrderChanged('oldest')}>
									<FormattedMessage id="comments.oldest" />
								</DropdownItem>
							</DropdownGroup>
						</Dropdown>
						<Dropdown>
							<Button size={Size.sm} variant={Variant.light} intent={Intent.tertiary} loading={isExporting} icon="cloud-download">
								{isExporting ? <FormattedMessage id="loading" /> : <FormattedMessage id="export" />} <FontAwesomeIcon icon="caret-down" className="ml-2" />
							</Button>
							<DropdownGroup>
								<DropdownItem onClick={() => _export({ format: 'pdf' })} icon="file-pdf">
									<FormattedMessage id={`comments.exports.format.pdf`} />
								</DropdownItem>
							</DropdownGroup>
						</Dropdown>
					</div>
				</div>
				<div className="flex flex-wrap items-center space-x-3 text-sm">
					<div className="grid items-center flex-1 grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3">
						{'startDate' in filters && (
							<InputGroup
								block
								prepended={
									<span className="inline-flex items-center mr-1.5 text-gray-500">
										<FormattedMessage id="comments.filters.from" />
									</span>
								}
								type="date"
								size={Size.sm}
								value={filters.startDate}
								onChange={e => setFilters({ ...filters, startDate: e.target.value })}
							/>
						)}

						{'endDate' in filters && (
							<InputGroup
								block
								prepended={
									<span className="inline-flex items-center mr-1.5 text-gray-500">
										<FormattedMessage id="comments.filters.to" />
									</span>
								}
								type="date"
								size={Size.sm}
								value={filters.endDate}
								onChange={e => setFilters({ ...filters, endDate: e.target.value })}
							/>
						)}
						{'users' in filters && fileable?.pivot?.Permissions === Permissions.ReadWrite && (
							<Dropdown>
								<Button size={Size.sm} className="space-x-2" variant={Variant.light} intent={Intent.secondary} type="button">
									{filters.users!.length === 0 ? (
										<>
											<FontAwesomeIcon icon="users" />
											<span>
												<FormattedMessage id="comments.audience.all-members" />
											</span>
										</>
									) : filters.users!.length === 1 && filters.users![0].ID === account?.ID ? (
										<>
											<FontAwesomeIcon icon="lock" />
											<span>
												<FormattedMessage id="comments.audience.self" />
											</span>
										</>
									) : (
										<>
											<FontAwesomeIcon icon={['fad', 'users']} />
											<span>
												<FormattedMessage id="comments.audience.selected-members" values={{ n: filters.users!.length }} />
											</span>
										</>
									)}
									<FontAwesomeIcon icon="caret-down" />
								</Button>
								<DropdownGroup>
									<DropdownItem icon="users" onClick={() => setFilters({ ...filters, users: [] })}>
										<FormattedMessage id="comments.audience.all-members" />
									</DropdownItem>
									<DropdownItem icon={['fad', 'users']} onClick={() => setShowUserSelection(true)}>
										<FormattedMessage id="comments.audience.select-members" />
									</DropdownItem>
									<DropdownItem icon="user" onClick={() => setFilters({ ...filters, users: [members.find(m => m.ID === account!.ID)!] })}>
										<FormattedMessage id="comments.audience.self" />
									</DropdownItem>
								</DropdownGroup>
							</Dropdown>
						)}

						{'archived' in filters && (
							<Button
								size={Size.sm}
								className="!justify-start !space-x-2"
								variant={Variant.light}
								intent={Intent.secondary}
								type="button"
								onClick={() => setFilters({ ...filters, archived: !filters.archived })}>
								<Checkbox checked={filters.archived} className="pointer-events-none" />
								<span>
									<FormattedMessage id="comments.filters.archived" />
								</span>
							</Button>
						)}

						<Dropdown>
							<Button icon={['fal', 'filter']} size={Size.sm} variant={Variant.light} intent={Intent.secondary} className="border-dashed !text-gray-400 justify-self-start">
								<FormattedMessage id="comments.filters.add" />
								<FontAwesomeIcon icon="caret-down" className="ml-1.5" />
							</Button>
							<DropdownGroup>
								{fileable?.pivot?.Permissions === Permissions.ReadWrite && (
									<DropdownItem icon={['fad', 'users']} onClick={() => setFilters({ ...filters, users: [] })} disabled={'users' in filters}>
										<FormattedMessage id="comments.filters.audience" />
									</DropdownItem>
								)}
								<DropdownItem icon="calendar-day" onClick={() => setFilters({ ...filters, startDate: '', endDate: '' })} disabled={'startDate' in filters || 'endDate' in filters}>
									<FormattedMessage id="comments.filters.date" />
								</DropdownItem>
								<DropdownItem icon="archive" onClick={() => setFilters({ ...filters, archived: true })} disabled={'archived' in filters}>
									<FormattedMessage id="comments.filters.archived" />
								</DropdownItem>
							</DropdownGroup>
						</Dropdown>
					</div>
					{!isEmpty(filters) && (
						<Button size={Size.xs} variant={Variant.danger} intent={Intent.tertiary} onClick={() => setFilters({})}>
							<FontAwesomeIcon icon="times" className="mr-1.5" />
							<FormattedMessage id="comments.filters.clear" />
						</Button>
					)}
				</div>
			</div>
			{showUserSelection && (
				<DialogUserSelection users={members.filter(m => m.ID !== account!.ID)} selection={audience} onChange={onUsersSelected} onAfterClose={() => setShowUserSelection(false)} />
			)}
		</>
	);
};
