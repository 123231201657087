import { FileManagerContext } from '@components/FileManager';
import { useView } from '@hooks/use-view';
import useClient from '@hooks/useClient';
import File from '@models/File';
import Folder from '@models/Folder';
import { versionMiddleware } from '@service/Client';
import { Button, InputGroup, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, ModalSize, Row, ValidationField, Variant } from '@convoflo/ui';
import type { Fileable } from '@types';
import { beforeLast } from '@utils/StringUtils';
import { type FC, type FormEvent, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';
import type { WretchError } from 'wretch';

type RenameMutation = {
	name: string;
};

type RenamedEvent = {
	fileable: Fileable;
};

type DialogRenameFileableProps = Omit<ModalProps, 'isOpen'> & {
	fileable: Fileable;
	onRenamed?: (event: RenamedEvent) => void;
};

export const DialogRenameFileable: FC<DialogRenameFileableProps> = ({ fileable, onRenamed = () => undefined, ...props }) => {
	const { client, validation } = useClient();
	const { update: updateItem = () => undefined } = useContext(FileManagerContext) ?? {};
	const { view, update: updateView } = useView();

	const nameWithoutExtension = fileable instanceof File ? beforeLast(fileable.getName() || '', `.${fileable.getFileExtension()}`) : fileable.getName() || '';

	const [isOpen, setIsOpen] = useState(true);
	const [name, setName] = useState(nameWithoutExtension);

	const { mutate: rename, isLoading: isRenaming } = useMutation<RenamedEvent, WretchError, RenameMutation>(
		async ({ name }) => {
			let payload = await client
				.url(fileable.getRoute(null, fileable instanceof File ? 'files' : undefined))
				.middlewares([versionMiddleware(2)])
				.catcher(403, error => {
					if (error.json?.error?.code === 'ongoing_signature') {
						toast.error(<FormattedMessage id="dialog-rename.error_ongoing_signature_request" />);
						return;
					}

					throw error;
				})
				.json({ name })
				.put()
				.json<Fileable>();

			if (fileable instanceof File) {
				payload = new File(payload);
			} else if (fileable instanceof Folder) {
				payload = new Folder(payload);
			}

			return { fileable: payload };
		},
		{
			onError: console.log,
			onSuccess: event => {
				toast.success(<FormattedMessage id="dialog-rename.renamed" values={{ item: event.fileable.getName() }} />);
				onRenamed(event);
				setIsOpen(false);

				updateItem(event.fileable);

				if ((view instanceof Folder || view instanceof File) && view.getKey() === event.fileable.getKey()) {
					updateView(event.fileable);
				}
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		rename({ name });
	};

	return (
		<Modal size={ModalSize.XSmall} isOpen={isOpen} onSubmit={onSubmit} {...props}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage
					id="dialog-rename.title"
					values={{
						item: fileable.getName()
					}}
				/>
			</ModalHeaderOnlyTitle>
			<ModalBody disabled={isRenaming}>
				<Row>
					<ValidationField fieldName="name" validation={validation}>
						<InputGroup
							block
							appended={fileable instanceof File ? <span className="text-sm text-gray-600">.{fileable.getFileExtension()}</span> : undefined}
							autoFocus
							value={name || ''}
							type="text"
							onChange={e => setName(e.target.value)}
						/>
					</ValidationField>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button loading={isRenaming} disabled={name === ''} type="submit" variant={Variant.primary}>
					<FormattedMessage id="dialog-rename.rename" />
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
