import type { NotificationItemType, NotificationType } from '@components/Notification';
import useClient from '@hooks/useClient';
import File from '@models/File';
import Folder from '@models/Folder';
import { type FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

type NotificationItemDetailsProps = {
	notification: NotificationType;
	item: NotificationItemType;
	source?: string;
};

export const NotificationItemDetails: FC<NotificationItemDetailsProps> = ({ notification, item, source }) => {
	const { client } = useClient();

	const [isRead, setIsRead] = useState(!!item.read_at);

	const markAsRead = async () => {
		setIsRead(true);
		client
			.url('account/notifications')
			.json({ items: [item.Id] })
			.put()
			.res();
	};

	// const markAsUnread = async () => {
	// 	setIsRead(false);
	// 	client
	// 		.url('account/notifications')
	// 		.json({ items: [item.Id] })
	// 		.put()
	// 		.res();
	// };

	const object = item.object['@type'] === 'Folder' ? new Folder(item.object) : item.object['@type'] === 'Document' ? new File(item.object) : undefined;
	if (!object) {
		return null;
	}

	let link = object.Link;

	if (link !== null) {
		link += link.indexOf('?') > 0 ? '&' : '?';
		link += `ref=notif&_niid=${notification.Id}`;

		if (source) {
			link += `&source=${source}`;
		}
	}

	return (
		<div className="flex items-center my-1 group">
			{object.icon('mr-1')}
			<div className="flex-1 min-w-0 truncate">
				<Link to={link} className={`truncate text-sm ${!isRead ? 'text-theme-primary hover:underline' : 'text-black text-opacity-50 hover:underline'}`}>
					{object.getName()}
				</Link>
				{!isRead && (
					<span className="inline-block text-sm text-gray-400 sm:hidden group-hover:inline-block">
						<span className="mx-2">–</span>
						<button type="button" onClick={markAsRead} className="text-theme-primary hover:underline">
							<FormattedMessage id="alerts.mark_as_read" />
						</button>
					</span>
				)}
			</div>
		</div>
	);
};
