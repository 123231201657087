import type { CannedMessage } from '@components/CannedMessages';
import { Button, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Variant } from '@convoflo/ui';
import useClient from '@hooks/useClient';
import { type FC, type FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';

type CannedMessageConfirmDeleteProps = Omit<ModalProps, 'isOpen'> & {
	message: CannedMessage;
};

export const CannedMessageConfirmDelete: FC<CannedMessageConfirmDeleteProps> = ({ message, ...modalProps }) => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	const [isOpen, setIsOpen] = useState(true);

	const { mutate: _delete, isLoading: isDeleting } = useMutation(async () => await client.url(`account/canned-messages/${message.Id}`).delete().res(), {
		onError: (_error, _variables, previousMessages) => {
			queryClient.setQueryData(['canned-messages'], previousMessages);
		},
		onMutate: async () => {
			await queryClient.cancelQueries(['canned-messages']);

			const previousMessages = queryClient.getQueryData<CannedMessage[]>(['canned-messages']);

			if (previousMessages === undefined) {
				return undefined;
			}

			queryClient.setQueryData(
				['canned-messages'],
				previousMessages.filter(_message => _message.Id !== message.Id)
			);

			return previousMessages;
		},
		onSuccess: () => {
			toast.success(<FormattedMessage id="canned-messages-crud.canned_message_deleted" />);
		}
	});

	const onSubmitDelete = (e: FormEvent) => {
		e.preventDefault();
		_delete();
	};

	return (
		<Modal isOpen={isOpen} onSubmit={onSubmitDelete} {...modalProps}>
			<ModalHeaderOnlyTitle>{message.Title}</ModalHeaderOnlyTitle>
			<ModalBody>
				<p className="leading-tight">
					<FormattedMessage id="dialog-delete.confirmation" />
				</p>
			</ModalBody>
			<ModalFooter>
				<Button disabled={isDeleting} type="submit" className="mr-2" variant={Variant.danger}>
					{isDeleting ? <FormattedMessage id="deleting" /> : <FormattedMessage id="delete" />}
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
