import untypedCountries from '@assets/countries.json';
import { Input, type InputProps } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown, { DropdownDivider, DropdownGroup, DropdownItem } from '@ui/Dropdown';
import { useEffect, useState, type FC } from 'react';

const countries: Record<string, string> = untypedCountries;

type PhoneNumberInputProps = Omit<InputProps, 'onChange' | 'type'> & {
	onChange: ({ country, phone }: { country: string; phone: string }) => void;
	defaultCountryValue?: string;
	defaultPhoneNumberValue?: string;
	className?: string;
};

const PhoneNumberInput: FC<PhoneNumberInputProps> = ({ onChange = () => undefined, defaultCountryValue = '', defaultPhoneNumberValue = '', className = '', ...inputProps }) => {
	const [country, setCountry] = useState<string>(defaultCountryValue);
	const [phone, setPhone] = useState<string>(defaultPhoneNumberValue);

	useEffect(() => {
		onChange({ country, phone });
	}, [onChange, phone, country]);

	return (
		<div className={`input-group ${className}`}>
			<Dropdown>
				<button className="px-3 py-2 text-sm border border-r-0 border-gray-400 rounded-l" type="button" aria-haspopup="true" aria-expanded="false">
					{countries[country]}
					<FontAwesomeIcon icon="caret-down" className="ml-2" />
				</button>
				<DropdownGroup>
					{['CA', 'US'].map(c => (
						<DropdownItem key={c} active={country === c} onClick={() => setCountry(c)}>
							{countries[c]}
						</DropdownItem>
					))}
				</DropdownGroup>
				<DropdownDivider />
				<DropdownGroup>
					{Object.keys(countries)
						.filter(c => !['US', 'CA'].includes(c))
						.map(c => (
							<DropdownItem key={c} active={country === c} onClick={() => setCountry(c)}>
								{countries[c]}
							</DropdownItem>
						))}
				</DropdownGroup>
			</Dropdown>
			<Input type="tel" onChange={e => setPhone(e.target.value)} {...inputProps} />
		</div>
	);
};

export default PhoneNumberInput;
