import { type FileManagerResults, useFileManager } from '@components/FileManager';
import { Alert, Button, Input, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Row, ValidationField, Variant } from '@convoflo/ui';
import useClient from '@hooks/useClient';
import File from '@models/File';
import Folder from '@models/Folder';
import type { Fileable } from '@types';
import { type FC, type FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { type InfiniteData, useMutation, useQueryClient } from 'react-query';
import type { WretchError } from 'wretch';

export type DeletedEvent = {
	sourceFiles: File[];
	sourceFolders: Folder[];
};

type DeletedEventResponse = {
	source_files: File[];
	source_folders: Folder[];
};

type DeleteMutation = {
	files: File[];
	folders: Folder[];
	accountPassword?: string;
};

export type DialogDeleteFileablesProps = Omit<ModalProps, 'isOpen'> & {
	items: Fileable[];
	onDeleted?: (event: DeletedEvent) => void;
};

export const DialogDeleteFileables: FC<DialogDeleteFileablesProps> = ({ items, onDeleted = () => undefined, ...props }) => {
	const { client, validation } = useClient();
	const queryClient = useQueryClient();
	const filemanagerContext = useFileManager();

	const [isOpen, setIsOpen] = useState(true);
	const [accountPassword, setAccountPassword] = useState('');

	const { mutate: _delete, isLoading: isDeleting } = useMutation<DeletedEvent, WretchError, DeleteMutation, InfiniteData<FileManagerResults>>(
		async ({ files, folders, accountPassword }) => {
			const { source_files, source_folders } = await client
				.url('files')
				.json({
					account_password: accountPassword,
					files: files.map(f => f.OriginalLink),
					folders: folders.map(f => f.OriginalLink)
				})
				.delete()
				.json<DeletedEventResponse>();

			return {
				sourceFiles: source_files.map((file: File) => new File(file)),
				sourceFolders: source_folders.map((folder: Folder) => new Folder(folder))
			};
		},
		{
			onSuccess: event => {
				const fileables = (event.sourceFolders as Fileable[]).concat(event.sourceFiles as Fileable[]);
				toast.success(<FormattedMessage id="dialog-delete.deleted" values={{ n: items.length, item: fileables.length > 0 ? fileables[0].getName() : null }} />);

				queryClient.invalidateQueries(['account']);
				filemanagerContext?.remove?.(fileables);

				setIsOpen(false);
				onDeleted(event);
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		_delete({ accountPassword, files: items.filter(f => f instanceof File) as File[], folders: items.filter(f => f instanceof Folder) as Folder[] });
	};

	return (
		<Modal isOpen={isOpen} onSubmit={onSubmit} {...props}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage
					id="dialog-delete.title"
					values={{
						n: items.length,
						item:
							items.length > 0 ? (
								<span>
									{items[0].icon('mx-2')}
									{items[0].getName()}
								</span>
							) : null
					}}
				/>
			</ModalHeaderOnlyTitle>
			<ModalBody>
				<Alert variant={Variant.danger}>
					<p>
						<FormattedMessage id="dialog-delete.confirmation" />
					</p>
				</Alert>

				{'account_password' in validation && (
					<Row>
						<Label>
							<FormattedMessage id="dialog-account-password.content" />
						</Label>
						<ValidationField fieldName="account_password" validation={validation}>
							<Input block autoFocus value={accountPassword} type="password" onChange={e => setAccountPassword(e.target.value)} />
						</ValidationField>
					</Row>
				)}
			</ModalBody>

			<ModalFooter>
				<Button disabled={isDeleting} type="submit" className="mr-2" variant={Variant.danger}>
					{isDeleting ? <FormattedMessage id="deleting" /> : <FormattedMessage id="delete" />}
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
