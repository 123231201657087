import crissxcrossImage from '@assets/images/crissxcross.png';
import esignImage from '@assets/images/esign-home-page.png';
import computerImage from '@assets/images/home-computer.png';
import headerImage from '@assets/images/home-header.jpg';
import { AsyncCreatableComboBox, ContactOption, EmailValueLabel } from '@components/ComboBox';
import DialogTrialOffer from '@components/DialogTrialOffer';
import StarSection from '@components/StarSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { Button, Input, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, Row, Size, Variant } from '@convoflo/ui';
import type { Contact } from '@types';
import { CopyToClipboard } from '@ui/CopyToClipboard';
import { RichTextEditor, type RichTextEditorRef } from '@ui/RichTextEditor';
import { PageTitle } from '@ui/Typography';
import * as EmailValidator from 'email-validator';
import debounce from 'lodash.debounce';
import { type FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useParams } from 'react-router-dom';
import type { MultiValue } from 'react-select';

const frenchYoutubeUrl = 'https://www.youtube.com/embed/9On2Mb405cs';

export const ReferralLanding = () => {
	const { account } = useAccount();

	const history = useHistory();

	const { locale } = useIntl();
	const { id } = useParams<{ id: string }>();

	const [, setReferralId] = useLocalStorage('idRef', id);

	const [playerUrl, setPlayerUrl] = useState(frenchYoutubeUrl);
	const [showTrialModal, setShowTrialModal] = useState(false);

	const onTimeClicked = (seconds: number) => {
		setPlayerUrl(frenchYoutubeUrl + '?autoplay=1;start=' + seconds);
	};

	useEffect(() => {
		if (id) {
			setReferralId(id);
			history.replace('/referral-landing');
		}
	}, [id, history, setReferralId]);

	const inTrial = !!account?.business.trial_ends_at;

	useEffect(() => {
		if (inTrial) {
			history.replace('/');
		}
	}, [inTrial, history]);

	return (
		<>
			<section className="text-right text-white bg-blue-800">
				<div className="container px-10 py-1 lg:px-36">
					<a href="asd" className="hover:underline">
						<FormattedMessage id="ref.change_lang" />
					</a>
				</div>
			</section>
			<div className="relative py-24 text-white bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${headerImage})` }}>
				<div className="container px-10 lg:px-36">
					<div className="text-center">
						<h1 className="font-bold text-7xl">
							<FormattedMessage id="ref.title" />
						</h1>
					</div>
					<div className="grid-cols-12 py-16 lg:grid">
						<div className="col-span-7">
							<p className="text-4xl display-1">
								<FormattedMessage id="ref.header_title" />
							</p>
							<h1 className="mt-4 text-4xl font-bold">
								<FormattedMessage id="ref.header_sub" />
							</h1>
						</div>
						<div className="col-span-4 col-start-9">
							<div className="mt-16 text-center lg:text-left">
								{account !== null && (
									<button
										onClick={() => setShowTrialModal(true)}
										className="p-4 text-xl font-bold transition duration-150 ease-in-out border-4 border-white border-solid rounded-full hover:bg-white hover:text-gray-700">
										<FormattedMessage id="ref.start_trial" />
									</button>
								)}
								{account === null && (
									<a
										href={`${import.meta.env.VITE_SITE_URL}/${locale}/plans`}
										className="p-4 text-xl font-bold transition duration-150 ease-in-out border-4 border-white border-solid rounded-full hover:bg-white hover:text-gray-700">
										<FormattedMessage id="ref.start_trial" />
									</a>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			{locale === 'fr' && (
				<section className="bg-blue-800">
					<div className="container px-10 py-10 md:px-20 lg:px-36 ref-video-cont">
						<div className="mb-4 text-center">
							<h1 className="mb-4 text-4xl font-bold text-white">
								<FormattedMessage id="ref.video_title" />
							</h1>
							<div className="h-4 mx-auto bg-white w-36"></div>
						</div>
						<div className="grid mt-8 xl:grid-cols-2 lg:gap-10">
							<div>
								<div className="aspect-w-16 aspect-h-9">
									<iframe
										className="w-full h-full embed-responsive-item"
										src={playerUrl}
										id="player"
										allowFullScreen
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										title="youtube"></iframe>
								</div>
							</div>
							<div className="text-left text-white">
								<p className="text-lg font-light ref-video-text">
									<FormattedMessage id="ref.video_sub" />
								</p>
								<p className="mt-4 text-lg font-bold ref-video-text">
									<FormattedMessage id="ref.video_list_title" />
								</p>

								<ul>
									<li>
										<button type="button" className="transition duration-150 cursor-pointer hover:opacity-80" onClick={() => onTimeClicked(9)}>
											<FormattedMessage
												id="ref.video_link1"
												values={{
													u: msg => <span className="underline">{msg}</span>
												}}
											/>
										</button>
									</li>
									<li>
										<button type="button" className="transition duration-150 cursor-pointer hover:opacity-80" onClick={() => onTimeClicked(57)}>
											<FormattedMessage
												id="ref.video_link2"
												values={{
													u: msg => <span className="underline">{msg}</span>
												}}
											/>
										</button>
									</li>
									<li>
										<button type="button" className="transition duration-150 cursor-pointer hover:opacity-80" onClick={() => onTimeClicked(91)}>
											<FormattedMessage
												id="ref.video_link3"
												values={{
													u: msg => <span className="underline">{msg}</span>
												}}
											/>
										</button>
									</li>
									<li>
										<button type="button" className="transition duration-150 cursor-pointer hover:opacity-80" onClick={() => onTimeClicked(116)}>
											<FormattedMessage
												id="ref.video_link4"
												values={{
													u: msg => <span className="underline">{msg}</span>
												}}
											/>
										</button>
									</li>
									<li>
										<button type="button" className="transition duration-150 cursor-pointer hover:opacity-80" onClick={() => onTimeClicked(228)}>
											<FormattedMessage
												id="ref.video_link5"
												values={{
													u: msg => <span className="underline">{msg}</span>
												}}
											/>
										</button>
									</li>
									<li>
										<button type="button" className="transition duration-150 cursor-pointer hover:opacity-80" onClick={() => onTimeClicked(281)}>
											<FormattedMessage
												id="ref.video_link6"
												values={{
													u: msg => <span className="underline">{msg}</span>
												}}
											/>
										</button>
									</li>
									<li>
										<button type="button" className="transition duration-150 cursor-pointer hover:opacity-80" onClick={() => onTimeClicked(307)}>
											<FormattedMessage
												id="ref.video_link7"
												values={{
													u: msg => <span className="underline">{msg}</span>
												}}
											/>
										</button>
									</li>
									<li>
										<button type="button" className="transition duration-150 cursor-pointer hover:opacity-80" onClick={() => onTimeClicked(347)}>
											<FormattedMessage
												id="ref.video_link8"
												values={{
													u: msg => <span className="underline">{msg}</span>
												}}
											/>
										</button>
									</li>
									<li>
										<button type="button" className="transition duration-150 cursor-pointer hover:opacity-80" onClick={() => onTimeClicked(389)}>
											<FormattedMessage
												id="ref.video_link9"
												values={{
													u: msg => <span className="underline">{msg}</span>
												}}
											/>
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</section>
			)}

			<section className="bg-white">
				<div className="container px-10 py-14 xl:px-56 md:px-20">
					<div className="grid lg:grid-cols-2">
						<div className="hidden lg:block">
							<img src={computerImage} alt="computer" className="xl:max-w-lg lg:max-w-md" />
						</div>
						<div>
							<h1 className="mb-3 text-4xl font-bold text-blue-900">
								<FormattedMessage id="ref.simplify_title" />
							</h1>
							<p>
								<FormattedMessage id="ref.simplify_sub" />
							</p>

							<div className="flex items-center mt-8 align-middle">
								<figure className="mr-5">
									<span className="flex items-center justify-center w-16 h-16 text-white bg-blue-500 rounded-full">
										<FontAwesomeIcon icon="lock" size="2x" />
									</span>
								</figure>
								<div className="flex-1 text-xl">
									<FormattedMessage
										id="ref.simplify_av1"
										values={{
											strong: msg => <strong>{msg}</strong>
										}}
									/>
								</div>
							</div>

							<div className="flex items-center mt-8 align-middle">
								<figure className="mr-5">
									<span className="flex items-center justify-center w-16 h-16 text-white bg-blue-500 rounded-full">
										<FontAwesomeIcon icon="universal-access" size="2x" />
									</span>
								</figure>
								<div className="flex-1 text-xl">
									<FormattedMessage
										id="ref.simplify_av2"
										values={{
											strong: msg => <strong>{msg}</strong>
										}}
									/>
								</div>
							</div>

							<div className="flex items-center mt-8 align-middle">
								<figure className="mr-5">
									<span className="flex items-center justify-center w-16 h-16 text-white bg-blue-500 rounded-full">
										<FontAwesomeIcon icon="globe" size="2x" />
									</span>
								</figure>
								<div className="flex-1 text-xl">
									<FormattedMessage
										id="ref.simplify_av3"
										values={{
											strong: msg => <strong>{msg}</strong>
										}}
									/>
								</div>
							</div>

							<div className="flex items-center mt-8 align-middle">
								<figure className="mr-5">
									<span className="flex items-center justify-center w-16 h-16 text-white bg-blue-500 rounded-full">
										<FontAwesomeIcon icon="desktop" size="2x" />
									</span>
								</figure>
								<div className="flex-1 text-xl">
									<FormattedMessage
										id="ref.simplify_av4"
										values={{
											strong: msg => <strong>{msg}</strong>
										}}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="mt-12 text-center">
						{account !== null && (
							<Link to="/account/upgrade" className="p-3 text-lg text-white transition duration-150 border-solid rounded-lg bg-theme-primary hover:bg-blue-600 hover:text-gray-200">
								<FormattedMessage id="ref.start_trial" />
							</Link>
						)}
						{account === null && (
							<a href={`${import.meta.env.VITE_SITE_URL}/${locale}/plans`} className="p-3 text-lg text-white border-solid rounded-lg bg-theme-primary">
								<FormattedMessage id="ref.start_trial" />
							</a>
						)}
					</div>
				</div>
			</section>

			<section className="bg-blue-800" style={{ backgroundImage: `url(${crissxcrossImage})` }}>
				<div className="container pb-16 text-center pt-14">
					<h2 className="mb-4 text-4xl font-bold text-white">
						<FormattedMessage id="ref.security_title" />
					</h2>
					<div className="h-4 mx-auto bg-white w-36"></div>

					<p className="mt-4 mb-8 text-xl text-white">
						<FormattedMessage id="ref.security_desc" />
					</p>
					<a
						className="p-3 text-lg text-white transition duration-150 bg-blue-500 border-solid rounded-lg hover:bg-blue-600 hover:text-gray-200"
						href={`${import.meta.env.VITE_SITE_URL}/${locale}/about/security`}>
						<FormattedMessage id="ref.learn_more" />
					</a>
				</div>
			</section>

			<StarSection theme="light" />

			<section className="bg-white">
				<div className="container px-10 py-14 xl:px-56 md:px-20">
					<div className="grid gap-10 lg:grid-cols-2">
						<div>
							<h1 className="mb-3 text-4xl font-bold text-blue-900">
								<FormattedMessage id="ref.esign_title" />
							</h1>
							<p>
								<FormattedMessage id="ref.esign_sub" />
							</p>

							<div className="flex items-center mt-8 align-middle">
								<figure className="mr-5">
									<span className="flex items-center justify-center w-16 h-16 text-white bg-blue-500 rounded-full">
										<FontAwesomeIcon icon="smile-plus" size="2x" />
									</span>
								</figure>
								<div className="flex-1 text-xl">
									<FormattedMessage
										id="ref.esign_av1"
										values={{
											strong: msg => <strong>{msg}</strong>
										}}
									/>
								</div>
							</div>

							<div className="flex items-center mt-8 align-middle">
								<figure className="mr-5">
									<span className="flex items-center justify-center w-16 h-16 text-white bg-blue-500 rounded-full">
										<FontAwesomeIcon icon="users-cog" size="2x" />
									</span>
								</figure>
								<div className="flex-1 text-xl">
									<FormattedMessage
										id="ref.esign_av2"
										values={{
											strong: msg => <strong>{msg}</strong>
										}}
									/>
								</div>
							</div>

							<div className="flex items-center mt-8 align-middle">
								<figure className="mr-5">
									<span className="flex items-center justify-center w-16 h-16 text-white bg-blue-500 rounded-full">
										<FontAwesomeIcon icon="folder-open" size="2x" />
									</span>
								</figure>
								<div className="flex-1 text-xl">
									<FormattedMessage
										id="ref.esign_av3"
										values={{
											strong: msg => <strong>{msg}</strong>
										}}
									/>
								</div>
							</div>

							<div className="flex items-center mt-8 align-middle">
								<figure className="mr-5">
									<span className="flex items-center justify-center w-16 h-16 text-white bg-blue-500 rounded-full">
										<FontAwesomeIcon icon="dollar-sign" size="2x" />
									</span>
								</figure>
								<div className="flex-1 text-xl">
									<FormattedMessage
										id="ref.esign_av4"
										values={{
											strong: msg => <strong>{msg}</strong>
										}}
									/>
								</div>
							</div>
						</div>
						<div className="hidden lg:block">
							<img src={esignImage} alt="computer" className="xl:max-w-lg lg:max-w-md" />
						</div>
					</div>

					<div className="mt-12 text-center">
						{account !== null && (
							<Link to="/account/upgrade" className="p-3 text-lg text-white transition duration-150 bg-blue-500 border-solid rounded-lg hover:bg-blue-600 hover:text-gray-200">
								<FormattedMessage id="ref.start_trial" />
							</Link>
						)}
						{account === null && (
							<a href={`${import.meta.env.VITE_SITE_URL}/${locale}/plans`} className="p-3 text-lg text-white bg-blue-500 border-solid rounded-lg">
								<FormattedMessage id="ref.start_trial" />
							</a>
						)}
					</div>
				</div>
			</section>

			<section className="px-10 py-16 text-center text-white bg-blue-800">
				<h1 className="mb-8 text-3xl font-bold">
					<FormattedMessage id="ref.demo_title" />
				</h1>
				<a
					className="px-5 py-3 text-lg text-white transition duration-150 bg-transparent border-4 border-white border-solid rounded-lg hover:bg-white hover:text-blue-800"
					href={`${import.meta.env.VITE_SITE_URL}/${locale}/book`}>
					<FormattedMessage id="ref.book_button" />
				</a>
			</section>

			{showTrialModal && <DialogTrialOffer onAfterClose={() => setShowTrialModal(false)} referredBy={id} />}
		</>
	);
};

export const ReferralAuth = () => {
	const { account } = useAccount();
	const { client } = useClient();
	const { formatMessage } = useIntl();

	const [textCopied, setTextCopied] = useState(false);
	const refUrl = `${window.location.origin}/referral-landing/${account!.ReferralId}`;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isEmailSending, setIsEmailSending] = useState(false);
	const [contacts, setContacts] = useState<Contact[]>([]);

	const input = useRef<HTMLInputElement>(null);
	const editor = useRef<RichTextEditorRef>(null);

	const onCopy = () => {
		setTextCopied(true);

		input.current?.focus();
		input.current?.select();
	};

	const sendEmail = async () => {
		var data = {
			contacts: contacts,
			message: editor.current?.markdown() ?? ''
		};

		await client.url('send/referral').json(data).post().res();
		toast.success(<FormattedMessage id="account.referral_sent" />);
		setIsModalOpen(false);
	};

	const onSubmit = (event: FormEvent) => {
		event.preventDefault();
		setIsEmailSending(true);
		sendEmail()
			.finally(() => {
				setIsEmailSending(false);
			})
			.catch(console.error);
	};

	const loadUsers = useMemo(
		() =>
			debounce((inputValue, callback) => {
				client
					.url('contacts')
					.query({ q: inputValue, sort: '!shares' })
					.get()
					.json()
					.then(options => callback(options));
			}, 250),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const onUsersChanged = (options: MultiValue<Contact>) => {
		setContacts((options as Contact[]) || []);
	};

	useEffect(() => {
		if (isModalOpen) {
			setContacts([]);
		}
	}, [isModalOpen]);

	return (
		<Scrollbars autoHide>
			<div className="container max-w-3xl px-4 py-8 md:px-8 md:py-16">
				<PageTitle>
					<FormattedMessage id="ref.page_title" />
				</PageTitle>
				<p className="text-gray-700">
					<FormattedMessage id="ref.page_sub" />
				</p>
				<div className="grid grid-cols-2 gap-8 mt-8">
					<div className="flex-col items-center p-4 bg-white rounded shadow">
						<FontAwesomeIcon icon="mouse-pointer" mask="circle" transform="shrink-8" size="4x" className="mb-4 text-green-500" />
						<h5 className="mb-2 text-lg font-bold">
							<FormattedMessage id="ref.simple_title" />
						</h5>
						<p className="text-gray-600">
							<FormattedMessage id="ref.simple_sub" />
						</p>
					</div>
					<div className="flex-col items-center p-4 bg-white rounded shadow">
						<FontAwesomeIcon icon="shield-alt" mask="circle" transform="shrink-8" size="4x" className="mb-4 text-blue-500" />
						<h5 className="mb-2 text-lg font-bold">
							<FormattedMessage id="ref.secure_title" />
						</h5>
						<p className="text-gray-600">
							<FormattedMessage id="ref.secure_sub" />
						</p>
					</div>
				</div>
				<div className="grid grid-cols-4 mt-8">
					<div className="items-center col-span-2 col-start-2 p-4 bg-white rounded shadow">
						<FontAwesomeIcon icon="signature" mask="circle" transform="shrink-8" size="4x" className="mb-4 text-purple-700" />
						<h5 className="mb-2 text-lg font-bold">
							<FormattedMessage id="ref.profitable_title" />
						</h5>
						<p className="text-gray-600">
							<FormattedMessage id="ref.profitable_sub" />
						</p>
					</div>
				</div>
				<div className="mt-10">
					<h2 className="mb-4 text-3xl font-bold">
						<FormattedMessage id="ref.participate_title" />
					</h2>
					<div className="flex items-center align-middle">
						<figure className="mr-5">
							<span className="flex items-center justify-center text-3xl italic text-white bg-blue-500 rounded-full w-14 h-14">1</span>
						</figure>
						<p className="flex-1">
							<FormattedMessage id="ref.praticiapte_step1" />
						</p>
					</div>
					<div className="flex items-center mt-5">
						<figure className="mr-5">
							<span className="flex items-center justify-center text-3xl italic text-white bg-blue-500 rounded-full w-14 h-14">2</span>
						</figure>
						<p className="flex-1">
							<FormattedMessage id="ref.praticiapte_step2" />
						</p>
					</div>
					<div className="flex items-center mt-5">
						<figure className="mr-5">
							<span className="flex items-center justify-center text-3xl italic text-white bg-blue-500 rounded-full w-14 h-14">3</span>
						</figure>
						<p className="flex-1">
							<FormattedMessage id="ref.praticiapte_step3" />
						</p>
					</div>
				</div>

				<div className="relative items-center px-4 pt-4 pb-8 mt-10 bg-white rounded shadow">
					<h3 className="mb-4 text-2xl font-bold text-center">
						<FormattedMessage id="ref.your_link_title" />
					</h3>
					<div className="flex items-stretch">
						<Input value={refUrl} ref={input} block readOnly className="border-r-0 rounded-r-none" />
						{!textCopied && (
							<CopyToClipboard text={refUrl} onCopy={onCopy}>
								<Button intent={Intent.secondary} type="button" className="rounded-l-none">
									<FontAwesomeIcon className="mr-2" icon="clipboard" />
									<FormattedMessage id="copy" />
								</Button>
							</CopyToClipboard>
						)}

						{textCopied && (
							<Button variant={Variant.success} type="button" disabled className="rounded-l-none">
								<FontAwesomeIcon className="mr-2" icon="check" />
								<FormattedMessage id="copied" />
							</Button>
						)}
					</div>
				</div>

				<div className="mt-10 text-center">
					<Button intent={Intent.primary} type="button" size={Size['2xl']} onClick={() => setIsModalOpen(true)}>
						<FontAwesomeIcon className="mr-2" icon="mail-bulk" />
						<FormattedMessage id="ref.share_btn" />
					</Button>
				</div>
			</div>

			<Modal isOpen={isModalOpen} onSubmit={onSubmit}>
				<ModalHeaderOnlyTitle>
					<FormattedMessage id="ref.send_email_title" />
				</ModalHeaderOnlyTitle>
				<ModalBody>
					<Row>
						<AsyncCreatableComboBox
							value={contacts}
							loadOptions={loadUsers}
							getOptionValue={user => user.Email}
							components={{
								Option: ContactOption,
								MultiValueLabel: EmailValueLabel
							}}
							filterOption={({ data }, input) => (input ? data.Email.indexOf(input) >= 0 || (!!data.Name && data.Name.indexOf(input) >= 0) : true)}
							isOptionDisabled={user => contacts.some(u => u.Email === user.Email)}
							autoFocus
							isMulti
							onChange={onUsersChanged}
							isValidNewOption={input => EmailValidator.validate(input)}
							getNewOptionData={input => ({ Source: 'share', Email: input } as Contact)}
							placeholder={formatMessage({ id: 'dialog-send.enter_email_addresses' })}
							formatCreateLabel={input => formatMessage({ id: 'dialog-send.add_email' }, { email: input })}
						/>
					</Row>

					<Row>
						<Label htmlFor="content">
							<FormattedMessage id="ref.your_message" />
						</Label>
						<div className="overflow-hidden border border-gray-300 rounded">
							<RichTextEditor ref={editor} value={formatMessage({ id: 'ref.default_email' })} />
						</div>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button variant={Variant.primary} type="submit" disabled={isEmailSending}>
						<FormattedMessage id="ref.send_btn" />
					</Button>
					<Button variant={Variant.light} intent={Intent.secondary} type="button" onClick={() => setIsModalOpen(false)}>
						<FormattedMessage id="ref.cancel_btn" />
					</Button>
				</ModalFooter>
			</Modal>
		</Scrollbars>
	);
};
