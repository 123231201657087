import ApiClientContext from '@contexts/ApiClient';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { useSearchParams } from '@hooks/useSearchParams';
import { Client } from '@service/Client';
import type { ValidationErrors } from '@types';
import { type FC, type PropsWithChildren, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import type { FetchLike, WretchOptions } from 'wretch';
import { AccountProvider } from './AccountProvider';

const emptyValidation: ValidationErrors = {};

const ApiClientProvider: FC<PropsWithChildren> = ({ children }) => {
	const history = useHistory();
	const location = useLocation();
	const { locale } = useIntl();
	const [{ debug, access_token: authTokenFromUrl = null }] = useSearchParams();

	const [authToken, setAuthToken] = useLocalStorage<string | null>('auth');
	const [validation, setValidation] = useState(emptyValidation);

	const clearValidationMiddleware = (next: FetchLike) => (url: string, opts: WretchOptions) => {
		setValidation(emptyValidation);
		return next(url, opts);
	};

	const authTokenMiddleware = (next: FetchLike) => (url: string, opts: Record<string, any>) => {
		if (authToken) {
			opts.headers = { ...opts.headers, Authorization: `Bearer ${authToken}` };
		}
		return next(url, opts);
	};

	const localeMiddleware = (next: FetchLike) => (url: string, opts: Record<string, any>) => {
		opts.headers = { ...opts.headers, 'Accept-Language': locale };
		return next(url, opts);
	};

	const debugMiddleware = (next: FetchLike) => (url: string, opts: Record<string, any>) => {
		if (debug) {
			url += `${url.includes('?') ? '&' : '?'}debug=${debug}`;
		}
		return next(url, opts);
	};

	const client = Client.middlewares([localeMiddleware, clearValidationMiddleware, authTokenMiddleware, debugMiddleware], true)
		.catcher(400, async (error, originalRequest) => {
			if (error.json?.error?.code === 'tfa_required') {
				history.replace('/login/multi-factor-auth-code', { next: history.location });
				throw error;
			}
			if ((await error.response.text()) === 'terms_not_accepted') {
				history.replace('/vf/', { next: history.location });
				throw error;
			}
		})
		.catcher(401, () => {
			history.replace(`/login?next=${encodeURIComponent(location.pathname + location.search)}`, { next: location });
			setAuthToken(null);
			toast.error(<FormattedMessage id="auth_required" />, { id: 'auth_required' });
		})
		.catcher(402, error => {
			if (error.json.error.code === 'unauthorized') {
				toast.error(<FormattedMessage id="payment-requests.payment_required_access_file" />);
				throw error;
			}

			history.replace('/trial-expired');

			throw error;
		})
		.catcher(403, error => {
			if (error.json?.error?.type === 'NeedsNewPasswordException') {
				history.replace('/password/new', { next: history.location });
				return;
			}
			if (error.json?.error?.type === 'TermsNotAcceptedException') {
				history.replace(`/terms?next=${encodeURIComponent(location.pathname + location.search)}`, { next: history.location });
				return;
			}

			toast.error(<FormattedMessage id="forbidden" />);

			throw error;
		})
		.catcher(422, error => {
			setValidation(error.json.errors as ValidationErrors);
			throw error;
		})
		.catcher(429, error => {
			if (location.pathname !== '/under-maintenance') {
				history.replace(`/too-many-requests?next=${encodeURIComponent(location.pathname + location.search)}`, { next: history.location });
			}
			throw error;
		})
		.catcher(503, error => {
			if (location.pathname !== '/under-maintenance') {
				history.replace(`/under-maintenance?next=${encodeURIComponent(location.pathname + location.search)}`, { next: history.location });
			}
			throw error;
		});

	useEffect(() => {
		if (authTokenFromUrl) {
			setAuthToken(authTokenFromUrl);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ApiClientContext.Provider
			value={{
				authToken,
				setAuthToken,
				validation,
				setValidation,
				client
			}}>
			<AccountProvider>{children}</AccountProvider>
		</ApiClientContext.Provider>
	);
};

export default ApiClientProvider;
