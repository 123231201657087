import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const usePrevious = (value: Partial<Location>): Partial<Location> | undefined => {
	const ref = useRef<Partial<Location>>();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

const useLocationChange = (action: (location: Partial<Location> | undefined, previousLocation: Partial<Location> | undefined) => void) => {
	const location = useLocation();
	const prevLocation = usePrevious(location);

	useEffect(() => {
		if (location !== prevLocation && prevLocation !== undefined) {
			action(location, prevLocation);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, prevLocation]);
};

export default useLocationChange;
