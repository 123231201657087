import { type Activity, ActivityItem } from '@components/Activity';
import { createResourceLengthAwarePagination } from '@components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import { Button, Variant } from '@convoflo/ui';
import type { Fileable } from '@types';
import arraySort from 'array-sort';
import { endOfMonth, isThisMonth, isThisWeek, isThisYear, isToday, isWithinInterval, parseISO, startOfMonth, subMonths } from 'date-fns';
import flatten from 'lodash.flatten';
import groupBy from 'lodash.groupby';
import { type FC, useEffect, useLayoutEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage } from 'react-intl';
import { useInfiniteQuery } from 'react-query';

type ActivityListProps = {
	fileable: Fileable;
	limit?: number | null;
	onLoaded?: () => void;
};

export const ActivityList: FC<ActivityListProps> = ({ fileable, limit = 25, onLoaded = () => undefined }) => {
	const { client } = useClient();
	const { ref: loadMoreRef, inView: loadMoreInView } = useInView();

	const route = fileable.getRoute('timeline');

	const {
		data: entries,
		isLoading,
		error,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage,
		isSuccess
	} = useInfiniteQuery(
		['timeline', route, limit],
		async ({ pageParam = 1 }) =>
			createResourceLengthAwarePagination<Activity>(
				await client
					.url(route)
					.query({ page: pageParam, limit: limit ?? 9999 })
					.get()
					.json()
			),
		{
			getNextPageParam: paginator => (paginator.links.next ? paginator.meta.currentPage + 1 : undefined)
		}
	);

	useEffect(() => {
		if (error) {
			console.error(error);
		}
	}, [error]);

	const flattenedEntries = flatten(entries?.pages.map(paginator => paginator.data) ?? []);

	const groupedTimeline = useMemo(
		() =>
			groupBy(flattenedEntries, entry => {
				const parsedDate = parseISO(entry.created_at);

				if (isToday(parsedDate)) {
					return 'today';
				}

				if (isThisWeek(parsedDate)) {
					return 'this-week';
				}

				if (isThisMonth(parsedDate)) {
					return 'this-month';
				}

				const dateLastMonth = subMonths(new Date(), 1);

				if (isWithinInterval(parsedDate, { start: startOfMonth(dateLastMonth), end: endOfMonth(dateLastMonth) })) {
					return 'last-month';
				}

				if (isThisYear(parsedDate)) {
					return 'this-year';
				}

				return 'other';
			}),
		[flattenedEntries]
	);

	// Load the next page when is available and in view
	useEffect(() => {
		if (loadMoreInView && !isLoading && hasNextPage && !isFetchingNextPage) {
			// fetchNextPage();
		}
	}, [fetchNextPage, loadMoreInView, isLoading, hasNextPage, isFetchingNextPage]);

	const lastId = flattenedEntries.length > 0 ? flattenedEntries[flattenedEntries.length - 1]?.Id : null;
	const groupOrder = ['today', 'this-week', 'this-month', 'last-month', 'this-year', 'other'];

	// Fired only once the first set of data fetched
	useLayoutEffect(() => {
		if (isSuccess) onLoaded();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccess]);

	if (isLoading) {
		return (
			<p>
				<FontAwesomeIcon icon="spinner" pulse fixedWidth />
				<FormattedMessage id="loading" />
			</p>
		);
	}

	if (entries === undefined) {
		return null;
	}

	return (
		<div>
			{flattenedEntries.length > 0 &&
				arraySort(Object.entries(groupedTimeline), [
					([aKey], [bKey]) => {
						return groupOrder.indexOf(aKey) > groupOrder.indexOf(bKey) ? 1 : -1;
					}
				]).map(([groupKey, entries]) => (
					<div key={groupKey} className="my-4">
						<h2 className="mb-4 text-2xl font-bold">
							<FormattedMessage id={`timeline.title.${groupKey}`} />
						</h2>

						{entries.map(entry => (
							<ActivityItem key={entry.Id} entry={entry} fileable={fileable} isLast={entry.Id === lastId && !hasNextPage} />
						))}
					</div>
				))}

			{/* Load more */}
			<div ref={loadMoreRef}>
				{isLoading || isFetchingNextPage ? (
					<FontAwesomeIcon icon="spinner-third" spin size="3x" className="my-8 text-gray-500" />
				) : (
					hasNextPage && (
						<div className="flex items-center mt-8 space-x-2">
							<Button variant={Variant.dark} onClick={() => fetchNextPage()} shadow>
								<FontAwesomeIcon icon="plus" className="mr-2" />
								<FormattedMessage id="timeline.load_more" />
							</Button>
							<div className="flex-1 h-1 bg-gray-300 rounded-r-full"></div>
						</div>
					)
				)}
			</div>
		</div>
	);
};
