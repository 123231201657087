import { ComboBox, UserOption } from '@components/ComboBox';
import { FileSelector } from '@components/FileSelector';
import { useImporter } from '@components/Importer';
import type { OrganizationUser } from '@components/Team';
import { useWizard } from '@components/Wizard';
import { Button, Checkbox, HelperText, Select as HtmlSelect, Intent, Label, Row, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import Folder from '@models/Folder';
import { type FolderTemplate, Permissions } from '@types';
import Card from '@ui/Card';
import { type FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

export const ConfigurationStep: FC = () => {
	const { account } = useAccount();
	const { client } = useClient();
	const { nextStep, goToStep } = useWizard();
	const { creator, folder, template, setCreator, setFolder, setTemplate, isInviting, setIsInviting } = useImporter();
	const queryClient = useQueryClient();

	const [showFileSelector, setShowFileSelector] = useState(false);

	const { data: templates = [], isFetching } = useQuery(['folder-templates'], async () => {
		return await client.url('account/folder_templates').get().json<FolderTemplate[]>();
	});

	const refreshTemplates = () => {
		queryClient.invalidateQueries(['folder-templates']);
	};

	useEffect(() => {
		if (account && !account?.isAdmin()) {
			setCreator(account.business.users.find(user => user.ID === account.ID));
		}
	}, [account, setCreator]);

	return (
		<>
			<Card className="space-y-12">
				<div className="prose max-w-none">
					<h2>
						<FormattedMessage id="importer.step.configuration" />
					</h2>
				</div>
				{account?.isAdmin() && (
					<Row>
						<Label>
							<FormattedMessage id="importer.config.creator.label" />
						</Label>
						<ComboBox
							value={creator}
							components={{
								Option: UserOption
							}}
							placeholder={<FormattedMessage id="importer.config.creator.select" />}
							getOptionLabel={({ Name }) => Name ?? ''}
							getOptionValue={({ ID }) => String(ID)}
							options={account?.business?.users ?? []}
							onChange={(user, action) => {
								if (action.action === 'clear' || !user) {
									setCreator(undefined);
									return;
								}
								setCreator(user as unknown as OrganizationUser);
							}}
							isSearchable={false}
						/>
						<HelperText>
							<FormattedMessage id="importer.config.creator.help" />
						</HelperText>
					</Row>
				)}
				<Row>
					<Label>
						<FormattedMessage id="importer.config.location.label" />
					</Label>
					<div className="p-3 border rounded-lg bg-gray-50">
						<p className="mb-4 text-gray-700">
							{folder ? (
								<>
									{folder.icon('mr-2')}
									{folder.getName()}
								</>
							) : (
								<>
									<FontAwesomeIcon icon="home" className="mr-2" />
									<FormattedMessage id="file-selector.root" />
								</>
							)}
						</p>
						<button type="button" onClick={() => setShowFileSelector(true)} className="text-sm text-theme-primary hover:underline">
							<FormattedMessage id="importer.config.location.change" />
						</button>
					</div>
					{showFileSelector && (
						<FileSelector
							startingFolder={null}
							onAfterClose={() => setShowFileSelector(false)}
							selectedItems={folder ? [folder] : undefined}
							isItemDisabled={fileable => !(fileable instanceof Folder) || fileable.pivot?.Permissions !== Permissions.ReadWrite}
							onItemsSelected={fileables => (fileables.length > 0 ? setFolder(fileables[0] as Folder) : setFolder(null))}
						/>
					)}
					<HelperText>
						<FormattedMessage id="importer.config.location.help" />
					</HelperText>
				</Row>
				<Row>
					<Label>
						<FormattedMessage id="importer.config.template.label" />
					</Label>
					<HtmlSelect size={Size.sm} value={template?.Id} onChange={e => setTemplate(templates.find(template => template.Id == e.target.value))} disabled={isFetching}>
						<FormattedMessage id="importer.config.template.choose">{msg => <option value="">{msg}</option>}</FormattedMessage>
						{templates.map(template => (
							<option value={template.Id}>{template.Name}</option>
						))}
					</HtmlSelect>
					<button type="button" onClick={refreshTemplates} className="ml-3">
						<FontAwesomeIcon icon="redo" className="text-gray-600" />
					</button>
					<HelperText>
						<FormattedMessage
							id="importer.config.template.help"
							values={{
								a: msg => (
									<Link to="/organization/folder-templates" className="text-theme-primary hover:underline" target="_blank">
										{msg}
									</Link>
								)
							}}
						/>
					</HelperText>
				</Row>
				<Row>
					<Checkbox onChange={e => setIsInviting(e.target.checked)} checked={isInviting}>
						<FormattedMessage id="importer.config.share.label" />
					</Checkbox>
				</Row>
				<footer className="flex items-center justify-between mt-6">
					<Button variant={Variant.primary} intent={Intent.tertiary} onClick={() => goToStep(1)} iconStart="arrow-left" animateIcon size={Size.sm}>
						2. <FormattedMessage id="importer.step.naming" />
					</Button>
					<Button variant={Variant.primary} onClick={nextStep} iconEnd="arrow-right" animateIcon disabled={!creator}>
						4. <FormattedMessage id="importer.step.review" />
					</Button>
				</footer>
			</Card>
		</>
	);
};
