import * as React from "react";
const SvgIllNotificationProfiles = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 427.848 434.96", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M40.618 337.36a34.27 34.27 0 0 0-14.69-5.05c-2.4-.25-5-.18-6.94 1.27a1.44 1.44 0 0 0-.72 1.27 1.57 1.57 0 0 0 .68.87c6 4.56 15 5.13 21.67 1.64M40.238 336.97a71.89 71.89 0 0 1-9.49-8.35 24 24 0 0 1-5.94-11 4.4 4.4 0 0 1 .47-3.68c7.56 5.91 13.5 13.55 15 23", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M40.538 337.31a34.32 34.32 0 0 1 .17-12.46 16.82 16.82 0 0 1 6.64-10.27c1.3 3.05.74 6.57-.18 9.76a47.28 47.28 0 0 1-6.58 13.85M47.948 350.59c-4.5.54-8.42 2.91-11.78 6a22.26 22.26 0 0 0-6.73 11.56c4.41-.27 8.15-3.39 10.87-6.87 2.72-3.48 4.59-7.32 7.58-10.59M49.558 352.71a29.08 29.08 0 0 1 3.35-27.48c2.57 9.12 1.62 19.42-3.35 27.48M59.348 364.37a21 21 0 0 0-20.08 11.6c-.25.5-.47 1.13-.14 1.57a1.5 1.5 0 0 0 1.34.4c3.95-.26 7.46-2.64 10.36-5.35 2.9-2.71 5.41-5.85 8.61-8.19M59.038 363.73a37.3 37.3 0 0 1-.37-20.89c.73-2.69 2-5.52 4.45-6.78a.9.9 0 0 1 .69-.12.93.93 0 0 1 .43.5 12.66 12.66 0 0 1 1 6 37.74 37.74 0 0 1-.94 6.07c-.78 3.71-1.2 7.7-3.23 10.89a21.34 21.34 0 0 0-1.3 2.4c-.38.81-.39 1.1-.74 1.74M84.958 367.6c-1.92-7.31.12-16.16 4.72-22.15.34-.44.79-.92 1.34-.87.85.08 1.07 1.21 1 2.06a40 40 0 0 1-7.09 21M87.348 369.19c8.87 2.31 19 .07 26.44-5.37a21.44 21.44 0 0 0-14.32-1.76c-4.78 1.09-9.15 3.24-12.12 7.13M89.268 364.33c5-8.1 14.5-13.39 24-13.87-1.09 3.86-4.26 6.86-7.85 8.67-3.59 1.81-7.57 2.61-11.51 3.37-1.54.3-3.64.63-4.63 1.83M85.448 340.73a69.42 69.42 0 0 0 3.07-14.05c.37-4.79-.31-9.79-2.8-13.9-4.64 9-5.11 19.48-.41 28.43M101.088 332.83c5.43-9.87 16.5-17.75 27.71-18.86-4.32 10.77-16.11 19.09-27.71 18.86M97.668 334.76c8.1 1.23 15 6.41 22.56 9.54a10.11 10.11 0 0 0 5.1 1 4 4 0 0 0 2.5-7.07 11 11 0 0 0-3.7-2.24c-8.24-3.43-17.08-3.52-25.76-1.44", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M92.978 340.44c1.84.53 2.46 1.47 3.92 2.71a42.09 42.09 0 0 0 17.11 8.82c.65.16 1.51.23 1.83-.36.32-.59-.07-1.14-.44-1.6a23.56 23.56 0 0 0-10.33-7c-3.95-1.47-7.37-3.24-12.09-2.86M87.698 306.14a32.42 32.42 0 0 0 24.82 8.45 1.72 1.72 0 0 0 1.13-.41c.63-.68-.12-1.73-.85-2.3-7.3-5.69-16.14-8.05-25.1-5.74", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M93.968 303.84a34.61 34.61 0 0 1 31-5.11 24.51 24.51 0 0 1-14.76 6.48 46.42 46.42 0 0 1-16.21-1.37M91.468 288.08c-.71-7.28-.25-13-.75-20.35 0-.59-.17-1.29-.72-1.5-.76-.3-1.39.64-1.64 1.42a23.32 23.32 0 0 0 3.1 20.27", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M90.948 288.89a43.06 43.06 0 0 0 12.07-11.73c2.38-3.26 4.39-7.17 3.85-11.17a11.56 11.56 0 0 0-8.71 4.32 24.23 24.23 0 0 0-4.43 8.93c-1 3.2-1.65 6.5-2.78 9.65M102.808 285.59a97.39 97.39 0 0 0 29.7-17.51c-3.55-1.43-7.61-.57-11.16.86a35.21 35.21 0 0 0-18.7 16.57M94.278 292.87c5.49.6 11 1 16.49.08 5.49-.92 10.4-3 15.4-5.11a1.9 1.9 0 0 0 1.05-.8 1.21 1.21 0 0 0-.38-1.31 3.12 3.12 0 0 0-1.31-.62c-5.43-1.52-11.3-.81-16.64 1a78.09 78.09 0 0 0-14.61 6.77M62.978 306.39c-3.74-7.55-3.5-16.36-.27-24.15 2.28 3.47 2.55 7.88 2.14 12-.41 4.12-1.67 8.37-1.76 12.51M48.028 298.85a33.67 33.67 0 0 0-25 10.66 23.4 23.4 0 0 0 13.68-2.54 69.6 69.6 0 0 0 11.75-7.86M37.988 295.49c-4.57-.37-9-.93-13.36.41a16 16 0 0 0-10.18 8.63 24.08 24.08 0 0 0 12.52-2.36 72 72 0 0 0 11-6.68M52.858 295.64c-4.11-2.69-8.33-5.43-13.11-6.55-4.78-1.12-10.33-.29-13.67 3.32 3.87 2.27 8.57 2.56 13.06 2.56s10-.06 14.24 1.44", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M52.888 295.86c-7-6.9-9.69-19.29-6.87-28.73a43.78 43.78 0 0 1 6.87 28.73M72.208 276.31c1.32-3.08 2.26-6.31 3.44-9.45a27.18 27.18 0 0 1 4.92-8.71 12.93 12.93 0 0 1 8.7-4.51 35 35 0 0 1-16.92 22.72M68.708 280.39c-2.19-3-4.72-7.12-7.7-9.37-2.98-2.25-6.84-3.62-10.47-2.75a24.41 24.41 0 0 0 18.17 12.12M69.408 276.56a51.82 51.82 0 0 1-3.46-11.22 17.51 17.51 0 0 1 1.75-11.37c3.86 7.3 5.1 15.12 1.91 22.73M72.238 253.97c2.19-7.22 8.81-12.55 16-15a6 6 0 0 1 2.8-.42 2.44 2.44 0 0 1 2.06 1.72c.26 1.23-.76 2.34-1.71 3.15a54.26 54.26 0 0 1-19.15 10.55M72.348 247.97c.28-4.66.57-9.38 2.09-13.8s4.43-8.56 8.68-10.49c.73 4.71-1.35 9.39-3.85 13.44-2.5 4.05-5.53 7.87-7 12.39M72.208 251.22c-1.81-5.27-2-12-5.15-16.55-2.2-3.2-5.73-5.39-7.63-8.79a13.33 13.33 0 0 0 .44 10 28.91 28.91 0 0 0 5.84 8.36c2.33 2.49 4.33 4.36 6.5 7", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M72.238 406.34c-.14 0-.26-35.14-.27-78.47-.01-43.33.1-78.48.24-78.48.14 0 .26 35.13.27 78.48.01 43.35-.13 78.47-.24 78.47z", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M102.948 301.09a8.61 8.61 0 0 1-1.32.49l-3.64 1.23c-3.06 1-7.27 2.55-11.84 4.43s-8.8 3.52-11.23 5.62a8.24 8.24 0 0 0-2.25 3c-.35.82-.41 1.33-.46 1.32-.05-.01 0-.13 0-.37a5.46 5.46 0 0 1 .26-1 8.07 8.07 0 0 1 2.2-3.18 19.94 19.94 0 0 1 4.87-3c2-.91 4.11-1.86 6.4-2.8 4.59-1.88 8.83-3.34 11.93-4.3 1.55-.49 2.82-.84 3.69-1.08a6.48 6.48 0 0 1 1.39-.36z", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M113.018 276.36s-.12.15-.37.4l-1.13 1.06-4.17 3.82-14 12.48c-5.47 4.86-10.2 9.51-13.47 13-1.64 1.75-2.93 3.19-3.81 4.21l-1 1.16c-.24.26-.37.4-.38.38-.01-.02.09-.16.3-.45.21-.29.52-.7.94-1.22.84-1.05 2.09-2.54 3.7-4.33a177.66 177.66 0 0 1 13.4-13.15c5.49-4.86 10.48-9.22 14.11-12.37l4.32-3.7 1.19-1a2 2 0 0 1 .37-.29z", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M87.348 299.07c-.14-.07 1-2.71 2.14-6 1.14-3.29 1.69-6.15 1.84-6.13a20.59 20.59 0 0 1-1.36 6.29 39.22 39.22 0 0 1-1.7 4.22 6.5 6.5 0 0 1-.92 1.62zM72.278 320.33a6.47 6.47 0 0 1-.85-1c-.57-.74-1.29-1.67-2.16-2.77-1.81-2.35-4.32-5.58-7.13-9.12a741.74 741.74 0 0 0-7.26-9l-2.21-2.73a6.63 6.63 0 0 1-.76-1 5.47 5.47 0 0 1 .91.91c.56.61 1.36 1.51 2.33 2.63 2 2.24 4.58 5.38 7.39 8.93 2.81 3.55 5.28 6.82 7 9.22.87 1.2 1.57 2.18 2 2.87a7 7 0 0 1 .74 1.06z", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M65.538 311.69a12.37 12.37 0 0 1-2.82-5.28 15.31 15.31 0 0 1-.64-5.32h.27c-.1.41-.18.63-.22.62-.04-.01 0-.23 0-.65h.23a23.74 23.74 0 0 0 .9 5.17 40.85 40.85 0 0 0 2.28 5.46zM56.848 300.76a8.84 8.84 0 0 0-2-1.15 13.09 13.09 0 0 0-5.47-.43 32.88 32.88 0 0 0-5.5.89 17.12 17.12 0 0 1-2.27.54 8.1 8.1 0 0 1 2.17-.89 24.68 24.68 0 0 1 5.57-1.05 11.92 11.92 0 0 1 5.64.61 3.44 3.44 0 0 1 1.86 1.48zM54.788 298.11c0 .07-1.15-.46-3-1.1a30.33 30.33 0 0 0-15.2-1.16c-2 .35-3.12.71-3.15.63-.03-.08.27-.16.8-.34a17.45 17.45 0 0 1 2.28-.64 26.77 26.77 0 0 1 15.4 1.17 18.16 18.16 0 0 1 2.15 1c.47.24.74.4.72.44zM72.208 286.9a62.29 62.29 0 0 1-2.2-7.64 62 62 0 0 1-1.71-7.77 61.63 61.63 0 0 1 2.2 7.64 64.22 64.22 0 0 1 1.71 7.77z", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M72.348 286.9a37.49 37.49 0 0 0-2.44-4.58 20.2 20.2 0 0 0-3.59-3.7 3.36 3.36 0 0 1 1.39.71 11.48 11.48 0 0 1 2.62 2.7 10.26 10.26 0 0 1 2.02 4.87zM72.238 387.61s-.15-.84-.55-2.34a33.35 33.35 0 0 0-2.35-6.08c-2.43-5-6.84-11.25-12.46-17.55-2.78-3.18-5.48-6.18-7.77-9.05a63.11 63.11 0 0 1-5.55-8 39.52 39.52 0 0 1-2.81-5.95c-.26-.73-.45-1.3-.55-1.7a2.43 2.43 0 0 1-.14-.61s.3.81.88 2.24a43 43 0 0 0 2.93 5.84 66.37 66.37 0 0 0 5.61 7.9c2.3 2.84 5 5.82 7.79 9 5.62 6.32 10 12.69 12.4 17.73a30.55 30.55 0 0 1 2.21 6.2c.16.76.27 1.35.31 1.76a2.64 2.64 0 0 1 .05.61zM99.988 356.27a11.23 11.23 0 0 1-1.45.82 31.17 31.17 0 0 0-3.77 2.49 42.22 42.22 0 0 0-5 4.52c-1.74 1.83-3.53 4-5.36 6.25-3.61 4.62-7.06 8.62-9.06 11.89-1 1.6-1.79 3-2.29 3.91a10.73 10.73 0 0 1-.8 1.46 1.48 1.48 0 0 1 .14-.41c.11-.26.25-.66.49-1.14a36.81 36.81 0 0 1 2.16-4c2-3.31 5.38-7.43 9-12 1.83-2.29 3.64-4.43 5.41-6.27a41 41 0 0 1 5.08-4.48 25.84 25.84 0 0 1 3.9-2.38c.48-.24.87-.39 1.13-.5.26-.11.41-.18.42-.16z", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M100.538 366.44a23.12 23.12 0 0 1-3.08.44 40.64 40.64 0 0 0-7.29 1.44 22.15 22.15 0 0 0-6.53 3.42c-1.5 1.17-2.21 2.13-2.28 2.07a2.39 2.39 0 0 1 .46-.7 10.68 10.68 0 0 1 1.6-1.64 19.54 19.54 0 0 1 6.6-3.63 32.31 32.31 0 0 1 7.41-1.31 16.48 16.48 0 0 1 3.11-.09z", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M82.258 372.7c-.16-.09 1.22-2.21 2.3-5.06 1.08-2.85 1.24-5.39 1.42-5.36a4.85 4.85 0 0 1 0 1.65 17.39 17.39 0 0 1-.9 3.89 18.6 18.6 0 0 1-1.78 3.56 5.38 5.38 0 0 1-1.04 1.32zM108.978 328.97a2 2 0 0 1-.49.26l-1.45.66c-.63.27-1.4.62-2.26 1.07-.86.45-1.89.91-2.94 1.55l-1.68.95c-.58.33-1.15.73-1.77 1.11-1.23.75-2.47 1.68-3.79 2.62a64.82 64.82 0 0 0-14.62 15.13c-.9 1.35-1.78 2.62-2.49 3.88-.36.63-.74 1.22-1 1.81l-.9 1.71c-.6 1.07-1 2.1-1.44 3-.44.9-.74 1.66-1 2.3l-.61 1.46a2.25 2.25 0 0 1-.24.5 2 2 0 0 1 .14-.53l.52-1.5c.22-.66.52-1.45.92-2.35.4-.9.8-1.94 1.38-3l.87-1.74c.31-.6.68-1.2 1-1.83.69-1.28 1.57-2.57 2.46-3.94a63.21 63.21 0 0 1 14.76-15.28c1.34-.94 2.59-1.85 3.85-2.59.62-.38 1.2-.77 1.79-1.1l1.71-.93c1.07-.62 2.1-1.06 3-1.48a23.77 23.77 0 0 1 2.31-1l1.49-.57a2.45 2.45 0 0 1 .48-.17z", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M87.018 343.69a3.48 3.48 0 0 1 1.21-1.8 7.56 7.56 0 0 1 4.86-1.87 13.45 13.45 0 0 1 5.12 1 7.57 7.57 0 0 1 1.94.94 11.32 11.32 0 0 1-2.06-.6 15.62 15.62 0 0 0-5-.83 7.93 7.93 0 0 0-4.65 1.63 13.37 13.37 0 0 0-1.42 1.53zM83.738 347.07a18.57 18.57 0 0 0 1.38-4.49 18.81 18.81 0 0 0-.31-4.69 3 3 0 0 1 .57 1.31 9.3 9.3 0 0 1-.79 6.73c-.43.78-.81 1.18-.85 1.14zM47.968 392.65c.76 13.42 4.81 27.08 11 40.89h27.07c5.94-13.67 9.7-27.31 10.37-40.89z", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M92.058 414.38c0 .14-8.92.25-19.91.25s-19.9-.11-19.9-.25 8.91-.26 19.9-.26c10.99 0 19.91.12 19.91.26zM91.448 419.24c0 .14-8.35.25-18.66.25s-18.66-.11-18.66-.25 8.36-.25 18.66-.25 18.66.11 18.66.25zM59.438 401.46a77.88 77.88 0 0 1 0 12.47 77.88 77.88 0 0 1 0-12.47z", style: {
  fill: "#fafafa"
} }), /* @__PURE__ */ React.createElement("path", { d: "M73.938 401.46a80.38 80.38 0 0 1 0 12.92 83.59 83.59 0 0 1 0-12.92zM87.558 401.35a86.85 86.85 0 0 1 0 13.17 83.52 83.52 0 0 1 0-13.17z", style: {
  fill: "#fafafa"
} }), /* @__PURE__ */ React.createElement("path", { d: "M95.578 401.46c0 .14-10.45.26-23.34.26s-23.35-.12-23.35-.26 10.45-.25 23.35-.25c12.9 0 23.34.11 23.34.25z", style: {
  fill: "#fafafa"
} }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#f5f5f5"
}, d: "M205.488 304.88h171.92v79.28h-171.92z" }), /* @__PURE__ */ React.createElement("path", { d: "M373.348 380.43h-163.92v-71.38h163.92zm-162.82-1.07h161.72v-69.24h-161.75zM377.408 304.88h42v79.28h-42z", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M209.958 331.14h162.82a.22.22 0 0 0 0-.43h-162.82a.22.22 0 0 0 0 .43z", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M291.898 330.97a177.93 177.93 0 0 0 .06-21.54.22.22 0 0 0-.44 0 177.93 177.93 0 0 0 .06 21.54.16.16 0 0 0 .32 0zM335.728 320.16a2.22 2.22 0 1 1-2.22-2.22 2.21 2.21 0 0 1 2.22 2.22zM250.278 320.16a2.22 2.22 0 1 1-2.22-2.22 2.21 2.21 0 0 1 2.22 2.22zM293.588 344.74a2.22 2.22 0 1 1-2.22-2.21 2.21 2.21 0 0 1 2.22 2.21z", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#e0e0e0"
}, transform: "translate(-34.652 -42.03)", d: "m260.7 426.2-8.85 49.78h4.98l16.31-49.78zM399.9 426.2l7.4 49.86h-4.98l-14.87-49.86zM433.13 426.2l8.39 49.86h-4.98l-15.86-49.86z" }), /* @__PURE__ */ React.createElement("path", { d: "M293.438 367.18a2.22 2.22 0 1 1-2.22-2.22 2.21 2.21 0 0 1 2.22 2.22zM209.958 355.7h162.82a.22.22 0 0 0 0-.44h-162.82a.22.22 0 0 0 0 .44z", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { transform: "rotate(89.7)", style: {
  fill: "#e0e0e0"
}, d: "M269.088-324.05h10.58v51.98h-10.58z" }), /* @__PURE__ */ React.createElement("path", { transform: "rotate(89.7)", style: {
  fill: "#f5f5f5"
}, d: "M289.939-330.143h16.44v57.93h-16.44z" }), /* @__PURE__ */ React.createElement("path", { d: "M320.608 304.55a58.53 58.53 0 0 1-.29-8.22 58.53 58.53 0 0 1 .29-8.22 61.49 61.49 0 0 1 .28 8.22 61.49 61.49 0 0 1-.28 8.22zM323.038 304.55a59.54 59.54 0 0 1-.28-8.22 59.54 59.54 0 0 1 .28-8.22 59.54 59.54 0 0 1 .28 8.22 59.54 59.54 0 0 1-.28 8.22zM282.548 304.55a61.49 61.49 0 0 1-.28-8.22 61.49 61.49 0 0 1 .28-8.22 59.54 59.54 0 0 1 .28 8.22 59.54 59.54 0 0 1-.28 8.22zM284.978 304.55a61.49 61.49 0 0 1-.28-8.22 61.49 61.49 0 0 1 .28-8.22 58.53 58.53 0 0 1 .29 8.22 58.53 58.53 0 0 1-.29 8.22zM290.288 296.59c0-.16 5.85-.28 13.06-.28 7.21 0 13.05.12 13.05.28 0 .16-5.84.29-13.05.29-7.21 0-13.06-.13-13.06-.29z", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { transform: "rotate(89.7)", style: {
  fill: "#ebebeb"
}, d: "M279.377-351.992h10.58v71.49h-10.58z" }), /* @__PURE__ */ React.createElement("path", { d: "M343.288 286.81a3.42 3.42 0 1 1 3.38-3.42 3.4 3.4 0 0 1-3.38 3.42z", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { transform: "rotate(90)", style: {
  fill: "#e0e0e0"
}, d: "M282.33-333.998h2.12v47.85h-2.12z" }), /* @__PURE__ */ React.createElement("path", { d: "M456.7 476.06c0 .14-92.55.26-206.69.26s-206.71-.12-206.71-.26 92.53-.26 206.71-.26 206.69.12 206.69.26z", style: {
  fill: "#263238"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M224 252.84c-.76-1.26 5.1-6.18 14.52-13.86l7.71-6.31a39.82 39.82 0 0 1 10.91-6.77 27 27 0 0 1 15.28-1 25.43 25.43 0 0 1 7.74 3.1 20.64 20.64 0 0 1 6.62 6.31 14.52 14.52 0 0 1 1.29 2.43 9.27 9.27 0 0 1 .69 3.81 7.85 7.85 0 0 1-1.7 4.64 7.21 7.21 0 0 1-2.25 1.9 7.14 7.14 0 0 1-3 .83 8 8 0 0 1-4.38-1.15c-.84-.48-1.3-.83-1.54-1-.24-.17-.17-.1-.1-.07a1.76 1.76 0 0 0 .27.07c.17 0 .18 0 0 0a19.61 19.61 0 0 0-2.48.65c-.64.18-1.41.33-2.12.47-.71.14-1.41.19-2.08.22a35.6 35.6 0 0 1-7.1-.45c-2-.28-3.8-.54-5.32-.59a13.21 13.21 0 0 0-4.11.47 37.24 37.24 0 0 0-8.06 3.89 46.48 46.48 0 0 1-7.85 3.94 19 19 0 0 1-6.93 1.27 9.22 9.22 0 0 1-4.39-1.07c-.9-.52-1.24-1-1.14-1.36.25-.75 2.17-.57 5-1.59a22.86 22.86 0 0 0 4.93-2.5 65.86 65.86 0 0 0 6.2-4.75 37.86 37.86 0 0 1 9.35-6 20.89 20.89 0 0 1 6.83-1.48 52 52 0 0 1 6.49.2 30.11 30.11 0 0 0 5.33.07c.39 0 .73-.11 1.09-.17.36-.06.62-.14 1-.28a24.58 24.58 0 0 1 3.84-1.06 9.74 9.74 0 0 1 3.33 0 9 9 0 0 1 1.86.54 12.07 12.07 0 0 1 1.4.7c.71.43 1.18.75 1.32.81l-.05.1-.25.53a10.14 10.14 0 0 0-.53 1.28 3.3 3.3 0 0 0-.19.76 1.39 1.39 0 0 0 0 .28s0 .06 0 0c-.07-.23.08.32.06.26v-.09a1.43 1.43 0 0 0-.34-.39 1.38 1.38 0 0 0-.35-.2h.2-.65a3.49 3.49 0 0 0-.87.1 6.35 6.35 0 0 0-.75.19l-.22.08a3.36 3.36 0 0 0-.37-.65c-1.51-2.4-4.7-4.31-8-5.15a18.3 18.3 0 0 0-9.78.17 35.8 35.8 0 0 0-9 4.67c-3 2-5.92 3.82-8.59 5.38-10.77 6.3-18.03 9.03-18.77 7.82z", style: {
  fill: "#f5f5f5"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "m274.76 442.53-1.45 21.91s21.81 7.24 21.93 11.31l-42.61.31.86-34.24z", style: {
  fill: "#455a64"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.6
} }, /* @__PURE__ */ React.createElement("path", { d: "M260.19 462.19a1.74 1.74 0 0 0-1.26 1.95 1.67 1.67 0 0 0 1.92 1.27 1.83 1.83 0 0 0 1.33-2.07 1.75 1.75 0 0 0-2.15-1.1", style: {
  fill: "#fff"
}, transform: "translate(-34.652 -42.03)" })), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.6
} }, /* @__PURE__ */ React.createElement("path", { d: "m252.63 476.06.09-3.45 40.61.7a3.33 3.33 0 0 1 1.91 2.44z", style: {
  fill: "#fff"
}, transform: "translate(-34.652 -42.03)" })), /* @__PURE__ */ React.createElement("path", { d: "M274 464.24c0 .21-1.05.27-2.1.91s-1.61 1.5-1.8 1.41c-.19-.09.16-1.28 1.43-2s2.52-.51 2.47-.32zM278.41 466.23c0 .2-.87.53-1.6 1.38-.73.85-1 1.79-1.17 1.77-.17-.02-.3-1.2.63-2.24.93-1.04 2.15-1.14 2.14-.91zM280.79 471.65c-.19 0-.45-1 .12-2.13.57-1.13 1.55-1.55 1.64-1.37.09.18-.54.76-1 1.69-.46.93-.55 1.79-.76 1.81zM273.78 459.21c-.09.19-1-.13-2.22-.08-1.22.05-2.1.38-2.21.2-.11-.18.81-.89 2.19-.93 1.38-.04 2.35.65 2.24.81z", style: {
  fill: "#263238"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "m253.28 449.95.09-3.53 21.09.45-.25 3.9z", transform: "translate(-34.652 -42.03)", style: {
  opacity: 0.3
} }), /* @__PURE__ */ React.createElement("path", { d: "M274 454.79a4 4 0 0 1-2.13-.08 9.46 9.46 0 0 1-2.21-.75 9.32 9.32 0 0 1-1.21-.69 2.78 2.78 0 0 1-.63-.52.91.91 0 0 1-.06-1.11 1.16 1.16 0 0 1 .93-.45 2.59 2.59 0 0 1 .79.14 8.58 8.58 0 0 1 1.32.49 7.44 7.44 0 0 1 1.92 1.35c1 .95 1.28 1.73 1.21 1.77-.07.04-.56-.6-1.54-1.38a8.56 8.56 0 0 0-1.87-1.13 7.61 7.61 0 0 0-1.22-.42c-.45-.13-.83-.14-1 .05-.17.19 0 .11 0 .24a1.86 1.86 0 0 0 .46.38 9.47 9.47 0 0 0 1.11.68 11 11 0 0 0 2 .85 12.85 12.85 0 0 1 2.13.58z", style: {
  fill: "#263238"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M273.71 455.08a3.49 3.49 0 0 1-.5-2.08 7.16 7.16 0 0 1 .36-2.33 8.47 8.47 0 0 1 .58-1.28 1.79 1.79 0 0 1 1.4-1 1 1 0 0 1 .87.62 3.27 3.27 0 0 1 .2.79 4.51 4.51 0 0 1-.05 1.43 4.89 4.89 0 0 1-1 2.18c-.87 1.08-1.74 1.3-1.76 1.23-.02-.07.67-.49 1.34-1.53a4.93 4.93 0 0 0 .74-2 4.23 4.23 0 0 0 0-1.23c-.05-.46-.21-.83-.38-.79-.17.04-.58.32-.77.68a7.07 7.07 0 0 0-.56 1.14 7.7 7.7 0 0 0-.48 2.11c-.07 1.24.13 2.03.01 2.06z", style: {
  fill: "#263238"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "m232.6 442.07-3 21.76s21.25 8.73 21.1 12.81L208.19 474l3.24-34.09z", style: {
  fill: "#455a64"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.6
} }, /* @__PURE__ */ React.createElement("path", { d: "M216.7 460.68a1.73 1.73 0 0 0-1.39 1.85 1.66 1.66 0 0 0 1.82 1.4 1.83 1.83 0 0 0 1.47-2 1.74 1.74 0 0 0-2.06-1.25", style: {
  fill: "#fff"
}, transform: "translate(-34.652 -42.03)" })), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.6
} }, /* @__PURE__ */ React.createElement("path", { d: "m208.19 474 .33-3.43 41 4a3 3 0 0 1 1.42 2.42z", style: {
  fill: "#fff"
}, transform: "translate(-34.652 -42.03)" })), /* @__PURE__ */ React.createElement("path", { d: "M230.34 463.68c0 .21-1.07.2-2.16.76s-1.72 1.38-1.9 1.28c-.18-.1.25-1.27 1.57-1.93 1.32-.66 2.55-.3 2.49-.11zM234.6 466c0 .21-.9.47-1.7 1.28-.8.81-1.08 1.71-1.29 1.68-.21-.03-.22-1.22.79-2.2 1.01-.98 2.22-.99 2.2-.76zM236.59 471.54c-.19 0-.37-1 .27-2.12s1.66-1.43 1.73-1.25c.07.18-.59.72-1.11 1.62-.52.9-.68 1.75-.89 1.75zM230.46 458.65c-.1.18-1-.21-2.2-.24-1.2-.03-2.13.24-2.22 0-.09-.24.86-.84 2.25-.78 1.39.06 2.29.86 2.17 1.02z", style: {
  fill: "#263238"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "m210.66 447.98.33-3.51 21.02 1.91-.53 3.87z", transform: "translate(-34.652 -42.03)", style: {
  opacity: 0.3
} }), /* @__PURE__ */ React.createElement("path", { d: "M231 454.24a3.85 3.85 0 0 1-2.12-.22 10 10 0 0 1-2.15-.9 10.13 10.13 0 0 1-1.16-.77 2.82 2.82 0 0 1-.59-.57.9.9 0 0 1 0-1.11 1.15 1.15 0 0 1 1-.38 2.86 2.86 0 0 1 .79.19 7.78 7.78 0 0 1 1.27.58 7.52 7.52 0 0 1 1.83 1.49c.89 1 1.15 1.81 1.08 1.85-.07.04-.52-.64-1.44-1.49a8.39 8.39 0 0 0-1.79-1.26 7.89 7.89 0 0 0-1.18-.5c-.44-.16-.82-.2-1 0-.18.2-.05.1 0 .24a1.9 1.9 0 0 0 .43.41 11.48 11.48 0 0 0 1.06.75 11.81 11.81 0 0 0 2 1 15.59 15.59 0 0 1 1.97.69z", style: {
  fill: "#263238"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M230.68 454.51a3.41 3.41 0 0 1-.35-2.1 7 7 0 0 1 .52-2.3 7.08 7.08 0 0 1 .67-1.23 1.75 1.75 0 0 1 1.46-.94 1 1 0 0 1 .83.68 3.14 3.14 0 0 1 .14.8 4.76 4.76 0 0 1-.14 1.43 4.94 4.94 0 0 1-1.13 2.1c-.94 1-1.83 1.18-1.84 1.11-.01-.07.71-.44 1.45-1.43a5 5 0 0 0 .87-1.93 4.18 4.18 0 0 0 .08-1.23c0-.46-.15-.84-.32-.81-.17.03-.61.28-.82.62a8.25 8.25 0 0 0-.64 1.1 7.66 7.66 0 0 0-.62 2.08c-.18 1.24-.04 2.03-.16 2.05zM279 258.1s12 20.79 12 49.68c0 12.8-12 141-12 141l-29.6.06 1.64-65.54 3.78-124.94z", style: {
  fill: "#263238"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "m227.86 257.52-.09.12c-13.19 17.2-18.09 38.35-17.19 58.87l3.41 44.8-5.78 82.75 32.55 4.73 8.05-77.07s13.55-83.26 13.55-85.54V258.1s-30.2-6.36-34.5-.58z", style: {
  fill: "#263238"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M266.52 169.84c.88.16 9.38 3.73 13.24 5.36a5.54 5.54 0 0 1 2.8 2.64l19 38.25-14.66 10.15s-4.12 30.35-7.9 31.86c-1.41.56-46.8 0-46.8 0s-5.53 2-7.52 0c-1.53-1.52-.94-4.47-.94-4.47v-62.35l-2.77-17.17s26.38-7.65 45.55-4.27z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "m231 187.39.81 31-26.42 2.52c0-.17-2.86-40.2 15.53-46.82z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M285.76 220.93c-.15-1-.33-2.18-.61-4.07-.4-2.8-.92-6.43-1.5-10.43s-1.07-7.64-1.47-10.45c-.17-1.24-.31-2.3-.43-3.17a4.66 4.66 0 0 1-.1-1.17 4.45 4.45 0 0 1 .31 1.14c.16.74.37 1.81.61 3.14.47 2.66 1.05 6.35 1.63 10.43.58 4.08 1.05 7.78 1.34 10.46.15 1.35.25 2.44.3 3.19v1s0 .1-.09.05V221s0-.07.01-.07zM205.39 220.93a14.72 14.72 0 0 1 1.88-.28l5.16-.59c4.36-.46 10.38-1.07 17-1.71l2.32-.22-.25.28c-.16-6.15-.27-11.62-.32-15.56v-4.64a9.32 9.32 0 0 1 .06-1.69 9.46 9.46 0 0 1 .15 1.68c.05 1.18.12 2.75.2 4.64.16 3.94.33 9.41.5 15.56v.26h-.26l-2.32.22c-6.65.64-12.68 1.19-17.05 1.55l-5.18.41a12.35 12.35 0 0 1-1.89.09z", style: {
  fill: "#263238"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M284.08 238.63a13.5 13.5 0 0 0-7.41-7.73 25.12 25.12 0 0 0-10.76-1.9 35.16 35.16 0 0 0-18.28 5c-8.11 5.13-13.53 14.22-22.45 17.77l-1.09 1.57a36.47 36.47 0 0 0 19.91-4.72c3.91-2.25 7.45-5.25 11.73-6.7 3.78-1.29 7.86-1.27 11.85-1.24 3.77 0 7.78.14 10.91 2.25a6.27 6.27 0 0 0 2.94 1.5 3 3 0 0 0 2.83-2.09 5.83 5.83 0 0 0-.18-3.71z", transform: "translate(-34.652 -42.03)", style: {
  opacity: 0.3
} }), /* @__PURE__ */ React.createElement("path", { d: "m272.61 217.14 3.17-8a2.12 2.12 0 0 1 2-1.34h15a1.52 1.52 0 0 1 1.42 2l-9.64 26.2a.77.77 0 0 1-.73.51H266a.78.78 0 0 1-.73-1.08z", style: {
  fill: "#263238"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "m291.83 241.52 1.44-17.5-1.47-7.67-2.35 6.39v2.57c-.87-.22-8.93-7.06-11.1-5.59-2.17 1.47-7.71 9.55-7.71 9.55s9.75 9.53 11.31 10.24c0 0-1.77 7-2.33 9.61-.56 2.61-1.09 10 2.33 11.19 3.42 1.19 12.61-7 11.32-12.19-.86-3.44-1.44-6.6-1.44-6.6z", style: {
  fill: "#ab6e65"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "m228.7 219 .85 10.24 24.64-9 11-5.07 4.8 1.2s15.09 1 15.22 2c.13 1 .83 1.79 0 1.92-.83.13-12.75.69-12.75.69s12.86.82 13.15 1.78c.29.96-.12 2.47-.81 2.47h-14s13.16.82 13.3 1.37c.14.55 1.09 1.51-.14 1.92-1.23.41-13.3.82-13.3.82l11.34 1.99s.41 1.78-.55 1.78-25.78 1.65-25.78 1.65-29.06 18.54-34.55 19.88c-5.49 1.34-10.28-5-11-9.49-1-6.76-2-24.46-2-24.46z", style: {
  fill: "#ab6e65"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "m255.69 234.76.51-.07 1.48-.12 5.68-.42 9-.62 5.6-.38 3.06-.21a2.29 2.29 0 0 0 1.37-.35c.24-.25-.14-.79-.5-1.11l.1.05-11.38-2a.23.23 0 0 1-.19-.27.22.22 0 0 1 .22-.19l6.74-.3 3.52-.21 1.79-.15c.58-.08 1.24-.08 1.56-.4.32-.32.15-1-.2-1.18a5.82 5.82 0 0 0-.82-.15l-.93-.11-3.77-.39-7.77-.72a.27.27 0 0 1 0-.54h13.91c.19 0 .41-.23.55-.51a1.21 1.21 0 0 0-.29-1.61 7.62 7.62 0 0 0-1.48-.32l-1.59-.23-3.19-.38-6.29-.65a.27.27 0 0 1-.25-.3.28.28 0 0 1 .26-.25l8.12-.39 3.94-.22a2.81 2.81 0 0 0 .83-.12.61.61 0 0 0 .21-.6 1.12 1.12 0 0 0-.35-.68 1.8 1.8 0 0 0-.77-.29c-2.46-.52-5-.75-7.38-1s-4.78-.49-7.08-.7l-4.79-1.22h.15l-11 5-18.05 6.51-4.92 1.74-1.28.44a3.09 3.09 0 0 1-.45.13l.43-.27 1.26-.49 4.88-1.84 18-6.67 11-5.1a.2.2 0 0 1 .15 0l4.8 1.19c2.3.19 4.67.42 7.09.67 2.42.25 4.91.5 7.46 1a2.21 2.21 0 0 1 1 .41 1.72 1.72 0 0 1 .53 1 1.12 1.12 0 0 1-.46 1.12 2.88 2.88 0 0 1-1.07.2l-4 .23-8.12.4v-.55l6.3.65 3.2.38 1.61.23a6.78 6.78 0 0 1 1.68.4 1.49 1.49 0 0 1 .71 1.09 2.33 2.33 0 0 1-.21 1.23 2 2 0 0 1-.37.53.94.94 0 0 1-.29.21.87.87 0 0 1-.39.09h-13.98v-.54l7.78.73 3.77.4.93.12a4.2 4.2 0 0 1 1 .2 1.35 1.35 0 0 1 .63.92 1.15 1.15 0 0 1-.3 1.08 1.79 1.79 0 0 1-1 .44l-.92.11c-.61.06-1.21.1-1.8.14l-3.53.2-6.75.28v-.47l11.38 2.09a.13.13 0 0 1 .09 0 3 3 0 0 1 .57.7.86.86 0 0 1 0 1 2.43 2.43 0 0 1-1.63.48l-3.06.19-5.61.34-9 .52-5.7.31-1.48.06a2.82 2.82 0 0 1-.46.08z", style: {
  fill: "#7c4b43"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M221.73 240.87a6.42 6.42 0 0 1 .19-2.16 13.58 13.58 0 0 1 2-4.84 13.05 13.05 0 0 1 3.73-3.67 5.82 5.82 0 0 1 1.93-1c.14.18-3 1.61-5.2 4.94a16.54 16.54 0 0 0-2 4.63 12.37 12.37 0 0 1-.65 2.1z", style: {
  fill: "#7c4b43"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M285.05 214.42a1.32 1.32 0 1 1-1.66-.6 1.26 1.26 0 0 1 1.66.6z", style: {
  fill: "#fafafa"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M288 224.35a9 9 0 0 1 .47-1.51l1.45-4.05c.65-1.73 1.4-3.75 2.22-6 .22-.57.43-1.14.65-1.73.22-.59.59-1.27.43-1.64-.07-.16-.31-.24-.62-.22h-14.35a1.18 1.18 0 0 0-1.17.48c-.2.37-.37.88-.54 1.28l-1.67 4a8.79 8.79 0 0 1-.67 1.43 8.39 8.39 0 0 1 .49-1.5c.38-1 .89-2.4 1.5-4 .18-.43.3-.84.55-1.35a1.42 1.42 0 0 1 .66-.57 1.86 1.86 0 0 1 .85-.14H292.54a1.09 1.09 0 0 1 1.1.5 1.47 1.47 0 0 1-.07 1.13c-.12.3-.23.6-.34.9-.23.58-.45 1.16-.67 1.72l-2.33 5.95c-.66 1.63-1.2 3-1.61 4a10.82 10.82 0 0 1-.62 1.32z", style: {
  fill: "#455a64"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M244.68 110.75c-2.52-1.6-3.13-5.3-1.76-8a9.7 9.7 0 0 1 7.2-4.73A13.74 13.74 0 0 1 262 102c1.62 1.68 2.94 3.86 5.14 4.63 1.94.67 4.08.06 6.11.33a9.22 9.22 0 0 1 6.47 4.66 19.92 19.92 0 0 1 2.29 7.85c.3 2.51.49 5.26 2.23 7.09s4.38 2.16 6.48 3.43a8.87 8.87 0 0 1 2.64 12.11c-.84 1.22-2 2.33-2.13 3.81-.13 1.48.78 2.54 1.19 3.82a6 6 0 0 1-5.13 7.44c-1.74.1-3.62-.58-5.19.2-1.57.78-2.13 2.65-3.13 4.08a7.49 7.49 0 0 1-9.13 2.36c-1.28-.63-2.5-1.67-3.93-1.58-1.43.09-2.54 1.32-3.95 1.59-1.84.35-3.58-1-4.67-2.52-1.09-1.52-1.86-3.31-3.25-4.55-1.83-1.63-4.41-2-6.72-2.83a19 19 0 0 1-10.71-26.55", style: {
  fill: "#263238"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M271.32 143.68a5.1 5.1 0 0 1 2-1.35h-.15a3.21 3.21 0 0 1 .43-.07 2 2 0 0 1 1.6.2 3.83 3.83 0 0 1 2.06 1.8 4.48 4.48 0 0 1-.52 4.61 3.42 3.42 0 0 1-6.28-1.17 4.24 4.24 0 0 1 .86-4.02z", style: {
  fill: "#455a64"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "m238.09 170.46-2.67-49.12A4.17 4.17 0 0 1 239 117l22-6.21c7.06-1 12 7.63 12.67 14.73.72 7.88 1.29 17.54.52 23.46-1.54 11.9-10.58 13.67-10.58 13.67s.22 10.68.29 15l-.79 14.83z", style: {
  fill: "#ab6e65"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M269.08 135.82a1.52 1.52 0 0 1-1.42 1.57 1.45 1.45 0 0 1-1.61-1.32 1.53 1.53 0 0 1 1.42-1.58 1.46 1.46 0 0 1 1.61 1.33zM271.7 130.61c-.18.2-1.36-.62-3-.57-1.64.05-2.81.9-3 .71-.19-.19.09-.45.6-.85a4.22 4.22 0 0 1 2.39-.81 4 4 0 0 1 2.39.7c.53.37.71.72.62.82zM253.31 135.82a1.51 1.51 0 0 1-1.42 1.57 1.45 1.45 0 0 1-1.6-1.32 1.52 1.52 0 0 1 1.42-1.58 1.45 1.45 0 0 1 1.6 1.33zM255.64 131.37c-.19.2-1.36-.62-3-.57-1.64.05-2.81.91-3 .71-.19-.2.09-.45.6-.84a4.18 4.18 0 0 1 2.39-.82 4 4 0 0 1 2.39.7c.52.37.71.73.62.82zM261.92 143.33a10.38 10.38 0 0 1 2.64-.56c.41-.05.81-.15.87-.43a2.16 2.16 0 0 0-.31-1.23c-.43-1-.87-2.05-1.33-3.15-1.84-4.48-3.19-8.16-3-8.24.19-.08 1.82 3.5 3.67 8 .44 1.1.87 2.16 1.27 3.17a2.38 2.38 0 0 1 .26 1.63 1 1 0 0 1-.67.63 2.85 2.85 0 0 1-.7.12 11.15 11.15 0 0 1-2.7.06z", style: {
  fill: "#263238"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M263.6 162.65a30.12 30.12 0 0 1-16-3.79s4.08 8 15.91 6.59zM261.78 147.22a4 4 0 0 0-3.36-1.66 2.63 2.63 0 0 0-2.12 1.23 2.52 2.52 0 0 0 .12 2.64 2.49 2.49 0 0 0 2.68.88 5.84 5.84 0 0 0 2.36-1.56 1.76 1.76 0 0 0 .5-.62.85.85 0 0 0-.08-.83", style: {
  fill: "#7c4b43"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M257.14 144.59c.26 0 .31 1.76 1.87 3s3.44 1 3.46 1.22c.02.22-.41.36-1.21.4a4.42 4.42 0 0 1-2.88-.91 3.86 3.86 0 0 1-1.46-2.5c-.09-.8.08-1.21.22-1.21zM256.17 124.61c-.15.45-1.79.29-3.7.58-1.91.29-3.45.85-3.72.46-.12-.19.14-.62.76-1.07a6.24 6.24 0 0 1 2.73-1 6.1 6.1 0 0 1 2.91.26c.72.22 1.08.56 1.02.77zM271 125c-.28.38-1.42 0-2.77.05s-2.5.32-2.77-.06c-.12-.19.05-.56.54-.93a4 4 0 0 1 4.46 0c.46.37.63.75.54.94z", style: {
  fill: "#263238"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M281.64 123a8.07 8.07 0 0 0-3.12-4.48c-.85-.56-1.85-.9-2.64-1.54-1.71-1.38-2.11-3.79-3.17-5.72a8.93 8.93 0 0 0-6.81-4.52c-2.35-.24-4.77.46-7.06-.11-1.69-.43-3.17-1.53-4.88-1.85-3.34-.64-6.45 1.76-8.87 4.14 0 2.39 1.9 4 3.73 5.52a8.26 8.26 0 0 0 6.71 1.77c1.83-.33 3.82-1.25 5.4-.28 1.58.97 1.73 3.52 3.2 4.8 2.11 1.83 5.45.12 8.13.91 2.06.61 3.35 2.58 4.72 4.24 1.37 1.66 3.42 3.24 5.48 2.62a23.74 23.74 0 0 0-.82-5.5z", style: {
  fill: "#263238"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M256.58 112.93c-1.5-.43-4.56-1.4-5.36-2.69a9.16 9.16 0 0 0-8-4.58 7.55 7.55 0 0 0-6.79 6 10.86 10.86 0 0 0-9.33 6 11.42 11.42 0 0 0-.6 1.39 8.26 8.26 0 0 0-.5 6.95c.52 1.35 1.4 2.86.71 4.13s-2.5 1.33-3.89 1.81a5.47 5.47 0 0 0-3.46 6.12 6.55 6.55 0 0 1 .44 2.7 5.86 5.86 0 0 1-1.62 2.33 6.83 6.83 0 0 0 4 11.11 8.9 8.9 0 0 1 3.14.72c1.05.65 1.52 1.92 2.26 2.91a5.89 5.89 0 0 0 6.38 2 5.15 5.15 0 0 0 1.28-.65 4.23 4.23 0 0 0 3.76 1.62l-.44-25a2.06 2.06 0 0 0 1.51 1.18c4 .91 4.6-7.35 3.7-9.59a23.14 23.14 0 0 0 4-4.24 16.68 16.68 0 0 0 1.84-8.1c1.47.1 7.63-1.93 6.97-2.12z", style: {
  fill: "#263238"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M239.65 137.85c-.12-2.54-3.23-3.08-5.43-1.81-1.21.7-2.14 2.1-2 4.8.29 7.19 7.45 5.41 7.45 5.2 0-.21.13-5.22-.02-8.19z", style: {
  fill: "#ab6e65"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M237.34 143.41s-.12.09-.32.2a1.26 1.26 0 0 1-.94 0 3.09 3.09 0 0 1-1.55-2.72 4.26 4.26 0 0 1 .28-1.81 1.47 1.47 0 0 1 .9-1 .63.63 0 0 1 .75.32c.11.19.07.34.11.35.04.01.15-.13.08-.42a.82.82 0 0 0-.31-.44.94.94 0 0 0-.7-.15 1.76 1.76 0 0 0-1.26 1.16 4.31 4.31 0 0 0-.35 2c.11 1.44.9 2.77 2 3a1.32 1.32 0 0 0 1.13-.24c.18-.08.21-.24.18-.25z", style: {
  fill: "#7c4b43"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "M246.68 111a4.41 4.41 0 0 0-1.43 1.2 6.59 6.59 0 0 0-.53 5v.18h-.18a6.94 6.94 0 0 0-4.63 4.08 5.54 5.54 0 0 0-.38 1.75 10.7 10.7 0 0 0 .23 1.9 13.34 13.34 0 0 1 .27 2 3.37 3.37 0 0 1-.57 2.12 8.72 8.72 0 0 1-3.56 2.68 12.09 12.09 0 0 0-3.34 2.19 9 9 0 0 0-2.69 6 9.41 9.41 0 0 0 1 4.45c.48 1 .88 1.45.84 1.48-.04.03-.12-.11-.31-.33a7.58 7.58 0 0 1-.71-1.06 9.11 9.11 0 0 1 1.59-10.89 12.33 12.33 0 0 1 3.44-2.3 8.36 8.36 0 0 0 3.37-2.52c.79-1.09.44-2.51.23-3.8a10.29 10.29 0 0 1-.22-2 5.49 5.49 0 0 1 .42-1.91 7.3 7.3 0 0 1 5-4.28l-.14.23c-.42-2.22-.09-4.16.73-5.18a3 3 0 0 1 1.12-.92 1.73 1.73 0 0 1 .45-.07zM234.66 146.1a5.1 5.1 0 0 1 2-1.35h-.15a3.21 3.21 0 0 1 .43-.07 2.06 2.06 0 0 1 1.6.2 3.76 3.76 0 0 1 2.06 1.8 4.48 4.48 0 0 1-.52 4.61 3.42 3.42 0 0 1-6.28-1.18 4.24 4.24 0 0 1 .86-4.01zM257.8 296.92c.14 0-3.56 34-8.26 76-4.7 42-8.64 75.92-8.78 75.91-.14-.01 3.56-34 8.26-76 4.7-42 8.64-75.93 8.78-75.91zM241.14 261.67a3.18 3.18 0 0 1-.36.93 8.91 8.91 0 0 1-.54 1.06 12.57 12.57 0 0 1-.83 1.32 19.55 19.55 0 0 1-2.66 3.1 20.87 20.87 0 0 1-8.66 4.87 19.74 19.74 0 0 1-4 .67 14 14 0 0 1-1.57 0 9.38 9.38 0 0 1-1.18-.08 4.72 4.72 0 0 1-1-.18c0-.11 1.44.09 3.71-.13a20.63 20.63 0 0 0 3.92-.76 23.75 23.75 0 0 0 4.51-1.91 24.26 24.26 0 0 0 4-2.85 21.77 21.77 0 0 0 2.69-3c1.31-1.77 1.88-3.09 1.97-3.04zM284.27 275s-.27 0-.76-.12a12.68 12.68 0 0 1-2-.65 17.9 17.9 0 0 1-9.61-9.56 11.91 11.91 0 0 1-.66-2c-.11-.48-.16-.75-.12-.76.04-.01.37 1 1.12 2.6a19.55 19.55 0 0 0 9.42 9.37c1.58.73 2.64 1.01 2.61 1.12zM261.1 259.85a5.3 5.3 0 0 1 .08 1.29v3.53c-.05 3-.2 7.09-.53 11.62-.44 6.25-1.12 11.65-1.53 14.34v.11H259a10.88 10.88 0 0 1-2-.09 12.64 12.64 0 0 1 2-.16l-.12.11c.25-2.7.82-8.11 1.26-14.35.33-4.52.54-8.62.68-11.59.07-1.41.12-2.59.17-3.52a7.58 7.58 0 0 1 .11-1.29z", style: {
  fill: "#455a64"
}, transform: "translate(-34.652 -42.03)" }), /* @__PURE__ */ React.createElement("path", { d: "m338.448 158.72-45.1.25a4.14 4.14 0 0 0-4.12 4.16l.18 32.75a4.14 4.14 0 0 0 4.16 4.12l16.4-.09c1.67 2.3 4.36 6 5.83 8a1.14 1.14 0 0 0 1.91-.11l4.55-7.93 16.36-.09a4.15 4.15 0 0 0 4.13-4.17l-.18-32.74a4.13 4.13 0 0 0-4.12-4.15z", style: {
  fill: "#ebebeb"
} }), /* @__PURE__ */ React.createElement("path", { d: "m324.786 173.633-3.5-3.5a2.003 2.003 0 0 0-1.417-.587H308.68a2.003 2.003 0 0 0-2.003 2.003v14.69c0 1.106.897 2.002 2.003 2.002h14.69a2.003 2.003 0 0 0 2.003-2.003V175.05a2.003 2.003 0 0 0-.587-1.416zm-8.761 11.938a2.67 2.67 0 1 1 0-5.342 2.67 2.67 0 0 1 0 5.342zm4.006-12.708v4.194a.5.5 0 0 1-.5.501h-9.682a.5.5 0 0 1-.501-.5v-4.34a.5.5 0 0 1 .5-.501h9.537a.5.5 0 0 1 .354.146l.145.146a.5.5 0 0 1 .147.354z", style: {
  fill: "#fff",
  strokeWidth: 0.0417312
} }), /* @__PURE__ */ React.createElement("path", { d: "M338.448 158.72a3.16 3.16 0 0 1 1.17.12 4.21 4.21 0 0 1 2.62 2.12 4.79 4.79 0 0 1 .47 2.45v3c0 2.16 0 4.62.07 7.36 0 5.5.1 12.12.16 19.65v1.42a9.19 9.19 0 0 1-.05 1.47 4.37 4.37 0 0 1-1.44 2.66 4.43 4.43 0 0 1-2.93 1h-3.1l-13.11.09.21-.12-2.54 4.42c-.42.75-.86 1.51-1.29 2.27l-.66 1.14a1.43 1.43 0 0 1-1.28.81 1.35 1.35 0 0 1-.77-.24 2 2 0 0 1-.51-.55l-.8-1.09-1.6-2.2-3.27-4.47.22.11-11.9.07h-4.93a4.42 4.42 0 0 1-3.79-3 4.89 4.89 0 0 1-.21-1.26v-8.27c0-6.2-.06-12.19-.08-17.89V163.5a4.88 4.88 0 0 1 .35-2.09 4.37 4.37 0 0 1 3.13-2.57 11.23 11.23 0 0 1 2-.1h43.95-1.94l-5.55.08-20.43.19-14.11.11h-1.91a11.75 11.75 0 0 0-1.9.09 4 4 0 0 0-2.8 2.32 4.3 4.3 0 0 0-.3 1.9V169.73c0 5.7.07 11.68.11 17.89v7.07a7.52 7.52 0 0 0 .19 2.3 3.91 3.91 0 0 0 3.35 2.67h4.88l11.89-.06h.13l.08.11 3.26 4.47 1.61 2.2.79 1.09a1.5 1.5 0 0 0 .38.43.88.88 0 0 0 .49.15.9.9 0 0 0 .84-.55l.66-1.14 1.3-2.27 2.54-4.41.07-.13h.18l13.12-.05c2.06-.12 4.28.41 5.75-.93a3.93 3.93 0 0 0 1.35-2.38 8.2 8.2 0 0 0 .05-1.41v-1.42c0-7.52 0-14.15-.05-19.65v-10.3a4.62 4.62 0 0 0-.43-2.38 4.14 4.14 0 0 0-2.49-2.11 7 7 0 0 0-1.2-.2z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M352.728 161.31a11.11 11.11 0 1 1-11.16-11.05 11.1 11.1 0 0 1 11.16 11.05z", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { d: "M342.988 157.38v7.43h-1.64v-6h-1.49v-1.39z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M311.948 11.88h-49.88a4.58 4.58 0 0 0-4.59 4.58v36.26a4.58 4.58 0 0 0 4.59 4.59h18.16c1.83 2.56 4.78 6.64 6.4 8.87a1.27 1.27 0 0 0 2.12-.11l5.09-8.76h18.11a4.58 4.58 0 0 0 4.59-4.59V16.46a4.58 4.58 0 0 0-4.59-4.58z", style: {
  fill: "#ebebeb"
} }), /* @__PURE__ */ React.createElement("path", { d: "M311.948 11.88a3.67 3.67 0 0 1 1.3.14 4.69 4.69 0 0 1 2.88 2.36 5.39 5.39 0 0 1 .51 2.72v11.45c0 6.08 0 13.42.06 21.75v1.58a10.12 10.12 0 0 1-.07 1.63 4.77 4.77 0 0 1-1.65 2.9 4.86 4.86 0 0 1-3.24 1.12h-17.9l.21-.12-2.83 4.88-1.45 2.51-.73 1.26a1.55 1.55 0 0 1-1.4.87 1.49 1.49 0 0 1-.84-.26 2.21 2.21 0 0 1-.55-.61l-.9-1.19-1.76-2.44-3.59-5 .21.11h-18.6a4.86 4.86 0 0 1-4.16-3.36 5.06 5.06 0 0 1-.23-1.39V16.87a5.34 5.34 0 0 1 .39-2.3 4.86 4.86 0 0 1 3.46-2.82 11.75 11.75 0 0 1 2.2-.09h17.74l22.62.09h8.29H303.628l-22.62.08h-17.66a11.28 11.28 0 0 0-2.11.09 4.38 4.38 0 0 0-3.13 2.56 4.84 4.84 0 0 0-.35 2.12v34.61a8.21 8.21 0 0 0 .2 2.55 4.34 4.34 0 0 0 3.71 3h18.68l.08.11 3.58 5 1.76 2.44.88 1.22a1.57 1.57 0 0 0 .43.48.91.91 0 0 0 .55.18 1.05 1.05 0 0 0 1-.62l.73-1.27 1.46-2.5 2.84-4.87.07-.12h14.62c2.27-.12 4.74.49 6.39-1a4.37 4.37 0 0 0 1.51-2.64 10 10 0 0 0 .07-1.56V50.3c0-8.33 0-15.67.06-21.75V17.1a5.19 5.19 0 0 0-.46-2.65 4.62 4.62 0 0 0-2.75-2.35 7.39 7.39 0 0 0-1.22-.22z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 314.968, cy: 15.76, r: 12.29, style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { d: "M318.848 17.86h-1.34v1.73h-1.86v-1.73h-4.44v-1.28l3.88-5.23h2l-3.59 5h2.21v-1.58h1.8v1.54h1.34z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M165.708 12.37h-65.88a6.06 6.06 0 0 0-6.06 6.06v47.88a6.07 6.07 0 0 0 6.06 6.06h24c2.42 3.38 6.31 8.76 8.45 11.71a1.67 1.67 0 0 0 2.8-.14l6.72-11.57h23.92a6.06 6.06 0 0 0 6.06-6.06V18.43a6.06 6.06 0 0 0-6.07-6.06z", style: {
  fill: "#ebebeb"
} }), /* @__PURE__ */ React.createElement("path", { d: "M165.708 12.37h.44a5.94 5.94 0 0 1 5 3.32 6.05 6.05 0 0 1 .56 1.65 10.07 10.07 0 0 1 .11 1.92V34.38c0 8 0 17.73.06 28.73v2.08a13 13 0 0 1-.09 2.14 6.29 6.29 0 0 1-2.16 3.8 6.44 6.44 0 0 1-4.23 1.46h-23.71l.21-.12-3.64 6.5-1.91 3.27c-.33.55-.65 1.11-1 1.67a2 2 0 0 1-1.78 1.11 1.85 1.85 0 0 1-1.06-.33 2.89 2.89 0 0 1-.71-.79l-1.16-1.6c-.77-1.06-1.54-2.14-2.33-3.22l-4.73-6.56.21.1h-24.56a6.33 6.33 0 0 1-5.42-4.39 6.83 6.83 0 0 1-.29-1.79V18.97a7.06 7.06 0 0 1 .51-3 6.34 6.34 0 0 1 4.52-3.68 16.65 16.65 0 0 1 2.89-.12h23.43l29.87.09h10.95-10.95l-29.87.08h-23.43a16.15 16.15 0 0 0-2.8.12 5.91 5.91 0 0 0-4.19 3.42 6.54 6.54 0 0 0-.46 2.83v45.7a11.54 11.54 0 0 0 .27 3.4 5.83 5.83 0 0 0 5 4H123.948l.08.11 4.73 6.56 2.33 3.23c.38.53.77 1.06 1.15 1.6a2.41 2.41 0 0 0 .59.66 1.33 1.33 0 0 0 .78.24 1.45 1.45 0 0 0 1.33-.85l1-1.67c.65-1.11 1.29-2.21 1.92-3.31l3.75-6.44.07-.12h23.85a5.89 5.89 0 0 0 3.95-1.35 5.79 5.79 0 0 0 2-3.53 12.84 12.84 0 0 0 .09-2.07v-2.08c0-11 0-20.69.06-28.73V18.94a7 7 0 0 0-.61-3.5 6.07 6.07 0 0 0-3.66-3.11 8.45 8.45 0 0 0-1.65.04z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 167.058, cy: 16.24, r: 16.24, style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { d: "M164.458 11.86v9.11h-2.11v-7.41h-1.82v-1.7zM173.688 18.34c0 1.72-1.54 2.8-3.86 2.8s-3.83-1.08-3.83-2.8a2.32 2.32 0 0 1 1.35-2.15 2.12 2.12 0 0 1-1-1.88c0-1.6 1.44-2.6 3.51-2.6s3.53 1 3.53 2.6a2.13 2.13 0 0 1-1.07 1.88 2.3 2.3 0 0 1 1.37 2.15zm-2.14-.07c0-.8-.66-1.3-1.72-1.3s-1.69.5-1.69 1.3.65 1.32 1.69 1.32 1.72-.51 1.72-1.32zm-3.13-3.87c0 .69.55 1.13 1.41 1.13.86 0 1.43-.44 1.43-1.13s-.57-1.14-1.43-1.14-1.41.43-1.41 1.14z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M55.348 77.15H4.908a4.64 4.64 0 0 0-4.64 4.64v36.67a4.64 4.64 0 0 0 4.64 4.64h18.37c1.85 2.59 4.83 6.72 6.47 9a1.28 1.28 0 0 0 2.14-.1l5.15-8.87h18.31a4.64 4.64 0 0 0 4.64-4.64v-36.7a4.64 4.64 0 0 0-4.64-4.64z", style: {
  fill: "#ebebeb"
} }), /* @__PURE__ */ React.createElement("path", { d: "M55.348 77.15a4 4 0 0 1 1.31.15 4.67 4.67 0 0 1 2.91 2.39 5.35 5.35 0 0 1 .52 2.74v11.58c0 6.16 0 13.58.06 22v1.59a9.12 9.12 0 0 1-.07 1.64 4.86 4.86 0 0 1-1.67 2.94 5 5 0 0 1-3.27 1.14h-18.1l.21-.12c-.9 1.64-1.9 3.29-2.9 4.96-.48.84-1 1.68-1.47 2.53l-.7 1.28a1.54 1.54 0 0 1-1.41.88 1.46 1.46 0 0 1-.84-.26 2.33 2.33 0 0 1-.58-.62l-.88-1.22c-.59-.82-1.19-1.64-1.78-2.47l-3.63-5 .21.11H4.438a4.9 4.9 0 0 1-4.2-3.4 5.06 5.06 0 0 1-.23-1.39V82.29a5.38 5.38 0 0 1 .39-2.33 4.93 4.93 0 0 1 3.5-2.84 13 13 0 0 1 2.22-.1h18.03l22.87.08h8.39-8.39l-22.87.09h-18a13.54 13.54 0 0 0-2.13.09 4.47 4.47 0 0 0-3.17 2.6 4.93 4.93 0 0 0-.35 2.14v34.96a8.45 8.45 0 0 0 .2 2.59 4.4 4.4 0 0 0 3.76 3h18.95l.08.1 3.62 5 1.78 2.47.89 1.23a1.62 1.62 0 0 0 .43.49.94.94 0 0 0 .56.18 1.07 1.07 0 0 0 1-.63l.74-1.28 1.47-2.53 2.85-4.63.07-.12h14.83c2.3-.13 4.8.48 6.46-1a4.41 4.41 0 0 0 1.54-2.67 9.17 9.17 0 0 0 .06-1.58V82.43a5.26 5.26 0 0 0-.46-2.67 4.64 4.64 0 0 0-2.79-2.38 7.15 7.15 0 0 0-1.19-.23z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("circle", { cx: -65.104, cy: 73.218, r: 12.43, transform: "rotate(-76.79)", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { d: "M60.088 75.91v1.27l-3.13 7.16h-2.12l3-6.84h-2.77v1.38h-1.72v-3z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M140.988 141.49h-69.45a6.39 6.39 0 0 0-6.39 6.39v50.47a6.39 6.39 0 0 0 6.39 6.39h25.28c2.55 3.56 6.66 9.24 8.91 12.35a1.77 1.77 0 0 0 3-.15l7.08-12.2h25.22a6.39 6.39 0 0 0 6.38-6.39v-50.47a6.39 6.39 0 0 0-6.42-6.39z", style: {
  fill: "#ebebeb"
} }), /* @__PURE__ */ React.createElement("path", { d: "M140.988 141.49h.46a5.84 5.84 0 0 1 1.34.22 6.42 6.42 0 0 1 4 3.28 6.33 6.33 0 0 1 .59 1.75 11.05 11.05 0 0 1 .11 2v15.94c0 8.47 0 18.68.06 30.28v2.2a13.2 13.2 0 0 1-.09 2.25 6.57 6.57 0 0 1-2.27 4 6.69 6.69 0 0 1-4.46 1.54h-25l.21-.12-3.94 6.8-2 3.48-1 1.76a2.06 2.06 0 0 1-1.86 1.17 2 2 0 0 1-1.11-.35 2.9 2.9 0 0 1-.75-.83l-1.22-1.68-2.45-3.4-5-6.92.21.11H70.908a6.69 6.69 0 0 1-5.71-4.61 6.78 6.78 0 0 1-.3-1.89V148.45a7.37 7.37 0 0 1 .54-3.17 6.66 6.66 0 0 1 4.75-3.87 17.44 17.44 0 0 1 3-.13h24.74l31.49.08 8.56.05h2.99-2.99l-8.56.05-31.49.08h-24.74a17 17 0 0 0-3 .13 6.2 6.2 0 0 0-4.42 3.61 7 7 0 0 0-.5 3v48.18a11.73 11.73 0 0 0 .29 3.58 6.14 6.14 0 0 0 5.26 4.25c2.45.06 5 0 7.53 0h18.47l.07.11 5 6.92 2.46 3.4 1.21 1.69a2.69 2.69 0 0 0 .62.7 1.46 1.46 0 0 0 .83.26 1.55 1.55 0 0 0 1.42-.91l1-1.76 2-3.48c1.34-2.3 2.65-4.57 4-6.79l.07-.12h25.13a6.3 6.3 0 0 0 4.18-1.43 6.2 6.2 0 0 0 2.13-3.73 13.06 13.06 0 0 0 .1-2.18v-2c0-11.6 0-21.81.05-30.28v-15.94a10.7 10.7 0 0 0-.1-2 6.47 6.47 0 0 0-.55-1.71 6.4 6.4 0 0 0-3.87-3.27 9.72 9.72 0 0 0-1.58-.28z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("circle", { cx: -4.688, cy: 206.054, r: 17.12, transform: "rotate(-45)", style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { d: "M146.958 151.29c0 2-1.51 3.74-4.81 3.74a7.94 7.94 0 0 1-4.42-1.24l1-2a5.67 5.67 0 0 0 3.32 1c1.34 0 2.17-.55 2.17-1.49s-.65-1.43-2.17-1.43h-1.23v-1.77l2.39-2.7h-4.86v-2.15h8.12v1.72l-2.62 3c2.05.32 3.11 1.61 3.11 3.32z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M404.248 71.97h-59.13a7.28 7.28 0 0 0-7.27 7.27v42.13a7.28 7.28 0 0 0 7.27 7.27h21.11c2.28 3.19 6 8.28 8 11.06a1.57 1.57 0 0 0 2.64-.13l6.35-10.93h21a7.28 7.28 0 0 0 7.27-7.27v-42.1a7.28 7.28 0 0 0-7.24-7.3z", style: {
  fill: "#ebebeb"
} }), /* @__PURE__ */ React.createElement("path", { d: "M365.977 98.687h-4.548c-.753 0-1.364.61-1.364 1.364v13.642c0 .754.611 1.364 1.364 1.364h4.548c.753 0 1.364-.61 1.364-1.364v-13.642c0-.753-.61-1.364-1.364-1.364zm-2.274 14.097a1.364 1.364 0 1 1 0-2.729 1.364 1.364 0 0 1 0 2.729zm18.19-22.2c0 2.411-1.476 3.763-1.892 5.374h5.782c1.899 0 3.377 1.578 3.386 3.303.005 1.02-.43 2.117-1.105 2.796l-.006.006c.559 1.327.468 3.186-.53 4.518.494 1.471-.003 3.28-.93 4.249.244 1 .127 1.852-.35 2.537-1.16 1.667-4.036 1.69-6.467 1.69h-.162c-2.745 0-4.991-1-6.796-1.803-.907-.404-2.093-.903-2.993-.92a.682.682 0 0 1-.67-.682V99.501c0-.182.073-.356.203-.484 2.251-2.225 3.22-4.581 5.065-6.43.842-.843 1.148-2.117 1.444-3.348.252-1.052.781-3.285 1.928-3.285 1.365 0 4.093.455 4.093 4.63z", style: {
  fill: "#fff",
  strokeWidth: 0.0568428
} }), /* @__PURE__ */ React.createElement("path", { d: "M404.248 71.97h.41a6.17 6.17 0 0 1 1.21.15 7.18 7.18 0 0 1 4 2.41 7.35 7.35 0 0 1 1.54 3.1 21.25 21.25 0 0 1 .2 4.14v10.19c0 7.62 0 16.8.05 27.21v2a7.76 7.76 0 0 1-.23 2 7.48 7.48 0 0 1-5.84 5.56 11.26 11.26 0 0 1-2.16.14h-20.3l.21-.12q-1.72 3-3.53 6.09c-.6 1-1.2 2.08-1.81 3.12l-.91 1.58a1.89 1.89 0 0 1-1.7 1.06 1.86 1.86 0 0 1-1-.32 2.75 2.75 0 0 1-.67-.75l-1.09-1.51-2.2-3-4.47-6.2.21.11h-21.54a7.59 7.59 0 0 1-5.75-3.29 7.68 7.68 0 0 1-1.2-3.14 27.93 27.93 0 0 1-.09-3.28V79.73a8.47 8.47 0 0 1 .39-2.82 7.45 7.45 0 0 1 6.14-5 26.24 26.24 0 0 1 2.64-.07h19.29l27.93.08h10.27-10.27l-27.93.08h-19.29a22.28 22.28 0 0 0-2.59.07 6.72 6.72 0 0 0-2.44.79 7.06 7.06 0 0 0-3.31 4 7.67 7.67 0 0 0-.37 2.67v39.49a28.43 28.43 0 0 0 .08 3.21 7 7 0 0 0 6.47 6h21.69l.08.11 4.46 6.2 2.2 3 1.09 1.51a2.32 2.32 0 0 0 .55.63 1.26 1.26 0 0 0 .73.23 1.36 1.36 0 0 0 1.25-.8l.92-1.58c.6-1.05 1.21-2.09 1.81-3.12l3.54-6.08.07-.12h20.44a10.21 10.21 0 0 0 2.08-.12 7.06 7.06 0 0 0 5.51-5.22 16.85 16.85 0 0 0 .22-3.9c0-10.41 0-19.59.06-27.21V81.57a21.45 21.45 0 0 0-.16-4.09 7.2 7.2 0 0 0-5.38-5.48 13.82 13.82 0 0 0-1.51-.03z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 408.368, cy: 73.4, r: 19.48, style: {
  fill: "#1b9ff1"
} }), /* @__PURE__ */ React.createElement("path", { d: "M404.888 68.18v10.23h-2.37v-8.33h-2v-1.9zM406.668 73.3c0-3.38 1.87-5.29 4.38-5.29s4.38 1.91 4.38 5.29-1.85 5.28-4.38 5.28-4.38-1.91-4.38-5.28zm6.38 0c0-2.33-.82-3.29-2-3.29-1.18 0-2 1-2 3.29s.82 3.28 2 3.28c1.18 0 2-.96 2-3.28z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M103.87 157.421h4.816c.8 0 1.444.644 1.444 1.445v10.11h5.278c1.071 0 1.607 1.294.849 2.053l-9.154 9.16a1.166 1.166 0 0 1-1.643 0l-9.166-9.16c-.759-.759-.223-2.053.848-2.053h5.285v-10.11c0-.8.643-1.445 1.444-1.445zm17.815 22.63v6.74c0 .8-.644 1.444-1.444 1.444H92.316c-.8 0-1.445-.644-1.445-1.444v-6.74c0-.801.644-1.445 1.445-1.445h8.829l2.949 2.949a3.084 3.084 0 0 0 4.369 0l2.949-2.95h8.829c.8 0 1.444.645 1.444 1.445zm-7.462 5.295c0-.662-.542-1.203-1.204-1.203-.662 0-1.204.541-1.204 1.203s.542 1.204 1.204 1.204c.662 0 1.204-.542 1.204-1.204zm3.851 0c0-.662-.541-1.203-1.203-1.203s-1.204.541-1.204 1.203.542 1.204 1.204 1.204c.662 0 1.203-.542 1.203-1.204z", style: {
  fill: "#fff",
  strokeWidth: 0.0601839
} }), /* @__PURE__ */ React.createElement("path", { d: "M132.773 30.725c-8.15 0-14.755 5.366-14.755 11.989 0 2.859 1.233 5.475 3.285 7.533-.72 2.905-3.13 5.493-3.158 5.522a.458.458 0 0 0-.087.501.451.451 0 0 0 .42.277c3.822 0 6.687-1.833 8.105-2.963a17.579 17.579 0 0 0 6.19 1.119c8.15 0 14.756-5.367 14.756-11.99 0-6.622-6.606-11.988-14.756-11.988z", style: {
  fill: "#fff",
  strokeWidth: 0.0576387
} }), /* @__PURE__ */ React.createElement("path", { d: "m296.435 26.153-8.598-3.583a2.156 2.156 0 0 0-1.653 0l-8.598 3.583a2.147 2.147 0 0 0-1.326 1.984c0 8.89 5.128 15.034 9.92 17.031.528.22 1.124.22 1.652 0 3.838-1.598 9.925-7.12 9.925-17.031a2.15 2.15 0 0 0-1.322-1.984zm-2.113 5.114-8.24 8.24a.719.719 0 0 1-1.013 0l-4.657-4.657a.719.719 0 0 1 0-1.012l1.012-1.012a.719.719 0 0 1 1.012 0l3.14 3.14 6.721-6.723a.719.719 0 0 1 1.012 0l1.013 1.012a.71.71 0 0 1 0 1.012z", style: {
  fill: "#fff",
  strokeWidth: 0.0447845
} }), /* @__PURE__ */ React.createElement("path", { d: "M45.1 96.704c-2.566.174-6.226 2.71-8.078 3.542-1.441.648-2.684 1.208-3.769 1.208-1.12 0-1.288-.802-1.055-2.57.055-.397.58-3.923-2.115-3.77-1.243.075-3.184 1.229-8.394 6.24l2.055-5.135c1.506-3.759-2.635-7.503-6.423-5.091l-2.72 1.827a.796.796 0 0 0-.242 1.095l.852 1.337a.788.788 0 0 0 1.094.243l2.873-1.927c.911-.58 2.016.357 1.62 1.342l-4.864 12.164c-.337.837.134 2.174 1.47 2.174.412 0 .818-.158 1.12-.465 2.09-2.09 7.662-7.464 10.46-9.698-.109 1.412-.104 2.917 1.02 4.15.758.833 1.848 1.254 3.244 1.254 1.763 0 3.368-.723 5.067-1.486 1.634-.733 4.903-3.1 6.854-3.259.421-.035.753-.361.753-.782v-1.59a.786.786 0 0 0-.822-.803z", style: {
  fill: "#fff",
  strokeWidth: 0.0495263
} }));
export default SvgIllNotificationProfiles;
