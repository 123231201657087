import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Variant } from '@convoflo/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Intent, Size } from '@convoflo/ui';
import ErrorLayout from '@ui/Layout/Error';
import AppContext from '@contexts/AppContext';

const Page = () => {
	const { formatMessage } = useIntl();
	const { setPageTitle: setTitle } = useContext(AppContext);
	const history = useHistory();
	const { locale } = useIntl();

	const goBack = () => {
		if (history.length > 1) {
			history.goBack();
		} else {
			history.replace('/');
		}
	};

	const reportProblem = () => {
		const link = `${import.meta.env.VITE_SITE_URL}/${locale}/contact`;
		window.open(link, '_blank') || window.location.replace(link);
	};

	useEffect(() => {
		setTitle(formatMessage({ id: 'errors.page_not_found' }));
	}, [setTitle, formatMessage]);

	return (
		<ErrorLayout>
			<h1 className="mb-2 text-xl font-semibold text-black">
				<FormattedMessage id="errors.page_not_found" />
			</h1>
			<p className="my-2 leading-tight text-gray-600">
				<FormattedMessage id="errors.page_not_found_description" />
			</p>
			<div className="flex items-center mt-6 space-x-4">
				<Button intent={Intent.primary} variant={Variant.primary} size={Size.sm} onClick={goBack} shadow>
					{history.length > 1 ? <FormattedMessage id="errors.page_not_found_go_back" /> : <FormattedMessage id="errors.page_not_found_home" />}
				</Button>
				<Button variant={Variant.secondary} size={Size.sm} onClick={reportProblem} shadow>
					<FontAwesomeIcon icon="exclamation-triangle" className="mr-2" size="sm" />
					<FormattedMessage id="errors.page_not_found_problem" />
				</Button>
			</div>
		</ErrorLayout>
	);
};

export default Page;
