import untypedCanadianProvinces from '@assets/canada_provinces.json';
import untypedCountries from '@assets/countries.json';
import untypedUSStates from '@assets/us_states.json';
import { useBillingInformation } from '@components/Account';
import { useCart, useStore } from '@components/Checkout';
import AppContext from '@contexts/AppContext';
import { Button, Checkbox, HelperText, Input, InputBlock, InputClassNames, Intent, Label, Row, Select, Size, Toggle, ValidationField, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { useStripePaymentMethodCreateMutation } from '@state/queries/stripe';
import { useSubscriptionCheckPromoCodeMutation, useSubscriptionSubcribeMutation } from '@state/queries/subscriptions';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import Card from '@ui/Card';
import { formatDate } from '@utils/DateUtils';
import { filesize } from '@utils/StringUtils';
import { type FC, type FormEvent, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';
import { cardElementStyles, defaultCountry, defaultCurrency, extraStorageBlock } from '../../constants';
import { Header } from './Header';

const countries: Record<string, string> = untypedCountries;
const canadianProvinces: Record<string, string> = untypedCanadianProvinces;
const usStates: Record<string, string> = untypedUSStates;

export const BillingInformationStep: FC = () => {
	const { account } = useAccount();
	const history = useHistory();
	const { locale, formatMessage } = useIntl();
	const { validation } = useClient();
	const stripe = useStripe();
	const elements = useElements();
	const { setPageTitle: setTitle } = useContext(AppContext);
	const { cart, getItemById, setFrequency, applyPromoCode } = useCart();
	const { store } = useStore();
	const [billingInformation, saveBillingInformation] = useBillingInformation();

	const [name, setName] = useState(billingInformation?.BillingName ?? account?.Name ?? '');
	const [country, setCountry] = useState(billingInformation?.BillingCountry ?? account?.Country ?? defaultCountry);
	const [region, setRegion] = useState(billingInformation?.BillingRegion ?? null);
	const [agreed, setAgreed] = useState(false);
	const [showPromoCodeInput, setShowPromoCodeInput] = useState(!!cart?.coupon);
	const [promoCode, setPromoCode] = useState(cart?.coupon ?? '');

	const [cardFilledIn, setCardFilledIn] = useState(false);

	// Mutations
	const { mutateAsync: createPaymentToken } = useStripePaymentMethodCreateMutation();
	const { mutateAsync: subscribe, isLoading: isSubscribing } = useSubscriptionSubcribeMutation();
	const { mutateAsync: checkPromoCodeMutation, isLoading: isCheckingPromoCode, isSuccess: isPromoCodeOk, isError: isPromoCodeError } = useSubscriptionCheckPromoCodeMutation();

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();

		if (elements === null || !billingInformation || !cart || !agreed || !stripe) {
			return;
		}

		if (name && country && region) {
			saveBillingInformation({ name, country, region });
		}

		try {
			const token = await createPaymentToken({ stripe, elements, billingInformation });
			await subscribe({ paymentMethodToken: token, billingInformation, cart, agreement: agreed });
			toast.success(<FormattedMessage id="plans.subscribed" />);
			history.push('/');
		} catch {
			// TODO: Show error message to user
		}
	};

	const checkPromoCode = async (code: string) => {
		try {
			await checkPromoCodeMutation({ code, frequency: cart?.frequency });
			applyPromoCode(code);
		} catch {
			// ignore
		}
	};

	useEffect(() => {
		setTitle(formatMessage({ id: 'plans.billing_information' }));
	}, [setTitle, formatMessage]);

	useEffect(() => {
		saveBillingInformation({ country, region });
	}, [country, region, saveBillingInformation]);

	useEffect(() => {
		setPromoCode(cart?.coupon ?? '');
	}, [cart?.coupon]);

	const onCardChange = ({ complete }: StripeCardElementChangeEvent) => {
		setCardFilledIn(complete);
	};

	if (!store || !cart) {
		return <Redirect to="/account/checkout" />;
	}

	if (!getItemById('plan')) {
		return <Redirect to="/account/checkout" />;
	}

	const selectedPlan = store.plans.find(plan => plan.Key === getItemById('plan')!.options.key)!;

	return (
		<form onSubmit={onSubmit} className="max-w-2xl">
			<Header>
				<FormattedMessage id="plans.billing_information" />
			</Header>
			<Card className="mb-12">
				<Row>
					<Label htmlFor="name">
						<FormattedMessage id="plans.billing_name" />
					</Label>
					<ValidationField fieldName="name" validation={validation}>
						<InputBlock id="name" value={name} onChange={e => setName(e.target.value)} />
					</ValidationField>
					<HelperText>
						<FormattedMessage id="plans.billing_name_footnote" />
					</HelperText>
				</Row>

				<Row>
					<Label htmlFor="country">
						<FormattedMessage id="plans.billing_country" />
					</Label>
					<ValidationField fieldName="country" validation={validation}>
						<Select className="w-full" id="country" value={country} onChange={e => setCountry(e.target.value)}>
							{Object.keys(countries).map(c => (
								<option key={c} value={c}>
									{countries[c]}
								</option>
							))}
						</Select>
					</ValidationField>
				</Row>

				{['CA', 'US'].includes(country) && (
					<Row>
						<Label htmlFor="region">
							<FormattedMessage id="billing-address.region" values={{ country }} />
						</Label>
						{country === 'CA' && (
							<ValidationField fieldName="region" validation={validation}>
								<Select className="w-full" id="region" value={region || ''} onChange={e => setRegion(e.target.value)}>
									<FormattedMessage id="plans.select_province">{msg => <option>{msg}</option>}</FormattedMessage>
									{Object.keys(canadianProvinces).map(p => (
										<option key={p} value={p}>
											{canadianProvinces[p]}
										</option>
									))}
								</Select>
							</ValidationField>
						)}
						{country === 'US' && (
							<ValidationField fieldName="region" validation={validation}>
								<Select className="w-full" id="region" value={region || ''} onChange={e => setRegion(e.target.value)}>
									<FormattedMessage id="plans.select_state">{msg => <option>{msg}</option>}</FormattedMessage>
									{Object.keys(usStates).map(s => (
										<option key={s} value={s}>
											{usStates[s]}
										</option>
									))}
								</Select>
							</ValidationField>
						)}
					</Row>
				)}

				<Row>
					<Label>
						<FormattedMessage id="plans.credit_card" />
					</Label>

					<ValidationField fieldName="card" validation={validation}>
						<div className={`${InputClassNames} px-3 py-2 text-base`}>
							<CardElement options={{ hidePostalCode: true, style: cardElementStyles }} onChange={onCardChange} />
						</div>
					</ValidationField>
				</Row>
			</Card>

			<header className="mb-6 space-y-3">
				<h1 className="font-serif text-4xl font-bold">
					<FormattedMessage id="plans.summary" />
				</h1>
			</header>

			<Card className="pb-0 mt-6">
				<div className="flex justify-center mb-6">
					<label className="inline-flex items-center justify-center gap-2 font-semibold text-green-600">
						<Toggle onChange={() => setFrequency(cart.frequency === 'year' ? 'month' : 'year')} checked={cart.frequency === 'year'} disabled={!!getItemById('esigns')} />
						<FormattedMessage id="plans.i_prefer_yearly" values={{ percent: <FormattedNumber value={0.1} style="percent" />, active_promo_code: promoCode ? 1 : 0 }} />
					</label>
				</div>
				{showPromoCodeInput ? (
					<div className="flex items-baseline justify-between px-6 py-2 -mx-6 bg-gray-100">
						<h3 className="flex-1 font-bold">
							<FormattedMessage id="plans.promo_code" />
						</h3>
						<div>
							<div className="flex items-stretch">
								<Input
									size={Size.sm}
									type="text"
									disabled={isPromoCodeOk && !!cart.coupon}
									onKeyDown={e => (e.code === 'Enter' ? checkPromoCode(promoCode) : void 0)}
									value={promoCode}
									onChange={e => setPromoCode(e.target.value)}
									className="flex-1 flex-grow border-r-0 rounded-r-none max-w-36"
									icon={isPromoCodeOk && cart.coupon ? 'check' : isPromoCodeError ? 'exclamation-circle' : undefined}
									iconColor={isPromoCodeOk ? 'text-green-500' : 'text-red-600'}
								/>
								{cart.coupon ? (
									<Button size={Size.sm} className="flex-shrink rounded-l-none" variant={Variant.danger} intent={Intent.secondary} type="button" onClick={() => applyPromoCode('')}>
										<FormattedMessage id="remove" />
									</Button>
								) : (
									<Button
										size={Size.sm}
										className="flex-shrink rounded-l-none"
										variant={Variant.primary}
										loading={isCheckingPromoCode}
										type="button"
										onClick={() => checkPromoCode(promoCode)}>
										<FormattedMessage id="apply" />
									</Button>
								)}
							</div>
						</div>
					</div>
				) : (
					<div className="flex items-center justify-end px-6 py-2 -mx-6 bg-gray-100">
						<p>
							<button type="button" className="text-xs underline text-theme-primary" onClick={() => setShowPromoCodeInput(true)}>
								<FormattedMessage id="plans.have_valid_promo_code" />
							</button>
						</p>
					</div>
				)}
				<div className="flex flex-col gap-4 py-5">
					{cart.items.map(item => (
						<div className="flex items-center justify-between gap-4" key={item.id}>
							<span className="font-medium">
								{item.id === 'plan' && <FormattedMessage id="plans.plan" values={{ plan: selectedPlan.Name }} />}
								{item.id === 'embed' && <FormattedMessage id="plans.embed_title" />}
								{item.id === 'payments' && <FormattedMessage id="plans.payments_title" />}
								{item.id === 'users' && <FormattedMessage id="plans.plan_users" values={{ n: item.quantity, strong: msg => <span>{msg}</span> }} />}
								{item.id === 'storage' && (
									<FormattedMessage
										id="plans.plan_storage"
										values={{
											n: filesize(extraStorageBlock * item.quantity, locale),
											strong: msg => <span>{msg}</span>
										}}
									/>
								)}
								{item.id === 'esigns' && <FormattedMessage id="plans.num_signatures" values={{ n: item.options.limit }} />}
								{item.id === 'support_plan' && <FormattedMessage id={`plans.support-${item.options!.level}`} />}
								{item.id === 'white_labeling' && <FormattedMessage id={`plans.white_labeling-${item.options!.level}`} />}
							</span>
							<div className="flex flex-1 h-px border-b border-gray-300 border-dotted" />
							<div>
								<FormattedNumber value={item.total} style="currency" currency={defaultCurrency} />
							</div>
						</div>
					))}

					<div className="flex items-baseline justify-between px-6 py-2 -mx-6 bg-gray-100">
						<h3 className="flex-1 font-bold">
							<FormattedMessage id="plans.subtotal" />
						</h3>
						<span>
							<FormattedNumber value={cart.subtotal} style="currency" currency={defaultCurrency} />
						</span>
					</div>

					{cart.discounts.map(discount => (
						<div className="flex items-center justify-between gap-4">
							<h3 className="font-medium">
								{discount.title}{' '}
								<span className="text-gray-500">
									(<FormattedNumber value={discount.value / 100} style="percent" minimumFractionDigits={0} maximumFractionDigits={2} />)
								</span>
							</h3>
							<div className="flex flex-1 h-px border-b border-gray-300 border-dotted" />
							<span>
								<FormattedNumber value={discount.amount} style="currency" currency={defaultCurrency} />
							</span>
						</div>
					))}

					{cart.discounts.length > 0 && (
						<div className="flex items-baseline justify-between px-6 py-2 -mx-6 bg-gray-100">
							<h3 className="flex-1 font-bold">
								<FormattedMessage id="plans.total_excluding_taxes" />
							</h3>
							<span>
								<FormattedNumber value={cart.total_no_taxes} style="currency" currency={defaultCurrency} />
							</span>
						</div>
					)}

					{cart.tax_amounts.map(taxAmount => (
						<div className="flex items-center justify-between gap-4">
							<h3 className="font-medium">
								<FormattedMessage id={`plans.tax_${taxAmount.display_name}`} />{' '}
								<span className="text-gray-500">
									(<FormattedNumber value={taxAmount.percentage / 100} style="percent" minimumFractionDigits={0} maximumFractionDigits={3} />)
								</span>
							</h3>
							<div className="flex flex-1 h-px border-b border-gray-300 border-dotted" />
							<span>
								<FormattedNumber value={taxAmount.amount} style="currency" currency={defaultCurrency} />
							</span>
						</div>
					))}

					<div className="flex items-baseline justify-between px-6 py-2 -mx-6 bg-gray-100">
						<h3 className="flex-1 text-xl font-bold">
							<FormattedMessage id="plans.total" />
						</h3>
						<span className="text-xl font-bold">
							<FormattedNumber value={cart.total} style="currency" currency={defaultCurrency} />
						</span>
					</div>
				</div>

				<div className="flex items-center col-span-2 gap-2 p-3 -mx-6 border-t border-blue-100 bg-blue-50">
					<FontAwesomeIcon icon="info-circle" className="text-blue-600" />
					<p className="flex-1 text-sm text-blue-600">
						<FormattedMessage
							id="plans.summary_billed"
							values={{
								price: (
									<span className="font-semibold">
										<FormattedNumber value={cart.total} currency={defaultCurrency} style="currency" minimumFractionDigits={2} />
									</span>
								),
								frequency: cart.frequency === 'year' ? 'year' : 'month',
								date: cart.frequency === 'year' ? formatDate(null, locale, locale === 'fr' ? 'd MMMM' : 'MMMM do') : formatDate(null, locale, 'do')
							}}
						/>
					</p>
				</div>
			</Card>

			<Card className="mt-6">
				<div className="flex items-center">
					<Checkbox checked={agreed} onChange={e => setAgreed(e.target.checked)}>
						<span>
							<FormattedMessage
								id="plans.i_agree"
								values={{
									link: msg => (
										<a href={formatMessage({ id: 'plans.i_agree_link' })} target="_blank" className="underline text-theme-primary" rel="noreferrer">
											{msg}
										</a>
									)
								}}
							/>
						</span>
					</Checkbox>
				</div>
			</Card>

			<div className="flex items-center justify-between my-12">
				<Button type="button" variant={Variant.light} intent={Intent.tertiary} iconStart="long-arrow-alt-left" animateIcon onClick={() => history.push('/account/checkout/support')}>
					<FormattedMessage id="plans.support_plans" />
				</Button>
				<Button type="submit" variant={Variant.success} size={Size.lg} loading={isSubscribing} iconEnd="check" disabled={!cardFilledIn || name === '' || !agreed || isSubscribing} animateIcon>
					<FormattedMessage id="plans.purchase" />
				</Button>
			</div>
		</form>
	);
};
