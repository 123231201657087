import type { CannedMessage, CannedMessageCategory } from '@components/CannedMessages';
import { Button, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Select, Variant } from '@convoflo/ui';
import useClient from '@hooks/useClient';
import { type FC, type FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import type { WretchError, WretchResponse } from 'wretch';

type DialogDeleteCannedMessageCategoryProps = Omit<ModalProps, 'isOpen'> & {
	category: Partial<CannedMessageCategory>;
};

export const DialogDeleteCannedMessageCategory: FC<DialogDeleteCannedMessageCategoryProps> = ({ category, ...modalProps }) => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	const [isOpen, setIsOpen] = useState(true);
	const [includeMessages, setIncludesMessages] = useState(false);

	const { mutate: _delete, isLoading: isDeleting } = useMutation<WretchResponse, WretchError, boolean, CannedMessage[]>(
		async includeMessages => await client.url(`account/canned-categories/${category.Id}`).json({ deleteMsg: includeMessages }).delete().res(),
		{
			onError: (_error, _variables, previousMessages) => {
				queryClient.setQueryData(['canned-messages'], previousMessages);
			},
			onMutate: async includeMessages => {
				await queryClient.cancelQueries(['canned-messages']);

				const previousMessages = queryClient.getQueryData<CannedMessage[]>(['canned-messages']);

				if (previousMessages === undefined) {
					return undefined;
				}

				if (includeMessages) {
					queryClient.setQueryData(
						['canned-messages'],
						previousMessages.filter(_message => _message.category!.Id !== category.Id)
					);
				} else {
					queryClient.setQueryData(
						['canned-messages'],
						previousMessages.map(_message => ({ ..._message, category: null }))
					);
				}

				return previousMessages;
			},
			onSuccess: () => {
				toast.success(<FormattedMessage id="canned-categories-crud.canned_category_deleted" />);
			}
		}
	);

	const onSubmitDelete = (e: FormEvent) => {
		e.preventDefault();
		_delete(includeMessages);
	};

	return (
		<Modal isOpen={isOpen} onSubmit={onSubmitDelete} {...modalProps}>
			<ModalHeaderOnlyTitle>{category.Name}</ModalHeaderOnlyTitle>
			<ModalBody>
				<p>
					<FormattedMessage id="dialog-delete.confirmation" />
				</p>
				{category.messages && category.messages?.length > 0 && (
					<Select className="w-full mt-6" onChange={e => setIncludesMessages(e.target.value === 'true')}>
						<FormattedMessage id="canned-categories-crud.delete_category_messages">{msg => <option value="true">{msg}</option>}</FormattedMessage>
						<FormattedMessage id="canned-categories-crud.delete_only_category">{msg => <option value="false">{msg}</option>}</FormattedMessage>
					</Select>
				)}
			</ModalBody>
			<ModalFooter>
				<Button disabled={isDeleting} type="submit" className="mr-2" variant={Variant.danger}>
					{isDeleting ? <FormattedMessage id="deleting" /> : <FormattedMessage id="delete" />}
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
