import { DateTimeDisplay } from '@components/DateTime';
import { DialogPreview, FileLabel, FileableContextMenu, FileableSyncStatus } from '@components/FileManager';
import { LabelSelector } from '@components/Labels';
import AppContext from '@contexts/AppContext';
import { Alert, Badge, Intent, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import { usePrevious } from '@hooks/usePrevious';
import File from '@models/File';
import Folder from '@models/Folder';
import { type Fileable, Permissions } from '@types';
import Breadcrumbs from '@ui/Breadcrumbs';
import UserAvatar from '@ui/UserAvatar';
import { filesize } from '@utils/StringUtils';
import { type FC, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@ui/Tooltip';
import { FileSignRequestStatus } from './FileManager/FileSignRequestStatus';

type FileableHeaderProps = {
	fileable: Fileable;
};

export const FileableHeader: FC<FileableHeaderProps> = ({ fileable }) => {
	// Helpers
	const history = useHistory();
	const { locale } = useIntl();
	const { account } = useAccount();
	const { setPageHeader } = useContext(AppContext);

	// States
	const [dialogPreviewOpened, setDialogPreviewOpened] = useState(false);

	const onBreadcrumbNavigate = (fileable: Fileable | null) => {
		history.push({ pathname: fileable !== null ? fileable.getUrl('files') : '/files', state: { view: fileable } });
	};

	const ancestors = useMemo(() => (fileable.ancestors ? [...fileable.ancestors.reverse()] : undefined), [fileable]);
	const previousAncestors = usePrevious(ancestors);

	useEffect(() => {
		setPageHeader(<Breadcrumbs items={ancestors ?? previousAncestors ?? []} onNavigate={onBreadcrumbNavigate} />);
	}, [ancestors, previousAncestors, setPageHeader]);

	return (
		<>
			{fileable instanceof File && fileable.isInfected() && (
				<Alert variant={Variant.danger} className="mb-4" icon="bug">
					<p>
						<FormattedMessage id="file-manager.infected" />
					</p>
				</Alert>
			)}

			<div className="flex mb-8 space-x-2">
				<div>
					{fileable instanceof File && fileable.current.Thumbnail !== null && !fileable.isInfected() ? (
						<div className="p-1 bg-white border rounded">
							<button onClick={() => setDialogPreviewOpened(true)} className="flex">
								<img alt={fileable.getName()} src={fileable.current.Thumbnail} className="object-cover w-20 h-20 lg:w-24 lg:h-24" />
							</button>
						</div>
					) : (
						fileable.icon('mt-3 text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl')
					)}
				</div>
				<div className="flex-1">
					<FileableContextMenu placement="bottom-start" fileable={fileable} disabled={!account}>
						<button className="mt-2 text-left focus:outline-none group">
							<div className="flex">
								<div className="flex-1 space-y-1">
									<h1 className="text-base font-semibold leading-tight sm:text-lg md:text-xl lg:text-2xl">
										{fileable.getName()}
										{fileable.syncs && (
											<span className="ml-2 align-top">
												{fileable.syncs.map(sync => (
													<FileableSyncStatus key={sync.Id} fileable={fileable} sync={sync} />
												))}
											</span>
										)}
										{account && (
											<FontAwesomeIcon icon="caret-down" size="xs" className="ml-2 text-gray-400 transition-opacity duration-200 ease-in-out opacity-0 group-hover:opacity-100" />
										)}
									</h1>
									{fileable instanceof File && fileable.current.Size > 0 && (
										<p className="text-xs text-gray-600">
											{filesize(fileable.current.Size, locale)} &ndash;{' '}
											<DateTimeDisplay className="text-xs leading-tight text-gray-500" value={fileable.current.created_at} defaultFormat="relative" />
										</p>
									)}
									{fileable instanceof Folder && fileable.pivot?.Permissions === 'readonly' && (
										<p className="text-xs text-gray-600">
											<FormattedMessage
												id="file-manager.secure_space_of"
												values={{
													creator: fileable.creator.Name,
													organization: fileable.creator.business?.Name,
													b: msg => <span className="font-medium text-black">{msg}</span>
												}}
											/>
										</p>
									)}
								</div>
							</div>
						</button>
					</FileableContextMenu>
					<div className="flex items-center gap-0.5 flex-wrap order-none lg:order-last mt-2" onClick={e => e.stopPropagation()}>
						{fileable instanceof File && fileable.signer?.map(signRequest => <FileSignRequestStatus key={signRequest.Id} file={fileable} signRequest={signRequest} />)}
						{account?.hasFullAccess() && (
							<>
								{/* Sometimes it's not included in the response, we'll fix that */}
								{(fileable.properties ?? []).map(prop => (
									<LabelSelector key={prop.Id} label={prop} model={fileable} />
								))}
								<LabelSelector model={fileable} />
							</>
						)}
					</div>
				</div>
				{account?.hasFullAccess() && fileable.Scope === 'internal' && fileable.collaborators?.length > 0 && (
					<button className="block text-right focus:outline-none" onClick={() => fileable.pivot?.Permissions === Permissions.ReadWrite && history.push('?open=permissions')}>
						<h4 className="mb-2 font-medium text-right text-gray-600">
							<FormattedMessage id="members" />
						</h4>
						<div className="flex justify-end -space-x-2">
							{fileable.collaborators.map(collaborator => (
								<UserAvatar key={collaborator.ID} size={Size.sm} user={collaborator} />
							))}
							{fileable.members_count > fileable.collaborators.length && (
								<Badge intent={Intent.tertiary} className="h-8 font-bold leading-8 tracking-tighter bg-gray-400 ring-white ring-2">
									+{fileable.members_count - fileable.collaborators.length}
								</Badge>
							)}
						</div>
					</button>
				)}
			</div>

			{dialogPreviewOpened && fileable instanceof File && <DialogPreview file={fileable} onAfterClose={() => setDialogPreviewOpened(false)} showNavigation={false} />}
		</>
	);
};
