import classNames from 'classnames';
import type { FC, HTMLAttributes } from 'react';

type SidebarGroupProps = HTMLAttributes<HTMLElement>;

export const SidebarGroup: FC<SidebarGroupProps> = ({ className, children, ...divProps }) => {
	return (
		<div className={classNames('px-4 mt-6 mb-2 first:mt-0', className)} {...divProps}>
			<h5 className="text-base font-medium text-gray-400 md:text-xs">{children}</h5>
		</div>
	);
};
