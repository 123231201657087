import type { Comment } from '@components/Message';
import { type ResourceLengthAwarePaginatorType } from '@components/Pagination';
import set from 'lodash.set';
import { type InfiniteData } from 'react-query';

export const addMessage = (message: Comment, messages: InfiniteData<ResourceLengthAwarePaginatorType<Comment>>, parentId?: number) => {
	// Add to root
	if (!parentId) {
		let newSet = set(messages, 'pages[0].data', [message].concat(messages.pages[0].data));
		newSet = set(messages, 'pages[0].meta.total', messages.pages[0].meta.total + 1);

		return newSet;
	}

	// Add as a reply
	return set(
		messages,
		'pages',
		messages.pages.map(group => ({
			...group,
			data: group.data.map(_message => {
				if (_message.ID === parentId) {
					_message.replies = _message.replies.concat([message]);
				}

				return _message;
			})
		}))
	);
};

export const replaceMessage = (message: Comment, messages: InfiniteData<ResourceLengthAwarePaginatorType<Comment>>) => {
	return set(
		messages,
		'pages',
		messages.pages.map(group => ({
			...group,
			data: group.data.map(_message =>
				_message.ID === message.ID || _message.ID === 0
					? message
					: {
							..._message,
							replies: _message.replies.map(reply => (reply.ID === message.ID || reply.ID === 0 ? message : reply))
					  }
			)
		}))
	);
};

export const removeMessage = (message: Comment, messages: InfiniteData<ResourceLengthAwarePaginatorType<Comment>>) => {
	return set(
		messages,
		'pages',
		messages.pages.map(group => ({
			...group,
			data: group.data
				.filter(_message => _message.ID !== message.ID)
				.map(_message => ({
					..._message,
					replies: _message.replies.filter(reply => reply.ID !== message.ID)
				}))
		}))
	);
};
