import AppContext from '@contexts/AppContext';
import { Button, HelperText, Input, Label, Row, Select, Size, ValidationField, Variant } from '@convoflo/ui';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { useOrganizationUpdateMutation } from '@state/queries/organization';
import { type FC, type FormEvent, type PropsWithChildren, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';

export const OnboardingOrganizationPage: FC = () => {
	const { account } = useAccount();
	const { formatMessage } = useIntl();
	const { validation } = useClient();
	const history = useHistory();
	const { setPageTitle: setTitle } = useContext(AppContext);

	const [name, setName] = useState(account?.business.Name ?? '');
	const [type, setType] = useState(account?.business.Profile.Type ?? '');
	const [employeeCount, setEmployeeCount] = useState(account?.business.Profile.EmployeeCount ?? '');

	const { mutateAsync: save, isLoading: isSaving } = useOrganizationUpdateMutation();

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		try {
			await save({ name, type, employeeCount });
			history.replace('/onboarding/verification');
		} catch {
			// TODO: Show error to user
		}
	};

	useEffect(() => {
		setTitle(formatMessage({ id: 'welcome.page_title' }));
	}, [setTitle, formatMessage]);

	if (account?.business.Name || !account?.isAdmin()) {
		return <Redirect to="/onboarding/verification" />;
	}

	const disabled = !name || !type || !employeeCount;

	return (
		<form onSubmit={onSubmit}>
			<header className="mb-8 space-y-2">
				<h1 className="text-3xl font-bold md:text-4xl">
					<FormattedMessage id="onboarding.organization.title" />
				</h1>
				<p className="text-gray-400">
					<FormattedMessage id="onboarding.organization.subtitle" />
				</p>
			</header>
			<Row>
				<Label htmlFor="name">
					<FormattedMessage id="signup.organization" />
				</Label>
				<ValidationField fieldName="name" validation={validation}>
					<Input block size={Size.lg} id="name" icon="briefcase" value={name} onChange={e => setName(e.target.value)} type="text" />
				</ValidationField>
				<HelperText>
					<FormattedMessage id="signup.organization_helper" />
				</HelperText>
			</Row>
			<Row>
				<Label htmlFor="type">
					<FormattedMessage id="onboarding.organization.type_label" />
				</Label>
				<ValidationField fieldName="name" validation={validation}>
					<div className="grid grid-cols-1 gap-2 md:grid-cols-2">
						<OrganizationType type="accounting" selected={type === 'accounting'} onSelected={setType} icon={['fad', 'calculator']} iconClassName="text-green-600">
							<FormattedMessage id="onboarding.organization.types.accounting" />
						</OrganizationType>
						<OrganizationType type="notary" selected={type === 'notary'} onSelected={setType} icon={['fad', 'user-tie']} iconClassName="text-orange-600">
							<FormattedMessage id="onboarding.organization.types.notary" />
						</OrganizationType>
						<OrganizationType type="law" selected={type === 'law'} onSelected={setType} icon={['fad', 'gavel']} iconClassName="text-purple-600">
							<FormattedMessage id="onboarding.organization.types.law" />
						</OrganizationType>
						<OrganizationType type="services" selected={type === 'services'} onSelected={setType} icon={['fad', 'hands-helping']} iconClassName="text-blue-600">
							<FormattedMessage id="onboarding.organization.types.professional_services" />
						</OrganizationType>
						<OrganizationType type="public" selected={type === 'public'} onSelected={setType} icon={['fad', 'city']} iconClassName="text-red-600">
							<FormattedMessage id="onboarding.organization.types.public" />
						</OrganizationType>
						<OrganizationType type="other" selected={type === 'other'} onSelected={setType} icon={['fad', 'building']} iconClassName="text-gray-600">
							<FormattedMessage id="onboarding.organization.types.other" />
						</OrganizationType>
					</div>
				</ValidationField>
			</Row>
			<Row>
				<Label htmlFor="name">
					<FormattedMessage id="onboarding.organization.employees_label" />
				</Label>
				<ValidationField fieldName="name" validation={validation}>
					<Select size={Size.lg} block onChange={e => setEmployeeCount(e.target.value)} value={employeeCount}>
						<FormattedMessage id="onboarding.organization.employees_select">{msg => <option value="">{msg}</option>}</FormattedMessage>
						<FormattedMessage id="onboarding.organization.employees_1">{msg => <option value="1">{msg}</option>}</FormattedMessage>
						<FormattedMessage id="onboarding.organization.employees_2_to_10">{msg => <option value="2-10">{msg}</option>}</FormattedMessage>
						<FormattedMessage id="onboarding.organization.employees_11_to_50">{msg => <option value="11-50">{msg}</option>}</FormattedMessage>
						<FormattedMessage id="onboarding.organization.employees_51_500">{msg => <option value="51-500">{msg}</option>}</FormattedMessage>
						<FormattedMessage id="onboarding.organization.employees_500_more">{msg => <option value="500+">{msg}</option>}</FormattedMessage>
					</Select>
				</ValidationField>
			</Row>
			<Row className="mt-16">
				<Button block variant={Variant.primary} disabled={disabled} loading={isSaving} type="submit" size={Size.xl}>
					<FormattedMessage id="signup.continue" />
				</Button>
			</Row>
		</form>
	);
};

type OrganizationTypeProps = PropsWithChildren<{
	type: string;
	selected?: boolean;
	onSelected?: (type: string) => void;
	icon: IconProp;
	iconClassName?: string;
}>;

export const OrganizationType: FC<OrganizationTypeProps> = ({ type, selected = false, onSelected = () => undefined, icon, iconClassName, children }) => {
	return (
		<button
			type="button"
			className={`flex items-center justify-between bg-white transition-colors rounded focus:outline-none p-3 text-left border focus:bg-theme-primary-lightest/25 ${
				selected ? 'bg-theme-primary-lightest/25 border-theme-primary-lighter' : 'border-gray-300'
			}`}
			onClick={() => onSelected(type)}>
			<div className="flex items-center space-x-3">
				<FontAwesomeIcon icon={icon} className={iconClassName} size="lg" fixedWidth />
				<span className={selected ? 'text-gray-700' : 'text-gray-500'}>{children}</span>
			</div>
			{selected && <FontAwesomeIcon icon="check-circle" className="text-theme-primary" />}
		</button>
	);
};
