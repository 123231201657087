import Logo from '@assets/images/Logo.svg?react';
import { useGuidedTour } from '@components/GuidedTour';
import { Alert, Label, Modal, ModalBody, type ModalProps, ModalSize, Toggle, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEnvironment } from '@hooks/use-environment';
import { type ChangeEvent, type FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

export const DialogSupport: FC<Omit<ModalProps, 'isOpen'>> = ({ ...modalProps }) => {
	const { locale, formatMessage } = useIntl();
	const { enable: enableGuidedTour, disable: disableGuidedTour, reset: resetGuidedTour, enabled: guidedTourEnabled } = useGuidedTour();
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(true);
	const environment = useEnvironment();

	const activateTours = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			enableGuidedTour();
			resetGuidedTour();
			history.push('/files');
			setIsOpen(false);
		} else {
			disableGuidedTour();
		}
	};

	return (
		<Modal {...modalProps} closeable={true} isOpen={isOpen} size={ModalSize.Large}>
			<ModalBody>
				{environment && (
					<>
						<div className="flex flex-col items-center mb-6 space-y-4">
							<img src={environment.LogoLight} className="w-full max-w-40" alt={environment.Name ?? 'Convoflo'} />
							<h4 className="text-lg font-bold text-center text-gray-700">
								<FormattedMessage id="support-box.service.title" values={{ service: environment.Name ?? 'Convoflo' }} />
							</h4>
						</div>
						<Alert variant={Variant.info}>
							<p>
								<FormattedMessage
									id="support-box.service.body"
									values={{
										service: environment.Name ?? 'Convoflo',
										a: msg => (
											<a target="_blank" rel="noreferrer" className="text-theme-primary hover:underline" href={environment.SupportUrl!}>
												{msg}
											</a>
										)
									}}
								/>
							</p>
						</Alert>
						<div className="h-px my-10 bg-gray-200" />
					</>
				)}
				<div className="flex flex-col items-center mb-6 space-y-4">
					<Logo className="w-full text-theme-inverse max-w-40" />
					<h4 className="text-lg font-bold text-center text-gray-700">
						<FormattedMessage id="support-box.platform.title" />
					</h4>
				</div>
				<p>{environment ? <FormattedMessage id="support-box.platform.body-branding" values={{ service: environment.Name }} /> : <FormattedMessage id="support-box.platform.body" />}</p>
				<div className="grid grid-cols-1 sm:grid-cols-2">
					<ul className="mt-6 space-y-3">
						{/* <li className="font-medium text-gray-700">
							<FontAwesomeIcon icon="file-pdf" className="h-4 mr-2 text-red-600" />
							<a href={formatMessage({ id: 'support-box.links.quick_start_guide' })} className="underline" target="_blank" rel="noreferrer">
								<FormattedMessage id="support-box.quick_start_guide" />
							</a>
						</li> */}
						<li className="font-medium text-gray-700">
							<FontAwesomeIcon icon="file-pdf" className="h-4 mr-2 text-red-600" />
							<a href={formatMessage({ id: 'support-box.links.user_guide' })} className="underline" target="_blank" rel="noreferrer">
								<FormattedMessage id="support-box.user_guide" />
							</a>
						</li>
						<li className="font-medium text-gray-700">
							<FontAwesomeIcon icon="file-pdf" className="h-4 mr-2 text-red-600" />
							<a href={formatMessage({ id: 'support-box.links.external_guide' })} className="underline" target="_blank" rel="noreferrer">
								<FormattedMessage id="support-box.external_guide" />
							</a>
						</li>
						<li className="font-medium text-gray-700">
							<FontAwesomeIcon icon="file-pdf" className="h-4 mr-2 text-red-600" />
							<a href={formatMessage({ id: 'support-box.links.advanced_guide' })} className="underline" target="_blank" rel="noreferrer">
								<FormattedMessage id="support-box.advanced_guide" />
							</a>
						</li>
					</ul>
					<ul className="mt-6 space-y-3">
						{locale === 'fr' && (
							<li className="font-medium text-gray-700">
								<FontAwesomeIcon icon="video" className="h-4 mr-2 text-gray-600" />
								<a href={formatMessage({ id: 'support-box.tutorials.link' })} className="underline" target="_blank" rel="noreferrer">
									<FormattedMessage id="support-box.tutorials.action" />
								</a>
							</li>
						)}
						{!!environment?.SupportUrl && (
							<li className="font-medium text-gray-700">
								<FontAwesomeIcon icon="phone" className="h-4 mr-2 text-gray-600" />
								<a href={`${import.meta.env.VITE_SITE_URL}/${locale}/contact`} className="underline" target="_blank" rel="noreferrer">
									<FormattedMessage id="support-box.contact_us" />
								</a>
							</li>
						)}
						<li className="font-medium text-gray-700">
							<FontAwesomeIcon icon="question-circle" className="h-4 mr-2 text-blue-500" />
							<a href={`${import.meta.env.VITE_SITE_URL}/${locale}/support`} className="underline" target="_blank" rel="noreferrer">
								<FormattedMessage id="support-box.visit_faq" />
							</a>
						</li>
					</ul>
				</div>
				<div className="pt-6 mt-6 border-t">
					<Label className="flex items-center gap-4">
						<FormattedMessage id="onboarding.activate_tour" />
						<Toggle checked={guidedTourEnabled} onChange={activateTours} />
					</Label>
				</div>
			</ModalBody>
		</Modal>
	);
};
