import { Editor, useCurrentEditor } from '@tiptap/react';
import { ToolbarButton, type ToolbarButtonProps } from '@ui/RichTextEditor/Toolbar';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export interface LinkToolbarButtonProps extends ToolbarButtonProps {
	getLinkUrl?: (prevUrl?: string | null) => Promise<string | null>;
}

export const LinkToolbarButton = ({ getLinkUrl, ...props }: LinkToolbarButtonProps) => {
	const { editor } = useCurrentEditor();
	const { formatMessage } = useIntl();

	const setLink = useCallback(
		(editor: Editor) => {
			if (!editor) return;

			const previousUrl = editor.getAttributes('link').href;
			const url = window.prompt(formatMessage({ id: 'text-editor.title.url' }), previousUrl);

			// cancelled
			if (url === null) {
				return;
			}

			// empty
			if (url === '') {
				editor.chain().focus().extendMarkRange('link').unsetLink().run();
				return;
			}

			// update link
			editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
		},
		[formatMessage]
	);

	if (editor === null) {
		return null;
	}

	return <ToolbarButton type={['link']} handler={() => setLink(editor)} {...props} />;
};
