import { forwardRef, type ButtonHTMLAttributes, type ForwardRefRenderFunction } from 'react';

const LinkButton: ForwardRefRenderFunction<HTMLButtonElement, ButtonHTMLAttributes<HTMLElement>> = ({ className = '', ...props }, ref) => {
	return (
		<button
			{...props}
			ref={ref}
			className={`text-theme-primary font-semibold hover:transition-colors focus:ring ease-in-out duration-100 focus:outline-none rounded-md whitespace-nowrap ${className}`}
		/>
	);
};

export default forwardRef(LinkButton);
