import * as React from "react";
const SvgWoltersKlumerIcon = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 6.879 6.879", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("circle", { id: "a", cx: 13, cy: 13, r: 13 }), /* @__PURE__ */ React.createElement("mask", { id: "b", fill: "#fff" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#a" }))), /* @__PURE__ */ React.createElement("g", { transform: "scale(.26458)" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#a", fill: "#007ac3", style: {
  fillRule: "evenodd"
} }), /* @__PURE__ */ React.createElement("path", { fill: "#85bc20", d: "M3.824 3.824h18.353v18.353H3.824Z", mask: "url(#b)", style: {
  fillRule: "evenodd"
} }), /* @__PURE__ */ React.createElement("path", { fill: "#a6d1ea", d: "M0 9.941h26v6.118H0Z", mask: "url(#b)", style: {
  fillRule: "evenodd"
} }), /* @__PURE__ */ React.createElement("path", { fill: "#409bd2", d: "M9.941 9.941H26v6.118H9.941Z", mask: "url(#b)", style: {
  fillRule: "evenodd"
} }), /* @__PURE__ */ React.createElement("path", { fill: "#a6d1ea", d: "M9.941 0h6.118v26H9.941Z", mask: "url(#b)", style: {
  fillRule: "evenodd"
} }), /* @__PURE__ */ React.createElement("path", { fill: "#409bd2", d: "M9.941 9.941h6.118V26H9.941Z", mask: "url(#b)", style: {
  fillRule: "evenodd"
} }), /* @__PURE__ */ React.createElement("path", { fill: "#a4cd58", d: "M9.941 9.941h12.235v12.235H9.941Z", mask: "url(#b)", style: {
  fillRule: "evenodd"
} }), /* @__PURE__ */ React.createElement("path", { fill: "#d4e8b1", d: "M3.824 3.824h12.235v12.235H3.824Z", mask: "url(#b)", style: {
  fillRule: "evenodd"
} }), /* @__PURE__ */ React.createElement("path", { fill: "#409bd2", d: "M0 0h9.941v9.941H0Z", mask: "url(#b)", style: {
  fillRule: "evenodd"
} }), /* @__PURE__ */ React.createElement("path", { fill: "#e5202e", d: "M9.941 9.941h6.118v6.118H9.941Z", mask: "url(#b)", style: {
  fillRule: "evenodd"
} }), /* @__PURE__ */ React.createElement("path", { fill: "#a4cd58", d: "M3.824 3.824h6.118v6.118H3.824Z", mask: "url(#b)", style: {
  fillRule: "evenodd"
} }), /* @__PURE__ */ React.createElement("path", { fill: "#85bc20", d: "M16.059 16.059h6.118v6.118h-6.118z", mask: "url(#b)", style: {
  fillRule: "evenodd"
} })));
export default SvgWoltersKlumerIcon;
