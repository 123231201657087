import { ActivityList } from '@components/Activity';
import { FileableHeader } from '@components/FileableHeader';
import { DialogNewFileVersion, DialogPreview, FileManagerTabs, FileableProvider, VersionsManager, useSecureSpace } from '@components/FileManager';
import { MessageList } from '@components/Message';
import { NotificationConfirmationToastContents } from '@components/NotificationsListener';
import { Dropzone, UploadButton, type UploadButtonHandles } from '@components/Upload';
import { Button, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useView } from '@hooks/use-view';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import useFileUtils from '@hooks/useFileUtils';
import File from '@models/File';
import { Permissions } from '@types';
import { type FC, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import toast from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import type { WretchError } from 'wretch';

type FileViewPageProps = {
	file: File;
};

type UploadNewExternalVersionMutation = {
	link: string;
};

export const FileViewPage: FC<FileViewPageProps> = ({ file }) => {
	const { key } = useView();
	const queryClient = useQueryClient();
	const { account } = useAccount();
	const { client } = useClient();
	const history = useHistory();
	const { download, isDownloading } = useFileUtils();
	const { tab } = useView();

	const [dialogPreviewOpened, setDialogPreviewOpened] = useState(false);
	const [newVersionFile, setNewVersionFile] = useState<globalThis.File | null>(null);

	const { mutate: uploadNewExternalVersion, isLoading: isUploadingNewVersion } = useMutation<File, WretchError, UploadNewExternalVersionMutation>(
		async ({ link }) => new File(await client.url(`documents/${file.getKey()}/versions`).json({ external_link: link }).post().json()),
		{
			onSuccess: file => {
				queryClient.invalidateQueries(['view', key, 'versions']);
				queryClient.setQueryData(['view', key], file);

				const usersInToast = file.collaborators.filter(collaborator => !!collaborator.pivot.Notify && collaborator.ID !== account?.ID);
				let context = null;

				// A way to check if user is external
				if (!file.collaborators.some(member => member.ID === file.creator.ID)) {
					context = { organization: file.creator.business };
				}

				if (usersInToast.length === 0 && context === null) {
					toast.success(
						<div>
							<h5 className="font-semibold">
								<FormattedMessage id="uploader.files_added" />
							</h5>
						</div>,
						{ id: 'notification-confirmation', duration: 15000 }
					);
				}

				toast.success(
					<div>
						<h5 className="font-semibold">
							<FormattedMessage id="uploader.files_sent_to" />
						</h5>
						<NotificationConfirmationToastContents users={usersInToast} context={context} />
					</div>,
					{ id: 'notification-confirmation', duration: 15000 }
				);
			}
		}
	);

	const uploader = useRef<UploadButtonHandles>(null);

	const selectFiles = () => {
		if (uploader.current !== null) {
			uploader.current.chooseFiles();
		}
	};

	const printTimeline = () => {
		window.open('/print?page=' + encodeURIComponent(file.getUrl('timeline')));
	};

	const preview = async () => {
		if (file.isPreviewable()) {
			setDialogPreviewOpened(true);
		} else if (file.current.ExternalLink !== null) {
			history.push(file.getUrl('view'), { view: file });
		}
	};

	const secureSpace = useSecureSpace(file);

	const canViewMessages = (secureSpace && secureSpace.canViewMessages()) || (!secureSpace && file?.canViewMessages());

	const uploadNewVersion = file.current.ExternalLink !== null ? () => uploadNewExternalVersion({ link: file.current.ExternalLink }) : selectFiles;

	return (
		<FileableProvider fileable={file}>
			<div className="flex flex-col px-6 pt-6 pb-2 bg-white border-b border-gray-200">
				<FileableHeader fileable={file} />
				{/* Buttons for files */}
				<div className="flex items-center mb-8 space-x-4">
					{(file.isPreviewable() || file.current.ExternalLink !== null) && (
						<Button variant={Variant.primary} onClick={preview} shadow>
							<FontAwesomeIcon icon="eye" className="mr-2" />
							<FormattedMessage id="file.view" />
						</Button>
					)}
					{file.isDownloadableBy(account) && (
						<Button variant={Variant.primary} onClick={() => download(file)} disabled={isDownloading} shadow>
							{isDownloading ? <FontAwesomeIcon icon="spinner" pulse className="mr-2" /> : <FontAwesomeIcon icon="cloud-download" className="mr-2" />}
							<FormattedMessage id="file.download" />
						</Button>
					)}
					{([Permissions.ReadWrite, Permissions.Editor].includes(file?.pivot?.Permissions || Permissions.None) || file.Editable) && account && (
						<>
							{file.current.ExternalLink === null ? (
								<>
									<Button variant={Variant.success} shadow onClick={uploadNewVersion} disabled={isUploadingNewVersion}>
										{isUploadingNewVersion ? <FontAwesomeIcon icon="spinner" pulse className="mr-2" /> : <FontAwesomeIcon icon="cloud-download" className="mr-2" />}
										<FormattedMessage id="file.upload_new_version" />
									</Button>
									<UploadButton ref={uploader} multiple={false} onFilesSelected={files => setNewVersionFile(files[0])} />
									{createPortal(<Dropzone onFilesDropped={files => setNewVersionFile(files[0])} fullScreen />, document.body)}
								</>
							) : (
								<Button variant={Variant.success} shadow onClick={uploadNewVersion} disabled={isUploadingNewVersion}>
									{isUploadingNewVersion ? <FontAwesomeIcon icon="spinner" pulse className="mr-2" /> : <FontAwesomeIcon icon="save" className="mr-2" />}
									<FormattedMessage id="save" />
								</Button>
							)}
						</>
					)}
				</div>
				{account?.hasFullAccess() && <FileManagerTabs fileable={file} />}
			</div>

			{account?.hasFullAccess() && (
				<div className="pt-8 mb-48 sm:px-6 md:mb-32">
					{tab === 'comments' && canViewMessages && (
						<div className="max-w-4xl mx-auto">
							<MessageList item={file} />
						</div>
					)}
					{tab === 'timeline' && (
						<>
							<div className="max-w-4xl px-6 mx-auto">
								<div className="flex justify-end space-x-4">
									<Button variant={Variant.dark} shadow onClick={printTimeline} circle>
										<FontAwesomeIcon icon="print" />
									</Button>
								</div>
								<ActivityList fileable={file} />
							</div>
						</>
					)}
					{tab === 'versions' && <VersionsManager file={file} />}
				</div>
			)}

			{account?.hasFullAccess() === false && (
				<div className="pt-8 mb-48 sm:px-6 md:mb-32">
					{canViewMessages && (
						<div className="mx-auto mt-8">
							<MessageList item={file} />
						</div>
					)}
				</div>
			)}

			{dialogPreviewOpened && <DialogPreview file={file} onAfterClose={() => setDialogPreviewOpened(false)} showNavigation={false} />}
			{newVersionFile !== null && <DialogNewFileVersion file={newVersionFile} view={file} onAfterClose={() => setNewVersionFile(null)} />}
		</FileableProvider>
	);
};
