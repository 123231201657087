import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Variant, Intent, Size } from '@convoflo/ui';
import { type FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Card from '@ui/Card';
import { DialogNotificationProfilePicker } from './DialogNotificationProfilePicker';

type NotificationProfileManagerProps = {
	onCollapse?: () => void;
};

export const NotificationProfileManager: FC<NotificationProfileManagerProps> = ({ onCollapse }) => {
	const [showProfilePicker, setShowProfilePicker] = useState(false);

	return (
		<>
			<Card className="relative flex flex-col items-start flex-1">
				{onCollapse !== undefined && (
					<span className="absolute top-3 right-3">
						<Button circle icon="caret-up" variant={Variant.light} intent={Intent.primary} size={Size.sm} onClick={onCollapse} />
					</span>
				)}
				<div className="flex mb-4">
					<FontAwesomeIcon icon={['fad', 'user-tie']} size="2x" className="text-theme-primary" />
				</div>
				<div className="flex-1 mb-4 prose-sm prose">
					<h3>
						<FormattedMessage id="notifications.profile.title" />{' '}
						<sup className="px-1.5 py-0.5 ml-1 text-xs leading-none text-white bg-red-600 rounded-full">
							<FormattedMessage id="new" />
						</sup>
					</h3>
					<p>
						<FormattedMessage id="notifications.profile.description" />
					</p>
				</div>
				<Button size={Size.sm} variant={Variant.primary} intent={Intent.secondary} onClick={() => setShowProfilePicker(true)}>
					<FormattedMessage id="notifications.profile.choose" />
				</Button>
			</Card>
			{showProfilePicker && <DialogNotificationProfilePicker onAfterClose={() => setShowProfilePicker(false)} />}
		</>
	);
};
