import { DateTimeDisplay } from '@components/DateTime';
import type { Layout } from '@components/SignRequest';
import { Button, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Size, Variant } from '@convoflo/ui';
import useClient from '@hooks/useClient';
import { type FC, type FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';

export type LayoutItemProps = {
	layout: Layout;
};

export const LayoutItem: FC<LayoutItemProps> = ({ layout }) => {
	const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

	return (
		<>
			<div className="p-6">
				<div className="flex items-start justify-between mb-4">
					<div className="space-y-1">
						<h2 className="text-sm font-semibold leading-tight">{layout.name}</h2>
						<p className="text-xs italic text-gray-500">{<DateTimeDisplay value={layout.created} showTooltip={false} force defaultFormat="long" />}</p>
						<p className="text-gray-500">{layout.description}</p>
					</div>
				</div>
				<Button icon="trash-alt" size={Size.sm} variant={Variant.danger} intent={Intent.secondary} onClick={() => setIsDeleteModalOpened(true)}>
					<FormattedMessage id="sign-requests-crud.delete_sign_request" values={{ type: 'layout' }} />
				</Button>
			</div>

			{isDeleteModalOpened && <DialogDeleteLayout layout={layout} onAfterClose={() => setIsDeleteModalOpened(false)} />}
		</>
	);
};

export type DialogDeleteLayoutProps = Omit<ModalProps, 'isOpen'> & {
	layout: Layout;
};

export const DialogDeleteLayout: FC<DialogDeleteLayoutProps> = ({ layout, ...props }) => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	const [isOpen, setIsOpen] = useState(true);

	const { mutate: _delete, isLoading: isDeleting } = useMutation<void>(async () => await client.url(`account/layouts/${layout.id}`).delete().res(), {
		onError: (_error, _variables, lastLayout) => {
			queryClient.setQueryData(['layouts'], lastLayout);
		},
		onMutate: async () => {
			await queryClient.cancelQueries(['layouts']);

			const lastLayout = queryClient.getQueryData<Layout[]>(['layouts']);

			if (lastLayout === undefined) {
				return undefined;
			}

			queryClient.setQueryData(
				['layouts'],
				lastLayout.filter(delLayout => delLayout.id !== layout.id)
			);

			return lastLayout;
		},
		onSuccess: () => {
			setIsOpen(false);
			toast.success(<FormattedMessage id="sign-requests-crud.deleted_layout" values={{ type: 'layout' }} />);
		}
	});

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		_delete();
	};

	return (
		<Modal isOpen={isOpen} onSubmit={onSubmit} {...props}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage
					id="sign-requests-crud.delete_sign_request"
					values={{
						type: 'layout'
					}}
				/>
			</ModalHeaderOnlyTitle>
			<ModalBody>
				<FormattedMessage
					id="sign-requests-crud.delete_layout_text"
					values={{
						name: layout.name,
						semi: msg => <span className="italic font-semibold">{msg}</span>,
						strong: msg => <span className="font-bold text-red-700">{msg}</span>
					}}
				/>
			</ModalBody>
			<ModalFooter>
				<Button type="submit" variant={Variant.danger} disabled={isDeleting}>
					<FormattedMessage id="delete" />
				</Button>

				<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
