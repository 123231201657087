import { DateTimeDisplay } from '@components/DateTime';
import type { AttachmentType } from '@components/Message';
import type { PaymentRequest } from '@components/PaymentRequest';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePaymentRequests } from '@hooks/usePaymentRequests';
import type { Modify } from '@types';
import type { FC } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { defaultCurrency } from '../../../../constants';

type MessagePaymentRequestAttachmentProps = {
	attachment: Modify<AttachmentType, { item: PaymentRequest }>;
	onClick?: () => void;
	className?: string;
};

export const MessagePaymentRequestAttachment: FC<MessagePaymentRequestAttachmentProps> = ({ attachment, className = '' }) => {
	const { pay } = usePaymentRequests();

	if (attachment.item.payed_at !== null) {
		return (
			<div className="inline-flex items-center p-2 bg-green-100 border border-green-400 rounded-md">
				<FontAwesomeIcon icon="check-circle" className="mr-1.5 text-xs text-green-500" />
				<span className="text-xs font-medium text-green-700">
					<FormattedMessage id="payment-requests.payment_made" values={{ date: <DateTimeDisplay value={attachment.item.payed_at} force defaultFormat="long" /> }} />
				</span>
			</div>
		);
	}

	if (attachment.item.Status === 'processing') {
		return (
			<div className="inline-flex items-center p-2 bg-yellow-100 border border-yellow-400 rounded-md">
				<FontAwesomeIcon icon="hourglass-half" className="mr-1.5 text-xs text-yellow-500" />
				<span className="text-xs font-medium text-yellow-600">
					<FormattedMessage id="payment-requests.processing" />
				</span>
			</div>
		);
	}

	if (attachment.item.Amount) {
		return (
			<Button onClick={() => pay(attachment.item)} size={Size.sm} intent={Intent.secondary} variant={Variant.success} iconEnd="arrow-right" className={className}>
				<span>
					<FormattedMessage
						id="payment-requests.make_payment"
						values={{
							strong: msg => <strong className="font-semibold">{msg}</strong>,
							amount: <FormattedNumber value={attachment.item.Amount} style="currency" currencyDisplay="symbol" currency={defaultCurrency} />
						}}
					/>
				</span>
			</Button>
		);
	}

	return (
		<div className="inline-flex items-center p-2 bg-yellow-100 border border-yellow-400 rounded-md">
			<FontAwesomeIcon icon="clock" className="mr-1.5 text-xs text-yellow-500" />
			<span className="text-xs font-medium text-yellow-600">
				<FormattedMessage id="payment-requests.pending" />
			</span>
		</div>
	);
};
