import classNames from 'classnames';
import { type FC, type PropsWithChildren } from 'react';

type PageProps = PropsWithChildren<{
	theme?: 'light' | 'dark' | null;
	layout?: 'full' | 'minimal' | 'nothing' | 'print' | 'onboarding' | null;
}>;

const Page: FC<PageProps> = ({ theme = 'light', layout = 'full', children }) => {
	if (layout === 'nothing') {
		return <main className="min-h-screen bg-gray-50">{children}</main>;
	}

	if (layout === 'print') {
		return <main className="p-6 lg:p-16">{children}</main>;
	}

	return <main className={classNames('w-full h-full relative overflow-hidden flex flex-col')}>{children}</main>;
};

export default Page;
