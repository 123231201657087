import MicrosoftSharePointIcon from '@assets/images/sharepoint-icon.svg?react';
import type { ConnectedAppType } from '@components/ConnectedApps';
import { Button, Checkbox, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, ModalSize, Row, Size, Variant, type ModalProps } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	useMicrosoftSharePointDrivesQuery,
	useMicrosoftSharePointOptionsQuery,
	useMicrosoftSharePointSaveOptionsMutation,
	useMicrosoftSharePointSitesQuery,
	type SharePointSiteType
} from '@state/queries/microsoft-sharepoint';
import classNames from 'classnames';
import { useEffect, useState, type FC, type FormEvent } from 'react';
import toast from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';

type DialogSharePointSiteConfigurationProps = Omit<ModalProps, 'isOpen'> & {
	connection: ConnectedAppType;
};

export const DialogSharePointSiteConfiguration: FC<DialogSharePointSiteConfigurationProps> = ({ connection, ...modalProps }) => {
	const [isOpen, setIsOpen] = useState(true);
	const [selectedSites, setSelectedSites] = useState<string[]>([]);

	const { data: options } = useMicrosoftSharePointOptionsQuery();
	const { data: sites, isLoading: isLoadingSites } = useMicrosoftSharePointSitesQuery(connection, true);
	const { mutateAsync: save, isLoading: isSaving } = useMicrosoftSharePointSaveOptionsMutation();

	useEffect(() => {
		if (!options) {
			return;
		}

		setSelectedSites(options.sites ?? []);
	}, [options]);

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();

		try {
			await save({ sites: selectedSites });
			toast.success(<FormattedMessage id="file-settings.saved" />);
			setIsOpen(false);
		} catch {
			// TODO: Show error message to user
		}
	};

	return (
		<Modal isOpen={isOpen} size={ModalSize.Large} closeable={true} onSubmit={onSubmit} {...modalProps}>
			<ModalHeaderOnlyTitle>
				<MicrosoftSharePointIcon className="size-5" />
				<span>
					<FormattedMessage id="sharepoint.config.title" />
				</span>
			</ModalHeaderOnlyTitle>
			<ModalBody>
				<Row>
					<Label>
						<FormattedMessage id="sharepoint.config.sites_available" />
					</Label>
					<p className="text-sm">
						<FormattedMessage id="sharepoint.config.sites_available_desc" />
					</p>
					<div
						className={classNames('p-3 mt-3 overflow-auto bg-gray-100 rounded max-h-1/2-screen', {
							'grid grid-cols-3 gap-2': !!sites?.length
						})}>
						{isLoadingSites && (
							<p className="text-sm italic text-gray-500">
								<FontAwesomeIcon icon="spinner" pulse className="mr-2" />
								<FormattedMessage id="sharepoint.config.loading_sites" />
							</p>
						)}
						{sites?.map(site => (
							<div key={site.id}>
								<Checkbox
									checked={selectedSites.includes(site.id)}
									onChange={e => setSelectedSites(sites => (e.target.checked ? sites.concat(site.id) : sites.filter(id => id !== site.id)))}
									size={Size.sm}>
									<span className="text-sm">{site.name}</span>
								</Checkbox>
							</div>
						))}
					</div>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button type="submit" variant={Variant.primary} intent={Intent.primary} loading={isSaving} iconStart="save">
					<FormattedMessage id="save" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};

type SiteSectionProps = {
	connection: ConnectedAppType;
	site: SharePointSiteType;
};

const SiteSection: FC<SiteSectionProps> = ({ connection, site }) => {
	const [showDrives, setShowDrives] = useState(false);
	const [selectedDrives, setSelectedDrives] = useState<string[]>([]);

	const { data: options } = useMicrosoftSharePointOptionsQuery();
	const { data: drives, isLoading: isLoadingDrives } = useMicrosoftSharePointDrivesQuery(connection, site.id, { all: true, enabled: showDrives });

	return (
		<div className="w-1/2">
			<div className="">
				<button className="flex items-center gap-3" type="button" onClick={() => setShowDrives(!showDrives)}>
					<FontAwesomeIcon
						size="sm"
						icon="caret-right"
						className={classNames('transition-transform', {
							'rotate-90': showDrives
						})}
					/>
					<h2 className="flex items-center gap-2 text-sm">
						<span className="font-bold">{site.name}</span>
						<span className="text-xs text-gray-400">({drives && selectedDrives.length ? `${selectedDrives.length}/${drives.length}` : drives ? drives.length : '...'})</span>
					</h2>
				</button>
			</div>
			{isLoadingDrives && (
				<p className="my-2 ml-4 text-sm italic text-gray-500">
					<FontAwesomeIcon icon="spinner" pulse className="mr-2" />
					<span>Loading drives...</span>
				</p>
			)}
			{showDrives && !!drives?.length && (
				<div className="flex flex-col gap-2 my-2">
					{drives?.map(drive => (
						<div key={drive.id} className="flex items-center gap-3 ml-4 text-sm">
							<Checkbox
								checked={selectedDrives.includes(drive.id)}
								onChange={e => setSelectedDrives(drives => (e.target.checked ? drives.concat(drive.id) : drives.filter(id => id !== drive.id)))}
								size={Size.sm}>
								{drive.name}
							</Checkbox>
						</div>
					))}
				</div>
			)}
		</div>
	);
};
