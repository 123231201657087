import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import File from '@models/File';
import QueryString from 'qs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useQuery } from 'react-query';
import { PREVIEWABLE_TYPES } from '../DialogPreview';

export const useGallery = (file: File | undefined, onNavigate = (file: File) => {}, enabled = true) => {
	const { client } = useClient();
	const { account } = useAccount();

	const [, updateState] = useState({});
	const initialFile = useRef(file);

	const forceUpdate = useCallback(() => updateState({}), []);

	const { data: gallery = [] } = useQuery(
		['file', initialFile.current?.Id, 'gallery'],
		async () => {
			const query = QueryString.stringify({ file: initialFile.current?.Id, filters: { types: PREVIEWABLE_TYPES } });
			const { documents: files } = await client.url('files').query(query).get().json<{ documents: File[] }>();
			return files.map(image => new File(image));
		},
		{
			enabled: !!initialFile && !!account && enabled,
			staleTime: Infinity
		}
	);

	const currentIndex = useMemo(() => gallery.findIndex(_file => _file.getKey() === file?.getKey()), [file, gallery]);

	useEffect(() => {
		if (file !== undefined && initialFile.current === undefined) {
			initialFile.current = file;
			forceUpdate();
		}
	}, [file, forceUpdate]);

	useHotkeys(
		'left',
		() => {
			onNavigate(gallery[currentIndex - 1]!);
		},
		{
			enabled: currentIndex > 0 && enabled
		},
		[gallery, currentIndex]
	);

	useHotkeys(
		'right',
		() => {
			onNavigate(gallery[currentIndex + 1]!);
		},
		{
			enabled: currentIndex < gallery.length - 1 && enabled
		},
		[gallery, currentIndex]
	);

	return {
		previousFile: currentIndex > 0 ? gallery[currentIndex - 1] : null,
		nextFile: currentIndex < gallery.length - 1 ? gallery[currentIndex + 1] : null
	};
};
