import { FileSelector, type FileSelectorSelection } from '@components/FileSelector';
import {
	Alert,
	Button,
	Checkbox,
	HelperText,
	InputBlock,
	Intent,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeaderOnlyTitle,
	type ModalProps,
	Row,
	Size,
	ValidationField,
	Variant
} from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import useIsDirty from '@hooks/useIsDirty';
import Collaborator from '@models/Collaborator';
import File from '@models/File';
import Folder from '@models/Folder';
import SignRequest from '@models/SignRequest';
import { useSignRequestTemplateCreateMutation, useSignRequestTemplateQuery, useSignRequestTemplateUpdateMutation } from '@state/queries/sign-request-templates';
import { useSignRequestCreateMutation, useSignRequestOverviewQuery, useSignRequestUpdateMutation } from '@state/queries/sign-requests';
import type { SignRequestRole } from '@types';
import Chip from '@ui/Chip';
import ProgressBar from '@ui/ProgressBar';
import UserAvatar from '@ui/UserAvatar';
import { formatDate } from '@utils/DateUtils';
import { isValidDocumentForSignRequest } from '@utils/OneSpanUtils';
import { type ChangeEvent, type FC, type FormEvent, type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { Prompt, useHistory } from 'react-router-dom';
import { type OptionProps, components } from 'react-select';
import { ReactSortable } from 'react-sortablejs';
import { DialogSignerNames, type UnnamedSigner } from '.';
import { joinWithObject } from '../../utils';
import { ComboBox } from '../ComboBox';
import { useFileableMembers } from '../FileManager';
import { useLocalStorage } from '@hooks/useLocalStorage';

type SignRequestFormProps = Omit<ModalProps, 'isOpen'> & {
	folder?: Folder | null;
	signRequest?: SignRequest | null;
	onSave?: (signatureRequest: SignRequest) => void;
};

type FileId = {
	id: number | string;
	file: File;
};

export const SignRequestForm: FC<SignRequestFormProps> = ({ folder = null, signRequest = null, onSave = () => undefined, ...modalProps }) => {
	const { formatMessage, formatNumber, locale } = useIntl();
	const { validation } = useClient();
	const history = useHistory();

	const { members = [] } = useFileableMembers(folder ?? undefined, { orderBy: 'scope' });

	const [isOpen, setIsOpen] = useState(true);
	const [label, setLabel] = useState(signRequest?.Label ?? formatMessage({ id: 'sign-requests-crud.default-subject' }, { date: formatDate(null, locale, 'PPP') }));
	const [files, setFiles] = useState<File[]>(signRequest?.documents ?? []);
	const [roles, setRoles] = useState<Partial<SignRequestRole>[]>(signRequest?.roles ?? []);
	const [usingTemplate, setUsingTemplate] = useState(false);
	const [selectedTemplate, setSelectedTemplate] = useState<SignRequest | null>(null);
	const [unnamedSigners, setUnnamedSigners] = useState<UnnamedSigner[]>([]);
	const [fileSelectorVisible, setFileSelectorVisible] = useState(false);
	const [errorMessage, setErrorMessage] = useState<ReactNode>(null);
	const [ordered, setOrdered] = useState((signRequest?.roles ?? []).some(role => role.Order !== null));
	const [reassign, setReassign] = useLocalStorage('module.signatures.enable_reassignment', true);
	const [filesId, setFilesId] = useState<FileId[]>([]);

	const { isDirty, setIsDirty } = useIsDirty([label, filesId, roles, usingTemplate, selectedTemplate]);

	const { data: templates } = useSignRequestTemplateQuery(folder !== null && signRequest === null);
	const { data: overview } = useSignRequestOverviewQuery('user');

	const { mutateAsync: create, isLoading: isCreating } = useSignRequestCreateMutation();
	const { mutateAsync: createTemplate, isLoading: isCreatingTemplate } = useSignRequestTemplateCreateMutation();
	const { mutateAsync: save, isLoading: isSaving } = useSignRequestUpdateMutation();
	const { mutateAsync: saveTemplate, isLoading: isSavingTemplate } = useSignRequestTemplateUpdateMutation();

	const submittable = useMemo(() => {
		if (label.trim() === '') {
			return false;
		}

		if (roles.length === 0) {
			return false;
		}

		// Check if every signer has a placeholder assigned
		if (!usingTemplate && folder !== null && !roles.every(role => role.signer !== null)) {
			return false;
		}

		if (usingTemplate) {
			if (selectedTemplate === null) {
				return false;
			}

			// Check if every role has been assigned
			if (!selectedTemplate.roles.every(templateRole => roles.some(role => role.Id === templateRole.Id))) {
				return false;
			}
		}

		if (!usingTemplate) {
			if (filesId.length === 0) {
				return false;
			}
		}

		if (folder instanceof Folder) {
			const signersId = roles.filter(role => !!role.signer).map(role => role.signer?.ID);

			if (signersId.length !== new Set(signersId).size) {
				return false;
			}
		}

		return true;
	}, [label, roles, filesId, folder, selectedTemplate, usingTemplate]);

	const onSubmit = async (event?: FormEvent) => {
		event?.preventDefault();
		setIsDirty(false);

		if (folder instanceof Folder && signRequest !== null) {
			try {
				const updatedSignRequest = await save({ signRequest, label, roles, filesId, ordered, enableReassignment: reassign });
				toast.success(<FormattedMessage id="sign-requests-crud.edited" values={{ type: 'request' }} />);
				onSave(updatedSignRequest);
				goToDesigner(updatedSignRequest);
			} catch (error: any) {
				if (error.status === 406) {
					setUnnamedSigners(error.json);
				} else if (error.status !== 422) {
					setErrorMessage(error.text || <FormattedMessage id="uploader.errors.generic" />);
				}
			}
		} else if (folder instanceof Folder && signRequest === null) {
			try {
				const createdSignRequest = await create({ folder, label, roles, filesId, selectedTemplate, ordered, enableReassignment: reassign });
				toast.success(<FormattedMessage id="sign-requests-crud.created" values={{ type: 'request' }} />);
				onSave(createdSignRequest);
				if (!usingTemplate) {
					goToDesigner(createdSignRequest);
				} else {
					setIsOpen(false);
				}
			} catch (error: any) {
				if (error.status === 406) {
					setUnnamedSigners(error.json);
				} else if (error.json?.error?.code === 'quota_reached') {
					setErrorMessage(<FormattedMessage id="sign-requests-crud.error_quota" />);
				} else if (error.status !== 422) {
					setErrorMessage(error.text || <FormattedMessage id="uploader.errors.generic" />);
				}
			}
		} else if (folder === null && signRequest !== null) {
			try {
				const updatedTemplate = await saveTemplate({ signRequest, label, roles, files });
				toast.success(<FormattedMessage id="sign-requests-crud.edited" values={{ type: 'template' }} />);
				onSave(updatedTemplate);
				goToDesigner(updatedTemplate);
			} catch (error: any) {
				if (error.status !== 422) {
					setErrorMessage(error.text || <FormattedMessage id="uploader.errors.generic" />);
				}
			}
		} else if (folder === null && signRequest === null) {
			try {
				const createdTemplate = await createTemplate({ label, roles, files });
				toast.success(<FormattedMessage id="sign-requests-crud.created" values={{ type: 'template' }} />);
				onSave(createdTemplate);
				goToDesigner(createdTemplate);
			} catch (error: any) {
				if (error.status !== 422) {
					setErrorMessage(error.text || <FormattedMessage id="uploader.errors.generic" />);
				}
			}
		}
	};

	const onSignersNamed = () => {
		setUnnamedSigners([]);
		onSubmit();
	};

	const onRoleAdded = (user: Collaborator | null = null) => {
		// Check if template
		if (user === null) {
			setRoles(roles => roles.concat({ Label: '' }));
			return;
		}

		setRoles(roles => roles.concat({ signer: user || null }));
	};

	const onSignerChanged = (index: number, user: Collaborator) => {
		setRoles(roles => roles.map((role, i) => (i === index ? { ...role, signer: user } : role)));
	};

	const onRoleLabelChanged = (index: number, label: string) => {
		setRoles(roles => roles.map((r, i) => (i === index ? { ...r, Label: label } : r)));
	};

	const onRoleRemoved = (index: number) => {
		setRoles(roles => roles.filter((_, i) => index !== i));
	};

	const hasSignersLeft = useMemo(() => (roles.filter(role => !!role.signer).length !== members.length || folder === null) && !usingTemplate, [folder, roles, usingTemplate, members.length]);

	const onItemsSelected = useCallback((items: FileSelectorSelection) => setFiles(items), []);

	const onAddSmsVerification = (role: Partial<SignRequestRole>) => {
		setRoles(roles => roles.map(r => (r.signer?.ID === role.signer?.ID ? { ...r, Auth: 'SMS' } : r)));
	};

	const onPhoneChanged = (e: ChangeEvent<HTMLInputElement>, role: Partial<SignRequestRole>) => {
		setRoles(roles => roles.map(r => (r.signer?.ID === role.signer?.ID ? { ...r, Question: e.target.value } : r)));
	};

	const goToDesigner = (signRequest: SignRequest) => {
		setIsOpen(false);
		history.push({ search: `sign_request_designer=${signRequest.Id}` });
	};

	// When we change the selected template, we set the roles to the template's
	useEffect(() => {
		if (selectedTemplate !== null) {
			setRoles(selectedTemplate.roles);
		}
	}, [selectedTemplate]);

	// When we remove the checkbox, we set the selected template to null and set the roles back to the initial value
	useEffect(() => {
		if (!usingTemplate) {
			setSelectedTemplate(null);
			setRoles(signRequest?.roles || []);
		} else {
			setRoles([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [usingTemplate]);

	useEffect(() => {
		setFilesId(files.map(file => ({ id: String(file.getKey()), file: file })));
	}, [files]);

	const isLoading = isSaving || isSavingTemplate || isCreating || isCreatingTemplate;

	const canAddAnotherSigner = folder === null ? roles.every(role => role.Label) : roles.every(role => role.signer);

	return (
		<>
			<Prompt when={isDirty} message={formatMessage({ id: 'unsaved_changes' })} />

			<Modal isOpen={isOpen} onSubmit={onSubmit} {...modalProps}>
				<ModalHeaderOnlyTitle>
					{signRequest === null ? (
						<FormattedMessage id="sign-requests-crud.create-type" values={{ type: folder === null ? 'template' : 'request' }} />
					) : (
						<FormattedMessage id="sign-requests-crud.modify-type" values={{ type: folder === null ? 'template' : 'request' }} />
					)}
				</ModalHeaderOnlyTitle>

				<ModalBody>
					{!!errorMessage && (
						<Alert variant={Variant.danger} className="mb-6">
							<p>{errorMessage}</p>
						</Alert>
					)}

					<Row>
						<Label htmlFor="label">
							<FormattedMessage id="sign-requests-crud.label" />
						</Label>
						<ValidationField validation={validation} fieldName="label">
							<InputBlock id="label" placeholder={formatMessage({ id: 'sign-requests-crud.placeholder-title' })} type="text" value={label} onChange={e => setLabel(e.target.value)} />
						</ValidationField>
					</Row>

					<Row>
						<Label>
							<FormattedMessage id="sign-requests-crud.documents" />
						</Label>

						<div className="flex items-center space-x-4">
							<Button
								size={Size.sm}
								onClick={() => setFileSelectorVisible(true)}
								variant={Variant.light}
								intent={Intent.secondary}
								type="button"
								disabled={usingTemplate || isLoading || files.length >= 25}>
								<FontAwesomeIcon icon="cabinet-filing" className="mr-2" />
								<FormattedMessage id="sign-requests-crud.select_documents" />
							</Button>

							{templates !== undefined && templates.meta.total > 0 && !signRequest?.activated_at && (
								<>
									<span className="text-sm">
										<FormattedMessage id="sign-requests-crud.or" />
									</span>
									<Checkbox className="text-sm font-semibold" checked={usingTemplate} onChange={e => setUsingTemplate(e.target.checked)}>
										<FormattedMessage id="sign-requests-crud.use_template" />
									</Checkbox>
								</>
							)}
						</div>

						{usingTemplate && (
							<div className="my-4">
								<ComboBox
									components={{
										Option: TemplateOptionRenderer
									}}
									value={selectedTemplate}
									options={templates?.data ?? []}
									getOptionLabel={template => template.Label}
									getOptionValue={template => String(template.Id)}
									isDisabled={isLoading}
									onChange={template => setSelectedTemplate(template as SignRequest)}
									isClearable={false}
									placeholder={formatMessage({ id: 'sign-requests-crud.placeholder_templates' })}
								/>
							</div>
						)}

						{files.length >= 25 && (
							<p className="my-2 text-sm italic text-blue-400">
								<FontAwesomeIcon icon="info-circle" className="mr-2 text-blue-400" />
								<FormattedMessage id="sign-requests-crud.max-documents" />
							</p>
						)}

						{filesId && filesId.length > 0 && (
							<div className="mt-4">
								<ReactSortable list={filesId} setList={setFilesId}>
									{filesId.map(fileId => (
										<Chip
											id={fileId.file.getKey() || 0}
											key={fileId.file.getKey()}
											tip={fileId.file.getName()}
											className="text-xs transition duration-300 hover:cursor-pointer"
											onRemove={id => setFiles(files => files.filter(d => d.getKey() !== id))}>
											{fileId.file.icon('mr-1')}
											{fileId.file.getName()}
										</Chip>
									))}
								</ReactSortable>
							</div>
						)}

						{files.length === 0 && !usingTemplate && (
							<div className="p-4 mt-2 bg-gray-100 rounded">
								<FontAwesomeIcon icon="long-arrow-up" className="indicator" />
								<h5 className="mt-2 mb-1 text-sm font-semibold text-gray-700">
									<FormattedMessage id="sign-requests-crud.add-documents" />
								</h5>
								<p className="text-xs text-gray-500">
									<FormattedMessage id="sign-requests-crud.add-documents-desc" />
								</p>
							</div>
						)}
					</Row>

					{(!usingTemplate || (usingTemplate && selectedTemplate !== null)) && (
						<>
							<Row>
								<Label>
									<FormattedMessage id="sign-requests-crud.signatures" />
								</Label>
								<div className="grid grid-cols-1 gap-6 mt-4 md:grid-cols-2">
									{folder !== null &&
										roles.map((role, index) => (
											<div className={role.Label ? 'bg-light' : ''} key={index}>
												<div className="flex items-center mb-2">
													<p className="flex-1 text-sm">{role.Label || <FormattedMessage id="sign-requests-crud.signature_counter" values={{ n: index + 1 }} />}</p>
													{!usingTemplate && (
														<button className="text-sm text-gray-500" onClick={() => onRoleRemoved(index)} type="button">
															<FontAwesomeIcon icon="times" fixedWidth />
														</button>
													)}
												</div>
												<ComboBox
													value={role.signer}
													getOptionLabel={user => user.Name || ''}
													getOptionValue={user => String(user.ID)}
													components={{
														Option: SignerOptionRenderer
													}}
													isSearchable
													isOptionDisabled={user => roles.filter(r => r.Id !== role.Id).some(role => role.signer?.ID === user.ID)}
													isDisabled={isLoading || !!role.signed_at}
													onChange={user => (user ? onSignerChanged(index, user) : onRoleRemoved(index))}
													options={members}
													placeholder={formatMessage({ id: 'sign-requests-crud.placeholder-recipients' })}
												/>
												{role.Auth === 'SMS' && (
													<ValidationField
														fieldName={`roles.${index}.phone`}
														validation={validation}
														errorMessage={formatMessage({ id: 'sign-requests-crud.invalid_phone' })}>
														<InputBlock
															size={Size.sm}
															className="mt-2"
															placeholder={formatMessage({ id: 'sign-requests-crud.enter_phone_number' })}
															type="tel"
															onChange={e => onPhoneChanged(e, role)}
															value={role.Question || ''}
														/>
													</ValidationField>
												)}
												{!role.Auth && (
													<button type="button" className="text-xs text-gray-600 focus:outline-none" onClick={() => onAddSmsVerification(role)}>
														<FormattedMessage id="sign-requests-crud.add_sms_verification" />
													</button>
												)}
											</div>
										))}

									{folder === null &&
										roles.map((role, index) => (
											<div key={index}>
												<div className="flex items-center justify-between">
													<p className="flex-1 mb-2 text-sm text-gray-500">
														<FormattedMessage id="sign-requests-crud.signature_counter" values={{ n: index + 1 }} />
													</p>
													<Button circle size={Size.xs} variant={Variant.light} onClick={() => onRoleRemoved(index)} type="button">
														<FontAwesomeIcon icon="times" fixedWidth />
													</Button>
												</div>
												<InputBlock type="text" value={role.Label || ''} onChange={e => onRoleLabelChanged(index, e.target.value)} />
											</div>
										))}

									{hasSignersLeft && (
										<Button
											disabled={!canAddAnotherSigner}
											className="flex flex-col items-center justify-center space-y-1"
											intent={Intent.secondary}
											onClick={() => onRoleAdded()}
											type="button">
											<FontAwesomeIcon icon="plus" mask="circle" transform="shrink-8" size="2x" className="text-gray-600" />
											<p className="text-sm text-gray-500">
												<FormattedMessage id="sign-requests-crud.add_signature" />
											</p>
										</Button>
									)}
								</div>
							</Row>
							{folder !== null && (
								<Row>
									<Label htmlFor="enable-ordered">
										<FormattedMessage id="sign-requests-crud.set_signing_order" />
									</Label>
									<label className="flex items-start">
										<Checkbox className="mt-1 mr-2" id="enable-ordered" checked={ordered} onChange={e => setOrdered(e.target.checked)} />
										<HelperText>
											<FormattedMessage id="sign-requests-crud.set_signing_order_desc" />
										</HelperText>
									</label>
								</Row>
							)}
							{folder !== null && (
								<Row>
									<label className="flex items-start">
										<Checkbox className="mt-1 mr-2" checked={reassign} onChange={e => setReassign(e.target.checked)} />
										<HelperText>
											<FormattedMessage id="sign-requests-crud.set_signing_reassign" />
										</HelperText>
									</label>
								</Row>
							)}
						</>
					)}
				</ModalBody>

				<ModalFooter className="justify-between">
					<div className="flex items-center gap-3">
						<Button variant={Variant.primary} type="submit" disabled={!submittable || isLoading} loading={isLoading}>
							{usingTemplate ? <FormattedMessage id="sign-requests-crud.create_and_send" /> : <FormattedMessage id="sign-requests-crud.apply_signature" />}
						</Button>
						<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
							<FormattedMessage id="cancel" />
						</Button>
					</div>
					{overview && signRequest === null && (
						<ProgressBar current={(overview.contract.Data.used / overview.contract.Data.limit) * 100} className="bg-white w-36">
							<FormattedMessage id="x_of_n" values={{ x: formatNumber(overview.contract.Data.used), n: formatNumber(overview.contract.Data.limit) }} />
						</ProgressBar>
					)}
				</ModalFooter>
			</Modal>

			{fileSelectorVisible && (
				<FileSelector
					foldersAreSelectable={false}
					title={<FormattedMessage id="sign-requests-crud.select_documents" />}
					selectedItems={files}
					startingFolder={folder}
					multiple
					isItemDisabled={item => item instanceof File && !isValidDocumentForSignRequest(item)}
					onItemsSelected={onItemsSelected}
					onAfterClose={() => setFileSelectorVisible(false)}
				/>
			)}

			{unnamedSigners.length > 0 && folder !== null && <DialogSignerNames signers={unnamedSigners} folder={folder} onSuccess={onSignersNamed} onAfterClose={() => setUnnamedSigners([])} />}
		</>
	);
};

const SignerOptionRenderer = ({ ...props }: OptionProps<Collaborator, false>) => (
	<components.Option {...props}>
		<div className="flex items-center">
			<UserAvatar user={props.data} size={Size.sm} className="mr-2" />
			<div className="flex-1 truncate">
				<h6 className="text-sm font-semibold leading-tight">{props.data.Name || props.data.Email}</h6>
				{props.data.Name && <p className={`${props.isSelected ? 'text-white text-opacity-75' : 'text-gray-500'} text-sm`}>{props.data.Email}</p>}
			</div>
		</div>
	</components.Option>
);

const TemplateOptionRenderer = ({ data, ...props }: OptionProps<SignRequest, false>) => (
	<components.Option data={data} {...props}>
		<h6 className="text-lg font-semibold">{data.Label}</h6>
		<p className="mt-1 text-sm">
			{joinWithObject(
				data.documents.map((document: File) => (
					<span key={document.getKey()} className="text-sm">
						{document.icon('mx-1')}
						{document.getName()}
					</span>
				)),
				<>, </>
			)}
		</p>
	</components.Option>
);
