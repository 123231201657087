import { useIndexedDatabase, type ConvofloDatabase } from '@hooks/use-indexed-db';
import { useAccount } from '@hooks/useAccount';
import { useMutation, useQuery, useQueryClient } from 'react-query';

type UseMessageDraftOptions = {
	enabled?: boolean;
	onSaved?: () => void;
};

export const useMessageDraft = (key: string, { enabled, onSaved }: UseMessageDraftOptions = { enabled: true, onSaved: () => undefined }) => {
	const queryClient = useQueryClient();
	const { account } = useAccount();
	const db = useIndexedDatabase();

	const { data: draft, isFetched } = useQuery(
		['drafts', account?.ID, key],
		async () => {
			return await (await db).get('drafts', `${account!.ID!}-${key}`);
		},
		{
			onError: console.error,
			enabled: enabled && !!account?.ID
		}
	);

	const {
		mutateAsync: save,
		isLoading: isSaving,
		isSuccess: isSaved
	} = useMutation<boolean, Error, Omit<ConvofloDatabase['drafts']['value'], 'updated_at'>>(
		async ({ title, body, pluginsData }) => {
			if (!enabled) {
				return false;
			}

			await (await db).put('drafts', { title, body, pluginsData, updated_at: new Date() }, `${account!.ID!}-${key}`);

			return true;
		},
		{
			onError: console.error,
			onSuccess: () => {
				onSaved?.();
				queryClient.invalidateQueries(['drafts', account?.ID, key]);
			}
		}
	);

	const {
		mutateAsync: discard,
		isLoading: isDiscarding,
		isSuccess: isDiscarded
	} = useMutation<boolean, Error, void>(
		async () => {
			if (!enabled) {
				return false;
			}

			await (await db).delete('drafts', `${account!.ID}-${key}`);
			return true;
		},
		{
			onError: console.error,
			onSuccess: () => {
				queryClient.removeQueries(['drafts', account?.ID, key]);
			}
		}
	);

	return [draft, { discard, save, isFetched, isSaving, isSaved, isDiscarding, isDiscarded }] as const;
};
