import { Logo } from '@components/Logo';
import AppContext from '@contexts/AppContext';
import { Button, HelperText, Input, Label, Row, ValidationField, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePasswordStrengthCheck } from '@hooks/use-password-strength-check';
import useClient from '@hooks/useClient';
import { useResetPasswordMutation } from '@state/queries/account';
import Card from '@ui/Card';
import ProgressBar from '@ui/ProgressBar';
import { type FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

export const NewPasswordPage = () => {
	const { validation } = useClient();
	const { state } = useLocation<{ next: Location }>();
	const history = useHistory();
	const { formatMessage } = useIntl();
	const { setPageTitle: setTitle } = useContext(AppContext);

	const next = useRef<string>(state?.next.href || '/');

	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	const passwordStrength = usePasswordStrengthCheck(password);

	const { mutateAsync: resetPassword, isLoading } = useResetPasswordMutation();

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		try {
			await resetPassword({ password, passwordConfirmation });
			history.replace(next.current);
		} catch {
			// TODO: Show error message to user
		}
	};

	useEffect(() => {
		setTitle(formatMessage({ id: 'login.title.reset_password' }));
	}, [setTitle, formatMessage]);

	return (
		<div className="flex flex-col min-h-screen bg-gray-200">
			<div className="container flex flex-col items-center justify-center flex-1 max-w-sm px-2 py-8 mx-auto">
				<div className="max-w-full mb-8">
					<Logo light={true} className="h-12" />
				</div>
				<Card>
					<h2 className="mb-8 text-xl font-semibold text-center text-gray-700">
						<FormattedMessage id="login.change_password_intro" />
					</h2>
					<form onSubmit={onSubmit}>
						<fieldset disabled={isLoading}>
							<Row>
								<Label htmlFor="password">
									<FormattedMessage id="login.password" />
								</Label>
								<ValidationField fieldName="password" validation={validation}>
									<Input block className="rounded-b-none" tabIndex={1} id="password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
								</ValidationField>
								<ProgressBar max={100} current={passwordStrength ?? 0} reverseColors className="h-3 rounded-t-none" />
								<HelperText>
									<FormattedMessage id="signup.password_helper" />
								</HelperText>
							</Row>
							<Row>
								<Label htmlFor="password_confirmation">
									<FormattedMessage id="login.password_confirmation" />
								</Label>
								<ValidationField fieldName="password_confirmation" validation={validation}>
									<Input block tabIndex={2} id="password_confirmation" type="password" value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)} />
								</ValidationField>
							</Row>
							<Row>
								<Button block tabIndex={3} type="submit" variant={Variant.primary} loading={isLoading}>
									{isLoading ? <FontAwesomeIcon icon="spinner" pulse /> : <FormattedMessage id="login.change_password" />}
								</Button>
							</Row>
						</fieldset>
					</form>
				</Card>
				<p className="mt-4 text-xs text-gray-500">
					<FormattedMessage id="copyright.short" values={{ year: new Date().getFullYear() }} />
				</p>
			</div>
		</div>
	);
};
