import { Title } from '@components/Account';
import { DialogSignatureRequestAccess, SignRequestDashboard, SignRequestsPromo } from '@components/SignRequest';
import { SignatureLayoutsDashboard } from '@components/SignRequest/SignatureLayoutsDashboard';
import { SignatureTemplatesDashboard } from '@components/SignRequest/SignatureTemplatesDashboard';
import AppContext from '@contexts/AppContext';
import { Size, Toggle } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { useSignRequestOverviewQuery } from '@state/queries/sign-requests';
import { Module } from '@types';
import { Sidebar, SidebarGroup, SidebarLink } from '@ui/Layout';
import ProgressBar from '@ui/ProgressBar';
import { formatDate } from '@utils/DateUtils';
import { isFuture, parseISO } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Route, Switch } from 'react-router-dom';

export const DashboardSignaturesPage = () => {
	const { account } = useAccount();
	const { setPageTitle: setTitle, setPageHeader } = useContext(AppContext);
	const { formatMessage } = useIntl();
	const { locale, formatNumber } = useIntl();

	const [scope, setScope] = useLocalStorage<'user' | 'organization'>('dashboard.signatures.scope', 'user');
	const [showRequestAccessDialog, setShowRequestAccessDialog] = useState(false);

	const { data: overview } = useSignRequestOverviewQuery(scope);

	useEffect(() => {
		setTitle(formatMessage({ id: 'dashboard-esign.title' }));
	}, [setTitle, formatMessage]);

	useEffect(() => {
		setPageHeader(
			<Title icon="signature">
				<FormattedMessage id="dashboard-esign.title" />
			</Title>
		);
	}, [setPageHeader]);

	if (!account!.business.hasModule(Module.Esign)) {
		return (
			<Scrollbars autoHide>
				<div className="bg-theme-canvas-lightest">
					<SignRequestsPromo />
				</div>
			</Scrollbars>
		);
	}

	return (
		<>
			<div className="relative flex flex-col h-full overflow-hidden">
				{overview?.IsSandbox && (
					<div className="relative flex flex-col px-3 py-4 overflow-hidden min-h-20 bg-gradient-to-br from-yellow-700 to-yellow-500">
						<p className="absolute px-12 py-1 mr-2 text-xs font-bold text-white -rotate-45 translate-y-2 -translate-x-14 bg-black/15">
							<FormattedMessage id="trial_mode" />
						</p>
						<p className="pl-20 my-auto text-sm font-light text-white sm:text-base">
							<FormattedMessage
								id="signatures.trial_mode_banner"
								values={{
									a: msg => (
										<button type="button" className="underline" onClick={() => setShowRequestAccessDialog(true)}>
											{msg}
										</button>
									),
									tel: msg => (
										<a href="tel:+18008977432" className="underline">
											{msg}
										</a>
									)
								}}
							/>
						</p>
					</div>
				)}
				<div className="p-3 text-sm text-yellow-600 bg-yellow-100 md:hidden">
					<FontAwesomeIcon icon="exclamation-triangle" className="mr-2" />
					<FormattedMessage id="mobile_warning" />
				</div>

				<div className="flex-1 h-full md:flex md:flex-row">
					<aside className="mt-8 w-36 sm:w-64">
						<Scrollbars autoHide>
							<Sidebar className="pr-2 mb-6">
								<SidebarLink to="/dashboard/signatures" icon="signature" count={overview?.totals.all}>
									<FormattedMessage id="sign-requests-crud.all" />
								</SidebarLink>
								<SidebarLink to={{ pathname: '/dashboard/signatures', search: 'filter=draft' }} icon="edit" count={overview?.totals.draft}>
									<FormattedMessage id="sign-requests-crud.draft-plural" />
								</SidebarLink>
								<SidebarLink to={{ pathname: '/dashboard/signatures', search: 'filter=sent' }} icon="paper-plane" count={overview?.totals.sent}>
									<FormattedMessage id="sign-requests-crud.sent-plural" />
								</SidebarLink>
								<SidebarLink to={{ pathname: '/dashboard/signatures', search: 'filter=completed' }} icon="check-circle" count={overview?.totals.completed}>
									<FormattedMessage id="sign-requests-crud.completed-plural" />
								</SidebarLink>
								<SidebarLink to={{ pathname: '/dashboard/signatures', search: 'filter=archived' }} icon="inbox-in" count={overview?.totals.archived}>
									<FormattedMessage id="sign-requests-crud.archived-plural" />
								</SidebarLink>
								<SidebarGroup>
									<FormattedMessage id="sign-requests-crud.others" />
								</SidebarGroup>
								<SidebarLink to="/dashboard/signatures/templates" icon="layer-group" count={overview?.totals.templates}>
									<FormattedMessage id="dashboard-esign.templates" />
								</SidebarLink>
								<SidebarLink to="/dashboard/signatures/layouts" icon="object-ungroup" count={overview?.totals.layouts}>
									<FormattedMessage id="dashboard-esign.layout" />
								</SidebarLink>

								{account!.isAdmin() && (
									<div className="mt-6">
										<div className="h-px bg-gradient-to-r from-gray-300 to-gray-200" />
										<div className="flex flex-col items-start px-4 py-6 space-y-2">
											<label className="text-sm font-medium text-gray-600">
												<FormattedMessage id="sign-requests-crud.show_org" />
											</label>
											{/* <p className="text-xs text-gray-500">Will show every signature request of every user.</p> */}
											<Toggle size={Size.sm} checked={scope === 'organization'} onChange={e => setScope(e.target.checked ? 'organization' : 'user')} />
										</div>
										{overview && (
											<>
												<div className="h-px bg-gradient-to-r from-gray-300 to-gray-200" />
												<div className="px-4 py-6 space-y-2">
													{overview.contract.ends_at && !isFuture(parseISO(overview.contract.ends_at)) && (
														<p className="text-xs text-red-500">
															<FormattedMessage id="sign-requests-crud.contract_expired_on" values={{ date: formatDate(overview.contract.ends_at, locale, 'PP') }} />
														</p>
													)}
													{overview.contract.ends_at && isFuture(parseISO(overview.contract.ends_at)) && (
														<p className="text-xs text-gray-500">
															<FormattedMessage id="sign-requests-crud.contract_ends_on" values={{ date: formatDate(overview.contract.ends_at, locale, 'PP') }} />
														</p>
													)}
													<ProgressBar current={(overview.contract.Data.used / overview.contract.Data.limit) * 100} className="w-full bg-white">
														<FormattedMessage id="x_of_n" values={{ x: formatNumber(overview.contract.Data.used), n: formatNumber(overview.contract.Data.limit) }} />
													</ProgressBar>
													<p className="text-xs">
														{overview.IsSandbox && !account?.business.subscription ? (
															<Link to="/account/checkout" className="text-theme-primary hover:underline">
																<FormattedMessage id="dashboard-esign.increase_limit" />
															</Link>
														) : (
															<a
																href={`${import.meta.env.VITE_SITE_URL}/contact`}
																target="_blank"
																rel="noopener noreferrer"
																className="text-theme-primary hover:underline">
																<FormattedMessage id="dashboard-esign.increase_limit" />
															</a>
														)}
													</p>
												</div>
											</>
										)}
									</div>
								)}
							</Sidebar>
						</Scrollbars>
					</aside>

					<article className="flex-1 h-full">
						<Scrollbars autoHide>
							<div className="pb-6 mt-8 md:px-8 md:pb-12">
								<Switch>
									<Route path="/dashboard/signatures/templates">
										<SignatureTemplatesDashboard view={scope} />
									</Route>
									<Route path="/dashboard/signatures/layouts">
										<SignatureLayoutsDashboard />
									</Route>
									<Route>
										<SignRequestDashboard view={scope} showTabs={false} />
									</Route>
								</Switch>
							</div>
						</Scrollbars>
					</article>
				</div>
			</div>
			<DialogSignatureRequestAccess isOpen={showRequestAccessDialog} onAfterClose={() => setShowRequestAccessDialog(false)} />
		</>
	);
};
