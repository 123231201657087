import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type FC, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useAccountVerification } from './use-account-verification';

type VerificationStatusProps = {};

export const VerificationStatus: FC<VerificationStatusProps> = () => {
	const { verified, verificationEmailSent } = useAccountVerification(5000);

	useEffect(() => {
		if (verificationEmailSent) {
			toast.success(<FormattedMessage id="welcome.verification_email_send" />);
		}
	}, [verificationEmailSent]);

	return (
		<div className="p-3 text-white rounded-md bg-black/25 ring ring-white/10">
			{!verified && (
				<>
					<div className="flex items-center space-x-2">
						<span className="relative flex w-3 h-3">
							<span className="absolute inline-flex w-full h-full bg-yellow-400 rounded-full opacity-75 animate-ping"></span>
							<span className="relative inline-flex w-3 h-3 bg-yellow-300 rounded-full"></span>
						</span>
						<span className="font-medium text-yellow-200">
							<FormattedMessage id="onboarding.verification-status.waiting" />
						</span>
					</div>
					<p className="mt-4 text-sm">
						<FormattedMessage id="onboarding.verification-status.help" />
					</p>
				</>
			)}
			{verified && (
				<div className="flex items-center space-x-2">
					<FontAwesomeIcon icon="check-circle" className="text-green-400" />
					<span className="font-medium text-green-400">
						<FormattedMessage id="onboarding.verification-status.verified" />
					</span>
				</div>
			)}
		</div>
	);
};
