import { Logo } from '@components/Logo';
import { OnboardingStep } from '@components/Onboarding';
import { VerificationStatus } from '@components/Onboarding/VerificationStatus';
import AppContext from '@contexts/AppContext';
import { useAccount } from '@hooks/useAccount';
import { ViewProvider } from '@providers/ViewProvider';
import { useContext, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { OnboardingNextStepsPage } from './next-steps';
import { OnboardingOrganizationPage } from './organization';
import { OnboardingSecureSpacePage } from './secure-space';
import { OnboardingVerificationPage } from './verification';

export const OnboardingPage = () => {
	const { formatMessage } = useIntl();
	const { account } = useAccount();
	const { setPageTitle: setTitle } = useContext(AppContext);
	const location = useLocation();
	let match = useRouteMatch();

	useEffect(() => {
		setTitle(formatMessage({ id: 'welcome.page_title' }));
	}, [setTitle, formatMessage]);

	const currentStep = useMemo(() => {
		if (location.pathname === '/onboarding/verification') {
			return 1;
		} else if (location.pathname === '/onboarding/secure-space') {
			return 2;
		} else if (location.pathname === '/onboarding/next-steps') {
			return 3;
		}

		return 0;
	}, [location.pathname]);

	return (
		<div className="h-screen bg-gradient-to-br from-gray-100 via-gray-50 to-white md:flex">
			<div className="flex flex-col px-6 py-6 overflow-auto bg-theme-inverse md:py-16 md:px-8 md:w-96">
				<div className="mb-6 md:mb-16">
					<Logo dark className="h-12" />
				</div>
				<div className="flex-1">
					<h1 className="mb-6 text-4xl font-bold text-white">
						<FormattedMessage id="welcome.page_title" />
					</h1>
					<p className="text-sm text-white">
						<FormattedMessage id="welcome.sub" />
					</p>

					<ul className="my-8 space-y-6 md:my-12">
						<OnboardingStep done>
							<FormattedMessage id="onboarding.created_account" />
						</OnboardingStep>
						{account?.isAdmin() && (
							<OnboardingStep current={currentStep === 0} done={currentStep > 0}>
								<FormattedMessage id="onboarding.your_organization" />
							</OnboardingStep>
						)}
						<OnboardingStep current={currentStep === 1} done={currentStep > 1}>
							<FormattedMessage id="onboarding.verification" />
						</OnboardingStep>
						{account?.hasFullAccess() && (
							<>
								<OnboardingStep current={currentStep === 2} done={currentStep > 2}>
									<FormattedMessage id="onboarding.create_secure_space" />
								</OnboardingStep>
								<OnboardingStep current={currentStep === 3} done={currentStep > 3}>
									<FormattedMessage id="onboarding.next_steps" />
								</OnboardingStep>
							</>
						)}
					</ul>
				</div>
				{currentStep < 1 && (
					<div className="hidden md:block">
						<VerificationStatus />
					</div>
				)}
			</div>
			<div className="flex flex-col flex-1 pb-24 overflow-auto md:pb-0">
				<div className="max-w-2xl px-6 py-6 md:py-16 md:px-8 lg:px-16">
					<Switch location={location}>
						<Route path={`${match.path}/organization`}>
							<OnboardingOrganizationPage />
						</Route>
						<Route path={`${match.path}/verification`}>
							<OnboardingVerificationPage />
						</Route>
						<Route path={`${match.path}/secure-space`}>
							<ViewProvider>{view => <OnboardingSecureSpacePage />}</ViewProvider>
						</Route>
						<Route path={`${match.path}/next-steps`}>
							<OnboardingNextStepsPage />
						</Route>
						<Route path={`${match.path}`}>
							<Redirect to={`${match.path}/organization`} />
						</Route>
					</Switch>
				</div>
			</div>
		</div>
	);
};
