import { ExcelViewer } from '@components/ExcelViewer';
import { EXCEL_TYPES, IMAGE_TYPES, PDF_TYPES } from '@components/FileManager/DialogPreview';
import { useGallery } from '@components/FileManager/hooks/use-gallery';
import FullScreenLoading from '@components/FullScreenLoading';
import PDFViewer from '@components/PDFViewer';
import PhotoViewer from '@components/PhotoViewer';
import AppContext from '@contexts/AppContext';
import { Button, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import File, { FileVersionSource } from '@models/File';
import { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import type { WretchError } from 'wretch';

export const PreviewPage = () => {
	const { account } = useAccount();
	const { setPageTitle: setTitle } = useContext(AppContext);
	const { state } = useLocation<{ view: File }>();
	const { url1, url2 } = useParams<{ url1: string; url2: string }>();
	const { formatMessage } = useIntl();
	const { client } = useClient();
	const history = useHistory();

	const { data: file, isLoading: isFileLoading } = useQuery(['view', `file:${url1}/${url2}`], async () => new File(await client.url(`documents/${url1}/${url2}`).get().json()), {
		enabled: !(state?.view instanceof File) || !state?.view.current,
		initialData: state?.view ? new File(state.view) : undefined,
		staleTime: Infinity,
		refetchOnMount: 'always'
	});

	const { data: fileLocation, isLoading: isFileLocationLoading } = useQuery<string, WretchError>(
		['file_preview', file?.current.Id],
		async () => await client.url(`documents/${file?.getKey()}/download?mode=preview`).get().text(),
		{
			enabled: file instanceof File,
			staleTime: Infinity,
			refetchOnMount: 'always',
			onError: error => {
				if (error.status === 402) {
					history.goBack();
				} else if (error.status === 302 && error.json.error.code === 'ongoing_sign_request') {
					history.push(error.json.error.message);
					return;
				}
			}
		}
	);

	const { mutate: download, isLoading: isDownloading } = useMutation(async () => await client.url(`documents/${file?.getKey()}/download?mode=download`).get().text(), {
		onSuccess: link => {
			window.location.href = link;
		}
	});

	const navigate = (_file: File) => {
		history.push(_file.getUrl('view'), { view: _file });
	};

	const { previousFile, nextFile } = useGallery(file, navigate);

	useEffect(() => {
		if (file instanceof File) {
			setTitle(file.getName());
		} else {
			setTitle(formatMessage({ id: 'loading' }));
		}
	}, [file, formatMessage, setTitle]);

	if (isFileLoading || isFileLocationLoading || !file || !fileLocation) {
		return <FullScreenLoading />;
	}

	const isPdf = file.current.Source !== FileVersionSource.External && fileLocation && PDF_TYPES.includes(file.current.MimeType);
	const isImage = file.current.Source !== FileVersionSource.External && fileLocation && IMAGE_TYPES.includes(file.current.MimeType);
	const isExcel = file.current.Source !== FileVersionSource.External && fileLocation && EXCEL_TYPES.includes(file.current.MimeType);

	if (file.current.ExternalLink !== null) {
		window.location.href = file.current.ExternalLink;
	}

	return (
		<>
			{isPdf && <PDFViewer file={file} location={fileLocation} />}
			{isImage && <PhotoViewer file={file} location={fileLocation} />}
			{isExcel && <ExcelViewer file={file} location={fileLocation} />}
			{!isPdf && !isImage && <Redirect to={file.getUrl()} />}
			<div className="flex items-center justify-between px-8 py-2 text-white bg-gray-900">
				<div className="space-x-2">
					{account !== null && previousFile && (
						<button className="group focus:outline-none" onClick={() => navigate(previousFile)}>
							{!previousFile.current.Thumbnail && (
								<div className="flex items-center float-right w-32 h-24 rounded-sm">
									<FontAwesomeIcon icon="arrow-left" className="mx-auto text-3xl" />
								</div>
							)}
							{previousFile.current.Thumbnail && (
								<>
									<div className="absolute flex items-center w-32 h-24 duration-150 bg-black rounded-sm opacity-0 group-hover:opacity-80">
										<FontAwesomeIcon icon="arrow-left" className="mx-auto text-3xl" />
									</div>
									<div
										className="w-32 h-24 bg-transparent bg-center bg-no-repeat bg-cover rounded-md "
										style={{ backgroundImage: `url(${'' + previousFile.current.Thumbnail}` }}></div>
								</>
							)}
						</button>
					)}
				</div>
				<div className="space-x-2">
					<Button variant={Variant.light} size={Size.sm} onClick={() => download()} disabled={isDownloading || file.RestrictDownloads}>
						{isDownloading ? <FontAwesomeIcon spin icon="spinner-third" className="mr-2" /> : <FontAwesomeIcon icon="download" className="mr-2" />}
						<FormattedMessage id="file-manager.download" />
					</Button>
				</div>
				<div className="space-x-2">
					{account !== null && nextFile && (
						<button className="group focus:outline-none" onClick={() => navigate(nextFile)}>
							{!nextFile.current.Thumbnail && (
								<div className="flex items-center float-right w-32 h-24 rounded-sm">
									<FontAwesomeIcon icon="arrow-right" className="mx-auto text-3xl" />
								</div>
							)}
							{nextFile.current.Thumbnail && (
								<>
									<div className="absolute flex items-center w-32 h-24 duration-150 bg-black rounded-sm opacity-0 group-hover:opacity-80">
										<FontAwesomeIcon icon="arrow-right" className="mx-auto text-3xl" />
									</div>
									<div
										className="float-right w-32 h-24 bg-transparent bg-center bg-no-repeat bg-cover rounded-md"
										style={{ backgroundImage: `url(${'' + nextFile.current.Thumbnail}` }}></div>
								</>
							)}
						</button>
					)}
				</div>
			</div>
		</>
	);
};
