import { type Comment, MessageListContext, removeMessage } from '@components/Message';
import type { ResourceLengthAwarePaginatorType } from '@components/Pagination';
import { Alert, Button, Intent, Modal, ModalBody, ModalFooter, type ModalProps, Variant } from '@convoflo/ui';
import useClient from '@hooks/useClient';
import { type FC, type FormEvent, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { type InfiniteData, useMutation, useQueryClient } from 'react-query';

type DialogDeleteMessageProps = Omit<ModalProps, 'isOpen'> & {
	message: Comment;
};

export const DialogDeleteMessage: FC<DialogDeleteMessageProps> = ({ message, ...modalProps }) => {
	const { client } = useClient();
	const queryClient = useQueryClient();
	const { queryKey } = useContext(MessageListContext);

	const [isOpen, setIsOpen] = useState(true);

	const { mutate: _delete, isLoading: isDeleting } = useMutation(async () => await client.url(`comments/${message.ID}`).delete().json(), {
		onSuccess: () => {
			setIsOpen(false);
			toast.success(<FormattedMessage id="comments.deleted" />);
		},
		onMutate: async () => {
			await queryClient.cancelQueries(queryKey);

			setIsOpen(false);

			const previousMessages = queryClient.getQueryData<InfiniteData<ResourceLengthAwarePaginatorType<Comment>>>(queryKey);

			if (previousMessages === undefined) {
				return undefined;
			}

			const newMessages = removeMessage(message, previousMessages);

			if (!message.parent) {
				newMessages.pages[0].meta.total = newMessages.pages[0].meta.total - 1;
			}

			queryClient.setQueryData<InfiniteData<ResourceLengthAwarePaginatorType<Comment>>>(queryKey, newMessages);

			return previousMessages;
		},
		onError: (_error, _variables, previousMessages) => {
			queryClient.setQueryData(queryKey, previousMessages);
		}
	});

	const onDeleteSubmitted = (e: FormEvent) => {
		e.preventDefault();
		_delete();
	};

	return (
		<Modal isOpen={isOpen} onSubmit={onDeleteSubmitted} {...modalProps}>
			<ModalBody className="prose max-w-none">
				<p>
					<FormattedMessage id="comments.remove_message.description" />
				</p>
				{message.attachments.length > 0 && (
					<Alert variant={Variant.warning}>
						<FormattedMessage id="comments.warning_delete_attachments" />
					</Alert>
				)}
			</ModalBody>
			<ModalFooter>
				<Button disabled={isDeleting} type="submit" className="mr-2" variant={Variant.danger} loading={isDeleting}>
					{isDeleting ? <FormattedMessage id="deleting" /> : <FormattedMessage id="delete" />}
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
