import { Checkbox } from '@convoflo/ui';
import type { Fileable } from '@types';
import { type FC, type KeyboardEvent, useState } from 'react';

type EditableFileableItemProps = {
	fileable: Fileable;
	onEnter?: (folder: string) => void;
	onCancel?: () => void;
};

export const EditableFileableItem: FC<EditableFileableItemProps> = ({ fileable, onEnter = () => undefined, onCancel = () => undefined }) => {
	const [name, setName] = useState('');
	const [submitting, setSubmitting] = useState(false);
	const onKeyDown = (e: KeyboardEvent) => {
		if (e.key === 'Escape') {
			onCancel();
		}

		if (e.key === 'Enter') {
			onEnter(name);
			setSubmitting(true);
		}
	};

	return (
		<div className="flex text-sm bg-theme-primary-lightest">
			<Checkbox className="invisible mx-2" />

			<div className="flex items-center flex-1 p-2">
				{fileable.icon('mr-2')}
				<input
					type="text"
					autoFocus
					onKeyDown={onKeyDown}
					value={name}
					onChange={e => setName(e.target.value)}
					className="w-full px-2 py-1 text-sm border-0 focus:border-0"
					disabled={submitting}
					onBlur={onCancel}
				/>
			</div>
		</div>
	);
};
