import { DialogPreview } from '@components/FileManager';
import { Dropzone, UploadButton, type UploadButtonHandles, UploadFile, UploadStatus, useUploadQueue } from '@components/Upload';
import { Button, Intent, Modal, ModalBody, ModalFooter, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import Collaborator from '@models/Collaborator';
import File from '@models/File';
import type { Modify } from '@types';
import { formatDate } from '@utils/DateUtils';
import set from 'lodash.set';
import { type FC, useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import type { WretchError } from 'wretch/types';
import { MessageFileAttachment, PendingMessageFileAttachment } from '../../MessageFileAttachment';
import { MessageListContext } from '../../contexts';
import type { AttachmentType, FileRequestType } from '../../types';

type MessageFileRequestAttachmentProps = {
	attachment: Modify<AttachmentType, { item: FileRequestType }>;
	onClick?: () => void;
	className?: string;
};

const emptyFiles: UploadFile[] = [];
const emptyUsers: Collaborator[] = [];

export const MessageFileRequestAttachment: FC<MessageFileRequestAttachmentProps> = ({ attachment: initialAttachment, onClick, className }) => {
	const { client } = useClient();
	const { account } = useAccount();
	const { locale } = useIntl();

	const { fileable: secureSpace } = useContext(MessageListContext);
	const { files: pendingFiles, onFileUpdated, add, clear, isFinished, fileable } = useUploadQueue(initialAttachment.item.Folder ?? secureSpace, emptyFiles, emptyUsers);

	const [attachmentPreview, setAttachmentPreview] = useState<File>();
	const [attachment, setAttachment] = useState(initialAttachment);
	const [showConfirmationNoFiles, setShowConfirmationNoFiles] = useState(false);

	const uploader = useRef<UploadButtonHandles>(null);

	const selectFiles = () => {
		if (uploader.current !== null) {
			uploader.current.chooseFiles();
		}
	};

	const onFilesSelected = (files: globalThis.File[]) => {
		add(files.map(file => UploadFile.fromFile(file, fileable, emptyUsers)).filter(file => file.file));
	};

	const attachmentOnClick = async (attachment: AttachmentType | File) => {
		const file = attachment['@type'] === 'Document' ? new File(attachment) : new File(attachment.item);

		if (file.isPreviewable()) {
			setAttachmentPreview(file);
		} else {
			const url = await client.url(file.getRoute('download')).get().text();
			window.location.href = url;
		}
	};

	const { mutate: attachFilesToFileRequest } = useMutation<FileRequestType, WretchError, File[]>(
		async files =>
			await client
				.url(`folders/${secureSpace!.getKey()}/file-requests/${attachment.item.Id}`)
				.json({
					files: files.map(file => file.Id)
				})
				.put()
				.json<FileRequestType>(),
		{
			onSuccess: fileRequest => {
				setAttachment(attachment => set(attachment, 'item', fileRequest));
				clear();
			}
		}
	);

	const markAsCompleted = () => {
		setShowConfirmationNoFiles(true);
	};

	const { mutate: _markAsCompleted } = useMutation<FileRequestType, WretchError>(
		async () =>
			await client
				.url(`folders/${secureSpace!.getKey()}/file-requests/${attachment.item.Id}`)
				.json({
					completed: true
				})
				.put()
				.json<FileRequestType>(),
		{
			onMutate: () => {
				setShowConfirmationNoFiles(false);
			},
			onSuccess: fileRequest => {
				toast.success(<FormattedMessage id="file-requests.complete_completed" />);
				setAttachment(attachment => set(attachment, 'item', fileRequest));
			}
		}
	);

	useEffect(() => {
		if (!isFinished || pendingFiles.length === 0) {
			return;
		}

		attachFilesToFileRequest(pendingFiles.filter(file => file.status === UploadStatus.Completed).map(file => file.model!));
	}, [isFinished, pendingFiles, attachFilesToFileRequest]);

	const canAddFiles = attachment.item.users === undefined && attachment.item.completed_at === null;
	const canManage = attachment.item.creator.ID === account?.ID;

	return (
		<div>
			<div className="grid grid-cols-1 gap-1 mb-2 sm:gap-2 sm:flex">
				{canAddFiles && (
					<>
						<Dropzone onFilesDropped={onFilesSelected} />
						<UploadButton ref={uploader} multiple={true} onFilesSelected={onFilesSelected} />
						<button
							className="inline-flex items-center justify-center w-48 gap-2 text-sm italic text-gray-500 transition-colors border-2 border-gray-200 border-dashed rounded-md cursor-pointer bg-gray-200/50 hover:bg-gray-200/75"
							onClick={selectFiles}>
							<FontAwesomeIcon icon="file-plus" />
							<span>
								<FormattedMessage id="file-manager.add_files" />
							</span>
						</button>
					</>
				)}
				{(attachment.item.files.length > 0 || pendingFiles.length > 0) && (
					<>
						{attachment.item.files.map(file => (
							<MessageFileAttachment key={file.Id} name={file.current.Name} onClick={() => attachmentOnClick(file)} src={file.current.Thumbnail ?? undefined} />
						))}
						{pendingFiles.map(file => (
							<PendingMessageFileAttachment key={file.id} fileable={fileable!} file={file} start={true} onFileUpdated={onFileUpdated} />
						))}
					</>
				)}
			</div>
			{canManage && attachment.item.files.length === 0 && (
				<p className="px-3 py-2 text-xs italic bg-yellow-100 text-black/50">
					<FormattedMessage id="no_files_added_yet" />
				</p>
			)}
			{canAddFiles && (
				<div className="flex flex-col gap-2 mt-3 sm:items-center sm:flex-row">
					<Button size={Size.sm} variant={Variant.primary} disabled={!isFinished} intent={Intent.primary} onClick={() => markAsCompleted()}>
						<FormattedMessage id="file-requests.finalize_request" />
					</Button>
					<span className="text-xs text-gray-500">
						<FormattedMessage id="file-requests.finalize_request_info" values={{ i: msg => <em className="italic">{msg}</em>, creator: attachment.item.creator.Name }} />
					</span>
					<Modal isOpen={showConfirmationNoFiles}>
						<ModalBody>
							<p>
								<FormattedMessage id="file-requests.complete_confirmation" values={{ user: attachment.item.creator.Name }} />
							</p>
						</ModalBody>
						<ModalFooter>
							<Button variant={Variant.primary} intent={Intent.primary} type="button" icon="save" onClick={() => _markAsCompleted()}>
								<FormattedMessage id="confirm" />
							</Button>
							<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setShowConfirmationNoFiles(false)} type="button">
								<FormattedMessage id="cancel" />
							</Button>
						</ModalFooter>
					</Modal>
				</div>
			)}
			{canManage && attachment.item.completed_at !== null && (
				<div className="inline-flex items-center p-2 bg-green-100 border border-green-400 rounded-md">
					<FontAwesomeIcon icon="check-circle" className="mr-1.5 text-xs text-green-500" />
					<span className="text-xs font-medium text-green-700">
						<FormattedMessage id="file-requests.completed_on" values={{ date: formatDate(attachment.item.completed_at, locale) }} />
					</span>
				</div>
			)}
			{canManage && attachment.item.completed_at === null && !attachment.item.Shared && (
				<div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
					{attachment.item.users.map(user => (
						<p className="mt-3 text-xs italic text-gray-600">
							{user.pivot.completed_at ? (
								<FontAwesomeIcon fixedWidth icon="check" className="mr-1 text-green-600" />
							) : (
								<FontAwesomeIcon fixedWidth icon="hourglass-half" className="mr-1 text-blue-600" />
							)}
							{user.Name}
						</p>
					))}
				</div>
			)}

			{attachmentPreview !== undefined && <DialogPreview file={attachmentPreview} onAfterClose={() => setAttachmentPreview(undefined)} />}
		</div>
	);
};
