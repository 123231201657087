/* eslint-disable react/style-prop-object */
import { BillingInformationStep, CheckoutProvider, ExtrasStep, ModulesStep, PlansStep, useCart, useStore } from '@components/Checkout';
import { SupportPlansStep } from '@components/Checkout/SupportPlansStep';
import { OnboardingStep } from '@components/Onboarding';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Elements } from '@stripe/react-stripe-js';
import { type StripeElementLocale, loadStripe } from '@stripe/stripe-js';
import { OnboardingLayout } from '@ui/Layout/OnboardingLayout';
import { filesize } from '@utils/StringUtils';
import classNames from 'classnames';
import { type FC, useMemo, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Route, Switch, useParams } from 'react-router-dom';
import { defaultCurrency, extraStorageBlock } from '../../constants';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY ?? '');

export const CheckoutPage = () => {
	const { locale } = useIntl();
	const { step: currentStepKey = 'plans' } = useParams<{ step: string }>();

	const steps = useMemo(
		() => [
			{ key: 'plans', label: <FormattedMessage id="plans.plan_selection" /> },
			{ key: 'extras', label: <FormattedMessage id="plans.extras" /> },
			{ key: 'modules', label: <FormattedMessage id="plans.modules" /> },
			{ key: 'support', label: <FormattedMessage id="plans.support_plans" /> },
			{ key: 'billing', label: <FormattedMessage id="plans.billing_information" /> }
		],
		[]
	);

	const currentStepIndex = steps.findIndex(step => step.key === currentStepKey);

	return (
		<CheckoutProvider>
			<OnboardingLayout
				sidebarCenterContents={
					<div className="flex-1">
						<ul className="flex flex-col gap-2 my-3 sm:gap-3 lg:gap-6">
							{steps.map((step, index) => (
								<OnboardingStep key={step.key} current={currentStepIndex === index} done={index < currentStepIndex}>
									{step.label}
								</OnboardingStep>
							))}
						</ul>
					</div>
				}
				sidebarBottomContents={<SummaryBox />}>
				<Switch>
					<Route path="/account/checkout/modules">
						<ModulesStep />
					</Route>
					<Route path="/account/checkout/extras">
						<ExtrasStep />
					</Route>
					<Route path="/account/checkout/support">
						<SupportPlansStep />
					</Route>
					<Route path="/account/checkout/billing">
						<Elements stripe={stripePromise} options={{ locale: locale as StripeElementLocale }}>
							<BillingInformationStep />
						</Elements>
					</Route>
					<Route>
						<PlansStep />
					</Route>
				</Switch>
			</OnboardingLayout>
		</CheckoutProvider>
	);
};

const SummaryBox: FC = () => {
	const { cart, isLoading, removeItemById } = useCart();
	const { store } = useStore();
	const { locale } = useIntl();

	const [collapsed, setCollapsed] = useState(true);

	if (!cart || !store || cart.items.length === 0) {
		return null;
	}

	return (
		<div className={classNames('mt-3 px-2 py-1 lg:p-4 text-sm text-white border rounded-md border-white/25 bg-white/5 transition-opacity', { 'opacity-50': isLoading })}>
			<button type="button" onClick={() => setCollapsed(collapsed => !collapsed)} className="flex items-center w-full gap-2 p-1 font-semibold cursor-pointer">
				<FontAwesomeIcon icon="caret-down" className={classNames('transition-transform lg:hidden', { '-rotate-90': collapsed })} />
				<h2 className="font-serif text-sm lg:text-base">
					<FormattedMessage id="plans.your_package" />
				</h2>
			</button>

			<div
				className={classNames('-mx-2 lg:-mx-4 mt-4', {
					'hidden lg:block': collapsed,
					'': !collapsed
				})}>
				<div>
					{cart.items
						.filter(item => !!item.amount)
						.map(item => (
							<div className="flex items-baseline px-4 py-1 overflow-hidden group" key={item.id}>
								<h3 className="flex-1 font-semibold">
									{item.id === 'plan' && <FormattedMessage id="plans.plan" values={{ plan: store.plans.find(plan => plan.Key === item.options.key)!.Name }} />}
									{item.id === 'embed' && <FormattedMessage id="plans.embed_title" />}
									{item.id === 'payments' && <FormattedMessage id="plans.payments_title" />}
									{item.id === 'users' && (
										<FormattedMessage
											id="plans.plan_users"
											values={{
												n: item.quantity,
												strong: msg => <span>{msg}</span>
											}}
										/>
									)}
									{item.id === 'storage' && (
										<FormattedMessage
											id="plans.plan_storage"
											values={{
												n: filesize(extraStorageBlock * item.quantity, locale),
												strong: msg => <span>{msg}</span>
											}}
										/>
									)}
									{item.id === 'esigns' && <FormattedMessage id="plans.num_signatures" values={{ n: item.options.limit }} />}
									{item.id === 'support_plan' && <FormattedMessage id={`plans.support-${item.options!.level}`} />}
									{item.id === 'white_labeling' && <FormattedMessage id={`plans.white_labeling-${item.options!.level}`} />}
								</h3>
								<div className={classNames('flex items-center gap-1', { 'transition-transform translate-x-5 group-hover:translate-x-0': item.id !== 'plan' })}>
									<div>
										<FormattedNumber value={item.total} style="currency" currencyDisplay="narrowSymbol" currency={defaultCurrency} />
									</div>
									{item.id !== 'plan' && (
										<button type="button" onClick={() => removeItemById(item.id)} className="p-1 transition-opacity opacity-0 group-hover:opacity-50">
											<FontAwesomeIcon icon="times" />
										</button>
									)}
								</div>
							</div>
						))}

					{cart.discounts.map(discount => (
						<div key={discount.title} className="flex items-baseline gap-2 px-4 py-2 border-t border-b border-white/25">
							<h3 className="flex-1 text-white">
								<span className="mr-2">{discount.title}</span>
								<span className="font-normal text-white/50">
									(<FormattedNumber value={discount.value / 100} style="percent" minimumFractionDigits={0} maximumFractionDigits={2} />)
								</span>
							</h3>
							<p>
								<FormattedNumber value={discount.amount} style="currency" currencyDisplay="narrowSymbol" currency={defaultCurrency} />
							</p>
						</div>
					))}
				</div>

				<div className="-mx-2 lg:-mx-4">
					<div className="flex items-baseline gap-2 px-6 pt-3 lg:px-8">
						<h3 className="flex-1 text-white/50">
							<FormattedMessage id="plans.subtotal" />
						</h3>
						<p className="text-right">
							<FormattedNumber value={cart.total_no_taxes} style="currency" currency={defaultCurrency} />{' '}
							<FormattedMessage id="plans.payment_frequency_unit" values={{ frequency: cart.frequency === 'year' ? 'year' : 'month' }} />
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
