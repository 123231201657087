import DataGridXL from '@assets/scripts/datagridxl2.esm.js';
import File from '@models/File';
import { parse as parseCsv } from 'papaparse';
import { type FC, useCallback, useRef } from 'react';

type ExcelViewerProps = {
	file: File;
	location: string;
};

export const ExcelViewer: FC<ExcelViewerProps> = ({ file, location }) => {
	const dataGridRef = useRef<DataGridXL>();

	const measuredRef = useCallback(
		(node: HTMLDivElement) => {
			if (node === null) {
				return;
			}

			parseCsv(location, {
				download: true,
				complete: data => {
					dataGridRef.current = new DataGridXL(node, {
						data: data.data,
						allowInsertRows: false,
						allowDeleteRows: false,
						allowMoveRows: false,
						allowInsertCols: false,
						allowDeleteCols: false,
						allowMoveCols: false,
						allowFillCells: false,
						allowEditCells: false,
						// disallow clipboard
						allowCut: false,
						allowPaste: false,
						// still allow copy & col resize (default)
						allowResizeCols: true,
						allowCopy: true
					});
				}
			});
		},
		[location]
	);

	return <div ref={measuredRef} className="flex flex-col h-full" />;
};
