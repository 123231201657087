import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { type FC, type PropsWithChildren } from 'react';

type CheckoutButtonProps = {
	selected?: boolean;
	onClick?: () => void;
};

export const CheckoutButton: FC<PropsWithChildren<CheckoutButtonProps>> = ({ selected = false, onClick, children }) => {
	const child = (
		<>
			<div
				className={classNames('p-2 transition-colors duration-150', {
					'bg-theme-primary': selected,
					'bg-gray-300': !selected
				})}>
				<FontAwesomeIcon size="lg" icon={selected ? 'check-circle' : ['far', 'circle']} fixedWidth className="mt-3 text-white" />
			</div>
			<div className="flex-1 p-4">{children}</div>
		</>
	);

	if (onClick !== undefined) {
		return (
			<button
				onClick={onClick}
				type="button"
				className={classNames('relative text-left transition-all duration-150 focus:outline-none w-full items-stretch bg-white border-2 flex', {
					'border-theme-primary': selected,
					'border-gray-300': !selected
				})}>
				{child}
			</button>
		);
	}

	return (
		<div
			className={classNames('relative text-left transition-all duration-150 focus:outline-none w-full items-stretch bg-white border-2 flex', {
				'border-theme-primary': selected,
				'border-gray-300': !selected
			})}>
			{child}
		</div>
	);
};
