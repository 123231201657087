import { type CannedMessage, type CannedMessageCategory } from '@components/CannedMessages';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import { useAccount } from '@hooks/useAccount';
import { useCannedMessagesQuery } from '@state/queries/canned-messages';
import Dropdown, { DropdownDivider, DropdownGroup, DropdownItem } from '@ui/Dropdown';
import { type FC, Fragment, type PropsWithChildren, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

type CannedMessageDropDownProps = PropsWithChildren<{
	onMessageSelected: (message: string) => void;
	onDefaultLoaded?: (message: CannedMessage) => void;
	disabled?: boolean;
}>;

export const CannedMessageDropDown: FC<CannedMessageDropDownProps> = ({ onMessageSelected, onDefaultLoaded = () => undefined, disabled = false, children }) => {
	const history = useHistory();
	const { account } = useAccount();

	const { data: messages } = useCannedMessagesQuery();

	const accountId = account?.ID;

	const [categories, uncategorizedMessages] = useMemo(() => {
		if (!messages) {
			return [[], []];
		}

		const categories: CannedMessageCategory[] = [];
		const uncategorizedMessages: CannedMessage[] = [];

		messages.forEach(message => {
			const categoryIndex = categories.findIndex(category => category.Id === message.category?.Id);

			message.Order = accountId === message.creator.ID ? message.Order : -1;

			if (categoryIndex >= 0) {
				categories[categoryIndex].messages.push(message);
			} else if (message.category) {
				message.category.messages = [message];
				categories.push(message.category);
			} else {
				uncategorizedMessages.push(message);
			}
		});

		return [categories.sort((a, b) => a.Order - b.Order), uncategorizedMessages];
	}, [accountId, messages]);

	if (!account!.hasFullAccess()) {
		return null;
	}

	if (!children) {
		children = (
			<Button disabled={disabled} size={Size.sm} intent={Intent.secondary} variant={Variant.light} type="button" className="flex-wrap">
				<FormattedMessage id="dialog.folder-share.insert-canned-message" />
			</Button>
		);
	}

	return (
		<Dropdown>
			{children}

			{categories.length > 0 && (
				<DropdownGroup>
					{categories.map(category => (
						<Fragment key={category.Id}>
							<h4 className="px-3 py-1 text-sm font-semibold">{category.Name}</h4>
							{category.messages.map(message => (
								<DropdownItem key={message.Id} onClick={() => onMessageSelected(message.Content)}>
									{message.Title}
								</DropdownItem>
							))}
						</Fragment>
					))}
					<DropdownDivider />
				</DropdownGroup>
			)}

			{uncategorizedMessages.length > 0 && (
				<DropdownGroup>
					<h4 className="px-3 py-1 text-sm font-semibold">
						<FormattedMessage id="canned-messages-crud.without_category" />
					</h4>
					{uncategorizedMessages.map(message => (
						<DropdownItem key={message.Id} onClick={() => onMessageSelected(message.Content)}>
							{message.Title}
						</DropdownItem>
					))}
					<DropdownDivider />
				</DropdownGroup>
			)}

			<DropdownGroup>
				<DropdownItem icon="cog" onClick={() => history.push('/account/canned-messages')}>
					<FormattedMessage id="dialog.folder-share.manage-canned-messages" />
				</DropdownItem>
			</DropdownGroup>
		</Dropdown>
	);
};
