import { Button, Label, Row, Select, ValidationField, Variant } from '@convoflo/ui';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import useIsDirty from '@hooks/useIsDirty';
import { OrganizationType } from '@pages/onboarding/organization';
import { useOrganizationUpdateMutation } from '@state/queries/organization';
import Card from '@ui/Card';
import { type FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { Prompt } from 'react-router-dom';

export const OrganizationProfile = () => {
	const { formatMessage } = useIntl();
	const { account } = useAccount();
	const { validation } = useClient();

	const [type, setType] = useState(account?.business.Profile.Type ?? undefined);
	const [employeeCount, setEmployeeCount] = useState(account?.business.Profile.EmployeeCount ?? undefined);

	const { isDirty } = useIsDirty([type, employeeCount]);

	const { mutateAsync: save, isLoading: isSaving } = useOrganizationUpdateMutation();

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();

		try {
			await save({ type, employeeCount });
			toast.success(<FormattedMessage id="organization-settings.saved" />);
		} catch {
			// TODO: Add error message in toast
		}
	};

	return (
		<>
			<Prompt when={isDirty} message={formatMessage({ id: 'unsaved_changes' })} />
			<Card onSubmit={onSubmit}>
				<Row>
					<Label htmlFor="type">
						<FormattedMessage id="onboarding.organization.type_label" />
					</Label>
					<ValidationField fieldName="type" validation={validation}>
						<div className="grid grid-cols-1 gap-2 md:grid-cols-2">
							<OrganizationType type="accounting" selected={type === 'accounting'} onSelected={setType} icon={['fad', 'calculator']} iconClassName="text-green-600">
								<FormattedMessage id="onboarding.organization.types.accounting" />
							</OrganizationType>
							<OrganizationType type="notary" selected={type === 'notary'} onSelected={setType} icon={['fad', 'user-tie']} iconClassName="text-orange-600">
								<FormattedMessage id="onboarding.organization.types.notary" />
							</OrganizationType>
							<OrganizationType type="law" selected={type === 'law'} onSelected={setType} icon={['fad', 'gavel']} iconClassName="text-purple-600">
								<FormattedMessage id="onboarding.organization.types.law" />
							</OrganizationType>
							<OrganizationType type="services" selected={type === 'services'} onSelected={setType} icon={['fad', 'hands-helping']} iconClassName="text-blue-600">
								<FormattedMessage id="onboarding.organization.types.professional_services" />
							</OrganizationType>
							<OrganizationType type="public" selected={type === 'public'} onSelected={setType} icon={['fad', 'city']} iconClassName="text-red-600">
								<FormattedMessage id="onboarding.organization.types.public" />
							</OrganizationType>
							<OrganizationType type="other" selected={type === 'other'} onSelected={setType} icon={['fad', 'building']} iconClassName="text-gray-600">
								<FormattedMessage id="onboarding.organization.types.other" />
							</OrganizationType>
						</div>
					</ValidationField>
				</Row>
				<Row>
					<Label htmlFor="employee_count">
						<FormattedMessage id="onboarding.organization.employees_label" />
					</Label>
					<ValidationField fieldName="employee_count" validation={validation}>
						<Select value={employeeCount ?? ''} onChange={e => setEmployeeCount(e.target.value || undefined)}>
							<FormattedMessage id="onboarding.organization.employees_select">{msg => <option value="">{msg}</option>}</FormattedMessage>
							<FormattedMessage id="onboarding.organization.employees_1">{msg => <option value="1">{msg}</option>}</FormattedMessage>
							<FormattedMessage id="onboarding.organization.employees_2_to_10">{msg => <option value="2-10">{msg}</option>}</FormattedMessage>
							<FormattedMessage id="onboarding.organization.employees_11_to_50">{msg => <option value="11-50">{msg}</option>}</FormattedMessage>
							<FormattedMessage id="onboarding.organization.employees_51_500">{msg => <option value="51-500">{msg}</option>}</FormattedMessage>
							<FormattedMessage id="onboarding.organization.employees_500_more">{msg => <option value="500+">{msg}</option>}</FormattedMessage>
						</Select>
					</ValidationField>
				</Row>

				<Button variant={Variant.primary} type="submit" disabled={isSaving || !isDirty || !type || !employeeCount} loading={isSaving} icon="save">
					<FormattedMessage id="save" />
				</Button>
			</Card>
		</>
	);
};
