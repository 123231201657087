import { Button, HelperText, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Row, Variant } from '@convoflo/ui';
import useClient from '@hooks/useClient';
import { type FC, type FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import type { WretchError, WretchResponse } from 'wretch';
import type { PaymentRequest } from './types';

type DialogDeletePaymentRequestProps = Omit<ModalProps, 'isOpen'> & {
	paymentRequest: PaymentRequest;
};

export const DialogDeletePaymentRequest: FC<DialogDeletePaymentRequestProps> = ({ paymentRequest, ...modalProps }) => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	const [isOpen, setIsOpen] = useState(true);
	const [deleteFiles, setDeleteFiles] = useState(false);

	const { mutate: _delete, isLoading: isDeleting } = useMutation<WretchResponse, WretchError, { deleteFiles?: boolean }>(
		async ({ deleteFiles = false }) => await client.url(`payment-requests/${paymentRequest.Id}`).json({ delete_files: deleteFiles }).delete().res(),
		{
			onSuccess: () => {
				setIsOpen(false);
				toast.success(<FormattedMessage id="payment-requests.cancel.canceled" />);
				queryClient.invalidateQueries(['payment-requests']);
			}
		}
	);

	const onDeleteSubmitted = (e: FormEvent) => {
		e.preventDefault();
		_delete({ deleteFiles });
	};

	return (
		<Modal isOpen={isOpen} onSubmit={onDeleteSubmitted} {...modalProps}>
			<ModalHeaderOnlyTitle icon="ban">
				<FormattedMessage id="payment-requests.cancel.title" />
			</ModalHeaderOnlyTitle>
			<ModalBody className="prose max-w-none">
				{paymentRequest.files!.length === 0 ? (
					<p>
						<FormattedMessage id="payment-requests.cancel.description" />
					</p>
				) : (
					<>
						<p>
							<FormattedMessage id="payment-requests.cancel.description_with_files" />
						</p>
						<Row>
							<Label htmlFor="delete-files" className="inline-flex items-center mb-1 text-sm">
								<input checked={deleteFiles} type="radio" className="mt-1 mr-2 form-radio" id="delete-files" onChange={() => setDeleteFiles(true)} />
								<FormattedMessage id="payment-requests.cancel.delete_files" />
							</Label>

							<HelperText>
								<FormattedMessage id="payment-requests.cancel.delete_files_desc" />
							</HelperText>

							<Label htmlFor="unblock-files" className="inline-flex items-center mb-1 text-sm">
								<input checked={!deleteFiles} type="radio" className="mr-2 form-radio" id="unblock-files" onChange={() => setDeleteFiles(false)} />
								<FormattedMessage id="payment-requests.cancel.unblock_files" />
							</Label>

							<HelperText>
								<FormattedMessage id="payment-requests.cancel.unblock_files_desc" />
							</HelperText>
						</Row>
					</>
				)}
			</ModalBody>
			<ModalFooter>
				<Button disabled={isDeleting} type="submit" className="mr-2" variant={Variant.danger}>
					{paymentRequest.files!.length > 0 && !isDeleting && deleteFiles && <FormattedMessage id="payment-requests.cancel.cancel_and_delete_files" />}
					{paymentRequest.files!.length > 0 && !isDeleting && !deleteFiles && <FormattedMessage id="payment-requests.cancel.cancel_and_unblock_files" />}
					{paymentRequest.files!.length === 0 && !isDeleting && <FormattedMessage id="payment-requests.cancel.cancel" />}
					{isDeleting && <FormattedMessage id="payment-requests.cancel.canceling" />}
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="close" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
