import useClient from '@hooks/useClient';
import Account from '@models/Account';
import Organization from '@models/Organization';
import { useMutation, useQueryClient } from 'react-query';
import type { WretchError } from 'wretch';
import { accountUpdater } from './account';

export type SaveOrgnizationMutation = {
	name?: string;
	secureSpaceSessionTimeout?: number | null;
	logoLight?: File;
	logoDark?: File;
	reminderIntervals?: number[];
	defaults?: Record<string, any>;
	applyToAll?: boolean;
	employeeCount?: string;
	type?: string;
	tabsOrder?: string[];
	secureSpaceTerms?: string | null;
};

export type SaveOrganizationSettingsApiRequest = {
	_method?: 'put';
	name?: string;
	secure_space_session_timeout?: number | null;
	logo_light?: File;
	logo_dark?: File;
	reminder_intervals?: number[];
	defaults?: object;
	apply_to_all?: boolean;
	employee_count?: string;
	type?: string;
	tabs_order?: string[];
	secure_space_terms?: string | null;
};

export const useOrganizationUpdateMutation = () => {
	const { client, authToken } = useClient();
	const queryClient = useQueryClient();

	return useMutation<Organization, WretchError, SaveOrgnizationMutation>({
		mutationFn: async ({ name, logoLight, logoDark, employeeCount, type, reminderIntervals, secureSpaceSessionTimeout, secureSpaceTerms, defaults, applyToAll }) => {
			const data: SaveOrganizationSettingsApiRequest = {};

			if (name) {
				data.name = name;
			}

			if (employeeCount) {
				data.employee_count = employeeCount;
			}

			if (type) {
				data.type = type;
			}

			if (reminderIntervals) {
				data.reminder_intervals = reminderIntervals;
			}

			if (secureSpaceSessionTimeout !== undefined) {
				data.secure_space_session_timeout = secureSpaceSessionTimeout;
			}

			if (secureSpaceTerms) {
				data.secure_space_terms = secureSpaceTerms;
			}

			if (defaults) {
				data.defaults = defaults;

				if (applyToAll) {
					data.apply_to_all = applyToAll;
				}
			}

			if (logoLight || logoDark) {
				data._method = 'put';

				if (logoDark) {
					data.logo_dark = logoDark;
				}

				if (logoLight) {
					data.logo_light = logoLight;
				}

				return new Organization(await client.url('organization').formData(data).post().json<Organization>());
			}

			return new Organization(await client.url('organization').json(data).put().json<Organization>());
		},
		onMutate: async ({ name, logoLight, logoDark, employeeCount, type, reminderIntervals, secureSpaceSessionTimeout }) => {
			await queryClient.cancelQueries(['account', authToken]);

			const previousAccount = queryClient.getQueryData<Account>(['account', authToken]);

			if (previousAccount === undefined) {
				return undefined;
			}

			const updates = { ...previousAccount };

			if (name) {
				updates.business.Name = name;
			}

			if (logoDark) {
				updates.business.LogoDark = URL.createObjectURL(logoDark);
			}

			if (logoLight) {
				updates.business.LogoLight = URL.createObjectURL(logoLight);
			}

			if (reminderIntervals) {
				updates.business.ReminderIntervals = reminderIntervals;
			}

			if (secureSpaceSessionTimeout) {
				updates.business.TempLoginTimeout = secureSpaceSessionTimeout;
			}

			if (type) {
				updates.business.Profile.Type = type;
			}

			if (employeeCount) {
				updates.business.Profile.EmployeeCount = employeeCount;
			}

			queryClient.setQueryData(['account', authToken], accountUpdater(updates));

			return previousAccount;
		},
		onError: (_error, _variables, account) => {
			queryClient.setQueryData(['account', authToken], account);
		},
		onSuccess: organization => {
			queryClient.setQueryData<Account | null>(['account', authToken], accountUpdater({ business: organization }));
		}
	});
};

export const useOrganizationLogoDeleteMutation = () => {
	const { client, authToken } = useClient();
	const queryClient = useQueryClient();

	return useMutation<Organization, WretchError, 'light' | 'dark'>({
		mutationFn: async (theme: string) => await client.url(`organization/logo/${theme}`).delete().res(),
		onMutate: async theme => {
			await queryClient.cancelQueries(['account', authToken]);

			const previousAccount = queryClient.getQueryData<Account>(['account', authToken]);

			if (previousAccount === undefined) {
				return undefined;
			}

			const updates = { ...previousAccount };

			if (theme === 'dark') {
				updates.business.LogoDark = null;
			}

			if (theme === 'light') {
				updates.business.LogoLight = null;
			}

			queryClient.setQueryData(['account', authToken], accountUpdater(updates));

			return previousAccount;
		},
		onError: (_error, _variables, account) => {
			queryClient.setQueryData(['account', authToken], account);
		}
	});
};

export const useOrganizationJoinMutation = () => {
	const { client, authToken } = useClient();
	const queryClient = useQueryClient();

	return useMutation<Account, WretchError, string>({
		mutationFn: async token => new Account(await client.url(`org/users/invites/${token}/accept`).post().json<Account>()),
		onSuccess: account => {
			queryClient.setQueryData(['account', authToken], account);
		}
	});
};
