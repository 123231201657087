import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, HelperText, InputBlock, Label, Row, ValidationField, Variant } from '@convoflo/ui';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { type FC, type FormEvent, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import Card from '@ui/Card';
import MultiFactorInput from '@ui/MultiFactorInput';
import type { WretchError, WretchResponse } from 'wretch';

const CODE_LENGTH = 6;

type GateMultiFactorAuthenticationProps = {
	onConfirmed: () => void;
};

const GateMultiFactorAuthentication: FC<GateMultiFactorAuthenticationProps> = ({ onConfirmed = () => undefined }) => {
	const { account } = useAccount();
	const { client, validation, setValidation } = useClient();
	const { locale } = useIntl();

	const autoSubmit = useRef(true);

	const [method, setMethod] = useState<'app' | 'sms' | 'recovery' | null>(account!.TwoFactorType);
	const [code, setCode] = useState('');
	const [verified, setVerified] = useState(false);
	const [emailCodeSent, setEmailCodeSent] = useState(false);
	const [smsCodeResent, setSmsCodeResent] = useState(false);

	const { mutate: verify, isLoading: verifying } = useMutation(
		async ({ method }: { method: string }) =>
			await client
				.url('auth/mfa/verify')
				.post({ [method === 'recovery' ? 'recovery_code' : 'code']: code })
				.res(),
		{
			onSuccess: () => {
				setVerified(true);
			}
		}
	);

	const { mutate: resendCode, isLoading: isSendingCode } = useMutation<WretchResponse, WretchError, string>(async method => await client.url('auth/mfa/resend').post({ method }).res(), {
		onSuccess: (_, method) => {
			if (method === 'sms') {
				setSmsCodeResent(true);
			} else if (method === 'email') {
				setEmailCodeSent(true);
			}
		}
	});

	const useAppMethod = () => {
		setValidation({});
		setMethod('app');
	};

	const useRecovery = () => {
		setValidation({});
		setMethod('recovery');
	};

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		verify({ method: method! });
	};

	// Once verified, send back or home page
	useEffect(() => {
		if (verified && method !== 'recovery') {
			onConfirmed();
		}
	}, [verified, method, onConfirmed]);

	useEffect(() => {
		if (method === null) {
			return;
		}

		if (code.length === CODE_LENGTH && autoSubmit.current && ['sms', 'app'].includes(method)) {
			autoSubmit.current = false;
			verify({ method: method! });
		}
	}, [code, method, verify]);

	return (
		<div className="fixed inset-0 z-50 flex w-screen h-screen bg-gray-200">
			<div className="container flex flex-col items-center justify-center flex-1 max-w-sm px-2 py-8 mx-auto">
				<div className="flex items-center justify-center mb-8">
					<FontAwesomeIcon icon="mobile" mask="circle" transform="shrink-8" size="3x" className="mr-3" />
					<h1 className="flex-1 text-2xl font-semibold">
						<FormattedMessage id="2fa.title" />
					</h1>
				</div>
				{verified ? (
					<Card>
						<header className="flex items-center mb-2">
							<FontAwesomeIcon mask="circle" icon="check" transform="shrink-8" className="mr-2 text-green-500" size="lg" />
							<h4 className="text-xl text-green-600">
								<FormattedMessage id="2fa.verified" />
							</h4>
						</header>

						{method !== 'recovery' && (
							<p className="text-sm italic font-light text-gray-500">
								<FormattedMessage id="2fa.redirecting" />
							</p>
						)}

						{method === 'recovery' && (
							<>
								<p className="text-sm font-light">
									<FormattedMessage id="2fa.recovery_code_used" />
								</p>

								<div className="flex justify-end mt-6">
									<Button variant={Variant.primary} onClick={() => onConfirmed()} type="button" animateIcon={true} iconEnd="chevron-right">
										<FormattedMessage id="2fa.continue" />
									</Button>
								</div>
							</>
						)}
					</Card>
				) : (
					<Card onSubmit={onSubmit}>
						{method === 'sms' && (
							<Row>
								<Label htmlFor="code">
									<FormattedMessage id="2fa.sms_desc" />
								</Label>
								<ValidationField fieldName="code" validation={validation}>
									<MultiFactorInput autoFocus id="code" onChange={setCode} disabled={verifying} fullWidth className="text-center" />
								</ValidationField>
								<HelperText>
									<FormattedMessage
										id="2fa.help_sms"
										values={{
											sms_resend_sms: (
												<button
													type="button"
													onClick={() => resendCode('sms')}
													disabled={smsCodeResent || isSendingCode}
													className={`break-all ${smsCodeResent ? 'text-gray-500' : 'text-theme-primary hover:underline'}`}>
													<FormattedMessage id="2fa.sms_resend_sms" />
													{smsCodeResent && <FontAwesomeIcon icon="check" className="mx-1" />}
												</button>
											),
											sms_resend_email: (
												<button
													type="button"
													onClick={() => resendCode('email')}
													disabled={emailCodeSent || isSendingCode}
													className={`break-all ${emailCodeSent ? 'text-gray-500' : 'text-theme-primary hover:underline'}`}>
													<FormattedMessage id="2fa.sms_resend_email" />
													{emailCodeSent && <FontAwesomeIcon icon="check" className="mx-1" />}
												</button>
											)
										}}
									/>
								</HelperText>
							</Row>
						)}

						{method === 'app' && (
							<Row>
								<Label htmlFor="code">
									<FormattedMessage id="2fa.app_desc" />
								</Label>
								<ValidationField fieldName="code" validation={validation}>
									<MultiFactorInput autoFocus id="code" onChange={setCode} disabled={verifying} fullWidth className="text-center" />
								</ValidationField>
								<HelperText>
									<FormattedMessage
										id="2fa.help_app"
										values={{
											use_recovery_code: (
												<button type="button" onClick={useRecovery} className="break-all text-theme-primary hover:underline">
													<FormattedMessage id="2fa.use_recovery_code" />
												</button>
											)
										}}
									/>
								</HelperText>
							</Row>
						)}

						{method === 'recovery' && (
							<Row>
								<Label htmlFor="code">
									<FormattedMessage id="2fa.recovery_desc" />
								</Label>
								<ValidationField fieldName="recovery_code" validation={validation}>
									<InputBlock autoFocus id="code" type="text" onChange={e => setCode(e.target.value)} disabled={verifying} />
								</ValidationField>
								<HelperText>
									<FormattedMessage
										id="2fa.help_recovery"
										values={{
											switch_to_app: (
												<button type="button" onClick={useAppMethod} className="break-all text-theme-primary hover:underline">
													<FormattedMessage id="2fa.switch_to_app" />
												</button>
											),
											contact_us: (
												<a href={`${import.meta.env.VITE_SITE_URL}/${locale}/contact`} target="_blank" rel="noopener noreferrer" className="text-theme-primary hover:underline">
													<FormattedMessage id="2fa.contact_us" />
												</a>
											)
										}}
									/>
								</HelperText>
							</Row>
						)}

						<Button type="submit" block loading={verifying} disabled={code.length < 6} variant={Variant.primary}>
							<FormattedMessage id="2fa.verify" />
						</Button>
					</Card>
				)}
			</div>
		</div>
	);
};

export default GateMultiFactorAuthentication;
