import imgLabelsEn from '@assets/images/labels-en.png';
import imgLabelsFr from '@assets/images/labels-fr.png';
import imgPermissionsEn from '@assets/images/permissions-en.png';
import imgPermissionsFr from '@assets/images/permissions-fr.png';
import imgSignaturesEn from '@assets/images/signature-onespan-en.png';
import imgSignaturesFr from '@assets/images/signature-onespan-fr.png';
import imgSecureSpaceEn from '@assets/images/support-en.png';
import imgSecureSpaceFr from '@assets/images/support-fr.png';
import FullScreenLoading from '@components/FullScreenLoading';
import AppContext from '@contexts/AppContext';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { useSubscriptionDowngradeMutation } from '@state/queries/subscriptions';
import type { Plan } from '@types';
import { OnboardingLayout } from '@ui/Layout/OnboardingLayout';
import { MarkdownContent } from '@ui/MarkdownContent';
import classNames from 'classnames';
import { type ButtonHTMLAttributes, type FC, type FormEvent, useContext, useLayoutEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { defaultCurrency } from '../constants';

export type PlansResponseType = {
	plans: Plan[];
	modules: {
		user: {
			amount: number;
		};
	};
};

export const TrialExpiredPage = () => {
	const { formatMessage, formatNumber, locale } = useIntl();
	const { client } = useClient();
	const { account } = useAccount();
	const history = useHistory();
	const { setPageTitle: setTitle } = useContext(AppContext);

	const [willUpgrade, setWillUpgrade] = useState<boolean>();

	const { data: plans, isLoading } = useQuery(['plans'], async () => await client.url('plans').get().json<PlansResponseType>());

	const { mutateAsync: downgrade, isLoading: isDowngrading } = useSubscriptionDowngradeMutation();

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		if (willUpgrade) {
			history.push('/account/checkout');
			return;
		}
		try {
			await downgrade();
			history.replace('/files');
		} catch {
			// TODO: Show error to user
		}
	};

	useLayoutEffect(() => {
		setTitle(formatMessage({ id: 'trial-expired.title' }));
	}, [setTitle, formatMessage]);

	if (isLoading || !plans) {
		return <FullScreenLoading />;
	}

	return (
		<OnboardingLayout
			sidebarCenterContents={
				<>
					<h1 className="mb-6 font-serif text-4xl font-bold text-white">
						<FormattedMessage id="trial-expired.title" />
					</h1>

					<p className="text-sm text-white">
						<FormattedMessage id="trial-expired.desc" />
					</p>
				</>
			}
			sidebarBottomContents={
				<div className="container flex flex-wrap items-center justify-center gap-6 mx-auto mt-8 lg:flex-col md:gap-12">
					<img
						style={{ width: 144 }}
						className="max-w-full"
						loading="lazy"
						src="https://assets.capterra.com/badge/81148a292374affef6f0bd8ba377b623.svg?v=2156454&p=216711"
						alt="Convoflo: 4.7 ⭐ on Capterra"
					/>
					<img width={128} loading="lazy" className="max-w-full" alt="GetApp Category Leaders 2024" src="https://brand-assets.getapp.com/badge/9f35b540-e656-4dbd-b4b5-bdd2b1738e74.png" />
					<img
						width={128}
						loading="lazy"
						className="max-w-full"
						alt="Capterra Best Value App for 2023"
						src="https://capterra.s3.amazonaws.com/assets/images/gdm-badges/CA_Badge_BestValue_2023_FullColor-Positive.png"
					/>
					<img
						width={128}
						loading="lazy"
						className="max-w-full"
						alt="2024 Capterra Shortlist for Portal"
						src="https://brand-assets.capterra.com/badge/b317eaeb-26ea-4529-8d41-e95a2a2ee746.png"
					/>
				</div>
			}>
			<div className="flex px-6 py-6 bg-gray-200 md:px-8 lg:px-16">
				<p className="font-serif text-2xl italic text-gray-700">
					<FormattedMessage id="trial-expired.heading" />
				</p>
			</div>
			<form className="flex-1 px-6 py-12 md:px-8 lg:px-16" onSubmit={onSubmit}>
				<div className="max-w-6xl mx-auto">
					{account!.isAdmin() ? (
						<>
							<p className="mb-6 text-lg text-center">
								<FormattedMessage id="trial-expired.choose_option" />
							</p>
							<div className="grid gap-8 lg:grid-cols-2">
								<BigButton selected={willUpgrade === undefined ? willUpgrade : willUpgrade === true} onSelected={() => setWillUpgrade(true)}>
									<MarkdownContent size="sm" className="prose-h3:mt-0">
										{formatMessage(
											{ id: 'trial-expired.option_1' },
											{
												planPrice: formatNumber(plans.plans[0].Price, { currency: defaultCurrency, style: 'currency', currencyDisplay: 'symbol' }),
												userPrice: formatNumber(plans.modules.user.amount, { currency: defaultCurrency, style: 'currency', currencyDisplay: 'symbol' })
											}
										)}
									</MarkdownContent>
								</BigButton>
								<BigButton selected={willUpgrade === undefined ? willUpgrade : willUpgrade === false} onSelected={() => setWillUpgrade(false)}>
									<MarkdownContent size="sm" className="prose-h3:mt-0">
										{formatMessage({ id: 'trial-expired.option_2' })}
									</MarkdownContent>
								</BigButton>
							</div>
						</>
					) : (
						<>
							<p className="mb-6 text-lg text-center">
								<FormattedMessage id="trial-expired.choose_option_user" />
							</p>
						</>
					)}
					{willUpgrade === false && (
						<div>
							<div className="flex items-center px-6 py-3 mt-12 mb-6 space-x-3 bg-yellow-100 border border-yellow-300 rounded-md">
								<FontAwesomeIcon icon="exclamation-triangle" className="text-yellow-500" />
								<h1 className="text-yellow-800">
									<FormattedMessage id="trial-expired.lose_title" />
								</h1>
							</div>
							<div className="flex flex-col gap-8">
								<div className="flex gap-4 overflow-hidden prose-sm prose bg-gray-100 rounded-xl prose-h3:mt-0 max-w-none ring-2 ring-gray-200">
									<div className="p-4">
										<MarkdownContent size="sm" className="prose-h3:mt-0">
											{formatMessage({ id: 'trial-expired.lose_1' })}
										</MarkdownContent>
									</div>
									<div className="bg-top bg-no-repeat bg-cover w-[64rem]" style={{ backgroundImage: `url(${locale === 'fr' ? imgSecureSpaceFr : imgSecureSpaceEn})` }} />
								</div>
								<div className="flex gap-4 overflow-hidden prose-sm prose bg-gray-100 rounded-xl prose-h3:mt-0 max-w-none ring-2 ring-gray-200">
									<div className="p-4">
										<MarkdownContent size="sm" className="prose-h3:mt-0">
											{formatMessage({ id: 'trial-expired.lose_2' })}
										</MarkdownContent>
									</div>
									<div className="bg-top bg-no-repeat bg-cover w-[64rem]" style={{ backgroundImage: `url(${locale === 'fr' ? imgPermissionsFr : imgPermissionsEn})` }} />
								</div>
								<div className="flex gap-4 overflow-hidden prose-sm prose bg-gray-100 rounded-xl prose-h3:mt-0 max-w-none ring-2 ring-gray-200">
									<div className="p-4">
										<MarkdownContent size="sm" className="prose-h3:mt-0">
											{formatMessage({ id: 'trial-expired.lose_3' })}
										</MarkdownContent>
									</div>
									<div className="bg-top bg-no-repeat bg-cover w-[64rem]" style={{ backgroundImage: `url(${locale === 'fr' ? imgLabelsFr : imgLabelsEn})` }} />
								</div>
								<div className="flex gap-4 overflow-hidden prose-sm prose bg-gray-100 rounded-xl prose-h3:mt-0 max-w-none ring-2 ring-gray-200">
									<div className="p-4">
										<MarkdownContent size="sm" className="prose-h3:mt-0">
											{formatMessage({ id: 'trial-expired.lose_4' })}
										</MarkdownContent>
									</div>
									<div className="bg-top bg-no-repeat bg-cover w-[64rem]" style={{ backgroundImage: `url(${locale === 'fr' ? imgSignaturesFr : imgSignaturesEn})` }} />
								</div>
							</div>
							<p className="mt-6 font-medium font-xl">
								<FormattedMessage
									id="trial-expired.more_features"
									values={{
										a: msg => (
											<a href={`${import.meta.env.VITE_SITE_URL}/${locale}/product/features`} className="text-theme-primary hover:underline">
												{msg}
											</a>
										)
									}}
								/>
							</p>
						</div>
					)}
					{willUpgrade !== undefined && (
						<div className="flex justify-center mt-16">
							<Button variant={Variant.primary} size={Size.xl} intent={Intent.primary} iconEnd={willUpgrade ? 'arrow-right' : undefined} animateIcon loading={isDowngrading}>
								{willUpgrade ? <FormattedMessage id="upgrade" /> : <FormattedMessage id="trial-expired.downgrade_account" />}
							</Button>
						</div>
					)}
				</div>
			</form>
		</OnboardingLayout>
	);
};

type BigButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	selected?: boolean;
	onSelected?: () => void;
};

export const BigButton: FC<BigButtonProps> = ({ className, selected, onSelected, children, ...buttonProps }) => {
	return (
		<button
			onClick={onSelected}
			type="button"
			className={classNames(className, 'relative text-left transition-all duration-150 focus:outline-none w-full items-stretch bg-white border-2 rounded-md flex group cursor-pointer', {
				'border-theme-primary': selected,
				'opacity-50': selected === false,
				'border-theme-primary-lightest hover:border-theme-primary-lighter': !selected
			})}
			{...buttonProps}>
			<div
				className={classNames('p-2 transition-colors duration-150', {
					'bg-theme-primary': selected,
					'bg-theme-primary-lightest group-hover:bg-theme-primary-lighter': !selected
				})}>
				<FontAwesomeIcon size="lg" icon={selected ? 'check-circle' : ['far', 'circle']} fixedWidth className="mt-3 text-white" />
			</div>
			<div className="flex-1 p-4">{children}</div>
		</button>
	);
};
