import { useAccount } from '@hooks/useAccount';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export const UpgradeTrialBanner = () => {
	const { account } = useAccount();

	if (account!.business.getTrialDaysLeft() <= 0 || !account!.isAdmin()) {
		return null;
	}

	return (
		<div className="bg-yellow-200">
			<p className="px-4 py-2 text-xs text-center text-yellow-700 md:text-base">
				<FormattedMessage
					id="banner.days_left_in_trial"
					values={{
						days: account!.business.getTrialDaysLeft() + 1,
						strong: msg => <span className="font-semibold">{msg}</span>,
						link: msg => (
							<Link to="/account/upgrade" className="font-bold underline transition-colors focus:outline-none hover:text-yellow-600 focus:text-yellow-800">
								{msg}
							</Link>
						)
					}}
				/>
			</p>
		</div>
	);
};
