import { useContext } from 'react';
import { ImportContext } from './providers';

export const useImporter = () => {
	const context = useContext(ImportContext);

	if (context === undefined) {
		throw new Error('useImporter() must be used within a ImporterContext');
	}

	return context;
};
