import { SidebarAnnouncements } from '@components/Announcement';
import LanguageSwitcher from '@components/LanguageSwitcher';
import { Logo } from '@components/Logo';
import AppContext from '@contexts/AppContext';
import { Button } from '@convoflo/ui';
import type { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import { useLocalStorage } from '@hooks/useLocalStorage';
import Account from '@models/Account';
import { env } from '@utils/StringUtils';
import classNames from 'classnames';
import { type ButtonHTMLAttributes, type FC, type PropsWithChildren, type ReactNode, useContext, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, type LinkProps, NavLink, type NavLinkProps, useLocation } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { DialogSupport } from '../../components/DialogSupport';

type DrawerProps = {
	account: Account;
	theme?: 'light' | 'dark' | null;
	layout?: 'full' | 'minimal' | 'nothing' | 'print' | null;
};

export const Drawer: FC<DrawerProps> = ({ account, theme = 'light', layout = 'full' }) => {
	const location = useLocation();
	const { logout } = useAccount();
	const { locale } = useIntl();
	const { showDrawer, setShowDrawer } = useContext(AppContext);

	const [opened, setOpened] = useLocalStorage('menu.collapsed', true);

	const [showSupportBox, setShowSupportBox] = useState(false);

	const menuSwipeHandlers = useSwipeable({
		onSwipedLeft: () => setOpened(false)
	});

	// Close when we navigate
	useEffect(() => {
		setShowDrawer(false);
	}, [location, setShowDrawer]);

	return (
		<>
			{/* Overlay */}
			{showDrawer && (
				<div onClick={() => setShowDrawer(false)} className="fixed inset-0 z-10 transition-colors duration-200 ease-in-out bg-black bg-opacity-50 cursor-pointer md:z-auto md:hidden" />
			)}

			{/* Opened drawer */}
			<aside
				{...menuSwipeHandlers}
				className={classNames(
					'flex flex-shrink-0 ease-in-out transition-transform duration-300 z-10 bg-theme-inverse fixed inset-0 max-w-sm justify-between flex-col md:flex md:static md:row-span-3 md:min-w-none',
					{
						'translate-x-0': showDrawer,
						'md:w-64': opened,
						'-translate-x-full md:translate-x-0': !showDrawer,
						'md:w-16': !opened
					}
				)}>
				{/* Desktop divider */}
				<button
					className={classNames('hidden sm:flex opacity-0 items-stretch justify-center hover:opacity-100 absolute -right-2 w-4 h-full z-[11] transition', {
						'cursor-w-resize': opened,
						'cursor-e-resize': !opened
					})}
					onClick={() => setOpened(!opened)}>
					<div className="absolute h-full flex flex-col items-center justify-center bg-gray-600/50 ring-1 ring-white/10 z-[12] w-2" />
				</button>

				<Scrollbars>
					<div className="flex flex-col justify-between h-full">
						<div className="md:py-4">
							{!opened && (
								<button type="button" onClick={() => setOpened(!opened)} className="flex items-center justify-center w-full py-4">
									<FontAwesomeIcon icon="bars" className="text-xl text-white" />
								</button>
							)}
							{opened && (
								<header className="flex items-center py-2 pl-8 pr-4 mt-4 mb-4 space-x-8 md:block md:mt-0 md:m-0 md:p-4">
									<Link to="/files" className="flex-1 block mx-6">
										<Logo dark />
									</Link>
									<Button circle className="text-white md:hidden" onClick={() => setShowDrawer(false)}>
										<FontAwesomeIcon size="lg" icon="times" />
									</Button>
								</header>
							)}

							<nav className="flex flex-col">
								{account.hasFullAccess() && (
									<PrimaryLink to="/" short={!opened} icon="home" active={location.pathname === '/'}>
										<FormattedMessage id="drawer.home" />
									</PrimaryLink>
								)}
								{account.hasFullAccess() && (
									<PrimaryLink to="/files" short={!opened} icon="folders" active={/^(\/files)|(\/vf\/)|(\/dl\/)/i.test(location.pathname)}>
										<FormattedMessage id="drawer.files" />
									</PrimaryLink>
								)}
								{!account.hasFullAccess() && (
									<PrimaryLink to="/files" short={!opened} icon="shield" active={/^(\/files)|(\/vf\/)|(\/dl\/)/i.test(location.pathname)} id="step-secure-spaces">
										<FormattedMessage id="drawer.secure_spaces" />
									</PrimaryLink>
								)}
								{account.hasFullAccess() && (
									<Group title={opened ? <FormattedMessage id="drawer.dashboards" /> : undefined}>
										<PrimaryLink to="/dashboard/labels" icon="tags" short={!opened} active={location.pathname.startsWith('/dashboard/labels')}>
											<FormattedMessage id="organization.properties" />
										</PrimaryLink>
										<PrimaryLink to="/dashboard/signatures" short={!opened} icon="signature" active={location.pathname.startsWith('/dashboard/signatures')}>
											<FormattedMessage id="drawer.sign_requests" />
										</PrimaryLink>
										{/* <div
											className={classNames('transition overflow-hidden', {
												'h-auto opacity-100 translate-y-0': location.pathname.startsWith('/dashboard/signatures'),
												'h-0 opacity-0 -translate-y-full': !location.pathname.startsWith('/dashboard/signatures')
											})}>
											<SecondaryLink to="/dashboard/signatures/templates" active={location.pathname === '/dashboard/signatures/templates'}>
												<FormattedMessage id="dashboard-esign.templates" />
											</SecondaryLink>
											<SecondaryLink to="/dashboard/signatures/layouts" active={location.pathname === '/dashboard/signatures/layouts'}>
												<FormattedMessage id="dashboard-esign.layout" />
											</SecondaryLink>
										</div> */}
										<PrimaryLink to="/dashboard/payments" short={!opened} icon="dollar-sign" active={location.pathname.startsWith('/dashboard/payments')}>
											<FormattedMessage id="drawer.payment_requests" />
										</PrimaryLink>
									</Group>
								)}
							</nav>
						</div>

						<div className="flex flex-col py-4">
							{opened && (
								<div className="mx-4 mb-6">
									<SidebarAnnouncements />
								</div>
							)}

							{opened && account.hasFullAccess() && (
								<Link to="/news" className="transition self-center px-2.5 py-1.5 mb-6 text-xs text-white border rounded-md active:ring ring-white/10 border-white/25">
									<FontAwesomeIcon icon={['fal', 'sparkles']} className="mr-2" />
									<FormattedMessage id="drawer.whats_new" />
								</Link>
							)}

							<nav
								className={classNames('flex justify-center items-center mb-6 gap-3', {
									'flex-row': opened,
									'flex-col': !opened
								})}>
								<TertiaryLink small={!opened} to="/account" icon="cog" id="step-options" />
								<button
									id="step-tutorials-button"
									className={classNames(tertiaryLinkClassName, {
										'!w-10 !h-10': !opened,
										'!w-12 !h-12': opened
									})}
									onClick={() => setShowSupportBox(true)}>
									<FontAwesomeIcon icon="question-circle" size={opened ? 'lg' : undefined} />
								</button>
								<TertiaryLinkButton small={!opened} onClick={() => logout()} icon="sign-out" id="step-logout" />
							</nav>

							{opened && (
								<div className="flex justify-center mb-4">
									<LanguageSwitcher />
								</div>
							)}

							{opened && (
								<>
									<p className="text-xs text-center text-white">
										<a href={`${import.meta.env.VITE_SITE_URL}/${locale}/privacy-policy`} className="hover:underline">
											<FormattedMessage id="drawer.privacy_policy" />
										</a>{' '}
										·{' '}
										<a href={`${import.meta.env.VITE_SITE_URL}/${locale}/terms-of-use`} className="hover:underline">
											<FormattedMessage id="drawer.terms" />
										</a>
									</p>
									<p className="text-xs text-center text-white text-opacity-50">
										<FormattedMessage id="copyright.short" values={{ year: new Date().getFullYear() }} /> ·{' '}
										<Link to="/releases" className="hover:text-white">
											<FormattedMessage id="version_x" values={{ version: APP_VERSION }} />
										</Link>
									</p>
								</>
							)}
						</div>
					</div>
				</Scrollbars>
			</aside>
			{showSupportBox && <DialogSupport onAfterClose={() => setShowSupportBox(false)} />}
		</>
	);
};

type PrimaryLinkProps = LinkProps & {
	icon?: IconName;
	alerts?: ReactNode;
	active?: boolean;
	short?: boolean;
};

const PrimaryLink: FC<PrimaryLinkProps> = ({ icon, alerts, active = false, short = false, children, ...linkProps }) => {
	return (
		<Link
			className={classNames('flex items-center py-3 m-2 rounded', {
				'px-3 flex justify-center': short,
				'px-6': !short,
				'text-white font-semibold bg-theme-canvas-lighter bg-opacity-10': active,
				'text-theme-canvas-lighter hover:text-theme-canvas-lightest transition-colors ease-in-out duration-250': !active
			})}
			{...linkProps}>
			{icon !== undefined && <FontAwesomeIcon icon={[active ? 'fas' : 'fal', icon]} fixedWidth className={classNames({ 'mr-4': !short })} />}
			{!short && (
				<>
					<span className="flex-1">{children}</span>
					{alerts !== undefined && <span className="px-3 ml-2 text-sm font-bold text-white rounded-full bg-theme-primary">{alerts}</span>}
				</>
			)}
		</Link>
	);
};

type SecondaryLinkProps = LinkProps & {
	icon?: IconProp;
	alerts?: ReactNode;
	active?: boolean;
};

const SecondaryLink: FC<SecondaryLinkProps> = ({ icon, alerts, active = false, children, ...linkProps }) => {
	return (
		<Link
			className={classNames('flex m-2 items-center text-sm px-6 py-2', {
				'text-white font-semibold': active,
				'text-blue-300 hover:text-blue-100 transition-colors ease-in-out duration-200': !active
			})}
			{...linkProps}>
			{icon !== undefined ? <FontAwesomeIcon icon={icon} fixedWidth className="mr-3" /> : <span className="w-5 mr-4"></span>}
			<span className="flex-1">{children}</span>
			{alerts !== undefined && <span className="px-3 ml-2 text-sm font-bold text-white bg-red-600 rounded-full">{alerts}</span>}
		</Link>
	);
};

const tertiaryLinkClassName =
	'flex items-center justify-center text-white text-opacity-75 transition-colors duration-200 ease-in-out border-2 border-white border-opacity-25 rounded-full hover:text-opacity-100 hover:border-opacity-100';

const TertiaryLink = ({ to, icon, small = false, children, ...props }: NavLinkProps & { icon: IconProp; small?: boolean }) => (
	<NavLink
		to={to}
		activeClassName="text-opacity-100 border-opacity-100"
		className={classNames(tertiaryLinkClassName, {
			'w-12 h-12': !small,
			'w-10 h-10': small
		})}
		{...props}>
		<FontAwesomeIcon icon={icon} size={!small ? 'lg' : undefined} />
	</NavLink>
);

const TertiaryLinkButton = ({ icon, small = false, children, ...props }: ButtonHTMLAttributes<HTMLElement> & { icon: IconProp; small?: boolean }) => (
	<button
		className={classNames(
			'flex items-center justify-center text-white text-opacity-75 transition-colors duration-200 ease-in-out border-2 border-white border-opacity-25 rounded-full hover:text-opacity-100 hover:border-opacity-100 focus:outline-none',
			{
				'w-12 h-12': !small,
				'w-10 h-10': small
			}
		)}
		{...props}>
		<FontAwesomeIcon icon={icon} size={!small ? 'lg' : undefined} />
	</button>
);

type GroupProps = PropsWithChildren<{
	title: ReactNode;
}>;

export const Group: FC<GroupProps> = ({ title, children }) => (
	<>
		<h3 className="px-8 mt-8 mb-2 text-xs font-semibold tracking-widest text-white text-opacity-75 uppercase">{title}</h3>
		{children}
	</>
);
