import User from '@models/User';
import type { FC } from 'react';
import { Size } from '@convoflo/ui';
import UserAvatar from '@ui/UserAvatar';

type BlockquoteProps = {
	quote: string;
	user?: User;
};

const Blockquote: FC<BlockquoteProps> = ({ quote, user }) => (
	<blockquote className="relative px-4 py-2 my-4 text-sm italic bg-gray-100">
		<p>{quote}</p>
		{user !== undefined && (
			<cite className="flex items-center">
				<UserAvatar user={user} size={Size.md} className="mr-4" />
				<div className="flex flex-col items-start">
					<span className="mb-1 text-sm italic font-bold">{user.Name}</span>
				</div>
			</cite>
		)}
	</blockquote>
);

export default Blockquote;
