import { LoginWithMicrosoftButton } from '@components/MicrosoftEntraId/LoginWithMicrosoftButton';
import { Alert, Button, Checkbox, InputBlock, Label, Row, ValidationField, Variant } from '@convoflo/ui';
import { useEnvironment } from '@hooks/use-environment';
import useClient from '@hooks/useClient';
import { useLocalStorage } from '@hooks/useLocalStorage';
import useUrlSearch from '@hooks/useUrlSearch';
import { useAccountLoginMutation } from '@state/queries/account';
import type { LoginComponentProps } from '@types';
import Card from '@ui/Card';
import { type FC, type FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

export const AppLogin: FC<LoginComponentProps> = ({ email: initialEmail = '' }) => {
	const { validation } = useClient();
	const { locale } = useIntl();
	const { msei_error } = useUrlSearch();
	const environment = useEnvironment();

	const [isGenericError, setIsGenericError] = useState(false);
	const [email, setEmail] = useState(initialEmail);
	const [password, setPassword] = useState('');
	const [remember, setRemember] = useLocalStorage('remember_auth', false);

	const { mutateAsync: login, isLoading } = useAccountLoginMutation();

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		try {
			await login({ email, password, remember });
		} catch (error) {
			if (error?.status !== 422) {
				setIsGenericError(true);
			}
		}
	};

	return (
		<Card size={null}>
			<div className="p-6">
				{isGenericError && (
					<Alert variant={Variant.danger}>
						<p>
							<FormattedMessage id="errors.generic.title" />
						</p>
					</Alert>
				)}

				<form onSubmit={onSubmit}>
					<fieldset disabled={isLoading}>
						<Row>
							<Label htmlFor="email">
								<FormattedMessage id="login.email" />
							</Label>
							<ValidationField fieldName="email" validation={validation}>
								<InputBlock tabIndex={1} autoFocus id="email" type="email" value={email} onChange={e => setEmail(e.target.value)} icon="envelope" />
							</ValidationField>
						</Row>
						<Row>
							<div className="flex items-center justify-between">
								<Label htmlFor="password">
									<FormattedMessage id="login.password" />
								</Label>
								<Link to={`/login/forgot?email=${email}`} className="mb-2 text-sm text-theme-primary hover:underline">
									<FormattedMessage id="login.forgot_password" />
								</Link>
							</div>
							<ValidationField fieldName="password" validation={validation}>
								<InputBlock tabIndex={2} id="password" type="password" value={password} onChange={e => setPassword(e.target.value)} icon="lock" />
							</ValidationField>
						</Row>
						<Row className="flex">
							<Checkbox checked={remember} onChange={e => setRemember(e.target.checked)}>
								<span className="text-sm">
									<FormattedMessage id="login.remember" />
								</span>
							</Checkbox>
						</Row>
						<Row>
							<Button block tabIndex={3} type="submit" variant={Variant.primary} iconEnd="sign-in" loading={isLoading}>
								{isLoading ? <FormattedMessage id="login.logging_in" /> : <FormattedMessage id="login.login" />}
							</Button>
						</Row>
					</fieldset>
				</form>
			</div>
			<div className="h-px bg-gray-200"></div>
			<div className="p-6">
				{!!msei_error && (
					<Alert variant={Variant.danger} className="mb-6">
						<p>
							<FormattedMessage id="login.microsoft_no_account" />
						</p>
					</Alert>
				)}
				<div className="flex justify-center">
					<LoginWithMicrosoftButton />
				</div>
			</div>
			{!environment && (
				<>
					<div className="h-px bg-gray-200"></div>
					<div className="p-6">
						<p className="text-sm text-center">
							<FormattedMessage id="login.new_to_convoflo" />{' '}
							<a href={`${import.meta.env.VITE_SITE_URL}/${locale}/plans`} className="text-theme-primary hover:underline">
								<FormattedMessage id="login.try_it_out" />
							</a>
						</p>
					</div>
				</>
			)}
		</Card>
	);
};
