import AppContext from '@contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import { useSearchParams } from '@hooks/useSearchParams';
import { useAccountQuery } from '@state/queries/account';
import ErrorLayout from '@ui/Layout/Error';
import { type FC, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

export const UnderMaintenancePage: FC = () => {
	const { formatMessage } = useIntl();
	const { account } = useAccount();
	const { state } = useLocation<{ next: Location }>();
	const [searchParams] = useSearchParams();
	const { setPageTitle: setTitle } = useContext(AppContext);
	const history = useHistory();

	useEffect(() => {
		setTitle(formatMessage({ id: 'under_maintenance' }));
	}, [setTitle, formatMessage]);

	useEffect(() => {
		// When we get the account back, go where the user wanted to go
		if (account) {
			history.replace(state?.next ?? searchParams['next'] ?? '/');
		}
	}, [account, history, searchParams, state?.next]);

	return (
		<ErrorLayout header={false} icon={<FontAwesomeIcon icon="wrench" size="4x" />}>
			<h1 className="mb-2 text-xl font-semibold text-black">
				<FormattedMessage id="under_maintenance_heading" />
			</h1>
			<p className="my-2 leading-tight text-gray-600">
				<FormattedMessage id="under_maintenance_text" />
			</p>
		</ErrorLayout>
	);
};
