import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import { FileItem, createSecureSpace, useImporter } from '@components/Importer';
import { useWizard } from '@components/Wizard';
import type { IUser } from '@models/User';
import { type FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Card from '@ui/Card';
import UserAvatar from '@ui/UserAvatar';

const SHOW_AT_A_TIME = 20;

export const ReviewStep: FC = () => {
	const { data, creator, template, folder, isInviting } = useImporter();
	const { nextStep, goToStep } = useWizard();

	const [limit, setLimit] = useState(SHOW_AT_A_TIME);

	return (
		<Card>
			<div className="prose max-w-none">
				<h2>
					<FormattedMessage id="importer.step.review" />
				</h2>
				<ul>
					<li>
						<strong className="px-1 bg-gray-100 rounded-sm py-0.5 text-gray-800">
							<FormattedMessage id="importer.review.total_spaces_created" values={{ n: data.length }} />
						</strong>
					</li>
					<li>
						<FormattedMessage
							id="importer.review.creator"
							values={{
								n: data.length,
								creator: (
									<strong className="px-1 bg-gray-100 rounded-sm py-0.5 text-gray-800">
										<UserAvatar user={creator as IUser} size={Size.xs} className="!my-0 !mr-1" />
										{creator?.Name}
									</strong>
								)
							}}
						/>
					</li>
					{folder && (
						<li>
							<FormattedMessage
								id="importer.review.location"
								values={{
									n: data.length,
									folder: (
										<strong className="px-1 bg-gray-100 rounded-sm py-0.5 text-gray-800">
											<FontAwesomeIcon icon="folder" className="mr-1 text-gray-400" size="sm" />
											{folder.Name}
										</strong>
									)
								}}
							/>
						</li>
					)}
					{template && (
						<li>
							<FormattedMessage
								id="importer.review.template"
								values={{
									n: data.length,
									template: (
										<strong className="px-1 bg-gray-100 rounded-sm py-0.5 text-gray-800">
											<FontAwesomeIcon icon="sitemap" className="mr-1 text-gray-400" size="sm" />
											{template.Name}
										</strong>
									)
								}}
							/>
						</li>
					)}
					{isInviting && (
						<li>
							<FormattedMessage id="importer.review.invitations" />
						</li>
					)}
				</ul>
			</div>

			<div className="mt-6 overflow-hidden border rounded-md">
				{data.slice(0, limit).map(row => (
					<FileItem key={row.key} item={createSecureSpace(row.name[0], creator, template, isInviting ? row.members : [])} />
				))}
				{limit < data.length && (
					<div className="flex items-center justify-center p-3">
						<button type="button" onClick={() => setLimit(limit => limit + SHOW_AT_A_TIME)}>
							<FormattedMessage id="importer.review.view_more" />
						</button>
					</div>
				)}
			</div>
			<footer className="flex items-center justify-between mt-12">
				<Button type="button" variant={Variant.primary} intent={Intent.tertiary} iconStart="arrow-left" animateIcon size={Size.sm} onClick={() => goToStep(2)}>
					3. <FormattedMessage id="importer.step.configuration" />
				</Button>
				<Button type="button" variant={Variant.success} iconEnd="file-import" animateIcon onClick={nextStep}>
					<FormattedMessage id="importer.review.import" />
				</Button>
			</footer>
		</Card>
	);
};
