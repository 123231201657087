import { FileManagerContext } from '@components/FileManager';
import File from '@models/File';
import Folder from '@models/Folder';
import { ViewContext } from '@providers/ViewProvider';
import type { Fileable } from '@types';
import { type FC, type PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const FileableContext = createContext<
	| {
			fileable: Fileable;
			navigate: (newTab?: boolean) => void;
			update: (fileable: Fileable) => void;
	  }
	| undefined
>(undefined);

type FileableProviderProps = PropsWithChildren<{
	fileable: Fileable;
}>;

export const FileableProvider: FC<FileableProviderProps> = ({ fileable, children }) => {
	const history = useHistory();
	const fileManagerContext = useContext(FileManagerContext);
	const viewContext = useContext(ViewContext);

	const [_innerFileable, setFileable] = useState(fileable);

	const navigate = (newTab = false) => {
		if (newTab) {
			window.open(fileable.getUrl(), '_blank')?.focus();
			return;
		}
		history.push(fileable.getUrl());
	};

	const update = (fileable: Fileable) => {
		fileManagerContext?.update?.(fileable);

		if ((viewContext?.view instanceof Folder || viewContext?.view instanceof File) && viewContext.view.id() === fileable.id()) {
			viewContext?.update(fileable);
		}
		setFileable(fileable);
	};

	useEffect(() => {
		setFileable(fileable);
	}, [fileable]);

	return <FileableContext.Provider value={{ fileable: _innerFileable, update, navigate }}>{children}</FileableContext.Provider>;
};
