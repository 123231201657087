import { type FC, type PropsWithChildren } from 'react';
import Loader from '@assets/images/Icon-Loader.svg?react';

const FullScreenLoading: FC<PropsWithChildren> = ({ children }) => {
	return (
		<div className="fixed inset-0 flex flex-col items-center justify-center w-screen h-screen bg-gray-200">
			<div className="mb-6">
				<Loader className="w-24" />
			</div>
			{children !== undefined && <p className="text-xl italic font-semibold text-gray-500">{children}</p>}
		</div>
	);
};

export default FullScreenLoading;
