import DialogTrialOffer from '@components/DialogTrialOffer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { trialDays } from '../../constants';
import { useEnvironment } from '@hooks/use-environment';

export const FreeTrialOfferBanner = () => {
	const { account } = useAccount();
	const location = useLocation();
	const environment = useEnvironment();

	const [showBanner, setShowBanner] = useLocalStorage('menu.show-trial-offer', true);
	const [showTrialOffer, setShowTrialOffer] = useState(false);

	if (!showBanner || environment || !location.pathname.startsWith('/files') || !account!.isAdmin() || account!.business.plan !== null || account!.business.trial_ends_at !== null) {
		return null;
	}

	return (
		<>
			<div className="relative py-4 pl-6 pr-12 bg-yellow-200">
				<div className="absolute top-4 right-6">
					<Button onClick={() => setShowBanner(false)} circle={true} icon="times" variant={Variant.warning} size={Size.xs} intent={Intent.tertiary} />
				</div>
				<div className="flex space-x-4">
					<FontAwesomeIcon icon="rocket" size="lg" className="mt-1 text-yellow-500" />
					<div>
						<p className="text-xs text-yellow-700 md:text-sm">
							<FormattedMessage id="banner.start_your_trial" />
						</p>
						<button
							className="flex pt-2 text-xs font-bold text-left text-yellow-700 underline whitespace-normal transition-colors md:text-sm focus:outline-none hover:text-yellow-600 focus:text-yellow-800"
							onClick={() => setShowTrialOffer(true)}>
							<FormattedMessage
								id="banner.start_your_trial_action"
								values={{
									n: trialDays
								}}
							/>
						</button>
					</div>
				</div>
			</div>
			{showTrialOffer && <DialogTrialOffer onAfterClose={() => setShowTrialOffer(false)} />}
		</>
	);
};
