import File from '@models/File';
import { Permissions } from '@types';

export const SUPPORTED_FILE_TYPES = ['pdf', 'doc', 'docx', 'odt', 'txt', 'rtf'];

export const isValidDocumentForSignRequest = (document: File) => {
	if (document.current.Size > 1024 * 1024 * 16) {
		return false;
	} else if (![Permissions.ReadWrite, Permissions.Editor].includes(document?.pivot?.Permissions || Permissions.None)) {
		return false;
	}
	return SUPPORTED_FILE_TYPES.includes((document.getFileExtension() || '').toLowerCase());
};
