import useClient from '@hooks/useClient';
import Account from '@models/Account';
import { useMutation, useQueryClient } from 'react-query';
import type { WretchError, WretchResponse } from 'wretch';
import { accountUpdater } from './account';

type SendCodeMutation = {
	phone: string;
	country: string;
};

type SmsValidationMutation = {
	phone: string;
	country: string;
	code: string;
	accountPassword?: string;
};

type AppValidationMutation = {
	secret: string;
	code: string;
	accountPassword?: string;
};

type MultiFactorValidatorPayload = {
	type: 'sms' | 'app';
	label?: string;
	secret_key?: string;
	url?: string;
};

export const useMultiFactorAuthRemoveMutation = () => {
	const queryClient = useQueryClient();
	const { client, authToken } = useClient();

	return useMutation<
		WretchResponse,
		WretchError,
		{
			accountPassword?: string;
		}
	>({
		mutationFn: async ({ accountPassword }) => await client.url('account/mfa').json({ account_password: accountPassword }).delete().res(),
		onMutate: async () => {
			await queryClient.cancelQueries(['account', authToken]);

			const previousAccount = queryClient.getQueryData<Account>(['account', authToken]);

			if (!previousAccount) {
				return undefined;
			}

			queryClient.setQueryData(['account', authToken], accountUpdater({ TwoFactorType: null }));

			return previousAccount;
		},
		onError: (_error, _variables, account) => {
			queryClient.setQueryData(['account', authToken], account);
		}
	});
};

export const useMultiFactorAuthSetupMutation = () => {
	const { client } = useClient();

	return useMutation<MultiFactorValidatorPayload, WretchError, string>({
		mutationFn: async type => {
			if (type === 'sms') {
				return { type: 'sms' };
			}

			return await client.url('account/mfa').put({ type: type }).json();
		}
	});
};

export const useMultiFactorAuthSendSmsCodeMutation = () => {
	const { client, setValidation } = useClient();

	return useMutation<WretchResponse, WretchError, SendCodeMutation>({
		mutationFn: async ({ phone, country }) => await client.url('account/mfa').put({ type: 'sms', phone, country }).res(),
		onError: error => {
			error.status === 422 && setValidation(error.json.errors);
		}
	});
};

export const useMultiFactorAuthValidateSmsMutation = () => {
	const queryClient = useQueryClient();
	const { client, authToken, setValidation } = useClient();

	return useMutation<WretchResponse, WretchError, SmsValidationMutation>({
		mutationFn: async ({ code, phone, country, accountPassword }) => await client.url('account/mfa/validate').put({ code, type: 'sms', phone, country, account_password: accountPassword }).res(),

		onMutate: async () => {
			await queryClient.cancelQueries(['account', authToken]);

			const previousAccount = queryClient.getQueryData<Account>(['account', authToken]);

			if (!previousAccount) {
				return undefined;
			}

			queryClient.setQueryData(['account', authToken], accountUpdater({ TwoFactorType: 'sms' }));

			return previousAccount;
		},
		onError: (error, _variables, account) => {
			error.status === 422 && setValidation(error.json.errors);
			queryClient.setQueryData(['account', authToken], account);
		}
	});
};

export const useMultiFactorAuthValidateAppMutation = () => {
	const queryClient = useQueryClient();
	const { client, authToken, setValidation } = useClient();

	return useMutation<{ recovery_codes: string[] }, WretchError, AppValidationMutation>({
		mutationFn: async ({ secret, code, accountPassword }) =>
			await client.url('account/mfa/validate').put({ account_password: accountPassword, secret, code, type: 'app' }).json<{ recovery_codes: string[] }>(),

		onMutate: async () => {
			await queryClient.cancelQueries(['account', authToken]);

			const previousAccount = queryClient.getQueryData<Account>(['account', authToken]);

			if (!previousAccount) {
				return undefined;
			}

			queryClient.setQueryData(['account', authToken], accountUpdater({ TwoFactorType: 'app' }));

			return previousAccount;
		},
		onError: (error, _variables, account) => {
			error.status === 422 && setValidation(error.json.errors);
			queryClient.setQueryData(['account', authToken], account);
		}
	});
};
