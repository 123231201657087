import { type CannedMessage, type CannedMessageCategory, CannedMessageCategoryForm, CannedMessageCategoryItem, CannedMessageForm } from '@components/CannedMessages';
import { Badge, Button, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { versionMiddleware } from '@service/Client';
import Card from '@ui/Card';
import EmptyState from '@ui/EmptyState';
import { Tab } from '@ui/Tab';
import { useState } from 'react';
import { DragDropContext, Draggable, type DropResult, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';

export const CannedMessageManager = () => {
	const { client } = useClient();
	const { formatMessage } = useIntl();
	const { account } = useAccount();

	const [showCreateMessage, setShowCreateMessage] = useState(false);
	const [showCreateCategory, setShowCreateCategory] = useState(false);
	const [categories, setCategories] = useState<CannedMessageCategory[]>([]);
	const [sharedCategories, setSharedCategories] = useState<CannedMessageCategory[]>([]);
	const [uncategorizedMessages, setUncategorizedMessages] = useState<CannedMessage[]>([]);
	const [uncategorizedSharedMessages, setUncategorizedSharedMessages] = useState<CannedMessage[]>([]);

	const { isLoading } = useQuery(
		['canned-messages'],
		async () =>
			await client
				.url('account/canned-messages')
				.middlewares([versionMiddleware(2)])
				.get()
				.json<CannedMessage[]>(),
		{
			structuralSharing: false,
			onSuccess: messages => {
				const categories: CannedMessageCategory[] = [];
				const sharedCategories: CannedMessageCategory[] = [];
				const uncategorizedMessages: CannedMessage[] = [];
				const uncategorizedSharedMessages: CannedMessage[] = [];

				messages.forEach(message => {
					const categoryIndex = categories.findIndex(category => category.Id === message.category?.Id);
					const sharedCategoryIndex = sharedCategories.findIndex(category => category.Id === message.category?.Id);

					message.Order = account?.ID === message.creator.ID ? message.Order : -1;

					if (message.creator.ID !== account!.ID && sharedCategoryIndex >= 0) {
						sharedCategories[sharedCategoryIndex].messages.push(message);
					} else if (message.creator.ID !== account!.ID && message.category) {
						message.category.messages = [message];
						sharedCategories.push(message.category);
					} else if (categoryIndex >= 0) {
						categories[categoryIndex].messages.push(message);
					} else if (message.category) {
						message.category.messages = [message];
						categories.push(message.category);
					} else if (message.creator.ID !== account!.ID) {
						uncategorizedSharedMessages.push(message);
					} else {
						uncategorizedMessages.push(message);
					}
				});

				setCategories(categories.sort((a, b) => a.Order - b.Order));
				setSharedCategories(sharedCategories);
				setUncategorizedMessages(uncategorizedMessages);
				setUncategorizedSharedMessages(uncategorizedSharedMessages);
			}
		}
	);

	const handleOnDragEnd = (result: DropResult) => {
		if (result.destination === undefined) {
			return;
		}

		var categList = categories;
		result.draggableId = result.draggableId.replace('c-', '');

		if (result.type === 'category') {
			client
				.url(`account/canned-categories/` + result.draggableId)
				.json({ order: result.destination.index })
				.put()
				.json<CannedMessage>();

			//Update la catégorie bougée dans la liste
			categList.forEach((categ, index) => {
				if (categ.Id === parseInt(result.draggableId)) {
					categ.Order = result.destination!.index;
					categList[index] = categ;
				}
			});

			//Update les catégories dans la liste affectées par le changement de position
			categList.forEach((categ, index) => {
				if (result.source.index < result.destination!.index) {
					if (categ.Order >= result.source.index && categ.Order <= result.destination!.index && categ.Id !== parseInt(result.draggableId)) {
						categ.Order = categ.Order - 1;
						categList[index] = categ;
					}
				} else if (result.source.index > result.destination!.index) {
					if (categ.Order >= result.destination!.index && categ.Order <= result.source.index && categ.Id !== parseInt(result.draggableId)) {
						categ.Order = categ.Order + 1;
						categList[index] = categ;
					}
				}
			});
			setCategories(categList.sort((a, b) => a.Order - b.Order));
		} else if (result.type === 'message') {
			//Si c'est un message qui se fait déplacer
			client
				.url(`account/canned-messages/` + result.draggableId)
				.json({ order: result.destination.index, category_id: result.destination.droppableId === 'undefined' ? undefined : result.destination.droppableId })
				.put()
				.json<CannedMessage>();

			var msgListDest: CannedMessage[] = [];
			var msgListSource: CannedMessage[] = [];

			//La catégorie de destination, soit une nouvelle categorie ou la catégorie courrante du message
			var destCateg = categories.find(categ => categ.Id === parseInt(result.destination!.droppableId) || (result.destination!.droppableId === '' && categ.Id === null));
			var sourceCateg = categories.find(categ => categ.Id === parseInt(result.source.droppableId) || (result.source.droppableId === '' && categ.Id === null));

			//Si la destination est les messages sans categories
			if (destCateg === undefined) {
				//La liste des messages sans category est la liste de destination
				msgListDest = uncategorizedMessages;
			} else if (destCateg !== undefined) {
				//La liste des messages dans la catégorie de destination
				msgListDest = destCateg.messages;
			}

			//Si la source du message est les messages sans categories
			if (sourceCateg === undefined) {
				//La liste des messages sans category est la liste source
				msgListSource = uncategorizedMessages;
			} else if (sourceCateg !== undefined) {
				//La liste des messages dans la catégorie source
				msgListSource = sourceCateg.messages;
			}

			var msgFound =
				result.source.droppableId === result.destination.droppableId
					? msgListDest.find(msg => String(msg.Id) === result.draggableId)
					: msgListSource.find(msg => String(msg.Id) === result.draggableId);

			//Si le message se fait trouver dans la liste de message de la catégorie
			if (msgFound) {
				//Si le message reste dans sa catégorie
				if (result.source.droppableId === result.destination.droppableId) {
					//update le message dans la liste
					var index = msgFound.Order;
					msgFound.Order = result.destination.index;
					msgListDest[index] = msgFound;

					msgListDest.forEach((msg, index) => {
						//Si la position initiale est plus petite que la position finale
						if (result.source.index < result.destination!.index) {
							//Si l'ordre du message est plus grand que la position initiale et plus petit ou égal a la position finale, on decremente leur position
							if (msg.Order >= result.source.index && msg.Order <= result.destination!.index && msg.Id !== parseInt(result.draggableId)) {
								msg.Order = msg.Order - 1;
								msgListDest[index] = msg;
							}
						}
						//Si la position initiale est plus grande que la position finale
						else if (result.source.index > result.destination!.index) {
							//Si l'ordre du message est plus grand ou égal à la position finale et plus petite que la position initiale, on incrémente leur position
							if (msg.Order >= result.destination!.index && msg.Order <= result.source.index && msg.Id !== parseInt(result.draggableId)) {
								msg.Order = msg.Order + 1;
								msgListDest[index] = msg;
							}
						}
					});

					//Replace l'ordre des messages dans la liste (juste pour être safe)
					msgListDest = msgListDest.sort((a, b) => a.Order - b.Order);

					//Update la liste des messages

					//Si la categorie source et de destination existe
					if (destCateg !== undefined && sourceCateg !== undefined) {
						const orderUpdated = [...categories];
						orderUpdated[destCateg?.Order].messages = msgListDest;
						setCategories(orderUpdated);
					}
				}
				//Si le message va dans une autre categorie
				else {
					//Update le message
					msgFound.Order = result.destination.index;

					//Ajoute le message dans la nouvelle catégorie et sort
					msgListDest.push(msgFound);
					msgListDest.sort((a, b) => a.Order - b.Order);

					msgListSource = msgListSource.filter(msg => String(msg.Id) !== result.draggableId);

					//Update les messages dans la liste des messages de la catégorie de destination
					msgListDest.forEach(async (msg, index) => {
						//Si le message est plus grand ou égale a la position finale et que c'est pas msgFound
						if (msg.Order >= result.destination!.index && msg.Id !== msgFound?.Id) {
							msg.Order = msg.Order + 1;
							msgListDest[index] = msg;
						}
					});

					//Update les categories qui ont été touché (ajoute le message a la bonne place dans la categ de destination et l'enlève de la categorie source)
					const orderUpdated = [...categories];
					if (destCateg) {
						orderUpdated[destCateg?.Order].messages = msgListDest;
					}
					if (sourceCateg) {
						orderUpdated[sourceCateg?.Order].messages = msgListSource.filter(msg => msg.Id !== parseInt(result.draggableId));
					}
					setCategories(orderUpdated);

					//Si on bouge un message sans categorie vers une categorie
					if (sourceCateg === undefined && destCateg !== undefined) {
						setUncategorizedMessages(messages => messages.filter(message => message.Id !== parseInt(result.draggableId)));
					}
				}
			}
		}
	};

	const skeletons = [];
	for (var i = 0; i < 3; i++) {
		skeletons.push(
			<div className="pt-4 animate-pulse">
				<div className="w-1/4 h-4 bg-gray-200 rounded"></div>

				<div className="mt-4">
					<div className="h-4 bg-gray-200 rounded"></div>
					<div className="h-4 mt-2 bg-gray-200 rounded"></div>
				</div>
			</div>
		);
	}

	const isEmpty = categories.length === 0 && uncategorizedMessages.length === 0 && sharedCategories.length === 0;

	return (
		<div className="max-w-3xl">
			{!isEmpty && (
				<DragDropContext onDragEnd={handleOnDragEnd}>
					<div className="flex items-center justify-end mb-3 space-x-3">
						<Button variant={Variant.primary} size={Size.sm} shadow icon="plus-circle" onClick={() => setShowCreateMessage(true)}>
							<FormattedMessage id="canned-messages-crud.create_canned_message" />
						</Button>
						{/* <Button variant={Variant.light} size={Size.sm} shadow icon="plus-circle" onClick={() => setShowCreateCategory(true)}>
							<FormattedMessage id="canned-categories-crud.create_canned_category" />
						</Button> */}
					</div>
					<div className="space-y-8">
						<Tab.Group>
							<Tab.List className="mt-4">
								<Tab>
									<span>
										<FormattedMessage id="canned-messages-crud.me" />
									</span>
									<Badge variant={Variant.dark} className="bg-gray-500">
										{categories.reduce((total, cat) => total + cat.messages.length, 0) + uncategorizedMessages.length}
									</Badge>
								</Tab>
								<Tab>
									<span>
										<FormattedMessage id="canned-messages-crud.organization" />
									</span>
									<Badge variant={Variant.dark} className="bg-gray-500">
										{sharedCategories.reduce((total, cat) => total + cat.messages.length, 0) + uncategorizedSharedMessages.length}
									</Badge>
								</Tab>
							</Tab.List>
							<Tab.Panels>
								<Tab.Panel>
									{categories.length > 0 && (
										<Droppable droppableId="categories" type="category">
											{provided => (
												<div {...provided.droppableProps} ref={provided.innerRef} className="space-y-8">
													{categories.map((category, index) => (
														<Draggable key={`c-${category.Id}`} draggableId={`c-${category.Id}`} index={index}>
															{provided => (
																<div className="space-y-4" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
																	<CannedMessageCategoryItem category={category} messages={category.messages} />
																</div>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									)}
									{uncategorizedMessages.length > 0 && (
										<div className="space-y-4">
											<Droppable droppableId="uncategorized" type="category">
												{provided => (
													<div {...provided.droppableProps} ref={provided.innerRef}>
														<CannedMessageCategoryItem messages={uncategorizedMessages} />
														{provided.placeholder}
													</div>
												)}
											</Droppable>
										</div>
									)}
								</Tab.Panel>
								<Tab.Panel>
									{sharedCategories.length > 0 && (
										<div className="space-y-4">
											{sharedCategories.map(category => (
												<CannedMessageCategoryItem category={category} messages={category.messages} editable={false} />
											))}
										</div>
									)}
									{uncategorizedSharedMessages.length > 0 && (
										<div className="space-y-4">
											<CannedMessageCategoryItem messages={uncategorizedSharedMessages} />
										</div>
									)}
								</Tab.Panel>
							</Tab.Panels>
						</Tab.Group>
					</div>
				</DragDropContext>
			)}

			{isLoading && (
				<Card size={null} className="px-6 pt-3 pb-5 space-y-4 mt-14">
					{skeletons}
				</Card>
			)}

			{isEmpty && !isLoading && (
				<EmptyState
					icon="comment-alt"
					title={formatMessage({ id: 'canned-messages-crud.empty-title' })}
					description={formatMessage({ id: 'canned-messages-crud.empty-body' })}
					action={
						<Button variant={Variant.primary} className="shadow text" onClick={() => setShowCreateMessage(true)}>
							<FontAwesomeIcon icon="plus" className="mr-2" />
							<FormattedMessage id="canned-messages-crud.create_canned_message" />
						</Button>
					}
				/>
			)}

			{showCreateMessage && <CannedMessageForm onAfterClose={() => setShowCreateMessage(false)} />}
			{showCreateCategory && <CannedMessageCategoryForm onAfterClose={() => setShowCreateMessage(false)} />}
		</div>
	);
};
