import { Title } from '@components/Account';
import { useAnnouncements } from '@components/Announcement';
import { AnnouncementItem } from '@components/Announcement/AnnouncementItem';
import AppContext from '@contexts/AppContext';
import { useContext, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FormattedMessage, useIntl } from 'react-intl';

export const AnnouncementsPage = () => {
	const { announcements = [], isLoading } = useAnnouncements();
	const { formatMessage } = useIntl();
	const { setPageTitle: setTitle, setPageHeader } = useContext(AppContext);

	useEffect(() => {
		setTitle(formatMessage({ id: 'announcements.title' }));
	}, [setTitle, formatMessage]);

	useEffect(() => {
		setPageHeader(
			<Title icon="bell">
				<FormattedMessage id="announcements.title" />
			</Title>
		);
	}, [setPageHeader]);

	return (
		<Scrollbars autoHide>
			<div className="max-w-5xl px-4 py-8 mx-auto md:px-8">
				{isLoading && (
					<div className="">
						<FormattedMessage id="loading" />
					</div>
				)}

				{!isLoading && announcements.length > 0 && (
					<div className="space-y-16">
						{announcements.map(announcement => (
							<AnnouncementItem key={announcement.Id} announcement={announcement} />
						))}
					</div>
				)}
			</div>
		</Scrollbars>
	);
};
