import { escapeRegExp } from '@utils/StringUtils';
import classNames from 'classnames';
import type { FC } from 'react';

export const Highlighter: FC<{ text: string; highlight: string; highlightedClassName?: string }> = ({ text, highlight, highlightedClassName }) => {
	if (!text.trim()) {
		return text;
	}

	const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi');
	const parts = text.split(regex);

	return (
		<span>
			{parts
				.filter(part => part)
				.map((part, i) =>
					regex.test(part) ? (
						<mark key={i} className={classNames(highlightedClassName)}>
							{part}
						</mark>
					) : (
						<span key={i}>{part}</span>
					)
				)}
		</span>
	);
};
