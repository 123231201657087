import { useCurrentTab, useSecureSpace } from '@components/FileManager';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { useAccount } from '@hooks/useAccount';
import File from '@models/File';
import Folder from '@models/Folder';
import { NavBar, NavTab } from '@ui/Navigation';
import type { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { type Fileable, Permissions } from '@types';

type FileManagerTabsProps = {
	fileable: Fileable;
};

export type TabType = {
	key?: string;
	labelId?: string;
	label?: ReactNode;
	icon: IconName;
	show?: boolean;
};

export const DEFAULT_TABS_ORDER = ['comments', 'files', 'sign-requests', 'payments', 'file-requests', 'timeline', 'versions'];

export const TABS: TabType[] = [
	{
		key: 'comments',
		labelId: 'file-manager.discussion',
		icon: 'comments'
	},
	{
		key: 'files',
		labelId: 'file-manager.files',
		icon: 'folders'
	},
	{
		key: 'sign-requests',
		labelId: 'file-manager.sign-requests',
		icon: 'signature'
	},
	{
		key: 'payments',
		labelId: 'file-manager.payment-requests',
		icon: 'dollar-sign'
	},
	{
		key: 'file-requests',
		labelId: 'file-manager.file-requests',
		icon: 'bells'
	},
	{
		key: 'timeline',
		labelId: 'file-manager.activity',
		icon: 'heartbeat'
	},
	{
		key: 'versions',
		labelId: 'file-manager.versions',
		icon: 'save'
	}
];

export const FileManagerTabs: FC<FileManagerTabsProps> = ({ fileable }) => {
	const { account } = useAccount();
	const secureSpace = useSecureSpace(fileable);

	// const tabsOrder = account?.Settings.TabsOrder ?? account?.business.Settings.TabsOrder ?? DEFAULT_TABS_ORDER;
	const tabsOrder = DEFAULT_TABS_ORDER;

	const tabs: Record<string, TabType> = {
		comments: {
			icon: 'comments',
			label: <FormattedMessage id="file-manager.discussion" />,
			show: (secureSpace && secureSpace.canViewMessages()) || (!secureSpace && fileable.canViewMessages())
		},
		files: {
			icon: 'folders',
			label: <FormattedMessage id="file-manager.files" />,
			show: fileable instanceof Folder
		},
		'sign-requests': {
			icon: 'signature',
			label: <FormattedMessage id="file-manager.sign-requests" />,
			show: fileable instanceof Folder && secureSpace?.pivot?.Permissions === Permissions.ReadWrite
		},
		payments: {
			icon: 'dollar-sign',
			label: <FormattedMessage id="file-manager.payment-requests" />,
			show: fileable instanceof Folder && secureSpace?.pivot?.Permissions === Permissions.ReadWrite
		},
		'file-requests': {
			icon: 'bells',
			label: <FormattedMessage id="file-manager.file-requests" />,
			show: ((secureSpace && secureSpace.canViewMessages()) || (!secureSpace && fileable.canViewMessages())) && secureSpace?.pivot?.Permissions === Permissions.ReadWrite
		},
		versions: {
			icon: 'save',
			label: <FormattedMessage id="file-manager.versions" />,
			show: fileable instanceof File && [Permissions.ReadWrite, Permissions.Editor].includes(fileable?.pivot?.Permissions || Permissions.None)
		},
		timeline: {
			icon: 'heartbeat',
			label: <FormattedMessage id="file-manager.activity" />,
			show: fileable?.pivot?.Permissions === Permissions.ReadWrite
		}
	};

	const currentTab = useCurrentTab();
	const activateFirstTab = !tabs[currentTab].show;
	const shownTabs = filterObjectByCondition(orderObjectByKeys(tabs, tabsOrder), tab => tab.show);

	return (
		<NavBar>
			{Object.entries(shownTabs).map(([key, tab], i) => (
				<NavTab key={key} icon={tab.icon} to={{ pathname: fileable.getUrl(key), state: { fileable } }} exact active={(activateFirstTab && i === 0) || currentTab === key}>
					{tab.label}
				</NavTab>
			))}
		</NavBar>
	);
};

function orderObjectByKeys(obj: Record<string, any>, keyOrder: string[]) {
	const orderedObj = {};

	// Iterate over the keyOrder array
	for (const key of keyOrder) {
		// Check if the key exists in the original object
		if (obj.hasOwnProperty(key)) {
			// Add the key-value pair to the ordered object
			orderedObj[key] = obj[key];
			// Delete the key from the original object to avoid duplication
			delete obj[key];
		}
	}

	// Add any remaining keys from the original object to the ordered object
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			orderedObj[key] = obj[key];
		}
	}

	return orderedObj;
}

function filterObjectByCondition(obj: Record<string, any>, conditionFn: (property: any, key: string) => boolean) {
	const filteredObj = {};

	for (const key in obj) {
		if (obj.hasOwnProperty(key) && conditionFn(obj[key], key)) {
			filteredObj[key] = obj[key];
		}
	}

	return filteredObj;
}
