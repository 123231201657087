import { useAccount } from '@hooks/useAccount';
import { Badge, Variant } from '@convoflo/ui';
import Card from '@ui/Card';
import ProgressBar from '@ui/ProgressBar';
import { filesize } from '@utils/StringUtils';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

export const OrganizationOverview = () => {
	const { locale } = useIntl();
	const { account } = useAccount();

	const plan = account!.business.plan;

	if (account!.Usage.Users.limit !== plan.Quota.Users) {
		plan.Quota.Users = account!.Usage.Users.limit || 0;
	}

	return (
		<Card className="space-y-6">
			<div className="grid items-center grid-cols-2 gap-2">
				<h2 className="font-medium text-black">
					<FormattedMessage id="plans.your_plan" />
				</h2>
				<p className="flex items-center gap-2">
					{plan.Key === 'bronze' && <i className="inline-block w-4 h-4 bg-yellow-900 rounded-full from-yellow-700 to-yellow-900 bg-gradient-to-br"></i>}
					{plan.Key === 'silver' && <i className="inline-block w-4 h-4 bg-gray-300 rounded-full from-gray-100 to-gray-300 bg-gradient-to-br"></i>}
					{plan.Key === 'gold' && <i className="inline-block w-4 h-4 bg-yellow-600 rounded-full from-yellow-400 to-yellow-600 bg-gradient-to-br"></i>}
					{plan.Key === 'platinum' && <i className="inline-block w-4 h-4 bg-gray-500 rounded-full from-gray-300 to-gray-500 bg-gradient-to-br"></i>}
					<span className="font-medium">{plan.Name}</span>
				</p>
				{plan.Key !== 'platinum' && (
					<p className="col-start-2">
						<Link to="/organization/subscription" className="text-xs text-theme-primary">
							<FormattedMessage id="upgrade_better_plan" />
						</Link>
					</p>
				)}
				<h2 className="text-sm text-gray-500">
					<FormattedMessage id="plans.plan_brand_image" />
				</h2>
				<div>
					{plan.Features.Branding ? (
						<Badge variant={Variant.success}>
							<FormattedMessage id="plans.included" />
						</Badge>
					) : (
						<Badge variant={Variant.warning}>
							<FormattedMessage id="plans.not_included" />
						</Badge>
					)}
				</div>
			</div>

			<div>
				<h2 className="mb-2 font-medium text-black">
					<FormattedMessage id="plans.limits" />
				</h2>
				<div className="grid items-center grid-cols-2 gap-2">
					<h2 className="text-sm text-gray-500">
						<FormattedMessage id="usage.secure_spaces" />
					</h2>

					<ProgressBar
						max={account!.Usage.SecureSpaces.limit != null ? account!.Usage.SecureSpaces.limit : 100}
						current={account!.Usage.SecureSpaces.used}
						unlimited={account!.Usage.SecureSpaces.limit === null}
						className="w-full h-4">
						<FormattedMessage
							id="x_of_n"
							values={{
								x: account!.Usage.SecureSpaces.used,
								n: account!.Usage.SecureSpaces.limit === null ? <FormattedMessage id="usage.unlimited" /> : account!.Usage.SecureSpaces.limit
							}}
						/>
					</ProgressBar>

					<h2 className="text-sm text-gray-500">
						<FormattedMessage id="usage.storage" />
					</h2>

					<ProgressBar max={account!.Usage.Space.limit != null ? account!.Usage.Space.limit : 100} current={account!.Usage.Space.used} className="w-full h-4">
						<FormattedMessage
							id="x_of_n"
							values={{
								x: filesize(account!.Usage.Space.used, locale),
								n: account!.Usage.Space.limit !== null ? filesize(account!.Usage.Space.limit, locale) : <FormattedMessage id="usage.unlimited" />
							}}
						/>
					</ProgressBar>

					<h2 className="text-sm text-gray-500">
						<FormattedMessage id="usage.users" />
					</h2>

					<ProgressBar
						max={account!.Usage.Users.limit !== null ? account!.Usage.Users.limit : 100}
						current={account!.Usage.Users.used}
						unlimited={account!.Usage.Users.limit === null}
						className="w-full h-4">
						<FormattedMessage
							id="x_of_n"
							values={{ x: account!.Usage.Users.used, n: account!.Usage.Users.limit === null ? <FormattedMessage id="usage.unlimited" /> : account!.Usage.Users.limit }}
						/>
					</ProgressBar>

					<h2 className="text-sm text-gray-500">
						<FormattedMessage id="plans.max_file_size" />
					</h2>

					<div>
						<p className="text-lg font-semibold">{filesize(plan.Quota.FileSize, locale)}</p>
					</div>
				</div>
			</div>
		</Card>
	);
};
