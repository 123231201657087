import { ConfigurationStep, ConflictsStep, DataStep, NamingStep, ResultsStep, ReviewStep, useImporter } from '@components/Importer';
import { Logo } from '@components/Logo';
import { OnboardingStep } from '@components/Onboarding';
import { Wizard } from '@components/Wizard';
import { Alert, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useIsDirty from '@hooks/useIsDirty';
import { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { FormattedMessage } from 'react-intl';
import { Prompt } from 'react-router-dom';

export const ImportPage = () => {
	const { creator, folder, template, data } = useImporter();
	const [step, setStep] = useState(0);

	const { isDirty } = useIsDirty([creator, folder, template, data]);

	return (
		<>
			<Prompt when={isDirty} message="Êtes-vous certain de vouloir quitter la page?" />
			<div className="h-screen bg-gradient-to-br from-gray-200 via-gray-100 to-white md:flex">
				<div className="flex flex-col px-6 py-6 overflow-auto bg-gradient-to-tl from-blue-600 via-blue-600 to-blue-500 md:py-16 md:px-8 md:w-80">
					<div className="mb-6 md:mb-16">
						<Logo dark className="h-8" />
					</div>
					<div className="flex-1">
						<h1 className="mb-6 text-4xl font-bold text-white">
							<FormattedMessage id="importer.title" />
						</h1>
						<p className="text-sm text-white">
							<FormattedMessage id="importer.subtitle" />
						</p>

						<ul className="my-8 space-y-6 md:my-12">
							<OnboardingStep current={step === 0} done={step > 0}>
								<FormattedMessage id="importer.step.import" />
							</OnboardingStep>
							<OnboardingStep current={step === 1} done={step > 1}>
								<FormattedMessage id="importer.step.naming" />
							</OnboardingStep>
							<OnboardingStep current={step === 2} done={step > 2}>
								<FormattedMessage id="importer.step.configuration" />
							</OnboardingStep>
							<OnboardingStep current={step === 4} done={step > 4}>
								<FormattedMessage id="importer.step.review" />
							</OnboardingStep>
							<OnboardingStep current={step === 5} done={step > 5}>
								<FormattedMessage id="importer.step.results" />
							</OnboardingStep>
						</ul>
					</div>
				</div>
				<div className="flex flex-col flex-1 pb-24 overflow-auto md:pb-0">
					<Scrollbars>
						<div className="px-6 py-6 max-w-7xl md:py-16 md:px-8 lg:px-16">
							{/* <div className="mb-6">
								<button type="button" onClick={() => setShowOutput(show => !show)}>
									Toggle data output
								</button>
								{showOutput && <pre className="p-3 overflow-scroll font-mono text-white bg-black rounded-md max-h-96">{JSON.stringify(data, null, 4)}</pre>}
							</div> */}
							<div className="mb-6 lg:hidden">
								<Alert variant={Variant.warning}>
									<FormattedMessage id="importer.mobile_warning" />
								</Alert>
							</div>
							<Wizard onStep={setStep}>
								<DataStep />
								<NamingStep />
								<ConflictsStep />
								<ConfigurationStep />
								{/* <EmailValidationStep /> */}
								<ReviewStep />
								<ResultsStep />
							</Wizard>
						</div>
					</Scrollbars>
				</div>
				<div className="flex-col justify-between hidden gap-12 p-6 overflow-auto bg-white border-l-2 border-gray-200 lg:flex md:w-64">
					<header className="space-y-2">
						{step === 0 && (
							<div className="prose-sm prose">
								<h3>Exportation de vos contacts</h3>
								<p>
									Nous vous offrons la compatibilité avec certains services de comptables, contacts ou de taxes. Si vous utilisez un service ne faisant pas partie de cette liste,
									vous avez toujours la possibilité, de faire votre importation manuellement à l'aide de choix "CSV".
								</p>
							</div>
						)}
						{step === 1 && (
							<div className="prose-sm prose">
								<h3>Nomenclature</h3>
								<p>Une bonne règle de nomenclature est d'utiliser l'identifiant unique du contact dans le nom de l'espace sécurisé.</p>
							</div>
						)}
					</header>
					<footer className="space-y-2">
						<FontAwesomeIcon icon="question-circle" className="mr-2 text-theme-primary" />
						<div className="prose-sm prose">
							<h4>Besoin d'assistance?</h4>
							<p className="text-xs">Afin de bien vous aider à commencer, notre équipe est disponible pour vous aider à la création de vos espaces sécurisés.</p>
						</div>
					</footer>
				</div>
			</div>
		</>
	);
};
