import { ContactManager, Title } from '@components/Account';
import AppContext from '@contexts/AppContext';
import { type FC, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const ContactsPage: FC = () => {
	const { setPageTitle } = useContext(AppContext);
	const { formatMessage } = useIntl();

	useEffect(() => {
		setPageTitle(formatMessage({ id: 'contact-manager.title' }));
	}, [formatMessage, setPageTitle]);

	return (
		<article>
			<Title>
				<FormattedMessage id="contact-manager.title" />
			</Title>
			<ContactManager />
		</article>
	);
};
