import { useUnitTransactions } from '@components/Lecsor';
import { InputDate, Modal, ModalBody, ModalHeaderOnlyTitle, ModalSize, Size, type ModalProps } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Folder from '@models/Folder';
import { formatDate } from '@utils/DateUtils';
import { subDays } from 'date-fns';
import { useState, type FC } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

type LecsorUnitDetailsProps = Omit<ModalProps, 'isOpen'> & {
	secureSpace: Folder;
};

export const LecsorUnitTransactions: FC<LecsorUnitDetailsProps> = ({ secureSpace, ...modalProps }) => {
	const { locale, formatMessage } = useIntl();
	const associationId = (secureSpace.meta ?? []).find(({ key }) => key === 'lecsor:association')?.value;
	const accountId = (secureSpace.meta ?? []).find(({ key }) => key === 'lecsor:account')?.value;

	const [fromDate, setFromDate] = useState<Date | null>(subDays(new Date(), 30));
	const [toDate, setToDate] = useState<Date | null>(new Date());

	const { transactions, isLoading } = useUnitTransactions(associationId, accountId, fromDate, toDate);

	if (!associationId || !accountId) {
		return null;
	}

	return (
		<Modal isOpen={true} size={ModalSize.Large} {...modalProps}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="lecsor.transaction_details" />
			</ModalHeaderOnlyTitle>
			<ModalBody>
				{isLoading && (
					<div className="grid my-8 place-items-center">
						<FontAwesomeIcon size="5x" icon="spinner" pulse className="text-gray-500" />
					</div>
				)}

				<div className="flex items-center gap-3 p-3 pl-4 bg-gray-100 rounded-lg">
					<label htmlFor="start_date" className="flex-grow text-sm">
						<FormattedMessage id="dashboard-labels.date_range" />
					</label>
					<InputDate id="start_date" size={Size.sm} value={fromDate} placeholder={formatMessage({ id: 'alert-history.select_date' })} onChange={setFromDate} />
					<label htmlFor="end_date" className="text-sm text-gray-500">
						<FormattedMessage id="dashboard-labels.end_date" />
					</label>
					<InputDate id="end_date" size={Size.sm} value={toDate} placeholder={formatMessage({ id: 'alert-history.select_date' })} onChange={setToDate} />
				</div>
				{transactions && (
					<div className="overflow-hidden">
						<div className="mb-6 -mx-6 overflow-x-auto">
							<div className="inline-block min-w-full px-6 py-2 align-middle">
								<table className="min-w-full">
									<thead>
										<tr>
											<th scope="col" className="py-3.5 pl-6 pr-3 text-sm font-semibold whitespace-nowrap text-right">
												<FormattedMessage id="date" />
											</th>
											<th scope="col" className="font-semibold text-sm text-left py-3.5 px-3">
												<FormattedMessage id="description" />
											</th>
											<th scope="col" className="font-semibold text-sm py-3.5 px-3 text-right">
												<FormattedMessage id="amount" />
											</th>
											<th scope="col" className="pr-6 pl-3 py-3.5 whitespace-normal text-right">
												<FormattedMessage id="balance" />
											</th>
										</tr>
									</thead>
									<tbody className="border-gray-100 divide-y">
										{transactions.transactions.map(transaction => (
											<tr>
												<td className="py-2 pl-6 pr-3 text-sm text-right text-gray-500 whitespace-nowrap">{formatDate(transaction.date, locale, 'PP')}</td>
												<td className="px-3 py-2 text-sm text-left whitespace-nowrap">{transaction.description}</td>
												<td className="px-3 py-2 text-sm text-right whitespace-nowrap">
													<FormattedNumber value={transaction.amount} style="currency" currency="cad" currencyDisplay="narrowSymbol" />
												</td>
												<td className="py-2 pl-3 pr-6 text-sm text-right whitespace-nowrap">
													<FormattedNumber value={transaction.balance} style="currency" currency="cad" currencyDisplay="narrowSymbol" />
												</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr>
											<td colSpan={3} className="px-3 py-2 text-sm font-semibold text-right border-t-2 border-gray-300 whitespace-nowrap">
												<FormattedMessage id="balance" />
											</td>
											<td className="py-2 pl-3 pr-6 text-sm text-right border-t-2 border-gray-300 whitespace-nowrap">
												<FormattedNumber value={transactions.current_balance} style="currency" currency="cad" currencyDisplay="narrowSymbol" />
											</td>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
					</div>
				)}
			</ModalBody>
		</Modal>
	);
};
