import { ComboBox, UserOption } from '@components/ComboBox';
import { type MemberType, useFileableMembers } from '@components/FileManager';
import { MessageFormContext } from '@components/Message';
import { UploadFile } from '@components/Upload';
import { Alert, Button, Checkbox, HelperText, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Row, ValidationField, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import Collaborator from '@models/Collaborator';
import File from '@models/File';
import { CurrencyInput } from '@ui/CurrencyInput';
import uniqWith from 'lodash.uniqwith';
import { type FC, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import type { OnChangeValue } from 'react-select';
import { defaultCurrency, stripeConnectConfigs } from '../../../../constants';

export type PaymentRequestMethod = 'card' | 'acss_debit' | null;

export type PaymentRequestOptions = {
	amount: number;
	users: Collaborator[];
	files: UploadFile[];
	method: PaymentRequestMethod;
};

type DialogPaymentRequestOptionsProps = Omit<ModalProps, 'isOpen'> &
	Partial<PaymentRequestOptions> & {
		onChange?: (options: PaymentRequestOptions) => void;
	};

export const DialogPaymentRequestOptions: FC<DialogPaymentRequestOptionsProps> = ({
	amount: initialAmount,
	users: initialUsers,
	files: initialFiles,
	method: initialMethod,
	onChange = () => undefined,
	...modalProps
}) => {
	const [isOpen, setIsOpen] = useState(true);
	const { account } = useAccount();
	const { validation, setValidation } = useClient();

	const { formatMessage, formatNumber } = useIntl();

	const { fileable, pluginData } = useContext(MessageFormContext);
	const { members = [] } = useFileableMembers(fileable);

	const [method, setMethod] = useState<PaymentRequestMethod>(initialMethod ?? null);
	const [amount, setAmount] = useState(initialAmount ?? 0);
	const [users, setUsers] = useState(initialUsers ?? []);
	const [files, setFiles] = useState(initialFiles ?? []);

	const onUsersChanged = (users: OnChangeValue<Collaborator, true>) => {
		setUsers(users.filter(() => true)); // this is stupid
	};

	const onSubmit = () => {
		onChange({ amount, users, files, method });
		setIsOpen(false);
	};

	const toggle = (file: UploadFile) => {
		setFiles(files => (files.some(f => file.id === f.id) ? files.filter(f => file.id !== f.id) : files.concat([file])));
	};

	const limit =
		method === 'acss_debit'
			? account!.business.AcssDebitPaymentLimit ?? stripeConnectConfigs.acss_debit.limit
			: method === 'card'
			? stripeConnectConfigs.card.limit
			: Object.entries(stripeConnectConfigs).reduce((acc, [_, method]) => Math.min(method.limit, acc), 99999999);

	const submittable = useMemo(() => amount > 0 && amount <= limit && users.length > 0, [amount, limit, users.length]);

	useEffect(() => {
		if (amount > limit) {
			setValidation({
				amount: [
					<FormattedMessage
						id="payment-requests.options.amount_limited"
						values={{
							limit: <FormattedNumber value={limit} style="currency" currency={defaultCurrency} currencyDisplay="symbol" />
						}}
					/>
				]
			});
		} else {
			setValidation({});
		}
	}, [limit, amount, setValidation, formatMessage, formatNumber]);

	const audience = useMemo(() => {
		const audience = !pluginData['audience']?.users?.length ? members : pluginData['audience'].users;

		return uniqWith<MemberType>(
			audience.filter((member: MemberType) => member.ID !== account?.ID),
			(a, b) => a.ID === b.ID
		);
	}, [pluginData['audience']?.users, members, account?.ID]);

	let fees = 0;

	if (method === 'card') {
		fees = amount * stripeConnectConfigs.card.fees.percentage + stripeConnectConfigs.card.fees.amount;
	} else if (method === 'acss_debit') {
		fees = Math.min(amount * stripeConnectConfigs.acss_debit.fees.percentage, stripeConnectConfigs.acss_debit.fees.maximum);
	}

	return (
		<Modal isOpen={isOpen} {...modalProps}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="payment-requests.options.title" />
			</ModalHeaderOnlyTitle>
			<ModalBody>
				<Row>
					<Label>
						<FormattedMessage id="payment-requests.options.method" />
					</Label>
					<div className="grid gap-3 sm:grid-cols-2">
						<Button
							type="button"
							block
							className="!justify-start !text-left"
							variant={Variant.dark}
							intent={method === 'card' ? Intent.primary : Intent.secondary}
							onClick={() => setMethod('card')}>
							<div className="flex items-start">
								<FontAwesomeIcon icon="credit-card" className="mt-1 mr-3" size="lg" />
								<div className="space-y-1">
									<h5 className="font-semibold">
										<FormattedMessage id="payment-requests.options.card" />
									</h5>
									<p className="text-xs whitespace-normal opacity-75">
										<FormattedMessage id="payment-requests.options.delay_card" />
									</p>
									<p className="text-xs opacity-75">
										<FormattedMessage
											id="payment-requests.options.fee_card"
											values={{
												percentage: <FormattedNumber value={stripeConnectConfigs.card.fees.percentage} minimumFractionDigits={1} style="percent" />,
												amount: <FormattedNumber value={stripeConnectConfigs.card.fees.amount} currencyDisplay="symbol" currency={defaultCurrency} style="currency" />
											}}
										/>
									</p>
									<p className="text-xs whitespace-normal opacity-75">
										<FormattedMessage
											id="payment-requests.options.limit"
											values={{
												value: <FormattedNumber value={stripeConnectConfigs.card.limit} currencyDisplay="symbol" currency={defaultCurrency} style="currency" />
											}}
										/>
									</p>
								</div>
							</div>
						</Button>
						<Button
							type="button"
							block
							className="!justify-start !text-left"
							variant={Variant.dark}
							intent={method === 'acss_debit' ? Intent.primary : Intent.secondary}
							onClick={() => setMethod('acss_debit')}>
							<div className="flex items-start">
								<FontAwesomeIcon icon="university" className="mt-1 mr-3" size="lg" />
								<div className="space-y-1">
									<h5 className="font-semibold">
										<FormattedMessage id="payment-requests.options.bank_transfer" />
									</h5>
									<p className="text-xs whitespace-normal opacity-75">
										<FormattedMessage id="payment-requests.options.delay_bank" />
									</p>
									<p className="text-xs opacity-75">
										<FormattedMessage
											id="payment-requests.options.fee_bank"
											values={{
												percentage: <FormattedNumber value={stripeConnectConfigs.acss_debit.fees.percentage} style="percent" />,
												max: <FormattedNumber value={stripeConnectConfigs.acss_debit.fees.maximum} currencyDisplay="symbol" currency={defaultCurrency} style="currency" />
											}}
										/>
									</p>
									<p className="text-xs whitespace-normal opacity-75">
										<FormattedMessage
											id="payment-requests.options.limit"
											values={{
												value: (
													<FormattedNumber
														value={account!.business.AcssDebitPaymentLimit ?? stripeConnectConfigs.acss_debit.limit}
														currencyDisplay="symbol"
														currency={defaultCurrency}
														style="currency"
													/>
												)
											}}
										/>
									</p>
								</div>
							</div>
						</Button>
					</div>
					<button className="mt-2 text-sm text-theme-primary focus:outline-none hover:underline" onClick={() => setMethod(null)} type="button">
						<FormattedMessage id="payment-requests.options.no_preference" />
					</button>
				</Row>
				<Row>
					<Label htmlFor="amount">
						<FormattedMessage id="payment-requests.options.amount" />
					</Label>
					<div className="max-w-56">
						<ValidationField validation={validation} fieldName="amount">
							<CurrencyInput value={amount} onChange={setAmount} className="flex max-w-56" />
						</ValidationField>
					</div>
					<HelperText>
						<FormattedMessage id="payment-requests.options.amount_desc" />
					</HelperText>
				</Row>

				<Row>
					{fees > 0 && (
						<Alert variant={Variant.warning} className="flex-1">
							<FormattedMessage
								id="payment-requests.options.fee_approx"
								values={{
									strong: msg => <strong className="font-semibold">{msg}</strong>,
									amount: <FormattedNumber value={fees} currencyDisplay="symbol" currency={defaultCurrency} style="currency" />
								}}
							/>
						</Alert>
					)}
				</Row>
				<Row>
					<Label>
						<FormattedMessage id="payment-requests.options.users" />
					</Label>

					<ComboBox
						value={users}
						options={audience}
						getOptionValue={member => member.Email}
						components={{
							Option: UserOption
						}}
						placeholder={<FormattedMessage id="select" />}
						getOptionLabel={member => member.Name!}
						isSearchable
						isMulti
						onChange={onUsersChanged}
					/>
					<HelperText>
						<FormattedMessage id="payment-requests.options.users_desc" />
					</HelperText>
				</Row>
				{!pluginData['files']?.files?.length ? (
					<Alert variant={Variant.info}>
						<h5 className="mb-1 font-medium">
							<FormattedMessage id="did_you_know" />
						</h5>
						<p className="text-xs">
							<FormattedMessage id="payment-requests.add_files_tip" />
						</p>
					</Alert>
				) : (
					<Row>
						<Label>
							<FormattedMessage id="payment-requests.options.files" />
						</Label>

						<div className="overflow-hidden border border-gray-400 divide-y divide-gray-300 rounded-md">
							{pluginData['files'].files.map((file: UploadFile) => (
								<button
									type="button"
									className={`flex items-center w-full px-3 py-2 cursor-pointer ${files.some(f => file.id === f.id) ? 'bg-blue-100' : 'bg-gray-50'} focus:outline-none`}
									onClick={() => toggle(file)}>
									<Checkbox checked={files.some(f => file.id === f.id)} className="flex-1 pointer-events-none">
										<span className="text-sm text-gray-600">{file.file?.name}</span>
									</Checkbox>
									{File.fromName(file.file?.name ?? '').icon()}
								</button>
							))}
						</div>

						<HelperText>
							<FormattedMessage id="payment-requests.options.files_desc" />
						</HelperText>
					</Row>
				)}
			</ModalBody>
			<ModalFooter>
				<Button disabled={!submittable} variant={Variant.primary} intent={Intent.primary} type="button" onClick={onSubmit}>
					<FormattedMessage id="payment-requests.options.attach" />
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
