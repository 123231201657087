import { FileList } from '@components/FileManager';
import AppContext from '@contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useView } from '@hooks/use-view';
import { type FC, useContext, useEffect } from 'react';

type SearchViewPageProps = {
	query: string;
};

export const SearchViewPage: FC<SearchViewPageProps> = ({ query }) => {
	const { setEnableFullScreenDragNDrop } = useView();
	const { setPageHeader, setShowGlobalSearch } = useContext(AppContext);

	useEffect(() => {
		setEnableFullScreenDragNDrop(false);
	}, [setEnableFullScreenDragNDrop]);

	useEffect(() => {
		setPageHeader(
			<button type="button" onClick={() => setShowGlobalSearch(true)} className="text-base italic text-gray-600">
				<FontAwesomeIcon icon="search" fixedWidth />
				<FontAwesomeIcon icon="chevron-right" size="xs" className="mx-4 text-gray-400" />
				{query}
			</button>
		);
	}, [query, setPageHeader, setShowGlobalSearch]);

	return (
		<>
			<div className="py-8 mx-auto sm:px-6 max-w-7xl">
				<FileList view={query} />
			</div>
		</>
	);
};
