import type { ScheduledMessageType } from '@components/FileRequest';
import { Button, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Variant } from '@convoflo/ui';
import useClient from '@hooks/useClient';
import { type FC, type FormEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import type { WretchError, WretchResponse } from 'wretch';

export type DialogDeleteFileRequestProps = Omit<ModalProps, 'isOpen'> & {
	reminder: ScheduledMessageType;
};

export const DialogDeleteFileRequest: FC<DialogDeleteFileRequestProps> = ({ reminder, ...modalProps }) => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	const [isOpen, setIsOpen] = useState(true);

	const { mutate: _delete, isLoading: isDeleting } = useMutation<WretchResponse, WretchError, ScheduledMessageType>(
		async fileRequest => await client.url(`file_requests/${fileRequest.Id}`).delete().res(),
		{
			onError: console.error,
			onSuccess: () => {
				queryClient.invalidateQueries('reminders');
				setIsOpen(false);
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		_delete(reminder);
	};

	return (
		<Modal isOpen={isOpen} onSubmit={onSubmit} {...modalProps}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="file-requests-crud.remove_message.title" />
			</ModalHeaderOnlyTitle>
			<ModalBody>
				<p>
					<FormattedMessage id="file-requests-crud.remove_message.description" />
				</p>
			</ModalBody>
			<ModalFooter>
				<Button type="submit" disabled={isDeleting} variant={Variant.danger}>
					<FormattedMessage id="delete" />
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
