import { Button, Modal, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Variant } from '@convoflo/ui';
import { UploadFile, UploadListItem, UploadStatus, useUploadService } from '@components/Upload';
import { useAccount } from '@hooks/useAccount';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useFileableMembers } from '.';
import { NotificationConfirmationToastContents } from '../NotificationsListener';
import File from '@models/File';

type DialogNewFileVersionProps = Omit<ModalProps, 'isOpen'> & {
	file: globalThis.File;
	view: File;
};

export const DialogNewFileVersion: FC<DialogNewFileVersionProps> = ({ file, view, ...modalProps }) => {
	const [isOpen, setIsOpen] = useState(true);
	const [running, setRunning] = useState(true);
	const queryClient = useQueryClient();
	const { account } = useAccount();
	const { members = [] } = useFileableMembers(view);

	const service = useUploadService(view, { start: true });

	const onFileUpdated = useCallback(
		(file: UploadFile) => {
			if (file.status === UploadStatus.Completed && file.model) {
				queryClient.invalidateQueries(['view', `file:${file.model.URL}`, 'versions']);
				queryClient.setQueryData<File>(['view', `file:${file.model.URL}`], file.model);
				setIsOpen(false);
				const usersInToast = members.filter(collaborator => !!collaborator.pivot.Notify && collaborator.ID !== account?.ID);

				if (usersInToast.length > 0) {
					toast.success(
						<div>
							<h5 className="font-semibold">
								<FormattedMessage id="success.new_version_sent" />
							</h5>
							<NotificationConfirmationToastContents users={usersInToast} />
						</div>,
						{ id: 'notification-confirmation', duration: 15000 }
					);
				} else {
					toast.success(<FormattedMessage id="success.new_version" />, { id: 'notification-confirmation', duration: 15000 });
				}
			}
		},
		[queryClient, members, account?.ID]
	);

	useEffect(() => {
		if (!running) {
			setIsOpen(false);
		}
	}, [running]);

	const uploadFile = useMemo(() => UploadFile.fromFile(file, view), [file, view.getKey()]);

	return (
		<Modal isOpen={isOpen} {...modalProps}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="uploading_new_version" />
			</ModalHeaderOnlyTitle>

			<div className="my-6">
				<UploadListItem key={uploadFile.id} service={service} file={uploadFile} onFileUpdated={onFileUpdated} stop={!running} />
			</div>

			<ModalFooter>
				<Button onClick={() => setRunning(false)} variant={Variant.danger}>
					<FormattedMessage id="abort" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
