import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { type FC, type ReactNode, useMemo } from 'react';
import { Link, type LinkProps, useLocation } from 'react-router-dom';

type SidebarLinkProps = Omit<LinkProps, 'title'> & {
	description?: ReactNode;
	icon?: IconProp;
	iconColor?: string;
	count?: number;
};

export const SidebarLink: FC<SidebarLinkProps> = ({ description, icon, count, children, ...linkProps }) => {
	const location = useLocation();
	const match = useMemo(() => {
		if (typeof linkProps.to === 'object' && linkProps.to.pathname === location.pathname && linkProps.to.search === location.search.substring(1)) {
			return true;
		}
		if (typeof linkProps.to === 'string' && location.pathname === linkProps.to && location.search === '') {
			return true;
		}

		return false;
	}, [location, linkProps]);

	return (
		<Link className={classNames('group flex items-center px-4 py-2 transition rounded-r-lg', { 'bg-white': match })} {...linkProps}>
			{icon !== undefined && <FontAwesomeIcon icon={icon} fixedWidth className={classNames('transition mr-2 mt-0.5', { 'opacity-100': match, 'opacity-50 group-hover:opacity-75': !match })} />}
			<div className="flex flex-1 space-y-1">
				<h3 className={classNames('text-lg md:text-sm font-medium transition-colors ', { 'text-gray-800': match, 'text-gray-600 group-hover:text-gray-700': !match })}>{children}</h3>
			</div>
			{count !== undefined && <span className="py-0.5 px-2.5 text-xs text-white bg-gray-400 rounded-full">{count}</span>}
		</Link>
	);
};
