import { FileManagerContext, type MemberType } from '@components/FileManager';
import useClient from '@hooks/useClient';
import File from '@models/File';
import Folder from '@models/Folder';
import { versionMiddleware } from '@service/Client';
import { type Fileable, type FileablePivotType, Permissions } from '@types';
import { useCallback, useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { WretchError } from 'wretch';

type UpdatePermissionsMutation = {
	members: MemberType[];
	recursive: boolean;
};

export type NullablePivotMemberType = Omit<MemberType, 'pivot'> & {
	pivot: FileablePivotType | null;
};

export const useFileableMembers = (fileable?: Fileable, { loadSuggestions = false, orderBy = '' } = {}) => {
	const { client } = useClient();
	const queryClient = useQueryClient();
	const fileManagerContext = useContext(FileManagerContext);

	const queryKey = ['view', `${fileable instanceof File ? 'file' : 'folder'}:${fileable?.URL ?? ''}`, 'members'];

	const { data: members, isLoading } = useQuery(
		queryKey,
		async () =>
			await client
				.url(`${fileable!.getRoute('members', fileable instanceof File ? 'files' : undefined)}?order=${orderBy ?? ''}`)
				.middlewares([versionMiddleware(2)])
				.get()
				.json<MemberType[]>(),
		{
			enabled: !!fileable?.getKey(),
			onError: console.error,
			refetchOnMount: 'always',
			refetchOnWindowFocus: false
		}
	);

	const { data: suggestions, isLoading: isSuggestionsLoading } = useQuery(
		[...queryKey, 'suggestions'],
		async () =>
			await client
				.url(fileable!.getRoute('members/suggestions', fileable instanceof File ? 'files' : undefined))
				.middlewares([versionMiddleware(2)])
				.get()
				.json<NullablePivotMemberType[]>(),
		{
			enabled: !!fileable && loadSuggestions,
			onError: console.error,
			refetchOnMount: 'always',
			refetchOnWindowFocus: false
		}
	);

	const { mutateAsync: _update, isLoading: isUpdating } = useMutation<Fileable, WretchError, UpdatePermissionsMutation>(
		async ({ members, recursive }) => {
			let newOwner: MemberType | undefined;

			members.forEach(member => {
				if (member.pivot.Permissions === Permissions.Owner) {
					member.pivot.Permissions = Permissions.ReadWrite;
					newOwner = member;
				}
			});

			let payload = await client
				.url(fileable!.getRoute('members', fileable instanceof File ? 'files' : undefined))
				.middlewares([versionMiddleware(2)])
				.post({
					users: Object.fromEntries(new Map(members.map(member => [member.ID, { Permissions: member.pivot.Permissions, Notify: !!member.pivot.Notify }]))),
					recursive: recursive ? 1 : 0,
					owner: newOwner?.ID
				})
				.json<Fileable>();

			if (payload['@type'] === 'Document') {
				payload = new File(payload);
			} else if (payload['@type'] === 'Folder') {
				payload = new Folder(payload);
			}

			return payload;
		},
		{
			onError: error => console.error(error),
			onSuccess: (_fileable, { recursive }) => {
				if (_fileable instanceof File) {
					queryClient.setQueryData<File>(['view', `file:${_fileable.URL}`], _fileable);
				} else if (_fileable instanceof Folder) {
					queryClient.setQueryData<Folder>(['view', `folder:${_fileable.URL}`], _fileable);
				}
				if (recursive && _fileable instanceof Folder) {
					queryClient.invalidateQueries(['files', `folder:${fileable!.URL}`]);
				}
				queryClient.removeQueries(queryKey);
				fileManagerContext?.update?.(_fileable);
			}
		}
	);

	const update = useCallback(async (members: MemberType[], options: { recursive: boolean }) => _update({ members, ...options }), [_update]);

	return {
		members,
		suggestions,
		update,
		isLoading,
		isUpdating,
		isSuggestionsLoading
	};
};
