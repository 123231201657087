import classNames from 'classnames';
import type { FC, LiHTMLAttributes, OlHTMLAttributes, ReactNode } from 'react';

type StepsProps = OlHTMLAttributes<HTMLOListElement>;

const Steps: FC<StepsProps> = ({ className = '', ...props }) => {
	return <ol className={`list-none ${className} steps`} {...props} />;
};

type StepProps = LiHTMLAttributes<HTMLLIElement>;

export const Step: FC<StepProps> = ({ className = '', ...props }) => {
	return <li className={`mb -6 pl-12 ${className}`} {...props} />;
};

type StepsCircleProps = {
	current?: number;
	labels: ReactNode[];
	className?: string;
	size?: 'sm';
};

export const StepsCircle: FC<StepsCircleProps> = ({ size, labels, current = 1, className }) => {
	return (
		<ul className={classNames(className, 'inline-grid grid-flow-col overflow-hidden overflow-x-auto auto-cols-fr')} style={{ counterReset: 'step' }}>
			{labels.map((label, index) => (
				<li
					className={classNames(
						'after:content-[attr(data-content)] first:before:content-none grid grid-cols-1 grid-rows-2 text-center text-gray-400 place-items-center before:top-0 before:w-full before:col-start-1 before:row-start-1 before:bg-gray-300 before:-ml-[100%] after:z-[1] after:relative after:grid after:col-start-1 after:row-start-1 after:bg-gray-300 after:text-gray-400 after:rounded-full after:place-self-center after:place-items-center content-[counter(step)] gap-y-2',
						{
							'font-semibold text-theme-primary peer peer-[:before]:text-white peer-[:before]:bg-theme-primary after:!text-white after:bg-theme-primary': index < current,
							'min-w-16 sm:text-sm md:text-base lg:text-lg xl:text-xl before:size-2 after:size-8 after:text-lg': size === undefined,
							'min-w-12 text-xs md:text-sm before:size-0.5 after:size-6 after:text-xs': size === 'sm'
						}
					)}
					data-content={index + 1}
					style={{ counterIncrement: 'step' }}>
					{label}
				</li>
			))}
		</ul>
	);
};

export default Steps;
