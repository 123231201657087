import { createContext } from 'react';

import { attachInstruction, extractInstruction } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/tree-item';
import type { TreeAction, TreeNodeType } from './types';

export type TreeContextValue = {
	dispatch: (action: TreeAction) => void;
	uniqueContextId: Symbol;
	getPathToItem: (itemId: string) => string[];
	getMoveTargets: ({ itemId }: { itemId: string }) => TreeNodeType[];
	getChildrenOfItem: (itemId: string) => TreeNodeType[];
	registerTreeItem: (args: { itemId: string; element: HTMLElement; actionMenuTrigger: HTMLElement }) => void;
};

export const TreeContext = createContext<TreeContextValue>({
	dispatch: () => {},
	uniqueContextId: Symbol('uniqueId'),
	getPathToItem: () => [],
	getMoveTargets: () => [],
	getChildrenOfItem: () => [],
	registerTreeItem: () => {}
});

export const DependencyContext = createContext<{
	DropIndicator: typeof DropIndicator;
	attachInstruction: typeof attachInstruction;
	extractInstruction: typeof extractInstruction;
}>({
	DropIndicator: DropIndicator,
	attachInstruction: attachInstruction,
	extractInstruction: extractInstruction
});
