import ConvofloLogoEnglish from '@assets/images/LogoTemp-en.svg?react';
import ConvofloLogoFrench from '@assets/images/LogoTemp-fr.svg?react';
import AppContext from '@contexts/AppContext';
import { useEnvironment } from '@hooks/use-environment';
import { useAccount } from '@hooks/useAccount';
import classNames from 'classnames';
import { type FC, useContext } from 'react';
import { useIntl } from 'react-intl';

type BaseLogoProps = {
	className?: string;
};

type LightLogoProps = BaseLogoProps & {
	light: true;
	dark?: never;
};

type DarkLogoProps = BaseLogoProps & {
	light?: never;
	dark: true;
};

export const Logo: FC<LightLogoProps | DarkLogoProps> = ({ light, dark, className }) => {
	let { logo: logoUrl } = useContext(AppContext);
	const { account } = useAccount();
	const { locale } = useIntl();
	const environment = useEnvironment();

	// Overrides everything if there's a url
	if (logoUrl !== null) {
		return <img alt="" src={logoUrl} className={className} />;
	}

	// Requests dark logo and business has one
	if (dark && account?.business.LogoDark) {
		return <img alt={account.business.Name || environment?.Name || 'Convoflo'} src={account.business.LogoDark} className={className} />;
	}

	// Requests dark logo but business doesn't have a dark logo, but has a light one
	if (dark && account?.business.LogoLight) {
		return (
			<div className="bg-white rounded-md">
				<img alt={account.business.Name || environment?.Name || 'Convoflo'} src={account.business.LogoLight} className={className} />
			</div>
		);
	}

	// Requests light logo if the business has one
	if (light && account?.business.LogoLight) {
		return <img alt={account.business.Name || environment?.Name || 'Convoflo'} src={account.business.LogoLight} className={className} />;
	}

	// Requests dark logo but business doesn't have any logos, use env
	if (dark && environment?.LogoDark) {
		return <img alt={account?.business.Name || environment.Name} src={environment.LogoDark} className={classNames('text-white fill-current', className)} />;
	}

	// Requests dark logo but env doesn't have a dark logo, use light logo with a white background
	if (dark && environment?.LogoLight) {
		return (
			<div className="bg-white rounded-md">
				<img alt={account?.business.Name || environment.Name} src={environment.LogoLight} className={classNames('text-white', className)} />
			</div>
		);
	}

	// Requests light logo, use env
	if (light && environment?.LogoLight) {
		return <img alt={account?.business.Name || environment.Name} src={environment.LogoLight} className={className} />;
	}

	// Use the app's logo
	return locale === 'fr' ? (
		<ConvofloLogoFrench
			className={classNames('fill-current', className, {
				'text-theme-canvas-lightest': dark,
				'text-theme-inverse': light
			})}
		/>
	) : (
		<ConvofloLogoEnglish
			className={classNames('fill-current', className, {
				'text-theme-canvas-lightest': dark,
				'text-theme-inverse': light
			})}
		/>
	);
};
