import { ViewContext } from '@providers/ViewProvider';
import { useContext } from 'react';

export const useView = () => {
	const context = useContext(ViewContext);

	if (context === undefined) {
		throw new Error('useView() must be used within a ViewContext');
	}

	return context;
};
