import { GuidedTour } from '@components/GuidedTour';
import { NotificationItem, type NotificationType } from '@components/Notification';
import { createLengthAwarePagination } from '@components/Pagination';
import { Badge, Button, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeader, ModalHeaderOnlyTitle, type ModalProps, ModalSize, Row, Select, Size, Toggle, Variant } from '@convoflo/ui';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { useLocalStorage } from '@hooks/useLocalStorage';
import useLocationChange from '@hooks/useLocationChange';
import { useUserSummary } from '@hooks/useUserSummary';
import { useWidgets } from '@hooks/useWidgets';
import { useMarkNotificationAsReadMutation } from '@state/queries/notifications';
import type { Summary, SummaryType } from '@types';
import { StatCard } from '@ui/Card';
import { Tab } from '@ui/Tab';
import { Tooltip } from '@ui/Tooltip';
import UserAvatar from '@ui/UserAvatar';
import { subMonths } from 'date-fns';
import qs from 'qs';
import { type FC, type FormEvent, Fragment, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage } from 'react-intl';
import { useInfiniteQuery } from 'react-query';
import { Link } from 'react-router-dom';
import Widget from './Widget';

const UserSummary: FC = () => {
	const { onWidgetLoaded } = useWidgets();
	const { account } = useAccount();
	const { summary, status } = useUserSummary();

	const [markAllAsReadModalOpened, setMarkAllAsReadModalOpened] = useState(false);

	useEffect(() => {
		if (status === 'success') {
			onWidgetLoaded();
		}
	}, [status, onWidgetLoaded]);

	return (
		<Widget
			icon={<FontAwesomeIcon icon="bell" className="mr-2 text-yellow-500" mask="square" transform="shrink-8" size="2x" />}
			header={<FormattedMessage id="user-summary.new_alerts" values={{ count: summary.total_count }} />}
			subHeader={<FormattedMessage id="user-summary.subtitle" />}
			actions={[
				<Tooltip tip={<FormattedMessage id="user-summary.mark_as_read" />}>
					<Button size={Size.sm} onClick={() => setMarkAllAsReadModalOpened(true)} circle variant={Variant.light}>
						<FontAwesomeIcon icon="check-double" fixedWidth />
					</Button>
				</Tooltip>
			]}>
			{summary.total_count === 0 && status === 'success' && (
				<div className="flex flex-col items-center justify-center p-12 bg-white bg-opacity-50 rounded">
					<FontAwesomeIcon icon="check-double" size="2x" className="text-gray-300" />
					<h4 className="mt-4 font-semibold text-gray-600">
						<FormattedMessage id="user-summary.empty" />
					</h4>
				</div>
			)}

			{summary.total_count === 0 && status === 'loading' && (
				<div className="flex flex-col items-center justify-center p-12 bg-white bg-opacity-50 rounded">
					<h4 className="mt-4 font-semibold text-gray-600">
						<FontAwesomeIcon icon="spinner" spin className="mr-2" />
						<FormattedMessage id="loading" />
					</h4>
				</div>
			)}

			{summary.total_count > 0 && (
				<>
					<div className="grid grid-cols-2 gap-4 pb-2 lg:grid-cols-3 xl:grid-cols-4" id="step-summary">
						{Object.entries(summary.summary)
							.filter(([_, summary]) => summary.count.external + summary.count.internal > 0)
							.map(([type, summary], index) => (
								<span key={type} id={index <= 0 ? 'step-tile' : ''}>
									<UserSummaryBlock key={type} type={type as SummaryType} summary={summary} />
								</span>
							))}
					</div>
					<p className="text-xs italic font-light text-gray-400">
						<FormattedMessage id="user-summary.limit" />
					</p>

					<GuidedTour name="summary" enabled={account?.hasFullAccess()} />
				</>
			)}

			{markAllAsReadModalOpened && <UserSummaryMarkAsReadModal onAfterClose={() => setMarkAllAsReadModalOpened(false)} total={summary.total_count} />}
		</Widget>
	);
};

type UserSummarySettingsModalProps = Omit<ModalProps, 'isOpen'> & {
	total?: number;
	onMarkAllAsRead?: () => void;
};

export const UserSummarySettingsModal: FC<UserSummarySettingsModalProps> = ({ ...modalProps }) => {
	const [isOpen, setIsOpen] = useState(true);

	const [frequency, setFrequency] = useLocalStorage('summary.frequency', 'daily');

	return (
		<Modal isOpen={isOpen} size={ModalSize.XLarge} {...modalProps}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="user-summary.settings_title" />
			</ModalHeaderOnlyTitle>
			<ModalBody>
				<Row>
					<Label htmlFor="frequency">
						<FormattedMessage id="account-settings.frequency" />
					</Label>
					<Select onChange={e => setFrequency(e.target.value)} value={frequency}>
						<FormattedMessage id="account-settings.frequency-daily">{message => <option value="daily">{message}</option>}</FormattedMessage>
						<FormattedMessage id="account-settings.frequency-always">{message => <option value="always">{message}</option>}</FormattedMessage>
						<FormattedMessage id="account-settings.frequency-disabled">{message => <option value="disabled">{message}</option>}</FormattedMessage>
					</Select>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button variant={Variant.light} intent={Intent.secondary} type="button" onClick={() => setIsOpen(false)}>
					<FormattedMessage id="close" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};

type UserSummaryMarkAsReadModalProps = Omit<ModalProps, 'isOpen'> & {
	total?: number;
};

export const UserSummaryMarkAsReadModal: FC<UserSummaryMarkAsReadModalProps> = ({ total = 0, ...modalProps }) => {
	const [isOpen, setIsOpen] = useState(true);

	const { mutateAsync: markNotificationAsRead } = useMarkNotificationAsReadMutation();

	const onSubmit = (event: FormEvent) => {
		event.preventDefault();
		markNotificationAsRead({
			types: [
				'comment.created',
				'comment.read',
				'comment.replied',
				'folder.items.added',
				'folder.users.added',
				'secure-space.shared',
				'sign-request.created',
				'sign-request.completed',
				'file-request.created',
				'file-request.completed'
			]
		});
		setIsOpen(false);
	};

	return (
		<Modal isOpen={isOpen} onSubmit={onSubmit} size={ModalSize.XLarge} {...modalProps}>
			<ModalBody>
				<p className="mb-4 leading-snug">
					<FormattedMessage id="user-summary.warning_mark_all_as_read" values={{ total }} />
				</p>
				<p className="font-semibold text-red-700">
					<FormattedMessage id="user-summary.warning_mark_all_as_read_2" />
				</p>
			</ModalBody>
			<ModalFooter>
				<Button type="submit" variant={Variant.primary}>
					<FormattedMessage id="user-summary.mark_as_read" />
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} type="button" onClick={() => setIsOpen(false)}>
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};

type UserSummaryBlockProps = {
	type: SummaryType;
	summary: Summary;
};

export const UserSummaryBlock: FC<UserSummaryBlockProps> = ({ type, summary }) => {
	const [modalOpened, setModalOpened] = useState(false);

	const maxUsers = 3;

	let iconId = '';

	switch (type) {
		case 'files':
			iconId = 'file-alt';
			break;
		case 'messages':
			iconId = 'envelope';
			break;
		case 'secure_spaces':
			iconId = 'shield';
			break;
		case 'esign_received':
			iconId = 'signature';
			break;
		case 'esign_completed':
			iconId = 'check-square';
			break;
		case 'payment_request_created':
			iconId = 'dollar-sign';
			break;
		case 'payment_request_received':
			iconId = 'dollar-sign';
			break;
		case 'file_request_created':
			iconId = 'file';
			break;
		case 'file_request_completed':
			iconId = 'file';
			break;
	}

	return (
		<>
			{modalOpened && <ModalAlerts onAfterClose={() => setModalOpened(false)} summary={summary} group={type} />}

			<StatCard
				title={<FormattedMessage id={`user-summary.${type}_title`} values={{ count: summary.count.internal + summary.count.external }} />}
				stat={summary.count.internal + summary.count.external}
				icon={iconId as IconProp}
				onClick={() => setModalOpened(true)}>
				<div className="inline-flex items-center -space-x-1">
					{summary.users.slice(0, maxUsers).map(user => (
						<UserAvatar user={user} size={Size.xs} key={user.ID} />
					))}
					{summary.users.length > maxUsers && (
						<Badge intent={Intent.tertiary} className="h-5 font-bold leading-5 tracking-tighter bg-gray-400 ring-white ring-2">
							+{summary.users.length - maxUsers}
						</Badge>
					)}
				</div>

				<div className="flex-1 ml-1">
					<p className="text-xs leading-snug text-gray-600 transition-colors duration-300 ease-in-out group-hover:text-black">
						{summary.users
							.slice(0, maxUsers)
							.map(a => a.Name)
							.join(', ')}
						{summary.users.length > maxUsers && (
							<span>
								<FormattedMessage id="user-summary.total_users" values={{ count: summary.users.length - maxUsers }} />
							</span>
						)}
					</p>
				</div>
			</StatCard>
		</>
	);
};

type ModalAlertsProps = Omit<ModalProps, 'isOpen'> & {
	group: string;
	summary: Summary;
};

const ModalAlerts: FC<ModalAlertsProps> = ({ group, summary, ...modalProps }) => {
	const [tabIndex, setTabIndex] = useState(summary.count.external === 0 ? 1 : 0);
	const [isOpen, setIsOpen] = useState(true);
	const { client } = useClient();

	const { mutateAsync: markNotificationAsRead } = useMarkNotificationAsReadMutation();
	const [includeRead, setIncludeRead] = useLocalStorage('summary.include_read', true);
	const { ref: loadMoreRef, inView: loadMoreInView } = useInView();

	const {
		isLoading,
		data: notifications,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage
	} = useInfiniteQuery(
		['notifications', group, tabIndex, includeRead],
		async ({ pageParam = 1 }) =>
			createLengthAwarePagination<NotificationType>(
				await client
					.url('account/notifications')
					.query(
						qs.stringify(
							{
								start_date: subMonths(new Date(), 6).toISOString(),
								scope: tabIndex === 0 ? 'external' : 'internal',
								page: pageParam,
								unread: includeRead ? '' : 1,
								actions: summary.types
							},
							{ arrayFormat: 'brackets' }
						)
					)
					.get()
					.json()
			),
		{
			getNextPageParam: paginator => (paginator.nextPageUrl ? paginator.currentPage + 1 : undefined)
		}
	);

	const read = () => {
		markNotificationAsRead({ types: summary.types, scope: tabIndex === 0 ? 'external' : 'internal' });
		setIsOpen(false);
	};

	useLocationChange(() => {
		setIsOpen(false);
	});

	const isEmpty = notifications?.pages[0].data.length === 0;

	// Load the next page when is available and in view
	useEffect(() => {
		if (loadMoreInView && !isLoading && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [fetchNextPage, loadMoreInView, isLoading, hasNextPage, isFetchingNextPage]);

	const internalCount = summary.count.internal;
	const externalCount = summary.count.external;

	return (
		<Modal isOpen={isOpen} {...modalProps}>
			<ModalHeader>
				<div className="flex items-center justify-between flex-1">
					<h3 className="text-lg font-medium text-gray-900">
						<FormattedMessage id={`user-summary.${group}_title`} values={{ count: summary.count.internal + summary.count.external }} />
					</h3>
					<div className="inline-flex items-center gap-3">
						<Label className="text-xs !mb-0 inline-flex gap-2 items-center">
							<span>
								<FormattedMessage id="user-summary.include_read" />
							</span>
							<Toggle size={Size.sm} checked={includeRead} onChange={e => setIncludeRead(e.target.checked)} />
						</Label>
						<Link to={{ pathname: '/alerts', search: `actions=${summary.types.join(',')}` }} className="ml-3 text-xs font-normal underline text-theme-primary">
							<FormattedMessage id="view_all" />
							<FontAwesomeIcon icon="external-link" className="ml-2" />
						</Link>
					</div>
				</div>
			</ModalHeader>
			<Tab.Group onChange={setTabIndex}>
				<Tab.List className="mt-4">
					<Tab disabled={externalCount === 0 && !includeRead}>
						<FormattedMessage id="user-summary.external" />
						<Badge variant={Variant.danger} className="ml-2 text-sm">
							{externalCount}
						</Badge>
					</Tab>

					<Tab disabled={internalCount === 0 && !includeRead}>
						<FormattedMessage id="user-summary.internal" />
						<Badge variant={Variant.danger} className="ml-2 text-sm">
							{internalCount}
						</Badge>
					</Tab>
				</Tab.List>
			</Tab.Group>
			<ModalBody className="flex flex-col overflow-auto !p-0">
				<Scrollbars autoHide autoHeight autoHeightMax="50vh" className="flex-1">
					{notifications?.pages.map((group, i) => (
						<Fragment key={i}>
							{group.data.map(notification => (
								<NotificationItem key={notification.Id} notification={notification} source="summary" />
							))}
						</Fragment>
					))}

					{/* Empty state */}
					{isEmpty && (
						<div className="py-12 text-center text-gray-400">
							<FontAwesomeIcon icon="bells" className="mb-4 opacity-50" size="3x" />
							<h6 className="">
								<FormattedMessage id="alerts.no_alerts" />
							</h6>
						</div>
					)}

					<div ref={loadMoreRef} className="flex items-center justify-center">
						{isLoading || isFetchingNextPage ? (
							<FontAwesomeIcon icon="spinner-third" spin size="3x" className="my-8 text-gray-500" />
						) : (
							hasNextPage && (
								<Button variant={Variant.light} className="my-8 shadow" size={Size.xs} onClick={() => fetchNextPage()}>
									<FormattedMessage id="file-manager.load_more" />
								</Button>
							)
						)}
					</div>
				</Scrollbars>
			</ModalBody>
			<ModalFooter>
				<Button type="button" variant={Variant.primary} intent={Intent.secondary} onClick={read}>
					<FormattedMessage id="user-summary.mark_as_read" />
				</Button>
				<Button type="button" variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)}>
					<FormattedMessage id="close" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default UserSummary;
