import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Variant } from '@convoflo/ui';
import useClient from '@hooks/useClient';
import { type FC, type FormEvent } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';

type DialogCancelSubscriptionProps = Omit<ModalProps, 'isOpen'>;

export const DialogCancelSubscription: FC<DialogCancelSubscriptionProps> = ({ ...modalProps }) => {
	const { client } = useClient();

	const { mutate: _delete, isLoading: isDeleting } = useMutation<void>(async () => await client.url('account/subscription').delete().res(), {
		onSuccess: () => {
			toast.success(<FormattedMessage id="cancel-sub.subscription_cancelled" />);
			setTimeout(() => window.location.reload(), 5000);
		},
		onError: console.error
	});

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		_delete();
	};

	// const hasCancellableSubscription = account!.business.trial_ends_at === null && account!.business?.subscription?.Provider === 'stripe';
	const hasCancellableSubscription = false;

	return (
		<Modal isOpen={true} {...modalProps} closeable={true} onSubmit={onSubmit}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="account.cancelling_subscription" />
			</ModalHeaderOnlyTitle>
			<ModalBody className="prose max-w-none">
				<p>
					<FormattedMessage id="cancel-sub.description_1" />
				</p>
				<p>
					<FormattedMessage id="cancel-sub.description_2" />
				</p>
				{!hasCancellableSubscription && (
					<Alert variant={Variant.info} className="mt-12">
						<FormattedMessage id="cancel-sub.cancel_external" />
					</Alert>
				)}
			</ModalBody>
			{hasCancellableSubscription && (
				<ModalFooter>
					<Button variant={Variant.danger} type="submit" loading={isDeleting} disabled={isDeleting}>
						<FormattedMessage id="cancel-sub.cancel_sub" />
					</Button>
				</ModalFooter>
			)}
		</Modal>
	);
};
