import { DialogRootComposer } from '@components/DialogRootComposer';
import { DialogCreateFolder, DialogShareSecureSpace, FileList } from '@components/FileManager';
import { GuidedTour } from '@components/GuidedTour';
import { MfaBanner } from '@components/InAppMarketing';
import AppContext from '@contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useView } from '@hooks/use-view';
import { useAccount } from '@hooks/useAccount';
import Folder from '@models/Folder';
import { Button, Variant } from '@convoflo/ui';
import { type FC, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export const RootViewPage: FC = () => {
	const { account } = useAccount();
	const { setEnableFullScreenDragNDrop } = useView();
	const { setPageHeader } = useContext(AppContext);

	const [dialogRootComposerOpened, setDialogRootComposerOpened] = useState(false);
	const [dialogCreateSecureSpaceOpened, setDialogCreateSecureSpaceOpened] = useState(false);
	const [lastSecureSpaceCreated, setLastSecureSpaceCreated] = useState<Folder>();

	useEffect(() => {
		setEnableFullScreenDragNDrop(!!account?.hasFullAccess() && !dialogRootComposerOpened);
	}, [account, dialogRootComposerOpened, setEnableFullScreenDragNDrop]);

	useEffect(() => {
		setPageHeader(undefined);
	}, [setPageHeader]);

	return (
		<>
			<div className="flex flex-col px-6 pt-6 pb-2 bg-white border-b border-gray-200">
				<MfaBanner />
				{account!.hasFullAccess() && (
					<div className="flex items-center mb-8 space-x-4">
						<Button variant={Variant.primary} shadow onClick={() => setDialogRootComposerOpened(true)} id="step-send-button">
							<FontAwesomeIcon icon={['fad', 'edit']} className="mr-3" />
							<FormattedMessage id="file-manager.compose" />
						</Button>
						<Button variant={Variant.success} shadow onClick={() => setDialogCreateSecureSpaceOpened(true)} id="step-es-button">
							<FontAwesomeIcon icon="shield" className="mr-3" />
							<FormattedMessage id="file-manager.create_secure_space" />
						</Button>
					</div>
				)}
			</div>

			<div className="py-8 mx-auto sm:px-6 max-w-7xl">
				<FileList view={null} />
			</div>

			{dialogRootComposerOpened && <DialogRootComposer onAfterClose={() => setDialogRootComposerOpened(false)} />}
			{dialogCreateSecureSpaceOpened && (
				<DialogCreateFolder
					onCreated={folders => {
						if (folders.length === 1) {
							setLastSecureSpaceCreated(folders[0]);
						}
					}}
					onAfterClose={() => setDialogCreateSecureSpaceOpened(false)}
					parents={null}
					secureSpace
				/>
			)}
			{lastSecureSpaceCreated && <DialogShareSecureSpace onAfterClose={() => setLastSecureSpaceCreated(undefined)} folder={lastSecureSpaceCreated} />}

			<GuidedTour name="generalOverview" enabled={account?.hasFullAccess()} />
		</>
	);
};
