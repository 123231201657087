import { type FC, forwardRef, useCallback } from 'react';
import { FixedSizeList, type FixedSizeListProps, type ReactElementType } from 'react-window';
import { Scrollbars } from 'react-custom-scrollbars-2';
import AutoSizer from 'react-virtualized-auto-sizer';

const CustomScrollbars: ReactElementType = ({ onScroll, forwardedRef, style, children }) => {
	const refSetter = useCallback(
		scrollbarsRef => {
			if (scrollbarsRef) {
				forwardedRef(scrollbarsRef.view);
			} else {
				forwardedRef(null);
			}
		},
		[forwardedRef]
	);

	return (
		<Scrollbars ref={refSetter} style={{ ...style, overflow: 'hidden' }} onScroll={onScroll}>
			{children}
		</Scrollbars>
	);
};

const CustomScrollbarsVirtualList = forwardRef((props, ref) => <CustomScrollbars {...props} forwardedRef={ref} />);

const List: FC<Omit<FixedSizeListProps, 'height' | 'width'>> = props => (
	<AutoSizer>{({ height, width }) => <FixedSizeList {...props} height={height} width={width} outerElementType={CustomScrollbarsVirtualList} />}</AutoSizer>
);

export default List;
