import type { OrganizationUser } from '@components/Team';
import { type ModelType, Module, type Plan, type SecureSpaceTermsType, type Subscription } from '@types';
import { differenceInDays } from 'date-fns';

class Organization implements ModelType {
	'@type': 'Business';
	ID!: number;
	Modules!: number;
	LogoDark!: string | null;
	LogoLight!: string | null;
	Name!: string | null;
	TempLoginTimeout!: number | null;
	subscription!: Subscription | null;
	trial_ends_at!: string | null;
	plan!: Plan;
	ReminderIntervals!: number[];
	PartnerId!: string | null;
	HasPaymentsConnected!: boolean;
	HasInvoices!: boolean;
	Defaults!: Record<string, Record<string, any>>;
	users!: OrganizationUser[];
	Profile!: {
		EmployeeCount: string | null;
		Type: string | null;
	};
	// Settings: {
	// 	TabsOrder: string[] | null;
	// };
	AcssDebitPaymentLimit?: number | null;
	SecureSpaceTerms?: SecureSpaceTermsType | null;

	constructor(attributes: Record<string, any> = {}) {
		Object.assign(this, attributes);
	}

	id() {
		return `org_${this.ID}`;
	}

	hasModule(module: Module) {
		return (this.Modules & module) === module;
	}

	getTrialDaysLeft() {
		if (this.trial_ends_at === null) {
			return 0;
		}

		return differenceInDays(new Date(this.trial_ends_at), new Date());
	}

	getGraceDaysLeft() {
		if (!this.subscription) {
			return 0;
		}

		if (!this.subscription.ends_at) {
			return 0;
		}

		return differenceInDays(new Date(this.subscription.ends_at), new Date());
	}

	hasUnpaidSubscription() {
		if (this.trial_ends_at || this.subscription === undefined) {
			return false;
		}

		if (this.subscription === null) {
			return true;
		}

		return this.subscription.Status === 'unpaid' || this.subscription.Status === 'past_due';
	}
}

export default Organization;
