import useClient from '@hooks/useClient';
import Account from '@models/Account';
import { useMutation, useQueryClient } from 'react-query';
import type { WretchError } from 'wretch';

type ReadNotificationApiRequest = {
	notifications?: string[];
	uuid?: string[];
	items?: string[];
	types?: string[];
	scope?: string;
	read?: boolean;
	read_from?: string;
};

export type MarkNotificationAsReadMutation = void | {
	notificationIds?: string[];
	notificationItemIds?: string[];
	uuids?: string[];
	types?: string[];
	scope?: string;
	read?: boolean;
	source?: string;
};

export const useMarkNotificationAsReadMutation = () => {
	const { authToken } = useClient();
	const queryClient = useQueryClient();
	const { client } = useClient();

	return useMutation<Account, WretchError, MarkNotificationAsReadMutation>({
		mutationFn: async ({ scope, read, notificationIds = [], notificationItemIds = [], uuids = [], types = [], source } = {}) => {
			const data: ReadNotificationApiRequest = {};

			if (notificationIds.length > 0) {
				data.notifications = notificationIds;
			} else if (notificationItemIds.length > 0) {
				data.items = notificationItemIds;
			} else if (uuids.length > 0) {
				data.uuid = uuids;
			} else if (types.length > 0) {
				data.types = types;
			}

			if (source) {
				data.read_from = source;
			}

			if (scope) {
				data.scope = scope;
			}
			data.read = read; //it's fine if it's null / undefined

			return new Account(await client.url('account/notifications').json(data).put().json<Account>());
		},
		onMutate: ({ scope, read, notificationIds = [], notificationItemIds = [], uuids = [], types = [], source } = {}) => {
			// find all notifications that matches these
		},
		onSuccess: (account, { types = [] } = {}) => {
			queryClient.setQueryData<Account>(['account', authToken], account);

			// When the user marked as read a group or types, reset the summary
			if (types.length > 0) {
				queryClient.invalidateQueries(['summary']);
			}
		}
	});
};
