import type { FC } from 'react';
import { Redirect } from 'react-router-dom';
import useUrlSearch from '@hooks/useUrlSearch';
import { parseFileableUrl } from '@utils/StringUtils';
import { PrintTimelinePage } from './print/timeline';

export const PrintPage: FC = () => {
	const parsedPage = parseFileableUrl(useUrlSearch().page);

	if (parsedPage === null) {
		return <Redirect to="/" />;
	}

	if (parsedPage.rest === 'timeline') {
		return <PrintTimelinePage />;
	}

	return <Redirect to="/" />;
};
