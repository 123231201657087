import type { ComposerPluginReturnValue, OnErrorEvent, OnPostedEvent, OnPostingEvent, OnResetEvent } from '@components/Message';
import { createElement, Fragment } from 'react';

const noop = () => {
	return;
};

export const useComposerPlugins = (plugins: ComposerPluginReturnValue[]) => {
	// Components
	const beforeComponents = plugins.map((plugin, index) => createElement(Fragment, { key: `composer-components-before-${index}` }, [plugin.components?.before ?? null]));
	const buttonComponents = plugins.map((plugin, index) => createElement(Fragment, { key: `composer-components-button-${index}` }, [plugin.components?.button ?? null]));
	const afterComponents = plugins.map((plugin, index) => createElement(Fragment, { key: `composer-components-after-${index}` }, [plugin.components?.after ?? null]));

	// States
	const isDisabled = plugins.some(plugin => plugin.isDisabled ?? false);
	const isDirty = plugins.some(plugin => plugin.isDirty ?? false);
	const isPostable = plugins.every(plugin => plugin.isPostable ?? true);

	// Data
	const getData = () => plugins.reduce((data, plugin) => ({ ...data, [plugin.id]: plugin.data && plugin.isDirty ? plugin.data : {} }), {});
	const postData = () => plugins.reduce((_data, plugin) => ({ ..._data, ...(plugin.postData?.(getData()) ?? {}) }), {});

	// Handlers
	const onBeforePosting = async (event: OnPostingEvent) => await Promise.all(plugins.map(plugin => plugin.onBeforePosting?.(event) ?? noop()));
	const onError = (event: OnErrorEvent) => Promise.all(plugins.map(async plugin => await plugin.onError?.(event)));
	const onSuccess = (event: OnPostedEvent) => Promise.all(plugins.map(async plugin => await plugin.onSuccess?.(event)));
	const reset = (event: OnResetEvent) => Promise.all(plugins.map(async plugin => await plugin.onReset?.(event)));

	return {
		components: {
			before: beforeComponents,
			buttons: buttonComponents,
			after: afterComponents
		},
		isDirty,
		isDisabled,
		isPostable,
		data: getData(),
		postData,
		onBeforePosting,
		onError,
		onSuccess,
		reset
	};
};
