import { Button, Intent, Size, Variant } from '@convoflo/ui';
import mfaEn from '@assets/images/mfa-framed-en.png';
import mfaFr from '@assets/images/mfa-framed-fr.png';
import { useAccount } from '@hooks/useAccount';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

export const MfaBanner = () => {
	const { account } = useAccount();
	const { locale } = useIntl();
	const { push } = useHistory();

	const [showMfaTip, setShowMfaTip] = useLocalStorage('mfa.tip', true);

	if (!showMfaTip || !account || account?.TwoFactorType) {
		return null;
	}

	return (
		<div className="relative p-4 mb-12 overflow-hidden border-2 border-green-600 shadow-md rounded-xl bg-gradient-to-tl from-green-300/25 to-white">
			<div className="mr-36">
				<h2 className="mb-1 text-lg font-semibold leading-tight text-green-900 md:text-xl">
					<FormattedMessage id="mfa-reminder.secure_account" />
				</h2>
				<p className="text-xs md:text-sm text-green-900/75">
					<FormattedMessage id="mfa-reminder.secure_account_desc" />
				</p>
				<div className="flex items-center mt-3 space-x-2">
					<Button variant={Variant.success} size={Size.sm} intent={Intent.primary} onClick={() => push('/account/multi-factor-auth')}>
						<FormattedMessage id="mfa-reminder.action" />
					</Button>
					<Button variant={Variant.success} size={Size.sm} intent={Intent.tertiary} onClick={() => setShowMfaTip(false)}>
						<FormattedMessage id="mfa-reminder.not_now" />
					</Button>
				</div>
			</div>
			<img src={locale === 'fr' ? mfaFr : mfaEn} className="absolute w-32 right-4 top-1" alt="" />
		</div>
	);
};
