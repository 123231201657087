import type { EditableLabelType } from '@components/Labels';

export const addLabel = (label: EditableLabelType, labels: EditableLabelType[]) => {
	// Add to root
	if (!label.property) {
		return [...labels, label];
	}

	// Add as an option
	return labels.map(label => (label.property?.Id === label.Id ? { ...label, options: [...(label.options ?? []), label] } : label));
};

export const replaceLabel = (label: EditableLabelType, labels: EditableLabelType[]) => {
	return labels.map(_label => (label.Id === _label.Id ? label : { ..._label, options: _label.options?.map(option => (option.Id === label.Id ? label : option)) }));
};

export const removeLabel = (label: EditableLabelType, labels: EditableLabelType[]) => {
	return labels.filter(_label => label.Id !== _label.Id).map(_label => ({ ..._label, options: _label.options?.filter(option => option.Id !== label.Id) }));
};

export const getLabelText = (label: EditableLabelType | undefined, locale: string) => {
	if (typeof label?.Label === 'string') {
		return label.Label;
	}
	return (locale.startsWith('fr') ? label?.Label?.fr_CA ?? label?.Label?.en_US : label?.Label?.en_US) ?? '';
};
