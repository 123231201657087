import { Logo } from '@components/Logo';
import AppContext from '@contexts/AppContext';
import { Alert, Button, InputBlock, Label, Row, Variant } from '@convoflo/ui';
import useClient from '@hooks/useClient';
import useUrlSearch from '@hooks/useUrlSearch';
import Card from '@ui/Card';
import { type FormEvent, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import type { WretchError, WretchResponse } from 'wretch';

const ForgotPassword = () => {
	const { email: initialEmail = '' } = useUrlSearch();
	const { client } = useClient();
	const { setPageTitle: setTitle } = useContext(AppContext);
	const { formatMessage } = useIntl();

	const [error, setError] = useState<string>();
	const [email, setEmail] = useState(initialEmail);

	const {
		mutate: remind,
		isSuccess,
		isLoading
	} = useMutation<WretchResponse, WretchError, string>(async email => await client.url('login/forgot').post({ email }).res(), {
		onMutate: () => {
			setError(undefined);
		},
		onError: error => {
			setError(error.message);
		}
	});

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		remind(email);
	};

	useEffect(() => {
		setTitle(formatMessage({ id: 'login.title.forgot_password' }));
	}, [setTitle, formatMessage]);

	return (
		<div className="flex flex-col min-h-screen bg-gray-200">
			<div className="container flex flex-col items-center justify-center flex-1 max-w-sm px-2 py-8 mx-auto">
				<div className="max-w-full mb-8">
					<Logo light className="h-12" />
				</div>
				<Card>
					<div className="mb-8 prose">
						<p>
							<FormattedMessage id="login.forgot_intro" />
						</p>
					</div>

					{!!error && (
						<Alert variant={Variant.danger}>
							<p>{error}</p>
						</Alert>
					)}

					{isSuccess && (
						<Alert variant={Variant.success}>
							<p>
								<FormattedMessage id="login.password_reset_sent" />
							</p>
						</Alert>
					)}

					<form onSubmit={onSubmit}>
						<fieldset disabled={isLoading}>
							<Row>
								<Label htmlFor="email">
									<FormattedMessage id="login.email" />
								</Label>
								<InputBlock autoFocus id="email" type="email" value={email} onChange={e => setEmail(e.target.value)} icon="envelope" />
							</Row>
							<Row className="flex items-center justify-between">
								<Button type="submit" variant={Variant.primary} loading={isLoading} block disabled={isLoading || isSuccess}>
									<FormattedMessage id="login.continue" />
								</Button>
							</Row>
						</fieldset>
					</form>
					<p className="mt-4 text-center">
						<Link to={`/login?email=${email}`} className="text-sm text-theme-primary hover:underline">
							<FormattedMessage id="login.return_login" />
						</Link>
					</p>
				</Card>
				<p className="mt-4 text-xs text-gray-500">
					<FormattedMessage id="copyright.short" values={{ year: new Date().getFullYear() }} />
				</p>
			</div>
		</div>
	);
};

export default ForgotPassword;
