import untypedColors from '@assets/color-palette.json';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ColorType } from '@types';
import classNames from 'classnames';
import { type FC, type PropsWithChildren } from 'react';

const colors: ColorType[] = untypedColors;

type FileLabelProps = PropsWithChildren<{
	color?: string;
	icon?: IconProp;
}>;

export const FileLabel: FC<FileLabelProps> = ({ children, color, icon }) => {
	const _color = colors.find(c => color === c.value) ?? colors[0];

	return (
		<span className={classNames('flex items-center px-2 space-x-1 text-xs border rounded-full leading-0', _color.backgroundClassName, _color.borderClassName, _color.textClassName)}>
			{icon !== undefined && <FontAwesomeIcon icon={icon} />}
			<span>{children}</span>
		</span>
	);
};
