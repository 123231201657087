import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Fileable, ItemSyncType } from '@types';
import { Tooltip } from '@ui/Tooltip';
import { formatDate } from '@utils/DateUtils';
import classNames from 'classnames';
import type { FC } from 'react';
import { useIntl } from 'react-intl';

type FileableSyncStatusProps = {
	fileable: Fileable;
	sync: ItemSyncType;
};

export const FileableSyncStatus: FC<FileableSyncStatusProps> = ({ sync }) => {
	const { locale, formatMessage } = useIntl();

	if (!sync.Connection) {
		return null;
	}

	return (
		<Tooltip
			tip={formatMessage(
				{ id: 'sync.status' },
				{
					status: sync.Status,
					connection: sync.Connection.Name,
					syncedAt: formatDate(sync.last_synced_at, locale),
					error: sync.ErrorCode ?? ''
				}
			)}>
			<span
				className={classNames('text-white inline-grid place-items-center rounded-full size-3.5 ring-2 ring-white', {
					'bg-green-500': sync.Status === 'synced',
					'bg-yellow-500': sync.Status === 'pending',
					'bg-red-500': sync.Status === 'error',
					'bg-blue-500': sync.Status === 'syncing'
				})}>
				{sync.Status === 'synced' && <FontAwesomeIcon icon="check" className="size-2" />}
				{sync.Status === 'pending' && <FontAwesomeIcon icon="hourglass-end" className="size-2" />}
				{sync.Status === 'error' && <FontAwesomeIcon icon="exclamation" className="size-2" />}
				{sync.Status === 'syncing' && <FontAwesomeIcon icon="sync" className="size-2" spin />}
			</span>
		</Tooltip>
	);
};
