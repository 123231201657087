import { UserSummaryBlock, UserSummaryMarkAsReadModal } from '@components/UserSummary';
import { useAccount } from '@hooks/useAccount';
import { useUserSummary } from '@hooks/useUserSummary';
import File from '@models/File';
import Folder from '@models/Folder';
import { FileViewPage, FolderViewPage, RootViewPage, SearchViewPage } from '@pages/view';
import { ViewProvider } from '@providers/ViewProvider';
import type { SummaryType } from '@types';
import { type FC, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FormattedMessage } from 'react-intl';

export type FileManagerRouteParams = {
	folderParam1?: string;
	folderParam2?: string;
	fileParam1?: string;
	fileParam2?: string;
	tab?: string;
};

export const ViewPage = () => {
	const { account } = useAccount();

	return (
		<div className="flex h-full">
			<div className="flex-1">
				<Scrollbars autoHide>
					<ViewProvider>
						{view => (
							<>
								{view instanceof Folder && account && <FolderViewPage folder={view} />}
								{view instanceof File && <FileViewPage file={view} />}
								{typeof view === 'string' && account && <SearchViewPage query={view} />}
								{view === null && account && <RootViewPage />}
							</>
						)}
					</ViewProvider>
				</Scrollbars>
			</div>
			{account !== null && <SidebarUserSummary />}
		</div>
	);
};

const SidebarUserSummary: FC = () => {
	const { summary, status } = useUserSummary();

	const [markAllAsReadModalOpened, setMarkAllAsReadModalOpened] = useState(false);

	if (status !== 'success') {
		return null;
	}

	if (summary === undefined || summary.total_count === 0) {
		return null;
	}

	return (
		<div className="hidden xl:block w-72">
			<div className="flex flex-col h-full">
				<Scrollbars autoHide>
					<div className="flex-1 p-4">
						<div className="grid grid-cols-1 gap-4">
							{Object.entries(summary.summary)
								.filter(([_, summary]) => summary.count.external + summary.count.internal > 0)
								.map(([type, summary]) => (
									<UserSummaryBlock key={type} type={type as SummaryType} summary={summary} />
								))}
						</div>
						<p className="mt-2 text-xs italic font-light text-gray-400">
							<FormattedMessage id="user-summary.limit" />
						</p>
					</div>
				</Scrollbars>
			</div>
			{markAllAsReadModalOpened && <UserSummaryMarkAsReadModal onAfterClose={() => setMarkAllAsReadModalOpened(false)} total={summary.total_count} />}
		</div>
	);
};

export * from './file';
export * from './folder';
export * from './root';
export * from './search';
