import { useCurrentEditor } from '@tiptap/react';
import { ToolbarButton, type ToolbarButtonProps } from '@ui/RichTextEditor/Toolbar';

export const OrderedListToolbarButton = ({ ...props }: ToolbarButtonProps) => {
	const { editor } = useCurrentEditor();

	if (editor === null) {
		return null;
	}

	return <ToolbarButton type={['orderedList']} handler={() => editor.chain().focus().toggleOrderedList().run()} {...props} />;
};
