import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Intent, Modal, ModalBody, ModalFooter, type ModalProps, ModalSize, Variant } from '@convoflo/ui';
import useClient from '@hooks/useClient';
import File from '@models/File';
import Folder from '@models/Folder';
import { type FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { downloadResponse } from '@service/Client';
import type { Fileable } from '@types';
import { formatDate } from '@utils/DateUtils';
import { filesize } from '@utils/StringUtils';
import type { WretchError, WretchResponse } from 'wretch';
import { maxZipFileSize } from '../../constants';

type DownloadFileablesMutation = {
	items: Fileable[];
};

type DialogDownloadFileablesProps = Omit<ModalProps, 'isOpen'> & {
	items: Fileable[];
	onDownloadStart?: () => void;
	totalSize?: number;
};

export const DialogDownloadFileables: FC<DialogDownloadFileablesProps> = ({ items, onDownloadStart = () => undefined, totalSize, ...props }) => {
	const { client } = useClient();
	const { locale } = useIntl();

	const [isOpen, setIsOpen] = useState(true);
	const [errorCode, setErrorCode] = useState<string>();

	const {
		mutate: download,
		isLoading,
		isSuccess
	} = useMutation<WretchResponse, WretchError, DownloadFileablesMutation>(
		async ({ items }) =>
			await client
				.url('files/download')
				.json({
					files: items.filter(f => f instanceof File || f['@type'] === 'Document').map(f => f.OriginalLink),
					folders: items.filter(f => f instanceof Folder || f['@type'] === 'Folder').map(f => f.OriginalLink)
				})
				.post()
				.res(),
		{
			onMutate: () => {
				setErrorCode(undefined);
			},
			onError: error => {
				if (error.json.error.code === 'max_file_size_download_reached' && error.json.error.size > maxZipFileSize) {
					setErrorCode('max_file_size_download_reached');
				} else if (['max_file_size_download_reached', 'max_execution_time_download_reached'].includes(error.json.error.code)) {
					setErrorCode('background_download_started');
				} else {
					setErrorCode('generic');
				}
			},
			onSuccess: response => {
				downloadResponse(`convoflo-files-${formatDate(null, null, 'yMMddHHmmss')}.zip`, 'application/x-zip')(response);
				onDownloadStart();
				setTimeout(() => {
					setIsOpen(false);
				}, 7000);
			}
		}
	);

	// Trigger download as soon as this dialog opens
	useEffect(() => {
		setErrorCode(undefined);
		if (totalSize !== undefined && totalSize > maxZipFileSize) {
			setErrorCode('max_file_size_download_reached');
		} else if (items.length > 0) {
			download({ items });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totalSize, download]);

	return (
		<Modal isOpen={isOpen} {...props} size={ModalSize.XSmall}>
			<ModalBody>
				<div className="flex">
					{isLoading && (
						<>
							<FontAwesomeIcon icon="spinner" size="2x" className="mr-4 text-gray-800" pulse />
							<p>
								<FormattedMessage id="dialog-download.waiting" />
							</p>
						</>
					)}
					{isSuccess && (
						<>
							<FontAwesomeIcon icon="check" mask="circle" transform="shrink-7" size="2x" className="mr-4 text-green-600" />
							<p>
								<FormattedMessage id="dialog-download.started" />
							</p>
						</>
					)}
					{errorCode === 'max_file_size_download_reached' && (
						<>
							<FontAwesomeIcon icon="exclamation-circle" mask="circle" transform="shrink-7" size="2x" className="mr-4 text-red-600" />
							<p>
								<FormattedMessage id="dialog-download.too_large" values={{ total: filesize(maxZipFileSize, locale) }} />
							</p>
						</>
					)}
					{errorCode === 'background_download_started' && (
						<>
							<FontAwesomeIcon icon="hourglass-end" mask="circle" transform="shrink-7" size="2x" className="mr-4 text-yellow-400" />
							<p>
								<FormattedMessage id="dialog-download.too_long" />
							</p>
						</>
					)}
					{errorCode === 'generic' && (
						<>
							<FontAwesomeIcon icon="exclamation-circle" mask="circle" transform="shrink-7" size="2x" className="mr-4 text-red-600" />
							<p>
								<FormattedMessage id="dialog-download.error" />
							</p>
						</>
					)}
				</div>
			</ModalBody>
			{!isLoading && (
				<ModalFooter>
					<Button variant={Variant.light} intent={Intent.secondary} type="button" onClick={() => setIsOpen(false)}>
						<FormattedMessage id="close" />
					</Button>
				</ModalFooter>
			)}
		</Modal>
	);
};
