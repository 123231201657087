import classNames from 'classnames';
import { type FC, type InputHTMLAttributes, useId } from 'react';
import { FormattedMessage } from 'react-intl';

export type MessageTitleProps = InputHTMLAttributes<HTMLInputElement> & {};

export const MessageTitle: FC<MessageTitleProps> = ({ className, ...inputProps }) => {
	const id = useId();

	return (
		<div className={classNames(className)}>
			<label htmlFor={id} className="block px-3 text-xs font-light leading-none text-gray-300 select-none">
				<FormattedMessage id="comments.subject" />
			</label>
			<input id={id} type="text" className="w-full pt-1 text-sm font-light border-0 outline-none disabled:bg-gray-100 placeholder:text-gray-400/75 focus:ring-0" {...inputProps} />
		</div>
	);
};
