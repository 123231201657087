import { type ImportRow, useImporter } from '@components/Importer';
import { useWizard } from '@components/Wizard';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@ui/Card';
import { type ButtonHTMLAttributes, type FC, type ReactNode, useEffect, useState } from 'react';
import { Importer, ImporterField } from 'react-csv-importer';
import { FormattedMessage, useIntl } from 'react-intl';
import '../../css/import.css';
import { frCA } from './assets/frCA';
import IntuitQuickBooksIcon from './assets/intuit-quickbooks-icon.svg?react';
import IntuitQuickBooksLogo from './assets/intuit-quickbooks-logo.svg?react';
import MicrosoftOutlookIcon from './assets/microsoft-outlook-icon.svg?react';
import SageLogo from './assets/sage-logo.svg?react';
import ThomsonReutersIcon from './assets/thomson-reuters-icon.svg?react';
import WoltersKlumerIcon from './assets/wolters-klumer-icon.svg?react';

export const DataStep = () => {
	const { formatMessage, locale } = useIntl();
	const { nextStep } = useWizard();
	const { setOriginalData, reset } = useImporter();

	const [method, setMethod] = useState<string>();
	const [fileUploaded, setFileUploaded] = useState(false);

	useEffect(() => {
		reset();
		setMethod(undefined);
		setFileUploaded(false);
	}, [reset]);

	useEffect(() => {
		if (method === undefined) {
			setFileUploaded(false);
		}
	}, [method]);

	return (
		<>
			<Card>
				{method === undefined ? (
					<>
						<h2 className="mb-6 text-xl font-medium text-center">
							<FormattedMessage id="importer.import.title_choose" />
						</h2>
						<div className="grid grid-cols-3 gap-6 my-12">
							<MethodButton method="outlook" onSelected={setMethod} icon={<MicrosoftOutlookIcon className="w-8" />}>
								<span className="text-[#0078D4]">Outlook</span>
							</MethodButton>
							<MethodButton method="quickbooks" onSelected={setMethod} icon={<IntuitQuickBooksLogo className="h-8" />} />
							<MethodButton method="dtmax" onSelected={setMethod} icon={<ThomsonReutersIcon className="h-8" />}>
								DT Max
							</MethodButton>
							<MethodButton method="taxprep" onSelected={setMethod} icon={<WoltersKlumerIcon className="h-8" />}>
								TaxPrep
							</MethodButton>
							<MethodButton method="sage" onSelected={setMethod} icon={<SageLogo className="h-8" />} />
							<MethodButton method="csv" onSelected={setMethod} icon={<FontAwesomeIcon icon="file-csv" className="text-green-600" size="2x" />}>
								CSV
							</MethodButton>
						</div>
					</>
				) : (
					<>
						<div className="mb-6">
							{method === 'outlook' && (
								<Instructions
									icon={<MicrosoftOutlookIcon className="w-12" />}
									title={<FormattedMessage id="importer.import.title" values={{ app: 'Microsoft Outlook' }} />}
									// description="Suivez les instructions attentivement afin de récupérer votre liste de contacts depuis Microsoft Outlook."
									instructions={[
										{
											title: <FormattedMessage id="importer.import.outlook.step-1" />,
											description: <FormattedMessage id="importer.import.outlook.step-1.desc" />,
											src: formatMessage({ id: 'importer.import.outlook.step-1.link' })
										},
										{ title: <FormattedMessage id="importer.import.outlook.step-2" /> }
									].concat(fileUploaded ? [{ title: <FormattedMessage id="importer.import.step-3" /> }] : [])}
								/>
							)}
							{method === 'quickbooks' && (
								<Instructions
									icon={<IntuitQuickBooksIcon className="w-12" />}
									title={<FormattedMessage id="importer.import.title" values={{ app: 'Intuit QuickBooks' }} />}
									// description="Suivez les instructions attentivement afin de récupérer votre liste de contacts depuis Intuit QuickBooks."
									instructions={[
										{
											title: <FormattedMessage id="importer.import.quickbooks.step-1" />,
											description: <FormattedMessage id="importer.import.quickbooks.step-1.desc" />,
											src: formatMessage({ id: 'importer.import.quickbooks.step-1.link' })
										},
										{
											title: <FormattedMessage id="importer.import.quickbooks.step-2" />,
											description: <FormattedMessage id="importer.import.quickbooks.step-2.desc" />,
											imageSrc: 'a'
										},
										{ title: <FormattedMessage id="importer.import.quickbooks.step-3" /> }
									].concat(fileUploaded ? [{ title: <FormattedMessage id="importer.import.step-3" /> }] : [])}
								/>
							)}
							{method === 'dtmax' && (
								<Instructions
									icon={<ThomsonReutersIcon className="w-12" />}
									title={<FormattedMessage id="importer.import.title" values={{ app: 'Thomson Reuters DT Max' }} />}
									description={<FormattedMessage id="importer.import.dtmax.subtitle" />}
									instructions={[
										{
											title: <FormattedMessage id="importer.import.dtmax.step-1" />,
											description: <FormattedMessage id="importer.import.dtmax.step-1.desc" values={{ strong: msg => <strong className="font-bold">{msg}</strong> }} />,
											src: formatMessage({ id: 'importer.import.dtmax.step-1.link' })
										},
										{ title: <FormattedMessage id="importer.import.dtmax.step-2" /> }
									].concat(fileUploaded ? [{ title: <FormattedMessage id="importer.import.step-3" /> }] : [])}
								/>
							)}
							{method === 'taxprep' && (
								<Instructions
									icon={<WoltersKlumerIcon className="w-12" />}
									title={<FormattedMessage id="importer.import.title" values={{ app: 'Wolters Kulmer TaxPrep' }} />}
									description={<FormattedMessage id="importer.import.taxprep.subtitle" />}
									instructions={[
										{
											title: <FormattedMessage id="importer.import.taxprep.step-1" />,
											description: <FormattedMessage id="importer.import.taxprep.step-1.desc" />,
											src: formatMessage({ id: 'importer.import.taxprep.step-1.link' })
										},
										{ title: <FormattedMessage id="importer.import.taxprep.step-2" /> }
									].concat(fileUploaded ? [{ title: <FormattedMessage id="importer.import.step-3" /> }] : [])}
								/>
							)}
							{method === 'sage' && (
								<Instructions
									icon={<SageLogo className="w-12" />}
									title={<FormattedMessage id="importer.import.title" values={{ app: 'Sage Accounting' }} />}
									description={<FormattedMessage id="importer.import.sage.subtitle" />}
									instructions={[
										{
											title: <FormattedMessage id="importer.import.sage.step-1" />,
											description: <FormattedMessage id="importer.import.sage.step-1.desc" />,
											src: formatMessage({ id: 'importer.import.sage.step-1.link' })
										},
										{ title: <FormattedMessage id="importer.import.sage.step-2" /> }
									].concat(fileUploaded ? [{ title: <FormattedMessage id="importer.import.step-3" /> }] : [])}
								/>
							)}
							{method === 'csv' && (
								<Instructions
									icon={<FontAwesomeIcon icon="file-csv" className="text-green-600" size="2x" />}
									instructions={[
										{
											title: <FormattedMessage id="importer.import.csv.step-1" />,
											description: <FormattedMessage id="importer.import.csv.step-1.desc" />
										},
										{ title: <FormattedMessage id="importer.import.csv.step-2" /> }
									].concat(fileUploaded ? [{ title: <FormattedMessage id="importer.import.step-3" /> }] : [])}
									title={<FormattedMessage id="importer.import.csv.title" />}
								/>
							)}
						</div>
						<div className="ml-11">
							<Importer<ImportRow>
								dataHandler={rows => {
									setOriginalData(originalData => originalData.concat(rows));
								}}
								onComplete={() => nextStep()}
								locale={locale === 'fr' ? frCA : undefined}
								skipEmptyLines="greedy"
								defaultNoHeader={false}
								restartable={false}>
								{({ file }) => {
									setFileUploaded(file !== null);
									return (
										<>
											<ImporterField name="grouping" label={formatMessage({ id: 'importer.import.column.id' })} optional />
											<ImporterField name="business" label={formatMessage({ id: 'importer.import.column.business' })} optional />
											<ImporterField name="name" label={formatMessage({ id: 'importer.import.column.name' })} optional />
											<ImporterField name="email" label={formatMessage({ id: 'importer.import.column.email' })} optional />
											<ImporterField name="first_name" label={formatMessage({ id: 'importer.import.column.first_name' })} optional />
											<ImporterField name="last_name" label={formatMessage({ id: 'importer.import.column.last_name' })} optional />
										</>
									);
								}}
							</Importer>
						</div>
						<footer className="flex items-center justify-between mt-12">
							<Button
								variant={Variant.primary}
								intent={Intent.tertiary}
								size={Size.sm}
								onClick={() => setMethod(undefined)}
								iconStart="arrow-left"
								animateIcon
								disabled={method === undefined}>
								<FormattedMessage id="importer.import.use_another_method" />
							</Button>
						</footer>
					</>
				)}
			</Card>
		</>
	);
};

type MethodButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	method: string;
	onSelected: (method: string) => void;
	icon?: ReactNode;
};

const MethodButton: FC<MethodButtonProps> = ({ method, icon, onSelected = () => undefined, children, ...props }) => {
	return (
		<button
			type="button"
			onClick={() => onSelected(method)}
			className="flex items-center justify-center w-full p-4 space-x-3 transition border border-gray-200 rounded-xl hover:shadow hover:shadow-slate-200 hover:border-gray-300"
			{...props}>
			{icon !== undefined ? icon : null}
			<span className="text-xl semi">{children}</span>
		</button>
	);
};

type InstructionsProps = {
	title: ReactNode;
	description?: ReactNode;
	icon: ReactNode;

	instructions?: {
		title: ReactNode;
		imageSrc?: string;
		description?: ReactNode;
		src?: string;
	}[];
};

const Instructions: FC<InstructionsProps> = ({ title, description, icon, instructions = [] }) => {
	return (
		<>
			<header className="flex items-center mb-12 space-x-4">
				<div className="flex-shrink-0">{icon}</div>
				<div>
					<h2 className="text-xl font-medium">{title}</h2>
					{description !== undefined && <p className="text-sm text-gray-500">{description}</p>}
				</div>
			</header>
			{instructions.length > 0 && (
				<div className="grid grid-cols-1 gap-8">
					{instructions.map((block, index) => (
						<div className="flex items-baseline space-x-3">
							<div className="flex items-center justify-center flex-shrink-0 w-8 h-8 font-bold text-white bg-gray-600 rounded-full">{index + 1}</div>
							<div>
								<h6 className="font-semibold text-gray-700 mb-1.5 text-xl">{block.title}</h6>
								{!!block.description && (
									<p className="text-sm font-light text-gray-600">
										{block.src ? (
											<a href={block.src} target="_blank" rel="noreferrer" className="text-theme-primary hover:underline">
												{block.description}
											</a>
										) : (
											block.description
										)}
									</p>
								)}
								{!!block.imageSrc && <div className="w-full h-56 mt-4 bg-gray-100 rounded-md" />}
							</div>
						</div>
					))}
				</div>
			)}
		</>
	);
};
