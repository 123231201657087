import { Logo } from '@components/Logo';
import AppContext from '@contexts/AppContext';
import { Alert, Button, HelperText, Input, Label, Row, ValidationField, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePasswordStrengthCheck } from '@hooks/use-password-strength-check';
import useClient from '@hooks/useClient';
import useUrlSearch from '@hooks/useUrlSearch';
import Card from '@ui/Card';
import ProgressBar from '@ui/ProgressBar';
import { type FormEvent, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import type { WretchResponse } from 'wretch';

type PasswordResetMutation = {
	email: string;
	password: string;
	token: string;
	passwordConfirmation: string;
};

export const ResetPasswordPage = () => {
	const { token } = useParams<{ token: string }>();
	const { client, validation } = useClient();
	const { email: initialEmail } = useUrlSearch();
	const { formatMessage, locale } = useIntl();
	const { setPageTitle: setTitle } = useContext(AppContext);
	const history = useHistory();

	const [email, setEmail] = useState(initialEmail);
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	const passwordStrength = usePasswordStrengthCheck(password);

	const {
		mutate: resetPassword,
		isLoading,
		isSuccess: sent
	} = useMutation<WretchResponse, WretchResponse, PasswordResetMutation>(
		async ({ email, password, token, passwordConfirmation }) =>
			await client.url('password/reset').query({ locale }).post({ email, password, token, password_confirmation: passwordConfirmation }).res(),
		{
			onSuccess: () => {
				setTimeout(() => {
					history.replace(`/login?email=${email}`);
				}, 5000);
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		resetPassword({ email, password, token, passwordConfirmation });
	};

	useEffect(() => {
		setTitle(formatMessage({ id: 'login.title.reset_password' }));
	}, [setTitle, formatMessage]);

	return (
		<div className="flex flex-col min-h-screen bg-gray-200">
			<div className="container flex flex-col items-center justify-center flex-1 max-w-sm px-2 py-8 mx-auto">
				<div className="max-w-full mb-8">
					<Logo light={true} className="h-12" />
				</div>
				<Card>
					<h2 className="mb-8 text-xl font-semibold text-center text-gray-700">
						<FormattedMessage id="login.reset_intro" />
					</h2>

					{sent && (
						<Alert variant={Variant.success} className="mb-6">
							<p>
								<FormattedMessage id="login.password_resetted" />
							</p>
						</Alert>
					)}

					<form onSubmit={onSubmit}>
						<fieldset disabled={isLoading}>
							<Row>
								<Label htmlFor="email">
									<FormattedMessage id="login.email" />
								</Label>
								<ValidationField fieldName="email" validation={validation}>
									<Input block autoFocus id="email" type="email" value={email} onChange={e => setEmail(e.target.value)} icon="envelope" />
								</ValidationField>
							</Row>
							<Row>
								<Label htmlFor="password">
									<FormattedMessage id="login.new_password" />
								</Label>
								<ValidationField fieldName="password" validation={validation}>
									<Input block className="rounded-b-none" autoFocus id="password" type="password" value={password} onChange={e => setPassword(e.target.value)} icon="key" />
								</ValidationField>
								<ProgressBar max={100} current={passwordStrength ?? 0} reverseColors className="h-3 rounded-t-none" />
								<HelperText>
									<FormattedMessage id="signup.password_helper" />
								</HelperText>
							</Row>
							<Row>
								<Label htmlFor="password_confirmation">
									<FormattedMessage id="login.new_password_confirmation" />
								</Label>
								<ValidationField fieldName="password_confirmation" validation={validation}>
									<Input block id="password_confirmation" type="password" value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)} icon="key" />
								</ValidationField>
							</Row>
							<Row className="flex items-center justify-between">
								<Button block type="submit" variant={Variant.primary} disabled={isLoading || sent}>
									{isLoading ? <FontAwesomeIcon icon="spinner" pulse /> : <FormattedMessage id="login.reset_password" />}
								</Button>
							</Row>
						</fieldset>
					</form>
				</Card>
				<p className="mt-4 text-xs text-gray-500">
					<FormattedMessage id="copyright.short" values={{ year: new Date().getFullYear() }} />
				</p>
			</div>
		</div>
	);
};
