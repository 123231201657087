import { Input, type InputProps } from '@convoflo/ui';
import classNames from 'classnames';
import { type FC, useEffect, useRef, useState } from 'react';

type MultiFactorInputProps = Omit<InputProps, 'onChange'> & {
	fullWidth?: boolean;
	length?: number;
	onChange?: (input: string) => void;
};

const MultiFactorInput: FC<MultiFactorInputProps> = ({ fullWidth = false, className = '', length = 6, onChange = () => undefined, autoFocus = true, ...props }) => {
	const [input, setInput] = useState('');
	const inputField = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (inputField.current && autoFocus) {
			inputField.current.focus();
			inputField.current.select();
		}
	}, [inputField, autoFocus]);

	useEffect(() => {
		onChange(input);
	}, [onChange, input]);

	return (
		<Input
			block={fullWidth}
			{...props}
			style={{ width: fullWidth ? '' : `${length * 3 - length / 2}rem`, letterSpacing: '1rem' }}
			ref={inputField}
			maxLength={length}
			type="tel"
			value={input}
			className={classNames('text-3xl font-mono', className, { 'w-full': fullWidth })}
			autoComplete="one-time-code"
			autoCorrect="false"
			spellCheck="false"
			inputMode="numeric"
			onChange={e => setInput(e.target.value)}
			onFocus={e => e.target.select()}
			required
		/>
	);
};

export default MultiFactorInput;
