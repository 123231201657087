import FullScreenLoading from '@components/FullScreenLoading';
import { Logo } from '@components/Logo';
import type { InviteResponse } from '@components/Team/types';
import AppContext from '@contexts/AppContext';
import { Button, Variant } from '@convoflo/ui';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import useUrlSearch from '@hooks/useUrlSearch';
import { useOrganizationJoinMutation } from '@state/queries/organization';
import ErrorLayout from '@ui/Layout/Error';
import { type FormEvent, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

export const JoinOrganizationPage = () => {
	const { inviteUrl } = useUrlSearch();
	const { formatMessage } = useIntl();
	const { account } = useAccount();
	const { client } = useClient();
	const history = useHistory();
	const { setPageTitle: setTitle } = useContext(AppContext);

	const {
		data: joinRequest,
		isLoading: isVerifying,
		isError
	} = useQuery(['join-organization-request', inviteUrl], async () => await client.url(inviteUrl, true).get().json<InviteResponse>(), {
		enabled: !!inviteUrl
	});

	const { mutateAsync: join, isLoading: isAccepting } = useOrganizationJoinMutation();

	useEffect(() => {
		if (!joinRequest || !account) {
			return;
		}

		if (joinRequest.Email === '' || account.Email.toLocaleLowerCase() !== joinRequest.Email.toLocaleLowerCase()) {
			history.replace('/');
		}
	}, [joinRequest, account, history]);

	useEffect(() => {
		setTitle(formatMessage({ id: 'signup.title_invite' }));
	}, [formatMessage, setTitle]);

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		try {
			await join(joinRequest!.Token);
			history.replace('/');
		} catch {
			// TODO: Show error message to user
		}
	};

	if (isVerifying) {
		return <FullScreenLoading />;
	}

	if (isError || !inviteUrl || !joinRequest) {
		return <JoinErrorSection />;
	}

	return (
		<div className="h-screen bg-gradient-to-br from-gray-100 via-gray-50 to-white">
			<div className="container pt-10 text-center">
				<div className="px-10">
					<Logo light className="w-48 mx-auto mb-10" />
				</div>

				<form onSubmit={onSubmit} className="px-10 py-8 bg-white border-t-8 border-blue-400 rounded-lg shadow-sm 2xl:px-36 md:px-20 sm:px-14 2xl:mx-96 xl:mx-80 lg:mx-52 md:mx-20">
					<h1 className="mb-6 text-4xl font-bold tracking-tight">
						<FormattedMessage id="join.title" />
					</h1>

					<p>
						<FormattedMessage id="join.sub" />
					</p>

					<p className="text-xl font-bold">{joinRequest?.business?.Name}</p>

					<div className="px-6 py-4 my-8 bg-gray-100 rounded">
						<FormattedMessage id="join.note" values={{ email: <strong className="font-bold">{account?.Email}</strong> }} />
					</div>

					<Button block variant={Variant.primary} disabled={isAccepting} type="submit">
						<FormattedMessage id="join.btn" />
					</Button>
				</form>
			</div>
		</div>
	);
};

const JoinErrorSection = () => (
	<ErrorLayout>
		<h1 className="mb-2 text-xl font-semibold text-black">
			<FormattedMessage id="invitation.invalid_invite_title" />
		</h1>
		<p className="my-2 leading-tight text-gray-600">
			<FormattedMessage id="invitation.invalid_invite_text" />
		</p>
	</ErrorLayout>
);
