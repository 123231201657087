import { CannedMessageDropDown as BaseCannedMessagesDropDown } from '@components/CannedMessages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCurrentEditor } from '@tiptap/react';
import { ToolbarButton } from '@ui/RichTextEditor/Toolbar';
import type { FC } from 'react';

type CannedMessagesDropdownProps = {
	editorId?: string;
};

export const CannedMessagesToolbarDropdown: FC<CannedMessagesDropdownProps> = () => {
	const { editor } = useCurrentEditor();

	if (editor === null) {
		return null;
	}

	const onMessageSelected = (message: string) => {
		if (editor.isEmpty) {
			editor.chain().enter().insertContent(message).run();
			editor.commands.focus('start');
		} else {
			editor.chain().insertContent(message).focus().run();
		}
	};

	return (
		<BaseCannedMessagesDropDown onMessageSelected={onMessageSelected}>
			<ToolbarButton icon={['far', 'comment-alt-lines']}>
				<FontAwesomeIcon icon="caret-down" className="ml-1.5" />
			</ToolbarButton>
		</BaseCannedMessagesDropDown>
	);
};
