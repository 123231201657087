import type { BillingAddress } from '@components/Account';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { CardElement } from '@stripe/react-stripe-js';
import type { Stripe, StripeElements } from '@stripe/stripe-js';
import { useMutation } from 'react-query';

export const useStripePaymentMethodCreateMutation = () => {
	const { account } = useAccount();
	const { setValidation } = useClient();

	return useMutation({
		mutationFn: async ({ stripe, elements, billingInformation }: { stripe: Stripe; elements: StripeElements; billingInformation: BillingAddress }) => {
			const cardElement = elements.getElement(CardElement);

			if (cardElement === null || stripe === null) {
				throw new Error('Stripe not defined');
			}

			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: 'card',
				card: cardElement,
				billing_details: {
					email: account!.Email,
					address: {
						country: billingInformation.BillingCountry!,
						...(!!billingInformation.BillingRegion && { state: billingInformation.BillingRegion })
					},
					...(!!billingInformation.BillingName && { name: billingInformation.BillingName })
				}
			});

			if (error && error.param && error.code) {
				setValidation({ [error.param]: [error.code] });
				throw new Error('Stripe not defined');
			}

			if (!paymentMethod?.id) {
				throw new Error('Stripe token not defined');
			}

			return paymentMethod.id;
		}
	});
};
