import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Intent, Variant, resolveButtonClassNames } from '@convoflo/ui';
import Folder from '@models/Folder';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const LINK = 'https://fondsdeprevoyance.lecsor.com/scriptcase/app/lecsor/admin/?lang=fr';

type CustomerSupportButtonProps = {
	secureSpace: Folder;
};

export const LecsorPensionFundButton: FC<CustomerSupportButtonProps> = ({ secureSpace }) => {
	// if (!secureSpace.getMetaValue('lecsor:path')?.endsWith('/Administrateurs')) {
	// 	return null;
	// }

	if (
		!['//13-003H00/Administrateurs', '//13-003V01/Administrateurs', '//13-003V02/Administrateurs', '//13-003V03/Administrateurs', '//06-705V01/Administrateurs'].includes(
			secureSpace.getMetaValue('lecsor:path') ?? ''
		)
	) {
		return null;
	}

	return (
		<a href={LINK} target="_blank" rel="noreferrer" className={resolveButtonClassNames({ intent: Intent.primary, variant: Variant.secondary, iconStart: 'receipt', shadow: true })}>
			<FontAwesomeIcon icon="hands-usd" className="mr-2" />
			<FormattedMessage id="lecsor.contingency_fund" />
		</a>
	);
};
