import { CheckoutButton, useCart, useStore } from '@components/Checkout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import type { FC } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Header } from './Header';

export const SupportPlansStep: FC = () => {
	const { store } = useStore();
	const { addOrUpdateItem, getItemById } = useCart();
	const history = useHistory();
	const { search } = useLocation();

	if (!store) {
		return <Redirect to="/account/checkout" />;
	}

	const planItem = getItemById('plan');

	if (!planItem) {
		return <Redirect to="/account/checkout" />;
	}

	const onSupportPlanSelected = (key: string) => {
		addOrUpdateItem({ id: 'support_plan', options: { level: key } });
	};

	return (
		<div className="max-w-7xl">
			<Header subtitle={<FormattedMessage id="plans.add_support_plan_description" />}>
				<FormattedMessage id="plans.add_support_plan" />
			</Header>
			<table className="w-full border-collapse">
				<colgroup>
					<col className="w-1/4" />
					<col className="w-1/4" />
					<col className="w-1/4" />
					<col className="w-1/4" />
				</colgroup>
				<thead>
					<tr>
						<td />
						{Object.entries(store.support_plans).map(([key, plan]) => (
							<th key={key} className="p-4 pt-8 text-xl font-semibold text-center bg-gray-100 border-t border-l border-r border-gray-200">
								<FormattedMessage id={`plans.support-${key}-title`} />
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					<tr>
						<td />
						{Object.entries(store.support_plans).map(([key, plan]) => (
							<td key={key} className="px-4 pb-4 text-center bg-gray-100 border-l border-r">
								<div>
									<span className="text-3xl font-semibold">
										{!plan.percentage ? <FormattedMessage id="plans.included" /> : <FormattedNumber value={plan.percentage / 100} style="percent" />}
									</span>
								</div>
								<div>
									<p className="mt-2 text-xs text-gray-500">
										{!plan.percentage ? <FormattedMessage id="plans.with_your_subscription" /> : <FormattedMessage id="plans.of_your_subscription" />}
									</p>
								</div>
								<div className="mt-12">
									<Button variant={Variant.primary} block shadow onClick={() => onSupportPlanSelected(key)} disabled={getItemById('support_plan')?.options.level === key}>
										<FormattedMessage id="select" />
									</Button>
								</div>
							</td>
						))}
					</tr>
					<tr>
						<th scope="row" className="px-4 py-3 text-left border border-gray-200">
							<h2 className="mb-0.5 text-base font-semibold">
								<FormattedMessage id="plans.priority" />
							</h2>
							<p className="text-xs font-normal text-gray-500"></p>
						</th>
						<td className="py-3 font-semibold text-center border border-gray-200">
							<FormattedMessage id="plans.support_max_hours" values={{ hours: 48 }} />
						</td>
						<td className="py-3 font-semibold text-center border border-gray-200">
							<FormattedMessage id="plans.support_same_day" />
						</td>
						<td className="py-3 font-semibold text-center border border-gray-200">
							<FormattedMessage id="plans.support_less_hours" values={{ hours: 4 }} />
						</td>
					</tr>
					<tr>
						<th scope="row" className="px-4 py-3 text-left border border-gray-200">
							<h2 className="mb-0.5 text-base font-semibold">
								<FormattedMessage id="plans.email_support" />
							</h2>
							<p className="text-xs font-normal text-gray-500"></p>
						</th>
						<td className="py-3 font-semibold text-center border border-gray-200">
							<FontAwesomeIcon icon="check-circle" className="text-2xl text-green-600" />
						</td>
						<td className="py-3 font-semibold text-center border border-gray-200">
							<FontAwesomeIcon icon="check-circle" className="text-2xl text-green-600" />
						</td>
						<td className="py-3 font-semibold text-center border border-gray-200">
							<FontAwesomeIcon icon="check-circle" className="text-2xl text-green-600" />
						</td>
					</tr>
					<tr>
						<th scope="row" className="px-4 py-3 text-left border border-gray-200">
							<h2 className="mb-0.5 text-base font-semibold">
								<FormattedMessage id="plans.phone_support" />
							</h2>
							<p className="text-xs font-normal text-gray-500"></p>
						</th>
						<td className="py-3 font-semibold text-center border border-gray-200">
							<FontAwesomeIcon icon="times-circle" className="text-2xl text-red-500" />
						</td>
						<td className="py-3 font-semibold text-center border border-gray-200">
							<FontAwesomeIcon icon="check-circle" className="text-2xl text-green-600" />
						</td>
						<td className="py-3 font-semibold text-center border border-gray-200">
							<FontAwesomeIcon icon="check-circle" className="text-2xl text-green-600" />
						</td>
					</tr>
					<tr>
						<th scope="row" className="px-4 py-3 text-left border border-gray-200">
							<h2 className="mb-0.5 text-base font-semibold">
								<FormattedMessage id="plans.assistance_support" />
							</h2>
							<p className="text-xs font-normal text-gray-500"></p>
						</th>
						<td className="py-3 font-semibold text-center border border-gray-200">
							<FontAwesomeIcon icon="times-circle" className="text-2xl text-red-500" />
						</td>
						<td className="py-3 font-semibold text-center border border-gray-200">
							<FontAwesomeIcon icon="times-circle" className="text-2xl text-red-500" />
						</td>
						<td className="py-3 font-semibold text-center border border-gray-200">
							<FontAwesomeIcon icon="check-circle" className="text-2xl text-green-600" />
						</td>
					</tr>
				</tbody>
			</table>

			<div className="grid grid-cols-1 gap-4 mb-12 md:hidden">
				<CheckoutButton onClick={() => addOrUpdateItem({ id: 'support_plan', options: { level: 'standard' } })} selected={getItemById('support_plan')?.options.level === 'standard'}>
					<div className="flex items-center justify-between mb-2 text-lg">
						<h3 className="font-semibold leading-tight">
							<FormattedMessage id="plans.support-standard-title" />
						</h3>
						<span className="text-sm font-semibold text-right">
							<FormattedMessage
								id="plans.support_plan_price"
								values={{
									price: 'free'
								}}
							/>
						</span>
					</div>
					<p className="text-sm leading-snug text-gray-600">
						<FormattedMessage id="plans.support-standard-subtitle" />
					</p>
					<div className="mt-2 space-x-2 text-green-500">
						<FontAwesomeIcon icon="envelope" size="sm" />
					</div>
				</CheckoutButton>
				<CheckoutButton onClick={() => addOrUpdateItem({ id: 'support_plan', options: { level: 'premium' } })} selected={getItemById('support_plan')?.options.level === 'premium'}>
					<div className="flex items-center justify-between mb-2 text-lg">
						<h3 className="font-semibold leading-tight">
							<FormattedMessage id="plans.support-premium-title" />
						</h3>
						<span className="text-sm font-semibold text-right">
							<FormattedMessage
								id="plans.support_plan_price"
								values={{
									price: store.support_plans.premium.percentage / 100,
									span: msg => <span className="text-sm font-normal text-gray-500">{msg}</span>
								}}
							/>
						</span>
					</div>
					<p className="text-sm leading-snug text-gray-600">
						<FormattedMessage id="plans.support-premium-subtitle" />
					</p>

					<div className="mt-2 space-x-2 text-green-500">
						<FontAwesomeIcon icon="phone" size="sm" />
						<FontAwesomeIcon icon="envelope" size="sm" />
					</div>
				</CheckoutButton>
				<CheckoutButton onClick={() => addOrUpdateItem({ id: 'support_plan', options: { level: 'premium-plus' } })} selected={getItemById('support_plan')?.options.level === 'premium-plus'}>
					<div className="flex items-center justify-between mb-2 text-lg">
						<h3 className="font-semibold leading-tight">
							<FormattedMessage id="plans.support-premium-plus-title" />
						</h3>
						<span className="text-sm font-semibold leading-none text-right">
							<FormattedMessage
								id="plans.support_plan_price"
								values={{
									price: store.support_plans['premium-plus'].percentage / 100,
									span: msg => <span className="text-sm font-normal text-gray-500">{msg}</span>
								}}
							/>
						</span>
					</div>
					<p className="text-sm leading-snug text-gray-600">
						<FormattedMessage id="plans.support-premium-plus-subtitle" />
					</p>

					<div className="mt-2 space-x-2 text-green-500">
						<FontAwesomeIcon icon="database" size="sm" />
						<FontAwesomeIcon icon="phone" size="sm" />
						<FontAwesomeIcon icon="envelope" size="sm" />
					</div>
				</CheckoutButton>
			</div>

			<div className="flex items-center justify-between my-12">
				<Button
					type="button"
					variant={Variant.light}
					intent={Intent.tertiary}
					iconStart="long-arrow-alt-left"
					animateIcon
					onClick={() => history.push({ pathname: '/account/checkout/modules', search })}>
					<FormattedMessage id="plans.modules" />
				</Button>
				<Button variant={Variant.primary} size={Size.lg} iconEnd="long-arrow-alt-right" animateIcon onClick={() => history.push({ pathname: '/account/checkout/billing', search })}>
					<FormattedMessage id="plans.billing_information" />
				</Button>
			</div>
		</div>
	);
};
