import { type FileItemProps, FileableItemThumbnail } from '@components/FileManager';
import { type DataRow, type ImportRow, createSecureSpace, formatSecureSpaceName, useImporter } from '@components/Importer';
import { useWizard } from '@components/Wizard';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import { useAccount } from '@hooks/useAccount';
import Folder from '@models/Folder';
import { type Fileable, Permissions } from '@types';
import Card from '@ui/Card';
import UserAvatar from '@ui/UserAvatar';
import classNames from 'classnames';
import groupBy from 'lodash.groupby';
import isEqual from 'lodash.isequal';
import uniqWith from 'lodash.uniqwith';
import { type FC, type FormEvent } from 'react';
import { FormattedMessage } from 'react-intl';

export const NamingStep: FC = () => {
	const { setData, format, setFormat, originalData } = useImporter();
	const { nextStep, previousStep } = useWizard();

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();

		let id = 0;
		let groupedData = groupBy(originalData, row => ('grouping' in row ? row.grouping : `contact_${++id}`));

		let data = Object.entries(groupedData).map(
			([key, rows]) =>
				({
					status: 'pending',
					key,
					rows,
					name: uniqWith(rows.map(row => formatSecureSpaceName(format ?? '', row))),
					members: uniqWith(
						rows
							.map(row => {
								let email = row.email;
								if (email?.includes('(') && email?.includes(')')) {
									const matches = email.matchAll(/[<(]?([^<>\s]+@[^\s<>]+)[>)]?([^<>\s]*)\b/gm);
									for (const match of matches) {
										email = match[1].toLowerCase();
									}
								}

								return {
									email: email,
									first_name: row.first_name,
									last_name: row.last_name
								};
							})
							.filter(row => !!row.email),
						isEqual
					)
				} as DataRow)
		);
		setData(data);
		nextStep();
	};

	if (originalData.length === 0) {
		previousStep();
		return null;
	}

	const example = originalData[0];

	return (
		<Card onSubmit={onSubmit}>
			<div className="prose max-w-none">
				<h2>
					<FormattedMessage id="importer.naming.title" />
				</h2>
				<p>
					<FormattedMessage id="importer.naming.description" />
				</p>
				<p>
					<strong>
						<FormattedMessage id="importer.naming.choose" />
					</strong>
				</p>
			</div>

			<div className="mt-6 border divide-y rounded-md">
				{/* Corpo */}
				{!!example.business && !!example.grouping && <FormatChoice example={example} format="<BUSINESS> (<GROUPING>)" onSelected={setFormat} currentValue={format} />}
				{!!example.business && !!example.grouping && <FormatChoice example={example} format="<GROUPING> - <BUSINESS>" onSelected={setFormat} currentValue={format} />}
				{!!example.business && <FormatChoice example={example} format="<BUSINESS>" onSelected={setFormat} currentValue={format} />}

				{/* Particulier */}
				{!!example.first_name && !!example.last_name && !!example.grouping && (
					<FormatChoice example={example} format="<LAST_NAME>, <FIRST_NAME> (<GROUPING>)" onSelected={setFormat} currentValue={format} />
				)}
				{/* Particulier */}
				{!!example.name && !!example.grouping && <FormatChoice example={example} format="<NAME> (<GROUPING>)" onSelected={setFormat} currentValue={format} />}
				{!!example.name && <FormatChoice example={example} format="<NAME>" onSelected={setFormat} currentValue={format} />}
				{!!example.first_name && !!example.last_name && !!example.grouping && (
					<FormatChoice example={example} format="<GROUPING> - <FIRST_NAME> <LAST_NAME>" onSelected={setFormat} currentValue={format} />
				)}
				{!!example.first_name && !!example.last_name && !!example.grouping && (
					<FormatChoice example={example} format="<FIRST_NAME> <LAST_NAME> (<GROUPING>)" onSelected={setFormat} currentValue={format} />
				)}
				{!!example.first_name && !!example.last_name && <FormatChoice example={example} format="<FIRST_NAME> <LAST_NAME>" onSelected={setFormat} currentValue={format} />}
				{!!example.first_name && !!example.last_name && <FormatChoice example={example} format="<LAST_NAME>, <FIRST_NAME>" onSelected={setFormat} currentValue={format} />}
				{!!example.grouping && <FormatChoice example={example} format="<GROUPING>" onSelected={setFormat} currentValue={format} />}
			</div>
			<footer className="flex items-center justify-between mt-12">
				<Button type="button" variant={Variant.primary} intent={Intent.tertiary} onClick={previousStep} iconStart="arrow-left" animateIcon size={Size.sm}>
					1. <FormattedMessage id="importer.step.import" />
				</Button>
				<Button type="submit" variant={Variant.primary} iconEnd="arrow-right" animateIcon>
					3. <FormattedMessage id="importer.step.configuration" />
				</Button>
			</footer>
		</Card>
	);
};

type FormatChoiceProps = {
	example: ImportRow;
	format: string;
	currentValue?: string;
	onSelected?: (format: string) => void;
};

const FormatChoice: FC<FormatChoiceProps> = ({ example, format, currentValue, onSelected = () => undefined }) => {
	const { creator, template } = useImporter();

	const item = createSecureSpace(formatSecureSpaceName(format, example), creator, template);
	const selected = format === currentValue;

	return <FileItem item={item} selected={selected} onSelected={() => onSelected(format)} />;
};

export const FileItem: FC<FileItemProps & { selected?: boolean; onSelected?: (item: Fileable) => void }> = ({ item, selected = false, onSelected = () => undefined }) => {
	const { account } = useAccount();

	return (
		<button type="button" className="w-full overflow-hidden cursor-pointer" onClick={() => onSelected(item)}>
			<div
				className={classNames('select-none flex items-center relative gap-2 px-3 py-1', {
					'bg-yellow-100': selected,
					'hover:bg-gray-50': !selected
				})}>
				<div className="relative flex items-center justify-center flex-shrink-0 w-8 h-8 sm:w-12 sm:h-12">
					<FileableItemThumbnail fileable={item} />
				</div>

				<div className="flex items-center flex-1 gap-x-3">
					<div className="flex items-center flex-1 space-x-4 group">
						<div className="flex-1 text-left">
							{/* Title */}
							<span>
								<a href={item.getUrl(item instanceof Folder && !item.SecuredSpace ? 'files' : undefined)} onClick={e => e.preventDefault()} className="text-sm font-semibold">
									{item.getName()}
								</a>
							</span>
						</div>
					</div>

					{/* Members */}
					<div className="items-center justify-end flex-shrink-0 hidden sm:flex max-w-56">
						{account?.hasFullAccess() && item.pivot!.Permissions === Permissions.ReadWrite && item.collaborators.length > 0 ? (
							<span className="inline-flex items-center space-x-3 focus:outline-none">
								<span className="inline-flex items-center -space-x-1">
									{item.collaborators.map(user => (
										<UserAvatar key={user.ID} user={user} size={Size.xs} />
									))}
									{item.members_count > item.collaborators.length && (
										<span className="pl-2 text-sm font-bold tracking-tighter text-gray-500">+{item.members_count - item.collaborators.length}</span>
									)}
								</span>
							</span>
						) : item.creator ? (
							<span className="text-xs leading-tight text-gray-600 truncate">{item.creator.business?.Name ?? item.creator.Name}</span>
						) : null}
					</div>
				</div>
			</div>
		</button>
	);
};
