import { DialogAnnouncements } from '@components/Announcement';
import { useAccount } from '@hooks/useAccount';
import { FreeTrialOfferBanner, ResumeSubscriptionBanner, UnpaidSubscriptionBanner, UpgradeTrialBanner } from '@ui/Banners';
import { Topbar } from '@ui/Layout';
import Page from '@ui/Layout/Page';
import classNames from 'classnames';
import { type FC, type PropsWithChildren } from 'react';
import { use100vh } from 'react-div-100vh';
import { Drawer } from './Drawer';
import { GlobalSearch } from './GlobalSearch';

type ContainerProps = PropsWithChildren<{
	theme?: 'light' | 'dark' | null;
	layout?: 'full' | 'minimal' | 'nothing' | 'print' | null;
}>;

const Container: FC<ContainerProps> = ({ theme = 'light', layout = 'full', children }) => {
	const { account } = useAccount();
	const height = use100vh();
	const fullHeight = height ? height : '100vh';

	if (layout === 'print' || layout === 'nothing') {
		return (
			<Page theme={theme} layout={layout}>
				{children}
			</Page>
		);
	}

	return (
		<>
			<DialogAnnouncements />

			<div className={classNames('w-screen overflow-hidden h-screen bg-gray-100 flex')} style={{ height: fullHeight }}>
				{account && layout === 'full' && <Drawer account={account} theme={theme} layout={layout} />}

				<Page theme={theme} layout={layout}>
					{account && (
						<>
							<UnpaidSubscriptionBanner />
							<ResumeSubscriptionBanner />
							<UpgradeTrialBanner />
							<FreeTrialOfferBanner />
							<Topbar account={account} theme={theme} layout={layout} />
						</>
					)}
					{children}
				</Page>
				<GlobalSearch />
			</div>
		</>
	);
};

export default Container;
