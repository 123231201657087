import { createContext } from 'react';
import type { Fileable } from '@types';
import type { Comment } from '@components/Message';

export const MessageFormContext = createContext<{
	disabled: boolean;
	fileable?: Fileable;
	initialMessage?: Comment;
	pluginData: Record<string, any>;
}>({
	disabled: false,
	pluginData: {}
});
