import CheckEmailImage from '@assets/images/check-email.svg?react';
import { useAccountVerification } from '@components/Onboarding';
import AppContext from '@contexts/AppContext';
import { Alert, Button, Intent, Size, Variant } from '@convoflo/ui';
import { useAccount } from '@hooks/useAccount';
import useUrlSearch from '@hooks/useUrlSearch';
import { type FC, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

export const OnboardingVerificationPage: FC = () => {
	const { formatMessage } = useIntl();
	const { account } = useAccount();
	const { setPageTitle: setTitle } = useContext(AppContext);
	const history = useHistory();
	const { verification_url: verificationUrl } = useUrlSearch();

	const { verified, verify } = useAccountVerification();

	useEffect(() => {
		setTitle(formatMessage({ id: 'welcome.page_title' }));
	}, [setTitle, formatMessage]);

	const fullAccess = account?.hasFullAccess();

	useEffect(() => {
		if (verified && fullAccess && !account?.business.Name) {
			history.replace('/onboarding/organization');
		} else if (verified && fullAccess && account?.business.Name) {
			history.replace('/onboarding/secure-space');
		} else if (verified) {
			history.replace('/');
		}
	}, [verified, history, fullAccess, account?.business.Name]);

	useEffect(() => {
		if (verificationUrl) {
			verify(verificationUrl);
		}
	}, [verificationUrl, verify]);

	return (
		<div>
			<header className="mb-8 space-y-2">
				<h1 className="text-3xl font-bold md:text-4xl">
					<FormattedMessage id="onboarding.verification.title" />
				</h1>
				<p className="text-gray-400">
					<FormattedMessage id="onboarding.verification.subtitle" />
				</p>
			</header>

			<CheckEmailImage className="my-16" />

			<Alert variant={Variant.info} className="border">
				<p>
					<FormattedMessage id="onboarding.verification.not_received" />
				</p>
				<Button variant={Variant.info} size={Size.xs} intent={Intent.tertiary} className="mt-2">
					<FormattedMessage id="onboarding.verification.resend" />
				</Button>
			</Alert>
		</div>
	);
};
