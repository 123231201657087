import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Intent, Modal, ModalBody, ModalFooter, Variant } from '@convoflo/ui';
import { createNewReminder, FileRequestForm, FileRequestItem, type ScheduledMessageType } from '@components/FileRequest';
import { compareAsc, parseISO } from 'date-fns';
import useClient from '@hooks/useClient';
import useUrlSearch from '@hooks/useUrlSearch';
import Folder from '@models/Folder';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { Permissions } from '@types';
import EmptyState from '@ui/EmptyState';
import { MarkdownContent } from '@ui/MarkdownContent';

type FileRequestListProps = {
	folder: Folder;
};

export const FileRequestList: FC<FileRequestListProps> = ({ folder }) => {
	const { fr: highlighted = null } = useUrlSearch();
	const { client } = useClient();

	const [highlightedReminder, setHighlightedReminder] = useState<ScheduledMessageType | null>(null);
	const [defaultReminder, setDefaultReminder] = useState<Partial<ScheduledMessageType> | null>(null);

	const showHighlightedReminder = useCallback(
		async id => {
			setHighlightedReminder(
				await client
					.url(`${folder.getRoute('requests')}/${id}`)
					.get()
					.json<ScheduledMessageType>()
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[folder]
	);

	const { data: reminders = [] } = useQuery(['reminders', `folder:${folder.URL}`], async () => await client.url(folder.getRoute('requests')).get().json<ScheduledMessageType[]>());

	const orderedReminders = useMemo(() => {
		reminders.sort((a, b) => compareAsc(parseISO(a.remind_at), parseISO(b.remind_at)));
		return reminders;
	}, [reminders]);

	const onDuplicate = (reminder: ScheduledMessageType) => {
		setDefaultReminder(reminder);
	};

	useEffect(() => {
		if (highlighted !== null) {
			showHighlightedReminder(highlighted);
		}
	}, [folder, showHighlightedReminder, highlighted]);

	return (
		<>
			{/* Highlighted */}
			{highlightedReminder !== null && (
				<Modal isOpen>
					<ModalBody>
						<blockquote className="mt-2 blockquote">
							<MarkdownContent>{highlightedReminder.Body || ''}</MarkdownContent>
						</blockquote>
					</ModalBody>
					<ModalFooter>
						<Button variant={Variant.primary}>
							<FormattedMessage id="file-requests-crud.upload_files" />
						</Button>
						<Button variant={Variant.light} intent={Intent.secondary}>
							<FormattedMessage id="close" />
						</Button>
					</ModalFooter>
				</Modal>
			)}

			{/* Title & toolbar */}
			{folder?.pivot?.Permissions === Permissions.ReadWrite && reminders.length > 0 && (
				<div className="flex items-center justify-end mb-3">
					<Button circle variant={Variant.primary} shadow onClick={() => setDefaultReminder(createNewReminder())}>
						<FontAwesomeIcon icon="plus" />
					</Button>
				</div>
			)}

			{/* Create form */}
			{defaultReminder !== null && <FileRequestForm folder={folder} reminder={defaultReminder} multiple onAfterClose={() => setDefaultReminder(null)} />}

			{/* List */}
			{orderedReminders.filter(fr => !fr.Reminded).length > 0 && (
				<>
					<h5 className="mb-4 text-lg font-semibold">
						<FormattedMessage id="file-requests-crud.upcoming_reminders" />
					</h5>
					<div className="mb-8 space-y-4">
						{orderedReminders
							.filter(fr => !fr.Reminded)
							.map(reminder => (
								<FileRequestItem onDuplicate={onDuplicate} key={reminder.Id} folder={folder} reminder={reminder} />
							))}
					</div>
				</>
			)}

			{/* List */}
			{reminders.filter(fr => fr.Reminded).length > 0 && (
				<>
					<h5 className="mb-4 text-lg font-semibold">
						<FormattedMessage id="file-requests-crud.past_reminders" />
					</h5>
					<div className="space-y-4">
						{orderedReminders
							.filter(fr => fr.Reminded)
							.reverse()
							.map(reminder => (
								<FileRequestItem onDuplicate={onDuplicate} key={reminder.Id} folder={folder} reminder={reminder} readonly />
							))}
					</div>
				</>
			)}

			{/* Empty state */}
			{reminders.length === 0 && (
				<EmptyState
					icon="bells"
					title={<FormattedMessage id="file-requests-crud.empty-title" />}
					description={
						folder?.pivot?.Permissions === Permissions.ReadWrite ? (
							<span>
								<FormattedMessage id="file-requests-crud.empty-body" />
							</span>
						) : undefined
					}
					action={
						folder?.pivot?.Permissions === Permissions.ReadWrite ? (
							<Button variant={Variant.primary} shadow onClick={() => setDefaultReminder(createNewReminder())}>
								<FormattedMessage id="file-requests-crud.create-file-request" />
							</Button>
						) : undefined
					}
				/>
			)}
		</>
	);
};
