import { MicrosoftEntraIdBanner } from '@components/MicrosoftEntraId/MicrosoftEntraIdBanner';
import { InviteItem, type OrganizationInvite, type OrganizationQuota, type OrganizationUser, TeamMemberForm, TeamMemberItem } from '@components/Team';
import { Button, Input, Intent, Modal, ModalBody, ModalHeaderOnlyTitle, ModalSize, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import Card from '@ui/Card';
import ProgressBar from '@ui/ProgressBar';
import SortColumnHeader from '@ui/SortColumnHeader';
import { Table, TableCell, TableHeader } from '@ui/Table';
import debounce from 'lodash.debounce';
import { type ChangeEvent, type FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';

type GetOrganizationUsersResponse = {
	users: OrganizationUser[];
	quota: {
		total_user_used: number;
		total_user_available: number;
		price_per_user: number;
	};
};

export const TeamManager: FC = () => {
	const { formatMessage } = useIntl();
	const { account } = useAccount();
	const { client } = useClient();

	const [sort, setSort] = useState('name');
	const [query, setQuery] = useState('');
	const [isAddUserPanelOpened, setIsAddUserPanelOpened] = useState(false);
	const [showUpgradeAccountModal, setShowUpgradeAccountModal] = useState(false);
	const [showShowCallUpgradeAccountModal, setShowShowCallUpgradeAccountModal] = useState(false);

	const { mutate: sendAlertToAdmins } = useMutation(async () => await client.url('events/new-users-requested').post({ source: 'add-user' }).res(), {
		onError: console.error
	});

	useEffect(() => {
		if (showShowCallUpgradeAccountModal) {
			sendAlertToAdmins({});
		}
	}, [showShowCallUpgradeAccountModal, sendAlertToAdmins]);

	const { data, isLoading } = useQuery(['users', query, sort], async () => await client.url('org/users').query({ query, sort }).get().json<GetOrganizationUsersResponse>(), {
		select: ({ users, quota }) => ({
			quota: {
				used: quota.total_user_used,
				available: quota.total_user_available,
				pricePerUser: quota.price_per_user
			},
			users
		}),
		onError: console.error
	});

	const { data: invites } = useQuery(['invites'], async () => await client.url('org/users/invites').get().json<OrganizationInvite[]>());

	const openAddUser = (quota: OrganizationQuota) => {
		if (quota.available <= 0) {
			if (account?.business.trial_ends_at === null && (account?.business.subscription === null || account?.business.subscription.Provider === 'other')) {
				setShowShowCallUpgradeAccountModal(true);
			} else if (account?.business.trial_ends_at !== null) {
				setShowUpgradeAccountModal(true);
			} else {
				setIsAddUserPanelOpened(true);
			}
			return;
		}

		setIsAddUserPanelOpened(true);
	};

	const onSearchInputChanged = debounce((e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		e.persist();
		setQuery(e.target.value);
	}, 250);

	return (
		<>
			<ProgressBar
				max={account?.Usage.Users.limit ? account?.Usage.Users.limit : 100}
				current={account?.Usage.Users.used}
				unlimited={!account?.Usage.Users.limit}
				customBackground="bg-gray-100"
				className="h-4 mb-8 w-72">
				<FormattedMessage
					id="x_of_n"
					values={{ x: account?.Usage.Users.used, n: account?.Usage.Users.limit === null ? <FormattedMessage id="usage.unlimited" /> : account?.Usage.Users.limit }}
				/>
			</ProgressBar>

			<div className="my-12 empty:my-0">
				<MicrosoftEntraIdBanner />
			</div>

			<div className="flex justify-between mb-8" role="toolbar">
				<Input type="text" placeholder={formatMessage({ id: 'user-manager.search_placeholder' })} onChange={onSearchInputChanged} />
				<Button disabled={data?.quota === undefined} shadow type="button" variant={Variant.primary} intent={Intent.primary} onClick={() => openAddUser(data!.quota)}>
					<FontAwesomeIcon icon="user-plus" className="mr-2" />
					<FormattedMessage id="invitation.invite" />
				</Button>
			</div>

			{data?.users && (
				<Card size={null} className="mb-12">
					<Table>
						<thead>
							<tr>
								<TableHeader>
									<SortColumnHeader sortField="name" currentSort={sort} onSort={setSort}>
										<FormattedMessage id="name" />
									</SortColumnHeader>
								</TableHeader>
								<TableHeader>
									<SortColumnHeader sortField="role" currentSort={sort} onSort={setSort}>
										<FormattedMessage id="user-manager.role" />
									</SortColumnHeader>
								</TableHeader>
								<TableHeader>
									<SortColumnHeader sortField="mfa" currentSort={sort} onSort={setSort}>
										<FormattedMessage id="user-manager.mfa" />
									</SortColumnHeader>
								</TableHeader>
								<TableHeader>
									<SortColumnHeader sortField="date" currentSort={sort} onSort={setSort}>
										<FormattedMessage id="user-manager.date_joined" />
									</SortColumnHeader>
								</TableHeader>
								<TableHeader />
							</tr>
						</thead>
						<tbody className="bg-white">
							{!isLoading && data.users.map(user => <TeamMemberItem key={user.ID} user={user} />)}
							{isLoading && (
								<tr>
									<TableCell colSpan={3}>
										<div className="flex items-center justify-center h-24">
											<p>
												<FontAwesomeIcon icon="spinner" className="mr-2" pulse />
												<FormattedMessage id="loading" />
											</p>
										</div>
									</TableCell>
								</tr>
							)}
						</tbody>
					</Table>
				</Card>
			)}

			{(invites ?? [])?.length > 0 && (
				<div className="mt-12">
					<h2 className="px-6 mb-8 text-2xl font-medium text-gray-900 md:px-0">
						<FormattedMessage id="invitation.title" />
					</h2>
					<Card size={null}>
						<Table>
							<thead>
								<tr>
									<TableHeader>
										<span className="text-xs font-medium leading-4 tracking-wider uppercase">
											<FormattedMessage id="user-manager.email" />
										</span>
									</TableHeader>
									<TableHeader>
										<span className="text-xs font-medium leading-4 tracking-wider uppercase">
											<FormattedMessage id="user-manager.role" />
										</span>
									</TableHeader>
									<TableHeader>
										<span className="text-xs font-medium leading-4 tracking-wider uppercase">
											<FormattedMessage id="invitation.sent_on" />
										</span>
									</TableHeader>
									<TableHeader>
										<span className="text-xs font-medium leading-4 tracking-wider uppercase">
											<FormattedMessage id="invitation.expires_at" />
										</span>
									</TableHeader>
									<TableHeader />
								</tr>
							</thead>
							<tbody className="bg-white">
								{invites?.map(invite => (
									<InviteItem invite={invite} key={invite.Id} />
								))}
							</tbody>
						</Table>
					</Card>
				</div>
			)}

			<Modal isOpen={showUpgradeAccountModal} size={ModalSize.XSmall} closeable={true} onAfterClose={() => setShowUpgradeAccountModal(false)}>
				<ModalHeaderOnlyTitle>
					<FormattedMessage id="user-manager.limit-reached-upgrade-title" />
				</ModalHeaderOnlyTitle>
				<ModalBody className="prose-sm prose max-w-none">
					<p>
						<FormattedMessage id="user-manager.limit-reached-upgrade-body" />
					</p>
				</ModalBody>
			</Modal>

			<Modal isOpen={showShowCallUpgradeAccountModal} size={ModalSize.XSmall} closeable={true} onAfterClose={() => setShowShowCallUpgradeAccountModal(false)}>
				<ModalHeaderOnlyTitle>
					<FormattedMessage id="user-manager.limit-reached-contact-title" />
				</ModalHeaderOnlyTitle>
				<ModalBody className="prose-sm prose max-w-none">
					<p>
						<FormattedMessage
							id="user-manager.limit-reached-contact-body"
							values={{
								phone: (
									<a href="tel:+18008977432" className="text-theme-primary hover:underline">
										+1 800 897-7432
									</a>
								)
							}}
						/>
					</p>
				</ModalBody>
			</Modal>

			{isAddUserPanelOpened && data?.quota !== undefined && <TeamMemberForm quota={data.quota} onAfterClose={() => setIsAddUserPanelOpened(false)} />}
		</>
	);
};
