import ThreeDotsSpinner from '@assets/images/ThreeDotsSpinner.svg?react';
import { Size, Variant } from '@convoflo/ui';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { forwardRef, type ButtonHTMLAttributes, type FC, type ForwardRefRenderFunction, type ForwardedRef, type HTMLAttributes, type PropsWithChildren, type ReactNode } from 'react';

type CardProps = HTMLAttributes<HTMLFormElement> & {
	size?: Size | null;
	variant?: Variant;
};

const CardRenderFn: ForwardRefRenderFunction<HTMLFormElement | HTMLDivElement, CardProps> = ({ size = Size.md, variant = Variant.light, className, onSubmit, ...formProps }, ref) => {
	const cardClassName = classNames(className, 'border border-gray-200 w-full overflow-hidden', {
		'px-3 py-4': size === Size.sm,
		'px-6 py-8': size === Size.md,
		'': size === null,
		'bg-white': variant === Variant.light
	});

	if (onSubmit) {
		return <form ref={ref as unknown as ForwardedRef<HTMLFormElement>} className={cardClassName} onSubmit={onSubmit} {...formProps} />;
	}

	return <div ref={ref as unknown as ForwardedRef<HTMLDivElement>} className={cardClassName} {...(formProps as HTMLAttributes<HTMLDivElement>)} />;
};

type StatCardProps = Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> &
	PropsWithChildren<{
		title: ReactNode;
		stat: ReactNode;
		icon?: IconProp;
		isLoading?: boolean;
	}>;

export const StatCard: FC<StatCardProps> = ({ title, stat, icon, onClick, isLoading = false, children }) => {
	return (
		<Card size={null} className={`transition duration-300 ease-in-out translate-y-0 ${onClick ? 'hover:shadow-lg hover:-translate-y-1 group' : ''}`}>
			<button className="relative flex flex-col w-full h-full p-4 text-left transition-colors duration-200 ease-in-out focus:outline-none" type="button" onClick={onClick}>
				{icon !== undefined && (
					<FontAwesomeIcon icon={icon} size="3x" className="absolute top-0 right-0 m-4 text-gray-200 transition-opacity duration-300 ease-in-out group-hover:opacity-50" />
				)}
				<div className="flex items-end w-full" style={{ zIndex: 1 }}>
					<div className="flex-1 space-y-2">
						<h5 className="text-sm font-medium leading-tight text-gray-500 transition-colors duration-300 ease-in-out group-hover:text-black">{title}</h5>
						{!isLoading && <p className="text-2xl font-semibold leading-none">{stat}</p>}
						{isLoading && (
							<div className="flex flex-col items-start justify-end h-9">
								<ThreeDotsSpinner className="w-auto h-3 text-gray-600" />
							</div>
						)}
					</div>
				</div>
				{children !== undefined && <div className="flex-1 mt-4 mb-2">{children}</div>}
			</button>
		</Card>
	);
};

const Card = forwardRef(CardRenderFn);

export default Card;
