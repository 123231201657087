import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCurrentEditor } from '@tiptap/react';
import Dropdown, { DropdownGroup, DropdownItem } from '@ui/Dropdown';
import { ToolbarButton, type ToolbarButtonProps } from '@ui/RichTextEditor/Toolbar';
import { Children, type FC, type PropsWithChildren, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export const ToolbarDropdown: FC<PropsWithChildren> = ({ children }) => {
	const { editor } = useCurrentEditor();

	if (editor === null) {
		return null;
	}

	const allTypes = Children.map(children as any, ({ props: { type, icon } }: ReactElement<ToolbarButtonProps>) => ({ type, icon }));

	const selectedBlockText = allTypes.find(({ type }) => (editor.isActive.apply(editor, type ?? ['']) ? type : undefined))?.type ?? ['paragraph'];
	const selectedBlockIcon = allTypes.find(({ type, icon }) => (editor.isActive.apply(editor, type ?? ['']) ? icon : undefined))?.icon ?? 'paragraph';

	return (
		<Dropdown placement="bottom-start">
			<ToolbarButton>
				<span className="hidden sm:inline">
					<FormattedMessage id={`text-editor.${selectedBlockText[0]}`} values={selectedBlockText[1] ?? {}} />
				</span>
				<span className="sm:hidden">
					<FontAwesomeIcon icon={selectedBlockIcon} />
				</span>
				<FontAwesomeIcon icon="caret-down" className="ml-1.5" />
			</ToolbarButton>
			<DropdownGroup>
				{Children.map(children as any, ({ props: { type = ['paragraph'], icon, handler, children } }: ReactElement<ToolbarButtonProps>) => (
					<DropdownItem key={`${type[0]}-${type[1] ?? ''}`} active={editor.isActive.apply(editor, type ?? ['', {}])} icon={icon} onClick={handler}>
						{children || <FormattedMessage id={`text-editor.${type[0]}`} values={type[1] ?? {}} />}
					</DropdownItem>
				))}
			</DropdownGroup>
		</Dropdown>
	);
};
