import { Size } from '@convoflo/ui';
import { useEcho } from '@hooks/useEcho';
import UserAvatar from '@ui/UserAvatar';
import type { PusherChannel } from 'laravel-echo/dist/channel';
import { type FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import type { AppUpdatedEvent, NotificationSent } from '../events';

/**
 * @see https://www.npmjs.com/package/@harelpls/use-pusher
 */
export const NotificationsListener = () => {
	const echo = useEcho();

	const globalChannel = useRef<PusherChannel | null>(null);

	const refresh = useCallback(() => {
		window.location.reload();
	}, []);

	useEffect(() => {
		globalChannel.current = echo?.channel('app').listen('.TagMyDoc\\Events\\App\\Updated', (event: AppUpdatedEvent) => {
			toast(
				<FormattedMessage
					id="app.updated"
					values={{
						a: msg => (
							<button onClick={refresh} className="text-theme-primary hover:underline focus:outline-none">
								{msg}
							</button>
						)
					}}
				/>,
				{
					duration: Infinity
				}
			);
		}) as PusherChannel;

		return () => {
			globalChannel.current?.stopListening('.TagMyDoc\\Events\\App\\Updated');
			echo?.leave('global');
		};
	}, [echo, refresh]);

	return null;
};

const MAX_NOTIFICATIONS_LIST = 2;

export const NotificationConfirmationToastContents: FC<NotificationSent> = ({ users, context }) => {
	const [expanded, setExpanded] = useState(false);

	const internalUsers = useMemo(() => users.filter(user => user.Scope === 'internal'), [users]);
	const externalUsers = useMemo(() => users.filter(user => user.Scope !== 'internal'), [users]);

	return (
		<>
			<ul className="mt-3 space-y-2">
				{context?.organization ? (
					<>
						<li>{context.organization.Name}</li>
						{externalUsers.length > 0 && (
							<>
								<li className="my-2 italic text-gray-500">
									<FormattedMessage id="and" />
								</li>
								{externalUsers.slice(0, !expanded ? MAX_NOTIFICATIONS_LIST : externalUsers.length).map(user => (
									<li key={user.ID} className="flex items-center">
										<UserAvatar user={user} className="mr-1" size={Size.xs} />
										{user.Name}
									</li>
								))}
							</>
						)}
					</>
				) : (
					<>
						{externalUsers.slice(0, !expanded ? MAX_NOTIFICATIONS_LIST : externalUsers.length).map(user => (
							<li key={user.ID} className="flex items-center">
								<UserAvatar user={user} className="mr-1" size={Size.xs} />
								{user.Name}
							</li>
						))}
						{externalUsers.length > 0 && internalUsers.length > 0 && (
							<li className="!mt-4 text-xs italic text-gray-500">
								<FormattedMessage id="app.notification.organization_members" />
							</li>
						)}
						{internalUsers.slice(0, !expanded ? MAX_NOTIFICATIONS_LIST : internalUsers.length).map(user => (
							<li key={user.ID} className="flex items-center">
								<UserAvatar user={user} className="mr-1" size={Size.xs} />
								{user.Name}
							</li>
						))}
					</>
				)}
				{!expanded && (externalUsers.length > MAX_NOTIFICATIONS_LIST || internalUsers.length > MAX_NOTIFICATIONS_LIST) && (
					<li className="!mt-4">
						<button type="button" className="text-xs text-theme-primary hover:underline" onClick={() => setExpanded(true)}>
							<FormattedMessage id="load_more" />
						</button>
					</li>
				)}
			</ul>
		</>
	);
};
