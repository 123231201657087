import type SignRequest from '@models/SignRequest';
import { Tooltip } from '@ui/Tooltip';
import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FileLabel } from './FileLabel';
import type File from '@models/File';

type FileSignRequestStatusProps = {
	file: File;
	signRequest: SignRequest;
};

export const FileSignRequestStatus: FC<FileSignRequestStatusProps> = ({ file, signRequest }) => {
	const { formatList } = useIntl();

	// We don't have much information other
	if (file.current.Source === 'ONESPAN_SIGN' && !file.signer?.length) {
		return (
			<FileLabel icon="signature" color="red">
				<FormattedMessage id="file-manager.signed" />
			</FileLabel>
		);
	}

	if (!file.signer?.length) {
		return null;
	}

	const signedRoles = signRequest.roles.filter(role => !!role?.signed_at);

	let label = <FormattedMessage id="file-manager.signed" />;

	if (signedRoles.length !== signRequest.roles.length) {
		label = <FormattedMessage id="x_of_n" values={{ x: signedRoles.length, n: signRequest.roles.length }} />;
	}

	return (
		<FileLabel icon="signature" color="red">
			<Tooltip tip={formatList(signedRoles.map(role => role.signer.Name))}>
				<span>{label}</span>
			</Tooltip>
		</FileLabel>
	);
};
