import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Intent, Variant, resolveButtonClassNames } from '@convoflo/ui';
import Folder from '@models/Folder';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const LINK = 'https://helpdesk.lecsor.com';

type CustomerSupportButtonProps = {
	secureSpace: Folder;
};

export const LecsorCustomerSupportButton: FC<CustomerSupportButtonProps> = ({ secureSpace }) => {
	if (secureSpace.getMetaValue('lecsor:path')?.endsWith('/Coproprietaires') || secureSpace.getMetaValue('lecsor:path')?.endsWith('/Administrateurs')) {
		return (
			<a href={LINK} target="_blank" rel="noreferrer" className={resolveButtonClassNames({ intent: Intent.primary, variant: Variant.secondary, iconStart: 'headset', shadow: true })}>
				<FontAwesomeIcon icon="receipt" className="mr-2" />
				<FormattedMessage id="lecsor.helpdesk" />
			</a>
		);
	}

	return null;
};
