import type { MemberType } from '@components/FileManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { type GroupHeadingProps, components } from 'react-select';

export const ScopeGroupHeading = ({ data, ...props }: GroupHeadingProps<MemberType>) => {
	const isInternal = data.label === 'internal';

	return (
		<components.GroupHeading data={data} {...props}>
			<FontAwesomeIcon icon={isInternal ? 'building' : 'globe-americas'} className="mr-2" />
			<FormattedMessage id={isInternal ? 'alerts.internal' : 'alerts.external'} />
		</components.GroupHeading>
	);
};
