import { EnvironmentSwitcher } from '@components/EnvironmentSwicher';
import { GuidedTourProvider } from '@components/GuidedTour';
import { ImportContextProvider } from '@components/Importer';
import { NotificationsListener } from '@components/NotificationsListener';
import AppContext from '@contexts/AppContext';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import Error403Page from '@pages/Errors/403';
import Error404Page from '@pages/Errors/404';
import GenericErrorPage from '@pages/Errors/Generic';
import { TooManyRequestsPage } from '@pages/Errors/too-many-requests';
import HomePage from '@pages/Home';
import { PreviewPage } from '@pages/PreviewFile';
import { ReferralLanding as ReferralLandingPage, ReferralAuth as ReferralPage } from '@pages/Referral';
import SignatureSignerPage from '@pages/Signatures/Signer';
import { AccountEmailChangePage, AccountPage, CheckoutPage } from '@pages/account';
import { ImportPage } from '@pages/account/import';
import { AnnouncementsPage } from '@pages/announcements';
import { DashboardLabelsPage } from '@pages/dashboard/labels';
import { DashboardPaymentsPage } from '@pages/dashboard/payments';
import { DashboardSecureSpacesPage } from '@pages/dashboard/secure-spaces';
import { DashboardSignaturesPage } from '@pages/dashboard/signatures';
import SecureGatewayPage from '@pages/gateway';
import { JoinOrganizationPage } from '@pages/join';
import { LoginPage } from '@pages/login';
import ForgotPasswordPage from '@pages/login/forgot';
import MultiFactorCodePage from '@pages/login/multi-factor-auth-code';
import { NotificationsPage } from '@pages/notifications';
import { OnboardingPage } from '@pages/onboarding';
import { NewPasswordPage } from '@pages/password/new';
import { ResetPasswordPage } from '@pages/password/reset';
import { PrintPage } from '@pages/print';
import { ReleasesPage } from '@pages/releases';
import { SignUpPage } from '@pages/signup';
import { TermsPage } from '@pages/terms';
import { TrialExpiredPage } from '@pages/trial-expired';
import { UnderMaintenancePage } from '@pages/under-maintenance';
import { ViewPage } from '@pages/view';
import ApiClientProvider from '@providers/ApiClientProvider';
import { ErrorBoundary } from '@sentry/react';
import Container from '@ui/Layout/Container';
import { type FC, useContext, useLayoutEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useIntl } from 'react-intl';
import { Redirect, Route, type RouteProps, BrowserRouter as Router, Switch, useLocation, useRouteMatch } from 'react-router-dom';

const AuthenticatedRoute: FC<RouteProps> = ({ children, ...routeProps }) => {
	const { authToken } = useClient();
	const { account } = useAccount();
	const location = useLocation();

	const matchesOnboarding = useRouteMatch('/onboarding');

	const conditionnallyRenderRoute = () => {
		if (account === null || authToken === null) {
			let next = encodeURIComponent(`${location.pathname}${location.search}${location.hash}`);
			return <Redirect to={{ pathname: '/login', search: `?next=${next}`, state: { next: location } }} />;
		}

		if (!account.hasVerifiedEmail() && matchesOnboarding === null && account.hasFullAccess()) {
			return <Redirect to="/onboarding" />;
		}

		return children;
	};

	return <Route {...routeProps} render={conditionnallyRenderRoute} />;
};

const GuestRoute: FC<RouteProps> = ({ children, ...routeProps }) => {
	const { account } = useAccount();
	const { authToken } = useClient();
	const { state } = useLocation<{ next?: Location }>();

	const conditionnallyRenderRoute = () => {
		if (account !== null && authToken !== null) {
			return <Redirect to={state?.next || '/'} />;
		}

		return children;
	};

	return <Route {...routeProps} render={conditionnallyRenderRoute} />;
};

const App = () => {
	const { formatMessage, locale } = useIntl();
	const { pageTitle, name } = useContext(AppContext);

	useLayoutEffect(() => {
		window.document.title = formatMessage({ id: 'page-title' }, { title: pageTitle, app: name });
	}, [formatMessage, name, pageTitle]);

	return (
		<Router>
			<ApiClientProvider>
				<ErrorBoundary
					fallback={
						<Container layout="nothing">
							<GenericErrorPage />
						</Container>
					}>
					<GuidedTourProvider>
						<EnvironmentSwitcher />
						<NotificationsListener />
						<Switch>
							<GuestRoute exact path="/password/reset/:token">
								<ResetPasswordPage />
							</GuestRoute>
							<GuestRoute path="/register/:plan?">
								<Redirect to="/signup" />
							</GuestRoute>
							<GuestRoute path="/signup">
								<GoogleReCaptchaProvider language={locale} reCaptchaKey={import.meta.env.VITE_RECAPTCHA_SITEKEY!}>
									<SignUpPage />
								</GoogleReCaptchaProvider>
							</GuestRoute>
							<AuthenticatedRoute path="/join">
								<JoinOrganizationPage />
							</AuthenticatedRoute>
							<AuthenticatedRoute path="/account/email">
								<AccountEmailChangePage />
							</AuthenticatedRoute>
							<Route exact path="/dl/:url1/:url2/view">
								<Container theme="dark" layout="minimal">
									<PreviewPage />
								</Container>
							</Route>
							<AuthenticatedRoute exact path="/print">
								<Container layout="print">
									<PrintPage />
								</Container>
							</AuthenticatedRoute>
							<AuthenticatedRoute exact path="/vf/:folderParam1/:folderParam2/:tab?">
								<Container>
									<ViewPage />
								</Container>
							</AuthenticatedRoute>
							<AuthenticatedRoute exact path="/terms">
								<TermsPage />
							</AuthenticatedRoute>
							<AuthenticatedRoute path="/onboarding">
								<OnboardingPage />
							</AuthenticatedRoute>
							<AuthenticatedRoute exact path="/login/multi-factor-auth-code">
								<MultiFactorCodePage />
							</AuthenticatedRoute>
							<Route exact path="/dl/:fileParam1/:fileParam2/:tab?">
								<Container>
									<ViewPage />
								</Container>
							</Route>
							<AuthenticatedRoute exact path="/files">
								<Container>
									<ViewPage />
								</Container>
							</AuthenticatedRoute>
							<AuthenticatedRoute exact path="/dashboard/labels">
								<Container>
									<DashboardLabelsPage />
								</Container>
							</AuthenticatedRoute>
							<AuthenticatedRoute exact path="/sign/:signRequestId">
								<Container>
									<SignatureSignerPage />
								</Container>
							</AuthenticatedRoute>
							<AuthenticatedRoute path="/account/checkout/:step?">
								<Container layout="nothing">
									<CheckoutPage />
								</Container>
							</AuthenticatedRoute>
							<AuthenticatedRoute exact path="/account/upgrade">
								<Redirect to="/account/checkout" />
							</AuthenticatedRoute>
							<AuthenticatedRoute exact path="/account/import">
								<ImportContextProvider>
									<ImportPage />
								</ImportContextProvider>
							</AuthenticatedRoute>
							<AuthenticatedRoute path="/account">
								<Container>
									<AccountPage />
								</Container>
							</AuthenticatedRoute>
							<AuthenticatedRoute path="/organization">
								<Container>
									<AccountPage />
								</Container>
							</AuthenticatedRoute>
							<AuthenticatedRoute path="/dashboard/signatures">
								<Container>
									<DashboardSignaturesPage />
								</Container>
							</AuthenticatedRoute>
							<AuthenticatedRoute exact path="/dashboard/payments">
								<Container>
									<DashboardPaymentsPage />
								</Container>
							</AuthenticatedRoute>
							<AuthenticatedRoute exact path="/dashboard/secure-spaces">
								<Container>
									<DashboardSecureSpacesPage />
								</Container>
							</AuthenticatedRoute>
							<AuthenticatedRoute path="/alerts" exact>
								<Container>
									<NotificationsPage />
								</Container>
							</AuthenticatedRoute>
							<AuthenticatedRoute path="/notifications" exact>
								<Container>
									<NotificationsPage />
								</Container>
							</AuthenticatedRoute>
							<AuthenticatedRoute path="/releases" exact>
								<Container>
									<ReleasesPage />
								</Container>
							</AuthenticatedRoute>
							<AuthenticatedRoute path="/news" exact>
								<Container>
									<AnnouncementsPage />
								</Container>
							</AuthenticatedRoute>
							<AuthenticatedRoute path="/trial-expired" exact>
								<TrialExpiredPage />
							</AuthenticatedRoute>
							<GuestRoute exact path="/login/forgot">
								<ForgotPasswordPage />
							</GuestRoute>
							<Route exact path="/login">
								<LoginPage />
							</Route>
							<AuthenticatedRoute exact path="/password/new">
								<NewPasswordPage />
							</AuthenticatedRoute>
							<AuthenticatedRoute exact path="/">
								<Container>
									<HomePage />
								</Container>
							</AuthenticatedRoute>
							<Route exact path="/referral-landing/:id?">
								<Container layout="nothing">
									<ReferralLandingPage />
								</Container>
							</Route>
							<AuthenticatedRoute exact path="/referral">
								<Container layout="full">
									<ReferralPage />
								</Container>
							</AuthenticatedRoute>
							<Route exact path="/gateway/:secret">
								<SecureGatewayPage />
							</Route>
							<Route exact path="/403">
								<Container layout="nothing">
									<Error403Page />
								</Container>
							</Route>
							<Route exact path="/under-maintenance">
								<Container layout="nothing">
									<UnderMaintenancePage />
								</Container>
							</Route>
							<Route exact path="/too-many-requests">
								<Container layout="nothing">
									<TooManyRequestsPage />
								</Container>
							</Route>
							<Route>
								<Container layout="nothing">
									<Error404Page />
								</Container>
							</Route>
						</Switch>
					</GuidedTourProvider>
				</ErrorBoundary>
			</ApiClientProvider>
		</Router>
	);
};

export default App;
