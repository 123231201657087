import {
	DialogCopyFileables,
	DialogCreateFolder,
	DialogDeleteFileables,
	DialogDownloadFileables,
	DialogMoveFileables,
	DialogShareSecureSpace,
	type FileManagerSaveSettingsMutation,
	type SelectionType,
	useFileManager,
	useFileManagerPolicies
} from '@components/FileManager';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useView } from '@hooks/use-view';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { useDebounce } from '@hooks/useDebounce';
import Folder from '@models/Folder';
import { Permissions } from '@types';
import Dropdown, { DropdownGroup, DropdownItem } from '@ui/Dropdown';
import InsetButton from '@ui/InsetButton';
import { Tooltip } from '@ui/Tooltip';
import { filesize } from '@utils/StringUtils';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import type { WretchError, WretchResponse } from 'wretch';

type FileablesInfo = {
	files_count: number;
	total_size: number;
};

export const FileListToolbar = () => {
	const { client } = useClient();
	const { account } = useAccount();
	const { view, key } = useView();
	const queryClient = useQueryClient();
	const { selection, setSelection, selectAll, fileables, openUploadFiles } = useFileManager()!;
	const { locale } = useIntl();
	const { canCreateFolder, canUploadFiles } = useFileManagerPolicies(account!, view);

	const [lastSecureSpaceCreated, setLastSecureSpaceCreated] = useState<Folder>();
	const [filesCount, setFilesCount] = useState(0);
	const [totalFileSize, setTotalFileSize] = useState(0);
	const [dialogCreateFolderOpened, setDialogCreateFolderOpened] = useState(false);
	const [dialogCreateSecureSpaceOpened, setDialogCreateSecureSpaceOpened] = useState(false);
	const [dialogMoveItemsOpened, setDialogMoveItemsOpened] = useState(false);
	const [dialogDeleteItemsOpened, setDialogDeleteItemsOpened] = useState(false);
	const [dialogCopyItemsOpened, setDialogCopyItemsOpened] = useState(false);
	const [dialogDownloadItemsOpened, setDialogDownloadItemsOpened] = useState(false);

	const { mutate: savePreferences } = useMutation<WretchResponse, WretchError, FileManagerSaveSettingsMutation>(
		async ({ folder, order }) => {
			if (folder !== null) {
				return await client.url(`folders/${folder.getKey()}/preferences`).json({ files_sort: order }).put().res();
			}
			return await client.url(`account/preferences`).json({ root_files_sort: order }).put().res();
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['files', key]);
			}
		}
	);

	const { mutate: getInfo, isLoading: isCalculating } = useMutation<FileablesInfo, WretchError, SelectionType>(
		async ({ fileables, parent } = {}) => {
			if (fileables) {
				return await client
					.url('files/info')
					.query({
						'files[]': fileables.filter(fileable => fileable['@type'] === 'Document').map(fileable => fileable.OriginalLink),
						'folders[]': fileables.filter(fileable => fileable['@type'] === 'Folder').map(fileable => fileable.OriginalLink)
					})
					.get()
					.json<FileablesInfo>();
			}

			if (parent) {
				return await client
					.url('files/info')
					.query({
						'folders[]': parent.OriginalLink
					})
					.get()
					.json<FileablesInfo>();
			}

			return { files_count: 0, total_size: 0 };
		},
		{
			onSuccess: ({ files_count, total_size }) => {
				setFilesCount(files_count);
				setTotalFileSize(total_size);
			}
		}
	);

	useHotkeys(
		'Esc',
		() => {
			setSelection(undefined);
		},
		{
			enabled: selection !== undefined
		}
	);

	const debouncedSelection = useDebounce(selection, 500);

	useEffect(() => {
		getInfo(debouncedSelection);
	}, [getInfo, debouncedSelection]);

	const selectionCount = useMemo(() => {
		if (selection?.fileables) {
			return selection.fileables.length;
		} else if (selection?.parent) {
			return selection.parent.files_count + selection.parent.folders_count;
		}
		return 0;
	}, [selection]);

	const nothingSelected = !Boolean(selection?.fileables?.length || selection?.parent);
	const areAllReadWrite = selection?.fileables?.every(fileable => fileable.pivot?.Permissions === Permissions.ReadWrite);
	const showSelectParent = fileables.length <= (selection?.fileables?.length ?? 0);

	//#region Policies
	const showUploadFiles = useMemo(() => {
		if (view === null) {
			return account!.hasFullAccess();
		}

		// If a regular folder/secure space and has readwrite
		if (view instanceof Folder && (view?.pivot?.Permissions === Permissions.ReadWrite || view.Access === 'public')) {
			return true;
		}

		return view instanceof Folder && view?.SecureSpace?.Access === 'public';
	}, [account, view]);

	const canCopy = useMemo(() => account!.hasFullAccess(), [account]);
	const canMove = useMemo(() => account!.hasFullAccess(), [account]);

	//#endregion

	return (
		<>
			<div className="flex flex-col gap-3 p-3 bg-gray-50">
				<div
					className={classNames({
						hidden: selection === undefined,
						flex: selection !== undefined
					})}>
					<div className="flex flex-col gap-1 text-sm">
						<h5 className="flex items-center gap-4">
							<span className="font-medium">
								<FormattedMessage
									id="file-selector.selection"
									values={{
										n: selectionCount,
										type: selection?.parent ? 'parent' : '',
										folder: selection?.parent?.getName() ?? '',
										strong: msg => <strong className="font-bold">{msg}</strong>
									}}
								/>
							</span>

							{view instanceof Folder && showSelectParent && (
								<button type="button" onClick={() => setSelection({ parent: view })} className="!inline text-theme-primary hover:underline">
									<FormattedMessage id="file-selector.selection_folder" values={{ folder: view.getName(), strong: msg => <strong className="font-bold">{msg}</strong> }} />
								</button>
							)}
							{selection?.fileables && !showSelectParent && (
								<button type="button" onClick={selectAll} className="text-theme-primary hover:underline">
									<FormattedMessage id="file-selector.select_all" />
								</button>
							)}
							{(selection?.fileables?.length ?? 0) > 0 && (
								<button type="button" onClick={() => setSelection({ fileables: [] })} className="text-theme-primary hover:underline">
									<FormattedMessage id="file-selector.unselect_all" />
								</button>
							)}
						</h5>
						{isCalculating ? (
							<p className="text-xs text-gray-400">
								<FontAwesomeIcon icon="spinner" pulse className="mr-1.5" />
								<FormattedMessage id="file-manager.calculating_total_size" />
							</p>
						) : (
							<p className="text-xs text-gray-400">
								<FormattedMessage id="file-manager.selection" values={{ n: filesCount, size: filesize(totalFileSize, locale) }} />
							</p>
						)}
					</div>
				</div>
				<div className="flex items-center justify-between gap-3">
					<div className="flex items-center gap-3">
						{showUploadFiles && (
							<Button size={Size.sm} intent={Intent.secondary} variant={Variant.light} onClick={openUploadFiles} type="button" iconStart="cloud-upload" disabled={!canUploadFiles}>
								<FormattedMessage id="file-manager.add_files" />
							</Button>
						)}

						{canCreateFolder && (
							<Button size={Size.sm} intent={Intent.secondary} variant={Variant.light} type="button" onClick={() => setDialogCreateSecureSpaceOpened(true)} iconStart="shield">
								<FormattedMessage id="file-manager.create_secure_space" />
							</Button>
						)}

						{canCreateFolder && (
							<Button size={Size.sm} intent={Intent.secondary} variant={Variant.light} type="button" onClick={() => setDialogCreateFolderOpened(true)} iconStart="folder">
								<FormattedMessage id="file-manager.create_folder" />
							</Button>
						)}
					</div>

					{selection !== undefined ? (
						<div className="flex items-center gap-3">
							<Tooltip tip={<FormattedMessage id="file-manager.download_items" values={{ n: selection.fileables?.length ?? 0 }} />}>
								<Button
									size={Size.sm}
									intent={Intent.secondary}
									variant={Variant.light}
									onClick={() => setDialogDownloadItemsOpened(true)}
									iconStart="cloud-download"
									circle
									disabled={nothingSelected}
								/>
							</Tooltip>
							<Tooltip tip={<FormattedMessage id="file-manager.move_items" values={{ n: selection.fileables?.length ?? 0 }} />}>
								<Button
									size={Size.sm}
									intent={Intent.secondary}
									variant={Variant.light}
									onClick={() => setDialogMoveItemsOpened(true)}
									disabled={!areAllReadWrite || nothingSelected || !canMove}
									iconStart="arrow-right"
									circle
								/>
							</Tooltip>
							<Tooltip tip={<FormattedMessage id="file-manager.copy_items" values={{ n: selection.fileables?.length ?? 0 }} />}>
								<Button
									size={Size.sm}
									intent={Intent.secondary}
									variant={Variant.light}
									onClick={() => setDialogCopyItemsOpened(true)}
									iconStart="copy"
									circle
									disabled={nothingSelected || !canCopy}
								/>
							</Tooltip>
							<Tooltip tip={<FormattedMessage id="file-manager.delete_items" values={{ n: selection.fileables?.length ?? 0 }} />}>
								<Button
									size={Size.sm}
									intent={Intent.secondary}
									variant={Variant.danger}
									onClick={() => setDialogDeleteItemsOpened(true)}
									disabled={!areAllReadWrite || nothingSelected}
									iconStart="trash"
									circle
								/>
							</Tooltip>
							<div className="mx-3 w-0.5 bg-black/10 h-4" />
							<Tooltip tip={<FormattedMessage id="file-manager.discard_selection" />}>
								<Button size={Size.sm} intent={Intent.secondary} variant={Variant.light} onClick={() => setSelection(undefined)} iconStart="times" circle />
							</Tooltip>
						</div>
					) : (
						<Dropdown placement="bottom-end">
							<InsetButton size={Size.sm}>
								<FontAwesomeIcon icon="sort" className="mr-2" />
								<FormattedMessage id="file-manager.sort" />
							</InsetButton>
							<DropdownGroup>
								<DropdownItem icon="sort-numeric-up" onClick={() => savePreferences({ folder: view instanceof Folder ? view : null, order: '!date' })}>
									<FormattedMessage id="file-manager.sort_newest" />
								</DropdownItem>
								<DropdownItem icon="sort-numeric-down" onClick={() => savePreferences({ folder: view instanceof Folder ? view : null, order: 'date' })}>
									<FormattedMessage id="file-manager.sort_oldest" />
								</DropdownItem>
								<DropdownItem icon="sort-alpha-down" onClick={() => savePreferences({ folder: view instanceof Folder ? view : null, order: 'name' })}>
									<FormattedMessage id="file-manager.sort_a_to_z" />
								</DropdownItem>
								<DropdownItem icon="sort-alpha-up" onClick={() => savePreferences({ folder: view instanceof Folder ? view : null, order: '!name' })}>
									<FormattedMessage id="file-manager.sort_z_to_a" />
								</DropdownItem>
							</DropdownGroup>
						</Dropdown>
					)}
				</div>
			</div>

			{dialogCreateFolderOpened && canCreateFolder && (
				<DialogCreateFolder
					onAfterClose={() => setDialogCreateFolderOpened(false)}
					parents={
						(selection?.fileables?.filter(fileable => fileable['@type'] === 'Folder') as Folder[]) ??
						(selection?.parent ? [selection.parent] : view instanceof Folder ? ([view] as Folder[]) : null)
					}
					onCreated={() => setSelection(undefined)}
				/>
			)}
			{dialogCreateSecureSpaceOpened && canCreateFolder && (
				<DialogCreateFolder
					onAfterClose={() => setDialogCreateSecureSpaceOpened(false)}
					parents={
						(selection?.fileables?.filter(fileable => fileable['@type'] === 'Folder') as Folder[]) ??
						(selection?.parent ? [selection.parent] : view instanceof Folder ? ([view] as Folder[]) : null)
					}
					secureSpace
					onCreated={folders => {
						setSelection(undefined);
						if (folders.length === 1) {
							setLastSecureSpaceCreated(folders[0]);
						}
					}}
				/>
			)}
			{lastSecureSpaceCreated && <DialogShareSecureSpace onAfterClose={() => setLastSecureSpaceCreated(undefined)} folder={lastSecureSpaceCreated} />}
			{dialogDownloadItemsOpened && (
				<DialogDownloadFileables
					onDownloadStart={() => setSelection(undefined)}
					onAfterClose={() => setDialogDownloadItemsOpened(false)}
					items={selection?.parent ? [selection.parent] : selection?.fileables ?? []}
				/>
			)}
			{dialogCopyItemsOpened && (
				<DialogCopyFileables
					onAfterClose={() => setDialogCopyItemsOpened(false)}
					items={selection?.parent ? [selection.parent] : selection?.fileables ?? []}
					onCopied={() => setSelection(undefined)}
				/>
			)}
			{dialogDeleteItemsOpened && (
				<DialogDeleteFileables
					onAfterClose={() => setDialogDeleteItemsOpened(false)}
					items={selection?.parent ? [selection.parent] : selection?.fileables ?? []}
					onDeleted={() => setSelection(undefined)}
				/>
			)}
			{dialogMoveItemsOpened && (
				<DialogMoveFileables
					onMoved={() => setSelection(undefined)}
					onAfterClose={() => setDialogMoveItemsOpened(false)}
					fileables={selection?.parent ? [selection.parent] : selection?.fileables ?? []}
				/>
			)}
		</>
	);
};
