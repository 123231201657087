import { FolderTemplatesManager, Title } from '@components/Account';
import AppContext from '@contexts/AppContext';
import { type FC, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const FolderTemplatesPage: FC = () => {
	const { setPageTitle: setTitle } = useContext(AppContext);
	const { formatMessage } = useIntl();

	useEffect(() => {
		setTitle(formatMessage({ id: 'folder-templates.title' }));
	}, [setTitle, formatMessage]);

	return (
		<article className="max-w-3xl">
			<Title>
				<FormattedMessage id="folder-templates.title" />
			</Title>
			<FolderTemplatesManager />
		</article>
	);
};
