import { FileSelector, type FileSelectorSelection } from '@components/FileSelector';
import { MessageFormContext } from '@components/Message/contexts';
import { PendingMessageFileAttachment } from '@components/Message/MessageFileAttachment';
import { UploadFile, UploadStatus, useUploadQueue } from '@components/Upload';
import { useDeferredPromise } from '@hooks/use-defered-promise';
import File from '@models/File';
import Folder from '@models/Folder';
import { forwardRef, type ForwardRefRenderFunction, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const emptyFiles: UploadFile[] = [];

export type FileAttachmentsRef = {
	reset: () => void;
	add: (files: UploadFile[]) => void;
	upload: () => Promise<File[]>;
	files: UploadFile[];
};

type FileAttachmentsProps = {
	folder?: Folder;
	folderSelection?: boolean;
	onFolderSelected?: (folder: Folder | undefined) => void;
	onFilesChanged?: (files: UploadFile[]) => void;
};

const FileAttachmentsRenderFunction: ForwardRefRenderFunction<FileAttachmentsRef, FileAttachmentsProps> = (
	{ folder, folderSelection = true, onFolderSelected = () => undefined, onFilesChanged = () => undefined },
	ref
) => {
	const { pluginData } = useContext(MessageFormContext);

	const [isUploading, setIsUploading] = useState(false);
	const [folderPickerOpened, setFolderPickerOpened] = useState(false);

	const { defer, deferRef } = useDeferredPromise<File[]>();
	const { fileable: initialFileable } = useContext(MessageFormContext);

	const { files, onFileUpdated, add, remove, clear, isFinished, fileable } = useUploadQueue(folder ?? initialFileable ?? null, emptyFiles, pluginData['audience']?.users);

	const onFolderPicked = (items: FileSelectorSelection) => {
		onFolderSelected(items[0] as Folder);
	};

	useImperativeHandle(ref, () => ({
		files,
		reset: () => {
			clear();
			onFolderSelected(undefined);
		},
		add: (files: UploadFile[]) => {
			add(files);
		},
		upload: () => {
			setIsUploading(true);
			return defer().promise;
		}
	}));

	useEffect(() => {
		if (!isFinished || !isUploading) {
			return;
		} else if (isFinished && files.length > 0) {
			setIsUploading(false);
			deferRef?.resolve(files.filter(file => file.status === UploadStatus.Completed).map(file => file.model!));
		} else if (isFinished && files.length === 0) {
			setIsUploading(false);
			deferRef?.resolve([]);
		}
	}, [isFinished, files, isUploading, deferRef]);

	useEffect(() => {
		onFilesChanged(files);
	}, [files]);

	if (files.length === 0) {
		return null;
	}

	return (
		<div className="px-4 mt-2">
			<header className="flex items-center gap-2 mb-2">
				<div className="w-2 h-px bg-gray-300" />
				<h5 className="text-sm font-medium text-gray-500 ">
					<FormattedMessage id="comments.attachments" values={{ n: files.length }} />
				</h5>
				<div className="flex-1 w-2 h-px bg-gray-300" />
			</header>
			{folderSelection && files.length > 0 && (
				<button className="inline-flex items-center gap-3 mb-3 text-xs text-gray-400" type="button" onClick={() => setFolderPickerOpened(true)}>
					<span className="px-2 py-1 italic text-gray-600 bg-gray-300 rounded">
						<FormattedMessage id="dialog-send.choose_subfolder" />
					</span>
					{files.length > 0 && folder && (
						<span className="text-xs text-gray-600">
							{folder.icon('mr-2')}
							{folder.getName()}
						</span>
					)}
					{folderPickerOpened && (
						<FileSelector
							onItemsSelected={onFolderPicked}
							onAfterClose={() => setFolderPickerOpened(false)}
							rootIsSelectable={false}
							selectedItems={folder ? [folder] : undefined}
							startingFolder={fileable instanceof Folder ? fileable : undefined}
							isItemDisabled={item => !(item instanceof Folder)}
						/>
					)}
				</button>
			)}
			<div className="flex flex-wrap">
				{files.map(file => (
					<PendingMessageFileAttachment key={file.id} fileable={fileable!} file={file} start={isUploading} onRemove={() => remove(file)} onFileUpdated={onFileUpdated} />
				))}
			</div>
		</div>
	);
};

export const FileAttachments = forwardRef(FileAttachmentsRenderFunction);
