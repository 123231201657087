import { LoginWithMicrosoftButton } from '@components/MicrosoftEntraId/LoginWithMicrosoftButton';
import AppContext from '@contexts/AppContext';
import { Alert, Badge, Button, Checkbox, HelperText, InputBlock, Label, PasswordInputBlock, Row, Size, ValidationField, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePasswordStrengthCheck } from '@hooks/use-password-strength-check';
import useClient from '@hooks/useClient';
import { useLocalStorage } from '@hooks/useLocalStorage';
import useUrlSearch from '@hooks/useUrlSearch';
import Folder from '@models/Folder';
import User from '@models/User';
import type { LoginComponentProps } from '@types';
import Blockquote from '@ui/Blockquote';
import Card from '@ui/Card';
import ProgressBar from '@ui/ProgressBar';
import { StepsCircle } from '@ui/Steps';
import UserAvatar from '@ui/UserAvatar';
import { type FC, type FormEvent, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import type { WretchError } from 'wretch';

type SecureSpaceLoginRequest = {
	password?: string;
	password_confirmation?: string;
	remember?: 0 | 1;
	answer?: string;
	eem?: string;
	email?: string;
	timezone?: string;
	first_name?: string;
	last_name?: string;
};

enum SecureSpaceLoginState {
	EnterEmail,
	EnterPassword,
	CreatePassword,
	EnterAnswer,
	EnterName
}

type SecureSpaceLoginMutation = {
	email?: string;
	password?: string;
	passwordConfirmation?: string;
	secretQuestionAnswer?: string;
	encryptedEmail?: string;
	firstName?: string;
	lastName?: string;
};

type SimpleAccount = Pick<User, 'ID' | 'Avatar' | 'Name' | 'Title'> & { UnreadNotifications: number };

type SecureSpaceLoginProps = LoginComponentProps & {
	secureSpace: Folder;
};

const SecureSpaceLogin: FC<SecureSpaceLoginProps> = ({ email: initialEmail = '', onSuccess = () => undefined, secureSpace }) => {
	// Utils
	const { formatMessage } = useIntl();
	const { eem = '' } = useUrlSearch(useUrlSearch().next);
	const { client, validation, setValidation } = useClient();
	const { setPageTitle: setTitle } = useContext(AppContext);
	const { locale } = useIntl();

	// States
	const [user, setUser] = useState<SimpleAccount>();
	const [email, setEmail] = useState(initialEmail);
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [state, setState] = useState(SecureSpaceLoginState.EnterEmail);
	const [question, setQuestion] = useState('');
	const [answer, setAnswer] = useState('');
	const [error, setError] = useState(null);
	const [remember, setRemember] = useLocalStorage('remember_auth', false);
	const [, setToursList] = useLocalStorage<number[]>('showTour');
	const [, setShowTours] = useLocalStorage<boolean>('tours.active', false);

	const passwordStrength = usePasswordStrengthCheck(password);

	const { mutate: login, isLoading: isLoggingIn } = useMutation<{ token: string }, WretchError, SecureSpaceLoginMutation>(
		async ({ email, password, passwordConfirmation, secretQuestionAnswer, encryptedEmail, firstName, lastName }) => {
			const data: SecureSpaceLoginRequest = {
				password,
				remember: remember ? 1 : 0,
				first_name: firstName,
				last_name: lastName,
				password_confirmation: passwordConfirmation,
				answer: secretQuestionAnswer,
				timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
			};

			if (encryptedEmail !== '') {
				data.eem = encryptedEmail;
			}

			if (email !== '') {
				data.email = email;
			}

			return await client
				.url(`folders/${secureSpace.URL}/auth`)
				.post(data)
				.error(422, payload => {
					const errors = payload.json.errors;

					setValidation(errors);

					if ([SecureSpaceLoginState.EnterEmail, SecureSpaceLoginState.EnterAnswer].includes(state) && 'password' in errors) {
						if (payload.json.data !== undefined) {
							setUser(payload.json.data.user as SimpleAccount);
						}
						setState('password_confirmation' in errors ? SecureSpaceLoginState.CreatePassword : SecureSpaceLoginState.EnterPassword);
					} else if ([SecureSpaceLoginState.EnterEmail].includes(state) && 'answer' in errors) {
						setState(SecureSpaceLoginState.EnterAnswer);
					} else if ('first_name' in errors || 'last_name' in errors) {
						setState(SecureSpaceLoginState.EnterName);
						setToursList([]);
						setShowTours(true);
					}

					if (payload.response.headers.has('X-TMD-Security-Question')) {
						setQuestion(payload.response.headers.get('X-TMD-Security-Question')!);
					}
					throw error;
				})
				.forbidden(async error => {
					if (state === SecureSpaceLoginState.EnterEmail) {
						setValidation(validation => ({ ...validation, email: [formatMessage({ id: 'folder-auth-form.forbidden' })] }));
					} else if (state === SecureSpaceLoginState.EnterPassword) {
						let errorMessage = formatMessage({ id: 'folder-auth-form.incorrect-password' });
						try {
							errorMessage = error.json.message;
						} catch {
							try {
								errorMessage = error.text ?? formatMessage({ id: 'folder-auth-form.incorrect-password' });
							} catch {}
						}

						setValidation(validation => ({ ...validation, password: [errorMessage] }));
					}
					throw error;
				})
				.unauthorized(error => {
					if (state === SecureSpaceLoginState.EnterAnswer) {
						setValidation(validation => ({
							...validation,
							answer: [formatMessage({ id: 'folder-auth-form.wrong_answer' }, { attempts: parseInt(error.response.headers.get('X-TMD-Security-Question-Attempts-Left')!) })]
						}));
					}
					throw error;
				})
				.json<{ token: string }>();
		},
		{
			onSuccess: ({ token }) => {
				onSuccess(token);
			}
		}
	);

	const onSubmit = (event: FormEvent) => {
		event.preventDefault();
		setError(null);
		login({ email, password, passwordConfirmation: confirmPassword, secretQuestionAnswer: answer, encryptedEmail: eem, firstName, lastName });
	};

	const isFormOk = useMemo(() => {
		let isOk = false;

		if (state === SecureSpaceLoginState.EnterEmail) {
			isOk = email !== '' || eem !== '';
		}

		if (state === SecureSpaceLoginState.EnterAnswer) {
			isOk = answer !== '' && (email !== '' || eem !== '');
		}

		if ([SecureSpaceLoginState.CreatePassword, SecureSpaceLoginState.EnterPassword].includes(state)) {
			isOk = password !== '' && (email !== '' || eem !== '');
		}

		if (state === SecureSpaceLoginState.CreatePassword) {
			isOk = confirmPassword !== '' && password !== '' && (email !== '' || eem !== '');
		}

		if (state === SecureSpaceLoginState.EnterName) {
			isOk = firstName !== '' && lastName !== '';
		}

		return isOk;
	}, [state, eem, email, password, confirmPassword, answer, firstName, lastName]);

	// Initial loading when there's a prefilled email
	useEffect(() => {
		if (eem !== '') {
			login({ encryptedEmail: eem });
		}
	}, [eem, login]);

	// When the state changes, remove any validation
	useEffect(() => {
		setValidation({});
	}, [state, setValidation]);

	useEffect(() => {
		setTitle(secureSpace.getName()!);
	}, [setTitle, secureSpace]);

	const steps = useMemo(() => {
		const labels = [<FormattedMessage id="folder-auth-form.email" />];
		let current = 1;

		switch (state) {
			case SecureSpaceLoginState.EnterEmail:
				labels.push(<FormattedMessage id="folder-auth-form.password" />);
				break;
			case SecureSpaceLoginState.EnterAnswer:
				labels.push(<FormattedMessage id="folder-auth-form.secret_question" />);
				labels.push(<FormattedMessage id="folder-auth-form.password" />);
				current = 2;
				break;
			case SecureSpaceLoginState.EnterPassword:
				if (answer) {
					labels.push(<FormattedMessage id="folder-auth-form.secret_question" />);
					current = 3;
				} else {
					current = 2;
				}
				labels.push(<FormattedMessage id="folder-auth-form.password" />);
				break;
			case SecureSpaceLoginState.CreatePassword:
				if (answer) {
					labels.push(<FormattedMessage id="folder-auth-form.secret_question" />);
					current = 3;
				} else {
					current = 2;
				}

				labels.push(<FormattedMessage id="folder-auth-form.password" />);
				labels.push(<FormattedMessage id="folder-auth-form.name" />);
				break;
			case SecureSpaceLoginState.EnterName:
				if (answer) {
					labels.push(<FormattedMessage id="folder-auth-form.secret_question" />);
					current = 4;
				} else {
					current = 3;
				}
				labels.push(<FormattedMessage id="folder-auth-form.password" />);
				labels.push(<FormattedMessage id="folder-auth-form.name" />);
				break;
		}

		return {
			labels,
			current
		};
	}, [answer, state]);

	const reset = () => {
		setUser(undefined);
		setState(SecureSpaceLoginState.EnterEmail);
		setEmail('');
	};

	return (
		<>
			<StepsCircle className="w-full mx-8 mb-8" current={steps.current} labels={steps.labels} />

			<div className="grid sm:grid-cols-12">
				<div className="flex flex-col col-span-5 p-6 my-4 bg-gray-800 rounded-l-md">
					<h1 className="text-2xl font-semibold text-white">{secureSpace.Name}</h1>
					<p className="flex-1 my-4 text-sm text-white text-opacity-75">
						{state === SecureSpaceLoginState.EnterEmail && <FormattedMessage id="folder-auth-form.intro-email" values={{ business: secureSpace.creator.business!.Name }} />}
						{state === SecureSpaceLoginState.CreatePassword && <FormattedMessage id="folder-auth-form.intro-create" values={{ business: secureSpace.creator.business!.Name }} />}
						{![SecureSpaceLoginState.EnterEmail, SecureSpaceLoginState.CreatePassword].includes(state) && (
							<FormattedMessage id="folder-auth-form.intro" values={{ business: secureSpace.creator.business!.Name }} />
						)}
					</p>
					<div className="flex items-center">
						<UserAvatar user={secureSpace.creator} className="mr-4" />
						<div className="flex-1">
							<p className="text-sm text-white text-opacity-50">
								<FormattedMessage
									id="folder-auth-form.secure_space_by"
									values={{
										name: <span className="font-semibold">{secureSpace.creator.Name}</span>,
										organization: <span className="font-semibold">{secureSpace.creator.business?.Name}</span>
									}}
								/>
							</p>
						</div>
					</div>
				</div>

				<Card className="col-span-7">
					{error !== null && (
						<Alert variant={Variant.danger}>
							<p>{error}</p>
						</Alert>
					)}

					<form onSubmit={onSubmit} className="flex flex-col h-full">
						<fieldset disabled={isLoggingIn} className="flex-1">
							{user !== undefined && (
								<div className="flex items-center justify-between px-6 py-4 mb-4 -mx-6 -mt-8 bg-blue-50">
									<div>
										<p className="mb-1 text-xs text-gray-500">
											<FormattedMessage id="folder-auth-form.logging_in_as" />
										</p>
										<h4 className="text-lg font-semibold leading-none text-gray-600">{user.Name}</h4>
										{[SecureSpaceLoginState.CreatePassword, SecureSpaceLoginState.EnterPassword].includes(state) && (
											<button className="flex items-center mt-2 text-xs text-theme-primary hover:underline" type="button" onClick={reset}>
												<FontAwesomeIcon icon="arrow-left" className="mr-1" size="sm" />
												<FormattedMessage id="folder-auth-form.change_user" />
											</button>
										)}
									</div>
									<div className="relative">
										{user.UnreadNotifications > 0 && (
											<Badge className="absolute top-0 border-2 border-white pointer-events-none animate-pingslow -right-4" inverse variant={Variant.danger}>
												{user.UnreadNotifications > 99 ? '99+' : <FormattedNumber value={user.UnreadNotifications} />}
											</Badge>
										)}
										<UserAvatar user={user} size={Size.sm} />
									</div>
								</div>
							)}
							{state === SecureSpaceLoginState.EnterEmail && (
								<>
									<Row>
										<Label htmlFor="email">
											<FormattedMessage id="login.email" />
										</Label>
										<ValidationField fieldName="email" validation={validation}>
											<InputBlock tabIndex={1} autoFocus id="email" type="email" value={email} onChange={e => setEmail(e.target.value)} icon="envelope" />
										</ValidationField>
									</Row>

									<Row className="flex items-center justify-between">
										<Button block tabIndex={2} type="submit" iconEnd="arrow-to-right" variant={Variant.success} disabled={!isFormOk || isLoggingIn} loading={isLoggingIn}>
											{isLoggingIn ? <FormattedMessage id="folder-auth-form.validating" /> : <FormattedMessage id="folder-auth-form.continue" />}
										</Button>
									</Row>
								</>
							)}

							{state === SecureSpaceLoginState.EnterAnswer && (
								<>
									<Row>
										<p className="text-sm">
											<FormattedMessage id="folder-auth-form.security_question_description" values={{ name: secureSpace.creator.Name }} />
										</p>
										<Blockquote quote={question} />
									</Row>

									<Row>
										<Label htmlFor="answer">
											<FormattedMessage id="folder-auth-form.answer" />
										</Label>
										<ValidationField fieldName="answer" validation={validation}>
											<InputBlock
												autoFocus
												placeholder={formatMessage({ id: 'folder-auth-form.enter_security_answer' })}
												tabIndex={1}
												id="email"
												type="password"
												value={answer}
												onChange={e => setAnswer(e.target.value)}
											/>
										</ValidationField>
									</Row>

									<Row className="flex items-center justify-between">
										<Button tabIndex={2} type="submit" variant={Variant.success} iconEnd="arrow-to-right" block disabled={!isFormOk || isLoggingIn} loading={isLoggingIn}>
											{isLoggingIn ? <FormattedMessage id="folder-auth-form.validating" /> : <FormattedMessage id="folder-auth-form.continue" />}
										</Button>
									</Row>
								</>
							)}

							{state === SecureSpaceLoginState.EnterName && (
								<>
									<Row>
										<Label htmlFor="answer">
											<FormattedMessage id="folder-auth-form.first_name" />
										</Label>
										<ValidationField fieldName="first_name" validation={validation}>
											<InputBlock
												autoFocus
												placeholder={formatMessage({ id: 'folder-auth-form.enter_first_name' })}
												tabIndex={1}
												type="text"
												value={firstName}
												onChange={e => setFirstName(e.target.value)}
											/>
										</ValidationField>
									</Row>

									<Row className={[SecureSpaceLoginState.EnterName].includes(state) ? 'block' : 'hidden'}>
										<Label htmlFor="answer">
											<FormattedMessage id="folder-auth-form.last_name" />
										</Label>
										<ValidationField fieldName="last_name" validation={validation}>
											<InputBlock
												placeholder={formatMessage({ id: 'folder-auth-form.enter_last_name' })}
												tabIndex={2}
												type="text"
												value={lastName}
												onChange={e => setLastName(e.target.value)}
											/>
										</ValidationField>
									</Row>

									<Row className="flex flex-col items-center justify-between">
										<Button tabIndex={3} type="submit" variant={Variant.success} iconEnd="unlock" block disabled={!isFormOk || isLoggingIn} loading={isLoggingIn}>
											{isLoggingIn ? <FormattedMessage id="folder-auth-form.unlocking" /> : <FormattedMessage id="folder-auth-form.unlock" />}
										</Button>
										<p className="mt-2 text-xs text-center text-gray-500">
											<FormattedMessage
												id="folder-auth-form.accept_terms"
												values={{
													a: msg => (
														<a
															className="text-theme-primary hover:underline"
															href={`${import.meta.env.VITE_SITE_URL}/${locale}/terms-of-use`}
															target="_blank"
															rel="noreferrer">
															{msg}
														</a>
													)
												}}
											/>
										</p>
									</Row>
								</>
							)}

							{state === SecureSpaceLoginState.CreatePassword && (
								<>
									<Row>
										<div className="flex items-center justify-between">
											<Label htmlFor="password">
												<FormattedMessage id="folder-auth-form.create_your_password" />
											</Label>
										</div>
										<ValidationField fieldName="password" validation={validation}>
											<PasswordInputBlock
												className="rounded-b-none"
												autoFocus
												tabIndex={1}
												id="password"
												value={password}
												onChange={e => setPassword(e.target.value)}
												icon="lock"
											/>
										</ValidationField>
										<ProgressBar max={100} current={passwordStrength ?? 0} reverseColors className="h-3 rounded-t-none" />
										<HelperText>
											<FormattedMessage id="folder-auth-form.password_rules" />
										</HelperText>
									</Row>
									<Row>
										<Label htmlFor="confirm-password">
											<FormattedMessage id="folder-auth-form.confirm_password" />
										</Label>
										<ValidationField fieldName="password_confirmation" validation={validation}>
											<InputBlock tabIndex={2} id="confirm-password" type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} icon="lock" />
										</ValidationField>
									</Row>
									<Row>
										<Checkbox checked={remember} onChange={e => setRemember(e.target.checked)}>
											<span className="text-sm">
												<FormattedMessage id="login.remember" />
											</span>
										</Checkbox>
									</Row>
									<Row className="flex items-center justify-between">
										<Button tabIndex={3} type="submit" variant={Variant.success} iconEnd="arrow-to-right" block disabled={!isFormOk || isLoggingIn} loading={isLoggingIn}>
											{isLoggingIn ? <FormattedMessage id="folder-auth-form.validating" /> : <FormattedMessage id="folder-auth-form.continue" />}
										</Button>
									</Row>
									<div className="flex flex-col items-center gap-3 pt-6 mt-6 -mx-6 border-t-2 border-gray-200">
										<p className="text-sm text-gray-500">
											<FormattedMessage id="importer.conflicts.or" />
										</p>
										<LoginWithMicrosoftButton />
									</div>
								</>
							)}

							{state === SecureSpaceLoginState.EnterPassword && (
								<>
									<Row>
										<div className="flex items-center justify-between">
											<Label htmlFor="password">
												<FormattedMessage id="folder-auth-form.password" />
											</Label>
											<Link to={`/login/forgot?email=${email}`} className="mb-2 text-sm text-theme-primary hover:underline">
												<FormattedMessage id="folder-auth-form.login_password_lost" />
											</Link>
										</div>
										<ValidationField fieldName="password" validation={validation}>
											<PasswordInputBlock autoFocus tabIndex={2} id="password" value={password} onChange={e => setPassword(e.target.value)} icon="lock" />
										</ValidationField>
									</Row>
									<Row>
										<Checkbox checked={remember} onChange={e => setRemember(e.target.checked)}>
											<span className="text-sm">
												<FormattedMessage id="login.remember" />
											</span>
										</Checkbox>
									</Row>
									<Row className="flex items-center justify-between">
										<Button tabIndex={3} type="submit" variant={Variant.success} iconEnd="unlock" block disabled={!isFormOk || isLoggingIn} loading={isLoggingIn}>
											{isLoggingIn ? <FormattedMessage id="folder-auth-form.unlocking" /> : <FormattedMessage id="folder-auth-form.unlock" />}
										</Button>
									</Row>
									<div className="flex flex-col items-center gap-3 pt-6 mt-6 -mx-6 border-t-2 border-gray-200">
										<p className="text-sm text-gray-500">
											<FormattedMessage id="importer.conflicts.or" />
										</p>
										<LoginWithMicrosoftButton />
									</div>
								</>
							)}
						</fieldset>
					</form>
				</Card>
			</div>
		</>
	);
};

export default SecureSpaceLogin;
