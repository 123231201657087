import type { AttachmentType } from '@components/Message';
import File from '@models/File';
import type { Fileable } from '@types';

export const createFileableComparator = (a: Fileable) => (b: Fileable) => fileableComparator(a, b);

export const fileableComparator = (a: Fileable, b: Fileable) => a['@type'] === b['@type'] && a.getKey() === b.getKey();

export const fileToAttachment = (file: File): AttachmentType => ({
	'@type': 'Attachment',
	Id: file.getKey() ?? 0,
	item: file
});
