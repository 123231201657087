import { UploadFile, UploadStatus } from '@components/Upload';
import Collaborator from '@models/Collaborator';
import type { Fileable } from '@types';
import { useCallback, useEffect, useState } from 'react';

const emptyFiles: UploadFile[] = [];
const emptyUsers: Collaborator[] = [];

const cleanFiles = (files: File[] | UploadFile[], fileable: Fileable | null) => files.map(file => (file instanceof UploadFile ? file : UploadFile.fromFile(file, fileable)));

export const useUploadQueue = (fileable: Fileable | null, initialFiles: File[] | UploadFile[] = emptyFiles, users: Collaborator[] = emptyUsers) => {
	const [files, setFiles] = useState<UploadFile[]>(cleanFiles(initialFiles, fileable));

	const onFileUpdated = useCallback((file: UploadFile) => {
		setFiles(files => files.map(f => (f.id === file.id ? file : f)));
	}, []);

	const add = useCallback((_files: UploadFile | UploadFile[]) => {
		setFiles(files => files.concat(_files));
	}, []);

	const remove = useCallback((file: UploadFile) => {
		setFiles(files => files.filter(f => f.id !== file.id));
	}, []);

	const clear = useCallback(() => {
		setFiles([]);
	}, []);

	const isFinished = files.every(f => [UploadStatus.Aborted, UploadStatus.Completed, UploadStatus.Error].includes(f.status));

	useEffect(() => {
		setFiles(files =>
			files.map(file => {
				file.scope = fileable;
				file.users = users;
				return file;
			})
		);
	}, [fileable, users]);

	return { files, isFinished, onFileUpdated, add, remove, clear, fileable };
};
