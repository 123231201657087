import { Subtitle, Title } from '@components/Account';
import { OrganizationBranding, OrganizationOverview } from '@components/Organization';
import AppContext from '@contexts/AppContext';
import { useAccount } from '@hooks/useAccount';
import { OrganizationProfile } from '@components/Organization';
import { type FC, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const OrganizationProfilePage: FC = () => {
	const { setPageTitle } = useContext(AppContext);
	const { formatMessage } = useIntl();
	const { account } = useAccount();

	useEffect(() => {
		setPageTitle(formatMessage({ id: 'organization.profile' }));
	}, [formatMessage, setPageTitle]);

	return (
		<div className="max-w-3xl">
			<Title>{account!.business.Name ?? ''}</Title>
			<article className="space-y-12">
				<section>
					<OrganizationOverview />
				</section>
				<section>
					<Subtitle id="#settings">
						<FormattedMessage id="organization.branding" />
					</Subtitle>
					<OrganizationBranding />
				</section>
				<section>
					<Subtitle id="#profile" className="!mb-0">
						<FormattedMessage id="organization.profile" />
					</Subtitle>
					<p className="mb-4 text-xs text-gray-400">
						<FormattedMessage id="organization.profile.subtitle" />
					</p>
					<OrganizationProfile />
				</section>
			</article>
		</div>
	);
};
