import FullScreenLoading from '@components/FullScreenLoading';
import { Button, Variant } from '@convoflo/ui';
import useClient from '@hooks/useClient';
import useUrlSearch from '@hooks/useUrlSearch';
import File from '@models/File';
import Folder from '@models/Folder';
import { versionMiddleware } from '@service/Client';
import type { SecureSpaceTermsType } from '@types';
import Card from '@ui/Card';
import { formatDate } from '@utils/DateUtils';
import { parseFileableUrl } from '@utils/StringUtils';
import { type FC, type FormEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

export const TermsPage: FC = () => {
	const { next = '' } = useUrlSearch();
	const history = useHistory();
	const { locale } = useIntl();
	const { client } = useClient();
	const extraction = parseFileableUrl(decodeURIComponent(next));

	const { data: terms } = useQuery(
		['secure_space', extraction?.param1, extraction?.param2, 'terms'],
		async () => {
			if (extraction?.isFolder) {
				return await client
					.middlewares([versionMiddleware(2)])
					.url(`folders/${extraction.param1}/${extraction.param2}/terms`)
					.get()
					.json<SecureSpaceTermsType>();
			} else {
				return await client
					.middlewares([versionMiddleware(2)])
					.url(`files/${extraction!.param1}/${extraction!.param2}/terms`)
					.get()
					.json<SecureSpaceTermsType>();
			}
		},
		{
			enabled: !!extraction?.param1 && !!extraction?.param2
		}
	);

	const { mutate: accept, isLoading: accepting } = useMutation(
		async () => {
			if (extraction?.isFolder) {
				return new Folder(
					await client
						.middlewares([versionMiddleware(2)])
						.url(`folders/${extraction!.param1}/${extraction!.param2}/terms`)
						.put()
						.json<Folder>()
				);
			} else {
				return new File(
					await client
						.middlewares([versionMiddleware(2)])
						.url(`files/${extraction!.param1}/${extraction!.param2}/terms`)
						.put()
						.json<File>()
				);
			}
		},
		{
			onSuccess: () => {
				// navigate to original folder
				history.replace(next);
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		accept();
	};

	if (!terms) {
		return <FullScreenLoading />;
	}

	return (
		<div className="flex h-screen bg-gray-200">
			<form onSubmit={onSubmit} className="container flex flex-col max-w-2xl px-6 py-8 mx-auto md:justify-center">
				<div className="mb-3 space-y-1">
					<h1 className="text-2xl font-bold">
						<FormattedMessage id="terms.terms_and_conditions_of_x" values={{ organization: terms.Organization.Name }} />
					</h1>
					<p className="text-sm text-gray-600">
						<FormattedMessage id="terms.before_continuing" values={{ organization: terms.Organization.Name }} />
					</p>
				</div>
				<Card className="!overflow-auto md:!max-h-1/2-screen md:!min-h-96">
					<div dangerouslySetInnerHTML={{ __html: terms?.Text }} />
					<p className="mt-6 text-xs italic text-left text-gray-400">
						<FormattedMessage id="terms.last_updated" values={{ name: terms.creator.Name, date: formatDate(terms?.created_at, locale) }} />
					</p>
					<p className="mt-3 text-xs">
						<FormattedMessage
							id="terms.footnote"
							values={{
								link1: msg => (
									<a href={`${import.meta.env.VITE_SITE_URL}/${locale}/privacy-policy`} rel="noreferrer" target="_blank" className="text-theme-primary hover:underline">
										{msg}
									</a>
								),
								link2: msg => (
									<a
										href={locale === 'fr' ? 'https://app.convoflo.com/dl/204MbE/mYvw' : 'https://www.convoflo.com/dl/1DMZ7g/mYvu'}
										rel="noreferrer"
										target="_blank"
										className="text-theme-primary hover:underline">
										{msg}
									</a>
								)
							}}
						/>
					</p>
				</Card>
				<div className="mt-6">
					<Button variant={Variant.primary} loading={accepting} disabled={accepting}>
						<FormattedMessage id="terms.i_accept" />
					</Button>
				</div>
			</form>
		</div>
	);
};
