import { DialogNewFileVersion } from '@components/FileManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEnvironment } from '@hooks/use-environment';
import { useAccount } from '@hooks/useAccount';
import { useLocalStorage } from '@hooks/useLocalStorage';
import useScript from '@hooks/useScript';
import File from '@models/File';
import { Permissions } from '@types';
import { type FC, useCallback, useLayoutEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Prompt } from 'react-router-dom';

type PDFViewerProps = {
	file: File;
	location: string;
};

const PDFViewer: FC<PDFViewerProps> = ({ file, location }) => {
	const { locale, formatMessage } = useIntl();
	const { account } = useAccount();
	const environment = useEnvironment();
	// const scriptStatus = useScript('https://documentcloud.adobe.com/view-sdk/main.js');
	// const scriptStatus = useScript('https://documentservices.adobe.com/view-sdk/viewer.js');
	const scriptStatus = useScript('https://acrobatservices.adobe.com/view-sdk/viewer.js');

	const [showFallbackOption, setShowFallbackOption] = useLocalStorage('pdf-viewer.show-fallback', true);
	const [newFile, setNewFile] = useState<globalThis.File | null>(null);
	const [modified, setModified] = useState(false);
	const [showFallback, setShowFallback] = useState(false);

	const fileName = file.getName();
	const permission = file.pivot?.Permissions;

	const readyListener = useCallback(() => {
		const adobeDCView = new (window as any).AdobeDC.View({
			// The reason we hard code the API key, is Adobe doesn't support an API key for multiple domain.
			// So instead of having a branch just for app.convoflo.com, we simply hard code it.
			clientId: environment?.Keys?.adobe_reader ?? import.meta.env.VITE_ADOBEDC_KEY,
			divId: 'adobe-dc-view',
			locale: locale === 'fr' ? 'fr-FR' : 'en-US'
		});

		// User profile callback if the user is logged in
		if (account?.Email) {
			adobeDCView.registerCallback((window as any).AdobeDC.View.Enum.CallbackType.GET_USER_PROFILE_API, async () => ({
				code: (window as any).AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
				data: {
					userProfile: {
						name: account.Name,
						firstName: account.FirstName,
						lastName: account.LastName,
						email: account.Email
					}
				}
			}));
		}

		// Save callback if the user can create new versions
		if ([Permissions.ReadWrite, Permissions.Editor].includes(permission || Permissions.None) || file.Editable) {
			adobeDCView.registerCallback((window as any).AdobeDC.View.Enum.CallbackType.SAVE_API, async (metaData: any, content: any, option: any) => {
				setNewFile(new globalThis.File([content], fileName));
				return {
					code: (window as any).AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
					data: {
						metaData: metaData
					}
				};
			});
		}

		adobeDCView.previewFile(
			{
				content: { location: { url: location } },
				metaData: { fileName }
			},
			{
				showDownloadPDF: file.isDownloadableBy(account),
				showPrintPDF: file.isDownloadableBy(account),
				showDisabledSaveButton: true,
				defaultViewMode: 'FIT_WIDTH',
				focusOnRendering: true
			}
		);
	}, [locale, location, fileName, permission, account?.Name, account?.FirstName, account?.LastName, account?.Email, file.Editable, environment?.Keys]);

	useLayoutEffect(() => {
		if (scriptStatus === 'ready') {
			if ((window as any).AdobeDC) {
				readyListener();
			} else {
				document.addEventListener('adobe_dc_view_sdk.ready', readyListener);
			}
		}

		return () => {
			document.removeEventListener('adobe_dc_view_sdk.ready', readyListener);
		};
	}, [readyListener, scriptStatus]);

	return (
		<>
			<Prompt when={modified} message={formatMessage({ id: 'unsaved_changes' })} />
			<div className="flex flex-col h-full">
				{showFallbackOption && !showFallback && (
					<div className="flex items-center px-4 py-2 text-sm text-center bg-yellow-50">
						<div className="flex items-center flex-1 gap-2">
							<FontAwesomeIcon icon="exclamation-triangle" className="mr-2 text-yellow-500" />
							<p className="text-xs text-yellow-700">
								<FormattedMessage
									id="pdf-viewer.fallback"
									values={{
										button: msg => (
											<button onClick={() => setShowFallback(true)} className="underline text-theme-primary">
												{msg}
											</button>
										)
									}}
								/>
							</p>
						</div>
						{/*<button onClick={() => setShowFallbackOption(false)}>
							<FontAwesomeIcon icon="times" fixedWidth className="text-yellow-700" />
								</button>*/}
					</div>
				)}
				{showFallback ? (
					<object data={location} type="application/pdf" width="100%" height="100%">
						<iframe title={file.getName()} src={location} width="100%" height="100%" style={{ border: 'none' }}>
							<p>
								Your browser does not support PDFs.
								<a href={location}>Download the PDF</a>
							</p>
						</iframe>
					</object>
				) : (
					<div className="flex flex-col h-full" id="adobe-dc-view" />
				)}
			</div>
			{newFile !== null && (
				<DialogNewFileVersion
					file={newFile}
					view={file}
					onAfterClose={() => {
						setNewFile(null);
					}}
				/>
			)}
		</>
	);
};

export default PDFViewer;
