import FlagCA from '@assets/flags/CA.svg?react';
import FlagUS from '@assets/flags/US.svg?react';
import { Role } from '@components/Team';
import Organization from '@models/Organization';
import User, { type IElevatedUser } from '@models/User';
import type { ModelType, Usage } from '@types';

class Account implements ModelType, IElevatedUser {
	'@type': 'User';
	ID!: number | null;
	Name!: string | null;
	Avatar!: string | null;
	FirstName!: string | null;
	LastName!: string | null;
	Title!: string | null;
	DataLocation!: string;
	Role!: Role;
	business: Organization;
	Usage!: Usage;
	EmailId!: string | null;
	ReferralId!: String;
	email_verified_at!: string | null;
	unread_notifications_external!: number;
	unread_notifications_internal!: number;
	Locale!: string;
	Country!: string;
	Email!: string;
	PhoneNumber!: string | null;
	PhoneExtension!: string | null;
	TwoFactorType!: 'app' | 'sms' | null;
	// Settings: {
	// 	TabsOrder: string[] | null;
	// };

	constructor(attributes: Record<string, any> = {}) {
		Object.assign(this, attributes);
		this.business = new Organization(attributes.business);
	}

	getKey() {
		return this.ID;
	}

	id() {
		return `user_${this.ID}`;
	}

	getFullName() {
		return `${this.FirstName} ${this.LastName}`;
	}

	hasName() {
		return this.FirstName !== null && this.LastName !== null;
	}

	hasVerifiedEmail() {
		return this.email_verified_at !== null;
	}

	isAdmin() {
		return this.Role === Role.admin;
	}

	hasFullAccess() {
		return this.business.plan !== null && this.Role !== Role.limited;
	}

	hasLimitedAccess() {
		return this.business.plan === null || this.Role === Role.limited;
	}

	hasModule(module: number) {
		return this.business.Modules % module === module;
	}

	storageIcon() {
		if (this.DataLocation === 'CA') {
			return <FlagCA className="h-3" />;
		}
		return <FlagUS className="h-3" />;
	}

	getTotalAlerts() {
		return this.unread_notifications_external + this.unread_notifications_internal;
	}

	toUser() {
		return new User({ Avatar: this.Avatar, Name: this.Name, ID: this.ID });
	}
}

export default Account;
