import { useLocation } from 'react-router-dom';

const useUrlSearch = (searchString: string | undefined = undefined) => {
	const { search } = useLocation();
	const data: Record<string, string> = {};

	if (!searchString) {
		searchString = search;
	}

	for (const [key, value] of new URLSearchParams(searchString)) {
		data[key] = value;
	}

	return data;
};

export const useUpdateUrlSearch = (searchParams: Record<string, string | null | undefined> = {}) => {
	const location = useLocation();
	const currentSearchParams = new URLSearchParams(location.search);

	for (const [key, value] of Object.entries(searchParams)) {
		if (!value) {
			currentSearchParams.delete(key);
		} else {
			currentSearchParams.set(key, value);
		}
	}

	const updater = (searchParams: Record<string, string | null | undefined> = {}) => {
		for (const [key, value] of Object.entries(searchParams)) {
			if (!value) {
				currentSearchParams.delete(key);
			} else {
				currentSearchParams.set(key, value);
			}
		}

		return currentSearchParams.toString();
	};

	return updater;
};

export default useUrlSearch;
