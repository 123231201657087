import { type SelectionType, useFileManager, useFileable } from '@components/FileManager';
import type { HasLabels, LabelType } from '@components/Labels';
import { type Comment, MessageListContext, replaceMessage, useMessage } from '@components/Message';
import type { ResourceLengthAwarePaginatorType } from '@components/Pagination';
import useClient from '@hooks/useClient';
import File from '@models/File';
import Folder from '@models/Folder';
import { ViewContext } from '@providers/ViewProvider';
import type { Fileable } from '@types';
import { useContext } from 'react';
import { type InfiniteData, type QueryKey, type UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import type { WretchError } from 'wretch';

export const useLabels = (label?: LabelType, queryOptions?: UseQueryOptions<Promise<LabelType[]>, WretchError, LabelType[], QueryKey>) => {
	const { client } = useClient();

	const { data: props, isLoading } = useQuery(
		['properties', label?.Id] as QueryKey,
		async () =>
			await client
				.url(label ? `organization/properties/${label.Id}/options?translate=1` : `organization/properties?translate=1`)
				.get()
				.json<LabelType[]>(),
		{
			staleTime: Infinity,
			...queryOptions
		}
	);

	return {
		props,
		isLoading
	};
};

type UseLabelsMutationsScope = {
	model?: HasLabels;
	selection?: SelectionType;
};

export const useLabelsMutations = (scope: UseLabelsMutationsScope) => {
	const { client } = useClient();
	const { queryKey: messagesQueryKey } = useContext(MessageListContext);
	const queryClient = useQueryClient();
	const { update: updateFileable } = useFileable() ?? {};
	const { update: updateMessage } = useMessage() ?? {};
	const { update: updateItem } = useFileManager() ?? {};
	const viewContext = useContext(ViewContext);

	const routeUrl = (label: LabelType) => {
		if (scope.selection?.fileables) {
			return `properties/${label.Id}/batch?${scope.selection.fileables.map(fileable => `id[]=${fileable.id()}`).join('&')}`;
		} else if (scope.selection?.parent) {
			return `properties/${label.Id}?parent=${scope.selection.parent.id()}`;
		} else if (scope.model?.['@type'] === 'Document') {
			return scope.model.getRoute(`properties/${label.Id}`, 'files');
		} else if (scope.model?.['@type'] === 'Folder') {
			return scope.model.getRoute(`properties/${label.Id}`);
		} else if (scope.model?.['@type'] === 'Comment') {
			return `comments/${(scope.model as Comment).ID}/properties/${label.Id}`;
		} else {
			throw new Error('Model must be an instance of Fileable or Comment');
		}
	};

	const onError = (_error: WretchError, _variables: LabelType, previousData: any) => {
		if (scope.model?.['@type'] !== 'Comment') {
			return;
		}
		queryClient.setQueryData(messagesQueryKey, previousData);
	};

	const onMutate =
		(remove = false) =>
		async (label: LabelType) => {
			if (scope.model?.['@type'] !== 'Comment') {
				return undefined;
			}

			await queryClient.cancelQueries(messagesQueryKey);

			const previousData = queryClient.getQueryData<InfiniteData<ResourceLengthAwarePaginatorType<Comment>>>(messagesQueryKey);

			if (previousData === undefined) {
				return undefined;
			}

			if (remove) {
				// Remove
				scope.model.properties = scope.model.properties.filter(prop => prop.Id !== label.Id);
			} else if (!label.property?.IsMulti && scope.model.properties.some(prop => prop.property?.Id === label.property?.Id)) {
				// Update when is a multi
				scope.model.properties = scope.model.properties.map(prop => (prop.property?.Id === label.property?.Id ? label : prop));
			} else {
				// Add
				scope.model.properties = scope.model.properties.concat([label]);
			}

			queryClient.setQueryData(messagesQueryKey, replaceMessage(scope.model as Comment, previousData));

			return previousData;
		};

	const onSuccess = (model: HasLabels) => {
		if (scope.selection?.fileables && viewContext) {
			queryClient.invalidateQueries(['files', viewContext.key]);
			queryClient.invalidateQueries(['view', viewContext.key]);
		}

		updateFileable?.(model as Fileable);
		updateMessage?.(model as Comment);
		updateItem?.(model as Fileable);
	};

	const { mutate: attach, isLoading: isAttaching } = useMutation<Fileable | Comment, WretchError, LabelType>(
		async label => {
			let _model = await client.url(routeUrl(label)).put().json<Fileable | Comment>();

			if (_model['@type'] === 'Document') {
				_model = new File(_model);
			}
			if (_model['@type'] === 'Folder') {
				_model = new Folder(_model);
			}

			return _model;
		},
		{
			onError,
			onMutate: onMutate(),
			onSuccess
		}
	);

	const { mutate: detach, isLoading: isDetaching } = useMutation<Fileable, WretchError, LabelType>(
		async label => {
			let _fileable = await client.url(routeUrl(label)).delete().json<Fileable>();

			if (_fileable['@type'] === 'Document') {
				_fileable = new File(_fileable);
			}
			if (_fileable['@type'] === 'Folder') {
				_fileable = new Folder(_fileable);
			}

			return _fileable;
		},
		{
			onError,
			onMutate: onMutate(true),
			onSuccess
		}
	);

	return {
		attach,
		detach,
		isAttaching,
		isDetaching
	};
};
