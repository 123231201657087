import { Button, HelperText, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, ModalSize, Row, Toggle, Variant } from '@convoflo/ui';
import { type FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import type { Fileable } from '@types';

export type MessageOptions = {
	markAsImportant: boolean;
	showPreviewInEmail: boolean;
	readNotification: boolean;
};

type DialogMessageOptionsProps = Omit<ModalProps, 'isOpen'> &
	Partial<MessageOptions> & {
		fileable: Fileable;
		isReply?: boolean;
		onChange?: (options: MessageOptions) => void;
	};

export const DialogMessageOptions: FC<DialogMessageOptionsProps> = ({
	fileable,
	isReply = false,
	markAsImportant: initialMarkAsImportant = false,
	readNotification: initialReadNotification = false,
	showPreviewInEmail: initialShowPreviewInEmail = false,
	onChange = () => undefined,
	...modalProps
}) => {
	const [isOpen, setIsOpen] = useState(true);
	const [markAsImportant, setMarkAsImportant] = useState(initialMarkAsImportant);
	const [readNotification, setReadNotification] = useState(initialReadNotification);
	const [showPreviewInEmail, setShowPreviewInEmail] = useState(initialShowPreviewInEmail);

	const onSubmit = () => {
		onChange({ markAsImportant, showPreviewInEmail, readNotification });
		setIsOpen(false);
	};

	return (
		<Modal size={ModalSize.Small} isOpen={isOpen} {...modalProps} closeable={true}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="comments.email_settings" />
			</ModalHeaderOnlyTitle>
			<ModalBody>
				<p className="mb-6 text-sm text-gray-500">
					<FormattedMessage id="comments.email_settings_intro" />
				</p>
				{initialMarkAsImportant !== undefined && (
					<Row>
						<Label>
							<FormattedMessage id="comments.mark_as_important" />
							<Toggle className="ml-4" checked={markAsImportant} onChange={e => setMarkAsImportant(e.target.checked)} />
						</Label>
						<HelperText>
							<FormattedMessage id="comments.mark_as_important_desc" />
						</HelperText>
					</Row>
				)}
				{initialShowPreviewInEmail !== undefined && (
					<Row>
						<Label>
							<FormattedMessage id="comments.show_preview" />
							<Toggle className="ml-4" checked={showPreviewInEmail} onChange={e => setShowPreviewInEmail(e.target.checked)} />
						</Label>
						<HelperText>
							<FormattedMessage id="comments.show_preview_desc" />
						</HelperText>
					</Row>
				)}

				<Row>
					<Label>
						<FormattedMessage id="comments.read_notification" />
						<Toggle className="ml-4" checked={readNotification} onChange={e => setReadNotification(e.target.checked)} />
					</Label>
					<HelperText>
						<FormattedMessage id="comments.read_notification_desc" />
					</HelperText>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button variant={Variant.primary} intent={Intent.primary} onClick={onSubmit} type="button">
					<FormattedMessage id="ok" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
