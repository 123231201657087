import { Title } from '@components/Account';
import { AppManager } from '@components/ConnectedApps';
import AppContext from '@contexts/AppContext';
import { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const AppsPage = () => {
	const { formatMessage } = useIntl();
	const { setPageTitle } = useContext(AppContext);

	useEffect(() => {
		setPageTitle(formatMessage({ id: 'apps.title' }));
	}, [formatMessage, setPageTitle]);

	return (
		<article className="max-w-3xl">
			<Title>
				<FormattedMessage id="apps.title" />
			</Title>
			<div className="mb-6">
				<p className="text-sm text-gray-700">
					<FormattedMessage id="apps.description" />
				</p>
			</div>
			<div className="space-y-12">
				<AppManager />
			</div>
		</article>
	);
};
