import { Subtitle } from '@components/Account';
import { type AppType, AppItem } from '@components/ConnectedApps';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { useAppsQuery } from '@state/queries/apps';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';

export const AppManager: FC = () => {
	const { data: apps = [] } = useAppsQuery({ connected: false });
	const { data: connectedApps = [] } = useAppsQuery({ connected: true });

	return (
		<>
			{connectedApps.length > 0 && (
				<section>
					<Subtitle>
						<FormattedMessage id="apps.connected_integrations" />
					</Subtitle>
					<div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
						{connectedApps.map(app => (
							<AppItem key={app.Id} app={app} />
						))}
					</div>
				</section>
			)}
			{apps.length > 0 && (
				<section>
					<Subtitle>
						<FormattedMessage id="apps.available_apps" />
					</Subtitle>
					<div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
						{apps.map(app => (
							<AppItem key={app.Id} app={app} />
						))}
					</div>
				</section>
			)}
		</>
	);
};
