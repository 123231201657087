import DialogGracePeriod from '@components/DialogGracePeriod';
import { useAccount } from '@hooks/useAccount';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

export const ResumeSubscriptionBanner = () => {
	const { account } = useAccount();

	const [showGracePeriodDialog, setShowGracePeriodDialog] = useState(false);

	if (account!.business.getGraceDaysLeft() <= 0 || !account!.isAdmin()) {
		return null;
	}

	return (
		<>
			<div className="bg-yellow-200">
				<p className="px-4 py-2 text-xs text-center text-yellow-700 md:text-base">
					<FormattedMessage
						id="banner.days_left_in_grace_period"
						values={{
							days: account!.business.getGraceDaysLeft() + 1,
							strong: msg => <span className="font-semibold">{msg}</span>,
							link: msg => (
								<button onClick={() => setShowGracePeriodDialog(true)} className="font-bold underline transition-colors focus:outline-none hover:text-yellow-600 focus:text-yellow-800">
									{msg}
								</button>
							)
						}}
					/>
				</p>
			</div>
			{showGracePeriodDialog && <DialogGracePeriod onAfterClose={() => setShowGracePeriodDialog(false)} />}
		</>
	);
};
