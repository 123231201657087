import ImageEsignDashboardEn from '@assets/images/esign-dashboard-preview-en.png';
import ImageEsignDashboardFr from '@assets/images/esign-dashboard-preview-fr.png';
import { type ModuleType, type StoreResponseType } from '@components/Checkout';
import { Alert, Button, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, Size, Variant, type ModalProps } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { useState, type FC } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { defaultCurrency } from '../../constants';
import toast from 'react-hot-toast';
import { MarkdownContent } from '@ui/MarkdownContent';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

export const SignRequestsPromo = () => {
	const { account } = useAccount();
	const { client } = useClient();
	const { locale, formatMessage } = useIntl();

	const { data: store } = useQuery(['store'], async () => await client.url('plans').get().json<StoreResponseType>(), {
		staleTime: Infinity
	});

	const [totalSignatures, setTotalSignatures] = useState(100);
	const [showRequestAccessDialog, setShowRequestAccessDialog] = useState(false);
	const [showTrialDialog, setShowTrialDialog] = useState(false);

	const {
		mutate: activateTrial,
		isLoading: isTrialActivating,
		isSuccess: isTrialActivated
	} = useMutation<void>(async () => await client.url('organization/modules/signatures/trial').post().res(), {
		onSuccess: () => {
			setShowTrialDialog(false);
			toast.success(<FormattedMessage id="sign-requests-crud.trial-activated" />);
			window.location.reload();
		},
		onError: () => {
			setShowTrialDialog(false);
			toast.error(<FormattedMessage id="sign-requests-crud.trial-activation-failed" />);
		}
	});

	const signaturePackages: Record<number, ModuleType> = store?.modules.esign || {};
	const monthlyPrice = signaturePackages[totalSignatures]?.amount ?? 0;

	return (
		<>
			<div className="container max-w-5xl px-4 py-8 md:px-8 md:py-16">
				<div className="mb-24 prose-sm prose lg:prose-base max-w-none prose-img:m-0">
					<h1 className="font-serif">
						<FormattedMessage id="sign-requests-crud.unavailable-subtitle" />
					</h1>

					<div className="flex flex-col gap-6 lg:flex-row ">
						<div className="order-2 lg:order-1">
							<p>
								<FormattedMessage id="sign-requests-crud.unavailable-intro_1" />
							</p>

							<p>
								<FormattedMessage id="sign-requests-crud.unavailable-intro_2" />
							</p>

							{account?.isAdmin() ? (
								<div className="flex gap-4 mt-4">
									<Button variant={Variant.primary} className="!rounded-full !px-5" onClick={() => setShowRequestAccessDialog(true)}>
										<FormattedMessage id="user-manager.upgrade-account" />
									</Button>
									<Button variant={Variant.secondary} className="!rounded-full !px-5" onClick={() => setShowTrialDialog(true)}>
										<FormattedMessage id="try_for_free" />
									</Button>
								</div>
							) : (
								<Alert variant={Variant.warning}>
									<FormattedMessage id="contact_admin_request_access_signatures" />
								</Alert>
							)}
						</div>
						<div className="order-1 mx-auto min-w-96 lg:order-2">
							<img src={locale === 'fr' ? ImageEsignDashboardFr : ImageEsignDashboardEn} className="overflow-hidden rounded-lg" alt="" />
						</div>
					</div>
				</div>

				<div className="grid items-center max-w-lg grid-cols-1 mx-auto mt-16 gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
					<div className="relative p-8 bg-theme-inverse rounded-3xl sm:p-10">
						<select
							style={{
								backgroundImage: `url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`,
								backgroundRepeat: 'no-repeat',
								backgroundPosition: `center right`
							}}
							value={totalSignatures}
							className="font-semibold bg-transparent border-0 rounded appearance-none text-theme-primary text-base/7 ring-0"
							onChange={e => setTotalSignatures(parseInt(e.currentTarget.value))}>
							{Object.entries(signaturePackages).map(([amount]) => (
								<FormattedMessage key={amount} id="n_signatures" values={{ n: amount }}>
									{msg => <option value={amount}>{msg}</option>}
								</FormattedMessage>
							))}
						</select>
						<p className="flex items-baseline mt-4 gap-x-2">
							<span className="text-5xl font-semibold tracking-tight text-white">
								<FormattedNumber value={monthlyPrice} currency={defaultCurrency} currencyDisplay="narrowSymbol" style="currency" />
							</span>
							<span className="text-base text-gray-400">
								<FormattedMessage id="payment-requests.promo.per_month" />
							</span>
						</p>
						<p className="mt-6 text-white text-base/7">
							<FormattedMessage id="signature_module_full_caption" />
						</p>
						<ul className="mt-8 space-y-3 text-white text-sm/6 sm:mt-10">
							<li className="flex gap-x-3">
								<svg className="flex-none w-5 h-6 text-theme-primary" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
									<path
										fill-rule="evenodd"
										d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
										clip-rule="evenodd"
									/>
								</svg>
								<FormattedMessage id="n_signatures" values={{ n: totalSignatures }} />
							</li>
							<li className="flex gap-x-3">
								<svg className="flex-none w-5 h-6 text-theme-primary" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
									<path
										fill-rule="evenodd"
										d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
										clip-rule="evenodd"
									/>
								</svg>
								<FormattedMessage id="no_watermark" />
							</li>
							<li className="flex gap-x-3">
								<svg className="flex-none w-5 h-6 text-theme-primary" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
									<path
										fill-rule="evenodd"
										d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
										clip-rule="evenodd"
									/>
								</svg>
								<FormattedMessage id="custom_layouts" />
							</li>
							<li className="flex gap-x-3">
								<svg className="flex-none w-5 h-6 text-theme-primary" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
									<path
										fill-rule="evenodd"
										d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
										clip-rule="evenodd"
									/>
								</svg>
								<FormattedMessage id="custom_templates" />
							</li>
							<li className="flex gap-x-3">
								<svg className="flex-none w-5 h-6 text-theme-primary" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
									<path
										fill-rule="evenodd"
										d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
										clip-rule="evenodd"
									/>
								</svg>
								<FormattedMessage id="proof_and_audit_trails" />
							</li>
							<li className="flex gap-x-3">
								<svg className="flex-none w-5 h-6 text-theme-primary" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
									<path
										fill-rule="evenodd"
										d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
										clip-rule="evenodd"
									/>
								</svg>
								<FormattedMessage id="accessible_to_all_devices" />
							</li>
						</ul>
						{account!.isAdmin() && (
							<div className="mt-8">
								<Button variant={Variant.primary} block intent={Intent.primary} onClick={() => setShowRequestAccessDialog(true)}>
									<FormattedMessage id="sync.dialog.book" />
								</Button>
							</div>
						)}
					</div>
					<div className="p-8 bg-white rounded-3xl rounded-t-3xl sm:mx-8 sm:rounded-t-none sm:p-10 lg:mx-0 lg:rounded-tr-3xl lg:rounded-bl-none">
						<h3 className="font-semibold text-theme-secondary text-base/7">
							<FormattedMessage id="trial" />
						</h3>
						<p className="flex items-baseline mt-4 gap-x-2">
							<span className="text-5xl font-semibold tracking-tight text-gray-900">
								<FormattedMessage id="free" />
							</span>
						</p>
						<p className="mt-6 text-gray-600 text-base/7">
							<FormattedMessage id="signature_module_trial_caption" />
						</p>
						<ul className="mt-8 space-y-3 text-gray-600 text-sm/6 sm:mt-10">
							<li className="flex gap-x-3">
								<svg className="flex-none w-5 h-6 text-theme-secondary" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
									<path
										fill-rule="evenodd"
										d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
										clip-rule="evenodd"
									/>
								</svg>
								<FormattedMessage id="n_signatures" values={{ n: 10 }} />
							</li>
							<li className="flex gap-x-3">
								<FontAwesomeIcon icon={faTimes} className="flex-none w-5 h-5 text-red-700" />
								<FormattedMessage id="no_watermark" />
							</li>
							<li className="flex gap-x-3">
								<svg className="flex-none w-5 h-6 text-theme-secondary" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
									<path
										fill-rule="evenodd"
										d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
										clip-rule="evenodd"
									/>
								</svg>
								<FormattedMessage id="accessible_to_all_devices" />
							</li>
						</ul>
						{account!.isAdmin() && (
							<div className="mt-8">
								<Button variant={Variant.secondary} block intent={Intent.primary} onClick={() => setShowTrialDialog(true)}>
									<FormattedMessage id="try_for_free" />
								</Button>
							</div>
						)}
					</div>
				</div>

				<div className="grid gap-6 p-6 my-12 bg-black/5 md:gap-12 md:p-12 rounded-xl sm:grid-cols-2">
					<div className="flex items-start gap-3">
						<FontAwesomeIcon icon="check-circle" className="translate-y-1 text-theme-primary" size="lg" />
						<div>
							<h5 className="mb-1 text-xl font-medium text-stone-700">
								<FormattedMessage id="sign-requests-crud.unavailable-list_1_title" />
							</h5>
							<p className="text-sm text-stone-500">
								<FormattedMessage id="sign-requests-crud.unavailable-list_1_description" />
							</p>
						</div>
					</div>
					<div className="flex items-start gap-3">
						<FontAwesomeIcon icon="check-circle" className="translate-y-1 text-theme-primary" size="lg" />
						<div>
							<h5 className="mb-1 text-xl font-medium text-stone-700">
								<FormattedMessage id="sign-requests-crud.unavailable-list_2_title" />
							</h5>
							<p className="text-sm text-stone-500">
								<FormattedMessage id="sign-requests-crud.unavailable-list_2_description" />
							</p>
						</div>
					</div>
					<div className="flex items-start gap-3">
						<FontAwesomeIcon icon="check-circle" className="translate-y-1 text-theme-primary" size="lg" />
						<div>
							<h5 className="mb-1 text-xl font-medium text-stone-700">
								<FormattedMessage id="sign-requests-crud.unavailable-list_3_title" />
							</h5>
							<p className="text-sm text-stone-500">
								<FormattedMessage id="sign-requests-crud.unavailable-list_3_description" />
							</p>
						</div>
					</div>
					<div className="flex items-start gap-3">
						<FontAwesomeIcon icon="check-circle" className="translate-y-1 text-theme-primary" size="lg" />
						<div>
							<h5 className="mb-1 text-xl font-medium text-stone-700">
								<FormattedMessage id="sign-requests-crud.unavailable-list_4_title" />
							</h5>
							<p className="text-sm text-stone-500">
								<FormattedMessage id="sign-requests-crud.unavailable-list_4_description" />
							</p>
						</div>
					</div>
				</div>

				<p className="text-center">
					<FormattedMessage
						id="more_information_signatures"
						values={{
							a: msg => (
								<a target="_blank" rel="noreferrer" href={`${import.meta.env.VITE_SITE_URL}/${locale}/product/features/signatures`} className="underline text-theme-primary">
									{msg}
								</a>
							)
						}}
					/>
				</p>
			</div>

			<DialogSignatureRequestAccess isOpen={showRequestAccessDialog} onAfterClose={() => setShowRequestAccessDialog(false)} />

			<Modal isOpen={showTrialDialog} onAfterClose={() => setShowTrialDialog(false)} closeable shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true}>
				<ModalHeaderOnlyTitle>
					<FormattedMessage id="signature_trial_title" />
				</ModalHeaderOnlyTitle>
				<ModalBody>
					<MarkdownContent size={Size.sm}>{formatMessage({ id: 'signatures.trial.description' })}</MarkdownContent>
					<Alert variant={Variant.warning} className="mt-8">
						<MarkdownContent size={Size.sm}>{formatMessage({ id: 'signatures.trial.warning' })}</MarkdownContent>
					</Alert>
				</ModalBody>
				<ModalFooter>
					{!isTrialActivated && (
						<Button loading={isTrialActivating} type="button" variant={Variant.secondary} onClick={() => activateTrial()}>
							<FormattedMessage id="sign-requests-crud.activate_trial" />
						</Button>
					)}
					<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setShowTrialDialog(false)} type="button">
						<FormattedMessage id="cancel" />
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export const DialogSignatureRequestAccess: FC<ModalProps> = ({ ...modalProps }) => {
	const { client } = useClient();

	const {
		mutate: request,
		isLoading: isRequestingAccess,
		isSuccess: isRequestedAccess
	} = useMutation<void>(async () => await client.url('events/signatures-module-request').post().res(), {
		onError: console.error
	});

	return (
		<Modal closeable shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} {...modalProps}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="sign-requests-crud.request_access" />
			</ModalHeaderOnlyTitle>
			<ModalBody className="prose">
				{!isRequestedAccess ? (
					<p>
						<FormattedMessage
							id="sign-requests-crud.unavailable-cta-admin"
							values={{
								link: msg => (
									<a href="tel:+18008977432" className="text-theme-primary hover:underline">
										{msg}
									</a>
								)
							}}
						/>
					</p>
				) : (
					<Alert variant={Variant.success}>
						<FormattedMessage id="sign-requests-crud.unavailable-cta-admin-cta-desc" />
					</Alert>
				)}
			</ModalBody>
			<ModalFooter>
				{!isRequestedAccess && (
					<Button loading={isRequestingAccess} type="button" variant={Variant.primary} onClick={() => request()}>
						<FormattedMessage id="sign-requests-crud.unavailable-cta-admin-cta" />
					</Button>
				)}
				<Button variant={Variant.light} intent={Intent.secondary} onClick={() => modalProps.onAfterClose?.()} type="button">
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
