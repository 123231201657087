import { AccountDelete, AppSettings, Subtitle, Title } from '@components/Account';
import { DataExport } from '@components/Account/DataExport';
import AppContext from '@contexts/AppContext';
import { useAccount } from '@hooks/useAccount';
import { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const AccountSettingsPage = () => {
	const { setPageTitle } = useContext(AppContext);
	const { formatMessage } = useIntl();
	const { account } = useAccount();

	useEffect(() => {
		setPageTitle(formatMessage({ id: 'account.settings' }));
	}, [formatMessage, setPageTitle]);

	return (
		<article className="max-w-3xl">
			<Title>
				<FormattedMessage id="account.settings" />
			</Title>
			<div className="space-y-12">
				<section>
					<Subtitle>
						<FormattedMessage id="app-settings.title" />
					</Subtitle>
					<AppSettings />
				</section>
				{account!.hasFullAccess() && (
					<section>
						<Subtitle>
							<FormattedMessage id="account-export.section_title" />
						</Subtitle>
						<DataExport scope="user" />
					</section>
				)}
				{account!.hasFullAccess() && (
					<section>
						<Subtitle className="!text-red-600">
							<FormattedMessage id="account.danger_zone" />
						</Subtitle>
						<AccountDelete />
					</section>
				)}
			</div>
		</article>
	);
};
