import untypedCanadianProvinces from '@assets/canada_provinces.json';
import untypedCountries from '@assets/countries.json';
import untypedUSStates from '@assets/us_states.json';
import type { BillingAddress } from '@components/Account';
import { Button, InputBlock, Intent, Label, Select, Size, ValidationField, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import Card from '@ui/Card';
import { type FormEvent, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { WretchError } from 'wretch';

const countries: Record<string, string> = untypedCountries;
const canadianProvinces: Record<string, string> = untypedCanadianProvinces;
const usStates: Record<string, string> = untypedUSStates;

type SaveBillingAddressMutation = {
	name: string;
	address: string;
	city: string;
	postalCode: string;
	country: string;
	region: string;
};

export const BillingAddressesManager = () => {
	const { account } = useAccount();
	const { locale } = useIntl();
	const { client, validation } = useClient();
	const queryClient = useQueryClient();

	const [showEdit, setShowEdit] = useState(false);
	const [name, setName] = useState('');
	const [address, setAddress] = useState('');
	const [city, setCity] = useState('');
	const [region, setRegion] = useState('');
	const [postalCode, setPostalCode] = useState('');
	const [country, setCountry] = useState('');

	const { data: billingAddress, isLoading } = useQuery(['billing-address'], async () => await client.url('org/billing/address').get().json<BillingAddress>(), {
		onSuccess: address => {
			setName(address.BillingName ?? '');
			setAddress(address.BillingAddress ?? '');
			setCity(address.BillingCity ?? '');
			setRegion(address.BillingRegion ?? '');
			setPostalCode(address.BillingZip ?? '');
			setCountry(address.BillingCountry ?? account!.Country ?? '');
		},
		staleTime: Infinity
	});

	const { mutate: save, isLoading: isSaving } = useMutation<BillingAddress, WretchError, SaveBillingAddressMutation>(
		async ({ name, address, city, country, postalCode, region }) =>
			await client.url('org/billing/address').json({ name, address, city, country, postal_code: postalCode, region }).put().json<BillingAddress>(),
		{
			onSuccess: address => {
				queryClient.setQueryData<BillingAddress>(['billing-address'], address);
				setShowEdit(false);
				toast.success(<FormattedMessage id="billing-address.address-saved" />);
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		save({ name, address, city, country, postalCode, region });
	};

	const hasBillingAddress = [
		billingAddress?.BillingAddress,
		billingAddress?.BillingCity,
		billingAddress?.BillingCountry,
		billingAddress?.BillingName,
		billingAddress?.BillingRegion,
		billingAddress?.BillingZip
	].every(value => !!value);

	useEffect(() => {
		if (showEdit) {
			setName(billingAddress?.BillingName ?? '');
			setAddress(billingAddress?.BillingAddress ?? '');
			setCity(billingAddress?.BillingCity ?? '');
			setRegion(billingAddress?.BillingRegion ?? '');
			setPostalCode(billingAddress?.BillingZip ?? '');
			setCountry(billingAddress?.BillingCountry ?? account?.Country ?? '');
		}
	}, [billingAddress, showEdit, account?.Country]);

	if (isLoading) {
		return (
			<Card>
				<p>
					<FontAwesomeIcon icon="spinner" pulse />
					<FormattedMessage id="billing-address.loading" />
				</p>
			</Card>
		);
	}

	if (showEdit || !hasBillingAddress) {
		return (
			<Card onSubmit={onSubmit}>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8">
					<div>
						<Label htmlFor="name-field">
							<FormattedMessage id="name" />
						</Label>
						<ValidationField fieldName="name" validation={validation}>
							<InputBlock onChange={e => setName(e.target.value)} value={name} id="name-field" type="text" required />
						</ValidationField>
					</div>

					<div>
						<Label htmlFor="address-field">
							<FormattedMessage id="billing-address.address" />
						</Label>
						<ValidationField fieldName="address" validation={validation}>
							<InputBlock onChange={e => setAddress(e.target.value)} value={address} id="address-field" type="text" required />
						</ValidationField>
					</div>

					<div>
						<Label htmlFor="city-field">
							<FormattedMessage id="billing-address.city" />
						</Label>
						<ValidationField fieldName="city" validation={validation}>
							<InputBlock onChange={e => setCity(e.target.value)} value={city} id="city-field" type="text" required />
						</ValidationField>
					</div>

					<div>
						<Label htmlFor="region-field">
							<FormattedMessage id="billing-address.region" values={{ country }} />
						</Label>
						{country === 'CA' && (
							<ValidationField fieldName="region" validation={validation}>
								<Select className="block w-full mt-1" id="region-field" value={region} onChange={e => setRegion(e.target.value)} required>
									{Object.keys(canadianProvinces).map(p => (
										<option key={p} value={p}>
											{canadianProvinces[p]}
										</option>
									))}
								</Select>
							</ValidationField>
						)}
						{country === 'US' && (
							<ValidationField fieldName="region" validation={validation}>
								<Select className="block w-full mt-1" id="region-field" value={region} onChange={e => setRegion(e.target.value)} required>
									{Object.keys(usStates).map(s => (
										<option key={s} value={s}>
											{usStates[s]}
										</option>
									))}
								</Select>
							</ValidationField>
						)}
						{country !== 'US' && country !== 'CA' && (
							<ValidationField fieldName="region" validation={validation}>
								<InputBlock onChange={e => setRegion(e.target.value)} value={region} id="region-field" type="text" required />
							</ValidationField>
						)}
					</div>
					<div>
						<Label htmlFor="postal_code-field">
							{country === 'US' && <FormattedMessage id="billing-address.zip" />}
							{country !== 'US' && <FormattedMessage id="billing-address.postal_code" />}
						</Label>
						<ValidationField fieldName="postal_code" validation={validation}>
							<InputBlock onChange={e => setPostalCode(e.target.value)} value={postalCode} id="postal_code-field" type="text" required />
						</ValidationField>
					</div>

					<div>
						<Label htmlFor="field-billing_adress-country">
							<FormattedMessage id="billing-address.country" />
						</Label>
						<ValidationField fieldName="country" validation={validation}>
							<Select className="w-full" id="field-billing_adress-country" value={country} onChange={e => setCountry(e.target.value)} required>
								{Object.keys(countries).map(c => (
									<option key={c} value={c}>
										{countries[c]}
									</option>
								))}
							</Select>
						</ValidationField>
					</div>
					<div className="flex items-center space-x-2 md:col-span-2">
						<Button type="submit" variant={Variant.primary} disabled={isSaving} loading={isSaving} icon="save">
							{isSaving ? <FormattedMessage id="saving" /> : <FormattedMessage id="save" />}
						</Button>
						<Button type="button" intent={Intent.tertiary} variant={Variant.light} onClick={() => setShowEdit(false)}>
							<FormattedMessage id="cancel" />
						</Button>
					</div>
				</div>
			</Card>
		);
	}

	const localizedCountryName = new Intl.DisplayNames([locale], { type: 'region' });

	return (
		<Card>
			<div className="flex items-start">
				<address className="flex-1 text-gray-500">
					<p className="font-medium text-gray-700">{billingAddress?.BillingName}</p>
					<p>{billingAddress?.BillingAddress}</p>
					<p>
						{billingAddress?.BillingCity} {billingAddress?.BillingRegion} {billingAddress?.BillingZip}
					</p>
					<p>{localizedCountryName.of(billingAddress!.BillingCountry)}</p>
				</address>
				<Button size={Size.sm} variant={Variant.light} intent={Intent.secondary} icon="edit" onClick={() => setShowEdit(true)}>
					<FormattedMessage id="change" />
				</Button>
			</div>
		</Card>
	);
};
