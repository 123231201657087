import { ComboBox } from '@components/ComboBox';
import { useFileManager } from '@components/FileManager';
import { FileSelector, type FileSelectorSelection } from '@components/FileSelector';
import { GuidedTour } from '@components/GuidedTour';
import { Button, HelperText, InputBlock, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Row, Size, Toggle, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useView } from '@hooks/use-view';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import Folder from '@models/Folder';
import { type CreateFolderMutation, useFolderCreateMutation } from '@state/queries/folders';
import type { FolderTemplate } from '@types';
import ProgressBar from '@ui/ProgressBar';
import { Tooltip } from '@ui/Tooltip';
import { type FC, type FormEvent, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

type DialogCreateFolderProps = Omit<ModalProps, 'isOpen'> & {
	parents: Folder[] | null;
	secureSpace?: boolean;
	onCreated?: (folders: Folder[]) => void;
};

export const DialogCreateFolder: FC<DialogCreateFolderProps> = ({ parents, secureSpace = false, onCreated = () => undefined, ...modalProps }) => {
	const { formatMessage, formatList } = useIntl();
	const { account } = useAccount();
	const { client } = useClient();
	const { key, view } = useView();
	const { add = () => undefined } = useFileManager() ?? {};
	const queryClient = useQueryClient();

	const [isOpen, setIsOpen] = useState(true);
	const [name, setName] = useState('');
	const [allowUploads, setAllowUploads] = useState(true);
	const [copyPermissions, setCopyPermissions] = useState(true);
	const [usingPrefill, setUsingPrefill] = useState(false);
	const [prefillMode, setPrefillMode] = useState<'copy' | 'template' | null>(null);
	const [template, setTemplate] = useState<FolderTemplate | null>(null);
	const [sourceFolder, setSourceFolder] = useState<Folder | null>(null);

	const { data: templates = [] } = useQuery(['folder-templates'], async () => await client.url('account/folder_templates').get().json<FolderTemplate[]>());

	const { mutateAsync: create, isLoading: isCreating } = useFolderCreateMutation();

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();

		let data: CreateFolderMutation = {
			name,
			parents,
			secureSpace,
			permissionsSource: copyPermissions ? 'parent' : undefined,
			visibility: allowUploads ? 'public' : 'private'
		};

		if (prefillMode === 'template') {
			data.contentSource = 'template';
			data.contentSourceId = template!.Id;
		} else if (prefillMode === 'copy') {
			data.contentSource = 'folder';
			data.contentSourceId = sourceFolder?.ID!;
		}

		try {
			const folders = await create(data);
			toast.success(<FormattedMessage id="create-folder.created" values={{ secureSpace: secureSpace }} />);
			onCreated(folders);
			setIsOpen(false);

			// TODO: Move this into the useFolderCreateMutation.onSuccess hook
			if (view instanceof Folder && view.getKey() === parents?.[0]?.getKey()) {
				add?.(folders);
			} else if (parents === null && view === null) {
				add?.(folders) || queryClient.invalidateQueries(['files', key]);
			} else {
				queryClient.invalidateQueries(['files', key]);
				queryClient.invalidateQueries(['view', key]);
			}
		} catch (error) {
			// TODO: Show error message to user
			// TODO: Check if error is due quota exceeded
		}
	};

	const onItemsSelected = useCallback((items: FileSelectorSelection) => setSourceFolder(items.length === 0 ? null : (items[0] as Folder)), []);

	useEffect(() => {
		!usingPrefill && setPrefillMode(null);
	}, [usingPrefill]);

	return (
		<>
			<Modal isOpen={isOpen} onSubmit={onSubmit} {...modalProps}>
				<ModalHeaderOnlyTitle>
					<FormattedMessage id="create-folder.title" values={{ secureSpace: secureSpace }} />
					{parents !== null && (
						<p className="text-xs font-light text-gray-500">
							<FormattedMessage id="file-manager.in_list_folders" values={{ list: formatList(parents.map(folder => folder.getName())) }} />
						</p>
					)}
				</ModalHeaderOnlyTitle>
				<ModalBody disabled={isCreating}>
					<Row>
						<InputBlock
							icon={secureSpace ? 'shield' : 'folder'}
							iconColor={secureSpace ? 'text-green-500' : 'text-gray-400'}
							autoFocus
							autoComplete="password"
							value={name}
							type="text"
							id="step-name"
							onChange={e => setName(e.target.value)}
						/>
						{account !== null && (
							<div className="flex items-center mt-2">
								<div className="mr-2">{account.storageIcon()}</div>
								<p className="text-xs text-gray-500">
									<FormattedMessage id={`create-folder.storage_${account.DataLocation}`} />
									<Tooltip tip={<FormattedMessage id="create-folder.storage_help" />}>
										<span>
											<FontAwesomeIcon className="ml-2" icon={['far', 'info-circle']} />
										</span>
									</Tooltip>
								</p>
							</div>
						)}
					</Row>

					<Row className="flex items-center mt-5">
						<div className="flex-1">
							<Label htmlFor="prefill_contents" className="flex items-center">
								<FormattedMessage id="create-folder.prefill_contents" />
								<Toggle size={Size.sm} className="ml-4" checked={usingPrefill} onChange={e => setUsingPrefill(e.target.checked)} />
							</Label>
							<HelperText>
								<FormattedMessage id="create-folder.prefill_contents_desc" values={{ secureSpace: secureSpace }} />
							</HelperText>
						</div>
						<FontAwesomeIcon icon="sitemap" mask="circle" transform="shrink-10" size="3x" className="hidden ml-4 text-gray-800 sm:inline-block" />
					</Row>

					{usingPrefill && (
						<Row className="ml-6">
							<Label htmlFor="use_source_folder">
								<FormattedMessage id="create-folder.use_source_folder" />
							</Label>
							<label className="flex items-start">
								<input checked={prefillMode === 'copy'} type="radio" className="mt-1 mr-2 form-radio" id="use_source_folder" onChange={() => setPrefillMode('copy')} />
								<HelperText>
									<FormattedMessage id="create-folder.use_source_folder_desc" values={{ secureSpace: secureSpace }} />
								</HelperText>
							</label>
						</Row>
					)}

					{prefillMode === 'copy' && usingPrefill && (
						<Row className="ml-12">
							<FileSelector
								selectedItems={sourceFolder !== null ? [sourceFolder] : undefined}
								isItemDisabled={fileable => parents?.some(folder => fileable?.getKey() === folder?.getKey()) || fileable instanceof File}
								onItemsSelected={onItemsSelected}
								rootIsSelectable={false}
							/>
						</Row>
					)}

					{usingPrefill && (
						<Row className="ml-6">
							<Label htmlFor="use_template">
								<FormattedMessage id="create-folder.use_template" />
							</Label>
							<label className="flex items-start">
								<input checked={prefillMode === 'template'} type="radio" className="mt-1 mr-2 form-radio" id="use_template" onChange={() => setPrefillMode('template')} />
								<HelperText>
									<FormattedMessage
										id="create-folder.use_template_desc"
										values={{
											secureSpace: secureSpace,
											link: (
												<Link to="/organization/folder-templates" className="text-theme-primary hover:underline">
													<FormattedMessage id="create-folder.create_template" />
												</Link>
											)
										}}
									/>
								</HelperText>
							</label>
						</Row>
					)}

					{prefillMode === 'template' && usingPrefill && (
						<Row className="ml-12">
							<ComboBox
								isSearchable={false}
								placeholder={formatMessage({ id: 'create-folder.select' })}
								value={template}
								getOptionValue={template => String(template.Id)}
								getOptionLabel={template => template.Name}
								options={templates}
								onChange={template => setTemplate(template as FolderTemplate)}
							/>
						</Row>
					)}

					{secureSpace && (
						<Row className="flex items-center mt-5" id="step-read-write">
							<div className="flex-1">
								<Label htmlFor="upload_external" className="flex items-center">
									<FormattedMessage id="create-folder.upload_external" />
									<Toggle size={Size.sm} className="ml-4" checked={allowUploads} onChange={e => setAllowUploads(e.target.checked)} />
								</Label>
								<HelperText>
									<FormattedMessage id="create-folder.upload_external_desc" />
								</HelperText>
							</div>
							<FontAwesomeIcon icon="cloud-upload" mask="circle" transform="shrink-10" size="3x" className="hidden ml-4 text-gray-800 sm:inline-block" />
						</Row>
					)}

					{parents !== null && (
						<Row className="flex items-center mt-5">
							<div className="flex-1">
								<Label htmlFor="copy_permissions_from_parent" className="flex items-center">
									<FormattedMessage id="create-folder.copy_permissions" />
									<Toggle size={Size.sm} className="ml-4" checked={copyPermissions} onChange={e => setCopyPermissions(e.target.checked)} />
								</Label>
								<HelperText>
									<FormattedMessage id="create-folder.copy_permissions_desc" values={{ secure_space: secureSpace }} />
								</HelperText>
							</div>
							<FontAwesomeIcon icon="people-arrows" mask="circle" transform="shrink-10" size="3x" className="hidden ml-4 text-gray-800 sm:inline-block" />
						</Row>
					)}
				</ModalBody>

				<ModalFooter className="justify-between">
					<div className="flex items-center gap-3">
						<Button type="submit" variant={Variant.primary} intent={Intent.primary} loading={isCreating} disabled={name === ''} id="step-create-button">
							<FormattedMessage id="create" />
						</Button>
						<Button variant={Variant.light} intent={Intent.secondary} type="button" onClick={() => setIsOpen(false)}>
							<FormattedMessage id="cancel" />
						</Button>
					</div>
					{secureSpace && account !== null && (
						<div className="hidden sm:block">
							<p className="text-xs text-gray-600">
								<FormattedMessage id={account.Usage.SecureSpaces.limit === null ? 'create-folder.usage_unlimited' : 'create-folder.usage'} values={{ ...account.Usage.SecureSpaces }} />
								<Tooltip tip={<FormattedMessage id={account.Usage.SecureSpaces.limit === null ? 'create-folder.usage_help_unlimited' : 'create-folder.usage_help'} />}>
									<span>
										<FontAwesomeIcon className="ml-2" icon={['far', 'info-circle']} />
									</span>
								</Tooltip>
							</p>
							{account.Usage.SecureSpaces.limit !== null && (
								<ProgressBar className="mt-2" current={(account.Usage.SecureSpaces.used / account.Usage.SecureSpaces.limit) * 100} max={100} />
							)}
						</div>
					)}
				</ModalFooter>
			</Modal>
			<GuidedTour name="createEs" enabled={secureSpace && account?.hasFullAccess()} />
		</>
	);
};
