import { type Instruction, type ItemMode } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements, monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import type { DragLocationHistory } from '@atlaskit/pragmatic-drag-and-drop/types';
import { hasChildNodes, type TreeNodeType } from '@ui/Tree';
import classNames from 'classnames';
import { Fragment, memo, useCallback, useContext, useEffect, useRef, useState, type FC, type ReactNode } from 'react';
import { createRoot } from 'react-dom/client';
import { DependencyContext, TreeContext } from './TreeContext.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const indentPerLevel = 20;

function getParentLevelOfInstruction(instruction: Instruction): number {
	if (instruction.type === 'instruction-blocked') {
		return getParentLevelOfInstruction(instruction.desired);
	}
	if (instruction.type === 'reparent') {
		return instruction.desiredLevel - 1;
	}
	return instruction.currentLevel - 1;
}

function delay({ waitMs: timeMs, fn }: { waitMs: number; fn: () => void }): () => void {
	let timeoutId: number | null = window.setTimeout(() => {
		timeoutId = null;
		fn();
	}, timeMs);
	return function cancel() {
		if (timeoutId) {
			window.clearTimeout(timeoutId);
			timeoutId = null;
		}
	};
}

type TreeNodeProps = {
	item: TreeNodeType;
	mode: ItemMode;
	level: number;
	index: number;
	onNodeSelected?: (item: TreeNodeType) => void;
	render: (item: TreeNodeType, actions: { toggle: () => void }) => ReactNode;
	renderDragPreview: (item: TreeNodeType) => ReactNode;
};

export const TreeNode: FC<TreeNodeProps> = memo(({ item, mode, level, render = () => undefined, renderDragPreview = () => undefined, onNodeSelected = () => undefined, index }) => {
	const buttonRef = useRef<HTMLButtonElement>(null);

	const [state, setState] = useState<'idle' | 'dragging' | 'preview' | 'parent-of-instruction'>('idle');
	const [instruction, setInstruction] = useState<Instruction | null>(null);
	const cancelExpandRef = useRef<(() => void) | null>(null);

	const { dispatch, uniqueContextId, getPathToItem, registerTreeItem } = useContext(TreeContext);
	const { DropIndicator, attachInstruction, extractInstruction } = useContext(DependencyContext);
	const toggleOpen = useCallback(() => {
		dispatch({ type: 'toggle', itemId: item.id });
	}, [dispatch, item]);

	const actionMenuTriggerRef = useRef<HTMLButtonElement>(null);

	const cancelExpand = useCallback(() => {
		cancelExpandRef.current?.();
		cancelExpandRef.current = null;
	}, []);

	const clearParentOfInstructionState = useCallback(() => {
		setState(current => (current === 'parent-of-instruction' ? 'idle' : current));
	}, []);

	// When an item has an instruction applied
	// we are highlighting it's parent item for improved clarity
	const shouldHighlightParent = useCallback(
		(location: DragLocationHistory): boolean => {
			const target = location.current.dropTargets[0];

			if (!target) {
				return false;
			}

			const instruction = extractInstruction(target.data);

			if (!instruction) {
				return false;
			}

			const targetId = target.data.id;

			const path = getPathToItem(targetId);
			const parentLevel: number = getParentLevelOfInstruction(instruction);
			const parentId = path[parentLevel];
			return parentId === item.id;
		},
		[getPathToItem, extractInstruction, item]
	);

	useEffect(() => {
		return registerTreeItem({
			itemId: item.id,
			element: buttonRef.current!,
			actionMenuTrigger: actionMenuTriggerRef.current!
		});
	}, [item.id, registerTreeItem]);

	useEffect(() => {
		function updateIsParentOfInstruction({ location }: { location: DragLocationHistory }) {
			if (shouldHighlightParent(location)) {
				setState('parent-of-instruction');
				return;
			}
			clearParentOfInstructionState();
		}

		return combine(
			draggable({
				element: buttonRef.current!,
				getInitialData: () => ({
					id: item.id,
					type: 'tree-item',
					isOpenOnDragStart: item.isOpen,
					uniqueContextId
				}),
				onGenerateDragPreview: ({ nativeSetDragImage }) => {
					setCustomNativeDragPreview({
						getOffset: pointerOutsideOfPreview({ x: '16px', y: '8px' }),
						render: ({ container }) => {
							const root = createRoot(container);
							root.render(renderDragPreview(item));
							return () => root.unmount();
						},
						nativeSetDragImage
					});
				},
				onDragStart: ({ source }) => {
					setState('dragging');
					// collapse open items during a drag
					if (source.data.isOpenOnDragStart) {
						dispatch({ type: 'collapse', itemId: item.id });
					}
				},
				onDrop: ({ source }) => {
					setState('idle');
					if (source.data.isOpenOnDragStart) {
						dispatch({ type: 'expand', itemId: item.id });
					}
				}
			}),
			dropTargetForElements({
				element: buttonRef.current!,
				getData: ({ input, element }) => {
					const data = { id: item.id };

					return attachInstruction(data, {
						input,
						element,
						indentPerLevel,
						currentLevel: level,
						mode,
						block: []
					});
				},
				canDrop: ({ source }) => source.data.type === 'tree-item' && source.data.uniqueContextId === uniqueContextId,
				getIsSticky: () => true,
				onDrag: ({ self, source }) => {
					const instruction = extractInstruction(self.data);

					if (source.data.id !== item.id) {
						// expand after 500ms if still merging
						if (instruction?.type === 'make-child' && item.children.length && !item.isOpen && !cancelExpandRef.current) {
							cancelExpandRef.current = delay({
								waitMs: 500,
								fn: () => dispatch({ type: 'expand', itemId: item.id })
							});
						}
						if (instruction?.type !== 'make-child' && cancelExpandRef.current) {
							cancelExpand();
						}

						setInstruction(instruction);
						return;
					}
					if (instruction?.type === 'reparent') {
						setInstruction(instruction);
						return;
					}
					setInstruction(null);
				},
				onDragLeave: () => {
					cancelExpand();
					setInstruction(null);
				},
				onDrop: () => {
					cancelExpand();
					setInstruction(null);
				}
			}),
			monitorForElements({
				canMonitor: ({ source }) => source.data.uniqueContextId === uniqueContextId,
				onDragStart: updateIsParentOfInstruction,
				onDrag: updateIsParentOfInstruction,
				onDrop() {
					clearParentOfInstructionState();
				}
			})
		);
	}, [dispatch, item, mode, level, cancelExpand, uniqueContextId, extractInstruction, attachInstruction, getPathToItem, clearParentOfInstructionState, shouldHighlightParent, renderDragPreview]);

	useEffect(() => {
		return () => {
			cancelExpand();
		};
	}, [cancelExpand]);

	const aria = (() => {
		if (!item.children.length) {
			return undefined;
		}
		return {
			'aria-expanded': item.isOpen,
			'aria-controls': `tree-item-${item.id}--subtree`
		};
	})();

	return (
		<Fragment>
			<div
				className={classNames('flex items-stretch relative', {
					'rounded cursor-pointer relative': state === 'idle'
				})}
				style={{ paddingLeft: level * indentPerLevel }}>
				<button type="button" onClick={toggleOpen} className="grid w-6 min-h-full place-items-center">
					{hasChildNodes(item) && (
						<FontAwesomeIcon
							icon="chevron-right"
							size="xs"
							className={classNames('text-gray-400 transition-transform', {
								'rotate-90': item.isOpen
							})}
						/>
					)}
				</button>
				<button
					{...aria}
					className="relative flex-1 text-left text-current bg-transparent rounded"
					id={`tree-item-${item.id}`}
					onClick={() => onNodeSelected(item)}
					ref={buttonRef}
					type="button"
					data-index={index}
					data-level={level}>
					<span
						className={classNames('gap-1 inline-flex items-center bg-transparent rounded', {
							'opacity-40': state === 'dragging',
							'bg-transparent': state === 'parent-of-instruction'
						})}>
						{render(item, { toggle: toggleOpen })}
					</span>
				</button>
				{instruction && <DropIndicator instruction={instruction} />}
			</div>
			{item.children.length && item.isOpen ? (
				<div id={aria?.['aria-controls']}>
					{item.children.map((child, index, array) => {
						const childType: ItemMode = (() => {
							if (child.children.length && child.isOpen) {
								return 'expanded';
							}

							if (index === array.length - 1) {
								return 'last-in-group';
							}

							return 'standard';
						})();
						return (
							<TreeNode
								item={child}
								key={child.id}
								level={level + 1}
								mode={childType}
								render={render}
								renderDragPreview={renderDragPreview}
								index={index}
								onNodeSelected={onNodeSelected}
							/>
						);
					})}
				</div>
			) : null}
		</Fragment>
	);
});
