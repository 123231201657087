import { Title } from '@components/Account';
import { useAnnouncements } from '@components/Announcement';
import { DateTimeDisplay } from '@components/DateTime';
import AppContext from '@contexts/AppContext';
import type { Release } from '@types';
import Card from '@ui/Card';
import { MarkdownContent } from '@ui/MarkdownContent';
import { useContext, useEffect, type FC } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FormattedMessage, useIntl } from 'react-intl';

export const ReleasesPage = () => {
	const { announcements: releases = [], isLoading } = useAnnouncements('releases');
	const { formatMessage } = useIntl();
	const { setPageTitle: setTitle, setPageHeader } = useContext(AppContext);

	useEffect(() => {
		setTitle(formatMessage({ id: 'release-notes.title' }));
	}, [setTitle, formatMessage]);

	useEffect(() => {
		setPageHeader(
			<Title icon="bell">
				<FormattedMessage id="release-notes.title" />
			</Title>
		);
	}, [setPageHeader]);

	return (
		<Scrollbars autoHide>
			<div className="max-w-5xl px-4 py-8 mx-auto md:px-8">
				{isLoading && (
					<div className="">
						<FormattedMessage id="loading" />
					</div>
				)}

				{!isLoading && releases.length > 0 && (
					<div className="space-y-16">
						{releases.map(release => (
							<ReleaseItem key={release.Id} release={release} />
						))}
					</div>
				)}
			</div>
		</Scrollbars>
	);
};

type ReleaseItemProps = {
	release: Release;
};

const ReleaseItem: FC<ReleaseItemProps> = ({ release }) => (
	<Card id={`a${release.Id}`}>
		<div className="flex items-center justify-between mb-8">
			<h1 className="text-lg font-medium text-gray-400">
				<a href={`#a${release.Id}`} className="hover:underline">
					{release.Title}
				</a>
			</h1>
			<p className="text-xs text-gray-400">
				<DateTimeDisplay defaultFormat="relative" value={release.created_at} />
			</p>
		</div>
		<MarkdownContent>{release.Content}</MarkdownContent>
	</Card>
);
