import { Alert, Button, InputBlock, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, ModalSize, Row, Size, Toggle, Variant, type ModalProps } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import Folder from '@models/Folder';
import type { FolderTemplate } from '@types';
import EmptyState from '@ui/EmptyState';
import { removeNode, replaceNode, type TreeNodeType } from '@ui/Tree';
import { Tree } from '@ui/Tree/Tree';
import { ID } from '@utils/StringUtils';
import classNames from 'classnames';
import { useCallback, useEffect, useLayoutEffect, useRef, useState, type FC, type FormEvent } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Prompt } from 'react-router-dom';
import type { WretchResponse } from 'wretch';
import { WretchError } from 'wretch/resolver';

const isMobile = window.matchMedia('(poiner: coarse)').matches;

export const FolderTemplatesManager = () => {
	const { client } = useClient();

	const [selectedTemplate, setSelectedTemplate] = useState<FolderTemplate | null>(null);
	const [showAddForm, setShowAddForm] = useState(false);

	const { data: templates, isLoading } = useQuery(['folder-templates'], async () => await client.url('account/templates').get().json<FolderTemplate[]>());

	useEffect(() => {
		if (showAddForm) {
			setSelectedTemplate(null);
		}
	}, [showAddForm]);

	useEffect(() => {
		if (selectedTemplate !== null) {
			setShowAddForm(false);
		}
	}, [selectedTemplate]);

	return (
		<>
			<div className="flex justify-end mb-8" role="toolbar">
				<Button variant={Variant.primary} onClick={() => setShowAddForm(true)} icon="plus">
					<FormattedMessage id="folder-templates.create_template" />
				</Button>
			</div>

			{templates !== undefined && templates.length > 0 && (
				<div className="grid grid-cols-3 gap-3 ">
					{templates.map(template => (
						<FolderTemplateItem key={template.Id} template={template} />
					))}
				</div>
			)}

			{isLoading && (
				<div className="flex flex-col items-center justify-center text-center">
					<FontAwesomeIcon icon="spinner" pulse size="3x" className="mb-4 text-gray-600" />
					<p className="italic text-gray-700">
						<FormattedMessage id="loading" />
					</p>
				</div>
			)}

			{templates !== undefined && templates.length === 0 && (
				<EmptyState
					icon="sitemap"
					title={<FormattedMessage id="folder-templates.empty-title" />}
					description={<FormattedMessage id="folder-templates.empty-body" />}
					action={
						<Button variant={Variant.primary} shadow onClick={() => setShowAddForm(true)}>
							<FormattedMessage id="create" />
						</Button>
					}
				/>
			)}

			{showAddForm && <DialogFolderTemplate onAfterClose={() => setShowAddForm(false)} />}
		</>
	);
};

type FolderTemplateItemProps = {
	template: FolderTemplate;
};

const FolderTemplateItem: FC<FolderTemplateItemProps> = ({ template }) => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	const [showEditForm, setShowEditForm] = useState(false);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

	const { mutate: _delete, isLoading: isDeleting } = useMutation<WretchResponse, WretchError, FolderTemplate>(async template => await client.url(`account/templates/${template.Id}`).delete().res(), {
		onSuccess: template => {
			queryClient.invalidateQueries(['folder-templates']);
			toast.success(<FormattedMessage id="folder-templates.deleted" values={{ name: template.Name }} />);
			setShowDeleteConfirmation(false);
		}
	});

	return (
		<>
			<div className="relative overflow-hidden transition-all duration-300 bg-white border rounded-md hover:shadow-xl aspect-1 hover:border-transparent">
				<div className="absolute bottom-3 right-3 z-[2]">
					<Button variant={Variant.danger} intent={Intent.secondary} size={Size.sm} circle icon="trash" onClick={() => setShowDeleteConfirmation(true)} />
				</div>
				<button type="button" className="z-[1] flex-1 w-full overflow-hidden text-left pt-4 h-full flex flex-col" onClick={() => setShowEditForm(true)}>
					<div className="px-3 mb-3">
						<h3 className="text-sm font-bold leading-none">{template.Name}</h3>
					</div>
					<div className="relative flex-1 px-3">
						<div className="absolute inset-0 bg-gradient-to-b from-white/60 to-white z-[1]" />

						{template.children.map(child => (
							<FolderTemplateItemNode key={child.Id} template={child} />
						))}
					</div>
				</button>
			</div>

			<Modal isOpen={showDeleteConfirmation}>
				<ModalHeaderOnlyTitle>
					<FormattedMessage id="folder-templates.delete_confirmation_title" values={{ name: template.Name }} />
				</ModalHeaderOnlyTitle>
				<ModalBody>
					<p>
						<FormattedMessage id="folder-templates.delete_confirmation_body" values={{ name: template.Name, strong: msg => <strong className="font-bold">{msg}</strong> }} />
					</p>
				</ModalBody>
				<ModalFooter>
					<Button variant={Variant.danger} type="button" onClick={() => _delete(template)} loading={isDeleting}>
						<FormattedMessage id="delete" />
					</Button>
					<Button intent={Intent.secondary} type="button" onClick={() => setShowDeleteConfirmation(false)}>
						<FormattedMessage id="cancel" />
					</Button>
				</ModalFooter>
			</Modal>

			{showEditForm && <DialogFolderTemplate template={template} onAfterClose={() => setShowEditForm(false)} />}
		</>
	);
};

type FolderTemplateNodeProps = {
	template: FolderTemplate;
	level?: number;
};

const FolderTemplateItemNode: FC<FolderTemplateNodeProps> = ({ template, level = 0 }) => {
	return (
		<div className="my-1.5">
			<p className="w-full min-w-0 text-xs truncate" style={{ marginLeft: level * 20 }}>
				{new Folder({ SecuredSpace: template.SecuredSpace }).icon('mr-1')}
				{template.Name}
			</p>
			{(template.children ?? []).map(child => (
				<FolderTemplateItemNode key={child.Id} template={child} level={level + 1} />
			))}
		</div>
	);
};

type FolderTemplateNodeRequest = {
	Id?: string;
	Name: string;
	SecuredSpace: boolean;
	children: FolderTemplateNodeRequest[];
};

const fromResponse = (tree: FolderTemplate[]): FolderTemplateNode[] =>
	tree.map((node: FolderTemplate) => ({ ...node, isOpen: true, id: String(node.Id), children: fromResponse(node.children || []) }));

const toResponse = (tree: FolderTemplateNode[]): FolderTemplateNodeRequest[] =>
	tree.map(node => ({
		Name: node.Name,
		SecuredSpace: node.SecuredSpace,
		children: toResponse(node.children || []),
		...(!node.id.startsWith('_') && { Id: node.id })
	}));

type FolderTemplateNode = TreeNodeType & {
	Name: string;
	SecuredSpace: boolean;
};

type FolderTemplatePanelProps = Omit<ModalProps, 'isOpen'> & {
	template?: FolderTemplate | null;
	onClose?: () => void;
};

type CreateFolderTemplateMutation = {
	name: string;
	tree: FolderTemplateNode[];
};

type SaveFolderTemplateMutation = CreateFolderTemplateMutation & {
	template: FolderTemplate;
};

const DialogFolderTemplate: FC<FolderTemplatePanelProps> = ({ template = null, onClose = () => undefined, ...modalProps }) => {
	const { client } = useClient();
	const { formatMessage } = useIntl();
	const queryClient = useQueryClient();

	const [isOpen, setIsOpen] = useState(true);
	const [label, setLabel] = useState(template?.Name ?? '');
	const [tree, setTree] = useState<FolderTemplateNode[]>(fromResponse(template?.children || []));
	const [selectedNode, setSelectedNode] = useState<FolderTemplateNode | null>(null);

	const folderNameInput = useRef<HTMLInputElement>(null);

	const { mutate: create, isLoading: isCreating } = useMutation<FolderTemplate, WretchError, CreateFolderTemplateMutation>(
		async ({ name, tree }) =>
			await client
				.url('account/templates')
				.post({ name, children: JSON.stringify(toResponse(tree)) })
				.json<FolderTemplate>(),
		{
			onSuccess: template => {
				queryClient.invalidateQueries(['folder-templates']);
				toast.success(<FormattedMessage id="folder-templates.created" values={{ name: template.Name }} />);
				setIsOpen(false);
			}
		}
	);

	const { mutate: save, isLoading: isSaving } = useMutation<FolderTemplate, WretchError, SaveFolderTemplateMutation>(
		async ({ template, name, tree }) =>
			await client
				.url(`account/templates/${template.Id}`)
				.put({ name, children: JSON.stringify(toResponse(tree)) })
				.json<FolderTemplate>(),
		{
			onSuccess: template => {
				queryClient.invalidateQueries(['folder-templates']);
				toast.success(<FormattedMessage id="folder-templates.saved" values={{ name: template.Name }} />);
				setIsOpen(false);
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();

		if (template === null) {
			create({ name: label, tree });
		} else {
			save({ template, name: label, tree });
		}
	};

	const createNodeFromAttributes = (attributes: { SecuredSpace: boolean; Name: string }): FolderTemplateNode => ({ ...attributes, isOpen: true, children: [], id: ID() });

	const areAllNodesOk = useCallback((tree: FolderTemplateNode[]): boolean => tree.every(node => !!node.Name && areAllNodesOk(node.children || [])), []);

	const deleteNodeOnClick = () => {
		if (selectedNode !== null) {
			setTree(tree => removeNode(tree, selectedNode.id) as FolderTemplateNode[]);
		}
		setSelectedNode(null);
	};

	// When there's a selected node changed, update the tree
	useEffect(() => {
		if (selectedNode !== null) {
			setTree(tree => replaceNode(tree, selectedNode) as FolderTemplateNode[]);
		}
	}, [selectedNode]);

	useLayoutEffect(() => {
		if (selectedNode !== null && !selectedNode.Name && folderNameInput.current) {
			folderNameInput.current.focus();
		}
	}, [selectedNode]);

	return (
		<Modal size={ModalSize.XLarge} isOpen={isOpen} {...modalProps} onSubmit={onSubmit}>
			<Prompt when message={formatMessage({ id: 'unsaved_changes' })} />

			<ModalBody>
				{isMobile && (
					<Alert variant={Variant.warning} className="mb-3">
						<FormattedMessage id="folder-templates.warning_mobile" />
					</Alert>
				)}
				<Row>
					<Label htmlFor="name">
						<FormattedMessage id="folder-templates.label" />
					</Label>
					<InputBlock
						autoFocus={!template}
						placeholder={formatMessage({ id: 'folder-templates.title_placeholder' })}
						type="text"
						value={label}
						onChange={e => {
							e.persist();
							setLabel(e.target.value);
						}}
						id="name"
					/>
				</Row>

				<Row>
					<Label htmlFor="folders">
						<FormattedMessage id="folder-templates.folders" />
					</Label>
					<div className="grid grid-cols-3 gap-3">
						<div className="flex flex-col flex-1 col-span-2">
							<div className="flex-1 p-2 overflow-auto bg-gray-100 rounded-xl max-h-1/2-screen">
								{tree.length === 0 && (
									<div className="grid h-full place-items-center min-h-36">
										<p className="text-xs italic text-center text-gray-500">
											<FormattedMessage id="folder-templates.get_started" />
										</p>
									</div>
								)}
								<Tree
									data={tree}
									renderItem={(item: FolderTemplateNode) => {
										return (
											<div
												className={classNames('inline-flex gap-1 py-1.5 px-2 rounded', {
													'bg-black/10': item.id === selectedNode?.id
												})}>
												{new Folder({ SecuredSpace: item.SecuredSpace }).icon('text-sm')}
												<span className="text-xs">{item.Name}</span>
											</div>
										);
									}}
									renderItemDragPreview={(item: FolderTemplateNode) => {
										return (
											<div className="inline-flex gap-1 py-1.5">
												{new Folder({ SecuredSpace: item.SecuredSpace }).icon('text-sm')}
												<span className="text-xs">{item.Name}</span>
											</div>
										);
									}}
									onNodeSelected={item => setSelectedNode(item as FolderTemplateNode)}
									onUpdated={data => setTree(data as FolderTemplateNode[])}
								/>
							</div>
							<div className="flex items-center gap-3 p-3 mt-3 bg-gray-100 rounded-xl">
								<Button
									type="button"
									intent={Intent.primary}
									variant={Variant.success}
									size={Size.sm}
									onClick={() => {
										const node = createNodeFromAttributes({ SecuredSpace: true, Name: '' });
										setTree(tree => tree.concat([node]) as FolderTemplateNode[]);
										setSelectedNode(node);
									}}>
									<FontAwesomeIcon icon="shield" className="mr-1" />
									<FormattedMessage id="folder-templates.add_secure_space" />
								</Button>
								<Button
									type="button"
									intent={Intent.primary}
									variant={Variant.dark}
									size={Size.sm}
									onClick={() => {
										const node = createNodeFromAttributes({ SecuredSpace: false, Name: '' });
										setTree(tree => tree.concat([node]) as FolderTemplateNode[]);
										setSelectedNode(node);
									}}>
									<FontAwesomeIcon icon="folder" className="mr-1" />
									<FormattedMessage id="folder-templates.add_folder" />
								</Button>
							</div>
						</div>
						{!!selectedNode && (
							<div className="flex flex-col col-span-1 gap-6 p-4 bg-gray-100 rounded-xl">
								<div className="flex-1">
									<Row>
										<Label htmlFor="node-name">
											<FormattedMessage id="name" />
										</Label>
										<InputBlock
											placeholder={formatMessage({ id: 'contact-manager.name-placeholder' })}
											maxLength={64}
											size={Size.sm}
											ref={folderNameInput}
											autoFocus
											disabled={selectedNode === null}
											id="node-name"
											value={selectedNode?.Name || ''}
											onChange={e => {
												e.persist();
												setSelectedNode({ ...selectedNode, Name: e.target.value } as FolderTemplateNode);
											}}
										/>
									</Row>
									<Row>
										<Label htmlFor="node-secured-space">
											<FormattedMessage id="folder-templates.secure_space?" />
										</Label>
										<Toggle
											size={Size.sm}
											disabled={selectedNode === null}
											id="node-secured-space"
											checked={selectedNode?.SecuredSpace || false}
											onChange={e => setSelectedNode({ ...selectedNode, SecuredSpace: e.target.checked } as FolderTemplateNode)}
										/>
									</Row>
								</div>
								<Row>
									<Button variant={Variant.danger} intent={Intent.secondary} size={Size.sm} circle icon="trash" onClick={deleteNodeOnClick} />
								</Row>
							</div>
						)}
					</div>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button variant={Variant.success} disabled={!areAllNodesOk(tree) || tree.length === 0 || label === ''} loading={isSaving || isCreating} icon={template ? 'plus' : 'save'}>
					{template === null ? <FormattedMessage id="create" /> : <FormattedMessage id="save" />}
				</Button>
				<Button type="button" disabled={isSaving || isCreating} onClick={() => setIsOpen(false)}>
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
