import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

export const usePasswordStrengthCheck = (password: string) => {
	const { locale } = useIntl();

	const { data: options } = useQuery(['password-strength-options', locale], async () => {
		const zxcvbnCommonPackage = await import('@zxcvbn-ts/language-common');
		const zxcvbnEnPackage = await import('@zxcvbn-ts/language-en');
		const zxcvbnFrPackage = await import('@zxcvbn-ts/language-fr');

		return {
			dictionary: {
				...zxcvbnCommonPackage.dictionary,
				...zxcvbnEnPackage.dictionary,
				...zxcvbnFrPackage.dictionary
			},
			graphs: zxcvbnCommonPackage.adjacencyGraphs,
			translations: locale === 'fr' ? zxcvbnFrPackage.translations : zxcvbnEnPackage.translations
		};
	});

	if (!options || !password) {
		return null;
	}

	zxcvbnOptions.setOptions(options);
	return zxcvbn(password).score * 25;
};
