import { useContext } from 'react';
import { CheckoutContext } from '@components/Checkout';

export const useCart = () => {
	const context = useContext(CheckoutContext);

	if (context === undefined) {
		throw new Error('useCart() must be used within a CheckoutContext');
	}

	return {
		cart: context.cart,
		setFrequency: context.setFrequency,
		getItemById: context.getItemById,
		addItem: context.addItem,
		addOrUpdateItem: context.addOrUpdateItem,
		updateItem: context.updateItem,
		removeItemById: context.removeItemById,
		toggleItem: context.toggleItem,
		applyPromoCode: context.applyPromoCode,
		isLoading: context.isCartLoading
	};
};

export default useCart;
