import { Title } from '@components/Account';
import { OrganizationLabels } from '@components/Organization';
import AppContext from '@contexts/AppContext';
import { type FC, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const OrganizationPropertiesPage: FC = () => {
	const { setPageTitle } = useContext(AppContext);
	const { formatMessage } = useIntl();

	useEffect(() => {
		setPageTitle(formatMessage({ id: 'organization.properties' }));
	}, [formatMessage, setPageTitle]);

	return (
		<div className="max-w-3xl">
			<Title>
				<FormattedMessage id="organization.properties" />
			</Title>
			<OrganizationLabels />
		</div>
	);
};
