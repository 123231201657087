import Echo from 'laravel-echo';
import Pusher, { type AuthorizerCallback, Channel } from 'pusher-js';
import { useQuery } from 'react-query';
import type { WretchError } from 'wretch';
import useClient from './useClient';
import type { ChannelAuthorizationData } from 'pusher-js/types/src/core/auth/options';

export const useEcho = () => {
	const { client, authToken } = useClient();

	const { data: echo } = useQuery(
		['echo', authToken],
		async () => {
			return new Echo({
				broadcaster: 'pusher',
				client: new Pusher(import.meta.env.VITE_PUSHER_KEY!, {
					cluster: 'mt1',
					authorizer: (channel: Channel) => ({
						authorize: async (socketId: string, callback: AuthorizerCallback) => {
							try {
								const response = await client
									.url('broadcasting/auth')
									.post({
										socket_id: socketId,
										channel_name: channel.name
									})
									.json<ChannelAuthorizationData>();
								callback(null, response);
							} catch (error) {
								callback(error as WretchError, {
									auth: ''
								});
							}
						}
					}),
					forceTLS: true
				})
			});
		},
		{
			staleTime: Infinity,
			enabled: !!authToken && !!import.meta.env.VITE_PUSHER_KEY
		}
	);

	return echo;
};
