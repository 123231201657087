import tutorialEn1 from '@assets/images/alerts-schedule-tutorial-1-en.png';
import tutorialFr1 from '@assets/images/alerts-schedule-tutorial-1-fr.png';
import tutorialEn2 from '@assets/images/alerts-schedule-tutorial-2-en.png';
import tutorialFr2 from '@assets/images/alerts-schedule-tutorial-2-fr.png';
import tutorialEn3 from '@assets/images/alerts-schedule-tutorial-3-en.png';
import tutorialFr3 from '@assets/images/alerts-schedule-tutorial-3-fr.png';
import { DialogNotificationScheduleManager, type UserNotificationOptions } from '@components/Notification';
import { Button, Intent, Modal, ModalBody, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import Card from '@ui/Card';
import { hoursKeyValue } from '@utils/DateUtils';
import { type FC, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';

type NotificationScheduleManagerProps = {
	onCollapse?: () => void;
};

export const NotificationScheduleManager: FC<NotificationScheduleManagerProps> = ({ onCollapse }) => {
	const { formatMessage, locale } = useIntl();
	const { client } = useClient();

	const [showScheduleManager, setShowScheduleManager] = useState(false);
	const [showCreateScheduleTutorial, setShowCreateScheduleTutorial] = useState(false);

	const { data } = useQuery(['notification_options'], async () => await client.url('account/notifications/options').get().json<UserNotificationOptions>(), {
		refetchOnMount: 'always',
		refetchOnWindowFocus: false
	});

	const formattedSchedules = useMemo(() => {
		const schedules = data?.schedules ?? [];
		if (schedules.length === 0) {
			return null;
		}

		const times = hoursKeyValue(locale);

		return schedules
			.map(schedule => {
				const timeKeyValuePair = times.find(time => time.key === schedule)!;

				if (timeKeyValuePair.key === '00:00:00') {
					timeKeyValuePair.value = formatMessage({ id: 'alert-options.midnight' });
				} else if (timeKeyValuePair.key === '12:00:00') {
					timeKeyValuePair.value = formatMessage({ id: 'alert-options.noon' });
				}

				return timeKeyValuePair.value;
			})
			.join(', ');
	}, [data?.schedules, formatMessage, locale]);

	return (
		<>
			<Card className="relative flex flex-col items-start">
				{onCollapse !== undefined && (
					<span className="absolute top-3 right-3">
						<Button circle icon="caret-up" variant={Variant.light} intent={Intent.primary} size={Size.sm} onClick={onCollapse} />
					</span>
				)}
				<div className="flex mb-4">
					<FontAwesomeIcon icon={['fad', 'newspaper']} size="2x" className="text-gray-400" />
				</div>
				<div className="flex-1 mb-4 prose-sm prose">
					<h3>
						<FormattedMessage id="alert-options.schedule" />
					</h3>
					<p>
						<FormattedMessage id="alert-options.schedule.description" />{' '}
						<button className="text-theme-primary hover:underline focus:outline-none" onClick={() => setShowCreateScheduleTutorial(true)}>
							<FormattedMessage id="learn-more" />
						</button>
					</p>
				</div>
				{formattedSchedules !== null && <p className="mb-3 text-sm text-gray-500">{formattedSchedules}</p>}
				<Button size={Size.sm} variant={Variant.light} intent={Intent.secondary} onClick={() => setShowScheduleManager(true)}>
					{(data?.schedules ?? []).length === 0 ? <FormattedMessage id="alert-options.create_schedule" /> : <FormattedMessage id="alert-options.manage_schedule" />}
				</Button>
			</Card>
			<Modal isOpen={showCreateScheduleTutorial} closeable={true} onAfterClose={() => setShowCreateScheduleTutorial(false)}>
				<ModalBody>
					<CreateScheduleTutorial />
				</ModalBody>
			</Modal>
			{showScheduleManager && <DialogNotificationScheduleManager onAfterClose={() => setShowScheduleManager(false)} />}
		</>
	);
};

const CreateScheduleTutorial = () => {
	const { locale } = useIntl();

	return (
		<div className="mt-6 prose max-w-none prose-h2:mb-1 prose-h3:mb-1 prose-img:my-0">
			<h2 className="text-center">
				<FormattedMessage id="alert-options.schedule.tutorial.title" />
			</h2>
			<div className="">
				<div className="flex flex-col items-center py-4 sm:items-start sm:flex-row">
					<div className="flex-1">
						<h4 className="flex flex-col items-center sm:flex-row">
							<span className="inline-flex items-center justify-center w-8 h-8 mb-3 align-middle border border-gray-600 rounded-full sm:mb-0 sm:mr-2 shrink-0">1</span>
							<span>
								<FormattedMessage id="alert-options.schedule.tutorial.step1-title" />
							</span>
						</h4>
						<p className="text-center sm:text-left">
							<FormattedMessage id="alert-options.schedule.tutorial.step1-text" />
						</p>
					</div>
					<img src={locale === 'fr' ? tutorialFr1 : tutorialEn1} className="rounded-full sm:ml-8 w-36 h-36" alt="" />
				</div>
				<div className="flex flex-col items-center py-4 sm:items-start sm:flex-row">
					<div className="flex-1">
						<h4 className="flex flex-col items-center sm:flex-row">
							<span className="inline-flex items-center justify-center w-8 h-8 mb-3 align-middle border border-gray-600 rounded-full sm:mb-0 sm:mr-2 shrink-0">2</span>
							<span>
								<FormattedMessage id="alert-options.schedule.tutorial.step2-title" />
							</span>
						</h4>
						<p className="text-center sm:text-left">
							<FormattedMessage id="alert-options.schedule.tutorial.step2-text" />
						</p>
					</div>
					<img src={locale === 'fr' ? tutorialFr2 : tutorialEn2} className="border border-gray-200 rounded-full sm:ml-8 w-36 h-36" alt="" />
				</div>
				<div className="flex flex-col items-center py-4 sm:items-start sm:flex-row">
					<div className="flex-1">
						<h4 className="flex flex-col items-center sm:flex-row">
							<span className="inline-flex items-center justify-center w-8 h-8 mb-3 align-middle border border-gray-600 rounded-full sm:mb-0 sm:mr-2 shrink-0">3</span>
							<span>
								<FormattedMessage id="alert-options.schedule.tutorial.step3-title" />
							</span>
						</h4>
						<p className="text-center sm:text-left">
							<FormattedMessage id="alert-options.schedule.tutorial.step3-text" />
						</p>
					</div>
					<img src={locale === 'fr' ? tutorialFr3 : tutorialEn3} className="border border-gray-200 rounded-full sm:ml-8 w-36 h-36" alt="" />
				</div>
			</div>
		</div>
	);
};
