/* eslint-disable react/style-prop-object */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import { useWidgets } from '@hooks/useWidgets';
import type { ProductivityReport } from '@types';
import { type FC, type ReactNode, useEffect } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import Widget from './Widget';

const STALETIME = 60000;

const ProductivityReportWidget: FC = () => {
	const { client } = useClient();
	const { onWidgetLoaded } = useWidgets();

	const { data, isLoading, isSuccess } = useQuery(['productivity'], async () => await client.url('account/productivity').get().json<ProductivityReport>(), {
		refetchOnWindowFocus: false,
		staleTime: STALETIME
	});

	useEffect(() => {
		if (isSuccess) {
			onWidgetLoaded();
		}
	}, [isSuccess, onWidgetLoaded]);

	return (
		<Widget icon={<FontAwesomeIcon icon="chart-line" className="mr-2 text-blue-600" mask="square" transform="shrink-8" size="2x" />} header={<FormattedMessage id="productivity-report.title" />}>
			{isLoading && (
				<div className="flex flex-col items-center justify-center p-12 bg-white bg-opacity-50 rounded">
					<h4 className="mt-4 font-semibold text-gray-600">
						<FontAwesomeIcon icon="spinner" spin className="mr-2" />
						<FormattedMessage id="loading" />
					</h4>
				</div>
			)}

			{!isLoading && data !== undefined && (
				<div className="grid space-y-4 divide-x divide-gray-200 rounded-md lg:grid-cols-3 lg:shadow lg:space-y-0">
					<ReportBlock
						internal={data.TotalInternalFiles}
						external={data.TotalExternalFiles}
						internalLabel={<FormattedMessage id="productivity-report.internal_sent" />}
						externalLabel={<FormattedMessage id="productivity-report.external_sent" />}>
						<FormattedMessage id="productivity-report.total_files" />
					</ReportBlock>
					<ReportBlock
						internal={data.TotalInternalMessages}
						external={data.TotalExternalMessages}
						internalLabel={<FormattedMessage id="productivity-report.internal_sent" />}
						externalLabel={<FormattedMessage id="productivity-report.external_sent" />}>
						<FormattedMessage id="productivity-report.total_messages" />
					</ReportBlock>
					<ReportBlock
						internal={data.TotalInternalDownloads}
						external={data.TotalExternalDownloads}
						internalLabel={<FormattedMessage id="productivity-report.internal_received" />}
						externalLabel={<FormattedMessage id="productivity-report.external_received" />}>
						<FormattedMessage id="productivity-report.total_downloads" />
					</ReportBlock>
				</div>
			)}
		</Widget>
	);
};

type ReportBlockProps = {
	internal: number;
	external: number;
	internalLabel: ReactNode;
	externalLabel: ReactNode;
};

const ReportBlock: FC<ReportBlockProps> = ({ internal, external, internalLabel, externalLabel, ...props }) => {
	const { formatNumber } = useIntl();
	const total = internal + external;

	return (
		<div className="flex flex-col p-4 overflow-hidden bg-white rounded-md shadow lg:first:rounded-l-md lg:last:rounded-r-md lg:rounded-none lg:shadow-none">
			<h3 className="flex-1 text-sm text-gray-600">{props.children}</h3>
			<p className="mb-2 text-2xl font-semibold">{formatNumber(total)}</p>
			<div className="flex h-3 overflow-hidden bg-gray-200 rounded-full">
				<div className="h-full bg-blue-700" style={{ width: total > 0 ? `${(external / total) * 100}%` : 0 }}></div>
				<div className="h-full bg-blue-300" style={{ width: total > 0 ? `${(internal / total) * 100}%` : 0 }}></div>
			</div>
			<div className="grid grid-cols-2 mt-4 ">
				<div className="flex items-center lg:block xl:flex">
					<FontAwesomeIcon icon="user-friends" className="mr-3 text-blue-700" />
					<div className="flex-1">
						<h4 className="text-sm text-gray-600">{internalLabel}</h4>
						<p className="font-semibold">
							<FormattedNumber style="percent" value={total > 0 ? external / total : 0} />
						</p>
					</div>
				</div>
				<div className="flex items-center lg:block xl:flex">
					<FontAwesomeIcon icon="user-friends" className="mr-3 text-blue-300" />
					<div className="flex-1">
						<h4 className="text-sm text-gray-600">{externalLabel}</h4>
						<p className="font-semibold">
							<FormattedNumber style="percent" value={total > 0 ? internal / total : 0} />
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductivityReportWidget;
