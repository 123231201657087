import { type ReactNode, createContext } from 'react';

const AppContext = createContext<{
	pageTitle: string | null;
	logo: string | null;
	setPageTitle: (title: string) => void;
	setLocale: (locale: string) => void;
	setLogo: (logo: string | null) => void;
	setShowGlobalSearch: (show: boolean) => void;
	showGlobalSearch: boolean;
	setShowDrawer: (show: boolean) => void;
	showDrawer: boolean;
	setPageHeader: (children: ReactNode) => void;
	pageHeader: ReactNode;
	name: string;
	setName: (name: string) => void;
}>({
	pageTitle: null,
	logo: null,
	setLocale: () => undefined,
	setLogo: () => undefined,
	setPageTitle: () => undefined,
	setShowGlobalSearch: () => undefined,
	showGlobalSearch: false,
	showDrawer: false,
	setShowDrawer: () => undefined,
	setPageHeader: () => undefined,
	pageHeader: undefined,
	name: 'Convoflo',
	setName: () => undefined
});

export default AppContext;
