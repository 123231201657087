import { List } from './List';
import { Tab as NewTab } from './Tab';
import { Panels as NewPanels } from './Panels';
import { Tab as BaseTab } from '@headlessui/react';

export const Tab = Object.assign(NewTab, {
	List: List,
	Group: BaseTab.Group,
	Panel: BaseTab.Panel,
	Panels: NewPanels
});
