import { Size } from '@convoflo/ui';
import classNames from 'classnames';
import React, { forwardRef, type ForwardRefRenderFunction } from 'react';

type InsetButtonOptions = {
	disabled?: boolean;
	className?: string;
	size?: Size;
};

type InsetButtonProps = React.ButtonHTMLAttributes<HTMLElement> & InsetButtonOptions;

const InsetButtonRenderFunction: ForwardRefRenderFunction<HTMLButtonElement, InsetButtonProps> = (props, ref) => {
	return <button {...props} ref={ref} className={resolveClassName(props)} />;
};

const resolveClassName = ({ disabled = false, className, size = Size.md }: InsetButtonOptions) => {
	return classNames(
		className,
		'inline-flex text-gray-700 flex-wrap disabled:opacity-50 select-none disabled:cursor-default transition-colors focus:outline-none ease-in-out duration-250 whitespace-nowrap items-center justify-center',
		{
			// Size.xs
			'text-xs rounded': size === Size.xs,
			'px-2 py-1': size === Size.xs,

			// Size.sm
			'text-xs md:text-sm rounded': size === Size.sm,
			'px-2 md:px-3 py-1': size === Size.sm,

			// Size.md
			'text-base rounded-md font-semibold': size === Size.md,
			'px-4 py-2': size === Size.md,

			// Size.lg
			'text-lg rounded-md font-semibold': size === Size.lg,
			'px-5 py-2': size === Size.lg,

			// Size.xl
			'text-xl rounded-lg font-semibold': size === Size.xl,
			'px-5 py-3': size === Size.xl,

			// Size['2xl']
			'text-2xl rounded-lg font-bold': size === Size['2xl'],
			'px-6 py-3': size === Size['2xl'],

			// !disabled
			'active:shadow-inner focus:outline-none hover:bg-gray-300 hover:bg-opacity-50': !disabled
		}
	);
};

const InsetButton = forwardRef(InsetButtonRenderFunction);

export default InsetButton;
