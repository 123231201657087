import { Button, Input, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Row, Variant } from '@convoflo/ui';
import type { CannedMessage, CannedMessageCategory } from '@components/CannedMessages';
import useClient from '@hooks/useClient';
import { type FC, type FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import type { WretchError } from 'wretch';

type SaveCannedCategoryMutation = {
	name: string;
};

type CannedMessageCategoryFormProps = Omit<ModalProps, 'isOpen'> & {
	category?: CannedMessageCategory;
	onSave?: (category: CannedMessageCategory) => void;
};

export const CannedMessageCategoryForm: FC<CannedMessageCategoryFormProps> = ({ category, onSave = () => undefined, ...modalProps }) => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	const [isOpen, setIsOpen] = useState(true);
	const [name, setName] = useState(category?.Name ?? '');

	const { mutate: save, isLoading: isSaving } = useMutation<CannedMessageCategory, WretchError, SaveCannedCategoryMutation>(
		async ({ name }) =>
			category
				? await client.url(`account/canned-categories/${category.Id}`).json({ name }).put().json<CannedMessageCategory>()
				: await client.url(`account/canned-categories`).json({ name }).post().json<CannedMessageCategory>(),
		{
			onError: console.error,
			onSuccess: category => {
				queryClient.setQueryData<CannedMessage[]>(['canned-messages'], messages =>
					(messages ?? []).map(message => ({
						...message,
						category: message.category?.Id === category.Id ? category : message.category
					}))
				);
				toast.success(<FormattedMessage id="canned-categories-crud.canned_category_modified" />);
				onSave(category);
				setIsOpen(false);
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		save({ name });
	};

	return (
		<Modal isOpen={isOpen} {...modalProps} onSubmit={onSubmit}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="canned-categories-crud.edit_canned_category" />
			</ModalHeaderOnlyTitle>
			<ModalBody>
				<Row>
					<Label htmlFor="category">
						<FormattedMessage id="canned-categories-crud.canned_category_label" />
					</Label>

					<Input autoFocus block id="category" disabled={isSaving} type="text" value={name} onChange={e => setName(e.target.value)} />
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button icon="save" variant={Variant.primary} disabled={isSaving || name === ''}>
					<FormattedMessage id="save" />
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} type="button" onClick={() => setIsOpen(false)} disabled={isSaving}>
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
