import StripeLogo from '@assets/images/Powered by Stripe - black.svg?react';
import { Button, Modal, ModalBody, ModalHeaderOnlyTitle, ModalSize, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import { useLocalStorage } from '@hooks/useLocalStorage';
import Card from '@ui/Card';
import { type FC, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { defaultCurrency, products, stripeConnectConfigs } from '../../constants';

export const PaymentRequestsPromo: FC = () => {
	const { client } = useClient();
	const { formatMessage } = useIntl();

	const [requestMade, setRequestMade] = useLocalStorage('module.payments.requested', false);
	const [showTransactionFees, setShowTransactionFees] = useState(false);

	const { mutate: request, isLoading: isRequesting } = useMutation<void>(async () => await client.url('events/payments-module-request').post().res(), {
		onSuccess: () => {
			setRequestMade(true);
		},
		onError: console.error
	});

	if (requestMade) {
		return (
			<div className="px-6 mt-8 lg:mt-20">
				<div className="flex justify-center mx-auto mb-8">
					<FontAwesomeIcon icon="check-circle" size="6x" className="text-green-500" />
				</div>
				<Card className="max-w-lg mx-auto prose">
					<h2 className="text-gray-600">
						<FormattedMessage id="payment-requests.activated.plan_meet" />
					</h2>
					<p>
						<FormattedMessage id="payment-requests.activated.text1" />
					</p>
					<p>
						<a className="text-theme-primary hover:underline" href={formatMessage({ id: 'payment-requests.activated.link' })} target="_blank" rel="noreferrer">
							<FormattedMessage id="payment-requests.activated.cta" />
						</a>
					</p>
					<p>
						<FormattedMessage id="payment-requests.activated.text2" />
						<ol>
							<li>
								<FormattedMessage id="payment-requests.activated.text2a" />
							</li>
							<li>
								<FormattedMessage id="payment-requests.activated.text2b" />
							</li>
							<li>
								<FormattedMessage id="payment-requests.activated.text2c" />
							</li>
						</ol>
					</p>
					<p>
						<FormattedMessage id="payment-requests.activated.text3" />
					</p>
				</Card>
			</div>
		);
	}

	return (
		<div className="max-w-4xl px-6 mx-auto my-6 lg:my-16">
			<h1 className="font-serif text-3xl font-extrabold lg:text-5xl">
				<FormattedMessage id="payment-requests.promo.title" />
			</h1>
			<p className="mt-4 text-gray-500 lg:mt-8">
				<FormattedMessage id="payment-requests.promo.subheading" />
			</p>

			<div className="grid grid-cols-1 gap-8 my-8 lg:my-24 lg:grid-cols-2">
				<div className="flex flex-col px-6 py-4 border-b-4 rounded-md ring-1 ring-indigo-600/10 border-indigo-500/75 bg-gradient-to-tr from-indigo-50 to-indigo-100">
					<FontAwesomeIcon icon="dollar-sign" mask="square" transform="shrink-8" className="text-indigo-700" size="3x" />
					<h3 className="mt-6 text-xs font-bold uppercase text-indigo-700/50">
						<FormattedMessage id="payment-requests.promo.box1_title" />
					</h3>
					<p className="mt-2 text-lg font-light text-indigo-900/90">
						<FormattedMessage id="payment-requests.promo.box1_desc" />
					</p>
				</div>
				<div className="flex flex-col px-6 py-4 border-b-4 rounded-md ring-1 ring-green-600/10 border-green-500/75 bg-gradient-to-tr from-green-50 to-green-100">
					<FontAwesomeIcon icon="sync" mask="square" transform="shrink-8" className="text-green-700" size="3x" />
					<h3 className="mt-6 text-xs font-bold uppercase text-green-700/50">
						<FormattedMessage id="payment-requests.promo.box2_title" />
					</h3>
					<p className="mt-2 text-lg font-light text-green-800">
						<FormattedMessage id="payment-requests.promo.box2_desc" />
					</p>
				</div>
				<div className="flex flex-col px-6 py-4 border-b-4 rounded-md ring-1 ring-yellow-600/10 border-yellow-500/75 bg-gradient-to-tr from-yellow-50 to-yellow-100">
					<FontAwesomeIcon icon="rabbit-fast" mask="square" transform="shrink-8" className="text-yellow-700" size="3x" />
					<h3 className="mt-6 text-xs font-bold uppercase text-yellow-700/50">
						<FormattedMessage id="payment-requests.promo.box3_title" />
					</h3>
					<p className="mt-2 text-lg font-light text-yellow-800">
						<FormattedMessage id="payment-requests.promo.box3_desc" />
					</p>
				</div>
				<div className="flex flex-col px-6 py-4 border-b-4 rounded-md ring-1 ring-pink-600/10 border-pink-500/75 bg-gradient-to-tr from-pink-50 to-pink-100">
					<FontAwesomeIcon icon="phone-laptop" mask="square" transform="shrink-8" className="text-pink-700" size="3x" />
					<h3 className="mt-6 text-xs font-bold uppercase text-pink-700/50">
						<FormattedMessage id="payment-requests.promo.box4_title" />
					</h3>
					<p className="mt-2 text-lg font-light text-pink-800">
						<FormattedMessage id="payment-requests.promo.box4_desc" />
					</p>
				</div>
			</div>

			<div className="flex flex-col items-start my-24 mb-3 sm:flex-row">
				<h2 className="text-2xl font-bold leading-snug text-gray-500 transition-colors lg:text-4xl hover:text-gray-700">
					<FormattedMessage id="payment-requests.promo.big_cta" values={{ strong: msg => <span className="text-gray-900 border-b border-gray-900/25">{msg}</span> }} />
				</h2>

				<div className="flex flex-col items-start px-6 py-4 mt-12 bg-white rounded-lg shadow-lg sm:mt-0 sm:items-end sm:ml-12">
					<span className="inline-flex px-2 py-1 mb-2 text-xs font-semibold text-white uppercase bg-red-600 rounded-sm whitespace-nowrap">
						<FormattedMessage id="payment-requests.promo.launch_offer" />
					</span>
					<p className="text-5xl font-bold">
						<FormattedNumber value={products.modules.payments.amount / 100} style="currency" currency={products.modules.payments.currency} currencyDisplay="symbol" />
						<sup className="ml-1 text-base font-normal text-gray-400">
							<FormattedMessage id="payment-requests.promo.per_month" />
						</sup>
					</p>
					<p className="mt-4 text-xs text-gray-400">
						<FormattedMessage id="payment-requests.promo.price_conditions" />
						<Button onClick={() => setShowTransactionFees(true)} size={Size.xxs} circle variant={Variant.dark} icon="info" />
					</p>
				</div>
			</div>

			<div className="flex flex-col items-center justify-center mx-auto my-32">
				<Button size={Size['2xl']} variant={Variant.success} shadow onClick={() => request()} iconEnd="plus-circle" disabled={isRequesting} loading={isRequesting}>
					<FormattedMessage id="payment-requests.promo.activate" />
				</Button>

				<StripeLogo className="w-32 mx-auto mt-6 text-gray-400" />

				<p className="max-w-md mt-2 text-xs italic text-gray-400">
					<FormattedMessage id="payment-requests.stripe_mention" />
				</p>
			</div>

			<Modal isOpen={showTransactionFees} size={ModalSize.Small} closeable={true} onAfterClose={() => setShowTransactionFees(false)}>
				<ModalHeaderOnlyTitle>
					<FormattedMessage id="payment-requests.promo.fees_title" />
				</ModalHeaderOnlyTitle>
				<ModalBody className="prose">
					<div className="flex items-start divide-x divide-gray-200">
						<div className="flex flex-col items-center flex-1 px-6 py-4">
							<FontAwesomeIcon icon="credit-card" size="2x" />
							<h4 className="text-lg text-center text-semibold">
								<FormattedMessage id="payment-requests.promo.fees_card" />
							</h4>
							<p className="text-gray-700">
								<FormattedNumber value={stripeConnectConfigs.card.fees.percentage} minimumFractionDigits={1} style="percent" /> +{' '}
								<FormattedNumber value={stripeConnectConfigs.card.fees.amount} style="currency" currency={defaultCurrency} currencyDisplay="symbol" />
							</p>
						</div>
						<div className="flex flex-col items-center flex-1 px-6 py-4">
							<FontAwesomeIcon icon="university" size="2x" />
							<h4 className="text-lg text-center text-semibold">
								<FormattedMessage id="payment-requests.promo.fees_bank" />
							</h4>
							<p className="text-gray-700">
								<FormattedMessage
									id="payment-requests.promo.fees_bank_maximum"
									values={{
										percentage: <FormattedNumber value={stripeConnectConfigs.acss_debit.fees.percentage} style="percent" />,
										amount: <FormattedNumber value={stripeConnectConfigs.acss_debit.fees.maximum} style="currency" currency={defaultCurrency} currencyDisplay="symbol" />
									}}
								/>
							</p>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
};
