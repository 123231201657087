import { Checkbox, Size } from '@convoflo/ui';
import { inner } from '@floating-ui/react';
import Folder from '@models/Folder';
import User from '@models/User';
import UserAvatar from '@ui/UserAvatar';
import classNames from 'classnames';
import { type OptionProps, components } from 'react-select';

export const SecureSpaceOption = ({ data, innerProps, isSelected, isFocused, ...props }: OptionProps<Folder>) => {
	return (
		<components.Option
			innerProps={{
				...innerProps,
				className: classNames(innerProps.className, 'transition !text-inherit', {
					'!bg-theme-primary-lightest/50 hover:bg-theme-primary-lightest/75': isSelected,
					'bg-gray-50 hover:bg-gray-100': !isSelected
				})
			}}
			data={data}
			isFocused={false}
			isSelected={isSelected}
			{...props}>
			<div className="flex items-center gap-3">
				<div>
					<Checkbox checked={isSelected} />
				</div>
				<div className="flex-1">
					<h6
						className={classNames('text-sm', {
							'font-medium': isSelected
						})}>
						{data.getName()}
					</h6>
				</div>
				{!!data.collaborators?.length && (
					<div className="flex items-center gap-[-4px]">
						{data.collaborators.map((user: User) => (
							<UserAvatar key={user.ID} user={user} size={Size.xs} />
						))}
						{data.members_count > data.collaborators.length && (
							<span className="pl-2 text-sm font-bold tracking-tighter text-black/75">+{data.members_count - data.collaborators.length}</span>
						)}
					</div>
				)}
			</div>
		</components.Option>
	);
};
