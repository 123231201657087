import type { AnnouncementType } from '@components/Announcement';
import { DateTimeDisplay } from '@components/DateTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@ui/Card';
import { MarkdownContent } from '@ui/MarkdownContent';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

type AnnouncementItemProps = {
	announcement: AnnouncementType;
};

export const AnnouncementItem: FC<AnnouncementItemProps> = ({ announcement }) => (
	<Card id={`a${announcement.Id}`}>
		<div className="flex items-center justify-between mb-8">
			<h1 className="text-2xl font-medium">
				<a href={`#a${announcement.Id}`} className="hover:underline">
					{announcement.Title}
				</a>
			</h1>
			<p className="text-xs text-gray-400">
				<DateTimeDisplay defaultFormat="relative" value={announcement.created_at} />
			</p>
		</div>
		<MarkdownContent>{announcement.Content}</MarkdownContent>
		{announcement.Link !== null && (
			<div className="mt-4 prose-sm prose break-words max-w-none sm:prose-base">
				<p>
					<a href={announcement.Link} target="_blank" rel="noreferrer">
						<FormattedMessage id="announcements.learn_more" />
						<FontAwesomeIcon icon="external-link" className="ml-2" />
					</a>
				</p>
			</div>
		)}
	</Card>
);
