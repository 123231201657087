import { Input, type InputProps } from '@convoflo/ui';
import { Tab } from '@ui/Tab';
import classNames from 'classnames';
import type { ChangeEvent, FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { locales } from '../constants';

type TranslatableInputProps = Omit<InputProps, 'value' | 'onChange'> & {
	values?: Record<string, string>;
	onChange?: (event: ChangeEvent<HTMLInputElement>, locale: string) => void;
};

export const TranslatableInput: FC<TranslatableInputProps> = ({ block, values, onChange = () => undefined, ...inputProps }) => {
	return (
		<div
			className={classNames('flex-col flex-1', {
				flex: block,
				'inline-flex': !block
			})}>
			<Tab.Group>
				<Tab.Panels>
					{locales.map(locale => (
						<Tab.Panel>
							<Input {...inputProps} block={block} className="!rounded-b-none !border-b-0 !py-2" type="text" value={values?.[locale] ?? ''} onChange={e => onChange(e, locale)} />
						</Tab.Panel>
					))}
				</Tab.Panels>
				<Tab.List position="bottom" size={inputProps.size} className="border rounded-b border-t-transparent">
					{locales.map(locale => (
						<Tab position="bottom" size={inputProps.size}>
							<FormattedMessage id={locale} />
						</Tab>
					))}
				</Tab.List>
			</Tab.Group>
		</div>
	);
};
