import { type ComposerPlugin, DialogMessageOptions, type MessageOptions } from '@components/Message';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useIsDirty from '@hooks/useIsDirty';
import { Tooltip } from '@ui/Tooltip';
import isEmpty from 'lodash.isempty';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

export const usePluginMessageOptions: ComposerPlugin = ({ fileable, message, disabled, parent }, { enabled = true } = {}, data) => {
	const { formatMessage } = useIntl();
	const { account } = useAccount();

	// Plugin data
	const [showPreview, setShowPreview] = useState(message?.PreviewInAlert ?? false);
	const [readNotification, setReadNotification] = useState(message?.ReadNotification ?? false);
	const [markAsImportant, setMarkAsImportant] = useState(message?.Priority ?? account?.business.Defaults?.message?.importance ?? false);

	const [showDialogOptions, setShowDialogOptions] = useState(false);

	const isEditing = !!message?.ID;
	const listenForDraft = useRef(true);

	const { isDirty, setIsDirty } = useIsDirty([showPreview, markAsImportant, readNotification]);

	const onReset = useCallback(async () => {
		setIsDirty(false);
		setShowPreview(false);
		setReadNotification(false);
		setMarkAsImportant(account?.business.Defaults?.message?.importance ?? false);
		listenForDraft.current = true;
	}, [account?.business.Defaults?.message?.importance, setIsDirty]);

	const onMessageOptionsChanged = ({ markAsImportant, showPreviewInEmail, readNotification }: MessageOptions) => {
		setMarkAsImportant(markAsImportant);
		setShowPreview(showPreviewInEmail);
		setReadNotification(readNotification);
	};

	// Received from draft
	useEffect(() => {
		if (!listenForDraft.current) {
			return;
		}

		if (isEmpty(data?.['options'])) {
			return;
		}

		if (data?.['options']?.showPreview !== undefined) {
			setShowPreview(data['options'].showPreview);
		}
		if (data?.['options']?.readNotification !== undefined) {
			setReadNotification(data['options'].readNotification);
		}
		if (data?.['options']?.markAsImportant !== undefined) {
			setMarkAsImportant(data['options'].markAsImportant);
		}

		listenForDraft.current = false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.['options']]);

	return {
		id: 'options',
		data: {
			markAsImportant,
			showPreview,
			readNotification
		},
		postData: () => {
			if (!enabled) {
				return {};
			}

			return {
				priority: markAsImportant ? 1 : 0,
				show_preview: showPreview ? 1 : 0,
				read_notification: readNotification ? 1 : 0
			};
		},
		onReset,
		isDirty,
		components: {
			button: (
				<>
					<div id="step-message-options">
						<Tooltip tip={formatMessage({ id: 'comments.options' })}>
							<Button size={Size.sm} disabled={!enabled || disabled} circle variant={Variant.light} intent={Intent.secondary} onClick={() => setShowDialogOptions(true)} type="button">
								<FontAwesomeIcon icon="cog" />
							</Button>
						</Tooltip>
					</div>
					{showDialogOptions && (
						<DialogMessageOptions
							isReply={!!parent}
							fileable={fileable}
							markAsImportant={markAsImportant}
							showPreviewInEmail={isEditing ? undefined : showPreview}
							readNotification={readNotification}
							onChange={onMessageOptionsChanged}
							onAfterClose={() => setShowDialogOptions(false)}
						/>
					)}
				</>
			)
		}
	};
};
