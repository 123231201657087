import wretch, { type FetchLike, type WretchResponse } from 'wretch';
import download from 'downloadjs';
import FormDataAddon from 'wretch/addons/formData';
import QueryStringAddon from 'wretch/addons/queryString';
import qs from 'qs';

export const BaseUrl = import.meta.env.VITE_API_BASE_URL;

export const versionMiddleware = (version: number) => (next: FetchLike) => (url: string, opts: Record<string, any>) => {
	url = url.replace(/\/api\/([0-9]+)\//i, `/api/${version}/`);
	return next(url, opts);
};

export const Client = wretch().errorType('json').url(`${BaseUrl}/api/1/`).headers({ Accept: 'application/json', 'X-App-Version': APP_VERSION }).addon(FormDataAddon).addon(QueryStringAddon);

export const downloadResponse = (filename: string, mimeType: string) => async (response: WretchResponse) => {
	const contentDisposition = response.headers.get('Content-Disposition');
	mimeType = response.headers.get('content-type') || mimeType;

	if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
		const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
		if (matches != null && matches[1]) {
			filename = matches[1].replace(/['"]/g, '');
		}
	}

	const blob = await response.blob();

	download(blob, filename, mimeType);
};
