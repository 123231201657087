import type { ModelType } from '@types';

export interface IUser extends ModelType {
	'@type': 'User';
	ID: number | null;
	Name: string | null;
	Avatar: string | null;
	Title: string | null;
}

export interface IElevatedUser extends IUser {
	Email: string | null;
	Scope?: 'internal' | 'external';
}

class User implements IUser {
	'@type': 'User';
	ID!: number | null;
	Name!: string | null;
	Avatar!: string | null;
	Title!: string | null;

	constructor(attributes: Record<string, any> = {}) {
		Object.assign(this, attributes);
	}

	id() {
		return `user_${this.ID}`;
	}

	getKey() {
		return this.ID;
	}

	getName() {
		return this.Name;
	}
}

export default User;
