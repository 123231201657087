import { FileManagerContext, type FileManagerResults } from '@components/FileManager';
import { useView } from '@hooks/use-view';
import useClient from '@hooks/useClient';
import File from '@models/File';
import Folder from '@models/Folder';
import type { Fileable } from '@types';
import { useContext } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { type InfiniteData, useMutation, useQueryClient } from 'react-query';
import type { WretchError } from 'wretch';

export const useFileableHelpers = (fileable?: Fileable) => {
	const queryClient = useQueryClient();
	const { client } = useClient();
	const fileManagerContext = useContext(FileManagerContext);
	const { key } = useView()!;

	const { mutate: toggleNotifications, isLoading: istoggleNotificationsLoading } = useMutation<Fileable, WretchError, boolean, InfiniteData<FileManagerResults>>(
		async enable => {
			let payload = await client
				.url(fileable!.getRoute(enable ? 'follow' : 'unfollow'))
				.post()
				.json<Fileable>();

			if (fileable instanceof File) {
				return new File(payload);
			} else if (fileable instanceof Folder) {
				return new Folder(payload);
			}

			return payload;
		},
		{
			onMutate: enable => {
				fileable!.pivot!.Notify = enable;
				return fileManagerContext?.update?.(fileable!);
			},
			onError: (_error, _newMessage, context) => {
				queryClient.setQueryData(['files', key], context);
			},
			onSuccess: (fileable, enable) => {
				toast.success(<FormattedMessage id="file-manager.alerts_toggled" values={{ toggled: enable, name: fileable.getName() }} />);
				if (fileable instanceof File) {
					queryClient.setQueryData<File>(['view', `file:${fileable.URL}`], fileable);
				} else if (fileable instanceof Folder) {
					queryClient.setQueryData<Folder>(['view', `folder:${fileable.URL}`], fileable);
				}
			}
		}
	);

	const { mutate: toggleFavorite, isLoading: isToggleFavoriteLoading } = useMutation<Fileable, WretchError, boolean, InfiniteData<FileManagerResults>>(
		async enable => {
			let payload = await client.url(fileable!.getRoute('favorite'))[enable ? 'put' : 'delete']().json<Fileable>();

			if (fileable instanceof File) {
				return new File(payload);
			} else if (fileable instanceof Folder) {
				return new Folder(payload);
			}

			return payload;
		},
		{
			onMutate: enable => {
				fileable!.pivot!.Favorite = enable;
				return fileManagerContext?.update?.(fileable!);
			},
			onError: (_error, _newMessage, context) => {
				queryClient.setQueryData(['files', key], context);
			},
			onSuccess: (fileable, enable) => {
				toast.success(<FormattedMessage id="file-manager.favorite_toggled" values={{ toggled: enable, name: fileable.getName() }} />);
				if (fileable instanceof File) {
					queryClient.setQueryData<File>(['view', `file:${fileable.URL}`], fileable);
				} else if (fileable instanceof Folder) {
					queryClient.setQueryData<Folder>(['view', `folder:${fileable.URL}`], fileable);
				}
			}
		}
	);

	return {
		toggleNotifications: (enable: boolean) => toggleNotifications(enable),
		disableAlerts: () => toggleNotifications(false),
		enableAlerts: () => toggleNotifications(true),
		toggleFavorite: (enable: boolean) => toggleFavorite(enable),
		addToFavorites: () => toggleFavorite(true),
		removeFromFavorite: () => toggleFavorite(false),
		isLoading: istoggleNotificationsLoading || isToggleFavoriteLoading
	};
};
