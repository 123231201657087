import { DateTimeDisplay } from '@components/DateTime';
import { useFileableMembers } from '@components/FileManager';
import type { ScheduledMessageType } from '@components/FileRequest';
import { Alert, Button, InputDateTime, Intent, Label, Modal, ModalBody, ModalFooter, type ModalProps, Row, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import Folder from '@models/Folder';
import User from '@models/User';
import { RichTextEditor, type RichTextEditorRef } from '@ui/RichTextEditor';
import UserAvatar from '@ui/UserAvatar';
import UserPillSelector from '@ui/UserPillSelector';
import { formatLocalDateToUTC } from '@utils/DateUtils';
import { ID } from '@utils/StringUtils';
import { formatISO, parseISO } from 'date-fns';
import { type FC, type FormEvent, forwardRef, useCallback, useEffect, useId, useImperativeHandle, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import type { WretchError } from 'wretch';

export type FileRequestFormProps = Omit<ModalProps, 'isOpen'> & {
	reminder?: Partial<ScheduledMessageType>;
	folder: Folder;
	multiple?: boolean;
};

export const FileRequestForm: FC<FileRequestFormProps> = ({ reminder, folder, multiple = false, ...props }) => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	const defaultId = useId();

	const [isOpen, setIsOpen] = useState(true);
	const [reminders, setReminders] = useState<Partial<ScheduledMessageType>[]>([reminder ?? { Id: defaultId }]);
	const [saving, setSaving] = useState(false);

	const editorRefs = useRef<(ReminderFormRowHandle | null)[]>([]);

	const addReminder = () => {
		setReminders(reminders => reminders.concat(createNewReminder()));
	};

	const { mutateAsync: save } = useMutation<ScheduledMessageType, WretchError, Partial<ScheduledMessageType>>(async reminder => {
		let data = {
			Body: reminder.Body,
			remind_at: formatLocalDateToUTC(reminder.remind_at ?? new Date()).toUTCString(),
			users: (reminder.users || []).map(u => u.ID)
		};

		let route = folder.getRoute('requests');

		if (typeof reminder.Id === 'number') {
			route += `/${reminder.Id}`;
		}

		return await client.url(route).json(data)[typeof reminder.Id === 'string' ? 'post' : 'put']().json<ScheduledMessageType>();
	});

	const saveAll = async (reminders: Partial<ScheduledMessageType>[]) => {
		await Promise.all(reminders.map((reminder, index) => save({ ...reminder, Body: editorRefs.current[index]?.body() ?? '' })));
		queryClient.invalidateQueries('reminders');
		setIsOpen(false);
	};

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		setSaving(true);
		saveAll(reminders).finally(() => setSaving(false));
	};

	const allFormsOk = reminders.every((reminder, index) => editorRefs.current[index]?.body() && (reminder.users || []).length > 0);

	const onReminderUpdated = (reminder: Partial<ScheduledMessageType>) => {
		setReminders(reminders => reminders.map(r => (r.Id === reminder.Id ? reminder : r)));
	};

	const onReminderRemoved = (reminder: Partial<ScheduledMessageType>) => {
		setReminders(reminders => reminders.filter(r => r.Id !== reminder.Id));
	};

	useEffect(() => {
		if (reminders.length === 0) {
			setIsOpen(false);
		}
		editorRefs.current = editorRefs.current.slice(0, reminders.length);
	}, [reminders]);

	return (
		<Modal isOpen={isOpen} onSubmit={onSubmit} {...props}>
			<ModalBody>
				<div className="space-y-2">
					{reminders.map((reminder, index) => (
						<ReminderFormRow
							ref={form => (editorRefs.current[index] = form)}
							key={reminder.Id}
							folder={folder}
							reminder={reminder}
							disabled={saving}
							collapsed={index < reminders.length - 1}
							showRemove={reminders.length > 1}
							onUpdated={onReminderUpdated}
							onRemoved={onReminderRemoved}
						/>
					))}
				</div>
			</ModalBody>
			<ModalFooter>
				<Button variant={Variant.primary} type="submit" disabled={saving || !allFormsOk}>
					<FormattedMessage id="file-requests-crud.save_reminders" values={{ n: reminders.length }} />
				</Button>

				{multiple && (
					<Button variant={Variant.light} intent={Intent.secondary} type="button" onClick={addReminder}>
						<FontAwesomeIcon icon="plus" className="mr-2" />
						<FormattedMessage id="file-requests-crud.add_another" />
					</Button>
				)}

				<Button variant={Variant.light} intent={Intent.secondary} type="button" onClick={() => setIsOpen(false)} disabled={saving}>
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export const createNewReminder = (): Partial<ScheduledMessageType> => ({ Id: ID() });

export const MAX_ITEMS_LISTED_IN_SELECTION = 3;
const MAX_CHARS = 5000;

type ReminderFormRowProps = {
	reminder: Partial<ScheduledMessageType>;
	folder: Folder;
	disabled?: boolean;
	collapsed?: boolean;
	showRemove?: boolean;
	onUpdated?: (reminder: Partial<ScheduledMessageType>) => void;
	onRemoved?: (reminder: Partial<ScheduledMessageType>) => void;
};

type ReminderFormRowHandle = {
	body: () => string;
};

const ReminderFormRow = forwardRef<ReminderFormRowHandle, ReminderFormRowProps>(
	({ reminder, folder, collapsed: initiallyCollapsed = false, disabled = false, showRemove = false, onUpdated = () => undefined, onRemoved = () => undefined }, ref) => {
		const { formatMessage } = useIntl();
		const { members = [] } = useFileableMembers(folder);

		const [remindAt, setRemindAt] = useState(reminder.remind_at ? parseISO(reminder.remind_at) : null);
		const [users, setUsers] = useState(reminder.users || []);
		const [collapsed, setCollapsed] = useState(initiallyCollapsed);
		const [totalCharacters, setTotalCharacters] = useState(0);

		const editor = useRef<RichTextEditorRef>(null);

		useImperativeHandle(ref, () => ({
			body: () => editor.current?.json() ?? {}
		}));

		const onChangeUser = useCallback((user: User, checked: boolean) => {
			setUsers(users => (checked ? [...users, user] : users.filter(u => user.ID !== u.ID)));
		}, []);

		useEffect(() => {
			setCollapsed(initiallyCollapsed);
		}, [initiallyCollapsed]);

		useEffect(() => {
			onUpdated({ ...reminder, remind_at: remindAt ? formatISO(remindAt) : undefined, users });
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [remindAt, users]);

		if (collapsed) {
			return (
				<div className="px-4 py-3 text-gray-400 bg-gray-200 rounded">
					<div className="flex items-start justify-between">
						<div className="flex-1">
							<h4 className="text-sm font-semibold text-gray-500">
								<FormattedMessage id="file-requests-crud.reminder_on" values={{ date: <DateTimeDisplay value={remindAt ?? null} force defaultFormat="long" /> }} />
							</h4>
							{totalCharacters === 0 && (
								<Alert size={Size.sm} variant={Variant.warning}>
									<p>
										<FormattedMessage id="file-requests-crud.missing_message" />
									</p>
								</Alert>
							)}

							{users.length === 0 && (
								<Alert size={Size.sm} variant={Variant.warning}>
									<p>
										<FormattedMessage id="file-requests-crud.missing_recipients" />
									</p>
								</Alert>
							)}
							{users.length > 0 && (
								<div className="mt-2 -space-x-1">
									{users.map(user => (
										<UserAvatar user={user} size={Size.xs} />
									))}
								</div>
							)}
						</div>
						<Button circle size={Size.sm} variant={Variant.light} intent={Intent.secondary} className="ml-2" onClick={() => setCollapsed(false)}>
							<FontAwesomeIcon icon="plus-square" />
						</Button>
					</div>
				</div>
			);
		}

		return (
			<div className="relative">
				{showRemove && (
					<Button variant={Variant.light} circle className="absolute top-0 right-0" type="button" onClick={() => onRemoved(reminder)}>
						<FontAwesomeIcon icon="times" />
					</Button>
				)}

				{/* reminder date and time */}
				<Row>
					<Label>
						<FormattedMessage id="file-requests-crud.send_reminder_on" />
					</Label>
					<InputDateTime disabled={disabled} placeholder={formatMessage({ id: 'file-requests-crud.select_date' })} value={remindAt} onChange={setRemindAt} />
				</Row>

				{/* message */}
				<Row>
					<Label>
						<FormattedMessage id="file-requests-crud.message" />
					</Label>
					<RichTextEditor
						ref={editor}
						characterLimit={MAX_CHARS}
						disabled={disabled}
						value={reminder.Body}
						onCharacterCountChange={setTotalCharacters}
						placeholder={formatMessage({ id: 'file-requests-crud.placeholder-content' })}
					/>
				</Row>

				{/* users */}
				<Row>
					<Label>
						<FormattedMessage id="file-requests-crud.to" />
					</Label>
					<div>
						{members.map(user => (
							<UserPillSelector className="mb-1 mr-1" key={user.ID} user={user} onClick={onChangeUser} selected={users.some(u => u.ID === user.ID)} />
						))}
					</div>
				</Row>
			</div>
		);
	}
);
