import { Alert, Button, InputBlock, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Row, ValidationField, Variant } from '@convoflo/ui';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import useIsDirty from '@hooks/useIsDirty';
import { useAccountProfileUpdateMutation } from '@state/queries/profile';
import { type FC, type FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const DialogNoName: FC<Omit<ModalProps, 'isOpen'>> = ({ ...modalProps }) => {
	const { validation } = useClient();
	const { account } = useAccount();
	const { formatMessage } = useIntl();

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');

	const { isDirty } = useIsDirty([firstName, lastName]);

	const { mutateAsync: save, isLoading: isSaving } = useAccountProfileUpdateMutation();

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		try {
			await save({ firstName, lastName });
		} catch {
			// TODO: Show error message to user
		}
	};

	return (
		<Modal isOpen={account?.hasName() === false} onSubmit={onSubmit} shouldCloseOnEsc={false} shouldCloseOnOverlayClick={false} {...modalProps}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="whats_your_name.title" />
			</ModalHeaderOnlyTitle>
			<ModalBody>
				<Alert variant={Variant.warning} className="mb-4">
					<p>
						<FormattedMessage id="whats_your_name.intro" />
					</p>
				</Alert>
				<Row className="grid grid-cols-2 gap-2">
					<div>
						<Label htmlFor="first_name">
							<FormattedMessage id="signup.first_name" />
						</Label>
						<ValidationField validation={validation} fieldName="first_name">
							<InputBlock
								placeholder={formatMessage({ id: 'account-profile.placeholder-first_name' })}
								id="first_name"
								value={firstName}
								onChange={e => setFirstName(e.target.value)}
								required
							/>
						</ValidationField>
					</div>
					<div>
						<Label htmlFor="last_name">
							<FormattedMessage id="signup.last_name" />
						</Label>
						<ValidationField validation={validation} fieldName="last_name">
							<InputBlock
								placeholder={formatMessage({ id: 'account-profile.placeholder-last_name' })}
								id="last_name"
								value={lastName}
								onChange={e => setLastName(e.target.value)}
								required
							/>
						</ValidationField>
					</div>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button type="submit" disabled={isSaving || !isDirty || firstName === '' || lastName === ''} variant={Variant.primary}>
					<FormattedMessage id="save" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
