import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { type FC, isValidElement, type ReactNode } from 'react';

type EmptyStateProps = {
	title: ReactNode;
	icon?: IconProp;
	description?: ReactNode;
	action?: ReactNode;
	className?: string;
};

const EmptyState: FC<EmptyStateProps> = ({ title, icon, description, action, className = '' }) => {
	return (
		<div className={classNames('p-4 my-6 text-center md:my-12 max-w-lg mx-auto', className)}>
			{icon !== undefined && <FontAwesomeIcon icon={icon} size="4x" mask="circle" transform="shrink-8" className="mb-4 text-gray-300" />}
			<h4 className="mb-3 text-lg text-gray-400">{title}</h4>
			{typeof description === 'string' && <p className="mt-3 text-xs text-gray-500">{description}</p>}
			{isValidElement(description) && description}
			{action !== undefined && <div className="mt-6">{action}</div>}
		</div>
	);
};

export default EmptyState;
