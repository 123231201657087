import { DateDisplay } from '@components/DateTime';
import { Button, HelperText, InputGroup, Intent, Label, Row, Size, Toggle, ValidationField, Variant } from '@convoflo/ui';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import useIsDirty from '@hooks/useIsDirty';
import { useOrganizationUpdateMutation } from '@state/queries/organization';
import Card from '@ui/Card';
import { RichTextEditor, type RichTextEditorRef } from '@ui/RichTextEditor';
import { type FC, type FormEvent, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { Prompt } from 'react-router-dom';

export const OrganizationSettings = () => {
	const { formatMessage, locale } = useIntl();
	const { account } = useAccount();
	const { validation } = useClient();

	const editor = useRef<RichTextEditorRef>(null);
	const [secureSpaceSessionTimeout, setSecureSpaceSessionTimeout] = useState(account!.business.TempLoginTimeout);
	const [reminderIntervals, setReminderIntervals] = useState<number[]>(account!.business.ReminderIntervals);

	const { isDirty, setIsDirty } = useIsDirty([secureSpaceSessionTimeout, JSON.stringify(reminderIntervals)]);

	const { mutateAsync: save, isLoading: isSaving } = useOrganizationUpdateMutation();

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();

		try {
			save({
				secureSpaceSessionTimeout: secureSpaceSessionTimeout ?? null,
				reminderIntervals: reminderIntervals.filter(interval => interval !== null),
				secureSpaceTerms: JSON.stringify(editor.current?.json() ?? {})
			});
			toast.success(<FormattedMessage id="organization-settings.saved" />);
			setIsDirty(false);
		} catch {
			// Add error message in toast
		}
	};

	return (
		<>
			<Prompt when={isDirty} message={formatMessage({ id: 'unsaved_changes' })} />
			<Card onSubmit={onSubmit} size={null} className="divide-y">
				<Row className="grid grid-cols-2 gap-6 px-6 py-8">
					<div>
						<Label htmlFor="secure_space_session_timeout-field">
							<FormattedMessage id="organization-settings.secure_space_session_timeout" />
						</Label>
						<HelperText>
							<FormattedMessage id="organization-settings.secure_space_session_timeout_info" />
						</HelperText>
					</div>
					<div className="flex flex-col items-start gap-3">
						<Toggle size={Size.sm} checked={secureSpaceSessionTimeout !== null} onChange={e => setSecureSpaceSessionTimeout(e.target.checked ? 0 : null)} />
						<ValidationField fieldName="secure_space_session_timeout-field" validation={validation}>
							<InputGroup
								block
								onChange={e => setSecureSpaceSessionTimeout(parseInt(e.target.value || '0'))}
								type="tel"
								autoCorrect="false"
								spellCheck="false"
								inputMode="numeric"
								min="0"
								maxLength={4}
								disabled={secureSpaceSessionTimeout === null}
								value={secureSpaceSessionTimeout ?? 0}
								id="secure_space_session_timeout-field"
								appended={
									<span className="text-sm text-gray-600">
										<FormattedMessage id="organization-settings.minutes" values={{ n: secureSpaceSessionTimeout }} />
									</span>
								}
							/>
						</ValidationField>
					</div>
				</Row>
				<Row className="grid grid-cols-2 gap-6 px-6 py-8">
					<Label>
						<FormattedMessage id="organization-settings.reminder_intervals" />
					</Label>

					<ReminderIntervalManager value={reminderIntervals} onChange={setReminderIntervals} />
				</Row>

				<Row className="px-6 py-8">
					<Label>
						<FormattedMessage id="organization-settings.secure_space_terms" />
					</Label>

					<HelperText className="mb-6">
						<FormattedMessage id="organization-settings.secure_space_terms_description" />
					</HelperText>

					<RichTextEditor showCannedMessages={false} value={account!.business.SecureSpaceTerms?.Text ?? ''} ref={editor} onCharacterCountChange={() => setIsDirty(true)} />

					{!!account!.business.SecureSpaceTerms && (
						<HelperText>
							<FormattedMessage
								id="terms.last_updated"
								values={{
									name: account!.business.SecureSpaceTerms.creator.Name,
									date: <DateDisplay value={account!.business.SecureSpaceTerms.created_at} force={true} defaultFormat="PPpp" />
								}}
							/>
						</HelperText>
					)}
					<HelperText className="mt-6">
						<FormattedMessage
							id="terms.footnote"
							values={{
								link1: msg => (
									<a href={`${import.meta.env.VITE_SITE_URL}/${locale}/privacy-policy`} rel="noreferrer" target="_blank" className="text-theme-primary hover:underline">
										{msg}
									</a>
								),
								link2: msg => (
									<a
										href={locale === 'fr' ? 'https://app.convoflo.com/dl/204MbE/mYvw' : 'https://www.convoflo.com/dl/1DMZ7g/mYvu'}
										rel="noreferrer"
										target="_blank"
										className="text-theme-primary hover:underline">
										{msg}
									</a>
								)
							}}
						/>
					</HelperText>
				</Row>

				<div className="px-6 py-4">
					<Button variant={Variant.primary} type="submit" disabled={!isDirty} loading={isSaving} icon="save">
						<FormattedMessage id="save" />
					</Button>
				</div>
			</Card>
		</>
	);
};

type ReminderIntervalManagerProps = {
	value?: number[];
	onChange: (value: number[]) => void;
};

export const ReminderIntervalManager: FC<ReminderIntervalManagerProps> = ({ value, onChange }) => {
	const [intervals, setIntervals] = useState<number[]>(value ?? []);

	const removeReminderByIndex = (index: number) => {
		setIntervals(intervals => intervals.filter((_, i) => index !== i));
	};

	const changeReminderByIndex = (index: number, value: number) => {
		setIntervals(intervals => intervals.map((interval, i) => (index === i ? value : interval)));
	};

	useEffect(() => {
		onChange(intervals.filter(interval => interval > 0));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [intervals]);

	return (
		<div className="overflow-hidden border rounded">
			{intervals.length === 0 && (
				<div className="px-4 py-8 text-center">
					<p className="mb-3 text-sm text-center text-gray-500">
						<FormattedMessage id="organization-settings.no_reminders" />
					</p>
					<Button intent={Intent.tertiary} variant={Variant.primary} icon="plus" size={Size.sm} type="button" onClick={() => setIntervals(intervals => intervals.concat([0]))}>
						<FormattedMessage id="organization-settings.add_reminder" />
					</Button>
				</div>
			)}
			{intervals.map((interval, index) => (
				<div className="flex items-center gap-3 px-3 py-2" key={index}>
					<span className="text-sm text-gray-500">
						<FormattedMessage id="organization-settings.after" />
					</span>
					<select
						className="inline-flex flex-1 px-2 py-1 pr-8 mx-1 text-sm text-left border-0 border-b-2 border-gray-200 rounded bg-gray-50 focus-within:border-blue-500 focus:ring-0"
						value={interval || ''}
						onChange={e => changeReminderByIndex(index, parseInt(e.target.value))}>
						<FormattedMessage id="organization-settings.select_interval">{msg => <option value="0">{msg}</option>}</FormattedMessage>
						<FormattedMessage id="organization-settings.days" values={{ n: 1 }}>
							{msg => (
								<option value="1" disabled={intervals.includes(1)}>
									{msg}
								</option>
							)}
						</FormattedMessage>
						<FormattedMessage id="organization-settings.days" values={{ n: 3 }}>
							{msg => (
								<option value="3" disabled={intervals.includes(3)}>
									{msg}
								</option>
							)}
						</FormattedMessage>
						<FormattedMessage id="organization-settings.weeks" values={{ n: 1 }}>
							{msg => (
								<option value="7" disabled={intervals.includes(7)}>
									{msg}
								</option>
							)}
						</FormattedMessage>
						<FormattedMessage id="organization-settings.weeks" values={{ n: 2 }}>
							{msg => (
								<option value="14" disabled={intervals.includes(14)}>
									{msg}
								</option>
							)}
						</FormattedMessage>
						<FormattedMessage id="organization-settings.weeks" values={{ n: 3 }}>
							{msg => (
								<option value="21" disabled={intervals.includes(21)}>
									{msg}
								</option>
							)}
						</FormattedMessage>
						<FormattedMessage id="organization-settings.months" values={{ n: 1 }}>
							{msg => (
								<option value="30" disabled={intervals.includes(30)}>
									{msg}
								</option>
							)}
						</FormattedMessage>
					</select>
					<Button variant={Variant.light} intent={Intent.secondary} size={Size.xs} circle icon="times" onClick={() => removeReminderByIndex(index)} type="button" />
				</div>
			))}
			{intervals.length > 0 && !intervals.includes(0) && (
				<footer className="px-3 py-2 border-t border-gray-100">
					<Button icon="plus" variant={Variant.primary} intent={Intent.tertiary} size={Size.sm} type="button" onClick={() => setIntervals(intervals => intervals.concat([0]))}>
						<FormattedMessage id="organization-settings.add_reminder" />
					</Button>
				</footer>
			)}
		</div>
	);
};
