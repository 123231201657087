import File from '@models/File';
import { type FC, useRef } from 'react';
import { type ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

type PhotoViewerProps = {
	file: File;
	location: string;
	showNavigation?: boolean;
};

const PhotoViewer: FC<PhotoViewerProps> = ({ file, location }) => {
	const wrapper = useRef<ReactZoomPanPinchRef>(null);

	const onImageLoad = () => {
		wrapper.current?.centerView();
	};

	return (
		<TransformWrapper centerOnInit centerZoomedOut minScale={0.1} limitToBounds={true} ref={wrapper}>
			<TransformComponent wrapperStyle={{ width: '100%', height: '100%' }} wrapperClass="flex-1">
				<img src={location} alt={file.getName()} className="max-w-full max-h-full" onLoad={onImageLoad} />
			</TransformComponent>
		</TransformWrapper>
	);
};

export default PhotoViewer;
