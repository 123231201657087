import ProductivityReport from '@components/ProductivityReport';
import UserSummary from '@components/UserSummary';
import AppContext from '@contexts/AppContext';
import { Size } from '@convoflo/ui';
import { useAccount } from '@hooks/useAccount';
import { PageTitle } from '@ui/Typography';
import UserAvatar from '@ui/UserAvatar';
import { Children, createContext, type FC, type PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';

export const WidgetsContext = createContext({
	onWidgetLoaded: () => {}
});

type WidgetsProps = PropsWithChildren<{
	onLoadedProgress?: (percentage: number) => void;
	gapSize?: Size;
}>;

const Widgets: FC<WidgetsProps> = ({ onLoadedProgress = () => undefined, gapSize = Size.md, children }) => {
	const [widgetsLoaded, setWidgetsLoaded] = useState(0);
	const numWidgets = Children.count(children);

	const onWidgetLoaded = useCallback(() => {
		setWidgetsLoaded(widgetsLoaded => widgetsLoaded + 1);
	}, []);

	useEffect(() => {
		onLoadedProgress(Math.min((widgetsLoaded / numWidgets) * 100, 100));
	}, [onLoadedProgress, widgetsLoaded, numWidgets]);

	return <WidgetsContext.Provider value={{ onWidgetLoaded }}>{children}</WidgetsContext.Provider>;
};

/**
 * 1. Sommaire
 * 2. ES non-activés
 * 3. E-sign
 * 	  a. en cours de signatures
 *    b. doit signer
 * 4. announcements
 * 5. productivity report
 */
const Home = () => {
	const { account } = useAccount();
	const { formatMessage } = useIntl();
	const { setPageTitle: setTitle, setPageHeader } = useContext(AppContext);

	const [widgetsLoadedProgress, setWidgetsLoadedProgress] = useState(0);

	const firstName = account!.FirstName;

	useEffect(() => {
		setTitle(formatMessage({ id: 'home.title' }, { user: firstName }));
	}, [formatMessage, setTitle, firstName]);

	useEffect(() => {
		setPageHeader(undefined);
	}, [setPageHeader]);

	if (!account!.hasFullAccess()) {
		return <Redirect to="/files" />;
	}

	return (
		<>
			<div className={`${widgetsLoadedProgress === 100 ? 'opacity-0' : 'opacity-50'} h-1 bg-blue-100 transition-opacity duration-150 top-0 right-0 left-0 sticky`}>
				<div className="h-full transition-all duration-500 bg-blue-600" style={{ width: `${widgetsLoadedProgress}%` }} />
			</div>

			<Scrollbars autoHide>
				<div className="px-4 py-8 sm:px-8">
					<div className="container flex">
						<UserAvatar user={account!} className="mr-4 md:hidden" size={Size.md} />
						<UserAvatar user={account!} className="hidden mr-4 md:inline-flex" size={Size.lg} />
						<div className="flex-1">
							<p className="text-sm text-gray-500">
								<FormattedMessage id="welcome_back" />,
							</p>

							<PageTitle>{account!.Name}</PageTitle>
						</div>
					</div>
				</div>
				<div className="h-full px-4 py-8 sm:px-8 ">
					<div className="container">
						<Widgets onLoadedProgress={setWidgetsLoadedProgress}>
							<div className="grid grid-cols-1 space-y-8 lg:gap-16 lg:grid-cols-3 lg:space-y-0">
								<div className="col-span-3 space-y-8">
									<UserSummary />
									{account?.isAdmin() && <ProductivityReport />}
								</div>
								{/* <div className="space-y-8">
									<UnactivatedSecureSpaces />
								</div> */}
							</div>
						</Widgets>
					</div>
				</div>
			</Scrollbars>
		</>
	);
};

export default Home;
