import { GuidedTour } from '@components/GuidedTour';
import { NotificationItem, type NotificationType } from '@components/Notification';
import { createLengthAwarePagination } from '@components/Pagination';
import { Badge, Button, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import useLocationChange from '@hooks/useLocationChange';
import { useMarkNotificationAsReadMutation } from '@state/queries/notifications';
import { DropdownContext } from '@ui/Dropdown';
import EmptyState from '@ui/EmptyState';
import { Tab } from '@ui/Tab';
import { type FC, Fragment, useContext, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage } from 'react-intl';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

type NotificationDropdownProps = {
	limit?: number;
};

export const NotificationDropdown: FC<NotificationDropdownProps> = ({ limit = 20 }) => {
	const { client } = useClient();
	const { account } = useAccount();
	const { ref: loadMoreRef, inView: loadMoreInView } = useInView();
	const history = useHistory();

	const [tabIndex, setTabIndex] = useState(0);

	const { setIsOpen: setIsDropdownOpen } = useContext(DropdownContext);

	const { mutateAsync: markAllAsReadMutation } = useMarkNotificationAsReadMutation();

	const {
		isLoading,
		isSuccess,
		data: notifications,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage
	} = useInfiniteQuery(
		['alerts', limit, tabIndex],
		async ({ pageParam = 1 }) =>
			createLengthAwarePagination<NotificationType>(
				await client
					.url('account/notifications')
					.query({ limit, page: pageParam, scope: tabIndex === 0 ? 'external' : 'internal' })
					.get()
					.json()
			),
		{
			enabled: tabIndex <= 1,
			getNextPageParam: paginator => (paginator.nextPageUrl ? paginator.currentPage + 1 : undefined)
		}
	);

	const isAllRead = !account?.unread_notifications_external && !account?.unread_notifications_internal;

	const markAllAsRead = async () => {
		if (isAllRead) {
			return;
		}

		markAllAsReadMutation();
	};

	const isEmpty = notifications?.pages[0].data.length === 0;

	useEffect(() => {
		if (tabIndex === 2) {
			history.push('/notifications');
		} else if (tabIndex === 3) {
			history.push('/account/notifications');
		}
	}, [history, tabIndex]);

	// Load the next page when is available and in view
	useEffect(() => {
		if (loadMoreInView && !isLoading && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [fetchNextPage, loadMoreInView, isLoading, hasNextPage, isFetchingNextPage]);

	useLocationChange(() => {
		setIsDropdownOpen(false);
	});

	return (
		<>
			<Tab.Group onChange={setTabIndex}>
				<Tab.List>
					<Tab id="step-tabs">
						<FormattedMessage id="alerts.external" />
						{account!.unread_notifications_external > 0 && (
							<Badge variant={Variant.danger} inverse className="ml-1">
								{account!.unread_notifications_external}
							</Badge>
						)}
					</Tab>
					<Tab>
						<FormattedMessage id="alerts.internal" />
						{account!.unread_notifications_internal > 0 && (
							<Badge variant={Variant.danger} inverse className="ml-1">
								{account!.unread_notifications_internal}
							</Badge>
						)}
					</Tab>
					<Tab id="step-all">
						<FormattedMessage id="alerts.all" />
					</Tab>
					<Tab>
						<FontAwesomeIcon icon="cog" />
					</Tab>
				</Tab.List>
			</Tab.Group>

			<main className="flex flex-col">
				<Scrollbars autoHide autoHeight autoHeightMax="50vh" className="flex-1">
					{notifications?.pages.map((group, i) => (
						<Fragment key={i}>
							{group.data.map(notification => (
								<NotificationItem key={notification.Id} notification={notification} source="dropdown" />
							))}
						</Fragment>
					))}

					{/* Empty state */}
					{isEmpty && <EmptyState icon="bells" title={<FormattedMessage id="alerts.no_alerts" />} />}

					<div ref={loadMoreRef} className="flex items-center justify-center">
						{isLoading || isFetchingNextPage ? (
							<FontAwesomeIcon icon="spinner-third" spin size="3x" className="my-8 text-gray-500" />
						) : (
							hasNextPage && (
								<Button variant={Variant.light} className="my-8 shadow" size={Size.xs} onClick={() => fetchNextPage()}>
									<FormattedMessage id="file-manager.load_more" />
								</Button>
							)
						)}
					</div>
				</Scrollbars>
				<footer className="flex justify-end px-4 py-2 bg-gray-100">
					<button className="text-sm text-theme-primary hover:underline disabled:text-opacity-50" onClick={markAllAsRead} disabled={isAllRead || !isSuccess}>
						<FormattedMessage id="alerts.mark-all-as-read" />
					</button>
				</footer>
			</main>

			<GuidedTour name="notifications" enabled={account?.hasFullAccess()} />
		</>
	);
};
