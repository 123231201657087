import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Title } from '@components/Account';
import { DialogNotificationProfilePicker, NotificationManager, NotificationProfileManager, NotificationScheduleManager } from '@components/Notification';
import AppContext from '@contexts/AppContext';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

export const NotificationOptionsPage = () => {
	const { formatMessage } = useIntl();
	const { setPageTitle: setTitle } = useContext(AppContext);

	const { state: welcome } = useLocation<{ welcome: boolean }>();

	const [showProfileOptions, setShowProfileOptions] = useLocalStorage('notifications.options.profiles', true);
	const [showScheduleOptions, setShowScheduleOptions] = useLocalStorage('notifications.options.schedule', true);

	useEffect(() => {
		setTitle(formatMessage({ id: 'account.alerts' }));
	}, [setTitle, formatMessage]);

	return (
		<article>
			<Title>
				<FormattedMessage id="alert-options.title" />
			</Title>

			{(!showProfileOptions || !showScheduleOptions) && (
				<div className="flex items-center gap-4 px-6 mb-4 md:px-0">
					{!showProfileOptions && (
						<button onClick={() => setShowProfileOptions(true)} className="flex items-center px-4 py-2 text-sm bg-white border border-gray-200 rounded hover:border-gray-300">
							<FontAwesomeIcon icon={['fad', 'user-tie']} className="mr-2 text-blue-500" />
							<span>
								<FormattedMessage id="notifications.profile.title" />
							</span>
							<span className="px-1.5 py-0.5 ml-1 text-xs leading-none text-white bg-red-600 rounded-full">
								<FormattedMessage id="new" />
							</span>
							<FontAwesomeIcon icon="caret-right" className="ml-4" />
						</button>
					)}
					{!showScheduleOptions && (
						<button onClick={() => setShowScheduleOptions(true)} className="flex items-center px-4 py-2 text-sm bg-white border border-gray-200 rounded hover:border-gray-300">
							<FontAwesomeIcon icon={['fad', 'newspaper']} className="mr-2 text-gray-400" />
							<span>
								<FormattedMessage id="alert-options.schedule" />
							</span>
							<FontAwesomeIcon icon="caret-right" className="ml-4" />
						</button>
					)}
				</div>
			)}

			{(showProfileOptions || showScheduleOptions) && (
				<div className="grid grid-cols-1 gap-4 mb-8 md:grid-cols-2">
					{showProfileOptions && <NotificationProfileManager onCollapse={() => setShowProfileOptions(false)} />}
					{showScheduleOptions && <NotificationScheduleManager onCollapse={() => setShowScheduleOptions(false)} />}
				</div>
			)}

			{welcome && <DialogNotificationProfilePicker isWelcome={true} />}

			<NotificationManager />
		</article>
	);
};
