import untypedColors from '@assets/color-palette.json';
import LabelsImage from '@assets/images/labels.svg?react';
import { type EditableLabelType, LabelSelector } from '@components/Labels';
import { Alert, Button, HelperText, Input, Intent, Label, Modal, ModalBody, ModalFooter, type ModalProps, Row, Size, Toggle, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import { useLocalStorage } from '@hooks/useLocalStorage';
import type { ColorType } from '@types';
import Card from '@ui/Card';
import { ColorPicker } from '@ui/ColorPicker';
import { EmojiPicker } from '@ui/EmojiPicker';
import { TranslatableInput } from '@ui/TranslatableInput';
import classNames from 'classnames';
import 'emoji-picker-element';
import { type ButtonHTMLAttributes, type FC, type FormEvent, type PropsWithChildren, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import type { WretchError, WretchResponse } from 'wretch';
import { getLabelText, removeLabel } from './utils';

const colors: ColorType[] = untypedColors;

type SelectedLabelType = Partial<
	Omit<EditableLabelType, 'property'> & {
		property: Partial<EditableLabelType['property']>;
	}
>;

export const OrganizationLabels = () => {
	const { client } = useClient();
	const { locale } = useIntl();
	const queryClient = useQueryClient();

	const [selectedLabel, setSelectedLabel] = useState<SelectedLabelType>();
	const [translatable, setTranslatable] = useState(locale !== 'en');

	const { data: labels } = useQuery(['organization', 'properties'], async () => await client.url('organization/properties').get().json<EditableLabelType[]>());

	const { mutate: save, isLoading: isSaving } = useMutation<EditableLabelType, WretchError, SelectedLabelType>(
		async label => {
			if (label.Id) {
				return await client
					.url(`organization/properties/${label.Id}`)
					.json({
						property: label.property?.Id,
						label: label.Label,
						emoji: label.Emoji,
						color: label.Color,
						is_multi: label.IsMulti
					})
					.put()
					.json<EditableLabelType>();
			}

			return await client
				.url(`organization/properties`)
				.json({
					property: label.property?.Id ?? '',
					label: label.Label,
					emoji: label.Emoji,
					color: label.Color,
					is_multi: label.IsMulti
				})
				.post()
				.json<EditableLabelType>();
		},
		{
			onSuccess: (label, { Id, property }) => {
				const isOption = !!property;

				if (!!Id) {
					toast.success(<FormattedMessage id={isOption ? 'properties.option_updated' : 'properties.property_updated'} />);
				} else {
					toast.success(<FormattedMessage id={isOption ? 'properties.option_created' : 'properties.property_created'} />);
				}

				queryClient.invalidateQueries(['organization', 'properties']);
				setSelectedLabel(undefined);
			}
		}
	);

	const { mutate: _delete, isLoading: isDeleting } = useMutation<WretchResponse, WretchError, EditableLabelType>(
		async label => await client.url(`organization/properties/${label.Id}`).delete().res(),
		{
			onError: (_error, label, previousLabels) => {
				queryClient.setQueryData(['organization', 'properties'], previousLabels);
				setSelectedLabel(label);
			},
			onMutate: async label => {
				await queryClient.cancelQueries(['organization', 'properties']);

				const previousLabels = queryClient.getQueryData<EditableLabelType[]>(['organization', 'properties']);

				if (previousLabels === undefined) {
					return undefined;
				}

				queryClient.setQueryData(['organization', 'properties'], removeLabel(label, previousLabels));
				setSelectedLabel(undefined);

				return previousLabels;
			},
			onSuccess: (_data, { options }) => {
				toast.success(<FormattedMessage id={!options ? 'properties.option_deleted' : 'properties.property_deleted'} />);
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();

		if (!selectedLabel) {
			return;
		}

		save(selectedLabel);
	};

	useEffect(() => {
		setTranslatable(Object.keys(selectedLabel?.Label ?? {}).length > 1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedLabel?.Id]);

	const selectedIsOption = !!selectedLabel?.property;

	return (
		<>
			<Card>
				<div className="flex items-start">
					<div className="prose-sm prose">
						<p>
							<FormattedMessage id="properties.intro" />
						</p>
					</div>
					<div className="hidden ml-6 sm:block">
						<LabelsImage className="w-48" />
					</div>
				</div>
				<Alert variant={Variant.info} className="mt-8">
					<p>
						<FormattedMessage
							id="properties.automation_tip"
							values={{
								link: msg => (
									<Link to="/organization/settings" className="underline">
										{msg}
									</Link>
								)
							}}
						/>
					</p>
				</Alert>
				<div className="grid grid-cols-3 gap-4 my-12">
					<div className="space-y-8">
						<button
							onClick={() => setSelectedLabel({})}
							className="flex items-center w-full px-2 py-1 space-x-1 text-xs text-left text-gray-500 transition-colors border border-gray-300 border-dashed rounded-full hover:bg-gray-50 bg-gray-white">
							<FontAwesomeIcon icon="plus" />
							<span>
								<FormattedMessage id="properties.create_property" />
							</span>
						</button>
						{labels?.map(label => (
							<div key={label.Id} className="overflow-hidden border rounded-2xl">
								<button
									className={classNames('bg-gray-50 w-full text-sm px-3 py-1.5 text-gray-500 font-medium flex items-center justify-between', {
										'border-blue-500': label.Id === selectedLabel?.Id,
										'hover:bg-gray-50': label.Id !== selectedLabel?.Id
									})}
									onClick={() => setSelectedLabel(label)}>
									<span>{getLabelText(label, locale)}</span>
									{label.Id === selectedLabel?.Id ? <span className={classNames('w-2 h-2 rounded-full bg-gray-500')} /> : <FontAwesomeIcon icon="pencil" size="sm" />}
								</button>

								<div className="p-2 space-y-2">
									{label.options?.map(option => (
										<LabelOption key={option.Id} option={option} selected={option.Id === selectedLabel?.Id} onClick={() => setSelectedLabel(option)} />
									))}
									<button
										onClick={() => setSelectedLabel({ property: label })}
										className="flex items-center w-full px-2 py-1 space-x-1 text-xs text-left text-gray-500 transition-colors border border-gray-300 border-dashed rounded-full hover:bg-gray-50 bg-gray-white">
										<FontAwesomeIcon icon="plus" />
										<span>
											<FormattedMessage id="properties.add_option" />
										</span>
									</button>
								</div>
							</div>
						))}
					</div>
					{selectedLabel ? (
						<form onSubmit={onSubmit} className="relative flex flex-col self-start col-span-2 p-4 bg-gray-50 rounded-xl">
							<div className="flex-1">
								<header className="flex items-center justify-between mb-8">
									<div>
										{selectedIsOption && (
											<p className="mb-1 text-xs text-gray-400">
												<FontAwesomeIcon icon="tags" size="sm" className="mr-1" />
												{getLabelText(selectedLabel.property, locale)}
											</p>
										)}
										<h1 className="text-sm font-semibold text-gray-500">
											<FormattedMessage
												id={selectedIsOption ? 'properties.title_option' : 'properties.title_property'}
												values={{
													state: selectedLabel.Id ? 'edit' : 'creating',
													label: getLabelText(selectedLabel as EditableLabelType, locale),
													em: msg => <span className={colorClassName(selectedLabel.Color as string)['textClassName']}>{msg}</span>
												}}
											/>
										</h1>
									</div>
									<div>
										<Button icon="times" size={Size.sm} circle intent={Intent.secondary} variant={Variant.light} onClick={() => setSelectedLabel(undefined)} type="button" />
									</div>
								</header>
								<Row>
									<div className="flex items-center justify-between">
										<Label>
											<FormattedMessage id="properties.label" />
										</Label>
										<Label htmlFor="translatable" className="flex items-center text-xs">
											<FormattedMessage id="properties.translate" />
											<Toggle size={Size.sm} className="ml-4" checked={translatable} onChange={e => setTranslatable(e.target.checked)} />
										</Label>
									</div>
									{translatable ? (
										<TranslatableInput
											block
											size={Size.sm}
											values={selectedLabel.Label}
											onChange={(e, locale) => setSelectedLabel({ ...selectedLabel, Label: { ...selectedLabel.Label, [locale]: e.target.value } })}
										/>
									) : (
										<Input
											type="text"
											block
											size={Size.sm}
											value={selectedLabel.Label?.en_US ?? ''}
											onChange={e => setSelectedLabel({ ...selectedLabel, Label: { en_US: e.target.value } })}
										/>
									)}
								</Row>
								{selectedIsOption && (
									<Row>
										<Label>
											<FormattedMessage id="properties.color" />
										</Label>
										<ColorPicker value={selectedLabel.Color ?? 'gray'} onChange={color => setSelectedLabel({ ...selectedLabel, Color: color ?? 'gray' })} />
									</Row>
								)}
								{selectedIsOption && (
									<Row>
										<Label>
											<FormattedMessage id="properties.emoji" />
										</Label>
										<div className="flex items-center">
											<EmojiPicker onChange={emoji => setSelectedLabel({ ...selectedLabel, Emoji: emoji })}>
												<button type="button" className="inline-flex items-center justify-center w-8 h-8 p-3 text-lg bg-white rounded-full">
													{selectedLabel.Emoji}
												</button>
											</EmojiPicker>
											<button type="button" className="ml-4 text-xs italic text-gray-500" onClick={() => setSelectedLabel({ ...selectedLabel, Emoji: null })}>
												<FormattedMessage id="properties.remove_emoji" />
											</button>
										</div>
									</Row>
								)}
								{!selectedIsOption && (
									<Row>
										<Label>
											<FormattedMessage id="properties.is_multi" />
											<Toggle className="ml-4" checked={selectedLabel.IsMulti} onChange={e => setSelectedLabel({ ...selectedLabel, IsMulti: e.target.checked })} />
										</Label>
										<HelperText>
											<FormattedMessage id="properties.is_multi_description" />
										</HelperText>
									</Row>
								)}
							</div>
							<Row className="flex items-center justify-between mt-6 space-x-2">
								<Button variant={Variant.success} type="submit" icon="save" size={Size.sm} loading={isSaving} disabled={isSaving}>
									<FormattedMessage id="save" />
								</Button>
								{!!selectedLabel.Id && (
									<Button
										onClick={() => _delete(selectedLabel as EditableLabelType)}
										variant={Variant.danger}
										intent={Intent.tertiary}
										type="button"
										size={Size.xs}
										loading={isDeleting}
										disabled={isDeleting}>
										<FormattedMessage id="delete" />
									</Button>
								)}
							</Row>
						</form>
					) : (
						<div className="relative flex flex-col self-start col-span-2 px-4 py-16 bg-gray-50 rounded-xl">
							<p className="text-sm italic text-center text-gray-500">
								<FormattedMessage id="properties.select_or_create" />
							</p>
						</div>
					)}
				</div>
			</Card>
			{labels !== undefined && labels.length === 0 && <LabelsWizard />}
		</>
	);
};

const colorClassName = (color: string, defaultColor: string = 'gray') => colors.find(_color => _color.value === color) ?? colors.find(_color => _color.value === defaultColor)!;

type LabelOptionProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	option: EditableLabelType;
	selected?: boolean;
};

const LabelOption: FC<LabelOptionProps> = ({ option, selected = false, ...buttonProps }) => {
	const { locale } = useIntl();

	const color = colors.find(_color => _color.value === option.Color);

	return (
		<button
			className={classNames(
				color?.backgroundClassName,
				color?.borderClassName,
				color?.textClassName,
				'flex space-x-1 w-full px-2 py-1 text-xs text-left text-gray-500 border border-gray-300 rounded-full bg-gray-50 justify-between items-center'
			)}
			{...buttonProps}>
			<span className="flex items-center justify-center space-x-1">
				<span>{option.Emoji}</span>
				<span>{getLabelText(option, locale)}</span>
			</span>
			{selected && <span className={classNames('w-2 h-2 rounded-full', color?.indicatorClassName)} />}
		</button>
	);
};

type LabelWizardProps = Omit<ModalProps, 'isOpen'>;
type LabelPresetType = EditableLabelType & { status: string };

const LabelsWizard: FC<LabelWizardProps> = ({ ...modalProps }) => {
	const { client } = useClient();
	const { locale } = useIntl();
	const queryClient = useQueryClient();
	const [isOpen, setIsOpen] = useLocalStorage<boolean>('model-properties.wizard', true);

	const [selectedPresets, setSelectedPresets] = useState<LabelPresetType[]>([]);
	const [isCreating, setIsCreating] = useState(false);

	const { data: presets = [], isLoading } = useQuery(['organization', 'properties', 'presets'], async () => await client.url('organization/properties/presets').get().json<LabelPresetType[]>(), {
		staleTime: Infinity
	});

	const toggle = (label: LabelPresetType) => {
		setSelectedPresets(presets => (presets.some(preset => preset.Id === label.Id) ? presets.filter(preset => preset.Id !== label.Id) : [...presets, label]));
	};

	const { mutateAsync: create } = useMutation<EditableLabelType, WretchError, LabelPresetType>(async label => {
		return await client
			.url(`organization/properties`)
			.json({
				property: label.property?.Id ?? '',
				label: label.Label,
				emoji: label.Emoji,
				color: label.Color,
				is_multi: label.IsMulti
			})
			.post()
			.json<EditableLabelType>();
	});

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();

		try {
			setIsCreating(true);
			await Promise.all(
				selectedPresets.map(async preset => {
					try {
						setSelectedPresets(presets.map(_preset => (preset.Id === _preset.Id ? { ..._preset, status: 'loading' } : _preset)));
						const label = await create(preset);
						await Promise.all(preset.options!.map(async option => await create({ ...option, property: label })));
						setSelectedPresets(presets.map(_preset => (preset.Id === _preset.Id ? { ..._preset, status: 'success' } : _preset)));
					} catch {
						setSelectedPresets(presets.map(_preset => (preset.Id === _preset.Id ? { ..._preset, status: 'error' } : _preset)));
					}
				})
			);
			toast.success(<FormattedMessage id="properties.properties_created" />);
			setIsOpen(false);
			queryClient.invalidateQueries(['organization', 'properties']);
		} catch {
			toast.error(<FormattedMessage id="errors.generic.description" />);
		} finally {
			setIsCreating(false);
		}
	};

	return (
		<Modal isOpen={isOpen} onSubmit={onSubmit} {...modalProps}>
			<ModalBody>
				<span className="inline-flex px-3 py-1 mb-6 text-sm font-semibold text-white uppercase bg-red-600 rounded">
					<FormattedMessage id="new" />
				</span>
				<div className="prose-sm prose prose-h1:text-lg prose-h1:font-bold">
					<h1>
						<FormattedMessage id="properties.presets_title" />
					</h1>
					<p>
						<FormattedMessage id="properties.presets_intro_1" />
					</p>
					<p>
						<FormattedMessage id="properties.presets_intro_2" />
					</p>
					<p className="font-medium">
						<FormattedMessage id="properties.presets_choose" />
					</p>
				</div>
				<div className="grid grid-cols-3 gap-4 mt-6">
					{presets.map(property => (
						<BigButton key={property.Id} status={isCreating ? 'loading' : 'idle'} selected={selectedPresets.some(p => p.Id === property.Id)} onClick={() => toggle(property)}>
							<h1 className="px-2 py-1 text-sm text-gray-700 bg-gray-50">{getLabelText(property, locale)}</h1>
							<div className="flex flex-col gap-2 p-2">
								{property.options!.map(option => (
									<LabelSelector key={option.Id} label={option as LabelPresetType} disabled={true} />
								))}
							</div>
						</BigButton>
					))}
				</div>
			</ModalBody>
			<ModalFooter>
				<Button variant={Variant.primary} type="submit" disabled={isLoading || selectedPresets.length === 0 || isCreating}>
					<FormattedMessage id="apply" />
				</Button>
				<Button variant={Variant.light} type="button" intent={Intent.secondary} disabled={isLoading || isCreating} onClick={() => setIsOpen(false)}>
					<FormattedMessage id="close" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};

type BigButtonProps = PropsWithChildren<{
	selected?: boolean;
	status?: string;
	onClick?: () => void;
}>;

const BigButton: FC<BigButtonProps> = ({ selected = false, status = 'idle', children, ...buttonProps }) => {
	return (
		<button
			type="button"
			className={classNames('relative overflow-hidden text-left hover:shadow-md transition-all duration-150 focus:outline-none w-full items-stretch bg-white border-2 rounded-md flex', {
				'border-blue-500': selected && status !== 'success' && status !== 'error',
				'border-gray-300': !selected && status !== 'success' && status !== 'error',
				'border-green-600': status === 'success',
				'border-red-600': status === 'error'
			})}
			{...buttonProps}>
			<div
				className={classNames('py-1 px-2 transition-colors duration-150', {
					'bg-blue-500': selected && status !== 'success' && status !== 'error',
					'bg-gray-300': !selected && status !== 'success' && status !== 'error',
					'bg-green-600': status === 'success',
					'bg-red-600': status === 'error'
				})}>
				{status === 'loading' && selected ? (
					<FontAwesomeIcon icon="spinner" fixedWidth pulse className="text-white" />
				) : (
					<FontAwesomeIcon icon={selected ? 'check-circle' : ['far', 'circle']} fixedWidth className="text-white" />
				)}
			</div>
			<div className="flex-1">{children}</div>
		</button>
	);
};
