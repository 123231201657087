import { FILE_LIST_RESULT_LIMIT, type FileManagerResults, type SelectionMode, type SelectionType, addFileables, removeFileables, replaceFileable } from '@components/FileManager';
import type { UploaderHandles } from '@components/Upload';
import { useView } from '@hooks/use-view';
import { usePrevious } from '@hooks/usePrevious';
import type { Fileable } from '@types';
import { createFileableComparator, fileableComparator } from '@utils/Utils';
import differenceWith from 'lodash.differencewith';
import uniqWith from 'lodash.uniqwith';
import { type Dispatch, type FC, type PropsWithChildren, type SetStateAction, createContext, useCallback, useEffect, useState } from 'react';
import { type InfiniteData, useQueryClient } from 'react-query';

export const FileManagerContext = createContext<
	| {
			fileables: Fileable[];
			setSelection: Dispatch<SetStateAction<SelectionType>>;
			setSelectionTo: (item: Fileable) => void;
			selectAll: () => void;
			selection: SelectionType;
			selectionMode: SelectionMode;
			add?: (fileables: Fileable[]) => InfiniteData<FileManagerResults> | undefined;
			update?: (fileable: Fileable) => InfiniteData<FileManagerResults> | undefined;
			remove?: (fileables: Fileable[]) => InfiniteData<FileManagerResults> | undefined;
			openUploadFiles: () => void;
	  }
	| undefined
>(undefined);

type FileManagerProviderProps = {
	fileables: Fileable[];
	uploadManager?: UploaderHandles;
};

export const FileManagerProvider: FC<PropsWithChildren<FileManagerProviderProps>> = ({ fileables, uploadManager, children }) => {
	const queryClient = useQueryClient();
	const { key } = useView();

	const [selection, setSelection] = useState<SelectionType>();
	const [lastSelectedItem, setLastSelectedItem] = useState<Fileable | null>(null);

	const add = useCallback(
		(fileables: Fileable[]) => {
			const previousState = queryClient.getQueryData<InfiniteData<FileManagerResults>>(['files', key]);

			if (previousState === undefined) {
				return undefined;
			}

			const newFileables = addFileables(fileables, previousState, { limit: FILE_LIST_RESULT_LIMIT });
			queryClient.setQueryData<InfiniteData<FileManagerResults>>(['files', key], newFileables);

			return previousState;
		},
		[queryClient, key]
	);

	const update = useCallback(
		(fileable: Fileable) => {
			const previousState = queryClient.getQueryData<InfiniteData<FileManagerResults>>(['files', key]);

			if (previousState === undefined) {
				return undefined;
			}

			const newFileables = replaceFileable(fileable, previousState);
			queryClient.setQueryData<InfiniteData<FileManagerResults>>(['files', key], newFileables);

			return previousState;
		},
		[queryClient, key]
	);

	const remove = useCallback(
		(fileables: Fileable[]) => {
			const previousState = queryClient.getQueryData<InfiniteData<FileManagerResults>>(['files', key]);

			if (previousState === undefined) {
				return undefined;
			}

			const newFileables = removeFileables(fileables, previousState);
			queryClient.setQueryData<InfiniteData<FileManagerResults>>(['files', key], newFileables);

			return previousState;
		},
		[queryClient, key]
	);

	const previousSelection = usePrevious(selection);

	useEffect(() => {
		if (selection?.parent === undefined && selection?.fileables !== undefined && selection?.fileables.length === 0) {
			setSelection(undefined);
		}
	}, [selection]);

	useEffect(() => {
		// if (!selection?.fileables?.length && !selection?.parent) {
		// setSelection(undefined);
		// return;
		// }

		// Will always be 1 or 0
		const newlySelectedFileable = differenceWith(selection?.fileables ?? [], previousSelection?.fileables ?? [], fileableComparator);
		if (newlySelectedFileable.length === 1) {
			setLastSelectedItem(newlySelectedFileable[0]);
		}
	}, [previousSelection, selection]);

	let lastSelectedIndex = lastSelectedItem !== null ? fileables.findIndex(createFileableComparator(lastSelectedItem)) : -1;

	const setSelectionTo = (item: Fileable) => {
		if (lastSelectedIndex === -1) return;

		const selectedIndex = fileables.findIndex(createFileableComparator(item));

		if (selectedIndex === -1) return;

		const [start, end] = selectedIndex > lastSelectedIndex ? [lastSelectedIndex, selectedIndex] : [selectedIndex, lastSelectedIndex];
		const rowsToSelect: Fileable[] = [];

		for (let i = start; i <= end; i++) {
			if (!selection?.fileables?.some(createFileableComparator(item))) {
				rowsToSelect.push(fileables[i]);
			}
		}

		setSelection(selection => ({ ...selection, fileables: uniqWith([...(selection?.fileables ?? []), ...rowsToSelect], (a, b) => a.id() === b.id()) }));
	};

	const selectAll = () => {
		setSelection({ fileables });
	};

	return (
		<FileManagerContext.Provider
			value={{ add, remove, update, setSelection, selection, setSelectionTo, selectAll, selectionMode: 'multiple', fileables, openUploadFiles: () => uploadManager.chooseFiles() }}>
			{children}
		</FileManagerContext.Provider>
	);
};
