import { DateTimeDisplay } from '@components/DateTime';
import { DialogDownloadFileables, DialogPreview, FileLabel, useSecureSpace } from '@components/FileManager';
import { LabelSelector } from '@components/Labels';
import {
	type AttachmentType,
	type Comment,
	DialogDeleteMessage,
	type FileRequestType,
	MessageFileAttachment,
	MessageFileRequestAttachment,
	MessageForm,
	MessageListContext,
	MessagePaymentRequestAttachment,
	removeMessage,
	replaceMessage
} from '@components/Message';
import { NotificationConfirmationToastContents } from '@components/NotificationsListener';
import type { ResourceLengthAwarePaginatorType } from '@components/Pagination';
import type { PaymentRequest } from '@components/PaymentRequest';
import { Badge, Button, Intent, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIndexedDatabase } from '@hooks/use-indexed-db';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import useUrlSearch from '@hooks/useUrlSearch';
import File from '@models/File';
import Folder from '@models/Folder';
import User from '@models/User';
import { MessagesMode, type Modify, Permissions } from '@types';
import { CopyToClipboard } from '@ui/CopyToClipboard';
import Dropdown, { DropdownGroup, DropdownItem } from '@ui/Dropdown';
import FontAwesomeSvg from '@ui/FontAwesomeSvg';
import { MarkdownContent } from '@ui/MarkdownContent';
import { Tooltip } from '@ui/Tooltip';
import UserAvatar from '@ui/UserAvatar';
import { unique } from '@utils/ArrayUtils';
import classNames from 'classnames';
import { type FC, memo, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { type InfiniteData, useMutation, useQueryClient } from 'react-query';

type MessageItemProps = {
	message: Comment;
	level?: number;
	parent?: Comment | null;
};

export const MessageItem: FC<MessageItemProps> = memo(({ message, level = 1, parent = null }) => {
	const { fileable } = useContext(MessageListContext);
	const { account } = useAccount();
	const secureSpace = useSecureSpace(fileable!);
	const db = useIndexedDatabase();

	const [state, setState] = useState('view');
	const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

	const container = useRef<HTMLDivElement>(null);
	const { comment: highlightedCommentId = '', frid: fileRequestId } = useUrlSearch();

	const isHighlighted = highlightedCommentId === String(message.ID);

	const userCanPost = useMemo(() => {
		const messagesMode = secureSpace?.MessagesMode ?? MessagesMode.OpenToAll;

		if (messagesMode === MessagesMode.OpenToAll) {
			return true;
		}

		return secureSpace?.pivot?.Permissions === Permissions.ReadWrite;
	}, [secureSpace]);

	const checkForReplyDraft = useCallback(async () => {
		if (await (await db).get('drafts', `${account!.ID!}-${fileable!.id()}.${message.ID}`)) {
			setState('reply');
		}
	}, [account!.ID!, db, fileable!.id(), message.ID]);

	useEffect(() => {
		// Pause for the moment, since it autofocuses inside the RTE, we don't want that
		// checkForReplyDraft();
	}, [checkForReplyDraft]);

	useLayoutEffect(() => {
		if (isHighlighted && container.current !== null) {
			container.current.scrollIntoView(true);
			if (!fileRequestId) {
				setState('reply');
			}
		}
	}, [fileRequestId, isHighlighted, message.ID]);

	const onMessageEdited = () => {
		toast.success(<FormattedMessage id="comments.edited" />);
		return;
	};

	return (
		<>
			<div
				className={classNames({
					'opacity-50': message.archived_at !== null,
					'animate-highlight': isHighlighted
				})}
				ref={container}>
				<div className="flex items-stretch mb-3">
					<div
						className={classNames('flex-1 min-w-0', {
							'ml-12 sm:ml-24': level > 1
						})}>
						{/* Header */}
						<MessageItemHeader message={message} onEdit={() => setState('edit')} onDelete={() => setIsConfirmingDelete(true)} />

						{state !== 'edit' && <MessageContents message={message} parent={parent} onShowReplyForm={() => setState('reply')} />}

						{/* Input */}
						{state === 'edit' && userCanPost && (
							<div className="mt-4">
								<MessageForm message={message} parent={parent ?? undefined} onClose={() => setState('view')} onPosted={onMessageEdited} />
							</div>
						)}

						{/* Reply form */}
						{state === 'reply' && userCanPost && (
							<div className={classNames('mt-6', { 'pl-16 ml-5': level === 1 })}>
								<MessageItemHeaderReply parent={message} />
								<div className="my-3 mr-4">
									<MessageForm parent={parent || message} onClose={() => setState('view')} />
								</div>
							</div>
						)}

						{/* Replies */}
						{!!message.replies && message.replies.length > 0 && (
							<div className="mt-6 space-y-4">
								{message.replies.map(reply => (
									<MessageItem key={reply.ID} level={level + 1} message={reply} parent={message} />
								))}
							</div>
						)}
					</div>
				</div>
			</div>
			{isConfirmingDelete && <DialogDeleteMessage message={message} onAfterClose={() => setIsConfirmingDelete(false)} />}
		</>
	);
});

type MessageItemHeaderProps = {
	message: Comment;
	onEdit?: () => void;
	onDelete?: () => void;
};

const MessageItemHeader: FC<MessageItemHeaderProps> = ({ message, onEdit = () => undefined, onDelete = () => undefined }) => {
	const { client } = useClient();
	const queryClient = useQueryClient();
	const { fileable, queryKey } = useContext(MessageListContext);
	const { formatMessage, formatList } = useIntl();
	const { account } = useAccount();

	const [copied, setCopied] = useState(false);

	const { mutate: pin } = useMutation(async () => await client.url(`comments/${message.ID}/pin`).put().json<Comment>(), {
		onSettled: () => {
			queryClient.invalidateQueries(queryKey);
		},
		onSuccess: () => {
			toast.success(<FormattedMessage id="comments.pinned" />);
		}
	});

	const { mutate: unpin } = useMutation(async () => await client.url(`comments/${message.ID}/unpin`).put().json<Comment>(), {
		onSettled: () => {
			queryClient.invalidateQueries(queryKey);
		},
		onSuccess: () => {
			toast.success(<FormattedMessage id="comments.unpinned" />);
		}
	});

	const { mutate: archive } = useMutation(async () => await client.url(`comments/${message.ID}`).query({ archive: 1 }).delete().json(), {
		onSuccess: () => {
			toast.success(<FormattedMessage id="comments.archived" />);
		},
		onMutate: async () => {
			await queryClient.cancelQueries(queryKey);

			const previousMessages = queryClient.getQueryData<InfiniteData<ResourceLengthAwarePaginatorType<Comment>>>(queryKey);

			if (previousMessages === undefined) {
				return undefined;
			}

			const newMessages = removeMessage(message, previousMessages);

			if (!message.parent) {
				newMessages.pages[0].meta.total = newMessages.pages[0].meta.total - 1;
			}

			queryClient.setQueryData<InfiniteData<ResourceLengthAwarePaginatorType<Comment>>>(queryKey, newMessages);

			return previousMessages;
		},
		onError: (_error, _variables, previousMessages) => {
			queryClient.setQueryData(queryKey, previousMessages);
		}
	});

	const { mutate: unarchive } = useMutation(async () => await client.url(`comments/${message.ID}/unarchive`).put().json(), {
		onSuccess: () => {
			toast.success(<FormattedMessage id="unarchived" />);
			queryClient.invalidateQueries(queryKey);
		}
	});

	const link = `${window.location.origin}${fileable!.getUrl('comments')}?comment=${message.ID}&ref=message_link`;

	return (
		<>
			<div className="relative">
				<div
					className={classNames('absolute hidden sm:block', {
						'-left-16 top-4': !message.parent,
						'-left-12 top-3.5': message.parent
					})}>
					<UserAvatar size={message.parent ? Size.sm : Size.md} showVerification user={message.creator} />
				</div>
			</div>
			<div
				className={classNames('relative group bubble-arrow flex items-center gap-3', {
					pinned: message.pinned_at !== null,
					'bg-red-100 important': message.Priority === 1,
					'bg-yellow-50': message.pinned_at !== null && message.Priority === 0,
					'bg-gray-50': message.pinned_at === null && message.Priority === 0,
					'border-t border-b border-l border-gray-200': message.parent,
					'p-4': !message.parent,
					'p-2 bubble-arrow-reply': message.parent
				})}>
				<div className="flex items-center flex-1">
					<div className="flex-shrink-0 mr-3 sm:hidden">
						<UserAvatar size={message.parent ? Size.xs : Size.sm} showVerification user={message.creator} />
					</div>
					<div>
						<p>
							<span className="text-sm font-semibold md:text-base">{message.creator.Name}</span>
							<span className="text-xs text-gray-400">
								{message.creator.Title && `, ${message.creator.Title}`}
								<span className="mx-2">&middot;</span>
								<CopyToClipboard text={link} onCopy={() => setCopied(true)}>
									<span>
										{message.created_at !== null ? (
											<a href={link} onClick={e => e.preventDefault()}>
												<DateTimeDisplay value={message.created_at} defaultFormat="relative" />
											</a>
										) : (
											<FormattedMessage id="comments.posting" />
										)}
										{/* Updated at */}
										{message.created_at !== message.updated_at && (
											<Tooltip
												tip={
													<FormattedMessage
														id="comments.modified_at"
														values={{ date: <DateTimeDisplay value={message.updated_at} defaultFormat="relative" showTooltip={false} /> }}
													/>
												}>
												<span>
													{' '}
													<FormattedMessage id="comments.modified" />
												</span>
											</Tooltip>
										)}
										{copied && (
											<span className="ml-2 text-green-600">
												<FontAwesomeIcon icon="check" className="mr-1" />
												<FormattedMessage id="comments.copied" />
											</span>
										)}
									</span>
								</CopyToClipboard>
								{message.audience === 'members' && !!message.users && (
									<>
										<span className="mx-2">&middot;</span>
										<Tooltip
											tip={formatMessage({ id: 'comments.audience.members-visible.tooltip' }, { n: message.users.length, list: formatList(message.users.map(u => u.Name)) })}>
											<span>
												<FontAwesomeIcon icon={['fad', 'users']} className="transition-colors hover:text-gray-700" />
											</span>
										</Tooltip>
									</>
								)}
								{message.audience === 'members' && !message.users && (
									<>
										<span className="mx-2">&middot;</span>
										<Tooltip tip={formatMessage({ id: 'comments.audience.members-invisible.tooltip' })}>
											<span>
												<FontAwesomeIcon icon={['fad', 'users']} className="transition-colors hover:text-gray-700" />
											</span>
										</Tooltip>
									</>
								)}
								{message.audience === 'self' && (
									<>
										<span className="mx-2">&middot;</span>
										<Tooltip tip={formatMessage({ id: 'comments.audience.self.tooltip' })}>
											<span>
												<FontAwesomeIcon icon="lock" className="transition-colors hover:text-gray-700" />
											</span>
										</Tooltip>
									</>
								)}
								{message.Source === 'scheduled' && (
									<>
										<span className="mx-2">&middot;</span>
										<Tooltip tip={formatMessage({ id: 'comments.scheduled.tooltip' })}>
											<span>
												<FontAwesomeIcon icon="history" className="transition-colors hover:text-gray-700" />
											</span>
										</Tooltip>
									</>
								)}
							</span>
						</p>

						{/* Properties */}
						<div className="flex items-center gap-0.5 flex-wrap group flex-1 mt-1 " onClick={e => e.stopPropagation()}>
							{message.pinned_at !== null && (
								<FileLabel icon="thumbtack" color="amber">
									<FormattedMessage id="comments.message_pinned" />
								</FileLabel>
							)}
							{message.archived_at !== null && (
								<FileLabel icon="archive" color="gray">
									<FormattedMessage id="comments.message_pinned" />
								</FileLabel>
							)}
							{message.Priority === 1 && (
								<FileLabel icon="flag" color="red">
									<FormattedMessage id="comments.important" />
								</FileLabel>
							)}
							{/* Sometimes it's not included in the response, we'll fix that eventually */}
							{(message.properties ?? []).map((prop, index) => (
								<LabelSelector key={index} label={prop} model={message} />
							))}
							{account!.hasFullAccess() && <LabelSelector key={message.properties?.length ?? 0} model={message} />}
						</div>
					</div>
				</div>
				{(fileable!.pivot?.Permissions === Permissions.ReadWrite || message.creator.ID === account!.ID) && (
					<Dropdown>
						<Button circle size={Size.sm} className="text-gray-400">
							<FontAwesomeSvg id="fa-ellipsis-v" />
						</Button>
						<DropdownGroup>
							{/*	Pin */}
							{fileable!.pivot?.Permissions === Permissions.ReadWrite && message.parent === null && (
								<>
									{message.pinned_at === null && (
										<DropdownItem icon={['fas', 'thumbtack']} onClick={() => pin()}>
											<FormattedMessage id="comments.pin" />
										</DropdownItem>
									)}
									{message.pinned_at !== null && (
										<DropdownItem icon={['far', 'thumbtack']} onClick={() => unpin()}>
											<FormattedMessage id="comments.unpin" />
										</DropdownItem>
									)}
								</>
							)}

							{/*	Edit */}
							{message.creator.ID === account!.ID && (
								<DropdownItem icon="edit" onClick={onEdit} disabled={message.Locked || !!message.archived_at || !!message.parent?.archived_at}>
									<FormattedMessage id="comments.edit" />
								</DropdownItem>
							)}

							{/* Archive */}
							{fileable!.pivot?.Permissions === Permissions.ReadWrite && message.archived_at === null && (
								<DropdownItem icon="archive" onClick={() => archive()} disabled={message.Locked || !!message.parent?.archived_at}>
									<FormattedMessage id="comments.archive" />
								</DropdownItem>
							)}

							{/* Unarchive */}
							{fileable!.pivot?.Permissions === Permissions.ReadWrite && message.archived_at !== null && (
								<DropdownItem icon="archive" onClick={() => unarchive()} disabled={message.Locked || !!message.parent?.archived_at}>
									<FormattedMessage id="unarchive" />
								</DropdownItem>
							)}

							{/* Delete */}
							{(message.creator.ID === account!.ID || fileable!.pivot?.Permissions === Permissions.ReadWrite) && (
								<DropdownItem icon="trash" onClick={onDelete} disabled={message.Locked}>
									<FormattedMessage id="delete" />
								</DropdownItem>
							)}
						</DropdownGroup>
					</Dropdown>
				)}
			</div>
		</>
	);
};

type MessageContentsProps = {
	message: Comment;
	parent?: Comment | null;
	onShowReplyForm?: () => void;
};

const MessageContents: FC<MessageContentsProps> = ({ message, parent = null, onShowReplyForm = () => undefined }) => {
	const { account } = useAccount();
	const { formatMessage } = useIntl();
	const { fileable, queryKey } = useContext(MessageListContext);
	const { client } = useClient();
	const queryClient = useQueryClient();
	const { comment: highlightedCommentId = '' } = useUrlSearch();

	const [attachmentPreview, setAttachmentPreview] = useState<File>();
	const [showDownloadAttachmentsModal, setShowDownloadAttachmentsModal] = useState(false);

	const { mutate: like, isLoading: isLiking } = useMutation(async () => await client.url(`comments/${message.ID}/likes`).put().json<Comment>(), {
		onError: (_error, _message, oldMessages) => {
			queryClient.setQueryData(queryKey, oldMessages);
		},
		onMutate: async () => {
			await queryClient.cancelQueries(queryKey);

			let currentMessages = queryClient.getQueryData<InfiniteData<ResourceLengthAwarePaginatorType<Comment>>>(queryKey);
			let messages: InfiniteData<ResourceLengthAwarePaginatorType<Comment>>;

			if (!currentMessages) {
				return undefined;
			}

			messages = replaceMessage({ ...message, liked: true, likes: unique<User>(message.likes.concat([account!.toUser()]), ['ID']) }, currentMessages);

			queryClient.setQueryData<InfiniteData<ResourceLengthAwarePaginatorType<Comment>>>(queryKey, messages);

			return currentMessages;
		},
		onSuccess: () => {
			toast.success(<FormattedMessage id="comments.liked" />);
		}
	});

	const { mutate: unlike, isLoading: isUnliking } = useMutation(async () => await client.url(`comments/${message.ID}/likes`).delete().json<Comment>(), {
		onError: (_error, _message, oldMessages) => {
			queryClient.setQueryData(queryKey, oldMessages);
		},
		onMutate: async () => {
			await queryClient.cancelQueries(queryKey);

			let currentMessages = queryClient.getQueryData<InfiniteData<ResourceLengthAwarePaginatorType<Comment>>>(queryKey);
			let messages: InfiniteData<ResourceLengthAwarePaginatorType<Comment>>;

			if (!currentMessages) {
				return undefined;
			}

			messages = replaceMessage({ ...message, liked: false, likes: message.likes.filter(u => u.ID !== account!.ID) }, currentMessages);

			queryClient.setQueryData<InfiniteData<ResourceLengthAwarePaginatorType<Comment>>>(queryKey, messages);

			return currentMessages;
		},
		onSuccess: () => {
			toast.success(<FormattedMessage id="comments.unliked" />);
		}
	});

	const likes = useMemo(
		(maxLikesListed = 3) => {
			const names = message.likes.map(like => like.Name);

			if (names.length === 0) {
				return null;
			}

			if (names.length === 1) {
				return names[0];
			}

			if (names.length <= maxLikesListed) {
				return formatMessage(
					{ id: 'comments.list_complete' },
					{
						1: names.slice(0, -1).join(', '),
						2: names.slice(-1)[0]
					}
				);
			}

			return formatMessage(
				{ id: 'comments.list_and_others' },
				{
					1: names.slice(0, maxLikesListed).join(', '),
					2: names.length - maxLikesListed
				}
			);
		},
		[message, formatMessage]
	);

	const { mutate: read, isLoading: isReading } = useMutation(async () => await client.url(`comments/${message.ID}/read`).put().json<Comment>(), {
		onSuccess: async _message => {
			await queryClient.cancelQueries(queryKey);

			let currentMessages = queryClient.getQueryData<InfiniteData<ResourceLengthAwarePaginatorType<Comment>>>(queryKey);

			if (!currentMessages) {
				return;
			}

			let messages: InfiniteData<ResourceLengthAwarePaginatorType<Comment>> = replaceMessage(_message, currentMessages);
			queryClient.setQueryData<InfiniteData<ResourceLengthAwarePaginatorType<Comment>>>(queryKey, messages);

			toast.success(
				<div>
					<h5 className="font-semibold">
						<FormattedMessage id="comments.read_receipt_sent_to" />
					</h5>
					<NotificationConfirmationToastContents users={[{ ...message.creator, Scope: 'internal' }]} />
				</div>,
				{ id: 'notification-confirmation', duration: 15000 }
			);
		}
	});

	const attachmentOnClick = async (attachment: AttachmentType | File) => {
		const file = attachment instanceof File ? attachment : new File(attachment.item);

		if (file.isPreviewable()) {
			setAttachmentPreview(file);
		} else {
			const url = await client.url(file.getRoute('download')).get().text();
			window.location.href = url;
		}
	};

	const fileAttachments = message.attachments.filter(attachment => attachment.item['@type'] === 'Document') as Modify<AttachmentType, { item: File }>[];
	const fileRequestAttachments = (message.attachments.filter(attachment => attachment.item['@type'] === 'FileRequest') as Modify<AttachmentType, { item: FileRequestType }>[]).map(attachment => {
		attachment.item.Folder = attachment.item.Folder ? new Folder(attachment.item.Folder) : null;
		return attachment;
	});
	const paymentRequestAttachments = message.attachments.filter(attachment => attachment.item['@type'] === 'PaymentRequest') as Modify<AttachmentType, { item: PaymentRequest }>[];
	const isHighlighted = highlightedCommentId === String(message.ID);

	useEffect(() => {
		if (isHighlighted && message.Comment == null) {
			read();
		}
	}, [isHighlighted, message.Comment, read]);

	const context = useMemo(() => {
		if (message.context?.constructor === Object && message.context['@type'] === 'Document') {
			return new File(message.context);
		}

		return null;
	}, [message.context]);

	if (message.Comment === null) {
		return (
			<div className={classNames('relative px-6 py-8 mb-3 text-center', { 'bg-gray-100': isHighlighted })}>
				{!isReading && (
					<button className="m-auto font-semibold transition duration-300 text-theme-primary hover:cursor-pointer hover:underline" onClick={() => read()}>
						<FormattedMessage id="comments.show_comment" values={{ user: message.creator.Name }} />
					</button>
				)}
				{isReading && <FontAwesomeIcon icon="spinner" pulse size="2x" />}
			</div>
		);
	}

	return (
		<>
			{message.Title !== null && (
				<div className="px-6 py-3 mb-4 bg-blue-50">
					<p className="text-sm font-semibold text-blue-600">{message.Title}</p>
				</div>
			)}
			<div
				className={classNames('relative', {
					'mx-6 my-8': !parent,
					'my-4 pr-4': parent
				})}>
				{message.Format === 'markdown' && <MarkdownContent size="sm">{message.Comment}</MarkdownContent>}
				{message.Format === 'prosemirror' && <div className={classNames('prose max-w-none break-words prose-sm')} dangerouslySetInnerHTML={{ __html: message.Comment }} />}
			</div>

			{/* Payment request attachments */}
			{paymentRequestAttachments.length > 0 && (
				<>
					<div className="flex items-center px-6 mb-3 space-x-4">
						<h4 className="text-xs text-gray-500">
							<FontAwesomeIcon icon="dollar-sign" className="mr-2 text-gray-300" />
							<FormattedMessage id="payment-request" />
						</h4>
						<div className="flex-1" />
					</div>
					<div className="flex flex-wrap items-start px-6 mb-3">
						{paymentRequestAttachments.map(attachment => (
							<MessagePaymentRequestAttachment key={attachment.Id} attachment={attachment} />
						))}
					</div>
				</>
			)}

			{/* File request attachments */}
			{fileRequestAttachments.length > 0 && (
				<>
					<div className="flex items-center px-6 mb-3 space-x-4">
						<h4 className="text-xs text-gray-500">
							<FontAwesomeIcon icon="copy" className="mr-2 text-gray-300" />
							<FormattedMessage id="file-requests.title" values={{ n: fileAttachments.length }} />
						</h4>
					</div>
					<div className="flex flex-wrap items-start px-6 mb-3">
						{fileRequestAttachments.map(attachment => (
							<MessageFileRequestAttachment key={attachment.Id} attachment={attachment} />
						))}
					</div>
				</>
			)}

			{/* Context */}
			{context && fileable instanceof Folder && (
				<>
					<div className="flex items-center px-6 mb-3 space-x-4">
						<h4 className="text-xs text-gray-500">
							<FontAwesomeIcon icon="copy" className="mr-2 text-gray-300" />
							<FormattedMessage id="comments.context" />
						</h4>
					</div>
					<div className="flex flex-wrap items-start px-6 mb-3">
						<MessageFileAttachment key={context.Id} name={context.current.Name} onClick={() => attachmentOnClick(context)} src={context.current.Thumbnail ?? undefined} />
					</div>
				</>
			)}

			{/* File attachments */}
			{fileAttachments.length > 0 && (
				<>
					<div className="flex items-center px-6 mb-3 space-x-4">
						<h4 className="text-xs text-gray-500">
							<FontAwesomeIcon icon="paperclip" className="mr-2 text-gray-300" />
							<FormattedMessage id="comments.attachments" values={{ n: fileAttachments.length }} />
						</h4>
						<div className="flex-1 h-px bg-gray-100" />
						<Tooltip tip={<FormattedMessage id="comments.download_attachments" values={{ n: fileAttachments.length }} />}>
							<Button size={Size.sm} type="button" onClick={() => setShowDownloadAttachmentsModal(true)} intent={Intent.secondary} variant={Variant.light} circle>
								<FontAwesomeIcon icon="cloud-download" />
							</Button>
						</Tooltip>
					</div>
					<div className="flex flex-wrap items-start px-6 mb-3">
						{fileAttachments.map(attachment => (
							<MessageFileAttachment
								key={attachment.Id}
								name={attachment.item.current.Name}
								onClick={() => attachmentOnClick(attachment)}
								src={attachment.item.current.Thumbnail ?? undefined}
							/>
						))}
					</div>
				</>
			)}

			{/*	Options */}
			<div
				className={classNames('inline-flex items-center space-x-6', {
					'px-6': !parent
				})}>
				{!message.liked && (
					<Tooltip tip={likes}>
						<button
							className="inline-flex items-center text-xs text-gray-400 transition-colors hover:text-gray-700"
							disabled={isLiking || !!message.archived_at || !!parent?.archived_at}
							onClick={() => like()}>
							<FontAwesomeSvg id="fa-liked" className="mr-1.5" />
							<span>{message.likes.length}</span>
						</button>
					</Tooltip>
				)}

				{message.liked && (
					<Tooltip tip={likes}>
						<button
							className="inline-flex items-center text-xs transition-colors text-theme-primary hover:text-theme-primary-lighter"
							disabled={isUnliking || !!message.archived_at || !!parent?.archived_at}
							onClick={() => unlike()}>
							<FontAwesomeSvg id="fa-liked" className="mr-1.5" />
							<span>{message.likes.length}</span>
						</button>
					</Tooltip>
				)}

				<button
					className="inline-flex items-center text-xs text-gray-400 transition-colors hover:text-gray-700"
					disabled={!!message.archived_at || !!parent?.archived_at}
					onClick={() => onShowReplyForm()}>
					<FontAwesomeSvg id="fa-comment" className="mr-1.5" />
					<span>
						<FormattedMessage id="comments.reply" />
					</span>
				</button>
			</div>

			{showDownloadAttachmentsModal && (
				<DialogDownloadFileables items={message.attachments.filter(attachment => attachment.item['@type'] === 'Document').map(attachment => attachment.item) as File[]} />
			)}
			{attachmentPreview !== undefined && <DialogPreview file={attachmentPreview} onAfterClose={() => setAttachmentPreview(undefined)} />}
		</>
	);
};

type MessageItemHeaderReplyProps = {
	parent: Comment;
	important?: boolean;
};

const MessageItemHeaderReply: FC<MessageItemHeaderReplyProps> = ({ parent, important = false }) => {
	const { account } = useAccount();

	const headerClassName = classNames('flex items-center px-6 py-4 space-x-3', {
		'bg-red-500/10': important,
		'bg-gray-300/10': !important
	});

	return (
		<>
			<div className={headerClassName}>
				<UserAvatar showVerification user={account!} size={Size.sm} />
				<div className="flex flex-col flex-1 md:space-y-0 md:space-x-2 md:items-baseline md:flex-row">
					<div>
						<h5 className="text-sm font-semibold md:text-base">{account!.Name}</h5>
						{account!.Title && <p className="text-[12px] italic text-gray-400">{account!.Title}</p>}
					</div>
				</div>

				{important && (
					<Badge variant={Variant.danger} className="flex items-center">
						<FontAwesomeIcon icon="flag" className="mr-1" />
						<FormattedMessage id="comments.important" />
					</Badge>
				)}
			</div>
		</>
	);
};
