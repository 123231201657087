import { MultiFactorAuth, Title } from '@components/Account';
import AppContext from '@contexts/AppContext';
import { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const MultiFactorAuthPage = () => {
	const { setPageTitle } = useContext(AppContext);
	const { formatMessage } = useIntl();

	useEffect(() => {
		setPageTitle(formatMessage({ id: '2fa.title' }));
	}, [formatMessage, setPageTitle]);

	return (
		<article className="max-w-3xl">
			<Title>
				<FormattedMessage id="2fa.title" />
			</Title>
			<MultiFactorAuth />
		</article>
	);
};
