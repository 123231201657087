import copy from 'copy-to-clipboard';
import React, { type FC, type PropsWithChildren, useMemo } from 'react';

interface Options {
	debug?: boolean | undefined;
	message?: string | undefined;
	format?: string | undefined;
}

type CopyToClipboardProps = PropsWithChildren<{
	text: string;
	onCopy?(text: string, result: boolean): void;
	options?: Options | undefined;
}>;

export const CopyToClipboard: FC<CopyToClipboardProps> = ({ text, onCopy = () => undefined, children, options, ...props }) => {
	const element = useMemo(() => React.Children.only(children), [children]);

	const onClick = (event: MouseEvent) => {
		const result = copy(text, options);

		if (onCopy) {
			onCopy(text, result);
		}

		// Bypass onClick if it was present
		element?.props?.onClick?.(event);
	};

	if (!element) {
		return;
	}

	return React.cloneElement(element, { ...props, onClick });
};
