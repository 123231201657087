import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { type FC, type PropsWithChildren } from 'react';

type StepProps = PropsWithChildren<{
	done?: boolean;
	current?: boolean;
}>;

export const OnboardingStep: FC<StepProps> = ({ done = false, current = false, children }) => {
	return (
		<li className="flex items-baseline gap-2 text-white lg:gap-3">
			<div className="flex items-center justify-center translate-y-px sm:translate-y-0.5">
				{done && (
					<span className="inline-flex items-center justify-center w-3 h-3 text-xs bg-white rounded-full sm:w-4 sm:h-4">
						<FontAwesomeIcon icon="check" className="text-theme-inverse" size="xs" />
					</span>
				)}
				{current && (
					<div className="flex items-center justify-center">
						<span className="relative flex w-3 h-3 sm:w-4 sm:h-4">
							<span className="absolute inline-flex w-full h-full bg-white rounded-full opacity-75 animate-ping"></span>
							<span className="relative inline-flex w-3 h-3 bg-white rounded-full sm:w-4 sm:h-4"></span>
						</span>
					</div>
				)}
				{!done && !current && (
					<div className="flex items-center justify-center">
						<span className="inline-flex w-3 h-3 rounded-full sm:w-4 sm:h-4 bg-white/50" />
					</div>
				)}
			</div>
			<span className={classNames('text-xs sm:text-sm', { 'line-through decoration-white/50': done, 'text-white': current, 'text-white/50': !current })}>{children}</span>
		</li>
	);
};
