import { type JSONContent } from '@tiptap/react';
import { openDB, type DBSchema } from 'idb';
import { useRef } from 'react';

export interface ConvofloDatabase extends DBSchema {
	drafts: {
		key: string;
		value: {
			title: string;
			body: JSONContent;
			pluginsData: Record<string, any>;
			updated_at: Date;
		};
	};
}

export const useIndexedDatabase = () => {
	const db = useRef(
		openDB<ConvofloDatabase>('convoflo', 1, {
			blocked: console.error,
			terminated: console.error,
			blocking: console.error,
			upgrade(database) {
				database.createObjectStore('drafts');
			}
		})
	);

	return db.current;
};
