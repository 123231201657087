import { Editor, useCurrentEditor } from '@tiptap/react';
import { ToolbarButton, type ToolbarButtonProps } from '@ui/RichTextEditor/Toolbar';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export interface ImageToolbarButtonProps extends ToolbarButtonProps {
	getImageUrl?: () => Promise<string>;
}

export const ImageToolbarButton = ({ getImageUrl, ...props }: ImageToolbarButtonProps) => {
	const { editor } = useCurrentEditor();
	const { formatMessage } = useIntl();

	const setImage = useCallback(
		(editor: Editor) => {
			const url = window.prompt(formatMessage({ id: 'text-editor.title.image' }));

			if (url) {
				editor.chain().focus().setImage({ src: url }).run();
			}
		},
		[formatMessage]
	);

	if (editor === null) {
		return null;
	}

	return <ToolbarButton handler={() => setImage(editor)} {...props} />;
};
