/* eslint-disable formatjs/no-literal-string-in-jsx */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import type { Fileable } from '@types';
import throttle from 'lodash.throttle';
import takeRight from 'lodash.takeright';
import dropRight from 'lodash.dropright';
import Dropdown, { DropdownGroup, DropdownItem } from './Dropdown';
import { Button, Variant } from '@convoflo/ui';
import { Size } from '@convoflo/ui';

type BreadcrumbsProps = {
	items: Fileable[];
	onNavigate: (item: Fileable | null) => void;
};

const Breadcrumbs: FC<BreadcrumbsProps> = ({ items, onNavigate = () => undefined }) => {
	const [numberOfVisibleElements, setNumberOfVisibleElements] = useState(items.length);

	const container = useRef<HTMLDivElement>(null);
	const home = useRef<HTMLSpanElement>(null);
	const expander = useRef<HTMLSpanElement>(null);
	const childContainers = useRef<(HTMLSpanElement | null)[]>([]);

	const calculateSize = useCallback(() => {
		if (container.current === null || childContainers.current.length === 0 || home.current === null) {
			return;
		}

		const containerWidth = container.current.clientWidth;

		let remainingWidth = containerWidth - home.current.clientWidth - (expander.current?.clientWidth ?? 0);
		let numberOfVisibleElements = 0;

		for (let element of childContainers.current.reverse()) {
			if (element === null) {
				return;
			}

			remainingWidth -= element.clientWidth;

			if (remainingWidth <= 0) {
				break;
			}

			numberOfVisibleElements++;
		}

		setNumberOfVisibleElements(numberOfVisibleElements);
	}, []);

	const handleResize = useRef(throttle(calculateSize, 200));

	useEffect(() => {
		childContainers.current = childContainers.current.slice(0, items.length);
		setNumberOfVisibleElements(items.length);
		calculateSize();
	}, [items, calculateSize]);

	useLayoutEffect(() => {
		window.addEventListener('resize', handleResize.current);
		// eslint-disable-next-line react-hooks/exhaustive-deps
		return () => window.removeEventListener('resize', handleResize.current);
	}, []);

	const visibleElements = takeRight(items, numberOfVisibleElements);
	const overflowElements = dropRight(items, numberOfVisibleElements);

	return (
		<div className="relative">
			<div className="flex flex-wrap items-center text-xs text-gray-600" ref={container}>
				<button className="focus:outline-none" onClick={() => onNavigate(null)}>
					<FontAwesomeIcon icon="home" fixedWidth />
				</button>
				{overflowElements.length > 0 && (
					<span ref={expander}>
						<FontAwesomeIcon icon="chevron-right" size="xs" className="mx-4 text-gray-400" />
						<Dropdown>
							<Button size={Size.sm} variant={Variant.light} className="py-0">
								...
							</Button>
							<DropdownGroup>
								{overflowElements.reverse().map(fileable => (
									<DropdownItem key={fileable.getKey()} iconElement={fileable.icon('mr-2')} onClick={() => onNavigate(fileable)}>
										{fileable.getName()}
									</DropdownItem>
								))}
							</DropdownGroup>
						</Dropdown>
					</span>
				)}
				{visibleElements.map(item => (
					<span key={item.getKey()}>
						<FontAwesomeIcon icon="chevron-right" size="xs" className="mx-4 text-gray-400" />
						<button className="focus:outline-none" onClick={() => onNavigate(item)}>
							{item.icon('mr-2')}
							{item.getName()}
						</button>
					</span>
				))}
			</div>
			<div className="flex flex-wrap h-0 overflow-hidden text-xs">
				<span ref={home}>
					<FontAwesomeIcon icon="home" />
				</span>
				{items.map((item, index) => (
					<span key={item.getKey()} ref={element => (childContainers.current[index] = element)}>
						<FontAwesomeIcon icon="chevron-right" size="xs" className="mx-4" />
						<span>
							{item.icon('mr-2')}
							{item.getName()}
						</span>
					</span>
				))}
			</div>
		</div>
	);
};

export default Breadcrumbs;
