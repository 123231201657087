import type { SessionType } from '@components/Account';
import { createResourcePagination } from '@components/Pagination';
import useClient from '@hooks/useClient';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const LIMIT = 10;
const RQKEY_ROOT = 'sessions';

export const RQKEY = (page: number) => [RQKEY_ROOT, page];

export const useSessionsQuery = (page: number) => {
	const { client } = useClient();

	return useQuery({
		queryKey: RQKEY(page),
		queryFn: async () => {
			return createResourcePagination<SessionType>(await client.url('account/sessions').query({ limit: LIMIT, page }).get().json());
		}
	});
};

export const useSessionsDeleteMutation = () => {
	const queryClient = useQueryClient();
	const { client } = useClient();

	return useMutation({
		mutationFn: async (session: SessionType) => await client.url(`account/sessions/${session.id}`).delete().res(),
		onSuccess: () => {
			queryClient.invalidateQueries([RQKEY_ROOT]);
		}
	});
};

export const useSessionsDeleteOthersMutation = () => {
	const queryClient = useQueryClient();
	const { client } = useClient();

	return useMutation({
		mutationFn: async () => await client.url(`account/sessions`).delete().res(),
		onSuccess: () => {
			queryClient.invalidateQueries([RQKEY_ROOT]);
		}
	});
};
