import imgWhiteLabelingEn from '@assets/images/white-labeling-1-en.png';
import imgWhiteLabelingFr from '@assets/images/white-labeling-1-fr.png';
import { CheckoutButton, useCart, useStore } from '@components/Checkout';
import FullScreenLoading from '@components/FullScreenLoading';
import AppContext from '@contexts/AppContext';
import { Button, Modal, ModalBody, ModalFooter, type ModalProps, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useUrlSearch from '@hooks/useUrlSearch';
import { filesize } from '@utils/StringUtils';
import { type FC, type FormEvent, useContext, useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import type { Plan } from 'types';
import { defaultCurrency } from '../../constants';
import { Header } from './Header';

export const PlansStep: FC = () => {
	const { locale, formatMessage } = useIntl();
	const { setPageTitle: setTitle } = useContext(AppContext);
	const history = useHistory();
	const { search } = useLocation();
	const { store } = useStore();
	const { addOrUpdateItem, getItemById } = useCart();
	const { plan: planParameter } = useUrlSearch();

	const [showBrandingOption, setShowBrandingOption] = useState(false);

	useEffect(() => {
		setTitle(formatMessage({ id: 'plans.choose_a_plan' }));
	}, [setTitle, formatMessage]);

	const onPlanSelected = (plan: Plan) => {
		addOrUpdateItem({ id: 'plan', options: { key: plan.Key } });
	};

	const onContinue = () => {
		const selectedPlan = store!.plans.find(plan => plan.Key === getItemById('plan')?.options.key)!;

		if (!selectedPlan.Features.Branding && !getItemById('white_labeling')) {
			setShowBrandingOption(true);
			return;
		}

		history.push({ pathname: '/account/checkout/extras', search });
	};

	useEffect(() => {
		if (!store || !planParameter || getItemById('plan')) {
			return;
		}

		onPlanSelected(store!.plans.find(plan => plan.Key === planParameter)!);
	}, [planParameter, store]);

	if (!store) {
		return <FullScreenLoading />;
	}

	return (
		<div className="max-w-7xl">
			<Header subtitle={<FormattedMessage id="plans.subtitle" />}>
				<FormattedMessage id="plans.choose_a_plan" />
			</Header>

			<table className="hidden w-full border-collapse lg:table">
				<colgroup>
					<col className="w-1/5" />
					<col className="w-1/5" />
					<col className="w-1/5" />
					<col className="w-1/5" />
					<col className="w-1/5" />
				</colgroup>
				<thead>
					<tr>
						<td />
						{store.plans.map(plan => (
							<th key={plan.Key} className="p-4 pt-8 text-xl font-semibold bg-gray-100 border-t border-l border-r border-gray-200">
								<div className="relative">
									<span className="inline-flex items-center justify-center gap-1 -translate-x-3">
										{plan.Key === 'bronze' && <i className="inline-block w-5 h-5 bg-orange-700 border-2 border-white rounded-full"></i>}
										{plan.Key === 'silver' && <i className="inline-block w-5 h-5 bg-gray-300 border-2 border-white rounded-full"></i>}
										{plan.Key === 'gold' && <i className="inline-block w-5 h-5 bg-yellow-500 border-2 border-white rounded-full"></i>}
										{plan.Key === 'platinum' && <i className="inline-block w-5 h-5 bg-gray-500 border-2 border-white rounded-full"></i>}
										<span>{plan.Name}</span>
									</span>
									{plan.Key === 'gold' && (
										<div className="absolute px-3 py-1 text-sm font-medium text-center text-white rounded-lg shadow-lg left-3 right-3 bg-theme-secondary -top-12">
											<FormattedMessage id="plans.most_popular" />
										</div>
									)}
								</div>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					<tr>
						<td />
						<td className="px-4 pb-4 text-sm text-center text-gray-500 bg-gray-100 border-l border-r border-gray-200">
							<FormattedMessage id="plans.bronze_description" />
						</td>
						<td className="px-4 pb-4 text-sm text-center text-gray-500 bg-gray-100 border-l border-r border-gray-200">
							<FormattedMessage id="plans.silver_description" />
						</td>
						<td className="px-4 pb-4 text-sm text-center text-gray-500 bg-gray-100 border-l border-r border-gray-200">
							<FormattedMessage id="plans.gold_description" />
						</td>
						<td className="px-4 pb-4 text-sm text-center text-gray-500 bg-gray-100 border-l border-r border-gray-200">
							<FormattedMessage id="plans.platinum_description" />
						</td>
					</tr>
					<tr>
						<td />
						{store.plans.map(plan => (
							<td className="px-4 pb-4 text-center bg-gray-100 border-l border-r">
								<div>
									{!!plan.Rebate && (
										<p className="text-sm text-red-600">
											<del>
												<FormattedNumber value={plan.Price} style="currency" currencyDisplay="narrowSymbol" currency={defaultCurrency} />
											</del>
											{plan.RebateType === 'percentage' && (
												<span className="px-2 py-1 ml-2 text-xs text-white uppercase bg-red-600 rounded-sm">
													<FormattedNumber value={-plan.Rebate} style="percent" />
												</span>
											)}
										</p>
									)}
									<span className="text-3xl font-semibold">
										<FormattedNumber value={plan.Price} style="currency" currencyDisplay="narrowSymbol" currency={defaultCurrency} />
									</span>
									<span className="ml-1 text-xs text-gray-600">
										<FormattedMessage id="plans.payment_frequency_unit" values={{ frequency: 'month' }} />
									</span>
								</div>
								<div className="mt-12">
									<Button
										variant={getItemById('plan')?.options.key === plan.Key ? Variant.secondary : Variant.primary}
										block
										shadow
										onClick={() => onPlanSelected(plan)}
										disabled={getItemById('plan')?.options.key === plan.Key}>
										<FormattedMessage id={getItemById('plan')?.options.key === plan.Key ? 'selected' : 'select'} />
									</Button>
								</div>
							</td>
						))}
					</tr>
					<tr>
						<th scope="row" className="px-4 py-3 text-left border border-gray-200">
							<h2 className="mb-0.5 text-base font-semibold">
								<FormattedMessage id="plans.brand_image_title" />
							</h2>
							<p className="text-xs font-normal text-gray-500">
								<FormattedMessage id="plans.brand_image_description" />
							</p>
						</th>
						{store.plans.map(plan => (
							<td className="py-3 text-lg text-center border border-gray-200">
								{plan.Features.Branding ? (
									<FontAwesomeIcon icon="check-circle" className="text-2xl text-green-600" />
								) : (
									<FontAwesomeIcon icon="times-circle" className="text-2xl text-red-500" />
								)}
							</td>
						))}
					</tr>
					<tr className="bg-gray-100">
						<th scope="row" className="px-4 py-3 text-left border border-gray-200">
							<h2 className="mb-0.5 text-base font-semibold">
								<FormattedMessage id="plans.users_title" />
							</h2>
							<p className="text-xs font-normal text-gray-500">
								<FormattedMessage id="plans.users_description" />
							</p>
						</th>
						{store.plans.map(plan => (
							<td className="py-3 font-semibold text-center border border-gray-200">{plan.Quota.Users}</td>
						))}
					</tr>
					<tr>
						<th scope="row" className="px-4 py-3 text-left border border-gray-200">
							<h2 className="mb-0.5 text-base font-semibold">
								<FormattedMessage id="plans.secure_spaces_title" />
							</h2>
							<p className="text-xs font-normal text-gray-500">
								<FormattedMessage id="plans.secure_spaces_description" />
							</p>
						</th>
						{store.plans.map(plan => (
							<td className="py-3 font-semibold text-center border border-gray-200">{plan.Quota.SecureSpaces || <FormattedMessage id="plans.no_limit" />}</td>
						))}
					</tr>
					<tr className="bg-gray-100">
						<th scope="row" className="px-4 py-3 text-left border border-gray-200">
							<h2 className="mb-0.5 text-base font-semibold">
								<FormattedMessage id="plans.file_size_limit_title" />
							</h2>
						</th>
						{store.plans.map(plan => (
							<td className="py-3 font-semibold text-center border border-gray-200">
								{plan.Quota.FileSize ? filesize(plan.Quota.FileSize, locale) : <FormattedMessage id="plans.no_limit" />}
							</td>
						))}
					</tr>
					<tr>
						<th scope="row" className="px-4 py-3 text-left border border-gray-200">
							<h2 className="mb-0.5 text-base font-semibold">
								<FormattedMessage id="plans.storage_space_title" />
							</h2>
							<p className="text-xs font-normal text-gray-500">
								<FormattedMessage id="plans.storage_space_description" />
							</p>
						</th>
						{store.plans.map(plan => (
							<td className="py-3 font-semibold text-center border border-gray-200">
								{plan.Quota.Storage ? filesize(plan.Quota.Storage, locale) : <FormattedMessage id="plans.no_limit" />}
							</td>
						))}
					</tr>
				</tbody>
			</table>

			{store.plans.length > 0 && (
				<div className="grid grid-cols-1 gap-4 lg:hidden">
					{store.plans.map(plan => (
						<PlanBlock key={plan.Key} plan={plan} onSelected={onPlanSelected} selected={getItemById('plan')?.options.key === plan.Key} />
					))}
				</div>
			)}

			<div className="flex items-center justify-end my-12">
				<Button variant={Variant.primary} size={Size.lg} iconEnd="long-arrow-alt-right" animateIcon disabled={!getItemById('plan')} onClick={onContinue}>
					<FormattedMessage id="plans.extras" />
				</Button>
			</div>

			{showBrandingOption && <DialogBrandingOffer onAfterClose={() => history.push({ pathname: '/account/checkout/extras', search })} />}
		</div>
	);
};

type PlanBlockProps = {
	plan: Plan;
	selected?: boolean;
	onSelected?: (plan: Plan) => void;
};

const PlanBlock: FC<PlanBlockProps> = ({ plan, selected = false, onSelected = () => undefined }) => {
	let { locale } = useIntl();

	return (
		<CheckoutButton onClick={() => onSelected(plan)} selected={selected}>
			<div className="flex items-center justify-between mb-2 text-lg">
				<h3 className="flex items-center font-semibold">
					{plan.Key === 'bronze' && <i className="inline-block w-6 h-6 mr-2 bg-orange-700 border-2 border-white rounded-full"></i>}
					{plan.Key === 'silver' && <i className="inline-block w-6 h-6 mr-2 bg-gray-300 border-2 border-white rounded-full"></i>}
					{plan.Key === 'gold' && <i className="inline-block w-6 h-6 mr-2 bg-yellow-500 border-2 border-white rounded-full"></i>}
					{plan.Key === 'platinum' && <i className="inline-block w-6 h-6 mr-2 bg-gray-500 border-2 border-white rounded-full"></i>}
					{plan.Name}
				</h3>

				<div className="text-right">
					{!!plan.Rebate && (
						<p className="text-sm text-right text-red-600">
							<del>
								<FormattedNumber value={plan.Price} style="currency" currencyDisplay="narrowSymbol" currency={defaultCurrency} />
							</del>
							{plan.RebateType === 'percentage' && (
								<span className="px-2 py-1 ml-2 text-xs text-white uppercase bg-red-600 rounded-sm">
									<FormattedNumber value={-plan.Rebate} style="percent" />
								</span>
							)}
						</p>
					)}
					<span className="font-semibold">
						<FormattedNumber value={plan.Price} style="currency" currencyDisplay="narrowSymbol" currency={defaultCurrency} />
					</span>
					<span className="ml-1 text-xs text-gray-600">
						<FormattedMessage id="plans.payment_frequency_unit" values={{ frequency: 'month' }} />
					</span>
				</div>
			</div>

			<ul className="mt-4 space-y-2">
				{plan.Features.Branding && (
					<li className="text-sm">
						<FontAwesomeIcon icon="check" className="mr-2 text-gray-500" />
						<FormattedMessage id="plans.plan_brand_image" />
					</li>
				)}
				<li className="text-sm">
					<FontAwesomeIcon icon="check" className="mr-2 text-gray-500" />
					<FormattedMessage id="plans.plan_users" values={{ n: plan.Quota.Users, strong: msg => <span className="font-bold">{msg}</span> }} />
				</li>
				<li className="text-sm">
					<FontAwesomeIcon icon="check" className="mr-2 text-gray-500" />
					<FormattedMessage id="plans.plan_secure_spaces" values={{ n: plan.Quota.SecureSpaces || -1, strong: msg => <span className="font-bold">{msg}</span> }} />
				</li>
				<li className="text-sm">
					<FontAwesomeIcon icon="check" className="mr-2 text-gray-500" />
					{plan.Quota.FileSize >= 1099511627776 ? (
						<FormattedMessage id="plans.plan_filesize_nolimit" values={{ strong: msg => <span className="font-bold">{msg}</span> }} />
					) : (
						<FormattedMessage id="plans.plan_filesize_limit" values={{ value: filesize(plan.Quota.FileSize || -1, locale), strong: msg => <span className="font-bold">{msg}</span> }} />
					)}
				</li>
				<li className="text-sm">
					<FontAwesomeIcon icon="check" className="mr-2 text-gray-500" />
					<FormattedMessage id="plans.plan_storage_limit" values={{ value: filesize(plan.Quota.Storage, locale), strong: msg => <span className="font-bold">{msg}</span> }} />
				</li>
			</ul>
		</CheckoutButton>
	);
};

const DialogBrandingOffer: FC<Omit<ModalProps, 'isOpen'>> = ({ ...modalProps }) => {
	const { toggleItem, getItemById } = useCart();
	const { store } = useStore();
	const { locale } = useIntl();

	const [isOpen, setIsOpen] = useState(true);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		setIsOpen(false);
	};

	const onClick = () => {
		toggleItem({ id: 'white_labeling', options: { level: 1 } });
	};

	return (
		<Modal isOpen={isOpen} onSubmit={onSubmit} {...modalProps}>
			<ModalBody className="pt-0">
				<div className="mb-8 -mx-8">
					<img className="object-cover object-top w-full h-48" src={locale === 'fr' ? imgWhiteLabelingFr : imgWhiteLabelingEn} />
				</div>
				<div className="prose">
					<h2>
						<FormattedMessage id="plans.dialog_branding.title" />
					</h2>
					<p>
						<FormattedMessage
							id="plans.dialog_branding.body"
							values={{ price: <FormattedNumber value={store!.modules.white_labeling[0].amount} style="currency" currencyDisplay="narrowSymbol" currency={defaultCurrency} /> }}
						/>
					</p>
					<CheckoutButton onClick={onClick} selected={getItemById('white_labeling')?.options.level === 1}>
						<FormattedMessage
							id="plans.dialog_branding.action"
							values={{ price: <FormattedNumber value={store!.modules.white_labeling[0].amount} style="currency" currencyDisplay="narrowSymbol" currency={defaultCurrency} /> }}
						/>
					</CheckoutButton>
				</div>
			</ModalBody>

			<ModalFooter>
				<Button type="submit" className="mr-2" variant={Variant.primary}>
					<FormattedMessage id="continue" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
