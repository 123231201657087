import type { LecsorUnitTransactionsType } from '@components/Lecsor';
import useClient from '@hooks/useClient';
import { lightFormat } from 'date-fns';
import { useQuery } from 'react-query';

export const useUnitTransactions = (associationId: string | undefined, accountId: string | undefined, fromDate: Date | null, toDate: Date | null) => {
	const { client } = useClient();

	const { data: transactions, isLoading } = useQuery(
		['lecsor', 'transactions', associationId, accountId, fromDate?.getTime(), toDate?.getTime()],
		async () =>
			await client
				.url(`lecsor/associations/${associationId}/units/${accountId}/transactions`)
				.query({
					from: lightFormat(fromDate!, 'yyy-MM-dd'),
					to: lightFormat(toDate!, 'yyy-MM-dd')
				})
				.get()
				.json<LecsorUnitTransactionsType>(),
		{
			enabled: !!associationId && !!accountId && fromDate !== null && toDate !== null
		}
	);

	return { transactions, isLoading };
};
