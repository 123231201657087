import type { Area } from 'react-easy-crop';

const createImage = (url: string): Promise<HTMLImageElement> =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener('load', () => resolve(image));
		image.addEventListener('error', error => reject(error));
		image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
		image.src = url;
	});

const getRadianAngle = (degreeValue: number) => (degreeValue * Math.PI) / 180;

export const getRotatedImage = async (imageSrc: string, rotation = 0): Promise<string | null> => {
	const image = await createImage(imageSrc);
	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');

	const rotRad = getRadianAngle(rotation);
	const bBoxWidth = Math.abs(Math.cos(rotRad) * image.width) + Math.abs(Math.sin(rotRad) * image.height);
	const bBoxHeight = Math.abs(Math.sin(rotRad) * image.width) + Math.abs(Math.cos(rotRad) * image.height);

	if (context === null) {
		return null;
	}

	const orientationChanged = rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;
	if (orientationChanged) {
		canvas.width = bBoxHeight;
		canvas.height = bBoxWidth;
	} else {
		canvas.width = bBoxWidth;
		canvas.height = bBoxHeight;
	}

	context.translate(bBoxWidth / 2, bBoxHeight / 2);
	context.rotate(rotRad);
	context.translate(-image.width / 2, -image.height / 2);
	context.drawImage(image, 0, 0);

	return new Promise((resolve, reject) => {
		canvas.toBlob(file => {
			if (file !== null) {
				resolve(URL.createObjectURL(file));
			} else {
				reject(new Error('Could not general jpeg'));
			}
		}, 'image/jpeg');
	});
};

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export const getCroppedImg = async (imageSrc: string, pixelCrop: Area, rotation = 0): Promise<Blob | null> => {
	const image = await createImage(imageSrc);
	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');

	if (context === null) {
		return null;
	}

	const maxSize = Math.max(image.width, image.height);
	const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

	// set each dimensions to double largest dimension to allow for a safe area for the
	// image to rotate in without being clipped by canvas context
	canvas.width = safeArea;
	canvas.height = safeArea;

	// translate canvas context to a central location on image to allow rotating around the center.
	context.translate(safeArea / 2, safeArea / 2);
	context.rotate(getRadianAngle(rotation));
	context.translate(-safeArea / 2, -safeArea / 2);

	// draw rotated image and store data.
	context.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
	const data = context.getImageData(0, 0, safeArea, safeArea);

	// set canvas width to final desired crop size - this will clear existing context
	canvas.width = pixelCrop.width;
	canvas.height = pixelCrop.height;

	// paste generated rotate image with correct offsets for x,y crop values.
	context.putImageData(data, 0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x, 0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y);

	// As Base64 string
	// return canvas.toDataURL('image/jpeg');

	// As a blob
	return new Promise(resolve => {
		canvas.toBlob(file => {
			resolve(file);
		}, 'image/jpeg');
	});
};

export const createTransparentImage = () => {
	const image = document.createElement('img');
	image.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
	return image;
};
