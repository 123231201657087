import type { AppType, ConnectedAppType } from '@components/ConnectedApps';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import qs from 'qs';

type UseAppsQueryFilters = {
	connected?: boolean;
	code?: string;
};

export const useAppsQuery = (filters: UseAppsQueryFilters) => {
	const { account } = useAccount();
	const { client } = useClient();

	return useQuery({
		queryKey: ['apps', filters, account?.Locale],
		queryFn: async () =>
			await client
				.url('apps')
				.query(
					qs.stringify(
						{ filter: filters },
						{
							encoder(str, defaultEncoder, charset, type) {
								return typeof str === 'boolean' ? (str ? '1' : '0') : defaultEncoder(str, defaultEncoder, charset);
							}
						}
					)
				)
				.get()
				.json<AppType[]>(),
		staleTime: Infinity
	});
};

export const useDisconnectAppMutation = () => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (connection: ConnectedAppType) => await client.url(`apps/connections/${connection.Id}`).delete().res(),
		onSuccess: () => {
			queryClient.invalidateQueries(['apps']);
		}
	});
};
