import { ComboBox, KeyValueDescriptionOption, type KeyValueDescriptionOptionType } from '@components/ComboBox';
import { DateTimeDisplay, type DateTimeFormat } from '@components/DateTime';
import { HelperText, Label, Row } from '@convoflo/ui';
import { useLocalStorage } from '@hooks/useLocalStorage';
import Card from '@ui/Card';
import { subMonths } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';

export const AppSettings = () => {
	const { formatMessage } = useIntl();

	// const [skipMessageBoxOnUpload, setSkipMessageBoxOnUpload] = useLocalStorage('uploader.skip-message', false);
	// const [skipConfirmationSecureSpace, setSkipConfirmationSecureSpace] = useLocalStorage('secure-space-send.skip-confirmation', false);
	const [dateTimeFormat, setDateTimeFormat] = useLocalStorage<DateTimeFormat>('datetime.format');

	const options = (['full', 'long', 'medium', 'short', 'mini', 'relative'] as DateTimeFormat[]).map<KeyValueDescriptionOptionType<DateTimeFormat>>(format => ({
		value: format,
		label: <FormattedMessage id={`datetime.${format}.title`} />,
		description: <FormattedMessage id="datetime.example" values={{ date: <DateTimeDisplay value={EXAMPLE_DATE} force defaultFormat={format} showTooltip={false} /> }} />
	}));

	return (
		<Card>
			{/* <Row>
					<div className="flex flex-row items-center justify-between">
						<Label htmlFor="secure_space_session_timeout-field">
							<FormattedMessage id="app-settings.uploader.skip-message" />
						</Label>
						<Toggle size={Size.sm} checked={!skipMessageBoxOnUpload} onChange={e => setSkipMessageBoxOnUpload(!e.target.checked)} />
					</div>
					<HelperText>
						<FormattedMessage id="app-settings.uploader.skip-message.desc" />
					</HelperText>
				</Row> */}
			<Row className="grid grid-cols-2 gap-6">
				<div>
					<Label htmlFor="secure_space_session_timeout-field">
						<FormattedMessage id="account-settings.datetime.title" />
					</Label>
					<HelperText>
						<FormattedMessage id="account-settings.datetime.description" />
					</HelperText>
				</div>
				<div>
					<ComboBox
						isSearchable={false}
						value={options.find(option => option.value === dateTimeFormat) ?? undefined}
						onChange={value => (value ? setDateTimeFormat(value.value) : void 0)}
						components={{
							Option: KeyValueDescriptionOption
						}}
						getOptionLabel={({ value }) => formatMessage({ id: `datetime.${value}.title` })}
						getOptionValue={({ value }) => value}
						placeholder={<FormattedMessage id="account-settings.datetime.placeholder" />}
						options={options}
					/>
				</div>
			</Row>
			{/* <Row>
					<div className="flex flex-row items-center justify-between">
						<Label htmlFor="secure_space_session_timeout-field">
							<FormattedMessage id="app-settings.secure-space-send.skip-confirmation" />
						</Label>
						<Toggle size={Size.sm} checked={!skipConfirmationSecureSpace} onChange={e => setSkipConfirmationSecureSpace(!e.target.checked)} />
					</div>
					<HelperText>
						<FormattedMessage id="app-settings.secure-space-send.skip-confirmation.desc" />
					</HelperText>
				</Row> */}
		</Card>
	);
};

const EXAMPLE_DATE = subMonths(new Date(), 1).toISOString();
