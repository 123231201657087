import LogoAmex from '@assets/images/amex.svg?react';
import LogoMastercard from '@assets/images/mastercard.svg?react';
import LogoVisa from '@assets/images/visa.svg?react';
import type { BillingCard } from '@components/Account';
import { Alert, Button, InputClassNames, Intent, Label, Modal, ModalBody, ModalFooter, type ModalProps, ModalSize, Row, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { type StripeCardElementChangeEvent, type StripeError, loadStripe } from '@stripe/stripe-js';
import Card from '@ui/Card';
import EmptyState from '@ui/EmptyState';
import { type FC, type FormEvent, type ReactNode, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { cardElementStyles } from '../../constants';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY ?? '');

export const BillingCardsManager = () => {
	const { client } = useClient();

	const [showAddForm, setShowAddForm] = useState(false);
	const [editingCard, setEditingCard] = useState<BillingCard>();
	const [successMessage, setSuccessMessage] = useState<ReactNode>();

	const { data: card, isLoading } = useQuery(
		['billing-card'],
		async () => {
			const response = await client.url('org/billing/card').get().res();

			if (response.status === 204) {
				return null;
			}

			return await response.json();
		},
		{
			staleTime: Infinity,
			onError: console.log
		}
	);

	const onCardAdded = () => {
		setShowAddForm(false);
		setSuccessMessage(<FormattedMessage id="billing-cards.added" />);
	};

	const onCardSaved = () => {
		setEditingCard(undefined);
		setSuccessMessage(<FormattedMessage id="billing-cards.saved" />);
	};

	return (
		<Elements stripe={stripePromise}>
			<Card className="mb-12">
				{successMessage !== undefined && (
					<Alert variant={Variant.success} className="mb-6">
						{successMessage}
					</Alert>
				)}
				{isLoading ? (
					<div className="flex items-center justify-center h-24" style={{ maxHeight: '50vh' }}>
						<p>
							<FontAwesomeIcon icon="spinner" className="mr-2" pulse />
							<FormattedMessage id="loading" />
						</p>
					</div>
				) : card ? (
					<CardRow card={card} onSelect={card => setEditingCard(card)} />
				) : (
					<EmptyState
						icon="credit-card-front"
						title={<FormattedMessage id="billing-cards.no_card" />}
						action={
							<Button variant={Variant.primary} onClick={() => setShowAddForm(true)}>
								<FormattedMessage id="billing-cards.add_card" />
							</Button>
						}
					/>
				)}
			</Card>
			{editingCard && <DialogCardForm onSaved={onCardSaved} onAfterClose={() => setEditingCard(undefined)} card={editingCard} />}
			{showAddForm && <DialogCardForm onSaved={onCardAdded} onAfterClose={() => setShowAddForm(false)} />}
		</Elements>
	);
};

type CardEditRowProps = Omit<ModalProps, 'isOpen'> & {
	card?: BillingCard;
	onSaved: () => void;
};

const DialogCardForm: FC<CardEditRowProps> = ({ card, onSaved = () => undefined, ...modalProps }) => {
	const stripe = useStripe();
	const elements = useElements();
	const { client, setValidation } = useClient();
	const queryClient = useQueryClient();

	const [isOpen, setIsOpen] = useState(true);
	const [cardFilledIn, setCardFilledIn] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const { mutate: getStripeToken } = useMutation<string, StripeError | Error>(
		async () => {
			const cardElement = elements!.getElement(CardElement);

			if (cardElement === null) {
				throw new Error('Cannot get Stripe token: Stripe Elements not loaded.');
			}

			const { error, paymentMethod } = await stripe!.createPaymentMethod({ type: 'card', card: cardElement });

			if (paymentMethod) {
				return paymentMethod.id;
			}

			throw error;
		},
		{
			onSuccess: token => {
				save(token);
			},
			onError: error => {
				setIsSaving(false);

				if (!(error instanceof Error)) {
					if (error.param !== undefined && error.code !== undefined) {
						setValidation({ [error.param]: [error.code] });
					}
				}
			}
		}
	);

	const { mutate: save } = useMutation(async (stripeToken: string) => await client.url('org/billing/card').put({ token: stripeToken }).json<BillingCard>(), {
		onSettled: () => {
			setIsSaving(false);
		},
		onSuccess: card => {
			queryClient.setQueryData(['billing-card'], card);
			onSaved();
		},
		onError: () => {
			toast.error(<FormattedMessage id="billing-cards.error_saving_card" />);
		}
	});

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		setIsSaving(true);
		getStripeToken();
	};

	const onCardChange = ({ complete }: StripeCardElementChangeEvent) => {
		setCardFilledIn(complete);
	};

	return (
		<Modal size={ModalSize.XSmall} isOpen={isOpen} {...modalProps} onSubmit={onSubmit}>
			<ModalBody>
				<Row>
					<Label>
						<FormattedMessage id="plans.credit_card" />
					</Label>

					<div className={`${InputClassNames} px-3 py-2 text-base`}>
						<CardElement options={{ hidePostalCode: true, style: cardElementStyles }} onChange={onCardChange} />
					</div>
				</Row>
			</ModalBody>

			<ModalFooter>
				<Button variant={Variant.primary} type="submit" disabled={isSaving || !stripe || !cardFilledIn} iconStart="save" loading={isSaving}>
					<FormattedMessage id="save" />
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} type="button" className="ml-2" onClick={() => setIsOpen(false)}>
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};

type CardRowProps = {
	card: BillingCard;
	onSelect?: (card: BillingCard) => void;
};

const CardRow: FC<CardRowProps> = ({ card, onSelect = () => undefined }) => {
	const { locale } = useIntl();

	const month = new Date(new Date().getFullYear(), card.ExpMonth, 1).toLocaleString(locale, { month: 'long' });

	return (
		<div className="flex items-center gap-6">
			{card.Brand === 'visa' && <LogoVisa className="h-4" />}
			{card.Brand === 'mastercard' && <LogoMastercard className="h-8" />}
			{card.Brand === 'amex' && <LogoAmex className="h-12" />}
			<div className="flex-1">
				<h6>
					<span className="font-mono">xxxx-{card.Last4}</span>
				</h6>
				{card.ExpMonth && card.ExpYear && (
					<p className="text-sm text-gray-600">
						<FormattedMessage id="billing-cards.expires_on" values={{ month, year: card.ExpYear }} />
					</p>
				)}
			</div>
			<Button variant={Variant.dark} onClick={() => onSelect(card)} size={Size.sm}>
				<FormattedMessage id="billing-cards.update" />
			</Button>
		</div>
	);
};
