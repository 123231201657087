import useClient from '@hooks/useClient';
import { useMutation, useQuery, useQueryClient, type UseMutateFunction } from 'react-query';
import type { WretchError } from 'wretch';
import type { BillingAddress, BillingAddressPutRequest } from '../types';

export const useBillingInformation = (): [BillingAddress | undefined, UseMutateFunction<BillingAddress, WretchError, BillingAddressPutRequest, BillingAddress>] => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	const { data } = useQuery(['billing-address'], async () => await client.url('org/billing/address').get().json<BillingAddress>(), {
		staleTime: Infinity
	});

	const { mutate: save } = useMutation<BillingAddress, WretchError, BillingAddressPutRequest, BillingAddress>(
		async address => await client.url('org/billing/address').json(address).put().json<BillingAddress>(),
		{
			onSuccess: address => {
				queryClient.setQueryData<BillingAddress>(['billing-address'], address);
			},
			onMutate: async addressProps => {
				await queryClient.cancelQueries(['billing-address']);

				const previousState = queryClient.getQueryData<BillingAddress>(['billing-address']);

				if (previousState === undefined) {
					return undefined;
				}

				queryClient.setQueryData<BillingAddress>(['billing-address'], address => ({
					'@type': 'BillingAddress',
					BillingAddress: addressProps.address ?? address?.BillingAddress ?? null,
					BillingCity: addressProps.city ?? address?.BillingCity ?? null,
					BillingCountry: addressProps.country ?? address?.BillingCountry ?? null,
					BillingName: addressProps.name ?? address?.BillingName ?? null,
					BillingRegion: addressProps.region ?? address?.BillingRegion ?? null,
					BillingZip: addressProps.postal_code ?? address?.BillingZip ?? null
				}));

				return previousState;
			},
			onError: (_error, _variables, previousState) => {
				queryClient.setQueryData(['billing-address'], previousState);
			}
		}
	);

	return [data, save];
};
