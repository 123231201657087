import type { ConnectedAppType } from '@components/ConnectedApps';
import type { HasLabels } from '@components/Labels';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import Collaborator from '@models/Collaborator';
import Folder from '@models/Folder';
import Organization from '@models/Organization';
import User from '@models/User';

export type ModelType = {
	'@type': string;
	id: () => string;
};

export type Modify<T, R> = Omit<T, keyof R> & R;

export type View = Fileable | string | null;

export type Testimonial = {
	quote: Record<string, string>;
	name: string;
	organization: string;
	title: Record<string, string>;
	picture: string | null;
};

export type Quota<NullLimit extends boolean = true> = {
	used: number;
	available: NullLimit extends true ? number | null : number;
	limit: NullLimit extends true ? number | null : number;
};

export type Usage = {
	ComposerMessages: Quota<false>;
	ComposerAttachments: Quota<false>;
	SecureSpaces: Quota;
	Space: Quota;
	Users: Quota;
};

export enum Module {
	Commenting = 0b00000001,
	CustomLogo = 0b00000010,
	NoSort = 0b00000100,
	Esign = 0b00001000,
	Gateway = 0b00010000,
	Payments = 0b00100000
}
export enum MessagesMode {
	OpenToAll = 0,
	OpenToMods = 1
}

export type Release = {
	'@type': 'Release';
	Id: number;
	Title: string;
	Content: string;
	created_at: string;
};

export type Contact = {
	'@type': 'UserContact';
	Id: number | null;
	Name: string | null;
	Avatar: string | null;
	Title: string | null;
	Email: string;
	Source: string;
};

export interface Fileable extends ModelType, HasLabels {
	'@type': string;
	expires_at: string | null;
	expires_by: User | null;
	created_at: string;
	deleted_at: string | null;
	pivot?: FileablePivotType;
	URL: string;
	Link: string;
	OriginalLink: string;
	collaborators: Collaborator[];
	creator: User & {
		business?: Organization;
	};
	SecuredSpace: boolean;
	ancestors?: Folder[];
	Access: FileableAccess;
	ReadonlyMessages: boolean;
	MessagesMode: MessagesMode;
	Modules: number;
	Scope?: 'internal' | 'external';
	SecureSpace?: Folder | null;
	members_count: number;
	members_hash?: string;
	syncs?: ItemSyncType[];

	getKey(): number | null;
	getName(): string | null;
	getRoute(suffix: string | null, prefix?: string): string;
	getUrl(suffix?: string): string;
	hasModule(module: Module): boolean;
	canViewMessages(): boolean;
	canUploadFiles(): boolean;
	icon(className?: string, colorize?: boolean, props?: Partial<FontAwesomeIconProps>): JSX.Element;
}

type BasePivotType = {
	Permissions: Permissions;
	Notify: boolean;
	Favorite: boolean;
};

export type FolderPivotType = BasePivotType & {
	secure_space_shared_at: string | null;
	secure_space_activated_at: string | null;
	ShareMessage: string | null;
	Viewed?: never;
	Color?: string | null;
};

export type FilePivotType = BasePivotType & {
	secure_space_shared_at?: never;
	secure_space_activated_at?: never;
	ShareMessage?: never;
	Viewed: boolean;
	Color?: never;
};

export type FileablePivotType = FolderPivotType | FilePivotType;

export type FolderTemplate = {
	'@type': 'FolderTemplate';
	Id: number;
	Name: string;
	SecuredSpace: boolean;
	children: FolderTemplate[];
};

export type Plan = {
	'@type': 'Plan';
	Id: number;
	Name: string;
	Key: string;
	Quota: {
		Users: number;
		SecureSpaces: number | null;
		FileSize: number;
		Storage: number;
	};
	Features: {
		Branding: boolean;
	};
	Price: number;
	RebateType?: 'percentage' | 'fixed' | 'none';
	Rebate?: number;
};

export type SupportPlan = Record<
	string,
	{
		percentage: number;
	}
>;

export type SignRequestRole = {
	'@type': 'SignRequestRole';
	Id: number | string;
	Label: string | null;
	Question: string | null;
	requested_at: string | null;
	signed_at: string | null;
	signer: Collaborator;
	Auth: 'SMS' | null;
	Order: number | null;
	Reassign: boolean;
	ReassignedSigner: {};
};

export type Subscription = {
	'@type': 'Subscription';
	trial_ends_at: string | null;
	started_at: string | null;
	ends_at: string | null;
	created_at: string;
	Interval: 'month' | 'year';
	Provider: 'stripe' | 'other';
	SupportPlan: 'standard' | 'premium' | 'premium-plus';
	Status: 'active' | 'unpaid' | 'incomplete' | 'incomplete_expired' | 'trialing' | 'past_due' | 'canceled';
	Products: {
		Plan: Plan;
		StorageBlocks: number;
		Modules: number;
		SupportPlan: 'standard' | 'premium' | 'premium-plus';
	};
};

export type ModuleContract = {
	'@type': 'ModuleContract';
	Data: Record<string, any>;
	ends_at: string | null;
};

export type ProductivityReport = {
	TotalExternalFiles: number;
	TotalExternalMessages: number;
	TotalExternalDownloads: number;
	TotalInternalFiles: number;
	TotalInternalMessages: number;
	TotalInternalDownloads: number;
};

export type Summary = {
	count: {
		internal: number;
		external: number;
	};
	users: User[];
	types: string[];
};

export type SummaryType = keyof UserSummaryReport['summary'];

export type UserSummaryReport = {
	summary: {
		files: Summary;
		messages: Summary;
		secure_spaces: Summary;
		esign_received: Summary;
		esign_completed: Summary;
		payment_request_created: Summary;
		payment_request_received: Summary;
		file_request_created: Summary;
		file_request_completed: Summary;
	};
	total_count: number;
};

export enum Permissions {
	Owner = 'owner',
	ReadOnly = 'readonly',
	Editor = 'editor',
	ReadWrite = 'readwrite',
	None = 'none'
}

export enum FileableAccess {
	Public = 'public',
	Private = 'private'
}

export type ValidationErrors = Record<string, string[]>;

export type LoginComponentProps = {
	email?: string;
	onSuccess?: (token: string) => void;
};

export type Metadata = {
	key: string;
	value: string;
};

export type ColorType = {
	value: string;
	backgroundClassName: string;
	borderClassName: string;
	textClassName: string;
	indicatorClassName: string;
};

export type EmailChangeRequestType = {
	'@type': 'EmailChangeRequest';
	Email: string | null;
};

export type EnvironmentType = {
	'@type': 'Environment';
	Environment: string;
	Name: string;
	Theme: Record<string, any> | null;
	SupportUrl: string | null;
	Keys: Record<string, string> | null;
	LogoLight: string;
	LogoDark: string;
};

export type SecureSpaceTermsType = {
	'@type': 'SecureSpaceTerms';
	Text: string;
	creator: User;
	Organization: Organization;
	created_at: string;
};

export type ItemSyncType = {
	'@type': 'SyncedItem';
	Id: number;
	Status: 'synced' | 'pending' | 'syncing' | 'error';
	ErrorCode: string | null;
	last_synced_at: string;
	Connection?: ConnectedAppType;
};

/*export type User = {
	'@type': string;
	ID: number;
	Name: string;
	Title: string | null;
	Avatar: string;
	Email: string;
	Scope: string;
	pivot: {
		Permissions: string;
		Notify: boolean;
		secure_space_activated_at: string | null;
		secure_space_shared_at: string | null;
	};
}*/

export interface SecureSpace {
	'@type': string;
	ID: number;
	'@id': string;
	Access: string;
	Link: string;
	OriginalLink: string;
	URL: string;
	Name: string;
	SecuredSpace: boolean;
	MessagesMode: number;
	collaborators: User[];
	members_count: number;
	Scope: string;
}
