import { CreatableComboBox } from '@components/ComboBox';
import { type DataRow, useImporter } from '@components/Importer';
import { useWizard } from '@components/Wizard';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@ui/Card';
import { type FC, type FormEvent, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export const ConflictsStep = () => {
	const { nextStep, previousStep } = useWizard();
	const { data } = useImporter();

	const conflicts = useMemo(() => data.filter(row => row.name.length > 1), [data]);

	if (conflicts.length === 0) {
		nextStep();
		return null;
	}

	return (
		<Card>
			<div className="prose max-w-none">
				<h2>
					<FontAwesomeIcon icon="exclamation-triangle" className="mr-2 text-yellow-400" /> <FormattedMessage id="importer.conflicts.title" />
				</h2>
				<p>
					<FormattedMessage id="importer.conflicts.subtitle" />
				</p>
			</div>

			<div className="my-12 border border-gray-100 divide-y divide-gray-100 rounded-md">
				{conflicts.map(row => (
					<div key={row.key} className="px-4 py-4">
						<h2 className="mb-2 font-medium">{row.key}</h2>
						<NameConflictResolver clientId={row.key} object={row} />
					</div>
				))}
			</div>
			<footer className="flex items-center justify-between mt-6">
				<Button variant={Variant.primary} intent={Intent.tertiary} onClick={previousStep} iconStart="arrow-left" animateIcon size={Size.sm}>
					2. <FormattedMessage id="importer.step.naming" />
				</Button>
				<Button variant={Variant.primary} onClick={nextStep} iconEnd="arrow-right" animateIcon disabled>
					4. <FormattedMessage id="importer.step.configuration" />
				</Button>
			</footer>
		</Card>
	);
};

type NameConflictResolverProps = {
	clientId: string;
	object: DataRow;
};

const NameConflictResolver: FC<NameConflictResolverProps> = ({ clientId, object }) => {
	const { data, setData } = useImporter();

	const [name, setName] = useState<string>();

	const resolve = (name: string | string[]) => {
		let newName = Array.isArray(name) ? name.join(', ') : name;
		setData(data => data.map(row => (row.key === clientId ? { ...row, name: [newName] } : row)));
	};

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		if (!name) {
			return;
		}

		resolve(name);
	};

	const duplicateSecureSpaces = () => {
		const originalObjet = data.find(row => row.key === clientId);

		if (!originalObjet) {
			return;
		}

		setData(data => [...data.filter(row => row.key !== clientId), ...originalObjet.name.map(name => ({ ...object, name: [name] }))]);
	};

	const options = useMemo(() => [...object.name.map(name => ({ label: name, value: name })), { label: object.name.join(', '), value: object.name.join(', ') }], [object.name]);

	return (
		<>
			<form onSubmit={onSubmit} className="flex items-center w-full space-x-3">
				<div className="flex-1 truncate">
					<CreatableComboBox
						options={options}
						value={name ? { value: name, label: name } : undefined}
						getOptionValue={({ value }) => value ?? ''}
						getNewOptionData={input => ({ value: input, label: input })}
						onChange={option => setName(option?.value)}
						placeholder={<FormattedMessage id="importer.conflicts.choose" />}
					/>
				</div>
				<div>
					<Button type="submit" variant={Variant.primary} intent={Intent.primary} disabled={!name}>
						<FormattedMessage id="importer.conflicts.confirm" />
					</Button>
				</div>
			</form>
			<p className="my-3 text-xs text-gray-600">
				<FormattedMessage id="importer.conflicts.or" />
			</p>
			<button type="button" className="text-sm text-theme-primary hover:underline" onClick={duplicateSecureSpaces}>
				<FormattedMessage id="importer.conflicts.create_secure_space" />
			</button>
		</>
	);
};
