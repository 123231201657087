import { isIOS } from '@utils/StringUtils';

export const blobToReadableStream = (blob: Blob) => {
	let position = 0;

	return new ReadableStream({
		pull: async controller => {
			const chunk = blob.slice(position, Math.min(blob.size, position + Math.max(controller.desiredSize ?? 0, 512 * 1024)));
			const buffer = await new Response(chunk).arrayBuffer();
			const uint8array = new Uint8Array(buffer);
			const bytesRead = uint8array.byteLength;

			position += bytesRead;
			controller.enqueue(uint8array);

			if (position >= blob.size) {
				controller.close();
			}
		}
	});
};

export const isBuggedSafari = (() => {
	// iOS bug
	if (isIOS) {
		return true;
	}

	// Safari < 14 doesn't have streams
	if (Blob.prototype.stream === undefined) {
		return true;
	}

	// This version of Safari has a bug with Blob.stream
	if (navigator.userAgent.includes('Safari') && navigator.userAgent.includes('Version/14.1 ')) {
		return true;
	}

	return false;
})();
