import { type CannedMessage, type CannedMessageCategory, CannedMessageCategoryForm, CannedMessageItem, DialogDeleteCannedMessageCategory } from '@components/CannedMessages';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { type FC, useMemo, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';

type CannedMessageCategoryItemProps = {
	messages: CannedMessage[];
	category?: CannedMessageCategory;
	editable?: boolean;
};

export const CannedMessageCategoryItem: FC<CannedMessageCategoryItemProps> = ({ category, messages, editable = true }) => {
	const { account } = useAccount();

	const [state, setState] = useState('view');
	const [openedState, setOpenedState] = useLocalStorage<Record<string, { opened?: boolean }>>('categories.canned-messages.state', {});

	const toggleExpanded = (expanded: boolean) => {
		setOpenedState({ ...openedState, [String(category?.Id ?? 'uncategorized')]: { opened: expanded } });
	};

	const expanded = openedState[String(category?.Id ?? 'uncategorized')]?.opened ?? true;

	const orderedMessages = useMemo(() => messages.sort((a, b) => a.Order - b.Order), [messages]);

	return (
		<>
			<div className="flex items-center">
				<div className="inline-flex items-center space-x-1">
					<Button intent={Intent.tertiary} onClick={() => toggleExpanded(!expanded)} icon={expanded ? 'caret-down' : 'caret-right'}>
						{category ? (
							category.Name
						) : (
							<em className="italic">
								<FormattedMessage id="canned-messages-crud.without_category" />
							</em>
						)}
					</Button>
				</div>

				{category?.Id !== undefined && editable && (
					<>
						<div className="flex-1"></div>

						<div>
							<Button circle size={Size.sm} intent={Intent.tertiary} variant={Variant.light} onClick={() => setState('edit')}>
								<FontAwesomeIcon icon="pencil" />
							</Button>
							<Button circle size={Size.sm} intent={Intent.tertiary} variant={Variant.danger} onClick={() => setState('delete')}>
								<FontAwesomeIcon icon="trash" />
							</Button>
						</div>
					</>
				)}
			</div>

			{expanded && (
				<Droppable isDropDisabled={!editable} droppableId={String(category?.Id)} type="message">
					{({ droppableProps, innerRef, placeholder }) => (
						<div {...droppableProps} ref={innerRef} className={`${orderedMessages.length <= 0 ? 'mt-5' : ''} space-y-6 pb-3 relative`}>
							{orderedMessages.length > 0 ? (
								orderedMessages.map((message, index) => (
									<Draggable key={String(message.Id)} draggableId={String(message.Id)} index={index} isDragDisabled={message.creator.ID !== account!.ID}>
										{({ innerRef, draggableProps, dragHandleProps }) => (
											<CannedMessageItem ref={innerRef} {...draggableProps} {...dragHandleProps} message={message} className={`${index === 0 ? 'mt-4' : ''}`} />
										)}
									</Draggable>
								))
							) : (
								<div className="flex flex-col items-center py-10 space-y-4 border-4 border-gray-200 border-dashed rounded-lg">
									<FontAwesomeIcon icon={['far', 'arrow-square-down']} className="text-gray-200" size="3x" />
									<p className="text-xl text-gray-300">
										<FormattedMessage id="canned-categories-crud.no_messages" />
									</p>
								</div>
							)}
							{placeholder}
						</div>
					)}
				</Droppable>
			)}
			{state === 'edit' && category && <CannedMessageCategoryForm category={category} onAfterClose={() => setState('view')} />}
			{state === 'delete' && category && <DialogDeleteCannedMessageCategory category={category} onAfterClose={() => setState('view')} />}
		</>
	);
};
