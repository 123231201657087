import { Subtitle, Title } from '@components/Account';
import { InvoiceManager } from '@components/Invoices';
import { BillingAddressesManager, BillingCardsManager, SubscriptionOverview } from '@components/Organization';
import AppContext from '@contexts/AppContext';
import { useAccount } from '@hooks/useAccount';
import { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const SubscriptionPage = () => {
	const { account } = useAccount();
	const { formatMessage } = useIntl();
	const { setPageTitle } = useContext(AppContext);

	useEffect(() => {
		setPageTitle(formatMessage({ id: 'account.settings' }));
	}, [formatMessage, setPageTitle]);

	return (
		<article className="max-w-3xl">
			<Title>
				<FormattedMessage id="account.subscription" />
			</Title>
			<div className="space-y-12">
				<section>
					<Subtitle>
						<FormattedMessage id="subscription.details" />
					</Subtitle>
					<SubscriptionOverview />
				</section>
				{account!.business.HasInvoices && (
					<section>
						<Subtitle>
							<FormattedMessage id="billing-history.title" />
						</Subtitle>
						<InvoiceManager />
					</section>
				)}
				<section>
					<Subtitle>
						<FormattedMessage id="account.payment_method" />
					</Subtitle>
					<BillingCardsManager />
				</section>
				<section>
					<Subtitle>
						<FormattedMessage id="account.billing_address" />
					</Subtitle>
					<BillingAddressesManager />
				</section>
			</div>
		</article>
	);
};
