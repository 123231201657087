import EmailIdImage from '@assets/images/email-id.svg?react';
import { Button, InputBlock, Intent, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import { useAccountProfileUpdateMutation } from '@state/queries/profile';
import Card from '@ui/Card';
import { CopyToClipboard } from '@ui/CopyToClipboard';
import { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const AccountEmailId = () => {
	const { formatMessage } = useIntl();
	const { account } = useAccount();

	const [copied, setCopied] = useState(false);

	const emailInput = useRef<HTMLInputElement>(null);

	const { mutateAsync: update, isLoading } = useAccountProfileUpdateMutation();

	const toggle = async (enabled: boolean) => {
		try {
			await update({ emailId: enabled });
		} catch {
			// TODO: Show error message to user
		}
	};

	const enabled = account!.EmailId !== null;

	const onEmailInputClicked = () => {
		emailInput.current?.focus();
		emailInput.current?.select();
	};

	return (
		<Card>
			{isLoading && (
				<p>
					<FontAwesomeIcon icon="spinner" pulse className="mr-2" />
					<FormattedMessage id="loading" />
				</p>
			)}

			{!isLoading && (
				<>
					<div className="flex items-start">
						<div className="prose-sm prose">
							<p>{enabled ? <FormattedMessage id="dialog.email-id.intro-activated" /> : <FormattedMessage id="dialog.email-id.intro" />}</p>
							{!enabled && (
								<div className="flex mt-6">
									<Button type="submit" variant={Variant.primary} disabled={isLoading} onClick={() => toggle(true)}>
										<FormattedMessage id="dialog.email-id.activate" />
									</Button>
								</div>
							)}
						</div>
						<div className="hidden ml-6 sm:block">
							<EmailIdImage className="w-48" />
						</div>
					</div>

					{enabled && (
						<>
							<div className="flex items-stretch mt-4">
								<InputBlock
									ref={emailInput}
									onClick={onEmailInputClicked}
									value={formatMessage({ id: 'dialog.email-id.email' }, { id: account!.EmailId })}
									readOnly
									className="font-mono border-r-0 rounded-r-none"
								/>
								<CopyToClipboard text={formatMessage({ id: 'dialog.email-id.email' }, { id: account!.EmailId })} onCopy={() => setCopied(true)}>
									<Button intent={Intent.secondary} variant={copied ? Variant.primary : Variant.light} disabled={copied} className="rounded-l-none flex-nowrap">
										{!copied && (
											<>
												<FontAwesomeIcon icon="clipboard" className="mr-2" />
												<FormattedMessage id="dialog.email-id.copy" />
											</>
										)}
										{copied && (
											<>
												<FontAwesomeIcon icon="check" className="mr-2" />
												<FormattedMessage id="dialog.email-id.copied" />
											</>
										)}
									</Button>
								</CopyToClipboard>
							</div>
							<div className="h-px mt-6 mb-4 bg-gray-50" />
							<div className="flex justify-end">
								<Button type="submit" variant={Variant.danger} intent={Intent.tertiary} size={Size.xs} disabled={isLoading} loading={isLoading} onClick={() => toggle(false)}>
									<FormattedMessage id="dialog.email-id.deactivate" />
								</Button>
							</div>
						</>
					)}
				</>
			)}
		</Card>
	);
};
