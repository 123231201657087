import { forwardRef, type ForwardRefRenderFunction, type InputHTMLAttributes, useImperativeHandle, useRef } from 'react';
import type { UploaderHandles } from './Uploader';

type UploadButtonProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'className' | 'type'> & {
	onFilesSelected?: (files: File[]) => void;
};

export interface UploadButtonHandles {
	chooseFiles(): void;
}

const UploadButtonRenderFunction: ForwardRefRenderFunction<UploaderHandles, UploadButtonProps> = ({ multiple = true, disabled = false, onFilesSelected = () => undefined, ...inputProps }, ref) => {
	const fileInput = useRef<HTMLInputElement>(null);

	const _onFilesSelected = () => {
		if (onFilesSelected !== undefined && fileInput.current !== null && fileInput.current.files?.length) {
			onFilesSelected([...Array.from(fileInput.current.files)]);
			fileInput.current.value = '';
		}
	};

	useImperativeHandle(ref, () => ({
		chooseFiles: () => {
			if (fileInput && fileInput.current && !disabled) {
				fileInput.current.value = '';
				fileInput.current.click();
			}
		}
	}));

	return <input className="hidden" ref={fileInput} multiple={multiple} type="file" onChange={_onFilesSelected} {...inputProps} />;
};

export const UploadButton = forwardRef(UploadButtonRenderFunction);
