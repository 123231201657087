import File from '@models/File';
import Folder from '@models/Folder';
import User from '@models/User';
import type { ModelType, SignRequestRole } from '@types';

export default class SignRequest implements ModelType {
	'@type': 'SignRequest';
	Id!: number;
	Label!: string;
	Link!: string;
	creator!: User;
	roles!: SignRequestRole[];
	documents!: File[];
	folder!: Folder | null;
	PackageId!: string | null;
	UsingTemplate!: boolean;
	activated_at!: string | null;
	completed_at!: string | null;
	created_at!: string;
	archived_at!: string | null;

	constructor(attributes: Record<string, any> = {}) {
		Object.assign(this, attributes);

		if (attributes?.folder) {
			this.folder = new Folder(attributes.folder);
		}

		if (attributes?.documents) {
			this.documents = (attributes?.documents as File[]).map(document => new File(document));
		}
	}

	id() {
		return `sr_${this.Id}`;
	}
}
