import { library } from '@fortawesome/fontawesome-svg-core';

import * as duoToneIcons from './duotone';
import * as brandIcons from './brands';
import * as lightIcons from './light';
import * as regularIcons from './regular';
import * as solidIcons from './solid';

const icons = [...Object.values(duoToneIcons), ...Object.values(brandIcons), ...Object.values(lightIcons), ...Object.values(regularIcons), ...Object.values(solidIcons)];

library.add(...icons);
