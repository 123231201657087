import type { StripeElementStyle } from '@stripe/stripe-js';

export const locales = ['en_US', 'fr_CA'];

export const defaultLocale = 'en';

export const defaultCurrency = 'cad';

export const defaultCountry = 'CA';

export const cardElementStyles: StripeElementStyle = {
	base: {
		lineHeight: '1.5',
		fontSize: '16px',
		fontFamily: [
			'system-ui',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'"Helvetica Neue"',
			'Arial',
			'"Noto Sans"',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
			'"Noto Color Emoji"'
		].join(', ')
	},
	invalid: {
		iconColor: '#e53e3e',
		color: '#e53e3e'
	}
};

export const products = {
	modules: {
		payments: {
			amount: 1499,
			currency: 'cad'
		}
	}
};

export const stripeConnectConfigs = {
	card: {
		limit: 999999.99,
		fees: {
			percentage: 0.029,
			amount: 0.3
		}
	},
	acss_debit: {
		limit: 2999.99,
		fees: {
			percentage: 0.01,
			maximum: 5
		}
	}
};

export const trialDays = 14;

export const maxZipFileSize = 1024 * 1024 * 1024 * 5;

export const extraStorageBlock = 1024 * 1024 * 1024 * 100;
