import type { DateTimeFormat } from '@components/DateTime';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { Tooltip } from '@ui/Tooltip';
import { format as formatDate, formatDistanceToNow, parseISO } from 'date-fns';
import { enUS, frCA } from 'date-fns/locale';
import { type FC, type HTMLAttributes, useMemo } from 'react';
import { useIntl } from 'react-intl';

type DateTimeDisplayProps = HTMLAttributes<HTMLSpanElement> & {
	value?: Date | string | null;
	defaultFormat?: DateTimeFormat;
	showTooltip?: boolean;
	force?: boolean;
};

export const DateTimeDisplay: FC<DateTimeDisplayProps> = ({ value, defaultFormat = 'short', showTooltip = true, force = false, ...spanProps }) => {
	const { locale: userLocale } = useIntl();

	let [format] = useLocalStorage('datetime.format');

	const parsedValue = useMemo(() => (value instanceof Date ? value : !value ? null : parseISO(value)), [value]);

	if (parsedValue === null) {
		return null;
	}

	const locale = userLocale.substring(0, 2) === 'fr' ? frCA : enUS;

	let dateFnsFormat = 'Pp';

	format = force ? defaultFormat : format ?? defaultFormat;

	if (format === 'relative') {
		let child = <span {...spanProps}>{formatDistanceToNow(parsedValue, { locale, addSuffix: true })}</span>;
		if (showTooltip) {
			return <Tooltip tip={formatDate(parsedValue, 'PPPPpp', { locale })}>{child}</Tooltip>;
		}

		return child;
	} else if (format === 'full') {
		dateFnsFormat = 'PPPPpp';
	} else if (format === 'long') {
		dateFnsFormat = 'PPPpp';
	} else if (format === 'medium') {
		dateFnsFormat = 'PPPp';
	} else if (format === 'short') {
		dateFnsFormat = 'PPp';
	} else if (format === 'mini') {
		dateFnsFormat = 'Pp';
	}

	let child = <span {...spanProps}>{formatDate(parsedValue, dateFnsFormat, { locale })}</span>;
	if (showTooltip) {
		return <Tooltip tip={formatDate(parsedValue, 'PPPPpp', { locale })}>{child}</Tooltip>;
	}

	return child;
};
