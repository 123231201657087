import useClient from '@hooks/useClient';
import { downloadResponse } from '@service/Client';
import { Button, HelperText, Intent, Label, Row, Size, Variant } from '@convoflo/ui';
import Card from '@ui/Card';
import { formatDate } from '@utils/DateUtils';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';

type DataExportProps = {
	scope: 'user' | 'organization';
};

export const DataExport: FC<DataExportProps> = ({ scope }) => {
	const { client } = useClient();

	const { mutate: _export, isLoading } = useMutation(
		async () =>
			await client
				.url(scope === 'user' ? 'account/export/secure-spaces.csv' : 'organization/export/secure-spaces.csv')
				.get()
				.res(),
		{
			onSuccess: downloadResponse(`convoflo-secure-spaces-${formatDate(null, null, 'yMMddHHmmss')}.csv`, 'text/csv;charset=UTF-8')
		}
	);

	return (
		<Card>
			<Row className="flex items-center gap-6">
				<div className="flex-1">
					<Label htmlFor="secure_space_session_timeout-field">
						<FormattedMessage id="account-export.title" />
					</Label>
					<HelperText>
						<FormattedMessage id={scope === 'organization' ? 'account-export.desc_organization' : 'account-export.desc'} />
					</HelperText>
				</div>
				<div>
					<Button loading={isLoading} type="button" size={Size.sm} variant={Variant.primary} intent={Intent.secondary} onClick={() => _export()} disabled={isLoading}>
						<FormattedMessage id="account-export.btn" />
					</Button>
				</div>
			</Row>
		</Card>
	);
};
