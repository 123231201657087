import { FileManagerContext } from '@components/FileManager';
import { FileSelector, type FileSelectorSelection } from '@components/FileSelector';
import { Alert, Button, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Row, Size, ValidationField, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useView } from '@hooks/use-view';
import useClient from '@hooks/useClient';
import File from '@models/File';
import Folder from '@models/Folder';
import { type Fileable, Permissions } from '@types';
import { type FC, type FormEvent, useCallback, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';
import type { WretchError } from 'wretch';

export type MovedEvent = {
	sourceFiles: File[];
	sourceFolders: Folder[];
	destination: Folder | null;
};

type MovedResponse = {
	destination_folder: Folder | null;
	source_files: File[];
	source_folders: Folder[];
	new_files: File[];
	new_folders: Folder[];
};

type MoveMutation = {
	files: File[];
	folders: Folder[];
	destination: Folder | null;
};

type DialogMoveFileablesProps = Omit<ModalProps, 'isOpen'> & {
	fileables: Fileable[];
	destination?: Folder | null;
	onMoved?: (event: MovedEvent) => void;
};

export const DialogMoveFileables: FC<DialogMoveFileablesProps> = ({ fileables, destination, onMoved = () => undefined, ...props }) => {
	const { client, validation } = useClient();
	const { add: addItem = () => undefined, remove: removeItem = () => undefined } = useContext(FileManagerContext) ?? {};
	const { view, update: updateView } = useView();

	const [isOpen, setIsOpen] = useState(true);
	const [selectedFolder, setSelectedFolder] = useState(destination);
	const [showFileSelector, setShowFileSelector] = useState(false);

	const { mutate: move, isLoading: isMoving } = useMutation<MovedEvent, WretchError, MoveMutation>(
		async ({ files, folders, destination }) => {
			const data = {
				files: files.map((fileable: Fileable) => fileable.OriginalLink),
				folders: folders.map((fileable: Fileable) => fileable.OriginalLink),
				folder: destination !== null ? destination.getKey() : null
			};

			const { destination_folder, source_files, source_folders } = await client.url('files/move').post(data).json<MovedResponse>();

			return {
				destination: destination_folder !== null ? new Folder(destination_folder) : null,
				sourceFiles: source_files.map((file: File) => new File(file)),
				sourceFolders: source_folders.map((folder: Folder) => new Folder(folder))
			};
		},
		{
			onError: error => console.error(error),
			onSuccess: event => {
				const fileables = (event.sourceFolders as Fileable[]).concat(event.sourceFiles as Fileable[]);

				onMoved(event);

				event.sourceFiles.forEach(file => {
					if (view instanceof File && file.id() === view.id()) {
						updateView(file);
					}
				});

				event.sourceFolders.forEach(folder => {
					if (view instanceof Folder && folder.id() === view.id()) {
						updateView(folder);
					}
				});

				// Remove the selected fileables from the FileManager if they exist
				removeItem(fileables);

				// Add the selected fileables to the FileManager if the destination matches
				if (view instanceof Folder && event.destination?.getKey() === view.getKey()) {
					addItem(fileables);
				}

				// Close when we are done
				setIsOpen(false);
				toast.success(<FormattedMessage id="dialog-move.moved" values={{ n: event.sourceFiles.length + event.sourceFolders.length, item: fileables[0].getName() }} />);
			}
		}
	);

	const onSubmit = (event: FormEvent) => {
		event.preventDefault();
		const files = fileables.filter((f: Fileable) => f instanceof File) as File[];
		const folders = fileables.filter((f: Fileable) => f instanceof Folder) as Folder[];
		if (selectedFolder === undefined) {
			return;
		}
		move({ files, folders, destination: selectedFolder });
	};

	const onItemsSelected = useCallback((items: FileSelectorSelection) => setSelectedFolder(items.length === 0 ? null : items[0]), []);

	const isItemDisabled = (fileable: Fileable) => {
		if (fileables.some(_fileable => fileable.id() === _fileable.id())) {
			return true;
		}

		if (!(fileable instanceof Folder)) {
			return true;
		}

		return fileable.pivot?.Permissions !== Permissions.ReadWrite;
	};

	return (
		<Modal isOpen={isOpen} onSubmit={onSubmit} {...props}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage
					id="dialog-move.title"
					values={{
						n: fileables.length,
						item:
							fileables.length > 0 ? (
								<span>
									{fileables[0].icon('mx-2')}
									{fileables[0].getName()}
								</span>
							) : null
					}}
				/>
			</ModalHeaderOnlyTitle>
			<ModalBody disabled={isMoving}>
				<Row>
					<Label>
						<FormattedMessage id="dialog-move.move-to" />
					</Label>
					<ValidationField fieldName="folder" validation={validation}>
						<div className="flex items-center space-x-4">
							<Button type="button" size={Size.sm} variant={Variant.light} intent={Intent.secondary} onClick={() => setShowFileSelector(true)}>
								{/* <FontAwesomeIcon icon="folder" className="mr-2" /> */}
								<FormattedMessage id="dialog-move.select_destination" />
							</Button>
							{selectedFolder && (
								<span className="text-sm italic text-gray-700">
									{selectedFolder.icon('mr-2')}
									{selectedFolder.getName()}
								</span>
							)}
							{selectedFolder === null && (
								<span className="text-sm italic text-gray-700">
									<FontAwesomeIcon icon="home" className="mr-2" />
									<FormattedMessage id="file-selector.root" />
								</span>
							)}
						</div>
					</ValidationField>

					{showFileSelector && (
						<FileSelector
							startingFolder={view instanceof Folder ? view : null}
							onAfterClose={() => setShowFileSelector(false)}
							selectedItems={selectedFolder ? [selectedFolder] : undefined}
							isItemDisabled={isItemDisabled}
							onItemsSelected={onItemsSelected}
						/>
					)}
				</Row>
				{selectedFolder && (
					<Alert variant={Variant.warning}>
						<p>
							<FormattedMessage id="dialog-move.move-permission-mention" values={{ n: fileables.length, folder: <strong>{selectedFolder.Name}</strong> }} />
						</p>
					</Alert>
				)}
			</ModalBody>
			<ModalFooter>
				<Button variant={Variant.primary} disabled={isMoving || selectedFolder === undefined} type="submit">
					{isMoving ? <FormattedMessage id="dialog-move.moving" /> : <FormattedMessage id="dialog-move.move" />}
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
