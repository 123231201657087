import fileSize from 'filesize';

/**
 * Get the portion of a string before the last occurrence of a given value.
 *
 * https://github.com/laravel/framework/blob/8.x/src/Illuminate/Support/Str.php#L126
 *
 * @param string  subject
 * @param string  search
 * @return string
 */
export function beforeLast(subject: string, search: string): string {
	if (search === '') {
		return search;
	}

	const position = subject.lastIndexOf(search);

	if (position === -1) {
		return subject;
	}

	return subject.substring(0, position);
}

/**
 * Return the remainder of a string after the last occurrence of a given value.
 *
 * https://github.com/laravel/framework/blob/8.x/src/Illuminate/Support/Str.php#L75
 *
 * @param string  subject
 * @param string  search
 * @return string
 */
export function afterLast(subject: string, search: string): string {
	if (search === '') {
		return subject;
	}

	const position = subject.lastIndexOf(search);

	if (position === -1) {
		return subject;
	}

	return subject.substring(position + search.length);
}

type FileableUrl = null | {
	full: string;
	isFolder: boolean;
	isFile: boolean;
	param1: string;
	param2: string;
	url: string;
	rest: string;
};

/**
 * @param string A url which resembles "/vf/111/2222" or "/dl/111/222"
 */
export const parseFileableUrl = (string: string | null): FileableUrl => {
	if (string === null) {
		return null;
	}

	const match = string.match(/\/?(vf|dl)\/([a-z0-9]+)\/([a-z0-9]+)(?:\/(.*))?/i);

	if (match === null) {
		return null;
	}

	return {
		full: match[0],
		isFolder: match[1] === 'vf',
		isFile: match[1] === 'dl',
		param1: match[2],
		param2: match[3],
		url: `${match[2]}/${match[3]}`,
		rest: match[4]
	};
};

export const isFileableUrl = (string: string | null): boolean => parseFileableUrl(string) !== null;

/**
 * Math.random should be unique because of its seeding algorithm.
 * Convert it to base 36 (numbers + letters), and grab the first 9 characters
 * after the decimal.
 */
export const ID = () => {
	return '_' + Math.random().toString(36).substring(2, 9);
};

export const filesize = (bytes: number, locale: string) => {
	const localizedSymbols = locale.startsWith('fr')
		? {
				B: 'o',
				KB: 'ko',
				MB: 'Mo',
				GB: 'Go',
				TB: 'To',
				PB: 'Po',
				EB: 'Eo',
				ZB: 'Zo',
				YB: 'Yo'
		  }
		: undefined;

	return fileSize(bytes, { locale, symbols: localizedSymbols, spacer: ' ', base: 2 });
};

export const escapeRegExp = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const isIOS =
	['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

export const isBraveBrowser = 'brave' in navigator;

// Because AWS Amplify can't have empty ENV vars, we have to use ' ' for a blank value
export const env = (variable?: string) => {
	return (variable || '').trim() || undefined;
};

export const isUuid = (value: string) => /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi.test(value);
