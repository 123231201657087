import { Title } from '@components/Account';
import { DateTimeDisplay } from '@components/DateTime';
import { FileableItemThumbnail, FileableProvider, getIcon, getName, useFileable } from '@components/FileManager';
import { LabelSelector, type LabelType } from '@components/Labels';
import { type Comment, useMessage } from '@components/Message';
import { MessageProvider } from '@components/Message/MessageProvider';
import { type LaravelLengthAwarePaginatorType, type LaravelLengthAwareResourcePaginatorType, SimpleResourcePaginator, createResourceLengthAwarePagination } from '@components/Pagination';
import AppContext from '@contexts/AppContext';
import { InputDate, Label, Size } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import { useLocalStorage } from '@hooks/useLocalStorage';
import File from '@models/File';
import Folder from '@models/Folder';
import { ViewProvider } from '@providers/ViewProvider';
import Dropdown, { DropdownGroup, DropdownItem } from '@ui/Dropdown';
import EmptyState from '@ui/EmptyState';
import InsetButton from '@ui/InsetButton';
import { MarkdownContent } from '@ui/MarkdownContent';
import classNames from 'classnames';
import { format, formatISO, parse } from 'date-fns';
import { type FC, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

export type FoldersPaginatorResponse = {
	data: LaravelLengthAwarePaginatorType<Folder>;
	totals: Record<string, number>;
};

export const DashboardLabelsPage: FC = () => {
	const { formatMessage } = useIntl();
	const { client } = useClient();
	const { setPageTitle: setTitle, setPageHeader } = useContext(AppContext);
	const history = useHistory();
	const location = useLocation();

	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const [labels, setLabels] = useState<LabelType[]>([]);
	const [sort, setSort] = useState('!date');
	const [operator, setOperator] = useLocalStorage('dashboard.labels.operator', 'and');
	const [urlSettingsLoaded, setUrlSettingsLoaded] = useState(false);

	const { data: orgLabels } = useQuery(
		['labels'],
		async () => {
			let payload = await client.url('organization/properties').get().json<LabelType[]>();
			let labels: LabelType[] = [];

			payload.forEach(parentProp => {
				parentProp.options?.forEach(prop => labels.push(prop));
			});

			return labels;
		},
		{
			refetchOnWindowFocus: false
		}
	);

	useEffect(() => {
		setTitle(formatMessage({ id: 'organization.properties' }));
	}, [setTitle, formatMessage]);

	useEffect(() => {
		setPageHeader(
			<Title icon="tags">
				<FormattedMessage id="organization.properties" />
			</Title>
		);
	}, [setPageHeader]);

	//Url
	useEffect(() => {
		if (urlSettingsLoaded) {
			let baseUrl = `/dashboard/labels?sort=${sort}&operator=${operator}&`;

			if (startDate) {
				baseUrl += `startDate=${format(startDate, 'yyyy-MM-dd')}&`;
			}
			if (endDate) {
				baseUrl += `endDate=${format(endDate, 'yyyy-MM-dd')}&`;
			}
			if (labels.length > 0) {
				baseUrl += labels.map(label => `id[]=${label.Id}`).join('&');
			}

			history.push(baseUrl);
		}
	}, [labels, sort, startDate, endDate, operator, history, urlSettingsLoaded]);

	useEffect(() => {
		if (orgLabels) {
			let currLabels = [];

			for (const [key, value] of new URLSearchParams(location.search)) {
				if (value) {
					switch (key) {
						case 'sort':
							setSort(value);
							break;
						case 'operator':
							setOperator(value as 'and' | 'or');
							break;
						case 'startDate':
							setStartDate(parse(value, 'yyyy-MM-dd', new Date()));
							break;
						case 'endDate':
							setEndDate(parse(value, 'yyyy-MM-dd', new Date()));
							break;
						case 'id[]':
							let propId = value.replace(',', '');
							let label = orgLabels.find(prop => prop.Id === propId);

							if (label) {
								currLabels.push(label);
							}
							break;
					}
				}
			}
			setLabels(currLabels);
			setUrlSettingsLoaded(true);
		}
	}, [location.search, orgLabels, setOperator]);

	const manageLabels = (label: LabelType) => {
		let labelList: LabelType[] = [...labels];

		if (labels.find(labelTemp => labelTemp.Id === label.Id)) {
			setLabels(labelList.filter(labelTemp => labelTemp.Id !== label.Id));
		} else {
			labelList.push(label);
			setLabels(labelList);
		}
	};

	return (
		<>
			<Scrollbars autoHide>
				<div className="px-6 pb-8 mt-8 space-y-6">
					<div className="flex items-center mb-4 space-x-4">
						<h3 className="text-base font-medium text-gray-400 md:text-xs">
							<FormattedMessage id="dashboard-labels.filters" />
						</h3>
						<div className="flex-1 h-px bg-gray-300"></div>
					</div>

					<div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
						<div className="mb-2">
							<Label>
								<FormattedMessage id="dashboard-labels.labels" />
							</Label>

							<div className="mb-2 mr-2">
								<select
									className="inline-block w-full p-0 pr-8 m-0 mb-2 text-xs text-gray-500 whitespace-normal bg-transparent border-0"
									value={operator}
									onChange={e => setOperator(e.target.value)}>
									<FormattedMessage id="dashboard-labels.and">{msg => <option value="and">{msg}</option>}</FormattedMessage>
									<FormattedMessage id="dashboard-labels.or">{msg => <option value="or">{msg}</option>}</FormattedMessage>
								</select>

								<div className="flex flex-wrap gap-1 p-1 bg-white/50 rounded-xl">
									{labels.map(label => (
										<LabelSelector key={label.Id} label={label} onRemove={label => manageLabels(label)} onChange={label => manageLabels(label)} editable={false} />
									))}
									<LabelSelector onChange={label => manageLabels(label)} isDisabled={label => labels.some(_label => _label.Id === label.Id)} />
								</div>
							</div>
						</div>

						<div className="pl-8 border-l border-gray-300">
							<Label htmlFor="start_date">
								<FormattedMessage id="dashboard-labels.date_range" />
							</Label>
							<div className="flex items-center gap-3">
								<InputDate id="start_date" size={Size.sm} value={startDate} placeholder={formatMessage({ id: 'alert-history.select_date' })} onChange={setStartDate} />
								<label htmlFor="end_date" className="text-sm text-gray-500">
									<FormattedMessage id="dashboard-labels.end_date" />
								</label>
								<InputDate id="end_date" block size={Size.sm} value={endDate} placeholder={formatMessage({ id: 'alert-history.select_date' })} onChange={setEndDate} />
							</div>
						</div>
					</div>

					{labels.length > 0 && (
						<>
							<div className="flex items-center mb-4 space-x-4">
								<h3 className="text-base font-medium text-gray-400 md:text-xs">
									<FormattedMessage id="dashboard-labels.results" />
								</h3>
								<div className="flex-1 h-px bg-gray-300"></div>
								<Dropdown placement="bottom-end">
									<InsetButton size={Size.sm}>
										<FontAwesomeIcon icon="sort" className="mr-2" />
										<FormattedMessage id="file-manager.sort" />
									</InsetButton>
									<DropdownGroup>
										<DropdownItem icon="sort-numeric-up" onClick={() => setSort('!date')}>
											<FormattedMessage id="alert-history.newest" />
										</DropdownItem>
										<DropdownItem icon="sort-numeric-down" onClick={() => setSort('date')}>
											<FormattedMessage id="alert-history.oldest" />
										</DropdownItem>
										{/* <DropdownItem icon="tags" onClick={() => setSort('!labeled_at')}>
											<FormattedMessage id="dashboard-labels.labeled_at_desc" />
										</DropdownItem>
										<DropdownItem icon="tags" onClick={() => setSort('labeled_at')}>
											<FormattedMessage id="dashboard-labels.labeled_at_asc" />
										</DropdownItem> */}
									</DropdownGroup>
								</Dropdown>
							</div>

							<div className="grid items-start grid-cols-1 gap-8 mt-5 lg:grid-cols-3">
								<MessagesBlock startDate={startDate ?? undefined} endDate={endDate ?? undefined} labels={labels} operator={operator} sort={sort} />
								<FoldersBlock startDate={startDate ?? undefined} endDate={endDate ?? undefined} labels={labels} operator={operator} sort={sort} />
								<ViewProvider>{view => <FilesBlock startDate={startDate ?? undefined} endDate={endDate ?? undefined} labels={labels} operator={operator} sort={sort} />}</ViewProvider>
							</div>
						</>
					)}
					{labels.length <= 0 && <EmptyState title={formatMessage({ id: 'dashboard-labels.no_labels' })} icon="tags" />}
				</div>
			</Scrollbars>
		</>
	);
};

type LabelsDashboardBlock = {
	startDate?: Date;
	endDate?: Date;
	labels?: LabelType[];
	operator?: string;
	sort?: string;
};

const FilesBlock: FC<LabelsDashboardBlock> = ({ startDate, endDate, labels = [], operator = 'and', sort = '!date' }) => {
	const { client } = useClient();

	const [page, setPage] = useState(1);

	const { data, isLoading } = useQuery(
		['labels', 'files', startDate, endDate, labels, operator, sort, page],
		async () => {
			let payload = await client
				.url('account/properties/files')
				.query(labels.map(label => `id[]=${label.Id}`).join('&'))
				.query({
					operator,
					start_date: startDate ? formatISO(startDate, { representation: 'date' }) : null,
					end_date: endDate ? formatISO(endDate, { representation: 'date' }) : null,
					sort,
					page
				})
				.get()
				.json<LaravelLengthAwareResourcePaginatorType<File, {}>>();

			payload.data = payload.data.map((file: File) => new File(file));
			return createResourceLengthAwarePagination<File, {}>(payload);
		},
		{
			refetchOnWindowFocus: false,
			enabled: labels.length > 0
		}
	);

	return (
		<div>
			<header className="flex items-center justify-between mb-4">
				<h3 className="text-base font-medium">
					<FontAwesomeIcon icon="copy" className="mr-2 text-gray-400" />
					<FormattedMessage id="dashboard-labels.files" />
				</h3>
				<span className="py-0.5 px-2.5 text-xs text-white bg-gray-400 rounded-full">{data?.meta.total}</span>
			</header>
			<div className="flex flex-col gap-6">
				{isLoading && <LoadingModelItems count={4} />}
				{!isLoading &&
					data?.data?.map(file => (
						<FileableProvider fileable={file}>
							<LabelsDashboardFileItem />
						</FileableProvider>
					))}
			</div>

			{!isLoading && data !== undefined && data.data.length > 0 && (
				<footer className="mt-3">
					<SimpleResourcePaginator {...data} onPage={setPage} />
				</footer>
			)}

			{!isLoading && data !== undefined && data.data.length <= 0 && (
				<p className="p-6 text-sm italic text-center text-gray-500">
					<FormattedMessage id="dashboard-labels.no_files" />
				</p>
			)}
		</div>
	);
};

const LabelsDashboardFileItem = () => {
	const { fileable, navigate } = useFileable()!;

	return (
		<button onClick={() => navigate(true)} className="flex flex-col items-stretch w-full text-left bg-white border hover:cursor-pointer">
			<div className="flex items-center gap-3 p-3">
				<div className="relative flex items-center justify-center flex-shrink-0 w-8 h-8 sm:w-12 sm:h-12" onClick={e => e.stopPropagation()}>
					<FileableItemThumbnail fileable={fileable} />
				</div>
				<p className="flex-1 font-semibold truncate">{getName(fileable)}</p>
			</div>
			<div className="flex flex-col gap-1 p-3 bg-gray-50">
				<div onClick={e => e.stopPropagation()} className="flex flex-wrap gap-2 bg-gray-50">
					{(fileable.properties ?? []).map(prop => (
						<LabelSelector key={prop.Id} label={prop} model={fileable} />
					))}
					<span className="transition-opacity opacity-100 lg:opacity-0 hover:opacity-100">
						<LabelSelector model={fileable} />
					</span>
				</div>
				<p className="mt-1 text-xs text-gray-500">
					<DateTimeDisplay value={fileable.created_at} /> &ndash; {fileable.creator.Name}
				</p>
			</div>
		</button>
	);
};

const FoldersBlock: FC<LabelsDashboardBlock> = ({ startDate, endDate, labels = [], operator = 'and', sort = '!date' }) => {
	const { client } = useClient();

	const [page, setPage] = useState(1);

	const { data, isLoading } = useQuery(
		['labels', 'folders', startDate, endDate, labels, operator, sort, page],
		async () => {
			let payload = await client
				.url('account/properties/folders')
				.query(labels.map(label => `id[]=${label.Id}`).join('&'))
				.query({
					operator,
					start_date: startDate ? formatISO(startDate, { representation: 'date' }) : null,
					end_date: endDate ? formatISO(endDate, { representation: 'date' }) : null,
					sort,
					page
				})
				.get()
				.json<LaravelLengthAwareResourcePaginatorType<Folder, {}>>();

			payload.data = payload.data.map((file: Folder) => new Folder(file));
			return createResourceLengthAwarePagination<Folder, {}>(payload);
		},
		{
			refetchOnWindowFocus: false,
			enabled: labels.length > 0
		}
	);

	return (
		<div>
			<header className="flex items-center justify-between mb-4">
				<h3 className="text-base font-medium">
					<FontAwesomeIcon icon="folder" className="mr-2 text-gray-400" />
					<FormattedMessage id="dashboard-labels.folders" />
				</h3>
				<span className="py-0.5 px-2.5 text-xs text-white bg-gray-400 rounded-full">{data?.meta.total}</span>
			</header>
			<div className="flex flex-col gap-6">
				{isLoading && <LoadingModelItems count={4} />}
				{!isLoading &&
					data?.data?.map(folder => (
						<FileableProvider fileable={folder}>
							<LabelsDashboardFolderItem />
						</FileableProvider>
					))}
			</div>

			{!isLoading && data !== undefined && data.data.length > 0 && (
				<footer className="mt-3">
					<SimpleResourcePaginator {...data} onPage={setPage} />
				</footer>
			)}

			{!isLoading && data !== undefined && data.data.length <= 0 && (
				<p className="p-6 text-sm italic text-center text-gray-500">
					<FormattedMessage id="dashboard-labels.no_files" />
				</p>
			)}
		</div>
	);
};

const LabelsDashboardFolderItem = () => {
	const { fileable, navigate } = useFileable()!;

	return (
		<button onClick={() => navigate(true)} className="flex flex-col items-stretch w-full text-left bg-white border hover:cursor-pointer">
			<div className="flex items-center gap-3 p-3">
				<div className="relative flex items-center justify-center flex-shrink-0 w-8 h-8 sm:w-12 sm:h-12" onClick={e => e.stopPropagation()}>
					<FileableItemThumbnail fileable={fileable} />
				</div>
				<p className="flex-1 font-semibold truncate">{getName(fileable)}</p>
			</div>
			<div className="flex flex-col gap-1 p-3 bg-gray-50">
				<div onClick={e => e.stopPropagation()} className="flex flex-wrap gap-2 bg-gray-50">
					{(fileable.properties ?? []).map(prop => (
						<LabelSelector key={prop.Id} label={prop} model={fileable} />
					))}
					<span className="transition-opacity opacity-100 lg:opacity-0 hover:opacity-100">
						<LabelSelector model={fileable} />
					</span>
				</div>
				<p className="mt-1 text-xs text-gray-500">
					<DateTimeDisplay value={fileable.created_at} /> &ndash; {fileable.creator.Name}
				</p>
			</div>
		</button>
	);
};

const MessagesBlock: FC<LabelsDashboardBlock> = ({ startDate, endDate, labels = [], operator = 'and', sort = '!date' }) => {
	const { client } = useClient();

	const [page, setPage] = useState(1);

	const { data, isLoading } = useQuery(
		['labels', 'messages', startDate, endDate, labels, operator, sort, page],
		async () =>
			createResourceLengthAwarePagination<Comment, {}>(
				await client
					.url('account/properties/messages')
					.query(labels.map(label => `id[]=${label.Id}`).join('&'))
					.query({
						operator,
						start_date: startDate ? formatISO(startDate, { representation: 'date' }) : null,
						end_date: endDate ? formatISO(endDate, { representation: 'date' }) : null,
						sort,
						page
					})
					.get()
					.json()
			),
		{
			refetchOnWindowFocus: false,
			enabled: labels.length > 0
		}
	);

	return (
		<div>
			<header className="flex items-center justify-between mb-4">
				<h3 className="text-base font-medium">
					<FontAwesomeIcon icon="comment" className="mr-2 text-gray-400" />
					<FormattedMessage id="dashboard-labels.comments" />
				</h3>
				<span className="py-0.5 px-2.5 text-xs text-white bg-gray-400 rounded-full">{data?.meta.total}</span>
			</header>
			<div className="flex flex-col gap-6">
				{isLoading && <LoadingModelItems count={4} />}
				{!isLoading &&
					data?.data?.map(message => (
						<MessageProvider message={message}>
							<LabelsDashboardMessageItem />
						</MessageProvider>
					))}
			</div>

			{!isLoading && data !== undefined && data.data.length > 0 && (
				<footer className="mt-3">
					<SimpleResourcePaginator {...data} onPage={setPage} />
				</footer>
			)}

			{!isLoading && data !== undefined && data.data.length <= 0 && (
				<p className="p-6 text-sm italic text-center text-gray-500">
					<FormattedMessage id="dashboard-labels.no_files" />
				</p>
			)}
		</div>
	);
};

const LabelsDashboardMessageItem = () => {
	const { message, navigate } = useMessage()!;

	const [showExpansion, setShowExpansion] = useState(true);
	const [expanded, setExpanded] = useState(false);

	const markdownRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		setShowExpansion((markdownRef.current?.clientHeight ?? 0) > 144);
	}, []);

	return (
		<button onClick={() => navigate(true)} className="flex flex-col items-stretch w-full text-left bg-white border hover:cursor-pointer">
			<div className="p-3">
				<p className="text-xs font-semibold text-gray-500">
					{getIcon(message.commentable!)} {getName(message.commentable!)}
				</p>
			</div>
			{message.Title && (
				<div className="px-4 py-2 bg-blue-50">
					<h4 className="text-sm font-semibold text-blue-600">{message.Title}</h4>
				</div>
			)}

			<div
				className={classNames('relative p-3 overflow-hidden', {
					'max-h-48': !expanded
				})}>
				<div ref={markdownRef}>
					{message.Format === 'markdown' && <MarkdownContent size="sm">{message.Comment}</MarkdownContent>}
					{message.Format === 'prosemirror' && <div className={classNames('prose max-w-none break-words prose-sm')} dangerouslySetInnerHTML={{ __html: message.Comment ?? '' }} />}
				</div>
				{showExpansion && !expanded && (
					<div className="backdrop-blur-[2px] absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-b from-transparent via-white to-white z-[1] flex items-center justify-center">
						<button
							className="w-full h-full text-sm text-theme-primary hover:underline"
							onClick={e => {
								e.stopPropagation();
								setExpanded(true);
							}}>
							<FormattedMessage id="see_more" />
						</button>
					</div>
				)}
			</div>

			<div className="flex flex-col gap-1 p-3 bg-gray-50">
				<div onClick={e => e.stopPropagation()} className="flex flex-wrap gap-2 bg-gray-50">
					{(message.properties ?? []).map(label => (
						<LabelSelector key={label.Id} label={label} model={message} />
					))}
					<span className="transition-opacity opacity-100 lg:opacity-0 hover:opacity-100">
						<LabelSelector model={message} />
					</span>
				</div>
				<p className="mt-1 text-xs text-gray-500">
					<DateTimeDisplay value={message.created_at} /> &ndash; {message.creator.Name}
				</p>
			</div>
		</button>
	);
};

const LoadingModelItems = ({ count = 3 }) => (
	<>
		{Array(count)
			.fill(0)
			.map((_: number, index) => (
				<div key={index} className="flex items-center p-3 hover:bg-gray-50 animate-pulse">
					<div className="mr-2">
						<div className="w-8 h-8 bg-gray-300 rounded-sm" />
					</div>

					<div className="flex-1">
						<div className="w-1/2 h-4 mb-2 bg-gray-300 rounded-sm"></div>
						<div className="h-3 bg-gray-200 rounded-sm w-1/7"></div>
					</div>
				</div>
			))}
	</>
);
