import { DateTimeDisplay } from '@components/DateTime';
import { DialogInviteDelete, type OrganizationInvite, Role } from '@components/Team';
import { Badge, Button, Intent, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import Dropdown, { DropdownGroup, DropdownItem } from '@ui/Dropdown';
import { TableCell } from '@ui/Table';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';
import type { WretchError } from 'wretch';

type InviteProps = {
	invite: OrganizationInvite;
};

export const InviteItem = ({ invite }: InviteProps) => {
	const { client } = useClient();

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [invitation, setInvitation] = useState<OrganizationInvite>(invite);

	const { mutate: resendMail } = useMutation<OrganizationInvite, WretchError>(
		async () =>
			await client
				.url(`org/users/invites`)
				.json({
					email: invite.Email
				})
				.put()
				.json<OrganizationInvite>(),
		{
			onError: console.log,
			onSuccess: (invitation: OrganizationInvite) => {
				toast.success(<FormattedMessage id="invitation.invite_resent" values={{ email: invitation.Email }} />);
				setInvitation(invitation);
			}
		}
	);

	return (
		<>
			<tr>
				<TableCell>
					<h5 className="text-sm font-bold break-words">{invite.Email}</h5>
				</TableCell>
				<TableCell>
					<Badge variant={invite.Role === Role.admin ? Variant.success : invite.Role === Role.regular ? Variant.primary : Variant.light}>
						<FormattedMessage id="role" values={{ role: invite.Role }} />
					</Badge>
				</TableCell>
				<TableCell>
					<span className="text-sm text-gray-600">
						<DateTimeDisplay value={invitation.created_at} />
					</span>
				</TableCell>
				<TableCell>
					{new Date() > new Date(invitation.expires_at) ? (
						<Badge variant={Variant.danger}>
							<FormattedMessage id="invitation.expired" />
						</Badge>
					) : (
						<span className="text-sm text-gray-600">
							<DateTimeDisplay value={invitation.expires_at} />
						</span>
					)}
				</TableCell>
				<TableCell className="text-right">
					<Dropdown>
						<Button variant={Variant.light} intent={Intent.tertiary} type="button" circle>
							<FontAwesomeIcon icon="ellipsis-v" className="text-gray-500" />
						</Button>
						<DropdownGroup>
							<DropdownItem icon="envelope" onClick={() => resendMail()}>
								<FormattedMessage id="invitation.resend" />
							</DropdownItem>

							<DropdownItem icon="trash-alt" onClick={() => setShowDeleteConfirmation(true)}>
								<FormattedMessage id="delete" />
							</DropdownItem>
						</DropdownGroup>
					</Dropdown>
				</TableCell>
			</tr>

			{showDeleteConfirmation && <DialogInviteDelete invite={invite} onAfterClose={() => setShowDeleteConfirmation(false)} />}
		</>
	);
};
