import DialogAccountPassword from '@components/DialogAccountPassword';
import { Button, HelperText, InputBlock, Label, PasswordInputBlock, Row, ValidationField, Variant } from '@convoflo/ui';
import { usePasswordStrengthCheck } from '@hooks/use-password-strength-check';
import useClient from '@hooks/useClient';
import useIsDirty from '@hooks/useIsDirty';
import type { ValidationErrors } from '@types';
import Card from '@ui/Card';
import ProgressBar from '@ui/ProgressBar';
import { type FormEvent, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { Prompt } from 'react-router-dom';
import type { WretchError, WretchResponse } from 'wretch';

type UpdatePasswordMutation = {
	password: string;
	passwordConfirmation: string;
	accountPassword?: string;
};

export const AccountPassword = () => {
	const { formatMessage } = useIntl();
	const { client } = useClient();

	const [isValid, setIsValid] = useState(false);
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	const [validation, setValidation] = useState<ValidationErrors>({});

	const passwordStrength = usePasswordStrengthCheck(password);

	const { isDirty, setIsDirty } = useIsDirty([password, passwordConfirmation]);

	const { mutate: save, isLoading: isSaving } = useMutation<WretchResponse, WretchError, UpdatePasswordMutation>(
		async ({ password, passwordConfirmation, accountPassword }) =>
			await client.url('account/security').put({ account_password: accountPassword, password, password_confirmation: passwordConfirmation }).res(),
		{
			onMutate: () => {
				setValidation({});
			},
			onError: error => {
				error.status === 422 && setValidation(error.json.errors);
			},
			onSuccess: () => {
				setIsDirty(false);
				setPassword('');
				setPasswordConfirmation('');
				toast.success(<FormattedMessage id="account-security.password-changed" />);
			}
		}
	);

	const onSubmit = (e?: FormEvent) => {
		if (e) {
			e.preventDefault();
		}
		if (password !== passwordConfirmation) {
			setValidation({ confirm_password: [formatMessage({ id: 'account-security.confirmation-invalid' })] });
			return;
		}
		save({ password, passwordConfirmation });
	};

	useEffect(() => {
		setIsValid(password.length >= 6 && passwordConfirmation.length >= 6);
	}, [password, passwordConfirmation]);

	const onAccountPasswordSubmitted = (accountPassword: string) => {
		save({ password, passwordConfirmation, accountPassword });
	};

	return (
		<>
			<Prompt when={isDirty} message={formatMessage({ id: 'unsaved_changes' })} />

			<Card onSubmit={onSubmit}>
				<Row>
					<Label htmlFor="password-field">
						<FormattedMessage id="account-security.new-password" />
					</Label>
					<div className="input-password-strength">
						<ValidationField fieldName="password" validation={validation}>
							<PasswordInputBlock
								id="password-field"
								onChange={e => setPassword(e.target.value)}
								value={password}
								placeholder={formatMessage({ id: 'account-security.placeholder-password' })}
								className="rounded-b-none"
								required
								minLength={8}
							/>
						</ValidationField>
						<ProgressBar reverseColors max={100} current={passwordStrength ?? 0} className="h-3 rounded-t-none" />
						<HelperText>
							<FormattedMessage id="account-security.password_rules" />
						</HelperText>
					</div>
				</Row>

				<Row>
					<Label htmlFor="confirm-password-field">
						<FormattedMessage id="account-security.confirm-password" />
					</Label>
					<ValidationField fieldName="confirm_password" validation={validation}>
						<InputBlock
							id="confirm-password-field"
							onChange={e => setPasswordConfirmation(e.target.value)}
							value={passwordConfirmation}
							placeholder={formatMessage({ id: 'account-security.placeholder-confirm-password' })}
							type="password"
							autoComplete="new-password"
							required
							minLength={8}
						/>
					</ValidationField>
				</Row>

				<Button type="submit" variant={Variant.primary} disabled={isSaving || !isDirty || !isValid} loading={isSaving} icon="save">
					<FormattedMessage id="account-security.change" />
				</Button>
			</Card>
			{'account_password' in validation && <DialogAccountPassword onAccountPasswordSubmitted={onAccountPasswordSubmitted} />}
		</>
	);
};
