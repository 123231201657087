import type { CartItem, CartItemPutRequest, CartType, ITEM_IDS, PAYMENT_FREQUENCIES, StoreResponseType } from '@components/Checkout';
import { createContext } from 'react';

export const CheckoutContext = createContext<
	| {
			// Store
			store?: StoreResponseType;
			isStoreLoading: boolean;

			// Cart
			cart?: CartType;
			isCartLoading: boolean;
			applyPromoCode: (promoCode: string) => void;
			setFrequency: (frequency: PAYMENT_FREQUENCIES) => void;
			addItem: (item: CartItemPutRequest) => void;
			updateItem: (item: CartItemPutRequest) => void;
			removeItemById: (id: ITEM_IDS) => void;
			addOrUpdateItem: (item: CartItemPutRequest) => void;
			toggleItem: (item: CartItemPutRequest) => void;
			getItemById: (id: ITEM_IDS) => CartItem | undefined;
	  }
	| undefined
>(undefined);
