import DialogAccountPassword from '@components/DialogAccountPassword';
import { Role } from '@components/Team';
import { Button, Variant } from '@convoflo/ui';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import type { ValidationErrors } from '@types';
import Card from '@ui/Card';
import { type FC, type FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';
import type { WretchError, WretchResponse } from 'wretch';

type DeleteAccountMutation = {
	accountPassword?: string;
};

export const AccountDelete: FC = () => {
	const { account, logout } = useAccount();
	const { client } = useClient();

	const [validation, setValidation] = useState<ValidationErrors>({});

	const { mutate: _delete, isLoading: isDeleting } = useMutation<WretchResponse, WretchError, DeleteAccountMutation>(
		async ({ accountPassword }) => await client.url('account').json({ account_password: accountPassword }).delete().res(),
		{
			onError: error => {
				error.status === 422 && setValidation(error.json.errors);
			},
			onSuccess: () => {
				toast.success(<FormattedMessage id="account-delete.account-deleted" />);
				setTimeout(() => {
					logout();
					window.location.reload();
				}, 5000);
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		_delete({});
	};

	const onAccountPasswordSubmitted = (accountPassword: string) => {
		_delete({ accountPassword });
	};

	return (
		<>
			<Card onSubmit={onSubmit}>
				<div className="prose">
					<p>
						<FormattedMessage id="account-delete.warning-desc" />
					</p>

					<ul>
						<li>
							<p>
								<FormattedMessage id="account-delete.consequence-1" />
							</p>
						</li>
						<li>
							<p>
								<FormattedMessage id="account-delete.consequence-2" />
							</p>
						</li>
						<li>
							<p>
								<FormattedMessage id="account-delete.consequence-3" />
							</p>
						</li>
					</ul>
				</div>

				<Button variant={Variant.danger} type="submit" disabled={isDeleting || account!.Role === Role.regular} loading={isDeleting} icon="trash">
					<FormattedMessage id="account-delete.delete" />
				</Button>
				{account!.Role === Role.regular && (
					<p className="text-gray-400 form-text">
						<FormattedMessage id="account-delete.forbidden-only-admins" />
					</p>
				)}
			</Card>
			{'account_password' in validation && <DialogAccountPassword onAccountPasswordSubmitted={onAccountPasswordSubmitted} />}
		</>
	);
};
