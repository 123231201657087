import untypedNotifications from '@assets/notification-types.json';
import { Title } from '@components/Account';
import { ComboBox } from '@components/ComboBox';
import { NotificationItem, type NotificationKind, type NotificationType } from '@components/Notification';
import { LengthAwarePaginator, createLengthAwarePagination } from '@components/Pagination';
import AppContext from '@contexts/AppContext';
import { InputDate, Label, Select as SelectInput, Size, Toggle } from '@convoflo/ui';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import useUrlSearch from '@hooks/useUrlSearch';
import User from '@models/User';
import Card from '@ui/Card';
import EmptyState from '@ui/EmptyState';
import UserAvatar from '@ui/UserAvatar';
import classNames from 'classnames';
import { formatISO, parseISO } from 'date-fns';
import random from 'lodash.random';
import qs from 'qs';
import { type FC, useContext, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { type OptionProps, components } from 'react-select';

const notificationTypes: Record<string, NotificationKind> = untypedNotifications;

type UserFilter = {
	value: number;
	user?: User;
	total?: number;
};

type ActionFilter = {
	value: string;
	action?: string;
	total?: number;
};

type AlertFiltersResponse = {
	users: UserFilter[];
	actions: ActionFilter[];
};

type NotificationsPageProps = {
	limit?: number;
	actions?: string[];
};

export const NotificationsPage: FC<NotificationsPageProps> = ({ limit = 50 }) => {
	const { formatMessage, locale } = useIntl();
	const { setPageHeader } = useContext(AppContext);
	const { client } = useClient();
	const { setPageTitle: setTitle } = useContext(AppContext);

	const { users: usersParam, actions: actionsParam, start: startDateParam, end: endDateParam, scope: scopeParam, source = 'history', read: readParam = '1' } = useUrlSearch();

	// Results
	const [currentPage, setCurrentPage] = useState(1);

	// Filters
	const [includeRead, setIncludeRead] = useState(readParam === '1');
	const [scope, setScope] = useState(scopeParam ?? '');
	const [startDate, setStartDate] = useState(startDateParam ? parseISO(startDateParam) : null);
	const [endDate, setEndDate] = useState(endDateParam ? parseISO(endDateParam) : null);
	const [sort, setSort] = useState('!date');
	const [selectedUsers, setSelectedUsers] = useState<UserFilter[]>(usersParam ? usersParam.split(',').map(id => ({ value: parseInt(id) })) : []);
	const [selectedActions, setSelectedActions] = useState<ActionFilter[]>(actionsParam ? actionsParam.split(',').map(id => ({ value: id })) : []);

	const selectedUsersId = selectedUsers.map(u => u.value);
	const selectedActionsId = selectedActions.map(a => a.value);

	const { data: notifications, isLoading } = useQuery(
		['alerts', scope, startDate, endDate, sort, selectedUsersId, selectedActionsId, currentPage, includeRead],
		async () =>
			createLengthAwarePagination<NotificationType>(
				await client
					.url('account/notifications')
					.query(
						qs.stringify({
							start_date: startDate ? formatISO(startDate) : '',
							end_date: endDate ? formatISO(endDate) : '',
							users: selectedUsersId,
							actions: selectedActionsId,
							page: currentPage,
							unread: includeRead ? '' : 1,
							scope,
							limit,
							sort
						})
					)
					.get()
					.json()
			),
		{
			refetchOnWindowFocus: false
		}
	);

	const { data: filters, isLoading: isFiltersLoading } = useQuery(
		['alert_filters', scope, startDate, endDate, selectedUsersId, selectedActionsId, includeRead],
		async () =>
			await client
				.url('account/notifications/filters')
				.query(
					qs.stringify({
						start_date: startDate ? formatISO(startDate) : '',
						end_date: endDate ? formatISO(endDate) : '',
						unread: includeRead ? '' : 1,
						users: selectedUsersId,
						actions: selectedActionsId
					})
				)
				.get()
				.json<AlertFiltersResponse>(),
		{
			onSuccess: ({ users, actions }) => {
				setSelectedActions(selectedActions => selectedActions.map(selectedAction => actions.find(action => action.value === selectedAction.value) ?? selectedAction));
				setSelectedUsers(setSelectedUsers => setSelectedUsers.map(selectedUser => users.find(user => user.value === selectedUser.value) ?? selectedUser));
			},
			refetchOnWindowFocus: false
		}
	);

	useEffect(() => {
		setTitle(formatMessage({ id: 'alert-history.title' }));
	}, [setTitle, formatMessage]);

	useEffect(() => {
		setPageHeader(
			<Title icon="bell">
				<FormattedMessage id="alert-history.title" />
			</Title>
		);
	}, [setPageHeader]);

	return (
		<Scrollbars autoHide>
			<div className="container py-8 md:px-8">
				<div className="grid items-start grid-cols-12 gap-4">
					<div className="grid grid-cols-2 col-span-12 gap-4 px-6 lg:grid-cols-1 lg:col-span-4 xl:col-span-3 2xl:col-span-2">
						<div>
							<Label htmlFor="show-read">
								<FormattedMessage id="alert-history.show_read_notifications" />
							</Label>
							<Toggle id="show-read" size={Size.sm} checked={includeRead} onChange={e => setIncludeRead(e.target.checked)} />
						</div>

						<div>
							<Label>
								<FormattedMessage id="alert-history.target" />
							</Label>
							<SelectInput block disabled={isFiltersLoading} value={scope} onChange={e => setScope(e.target.value)}>
								<FormattedMessage id="alert-history.any">{message => <option value="">{message}</option>}</FormattedMessage>
								<FormattedMessage id="alert-history.internal">{message => <option value="internal">{message}</option>}</FormattedMessage>
								<FormattedMessage id="alert-history.external">{message => <option value="external">{message}</option>}</FormattedMessage>
							</SelectInput>
						</div>

						<div>
							<Label>
								<FormattedMessage id="alert-history.sort" />
							</Label>
							<SelectInput block disabled={isFiltersLoading} value={sort} onChange={e => setSort(e.target.value)}>
								<FormattedMessage id="alert-history.newest">{message => <option value="!date">{message}</option>}</FormattedMessage>
								<FormattedMessage id="alert-history.oldest">{message => <option value="date">{message}</option>}</FormattedMessage>
							</SelectInput>
						</div>

						<div>
							<Label htmlFor="start_date">
								<FormattedMessage id="alert-history.start_date" />
							</Label>
							<InputDate block value={startDate} disabled={isFiltersLoading} placeholder={formatMessage({ id: 'alert-history.select_date' })} onChange={setStartDate} />
						</div>
						<div>
							<Label htmlFor="end_date">
								<FormattedMessage id="alert-history.end_date" />
							</Label>
							<InputDate block value={endDate} disabled={isFiltersLoading} placeholder={formatMessage({ id: 'alert-history.select_date' })} onChange={setEndDate} />
						</div>

						<div>
							<Label htmlFor="end_date">
								<FormattedMessage id="alert-history.users" />
							</Label>
							<ComboBox
								isDisabled={isFiltersLoading}
								isMulti
								value={selectedUsers}
								components={{
									Option: UserOption
								}}
								placeholder={<FormattedMessage id="alert-history.select" />}
								getOptionLabel={row => row.user?.Name ?? ''}
								getOptionValue={row => String(row.value)}
								options={filters?.users ?? []}
								onChange={(rows, action) => {
									if (action.action === 'clear') {
										setSelectedUsers([]);
										return;
									}
									setSelectedUsers((rows as UserFilter[]) ?? []);
								}}
							/>
						</div>
						<div>
							<Label htmlFor="end_date">
								<FormattedMessage id="alert-history.actions" />
							</Label>
							<ComboBox
								isDisabled={isFiltersLoading}
								components={{
									Option: ActionOption
								}}
								placeholder={<FormattedMessage id="alert-history.select" />}
								isMulti
								value={selectedActions}
								getOptionLabel={row => notificationTypes[row.value].label.short[locale]}
								getOptionValue={row => row.value}
								options={(filters?.actions ?? []).filter(a => a.value in notificationTypes)}
								onChange={(rows, action) => {
									if (action.action === 'clear') {
										setSelectedActions([]);
										return;
									}
									setSelectedActions((rows as ActionFilter[]) ?? []);
								}}
							/>
						</div>
					</div>

					<div className="col-span-12 lg:col-span-8 xl:col-span-9 2xl:col-span-10">
						{!isLoading && !!notifications?.data.length && (
							<div className="mb-6">
								<LengthAwarePaginator disabled={isLoading} {...notifications} onPage={setCurrentPage} />
							</div>
						)}
						<Card size={null}>
							{isLoading && <LoadingNotifications count={random(4, 10)} />}
							{!isLoading && notifications?.data.map(notification => <NotificationItem key={notification.Id} notification={notification} source={source} />)}
						</Card>
						{!isLoading && !notifications?.data.length && <EmptyState title={<FormattedMessage id="alerts.no_alerts" />} icon="bells" />}
						{!isLoading && !!notifications?.data.length && (
							<div className="mt-6">
								<LengthAwarePaginator disabled={isLoading} {...notifications} onPage={setCurrentPage} />
							</div>
						)}
					</div>
				</div>
			</div>
		</Scrollbars>
	);
};

const LoadingNotifications = ({ count = 3 }) => (
	<>
		{Array(count)
			.fill(0)
			.map((_: number, index) => (
				<div key={index} className="flex items-center p-3 hover:bg-gray-50 animate-pulse">
					<div className="mr-2">
						<div className="w-8 h-8 bg-gray-300 rounded-sm" />
					</div>

					<div className="flex-1">
						<div className="w-1/2 h-4 mb-2 bg-gray-300 rounded-sm"></div>
						<div className="h-3 bg-gray-200 rounded-sm w-1/7"></div>
					</div>
				</div>
			))}
	</>
);

const ActionOption = ({ ...props }: OptionProps<ActionFilter, true>) => {
	const { locale, formatNumber } = useIntl();

	return (
		<components.Option {...props}>
			<div className="flex items-center">
				<FontAwesomeIcon
					className={classNames('mr-2', notificationTypes[props.data.value].className)}
					style={!notificationTypes[props.data.value].className ? { color: notificationTypes[props.data.value].color } : {}}
					icon={notificationTypes[props.data.value].icon as IconProp}
					mask="circle"
					transform="shrink-9"
					size="2x"
				/>
				<div className="flex-1">
					<h6 className="font-semibold leading-snug">{notificationTypes[props.data.value].label.short[locale]}</h6>
					<p className="text-sm leading-snug text-black text-opacity-75">
						<FormattedMessage id="alert-history.total_alerts" values={{ n: formatNumber(props.data.total ?? 0) }} />
					</p>
				</div>
			</div>
		</components.Option>
	);
};

const UserOption = ({ ...props }: OptionProps<UserFilter, true>) => {
	if (!props.data.user) {
		return null;
	}

	return (
		<components.Option {...props}>
			<div className="flex items-center">
				<UserAvatar user={props.data.user} className="mr-2" size={Size.sm} />
				<div className="flex-1">
					<h6 className="font-semibold leading-snug">{props.data.user.Name}</h6>
					<p className="text-sm leading-snug text-black text-opacity-75">
						<FormattedMessage id="alert-history.total_alerts" values={{ n: props.data.total }} />
					</p>
				</div>
			</div>
		</components.Option>
	);
};
