import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import type { SessionType } from '@components/Account';
import { DateTimeDisplay } from '@components/DateTime';
import { createResourcePagination, SimpleResourcePaginator } from '@components/Pagination';
import useClient from '@hooks/useClient';
import { type FC, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Card from '@ui/Card';
import { useSessionsDeleteMutation, useSessionsDeleteOthersMutation, useSessionsQuery } from '@state/queries/sessions';

export const SessionManager: FC = () => {
	const [page, setPage] = useState(1);

	const { data, isLoading } = useSessionsQuery(page);

	const sessions = data?.data ?? [];

	const { mutateAsync: deleteOtherSessionsMutation } = useSessionsDeleteOthersMutation();

	const deleteOtherSessions = async () => {
		try {
			await deleteOtherSessionsMutation();
			toast.success(<FormattedMessage id="sessions.sessions_deleted" values={{ n: sessions.length - 1 }} />);
		} catch {
			// TODO: Show error message to user
		}
	};

	return (
		<Card>
			{isLoading && (
				<div className="flex flex-col items-center justify-center text-center">
					<FontAwesomeIcon icon="spinner" pulse size="3x" className="mb-4 text-gray-600" />
					<p className="italic text-gray-700">
						<FormattedMessage id="loading" />
					</p>
				</div>
			)}

			{!isLoading && (
				<>
					<div className="space-y-4 divide-y divide-gray-100">
						{sessions.map(session => (
							<SessionItem key={session.id} session={session} />
						))}
					</div>
					{data !== undefined && <SimpleResourcePaginator {...data} className="mt-8" onPage={setPage} />}
					{sessions.length > 1 && (
						<>
							<div className="h-px mt-6 mb-4 bg-gray-50" />
							<div className="flex justify-end">
								<Button type="submit" variant={Variant.danger} intent={Intent.tertiary} size={Size.xs} onClick={deleteOtherSessions}>
									<FormattedMessage id="sessions.log_out_all_other" />
								</Button>
							</div>
						</>
					)}
				</>
			)}
		</Card>
	);
};

type SessionItemProps = {
	session: SessionType;
};

const SessionItem: FC<SessionItemProps> = ({ session }) => {
	const { mutateAsync: deleteMutation } = useSessionsDeleteMutation();

	const _delete = async () => {
		try {
			await deleteMutation(session);
			toast.success(<FormattedMessage id="sessions.sessions_deleted" values={{ n: 1 }} />);
		} catch {
			// TODO: Show error message to user
		}
	};

	return (
		<div className="flex items-center pt-4 first:pt-0" key={session.id}>
			<div className="mr-4">
				{session.os === 'Windows' && <FontAwesomeIcon icon={['fab', 'windows']} size="2x" style={{ color: '#00A4EF' }} fixedWidth />}
				{(session.os === 'Mac OS' || session.os === 'OS X') && <FontAwesomeIcon icon={['fab', 'apple']} size="2x" style={{ color: '#A3AAAE' }} fixedWidth />}
				{session.os === 'iOS' && <FontAwesomeIcon icon={['fab', 'apple']} size="2x" style={{ color: '#A3AAAE' }} fixedWidth />}
				{session.os === 'Android' && <FontAwesomeIcon icon={['fab', 'android']} size="2x" style={{ color: '#3DDC84' }} fixedWidth />}
				{!['Windows', 'Mac OS', 'Android', 'iOS', 'OS X'].includes(session.os || '') && <FontAwesomeIcon icon="question-circle" size="2x" className="text-black" fixedWidth />}
			</div>
			<div className="flex-1">
				<h5 className="font-semibold">
					<FormattedMessage id="sessions.os_on_browser" values={{ os: session.os, browser: session.browser }} />
					{session.is_current_token && (
						<span className="ml-4 text-green-600">
							<FormattedMessage id="sessions.active" />
						</span>
					)}
				</h5>
				<p className="text-sm italic text-gray-600">
					<FormattedMessage id="sessions.last_used_at" values={{ date: <DateTimeDisplay value={session.last_used_at} /> }} />
				</p>
			</div>
			{!session.is_current_token && (
				<div className="ml-4">
					<Button variant={Variant.danger} intent={Intent.secondary} circle size={Size.sm} onClick={_delete}>
						<FontAwesomeIcon icon="trash" />
					</Button>
				</div>
			)}
		</div>
	);
};
