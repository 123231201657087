import { useIndexedDatabase } from '@hooks/use-indexed-db';
import { useMutation, useQueryClient } from 'react-query';

export const useFlushDraftsMutation = () => {
	const queryClient = useQueryClient();
	const db = useIndexedDatabase();

	return useMutation<boolean, Error, void>({
		mutationFn: async () => {
			(await db).clear('drafts');
			return true;
		},
		onSuccess: () => {
			queryClient.removeQueries(['drafts']);
		}
	});
};
