import { createContext } from 'react';

export const WizardContext = createContext<
	| {
			nextStep: () => void;
			previousStep: () => void;
			goToStep: (step: number) => void;
			step: number;
			stepCount: number;
			isFirstStep: boolean;
			isLastStep: boolean;
	  }
	| undefined
>(undefined);
