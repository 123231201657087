import { Variant } from '@convoflo/ui';
import type { FC, PropsWithChildren, ReactNode } from 'react';

type WidgetProps = PropsWithChildren<{
	header: ReactNode;
	subHeader?: ReactNode;
	actions?: ReactNode;
	className?: string;
	variant?: Variant;
	icon?: ReactNode;
}>;

const Widget: FC<WidgetProps> = ({ header, subHeader, actions, icon, className = '', variant = Variant.light, children }) => {
	switch (variant) {
		case Variant.danger:
			// className += ' bg-red-600';
			break;
		case Variant.light:
			// className += ' bg-green-200';
			break;
	}

	return (
		<div className={`rounded-md ${className}`}>
			<div className="flex items-start py-4">
				{icon}
				<div className="flex-1">
					<WidgetHeader variant={variant}>{header}</WidgetHeader>
					{subHeader !== undefined && <WidgetSubHeader variant={variant}>{subHeader}</WidgetSubHeader>}
				</div>
				{actions !== undefined && <div className="ml-4 space-x-2">{actions}</div>}
			</div>
			{children}
		</div>
	);
};

type WidgetHeaderProps = PropsWithChildren<{
	variant?: Variant;
}>;

export const WidgetHeader: FC<WidgetHeaderProps> = ({ variant = Variant.light, children }) => {
	return <h2 className={`${variant === Variant.danger ? 'text-white' : 'text-black'} font-semibold`}>{children}</h2>;
};

type WidgetSubHeaderProps = PropsWithChildren<{
	variant?: Variant;
}>;

export const WidgetSubHeader: FC<WidgetSubHeaderProps> = ({ variant = Variant.light, children }) => {
	return <p className={`${variant === Variant.danger ? 'text-white' : 'text-black'} text-sm text-gray-500`}>{children}</p>;
};

export default Widget;
