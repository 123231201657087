import { FileableSyncStatus } from '@components/FileManager';
import { Checkbox } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import File, { FileVersionSource } from '@models/File';
import Folder from '@models/Folder';
import type { Fileable } from '@types';
import { Tooltip } from '@ui/Tooltip';
import { filesize } from '@utils/StringUtils';
import { type CSSProperties, type FC, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type FileableItemItemProps = {
	item: Fileable;
	disabled?: boolean;
	selected?: boolean;
	onSelected?: (item: Fileable, selected: boolean) => void;
	onNavigated?: (folder: Folder) => void;
	style?: CSSProperties;
};

export const FileableItem: FC<FileableItemItemProps> = memo(({ item, disabled = false, selected = false, onSelected = () => undefined, onNavigated = () => undefined, style }) => {
	const { locale } = useIntl();

	return (
		<div className={`rounded flex text-sm ${selected ? 'bg-blue-100' : ''} transition-colors duration-100`} style={style}>
			<Checkbox disabled={disabled} checked={selected} onChange={e => onSelected(item, e.target.checked)} className={`mx-2 ${disabled ? 'invisible' : ''}`} />

			{item instanceof Folder && (
				<button className="flex items-center w-full p-2 text-left focus:outline-none disabled:text-gray-500" type="button" onClick={() => onNavigated(item)}>
					<div className="flex-1 min-w-0 truncate">
						{item.icon('mr-2')}
						{item.getName()}
						<span className="ml-1">
							{item.syncs?.map(sync => (
								<FileableSyncStatus key={sync.Id} fileable={item} sync={sync} />
							))}
						</span>
					</div>
					<div className="text-xs text-gray-400"></div>
				</button>
			)}

			{item instanceof File && (
				<button
					className="flex items-center w-full p-2 text-left truncate focus:outline-none disabled:text-gray-500"
					type="button"
					onClick={() => onSelected(item, !selected)}
					disabled={disabled}>
					<div className="flex-1 min-w-0 truncate">
						{item.icon('mr-2')}
						{item.getName()}
						<span className="ml-1">
							{item.syncs?.map(sync => (
								<FileableSyncStatus key={sync.Id} fileable={item} sync={sync} />
							))}
						</span>
					</div>
					{item.current.Source === FileVersionSource.OneSpanSign && (
						<div className="mr-2">
							<Tooltip tip={<FormattedMessage id="file-manager.document_signed" />}>
								<span>
									<FontAwesomeIcon size="lg" icon="signature" className="text-red-600" />
								</span>
							</Tooltip>
						</div>
					)}
					<div className="text-xs text-black text-opacity-50">{filesize(item.current.Size, locale)}</div>
				</button>
			)}
		</div>
	);
});
