import type { DataRow, ImportRow } from '@components/Importer';
import type { OrganizationUser } from '@components/Team';
import Folder from '@models/Folder';
import { type FolderTemplate, Permissions } from '@types';

export const formatSecureSpaceName = (format: string, row: ImportRow) => {
	let exampleText = format;

	const matches = format.matchAll(/<([A-Z_]+)>/g);

	for (const match of matches) {
		exampleText = exampleText.replace(`<${match[1]}>`, row[match[1].toLowerCase()]);
	}

	return exampleText;
};

export const createSecureSpace = (name: string, creator: OrganizationUser | undefined, template?: FolderTemplate, members: DataRow['members'] = []) => {
	return new Folder({
		Name: name,
		pivot: { Permissions: Permissions.ReadWrite },
		creator,
		SecuredSpace: true,
		folders_count: template?.children.length ?? 0,
		collaborators: [...members.map(member => ({ Name: member.first_name && member.first_name ? `${member.first_name} ${member.last_name}` : member.email })), ...(creator ? [creator] : [])]
	});
};
