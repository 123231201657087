import { UploadFile, UploadStatus, useUpload2, useUploadService } from '@components/Upload';
import File from '@models/File';
import { Button, Size, Variant } from '@convoflo/ui';
import type { Fileable } from '@types';
import { Tooltip } from '@ui/Tooltip';
import { convertFileToDataUrl } from '@utils/FileReaderUtils';
import classNames from 'classnames';
import { type FC, useEffect } from 'react';
import { FormattedNumber } from 'react-intl';
import { useQuery } from 'react-query';

type MessageFileAttachmentProps = {
	name: string;
	src?: string;
	percentage?: number;
	disabled?: boolean;
	file?: globalThis.File;
	className?: string;
	onRemove?: () => void;
	onClick?: () => void;
};

export const MessageFileAttachment: FC<MessageFileAttachmentProps> = ({ name, src, file, percentage, disabled = false, onRemove, onClick, className }) => {
	const { data: generatedSrc } = useQuery(['file_thumbnail', file?.name], async () => await convertFileToDataUrl(file!), {
		enabled: file instanceof global.File && file.size <= 1024 * 1024 && ['jpg', 'png'].includes(file.name.split('.').pop() ?? ''),
		staleTime: Infinity
	});

	src = typeof generatedSrc === 'string' ? generatedSrc : src;

	return (
		<Tooltip tip={name}>
			<div
				className={classNames(
					className,
					'px-2 sm:p-0 relative sm:w-24 sm:h-24 overflow-hidden group flex items-center sm:block bg-white sm:bg-checkered gap-2 border border-gray-200 sm:border-0 cursor-pointer'
				)}
				onClick={onClick}>
				{/* overlay, hidden on mobile */}
				<div className="order-3 flex transition-opacity sm:opacity-0 sm:pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto z-[4] sm:bg-white/75 sm:absolute inset-0 p-1">
					<div className="flex-1 hidden sm:block">
						<p className="text-xs font-medium break-all">{name}</p>
					</div>
					<div className="sm:absolute bottom-0 left-0 right-0 flex gap-1.5 p-2">
						{onRemove !== undefined && <Button circle size={Size.xs} variant={Variant.danger} icon="trash" onClick={onRemove} />}
					</div>
				</div>

				{/* name */}
				<header className="sm:absolute relative top-0 left-0 right-0 sm:bg-white/50 sm:backdrop-blur z-[2] sm:z-[3] p-1 order-2 flex-1 flex min-w-0">
					<span className="flex-1 min-w-0 text-sm font-medium truncate sm:text-xs">{name}</span>
				</header>

				{/* percentage */}
				{percentage !== undefined && (
					<div className="absolute inset-0 z-[3] sm:z-[2] bg-black/50">
						<div className="absolute inset-0 flex items-center justify-center sm:mt-3">
							<span className="text-2xl font-bold text-white">
								<FormattedNumber value={percentage / 100} style="percent" />
							</span>
						</div>
					</div>
				)}

				{/* thumbnail */}
				<div className="sm:absolute inset-0 z-[1] flex items-center justify-center order-1 w-8 sm:w-auto sm:bg-gray-300 sm:bg-transparent">
					{src ? (
						<div className="bg-checkered">
							<img src={src} alt="" className="object-cover object-center w-full h-full" />
						</div>
					) : (
						File.fromName(name).icon('sm:mt-3 text-3xl sm:text-4xl', true, { fixedWidth: false })
					)}
				</div>
			</div>
		</Tooltip>
	);
};

type PendingMessageFileAttachmentProps = {
	fileable: Fileable;
	file: UploadFile;
	disabled?: boolean;
	start?: boolean;
	onRemove?: () => void;
	onFileUpdated?: (file: UploadFile) => void;
};

export const PendingMessageFileAttachment: FC<PendingMessageFileAttachmentProps> = ({ fileable, file, onRemove = () => undefined, onFileUpdated, start = false }) => {
	const service = useUploadService(fileable, { start });
	const { percentage, status, bytesUploaded, file: model, abort, upload } = useUpload2(service, file);

	const onAbort = () => {
		if (status === UploadStatus.Pending) {
			onRemove();
		}

		if (status === UploadStatus.Uploading) {
			abort();
		}
	};

	useEffect(() => {
		file.status = status;
		file.model = model;
		file.bytesUploaded = bytesUploaded;
		onFileUpdated && onFileUpdated(file);
	}, [status, file, bytesUploaded, model, onFileUpdated]);

	useEffect(() => {
		if (start && service) {
			upload();
		}
	}, [service, start, upload]);

	const disabled = [UploadStatus.Processing, UploadStatus.Aborted, UploadStatus.Completed].includes(status);

	return (
		<MessageFileAttachment
			className={start ? 'opacity-100' : 'opacity-50'}
			percentage={start ? percentage : undefined}
			name={file.file.name}
			disabled={disabled}
			file={file.file}
			onRemove={onAbort}
		/>
	);
};
