import FlagCA from '@assets/flags/CA.svg?react';
import FlagQC from '@assets/flags/QC.svg?react';
import FlagUS from '@assets/flags/US.svg?react';
import AppContext from '@contexts/AppContext';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import { useAccount } from '@hooks/useAccount';
import { useAccountProfileUpdateMutation } from '@state/queries/profile';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { getSupportedLocale } from '../AppProviders';

const LanguageSwitcher = () => {
	const { account } = useAccount();
	const { setLocale } = useContext(AppContext);
	const { locale } = useIntl();

	const { mutateAsync: updateProfile } = useAccountProfileUpdateMutation();

	const onLanguageChange = (locale: 'en_US' | 'fr_CA') => {
		setLocale(getSupportedLocale(locale));

		if (account !== null) {
			updateProfile({ locale });
		}
	};

	if (locale.startsWith('fr')) {
		return (
			<Button type="button" variant={Variant.light} intent={Intent.primary} size={Size.sm} shadow onClick={() => onLanguageChange('en_US')}>
				<div className="inline-flex items-center gap-2">
					<FlagUS className="h-3" />
					<FlagCA className="h-3" />
					<span className="font-semibold">Go to English</span>
				</div>
			</Button>
		);
	}

	return (
		<Button type="button" variant={Variant.light} intent={Intent.primary} size={Size.sm} shadow onClick={() => onLanguageChange('fr_CA')}>
			<div className="inline-flex items-center gap-2">
				<FlagQC className="inline-block h-3" />
				<FlagCA className="h-3" />
				<span className="font-semibold">Allez en français</span>
			</div>
		</Button>
	);
};

export default LanguageSwitcher;
