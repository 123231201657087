import { format } from 'date-fns';
import { type FC } from 'react';
import { Input, type InputProps } from '.';

export type InputDateTimeProps = Omit<InputProps, 'onChange' | 'value' | 'type'> & {
	value?: InputProps['value'] | Date | null;
	onChange?: (value: Date | null) => void;
};

export const InputDate: FC<InputDateTimeProps> = ({ onChange = () => undefined, value, ...inputProps }) => {
	return (
		<Input
			value={value instanceof Date ? format(value, 'yyyy-MM-dd') : value ?? ''}
			type="date"
			onChange={e => onChange(e.target.valueAsDate ? convertDateToUTC(e.target.valueAsDate) : null)}
			{...inputProps}
		/>
	);
};

export const InputDateTime: FC<InputDateTimeProps> = ({ onChange = () => undefined, value, ...inputProps }) => {
	return (
		<Input
			value={value instanceof Date ? format(value, "yyyy-MM-dd'T'hh:mm") : value ?? ''}
			type="datetime-local"
			onChange={e => onChange(e.target.valueAsDate ? convertDateToUTC(e.target.valueAsDate) : e.target.valueAsNumber ? convertDateToUTC(new Date(e.target.valueAsNumber)) : null)}
			{...inputProps}
		/>
	);
};

function convertDateToUTC(date: Date) {
	return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
}
