import { createContext } from 'react';
import type { Comment } from '@components/Message';

export const MessageContext = createContext<
	| {
			message: Comment;
			navigate: (newTab?: boolean) => void;
			update: (message: Comment) => void;
	  }
	| undefined
>(undefined);
