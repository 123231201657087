import ApiClientContext from '@contexts/ApiClient';
import { useContext } from 'react';

export const useClient = () => {
	const context = useContext(ApiClientContext);

	if (context === undefined) {
		throw new Error('useClient() must be used within a ApiClientContext');
	}

	return context;
};

export default useClient;
