import Icon from '@assets/images/Icon.svg?react';
import { Logo } from '@components/Logo';
import { NotificationDropdown } from '@components/Notification';
import AppContext from '@contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import { useSearchParams } from '@hooks/useSearchParams';
import Account from '@models/Account';
import { Badge, Button, Intent, Variant } from '@convoflo/ui';
import Dropdown, { DropdownDivider, DropdownGroup, DropdownItem } from '@ui/Dropdown';
import UserAvatar from '@ui/UserAvatar';
import { type FC, useContext } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';

type TopbarProps = {
	account: Account;
	theme?: 'light' | 'dark' | null;
	layout?: 'full' | 'minimal' | 'nothing' | 'print' | null;
};

export const Topbar: FC<TopbarProps> = ({ account, theme = 'light', layout = 'full' }) => {
	const history = useHistory();
	const { logout } = useAccount();
	const { setShowGlobalSearch, setShowDrawer, pageHeader } = useContext(AppContext);
	const [{ q }, setSearchParams] = useSearchParams();

	if (layout === 'minimal') {
		return <MinimalMenu account={account} theme={theme} />;
	}

	return (
		<>
			{/* Top bar, only visible for >= md */}
			<div className="items-center justify-between hidden h-auto px-4 py-3 space-x-4 bg-white md:flex">
				<div className="flex-1">{pageHeader}</div>
				<div className="flex items-center space-x-4">
					{!q ? (
						<button onClick={() => setShowGlobalSearch(true)} className="inline-flex items-center gap-3 px-3 py-2 pr-24 text-sm text-gray-500 bg-gray-200 rounded-full">
							<FontAwesomeIcon icon="search" />
							<span>
								<FormattedMessage id="search" />
							</span>
						</button>
					) : (
						<Button circle icon="times" variant={Variant.light} intent={Intent.tertiary} onClick={() => setSearchParams({ q: undefined })} />
					)}
					<Dropdown placement="bottom-end" className="min-w-[512px] w-full !max-w-md">
						<div className="relative">
							{account.getTotalAlerts() > 0 && (
								<>
									<Badge className="absolute top-0 z-10 border-2 border-white pointer-events-none animate-ping-slow -right-2" inverse variant={Variant.danger}>
										<FormattedNumber value={account.getTotalAlerts()} />
									</Badge>
									<Badge className="absolute top-0 z-10 border-2 border-white pointer-events-none -right-2" inverse variant={Variant.danger}>
										<FormattedNumber value={account.getTotalAlerts()} />
									</Badge>
								</>
							)}
							<Button circle icon="bell" variant={Variant.light} intent={Intent.tertiary} id="step-alerts-bell" />
						</div>
						<NotificationDropdown />
					</Dropdown>
					<Dropdown placement="bottom-end">
						<Button circle className="p-1" intent={Intent.tertiary}>
							<UserAvatar size={null} className="w-8 h-8" user={account} />
						</Button>
						<DropdownGroup>
							<DropdownItem onClick={() => history.push('/account')} icon="user">
								<FormattedMessage id="account.account" />
							</DropdownItem>
							{account.isAdmin() && account.hasFullAccess() && (
								<DropdownItem onClick={() => history.push('/organization/settings')} icon="building">
									<FormattedMessage id="account.organization" />
								</DropdownItem>
							)}
							{/* {account.isAdmin() && account.hasFullAccess() && (
								<DropdownItem onClick={() => history.push('/referral')} icon="star">
									<FormattedMessage id="account.referral" />
								</DropdownItem>
							)} */}
							<DropdownDivider />
							<DropdownItem icon="sign-out" onClick={() => logout()}>
								<FormattedMessage id="logout" />
							</DropdownItem>
						</DropdownGroup>
					</Dropdown>
				</div>
			</div>

			{/* Mobile top bar */}
			<header className="relative flex items-center justify-between w-full h-16 px-4 py-2 text-white shadow bg-theme-inverse md:hidden">
				<div className="flex items-center space-x-4">
					<Button circle onClick={() => setShowDrawer(true)}>
						<FontAwesomeIcon icon="bars" />
					</Button>
					<Link to="/files">{account.business.LogoDark !== null ? <Logo dark={true} className="block h-8 mx-auto" /> : <Icon className="h-8 text-white" />}</Link>
				</div>
				<div className="flex items-center space-x-4">
					<Button circle icon="search" variant={Variant.info} intent={Intent.tertiary} onClick={() => setShowGlobalSearch(true)} />
					<Dropdown placement="bottom-end" className="sm:w-auto sm:min-w-[512px] max-h-full w-screen">
						<div className="relative">
							{account.getTotalAlerts() > 0 && (
								<>
									<Badge className="absolute top-0 border-2 border-white pointer-events-none animate-pingslow -right-2" inverse variant={Variant.danger}>
										<FormattedNumber value={account.getTotalAlerts()} />
									</Badge>
									<Badge className="absolute top-0 border-2 border-white pointer-events-none -right-2 z-[1]" inverse variant={Variant.danger}>
										<FormattedNumber value={account.getTotalAlerts()} />
									</Badge>
								</>
							)}
							<Button circle>
								<FontAwesomeIcon icon="bell" />
							</Button>
						</div>
						<NotificationDropdown />
					</Dropdown>
					<Dropdown placement="bottom-end">
						<Button circle className="p-1">
							<UserAvatar size={null} className="w-8 h-8" user={account} />
						</Button>
						<DropdownGroup>
							<DropdownItem onClick={() => history.push('/account')} icon="user">
								<FormattedMessage id="account.account" />
							</DropdownItem>
							<DropdownDivider />
							<DropdownItem icon="sign-out" onClick={() => logout()}>
								<FormattedMessage id="logout" />
							</DropdownItem>
						</DropdownGroup>
					</Dropdown>
				</div>
			</header>
		</>
	);
};

type MinimalMenuProps = {
	account: Account;
	theme?: 'light' | 'dark' | null;
};

const MinimalMenu: FC<MinimalMenuProps> = ({ account, theme = 'light' }) => {
	const { pageTitle } = useContext(AppContext);
	const { logout } = useAccount();

	const history = useHistory();

	return (
		<aside className="col-span-2 row-span-1">
			<header className={`${theme === 'light' ? '' : 'text-white bg-gray-800'} h-16 py-2 px-4 flex justify-between items-center w-full space-x-4`}>
				<div className="flex items-center space-x-4">
					<Button circle onClick={() => history.goBack()}>
						<FontAwesomeIcon icon="arrow-left" size="lg" />
					</Button>
					<Link to="/files">
						{account.business.LogoDark !== null ? (
							<img alt={account.business.Name || undefined} src={account.business.LogoDark} className="block h-8 mx-auto" />
						) : (
							<Icon className="h-8 text-white" />
						)}
					</Link>
				</div>
				{pageTitle !== null && <h1 className="text-sm font-semibold text-center truncate sm:text-base md:text-2xl">{pageTitle}</h1>}
				<div className="flex items-end h-auto py-3 space-x-4">
					<Dropdown placement="bottom-end" className="min-w-[512px] w-full !max-w-md max-h-full">
						<div className="relative">
							{account.getTotalAlerts() > 0 && (
								<>
									<Badge className="absolute top-0 border-2 border-white pointer-events-none animate-pingslow -right-2" inverse variant={Variant.danger}>
										<FormattedNumber value={account.getTotalAlerts()} />
									</Badge>
									<Badge className="absolute top-0 border-2 border-white pointer-events-none -right-2" inverse variant={Variant.danger}>
										<FormattedNumber value={account.getTotalAlerts()} />
									</Badge>
								</>
							)}
							<Button circle>
								<FontAwesomeIcon icon="bell" />
							</Button>
						</div>
						<NotificationDropdown />
					</Dropdown>
					<Dropdown placement="bottom-end">
						<Button circle className="p-1">
							<UserAvatar size={null} className="w-8 h-8" user={account} />
						</Button>
						<DropdownGroup>
							<DropdownItem onClick={() => history.push('/account')} icon="user">
								<FormattedMessage id="account.account" />
							</DropdownItem>
							{account.isAdmin() && account.hasFullAccess() && (
								<DropdownItem onClick={() => history.push('/organization/settings')} icon="building">
									<FormattedMessage id="account.organization" />
								</DropdownItem>
							)}
							<DropdownDivider />
							<DropdownItem icon="sign-out" onClick={() => logout()}>
								<FormattedMessage id="logout" />
							</DropdownItem>
						</DropdownGroup>
					</Dropdown>
				</div>
			</header>
		</aside>
	);
};
