import { createResourceLengthAwarePagination } from '@components/Pagination';
import type { SignRequestsResponse } from '@components/SignRequest';
import useClient from '@hooks/useClient';
import type File from '@models/File';
import SignRequest from '@models/SignRequest';
import type { SignRequestRole } from '@types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { WretchError } from 'wretch';

type CreateSignRequestTemplateMutation = {
	label: string;
	roles: Partial<SignRequestRole>[];
	files: File[];
};

type UpdateSignRequestTemplateMutation = {
	signRequest: SignRequest;
	label: string;
	roles: Partial<SignRequestRole>[];
	files: File[];
};

export const useSignRequestTemplateQuery = (enabled: boolean) => {
	const { client } = useClient();

	return useQuery({
		queryKey: ['sign-requests', 'templates'],
		queryFn: async () => {
			let payload = await client.url('account/sign_requests').query('filter=templates&limit=9999').get().json<SignRequestsResponse>();
			const paginator = createResourceLengthAwarePagination(payload);
			paginator.data = paginator.data.map(signRequest => new SignRequest(signRequest));

			return paginator;
		},
		staleTime: Infinity,
		refetchOnMount: 'always',
		enabled
	});
};

export const useSignRequestTemplateCreateMutation = () => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	return useMutation<SignRequest, WretchError, CreateSignRequestTemplateMutation>({
		mutationFn: async ({ label, roles, files }) =>
			new SignRequest(
				await client
					.url('account/sign_templates')
					.post({
						label,
						roles: roles.filter(role => !!role.Label).map(role => ({ label: role.Label! })),
						documents: files.map(file => file.OriginalLink)
					})
					.json<SignRequest>()
			),
		onSuccess: () => {
			queryClient.invalidateQueries(['sign-requests']);
		}
	});
};

export const useSignRequestTemplateUpdateMutation = () => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	return useMutation<SignRequest, WretchError, UpdateSignRequestTemplateMutation>({
		mutationFn: async ({ signRequest, label, roles, files }) =>
			new SignRequest(
				await client
					.url(`account/sign_templates/${signRequest.Id}`)
					.json({
						label,
						roles: roles.filter(role => role.Label).map(role => ({ id: role.Id, label: role.Label })),
						documents: files.map(file => file.OriginalLink)
					})
					.put()
					.json<SignRequest>()
			),
		onSuccess: () => {
			queryClient.invalidateQueries(['sign-requests']);
		}
	});
};
