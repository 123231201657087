import { useCurrentEditor } from '@tiptap/react';
import { ToolbarButton, type ToolbarButtonProps } from '@ui/RichTextEditor/Toolbar';

export const TaskListToolbarButton = ({ ...props }: ToolbarButtonProps) => {
	const { editor } = useCurrentEditor();

	if (editor === null) {
		return null;
	}

	return <ToolbarButton type={['taskList']} handler={() => editor.chain().focus().toggleTaskList().run()} {...props} />;
};
