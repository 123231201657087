import type { CannedMessage } from '@components/CannedMessages';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { versionMiddleware } from '@service/Client';
import { useQuery } from 'react-query';

const STALE_TIME = 1000 * 60 * 5;

export const useCannedMessages = (enable: boolean = true) => {
	const { client } = useClient();
	const { account } = useAccount();

	const { data: cannedMessages } = useQuery(
		['canned-messages'],
		async () =>
			await client
				.url('account/canned-messages')
				.middlewares([versionMiddleware(2)])
				.get()
				.json<CannedMessage[]>(),
		{
			enabled: account!.hasFullAccess() && enable,
			staleTime: STALE_TIME
		}
	);

	if (account!.hasLimitedAccess()) {
		return null;
	}

	return cannedMessages;
};
