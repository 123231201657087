import untypedColors from '@assets/color-palette.json';
import { FileManagerContext } from '@components/FileManager';
import { type HasLabels, type LabelType, useLabels, useLabelsMutations } from '@components/Labels';
import { getLabelText } from '@components/Organization/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ColorType } from '@types';
import classNames from 'classnames';
import { type FC, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Select, {
	type ActionMeta,
	type SingleValue as BaseSingleValue,
	type ClearIndicatorProps,
	type ContainerProps,
	type ControlProps,
	type DropdownIndicatorProps,
	type GroupHeadingProps,
	type GroupProps,
	type IndicatorSeparatorProps,
	type IndicatorsContainerProps,
	type MenuListProps,
	type MenuProps,
	type NoticeProps,
	type OptionProps,
	type PlaceholderProps,
	type SingleValueProps,
	type ValueContainerProps,
	components
} from 'react-select';

const colors: ColorType[] = untypedColors;

type LabelSelectorProps = {
	model?: HasLabels;
	label?: LabelType;
	disabled?: boolean;
	editable?: boolean;
	onChange?: (label: LabelType, oldProperty?: LabelType) => void;
	onRemove?: (label: LabelType) => void;
	isDisabled?: (label: LabelType) => boolean;
};

export const LabelSelector: FC<LabelSelectorProps> = ({ model, label, disabled = false, isDisabled = () => false, editable = true, onChange = () => undefined, onRemove = () => undefined }) => {
	const fileManagerContext = useContext(FileManagerContext);
	const { attach, detach, isAttaching, isDetaching } = useLabelsMutations({ selection: fileManagerContext?.selection, model });

	const [loadOptions, setLoadOptions] = useState(false);

	const { props, isLoading } = useLabels(label, {
		enabled: loadOptions && !disabled
	});

	const onSelectionChanged = (value: BaseSingleValue<LabelType>, { action }: ActionMeta<LabelType>) => {
		if (action === 'clear' && label) {
			if (model) {
				detach(label);
			}
			onRemove(label);
			return;
		}

		if (value === null) {
			return;
		}

		if (model) {
			attach(props?.find(prop => prop.Id === value.Id) ?? value);
		}

		onChange(value, label);
	};

	return (
		<Select
			isLoading={isLoading}
			isDisabled={isAttaching || isDetaching || disabled}
			menuPosition="fixed"
			onMenuOpen={() => setLoadOptions(true)}
			isClearable={!!label}
			value={label ?? null}
			isSearchable={false}
			menuPortalTarget={document.body}
			styles={{
				option: ({ backgroundColor, ...provided }) => provided,
				menuPortal: provided => ({ ...provided, zIndex: 2000 }),
				input: provided => ({ ...provided, 'input:focus': { '--tw-ring-offset-width': '-1px' } }),
				valueContainer: provided => ({ ...provided, input: { height: 1 }, 'input[readonly]': { height: 1 } })
			}}
			components={{
				LoadingIndicator: undefined,
				Control,
				Placeholder,
				SingleValue,
				SelectContainer,
				ValueContainer,
				Menu,
				MenuList,
				Group,
				GroupHeading,
				Option,
				ClearIndicator,
				NoOptionsMessage,
				IndicatorSeparator: label && !disabled ? IndicatorSeparator : null,
				DropdownIndicator: label && !disabled && editable ? DropdownIndicator : null,
				...(label && !disabled && { IndicatorsContainer })
			}}
			isOptionDisabled={label => isDisabled(label) || (model?.properties ?? []).some(prop => prop.Id === label.Id)}
			placeholder={<FormattedMessage id="properties.add_property" />}
			getOptionValue={({ Id }) => Id}
			options={props}
			onChange={onSelectionChanged}
			openMenuOnClick={editable}
			openMenuOnFocus={editable}
		/>
	);
};

const Control = ({ isFocused, ...props }: ControlProps<LabelType, false>) => {
	const value = props.getValue()[0];
	const color = colors.find(color => color.value === value?.Color) ?? colors[0];

	const className = classNames('!min-h-0 !rounded-full !py-0', color.backgroundClassName, color.borderClassName, {
		'!ring-0': isFocused,
		'!border-dashed': !value
	});

	return <components.Control isFocused={isFocused} {...props} className={className} />;
};

const Placeholder = ({ children, ...props }: PlaceholderProps<LabelType, false>) => (
	<components.Placeholder {...props}>
		<p className="flex items-center space-x-1 text-xs leading-0">
			<FontAwesomeIcon icon="plus" size="xs" />
			<span>{children}</span>
		</p>
	</components.Placeholder>
);

const ValueContainer = ({ ...props }: ValueContainerProps<LabelType, false>) => <components.ValueContainer {...props} className="cursor-pointer !px-1.5 bg-transparent !p-0" />;

const SingleValue = ({ ...props }: SingleValueProps<LabelType, false>) => {
	const { locale } = useIntl();
	const color = colors.find(color => color.value === props.data.Color) ?? colors[0];

	return (
		<components.SingleValue {...props}>
			<p className={classNames('flex items-center space-x-1 text-xs leading-0', color.textClassName)}>
				<span style={{ fontSize: 'smaller' }}>{props.data.Emoji}</span>
				{!props.data.Emoji && <span className="lg:hidden">{getLabelText(props.data, locale)[0]}</span>}
				<span className="hidden lg:inline">{getLabelText(props.data, locale)}</span>
			</p>
		</components.SingleValue>
	);
};

const SelectContainer = ({ ...props }: ContainerProps<LabelType, false>) => <components.SelectContainer {...props} className="bg-transparent" />;

const Menu = ({ ...props }: MenuProps<LabelType, false>) => <components.Menu {...props} className="!border !border-gray-300 !rounded-md !shadow-lg min-w-2xs w-max" />;

const MenuList = ({ ...props }: MenuListProps<LabelType, false>) => <components.MenuList {...props} className="!py-0" />;

const Option = ({ data, isDisabled, isSelected, ...props }: OptionProps<LabelType, false>) => {
	const { locale } = useIntl();
	const color = colors.find(color => color.value === data.Color) ?? colors[0];

	return (
		<div className={`${color.backgroundClassName} !hover:bg-transparent !rounded my-1 mx-1`}>
			<components.Option
				className={classNames('!bg-transparent !cursor-pointer !px-2 !py-1', {
					'opacity-50': isDisabled
				})}
				isDisabled={isDisabled}
				isSelected={isSelected}
				data={data}
				{...props}>
				<div className="flex items-center justify-between">
					<p className={classNames('text-sm flex items-center space-x-1', color.textClassName)}>
						<span style={{ fontSize: 'smaller' }}>{data.Emoji}</span>
						<span>{getLabelText(data, locale)}</span>
					</p>
					{isSelected && <FontAwesomeIcon icon="check" className={color.textClassName} />}
				</div>
			</components.Option>
		</div>
	);
};

const IndicatorsContainer = ({ ...props }: IndicatorsContainerProps<LabelType, false>) => {
	const value = props.getValue()[0];
	const color = colors.find(color => color.value === value?.Color) ?? colors[0];
	return <components.IndicatorsContainer {...props} className={classNames('!p-0 border-l !my-0 flex items-stretch', color.borderClassName)} />;
};

const ClearIndicator = ({ innerProps, ...props }: ClearIndicatorProps<LabelType, false>) => {
	const value = props.getValue()[0];
	const color = colors.find(color => color.value === value?.Color) ?? colors[0];

	const stopPropagation = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
		e.stopPropagation();
	};

	return (
		<components.ClearIndicator
			innerProps={{ ...innerProps, onClick: stopPropagation, onTouchEnd: stopPropagation }}
			{...props}
			className="cursor-pointer text-xs !p-0 !px-1 h-full flex items-center">
			<FontAwesomeIcon icon="times" size="xs" className={color.textClassName} fixedWidth />
		</components.ClearIndicator>
	);
};

const IndicatorSeparator = ({ ...props }: IndicatorSeparatorProps<LabelType, false>) => {
	const value = props.getValue()[0];
	const color = colors.find(color => color.value === value?.Color) ?? colors[0];
	return <components.IndicatorSeparator {...props} className={classNames('w-0 border-l !my-0', color.borderClassName)} />;
};

const DropdownIndicator = ({ innerProps, ...props }: DropdownIndicatorProps<LabelType, false>) => {
	const value = props.getValue()[0];
	const color = colors.find(color => color.value === value?.Color) ?? colors[0];

	const stopPropagation = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
		e.stopPropagation();
	};

	return (
		<components.DropdownIndicator
			innerProps={{ ...innerProps, onClick: stopPropagation, onTouchEnd: stopPropagation }}
			{...props}
			className="cursor-pointer !pl-1 !pr-1.5 !py-0 flex items-center h-full text-xs">
			<FontAwesomeIcon icon="caret-down" size="xs" className={color.textClassName} fixedWidth />
		</components.DropdownIndicator>
	);
};

const Group = ({ ...props }: GroupProps<LabelType, false>) => {
	return <components.Group className="!py-0" {...props} />;
};

const GroupHeading = ({ ...props }: GroupHeadingProps<LabelType, false>) => {
	const { locale } = useIntl();
	return (
		<components.GroupHeading {...props}>
			<h6 className="pt-3 text-gray-400">{getLabelText(props.data as LabelType, locale)}</h6>
		</components.GroupHeading>
	);
};

const NoOptionsMessage = ({ ...props }: NoticeProps<LabelType, false>) => {
	return (
		<div {...props} className="p-3">
			<div className="mb-2">
				<span className="px-2 py-0.5 text-xs text-white bg-red-600 rounded-full">
					<FormattedMessage id="new" />
				</span>
			</div>
			<h4 className="mb-2 font-medium text-gray-700">
				<FormattedMessage id="properties.add_label" />
			</h4>
			<p className="text-sm text-gray-600">
				<FormattedMessage id="properties.presets_intro_1" />{' '}
				<Link to="/organization/properties" className="cursor-pointer text-theme-primary hover:underline">
					<FormattedMessage id="get_started" />
				</Link>
			</p>
		</div>
	);
};
