import classNames from 'classnames';
import type { FC } from 'react';
import ReactMarkdown, { type Options } from 'react-markdown';
import remarkGfm from 'remark-gfm';

type MarkdownContentProps = Options & {
	size?: string;
	className?: string;
};

export const MarkdownContent: FC<MarkdownContentProps> = ({ size, className = '', ...props }) => {
	return (
		<ReactMarkdown
			{...props}
			remarkPlugins={[remarkGfm]}
			className={classNames('prose max-w-none break-words', className, {
				'prose-sm': size === 'sm',
				'prose-xs': size === 'xs',
				'prose-sm sm:prose-base': size === undefined
			})}
			linkTarget="_blank"
			components={{
				a: ({ node, children, href = '', ...props }) => {
					if (href.startsWith('http')) {
						props.rel = 'nofollow noreferrer noopener';
					}
					return (
						<a href={href} {...props}>
							{children}
						</a>
					);
				}
			}}
		/>
	);
};
