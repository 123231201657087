import { type LaravelLengthAwareResourcePaginatorType, createResourceLengthAwarePagination } from '@components/Pagination';
import { LengthAwareResourcePaginator } from '@components/Pagination/LengthAwareResourcePaginator';
import { FilterLink } from '@components/PaymentRequest';
import { SignRequestItem, SignRequestsPromo } from '@components/SignRequest';
import { SignRequestForm } from '@components/SignRequest/SignRequestForm';
import { Button, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, ModalSize, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { useSearchParams } from '@hooks/useSearchParams';
import Folder from '@models/Folder';
import SignRequest from '@models/SignRequest';
import { Module, Permissions, type Quota } from '@types';
import { StatCard } from '@ui/Card';
import EmptyState from '@ui/EmptyState';
import { formatDistanceStrict } from '@utils/DateUtils';
import { type FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery, useQueryClient } from 'react-query';

type SignRequestsReportGetApiResponse = {
	numbers: {
		total_pending: number;
		total_completed: number;
		avg_completed_time: number;
		avg_signing_time: number;
		layouts: number;
	};
	quota: Quota;
};

export type SignRequestsResponse = LaravelLengthAwareResourcePaginatorType<SignRequest> & {
	totals?: Record<string, number>;
};

type SignRequestDashboardProps = {
	view?: Folder | 'organization' | 'user';
	showTabs?: boolean;
};

export const SignRequestDashboard: FC<SignRequestDashboardProps> = ({ view = 'user', showTabs = true }) => {
	const [{ filter = '' }] = useSearchParams();
	const { client } = useClient();
	const { account } = useAccount();
	const { locale } = useIntl();
	const queryClient = useQueryClient();

	const [page, setPage] = useState(1);
	const [showCreateSignRequest, setShowCreateSignRequest] = useState(false);
	const [totals, setTotals] = useState<Record<string, number>>();

	const { data: report, isLoading: isReportLoading } = useQuery(['sign-requests', view], async () => await client.url('reports/sign_requests').get().json<SignRequestsReportGetApiResponse>(), {
		staleTime: 30000,
		onError: console.error,
		enabled: account!.business.hasModule(Module.Esign) && account!.isAdmin() && (view === 'user' || view === 'organization')
	});

	const { data: signRequests, isLoading } = useQuery(
		['sign-requests', view, filter, page],
		async () => {
			let url = 'account/sign_requests';

			if (view instanceof Folder) {
				url = `folders/${view.getKey()}/sign_requests`;
			} else if (view === 'organization') {
				url = 'organization/sign-requests';
			}

			const payload = await client
				.url(url)
				.query({ filter, page, 'include[totals]': showTabs ? 1 : 0 })
				.get()
				.json<SignRequestsResponse>();

			if (payload.totals) {
				setTotals(payload.totals);
			}

			const paginator = createResourceLengthAwarePagination<SignRequest>(payload);

			paginator.data = paginator.data.map(signRequest => new SignRequest(signRequest));

			return paginator;
		},
		{
			enabled: account!.business.hasModule(Module.Esign)
		}
	);

	const onSignRequestCreated = () => {
		queryClient.invalidateQueries(['sign-requests']);
	};

	const canCreateSignRequest = view instanceof Folder && view.pivot?.Permissions === Permissions.ReadWrite;

	if (!account!.business.hasModule(Module.Esign)) {
		return <SignRequestsPromo />;
	}

	return (
		<>
			{(view === 'user' || view === 'organization') && account!.isAdmin() && (
				<>
					<div className="flex items-center mb-4 space-x-4">
						<h3 className="text-base font-medium text-gray-400 md:text-xs">
							<FormattedMessage id="sign-requests-crud.esign_stats" />
						</h3>
						<div className="flex-1 h-px bg-gray-300" />
					</div>
					<div className="grid gap-4 mb-16 sm:grid-cols-2 xl:grid-cols-4">
						<StatCard
							isLoading={isReportLoading}
							title={<FormattedMessage id="dashboard-esign.requests_pending" values={{ n: report?.numbers.total_pending ?? 0 }} />}
							icon="paper-plane"
							stat={report?.numbers.total_pending ?? 0}
						/>
						<StatCard
							isLoading={isReportLoading}
							title={<FormattedMessage id="dashboard-esign.requests_completed" values={{ n: report?.numbers.total_completed ?? 0 }} />}
							icon="check"
							stat={report?.numbers.total_completed ?? 0}
						/>
						<StatCard
							isLoading={isReportLoading}
							title={<FormattedMessage id="dashboard-esign.avg_time_per_signature" />}
							icon="signature"
							stat={report?.numbers.avg_completed_time ? formatDistanceStrict(report.numbers.avg_completed_time, locale) : '–'}
						/>
						<StatCard
							isLoading={isReportLoading}
							title={<FormattedMessage id="dashboard-esign.avg_time_per_request" />}
							icon="user-clock"
							stat={report?.numbers.avg_signing_time ? formatDistanceStrict(report.numbers.avg_signing_time, locale) : '–'}
						/>
					</div>
				</>
			)}

			<div className="flex items-center mb-4 space-x-4">
				<h3 className="text-base font-medium text-gray-400 md:text-xs">
					<FormattedMessage id="dashboard-esign.title" />
				</h3>
				<div className="flex-1 h-px bg-gray-300" />
				{(canCreateSignRequest || !(view instanceof Folder)) && (
					<Button shadow onClick={() => setShowCreateSignRequest(true)} variant={Variant.primary} intent={Intent.primary} iconStart="paper-plane">
						<FormattedMessage id="sign-requests-crud.create-sign-request" />
					</Button>
				)}
			</div>

			{showTabs && (
				<div className="px-6 my-8 space-x-8 bg-white rounded">
					<FilterLink count={totals?.all ?? 0}>
						<FormattedMessage id="sign-requests-crud.all" />
					</FilterLink>
					<FilterLink count={totals?.sent ?? 0} value="sent">
						<FormattedMessage id="sign-requests-crud.sent" />
					</FilterLink>
					<FilterLink count={totals?.completed ?? 0} value="completed">
						<FormattedMessage id="sign-requests-crud.completed" />
					</FilterLink>
					<FilterLink count={totals?.archived ?? 0} value="archived">
						<FormattedMessage id="sign-requests-crud.archived" />
					</FilterLink>
				</div>
			)}

			<div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
				{signRequests !== undefined && signRequests.data.length === 0 && !isLoading && (
					<EmptyState className="lg:col-span-2" icon="signature" title={<FormattedMessage id="sign-requests-crud.no_results" />} />
				)}

				{signRequests?.data.map(SignRequest => (
					<SignRequestItem key={SignRequest.Id} signRequest={SignRequest} />
				))}

				{isLoading && (
					<div className="flex items-center justify-center h-48">
						<FontAwesomeIcon icon="spinner-third" spin size="3x" className="text-gray-500" />
					</div>
				)}
			</div>

			{signRequests !== undefined && (
				<div className="mt-8">
					<LengthAwareResourcePaginator {...signRequests} onPage={setPage} />
				</div>
			)}

			{showCreateSignRequest && view instanceof Folder && canCreateSignRequest && (
				<SignRequestForm folder={view} onSave={onSignRequestCreated} onAfterClose={() => setShowCreateSignRequest(false)} />
			)}

			<Modal isOpen={showCreateSignRequest && !(view instanceof Folder)} size={ModalSize.Small} closeable={true} onAfterClose={() => setShowCreateSignRequest(false)}>
				<ModalHeaderOnlyTitle>
					<FormattedMessage id="sign-requests-crud.create-type" values={{ type: 'request' }} />
				</ModalHeaderOnlyTitle>
				<ModalBody className="prose max-w-none">
					<p>
						<FormattedMessage id="sign-requests-crud.go_to_secure_space" />
					</p>
				</ModalBody>
				<ModalFooter>
					<Button variant={Variant.dark} intent={Intent.primary} type="button" onClick={() => setShowCreateSignRequest(false)}>
						<FormattedMessage id="ok" />
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};
