import SecureSystemImage from '@assets/images/shield-security-system.svg?react';
import { DialogCreateFolder, FileItem, FileListToolbar, FileManagerProvider, useFileManagerPolicies } from '@components/FileManager';
import { GuidedTour } from '@components/GuidedTour';
import { type LengthAwarePaginatorType, createLengthAwarePagination } from '@components/Pagination';
import { Uploader, type UploaderHandles } from '@components/Upload';
import { DraggedFileables } from '@contexts/DraggedFileablesContext';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useView } from '@hooks/use-view';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import useUrlSearch from '@hooks/useUrlSearch';
import File from '@models/File';
import Folder from '@models/Folder';
import type { FileManagerGetResponse } from '@service/types';
import type { Fileable, View } from '@types';
import Card from '@ui/Card';
import EmptyState from '@ui/EmptyState';
import { afterLast } from '@utils/StringUtils';
import { type FC, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage, useIntl } from 'react-intl';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

type FileManagerQueryParameters = {
	file?: string | null;
};

export type FileManagerSaveSettingsMutation = {
	folder: Folder | null;
	order?: string;
};

export const FILE_LIST_RESULT_LIMIT = 20;

export type FileManagerResults = {
	folders?: LengthAwarePaginatorType<Folder>;
	files?: LengthAwarePaginatorType<File>;
	order: string;
};

export type FileListProps = {
	view: View;
	onLoaded?: () => void;
};

export const FileList: FC<FileListProps> = ({ view = null, onLoaded }) => {
	// Utils
	const history = useHistory();
	const { account } = useAccount();
	const { file: highlightedFile = null }: FileManagerQueryParameters = useUrlSearch();
	const { client } = useClient();
	const { ref: loadMoreRef, inView: loadMoreInView } = useInView();
	const { locale } = useIntl();
	const { key, fullScreenDragNDropEnabled } = useView()!;
	const { canUploadFiles } = useFileManagerPolicies(account!, view);

	// States
	const [draggedFileables, setDraggedFileables] = useState<Fileable[]>([]);
	const [dialogCreateSecureSpaceOpened, setDialogCreateSecureSpaceOpened] = useState(false);

	// Refs
	const uploadManager = useRef<UploaderHandles>(null);

	// Query: files
	const {
		data,
		isLoading,
		isFetchingNextPage: isLoadingMore,
		hasNextPage: hasMoreResults,
		fetchNextPage,
		isSuccess,
		isFetched
	} = useInfiniteQuery<FileManagerResults>(
		['files', key],
		async ({ pageParam = 1 }) => {
			let url = 'files';
			let query: Record<string, string | number> = { page: pageParam, limit: FILE_LIST_RESULT_LIMIT };

			if (key !== null && key.startsWith('q:')) {
				query.q = afterLast(key, 'q:');
			} else if (key !== null && key.startsWith('folder:')) {
				url = `folders/${afterLast(key, 'folder:')}/files`;
			}

			let { documents: files, folders, ...payload } = await client.url(url).query(query).get().json<FileManagerGetResponse>();

			return {
				order: payload.order,
				files: files ? createLengthAwarePagination<File>({ ...files, data: files.data!.map(file => new File(file)) }) : undefined,
				folders: folders ? createLengthAwarePagination<Folder>({ ...folders, data: folders.data!.map(folder => new Folder(folder)) }) : undefined
			};
		},
		{
			refetchOnWindowFocus: false,
			getNextPageParam: (lastPaginator, pages) => {
				if (lastPaginator.files?.nextPageUrl) {
					return pages.length + 1;
				}

				if (lastPaginator.files === undefined) {
					return pages.length + 1;
				}

				return undefined;
			}
		}
	);

	// Query: favorites
	const { data: favorites } = useQuery(
		['files', 'favorites'],
		async () => {
			const payload = await client.url('favorites').get().json<Fileable[]>();
			return payload.map(fileable => {
				if (fileable['@type'] === 'Document') {
					fileable = new File(fileable);
				}
				if (fileable['@type'] === 'Folder') {
					fileable = new Folder(fileable);
				}
				return fileable;
			});
		},
		{
			refetchOnWindowFocus: false,
			enabled: view === null
		}
	);

	const onAction = (action: string) => {
		if (action === 'clear') {
			history.push('/files');
		} else if (action === 'upload') {
			uploadManager.current?.chooseFiles();
		} else if (action === 'create-secure-space') {
			setDialogCreateSecureSpaceOpened(true);
		}
	};

	// These are a hacky way of doing sorting by natural alpha
	const { files, folders } = (() => {
		const collator = new Intl.Collator(locale.substring(0, 2), {
			numeric: true,
			sensitivity: 'base'
		});

		let _files = data?.pages.reduce((accumulator, group) => accumulator.concat(group.files?.data || []), [] as File[]) || [];
		let _folders = data?.pages.reduce((accumulator, group) => accumulator.concat(group.folders?.data || []), [] as Folder[]) || [];

		if (data?.pages[0].order && data?.pages[0].order.slice(-4) === 'name') {
			_files.sort((a: File, b: File) => collator.compare(a.getName(), b.getName()));
			_folders.sort((a: Folder, b: Folder) => collator.compare(a.getName() || '', b.getName() || ''));

			if (data?.pages[0].order.substring(0, 1) === '!') {
				_files.reverse();
				_folders.reverse();
			}
		}

		return { files: _files, folders: _folders };
	})();

	const result = { files, folders, empty: files.length === 0 && folders.length === 0 };
	const fileables = (folders as Fileable[]).concat(files as Fileable[]);

	// Load the next page when is available and in view
	useEffect(() => {
		if (loadMoreInView && !isLoadingMore && hasMoreResults) {
			fetchNextPage();
		}
	}, [loadMoreInView, isLoadingMore, hasMoreResults, fetchNextPage]);

	useEffect(() => {
		if (isFetched) {
			onLoaded?.();
		}
	}, [isFetched]);

	return (
		<FileManagerProvider fileables={fileables} uploadManager={uploadManager.current ?? undefined}>
			<FontAwesomeIcon icon="ellipsis-v" className="text-gray-500" symbol="fa-ellipsis-v" />
			<Uploader item={typeof view !== 'string' ? view : null} pastable={fullScreenDragNDropEnabled} dragNDrop={fullScreenDragNDropEnabled} ref={uploadManager} disabled={!canUploadFiles} />

			<Card className="relative" size={null}>
				<FileListToolbar />

				<DraggedFileables.Provider value={{ draggedFileables, setDraggedFileables }}>
					{favorites !== undefined && favorites.length > 0 && view === null && (
						<>
							<header className="px-4 py-1 bg-gray-100 border-t border-b border-gray-200">
								<h6 className="text-xs text-gray-400 uppercase">
									<FormattedMessage tagName="small" id="file-manager.favorites" />
								</h6>
							</header>
							<div>
								{favorites.map(item => (
									<FileItem key={`fav-${item.getKey()}`} item={item} />
								))}
							</div>
						</>
					)}

					{!isLoading && !result.empty && (
						<>
							{favorites !== undefined && favorites.length > 0 && view === null && (
								<header className="px-4 py-1 bg-gray-100 border-t border-b border-gray-200">
									<h6 className="text-xs text-gray-400 uppercase">
										<FormattedMessage tagName="small" id="file-manager.files_folders" />
									</h6>
								</header>
							)}

							<div id="step-files">
								{result.folders.map(folder => (
									<FileItem key={`folder-${folder.getKey()}`} item={folder} />
								))}
								{result.files.map(file => (
									<FileItem key={`file-${file.getKey()}`} highlighted={highlightedFile === String(file.getKey())} item={file} />
								))}
							</div>

							{hasMoreResults && (
								<div ref={loadMoreRef} className="flex items-center justify-center h-24">
									{isLoadingMore && <FontAwesomeIcon icon="spinner-third" spin size="3x" className="text-gray-500" />}
									{!isLoadingMore && (
										<Button variant={Variant.dark} shadow size={Size.xs} disabled={isLoadingMore} onClick={() => fetchNextPage()}>
											<FormattedMessage id="file-manager.load_more" />
										</Button>
									)}
								</div>
							)}
						</>
					)}
				</DraggedFileables.Provider>

				{isLoading && (
					<Card size={null}>
						<LoadingFiles count={7} />
					</Card>
				)}

				{isSuccess && result.empty && <EmptyFiles view={view} onAction={onAction} />}
			</Card>

			{!result.empty && <GuidedTour name="files" enabled={!account?.hasFullAccess()} />}

			{dialogCreateSecureSpaceOpened && <DialogCreateFolder onAfterClose={() => setDialogCreateSecureSpaceOpened(false)} parents={view instanceof Folder ? [view] : null} secureSpace />}
		</FileManagerProvider>
	);
};

const LoadingFiles = ({ count = 3 }) => (
	<>
		{Array(count)
			.fill(0)
			.map((_: number, index) => (
				<div key={index} className="flex items-center px-1 py-3 md:p-1 hover:bg-gray-50 animate-pulse">
					<div className="mx-3">
						<div className="w-12 h-12 bg-gray-300 rounded-sm" />
					</div>

					<div className="grid flex-1 grid-cols-12 gap-x-3">
						<div className="flex items-center h-4 col-span-12 space-x-4 bg-gray-300 rounded-sm md:col-span-6"></div>
						<div className="flex items-center col-span-12 md:col-span-3">
							<div className="w-6 h-6 bg-gray-300 rounded-full" />
						</div>
					</div>

					<div className="w-10 h-10 mx-3 text-center"></div>
				</div>
			))}
	</>
);

type EmptyFilesProps = {
	view: View;
	onAction: (action: 'upload' | 'clear' | 'create-secure-space') => void;
};

const EmptyFiles: FC<EmptyFilesProps> = ({ view, onAction = () => undefined }) => {
	const { account } = useAccount();

	const { canCreateFolder, canUploadFiles } = useFileManagerPolicies(account!, view);

	if (typeof view === 'string') {
		return (
			<div className="flex flex-col items-center justify-center my-32">
				<FontAwesomeIcon icon={['fal', 'meh']} size="6x" className="mb-8 text-gray-300" />
				<h4 className="text-lg font-semibold text-gray-700">
					<FormattedMessage id="file-manager.no_results" values={{ i: msg => <em className="italic">{msg}</em>, query: view }} />
				</h4>
				<Button size={Size.sm} className="mt-8 shadow" variant={Variant.light} onClick={() => onAction('clear')}>
					<FontAwesomeIcon icon="times" className="mr-2" />
					<FormattedMessage id="file-manager.clear_search" />
				</Button>
			</div>
		);
	}

	if (view === null && canCreateFolder) {
		return (
			<div className="flex flex-col items-center justify-center max-w-lg mx-auto my-16">
				<div className="flex justify-center mb-8">
					<SecureSystemImage className="w-32" />
				</div>
				<h2 className="mb-4 text-2xl font-semibold text-gray-700">
					<FormattedMessage id="file-manager.start_title" />
				</h2>
				<p className="mb-12 text-center text-gray-600">
					<FormattedMessage id="file-manager.start_intro" />
				</p>
				<div className="flex items-center space-x-4">
					<Button shadow variant={Variant.success} size={Size.lg} className="group" onClick={() => onAction('create-secure-space')}>
						<FormattedMessage id="file-manager.create_first_secure_space" />
						<FontAwesomeIcon icon="arrow-right" className="ml-4 transition group-hover:translate-x-1" />
					</Button>
				</div>
			</div>
		);
	}

	if (view === null) {
		return (
			<div className="flex flex-col items-center justify-center max-w-lg mx-auto mt-16">
				<div className="flex justify-center mb-8">
					<SecureSystemImage className="w-32" />
				</div>
				<h2 className="mb-4 text-2xl font-semibold text-gray-700">
					<FormattedMessage id="file-manager.start_title.limited" />
				</h2>
				<p className="text-center text-gray-600">
					<FormattedMessage id="file-manager.start_intro.limited" />
				</p>
			</div>
		);
	}

	let action = canUploadFiles ? (
		<Button variant={Variant.primary} intent={Intent.secondary} onClick={() => onAction('upload')}>
			<FontAwesomeIcon icon="cloud-upload" className="mr-2" />
			<FormattedMessage id="file-manager.upload_files" />
		</Button>
	) : null;

	return <EmptyState icon="copy" title={<FormattedMessage id="file-manager.no_files" />} action={action || undefined} />;
};
