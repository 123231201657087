import IllNotificationProfile from '@assets/images/ill-notification-profiles.svg?react';
import type { NotificationOptionsMutation, UserNotificationOptions } from '@components/Notification';
import { Alert, Button, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import classNames from 'classnames';
import { type ButtonHTMLAttributes, type FC, type FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import type { WretchError } from 'wretch';

type DialogNotificationProfilePickerProps = Omit<ModalProps, 'isOpen'> & {
	isWelcome?: boolean;
};

export const DialogNotificationProfilePicker: FC<DialogNotificationProfilePickerProps> = ({ isWelcome, ...modalProps }) => {
	const [isOpen, setIsOpen] = useState(true);
	const { client } = useClient();
	const queryClient = useQueryClient();

	const [profile, setProfile] = useState<string | undefined>(isWelcome ? 'default' : undefined);

	const { mutate: save, isLoading } = useMutation<UserNotificationOptions, WretchError, NotificationOptionsMutation, UserNotificationOptions>(
		async ({ profile }) => await client.url(`account/notifications/options`).json({ profile }).put().json<UserNotificationOptions>(),
		{
			onError: console.log,
			onSuccess: async (userNotificationOptions, { profile }) => {
				queryClient.setQueryData<UserNotificationOptions>(['notification_options'], userNotificationOptions);

				toast.success(<FormattedMessage id="notifications.profile.picker.saved" values={{ profile }} />);
				setIsOpen(false);
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		save({ profile });
	};

	return (
		<Modal isOpen={isOpen} {...modalProps} onSubmit={onSubmit}>
			{!isWelcome && (
				<ModalHeaderOnlyTitle icon={['fad', 'user-tie']}>
					<FormattedMessage id="notifications.profile.title" />
				</ModalHeaderOnlyTitle>
			)}
			<ModalBody>
				{isWelcome ? (
					<>
						<h1 className="mt-6 mb-8 text-4xl font-bold tracking-tight text-theme-primary">
							<span className="inline text-transparent bg-gradient-to-br from-theme-primary-lighter to-theme-primary bg-clip-text" style={{ WebkitBoxDecorationBreak: 'clone' }}>
								<FormattedMessage id="notifications.profile.picker.title" />
							</span>
						</h1>
						<div className="flex items-start">
							<div className="flex-1">
								<p className="mb-4 text-sm font-semibold tracking-wide text-gray-600 uppercase">
									<FormattedMessage id="notifications.profile.picker.subtitle" />
								</p>
								<p className="text-sm text-gray-500">
									<FormattedMessage id="notifications.profile.description" />
								</p>
							</div>
							<div className="hidden w-48 ml-8 sm:block">
								<IllNotificationProfile />
							</div>
						</div>
					</>
				) : (
					<>
						<p className="text-sm text-gray-500">
							<FormattedMessage id="notifications.profile.description" />
						</p>
					</>
				)}
				<div className="grid grid-cols-1 gap-3 mt-6 lg:grid-cols-2">
					<ProfileItem selected={profile === 'default'} onSelected={() => setProfile('default')} className="relative">
						{isWelcome && (
							<span className="absolute px-2 py-0.5 text-xs font-medium text-white bg-green-600 shadow-green-600/25 shadow rounded-sm -top-3 left-4">
								<FormattedMessage id="notifications.profile.picker.perfect_start" />
							</span>
						)}
						<h3 className={`${isWelcome ? 'mt-2' : ''} mb-2 font-semibold`}>
							<FormattedMessage id="notifications.profile.default.title" />
						</h3>
						<p className="mb-2 text-xs text-gray-400">
							<FormattedMessage id="notifications.profile.default.description" />
						</p>
						<p className="text-xs font-semibold text-gray-500">
							<FormattedMessage id="notifications.profile.default.num_emails" />
						</p>
					</ProfileItem>
					<ProfileItem selected={profile === 'minimal'} onSelected={() => setProfile('minimal')}>
						<h3 className="mb-2 font-semibold">
							<FormattedMessage id="notifications.profile.minimal.title" />
						</h3>
						<p className="mb-2 text-xs text-gray-400">
							<FormattedMessage id="notifications.profile.minimal.description" />
						</p>
						<p className="text-xs font-semibold text-gray-500">
							<FormattedMessage id="notifications.profile.minimal.num_emails" />
						</p>
					</ProfileItem>
					<ProfileItem selected={profile === 'casual'} onSelected={() => setProfile('casual')}>
						<h3 className="mb-2 font-semibold">
							<FormattedMessage id="notifications.profile.casual.title" />
						</h3>
						<p className="mb-2 text-xs text-gray-400">
							<FormattedMessage id="notifications.profile.casual.description" />
						</p>
						<p className="text-xs font-semibold text-gray-500">
							<FormattedMessage id="notifications.profile.casual.num_emails" />
						</p>
					</ProfileItem>
					<ProfileItem selected={profile === 'complete'} onSelected={() => setProfile('complete')}>
						<h3 className="mb-2 font-semibold">
							<FormattedMessage id="notifications.profile.complete.title" />
						</h3>
						<p className="mb-2 text-xs text-gray-400">
							<FormattedMessage id="notifications.profile.complete.description" />
						</p>
						<p className="text-xs font-semibold text-gray-500">
							<FormattedMessage id="notifications.profile.complete.num_emails" />
						</p>
					</ProfileItem>
				</div>

				{!isWelcome && profile !== undefined && (
					<Alert variant={Variant.warning} className="mt-12">
						<FormattedMessage id="notifications.profile.settings_overridden" />
					</Alert>
				)}
			</ModalBody>
			<ModalFooter>
				<Button variant={Variant.primary} type="submit" disabled={isLoading || profile === undefined}>
					{isWelcome ? <FormattedMessage id="use" /> : <FormattedMessage id="apply" />}
				</Button>
				<Button variant={Variant.light} type="button" intent={Intent.secondary} disabled={isLoading} onClick={() => setIsOpen(false)}>
					<FormattedMessage id="close" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};

type ProfileItemProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	selected?: boolean;
	onSelected?: () => void;
};

const ProfileItem: FC<ProfileItemProps> = ({ children, selected = false, onSelected = () => undefined, className, ...buttonProps }) => {
	const divClassNames = classNames(className, 'flex flex-col justify-start relative transition rounded border text-left px-4 py-3 w-full focus:ring focus:outline-none', {
		'bg-theme-primary-lightest/25 border-theme-primary-lighter focus:ring-theme-primary/50': selected,
		'bg-white border-gray-200 focus:ring-gray-100 hover:border-gray-300': !selected
	});

	return (
		<button className={divClassNames} onClick={onSelected} type="button" {...buttonProps}>
			<div className="absolute top-2 right-2">
				<FontAwesomeIcon icon="check-circle" className={`transition-opacity border-2 border-white rounded-full ${!selected ? 'opacity-0' : 'opacity-100'} text-theme-primary`} />
			</div>
			<div>{children}</div>
		</button>
	);
};
