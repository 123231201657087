import MicrosoftEntraIdIcon from '@assets/images/microsoft-entra-id-icon.svg?react';
import { MicrosoftEntraIdConfigurationManager } from '@components/MicrosoftEntraId';
import { Button, Intent, Size, Toggle, Variant } from '@convoflo/ui';
import { useAppsQuery } from '@state/queries/apps';
import { useMicrosoftEntraIdGetOptionsQuery, useMicrosoftEntraIdSaveOptionsMutation } from '@state/queries/microsoft-entra-id';
import { useState, type FC } from 'react';
import { FormattedMessage } from 'react-intl';

export const MicrosoftEntraIdBanner: FC = () => {
	const [showMicrosoftEntraIdManager, setshowMicrosoftEntraIdManager] = useState(false);

	const { data = [] } = useAppsQuery({ connected: true });

	if (!data.some(app => app.Code === 'AZURE_ACTIVE_DIRECTORY')) {
		return false;
	}

	return (
		<>
			<div className="p-6 bg-gradient-to-br from-[#0294e4] via-[#0294e4] to-[#0294e4]/50 relative overflow-clip">
				<div className="absolute right-0 w-64 opacity-25 -top-4">
					<MicrosoftEntraIdIcon />
				</div>
				<div className="prose-sm prose prose-p:text-white/90 prose-headings:text-white">
					<h3>
						<FormattedMessage id="microsoft_entra_id.banner_title" />
					</h3>
					<p>
						<FormattedMessage id="microsoft_entra_id.banner_subtitle" />
					</p>
				</div>

				<div className="flex items-center gap-6 mt-6">
					<Button type="button" size={Size.sm} variant={Variant.light} intent={Intent.primary} onClick={() => setshowMicrosoftEntraIdManager(true)} icon="cog">
						<FormattedMessage id="microsoft_entra_id.configure" />
					</Button>
					<ActiveToggle />
				</div>
			</div>

			{showMicrosoftEntraIdManager && <MicrosoftEntraIdConfigurationManager onAfterClose={() => setshowMicrosoftEntraIdManager(false)} />}
		</>
	);
};

const ActiveToggle = () => {
	const { data: options } = useMicrosoftEntraIdGetOptionsQuery();
	const { mutateAsync: save } = useMicrosoftEntraIdSaveOptionsMutation();

	if (options?.enabled === undefined) {
		return null;
	}

	return (
		<span className="inline-flex items-center gap-2 p-1.5 bg-white/75 rounded-full">
			<Toggle size={Size.sm} checked={options.enabled} onChange={e => save({ enabled: e.target.checked })} />
			<span className="text-sm font-medium">
				<FormattedMessage id="active" />
			</span>
		</span>
	);
};
