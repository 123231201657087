import { autoPlacement, flip, FloatingPortal, shift, useFloating } from '@floating-ui/react';
import type { MentionNodeAttrs } from '@tiptap/extension-mention';
import { ReactRenderer } from '@tiptap/react';
import type { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion';
import classNames from 'classnames';
import { type ForwardedRef, forwardRef, type ReactNode, useEffect, useImperativeHandle, useState } from 'react';
import { FormattedMessage } from 'react-intl';

type SuggestionMenuProps<T> = SuggestionProps<T, any> & {
	renderMenuItem: (item: T) => ReactNode;
	onSelected?: (item: T) => MentionNodeAttrs;
};

type SuggestionMenuRef = {
	onKeyDown: (event: KeyboardEvent) => boolean;
};

const SuggestionMenuRenderFunction = <T,>({ renderMenuItem = () => null, decorationNode, items, command, onSelected }: SuggestionMenuProps<T>, ref: ForwardedRef<SuggestionMenuRef>) => {
	const [selectedIndex, setSelectedIndex] = useState(0);

	const { x, y, refs } = useFloating({
		elements: {
			reference: decorationNode
		},
		placement: 'bottom',
		open: !!decorationNode,
		middleware: [autoPlacement(), flip(), shift()]
		// whileElementsMounted: autoUpdate
	});

	const selectItem = (index: number) => {
		let item = items[index];

		if (item) {
			command(onSelected ? onSelected(item) : item);
		}
	};

	const upHandler = () => {
		setSelectedIndex((selectedIndex + items.length - 1) % items.length);
	};

	const downHandler = () => {
		setSelectedIndex((selectedIndex + 1) % items.length);
	};

	const enterHandler = () => {
		selectItem(selectedIndex);
	};

	useEffect(() => setSelectedIndex(0), [items]);

	useImperativeHandle(ref, () => ({
		onKeyDown: event => {
			if (event.key === 'Escape') {
				return true;
			}

			if (event.key === 'ArrowUp') {
				upHandler();
				return true;
			}

			if (event.key === 'ArrowDown') {
				downHandler();
				return true;
			}

			if (event.key === 'Enter') {
				enterHandler();
				return true;
			}

			return false;
		}
	}));

	return (
		<FloatingPortal>
			<div
				ref={refs.setFloating}
				style={{ left: x, top: y }}
				className={classNames('floating mx-2 mt-2 overflow-auto bg-white border border-gray-300 rounded-md shadow-lg max-h-1/2-screen')}
				role="menu"
				aria-orientation="vertical"
				aria-labelledby="options-menu">
				{items.length ? (
					items.map((item, index) => (
						<div
							className={classNames(
								'group leading-tight disabled:cursor-not-allowed disabled:bg-gray-100 hover:bg-gray-100 flex w-full items-center disabled:opacity-25 text-left px-4 py-2 text-sm focus:outline-none focus:bg-gray-600 focus:text-white',
								{
									'text-theme-primary': index === selectedIndex,
									'text-gray-800': index !== selectedIndex
								}
							)}>
							<button className={index === selectedIndex ? 'is-selected' : ''} key={index} onClick={() => selectItem(index)}>
								{renderMenuItem(item)}
							</button>
						</div>
					))
				) : (
					<div className="item">
						<FormattedMessage id="payment-requests.no_results" />
					</div>
				)}
			</div>
		</FloatingPortal>
	);
};

export type CreateSuggestionMenuOptions<SuggestionItem> = {
	char: string;
	onQuery: (query: string) => Promise<SuggestionItem[]>;
	renderMenuItem: (item: SuggestionItem) => ReactNode;
	onSelected?: (item: SuggestionItem) => MentionNodeAttrs;
};

export function createSuggestionMenu<T>({ char, onQuery, ...options }: CreateSuggestionMenuOptions<T>): Omit<SuggestionOptions<T>, 'editor'> {
	return {
		char,
		allowedPrefixes: null,
		allowSpaces: false,
		items: ({ query }) => onQuery(query),
		render: () => {
			let menu: ReactRenderer<SuggestionMenuRef, SuggestionMenuProps<T>>;

			return {
				onStart: ({ editor, ...props }) => {
					menu = new ReactRenderer<SuggestionMenuRef, SuggestionMenuProps<T>>(SuggestionMenu, {
						props: { editor, ...props, ...options },
						editor: editor
					});
				},

				onUpdate(props) {
					menu?.updateProps(props);
				},

				onKeyDown({ event }) {
					return menu?.ref?.onKeyDown(event) ?? true;
				},

				onExit() {
					menu?.destroy();
				}
			};
		}
	};
}

export const SuggestionMenu = forwardRef(SuggestionMenuRenderFunction) as <S = any>(props: SuggestionMenuProps<S> & { ref?: ForwardedRef<SuggestionMenuRef> }) => ReactNode;
