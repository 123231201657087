import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCurrentEditor } from '@tiptap/react';
import { EmojiPicker } from '@ui/EmojiPicker';
import { ToolbarButton } from '@ui/RichTextEditor/Toolbar';
import { type FC, useCallback } from 'react';

type EmojiPickerDropdownProps = {
	editorId?: string;
};

export const EmojiPickerDropdown: FC<EmojiPickerDropdownProps> = () => {
	const { editor } = useCurrentEditor();

	const insertEmoji = useCallback(
		(emoji: string) => {
			if (!editor) {
				return;
			}

			editor.chain().insertContent(emoji).focus().run();
		},
		[editor]
	);

	if (editor === null) {
		return null;
	}

	return (
		<EmojiPicker onChange={insertEmoji}>
			<ToolbarButton icon={['far', 'smile']}>
				<FontAwesomeIcon icon="caret-down" className="ml-1.5" />
			</ToolbarButton>
		</EmojiPicker>
	);
};
