import { FileManagerContext } from '@components/FileManager';
import { FileSelector, type FileSelectorSelection } from '@components/FileSelector';
import { Alert, Button, Checkbox, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Row, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useView } from '@hooks/use-view';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import File from '@models/File';
import Folder from '@models/Folder';
import { type Fileable, Permissions } from '@types';
import { type FC, type FormEvent, useCallback, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';
import type { WretchError } from 'wretch';

export type CopiedEvent = {
	destination: Folder | null;
	sourceFiles: File[];
	sourceFolders: Folder[];
	newFiles: File[];
	newFolders: Folder[];
};

type CopiedResponse = {
	destination_folder: Folder | null;
	source_files: File[];
	source_folders: Folder[];
	new_files: File[];
	new_folders: Folder[];
};

type CopyMutation = {
	files: File[];
	folders: Folder[];
	destination?: Folder | null;
	keepSettings: boolean;
};

type DialogCopyFileablesProps = Omit<ModalProps, 'isOpen'> & {
	items: Fileable[];
	destination?: Folder | null;
	onCopied?: (event: CopiedEvent) => void;
};

export const DialogCopyFileables: FC<DialogCopyFileablesProps> = ({ items, destination, onCopied = () => undefined, ...props }) => {
	const { client } = useClient();
	const { account } = useAccount();
	const { add: addItem = () => undefined } = useContext(FileManagerContext) ?? {};
	const { view } = useView();

	const [isOpen, setIsOpen] = useState(true);
	const [selectedFolder, setSelectedFolder] = useState(destination);
	const [keepSettings, setKeepSettings] = useState(true);
	const [showFileSelector, setShowFileSelector] = useState(false);

	const { mutate: copy, isLoading: isCopying } = useMutation<CopiedEvent, WretchError, CopyMutation>(
		async ({ files, folders, destination, keepSettings }) => {
			const data = {
				files: files.map((fileable: Fileable) => fileable.OriginalLink),
				folders: folders.map((fileable: Fileable) => fileable.OriginalLink),
				folder: destination instanceof Folder ? destination.getKey() : null,
				keep_settings: +keepSettings
			};

			const { source_files, source_folders, destination_folder, new_files, new_folders } = await client.url('files/copy').post(data).json<CopiedResponse>();

			return {
				destination: destination_folder !== null ? new Folder(destination_folder) : null,
				sourceFiles: source_files.map((file: File) => new File(file)),
				sourceFolders: source_folders.map((folder: Folder) => new Folder(folder)),
				newFiles: new_files.map((file: File) => new File(file)),
				newFolders: new_folders.map((folder: Folder) => new Folder(folder))
			};
		},
		{
			onError: error => console.error(error),
			onSuccess: event => {
				const fileables = (event.newFolders as Fileable[]).concat(event.newFiles as Fileable[]);
				toast.success(<FormattedMessage id="dialog-copy.copied" values={{ n: fileables.length, item: fileables[0].getName() }} />);
				setIsOpen(false);
				onCopied(event);

				// Add the selected fileables to the FileManager if the destination matches
				if (view instanceof Folder && event.destination?.getKey() === view.getKey()) {
					addItem(fileables);
				}

				if (view === null && event.destination === null) {
					addItem(fileables);
				}
			}
		}
	);

	const onSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const files = items.filter((f: Fileable) => f instanceof File) as File[];
		const folders = items.filter((f: Fileable) => f instanceof Folder) as Folder[];
		copy({ files, folders, destination: selectedFolder as Folder, keepSettings });
	};

	const firstItem = items[0];

	const onItemsSelected = useCallback((items: FileSelectorSelection) => setSelectedFolder(items.length === 0 ? null : items[0]), []);

	return (
		<Modal {...props} isOpen={isOpen} onSubmit={onSubmit}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage
					id="dialog-copy.title"
					values={{
						n: items.length,
						item:
							items.length > 0 ? (
								<span>
									{firstItem.icon('mx-2')}
									{firstItem.getName()}
								</span>
							) : null
					}}
				/>
			</ModalHeaderOnlyTitle>
			<ModalBody disabled={isCopying}>
				<Row>
					<Label>
						<FormattedMessage id="dialog-copy.copy-to" />
					</Label>
					<div className="flex items-center space-x-4">
						<Button type="button" size={Size.sm} variant={Variant.light} intent={Intent.secondary} onClick={() => setShowFileSelector(true)}>
							{/* <FontAwesomeIcon icon="folder" className="mr-2" /> */}
							<FormattedMessage id="dialog-copy.select_destination" />
						</Button>
						{selectedFolder && (
							<span className="text-sm italic text-gray-700">
								{selectedFolder.icon('mr-2')}
								{selectedFolder.getName()}
							</span>
						)}
						{selectedFolder === null && (
							<span className="text-sm italic text-gray-700">
								<FontAwesomeIcon icon="home" className="mr-2" />
								<FormattedMessage id="file-selector.root" />
							</span>
						)}
					</div>
					{showFileSelector && (
						<FileSelector
							startingFolder={view instanceof Folder ? view : null}
							onAfterClose={() => setShowFileSelector(false)}
							rootIsSelectable={account?.hasFullAccess()}
							selectedItems={selectedFolder ? [selectedFolder] : undefined}
							isItemDisabled={fileable => !(fileable instanceof Folder) || fileable.pivot?.Permissions !== Permissions.ReadWrite}
							onItemsSelected={onItemsSelected}
						/>
					)}
				</Row>

				<Row className="flex items-center">
					<div className="flex-1">
						<Label htmlFor="keep-settings">
							<FormattedMessage id="dialog-copy.keep_settings" />
						</Label>
						<Checkbox checked={keepSettings} onChange={e => setKeepSettings(e.target.checked)} className="text-sm">
							<FormattedMessage id="dialog-copy.keep_settings-desc" />
						</Checkbox>
					</div>
					<FontAwesomeIcon icon="cog" mask="circle" transform="shrink-10" size="3x" className="ml-4 text-gray-800" />
				</Row>

				{selectedFolder instanceof Folder && (
					<Alert variant={Variant.warning}>
						<p>
							<FormattedMessage
								id="dialog-copy.copy-permission-mention"
								values={{
									n: items.length,
									folder: <strong>{selectedFolder.getName()}</strong>
								}}
							/>
						</p>
					</Alert>
				)}
			</ModalBody>
			<ModalFooter>
				<Button loading={isCopying} variant={Variant.primary} disabled={isCopying || selectedFolder === undefined} type="submit">
					<FormattedMessage id="dialog-copy.copy" />
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
