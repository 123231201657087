import { useFileableMembers } from '@components/FileManager';
import { NotificationConfirmationToastContents } from '@components/NotificationsListener';
import { Dropzone, UploadButton, UploadFile, UploadListItem, UploadStatus, useUploadService } from '@components/Upload';
import { Button, Size } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import File from '@models/File';
import { type Fileable, Module } from '@types';
import { type ForwardRefRenderFunction, forwardRef, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { Prompt } from 'react-router-dom';

type UploaderProps = {
	item: Fileable | null;
	multiple?: boolean;
	pastable?: boolean;
	disabled?: boolean;
	validExtensions?: string[];
	dragNDrop?: boolean;
};

export interface UploaderHandles {
	chooseFiles(): void;
}

const UploaderRenderFunction: ForwardRefRenderFunction<UploaderHandles, UploaderProps> = (
	{ item = null, multiple = true, pastable = true, disabled = false, dragNDrop = true, validExtensions },
	ref
) => {
	const { formatMessage } = useIntl();
	const { client } = useClient();
	const { account } = useAccount();

	const [files, setFiles] = useState<UploadFile[]>([]);
	const [abortAll, setAbortAll] = useState(false);
	const [isVisible, setIsVisible] = useState(false);

	const { members = [] } = useFileableMembers(item ?? undefined);

	const allFilesAreFinished = useMemo(() => {
		return files.length > 0 && files.every(f => [UploadStatus.Aborted, UploadStatus.Completed, UploadStatus.Error].includes(f.status));
	}, [files]);

	const service = useUploadService(item, { start: files.length > 0 && !allFilesAreFinished });

	const currentQueueFinished = useRef(false);

	const cleanup = () => {
		setFiles(files => files.filter(file => ![UploadStatus.Completed, UploadStatus.Aborted, UploadStatus.Aborted].includes(file.status)));
		setAbortAll(false);
	};

	const { mutate: markFilesUploaded } = useMutation(
		async ({ files }: { files: File[] }) =>
			await client
				.url('files/finished')
				.json({ files: files.map(file => file.OriginalLink) })
				.post()
				.res(),
		{
			onSuccess: () => {
				currentQueueFinished.current = true;

				if (item === null) {
					return;
				}

				const usersInToast = members.filter(collaborator => !!collaborator.pivot.Notify && collaborator.ID !== account?.ID);
				let context = null;

				// A way to check if user is external
				if (!members.some(member => member.ID === item.creator.ID)) {
					context = { organization: item.creator.business };
				}

				if (usersInToast.length === 0 && context === null) {
					toast.success(
						<div>
							<h5 className="font-semibold">
								<FormattedMessage id="uploader.files_added" />
							</h5>
						</div>,
						{ id: 'notification-confirmation', duration: 15000 }
					);
					return;
				}

				toast.success(
					<div>
						<h5 className="font-semibold">
							<FormattedMessage id="uploader.files_sent_to" />
						</h5>
						<NotificationConfirmationToastContents users={usersInToast} context={context} />
					</div>,
					{ id: 'notification-confirmation', duration: 15000 }
				);
			}
		}
	);

	const _onPaste = useCallback(
		(event: ClipboardEvent) => {
			if (!event.clipboardData) {
				return;
			}
			cleanup();

			const _items = Array.from(event.clipboardData.items);

			setFiles(files =>
				files.concat(
					_items
						.filter(item => item.kind === 'file' && item.type.indexOf('image') === 0)
						.map<UploadFile>((file: DataTransferItem) => UploadFile.fromFile(file.getAsFile(), item))
						.filter((file: UploadFile) => file.file !== null)
				)
			);
			currentQueueFinished.current = false;
		},
		[item]
	);

	const _onFilesSelected = useCallback(
		(files: globalThis.File[]) => {
			cleanup();
			setFiles(_files =>
				_files.concat(
					files
						.map<UploadFile>(file => UploadFile.fromFile(file, item))
						.filter((file: UploadFile) => file.file)
						.filter((file: UploadFile) => (validExtensions !== undefined ? validExtensions.includes(file.file.name.split('.').pop() || '') : true))
				)
			);
			currentQueueFinished.current = false;
		},
		[item, validExtensions]
	);

	const onFileUpdated = useCallback((file: UploadFile) => {
		setFiles(files => files.map(f => (f.id === file.id ? file : f)));
	}, []);

	const hasCommentingModule = item !== null && item.hasModule(Module.Commenting);

	useEffect(() => {
		const filesToMarkAsRead = files.filter(file => file.model !== null && file.status === UploadStatus.Completed && file.scope !== null && file.scope.getKey() !== null);

		if (allFilesAreFinished && !currentQueueFinished.current && filesToMarkAsRead.length > 0) {
			markFilesUploaded({ files: filesToMarkAsRead.map(file => file.model) as File[] });
		}
	}, [allFilesAreFinished, files, hasCommentingModule, markFilesUploaded]);

	useLayoutEffect(() => {
		if (pastable && !disabled) {
			document.addEventListener('paste', _onPaste);
		} else {
			document.removeEventListener('paste', _onPaste);
		}
		return () => {
			document.removeEventListener('paste', _onPaste);
		};
	}, [pastable, _onPaste, disabled]);

	useEffect(() => {
		if (!allFilesAreFinished && files.length > 0 && !isVisible) {
			setIsVisible(true);
		}
	}, [isVisible, files, allFilesAreFinished]);

	return (
		<>
			{dragNDrop && createPortal(<Dropzone onFilesDropped={_onFilesSelected} disabled={disabled} fullScreen />, document.body)}

			<Prompt
				when={files.length > 0 && !allFilesAreFinished}
				message={formatMessage(
					{ id: 'uploader.warning_leave_page' },
					{ n: files.filter(file => [UploadStatus.Uploading, UploadStatus.Processing, UploadStatus.Pending].includes(file.status)).length }
				)}
			/>

			<div className={`fixed m-8 inset-x-auto bottom-0 shadow-lg sm:right-0 sm:w-80 z-10 ${isVisible ? 'block' : 'hidden'}`}>
				<header className="flex items-center justify-between px-3 py-2 text-gray-200 bg-gray-700 rounded-t-md">
					<h6 className="text-sm font-semibold">
						<FormattedMessage id="uploader.files_uploaded" values={{ n: files.filter(file => file.status === UploadStatus.Completed).length, total: files.length }} />
					</h6>
					<button type="button" className="hover:text-white" onClick={() => setIsVisible(false)} disabled={!allFilesAreFinished && files.length > 0}>
						<FontAwesomeIcon icon="times" fixedWidth />
					</button>
				</header>
				<main className="overflow-auto bg-white max-h " style={{ maxHeight: '20rem' }}>
					{files.length === 0 && (
						<p className="my-8 text-sm text-center text-gray-500">
							<FormattedMessage id="uploader.empty" />
						</p>
					)}
					{files.map(file => (
						<UploadListItem key={file.id} service={service} file={file} onFileUpdated={onFileUpdated} stop={abortAll} />
					))}
				</main>
				<footer className="text-right bg-white">
					<Button size={Size.sm} disabled={allFilesAreFinished || files.length === 0} onClick={() => setAbortAll(true)}>
						<FormattedMessage id="cancel" />
					</Button>
				</footer>
				<UploadButton ref={ref} multiple={multiple} disabled={disabled} onFilesSelected={_onFilesSelected} accept={validExtensions?.map(ext => `.${ext}`).join(',')} />
			</div>
		</>
	);
};

export const Uploader = forwardRef(UploaderRenderFunction);
