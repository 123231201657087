import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type FC, useState, useCallback, useLayoutEffect } from 'react';
import { FormattedMessage } from 'react-intl';

type DropzoneProps = {
	onFilesDropped: (files: File[]) => void;
	disabled?: boolean;
	className?: string;
	fullScreen?: boolean;
};

export const Dropzone: FC<DropzoneProps> = ({ onFilesDropped = () => undefined, disabled = false, fullScreen = false, className = '' }) => {
	const [isVisible, setIsVisible] = useState(false);

	const onWindowDragEnter = useCallback(
		(event: DragEvent) => {
			const areFilesBeingDragged =
				event?.dataTransfer?.types && (event.dataTransfer.types.indexOf ? event.dataTransfer.types.indexOf('Files') !== -1 : event.dataTransfer.types.includes('Files'));
			if (!areFilesBeingDragged || disabled) {
				return;
			}

			event.preventDefault();
			setIsVisible(true);
		},
		[disabled]
	);

	const onDropzoneDragEnterDragOver = useCallback((event: React.DragEvent) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = 'copy';
	}, []);

	const onDropzoneDragLeave = useCallback((event: React.DragEvent) => {
		event.preventDefault();
		setIsVisible(false);
	}, []);

	const onDropzoneDrop = useCallback(
		(event: React.DragEvent) => {
			const areFilesBeingDragged = event.dataTransfer.types && (event.dataTransfer.types.indexOf ? event.dataTransfer.types.indexOf('Files') !== -1 : event.dataTransfer.types.includes('Files'));
			if (!areFilesBeingDragged) {
				return;
			}

			setIsVisible(false);
			onFilesDropped(Array.from(event.dataTransfer.files));
			event.preventDefault();
		},
		[onFilesDropped]
	);

	useLayoutEffect(() => {
		window.addEventListener('dragenter', onWindowDragEnter);

		return () => {
			window.removeEventListener('dragenter', onWindowDragEnter);
		};
	}, [onWindowDragEnter]);

	if (disabled) {
		return null;
	}

	return (
		<div
			onDragOver={onDropzoneDragEnterDragOver}
			onDragLeave={onDropzoneDragLeave}
			onDrop={onDropzoneDrop}
			className={`${fullScreen ? 'fixed w-screen h-screen' : 'absolute'} inset-0 z-50 flex-col items-center justify-center bg-white border-8 border-blue-500 bg-opacity-90`}
			style={{ display: isVisible ? 'flex' : 'none' }}>
			<FontAwesomeIcon icon="cloud-upload" className="text-gray-400" size="4x" />
			<p className="mt-4 text-4xl italic font-semibold text-center text-gray-500">
				<FormattedMessage id="uploader.drop_files" />
			</p>
		</div>
	);
};
