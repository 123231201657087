import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCurrentEditor } from '@tiptap/react';
import type { ToolbarButtonProps } from '@ui/RichTextEditor/Toolbar';
import { Tooltip } from '@ui/Tooltip';
import classNames from 'classnames';
import { forwardRef, type ForwardRefRenderFunction } from 'react';

export const ToolbarButtonRenderFunction: ForwardRefRenderFunction<HTMLButtonElement, ToolbarButtonProps> = ({ handler, type, icon, title, children, ...buttonProps }, ref) => {
	const { editor } = useCurrentEditor();

	const active = type ? editor?.isActive(...type) : false;

	const className = classNames('focus:outline-none inline-flex items-center justify-center p-1.5 text-sm', {
		'text-theme-primary bg-theme-primary/10 rounded': active
	});

	const button = (
		<button onClick={handler} className={className} {...buttonProps} type="button" ref={ref}>
			{icon !== undefined && <FontAwesomeIcon icon={icon} size="sm" fixedWidth />}
			{children}
		</button>
	);

	return title ? <Tooltip tip={title}>{button}</Tooltip> : button;
};

export const ToolbarButton = forwardRef(ToolbarButtonRenderFunction);
