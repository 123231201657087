import type { ResourceSimplePaginatorType } from '@components/Pagination';
import { Button, Intent, Variant } from '@convoflo/ui';
import { FormattedMessage, useIntl } from 'react-intl';

type ResourceSimplePaginatorProps<T> = ResourceSimplePaginatorType<T> & {
	onPage?: (page: number) => void;
	className?: string;
	disabled?: boolean;
};

export const SimpleResourcePaginator = <T,>({
	disabled = false,
	meta,
	links,
	className = '',
	onPage = () => undefined
}: Omit<ResourceSimplePaginatorProps<T>, 'data'> & Partial<Pick<ResourceSimplePaginatorProps<T>, 'data'>>) => {
	const { formatMessage } = useIntl();

	return (
		<nav className={`flex items-center justify-between ${className}`}>
			{!!links.prev ? (
				<Button
					intent={Intent.secondary}
					variant={Variant.light}
					disabled={disabled}
					type="button"
					onClick={() => onPage(meta.currentPage - 1)}
					aria-label={formatMessage({ id: 'pagination.previous' })}>
					<FormattedMessage id="pagination.previous" />
				</Button>
			) : (
				<Button intent={Intent.secondary} variant={Variant.light} type="button" disabled aria-label={formatMessage({ id: 'pagination.previous' })}>
					<FormattedMessage id="pagination.previous" />
				</Button>
			)}

			{!!links.next ? (
				<Button
					intent={Intent.secondary}
					variant={Variant.light}
					disabled={disabled}
					type="button"
					onClick={() => onPage(meta.currentPage + 1)}
					aria-label={formatMessage({ id: 'pagination.next' })}>
					<FormattedMessage id="pagination.next" />
				</Button>
			) : (
				<Button intent={Intent.secondary} variant={Variant.light} type="button" disabled aria-label={formatMessage({ id: 'pagination.next' })}>
					<FormattedMessage id="pagination.next" />
				</Button>
			)}
		</nav>
	);
};
