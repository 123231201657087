import { FileManagerContext } from '@components/FileManager';
import Account from '@models/Account';
import Folder from '@models/Folder';
import { Permissions, type View } from '@types';
import { useContext, useMemo } from 'react';

export const useFileManagerPolicies = (user: Account, view: View) => {
	const fileManagerContext = useContext(FileManagerContext);

	const canCreateFolder = useMemo(() => {
		if (!user.hasFullAccess()) {
			return false;
		}

		if (fileManagerContext?.selection?.fileables) {
			return fileManagerContext.selection.fileables.every(folder => folder.pivot?.Permissions === Permissions.ReadWrite && folder['@type'] === 'Folder');
		}

		if (fileManagerContext?.selection?.parent) {
			return fileManagerContext.selection.parent.pivot?.Permissions === Permissions.ReadWrite;
		}

		if (typeof view === 'string') {
			return true;
		}

		if (view === null) {
			return true;
		}

		return view?.pivot?.Permissions === Permissions.ReadWrite;
	}, [fileManagerContext, user, view]);

	const canUploadFiles = useMemo(() => {
		if (view === null && user.hasFullAccess()) {
			return true;
		}

		if (fileManagerContext?.selection !== undefined) {
			return false;
		}

		if (view instanceof Folder && (view?.pivot?.Permissions === Permissions.ReadWrite || view.Access === 'public')) {
			return true;
		}

		return view instanceof Folder && view?.SecureSpace?.Access === 'public';
	}, [fileManagerContext?.selection, user, view]);

	return {
		canCreateFolder,
		canUploadFiles
	};
};
