import FullScreenLoading from '@components/FullScreenLoading';
import { Logo } from '@components/Logo';
import AppContext from '@contexts/AppContext';
import { Button, InputBlock, Label, Row, Size, ValidationField, Variant } from '@convoflo/ui';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import useUrlSearch from '@hooks/useUrlSearch';
import { useAccountEmailChangeMutation } from '@state/queries/account';
import type { EmailChangeRequestType } from '@types';
import ErrorLayout from '@ui/Layout/Error';
import { type FormEvent, useContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import type { WretchError } from 'wretch';

type SendRequestMutation = {
	url: string;
	email: string;
	emailConfirmation: string;
};

export const AccountEmailChangePage = () => {
	const { validationUrl } = useUrlSearch();
	const { formatMessage } = useIntl();
	const { account } = useAccount();
	const { client, validation } = useClient();
	const history = useHistory();
	const { setPageTitle: setTitle } = useContext(AppContext);

	const [email, setEmail] = useState('');
	const [emailConfirmation, setEmailConfirmation] = useState('');

	setTitle(formatMessage({ id: 'email-change.title' }));

	// Always validate when landing on the page, whether it is to enter new email, or confirmation button
	const {
		data: emailChangeRequest,
		isLoading: isVerifying,
		isError: isVerifyingError
	} = useQuery<EmailChangeRequestType>(['email-change-request', validationUrl], async () => await client.url(validationUrl, true).get().json<EmailChangeRequestType>(), {
		enabled: !!validationUrl,
		staleTime: Infinity
	});

	// Send the new user's email address request
	const {
		mutate: sendRequest,
		isLoading: isEmailChangeRequesting,
		isSuccess: isEmailChangeRequested
	} = useMutation<undefined, WretchError, SendRequestMutation>(
		async ({ url, email, emailConfirmation }) => await client.url(url, true).post({ email: email, email_confirmation: emailConfirmation }).res(),
		{
			onSuccess: () => {
				toast.success(<FormattedMessage id="email-change.toast" />);
			}
		}
	);

	// User confirms the change and redirect to the profile page
	const { mutateAsync: confirmEmailChange, isLoading: isEmailChangeConfirming } = useAccountEmailChangeMutation();

	// Auto-confirm the email change when landing on the page
	useEffect(() => {
		const _confirmEmailChange = async (url: string) => {
			try {
				await confirmEmailChange(url);
			} catch {
				history.replace('/account/profile');
				toast.error(<FormattedMessage id="email-change.error" />);
			}
		};

		if (emailChangeRequest?.Email && validationUrl) {
			_confirmEmailChange(validationUrl);
		}
	}, [confirmEmailChange, emailChangeRequest?.Email, history, validationUrl]);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		sendRequest({ url: validationUrl, email, emailConfirmation });
	};

	if (isVerifying || isEmailChangeConfirming) {
		return <FullScreenLoading />;
	}

	if (isVerifyingError || !validationUrl) {
		return <EmailChangeErrorSection />;
	}

	const showForm = !emailChangeRequest?.Email;

	return (
		<>
			<div className="flex flex-col justify-center h-screen bg-gradient-to-br from-gray-100 via-gray-50 to-white">
				<div className="container">
					<div className="mb-6">
						<Logo light className="w-48 mx-auto" />
					</div>

					<div className="px-10 py-8 bg-white border-t-8 border-blue-400 rounded-lg shadow-sm 2xl:px-36 md:px-20 sm:px-14 2xl:mx-96 xl:mx-80 lg:mx-52 md:mx-20">
						{showForm && (
							<form onSubmit={onSubmit}>
								{!isEmailChangeRequested ? (
									<>
										<h1 className="mb-6 text-4xl font-bold tracking-tight text-center">
											<FormattedMessage id="email-change.title" />
										</h1>

										<div className="px-6 py-4 my-8 bg-gray-100 rounded">
											<FormattedMessage id="email-change.note" values={{ email: <span className="font-bold">{account?.Email} </span> }} />
										</div>

										<Row>
											<Label htmlFor="email">
												<FormattedMessage id="email-change.new_email" />
											</Label>
											<ValidationField fieldName="email" validation={validation}>
												<InputBlock id="email" icon="envelope" value={email} type="email" onChange={e => setEmail(e.currentTarget.value)} disabled={isEmailChangeRequesting} />
											</ValidationField>
										</Row>

										<Row>
											<Label htmlFor="emailConfirmation">
												<FormattedMessage id="email-change.new_email_confirmation" />
											</Label>
											<ValidationField fieldName="emailConfirmation" validation={validation}>
												<InputBlock
													id="emailConfirmation"
													icon="envelope"
													value={emailConfirmation}
													type="email"
													onChange={e => setEmailConfirmation(e.currentTarget.value)}
													disabled={isEmailChangeRequesting}
												/>
											</ValidationField>
										</Row>

										<Button type="submit" className="block w-full" variant={Variant.primary} disabled={isEmailChangeRequesting || !email || !emailConfirmation} size={Size.xl}>
											<FormattedMessage id="signup.continue" />
										</Button>
									</>
								) : (
									<>
										<h1 className="mb-6 text-4xl font-bold tracking-tight text-center">
											<FormattedMessage id="email-change.request_sent" />
										</h1>

										<div className="prose break-normal">
											<p>
												<FormattedMessage id="email-change.confirmation_sent_at" values={{ email: <span className="font-bold">{emailConfirmation} </span> }} />
											</p>
										</div>

										<div className="mt-6">
											<button type="submit" className="text-sm text-theme-primary hover:underline hover:cursor-pointer" disabled={isEmailChangeRequesting}>
												<FormattedMessage id="email-change.resend_btn" />
											</button>
										</div>
									</>
								)}
							</form>
						)}

						{!showForm && (
							<>
								<h1 className="mb-6 text-4xl font-bold tracking-tight text-center">
									<FormattedMessage id="email-change-confirmation.title" />
								</h1>

								<div className="px-6 py-4 my-8 bg-gray-100 rounded">
									<FormattedMessage id="email-change-confirmation.body" values={{ email: <span className="font-bold">{account?.Email} </span> }} />
								</div>

								<Link to="/account/profile">
									<Button className="block w-full" variant={Variant.primary} size={Size.xl}>
										<FormattedMessage id="email-change-confirmation.btn" />
									</Button>
								</Link>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

const EmailChangeErrorSection = () => (
	<ErrorLayout>
		<h1 className="mb-2 text-xl font-semibold text-black">
			<FormattedMessage id="invalid-change-request.title" />
		</h1>
		<p className="my-2 leading-tight text-gray-600">
			<FormattedMessage
				id="invalid-change-request.body"
				values={{
					link: msg => (
						<Link to="/account/profile" className="font-semibold underline transition duration-300 text-theme-primary hover:underline">
							{msg}
						</Link>
					)
				}}
			/>
		</p>
	</ErrorLayout>
);
