import { type Invoice, InvoiceItem } from '@components/Invoices';
import { createResourcePagination, SimpleResourcePaginator } from '@components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import Card from '@ui/Card';
import { TableHeader } from '@ui/Table';
import { type FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';

type InvoiceManagerProps = {
	limit?: number;
};

export const InvoiceManager: FC<InvoiceManagerProps> = ({ limit = 20 }) => {
	const { account } = useAccount();
	const { client } = useClient();

	const [page, setPage] = useState(1);

	const { data, isLoading } = useQuery(['invoices', page], async () => createResourcePagination<Invoice>(await client.url('organization/invoices').query({ limit, page }).get().json()), {
		staleTime: Infinity,
		enabled: account!.business.HasInvoices
	});

	const invoices = data?.data ?? [];

	return (
		<Card size={null} className="mb-12 overflow-x-auto">
			<table className="min-w-full">
				<thead>
					<tr>
						<TableHeader scope="col">
							<FormattedMessage id="billing-history.invoice" />
						</TableHeader>
						<TableHeader scope="col">
							<FormattedMessage id="billing-history.date" />
						</TableHeader>
						<TableHeader scope="col">
							<FormattedMessage id="billing-history.name" />
						</TableHeader>
						<TableHeader scope="col" className="text-right">
							<FormattedMessage id="billing-history.amount" />
						</TableHeader>
						<TableHeader />
					</tr>
				</thead>
				<tbody className="">
					{!isLoading && invoices.map(invoice => <InvoiceItem key={invoice.id} invoice={invoice} />)}
					{isLoading && (
						<tr>
							<td colSpan={5}>
								<div className="flex items-center justify-center h-24" style={{ maxHeight: '50vh' }}>
									<p className="mb-0">
										<FontAwesomeIcon icon="spinner" className="mr-2" pulse />
										<FormattedMessage id="loading" />
									</p>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
			{data !== undefined && (
				<div className="px-4 py-3 sm:px-6">
					<SimpleResourcePaginator {...data} onPage={page => setPage(page)} />
				</div>
			)}
		</Card>
	);
};
