import { useBillingInformation } from '@components/Account';
import { type CartItem, type CartItemPutRequest, type CartPutRequest, type CartType, CheckoutContext, type ITEM_IDS, type PAYMENT_FREQUENCIES, type StoreResponseType } from '@components/Checkout';
import useClient from '@hooks/useClient';
import useUrlSearch from '@hooks/useUrlSearch';
import { type FC, type PropsWithChildren, useCallback, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { WretchError } from 'wretch';

const DEFAULT_CART: CartType = {
	discounts_total: 0,
	frequency: 'month',
	items: [
		{
			id: 'support_plan',
			amount: 0,
			quantity: 1,
			total: 0,
			options: {
				level: 'standard'
			}
		}
	],
	subtotal: 0,
	total: 0,
	total_no_taxes: 0,
	tax_amounts: [],
	discounts: [],
	coupon: ''
};

export const CheckoutProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
	const { client } = useClient();
	const { coupon: promoCodeFromUrl = '' } = useUrlSearch();
	const queryClient = useQueryClient();
	const [billingInformation] = useBillingInformation();

	const { data: store, isLoading: isStoreLoading } = useQuery(['store'], async () => await client.url('checkout/store').get().json<StoreResponseType>(), {
		staleTime: Infinity
	});

	// Load cart
	const { data: cart = DEFAULT_CART } = useQuery<CartType>(
		['cart'],
		() => ({ ...DEFAULT_CART, country: billingInformation?.BillingCountry, region: billingInformation?.BillingRegion } as CartType),
		{
			staleTime: Infinity
		}
	);

	// Save cart
	const { mutate: saveCart, isLoading: isCartLoading } = useMutation<CartType, WretchError, CartPutRequest, CartType>(
		async cart => {
			// Remove region when it is not Canada/USA
			if (!['US', 'CA'].includes(billingInformation?.BillingCountry ?? '')) {
				cart.region = null;
			}

			// Force yearly when signatures are in the cart
			if (cart.items.find(item => item.id === 'esigns') && cart.frequency === 'month') {
				cart.frequency = 'year';
			}

			return await client
				.url('checkout/cart')
				.json({ ...cart })
				.put()
				.json();
		},
		{
			onSuccess: cart => {
				queryClient.setQueryData<CartType>(['cart'], cart);
			},
			onMutate: async cart => {
				await queryClient.cancelQueries(['cart']);

				const previousState = queryClient.getQueryData<CartType>(['cart']);

				if (previousState === undefined) {
					return undefined;
				}

				queryClient.setQueryData<CartType>(['cart'], cart as CartType);

				return previousState;
			},
			onError: (_error, _variables, previousState) => {
				queryClient.setQueryData(['cart'], previousState);
			}
		}
	);

	const getItemById = useCallback((id: ITEM_IDS) => cart.items.find(item => item.id === id), [cart.items]);

	const addOrUpdateItem = useCallback((item: CartItemPutRequest) => saveCart({ ...cart, items: cart.items.filter(i => item.id !== i.id).concat(item as CartItem) }), [cart, saveCart]);

	const addItem = useCallback((item: CartItemPutRequest) => addOrUpdateItem(item), [addOrUpdateItem]);

	const updateItem = useCallback((item: CartItemPutRequest) => saveCart({ ...cart, items: cart.items.map(i => (item.id === i.id ? item : i)) }), [cart, saveCart]);

	const removeItemById = useCallback((id: ITEM_IDS) => saveCart({ ...cart, items: cart.items.filter(i => id !== i.id) }), [cart, saveCart]);

	const toggleItem = useCallback(
		(item: CartItemPutRequest) => {
			if (getItemById(item.id)) {
				removeItemById(item.id);
			} else {
				addItem(item);
			}
		},
		[addItem, getItemById, removeItemById]
	);

	const setFrequency = useCallback((frequency: PAYMENT_FREQUENCIES) => saveCart({ ...cart, frequency }), [cart, saveCart]);

	const applyPromoCode = useCallback(
		(promoCode: string) => {
			saveCart({ ...cart, coupon: promoCode });
		},
		[cart, saveCart]
	);

	useEffect(() => {
		saveCart({ ...cart, country: billingInformation?.BillingCountry, region: billingInformation?.BillingRegion });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [billingInformation?.BillingCountry, billingInformation?.BillingRegion, saveCart]);

	useEffect(() => {
		if (promoCodeFromUrl !== '') {
			saveCart({ ...cart, coupon: promoCodeFromUrl });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saveCart, promoCodeFromUrl]);

	return (
		<CheckoutContext.Provider
			value={{
				store,
				isStoreLoading,
				cart,
				isCartLoading,
				applyPromoCode,
				getItemById,
				addItem,
				addOrUpdateItem,
				updateItem,
				removeItemById,
				toggleItem,
				setFrequency
			}}>
			{children}
		</CheckoutContext.Provider>
	);
};
