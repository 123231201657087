import type { FC, PropsWithChildren, ReactNode } from 'react';

type HeaderProps = {
	subtitle?: ReactNode;
};

export const Header: FC<PropsWithChildren<HeaderProps>> = ({ subtitle, children }) => {
	return (
		<header className="mb-6 space-y-2 lg:mb-12">
			<h1 className="font-serif text-lg font-bold md:text-2xl lg:text-4xl">{children}</h1>
			{subtitle !== undefined && <p className="text-xs text-gray-500 md:text-sm lg:text-base">{subtitle}</p>}
		</header>
	);
};
