import { type DependencyList, useEffect, useState } from 'react';

const useIsDirty = (dependencies: DependencyList) => {
	const [initialState] = useState(dependencies);
	const [isDirty, setIsDirty] = useState(false);

	useEffect(() => {
		const newIsDirty = dependencies.some((arg, i) => arg !== initialState[i]);
		if (newIsDirty !== isDirty) {
			setIsDirty(newIsDirty);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, dependencies);

	return { isDirty, setIsDirty };
};

export default useIsDirty;
