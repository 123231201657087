import Folder from '@models/Folder';
import type { View } from '@types';

export const useSecureSpace = (fileable?: View) => {
	if (fileable === null || fileable === undefined || typeof fileable === 'string') {
		return null;
	}

	let secureSpace = null;

	if (fileable.SecuredSpace) {
		secureSpace = fileable;
	} else if (fileable.SecureSpace) {
		secureSpace = fileable.SecureSpace;
	}

	if (secureSpace && !(secureSpace instanceof Folder)) {
		secureSpace = new Folder(secureSpace);
	}

	return secureSpace;
};
