import LoaderComputing from '@assets/images/loader-computing.svg?react';
import MicrosoftEntraIdIcon from '@assets/images/microsoft-entra-id-icon.svg?react';
import { type KeyValueDescriptionOptionType, ComboBox, KeyValueDescriptionOption } from '@components/ComboBox';
import { type OrganizationUser, Role } from '@components/Team';
import { type ModalProps, Alert, Button, Checkbox, Input, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, ModalSize, Select, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	type MicrosoftEntraIdOptionsType,
	useMicrosoftEntraIdGetOptionsQuery,
	useMicrosoftEntraIdGroupsQuery,
	useMicrosoftEntraIdSaveOptionsMutation,
	useMicrosoftEntraIdSimulateGroupSyncMutation
} from '@state/queries/microsoft-entra-id';
import { StepsCircle } from '@ui/Steps';
import UserAvatar from '@ui/UserAvatar';
import set from 'lodash.set';
import { type FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';

export const MicrosoftEntraIdConfigurationManager: FC<Omit<ModalProps, 'isOpen'>> = ({ ...modalProps }) => {
	// Modal state
	const [isOpen, setIsOpen] = useState(true);
	const [step, setStep] = useState(1);

	const { data: options } = useMicrosoftEntraIdGetOptionsQuery();

	// Import state
	const [groups, setGroups] = useState<Partial<{ id: string; role: Role }>[]>([{ id: undefined, role: undefined }]);
	const [emailAttribute, setEmailAttribute] = useState('userPrincipalName');
	const [firstNameAttribute, setFirstNameAttribute] = useState('givenName');
	const [lastNameAttribute, setLastNameAttribute] = useState('surname');
	const [jobTitleAttribute, setJobTitleAttribute] = useState('jobTitle');
	const [isSuspendUserIfNotFound, setIsSuspendUserIfNotFound] = useState(false);

	const defaultAttributeValues = useRef<string[]>();

	const { data: microsoftEntraIdGroups = [], isLoading: isLoadingMicrosoftEntraIdGroups, isSuccess: isGroupsLoaded } = useMicrosoftEntraIdGroupsQuery();
	const { mutateAsync: simulate, isLoading: isSimulating, isSuccess: isSimulated, data: simulation } = useMicrosoftEntraIdSimulateGroupSyncMutation();
	const { mutateAsync: save } = useMicrosoftEntraIdSaveOptionsMutation();

	const roleOptions: KeyValueDescriptionOptionType<Role>[] = [
		{
			value: Role.admin,
			label: <FormattedMessage id="role" values={{ role: Role.admin }} />,
			description: <FormattedMessage id="role-description" values={{ role: Role.admin }} />
		},
		{
			value: Role.regular,
			label: <FormattedMessage id="role" values={{ role: Role.regular }} />,
			description: <FormattedMessage id="role-description" values={{ role: Role.regular }} />
		},
		{
			value: Role.limited,
			label: <FormattedMessage id="role" values={{ role: Role.limited }} />,
			description: <FormattedMessage id="role-description" values={{ role: Role.limited }} />
		}
	];

	const nextable = useMemo(() => {
		if (step === 1 && isGroupsLoaded) {
			return groups[0]?.id !== undefined && groups[0].role !== undefined && groups.every(group => !!group.id && !!group.role);
		}

		if (step === 2) {
			return emailAttribute !== '' && firstNameAttribute !== '' && lastNameAttribute !== '';
		}

		if (step === 3) {
			return true;
		}

		if (step === 4) {
			return isSimulated;
		}

		return false;
	}, [step, isGroupsLoaded, groups, emailAttribute, firstNameAttribute, lastNameAttribute, isSimulated]);

	const resetAttributeValues = () => {
		setEmailAttribute(defaultAttributeValues.current?.[0] ?? '');
		setFirstNameAttribute(defaultAttributeValues.current?.[1] ?? '');
		setLastNameAttribute(defaultAttributeValues.current?.[2] ?? '');
		setJobTitleAttribute(defaultAttributeValues.current?.[3] ?? '');
	};

	useEffect(() => {
		async function execute() {
			if (step === 4) {
				await simulate({
					groups: groups as MicrosoftEntraIdOptionsType['groups'],
					attributes: {
						email: emailAttribute,
						first_name: firstNameAttribute,
						last_name: lastNameAttribute,
						title: jobTitleAttribute
					},
					options: {
						suspend_if_not_found: isSuspendUserIfNotFound
					}
				});
			}
			if (step === 5) {
				try {
					await save({
						enabled: true,
						groups: groups as MicrosoftEntraIdOptionsType['groups'],
						attributes: {
							email: emailAttribute,
							first_name: firstNameAttribute,
							last_name: lastNameAttribute,
							title: jobTitleAttribute
						},
						options: {
							suspend_if_not_found: isSuspendUserIfNotFound
						}
					});
				} catch (error) {}
			}
		}
		execute();
	}, [step, simulate, save, emailAttribute, firstNameAttribute, lastNameAttribute, isSuspendUserIfNotFound, groups, jobTitleAttribute]);

	const saveAndRun = async () => {
		try {
			await save({
				enabled: true,
				groups: groups as MicrosoftEntraIdOptionsType['groups'],
				attributes: {
					email: emailAttribute,
					first_name: firstNameAttribute,
					last_name: lastNameAttribute,
					title: jobTitleAttribute
				},
				options: {
					suspend_if_not_found: isSuspendUserIfNotFound
				}
			});
			setIsOpen(false);
			toast.success('Microsoft Entra ID configuration saved successfully');
		} catch (error) {}
	};

	useEffect(() => {
		if (!options) {
			return;
		}

		if (options?.groups) {
			setGroups(options.groups ?? [{ id: undefined, role: undefined }]);
		}

		if (options?.attributes) {
			setEmailAttribute(options.attributes.email ?? 'userPrincipalName');
			setFirstNameAttribute(options.attributes.first_name ?? 'givenName');
			setLastNameAttribute(options.attributes.last_name ?? 'surname');
			setJobTitleAttribute(options.attributes.title ?? '');
		} else {
			resetAttributeValues();
		}

		if (options.options) {
			setIsSuspendUserIfNotFound(options.options?.suspend_if_not_found ?? false);
		}
	}, [options]);

	useEffect(() => {
		defaultAttributeValues.current = [emailAttribute, firstNameAttribute, lastNameAttribute, jobTitleAttribute];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Modal isOpen={isOpen} size={ModalSize.Large} closeable={true} {...modalProps}>
			<ModalHeaderOnlyTitle>
				<MicrosoftEntraIdIcon className="size-5" />
				<span>
					<FormattedMessage id="microsoft_entra_id.configure" />
				</span>
			</ModalHeaderOnlyTitle>
			<StepsCircle
				size="sm"
				className="w-full pt-4 pb-2 mt-6 bg-gray-100"
				current={step}
				labels={[
					<FormattedMessage id="microsoft_entra_id.groups_and_roles" />,
					<FormattedMessage id="microsoft_entra_id.attributes" />,
					<FormattedMessage id="microsoft_entra_id.options" />,
					<FormattedMessage id="microsoft_entra_id.simulation" />
				]}
			/>
			<ModalBody>
				{step === 1 && (
					<>
						<div className="prose-sm prose max-w-none">
							<p>
								<FormattedMessage id="microsoft_entra_id.roles_description" />
							</p>
						</div>

						<div className="grid items-center grid-cols-10 gap-3 my-6">
							{/* Headings */}
							<div className="col-span-4">
								<p className="text-sm font-semibold text-gray-500">
									<FormattedMessage id="microsoft_entra_id.group" />
								</p>
							</div>
							<div></div>
							<div className="col-span-4">
								<p className="text-sm font-semibold text-gray-500">
									<FormattedMessage id="microsoft_entra_id.convoflo_role" />
								</p>
							</div>
							{groups.map((group, index) => (
								<Fragment key={group.id}>
									<div className="col-span-4">
										<Select block value={group.id} onChange={e => setGroups([...set(groups, `[${index}].id`, e.target.value)])} disabled={isLoadingMicrosoftEntraIdGroups}>
											<option value="">
												<FormattedMessage id="select_group" />
											</option>
											{microsoftEntraIdGroups?.map(_group => (
												<option
													key={_group.id}
													value={_group.id}
													selected={group.id === _group.id}
													disabled={groups
														.map(g => g.id)
														.filter(g => g !== group.id)
														.includes(_group.id)}>
													{_group.name}
												</option>
											))}
										</Select>
									</div>
									<div className="flex justify-center place-items-center">
										<FontAwesomeIcon icon="arrow-right" className="text-gray-300" size="sm" />
									</div>
									<div className="col-span-4">
										<ComboBox
											isDisabled={!group.id}
											isSearchable={false}
											value={roleOptions.find(opt => opt.value === group.role)}
											components={{
												Option: KeyValueDescriptionOption
											}}
											getOptionValue={({ value }) => value}
											options={roleOptions}
											onChange={role => setGroups([...set(groups, `[${index}].role`, role?.value ?? Role.regular)])}
										/>
									</div>
									{groups.length > 1 && (
										<div className="justify-self-center">
											<Button
												icon="trash"
												circle
												variant={Variant.dark}
												intent={Intent.tertiary}
												size={Size.sm}
												onClick={() => setGroups(groups.filter((_, i) => i !== index))}
											/>
										</div>
									)}
								</Fragment>
							))}
							<div className="col-span-10">
								<button type="button" className="text-xs underline text-theme-primary" onClick={() => setGroups([...groups, { id: undefined, role: undefined }])}>
									<FormattedMessage id="add_another_group" />
								</button>
							</div>
						</div>
					</>
				)}

				{step === 2 && (
					<>
						<div className="grid items-center grid-cols-9 gap-3">
							{/* Headings */}
							<div className="col-span-4">
								<p className="text-sm font-semibold text-gray-500">
									<FormattedMessage id="microsoft_entra_id.mseid_attribute" />
								</p>
							</div>
							<div></div>
							<div className="col-span-4">
								<p className="text-sm font-semibold text-gray-500">
									<FormattedMessage id="microsoft_entra_id.convoflo_attribute" />
								</p>
							</div>

							{/* Email attribute */}
							<div className="col-span-4">
								<Input required block size={Size.sm} type="text" value={emailAttribute} onChange={e => setEmailAttribute(e.target.value)} />
							</div>
							<div className="flex justify-center place-items-center">
								<FontAwesomeIcon icon="arrow-right" className="text-gray-300" size="sm" />
							</div>
							<div className="col-span-4">
								<p className="text-sm font-bold">
									<FormattedMessage id="email_login" />
								</p>
							</div>

							{/* First name attribute */}
							<div className="col-span-4">
								<Input required block size={Size.sm} type="text" value={firstNameAttribute} onChange={e => setFirstNameAttribute(e.target.value)} />
							</div>
							<div className="flex justify-center place-items-center">
								<FontAwesomeIcon icon="arrow-right" className="text-gray-300" size="sm" />
							</div>
							<div className="col-span-4">
								<p className="text-sm font-bold">
									<FormattedMessage id="signup.first_name" />
								</p>
							</div>

							{/* Last name attribute */}
							<div className="col-span-4">
								<Input required block size={Size.sm} type="text" value={lastNameAttribute} onChange={e => setLastNameAttribute(e.target.value)} />
							</div>
							<div className="flex justify-center place-items-center">
								<FontAwesomeIcon icon="arrow-right" className="text-gray-300" size="sm" />
							</div>
							<div className="col-span-4">
								<p className="text-sm font-bold">
									<FormattedMessage id="signup.last_name" />
								</p>
							</div>

							{/* Title attribute */}
							<div className="col-span-4">
								<Input required block size={Size.sm} type="text" value={jobTitleAttribute} onChange={e => setJobTitleAttribute(e.target.value)} />
							</div>
							<div className="flex justify-center place-items-center">
								<FontAwesomeIcon icon="arrow-right" className="text-gray-300" size="sm" />
							</div>
							<div className="col-span-4">
								<p className="text-sm font-bold">
									<FormattedMessage id="account-profile.title" />
								</p>
							</div>

							<div className="col-span-9">
								<button type="button" className="text-xs underline text-theme-primary" onClick={resetAttributeValues}>
									<FormattedMessage id="use_default_settings" />
								</button>
							</div>
						</div>
					</>
				)}

				{step === 3 && (
					<>
						<div className="prose-sm prose max-w-none">
							<h3>
								<FormattedMessage id="microsoft_entra_id.deprovisioning" />
							</h3>
							<p>
								<FormattedMessage id="microsoft_entra_id.deprovisioning_description" />
							</p>
							<Checkbox checked={isSuspendUserIfNotFound} onChange={e => setIsSuspendUserIfNotFound(e.target.checked)}>
								<FormattedMessage id="microsoft_entra_id.suspend_user" />
							</Checkbox>
						</div>
					</>
				)}

				{step === 4 && (
					<>
						{isSimulating && (
							<div className="grid my-12 place-content-center">
								<div className="flex flex-col items-center gap-3">
									<LoaderComputing className="text-theme-primary size-16" />
									<p className="text-lg font-bold text-center">
										<FormattedMessage id="simulating" />
									</p>
								</div>
							</div>
						)}
						{simulation && (
							<>
								<Alert variant={Variant.info} className="mb-6">
									<FormattedMessage id="microsoft_entra_id.simulation_description" />
								</Alert>
								<div className="flex flex-col gap-3">
									{simulation.added.length > 0 && (
										<details className="p-3 bg-gray-100 border rounded" open>
											<summary className="cursor-pointer">
												<FormattedMessage
													id="microsoft_entra_id.n_user_added"
													values={{ n: simulation.added.length, strong: msg => <strong className="font-bold">{msg}</strong> }}
												/>
											</summary>
											<div className="flex flex-col gap-3 mt-6">
												{simulation.added.map(user => (
													<ResultRow status="added" user={user} />
												))}
											</div>
										</details>
									)}
									{simulation.changed.length > 0 && (
										<details className="p-3 bg-gray-100 border rounded" open>
											<summary className="cursor-pointer">
												<FormattedMessage
													id="microsoft_entra_id.n_user_updated"
													values={{ n: simulation.changed.length, strong: msg => <strong className="font-bold">{msg}</strong> }}
												/>
											</summary>
											<div className="flex flex-col gap-3 mt-6">
												{simulation.changed.map(user => (
													<ResultRow status="changed" user={user} />
												))}
											</div>
										</details>
									)}
									{simulation.suspended.length > 0 && (
										<details className="p-3 bg-gray-100 border rounded" open>
											<summary className="cursor-pointer">
												<FormattedMessage
													id="microsoft_entra_id.n_user_suspended"
													values={{ n: simulation.suspended.length, strong: msg => <strong className="font-bold">{msg}</strong> }}
												/>
											</summary>
											<div className="flex flex-col gap-3 mt-6">
												{simulation.suspended.map(user => (
													<ResultRow status="suspended" user={user} />
												))}
											</div>
										</details>
									)}
									{simulation.skipped.length > 0 && (
										<details className="p-3 bg-gray-100 border rounded">
											<summary className="cursor-pointer">
												<FormattedMessage
													id="microsoft_entra_id.n_user_skipped"
													values={{ n: simulation.skipped.length, strong: msg => <strong className="font-bold">{msg}</strong> }}
												/>
											</summary>
											<div className="flex flex-col gap-3 mt-6">
												{simulation.skipped.map(user => (
													<ResultRow status="skipped" user={user} />
												))}
											</div>
										</details>
									)}
								</div>
							</>
						)}
					</>
				)}
			</ModalBody>
			<ModalFooter className="justify-between">
				{step > 1 ? (
					<Button variant={Variant.light} intent={Intent.secondary} onClick={() => (step > 1 ? setStep(step => step - 1) : setIsOpen(false))} type="button" iconStart="arrow-left">
						<FormattedMessage id="back" />
					</Button>
				) : (
					<span />
				)}
				{step < 4 && (
					<Button disabled={!nextable} variant={Variant.primary} intent={Intent.primary} onClick={() => setStep(step => step + 1)} type="button" iconEnd="arrow-right">
						{step === 3 ? <FormattedMessage id="simulate" /> : <FormattedMessage id="continue" />}
					</Button>
				)}
				{step === 4 && (
					<>
						<Button variant={Variant.primary} intent={Intent.primary} onClick={saveAndRun} type="button" disabled={!isSimulated} icon="save">
							<FormattedMessage id="save_and_run" />
						</Button>
					</>
				)}
			</ModalFooter>
		</Modal>
	);
};

type ResultRowProps = {
	user: OrganizationUser;
	status: 'added' | 'changed' | 'suspended' | 'skipped';
};

const ResultRow: FC<ResultRowProps> = ({ user, status }) => {
	return (
		<div className="flex items-center gap-3 px-4">
			<div>
				<UserAvatar user={user} size={Size.sm} />
			</div>
			<div>
				<h5 className="text-sm font-bold">{user.Name}</h5>
				<p className="text-xs text-gray-500">{user.Email}</p>
			</div>
		</div>
	);
};
