import { S3 } from '@aws-sdk/client-s3';
import { type UploadService } from '@components/Upload';
import useClient from '@hooks/useClient';
import File from '@models/File';
import Folder from '@models/Folder';
import type { Fileable } from '@types';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

type AuthToken = {
	accessKeyId: string;
	bucket: string;
	endpoint: string;
	region: string;
	secretAccessKey: string;
	sessionToken: string;
};

type useUploadServiceOptions = {
	start?: boolean;
};

export const useUploadService = (fileable: Fileable | null, { start = false }: useUploadServiceOptions) => {
	const { client } = useClient();

	const [uploadService, setUploadService] = useState<UploadService>();

	const { data: authToken = null } = useQuery(
		['auth_token', fileable?.getKey()],
		async () => {
			if (fileable instanceof Folder) {
				return await client.url(`folders/${fileable.getKey()}/upload/token`).get().json<AuthToken>();
			} else if (fileable instanceof File) {
				return await client.url(`files/${fileable.getKey()}/upload/token`).get().json<AuthToken>();
			} else {
				return await client.url(`files/upload/token`).get().json<AuthToken>();
			}
		},
		{
			enabled: start,
			staleTime: 1000 * 60 * 60 * 4 // 4 hours
		}
	);

	const setupS3 = async (authToken: AuthToken) => {
		let endpoint = undefined,
			signatureHost: string | undefined,
			bucketEndpoint = false;

		if (authToken.endpoint) {
			endpoint = `https://${authToken.endpoint}`;
			signatureHost = `${authToken.bucket}.s3.amazonaws.com`;
			bucketEndpoint = true;
		}

		const s3 = new S3({
			credentials: {
				accessKeyId: authToken.accessKeyId,
				secretAccessKey: authToken.secretAccessKey,
				sessionToken: authToken.sessionToken
			},
			region: authToken.region,
			bucketEndpoint,
			endpoint: endpoint
		});

		s3.middlewareStack.add(
			next => args => {
				if (signatureHost) {
					(args.request as any).headers['host'] = signatureHost;
				}
				return next(args);
			},
			{
				step: 'build'
			}
		);

		setUploadService({
			service: s3,
			bucket: authToken.endpoint || authToken.bucket
		});
	};

	// Setup S3 when we have the auth token
	useEffect(() => {
		if (authToken === null) {
			return;
		}
		setupS3(authToken);
	}, [authToken]);

	return uploadService;
};
