import { type ComposerPlugin, DialogPaymentRequestOptions, MessageFormContext, type PaymentRequestMethod, type PaymentRequestOptions } from '@components/Message';
import { UploadFile } from '@components/Upload';
import { Button, type ButtonProps, Intent, Modal, ModalBody, ModalFooter, ModalHeader, type ModalProps, ModalSize, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import Collaborator from '@models/Collaborator';
import type File from '@models/File';
import classNames from 'classnames';
import { type FC, useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { defaultCurrency } from '../../../../constants';
import isEmpty from 'lodash.isempty';

export const usePluginPaymentRequests: ComposerPlugin<{ visible?: boolean }> = ({ disabled }, { enabled = false, visible = true }, data) => {
	// Plugin data
	const [paymentMethod, setPaymentMethod] = useState<PaymentRequestMethod>(null);
	const [paymentAmount, setPaymentAmount] = useState(0);
	const [paymentUsers, setPaymentUsers] = useState<Collaborator[]>([]);
	const [paywalledFiles, setPaywalledFiles] = useState<UploadFile[]>([]);

	const [showRequestPaymentPreview, setShowRequestPaymentPreview] = useState(false);
	const [showRequestPaymentOptions, setShowRequestPaymentOptions] = useState(false);

	const listenForDraft = useRef(true);

	const onReset = async () => {
		setPaymentAmount(0);
		setPaymentUsers([]);
		setPaywalledFiles([]);
		setPaymentMethod(null);
		listenForDraft.current = false;
	};

	const onPaymentRequestOptionsChanged = ({ amount, users, files, method }: PaymentRequestOptions) => {
		setPaymentAmount(amount);
		setPaymentUsers(users);
		setPaymentMethod(method);
		setPaywalledFiles(files);
	};

	// From draft
	useEffect(() => {
		if (!listenForDraft.current) {
			return;
		}

		if (isEmpty(data?.['payment-requests'])) {
			return;
		}

		setPaymentMethod(data?.['payment-requests']?.method);
		setPaymentAmount(data['payment-requests'].amount);
		setPaymentUsers(data['payment-requests'].users);
		setPaywalledFiles(data['payment-requests'].files.map((file: UploadFile) => UploadFile.hydrate(file)));

		listenForDraft.current = false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.['payment-requests']]);

	const isActive = paymentMethod !== null && paymentAmount > 0;

	return {
		id: 'payment-requests',
		data: {
			method: paymentMethod,
			amount: paymentAmount,
			users: paymentUsers,
			files: paywalledFiles
		},
		postData: data => {
			if ((!enabled && paymentAmount === 0) || paymentUsers.length === 0) {
				return {};
			}

			const attachments: UploadFile[] = data?.['files']?.files ?? [];
			const _paywalledFiles: File[] = attachments
				.filter(file => file.model !== null)
				.filter((file: UploadFile) => paywalledFiles.some(f => file.id === f.id))
				.map((file: UploadFile) => file.model!);

			return {
				payment_amount: paymentAmount * 100,
				payment_method: paymentMethod,
				payment_users: paymentUsers.map(user => user.ID),
				paywalled_files: _paywalledFiles.map(file => file.Id)
			};
		},
		onReset,
		isPostable: paymentMethod === null || isActive,
		isDirty: !!paymentAmount || paymentMethod !== null,
		components: {
			button: enabled ? (
				<>
					{visible && (
						<>
							<AudienceAwareComposerPluginButton
								size={Size.sm}
								onClick={() => (enabled ? setShowRequestPaymentOptions(true) : setShowRequestPaymentPreview(true))}
								disabled={disabled}
								variant={Variant.light}
								intent={Intent.secondary}
								type="button"
								icon={paymentAmount > 0 ? undefined : 'dollar-sign'}
								className={classNames('hidden sm:inline-flex relative rounded-full gap-2', {
									'text-theme-primary font-semibold hover:text-theme-primary !pl-1': isActive
								})}>
								{paymentAmount > 0 && (
									<span className="px-1.5 py-0.5 !font-normal !text-xs text-white rounded-full bg-theme-primary mr-1">
										<FormattedNumber value={paymentAmount} currency={defaultCurrency} style="currency" currencyDisplay="symbol" />
									</span>
								)}
								<span>
									<FormattedMessage id="payment-requests.action" />
								</span>
							</AudienceAwareComposerPluginButton>
							<AudienceAwareComposerPluginButton
								size={Size.sm}
								onClick={() => (enabled ? setShowRequestPaymentOptions(true) : setShowRequestPaymentPreview(true))}
								disabled={disabled}
								variant={isActive ? Variant.primary : Variant.light}
								intent={isActive ? Intent.primary : Intent.secondary}
								type="button"
								circle
								className="sm:hidden"
								icon={'dollar-sign'}
							/>
						</>
					)}
					{showRequestPaymentOptions && (
						<DialogPaymentRequestOptions
							method={paymentMethod}
							amount={paymentAmount * 100}
							users={paymentUsers}
							files={paywalledFiles}
							onChange={onPaymentRequestOptionsChanged}
							onAfterClose={() => setShowRequestPaymentOptions(false)}
						/>
					)}
					{showRequestPaymentPreview && <DialogPaymentModuleTeaser onAfterClose={() => setShowRequestPaymentPreview(false)} />}
				</>
			) : null
		}
	};
};

export const AudienceAwareComposerPluginButton: FC<ButtonProps> = ({ disabled: _disabled, ...buttonProps }) => {
	const { account } = useAccount();
	const { pluginData } = useContext(MessageFormContext);

	const disabled = (pluginData.audience?.users?.length && pluginData.audience.users[0].ID === account?.ID) || _disabled;

	return <Button disabled={disabled} {...buttonProps} />;
};

const DialogPaymentModuleTeaser: FC<Omit<ModalProps, 'isOpen'>> = ({ ...modalProps }) => {
	const history = useHistory();

	const [isOpen, setIsOpen] = useState(true);

	return (
		<Modal size={ModalSize.Medium} isOpen={isOpen} {...modalProps}>
			<ModalHeader className="flex items-start justify-between">
				<span className="px-3 py-1 text-sm font-semibold text-white uppercase bg-red-600 rounded">
					<FormattedMessage id="new" />
				</span>
				<Button circle icon="times" variant={Variant.light} intent={Intent.tertiary} onClick={() => setIsOpen(false)} size={Size.sm} className="bg-gray-100" />
			</ModalHeader>
			<ModalBody className="prose max-w-none prose-h2:mb-6 prose-h2:mt-4">
				<div className="flex justify-center">
					<FontAwesomeIcon icon="dollar-sign" className="text-yellow-600" mask="circle" transform="shrink-9" size="3x" fixedWidth={false} />
				</div>
				<h2 className="text-center">
					<FormattedMessage id="payment-requests.promo.title" />
				</h2>
				<p className="italic text-gray-500">
					<FormattedMessage id="payment-requests.promo.subheading" />
				</p>
				<p>
					<FormattedMessage id="payment-requests.promo.big_cta" values={{ strong: msg => <strong>{msg}</strong> }} />
				</p>
			</ModalBody>
			<ModalFooter>
				<Button onClick={() => history.push('/dashboard/payments')} variant={Variant.primary} iconEnd="arrow-right">
					<FormattedMessage id="comments.learn-more" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
