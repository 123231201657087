import { DialogNoName } from '@components/Account';
import { DialogOrganizationProfile } from '@components/DialogOrganizationProfile';
import FullScreenLoading from '@components/FullScreenLoading';
import { AccountContext } from '@contexts/AccountContext';
import AppContext from '@contexts/AppContext';
import useClient from '@hooks/useClient';
import useUrlSearch from '@hooks/useUrlSearch';
import GenericErrorPage from '@pages/Errors/Generic';
import { useAccountQuery, useLogoutMutation } from '@state/queries/account';
import { useMarkNotificationAsReadMutation } from '@state/queries/notifications';
import Container from '@ui/Layout/Container';
import { isUuid } from '@utils/StringUtils';
import { type FC, type PropsWithChildren, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

export const AccountProvider: FC<PropsWithChildren> = ({ children }) => {
	const { authToken } = useClient();
	const queryClient = useQueryClient();
	const location = useLocation();
	const { setLocale } = useContext(AppContext);
	const { _nid: notificationId = null, _niid: notificationItemId = null, locale: localeFromUrl, notif_id: notificationIdCompat = null, source } = useUrlSearch();
	const history = useHistory();

	const { data: account, isLoading, error } = useAccountQuery();
	const { mutateAsync: _logout } = useLogoutMutation();
	const { mutateAsync: markNotificationAsRead } = useMarkNotificationAsReadMutation();

	// Update the locale when the account is updated
	useEffect(() => {
		if (localeFromUrl) {
			setLocale(localeFromUrl);
		} else if (account?.Locale) {
			setLocale(account.Locale);
		}
	}, [setLocale, account?.Locale, localeFromUrl]);

	const loggedIn = !!account;

	useEffect(() => {
		if (!loggedIn) return;

		if (notificationIdCompat || isUuid(notificationId || '')) {
			markNotificationAsRead({ uuids: [notificationIdCompat || notificationId || ''], source });
		} else if (notificationItemId) {
			markNotificationAsRead({ notificationItemIds: [notificationItemId], source });
		} else if (notificationId) {
			markNotificationAsRead({ notificationIds: [notificationId], source });
		}
	}, [markNotificationAsRead, notificationId, notificationItemId, notificationIdCompat, loggedIn, source]);

	// When we lose the authToken, set the account as null
	useEffect(() => {
		if (authToken === null) {
			queryClient.removeQueries(['account']);
		}
	}, [authToken, queryClient]);

	const logout = async (next?: Location) => {
		try {
			await _logout();
			history.push('/login', { next });
			toast.success(<FormattedMessage id="logout_success" />);
		} catch {
			// TODO: Show error message to user
		}
	};

	useEffect(() => {
		if (account?.ID) {
			Sentry.setUser({ id: account.ID });
		}

		return () => {
			Sentry.setUser(null);
		};
	}, [account?.ID]);

	if (isLoading) {
		return <FullScreenLoading />;
	}

	if (error && error.status !== 503) {
		return (
			<Container layout="nothing">
				<GenericErrorPage />
			</Container>
		);
	}

	return (
		<AccountContext.Provider value={{ account: account ?? null, logout }}>
			{children}
			<DialogNoName />
			{account?.isAdmin() &&
				account?.hasFullAccess() &&
				account?.hasVerifiedEmail() &&
				(account?.business.Profile?.EmployeeCount === null || account?.business.Profile?.Type === null) &&
				!location.pathname.startsWith('/onboarding') && <DialogOrganizationProfile />}
		</AccountContext.Provider>
	);
};
