import type { EnvironmentType } from '@types';
import { useQueryClient } from 'react-query';
import { useLocalStorage } from './useLocalStorage';

export const useEnvironment = () => {
	const queryClient = useQueryClient();
	const [environmentLocalStorage] = useLocalStorage<EnvironmentType>('environment');

	return queryClient.getQueryData<EnvironmentType>(['environment']) ?? environmentLocalStorage ?? null;
};
