import Icon from '@assets/images/Icon.svg?react';
import AppContext from '@contexts/AppContext';
import { Size } from '@convoflo/ui';
import { useAccount } from '@hooks/useAccount';
import UserAvatar from '@ui/UserAvatar';
import { type FC, type PropsWithChildren, type ReactNode, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type ErrorLayoutProps = PropsWithChildren<{
	intended?: Location;
	header?: boolean;
	icon?: ReactNode;
}>;

const ErrorLayout: FC<ErrorLayoutProps> = ({ intended, icon, header = true, children }) => {
	const { account, logout } = useAccount();
	const { formatMessage } = useIntl();
	const { setPageTitle: setTitle } = useContext(AppContext);

	useEffect(() => {
		setTitle(formatMessage({ id: 'errors.page_not_found' }));
	}, [setTitle, formatMessage]);

	return (
		<div className="relative flex flex-col h-screen">
			{header && account !== null && (
				<div className="flex items-stretch justify-between p-4 space-x-4 bg-gray-200 sm:justify-end">
					<div className="flex items-center justify-end">
						<div className="hidden mr-4 sm:inline-block">
							<UserAvatar user={account} size={Size.md} />
						</div>
						<div>
							<p className="text-xs text-gray-500">
								<FormattedMessage id="logged_in_as" />
							</p>
							<h6 className="text-lg font-semibold">{account.Name}</h6>
							<p className="text-sm text-gray-600">{account.Email}</p>
						</div>
					</div>
					<div className="flex items-center justify-center border-gray-300 sm:pl-4 sm:ml-4 sm:border-l-2">
						<p className="small d-sm-none">
							<button onClick={() => logout(intended)} className="text-theme-primary hover:underline focus:outline-none">
								<FormattedMessage id="change_account" />
							</button>
						</p>
					</div>
				</div>
			)}

			<div className="flex items-center justify-center h-full">
				<div className="flex flex-col items-center justify-center max-w-3xl px-4 space-y-8 divide-gray-200 sm:flex-row sm:space-x-8 sm:space-y-0 sm:divide-x-2">
					{icon !== undefined ? icon : <Icon className="hidden w-24 text-theme-inverse sm:block" />}
					<div className="flex-1 py-2 pl-6 sm:py-0">{children}</div>
				</div>
			</div>
		</div>
	);
};

export default ErrorLayout;
