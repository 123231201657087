import MFAGraphic from '@assets/images/MFA.svg?react';
import DialogAccountPassword from '@components/DialogAccountPassword';
import { GuidedTour } from '@components/GuidedTour';
import PhoneNumberInput from '@components/PhoneNumberInput';
import { Alert, Badge, Button, Intent, Label, Size, ValidationField, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import {
	useMultiFactorAuthRemoveMutation,
	useMultiFactorAuthSendSmsCodeMutation,
	useMultiFactorAuthSetupMutation,
	useMultiFactorAuthValidateAppMutation,
	useMultiFactorAuthValidateSmsMutation
} from '@state/queries/multi-factor-auth';
import Card from '@ui/Card';
import { CopyToClipboard } from '@ui/CopyToClipboard';
import MultiFactorInput from '@ui/MultiFactorInput';
import Steps, { Step } from '@ui/Steps';
import classNames from 'classnames';
import download from 'downloadjs';
import QRCode from 'qrcode.react';
import { type FC, type FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Prompt } from 'react-router-dom';
import { defaultCountry } from '../../constants';

type MultiFactorValidatorPayload = {
	type: 'sms' | 'app';
	label?: string;
	secret_key?: string;
	url?: string;
};

export const MultiFactorAuth = () => {
	const { account } = useAccount();

	const [step2payload, setStep2Payload] = useState<MultiFactorValidatorPayload>();
	const [selectedType, setSelectedType] = useState<'app' | 'sms'>();
	const [recoveryCodes, setRecoveryCodes] = useState<string[]>([]);

	const { mutateAsync: setup, isLoading: isSaving } = useMultiFactorAuthSetupMutation();

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		if (!selectedType) {
			return;
		}
		try {
			setStep2Payload(await setup(selectedType));
		} catch {
			// TODO: Show error message to user
		}
	};

	const onAppValidated = (recoveryCodes: string[]) => {
		setRecoveryCodes(recoveryCodes);
		onCanceled();
	};

	const onCanceled = () => {
		setStep2Payload(undefined);
		setSelectedType(undefined);
	};

	return (
		<Card id="step-2fa">
			{step2payload?.type === 'app' && (
				<MultiFactorAppValidator secret={step2payload.secret_key || ''} label={step2payload.label} url={step2payload.url} onValidated={onAppValidated} onCancel={onCanceled} />
			)}

			{step2payload?.type === 'sms' && <MultiFactorSmsValidator onValidated={onCanceled} onCancel={onCanceled} />}

			{account!.TwoFactorType !== null && <MultiFactorEnabled onDeleted={onCanceled} recoveryCodes={recoveryCodes} />}

			{!step2payload && account!.TwoFactorType === null && (
				<>
					<div className="flex items-start mb-8">
						<div className="prose">
							<p>
								<FormattedMessage id="account-2fa.description" />
							</p>
						</div>
						<div className="hidden ml-6 sm:block">
							<MFAGraphic className="w-48" />
						</div>
					</div>

					<div id="step-2fa-c1">
						<p className="mb-4 text-lg font-medium">
							<FormattedMessage id="account-2fa.select_auth_method" />
						</p>
						<form method="post" onSubmit={onSubmit}>
							<div className="mb-4">
								<button
									type="button"
									className={`block w-full p-4 rounded-t-md border-t border-l border-r border-b text-left focus:outline-none ${
										selectedType === 'app' ? 'bg-theme-primary-lightest/25 border-theme-primary-lightest' : 'border-gray-300'
									}`}
									onClick={() => setSelectedType('app')}>
									<div className="flex items-start">
										<div className="mt-1.5 mr-4">
											<FontAwesomeIcon icon="key" fixedWidth size="2x" className="text-gray-400" />
										</div>
										<div className="flex-1">
											<Badge variant={Variant.warning}>
												<FormattedMessage id="account-2fa.auth_app_title_recommended" />
											</Badge>
											<h5 className="font-semibold">
												<FormattedMessage id="account-2fa.auth_app_title" />
											</h5>
											<p className="text-sm text-gray-600">
												<FormattedMessage id="account-2fa.auth_app_description" />
											</p>
										</div>
									</div>
								</button>
								<button
									type="button"
									className={classNames('block w-full p-4 rounded-b-md border-b border-l border-r text-left focus:outline-none', {
										'bg-theme-primary-lightest/25 border-theme-primary-lightest': selectedType === 'sms',
										'border-gray-300': selectedType !== 'sms'
									})}
									onClick={() => setSelectedType('sms')}>
									<div className="flex items-start">
										<div className="mt-1.5 mr-4">
											<FontAwesomeIcon icon="mobile" fixedWidth size="2x" className="text-gray-400" />
										</div>
										<div className="flex-1">
											<h5 className="font-semibold">
												<FormattedMessage id="account-2fa.auth_sms_title" />
											</h5>
											<p className="text-sm text-gray-600">
												<FormattedMessage id="account-2fa.auth_sms_description" />
											</p>
										</div>
									</div>
								</button>
							</div>

							<Button type="submit" variant={Variant.primary} disabled={isSaving || !selectedType}>
								{isSaving && <FontAwesomeIcon icon="spinner" pulse className="mr-2" />}
								<FormattedMessage id="account-2fa.continue" />
							</Button>
						</form>
					</div>
				</>
			)}
			<GuidedTour name="securityC1" enabled={account?.hasFullAccess()} />
			<GuidedTour name="security" enabled={!account?.hasFullAccess()} />
		</Card>
	);
};

type MultiFactorEnabledProps = {
	onDeleted?: () => void;
	recoveryCodes?: string[];
};

const MultiFactorEnabled: FC<MultiFactorEnabledProps> = ({ onDeleted = () => undefined, recoveryCodes = [] }) => {
	const { account } = useAccount();
	const { validation } = useClient();

	const [recoveryCodesCopied, setRecoveryCodesCopied] = useState(false);

	const { mutateAsync: _delete, isLoading: isSubmitting } = useMultiFactorAuthRemoveMutation();

	const downloadRecoveryCodes = () => {
		download(recoveryCodes.join('\n'), 'convoflo-recovery-codes.txt', 'text/plain');
	};

	const onAccountPasswordSubmitted = async (accountPassword: string) => {
		try {
			await _delete({ accountPassword });
			onDeleted();
		} catch {
			// TODO: add error handling via toast
		}
	};

	return (
		<>
			<div className="flex">
				<div className="mr-3">
					{account!.TwoFactorType === 'app' && <FontAwesomeIcon icon="key" mask="circle" transform="shrink-8" size="3x" className="text-theme-primary" />}
					{account!.TwoFactorType === 'sms' && <FontAwesomeIcon icon="mobile-android" mask="circle" transform="shrink-8" size="3x" className="text-theme-primary" />}
				</div>
				<div className="flex-1">
					{account!.TwoFactorType === 'app' && (
						<>
							<h5 className="mb-1 font-semibold leading-snug text-green-600">
								<FormattedMessage
									id="account-2fa.enabled_using_app"
									values={{
										enabled: (
											<span className="text-success">
												<FormattedMessage id="account-2fa.enabled" />
											</span>
										)
									}}
								/>
							</h5>
							<p className="text-sm leading-tight">
								<FormattedMessage id="account-2fa.app-enabled-description" />
							</p>
						</>
					)}

					{account!.TwoFactorType === 'sms' && (
						<>
							<h5 className="mb-1 font-semibold leading-snug text-green-600">
								<FormattedMessage
									id="account-2fa.enabled_using_sms"
									values={{
										enabled: (
											<span className="text-success">
												<FormattedMessage id="account-2fa.enabled" />
											</span>
										)
									}}
								/>
							</h5>
							<p className="text-sm leading-tight">
								<FormattedMessage id="account-2fa.sms-enabled-description" />
							</p>
						</>
					)}

					{recoveryCodes.length > 0 && (
						<Alert variant={Variant.info} className="my-4">
							<p className="mb-4">
								<FormattedMessage id="account-2fa.recovery_intro1" />
							</p>
							<p className="mb-4">
								<FormattedMessage id="account-2fa.recovery_intro2" values={{ strong: msg => <strong>{msg}</strong> }} />
							</p>
							<div className="grid grid-cols-1 mb-4 md:grid-cols-2">
								{recoveryCodes.map(code => (
									<div className="text-center">
										<code>{code}</code>
									</div>
								))}
							</div>
							<footer className="space-x-2">
								<Button size={Size.sm} variant={Variant.light} intent={Intent.secondary} onClick={downloadRecoveryCodes}>
									<FontAwesomeIcon icon="download" className="mr-2" />
									<FormattedMessage id="account-2fa.download" />
								</Button>

								{!recoveryCodesCopied && (
									<CopyToClipboard text={recoveryCodes.join('\n')} onCopy={() => setRecoveryCodesCopied(true)}>
										<Button size={Size.sm} variant={Variant.light} intent={Intent.secondary} type="button">
											<FontAwesomeIcon className="mr-2" icon="clipboard" />
											<FormattedMessage id="account-2fa.copy" />
										</Button>
									</CopyToClipboard>
								)}

								{recoveryCodesCopied && (
									<Button size={Size.sm} variant={Variant.primary} intent={Intent.secondary} type="button" disabled>
										<FontAwesomeIcon className="mr-2" icon="check" />
										<FormattedMessage id="account-2fa.copied" />
									</Button>
								)}
							</footer>
						</Alert>
					)}
				</div>
			</div>
			<div className="h-px mt-6 mb-4 bg-gray-50" />
			<div className="flex justify-end">
				<Button type="submit" variant={Variant.danger} intent={Intent.tertiary} size={Size.xs} disabled={isSubmitting} loading={isSubmitting} onClick={() => _delete({})}>
					<FormattedMessage id="dialog.email-id.deactivate" />
				</Button>
			</div>
			{'account_password' in validation && <DialogAccountPassword onAccountPasswordSubmitted={onAccountPasswordSubmitted} />}
		</>
	);
};

type MultiFactorSmsValidatorProps = {
	onValidated?: () => void;
	onCancel?: () => void;
};

const MultiFactorSmsValidator: FC<MultiFactorSmsValidatorProps> = ({ onValidated = () => undefined, onCancel = () => undefined }) => {
	const { validation } = useClient();
	const { account } = useAccount();
	const { formatMessage } = useIntl();

	const [code, setCode] = useState('');
	const [phone, setPhone] = useState('');
	const [country, setCountry] = useState(account!.Country || defaultCountry);
	const [codeSent, setCodeSent] = useState(false);

	const autoSubmit = useRef(true);

	const { mutateAsync: sendCodeMutation, isLoading: isCodeSending } = useMultiFactorAuthSendSmsCodeMutation();
	const { mutateAsync: validate, isLoading: isSubmitting } = useMultiFactorAuthValidateSmsMutation();

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		try {
			await validate({ code, phone, country });
			onValidated();
		} catch {
			// Will show password dialog
		}
	};

	const onPhoneInputChanged = useCallback(({ phone, country }: { phone: string; country: string }) => {
		setPhone(phone);
		setCountry(country);
	}, []);

	const onAccountPasswordSubmitted = async (accountPassword: string) => {
		try {
			await validate({ code, phone, country, accountPassword });
			onValidated();
		} catch {
			// Show message to user
		}
	};

	const sendCode = async (phone: string, country: string) => {
		try {
			await sendCodeMutation({ phone, country });
			setCodeSent(true);
		} catch {
			// TODO: Show error message to user
		}
	};

	useEffect(() => {
		const _validate = async () => {
			try {
				await validate({ code, phone, country });
				onValidated();
			} catch {
				// Show message to user
			}
		};

		if (code.length === 6 && autoSubmit.current) {
			autoSubmit.current = false;
			_validate();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [code, phone, country, validate]);

	return (
		<>
			<Prompt when message={formatMessage({ id: 'unsaved_changes' })} />
			<form onSubmit={onSubmit}>
				<Steps className="mb-4">
					<Step>
						<label htmlFor="phone-field">
							<FormattedMessage id="account-2fa.auth_sms_step1" />
						</label>
						<div className="form-group">
							<ValidationField fieldName="phone" validation={validation}>
								<PhoneNumberInput
									defaultCountryValue={country}
									defaultPhoneNumberValue={phone}
									onChange={onPhoneInputChanged}
									placeholder={formatMessage({ id: 'account-profile.placeholder-phone' })}
									required
								/>
							</ValidationField>
						</div>
					</Step>
					<Step>
						{codeSent && (
							<Button type="button" intent={Intent.primary} variant={Variant.primary} disabled size={Size.sm}>
								<FontAwesomeIcon icon="check" className="mr-2" />
								<FormattedMessage id="account-2fa.code_sent" />
							</Button>
						)}

						{!codeSent && isCodeSending && (
							<Button type="button" intent={Intent.primary} variant={Variant.primary} size={Size.sm}>
								<FontAwesomeIcon icon="spinner" className="mr-2" pulse />
								<FormattedMessage id="account-2fa.sending_code" />
							</Button>
						)}

						{!codeSent && !isCodeSending && (
							<Button type="button" onClick={() => sendCode(phone, country)} disabled={phone.length === 0} intent={Intent.primary} variant={Variant.primary} size={Size.sm}>
								<FontAwesomeIcon icon="arrow-alt-up" mask="mobile" transform="shrink-8 up-1" size="lg" className="mr-2" />
								<FormattedMessage id="account-2fa.auth_sms_step2" />
							</Button>
						)}
					</Step>
					<Step>
						<label>
							<FormattedMessage id="account-2fa.enter_6_digit_sms" />
						</label>
						<div className="mb-4">
							<ValidationField fieldName="code" validation={validation}>
								<MultiFactorInput onChange={value => setCode(value)} length={6} autoFocus={false} />
							</ValidationField>
						</div>
					</Step>
				</Steps>

				<div className="space-x-3">
					<Button type="submit" variant={Variant.primary} className="ml-12" disabled={isSubmitting} loading={isSubmitting}>
						<FormattedMessage id="account-2fa.enable" />
					</Button>

					<Button type="button" disabled={isSubmitting} onClick={onCancel}>
						<FormattedMessage id="cancel" />
					</Button>
				</div>
			</form>
			{'account_password' in validation && <DialogAccountPassword onAccountPasswordSubmitted={onAccountPasswordSubmitted} />}
		</>
	);
};

type MultiFactorAppValidatorProps = {
	secret: string;
	url?: string | null;
	label?: string | null;
	onValidated?: (recoveryCodes: string[]) => void;
	onCancel?: () => void;
};

const MultiFactorAppValidator: FC<MultiFactorAppValidatorProps> = ({ secret, url = null, label = null, onValidated = () => undefined, onCancel = () => undefined }) => {
	const { validation } = useClient();
	const { formatMessage } = useIntl();

	const [code, setCode] = useState('');
	const [showMoreInstructions, setShowMoreInstructions] = useState(false);

	const autoSubmit = useRef(true);

	const { mutateAsync: validate, isLoading: isSubmitting } = useMultiFactorAuthValidateAppMutation();

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		try {
			const { recovery_codes } = await validate({ secret, code });
			onValidated(recovery_codes);
		} catch {
			// Ignore since it's probably for the account password validation
		}
	};

	const onAccountPasswordSubmitted = async (accountPassword: string) => {
		try {
			const { recovery_codes } = await validate({ secret, code, accountPassword });
			onValidated(recovery_codes);
		} catch {
			// Ignore since it's probably for the account password validation
		}
	};

	useEffect(() => {
		const _validate = async () => {
			try {
				const { recovery_codes } = await validate({ secret, code });
				onValidated(recovery_codes);
			} catch {
				// Ignore since it's probably for the account password validation
			}
		};
		if (code.length === 6 && autoSubmit.current) {
			autoSubmit.current = false;
			_validate();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [secret, code, validate]);

	return (
		<>
			<Prompt when message={formatMessage({ id: 'unsaved_changes' })} />
			<form onSubmit={onSubmit}>
				<Steps>
					<Step>
						<Label>
							<FormattedMessage
								id="account-2fa.auth_app_step1"
								values={{
									link: msg => (
										<a href="https://support.google.com/accounts/answer/1066447" rel="noopener noreferrer" target="_blank">
											{msg}
										</a>
									)
								}}
							/>
						</Label>
					</Step>
					<Step>
						{!showMoreInstructions && !!url && (
							<>
								<Label>
									<FormattedMessage id="account-2fa.auth_app_step2a" />
								</Label>
								<div className="my-4">
									<QRCode value={url} size={256} />
									<button className="text-sm text-theme-primary hover:underline" onClick={() => setShowMoreInstructions(!showMoreInstructions)}>
										<FormattedMessage id="account-2fa.problem_scanning" />
									</button>
								</div>
							</>
						)}
						{(showMoreInstructions || !url) && (
							<>
								<Label>
									<FormattedMessage id="account-2fa.auth_app_step2b" />
								</Label>
								<dl className="my-4">
									{label !== '' && (
										<>
											<dt className="font-semibold">
												<FormattedMessage id="account-2fa.account_name" />
											</dt>
											<dd className="mb-4">{label}</dd>
										</>
									)}
									<dt className="font-semibold">
										<FormattedMessage id="account-2fa.your_key" />
									</dt>
									<dd className="mb-4">
										<code>{secret}</code>
									</dd>
									<dt className="font-semibold">
										<FormattedMessage id="account-2fa.type" />
									</dt>
									<dd className="mb-4">
										<FormattedMessage id="account-2fa.time_based" />
									</dd>
								</dl>
								{url !== null && (
									<button className="text-sm text-theme-primary hover:underline" onClick={() => setShowMoreInstructions(!showMoreInstructions)}>
										<FormattedMessage id="account-2fa.scan_qr" />
									</button>
								)}
							</>
						)}
					</Step>
					<Step>
						<Label>
							<FormattedMessage id="account-2fa.enter_6_digit_app" />
						</Label>
						<ValidationField fieldName="code" validation={validation}>
							<MultiFactorInput onChange={value => setCode(value)} autoFocus={false} />
						</ValidationField>
					</Step>
				</Steps>
				<div className="space-x-3">
					<Button type="submit" variant={Variant.primary} className="ml-12" disabled={isSubmitting} loading={isSubmitting}>
						<FormattedMessage id="account-2fa.enable" />
					</Button>

					<Button type="button" disabled={isSubmitting} onClick={onCancel}>
						<FormattedMessage id="cancel" />
					</Button>
				</div>
			</form>
			{'account_password' in validation && <DialogAccountPassword onAccountPasswordSubmitted={onAccountPasswordSubmitted} />}
		</>
	);
};
