import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateDisplay } from '@components/DateTime';
import type { Invoice } from '@components/Invoices';
import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TableCell } from '@ui/Table';

type InvoiceItemProps = {
	invoice: Invoice;
};

export const InvoiceItem: FC<InvoiceItemProps> = ({ invoice }) => {
	const { locale } = useIntl();

	const formatter = new Intl.NumberFormat(locale, {
		style: 'currency',
		currency: invoice.currency
	});

	return (
		<tr>
			<TableCell className="text-sm">
				<a href={invoice.link} target="_blank" rel="noreferrer" className="text-theme-primary hover:underline">
					<FormattedMessage id="billing-history.invoice_number" values={{ id: invoice.invoice_number }} />
					<FontAwesomeIcon icon="external-link" className="ml-2" />
				</a>
			</TableCell>
			<TableCell className="text-sm">
				<DateDisplay value={invoice.created_at} force defaultFormat="long" />
			</TableCell>
			<TableCell className="text-sm">{invoice.name}</TableCell>
			<TableCell className="text-sm text-right">{invoice.total && formatter.format(invoice.total)}</TableCell>
			<TableCell className="text-right">
				<a href={invoice.link_pdf} target="_blank" rel="noreferrer" className="text-theme-primary hover:underline">
					<FontAwesomeIcon icon="file-pdf" className="text-red-600" size="lg" />
				</a>
			</TableCell>
		</tr>
	);
};
