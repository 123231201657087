import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { useLocation, useHistory } from 'react-router-dom';

import { LabelSelector, type LabelType } from '@components/Labels';
import { Label } from '@convoflo/ui';
import useClient from '@hooks/useClient';

type LabelType = {
	Id: string;
	name: string;
};

interface LabelFilterProps {
	labels: LabelType[];
	setLabels: (labels: LabelType[]) => void;
	operator: 'and' | 'or';
	setOperator: (operator: 'and' | 'or') => void;
}

const LabelFilter = ({ labels, setLabels, operator, setOperator }: LabelFilterProps) => {
	const location = useLocation();
	const history = useHistory();
	const { client } = useClient();
	const [urlSettingsLoaded, setUrlSettingsLoaded] = useState(false);

	// Fetch labels from API
	const { data: orgLabels } = useQuery(
		['labels'],
		async () => {
			let payload = await client.url('organization/properties').get().json<LabelType[]>();
			let labels: LabelType[] = [];

			payload.forEach(parentProp => {
				parentProp.options?.forEach(prop => labels.push(prop));
			});

			return labels;
		},
		{
			refetchOnWindowFocus: false
		}
	);

	// Manage labels
	const manageLabels = (label: LabelType) => {
		let labelList: LabelType[] = [...labels];

		if (labels.find(labelTemp => labelTemp.Id === label.Id)) {
			setLabels(labelList.filter(labelTemp => labelTemp.Id !== label.Id));
		} else {
			labelList.push(label);
			setLabels(labelList);
		}
	};

	// Sync with URL params
	useEffect(() => {
		if (orgLabels) {
			let currLabels: LabelType[] = [];

			for (const [key, value] of new URLSearchParams(location.search)) {
				if (value) {
					switch (key) {
						case 'operator':
							setOperator(value as 'and' | 'or');
							break;
						case 'properties[]':
							let propId = value.replace(',', '');
							let label = orgLabels.find(prop => prop.Id === propId);
							if (label) {
								currLabels.push(label);
							}
							break;
					}
				}
			}
			setLabels(currLabels);
			setUrlSettingsLoaded(true);
		}
	}, [location.search, orgLabels, setOperator]);

	// Update URL on filter change
	useEffect(() => {
		if (urlSettingsLoaded) {
			let baseUrl = `/files?operator=${operator}`;
			history.push(baseUrl);
		}
	}, [labels, operator, history, urlSettingsLoaded]);

	return (
		<div className="mb-2">
			<Label>
				<FormattedMessage id="dialog.filter.properties" />
			</Label>

			<div className="mb-2 mr-2">
				<select
					className="inline-block w-full p-0 pr-8 m-0 mb-2 text-xs text-gray-500 whitespace-normal bg-transparent border-0"
					value={operator}
					onChange={e => setOperator(e.target.value as 'and' | 'or')}>
					<FormattedMessage id="dashboard-labels.and">
						{msg => <option value="and">{msg}</option>}
					</FormattedMessage>
					<FormattedMessage id="dashboard-labels.or">
						{msg => <option value="or">{msg}</option>}
					</FormattedMessage>
				</select>

				<div className="flex flex-wrap gap-1 p-1 bg-white/50 rounded-xl">
					{labels.map(label => (
						<LabelSelector key={label.Id} label={label} onRemove={manageLabels} onChange={manageLabels} editable={false} />
					))}
					<LabelSelector onChange={manageLabels} isDisabled={label => labels.some(_label => _label.Id === label.Id)} />
				</div>
			</div>
		</div>
	);
};

export default LabelFilter;
