import { S3 } from '@aws-sdk/client-s3';

export type FinishFileRequest = {
	version: string;
	folder?: number;
	users?: number[];
};

export class FileSizeError extends Error {
	override name: string = 'FileSizeError';
}

export enum UploadStatus {
	Pending = 'pending',
	Uploading = 'uploading',
	Aborted = 'aborted',
	Processing = 'processing',
	Error = 'error',
	Completed = 'completed'
}

export type PrepareFileResponse = {
	version: string;
	key: string;
};

export type UploadFileMutation = {
	version: string;
	key: string;
};

export type ProcessFileMutation = {
	version: string;
};

export type UploadService = {
	service: S3;
	bucket: string;
};
