import { Title } from '@components/Account';
import { CannedMessageManager } from '@components/CannedMessages';
import AppContext from '@contexts/AppContext';
import { type FC, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const CannedMessagePage: FC = () => {
	const { setPageTitle } = useContext(AppContext);
	const { formatMessage } = useIntl();

	useEffect(() => {
		setPageTitle(formatMessage({ id: 'account.canned_messages' }));
	}, [formatMessage, setPageTitle]);

	return (
		<article>
			<Title>
				<FormattedMessage id="account.canned_messages" />
			</Title>
			<CannedMessageManager />
		</article>
	);
};
