import untypedColors from '@assets/color-palette.json';
import { ComboBox } from '@components/ComboBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ColorType } from '@types';
import classNames from 'classnames';
import 'emoji-picker-element';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { components, type ContainerProps, type ControlProps, type OnChangeValue, type OptionProps, type SingleValueProps, type ValueContainerProps } from 'react-select';

const colors: ColorType[] = untypedColors;

type ColorPickerProps = {
	value?: string;
	onChange?: (color: string | undefined) => void;
};

export const ColorPicker: FC<ColorPickerProps> = ({ value, onChange = () => undefined }) => {
	return (
		<ComboBox
			value={colors.find(color => color.value === value)}
			isSearchable={false}
			components={{
				Control,
				Option,
				SingleValue,
				SelectContainer,
				ValueContainer
			}}
			getOptionValue={({ value }) => value}
			options={colors}
			onChange={(color: OnChangeValue<ColorType, false>) => onChange(color?.value)}
		/>
	);
};

const Option = ({ data, isSelected, ...props }: OptionProps<ColorType, false>) => (
	<div className={`${data.backgroundClassName} !hover:bg-transparent`}>
		<components.Option isSelected={false} data={data} {...props}>
			<div className="flex items-center justify-between">
				<p className={`text-sm ${data.textClassName}`}>
					<FormattedMessage id={`colors.${data.value}`} />
				</p>
				{isSelected && <FontAwesomeIcon icon="check" className={data.textClassName} />}
			</div>
		</components.Option>
	</div>
);

const SingleValue = ({ ...props }: SingleValueProps<ColorType, false>) => (
	<components.SingleValue {...props}>
		<p className={`text-sm ${props.data.textClassName}`}>
			<FormattedMessage id={`colors.${props.data.value}`} />
		</p>
	</components.SingleValue>
);

const Control = ({ isFocused, ...props }: ControlProps<ColorType, false>) => {
	const value = props.getValue()[0];
	const className = classNames('!min-h-0', value?.backgroundClassName ?? 'bg-transparent', value?.borderClassName ?? 'border-gray-600', {
		'outline-none ring-blue-200 ring !border-blue-400': isFocused
	});

	return <components.Control isFocused={isFocused} {...props} className={className} />;
};

const SelectContainer = ({ ...props }: ContainerProps<ColorType, false>) => <components.SelectContainer {...props} className="bg-transparent" />;

const ValueContainer = ({ ...props }: ValueContainerProps<ColorType, false>) => <components.ValueContainer {...props} className="bg-transparent" />;
