import { Tab, type TabListProps } from '@headlessui/react';
import classNames from 'classnames';
import type { FC } from 'react';

type ListProps = Omit<TabListProps<'div'>, 'className'> & {
	className?: string;
	size?: string;
	position?: string;
};

export const List: FC<ListProps> = ({ className, position = 'top', size, ...props }) => {
	return (
		<Tab.List
			className={classNames('flex items-stretch border-gray-300 bg-gray-50', className, {
				'border-b': position === 'top',
				'border-t': position === 'bottom',
				'px-8 space-x-8': size === undefined,
				'px-3 space-x-3': size === 'sm'
			})}
			{...props}
		/>
	);
};
