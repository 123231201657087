import { DEFAULT_TABS_ORDER } from '@components/FileManager';
import type { FileManagerRouteParams } from '@pages/view';
import { useParams } from 'react-router-dom';

export const useCurrentTab = () => {
	// const tabsOrder = account?.Settings.TabsOrder ?? account?.business.Settings.TabsOrder ?? DEFAULT_TABS_ORDER;
	const tabsOrder = DEFAULT_TABS_ORDER;

	const { tab = tabsOrder[0] } = useParams<FileManagerRouteParams>();

	return tab;
};
