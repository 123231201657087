import { tours } from '@components/GuidedTour';
import useClient from '@hooks/useClient';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { type FC, type PropsWithChildren, createContext, useState } from 'react';
import { useMutation } from 'react-query';
import { WretchError } from 'wretch/resolver';

type MarkTourAsFinishedMutation = {
	name: string;
	step: number;
	completed: boolean;
};

export const GuidedTourContext = createContext<
	| {
			enabled: boolean;
			enable: () => void;
			disable: () => void;
			reset: () => void;
			finish: (name: string, lastStep: number) => void;
			current?: string;
			setCurrent: (name: string | undefined) => void;
			isDone: (name: string) => boolean;
	  }
	| undefined
>(undefined);

export const GuidedTourProvider: FC<PropsWithChildren> = ({ children }) => {
	const { client } = useClient();
	const [current, setCurrent] = useState<string>();
	const [enabled, setEnabled] = useLocalStorage<boolean>('tours.active', false);
	const [toursDone, setToursDone] = useLocalStorage<string[]>('tours.finished', []);

	const { mutate: markTourAsFinished } = useMutation<void, WretchError, MarkTourAsFinishedMutation>(
		async ({ name, step, completed }) => await client.url('events/tour-ended').json({ name, completed, step }).post().res()
	);

	const reset = () => {
		setToursDone([]);
	};
	const finish = (name: string, lastStep: number) => {
		const stepsCount = tours[name]?.length ?? 0;
		setToursDone(toursDone.filter(_tour => _tour !== name).concat(name));
		setCurrent(undefined);
		markTourAsFinished({ name, completed: lastStep === stepsCount - 1, step: lastStep });
	};

	const isDone = (name: string) => toursDone.some(_tour => _tour === name);

	return (
		<GuidedTourContext.Provider value={{ enable: () => setEnabled(true), disable: () => setEnabled(false), enabled, reset, finish, current, setCurrent, isDone }}>
			{children}
		</GuidedTourContext.Provider>
	);
};
