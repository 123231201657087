import { format as dateFnsFormat, formatDistanceStrict as dateFnsFormatDistanceStrict, formatDistanceToNow, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { enUS, frCA } from 'date-fns/locale';

export const formatDate = (value: string | Date | null, locale: string | null = null, format: string = 'PPpp') => {
	const parsedValue = value instanceof Date ? value : value !== null ? parseISO(value) : new Date();

	const localeAsObject = locale?.substring(0, 2) === 'fr' ? frCA : enUS;

	return dateFnsFormat(parsedValue, format, { locale: localeAsObject });
};

export const formatDistanceStrict = (seconds: number, locale: string) => {
	const localeAsObject = locale.substring(0, 2) === 'fr' ? frCA : enUS;

	return dateFnsFormatDistanceStrict(0, seconds * 1000, { locale: localeAsObject });
};

export const formatRelativeDate = (value: string, locale: string | null) => {
	const localeAsObject = locale?.substring(0, 2) === 'fr' ? frCA : enUS;

	return formatDistanceToNow(new Date(value), { locale: localeAsObject, addSuffix: true });
};

export const formatLocalDateToUTC = (value: string | number | Date | null) => {
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	value = value || new Date();
	return zonedTimeToUtc(value, timezone);
};

export const hoursKeyValue = (locale: string | null = null) => {
	return [...Array.from(Array(24))].map((_, index) => ({
		key: `${String(index).padStart(2, '0')}:00:00`,
		value: formatDate(`2021-01-01 ${String(index).padStart(2, '0')}:00:00`, locale, 'p')
	}));
};
