import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import { DateTimeDisplay } from '@components/DateTime';
import { DialogDeleteFileRequest, FileRequestForm, type ScheduledMessageType, MAX_ITEMS_LISTED_IN_SELECTION } from '@components/FileRequest';
import Folder from '@models/Folder';
import User from '@models/User';
import { type FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Card from '@ui/Card';
import { MarkdownContent } from '@ui/MarkdownContent';
import UserAvatar from '@ui/UserAvatar';
import { joinWithObject } from '@utils';

type FileRequestItemProps = {
	folder: Folder;
	reminder: ScheduledMessageType;
	onDuplicate?: (reminder: ScheduledMessageType) => void;
	readonly?: boolean;
};

export const FileRequestItem: FC<FileRequestItemProps> = ({ folder, reminder, onDuplicate = () => undefined, readonly = false, ...props }) => {
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [showEditForm, setShowEditForm] = useState(false);

	return (
		<Card className={reminder.Reminded ? 'opacity-50' : ''}>
			<div className="flex items-center justify-between">
				<h4 className="text-sm font-semibold text-gray-500">
					<FormattedMessage id="file-requests-crud.reminder_on" values={{ date: <DateTimeDisplay value={reminder.remind_at ?? null} force defaultFormat="long" /> }} />
				</h4>
				<span className="space-x-2">
					{!readonly && (
						<>
							<Button size={Size.sm} circle variant={Variant.primary} intent={Intent.secondary} onClick={() => setShowEditForm(true)}>
								<FontAwesomeIcon icon="edit" />
							</Button>
							<Button size={Size.sm} circle variant={Variant.warning} intent={Intent.secondary} onClick={() => onDuplicate(reminder)}>
								<FontAwesomeIcon icon="copy" />
							</Button>
							<Button size={Size.sm} circle variant={Variant.danger} intent={Intent.secondary} onClick={() => setShowDeleteConfirmation(true)}>
								<FontAwesomeIcon icon="trash" />
							</Button>
						</>
					)}
				</span>
			</div>

			{reminder.Body && (
				<blockquote className="my-4">
					<MarkdownContent>{reminder.Body}</MarkdownContent>
				</blockquote>
			)}

			{reminder.users !== undefined && reminder.users.length > 0 && <p className="text-sm">{generateSimpleUsersText(reminder.users)}</p>}
			{showEditForm && <FileRequestForm folder={folder} reminder={reminder} onAfterClose={() => setShowEditForm(false)} />}
			{showDeleteConfirmation && <DialogDeleteFileRequest reminder={reminder} onAfterClose={() => setShowDeleteConfirmation(false)} />}
		</Card>
	);
};

const generateSimpleUsersText = (users: User[]) => {
	let names = users.map(user => (
		<span className="inline-flex items-center font-semibold">
			<UserAvatar user={user} className="mr-2" size={Size.xs} />
			{user.Name}
		</span>
	));

	if (names.length === 0) {
		return null;
	}

	if (names.length === 1) {
		return names[0];
	}

	if (names.length <= MAX_ITEMS_LISTED_IN_SELECTION) {
		return (
			<FormattedMessage
				id="file-requests-crud.list_complete"
				values={{
					1: <>{joinWithObject(names.slice(0, -1), <>, </>)}</>,
					2: names.slice(-1)[0]
				}}
			/>
		);
	}

	return (
		<FormattedMessage
			id="file-requests-crud.list_and_others"
			values={{
				1: <>{joinWithObject(names.slice(0, 3), <>, </>)}</>,
				2: names.length - MAX_ITEMS_LISTED_IN_SELECTION
			}}
		/>
	);
};
