import { DialogPreview, FileableSyncStatus, FileManagerContext } from '@components/FileManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useFileUtils from '@hooks/useFileUtils';
import File from '@models/File';
import type { Fileable } from '@types';
import { Tooltip } from '@ui/Tooltip';
import { type BaseSyntheticEvent, type FC, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

type FileableItemThumbnailProps = {
	fileable: Fileable;
};

export const FileableItemThumbnail: FC<FileableItemThumbnailProps> = ({ fileable }) => {
	const history = useHistory();
	const { download: downloadFile } = useFileUtils();
	const fileManagerContext = useContext(FileManagerContext);

	const [dialogPreviewOpened, setDialogPreviewOpened] = useState(false);

	const preview = async () => {
		if (fileable instanceof File && fileable.isPreviewable()) {
			setDialogPreviewOpened(true);
		} else if (fileable instanceof File && fileable.current.ExternalLink !== null) {
			history.push(fileable.getUrl('view'), { view: fileable });
		} else if (fileable instanceof File && !fileable.isPreviewable()) {
			downloadFile(fileable);
		}
	};

	const navigate = (event: BaseSyntheticEvent) => {
		event.preventDefault();

		if (fileManagerContext?.selection !== undefined) {
			return;
		}

		history.push(fileable.getUrl('files'), { view: fileable });
	};

	return (
		<>
			{dialogPreviewOpened && <DialogPreview file={fileable as File} onAfterClose={() => setDialogPreviewOpened(false)} />}

			{fileable instanceof File && !fileable.pivot!.Viewed && <span className="absolute w-3 h-3 bg-red-600 border-2 border-white rounded-full -top-1 -right-1 md:top-0.5 md:right-0.5" />}

			{fileable.pivot!.Favorite && (
				<div className="absolute -top-1 -left-1">
					<FontAwesomeIcon icon="star" className="text-yellow-400" size="xs" />
				</div>
			)}

			{fileable.syncs && (
				<div className="absolute bottom-0 right-0">
					<span className="text-sm">
						{fileable.syncs.map(sync => (
							<FileableSyncStatus key={sync.Id} fileable={fileable} sync={sync} />
						))}
					</span>
				</div>
			)}

			{fileable instanceof File ? (
				<button onClick={() => preview()} className="w-full h-full focus:outline-none group">
					<FileItemThumbnail item={fileable} />

					{fileable.isPreviewable() || fileable.current.ExternalLink !== null ? (
						<>
							{fileable.current.Thumbnail && <div className="absolute inset-0 z-10 flex w-full mx-auto my-auto duration-150 bg-black rounded opacity-0 group-hover:opacity-60"></div>}
							{!fileable.current.Thumbnail && (
								<div className="absolute inset-0 z-10 flex w-full mx-auto my-auto duration-150 bg-gray-800 rounded-full opacity-0 group-hover:opacity-50"></div>
							)}
							<FontAwesomeIcon icon="eye" className="absolute inset-0 z-10 flex mx-auto my-auto text-xl text-white duration-150 opacity-0 group-hover:opacity-100" />
						</>
					) : (
						<>
							<div className="absolute inset-0 z-10 flex w-full mx-auto my-auto duration-150 bg-gray-800 rounded-full opacity-0 group-hover:opacity-50"></div>
							<FontAwesomeIcon icon="arrow-to-bottom" className="absolute inset-0 z-10 flex mx-auto my-auto text-xl text-white duration-150 opacity-0 group-hover:opacity-100" />
						</>
					)}
				</button>
			) : (
				<button onClick={navigate} className="w-full h-full focus:outline-none group">
					<FileItemThumbnail item={fileable} />
				</button>
			)}
		</>
	);
};

type FileItemThumbnailProps = {
	item: Fileable;
};

const FileItemThumbnail: FC<FileItemThumbnailProps> = ({ item }) => {
	if (item instanceof File) {
		// Bug icon because of virus
		if (item.current.AVStatus === 'infected') {
			return (
				<Tooltip tip={<FormattedMessage id="file-manager.infected" />}>
					<span>
						<FontAwesomeIcon fixedWidth icon="bug" className="text-red-600" size="lg" />
					</span>
				</Tooltip>
			);
		}

		// Thumbnail
		if (item.current.Thumbnail) {
			return (
				<div className="w-full h-full rounded bg-checkered">
					<div className="w-full h-full bg-transparent bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${item.current.Thumbnail})` }} />
				</div>
			);
		}

		// File icon with its type
		return item.icon('!w-6 !sm:w-8 !h-full', true, { fixedWidth: false });
	}

	// Folder or secure space icon
	return item.icon('!w-6 !sm:w-8 !h-full', true, { fixedWidth: false });
};
