import untypedCountries from '@assets/countries.json';
import untypedLocales from '@assets/locales.json';
import DialogAccountPassword from '@components/DialogAccountPassword';
import { GuidedTour } from '@components/GuidedTour';
import { Button, HelperText, Input, InputBlock, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Row, Select, Size, ValidationField, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import useIsDirty from '@hooks/useIsDirty';
import { useAccountAvatarUpdateMutation, useAccountProfileUpdateMutation } from '@state/queries/profile';
import type { ValidationErrors } from '@types';
import Card from '@ui/Card';
import { convertFileToDataUrl } from '@utils/FileReaderUtils';
import { getCroppedImg, getRotatedImage } from '@utils/ImageUtils';
import { Orientation, getOrientation } from 'get-orientation/browser.es5';
import { type FC, type FormEvent, useCallback, useState } from 'react';
import Dropzone from 'react-dropzone';
import Cropper, { type Area } from 'react-easy-crop';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { Prompt } from 'react-router-dom';
import type { WretchError } from 'wretch';

const locales: Record<string, string> = untypedLocales;
const countries: Record<string, string> = untypedCountries;

export const AccountProfile = () => {
	const { account } = useAccount();
	const { formatMessage } = useIntl();
	const { validation } = useClient();

	const [firstName, setFirstName] = useState(account?.FirstName ?? '');
	const [lastName, setLastName] = useState(account?.LastName ?? '');
	const [title, setTitle] = useState(account?.Title ?? '');
	const [phone, setPhone] = useState(account?.PhoneNumber ?? '');
	const [phoneExtension, setPhoneExtension] = useState(account?.PhoneExtension ?? '');
	const [locale, setLocale] = useState(account?.Locale);
	const [country, setCountry] = useState(account?.Country);

	const [avatarEditorOpened, setAvatarEditorOpened] = useState(false);
	const [emailChangeOpened, setEmailChangeOpened] = useState(false);

	const { isDirty, setIsDirty } = useIsDirty([firstName, lastName, title, locale, country, phone, phoneExtension]);

	const { mutateAsync: save, isLoading: isSaving } = useAccountProfileUpdateMutation();
	const { mutateAsync: saveAvatar, isLoading: isAvatarSaving } = useAccountAvatarUpdateMutation();

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		try {
			await save({ firstName, lastName, title, country, phone, phoneExtension, locale });
			setIsDirty(false);
			toast.success(<FormattedMessage id="account-profile.profile-saved" />);
		} catch (error) {
			toast.error(<FormattedMessage id="account-profile.error-saving-profile" />);
		}
	};

	const onAvatarSelected = async (blob: Blob) => {
		try {
			await saveAvatar(blob);
			toast.success(<FormattedMessage id="account-profile.avatar-changed" />);
		} catch {
			toast.success(<FormattedMessage id="account-profile.error-saving-avatar" />);
		}
	};
	return (
		<>
			<Prompt when={isDirty} message={formatMessage({ id: 'unsaved_changes' })} />
			<Card onSubmit={onSubmit} className="!overflow-visible mt-24">
				<Row id="step-img-email">
					<Row className="-mt-24">
						<div className="mx-auto overflow-hidden border-4 border-white rounded-full w-36 h-36">
							{!isAvatarSaving ? (
								<button
									type="button"
									onClick={() => setAvatarEditorOpened(true)}
									className="flex w-full h-full bg-center bg-cover"
									style={{ backgroundImage: `url(${account?.Avatar})` }}>
									<div className="self-end w-full px-3 pt-3 pb-6 text-xs text-center text-white from-black/50 to-black/25 bg-gradient-to-t ">
										<FormattedMessage id="account-profile.update" />
									</div>
								</button>
							) : (
								<div className="w-full h-full text-white bg-center bg-cover" style={{ backgroundImage: `url(${account?.Avatar})` }}>
									<div className="flex items-center justify-center w-full h-full bg-black bg-opacity-50">
										<FontAwesomeIcon icon="spinner" size="2x" className="hidden lg:inline-block" pulse />
										<FontAwesomeIcon icon="spinner" size="4x" className="lg:hidden" pulse />
									</div>
								</div>
							)}
						</div>
					</Row>
					<Row>
						<Label>
							<FormattedMessage id="login.email" />
						</Label>
						<Input block readOnly={true} value={account?.Email} className="text-gray-500 bg-gray-100" />
						<HelperText>
							<button type="button" className="text-theme-primary hover:underline hover:cursor-pointer" onClick={() => setEmailChangeOpened(true)}>
								<FormattedMessage id="user-manager.email_change_request" />
							</button>
						</HelperText>
					</Row>
				</Row>

				<Row id="step-name-title">
					<Row className="items-center justify-between lg:flex">
						<div className="flex-1">
							<Label htmlFor="first-name-field">
								<FormattedMessage id="name" />
							</Label>
							<div className="grid grid-cols-2 gap-x-3" id="step-names">
								<ValidationField fieldName="first_name" validation={validation}>
									<InputBlock
										onChange={e => setFirstName(e.target.value)}
										value={firstName}
										id="first-name-field"
										type="text"
										placeholder={formatMessage({ id: 'account-profile.placeholder-first_name' })}
										required
									/>
								</ValidationField>
								<ValidationField fieldName="last_name" validation={validation}>
									<InputBlock
										onChange={e => setLastName(e.target.value)}
										value={lastName}
										type="text"
										placeholder={formatMessage({ id: 'account-profile.placeholder-last_name' })}
										required
									/>
								</ValidationField>
							</div>
						</div>
					</Row>

					{account?.hasFullAccess() && (
						<Row>
							<Label htmlFor="title-field">
								<FormattedMessage id="account-profile.title" />
							</Label>
							<ValidationField fieldName="title" validation={validation}>
								<Input block onChange={e => setTitle(e.target.value)} value={title} placeholder={formatMessage({ id: 'account-profile.placeholder-title' })} type="text" />
							</ValidationField>
							<HelperText>
								<FormattedMessage id="account-profile.title-hint" />
							</HelperText>
						</Row>
					)}
				</Row>

				<Row>
					<Label htmlFor="country-field">
						<FormattedMessage id="account-profile.country" />
					</Label>
					<ValidationField fieldName="country" validation={validation}>
						<Select className="w-full" id="country-field" value={country} onChange={e => setCountry(e.target.value)}>
							{Object.keys(countries).map(c => (
								<option key={c} value={c}>
									{countries[c]}
								</option>
							))}
						</Select>
					</ValidationField>
				</Row>

				<Row>
					<Label htmlFor="phone-field">
						<FormattedMessage id="account-profile.phone" />
					</Label>
					<div className="flex">
						<div className="flex-1">
							<ValidationField fieldName="phone" validation={validation}>
								<InputBlock
									onChange={e => setPhone(e.target.value)}
									value={phone}
									id="phone-field"
									placeholder={formatMessage({ id: 'account-profile.placeholder-phone' })}
									type="tel"
								/>
							</ValidationField>
						</div>
						<div className="w-32 ml-4">
							<ValidationField fieldName="phone_extension" validation={validation}>
								<InputBlock
									onChange={e => setPhoneExtension(e.target.value)}
									value={phoneExtension}
									placeholder={formatMessage({ id: 'account-profile.placeholder-extension' })}
									type="number"
								/>
							</ValidationField>
						</div>
					</div>
					<HelperText>
						<FormattedMessage id="account-profile.phone-hint" />
					</HelperText>
				</Row>

				<Row>
					<Label htmlFor="locale-field">
						<FormattedMessage id="account-profile.language" />
					</Label>
					<ValidationField fieldName="locale" validation={validation}>
						<Select id="locale-field" value={locale} onChange={e => setLocale(e.target.value)}>
							{Object.keys(locales).map(locale => (
								<option key={locale} value={locale}>
									{locales[locale]}
								</option>
							))}
						</Select>
					</ValidationField>
				</Row>

				<Row className="mt-8">
					<Button type="submit" variant={Variant.primary} disabled={isSaving || !isDirty} loading={isSaving} icon="save">
						<FormattedMessage id="save" />
					</Button>
				</Row>
			</Card>

			{avatarEditorOpened && <ModalAvatar onAfterClose={() => setAvatarEditorOpened(false)} onAvatarSelected={onAvatarSelected} />}
			{emailChangeOpened && <DialogEmailChange onAfterClose={() => setEmailChangeOpened(false)} />}

			<GuidedTour name="profile" enabled={!account?.hasFullAccess()} />
			<GuidedTour name="profileC1" enabled={account?.hasFullAccess()} />
		</>
	);
};

type ModalAvatarProps = Omit<ModalProps, 'isOpen'> & {
	onAvatarSelected: (blob: Blob) => void;
};

const ModalAvatar: FC<ModalAvatarProps> = ({ onAvatarSelected: onAvatarChanged = () => undefined, ...modalProps }) => {
	const [picture, setPicture] = useState<string | null>(null);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [rotation, setRotation] = useState(0);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
	const [isOpen, setIsOpen] = useState(true);

	const onDrop = useCallback(async (file: File[]) => {
		let p = (await convertFileToDataUrl(file[0])) as string;
		let rotation = 0;

		try {
			const orientation = await getOrientation(file[0]);
			if (orientation === Orientation.BOTTOM_RIGHT) {
				rotation = 180;
			} else if (orientation === Orientation.RIGHT_TOP) {
				rotation = 90;
			} else if (orientation === Orientation.LEFT_BOTTOM) {
				rotation = -90;
			}
		} catch {}

		setPicture(await getRotatedImage(p, rotation));
	}, []);

	const reset = () => {
		setPicture(null);
	};

	const onCropComplete = useCallback((_croppedArea: Area, croppedAreaPixels: Area) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	const select = useCallback(async () => {
		if (picture === null || croppedAreaPixels === null) {
			return;
		}

		try {
			const croppedImage = await getCroppedImg(picture, croppedAreaPixels, rotation);
			if (croppedImage === null) {
				return;
			}
			onAvatarChanged(croppedImage);
			setIsOpen(false);
		} catch (e) {
			console.error(e);
		}
	}, [croppedAreaPixels, rotation, onAvatarChanged, picture]);

	const rotate = () => {
		setRotation((rotation + 90) % 360);
	};

	return (
		<Modal isOpen={isOpen} {...modalProps}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="avatar-editor.choose_avatar" />
			</ModalHeaderOnlyTitle>

			<ModalBody>
				{picture !== null && (
					<>
						<div className="relative" style={{ height: 384 }}>
							<Cropper image={picture} rotation={rotation} crop={crop} zoom={zoom} aspect={1} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} />
						</div>

						<div className="flex items-center mt-3 space-x-4">
							<Button size={Size.sm} type="button" intent={Intent.secondary} onClick={rotate}>
								<FontAwesomeIcon icon="redo" className="mr-2" />
								<FormattedMessage id="avatar-editor.rotate" />
							</Button>
							<FontAwesomeIcon icon="user-circle" size="xs" />
							<input type="range" min={1} max={3} onChange={event => setZoom(parseInt(event.target.value))} step="0.01" value={zoom} className="flex-1 block w-full custom-range" />
							<FontAwesomeIcon icon="user-circle" size="2x" />
						</div>
					</>
				)}

				{picture === null && (
					<Dropzone onDrop={onDrop} multiple={false} accept={{ 'image/png': ['.png'], 'image/jpeg': ['.jpeg', 'jpg'] }} noKeyboard>
						{({ getRootProps, getInputProps }) => (
							<>
								<div
									{...getRootProps({
										className:
											'h-64 cursor-pointer border-4 border-dashed border-gray-600 rounded flex justify-center flex-col text-center items-center bg-gray-100 p-4 text-gray-600'
									})}>
									<FontAwesomeIcon icon="upload" size="3x" className="mb-3 text-gray-600" />
									<p>
										<em>
											<FormattedMessage id="avatar-editor.drag_n_drop" />
										</em>
									</p>
								</div>
								<input {...getInputProps()} />
							</>
						)}
					</Dropzone>
				)}
			</ModalBody>

			<ModalFooter className="justify-between">
				<div className="space-x-4">
					{picture !== null && (
						<Button type="button" variant={Variant.primary} onClick={select}>
							<FormattedMessage id="avatar-editor.select" />
						</Button>
					)}
					<Button type="button" variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)}>
						<FormattedMessage id="cancel" />
					</Button>
				</div>
				{picture !== null && (
					<Button size={Size.sm} variant={Variant.light} intent={Intent.tertiary} type="button" onClick={reset}>
						<FormattedMessage id="avatar-editor.choose_new_picture" />
					</Button>
				)}
			</ModalFooter>
		</Modal>
	);
};

type StartEmailChangeProps = Omit<ModalProps, 'isOpen'>;

type SendRequestMutation = {
	account_password?: string;
};

export const DialogEmailChange: FC<StartEmailChangeProps> = ({ ...modalProps }) => {
	const { client } = useClient();

	const [isOpen, setIsOpen] = useState(true);
	const [validation, setValidation] = useState<ValidationErrors>({});

	const { mutate: _sendRequest, isLoading: isDeleting } = useMutation<undefined, WretchError, SendRequestMutation>(
		async ({ account_password }) => await client.url('account/email').json({ account_password }).post().res(),
		{
			onMutate: () => {
				setValidation({});
			},
			onError: error => {
				error.status === 422 && setValidation(error.json.errors);
			},
			onSuccess: () => {
				setIsOpen(false);
				toast.success(<FormattedMessage id="start-email-change.toast" />);
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		_sendRequest({});
	};

	const onAccountPasswordSubmitted = (accountPassword: string) => {
		_sendRequest({ account_password: accountPassword });
	};

	return (
		<>
			<Modal isOpen={isOpen} onSubmit={onSubmit} {...modalProps}>
				<ModalHeaderOnlyTitle>
					<FormattedMessage id="start-email-change.modal_title" />
				</ModalHeaderOnlyTitle>
				<ModalBody>
					<p>
						<FormattedMessage id="start-email-change.modal_body" />
					</p>
				</ModalBody>
				<ModalFooter>
					<Button disabled={isDeleting} type="submit" className="mr-2" variant={Variant.primary}>
						<FormattedMessage id="start-email-change.modal_btn" />
					</Button>
					<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
						<FormattedMessage id="cancel" />
					</Button>
				</ModalFooter>
			</Modal>
			{'account_password' in validation && <DialogAccountPassword onAccountPasswordSubmitted={onAccountPasswordSubmitted} />}
		</>
	);
};
