import { WidgetsContext } from '@pages/Home';
import { useContext } from 'react';

export const useWidgets = () => {
	const context = useContext(WidgetsContext);

	if (context === undefined) {
		throw new Error('useWidgets() must be used within a WidgetsContext');
	}

	return context;
};
