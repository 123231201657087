import untypedCountries from '@assets/countries.json';
import FullScreenLoading from '@components/FullScreenLoading';
import type { OrganizationInvite } from '@components/Team';
import AppContext from '@contexts/AppContext';
import { Button, Checkbox, HelperText, Input, InputBlock, Label, PasswordInputBlock, Row, Select, Size, ValidationField, Variant } from '@convoflo/ui';
import { usePasswordStrengthCheck } from '@hooks/use-password-strength-check';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { useLocalStorage } from '@hooks/useLocalStorage';
import useUrlSearch from '@hooks/useUrlSearch';
import ErrorLayout from '@ui/Layout/Error';
import { OnboardingLayout } from '@ui/Layout/OnboardingLayout';
import ProgressBar from '@ui/ProgressBar';
import { type FormEvent, useContext, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import type { WretchError } from 'wretch';
import { defaultCountry, trialDays } from '../constants';

const countries: Record<string, string> = untypedCountries;

type BaseSignUpMutation = {
	firstName: string;
	lastName: string;
	password: string;
	country: string;
	agreement: boolean;
	phoneNumber: string;
	partner?: string;
};

type SignUpByTrialMutation = BaseSignUpMutation & {
	email: string;
	invitationToken?: never;
};

type SignUpByInvitationMutation = BaseSignUpMutation & {
	email?: never;
	invitationToken: string;
};

type SignUpResponse = {
	token: string;
};

export const SignUpPage = () => {
	const { first_name: defaultFirstName = '', last_name: defaultLastName = '', email: defaultEmail = '', buy: buyNow, partner = '', inviteUrl } = useUrlSearch();
	const { locale, formatMessage } = useIntl();
	const { account } = useAccount();
	const { client, validation, setAuthToken } = useClient();
	const history = useHistory();
	const { setPageTitle: setTitle, setLogo } = useContext(AppContext);

	const { executeRecaptcha } = useGoogleReCaptcha();

	const [firstName, setFirstName] = useState(defaultFirstName);
	const [lastName, setLastName] = useState(defaultLastName);
	const [email, setEmail] = useState(defaultEmail);
	const [password, setPassword] = useState('');
	const [country, setCountry] = useState<string | null>(defaultCountry);
	const [agreement, setAgreement] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [phoneNumberExtension, setPhoneNumberExtension] = useState('');
	const [referralId] = useLocalStorage('idRef', undefined);
	const [, setShowTours] = useLocalStorage<boolean>('tours.active', false);

	const passwordStrength = usePasswordStrengthCheck(password);

	const {
		data: invitationData,
		isLoading: isVerifyingInvitation,
		isError: isInvitationVerificationError
	} = useQuery(
		['invitation', inviteUrl],
		async () =>
			await client
				.url(inviteUrl, true)
				.catcher(403, () => undefined)
				.get()
				.json<OrganizationInvite>(),
		{
			enabled: !!inviteUrl,
			staleTime: Infinity
		}
	);

	const { mutate: signup, isLoading: isSigningUp } = useMutation<SignUpResponse, WretchError, SignUpByTrialMutation | SignUpByInvitationMutation>(
		async ({ firstName, lastName, email, password, country, agreement, phoneNumber, partner = '', invitationToken }) => {
			const token = await executeRecaptcha!();

			const data = {
				locale,
				'g-recaptcha-response': token,
				first_name: firstName,
				last_name: lastName,
				email,
				password,
				country,
				terms: +agreement,
				phone: phoneNumber,
				phone_extension: phoneNumberExtension,
				referred_by: referralId,
				partner
			};

			if (invitationToken) {
				return await client.url(`signup/invitation/${invitationToken}`).post(data).json();
			}

			return await client.url('signup').post(data).json();
		},
		{
			onError: console.error,
			onSuccess: ({ token }) => {
				setAuthToken(token);
				setShowTours(true);
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		if (!country || !executeRecaptcha) {
			return;
		}
		if (invitationData) {
			signup({ firstName, lastName, password, country, agreement, phoneNumber, partner, invitationToken: invitationData.Token });
		} else {
			signup({ firstName, lastName, email, password, country, agreement, phoneNumber, partner });
		}
	};

	useEffect(() => {
		if (account) {
			history.replace('/onboarding');
		}
	}, [account, history]);

	useEffect(() => {
		setTitle(formatMessage({ id: 'signup.title' }, { n: trialDays }));
	}, [setTitle, formatMessage]);

	useEffect(() => {
		if (invitationData) {
			setLogo(invitationData.business.LogoDark);
		}
	}, [invitationData, setLogo]);

	if (isVerifyingInvitation) {
		return <FullScreenLoading />;
	}

	if (isInvitationVerificationError) {
		return <InvitationError />;
	}

	return (
		<OnboardingLayout
			sidebarCenterContents={
				invitationData ? (
					<h1 className="mb-6 text-4xl font-bold tracking-tight text-white">
						<FormattedMessage id="join.join_business" values={{ business: invitationData.business.Name }} />
					</h1>
				) : (
					<>
						<h1 className="mb-6 text-4xl font-bold tracking-tight text-white">{buyNow ? <FormattedMessage id="signup.subtitle_buynow" /> : <FormattedMessage id="signup.subtitle" />}</h1>
						{!buyNow && (
							<>
								<p className="text-xl text-white">
									<FormattedMessage id="signup.title" values={{ n: trialDays }} />
								</p>
								<p className="text-sm text-white/50">
									<FormattedMessage id="signup.no_credit_card_required" />
								</p>
							</>
						)}
					</>
				)
			}
			sidebarBottomContents={
				<div className="container flex flex-wrap items-center justify-center gap-6 mx-auto mt-8 md:flex-col md:gap-12">
					<img
						style={{ width: 144 }}
						className="max-w-full"
						loading="lazy"
						src="https://assets.capterra.com/badge/81148a292374affef6f0bd8ba377b623.svg?v=2156454&p=216711"
						alt="Convoflo: 4.7 ⭐ on Capterra"
					/>
					<img width={128} loading="lazy" className="max-w-full" alt="GetApp Category Leaders 2024" src="https://brand-assets.getapp.com/badge/9f35b540-e656-4dbd-b4b5-bdd2b1738e74.png" />
					<img
						width={128}
						loading="lazy"
						className="max-w-full"
						alt="Capterra Best Value App for 2023"
						src="https://capterra.s3.amazonaws.com/assets/images/gdm-badges/CA_Badge_BestValue_2023_FullColor-Positive.png"
					/>
					<img
						width={128}
						loading="lazy"
						className="max-w-full"
						alt="2024 Capterra Shortlist for Portal"
						src="https://brand-assets.capterra.com/badge/b317eaeb-26ea-4529-8d41-e95a2a2ee746.png"
					/>
				</div>
			}>
			<div className="max-w-2xl">
				<header className="mb-8 space-y-2">
					<h1 className="text-3xl font-bold tracking-tight md:text-4xl">
						<FormattedMessage id="signup.create_account" />
					</h1>
					{!invitationData && (
						<p className="text-gray-400">
							<FormattedMessage
								id="signup.already_member"
								values={{
									a: msg => (
										<Link to="login" className="text-theme-primary hover:underline">
											{msg}
										</Link>
									)
								}}
							/>
						</p>
					)}
				</header>
				<form onSubmit={onSubmit}>
					<Row>
						<Label htmlFor="email">
							<FormattedMessage id="signup.email" />
						</Label>
						<ValidationField fieldName="email" validation={validation}>
							<InputBlock id="email" icon="envelope" value={invitationData?.Email ?? email} onChange={e => setEmail(e.target.value)} type="email" disabled={!!invitationData} />
						</ValidationField>
						<HelperText>
							<FormattedMessage id="signup.email_helper" />
						</HelperText>
					</Row>

					<Row>
						<Label htmlFor="password">
							<FormattedMessage id="signup.password" />
						</Label>
						<ValidationField fieldName="password" validation={validation}>
							<PasswordInputBlock className="rounded-b-none" id="password" icon="key" value={password} onChange={e => setPassword(e.target.value)} />
						</ValidationField>
						<ProgressBar max={100} current={passwordStrength ?? 0} reverseColors className="h-3 rounded-t-none" />
						<HelperText>
							<FormattedMessage id="signup.password_helper" />
						</HelperText>
					</Row>
					<Row className="grid grid-cols-1 gap-4 sm:grid-cols-2">
						<div className="flex-1">
							<Label htmlFor="firstName">
								<FormattedMessage id="signup.first_name" />
							</Label>
							<ValidationField fieldName="first_name" validation={validation}>
								<Input icon="user" block id="firstName" value={firstName} onChange={e => setFirstName(e.target.value)} />
							</ValidationField>
						</div>
						<div className="flex-1">
							<Label htmlFor="lastName">
								<FormattedMessage id="signup.last_name" />
							</Label>
							<ValidationField fieldName="last_name" validation={validation}>
								<Input icon="user" block id="lastName" value={lastName} onChange={e => setLastName(e.target.value)} />
							</ValidationField>
						</div>
					</Row>

					<Row>
						<Label htmlFor="country-field">
							<FormattedMessage id="account-profile.country" />
						</Label>
						<ValidationField fieldName="country" validation={validation}>
							<Select className="w-full" id="country-field" value={country ?? defaultCountry} onChange={e => setCountry(e.target.value)}>
								{Object.keys(countries).map(c => (
									<option key={c} value={c}>
										{countries[c]}
									</option>
								))}
							</Select>
						</ValidationField>
					</Row>

					<Row>
						<Label htmlFor="phone">
							<FormattedMessage id="signup.country_phone" />
							<small className="ml-2 text-gray-400">
								<FormattedMessage id="comments.optional" />
							</small>
						</Label>
						<ValidationField fieldName="phone" validation={validation}>
							<div className="flex items-stretch">
								<div className="flex-1">
									<Input id="phone" icon="phone" type="tel" block className="rounded-r-none" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
								</div>
								<div>
									<Input
										placeholder={formatMessage({ id: 'signup.phone_extension_placeholder' })}
										type="number"
										block
										className="w-24 border-l-0 rounded-l-none"
										value={phoneNumberExtension}
										onChange={e => setPhoneNumberExtension(e.target.value)}
									/>
								</div>
							</div>
						</ValidationField>
						<HelperText>
							<FormattedMessage id="signup.country_phone_optional" />
						</HelperText>
					</Row>

					<div className="mt-16">
						<Row>
							<Checkbox checked={agreement} onChange={e => setAgreement(e.target.checked)}>
								<p className="text-sm text-gray-700">
									<FormattedMessage
										id="signup.agree"
										values={{
											link1: msg => (
												<a href={`${import.meta.env.VITE_SITE_URL}/${locale}/terms-of-use`} rel="noreferrer" target="_blank" className="text-theme-primary hover:underline">
													{msg}
												</a>
											),
											link2: msg => (
												<a href={`${import.meta.env.VITE_SITE_URL}/${locale}/privacy-policy`} rel="noreferrer" target="_blank" className="text-theme-primary hover:underline">
													{msg}
												</a>
											)
										}}
									/>
								</p>
							</Checkbox>
							{'terms' in validation && (
								<div className="px-2 py-1 mt-2 ml-6 text-white bg-red-600 rounded">
									<p className="text-sm">{validation['terms'][0]}</p>
								</div>
							)}
						</Row>
						<Button variant={Variant.primary} disabled={isSigningUp} type="submit" size={Size.xl}>
							{buyNow === '1' || invitationData ? <FormattedMessage id="signup.continue" /> : <FormattedMessage id="signup.start_your_trial" />}
						</Button>
					</div>
				</form>
			</div>
		</OnboardingLayout>
	);
};

const InvitationError = () => (
	<ErrorLayout>
		<h1 className="mb-2 text-xl font-semibold text-black">
			<FormattedMessage id="invitation.invalid_invite_title" />
		</h1>
		<p className="my-2 leading-tight text-gray-600">
			<FormattedMessage id="invitation.invalid_invite_text" />
		</p>
	</ErrorLayout>
);
