import { InputGroup, type InputGroupProps } from '@convoflo/ui';
import { type ChangeEvent, type FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

const stringValue = (initialValue: string) => {
	let stringValue = initialValue || '0';

	// All characters with no
	// 0.03 = 3
	// 0.34 = 34
	stringValue = stringValue.replace(/\D/g, '');

	//    0 = 0
	//    3 = 0.03
	//   34 = 0.34
	//  234 = 2.34
	// 1234 = 12.34
	let value = Number(stringValue) / 100;

	stringValue = String(value);

	if (value === 0) {
		stringValue = '0.00';
	}

	let [integer, decimal] = stringValue.split('.');

	if (!decimal) {
		decimal = '00';
	} else if (decimal.length < 2) {
		decimal = decimal.padEnd(2, '0');
	}

	return `${integer}.${decimal}`;
};

export const CurrencyInput: FC<InputGroupProps> = ({ value, onChange, className, ...inputGroupProps }) => {
	const { locale } = useIntl();

	const [internalValue, setInternalValue] = useState(stringValue(String(value ?? 0)));

	const onAmountChanged = (e: ChangeEvent<HTMLInputElement>) => {
		setInternalValue(stringValue(e.target.value));
	};

	useEffect(() => {
		onChange(Number(internalValue));
	}, [internalValue]);

	return (
		<InputGroup
			placeholder="0.00"
			pattern="^\d*(\.\d{0,2})?$"
			prepended={<span className="mr-4 text-gray-500">{locale.startsWith('en') ? '$ ' : ''}</span>}
			appended={<span className="ml-4 text-gray-500">{locale.startsWith('fr') ? ' $' : ''}</span>}
			value={internalValue}
			className={className}
			onChange={onAmountChanged}
			{...inputGroupProps}
		/>
	);
};
