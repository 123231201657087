import FullScreenLoading from '@components/FullScreenLoading';
import AppContext from '@contexts/AppContext';
import useClient from '@hooks/useClient';
import { useLocalStorage } from '@hooks/useLocalStorage';
import type { EnvironmentType } from '@types';
import { useContext, useEffect, useLayoutEffect } from 'react';
import ReactGA from 'react-ga4';
import { useQuery } from 'react-query';

export const EnvironmentSwitcher = () => {
	const { client } = useClient();
	const { setName } = useContext(AppContext);

	const [localStorageEnvironment, setLocalStorageEnvironment] = useLocalStorage<EnvironmentType>('environment');

	const { data: environment, isLoading } = useQuery(
		['environment'],
		async () =>
			await client
				.url('env')
				.query(`origin=${encodeURIComponent(window.location.hostname)}`)
				.get()
				.json<EnvironmentType>(),
		{
			placeholderData: localStorageEnvironment,
			onSuccess: setLocalStorageEnvironment,
			onError: () => setLocalStorageEnvironment(null),
			staleTime: Infinity,
			select: data => (Array.isArray(data) ? null : data)
		}
	);

	useEffect(() => {
		if (!environment) {
			return;
		}

		setName(environment.Name);
	}, [environment, setName]);

	useLayoutEffect(() => {
		if (import.meta.env.VITE_GOOGLE_TAG_MANAGER) {
			ReactGA.initialize(import.meta.env.VITE_GOOGLE_TAG_MANAGER);
		}

		if (environment?.Theme?.['primary-color']) {
			document.querySelector('meta[name="theme-color"]')?.setAttribute('content', environment.Theme['primary-color']);
		}
	}, [environment]);

	if (isLoading) {
		return <FullScreenLoading />;
	}

	return null;
};
