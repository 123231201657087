import { UploadStatus } from '@components/Upload';
import Collaborator from '@models/Collaborator';
import File from '@models/File';
import type { Fileable } from '@types';

export class UploadFile {
	id: string = 'uf_' + Math.random().toString(36).substring(2, 9);
	status: UploadStatus = UploadStatus.Pending;
	bytesUploaded: number = 0;
	file?: globalThis.File = undefined;
	scope: Fileable | null = null;
	model: File | null = null;
	users: Collaborator[] = [];

	static fromFile(file: globalThis.File | null, scope: Fileable | null, users: Collaborator[] = []): UploadFile {
		const uploadFile = new UploadFile();
		uploadFile.scope = scope;
		uploadFile.users = users;
		if (file) {
			uploadFile.file = file;
		}
		return uploadFile;
	}

	static hydrate(file: UploadFile): UploadFile {
		const uploadFile = new UploadFile();
		uploadFile.id = file.id;
		uploadFile.scope = file.scope;
		uploadFile.users = file.users;
		if (uploadFile.model) {
			uploadFile.model = new File(uploadFile.model);
		}
		if (file) {
			uploadFile.file = file.file;
		}
		return uploadFile;
	}

	percentage(): number {
		return this.file ? (this.bytesUploaded / this.file.size) * 100 : 0;
	}
}
