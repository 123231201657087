import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type FC, type PropsWithChildren } from 'react';
import { Tooltip } from '@ui/Tooltip';

type ChipProps = PropsWithChildren<{
	id: string | number;
	onRemove: (id: string | number) => void;
	className?: string;
	tip?: string;
}>;

const Chip: FC<ChipProps> = ({ id, onRemove, className = '', tip, children }) => {
	const _children = (
		<span className={`${className} max-w-3xs inline-flex items-center bg-gray-100 px-2 py-1 rounded-full m-1 border-gray-300 border`}>
			<span className="inline-block truncate">{children}</span>
			{onRemove !== undefined && (
				<button className="inline-flex items-center justify-center w-4 h-4 p-1 ml-2 rounded-full hover:bg-gray-300 focus:outline-none focus:ring" onClick={() => onRemove(id)} type="button">
					<FontAwesomeIcon icon="times" fixedWidth />
				</button>
			)}
		</span>
	);

	if (tip) {
		return <Tooltip tip={tip}>{_children}</Tooltip>;
	}

	return _children;
};

export default Chip;
