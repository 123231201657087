import type { AppType } from '@components/ConnectedApps';
import { DialogSharePointSiteConfiguration } from '@components/MicrosoftSharePoint/DialogSharePointSiteConfiguration';
import { Badge, Button, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, ModalSize, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import { useSearchParams } from '@hooks/useSearchParams';
import { useDisconnectAppMutation } from '@state/queries/apps';
import { type FC, type FormEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

type AppItemProps = {
	app: AppType;
};

export const AppItem: FC<AppItemProps> = ({ app }) => {
	const { authToken } = useClient();
	const [searchParams] = useSearchParams();
	const history = useHistory();

	const [showSharePointConfiguration, setShowSharePointConfiguration] = useState(false);
	const [isDisconnectConfirmation, setIsDisconnectConfirmation] = useState(false);

	const { mutateAsync: disconnect, isLoading: isDisconnecting } = useDisconnectAppMutation();

	const redirect = () => {
		window.location.href = `${app.ConnectUrl}?_token=${encodeURIComponent(authToken!)}`;
	};

	useEffect(() => {
		if (searchParams.app === app.Code && searchParams.connected) {
			toast.success(<FormattedMessage id="apps.connected_to" values={{ app: app.Name }} />);
			history.replace('/organization/apps');
		}
	}, [app, history, searchParams.app, searchParams.connected]);

	const onDisconnectSubmit = async (e: FormEvent) => {
		e.preventDefault();
		if (!app.connections?.length) {
			return;
		}

		try {
			await disconnect(app.connections[0]!);
			toast.success(<FormattedMessage id="apps.disconnected_to" values={{ app: app.Name }} />);
		} catch (error) {
			toast.error(<FormattedMessage id="apps.error_disconnection" values={{ app: app.Name }} />);
		}
	};

	return (
		<>
			<Modal size={ModalSize.XSmall} onSubmit={onDisconnectSubmit} isOpen={isDisconnectConfirmation} onAfterClose={() => setIsDisconnectConfirmation(false)}>
				<ModalHeaderOnlyTitle>
					<img src={app.Icon} className="inline mr-2 align-middle size-5" alt="" />
					<FormattedMessage id="apps.disconnect_x" values={{ app: app.Name }} />
				</ModalHeaderOnlyTitle>
				<ModalBody className="prose-sm prose">
					<p>
						<FormattedMessage id="apps.disconnect_confirmation" />
					</p>
				</ModalBody>
				<ModalFooter>
					<Button variant={Variant.danger}>
						<FormattedMessage id="disconnect" />
					</Button>
					<Button variant={Variant.light} intent={Intent.secondary} type="button" onClick={() => setIsDisconnectConfirmation(false)}>
						<FormattedMessage id="cancel" />
					</Button>
				</ModalFooter>
			</Modal>
			<div className="relative flex flex-col p-4 overflow-hidden transition-colors bg-white border border-gray-200 rounded-lg hover:border-gray-300">
				{/* {isConnecting && (
					<div className="inset-0 absolute z-[1] bg-white/75 grid place-items-center backdrop-blur-sm">
						<div className="flex flex-col items-center gap-3">
							<FontAwesomeIcon icon="spinner" pulse className="fill-gray-600" size="2x" />
							<p className="text-sm font-bold text-gray-600">
								<FormattedMessage id="connecting..." />
							</p>
						</div>
					</div>
				)} */}
				{isDisconnecting && (
					<div className="inset-0 absolute z-[1] bg-white/75 grid place-items-center backdrop-blur-sm">
						<div className="flex flex-col items-center gap-3">
							<FontAwesomeIcon icon="spinner" pulse className="fill-gray-600" size="2x" />
							<p className="text-sm font-bold text-gray-600">
								<FormattedMessage id="disconnecting..." />
							</p>
						</div>
					</div>
				)}
				<div className="flex-1">
					<div className="flex items-start justify-between mb-4">
						<img src={app.Icon} className="size-10" alt="" />
						{app.Type !== null && (
							<Badge variant={Variant.dark}>
								<FormattedMessage id="apps.type" values={{ type: app.Type }} />
							</Badge>
						)}
					</div>
					<h3 className="font-semibold">{app.Name}</h3>
					<p className="mt-0.5 text-xs leading-none text-gray-400">
						<FormattedMessage
							id="apps.by_company"
							values={{
								company: app.Company,
								link: msg => (
									<a href={app.Website} target="_blank" rel="noreferrer" className="underline text-theme-primary">
										{msg}
									</a>
								)
							}}
						/>
					</p>
					<p className="mt-3 text-xs">{app.Description}</p>
					{app.connections?.length === 1 && app.connections[0]?.owner['@type'] === 'Business' && (
						<p className="inline-flex items-center gap-1.5 my-2 text-xs text-blue-500 bg-blue-100 py-0.5 rounded px-1.5">
							<FontAwesomeIcon icon="building" />
							<FormattedMessage id="apps.available_business" values={{ name: app.connections[0]?.owner.Name }} />
						</p>
					)}
					{app.connections && app.connections.length > 0 && app.connections[0]?.owner['@type'] === 'User' && (
						<p className="inline-flex items-center gap-1.5 my-2 text-xs text-blue-500 bg-blue-100 py-0.5 rounded px-1.5">
							<FontAwesomeIcon icon="users" />
							<FormattedMessage id="apps.available_user" values={{ name: app.connections[0]?.owner.Name }} />
						</p>
					)}
				</div>
				<footer className="flex items-center gap-3 mt-4">
					{!!app.IsConnected ? (
						<>
							{app.Code === 'AZURE_ACTIVE_DIRECTORY' && (
								<Button size={Size.sm} variant={Variant.dark} intent={Intent.secondary} onClick={() => history.push('/organization/users')} icon="cog">
									<FormattedMessage id="configure" />
								</Button>
							)}
							{app.Code === 'SHAREPOINT' && (
								<>
									<Button size={Size.sm} variant={Variant.dark} intent={Intent.secondary} onClick={() => setShowSharePointConfiguration(true)} icon="cog">
										<FormattedMessage id="configure" />
									</Button>
									{showSharePointConfiguration && <DialogSharePointSiteConfiguration connection={app.connections?.[0]!} onAfterClose={() => setShowSharePointConfiguration(false)} />}
								</>
							)}
							<Button size={Size.sm} variant={Variant.danger} intent={Intent.secondary} onClick={() => setIsDisconnectConfirmation(true)}>
								<FormattedMessage id="disconnect" />
							</Button>
						</>
					) : (
						<Button size={Size.sm} variant={Variant.primary} intent={Intent.primary} onClick={() => redirect()}>
							<FormattedMessage id="connect" />
						</Button>
					)}
				</footer>
			</div>
		</>
	);
};
