import { NotificationOptionItem, type UserNotificationOptions } from '@components/Notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import Card from '@ui/Card';
import { type FC, type PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';

export const NotificationManager: FC = () => {
	const { client } = useClient();

	const { data } = useQuery(['notification_options'], async () => await client.url('account/notifications/options').get().json<UserNotificationOptions>(), {
		refetchOnMount: 'always',
		refetchOnWindowFocus: false
	});

	const getOption = (key: string) => {
		const option = data?.options?.[key];
		if (!option) return null;
		return <NotificationOptionItem key={key} optionKey={key} option={option} options={{ App: option.App, Email: option.Email }} />;
	};

	return (
		<Card size={null}>
			{/* <GlobalNotificationOption /> */}
			{(data?.enabled ?? true) && (
				<>
					<AlertOptionDivider>
						<FormattedMessage id="alert-options.secure_spaces" />
					</AlertOptionDivider>
					<div className="divide-y divide-gray-50">
						{getOption('secure-space.shared')}
						{getOption('secure-space.activated')}
						{getOption('secure-space.activation.confirmed')}
						{getOption('folder.users.added')}
					</div>
					<AlertOptionDivider>
						<FormattedMessage id="alert-options.files" />
					</AlertOptionDivider>
					<div className="divide-y divide-gray-50">
						{getOption('folder.items.added')}
						{getOption('file.saved')}
						{getOption('file.downloaded')}
						{getOption('file.viewed')}
						{getOption('file.users.added')}
						{getOption('file.inboxed')}
						{getOption('file.branched')}
						{getOption('file.requested')}
					</div>
					<AlertOptionDivider>
						<FormattedMessage id="alert-options.messages" />
					</AlertOptionDivider>
					<div className="divide-y divide-gray-50">
						{getOption('comment.created')}
						{getOption('comment.liked')}
						{getOption('comment.read')}
						{getOption('comment.replied')}
					</div>
					<AlertOptionDivider>
						<FormattedMessage id="alert-options.payment-requests" />
					</AlertOptionDivider>
					<div className="divide-y divide-gray-50">
						{getOption('payment-request.received')}
						{getOption('payment-request.payed')}
					</div>
					<AlertOptionDivider>
						<FormattedMessage id="alert-options.system" />
					</AlertOptionDivider>
					<div className="divide-y divide-gray-50">
						{getOption('email.bounced')}
						{getOption('report.weekly.ready')}
					</div>
				</>
			)}
		</Card>
	);
};

const AlertOptionDivider: FC<PropsWithChildren> = ({ children }) => (
	<div className="grid grid-cols-12 gap-2 p-4 text-gray-600 bg-gray-50">
		<div className="col-span-12 sm:col-span-6">{children}</div>
		<div className="hidden col-span-3 space-x-2 text-sm text-center sm:block">
			<FontAwesomeIcon icon="browser" className="mr-2" />
			<FormattedMessage id="alert-options.in_app" />
		</div>
		<div className="hidden col-span-3 space-x-2 text-sm text-center sm:block">
			<FontAwesomeIcon icon="envelope" className="mr-2" />
			<FormattedMessage id="alert-options.by_email" />
		</div>
	</div>
);
