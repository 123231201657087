import type { AnnouncementType } from '@components/Announcement';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { WretchError, WretchResponse } from 'wretch';

type useAnnouncementsOptions = {
	hideOnDismiss?: boolean;
};

export const useAnnouncements = (intent?: string, { hideOnDismiss = false }: useAnnouncementsOptions = {}) => {
	const { client } = useClient();
	const { locale } = useIntl();
	const { account } = useAccount();
	const queryClient = useQueryClient();

	const queryKey = ['announcements', intent, locale];

	const { data: announcements, isLoading } = useQuery(
		queryKey,
		async () =>
			await client
				.url(`account/announcements${intent ? `/${intent}` : ''}`)
				.query({ locale: locale === 'fr' ? 'fr_CA' : 'en_US' })
				.get()
				.json<AnnouncementType[]>(),
		{
			staleTime: Infinity,
			enabled: !!account
		}
	);

	const { mutate: dismiss } = useMutation<WretchResponse, WretchError, AnnouncementType>(async announcement => await client.url(`account/announcements/${announcement.Id}`).delete().res(), {
		onError: (_error, _variables, previousAnnouncements) => {
			if (!hideOnDismiss) {
				return;
			}

			queryClient.setQueryData(queryKey, previousAnnouncements);
		},
		onMutate: async announcement => {
			if (!hideOnDismiss) {
				return undefined;
			}

			await queryClient.cancelQueries(queryKey);

			const previousAnnouncements = queryClient.getQueryData<AnnouncementType[]>(queryKey);

			if (previousAnnouncements === undefined) {
				return undefined;
			}

			queryClient.setQueryData(
				queryKey,
				previousAnnouncements.filter(_announcement => _announcement.Id !== announcement.Id)
			);

			return previousAnnouncements;
		}
	});

	return {
		announcements,
		isLoading,
		dismiss
	};
};
