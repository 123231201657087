import classNames from 'classnames';
import type { ReactNode } from 'react';
import { components, type OptionProps } from 'react-windowed-select';

export type KeyValueDescriptionOptionType<T = string> = {
	value: T;
	label: ReactNode;
	description: ReactNode;
};

export const KeyValueDescriptionOption = <T,>({ data, isSelected, isFocused, innerProps, ...props }: OptionProps<KeyValueDescriptionOptionType<T>, false>) => {
	const className = classNames(innerProps.className, 'hover:bg-gray-100', { '!bg-theme-primary': isSelected, '!bg-gray-200': isFocused });
	return (
		<components.Option innerProps={{ ...innerProps, className }} data={data} isFocused={isFocused} isSelected={isSelected} {...props}>
			<h6 className={classNames('font-semibold text-sm', { 'text-white': isSelected })}>{data.label}</h6>
			<p className={classNames('text-xs font-light', { 'text-white': isSelected, 'text-gray-500': !isSelected })}>{data.description}</p>
		</components.Option>
	);
};
