import AppContext from '@contexts/AppContext';
import { Size } from '@convoflo/ui';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import {
	AccountForwardingPage,
	AccountPasswordPage,
	AccountProfilePage,
	AccountSettingsPage,
	CannedMessagePage,
	ContactsPage,
	MultiFactorAuthPage,
	NotificationOptionsPage,
	SessionManagerPage,
	SubscriptionPage
} from '@pages/account';
import { FolderTemplatesPage, OrganizationProfilePage, OrganizationPropertiesPage, OrganizationSettingsPage, UsersPage } from '@pages/organization';
import { AppsPage } from '@pages/organization/apps';
import UserAvatar from '@ui/UserAvatar';
import classNames from 'classnames';
import { type FC, type PropsWithChildren, type ReactNode, useContext, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FormattedMessage } from 'react-intl';
import { NavLink, type NavLinkProps, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

export const AccountPage = () => {
	const { setPageHeader } = useContext(AppContext);
	const { account } = useAccount();
	const location = useLocation();

	const [showMobileNavigation, setShowMobileNavigation] = useState(false);

	useEffect(() => {
		setShowMobileNavigation(false);
	}, [location]);

	useEffect(() => {
		setPageHeader(
			<div className="flex items-center space-x-4">
				<UserAvatar user={account!} size={Size.sm} />
				<div className="justify-center flex-1">
					<h5 className="font-medium leading-none md:text-lg">{account!.Name}</h5>
					<p className="text-xs text-gray-500">{account!.Email}</p>
				</div>
			</div>
		);
	}, [account, setPageHeader]);

	return (
		<>
			<div className="relative flex flex-col h-full overflow-hidden">
				<header className="absolute top-0 left-0 right-0 flex items-stretch px-6 py-3 md:py-4 backdrop-blur-sm bg-white/75 shadow-sm z-[11] justify-between md:hidden">
					<div className="flex items-center space-x-4">
						<UserAvatar user={account!} />
						<div className="justify-center flex-1">
							<h5 className="font-medium md:text-xl">{account!.Name}</h5>
							<p className="text-xs text-gray-500">{account!.Email}</p>
						</div>
					</div>
					<button onClick={() => setShowMobileNavigation(e => !e)}>
						<FontAwesomeIcon icon="bars" size="lg" />
					</button>
				</header>

				<aside
					className={classNames('absolute inset-0 z-[1] w-full h-full pt-20 overflow-auto bg-white transition sm:hidden', {
						'translate-y-0': showMobileNavigation,
						'-translate-y-full': !showMobileNavigation
					})}>
					<AccountNavigation />
				</aside>

				<div className="flex-1 h-full md:flex md:flex-row">
					<aside className="hidden w-48 md:block xl:w-64" id="desktop-navigation">
						<Scrollbars autoHide>
							<AccountNavigation />
						</Scrollbars>
					</aside>

					<article className="flex-1 h-full">
						<Scrollbars autoHide>
							<div className="pt-24 pb-6 md:pt-12 md:px-8 md:pb-12">
								<article>
									<Switch>
										<Route path="/account/profile">
											<AccountProfilePage />
										</Route>
										<Route path="/account/notifications">
											<NotificationOptionsPage />
										</Route>
										<Route path="/account/alerts">
											<Redirect to="/account/notifications" />
										</Route>
										<Route path="/account/notifications">
											<NotificationOptionsPage />
										</Route>
										<Route path="/account/settings">
											<AccountSettingsPage />
										</Route>
										<Route path="/account/password">
											<AccountPasswordPage />
										</Route>
										<Route path="/account/sessions">
											<SessionManagerPage />
										</Route>
										<Route path="/account/multi-factor-auth">
											<MultiFactorAuthPage />
										</Route>
										<Route path="/account/contacts">{account!.hasFullAccess() ? <ContactsPage /> : <Redirect to="/account" />}</Route>
										<Route path="/account/canned-messages">{account!.hasFullAccess() ? <CannedMessagePage /> : <Redirect to="/account" />}</Route>
										<Route path="/account/forwarding">{account!.hasFullAccess() ? <AccountForwardingPage /> : <Redirect to="/account" />}</Route>

										<Route path="/account" exact>
											<Redirect to="/account/profile" />
										</Route>
										<Route path="/account/folder-templates" exact>
											<Redirect to="/organization/folder-templates" />
										</Route>

										<Route path="/organization/profile">{account!.isAdmin() && account!.hasFullAccess() ? <OrganizationProfilePage /> : <Redirect to="/account" />}</Route>
										<Route path="/organization/users">{account!.isAdmin() && account!.hasFullAccess() ? <UsersPage /> : <Redirect to="/account" />}</Route>
										<Route path="/organization/folder-templates">{account!.isAdmin() && account!.hasFullAccess() ? <FolderTemplatesPage /> : <Redirect to="/account" />}</Route>
										<Route path="/organization/properties">{account!.isAdmin() && account!.hasFullAccess() ? <OrganizationPropertiesPage /> : <Redirect to="/account" />}</Route>
										<Route path="/organization/subscription">{account!.isAdmin() && account!.hasFullAccess() ? <SubscriptionPage /> : <Redirect to="/account" />}</Route>
										<Route path="/organization/apps">{account!.isAdmin() && account!.hasFullAccess() ? <AppsPage /> : <Redirect to="/account" />}</Route>
										<Route path="/organization/settings">{account!.isAdmin() && account!.hasFullAccess() ? <OrganizationSettingsPage /> : <Redirect to="/account" />}</Route>

										<Route path="/organization/billing">
											<Redirect to="/account/subscription" />
										</Route>
										<Route path="/account/subscription">
											<Redirect to="/organization/subscription" />
										</Route>
									</Switch>
								</article>
							</div>
						</Scrollbars>
					</article>
				</div>
			</div>
		</>
	);
};

const AccountNavigation: FC = () => {
	const { account } = useAccount();
	return (
		<nav className="flex flex-col py-8 pr-2 mb-6">
			<SidebarLink to="/account/profile" icon="user-circle" title={<FormattedMessage id="account.profile" />} description={<FormattedMessage id="account.profile_desc" />} />
			<SidebarLink to="/account/notifications" icon="bell" title={<FormattedMessage id="account.alerts" />} description={<FormattedMessage id="account.alerts_desc" />} />
			<SidebarLink to="/account/settings" icon="cog" title={<FormattedMessage id="account.settings" />} description={<FormattedMessage id="account.settings_desc" />} />
			<SidebarGroup>
				<FormattedMessage id="account.security" />
			</SidebarGroup>
			<SidebarLink to="/account/password" icon="key" title={<FormattedMessage id="account.password" />} description={<FormattedMessage id="account.security_desc" />} />
			<SidebarLink to="/account/sessions" icon="globe-americas" title={<FormattedMessage id="account.sessions" />} description={<FormattedMessage id="account.security_desc" />} />
			<SidebarLink to="/account/multi-factor-auth" id="step-security" icon="shield" title={<FormattedMessage id="2fa.title" />} description={<FormattedMessage id="account.security_desc" />} />
			{account!.hasFullAccess() && (
				<>
					<SidebarGroup>
						<FormattedMessage id="account.content" />
					</SidebarGroup>
					<SidebarLink to="/account/contacts" icon="address-book" title={<FormattedMessage id="account.contacts" />} description={<FormattedMessage id="account.contacts_desc" />} />
					<SidebarLink
						to="/account/canned-messages"
						icon="comment-alt"
						title={<FormattedMessage id="account.canned_messages" />}
						description={<FormattedMessage id="account.canned_messages_desc" />}
					/>
					<SidebarLink to="/account/forwarding" icon="inbox-in" title={<FormattedMessage id="dialog.email-id.title" />} description={<FormattedMessage id="account.alerts_desc" />} />
				</>
			)}
			{account!.hasFullAccess() && account!.isAdmin() && (
				<>
					<SidebarGroup>
						<FormattedMessage id="account.organization" />
					</SidebarGroup>
					<SidebarLink to="/organization/profile" icon="building" title={<FormattedMessage id="organization.profile" />} description={<FormattedMessage id="account.users_desc" />} />
					<SidebarLink to="/organization/users" icon="users" title={<FormattedMessage id="account.users" />} description={<FormattedMessage id="account.users_desc" />} />
					<SidebarLink
						to="/organization/folder-templates"
						icon="sitemap"
						title={<FormattedMessage id="account.folder_templates" />}
						description={<FormattedMessage id="account.folder_templates_desc" />}
					/>
					<SidebarLink to="/organization/apps" icon="puzzle-piece" title={<FormattedMessage id="apps.title" />} description={<FormattedMessage id="account.folder_templates_desc" />} />
					<SidebarLink to="/organization/properties" icon="tags" title={<FormattedMessage id="organization.properties" />} />
					<SidebarLink to="/organization/subscription" icon="credit-card" title={<FormattedMessage id="account.billing" />} description={<FormattedMessage id="account.billing_desc" />} />
					<SidebarLink to="/organization/settings" icon="cog" title={<FormattedMessage id="account.settings" />} description={<FormattedMessage id="account.organization_desc" />} />
				</>
			)}
		</nav>
	);
};

export const SidebarGroup: FC<PropsWithChildren> = ({ children }) => {
	return (
		<div className="px-4 mt-6 mb-2">
			<h5 className="text-base font-medium text-gray-400 md:text-xs">{children}</h5>
		</div>
	);
};

type SidebarLinkProps = Omit<NavLinkProps, 'title'> & {
	title?: ReactNode;
	description?: ReactNode;
	icon?: IconProp;
	iconElement?: ReactNode;
	iconClassName?: string;
};

export const SidebarLink: FC<SidebarLinkProps> = ({ title, description, icon, iconClassName, iconElement, ...navLinkProps }) => {
	const match = useRouteMatch(navLinkProps.to as string);

	return (
		<NavLink className={classNames('group flex items-center px-4 py-2 transition rounded-r-lg gap-1.5', { 'bg-white': match })} {...navLinkProps}>
			<span className="w-5">
				{iconElement}
				{icon !== undefined && <FontAwesomeIcon icon={icon} className={classNames(iconClassName, 'transition', { 'opacity-100': match, 'opacity-50 group-hover:opacity-75': !match })} />}
			</span>
			<div className="flex flex-1 space-y-1">
				<h3 className={classNames('text-lg md:text-sm font-medium transition-colors ', { 'text-gray-800': match, 'text-gray-600 group-hover:text-gray-700': !match })}>{title}</h3>
				{/* <p className={`text-xs leading-tight transition-colors ${match ? 'text-gray-600' : 'text-gray-400 group-hover:text-gray-500'}`}>{description}</p> */}
			</div>
		</NavLink>
	);
};

export * from '../organization/apps';
export * from '../organization/subscription';
export * from './canned-messages';
export * from './checkout';
export * from './contacts';
export * from './email';
export * from './forwarding';
export * from './multi-factor-auth';
export * from './notifications-options';
export * from './password';
export * from './profile';
export * from './sessions';
export * from './settings';
