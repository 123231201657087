import StartTrialImg from '@assets/images/start-trial.svg?react';
import { Button, Intent, Modal, ModalBody, ModalFooter, type ModalProps, Variant } from '@convoflo/ui';
import { useStartTrialMutation } from '@state/queries/subscriptions';
import { type FC, type FormEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { trialDays } from '../constants';

type DialogTrialOfferProps = Omit<ModalProps, 'isOpen'> & { referredBy?: string };

const DialogTrialOffer: FC<DialogTrialOfferProps> = ({ referredBy, ...modalProps }) => {
	const [isOpen, setIsOpen] = useState(true);

	const { mutateAsync: activate, isLoading: isSubmitting } = useStartTrialMutation();

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		try {
			activate(referredBy);
		} catch {
			// TODO: Show error message to user
		}
	};

	return (
		<Modal onSubmit={onSubmit} isOpen={isOpen} {...modalProps}>
			<ModalBody>
				<h2 className="mb-8 text-2xl font-semibold">
					<FormattedMessage id="trial-offer.title" />
				</h2>
				<div className="flex items-start gap-8">
					<div className="prose">
						<p>
							<FormattedMessage id="trial-offer.intro" />
						</p>
					</div>
					<div>
						<StartTrialImg className="w-36" />
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button variant={Variant.success} disabled={isSubmitting}>
					<FormattedMessage id="activate_x_day_trial" values={{ days: trialDays }} />
				</Button>
				<Button type="button" variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} disabled={isSubmitting}>
					<FormattedMessage id="not_now" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default DialogTrialOffer;
