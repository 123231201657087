import { autoUpdate, flip, offset, shift, useDismiss, useFloating, useFocus, useInteractions, useRole, useHover, FloatingPortal, FloatingArrow, arrow, type Placement } from '@floating-ui/react';
import React, { cloneElement, useRef, useState, type PropsWithChildren, type ReactNode } from 'react';

export const Tooltip: React.FC<PropsWithChildren<{ tip?: ReactNode; placement?: Placement }>> = ({ children, tip, placement = 'top' }) => {
	const [isOpen, setIsOpen] = useState(false);
	const arrowRef = useRef(null);

	// Tell `useLayer()` how we would like to position our tooltip
	const { refs, floatingStyles, context } = useFloating({
		open: isOpen,
		onOpenChange: setIsOpen,
		placement,
		// Make sure the tooltip stays on the screen
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(12),
			flip({
				fallbackAxisSideDirection: 'start'
			}),
			shift(),
			arrow({
				element: arrowRef
			})
		]
	});

	const hover = useHover(context, { move: false });
	const focus = useFocus(context);
	const dismiss = useDismiss(context);
	// Role props for screen readers
	const role = useRole(context, { role: 'tooltip' });

	// Merge all the interactions into prop getters
	const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

	if (!tip) {
		return <>{children}</>;
	}

	let trigger = cloneElement(children as JSX.Element, {
		ref: refs.setReference,
		...getReferenceProps()
	});

	return (
		<>
			{trigger}
			{isOpen && (
				<FloatingPortal>
					<div className="relative z-[51] px-3 pb-1 text-center bg-gray-800 rounded w-max" ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
						<span className="text-xs leading-none text-white">{tip}</span>
						<FloatingArrow ref={arrowRef} context={context} />
					</div>
				</FloatingPortal>
			)}
		</>
	);
};
