import { Button, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Toggle, Variant } from '@convoflo/ui';
import SignRequest from '@models/SignRequest';
import { useSignRequestDeleteMutation } from '@state/queries/sign-requests';
import { type FC, type FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';

export type DialogDeleteSignRequestProps = Omit<ModalProps, 'isOpen'> & {
	signRequest: SignRequest;
	onDeleted?: (signRequest: SignRequest) => void;
};

export const DialogDeleteSignRequest: FC<DialogDeleteSignRequestProps> = ({ signRequest, ...modalProps }) => {
	const [isOpen, setIsOpen] = useState(true);
	const [archive, setArchive] = useState(false);

	const { mutateAsync: deleteMutation, isLoading: isDeleting } = useSignRequestDeleteMutation(signRequest);

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		try {
			await deleteMutation(archive);
			toast.success(<FormattedMessage id="sign-requests-crud.deleted" values={{ type: signRequest.folder === null ? 'template' : 'request' }} />);
		} catch {
			// TODO: Show error message to user
		}
	};

	return (
		<Modal isOpen={isOpen} onSubmit={onSubmit} {...modalProps}>
			<ModalHeaderOnlyTitle>
				{archive ? (
					<FormattedMessage
						id="sign-requests-crud.archive_sign_request"
						values={{
							type: signRequest.folder === null ? 'template' : 'request'
						}}
					/>
				) : (
					<FormattedMessage
						id="sign-requests-crud.delete_sign_request"
						values={{
							type: signRequest.folder === null ? 'template' : 'request'
						}}
					/>
				)}
			</ModalHeaderOnlyTitle>
			<ModalBody>
				<FormattedMessage
					id={archive ? 'sign-requests-crud.archive_sign_request_text' : 'sign-requests-crud.delete_sign_request_text'}
					values={{
						type: signRequest.folder === null ? 'template' : 'request',
						name: signRequest.Label,
						semi: msg => <span className="italic font-semibold">{msg}</span>,
						strong: msg => <span className="font-bold text-red-700">{msg}</span>
					}}
				/>
				{signRequest.archived_at === null && (
					<div className="flex items-center mt-4 font-semibold text-gray-600">
						<Toggle checked={archive} onChange={e => setArchive(e.target.checked)} className="mr-4" />
						<FormattedMessage id="archive" />
					</div>
				)}
			</ModalBody>
			<ModalFooter>
				{archive && (
					<Button type="submit" disabled={isDeleting} variant={Variant.primary}>
						<FormattedMessage id="archive" />
					</Button>
				)}
				{!archive && (
					<Button type="submit" disabled={isDeleting} variant={Variant.danger}>
						<FormattedMessage id="delete" />
					</Button>
				)}
				<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
