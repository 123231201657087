import FullScreenLoading from '@components/FullScreenLoading';
import AppContext from '@contexts/AppContext';
import useClient from '@hooks/useClient';
import SignRequest from '@models/SignRequest';
import type { SignRequestDesignerGetResponse, SignRequestDesignerQuery } from '@state/queries/sign-requests';
import { useContext, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import type { WretchError } from 'wretch';

const Page = () => {
	const { client } = useClient();
	const history = useHistory();
	const { signRequestId = null } = useParams<{ signRequestId: string }>();
	const { setPageTitle: setTitle } = useContext(AppContext);
	const { formatMessage } = useIntl();

	const alreadySignedCatcher = (error: WretchError) => {
		if (error.json?.error?.code === 'already_signed') {
			toast.error(<FormattedMessage id="sign.already_signed" />);
			history.goBack();
			return;
		}
		if (error.json?.error?.code === 'sign_request_completed') {
			toast.error(<FormattedMessage id="sign.sign_request_completed" />);
			history.goBack();
			return;
		}

		history.replace('/403', { error, intended: history.location });
	};

	const { data, isLoading } = useQuery<SignRequestDesignerQuery, WretchError>(
		['sign-requests', signRequestId, 'signer'],
		async () => {
			const { url, sign_request: signRequest } = await client.url(`sign/${signRequestId}/signer`).catcher(403, alreadySignedCatcher).get().json<SignRequestDesignerGetResponse>();
			return { signRequest: new SignRequest(signRequest), url };
		},
		{
			retry: (_, error) => {
				return error.status === 400 && error.json.error.code === 'sign_request_not_ready';
			},
			staleTime: Infinity,
			refetchOnMount: 'always',
			retryDelay: 3000,
			onError: error => {
				if (error.status === 400 && error.json.error.code === 'sign_request_not_ready') return;
				if (error.status === 404 || error.status === 403) {
					history.replace('/403', { error, intended: history.location });
				}
				console.error(error);
			}
		}
	);

	useEffect(() => {
		if (data) {
			setTitle(formatMessage({ id: 'sign-requests.title.signer' }, { n: data.signRequest.documents.length }));
		} else {
			setTitle(formatMessage({ id: 'loading' }));
		}
	}, [setTitle, formatMessage, data]);

	// If no sign request id is passed in the URL, go to home
	if (signRequestId === null) {
		return <Redirect to="/" />;
	}

	if (isLoading && !data) {
		return <FullScreenLoading />;
	}

	if (!data) {
		toast.error(<FormattedMessage id="sign.sign_request_not_found" />);
		return <Redirect to="/" />;
	}

	// If the sign request is already completed, go to the folder
	if (data.signRequest.completed_at) {
		return <Redirect to={data.signRequest.folder!.getUrl()} />;
	}

	window.location.href = data.url;

	return null;

	// return (
	// 	<div className="flex flex-col w-full h-full">
	// 		<iframe title={data.signRequest.Label} src={data.url} className="flex-auto w-full h-full border-0"></iframe>
	// 	</div>
	// );
};

export default Page;
