import type { NotificationOptionsMutation, UserNotificationOptions } from '@components/Notification';
import { Button, Checkbox, HelperText, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Row, Select, Size, Variant } from '@convoflo/ui';
import useClient from '@hooks/useClient';
import { hoursKeyValue } from '@utils/DateUtils';
import { type FC, type FormEvent, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import type { WretchError } from 'wretch';

type DialogNotificationScheduleManagerProps = Omit<ModalProps, 'isOpen'> & {};

export const DialogNotificationScheduleManager: FC<DialogNotificationScheduleManagerProps> = ({ ...modalProps }) => {
	const [isOpen, setIsOpen] = useState(true);
	const { formatMessage, locale } = useIntl();
	const queryClient = useQueryClient();
	const { client } = useClient();

	const [schedules, setSchedules] = useState(queryClient.getQueryData<UserNotificationOptions>(['notification_options'])?.schedules ?? []);
	const [includeRead, setIncludeRead] = useState(queryClient.getQueryData<UserNotificationOptions>(['notification_options'])?.scheduleIncludeRead ?? false);

	const { mutate: save, isLoading } = useMutation<UserNotificationOptions, WretchError, NotificationOptionsMutation, UserNotificationOptions>(
		async ({ schedules, scheduleIncludeRead }) =>
			await client.url(`account/notifications/options`).json({ schedules, schedule_include_read: scheduleIncludeRead }).put().json<UserNotificationOptions>(),
		{
			onError: (_err, _newTodo, oldOptions) => {
				queryClient.setQueryData(['notification_options'], oldOptions);
			},
			onMutate: async ({ schedules = [], scheduleIncludeRead = false }) => {
				await queryClient.cancelQueries(['notification_options']);
				const options = queryClient.getQueryData<UserNotificationOptions>(['notification_options']);

				if (options === undefined) {
					return undefined;
				}

				options.schedules = schedules;
				options.scheduleIncludeRead = scheduleIncludeRead;

				queryClient.setQueryData<UserNotificationOptions>(['notification_options'], options);
				return options;
			},
			onSuccess: () => {
				toast.success(<FormattedMessage id="alert-options.scheduler.saved" />);
				setIsOpen(false);
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		save({ schedules, scheduleIncludeRead: includeRead });
	};

	const memoizedTimes = useMemo(
		() =>
			hoursKeyValue(locale).map(({ key, value }) => {
				if (key === '00:00:00') {
					value = formatMessage({ id: 'alert-options.midnight' });
				} else if (key === '12:00:00') {
					value = formatMessage({ id: 'alert-options.noon' });
				}

				return { key, value };
			}),
		[formatMessage, locale]
	);

	return (
		<Modal isOpen={isOpen} {...modalProps} onSubmit={onSubmit}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="alert-options.scheduler.title" />
			</ModalHeaderOnlyTitle>
			<ModalBody>
				<p className="mb-6 text-xs text-gray-500">
					<FormattedMessage id="alert-options.scheduler.intro" />
				</p>
				<Row>
					{schedules.length > 0 && (
						<div className="grid grid-cols-2 p-3 mb-3 bg-gray-100 rounded-lg sm:grid-cols-3 place-items-start gap-x-8 gap-y-2">
							{schedules.map((schedule, index) => (
								<div className="flex items-center space-x-2">
									<Select size={Size.sm} disabled={isLoading} value={schedule} onChange={e => setSchedules(schedules => schedules.map((s, i) => (index === i ? e.target.value : s)))}>
										{memoizedTimes.map(time => (
											<option value={time.key} disabled={schedule === time.key}>
												{time.value}
											</option>
										))}
									</Select>
									<Button
										disabled={isLoading}
										type="button"
										size={Size.xs}
										circle
										variant={Variant.light}
										intent={Intent.secondary}
										icon="times"
										onClick={() => setSchedules(schedules => schedules.filter(s => s !== schedule))}
									/>
								</div>
							))}
						</div>
					)}
					<Button
						disabled={isLoading}
						variant={Variant.light}
						size={Size.sm}
						intent={Intent.secondary}
						icon="bell-plus"
						type="button"
						onClick={() => setSchedules(schedules => [...schedules, '17:00:00'])}>
						<FormattedMessage id="alert-options.scheduler.add_time" />
					</Button>
				</Row>

				<Row>
					<Label htmlFor="enable-read">
						<FormattedMessage id="alert-options.scheduler.include_read" />
					</Label>
					<label className="flex items-start">
						<Checkbox className="mt-1 mr-2" id="enable-read" checked={includeRead} onChange={e => setIncludeRead(e.target.checked)} />
						<HelperText>
							<FormattedMessage id="alert-options.scheduler.include_read_description" />
						</HelperText>
					</label>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button variant={Variant.success} type="submit" icon="save" disabled={isLoading}>
					<FormattedMessage id="save" />
				</Button>
				<Button variant={Variant.light} type="button" intent={Intent.secondary} disabled={isLoading} onClick={() => setIsOpen(false)}>
					<FormattedMessage id="close" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
