import { useFileable } from '@components/FileManager';
import { type Comment, MessageContext } from '@components/Message';
import { type FC, type PropsWithChildren, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

type MessageProviderProps = PropsWithChildren<{
	message: Comment;
}>;

export const MessageProvider: FC<MessageProviderProps> = ({ message, children }) => {
	const history = useHistory();
	const { fileable } = useFileable() ?? {};

	const [_innerMessage, setMessage] = useState(message);

	const navigate = (newTab = false) => {
		const url = `${fileable?.getUrl('comments') ?? message.commentable?.Link}?comment=${message.ID}&ref=message_link`;
		if (newTab) {
			window.open(url, '_blank')?.focus();
			return;
		}
		history.push(url);
	};

	const update = (message: Comment) => {
		setMessage(message);
	};

	useEffect(() => {
		setMessage(message);
	}, [message]);

	return <MessageContext.Provider value={{ message: _innerMessage, update, navigate }}>{children}</MessageContext.Provider>;
};
