import useClient from '@hooks/useClient';
import Account from '@models/Account';
import Folder from '@models/Folder';
import { versionMiddleware } from '@service';
import { useMutation, useQueryClient } from 'react-query';
import type { WretchError } from 'wretch';

type CreateFolderApiRequest = {
	name: string;
	parents?: number[];
	visibility?: 'public' | 'private';
	secured_space?: boolean;
	permissions_source?: 'parent';
	content_source?: 'template' | 'folder';
	content_source_id?: number;
};

export type CreateFolderMutation = {
	name: string;
	parents: Folder[] | null;
	visibility: 'public' | 'private';
	secureSpace: boolean;
	permissionsSource?: 'parent';
	contentSource?: 'template' | 'folder';
	contentSourceId?: number;
};

export const useFolderCreateMutation = () => {
	const queryClient = useQueryClient();
	const { client, authToken } = useClient();

	return useMutation<Folder[], WretchError, CreateFolderMutation>({
		mutationFn: async ({ name, parents, secureSpace, visibility, contentSource, contentSourceId, permissionsSource }) => {
			const data: CreateFolderApiRequest = {
				name,
				secured_space: secureSpace,
				visibility: 'private',
				content_source: contentSource,
				content_source_id: contentSourceId,
				permissions_source: permissionsSource
			};

			// Visibility can only be set when it's a secure space
			if (secureSpace) {
				data.visibility = visibility ? 'public' : 'private';
			}

			if (parents) {
				data.parents = parents.map(parent => parent.getKey()!);
			}

			const payload = await client
				.url('folders')
				.middlewares([versionMiddleware(2)])
				.post(data)
				.json<Folder[]>();

			return payload.map(folder => new Folder(folder));
		},
		onSuccess: folders => {
			const totalNewSecureSpaces = folders.filter(folder => folder.SecuredSpace).length;

			queryClient.setQueryData<Account | null>(['account', authToken], account =>
				account
					? new Account({
							...account,
							Usage: {
								...account.Usage,
								SecureSpaces: {
									...account.Usage.SecureSpaces,
									used: account.Usage.SecureSpaces.used + totalNewSecureSpaces,
									available:
										account.Usage.SecureSpaces.limit !== null && account.Usage.SecureSpaces.available !== null ? account.Usage.SecureSpaces.available - totalNewSecureSpaces : null
								}
							}
					  })
					: null
			);
		}
	});
};
