import type { FC, TableHTMLAttributes, TdHTMLAttributes, ThHTMLAttributes } from 'react';

type TableProps = TableHTMLAttributes<HTMLTableElement> & {
	responsive?: boolean;
};

export const Table: FC<TableProps> = ({ className = '', responsive = true, ...tableProps }) => {
	const table = <table {...tableProps} className={`${className} w-full`} />;

	if (responsive) {
		return <div className="overflow-x-auto">{table}</div>;
	}
	return table;
};

export const TableHeader = ({ className = '', ...tableHeaderProps }: ThHTMLAttributes<HTMLTableHeaderCellElement>) => {
	return <th className={`${className} px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider`} {...tableHeaderProps} />;
};

export const TableCell = ({ className = '', ...tableCellProps }: TdHTMLAttributes<HTMLTableCellElement>) => {
	return <td className={`${className} px-6 py-4 whitespace-nowrap border-b border-gray-200`} {...tableCellProps} />;
};
