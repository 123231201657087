import { ActivityList } from '@components/Activity';
import FullScreenLoading from '@components/FullScreenLoading';
import AppContext from '@contexts/AppContext';
import useClient from '@hooks/useClient';
import useUrlSearch from '@hooks/useUrlSearch';
import File from '@models/File';
import Folder from '@models/Folder';
import type { Fileable, View } from '@types';
import { PageTitle } from '@ui/Typography';
import { parseFileableUrl } from '@utils/StringUtils';
import { type FC, useContext, useEffect, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import type { WretchError } from 'wretch';

export const PrintTimelinePage: FC = () => {
	const { client } = useClient();
	const { setPageTitle: setTitle } = useContext(AppContext);
	const history = useHistory();
	const { formatMessage } = useIntl();

	const { state: initialState = null } = useLocation<{ view: View }>();

	const parsedPage = parseFileableUrl(useUrlSearch().page);

	const useQueryOptions = useMemo(() => {
		let initialData: any = null;

		if (typeof initialState?.view === 'string') {
			initialData = initialState.view;
		} else if (initialState?.view instanceof Folder) {
			initialData = initialState.view;
		} else if (initialState?.view?.['@type'] === 'Folder') {
			initialData = new Folder(initialState.view);
		}

		let needsToRefetch = true;

		if (initialData instanceof Folder) {
			needsToRefetch = initialData?.ancestors === undefined || initialData?.pivot?.Permissions === undefined;
		}

		return {
			enabled: needsToRefetch,
			initialData: initialData
		};
	}, [initialState?.view]);

	// Load the view
	const { data: view, isLoading } = useQuery<Fileable, WretchError>(
		['view', parsedPage?.isFile, parsedPage?.url],
		async () => {
			if (parsedPage?.isFile) {
				return new File(await client.url(`documents/${parsedPage?.url}`).get().json());
			} else {
				return new Folder(await client.url(`folders/${parsedPage?.url}`).get().json());
			}
		},
		{
			...useQueryOptions,
			onError: error => {
				if (error.status === 403) {
					history.push('/403', { error, intended: history.location });
				}

				if (error.status === 404) {
					history.replace('/files');
					toast.success(<FormattedMessage id="errors.folder_not_found" />);
				}
			}
		}
	);

	useEffect(() => {
		setTitle(view ? formatMessage({ id: 'file-manager.title.timeline' }, { file: view.getName() }) : formatMessage({ id: 'loading' }));
	}, [formatMessage, setTitle, view]);

	const print = () => {
		// setTimeout(() => window.print(), 1000);
		window.print();
	};

	if (!view || isLoading) {
		return <FullScreenLoading />;
	}

	return (
		<>
			<PageTitle>
				<FormattedMessage id="file-manager.title.timeline" values={{ file: view.getName() }} />
			</PageTitle>
			<ActivityList fileable={view} limit={null} onLoaded={print} />
		</>
	);
};
