import { SessionManager, Title } from '@components/Account';
import AppContext from '@contexts/AppContext';
import { type FC, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const SessionManagerPage: FC = () => {
	const { setPageTitle } = useContext(AppContext);
	const { formatMessage } = useIntl();

	useEffect(() => {
		setPageTitle(formatMessage({ id: 'account.sessions' }));
	}, [formatMessage, setPageTitle]);

	return (
		<article className="max-w-3xl">
			<Title>
				<FormattedMessage id="account.sessions" />
			</Title>
			<SessionManager />
		</article>
	);
};
