import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useSearchParams = () => {
	const history = useHistory();
	const location = useLocation();

	const searchParamsRecord = useMemo(() => {
		const record: Record<string, string> = {};
		for (const [key, value] of new URLSearchParams(location.search)) {
			if (value) {
				record[key] = value;
			}
		}
		return record;
	}, [location.search]);

	const setSearchParams = useCallback(
		(newSearchParams: Record<string, string | null | undefined> = {}, options: { replace?: boolean } = { replace: false }) => {
			const currentSearchParams = new URLSearchParams(location.search);
			for (const [key, value] of Object.entries(newSearchParams)) {
				if (!value) {
					currentSearchParams.delete(key);
				} else {
					currentSearchParams.set(key, value);
				}
			}

			if (options.replace) {
				history.replace({ search: currentSearchParams.toString() });
			} else {
				history.push({ search: currentSearchParams.toString() });
			}
		},
		[history, location.search]
	);

	return [searchParamsRecord, setSearchParams] as const;
};
