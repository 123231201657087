import tutorialEn1 from '@assets/images/private-messaging-tutorial-1-en.png';
import tutorialFr1 from '@assets/images/private-messaging-tutorial-1-fr.png';
import tutorialEn2 from '@assets/images/private-messaging-tutorial-2-en.png';
import tutorialFr2 from '@assets/images/private-messaging-tutorial-2-fr.png';
import { useFileableMembers, useSecureSpace } from '@components/FileManager';
import { type Comment, MessageForm, MessageItem, MessageListContext, MessageListStatusBar, type MessagesFilters } from '@components/Message';
import { type ResourceLengthAwarePaginatorType, createResourceLengthAwarePagination } from '@components/Pagination';
import { Alert, Button, Intent, Modal, ModalBody, type ModalProps, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useView } from '@hooks/use-view';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import useUrlSearch from '@hooks/useUrlSearch';
import { versionMiddleware } from '@service/Client';
import { type Fileable, MessagesMode, Permissions } from '@types';
import Card from '@ui/Card';
import EmptyState from '@ui/EmptyState';
import { formatLocalDateToUTC } from '@utils/DateUtils';
import { stringify } from 'qs';
import { type FC, Fragment, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage, useIntl } from 'react-intl';
import { useInfiniteQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import type { WretchError } from 'wretch';

type MessageListProps = {
	item: Fileable;
	order?: string;
	limit?: number;
};

export const MessageList: FC<MessageListProps> = ({ item, order: orderProp = 'newest', limit = 10 }) => {
	const { comment: focusedCommentId } = useUrlSearch();
	const { formatMessage } = useIntl();
	const { client } = useClient();
	const { account } = useAccount();
	const { ref: loadMoreRef, inView: loadMoreInView } = useInView();
	const { key } = useView();
	const history = useHistory();
	const secureSpace = useSecureSpace(item);

	const { members = [] } = useFileableMembers(item);

	const [order, setOrder] = useState(orderProp);
	const [filters, setFilters] = useState<MessagesFilters>({
		...(focusedCommentId && { ids: [focusedCommentId] })
	});

	const getMessagesRoute = item.getRoute('comments');

	const queryKey = ['messages', key, order, limit, filters];

	// Get messages
	const {
		data: messages,
		isLoading,
		isSuccess,
		isError,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage
	} = useInfiniteQuery<ResourceLengthAwarePaginatorType<Comment, { participants?: number }>, WretchError>(
		queryKey,
		async ({ pageParam = 1 }) =>
			createResourceLengthAwarePagination<Comment, { participants?: number }>(
				await client
					.middlewares([versionMiddleware(2)])
					.url(getMessagesRoute)
					.query(
						stringify(
							{
								page: pageParam,
								limit,
								order,
								filters: {
									ids: filters.ids ?? [],
									archived: filters.archived ? 1 : undefined,
									users: filters.users?.map(u => u.ID) ?? [],
									start_date: filters.startDate ? formatLocalDateToUTC(filters.startDate).toISOString() : undefined,
									end_date: filters.endDate ? formatLocalDateToUTC(filters.endDate).toISOString() : undefined
								}
							},
							{ arrayFormat: 'brackets' }
						)
					)
					.get()
					.forbidden(async error => {
						if (error.status === 403) {
							return;
						}
						throw error;
					})
					.json()
			),
		{
			getNextPageParam: paginator => (paginator.links.next ? paginator.meta.currentPage + 1 : undefined)
		}
	);

	// Load the next page when is available and in view
	useEffect(() => {
		if (loadMoreInView && !isLoading && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [fetchNextPage, loadMoreInView, isLoading, hasNextPage, isFetchingNextPage]);

	useEffect(() => {
		setFilters(filters => ({ ...filters, ...(focusedCommentId && { ids: [focusedCommentId] }) }));
	}, [focusedCommentId]);

	const removeMessageIdFilter = () => {
		setFilters({});
		history.push(item.getUrl('comments'));
	};

	const userCanPost = useMemo(() => {
		const messagesMode = secureSpace?.MessagesMode ?? MessagesMode.OpenToAll;

		if (messagesMode === MessagesMode.OpenToAll) {
			return true;
		}

		return secureSpace?.pivot?.Permissions === Permissions.ReadWrite;
	}, [secureSpace]);

	useLayoutEffect(() => {
		window.scrollTo({ top: 0 });
	}, []);

	return (
		<MessageListContext.Provider value={{ order, filters, setFilters, limit, fileable: item, onOrderChanged: setOrder, queryKey }}>
			<FontAwesomeIcon icon={['fas', 'thumbs-up']} symbol="fa-liked" />
			<FontAwesomeIcon icon="spinner" pulse symbol="fa-like-loader" />
			<FontAwesomeIcon icon={['far', 'thumbs-up']} symbol="fa-like" />
			<FontAwesomeIcon icon="thumbtack" symbol="fa-pin" transform="rotate-45" />
			<FontAwesomeIcon icon="archive" symbol="fa-archived" />
			<FontAwesomeIcon icon="ellipsis-v" symbol="fa-ellipsis-v" />
			<FontAwesomeIcon icon="comment" symbol="fa-comment" />

			{!!focusedCommentId && (
				<div className="flex items-center mb-8 space-x-2">
					<div className="flex-1 h-1 bg-gray-300 rounded-l-full"></div>
					<Button variant={Variant.dark} size={Size.sm} onClick={removeMessageIdFilter} shadow>
						<FontAwesomeIcon icon="comments" className="mr-2" />
						<FormattedMessage id="comments.see_all_messages" />
					</Button>
					<div className="flex-1 h-1 bg-gray-300 rounded-r-full"></div>
				</div>
			)}

			{isError && !!focusedCommentId && (
				<Alert variant={Variant.warning}>
					<p>
						<FormattedMessage id="comments.not_found" />
					</p>
				</Alert>
			)}

			{!focusedCommentId && userCanPost && (
				<Card size={null} className="pt-4" id="step-messages">
					<MessageForm />
				</Card>
			)}

			{!focusedCommentId && <MessageListStatusBar total={messages?.pages[0].meta.total ?? 0} participants={messages?.pages[0].meta.participants ?? 0} />}

			{isSuccess && (
				<>
					{(messages?.pages[0].meta.total ?? 0) > 0 ? (
						<>
							<div className="space-y-6">
								{messages?.pages.map((group, i) => (
									<Fragment key={i}>
										{group.data.map((message, index) => (
											<Fragment key={message.ID}>
												{!(i === 0 && index === 0) && <div className="w-1 h-12 ml-16 bg-gray-200 rounded-full sm:ml-24" />}
												<Card size={null} key={message.ID} className="relative sm:ml-16 overflow-visible !w-auto">
													<MessageItem key={message.ID} message={message} />
												</Card>
											</Fragment>
										))}
									</Fragment>
								))}
							</div>

							{/* Load more */}
							{!focusedCommentId && (
								<div ref={loadMoreRef} className="flex items-center justify-center">
									{isLoading || isFetchingNextPage ? (
										<FontAwesomeIcon icon="spinner-third" spin size="3x" className="my-8 text-gray-500" />
									) : (
										hasNextPage && (
											<Button intent={Intent.tertiary} shadow size={Size.xs} onClick={() => fetchNextPage()}>
												<FormattedMessage id="comments.load_more" />
											</Button>
										)
									)}
								</div>
							)}
						</>
					) : (
						<EmptyState icon="comments" title={formatMessage({ id: 'comments.empty-title' })} description={formatMessage({ id: 'comments.empty-body' })} />
					)}
				</>
			)}

			{isLoading && (
				<div className="flex flex-col items-center justify-center h-32 my-3 bg-gray-100 rounded-md">
					<p className="text-lg italic text-center text-gray-400">
						<FontAwesomeIcon pulse icon="spinner" className="mr-2" />
						<FormattedMessage id="comments.loading" />
					</p>
				</div>
			)}

			{!!focusedCommentId && (
				<div className="flex items-center mt-8 space-x-2">
					<div className="flex-1 h-1 bg-gray-300 rounded-l-full"></div>
					<Button variant={Variant.dark} size={Size.sm} onClick={removeMessageIdFilter} shadow>
						<FontAwesomeIcon icon="comments" className="mr-2" />
						<FormattedMessage id="comments.see_all_messages" />
					</Button>
					<div className="flex-1 h-1 bg-gray-300 rounded-r-full"></div>
				</div>
			)}
		</MessageListContext.Provider>
	);
};

export const DialogPrivateMessagesTutorial: FC<Omit<ModalProps, 'isOpen'>> = ({ ...modalProps }) => {
	const { locale } = useIntl();

	return (
		<Modal isOpen={true} closeable={true} {...modalProps}>
			<ModalBody>
				<div className="prose-sm prose max-w-none prose-h2:mb-1 prose-h3:mb-1 prose-img:my-0">
					<h2 className="text-center">
						<FormattedMessage id="comments.audience.tutorial.title" />
					</h2>
					<div>
						<div className="flex flex-col items-center py-4 sm:items-start sm:flex-row">
							<div className="flex-1">
								<h4 className="flex flex-col items-center sm:flex-row">
									<span className="inline-flex items-center justify-center w-8 h-8 mb-3 align-middle border border-gray-600 rounded-full sm:mb-0 sm:mr-2 shrink-0">1</span>
									<span>
										<FormattedMessage id="comments.audience.tutorial.step1-title" />
									</span>
								</h4>
								<p className="text-center sm:text-left">
									<FormattedMessage id="comments.audience.tutorial.step1-text" />
								</p>
							</div>
							<img src={locale === 'fr' ? tutorialFr1 : tutorialEn1} className="border border-gray-200 rounded-full sm:ml-8 w-36 h-36" alt="" />
						</div>
						<div className="flex flex-col items-center py-4 sm:items-start sm:flex-row">
							<div className="flex-1">
								<h4 className="flex flex-col items-center sm:flex-row">
									<span className="inline-flex items-center justify-center w-8 h-8 mb-3 align-middle border border-gray-600 rounded-full sm:mb-0 sm:mr-2 shrink-0">2</span>
									<span>
										<FormattedMessage id="comments.audience.tutorial.step2-title" />
									</span>
								</h4>
								<p className="text-center sm:text-left">
									<FormattedMessage
										id="comments.audience.tutorial.step2-text"
										values={{
											close_icon: <FontAwesomeIcon mask="circle" transform="shrink-6" icon="times" className="text-gray-300" />,
											bell_icon: <FontAwesomeIcon mask="circle" transform="shrink-6" icon="bell" className="text-gray-300" />
										}}
									/>
								</p>
							</div>
							<img src={locale === 'fr' ? tutorialFr2 : tutorialEn2} className="border border-gray-200 rounded-full sm:ml-8 w-36 h-36" alt="" />
						</div>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
};
