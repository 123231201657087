import FullScreenLoading from '@components/FullScreenLoading';
import LanguageSwitcher from '@components/LanguageSwitcher';
import { AppLogin } from '@components/Login';
import SecureSpaceLogin from '@components/Login/SecureSpaceLogin';
import { Logo } from '@components/Logo';
import AppContext from '@contexts/AppContext';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import useUrlSearch from '@hooks/useUrlSearch';
import Folder from '@models/Folder';
import type { GatewayGetResponse } from '@service/types';
import { parseFileableUrl } from '@utils/StringUtils';
import { type FC, useContext, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

export const LoginPage: FC = () => {
	const { email: emailParameter = '', next = '', gateway } = useUrlSearch();
	const { client, setAuthToken } = useClient();
	const { account } = useAccount();
	const { formatMessage } = useIntl();
	const { setPageTitle: setTitle, setLogo } = useContext(AppContext);
	const history = useHistory();
	const { state: locationState } = useLocation<{ intended: Location; next: Location }>();
	const location = useLocation();

	const email = useRef<string>(emailParameter);

	const signRequestExtraction = next.match(/\/sign\/([0-9]+)/i);
	const extraction = parseFileableUrl(next);

	const { data: gatewayOrganization = null, isLoading: isGatewayLoginLoading } = useQuery(['gateway', gateway], async () => client.url(`gateway/${gateway}`).get().json<GatewayGetResponse>(), {
		enabled: !!gateway
	});

	const { data: secureSpace = null, isLoading: isSecureSpaceLoginLoading } = useQuery(
		['secure_space', extraction?.param1, extraction?.param2, signRequestExtraction],
		async () => {
			if (extraction?.param1 && extraction?.param2 && extraction?.isFolder) {
				return new Folder(await client.url(`folders/${extraction.param1}/${extraction.param2}/auth`).get().json());
			}

			if (signRequestExtraction !== null && signRequestExtraction.length > 1) {
				return new Folder(await client.url(`sign/${signRequestExtraction[1]}`).get().json());
			}

			return null;
		},
		{
			enabled: signRequestExtraction !== null || (!!extraction?.param1 && !!extraction?.param2)
		}
	);

	const onSuccessfulLogin = (token: string) => {
		setAuthToken(token);
	};

	useEffect(() => {
		setTitle(formatMessage({ id: 'login.title' }));
	}, [setTitle, formatMessage]);

	useEffect(() => {
		if (emailParameter !== '') {
			email.current = emailParameter;
			let urlParams = new URLSearchParams(location.search);
			urlParams.delete('email');
			history.replace(`${location.pathname}?${urlParams.toString()}`);
		}
	}, [emailParameter, history, location.pathname, location.search]);

	// When we have a successful login
	useEffect(() => {
		if (!account) return;

		if (locationState && locationState.intended) {
			history.replace(locationState.intended);
		} else if (locationState && locationState.next) {
			history.replace(locationState.next);
		} else if (next) {
			history.replace(next, { next: locationState?.next });
		} else if (secureSpace instanceof Folder) {
			history.replace(secureSpace.getUrl(), { view: secureSpace });
		} else {
			history.replace('/');
		}
	}, [account, secureSpace, history, locationState, next]);

	useEffect(() => {
		if (secureSpace?.creator.business?.LogoLight) {
			setLogo(secureSpace.creator.business.LogoLight);
		}

		if (gatewayOrganization?.LogoLight) {
			setLogo(gatewayOrganization?.LogoLight);
		}
	}, [secureSpace, gatewayOrganization, setLogo]);

	if (isGatewayLoginLoading || isSecureSpaceLoginLoading) {
		return <FullScreenLoading />;
	}

	return (
		<div className="flex flex-col min-h-screen bg-gray-200">
			<div className={`container flex flex-col items-center justify-center flex-1 ${secureSpace !== null ? 'max-w-3xl' : 'max-w-sm'} px-2 py-8 mx-auto`}>
				<div className="max-w-full mb-8">
					<Logo light={true} className="h-12" />
				</div>

				{secureSpace ? <SecureSpaceLogin email={email.current} secureSpace={secureSpace} onSuccess={onSuccessfulLogin} /> : <AppLogin email={email.current} />}

				<div className="mt-12">
					<LanguageSwitcher />
				</div>
				<p className="mt-4 text-xs text-gray-500">
					<FormattedMessage id="copyright.short" values={{ year: new Date().getFullYear() }} />
				</p>
			</div>
		</div>
	);
};
