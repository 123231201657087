import GatewayImage from '@assets/images/gateway.svg?react';
import { Button, Input, Intent, Row, Select, Size, TextArea, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEnvironment } from '@hooks/use-environment';
import useClient from '@hooks/useClient';
import type { ModuleContract } from '@types';
import Card from '@ui/Card';
import { type FormEvent, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';

type SecureGatewayModuleContract = Omit<ModuleContract, 'Data'> & {
	Data: {
		domains: string[];
		hash: string;
	};
};

const gatewayHtmlCode = (baseUrl: string, hash: string, locale: string) => {
	return `<div><style="position: relative; padding-bottom: 80%; height: 0; overflow: hidden; max-width: 100%;"><iframe src="https://${baseUrl}/gateway/${hash}?locale=${locale}" style="border:0;position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>`;
};

export const GatewayModuleManager = () => {
	const { client } = useClient();
	const { formatMessage } = useIntl();
	const queryClient = useQueryClient();
	const environment = useEnvironment();

	const [domains, setDomains] = useState<string[]>([]);
	const [locale, setLocale] = useState('');

	const { data } = useQuery(['secure-gateway'], async () => await client.url('organization/modules/gateway').get().json<SecureGatewayModuleContract>(), {
		staleTime: Infinity,
		refetchOnMount: 'always'
	});

	const { mutate: save, isLoading: isSaving } = useMutation(
		async (domains: string[]) => await client.url('organization/modules/gateway').json({ urls: domains }).put().json<SecureGatewayModuleContract>(),
		{
			onMutate: async (domains: string[]) => {
				await queryClient.cancelQueries(['secure-gateway']);
				const previous = queryClient.getQueryData<SecureGatewayModuleContract>(['secure-gateway']);

				if (previous === undefined) {
					return { previous };
				}

				const next: SecureGatewayModuleContract = { ...previous };
				next.Data.domains = domains;

				queryClient.setQueryData<SecureGatewayModuleContract>(['secure-gateway'], next);
				return { previous };
			},
			onSuccess: () => {
				toast.success(<FormattedMessage id="account-settings.settings-saved" />);
			},
			onError: (_err, _newTodo, context) => {
				queryClient.setQueryData(['secure-gateway'], context?.previous);
			},
			onSettled: () => {
				queryClient.invalidateQueries(['secure-gateway']);
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		save(domains);
	};

	useEffect(() => {
		if (data?.Data.domains) {
			setDomains(data?.Data.domains);
		}
	}, [data]);

	const empty = domains.length === 0;
	const canSave = !empty && domains.every(domain => domain.trim() !== '');

	return (
		<Card onSubmit={onSubmit}>
			<div className="flex items-start mb-6">
				<div className="prose-sm prose">
					<p>
						<FormattedMessage id="gateway.intro" values={{ code: msg => <code className="p-1 text-xs bg-gray-100 rounded-sm">{msg}</code> }} />
					</p>
				</div>
				<div className="hidden ml-6 sm:block">
					<GatewayImage className="w-48" />
				</div>
			</div>

			<div className="overflow-hidden border rounded-md">
				<header className="border-b border-gray-100">
					<h4 className="px-3 py-2 text-sm font-medium text-gray-600 bg-gray-50">
						<FormattedMessage id="gateway.domains" />
					</h4>
				</header>
				<div className="divide-y divide-gray-50">
					{empty && (
						<div className="flex flex-col items-center my-6">
							<p className="mb-3 text-lg italic text-gray-500">
								<FormattedMessage id="gateway.no_domains" />
							</p>
							<Button type="button" size={Size.sm} intent={Intent.secondary} onClick={() => setDomains(domains => domains.concat(['']))}>
								<FontAwesomeIcon icon="plus" className="mr-2" />
								<FormattedMessage id="gateway.add_another_url" values={{ n: domains.length }} />
							</Button>
						</div>
					)}
					{domains.map((domain, index) => (
						<div className="flex items-stretch" key={index}>
							<div className="flex-1 p-3 space-x-2">
								<Input
									required
									className="flex-1"
									block
									size={Size.sm}
									type="url"
									value={domain}
									onChange={e => setDomains(domains => domains.map((domain, i) => (index === i ? e.target.value : domain)))}
								/>
							</div>
							<div className="flex items-center px-3">
								<Button
									variant={Variant.danger}
									intent={Intent.secondary}
									size={Size.xs}
									icon="times"
									onClick={() => setDomains(domains => domains.filter((_, i) => index !== i))}
									type="button">
									<FormattedMessage id="rules.remove" />
								</Button>
							</div>
						</div>
					))}
					{!empty && (
						<footer className="px-3 py-2 border-t border-gray-100">
							<Button icon="plus" variant={Variant.light} intent={Intent.secondary} size={Size.sm} type="button" onClick={() => setDomains(domains => domains.concat(['']))}>
								<FormattedMessage id="gateway.add_another_url" values={{ n: domains.length }} />
							</Button>
						</footer>
					)}
				</div>
			</div>

			{data?.Data.hash !== undefined && (
				<>
					<h2 className="mt-6 mb-4 text-lg font-semibold">
						<FormattedMessage id="gateway.html_code" />
					</h2>
					<TextArea
						size={Size.sm}
						block
						className="h-32 font-mono resize-none select-all"
						readOnly
						value={gatewayHtmlCode(environment?.Environment ?? 'app.convoflo.com', data.Data.hash, locale)}
					/>
					<Select size={Size.sm} value={locale} onChange={e => setLocale(e.target.value)}>
						<option value="">{formatMessage({ id: 'gateway.default_language' })}</option>
						<option value="en">{formatMessage({ id: 'english' })}</option>
						<option value="fr">{formatMessage({ id: 'french' })}</option>
					</Select>
				</>
			)}

			<Row className="mt-6">
				<Button type="submit" variant={Variant.primary} disabled={isSaving || !canSave} loading={isSaving} icon="save">
					<FormattedMessage id="save" />
				</Button>
			</Row>
		</Card>
	);
};
