import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { FC, HTMLAttributes } from 'react';

type TitleProps = HTMLAttributes<HTMLHeadingElement> & {
	icon?: IconProp;
};

export const Title: FC<TitleProps> = ({ icon, className, children, ...props }) => {
	return (
		<h2 className={classNames(className, 'text-2xl font-medium text-gray-900 px-6 md:px-0 mb-8 only:mb-0 font-serif')} {...props}>
			{icon && <FontAwesomeIcon icon={icon} size="sm" className="mr-3 text-gray-400" />}
			{children}
		</h2>
	);
};

export const Subtitle: FC<HTMLAttributes<HTMLHeadingElement>> = ({ className, ...props }) => {
	// eslint-disable-next-line jsx-a11y/heading-has-content
	return <h3 className={classNames(className, 'mb-4 text-gray-500 px-6 md:px-0')} {...props} />;
};
