import Loader from '@assets/images/Icon-Loader.svg?react';
import AppContext from '@contexts/AppContext';
import { Button, Intent, Modal, ModalBody, ModalFooter, type ModalProps, ModalSize, Variant } from '@convoflo/ui';
import type SignRequest from '@models/SignRequest';
import { useSignRequestDesignerUrlQuery } from '@state/queries/sign-requests';
import { type FC, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

type DialogDesignerProps = Omit<ModalProps, 'isOpen'> & {
	signRequest: SignRequest;
};

export const DialogDesigner: FC<DialogDesignerProps> = ({ signRequest, ...modalProps }) => {
	const { setPageTitle: setTitle } = useContext(AppContext);

	const [isOpen, setIsOpen] = useState(true);

	const { data: url, isLoading } = useSignRequestDesignerUrlQuery(signRequest);

	useEffect(() => {
		setTitle(signRequest.Label);
	}, [setTitle, signRequest.Label]);

	return (
		<Modal isOpen={isOpen} size={ModalSize.XLarge} className="sm:!max-w-[90vw] !h-full" {...modalProps}>
			<ModalBody className="!p-0 group relative !h-full !flex-initial">
				{isLoading ? (
					<div className="flex items-center justify-center my-32">
						<Loader className="w-6 mr-3" />
						<p className="my-auto italic text-center text-gray-500">
							<FormattedMessage id="loading" />
						</p>
					</div>
				) : (
					<div className="flex flex-col w-full h-full">
						<iframe title={signRequest.Label} src={url} className="flex-auto w-full h-full border-0"></iframe>
					</div>
				)}
			</ModalBody>
			<ModalFooter>
				<Button variant={Variant.primary} intent={Intent.primary} type="button" onClick={() => setIsOpen(false)}>
					<FormattedMessage id="close" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
