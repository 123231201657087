import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { FC, HTMLAttributes } from 'react';
import { NavLink, type NavLinkProps } from 'react-router-dom';

export const NavBar: FC<HTMLAttributes<HTMLElement>> = ({ className = '', ...props }) => {
	return (
		<nav role="tablist" className={`${className} flex flex-nowrap gap-3 md:flex-wrap overflow-x-auto md:overflow-x-visible justify-items-stretch items-center lg:justify-items-start`} {...props} />
	);
};

type NavTabProps = NavLinkProps & {
	icon?: IconProp;
	active?: boolean;
};

export const NavTab: FC<NavTabProps> = ({ active = false, icon, children, ...props }) => {
	return (
		<NavLink
			className={classNames('text-sm rounded px-4 transition-colors whitespace-nowrap py-2 flex flex-col lg:flex-row items-center lg:space-x-2 space-y-1 lg:space-y-0', {
				'text-black bg-gray-300 font-semibold': active,
				'text-gray-400 hover:text-gray-600': !active
			})}
			{...props}>
			{icon !== undefined && <FontAwesomeIcon size="sm" className="opacity-50" icon={icon} />}
			<span>{children}</span>
		</NavLink>
	);
};
