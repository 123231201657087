import type { ImporterLocale } from 'react-csv-importer';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types -- all exports are ImporterLocale which is already fully typed */
export const frCA: ImporterLocale = {
	general: {
		goToPreviousStepTooltip: 'Précédent'
	},

	fileStep: {
		initialDragDropPrompt: 'Déposez le fichier CSV ici, ou appuyez pour le sélectionner dans un dossier',
		activeDragDropPrompt: 'Déposez le fichier CSV ici',

		getImportError: message => `Erreur d'importation: ${message}`,
		getDataFormatError: message => `Veuillez vérifier le format des données: ${message}`,
		goBackButton: 'Retour',
		nextButton: 'Choisir les colonnes',

		rawFileContentsHeading: 'Raw File Contents',
		previewImportHeading: "Prévisualisation de l'importation",
		dataHasHeadersCheckbox: 'Les colonnes ont des entêtes',
		previewLoadingStatus: "Chargement de l'aperçu…"
	},

	fieldsStep: {
		stepSubtitle: 'Sélectionnez les colonnes',
		requiredFieldsError: 'Veuillez assigner tous les colonnes obligatoires',
		nextButton: 'Importer',

		dragSourceAreaCaption: 'Colonnes à importer',
		getDragSourcePageIndicator: (currentPage: number, pageCount: number) => `Page ${currentPage} de ${pageCount}`,
		getDragSourceActiveStatus: (columnCode: string) => `Affecter la colonne ${columnCode}`,
		nextColumnsTooltip: 'Afficher les colonnes suivantes',
		previousColumnsTooltip: 'Afficher les colonnes précédentes',
		clearAssignmentTooltip: "Effacer l'affectation des colonnes",
		selectColumnTooltip: "Sélectionner une colonne pour l'affectation",
		unselectColumnTooltip: 'Désélectionner la colonne',

		dragTargetAreaCaption: 'Target fields',
		getDragTargetOptionalCaption: field => `${field} (faculatif)`,
		getDragTargetRequiredCaption: field => `${field} (requis)`,
		dragTargetPlaceholder: 'Déposez la colonne ici',
		getDragTargetAssignTooltip: (columnCode: string) => `Affecter la colonne ${columnCode}`,
		dragTargetClearTooltip: "Effacer l'affectation des colonnes",

		columnCardDummyHeader: 'Champ non attribué',
		getColumnCardHeader: code => `Colonne ${code}`
	},

	progressStep: {
		stepSubtitle: 'Importer',
		uploadMoreButton: 'Téléverser plus',
		finishButton: 'Terminé',
		statusError: "Impossible d'importer",
		statusComplete: 'Complété',
		statusPending: 'Importation…',
		processedRowsLabel: 'Rangées traitées:'
	}
};
