import colors from '@assets/color-palette.json';
import type { LabelType } from '@components/Labels';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import Collaborator from '@models/Collaborator';
import User from '@models/User';
import { type Fileable, FileableAccess, type FolderPivotType, type ItemSyncType, MessagesMode, type Metadata, Module, Permissions } from '@types';
import Organization from './Organization';

const getIconColor = (value: string | null) => {
	if (value === null) {
		return null;
	}

	return colors.find(color => color.value === value)?.textClassName ?? null;
};

export default class Folder implements Fileable {
	'@type': 'Folder';
	expires_at!: string | null;
	expires_by!: User | null;
	URL!: string;
	Link!: string;
	OriginalLink!: string;
	collaborators!: Collaborator[];
	creator!: User & {
		business?: Organization;
	};
	ID: number | null = null;
	Name!: string;
	SecuredSpace: boolean = false;
	pivot?: FolderPivotType;
	Access!: FileableAccess;
	SecureSpace?: Folder;
	created_at!: string;
	deleted_at!: string | null;
	ancestors?: Folder[];
	IncreasedSecurity!: boolean;
	ReadonlyMessages!: boolean;
	meta?: Metadata[];
	MessagesMode!: MessagesMode;
	ExpiryInterval!: string | null;

	Modules!: number;
	folders_count: number = 0;
	files_count: number = 0;
	members_count: number = 0;
	members_hash?: string;
	activations?: {
		total: number;
		activated: number;
	};
	Scope?: 'internal' | 'external';
	properties!: LabelType[];
	Defaults?: Record<string, any>;
	syncs?: ItemSyncType[];

	constructor(attributes: Record<string, any> | Fileable = {}) {
		Object.assign(this, attributes);
		if (this.SecureSpace) {
			this.SecureSpace = new Folder(this.SecureSpace);
		}
		if (this.ancestors) {
			this.ancestors = this.ancestors.map(ancestor => new Folder(ancestor));
		}
	}

	id() {
		return `folder_${this.ID}`;
	}

	hasModule(module: Module) {
		if (this.Modules !== undefined) {
			return (this.Modules & module) === module;
		}
		return ((this.creator.business?.Modules ?? 0) & module) === module;
	}

	getKey() {
		return this.ID;
	}

	getName() {
		return this.Name;
	}

	getRoute(suffix: string | null = null, prefix: string = 'folders') {
		return `${prefix}/${this.getKey()}${suffix !== null ? `/${suffix}` : ''}`;
	}

	getUrl(suffix: string | null = null): string {
		return this.OriginalLink + (suffix !== null ? `/${suffix}` : '');
	}

	__icon(): [IconProp, string] {
		return [this.SecuredSpace ? 'shield' : ('folder' as IconProp), this.SecuredSpace ? 'text-green-600' : getIconColor(this.pivot?.Color ?? null) ?? 'text-gray-800'];
	}

	icon(className = '', colorize = true, { fixedWidth = true, ...props }: Partial<FontAwesomeIconProps> = {}) {
		return <FontAwesomeIcon {...props} fixedWidth={fixedWidth} icon={this.__icon()[0]} className={`${className} ${colorize ? this.__icon()[1] : ''}`} />;
	}

	isSecureSpace(): boolean {
		return this.SecuredSpace;
	}

	canUploadFiles(): boolean {
		return this.pivot?.Permissions === Permissions.ReadWrite || (this.isSecureSpace() && this.Access === FileableAccess.Public) || this.SecureSpace?.Access === FileableAccess.Public;
	}

	canViewMessages(): boolean {
		return this.pivot?.Permissions !== undefined && this.hasModule(Module.Commenting);
	}

	getMetaValue(key: string) {
		return (this.meta ?? []).find(({ key: _key }) => key === _key)?.value;
	}
}
