import type { FC, SVGAttributes } from 'react';

const FontAwesomeSvg: FC<SVGAttributes<SVGElement>> = ({ id, className = '', ...attrs }) => {
	return (
		<svg className={`${className} w-4 h-4 align-middle inline-block`} {...attrs}>
			<use xlinkHref={`#${id}`} />
		</svg>
	);
};

export default FontAwesomeSvg;
