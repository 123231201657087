import { Title } from '@components/Account';
import { PaymentRequestDashboard, PaymentRequestsOnboarding, PaymentRequestsPromo } from '@components/PaymentRequest';
import AppContext from '@contexts/AppContext';
import { Button, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, ModalSize, Size, Toggle, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { Module, type ModuleContract } from '@types';
import { Sidebar, SidebarLink } from '@ui/Layout/Sidebar';
import { type FC, useContext, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';

type PaymentsOverviewApiResponse = {
	totals: Record<string, number>;
	contract: ModuleContract;
};

export const DashboardPaymentsPage: FC = () => {
	const { account } = useAccount();
	const { client } = useClient();
	const { setPageTitle: setTitle, setPageHeader } = useContext(AppContext);
	const { formatMessage } = useIntl();
	const [scope, setScope] = useLocalStorage<'user' | 'organization'>('dashboard.payments.scope', 'user');
	const [showCreatePayment, setShowCreatePayment] = useState(false);

	const { data: overview } = useQuery(['payment-requests', 'overview', scope], async () => await client.url('payment-requests/overview').query({ scope }).get().json<PaymentsOverviewApiResponse>(), {
		enabled: account!.business.hasModule(Module.Payments)
	});

	useEffect(() => {
		setTitle(formatMessage({ id: 'payment-requests' }));
	}, [setTitle, formatMessage]);

	useEffect(() => {
		setPageHeader(
			<Title icon="dollar-sign">
				<FormattedMessage id="payment-requests" />
			</Title>
		);
	}, [setPageHeader]);

	if (!account!.business.HasPaymentsConnected) {
		return (
			<Scrollbars autoHide>
				<PaymentRequestsPromo />
			</Scrollbars>
		);
	}

	if (!account!.business.hasModule(Module.Payments)) {
		return (
			<Scrollbars autoHide>
				<PaymentRequestsOnboarding />
			</Scrollbars>
		);
	}

	return (
		<>
			<div className="relative flex flex-col h-full">
				<div className="p-3 mt-16 text-sm text-yellow-600 bg-yellow-100 md:hidden">
					<FontAwesomeIcon icon="exclamation-triangle" className="mr-2" />
					<FormattedMessage id="mobile_warning" />
				</div>

				<div className="flex-1 h-full md:flex md:flex-row">
					<aside className="mt-8 w-36 sm:w-64">
						<Scrollbars autoHide>
							<Sidebar className="pr-2 mb-6">
								<SidebarLink to="/dashboard/payments" icon="dollar-sign" count={overview?.totals.all}>
									<FormattedMessage id="payment-requests.all" />
								</SidebarLink>
								<SidebarLink to={{ pathname: '/dashboard/payments', search: 'filter=unpayed' }} icon="paper-plane" count={overview?.totals.unpayed}>
									<FormattedMessage id="payment-requests.unpayed" />
								</SidebarLink>
								<SidebarLink to={{ pathname: '/dashboard/payments', search: 'filter=payed' }} icon="check-circle" count={overview?.totals.payed}>
									<FormattedMessage id="payment-requests.payed" />
								</SidebarLink>
								<SidebarLink to={{ pathname: '/dashboard/payments', search: 'filter=refunded' }} icon="undo" count={overview?.totals.refunded}>
									<FormattedMessage id="payment-requests.refunded" />
								</SidebarLink>
								<SidebarLink to={{ pathname: '/dashboard/payments', search: 'filter=archived' }} icon="inbox-in" count={overview?.totals.archived}>
									<FormattedMessage id="payment-requests.archived" />
								</SidebarLink>

								{account!.isAdmin() && (
									<div className="mt-6">
										<div className="h-px bg-gradient-to-r from-gray-300 to-gray-200" />
										<div className="flex flex-col items-start px-4 py-6 space-y-2">
											<label className="text-sm font-medium text-gray-600">
												<FormattedMessage id="sign-requests-crud.show_org" />
											</label>
											<Toggle size={Size.sm} checked={scope === 'organization'} onChange={e => setScope(e.target.checked ? 'organization' : 'user')} />
										</div>
									</div>
								)}
							</Sidebar>
						</Scrollbars>
					</aside>

					<article className="flex-1 h-full">
						<Scrollbars autoHide>
							<div className="pb-6 mt-8 md:px-8 md:pb-12">
								<PaymentRequestDashboard view={scope} showTabs={false} onCreate={() => setShowCreatePayment(true)} />
							</div>
						</Scrollbars>
					</article>
				</div>
			</div>

			<Modal isOpen={showCreatePayment} size={ModalSize.Small} closeable={true} onAfterClose={() => setShowCreatePayment(false)}>
				<ModalHeaderOnlyTitle>
					<FormattedMessage id="payment-requests.create" />
				</ModalHeaderOnlyTitle>
				<ModalBody className="prose max-w-none">
					<p>
						<FormattedMessage id="payment-requests.create_body" />
					</p>
				</ModalBody>
				<ModalFooter>
					<Button variant={Variant.dark} intent={Intent.primary} type="button" onClick={() => setShowCreatePayment(false)}>
						<FormattedMessage id="ok" />
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};
