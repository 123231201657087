import { CheckoutButton, type ITEM_IDS, type ModuleType, useCart, useStore } from '@components/Checkout';
import AppContext from '@contexts/AppContext';
import { Button, InputGroup, Intent, Select, Size, Variant } from '@convoflo/ui';
import { filesize } from '@utils/StringUtils';
import { type ChangeEvent, type FC, useContext, useEffect } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { defaultCurrency, extraStorageBlock } from '../../constants';
import { Header } from './Header';

export const ExtrasStep: FC = () => {
	const { formatMessage, locale } = useIntl();
	const { setPageTitle: setTitle } = useContext(AppContext);
	const { store } = useStore();
	const { getItemById, addOrUpdateItem, removeItemById } = useCart();
	const history = useHistory();
	const { search } = useLocation();

	const modulePrice = (module: ModuleType) => {
		let price = module.amount;
		if (module.RebateType === 'percentage') {
			price = module.amount * (1 - module.Rebate);
		}
		return price;
	};

	const onExtraChanged = (id: ITEM_IDS) => {
		return (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
			event.persist();
			if (parseInt(event.target.value) === 0) {
				removeItemById(id);
				return;
			}
			addOrUpdateItem({ id, quantity: parseInt(event.target.value) });
		};
	};

	useEffect(() => {
		setTitle(formatMessage({ id: 'plans.choose_a_plan' }));
	}, [setTitle, formatMessage]);

	if (!store) {
		return <Redirect to="/account/checkout" />;
	}

	const planItem = getItemById('plan');

	if (!planItem) {
		return <Redirect to="/account/checkout" />;
	}

	const selectedPlan = store.plans.find(plan => plan.Key === planItem.options.key)!;
	const usersModule = getItemById('users');
	const storageModule = getItemById('storage');

	return (
		<div className="max-w-7xl">
			<Header subtitle={<FormattedMessage id="plans.extras_subtitle" />}>
				<FormattedMessage id="plans.add_extras" />
			</Header>

			<div className="grid grid-cols-1 gap-4 mb-12 md:grid-cols-2">
				<CheckoutButton selected={usersModule !== undefined}>
					<div className="flex items-center justify-between mb-2 text-lg">
						<h3 className="font-semibold">
							<FormattedMessage id="plans.additional_users" />
						</h3>

						{usersModule && (
							<div className="text-right">
								{!!store.modules.user.Rebate && (
									<p className="text-sm text-right text-red-600">
										<del>
											<FormattedNumber value={store.modules.user.amount * usersModule.quantity} style="currency" currencyDisplay="narrowSymbol" currency={defaultCurrency} />
										</del>
										{store.modules.user.RebateType === 'percentage' && (
											<span className="px-2 py-1 ml-2 text-xs text-white uppercase bg-red-600 rounded-sm">
												<FormattedNumber value={-store.modules.user.Rebate} style="percent" />
											</span>
										)}
									</p>
								)}
								<span className="font-semibold">
									+<FormattedNumber value={modulePrice(store.modules.user) * usersModule.quantity} style="currency" currency={defaultCurrency} currencyDisplay="narrowSymbol" />
								</span>
								<span className="ml-1 text-xs text-gray-600">
									<FormattedMessage id="plans.payment_frequency_unit" values={{ frequency: 'month' }} />
								</span>
							</div>
						)}
					</div>

					<p className="mb-2 text-sm leading-snug">
						<FormattedMessage id="plans.additional_users_description" values={{ user: selectedPlan.Quota.Users }} />
					</p>

					<InputGroup
						block
						appended={
							<span className="mr-10 text-gray-500">
								<FormattedMessage
									id="plans.additional_users_appended"
									values={{ price: <FormattedNumber value={modulePrice(store.modules.user)} style="currency" currency={defaultCurrency} currencyDisplay="narrowSymbol" /> }}
								/>
							</span>
						}
						value={usersModule?.quantity ?? 0}
						onChange={onExtraChanged('users')}
						type="number"
						min={0}
						max={9999}
						step={1}
					/>
				</CheckoutButton>

				<CheckoutButton selected={storageModule !== undefined}>
					<div className="flex items-center justify-between mb-2 text-lg">
						<h3 className="font-semibold">
							<FormattedMessage id="plans.additional_storage" />
						</h3>

						{storageModule && (
							<div className="text-right">
								{!!store.modules.storage.Rebate && (
									<p className="text-sm text-right text-red-600">
										<del>
											<FormattedNumber value={store.modules.storage.amount * storageModule.quantity} style="currency" currencyDisplay="narrowSymbol" currency={defaultCurrency} />
										</del>
										{store.modules.storage.RebateType === 'percentage' && (
											<span className="px-2 py-1 ml-2 text-xs text-white uppercase bg-red-600 rounded-sm">
												<FormattedNumber value={-store.modules.storage.Rebate} style="percent" />
											</span>
										)}
									</p>
								)}
								<span className="font-semibold">
									+<FormattedNumber value={modulePrice(store.modules.storage) * storageModule.quantity} style="currency" currency={defaultCurrency} currencyDisplay="narrowSymbol" />
								</span>
								<span className="ml-1 text-xs text-gray-600">
									<FormattedMessage id="plans.payment_frequency_unit" values={{ frequency: 'month' }} />
								</span>
							</div>
						)}
					</div>

					<p className="mb-2 text-sm leading-snug">
						<FormattedMessage id="plans.additional_storage_description" values={{ storage: filesize(selectedPlan.Quota.Storage, locale), size: filesize(extraStorageBlock, locale) }} />
					</p>

					<Select value={storageModule?.quantity ?? 0} onChange={onExtraChanged('storage')}>
						<FormattedMessage id="plans.num_signatures" values={{ n: 0 }}>
							{msg => <option value={0}>{msg}</option>}
						</FormattedMessage>
						{[1, 2, 5, 10].map(amount => (
							<option key={amount} value={amount}>
								{filesize(amount * extraStorageBlock, locale)}
							</option>
						))}
					</Select>
				</CheckoutButton>
			</div>

			<div className="flex items-center justify-between my-12">
				<Button
					type="button"
					variant={Variant.light}
					intent={Intent.tertiary}
					iconStart="long-arrow-alt-left"
					animateIcon
					onClick={() => history.push({ pathname: '/account/checkout', search })}>
					<FormattedMessage id="plans.plan_selection" />
				</Button>
				<Button variant={Variant.primary} size={Size.lg} iconEnd="long-arrow-alt-right" animateIcon onClick={() => history.push({ pathname: '/account/checkout/modules', search })}>
					<FormattedMessage id="plans.modules" />
				</Button>
			</div>
		</div>
	);
};
