import { DateTimeDisplay } from '@components/DateTime';
import { DialogTeamMemberDelete, type OrganizationUser, Role, TeamMemberForm } from '@components/Team';
import { Badge, Button, Intent, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown, { DropdownGroup, DropdownItem } from '@ui/Dropdown';
import { TableCell } from '@ui/Table';
import UserAvatar from '@ui/UserAvatar';
import classNames from 'classnames';
import { type FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

type TeamMemberItemProps = {
	user: OrganizationUser;
};

export const TeamMemberItem: FC<TeamMemberItemProps> = ({ user }) => {
	const [showEditForm, setShowEditForm] = useState(false);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

	return (
		<>
			<tr>
				<TableCell>
					<div className="flex items-center gap-3">
						<UserAvatar user={user} size={Size.sm} />
						<div>
							<h5 className="inline-flex items-center gap-2 font-semibold break-words">
								{user.Name || (
									<span className="italic text-gray-600">
										<FormattedMessage id="user-manager.no-name" />
									</span>
								)}

								{user.Locked && (
									<Badge variant={Variant.danger} className="inline-flex items-center">
										<FontAwesomeIcon icon="lock" className="mr-1" size="sm" />
										<FormattedMessage id="user-manager.account-locked" />
									</Badge>
								)}
							</h5>
							<p className="text-sm text-gray-500 break-words">{user.Email}</p>
						</div>
					</div>
				</TableCell>
				<TableCell>
					<Badge variant={user.Role === Role.admin ? Variant.success : user.Role === Role.regular ? Variant.primary : Variant.light}>
						<FormattedMessage id="role" values={{ role: user.Role }} />
					</Badge>
				</TableCell>
				<TableCell>
					<Badge variant={user.MFA ? Variant.success : Variant.danger}>{user.MFA ? <FormattedMessage id="activated" /> : <FormattedMessage id="deactivated" />}</Badge>
				</TableCell>
				<TableCell>
					<span className="text-sm text-gray-600">
						<DateTimeDisplay value={user.created_at} />
					</span>
				</TableCell>
				<TableCell className="text-right">
					<Dropdown>
						<Button variant={Variant.light} intent={Intent.tertiary} type="button" circle>
							<FontAwesomeIcon icon="ellipsis-v" className="text-gray-500" />
						</Button>
						<DropdownGroup>
							<DropdownItem icon="edit" onClick={() => setShowEditForm(true)}>
								<FormattedMessage id="user-manager.edit" />
							</DropdownItem>

							<DropdownItem icon="trash-alt" onClick={() => setShowDeleteConfirmation(true)}>
								<FormattedMessage id="delete" />
							</DropdownItem>
						</DropdownGroup>
					</Dropdown>
				</TableCell>
			</tr>
			{showEditForm && <TeamMemberForm user={user} onAfterClose={() => setShowEditForm(false)} />}
			{showDeleteConfirmation && <DialogTeamMemberDelete user={user} onAfterClose={() => setShowDeleteConfirmation(false)} />}
		</>
	);
};
