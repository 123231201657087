import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import App from './App';
import AppProviders from './AppProviders';
import './assets/icons';
import './css/index.css';

Sentry.init({
	dsn: 'https://3d97fc2c2651ecfc91c477938dff962d@o4508847409004544.ingest.de.sentry.io/4508847428599888',
	integrations: [Sentry.browserTracingIntegration({ traceFetch: false }), Sentry.replayIntegration()],
	// Tracing
	tracesSampleRate: 0.05, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 0.05, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	release: `convoflo@${APP_VERSION}`
});

// Polyfills
if (!('Locale' in Intl)) {
	import('@formatjs/intl-locale/polyfill');
}

if (!('ListFormat' in Intl)) {
	import('@formatjs/intl-listformat/polyfill');
	import('@formatjs/intl-listformat/locale-data/en');
	import('@formatjs/intl-listformat/locale-data/fr');
}

if (!('PluralRules' in Intl)) {
	import('@formatjs/intl-pluralrules/polyfill');
	import('@formatjs/intl-pluralrules/locale-data/en');
	import('@formatjs/intl-pluralrules/locale-data/fr');
}

if (!('DisplayNames' in Intl)) {
	import('@formatjs/intl-displaynames/polyfill');
	import('@formatjs/intl-displaynames/locale-data/en');
	import('@formatjs/intl-displaynames/locale-data/fr');
}

createRoot(document.getElementById('root')!, {
	// Callback called when an error is thrown and not caught by an ErrorBoundary.
	onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
		console.warn('Uncaught error', error, errorInfo.componentStack);
	}),
	// Callback called when React catches an error in an ErrorBoundary.
	onCaughtError: Sentry.reactErrorHandler(),
	// Callback called when React automatically recovers from errors.
	onRecoverableError: Sentry.reactErrorHandler()
}).render(
	<AppProviders>
		<App />
	</AppProviders>
);
