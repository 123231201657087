import { useCurrentEditor } from '@tiptap/react';
import {
	CannedMessagesToolbarDropdown,
	EmojiPickerDropdown,
	ImageToolbarButton,
	LinkToolbarButton,
	OrderedListToolbarButton,
	TaskListToolbarButton,
	ToolbarButton,
	ToolbarDropdown,
	UnorderedListToolbarButton
} from '@ui/RichTextEditor/Toolbar';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

type ToolbarProps = {
	showCannedMessages?: boolean;
};

export const Toolbar: FC<ToolbarProps> = ({ showCannedMessages = true }) => {
	const { editor } = useCurrentEditor();

	if (editor === null) {
		return null;
	}

	const undo = () => {
		editor.commands.undo();
	};

	const redo = () => {
		editor.commands.redo();
	};

	return (
		<div className="flex items-center px-2 py-1 mb-2 overflow-x-auto text-gray-600 transition-opacity opacity-75 hover:opacity-100 bg-gray-50">
			<div className="flex items-center gap-x-0.5 flex-nowrap">
				<ToolbarDropdown>
					<ToolbarButton handler={() => editor.chain().focus().setParagraph().run()} icon="paragraph" type={['paragraph']} />
					<ToolbarButton handler={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} icon={['far', 'h1']} type={['heading', { level: 1 }]}>
						<FormattedMessage id="text-editor.heading" values={{ level: 1 }} />
					</ToolbarButton>
					<ToolbarButton handler={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} type={['heading', { level: 2 }]} icon={['far', 'h2']}>
						<FormattedMessage id="text-editor.heading" values={{ level: 2 }} />
					</ToolbarButton>
					<ToolbarButton handler={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} type={['heading', { level: 3 }]} icon={['far', 'h3']}>
						<FormattedMessage id="text-editor.heading" values={{ level: 3 }} />
					</ToolbarButton>
					<ToolbarButton handler={() => editor.chain().focus().toggleCodeBlock().run()} icon={['far', 'code']} type={['codeBlock']} />
				</ToolbarDropdown>
			</div>
			<div className="flex items-center ml-2 border-l pl-2 gap-x-0.5">
				<ToolbarButton handler={() => editor.chain().focus().toggleBold().run()} icon="bold" type={['bold']} title={<FormattedMessage id="text-editor.tooltip.bold" />} />
				<ToolbarButton handler={() => editor.chain().focus().toggleItalic().run()} icon={['far', 'italic']} type={['italic']} title={<FormattedMessage id="text-editor.tooltip.italic" />} />
				<ToolbarButton
					handler={() => editor.chain().focus().toggleStrike().run()}
					icon={['far', 'strikethrough']}
					type={['strike']}
					title={<FormattedMessage id="text-editor.tooltip.strikethrough" />}
				/>
				<ToolbarButton handler={() => editor.chain().focus().toggleBlockquote().run()} icon="quote-left" type={['blockquote']} />
			</div>
			<div className="flex items-center ml-2 border-l pl-2 gap-x-0.5">
				<LinkToolbarButton icon={['far', 'link']} title={<FormattedMessage id="text-editor.tooltip.link" />} />
				<ImageToolbarButton icon="image" title={<FormattedMessage id="text-editor.tooltip.image" />} />
			</div>
			<div className="flex items-center gap-x-0.5 ml-2 border-l pl-2">
				<UnorderedListToolbarButton icon="list" title={<FormattedMessage id="text-editor.tooltip.bulletList" />} />
				<OrderedListToolbarButton icon="list-ol" title={<FormattedMessage id="text-editor.tooltip.orderedList" />} />
				<TaskListToolbarButton icon="check-square" title={<FormattedMessage id="text-editor.tooltip.taskList" />} />
				<ToolbarButton handler={() => editor.chain().focus().setHorizontalRule().run()} icon="horizontal-rule" title={<FormattedMessage id="text-editor.tooltip.hr" />} />
			</div>
			<div className="flex items-center gap-x-0.5 ml-2 border-l pl-2">
				<ToolbarButton icon={['far', 'reply']} title={<FormattedMessage id="text-editor.tooltip.undo" />} onClick={undo} />
				<ToolbarButton icon={['far', 'share']} title={<FormattedMessage id="text-editor.tooltip.redo" />} onClick={redo} />
			</div>
			<div className="flex items-center gap-x-0.5 ml-2 border-l pl-2">
				<EmojiPickerDropdown />
				{showCannedMessages && <CannedMessagesToolbarDropdown />}
			</div>
		</div>
	);
};
