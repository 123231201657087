import { SignRequestsPromo } from '@components/SignRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { Module } from '@types';
import EmptyState from '@ui/EmptyState';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { LayoutItem } from './LayoutItem';

export type Layout = {
	id: string;
	name: string;
	description: string;
	created: string;
};

type SignatureLayoutsDashboardProps = {};

export const SignatureLayoutsDashboard: FC<SignatureLayoutsDashboardProps> = () => {
	const { client } = useClient();
	const { account } = useAccount();

	const { data: layouts = [], isLoading } = useQuery(['sign-requests', 'layouts'], async () => await client.url('account/layouts').get().json<Layout[]>(), {
		enabled: account!.business.hasModule(Module.Esign),
		staleTime: Infinity
	});

	if (!account!.business.hasModule(Module.Esign)) {
		return <SignRequestsPromo />;
	}

	if (isLoading) {
		return (
			<div className="flex items-center justify-center h-48">
				<FontAwesomeIcon icon="spinner-third" spin size="3x" className="text-gray-500" />
			</div>
		);
	}

	if (layouts?.length === 0) {
		return <EmptyState icon="signature" className="lg:col-span-2" title={<FormattedMessage id="sign-requests-crud.no_results" />} />;
	}

	return (
		<div className="grid grid-cols-2 gap-4">
			{layouts.map(layout => (
				<LayoutItem key={layout.id} layout={layout} />
			))}
		</div>
	);
};
