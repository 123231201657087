import { WizardContext } from '@components/Wizard';
import { Children, type FC, type PropsWithChildren, type ReactElement, cloneElement, memo, useEffect, useMemo, useRef, useState } from 'react';

type WizardProps = {
	startIndex?: number;
	wrapper?: ReactElement;
	onStep?: (step: number) => void;
};

export const Wizard: FC<PropsWithChildren<WizardProps>> = memo(({ children, wrapper: Wrapper, startIndex = 0, onStep = () => undefined }) => {
	const [step, setStep] = useState(startIndex);
	const hasNextStep = useRef(true);
	const hasPreviousStep = useRef(false);
	const stepCount = Children.toArray(children).length;

	hasNextStep.current = step < stepCount - 1;
	hasPreviousStep.current = step > 0;

	const goToNextStep = useRef(() => {
		if (hasNextStep.current) {
			setStep(step => step + 1);
		}
	});

	const goToPreviousStep = useRef(() => {
		if (hasPreviousStep.current) {
			setStep(step => step - 1);
		}
	});

	const goToStep = useRef((stepIndex: number) => {
		if (stepIndex >= 0 && stepIndex < stepCount) {
			setStep(stepIndex);
		}
	});

	const doNextStep = useRef(() => {
		goToNextStep.current();
	});

	const wizardValue = useMemo(
		() => ({
			nextStep: doNextStep.current,
			previousStep: goToPreviousStep.current,
			step,
			stepCount,
			isFirstStep: !hasPreviousStep.current,
			isLastStep: !hasNextStep.current,
			goToStep: goToStep.current
		}),
		[step, stepCount]
	);

	useEffect(() => {
		onStep(step);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [step]);

	const activeStepContent = useMemo(() => Children.toArray(children)[step], [step, children]);

	const enhancedActiveStepContent = useMemo(() => (Wrapper ? cloneElement(Wrapper, { children: activeStepContent }) : activeStepContent), [Wrapper, activeStepContent]);

	return <WizardContext.Provider value={wizardValue}>{enhancedActiveStepContent}</WizardContext.Provider>;
});
