import { Size } from '@convoflo/ui';
import type { IElevatedUser } from '@models/User';
import UserAvatar from '@ui/UserAvatar';
import classNames from 'classnames';
import { type OptionProps, components } from 'react-select';

export const UserOption = <T extends IElevatedUser>({ data: user, innerProps, isSelected, isFocused, ...props }: OptionProps<T, true>) => {
	const className = classNames(innerProps.className, { '!bg-theme-primary': isSelected, '!bg-gray-100': isFocused });
	return (
		<components.Option innerProps={{ ...innerProps, className }} isFocused={isFocused} isSelected={isSelected} data={user} {...props}>
			<div className="flex items-center space-x-2">
				<UserAvatar user={user} size={Size.sm} />
				<div className="flex flex-col">
					<h5 className={classNames('text-sm font-semibold', { 'text-white': isSelected })}>{user.Name || user.Email}</h5>
					{user.Name && <p className={classNames('text-xs font-light', { 'text-white': isSelected, 'text-gray-500': !isSelected })}> {user.Email}</p>}
				</div>
			</div>
		</components.Option>
	);
};
