import { useAccount } from '@hooks/useAccount';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export const UnpaidSubscriptionBanner = () => {
	const { account } = useAccount();

	if (!account!.business.hasUnpaidSubscription() || !account!.hasFullAccess() || !account!.isAdmin()) {
		return null;
	}

	if (account!.isAdmin()) {
		return (
			<div className="bg-red-200">
				<p className="px-4 py-2 text-xs text-center text-red-700 md:text-base">
					<FormattedMessage
						id="banner.subscription_unpaid_admin"
						values={{
							link: msg => (
								<Link to="/organization/subscription" className="font-bold underline transition-colors focus:outline-none hover:text-red-600 focus:text-red-800">
									{msg}
								</Link>
							)
						}}
					/>
				</p>
			</div>
		);
	}

	return (
		<div className="bg-red-200">
			<p className="px-4 py-2 text-xs text-center text-red-700 md:text-base">
				<FormattedMessage id="banner.subscription_unpaid" />
			</p>
		</div>
	);
};
