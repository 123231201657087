import colors from '@assets/color-palette.json';
import { FileManagerContext } from '@components/FileManager';
import { LabelSelector, type LabelType, useLabels } from '@components/Labels';
import { Alert, Button, HelperText, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeader, type ModalProps, Row, Select, Size, Toggle, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useView } from '@hooks/use-view';
import useClient from '@hooks/useClient';
import Folder from '@models/Folder';
import { versionMiddleware } from '@service/Client';
import { Tab } from '@ui/Tab';
import { Table, TableCell } from '@ui/Table';
import { formatLocalDateToUTC } from '@utils/DateUtils';
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import uniqBy from 'lodash.uniqby';
import React, { type FC, type FormEvent, useContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHotkeys } from 'react-hotkeys-hook';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import type { WretchError } from 'wretch';

type DialogFolderSettingsProps = Omit<ModalProps, 'isOpen'> & {
	folder: Folder;
	onUpdated?: (folder: Folder) => void;
};

type SaveFolderMutation = Partial<{
	isSecureSpace: boolean;
	isPublic: boolean;
	isIncreasedSecurity: boolean;
	expiresAt: string | null;
	friendlyUrl: string | null;
	isReadonlyMessages: boolean;
	messagesMode: number;
	expiryInterval: number;
	onCreatedLabels: LabelType[];
	onViewedLabels: LabelType[];
	onCommentedLabels: LabelType[];
	color: string | null;
}>;

export const DialogFolderSettings: FC<DialogFolderSettingsProps> = ({ folder, onUpdated = () => undefined, ...modalProps }) => {
	const { client } = useClient();
	const { update: updateItem = () => undefined } = useContext(FileManagerContext) ?? {};
	const { view, update: updateView } = useView();
	const { formatMessage } = useIntl();

	const { props = [] } = useLabels();

	const [isOpen, setIsOpen] = useState(true);
	const [showMetadata, setShowMetadata] = useState(false);
	const [isSecureSpace, setIsSecureSpace] = useState(!!folder.SecuredSpace);
	const [isPublic, setIsPublic] = useState(folder.Access === 'public');
	const [isIncreasedSecurity, setIsIncreasedSecurity] = useState(!!folder.IncreasedSecurity);
	const [isReadonlyMessages, setIsReadonlyMessages] = useState(folder.ReadonlyMessages);
	const [messagesMode, setMessagesMode] = useState(folder.MessagesMode);
	const [color, setColor] = useState(folder.pivot?.Color ?? null);
	const [onCreatedLabels, setOnCreatedLabels] = useState<LabelType[]>(folder.Defaults?.file?.created?.properties ?? []);
	const [onViewedLabels, setOnViewedLabels] = useState<LabelType[]>(folder.Defaults?.file?.viewed?.properties ?? []);
	const [onCommentedLabels, setOnCommentedLabels] = useState<LabelType[]>(folder.Defaults?.commented?.properties ?? []);
	const [expiresAt, setExpiresAt] = useState(folder.expires_at ? format(parseISO(folder.expires_at), 'yyyy-MM-dd') : '');
	const [expiryIntervalDays, setExpiryIntervalDays] = useState(parseIntervalInDays(folder.ExpiryInterval));

	useHotkeys('m', () => {
		setShowMetadata(true);
	});

	const { mutate: save, isLoading: isSaving } = useMutation<Folder, WretchError, SaveFolderMutation>(
		async ({ isSecureSpace, isPublic, isIncreasedSecurity, isReadonlyMessages, messagesMode, color, onCommentedLabels, onCreatedLabels, onViewedLabels, expiresAt, expiryInterval }) =>
			new Folder(
				await client
					.url(folder.getRoute(null))
					.middlewares([versionMiddleware(2)])
					.json({
						color,
						secured_space: isSecureSpace,
						visibility: isPublic ? 'public' : 'private',
						IncreasedSecurity: isIncreasedSecurity,
						readonly_messages: isReadonlyMessages,
						messages_mode: messagesMode,
						defaults: {
							file: {
								created: { properties: (onCreatedLabels ?? []).map(prop => prop.Id) },
								viewed: { properties: (onViewedLabels ?? []).map(prop => prop.Id) }
							},
							commented: { properties: (onCommentedLabels ?? []).map(prop => prop.Id) }
						},
						expiry_interval: expiryInterval,
						expires_at: expiresAt ? formatLocalDateToUTC(expiresAt).toUTCString() : null
					})
					.put()
					.json()
			),
		{
			onError: error => console.error(error),
			onSuccess: folder => {
				setIsSecureSpace(!!folder.SecuredSpace);
				setIsPublic(folder.Access === 'public');
				setIsIncreasedSecurity(!!folder.IncreasedSecurity);
				setIsReadonlyMessages(folder.ReadonlyMessages);
				setMessagesMode(folder.MessagesMode);
				setColor(color => folder.pivot?.Color ?? color);
				setOnCreatedLabels(labels => folder.Defaults?.file?.created?.properties ?? labels);
				setOnViewedLabels(labels => folder.Defaults?.file?.viewed?.properties ?? labels);
				setOnCommentedLabels(labels => folder.Defaults?.commented?.properties ?? labels);
				toast.success(<FormattedMessage id="file-manager.settings_updated" values={{ item: folder.getName() }} />);

				updateItem(folder);

				if (view instanceof Folder && folder.getKey() === view.getKey()) {
					updateView(folder);
				}

				onUpdated(folder);
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		save({
			isSecureSpace,
			isIncreasedSecurity,
			isPublic,
			isReadonlyMessages,
			messagesMode,
			color,
			onCommentedLabels,
			onCreatedLabels,
			onViewedLabels,
			expiresAt,
			expiryInterval: expiryIntervalDays ?? undefined
		});
	};

	useEffect(() => {
		if (!props || props.length === 0 || (!folder.Defaults?.file?.created?.properties && !folder.Defaults?.file?.viewed?.properties && !folder.Defaults?.commented?.properties)) {
			return;
		}

		const _onCreatedLabels: LabelType[] = [];
		const _onViewedLabels: LabelType[] = [];
		const _onCommentedLabels: LabelType[] = [];

		props.forEach(prop => {
			prop.options?.forEach(option => {
				if ((folder.Defaults?.file?.created?.properties ?? []).some((prop: string) => prop === option.Id)) {
					_onCreatedLabels.push(option);
				}
				if ((folder.Defaults?.file?.viewed?.properties ?? []).some((prop: string) => prop === option.Id)) {
					_onViewedLabels.push(option);
				}
				if ((folder.Defaults?.commented?.properties ?? []).some((prop: string) => prop === option.Id)) {
					_onCommentedLabels.push(option);
				}
			});
		});

		setOnCreatedLabels(_onCreatedLabels);
		setOnViewedLabels(_onViewedLabels);
		setOnCommentedLabels(_onCommentedLabels);
	}, [props, folder]);

	return (
		<Modal isOpen={isOpen} onSubmit={onSubmit} {...modalProps}>
			<Tab.Group as={React.Fragment}>
				<ModalHeader className="flex-col !justify-normal !items-stretch">
					<h3 className="text-lg font-medium text-gray-900">
						{folder.icon('mr-2')}
						{folder.getName()}
					</h3>

					<Tab.List className="mt-4 -mx-8">
						<Tab>
							<FormattedMessage id="folder-settings.settings" />
						</Tab>
						{folder.SecuredSpace && (
							<Tab>
								<FormattedMessage id="folder-settings.automation" />
							</Tab>
						)}
						{showMetadata && (
							<Tab>
								<FormattedMessage id="folder-settings.metadata" />
							</Tab>
						)}
					</Tab.List>
				</ModalHeader>
				<ModalBody>
					<Tab.Panels>
						<Tab.Panel>
							{isSecureSpace ? (
								<div className="space-y-12">
									<section>
										<div className="flex items-center mb-4 space-x-3">
											<h3 className="text-xs font-medium text-gray-400 uppercase">
												<FormattedMessage id="folder-settings.permissions_security" />
											</h3>
										</div>
										<Row>
											<Label htmlFor="folder-permissions">
												<FormattedMessage id="folder-settings.scope_public" />
												<Toggle size={Size.sm} className="ml-4" checked={isPublic} onChange={e => setIsPublic(e.target.checked)} />
											</Label>
											<HelperText>
												<FormattedMessage id="folder-settings.scope_public_desc" />
											</HelperText>
										</Row>
										<Row>
											<Label htmlFor="readonly_messages">
												<FormattedMessage id="folder-settings.readonly_messages" />
												<Toggle
													size={Size.sm}
													className="ml-4"
													checked={isReadonlyMessages}
													onChange={e => {
														setIsReadonlyMessages(e.target.checked);
													}}
												/>
											</Label>
											<HelperText>
												<FormattedMessage id="folder-settings.readonly_messages_description" />
											</HelperText>
										</Row>
										<Row>
											<Label htmlFor="messages_mode">
												<FormattedMessage id="folder-settings.messages_mode" />
												<Select size={Size.sm} className="ml-4" value={messagesMode.toString()} onChange={e => setMessagesMode(parseInt(e.target.value))}>
													<FormattedMessage id="folder-settings.messages_mode_readwrite">{msg => <option value="1">{msg}</option>}</FormattedMessage>
													<FormattedMessage id="folder-settings.messages_mode_all">{msg => <option value="0">{msg}</option>}</FormattedMessage>
												</Select>
											</Label>
											<HelperText>
												<FormattedMessage id="folder-settings.messages_mode_description" />
											</HelperText>
										</Row>
										<Row>
											<Label htmlFor="IncreasedSecurity">
												<FormattedMessage id="folder-settings.increased_security" />
												<Toggle size={Size.sm} className="ml-4" checked={isIncreasedSecurity} onChange={e => setIsIncreasedSecurity(e.target.checked)} />
											</Label>
											<HelperText>
												<FormattedMessage id="folder-settings.increased_security_description" />
											</HelperText>
										</Row>
									</section>
									<section>
										<div className="flex items-center mb-4 space-x-3">
											<h3 className="text-xs font-medium text-gray-400 uppercase">
												<FormattedMessage id="account.danger_zone" />
											</h3>
										</div>
										<Row>
											<Label>
												<Button type="button" variant={Variant.danger} intent={Intent.secondary} size={Size.sm} onClick={() => setIsSecureSpace(false)}>
													<FormattedMessage id="folder-settings.convert_to_folder" />
												</Button>
											</Label>
											<HelperText>
												<FormattedMessage id="folder-settings.convert_to_folder_desc" />
											</HelperText>
										</Row>
									</section>
								</div>
							) : (
								<>
									<Row>
										<Button type="button" variant={Variant.success} intent={Intent.primary} onClick={() => setIsSecureSpace(true)} className="mb-4">
											<FormattedMessage id="folder-settings.convert_to_secure_space" />
										</Button>
										<HelperText>
											<FormattedMessage id="folder-settings.convert_to_secure_space_desc" />
										</HelperText>
									</Row>

									<Row>
										<Label htmlFor="color">
											<FormattedMessage id="folder-settings.color" />
										</Label>
										<div className="flex flex-wrap items-center">
											{colors.map(_color => (
												<button
													type="button"
													className={classNames('inline-flex items-center justify-center w-12 h-12 text-lg rounded-full hover:bg-gray-200 transition-colors', {
														'bg-gray-200': color === _color.value
													})}
													onClick={() => setColor(_color.value)}>
													<FontAwesomeIcon icon="folder" className={_color.textClassName} size="lg" />
												</button>
											))}
											<button
												type="button"
												onClick={() => setColor(null)}
												className="inline-flex items-center justify-center w-12 h-12 text-lg transition-colors rounded-full hover:bg-gray-200">
												<FontAwesomeIcon icon="times" className="text-red-600" />
											</button>
										</div>
										<HelperText>
											<FormattedMessage id="folder-settings.color-description" />
										</HelperText>
									</Row>
								</>
							)}
						</Tab.Panel>
						{folder.SecuredSpace && (
							<Tab.Panel>
								<div className="space-y-12">
									<section>
										<div className="flex items-center mb-4 space-x-3">
											<h3 className="text-xs font-medium text-gray-400 uppercase">
												<FormattedMessage id="folder-settings.upload_labels" />
											</h3>
										</div>

										<Row>
											<div className="flex items-baseline space-x-1">
												<p>
													<FormattedMessage id="folder-settings.apply_labels" />
												</p>
												<div className="flex items-center gap-0.5 flex-wrap flex-1 px-2 py-1 mx-1 text-sm text-center border-0 border-b-2 border-gray-200 rounded bg-gray-50 focus-within:border-theme-primary focus:ring-0">
													{onCreatedLabels.map(property => (
														<LabelSelector
															key={property.Id}
															label={property}
															onChange={prop => setOnCreatedLabels(onCreatedLabels.map(_prop => (_prop.Id === property.Id ? prop : _prop)))}
															onRemove={prop => setOnCreatedLabels(onCreatedLabels.filter(_prop => _prop.Id !== prop.Id))}
														/>
													))}
													<LabelSelector key={onCreatedLabels.length} onChange={label => setOnCreatedLabels(uniqBy(onCreatedLabels.concat(label), prop => prop.Id))} />
												</div>
											</div>
											<HelperText>
												<FormattedMessage id="folder-settings.upload_labels_description" />
											</HelperText>
										</Row>
									</section>
									<section>
										<div className="flex items-center mb-4 space-x-3">
											<h3 className="text-xs font-medium text-gray-400 uppercase">
												<FormattedMessage id="folder-settings.download_labels" />
											</h3>
										</div>
										<Row>
											<div className="flex items-baseline space-x-1">
												<p>
													<FormattedMessage id="folder-settings.apply_labels" />
												</p>
												<div className="flex items-center gap-0.5 flex-wrap flex-1 px-2 py-1 mx-1 text-sm text-center border-0 border-b-2 border-gray-200 rounded bg-gray-50 focus-within:border-theme-primary focus:ring-0">
													{onViewedLabels.map(property => (
														<LabelSelector
															key={property.Id}
															label={property}
															onChange={prop => setOnViewedLabels(onViewedLabels.map(_prop => (_prop.Id === property.Id ? prop : _prop)))}
															onRemove={prop => setOnViewedLabels(onViewedLabels.filter(_prop => _prop.Id !== prop.Id))}
														/>
													))}
													<LabelSelector key={onViewedLabels.length} onChange={property => setOnViewedLabels(uniqBy(onViewedLabels.concat(property), prop => prop.Id))} />
												</div>
											</div>
											<HelperText>
												<FormattedMessage id="folder-settings.download_labels_description" />
											</HelperText>
										</Row>
									</section>
									<section>
										<div className="flex items-center mb-4 space-x-3">
											<h3 className="text-xs font-medium text-gray-400 uppercase">
												<FormattedMessage id="folder-settings.commented_labels" />
											</h3>
										</div>
										<Row>
											<div className="flex items-baseline space-x-1">
												<p>
													<FormattedMessage id="folder-settings.apply_labels" />
												</p>
												<div className="flex items-center gap-0.5 flex-wrap flex-1 px-2 py-1 mx-1 text-sm text-center border-0 border-b-2 border-gray-200 rounded bg-gray-50 focus-within:border-theme-primary focus:ring-0">
													{onCommentedLabels.map(property => (
														<LabelSelector
															key={property.Id}
															label={property}
															onChange={prop => setOnCommentedLabels(onCommentedLabels.map(_prop => (_prop.Id === property.Id ? prop : _prop)))}
															onRemove={prop => setOnCommentedLabels(onCommentedLabels.filter(_prop => _prop.Id !== prop.Id))}
														/>
													))}
													<LabelSelector
														key={onCommentedLabels.length}
														onChange={property => setOnCommentedLabels(uniqBy(onCommentedLabels.concat(property), prop => prop.Id))}
													/>
												</div>
											</div>
											<HelperText>
												<FormattedMessage id="folder-settings.download_labels_description" />
											</HelperText>
										</Row>
									</section>
									<section>
										<div className="flex items-center mb-4 space-x-3">
											<h3 className="text-xs font-medium text-gray-400 uppercase">
												<FormattedMessage id="folder-settings.auto_delete" />
											</h3>
										</div>

										<Row>
											<p>
												<FormattedMessage
													id="folder-settings.expiration_date"
													values={{
														input: (
															<input
																type="date"
																className="w-32 px-0 py-1 mx-1 text-sm text-center border-0 border-b-2 border-gray-200 rounded bg-gray-50 focus-within:border-theme-primary focus:ring-0"
																value={expiresAt}
																placeholder={formatMessage({ id: 'folder-settings.select_date' })}
																onChange={e => setExpiresAt(e.target.value)}
															/>
														)
													}}
												/>
											</p>
											{!!folder.expires_by && (
												<Alert variant={Variant.warning} className="mt-2">
													<FormattedMessage
														id="folder-settings.expiration_date_description"
														values={{
															name: folder.expires_by.Name,
															strong: msg => <span className="font-semibold">{msg}</span>
														}}
													/>
												</Alert>
											)}
										</Row>
										<Row>
											<p>
												<FormattedMessage
													id="folder-settings.expiry_interval"
													values={{
														days: expiryIntervalDays ?? 0,
														input: (
															<input
																type="number"
																min={0}
																max={366}
																className="w-16 px-0 py-1 mx-1 text-sm text-center border-0 border-b-2 border-gray-200 rounded bg-gray-50 focus-within:border-theme-primary focus:ring-0"
																value={expiryIntervalDays ?? ''}
																onChange={e => setExpiryIntervalDays(e.target.valueAsNumber ?? 0)}
															/>
														)
													}}
												/>
											</p>
											<HelperText>
												<FormattedMessage id="folder-settings.expiry_interval_description" values={{ days: expiryIntervalDays || 0 }} />
											</HelperText>
										</Row>
									</section>
								</div>
							</Tab.Panel>
						)}
						{showMetadata && (
							<Tab.Panel>
								<Table>
									{folder.meta?.map(metadata => (
										<tr>
											<TableCell>{metadata.key}</TableCell>
											<TableCell>{metadata.value}</TableCell>
										</tr>
									))}
								</Table>
							</Tab.Panel>
						)}
					</Tab.Panels>
				</ModalBody>
				<ModalFooter>
					<Button variant={Variant.primary} intent={Intent.primary} loading={isSaving} type="submit" disabled={isSaving}>
						<FormattedMessage id="save" />
					</Button>
					<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
						<FormattedMessage id="close" />
					</Button>
				</ModalFooter>
			</Tab.Group>
		</Modal>
	);
};

const parseIntervalInDays = (interval: string | null) => {
	if (interval === null) {
		return null;
	}

	const matches = interval.match(/^P([0-9]+)D$/);

	if (matches && matches[1]) {
		return parseInt(matches[1], 10);
	}

	return null;
};
