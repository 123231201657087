import { Button, Intent, Modal, ModalBody, ModalFooter, type ModalProps, Variant } from '@convoflo/ui';
import { useSubscriptionResumeMutation } from '@state/queries/subscriptions';
import { type FC, type FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';

type DialogGracePeriodProps = Omit<ModalProps, 'isOpen'>;

const DialogGracePeriod: FC<DialogGracePeriodProps> = ({ ...modalProps }) => {
	const [isOpen, setIsOpen] = useState(true);

	const { mutate: resumeSubscription, isLoading: isSubmitting } = useSubscriptionResumeMutation();

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		try {
			resumeSubscription();
			toast.success(<FormattedMessage id="resume-subscription.resumed" />);
			setIsOpen(false);
		} catch {}
	};

	return (
		<Modal onSubmit={onSubmit} isOpen={isOpen} {...modalProps}>
			<ModalBody className="prose max-w-none">
				<p>
					<FormattedMessage id="resume-subscription.body" />
				</p>
			</ModalBody>
			<ModalFooter>
				<Button variant={Variant.success} disabled={isSubmitting}>
					<FormattedMessage id="resume-subscription.resume" />
				</Button>
				<Button type="button" variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} disabled={isSubmitting}>
					<FormattedMessage id="not_now" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default DialogGracePeriod;
