import { type LabelType } from '@components/Labels';
import { type ComposerRef, type ComposerState, RootComposer } from '@components/Message';
import { Button, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, ModalSize, Row, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { useLocalStorage } from '@hooks/useLocalStorage';
import Folder from '@models/Folder';
import ProgressBar from '@ui/ProgressBar';
import { filesize } from '@utils/StringUtils';
import classNames from 'classnames';
import { type FC, type FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { type MenuProps, type MultiValueProps, type OnChangeValue, components } from 'react-select';
import { SecureSpaceOption, WindowedComboBox } from './ComboBox';
import LabelFilter from './Labels/LabelFilter';
import type { UploadFile } from './Upload';

export type SendResult = {
	secureSpaces: Folder[];
	hasFiles: boolean;
	hasMessage: boolean;
	folderForFiles: Folder | null;
};

type DialogRootComposerFormProps = Omit<ModalProps, 'isOpen'> & {
	onAbort?: () => void;
	onSent?: (result: SendResult) => void;
};

export const DialogRootComposer: FC<DialogRootComposerFormProps> = ({ onAbort = () => undefined, onSent = () => undefined, ...modalProps }) => {
	const { locale, formatMessage } = useIntl();
	const { account } = useAccount();
	const { client, setValidation } = useClient();
	const composer = useRef<ComposerRef>(null);
	const [isOpen, setIsOpen] = useState(true);
	const [composerState, setComposerState] = useState<ComposerState>();
	const [secureSpaceIds, setSecureSpaceIds] = useState<number[]>([]);
	const [operator, setOperator] = useLocalStorage('operator', 'and');
	const [labels, setLabels] = useState<LabelType[]>([]);
	const [postable, setPostable] = useState(true);
	const [files, setFiles] = useState<UploadFile[]>([]);
	const [filterInputText, setFilterInputText] = useState('');

	const { data: availableSecureSpaces, isLoading } = useQuery(
		['available_secure_spaces', labels, operator],
		async () => {
			const queryParams = [];

			if (labels.length > 0) {
				queryParams.push(labels.map(label => `properties[]=${label.Id}`).join('&'));
			}

			if (operator) {
				queryParams.push(`operator=${operator}`);
			}

			const response = await client.url('send/securespaces').query(queryParams.join('&')).get().json<Folder[]>();

			return response.map(secureSpace => new Folder(secureSpace));
		},
		{
			staleTime: Infinity
		}
	);

	const onMessagePosted = () => {
		setIsOpen(false);
		toast.success(<FormattedMessage id="dialog.composer.batch.send" />);
	};

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		composer.current?.post();
	};

	const onSecureSpaceChanged = (secureSpaces: OnChangeValue<Folder, true>) => {
		setSecureSpaceIds(secureSpaces.map(secureSpace => secureSpace.ID!));
	};

	const hasAtLeastOneSelected = secureSpaceIds.length > 0;

	const fileable = useMemo(() => {
		if (hasAtLeastOneSelected) {
			return availableSecureSpaces?.[0];
		}
	}, [hasAtLeastOneSelected, availableSecureSpaces]);

	const usage = account!.Usage.ComposerMessages.used + secureSpaceIds.length;

	const [totalAttachmentsSize, allFilesOk] = useMemo(() => {
		return [files.reduce((totalSize, file) => totalSize + (file.file?.size ?? 0), 0), files.every(file => (file.file?.size ?? 0) <= account!.business.plan.Quota.FileSize)];
	}, [account, files]);

	useEffect(() => {
		if (totalAttachmentsSize > account!.Usage.ComposerAttachments.limit) {
			setValidation({
				message: [formatMessage({ id: 'dialog-send.filesize_limit_reached' }, { limit: filesize(account!.Usage.ComposerAttachments.limit, locale) })]
			});
			setPostable(false);
		} else if (account!.Usage.ComposerMessages.used + secureSpaceIds.length > account!.Usage.ComposerMessages.limit) {
			setValidation({
				message: [formatMessage({ id: 'dialog-send.messages_limit_reached' }, { limit: account!.Usage.ComposerMessages.limit })]
			});
			setPostable(false);
		} else if (!allFilesOk) {
			setValidation({
				message: [formatMessage({ id: 'dialog-send.filesize_quota_reached' }, { limit: filesize(account!.business.plan.Quota.FileSize, locale) })]
			});
			setPostable(false);
		} else if (account!.Usage.Space.available !== null && totalAttachmentsSize > account!.Usage.Space.available) {
			setValidation({
				message: [formatMessage({ id: 'dialog-send.composer_storage_quota_reached' }, { limit: filesize(account!.Usage.Space.available, locale) })]
			});
			setPostable(false);
		}
	}, [totalAttachmentsSize, secureSpaceIds.length, usage, account, setValidation, formatMessage, allFilesOk, locale]);

	return (
		<Modal isOpen={isOpen} size={ModalSize.Large} onSubmit={onSubmit} {...modalProps}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="dialog-send.title" />
			</ModalHeaderOnlyTitle>
			<ModalBody>
				<div className="mb-2">
					<LabelFilter labels={labels} setLabels={setLabels} operator={operator} setOperator={setOperator} />
				</div>

				<Row>
					<Label>
						<FormattedMessage id="dialog-send.step_2" />
					</Label>

					<WindowedComboBox<Folder, true>
						closeMenuOnSelect={false}
						hideSelectedOptions={false}
						isMulti
						getOptionLabel={secureSpace => secureSpace.Name}
						getOptionValue={secureSpace => String(secureSpace.getKey()!)}
						components={{
							Option: SecureSpaceOption,
							MultiValue: LimitedMultiValue,
							Menu: SecureSpaceMenu
						}}
						isLoading={isLoading}
						onChange={onSecureSpaceChanged}
						options={availableSecureSpaces}
						placeholder={formatMessage({ id: 'dialog-send.select_secure_spaces' })}
						inputValue={filterInputText}
						onInputChange={(value, action) => {
							if (action.action === 'input-change') {
								setFilterInputText(value);
							}
						}}
						styles={{
							option: base => ({
								...base,
								height: 36
							})
						}}
					/>
				</Row>

				{fileable && (
					<RootComposer
						ref={composer}
						fileable={fileable}
						secureSpaceIds={secureSpaceIds}
						onPosted={onMessagePosted}
						source="compose_root"
						inputProps={{
							placeholder: formatMessage({ id: 'comments.enter_message' }),
							className: 'border-t-0 rounded-t-none !border-gray-200'
						}}
						titleInputProps={{
							className: 'border border-gray-200 pt-2 border-b-0 rounded-t'
						}}
						plugins={{
							attachments: { enabled: true, onFilesChanged: setFiles },
							options: { enabled: true }
						}}
						onStateChanged={setComposerState}
					/>
				)}
			</ModalBody>
			<ModalFooter className="justify-between gap-4">
				<div className="flex items-center gap-4">
					<Button type="submit" variant={Variant.primary} disabled={usage > account!.Usage.ComposerMessages.limit || !composerState?.isPostable || secureSpaceIds.length === 0 || !postable}>
						<FontAwesomeIcon icon="paper-plane" className="mr-2" />
						<FormattedMessage id="dialog-send.send" />
					</Button>
					<Button variant={Variant.light} disabled={composerState?.isPosting === true} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
						<FormattedMessage id="cancel" />
					</Button>
				</div>
				<div className="hidden sm:block">
					<p className="text-xs text-gray-600">
						<FormattedMessage id="dialog-send.daily_limit" />
					</p>
					<ProgressBar className="mt-2" current={(usage / account!.Usage.ComposerMessages.limit) * 100} max={100}>
						<FormattedMessage
							id="x_of_n"
							values={{
								x: usage,
								n: account!.Usage.ComposerMessages.limit
							}}
						/>
					</ProgressBar>
				</div>
			</ModalFooter>
		</Modal>
	);
};

const MoreSelectedBadge = ({ items }: { items: any[] }) => {
	return <div className="px-3 py-1 text-xs font-bold text-white bg-theme-primary-darker rounded-full !ring-1 !ring-theme-primary-darker ml-2">+ {items.length}</div>;
};

const LimitedMultiValue = ({ index, getValue, ...props }: MultiValueProps) => {
	const maxToShow = 3;

	const overflow = getValue().slice(maxToShow);

	return index < maxToShow ? <components.MultiValue index={index} getValue={getValue} {...props} /> : index === maxToShow ? <MoreSelectedBadge items={overflow} /> : null;
};

export const SecureSpaceMenu = ({ children, ...props }: MenuProps<Folder, true>) => {
	const selectAll = () => {
		if (props.selectProps.inputValue) {
			const normalizedTarget = props.selectProps.inputValue
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toLowerCase();

			props.setValue(
				props.options.filter((option: Folder) =>
					option.Name.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '')
						.toLowerCase()
						.includes(normalizedTarget)
				),
				'select-option'
			);
			return;
		}
		props.setValue(props.options, 'select-option');
	};

	const unselectAll = () => {
		props.setValue([], 'select-option');
	};

	return (
		<components.Menu className={classNames(props.className, 'overflow-hidden')} {...props}>
			{children}
			<div className="flex items-center gap-3 p-3 bg-gray-50">
				<button type="button" onClick={selectAll} className="text-xs text-blue-600 hover:underline">
					<FormattedMessage id="file-selector.select_all" />
				</button>
				<div className="self-stretch w-px bg-gray-200" />
				<button type="button" onClick={unselectAll} className="text-xs text-blue-600 hover:underline">
					<FormattedMessage id="file-selector.unselect_all" />
				</button>
			</div>
		</components.Menu>
	);
};
