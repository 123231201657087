import { useCurrentEditor } from '@tiptap/react';
import classNames from 'classnames';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

type CharacterLimitDisplayProps = {
	limit?: number;
};

export const CharacterLimitDisplay: FC<CharacterLimitDisplayProps> = ({ limit }) => {
	const { editor } = useCurrentEditor();

	if (!editor?.storage?.characterCount || !limit) {
		return null;
	}

	const characters = editor.storage.characterCount.characters({});

	const characterCountClassName = classNames('text-xs mx-4', {
		'text-gray-600': (characters / limit) * 100 <= 95,
		'text-yellow-600': (characters / limit) * 100 > 95,
		'text-red-600': characters >= limit
	});

	return (
		<span className={characterCountClassName}>
			<FormattedMessage id="x_of_n" values={{ x: characters, n: limit }} />
		</span>
	);
};
