import { GuidedTourContext } from '@components/GuidedTour';
import { useContext } from 'react';

export const useGuidedTour = () => {
	const context = useContext(GuidedTourContext);

	if (context === undefined) {
		throw new Error('useGuidedTour() must be used within a GuidedTourContext');
	}

	return context;
};
