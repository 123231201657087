import { DateDisplay } from '@components/DateTime';
import DialogGracePeriod from '@components/DialogGracePeriod';
import { DialogCancelSubscription, DialogUpgradePlan } from '@components/Organization';
import { Alert, Button, Intent, Size, Variant } from '@convoflo/ui';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import type { PlansResponseType } from '@pages/trial-expired';
import { Module, type Plan } from '@types';
import Card from '@ui/Card';
import { CircleProgressBar } from '@ui/ProgressBar';
import { filesize } from '@utils/StringUtils';
import classNames from 'classnames';
import { isFuture } from 'date-fns';
import { type FC, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { defaultCurrency, extraStorageBlock } from '../../constants';

export const SubscriptionOverview = () => {
	const { account } = useAccount();
	const { client } = useClient();
	const { locale } = useIntl();
	const history = useHistory();

	const [showCancelSubscription, setShowCancelSubscription] = useState(false);
	const [selectedPlanUpgrade, setSelectedPlanUpgrade] = useState<Plan>();

	const hasActiveSubscription = account!.business.trial_ends_at === null && (account!.business.subscription?.ends_at === null || account!.business.subscription === null);
	const supportPlan = account!.business.subscription?.SupportPlan;

	const { data: plans } = useQuery(['plans', locale], async () => await client.url('plans').get().json<PlansResponseType>());

	const onPlanAction = (plan: Plan) => {
		if (hasActiveSubscription) {
			setSelectedPlanUpgrade(plan);
		} else {
			history.push(`/account/checkout?plan=${plan.Key}`);
		}
	};

	return (
		<>
			<Card>
				<div className="mb-6">
					<SubscriptionStatus />
				</div>
				{plans && (
					<div className="grid grid-cols-2 gap-6 my-6 xl:grid-cols-4">
						{plans.plans.map(plan => (
							<PlanBlock
								key={plan.Id}
								plan={plan}
								active={hasActiveSubscription && plan.Id === account!.business.plan.Id}
								disabled={hasActiveSubscription && plan.Id < account!.business.plan.Id}
								interval={account!.business.subscription?.Interval ?? 'month'}
								onAction={() => onPlanAction(plan)}
							/>
						))}
					</div>
				)}
				<div className="mt-6">
					<h2 className="mb-2 font-medium text-black">
						<FormattedMessage id="plans.included" />
					</h2>
					<div className="grid grid-cols-3 gap-6">
						<div className="rounded-lg ring ring-gray-200">
							<div className="flex flex-col h-full gap-3 p-3">
								<h2 className="text-sm font-bold">
									<FormattedMessage id={`plans.support-${supportPlan ?? 'standard'}`} />
								</h2>
								<div className="flex-1">
									<p className="text-xs text-gray-700">
										<FormattedMessage id={`plans.support-${supportPlan ?? 'standard'}-subtitle`} />
									</p>
								</div>
							</div>
						</div>
						{(account!.business.subscription?.Products.StorageBlocks ?? 0) > 0 && (
							<div className="rounded-lg ring ring-gray-200">
								<div className="flex flex-col h-full gap-3 p-3">
									<h2 className="text-sm font-bold">
										<FormattedMessage id="plans.additional_storage" />
									</h2>
									<div className="flex-1">
										<p className="text-xs text-gray-700">+ {filesize(extraStorageBlock * account!.business.subscription!.Products.StorageBlocks, locale)}</p>
									</div>
									{/* <button type="button" className="text-xs text-left underline text-theme-primary">
										<FormattedMessage id="plans.purchase_more_storage" />
									</button> */}
								</div>
							</div>
						)}
						{account!.business.hasModule(Module.Esign) && (
							<div className="rounded-lg ring ring-gray-200">
								<div className="flex flex-col h-full gap-3 p-3">
									<h2 className="text-sm font-bold">
										<FormattedMessage id="plans.signatures_title" />
									</h2>
									<div className="flex-1"></div>
									<Link to="/dashboard/signatures" className="text-xs text-left underline text-theme-primary">
										<FormattedMessage id="manage_signatures" />
									</Link>
								</div>
							</div>
						)}
						{account!.business.hasModule(Module.Payments) && (
							<div className="rounded-lg ring ring-gray-200">
								<div className="flex flex-col h-full gap-3 p-3">
									<h2 className="text-sm font-bold">
										<FormattedMessage id="plans.payments_title" />
									</h2>
									<div className="flex-1"></div>
									<Link to="/dashboard/payments" className="text-xs text-left underline text-theme-primary">
										<FormattedMessage id="manage_payments" />
									</Link>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="mt-6">
					<h2 className="mb-2 font-medium text-black">
						<FormattedMessage id="plans.limits" />
					</h2>

					<div className="grid grid-cols-3 gap-6">
						<div className="flex flex-col items-center gap-3 p-3 rounded-lg ring ring-gray-200">
							<h2 className="text-sm font-medium">
								<FormattedMessage id="usage.secure_spaces" />
							</h2>
							<CircleProgressBar showPercent max={account!.Usage.SecureSpaces.limit ?? undefined} current={account!.Usage.SecureSpaces.used} className="size-16" />
							<p className="text-xs font-bold">
								<FormattedMessage
									id="x_of_n"
									values={{
										x: account!.Usage.SecureSpaces.used,
										n: account!.Usage.SecureSpaces.limit === null ? <FormattedMessage id="usage.unlimited" /> : account!.Usage.SecureSpaces.limit
									}}
								/>
							</p>
						</div>

						<div className="flex flex-col items-center gap-3 p-3 rounded-lg ring ring-gray-200">
							<h2 className="text-sm font-medium">
								<FormattedMessage id="usage.storage" />
							</h2>
							<CircleProgressBar showPercent max={account!.Usage.Space.limit ?? undefined} current={account!.Usage.Space.used} className="size-16" />
							<p className="text-xs font-bold">
								<FormattedMessage
									id="x_of_n"
									values={{
										x: filesize(account!.Usage.Space.used, locale),
										n: account!.Usage.Space.limit !== null ? filesize(account!.Usage.Space.limit, locale) : <FormattedMessage id="usage.unlimited" />
									}}
								/>
							</p>
						</div>

						<div className="flex flex-col items-center gap-3 p-3 rounded-lg ring ring-gray-200">
							<h2 className="text-sm font-medium">
								<FormattedMessage id="usage.users" />
							</h2>
							<CircleProgressBar showPercent max={account!.Usage.Users.limit ?? undefined} current={account!.Usage.Users.used} className="size-16" />
							<p className="text-xs font-bold">
								<FormattedMessage
									id="x_of_n"
									values={{ x: account!.Usage.Users.used, n: account!.Usage.Users.limit === null ? <FormattedMessage id="usage.unlimited" /> : account!.Usage.Users.limit }}
								/>
							</p>
							<Link to="/organization/users" className="text-xs text-left underline text-theme-primary">
								<FormattedMessage id="user-manager.manage" />
							</Link>
						</div>
					</div>
				</div>

				{hasActiveSubscription && (
					<div className="mt-10">
						<p className="mb-3 text-xs italic text-gray-500">
							<FormattedMessage
								id="subscription.terms"
								values={{
									link: msg => (
										<a target="_blank" rel="noreferrer" href={msg as unknown as string} className="text-theme-primary hover:underline">
											{msg}
										</a>
									)
								}}
							/>
						</p>
						<button onClick={() => setShowCancelSubscription(true)} className="text-xs text-red-600 hover:underline focus:outline-none">
							<FormattedMessage id="subscription.cancel_subscription" />
						</button>
					</div>
				)}
			</Card>
			{showCancelSubscription && <DialogCancelSubscription onAfterClose={() => setShowCancelSubscription(false)} />}
			{selectedPlanUpgrade !== undefined && <DialogUpgradePlan plan={selectedPlanUpgrade} onAfterClose={() => setSelectedPlanUpgrade(undefined)} />}
		</>
	);
};

type PlanBlockProps = {
	plan: Plan;
	active?: boolean;
	disabled?: boolean;
	interval?: 'month' | 'year';
	onAction?: () => void;
	className?: string;
};

export const PlanBlock: FC<PlanBlockProps> = ({ plan, active = false, disabled = false, interval = 'month', className, onAction }) => {
	const { locale } = useIntl();

	return (
		<div
			className={classNames(
				'ring rounded-lg relative',
				{
					'ring ring-theme-primary bg-theme-primary text-white': active,
					'ring-gray-200': !active,
					'opacity-50': disabled
				},
				className
			)}>
			<div className="flex flex-col h-full gap-3 p-3">
				<header>
					<h3 className="text-sm font-bold">{plan.Name}</h3>
					<span className="text-sm font-semibold">
						<FormattedNumber value={interval === 'year' ? plan.Price * 12 : plan.Price} style="currency" currencyDisplay="narrowSymbol" currency={defaultCurrency} />
					</span>
					<span
						className={classNames('ml-1 text-xs', {
							'text-white/75': active,
							'text-gray-600': !active
						})}>
						<FormattedMessage id="plans.payment_frequency_unit" values={{ frequency: interval }} />
					</span>
				</header>

				<div
					className={classNames('flex-1 space-y-2', {
						'text-white/90': active
					})}>
					<p className="text-xs">
						<FormattedMessage id="plans.plan_users" values={{ n: plan.Quota.Users, strong: msg => <span className="font-bold">{msg}</span> }} />
					</p>
					<p className="text-xs">
						<FormattedMessage id="plans.plan_secure_spaces" values={{ n: plan.Quota.SecureSpaces || -1, strong: msg => <span className="font-bold">{msg}</span> }} />
					</p>
					<p className="text-xs">
						<FormattedMessage id="plans.plan_storage_limit" values={{ value: filesize(plan.Quota.Storage, locale), strong: msg => <span className="font-bold">{msg}</span> }} />
					</p>
					<p className="text-xs">
						{plan.Quota.FileSize >= 1099511627776 ? (
							<FormattedMessage id="plans.plan_filesize_nolimit" values={{ strong: msg => <span className="font-bold">{msg}</span> }} />
						) : (
							<FormattedMessage id="plans.plan_filesize_limit" values={{ value: filesize(plan.Quota.FileSize || -1, locale), strong: msg => <span className="font-bold">{msg}</span> }} />
						)}
					</p>
					{plan.Features.Branding && (
						<p className="text-xs">
							<FormattedMessage id="plans.plan_brand_image" />
						</p>
					)}
				</div>

				{!active && !disabled && onAction && (
					<footer>
						<Button type="button" variant={Variant.primary} intent={active ? Intent.secondary : Intent.primary} block size={Size.sm} onClick={onAction}>
							<FormattedMessage id="upgrade" />
						</Button>
					</footer>
				)}

				{active && onAction && (
					<footer>
						<Button type="button" variant={Variant.light} intent={Intent.primary} block size={Size.sm} disabled>
							<FormattedMessage id="current_plan" />
						</Button>
					</footer>
				)}
			</div>
		</div>
	);
};

const SubscriptionStatus = () => {
	const { account } = useAccount();

	const [showGracePeriodDialog, setShowGracePeriodDialog] = useState(false);

	const onTrial = account!.business.trial_ends_at !== null && isFuture(new Date(account!.business.trial_ends_at));
	const onGracePeriod = !!account!.business.subscription?.ends_at;
	const pastDue = account!.business.subscription?.Status === 'past_due';
	const unpaid = account!.business.hasUnpaidSubscription();
	const startDate = account!.business.subscription?.started_at ?? account!.business.subscription?.created_at;

	let statusClassName = classNames('w-2.5 h-2.5 rounded-full ring-2', {
		'bg-green-400 ring-green-100': !pastDue && !unpaid,
		'bg-yellow-400 ring-yellow-100': pastDue || unpaid
	});

	let heading = (
		<p className="text-lg font-semibold text-gray-700">
			<FormattedMessage id="subscription.active" />
		</p>
	);

	let warning = null;

	if (onTrial) {
		heading = (
			<div>
				<p className="text-lg font-semibold text-gray-700">
					<FormattedMessage id="subscription.in_trial" />
				</p>
				<p className="text-xs text-gray-500">
					<FormattedMessage id="subscription.until_date" values={{ date: <DateDisplay force defaultFormat="long" value={account!.business.trial_ends_at} /> }} />.{' '}
					<Link to="/account/upgrade" className="text-theme-primary hover:underline">
						<FormattedMessage id="user-manager.upgrade-account" />
					</Link>
				</p>
			</div>
		);
	}

	if (onGracePeriod) {
		heading = (
			<div>
				<p className="text-lg font-semibold text-gray-700">
					<FormattedMessage id="subscription.on_grace_period" />
				</p>
				<p className="text-xs text-gray-500">
					<FormattedMessage id="subscription.until_date" values={{ date: <DateDisplay force defaultFormat="long" value={account!.business.subscription!.ends_at} /> }} />.{' '}
					<button className="text-theme-primary hover:underline" onClick={() => setShowGracePeriodDialog(true)}>
						<FormattedMessage id="subscription.resume_subscription" />
					</button>
				</p>
			</div>
		);
	}

	if (pastDue) {
		heading = (
			<p className="text-lg font-semibold text-gray-700">
				<FormattedMessage id="subscription.needs_attention" />
			</p>
		);
		warning = (
			<p>
				<FormattedMessage id="subscription.past_due_warning" />
			</p>
		);
	}

	if (unpaid) {
		heading = (
			<p className="text-lg font-semibold text-gray-700">
				<FormattedMessage id="subscription.needs_attention" />
			</p>
		);
		warning = (
			<p>
				<FormattedMessage id="subscription.unpaid_warning" />
			</p>
		);
	}

	return (
		<>
			<div className="inline-flex items-baseline space-x-3">
				<span className={statusClassName} />
				{heading}
			</div>
			{!!startDate && (
				<p className="ml-5 text-xs">
					<FormattedMessage id="started_on" values={{ date: <DateDisplay value={startDate} force defaultFormat="long" /> }} />
				</p>
			)}
			{warning !== null && (
				<Alert variant={Variant.warning} className="mt-3">
					{warning}
				</Alert>
			)}
			{showGracePeriodDialog && <DialogGracePeriod onAfterClose={() => setShowGracePeriodDialog(false)} />}
		</>
	);
};
