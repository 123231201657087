import File from '@models/File';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import type { WretchError } from 'wretch';
import useClient from './useClient';

type DownloadFileMutation = {
	file: File;
	preview: boolean;
};

const useFileUtils = () => {
	const { client } = useClient();
	const history = useHistory();

	const { mutate: download, isLoading: isDownloading } = useMutation<string, WretchError, DownloadFileMutation>(
		async ({ file, preview = false }) =>
			await client
				.url(`documents/${file.getKey()}/download${preview ? '?mode=preview' : ''}`)
				.get()
				.text(),
		{
			onError: error => {
				if (error.status === 404) {
					toast.error(<FormattedMessage id="errors.file_not_found" />);
					return;
				}
				if (error.status === 302 && error.json.error.code === 'ongoing_sign_request') {
					history.push(error.json.error.message);
					return;
				}
			},
			onSuccess: url => {
				window.location.href = url;
			}
		}
	);

	return {
		download: (file: File) => {
			download({ file, preview: false });
		},
		preview: (file: File) => {
			download({ file, preview: true });
		},
		isDownloading
	};
};

export default useFileUtils;
