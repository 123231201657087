import type { OrganizationUser, Role } from '@components/Team';
import useClient from '@hooks/useClient';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const RQKEY_ROOT = 'microsoft-entra-id';
export const RQKEY = (...keys: string[]) => [RQKEY_ROOT, ...keys];

export type MicrosoftEntraIdGroupType = {
	id: string;
	name: string;
	description: string;
};

export type MicrosoftEntraIdUserType = {
	id: string;
	email: string;
	first_name: string;
	last_name: string;
};

export type MicrosoftEntraIdOptionsType = {
	enabled?: boolean;
	groups?: {
		id: string;
		role: Role;
	}[];
	attributes?: {
		email: string;
		first_name: string;
		last_name: string;
		title: string;
	};
	options?: {
		suspend_if_not_found?: boolean;
	};
};

export type MicrosoftEntraIdSimulateMutationResponse = {
	added: OrganizationUser[];
	suspended: OrganizationUser[];
	skipped: OrganizationUser[];
	changed: OrganizationUser[];
};

export const useMicrosoftEntraIdGroupsQuery = () => {
	const { client } = useClient();

	return useQuery({
		queryKey: RQKEY('groups'),
		queryFn: async () => {
			return await client.url('apps/azure-active-directory/groups').get().json<MicrosoftEntraIdGroupType[]>();
		},
		staleTime: 1000 * 60
	});
};

export const useMicrosoftEntraIdGetOptionsQuery = () => {
	const { client } = useClient();

	return useQuery({
		staleTime: 1000 * 60,
		queryKey: RQKEY('options'),
		queryFn: async () => {
			return await client.url('apps/azure-active-directory/options').get().json<MicrosoftEntraIdOptionsType>();
		}
	});
};

export const useMicrosoftEntraIdSaveOptionsMutation = () => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (payload: MicrosoftEntraIdOptionsType & { run?: boolean }) => {
			return await client.url(`apps/azure-active-directory/options`).json(payload).put().json<MicrosoftEntraIdOptionsType>();
		},
		onMutate: async payload => {
			await queryClient.cancelQueries(RQKEY('options'));

			const previousOptions = queryClient.getQueryData<MicrosoftEntraIdOptionsType>(RQKEY('options'));

			if (previousOptions === undefined) {
				return undefined;
			}

			queryClient.setQueryData<MicrosoftEntraIdOptionsType>(RQKEY('options'), options => ({ ...options, ...payload }));

			return previousOptions;
		},
		onError: (_error, _variables, previousOptions) => {
			queryClient.setQueryData(RQKEY('options'), previousOptions);
		}
	});
};

export const useMicrosoftEntraIdSimulateGroupSyncMutation = () => {
	const { client } = useClient();
	return useMutation({
		mutationFn: async (payload: MicrosoftEntraIdOptionsType) => {
			return await client.url(`apps/azure-active-directory/options/simulate`).json(payload).post().json<MicrosoftEntraIdSimulateMutationResponse>();
		}
	});
};
