import { tours, useGuidedTour } from '@components/GuidedTour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Intent, Modal, ModalBody, ModalFooter, ModalHeader, ModalSize, Size, Toggle, Variant } from '@convoflo/ui';
import { MarkdownContent } from '@ui/MarkdownContent';
import { type FC, useLayoutEffect, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { FormattedMessage, useIntl } from 'react-intl';

type GuidedTourProps = {
	name: string;
	enabled?: boolean;
	stepsToRemove?: number[];
};

const emptyArray: number[] = [];

export const GuidedTour: FC<GuidedTourProps> = ({ name, enabled = true, stepsToRemove = emptyArray }) => {
	const { locale } = useIntl();
	const { enabled: guidedTourEnabled, finish, current, setCurrent, isDone, disable } = useGuidedTour();

	const [disableOnClose, setDisableOnClose] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);

	const onClose = () => {
		if (disableOnClose) {
			disable();
		}
		finish(name, currentStep);
	};

	const steps = useMemo(() => {
		return tours[name].filter((_, index) => !stepsToRemove.includes(index)).map(step => step[locale]);
	}, [name, stepsToRemove, locale]);

	useHotkeys(
		'left,right',
		(_, event) => {
			if (event.key === 'left' && currentStep > 0) {
				setCurrentStep(step => step - 1);
			} else if (event.key === 'right' && currentStep < steps.length - 1) {
				setCurrentStep(step => step + 1);
			}
		},
		{
			enabled: isOpen
		},
		[currentStep, steps]
	);

	useLayoutEffect(() => {
		if (enabled && guidedTourEnabled && current === undefined && !isDone(name)) {
			setCurrent(name);
			setIsOpen(true);
		}
	}, [current, enabled, guidedTourEnabled, isDone, name, setCurrent]);

	return (
		<Modal isOpen={isOpen} onAfterClose={onClose} closeable={true} size={ModalSize.Medium} zIndex={100}>
			<ModalHeader className="!justify-start !items-center gap-3 py-4 bg-theme-inverse text-white">
				<FontAwesomeIcon icon={['fad', 'chalkboard-teacher']} size="xl" />
				<h2 className="text-lg font-medium">
					<FormattedMessage id="onboarding.title" />
				</h2>
			</ModalHeader>
			<ModalBody>
				<MarkdownContent size={Size.sm} className="prose-h2:text-center prose-img:rounded-xl prose-img:shadow-xl">
					{steps[currentStep]}
				</MarkdownContent>
			</ModalBody>
			<ModalFooter className="!justify-between">
				<span className="inline-flex items-center gap-3">
					<Toggle checked={disableOnClose} onChange={e => setDisableOnClose(e.target.checked)} size={Size.sm} />
					<label className="text-xs">
						<FormattedMessage id="onboarding.skip_tutorial" />
					</label>
				</span>
				<div className="flex items-center gap-3">
					{currentStep > 0 && (
						<Button size={Size.sm} variant={Variant.light} intent={Intent.tertiary} type="button" iconStart="arrow-left" circle={true} onClick={() => setCurrentStep(step => step - 1)} />
					)}
					{currentStep < steps.length - 1 ? (
						<Button size={Size.sm} type="button" variant={Variant.primary} onClick={() => setCurrentStep(step => step + 1)} iconEnd="arrow-right" circle={true} />
					) : (
						<Button size={Size.sm} type="button" variant={Variant.primary} onClick={() => setIsOpen(false)} className="rounded-full" iconEnd="check">
							<FormattedMessage id="onboarding.done" />
						</Button>
					)}
				</div>
			</ModalFooter>
		</Modal>
	);
};
