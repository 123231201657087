import Icon from '@assets/images/Icon.svg?react';
import { Size, Variant } from '@convoflo/ui';
import type { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IUser } from '@models/User';
import { env } from '@utils/StringUtils';
import classNames from 'classnames';
import type { FC, ImgHTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from './Tooltip';

type UserAvatarProps = ImgHTMLAttributes<HTMLElement> & {
	user: Omit<IUser, 'ID'> & { ID?: number | null };
	variant?: Variant;
	size?: Size | null;
	rounded?: boolean;
	showVerification?: boolean;
};

const UserAvatar: FC<UserAvatarProps> = ({ user, variant = Variant.light, size = Size.md, rounded = true, className = '', showVerification = false, ...props }) => {
	let iconSize: SizeProp | undefined = undefined;

	const { formatMessage } = useIntl();

	const isVerified = (env(import.meta.env.VITE_SUPPORT_USER_IDS) ?? '').split(',').includes(user.ID?.toString() ?? 'x');

	const imgClassName = classNames(className, 'bg-white inline-block', {
		'ring-white ring-2': variant === Variant.light && (!isVerified || !showVerification),
		'ring-convoflo ring-4': isVerified && variant === Variant.light && showVerification,
		'rounded-full': rounded,
		'w-5 h-5 min-w-5': size === Size.xs,
		'w-8 h-8 min-w-8': size === Size.sm,
		'w-12 h-12 min-w-12': size === Size.md,
		'w-16 h-16 min-w-16': size === Size.lg,
		'w-24 h-24 min-w-24': size === Size.xl,
		'w-36 h-36 min-w-36': size === Size['2xl']
	});

	const verifiedBadgeClassName = classNames('absolute flex items-center justify-center bg-convoflo rounded-full ', {
		'-bottom-1 -right-1 w-6 h-6 pl-[6px] pr-[8px]': size === Size.sm || size === Size.md,
		'-bottom-1 -right-1 w-3 h-3 pl-[3px] pr-[4px]': size === Size.xs
	});

	if (user.Name) {
		props.alt = user.Name.split(' ')
			.slice(0, 2)
			.map(item => item.charAt(0).toLocaleUpperCase())
			.join('');
		props.title = user.Name;
	}

	switch (size) {
		case Size.xs:
			iconSize = 'lg';
			break;
		case Size.sm:
			iconSize = '2x';
			break;
		case Size.md:
			iconSize = '3x';
			break;
		case Size.lg:
			iconSize = '4x';
			break;
		case Size.xl:
			iconSize = '5x';
			break;
		case Size['2xl']:
			iconSize = '6x';
			break;
	}

	if (user.Avatar) {
		const img = <img alt={user.Name || ''} src={user.Avatar} className={imgClassName} {...props} />;

		if (!showVerification) {
			return img;
		}

		return (
			<div className="relative flex-shrink-0 rounded-full">
				{img}
				{showVerification && isVerified && (
					<Tooltip tip={formatMessage({ id: 'support_team_member' }, { name: user.Name })}>
						<div className={verifiedBadgeClassName}>
							<Icon className="w-full h-full text-white" />
						</div>
					</Tooltip>
				)}
			</div>
		);
	}

	return (
		<Tooltip tip={user.Name}>
			<span>
				<FontAwesomeIcon icon="user-circle" size={iconSize} className={imgClassName} />
			</span>
		</Tooltip>
	);
};

export default UserAvatar;
