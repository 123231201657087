import FullScreenLoading from '@components/FullScreenLoading';
import LanguageSwitcher from '@components/LanguageSwitcher';
import { Button, Input, Intent, Label, Row, ValidationField, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import useUrlSearch from '@hooks/useUrlSearch';
import type { GatewayGetResponse } from '@service/types';
import Card from '@ui/Card';
import { type FormEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { Redirect, useParams } from 'react-router-dom';
import type { WretchError, WretchResponse } from 'wretch';

const Page = () => {
	const { client, validation } = useClient();
	const { secret }: { secret: string } = useParams();
	const { autofocus = true } = useUrlSearch();

	const [email, setEmail] = useState('');

	const { data: organization, isLoading, isError: isLoadingError } = useQuery(['gateway', secret], async () => client.url(`gateway/${secret}`).get().json<GatewayGetResponse>());

	const { mutate: checkLogin, isLoading: isChecking } = useMutation<WretchResponse, WretchError, { email: string }>(async () => client.url(`gateway/${secret}`).post({ email }).res(), {
		onSuccess: () => {
			window.open(`/login?email=${encodeURIComponent(email)}&gateway=${encodeURIComponent(secret)}`);
		}
	});

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		checkLogin({ email });
	};

	if (isLoadingError) {
		return <Redirect to="/login" />;
	}

	if (isLoading) {
		return <FullScreenLoading />;
	}

	return (
		<div className="flex flex-col min-h-screen bg-gray-200">
			<div className="container flex flex-col items-center justify-center flex-1 max-w-sm px-2 py-8 mx-auto space-y-8">
				{!!organization?.LogoLight && (
					<div className="max-w-full">
						<img src={organization.LogoLight} alt={organization?.Name || ''} />
					</div>
				)}

				<h1 className="text-2xl font-semibold text-center text-gray-600">
					<FormattedMessage id="gateway.external_title" />
				</h1>

				<Card size={null}>
					<div className="p-6">
						<form onSubmit={onSubmit}>
							<fieldset disabled={isChecking}>
								<Row>
									<Label htmlFor="email">
										<FormattedMessage id="login.email" />
									</Label>
									<ValidationField fieldName="email" validation={validation}>
										<Input
											block
											tabIndex={1}
											autoFocus={autofocus !== '0'}
											id="email"
											type="email"
											value={email}
											onChange={e => setEmail(e.target.value)}
											icon="envelope"
											required
										/>
									</ValidationField>
								</Row>
								<Row>
									<Button block variant={Variant.primary} tabIndex={3} type="submit" intent={Intent.primary} disabled={email === ''}>
										{isChecking ? <FontAwesomeIcon icon="spinner" pulse /> : <FormattedMessage id="login.continue" />}
									</Button>
								</Row>
							</fieldset>
						</form>
					</div>
				</Card>
				<div className="mt-12">
					<LanguageSwitcher />
				</div>
				<p className="mt-4 text-xs text-gray-500">
					<FormattedMessage id="copyright.short" values={{ year: new Date().getFullYear() }} />
				</p>
			</div>
		</div>
	);
};

export default Page;
