import { useCannedMessages } from '@components/CannedMessages';
import { DateTimeDisplay } from '@components/DateTime';
import { useFileableMembers } from '@components/FileManager';
import {
	addMessage,
	type Comment,
	type ComposerPluginConfig,
	MessageFormContext,
	MessageTitle,
	type MessageTitleProps,
	type OnPostingEvent,
	replaceMessage,
	useComposerPlugins,
	useMessageDraft,
	usePluginAudience,
	usePluginFileAttachments, // used
	usePluginFileRequests,
	usePluginMessageOptions, // used
	usePluginPaymentRequests
} from '@components/Message';
import type { ResourceLengthAwarePaginatorType } from '@components/Pagination';
import type { UploadFile } from '@components/Upload';
import { Size } from '@convoflo/ui';
import { useDebouncedState } from '@hooks/use-debounced-state';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import useIsDirty from '@hooks/useIsDirty';
import Folder from '@models/Folder';
import type { IUser } from '@models/User';
import { type HTMLContent, type JSONContent } from '@tiptap/react';
import type { Fileable } from '@types';
import { RichTextEditor, type RichTextEditorProps, type RichTextEditorRef } from '@ui/RichTextEditor';
import UserAvatar from '@ui/UserAvatar';
import classNames from 'classnames';
import equal from 'fast-deep-equal';
import isEmpty from 'lodash.isempty';
import { forwardRef, type ForwardRefRenderFunction, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { type InfiniteData, type QueryKey, useMutation, useQueryClient } from 'react-query';
import rehypeStringify from 'rehype-stringify';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import { type Plugin, unified } from 'unified';
import type { WretchError } from 'wretch';

export type PostMessageMutation = Record<string, any> & {
	messageId?: number;
	parentId?: number;
	body: string;
};

export type ComposerState = {
	isDirty: boolean;
	isPosting: boolean;
	isPosted: boolean;
	isDisabled: boolean;
	isPostable: boolean;
	hasDraft: boolean;
};

type ComposerProps = {
	fileable: Fileable;
	queryKey: QueryKey;
	plugins?: {
		attachments?: ComposerPluginConfig<{
			folder?: Folder;
			enableFolderSelection?: boolean;
			onFilesChanged?: (files: UploadFile[]) => void;
		}>;
		fileRequests?: ComposerPluginConfig;
		paymentRequests?: ComposerPluginConfig<{
			visible?: boolean;
		}>;
		audience?: ComposerPluginConfig<{
			containerClassName?: string;
		}>;
		options: ComposerPluginConfig;
	};
	message?: Comment;
	parent?: Comment;
	inputProps?: RichTextEditorProps;
	disabled?: boolean;
	source?: string;
	titleInputProps?: MessageTitleProps;
	className?: string;
	onPosted?: (message: Comment, vars: PostMessageMutation) => void;
	onStateChanged?: (state: ComposerState) => void;
	onBeforePosting?: (event: OnPostingEvent) => Promise<Folder | undefined>;
};

export type ComposerRef = {
	post: () => void;
	discard: () => void;
};

const ComposerRenderFunction: ForwardRefRenderFunction<ComposerRef, ComposerProps> = (
	{
		fileable,
		queryKey,
		disabled = false,
		plugins: pluginsConfig,
		message,
		parent,
		inputProps = {},
		titleInputProps = {},
		source,
		className,
		onPosted = () => undefined,
		onStateChanged = () => undefined,
		onBeforePosting = () => undefined
	},
	ref
) => {
	const { client } = useClient();
	const queryClient = useQueryClient();
	const { account } = useAccount();
	const { formatMessage, locale } = useIntl();
	const { members = [] } = useFileableMembers(fileable);
	const cannedMessages = useCannedMessages();
	const draftEnabled = useRef(false);

	const [debouncedTitle, title, setTitle] = useDebouncedState(message?.Title ?? '', 2000);
	const [characterCount, setCharacterCount] = useState(0);
	const [rteUpdates, _, setRTeUpdates] = useDebouncedState(0, 2000);
	const editor = useRef<RichTextEditorRef>(null);
	const { isDirty, setIsDirty } = useIsDirty([characterCount, title]);

	const [draft, { save: saveDraft, discard: discardDraft, isFetched: isDraftFetched }] = useMessageDraft(`${fileable.id()}.${parent?.ID ?? ''}`, {
		enabled: !message,
		onSaved: () => setIsDirty(false)
	});

	const plugins = useComposerPlugins([
		usePluginAudience({ fileable, message, disabled, parent }, pluginsConfig?.audience ?? {}, draft?.pluginsData),
		usePluginFileAttachments(
			{
				fileable,
				message,
				disabled,
				parent
			},
			pluginsConfig?.attachments ?? {},
			draft?.pluginsData
		),
		usePluginFileRequests(
			{
				fileable,
				message,
				disabled,
				parent
			},
			pluginsConfig?.fileRequests ?? {},
			draft?.pluginsData
		),
		usePluginPaymentRequests(
			{
				fileable,
				message,
				disabled,
				parent
			},
			pluginsConfig?.paymentRequests ?? {},
			draft?.pluginsData
		),
		usePluginMessageOptions(
			{
				fileable,
				message,
				disabled,
				parent
			},
			pluginsConfig?.options ?? {},
			draft?.pluginsData
		)
	]);

	useEffect(() => {
		if (!draftEnabled.current) {
			return;
		}
		if (title === '' && isEmpty(plugins.data) && equal(editor.current?.json(), draft?.body)) {
			return;
		}

		saveDraft({
			title,
			body: editor.current?.json() as JSONContent,
			pluginsData: plugins.data
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rteUpdates, debouncedTitle, JSON.stringify(plugins.data)]);

	useEffect(() => {
		if (!draft) {
			return;
		}

		setTitle(draft.title);
	}, [draft]);

	const {
		mutate: post,
		isLoading: isPosting,
		isSuccess: isPosted
	} = useMutation<Comment, WretchError, PostMessageMutation, InfiniteData<ResourceLengthAwarePaginatorType<Comment>>>(
		async ({ messageId, parentId, body, title }) => {
			const overriddenFileable = await onBeforePosting({ message: body });
			await plugins.onBeforePosting({ message: body });
			const data = plugins.postData();

			let params = {
				parent_id: parentId ?? null,
				comment: body,
				title,
				source,
				...data
			};

			if (messageId) {
				return await client.url(`comments/${messageId}`).json(params).put().json<Comment>();
			}

			return await client
				.url((overriddenFileable ?? fileable)!.getRoute('comments'))
				.json(params)
				.post()
				.json<Comment>();
		},
		{
			onMutate: async ({ messageId, parentId, body }) => {
				await queryClient.cancelQueries(queryKey);

				const temporaryMessage: Comment = {
					'@type': 'Comment',
					ID: messageId ?? 0,
					Comment: body,
					Priority: 0,
					created_at: null,
					updated_at: null,
					attachments: [],
					audience: 'all',
					creator: account!.toUser(),
					likes: [],
					liked: false,
					archived_at: null,
					pinned_at: null,
					PreviewInAlert: false,
					replies: [],
					Title: null,
					attachments_count: 0,
					properties: [],
					// parent,
					ReadNotification: false,
					Locked: false,
					users: [],
					commentable: undefined
				};

				let currentMessages = queryClient.getQueryData<InfiniteData<ResourceLengthAwarePaginatorType<Comment>>>(queryKey);
				let messages: InfiniteData<ResourceLengthAwarePaginatorType<Comment>>;

				if (!currentMessages) {
					return undefined;
				}

				if (message) {
					messages = replaceMessage(temporaryMessage, currentMessages);
				} else {
					messages = addMessage(temporaryMessage, currentMessages, parentId);
				}

				queryClient.setQueryData<InfiniteData<ResourceLengthAwarePaginatorType<Comment>>>(queryKey, messages);

				return currentMessages;
			},
			onError: async (error, _message, oldMessages) => {
				await plugins.onError({ error });
				queryClient.setQueryData(queryKey, oldMessages);
			},
			onSuccess: async (message, vars) => {
				const messages = queryClient.getQueryData<InfiniteData<ResourceLengthAwarePaginatorType<Comment>>>(queryKey);
				if (messages) {
					queryClient.setQueryData<InfiniteData<ResourceLengthAwarePaginatorType<Comment>>>(queryKey, replaceMessage(message, messages!));
				} else {
					queryClient.invalidateQueries(['messages']);
				}

				await reset();
				await plugins.onSuccess({ message });
				onPosted(message, vars);
			}
		}
	);

	const reset = async () => {
		await discardDraft();
		await plugins.reset({});
		setCharacterCount(0);
		setTitle('');
		editor.current?.clear();
		setIsDirty(false);
		draftEnabled.current = false;
	};

	const defaultSubject = useMemo(() => {
		if (!fileable) {
			return '';
		}

		let _defaultSubject = formatMessage({ id: 'comments.subject_default' }, { fileable: fileable.getName() });

		if (parent) {
			_defaultSubject = formatMessage({ id: 'comments.subject_default_reply' }, { fileable: fileable.getName() });
		}

		if (account!.business.Defaults?.message?.subject) {
			const _subject = JSON.parse(account!.business.Defaults?.message?.subject);

			if (_subject !== null) {
				const appLocale = locale === 'fr' ? 'fr_CA' : 'en_US';

				if (_subject[appLocale]) {
					_defaultSubject = _subject[appLocale];
				}
			}
		}

		return _defaultSubject;
	}, [account, fileable, formatMessage, locale, parent]);

	const isDisabled = plugins.isDisabled || disabled || isPosting;

	useEffect(() => {
		onStateChanged({
			isDirty,
			isDisabled,
			isPosted,
			isPosting,
			isPostable: plugins.isPostable && characterCount > 0,
			hasDraft: !!draft
		});
	}, [onStateChanged, isDirty, isDisabled, isPosted, isPosting, plugins.isPostable, draft, characterCount]);

	useImperativeHandle(ref, () => ({
		post: async () => {
			post({
				messageId: message?.ID,
				parentId: parent?.ID,
				title,
				body: JSON.stringify(editor.current!.json())
			});
		},
		discard: reset
	}));

	const { mutateAsync: getMentionables } = useMutation<IUser[], WretchError, string>(['composer'], async query =>
		members.filter((member: IUser) => member.Name!.toLowerCase().startsWith(query.toLowerCase())).slice(0, 10)
	);

	useEffect(() => {
		if (draftEnabled.current) {
			return;
		}

		if (message?.Format === 'prosemirror') {
			editor.current?.setHtmlContent(message.Comment ?? '');
			if (!!parent) {
				editor.current?.focusStart();
			}
			draftEnabled.current = true;
			return;
		}

		if (message) {
			const contents = unified()
				.use(remarkParse)
				.use(remarkGfm as Plugin)
				.use(remarkRehype)
				.use(rehypeStringify)
				.processSync(message.Comment ?? '');

			editor.current?.setHtmlContent(contents.value as HTMLContent);
			if (!!parent) {
				editor.current?.focusStart();
			}
			draftEnabled.current = true;
			return;
		}

		if (draft?.body) {
			editor.current?.setJsonContent(draft.body);
			if (!!parent) {
				editor.current?.focusStart();
			}
			draftEnabled.current = true;
			return;
		}

		// Only add the signature if the draft is not loaded, or the draft is blank
		if (cannedMessages !== undefined && (!isDraftFetched || draft === undefined)) {
			const signature = cannedMessages?.find(cannedMessage => cannedMessage.IsSignature);
			if (signature) {
				editor.current?.setHtmlContent('<p></p>' + signature?.Content);
				if (!!parent) {
					editor.current?.focusStart();
				}
			}

			if (isDraftFetched && draft === undefined) {
				draftEnabled.current = true;
			}
		}
	}, [message, cannedMessages, draft, isDraftFetched, !!parent]);

	return (
		<MessageFormContext.Provider value={{ fileable, disabled: isDisabled, pluginData: plugins.data }}>
			{plugins.components.before.length > 0 && <>{plugins.components.before}</>}
			<div className={classNames(className)} id="step-text-zone">
				<MessageTitle disabled={isDisabled} placeholder={defaultSubject} value={title} onChange={e => setTitle(e.target.value)} {...titleInputProps} />
				<RichTextEditor<IUser>
					suggestion={{
						char: '@',
						onQuery: getMentionables,
						onSelected: user => ({ id: String(user.ID), label: user.Name }),
						renderMenuItem: user => (
							<div className="flex items-center space-x-2">
								<UserAvatar user={user} size={Size.xs} />
								<span>{user.Name}</span>
							</div>
						)
					}}
					// onBlur={trySaveDraft}
					autoFocus={!!parent}
					ref={editor}
					{...inputProps}
					disabled={isDisabled}
					onCharacterCountChange={setCharacterCount}
					onUpdate={() => {
						if (draftEnabled.current) {
							setRTeUpdates(rteUpdates => rteUpdates + 1);
						}
					}}>
					{plugins.components.after.length > 0 && <>{plugins.components.after}</>}
				</RichTextEditor>
			</div>
			{plugins.components.buttons.length > 0 && <div className="flex items-center space-x-1.5 p-3">{plugins.components.buttons}</div>}
			{!!draft?.updated_at && (
				<p className="px-4 text-xs text-gray-400">
					<FormattedMessage
						id="comments.draft_saved_on"
						values={{
							date: <DateTimeDisplay value={draft.updated_at} defaultFormat="relative" force />,
							button: msg => (
								<button type="button" onClick={reset} className="text-red-600 underline">
									{msg}
								</button>
							)
						}}
					/>
				</p>
			)}
		</MessageFormContext.Provider>
	);
};

export const Composer = forwardRef(ComposerRenderFunction);
