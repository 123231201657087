import { EditableFileableItem, FileSelectorNavigation, type FileSelectorSelection, FileableItem, UploadFileableItem } from '@components/FileSelector';
import { UploadButton, type UploadButtonHandles, UploadFile } from '@components/Upload';
import { Button, Intent, Modal, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, ModalSize, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import File from '@models/File';
import Folder from '@models/Folder';
import { ViewContext, ViewProvider } from '@providers/ViewProvider.js';
import { versionMiddleware } from '@service/Client';
import { type Fileable, Permissions, type View } from '@types';
import List from '@ui/List';
import { unique } from '@utils/ArrayUtils';
import { type ButtonHTMLAttributes, type FC, type ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';

type FileSelectorProps = Omit<ModalProps, 'isOpen'> & {
	title?: ReactNode;
	multiple?: boolean;
	isItemDisabled?: (item: Fileable) => boolean;
	onItemsSelected?: (items: FileSelectorSelection) => void;
	startingFolder?: View;
	selectedItems?: Fileable[];
	rootIsSelectable?: boolean;
	foldersAreSelectable?: boolean;
};

export const FileSelector: FC<FileSelectorProps> = ({
	title,
	multiple = false,
	isItemDisabled = () => false,
	onItemsSelected = () => undefined,
	startingFolder = null,
	selectedItems,
	rootIsSelectable = true,
	foldersAreSelectable = true,
	...modalProps
}) => {
	const { client } = useClient();
	const { account } = useAccount();
	const queryClient = useQueryClient();

	// State
	const [isOpen, setIsOpen] = useState(true);
	const [selection, setSelection] = useState<Fileable[]>(selectedItems ?? []);
	const [currentFolder, setCurrentFolder] = useState<View>(startingFolder);
	const [showFolderCreation, setShowFolderCreation] = useState(false);
	const [uploadItems, setUploadItems] = useState<UploadFile[]>([]);

	const uploader = useRef<UploadButtonHandles>(null);

	const { data: items = [], isLoading } = useQuery(['file_selector', 'items', currentFolder], async () => {
		let route = 'files';
		let order = 'name';
		let params: Record<string, any> = {};

		if (currentFolder instanceof Folder) {
			params.folder = currentFolder.getKey();
		}

		if (currentFolder === 'favorites') {
			route = 'files/favorites';
		}

		if (currentFolder === 'recent') {
			route = 'files/recent';
			order = '!date';
		}

		let { folders, documents: files }: { folders: Fileable[]; documents: Fileable[] } = await client
			.url(route)
			.query({ ...params, order, format: 'simple' })
			.get()
			.json();

		folders = folders.map(folder => new Folder(folder));
		files = files.map(file => new File(file));

		return folders.concat(files);
	});

	const { data: ancestors } = useQuery(
		['file_selector', 'navigation', currentFolder],
		async () => {
			if (currentFolder === 'favorites') {
				return ['favorites'];
			}

			if (currentFolder === 'recent') {
				return ['recent'];
			}

			if (currentFolder === null || typeof currentFolder === 'string') {
				return [];
			}

			let { ancestors } = new Folder(
				await client
					.url(`folders/${currentFolder.URL}`)
					.middlewares([versionMiddleware(2)])
					.get()
					.json()
			);

			return [currentFolder].concat(ancestors!.map(ancestor => new Folder(ancestor))).reverse();
		},
		{
			staleTime: Infinity
		}
	);

	const inSelection = useCallback((item: Fileable) => selection.some(i => i?.getKey() === item.getKey()), [selection]);

	const onItemSelected = useCallback(
		(item: Fileable, selected: boolean) => {
			if (!multiple && selected) {
				setSelection([item]);
			} else if (!multiple && !selected) {
				setSelection([]);
			} else if (multiple && selected) {
				setSelection(selection => unique<Fileable>(selection.concat([item]), ['URL']));
			} else if (multiple && !selected) {
				setSelection(selection => selection.filter(i => i?.getKey() !== item?.getKey()));
			}
		},
		[multiple]
	);

	const { mutate: createFolder } = useMutation(
		async ({ folder, name }: { name: string; folder: Folder | null }) =>
			new Folder(
				await client
					.url('folders')
					.post({
						name,
						parent: folder instanceof Folder ? folder.getKey() : 0,
						copy_permissions_from_parent: 1
					})
					.json()
			),
		{
			onSuccess: folder => {
				setShowFolderCreation(false);
				const items = queryClient.getQueryData<Fileable[]>(['file_selector', 'items', currentFolder]);
				if (items === undefined) {
					return;
				}
				queryClient.setQueryData<Fileable[]>(['file_selector', 'items', currentFolder], [folder as Fileable].concat(items));
			}
		}
	);

	const onFilesSelected = (files: globalThis.File[]) => {
		if (typeof currentFolder === 'string') {
			return;
		}

		setUploadItems(items => items.concat(files.map(file => UploadFile.fromFile(file, currentFolder)).filter(file => file.file)));
	};

	const onFileUploaded = (file: File, uploadItem: UploadFile) => {
		setUploadItems(items => items.filter(i => uploadItem.id !== i.id));
		setSelection(selection => selection.concat([file]));
		if ((currentFolder instanceof Folder || currentFolder === null) && currentFolder?.getKey() === uploadItem.scope?.getKey()) {
			const items = queryClient.getQueryData<Fileable[]>(['file_selector', 'items', currentFolder]);
			if (items === undefined) {
				return;
			}
			queryClient.setQueryData<Fileable[]>(['file_selector', 'items', currentFolder], [file as Fileable].concat(items));
		}
	};

	const ok = () => {
		onItemsSelected(rootIsSelectable && selection.length === 0 && currentFolder === null ? [null] : selection.length === 0 && currentFolder instanceof Folder ? [currentFolder] : selection);
		setIsOpen(false);
	};

	const cancel = () => {
		setIsOpen(false);
	};

	const enabled = useMemo(() => {
		if (selection.length === 0) {
			return currentFolder === null ? rootIsSelectable : foldersAreSelectable;
		}
		return selection.every(item => (item instanceof Folder ? foldersAreSelectable : true));
	}, [currentFolder, foldersAreSelectable, rootIsSelectable, selection]);

	const canCreateFolderHere = (currentFolder === null && account?.hasFullAccess()) || (currentFolder instanceof Folder && currentFolder.pivot?.Permissions === Permissions.ReadWrite);
	const canUploadHere = (currentFolder === null && account?.hasFullAccess()) || (currentFolder instanceof Folder && currentFolder.pivot?.Permissions === Permissions.ReadWrite);

	return (
		<>
			<Modal size={ModalSize.XLarge} isOpen={isOpen} {...modalProps}>
				{canUploadHere && <UploadButton ref={uploader} multiple onFilesSelected={onFilesSelected} />}
				<ModalHeaderOnlyTitle>{title || <FormattedMessage id="file-selector.default_title" values={{ multiple }} />}</ModalHeaderOnlyTitle>

				{ancestors !== undefined && <FileSelectorNavigation folders={ancestors} onNavigated={folder => setCurrentFolder(folder)} />}

				<div className="flex flex-1 max-h-1/2-screen min-h-80">
					<div className="w-48 p-2 space-y-4 border-r-2 border-gray-100">
						{startingFolder instanceof Folder && (
							<div>
								<MenuItem
									className="mb-6"
									onClick={() => setCurrentFolder(startingFolder)}
									active={currentFolder instanceof Folder && startingFolder.getKey() === currentFolder.getKey()}>
									{startingFolder.icon('mr-2')}
									{startingFolder.getName()}
								</MenuItem>
							</div>
						)}
						<div className="space-y-2">
							{account?.hasFullAccess() && (
								<MenuItem onClick={() => setCurrentFolder(null)} active={currentFolder === null}>
									<FontAwesomeIcon icon="home" className="mr-2 text-gray-600" />
									<FormattedMessage id="file-selector.root" />
								</MenuItem>
							)}
							<MenuItem onClick={() => setCurrentFolder('favorites')} active={currentFolder === 'favorites'}>
								<FontAwesomeIcon icon="star" className="mr-2 text-yellow-500" />
								<FormattedMessage id="file-manager.favorites" />
							</MenuItem>
							<MenuItem onClick={() => setCurrentFolder('recent')} active={currentFolder === 'recent'}>
								<FontAwesomeIcon icon="clock" className="mr-2" />
								<FormattedMessage id="file-manager.recent" />
							</MenuItem>
						</div>
					</div>
					<div className="flex flex-col items-stretch flex-1 overflow-hidden">
						<div className="p-2 space-x-2 bg-gray-100">
							<Button type="button" size={Size.sm} disabled={!canUploadHere} variant={Variant.primary} onClick={uploader?.current?.chooseFiles}>
								<FontAwesomeIcon icon="cloud-upload" className="mr-1" />
								<FormattedMessage id="file-manager.upload_files" />
							</Button>

							<Button type="button" size={Size.sm} variant={Variant.light} disabled={showFolderCreation || !canCreateFolderHere} onClick={() => setShowFolderCreation(true)}>
								<FontAwesomeIcon icon="folder" className="mr-1" />
								<FormattedMessage id="file-selector.new_folder" />
							</Button>
						</div>

						{!isLoading && items.length + uploadItems.length === 0 && !showFolderCreation && (
							<div className="my-auto space-y-4 text-center">
								<p className="italic text-gray-500">
									<FormattedMessage id="file-selector.no_items" />
								</p>
							</div>
						)}

						{(currentFolder === null || currentFolder instanceof Folder) && showFolderCreation && (
							<EditableFileableItem fileable={new Folder()} onCancel={() => setShowFolderCreation(false)} onEnter={name => createFolder({ name, folder: currentFolder })} />
						)}

						<ViewProvider>
							{() => (
								<>
									{uploadItems.map(item => (
										<UploadFileableItem key={item.id} item={item} folder={currentFolder} onCompleted={onFileUploaded} />
									))}
								</>
							)}
						</ViewProvider>

						{!isLoading && (items.length + uploadItems.length > 0 || showFolderCreation) && (
							<div className="relative flex-1">
								<List itemSize={36} itemCount={items.length}>
									{({ index, style }) => (
										<FileableItem
											key={items[index].getKey()}
											item={items[index]}
											disabled={isItemDisabled(items[index])}
											selected={inSelection(items[index])}
											onSelected={onItemSelected}
											onNavigated={setCurrentFolder}
											style={style}
										/>
									)}
								</List>
							</div>
						)}

						{isLoading && (
							<p className="my-auto italic text-center text-gray-600">
								<FontAwesomeIcon icon="spinner" pulse className="mr-2" />
								<FormattedMessage id="loading" />
							</p>
						)}
					</div>
				</div>
				{multiple && (
					<div className="px-4 py-2 bg-gray-200">
						<p className="text-xs text-gray-600">
							<FormattedMessage
								id="file-selector.selection_simple"
								values={{
									n: selection.length,
									button: msg => (
										<button type="button" onClick={() => setSelection([])} className="ml-4 text-theme-primary hover:underline">
											{msg}
										</button>
									)
								}}
							/>
						</p>
					</div>
				)}
				<ModalFooter>
					<Button variant={Variant.primary} onClick={ok} type="button" disabled={!enabled}>
						{selection.length > 0 ? (
							<FormattedMessage id="select" />
						) : rootIsSelectable && currentFolder === null ? (
							<FormattedMessage id="file-selector.select_root" />
						) : currentFolder instanceof Folder ? (
							<FormattedMessage id="select_folder" />
						) : (
							<FormattedMessage id="select" />
						)}
					</Button>
					<Button variant={Variant.light} intent={Intent.secondary} onClick={cancel} type="button">
						<FormattedMessage id="cancel" />
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

const MenuItem = ({ active = false, className = '', ...buttonProps }: ButtonHTMLAttributes<HTMLButtonElement> & { active?: boolean }) => (
	<button {...buttonProps} className={`${active ? 'bg-gray-200' : ''} block w-full px-4 py-2 text-sm text-left truncate rounded focus-within:outline-none ${className}`} />
);
