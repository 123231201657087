import type { OrganizationUser } from '@components/Team';
import Folder from '@models/Folder';
import type { FolderTemplate } from '@types';
import { type Dispatch, type FC, type PropsWithChildren, type SetStateAction, createContext, useCallback, useState } from 'react';
import type { DataRow, ImportRow } from '../types';

export const ImportContext = createContext<
	| {
			creator?: OrganizationUser;
			folder?: Folder | null;
			template?: FolderTemplate;
			data: DataRow[];
			originalData: ImportRow[];
			format?: string;
			isInviting: boolean;
			setCreator: Dispatch<SetStateAction<OrganizationUser | undefined>>;
			setFolder: Dispatch<SetStateAction<Folder | null>>;
			setTemplate: Dispatch<SetStateAction<FolderTemplate | undefined>>;
			setData: Dispatch<SetStateAction<DataRow[]>>;
			setOriginalData: Dispatch<SetStateAction<ImportRow[]>>;
			setFormat: Dispatch<SetStateAction<string | undefined>>;
			setIsInviting: Dispatch<SetStateAction<boolean>>;
			reset: () => void;
	  }
	| undefined
>(undefined);

export const ImportContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const [creator, setCreator] = useState<OrganizationUser>();
	const [folder, setFolder] = useState<Folder | null>(null);
	const [template, setTemplate] = useState<FolderTemplate>();
	const [data, setData] = useState<DataRow[]>([]);
	const [originalData, setOriginalData] = useState<ImportRow[]>([]);
	const [format, setFormat] = useState<string>();
	const [isInviting, setIsInviting] = useState(false);

	const reset = useCallback(() => {
		setOriginalData([]);
		setData([]);
		setFormat(undefined);
	}, []);

	return (
		<ImportContext.Provider
			value={{
				reset,
				isInviting,
				setIsInviting,
				originalData,
				format,
				setFormat,
				creator,
				folder,
				template,
				data,
				setOriginalData,
				setCreator,
				setFolder,
				setTemplate,
				setData
			}}>
			{children}
		</ImportContext.Provider>
	);
};
