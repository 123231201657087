import { useIntl, FormattedMessage } from 'react-intl';
import type { LengthAwarePaginatorType } from '@components/Pagination';

type LengthAwarePaginatorProps<T> = LengthAwarePaginatorType<T> & {
	onPage?: (page: number) => void;
	eachSide?: number;
	className?: string;
	disabled?: boolean;
};

export const LengthAwarePaginator = <T,>({
	disabled = false,
	currentPage = 1,
	from,
	lastPage,
	nextPageUrl,
	prevPageUrl,
	to,
	total,
	onPage = () => undefined,
	eachSide = 3,
	className = ''
}: Omit<LengthAwarePaginatorProps<T>, 'data'> & Partial<Pick<LengthAwarePaginatorProps<T>, 'data'>>) => {
	const { formatMessage } = useIntl();

	const getRange = () => {
		if (lastPage === 1) {
			return [1];
		}

		let totalPages = eachSide * 2 + 1;

		if (lastPage < totalPages) {
			return Array.from(Array(lastPage).keys(), (_, i) => i + 1);
		}

		let range: (number | null)[] = [currentPage];
		let beforeCurrentPage = currentPage <= eachSide ? currentPage - 1 : eachSide;
		let afterCurrentPage = lastPage - currentPage <= eachSide ? lastPage - currentPage : eachSide;

		if (beforeCurrentPage > 0) {
			range.unshift(...Array.from(Array(beforeCurrentPage).keys(), (_, i) => i + currentPage - beforeCurrentPage));

			if (range[0] !== 1) {
				range.unshift(...[1, null]);
			}
		}

		if (afterCurrentPage > 0) {
			range.push(...Array.from(Array(afterCurrentPage).keys(), (_, i) => i + currentPage + 1));

			if (range[range.length - 1] !== lastPage) {
				range.push(...[null, lastPage]);
			}
		}

		return range;
	};

	return (
		<div className={`flex items-center justify-between ${className}`}>
			{/* Mobile navigation */}
			<div className="flex justify-between flex-1 sm:hidden">
				{!!prevPageUrl ? (
					<button
						disabled={disabled}
						type="button"
						className="inline-flex items-center px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md disabled:cursor-default disabled:text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700"
						onClick={() => onPage(currentPage - 1)}
						aria-label={formatMessage({ id: 'pagination.previous' })}>
						<FormattedMessage id="pagination.previous" />
					</button>
				) : (
					<span
						className="inline-flex items-center px-4 py-2 text-sm leading-5 text-gray-500 bg-white border border-gray-300 rounded-md"
						aria-label={formatMessage({ id: 'pagination.previous' })}>
						<FormattedMessage id="pagination.previous" />
					</span>
				)}

				{!!nextPageUrl ? (
					<button
						disabled={disabled}
						type="button"
						className="inline-flex items-center px-4 py-2 ml-3 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md disabled:cursor-default disabled:text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700"
						onClick={() => onPage(currentPage + 1)}
						aria-label={formatMessage({ id: 'pagination.next' })}>
						<FormattedMessage id="pagination.next" />
					</button>
				) : (
					<span
						className="inline-flex items-center px-4 py-2 ml-3 text-sm leading-5 text-gray-500 bg-white border border-gray-300 rounded-md"
						aria-label={formatMessage({ id: 'pagination.next' })}>
						<FormattedMessage id="pagination.next" />
					</span>
				)}
			</div>

			<div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
				<p className="text-sm leading-5 text-gray-700">
					<FormattedMessage
						id="pagination.results"
						values={{
							strong: msg => <strong className="font-medium">{msg}</strong>,
							offset: from,
							limit: to,
							total: total
						}}
					/>
				</p>
			</div>

			<nav className="relative z-0 hidden sm:inline-flex">
				{/* Previous */}

				{!!prevPageUrl ? (
					<button
						disabled={disabled}
						type="button"
						className="relative inline-flex items-center px-2 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out bg-white border border-gray-300 text-theme-primary disabled:cursor-default disabled:text-gray-400 rounded-l-md hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-500"
						onClick={() => onPage(currentPage - 1)}
						aria-label={formatMessage({ id: 'pagination.previous' })}>
						<FormattedMessage id="pagination.previous" />
					</button>
				) : (
					<span
						className="relative inline-flex items-center px-2 py-2 text-sm leading-5 text-gray-500 bg-white border border-gray-300 rounded-l-md"
						aria-label={formatMessage({ id: 'pagination.previous' })}>
						<FormattedMessage id="pagination.previous" />
					</span>
				)}

				{getRange().map(page => {
					if (page === null) {
						return <span className="relative inline-flex items-center px-4 py-2 -ml-px text-sm leading-5 text-gray-700 bg-white border border-gray-300">…</span>;
					}

					return page === currentPage ? (
						<span key={page} className="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-bold leading-5 text-gray-700 bg-white border border-gray-300">
							{page}
						</span>
					) : (
						<button
							disabled={disabled}
							key={page}
							type="button"
							className="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium leading-5 transition duration-150 ease-in-out bg-white border border-gray-300 text-theme-primary disabled:cursor-default disabled:text-gray-400 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-700"
							onClick={() => onPage(page)}>
							{page}
						</button>
					);
				})}

				{/* Next */}
				{!!nextPageUrl ? (
					<button
						disabled={disabled}
						type="button"
						className="relative inline-flex items-center px-2 py-2 -ml-px text-sm font-medium leading-5 transition duration-150 ease-in-out bg-white border border-gray-300 text-theme-primary disabled:cursor-default disabled:text-gray-400 rounded-r-md hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-500"
						onClick={() => onPage(currentPage + 1)}
						aria-label={formatMessage({ id: 'pagination.next' })}>
						<FormattedMessage id="pagination.next" />
					</button>
				) : (
					<span
						className="relative inline-flex items-center px-2 py-2 -ml-px text-sm leading-5 text-gray-500 bg-white border border-gray-300 rounded-r-md"
						aria-label={formatMessage({ id: 'pagination.next' })}>
						<FormattedMessage id="pagination.next" />
					</span>
				)}
			</nav>
		</div>
	);
};
