import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Folder from '@models/Folder';
import type { View } from '@types';
import { type FC, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

type FileSelectorNavigationProps = {
	folders: View[];
	onNavigated: (folder: View) => void;
};

export const FileSelectorNavigation: FC<FileSelectorNavigationProps> = ({ folders, onNavigated = () => undefined }) => {
	return (
		<div className="flex items-center mt-4 text-xs text-gray-600 bg-gray-200">
			<button type="button" onClick={() => onNavigated(null)} className="px-4 py-2 focus-within:outline-none">
				<FontAwesomeIcon icon="home" />
			</button>
			{folders.map((folder, index) => (
				<Fragment key={folder instanceof Folder ? folder.getKey() : folder}>
					{folder === 'favorites' && (
						<span key={folder}>
							<FontAwesomeIcon icon="chevron-right" size="xs" className="text-gray-400" />
							<span className="px-4 py-2">
								<FontAwesomeIcon icon="star" className="mr-2 text-yellow-500" />
								<FormattedMessage id="file-manager.favorites" />
							</span>
						</span>
					)}
					{folder === 'recent' && (
						<span key={folder}>
							<FontAwesomeIcon icon="chevron-right" size="xs" className="text-gray-400" />
							<span className="px-4 py-2">
								<FontAwesomeIcon icon="clock" className="mr-2" />
								<FormattedMessage id="file-manager.recent" />
							</span>
						</span>
					)}
					{folder instanceof Folder && (
						<span key={folder.getKey()}>
							<FontAwesomeIcon icon="chevron-right" size="xs" className="text-gray-400" />
							<button type="button" onClick={e => (index === folders.length ? e.preventDefault() : onNavigated(folder))} className="px-4 py-2 focus-within:outline-none">
								{folder.icon('mr-2')}
								{folder.getName()}
							</button>
						</span>
					)}
				</Fragment>
			))}
		</div>
	);
};
