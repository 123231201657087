import type { Activity } from '@components/Activity';
import { DateDisplay } from '@components/DateTime';
import { LabelSelector, type LabelType } from '@components/Labels';
import Folder from '@models/Folder';
import type { Fileable } from '@types';
import { isObject } from '../../utils';
import { type FC, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

type ActivityItemDetailsProps = {
	entry: Activity;
	fileable: Fileable;
};

export const ActivityItemDetails: FC<ActivityItemDetailsProps> = ({ entry, fileable }) => {
	let langObjectScope = fileable instanceof Folder ? 'folder' : 'file';

	if (entry.Activity === 'folder.updated' && isObject(entry.Params)) {
		let formattedValue = (value: any, key: string) => {
			if (value === true || value === 'true' || value === '1' || value === 1) {
				return <FormattedMessage id="timeline.values.true" />;
			}

			if (value === false || value === 'false' || value === '0' || value === 0) {
				return <FormattedMessage id="timeline.values.false" />;
			}

			if (key === 'Access' && value === 'private') {
				return <FormattedMessage id="timeline.values.false" />;
			}

			if (key === 'Access' && value === 'public') {
				return <FormattedMessage id="timeline.values.true" />;
			}

			if (key === 'expires_at') {
				return <DateDisplay defaultFormat="long" force value={value} />;
			}

			if (key === 'Defaults') {
				return '';
			}

			return value;
		};

		return (
			<div className="p-2 my-3 rounded bg-yellow-50">
				<div className="grid grid-cols-3 gap-8">
					{Object.keys(entry.Params.original).map((key, index) => (
						<Fragment key={index}>
							<div>
								<label className="text-sm font-semibold">
									<FormattedMessage id={`timeline.${langObjectScope}.keys.${key}`} />
								</label>
							</div>
							<div className="col-span-2">
								<del className="mr-1 text-sm text-red-600">{formattedValue(entry.Params.original[key], key)}</del>
								<ins className="ml-1 text-sm font-semibold text-green-600">{formattedValue(entry.Params.changes[key], key)}</ins>
							</div>
						</Fragment>
					))}
				</div>
			</div>
		);
	}

	if (['property.attached', 'property.detached'].includes(entry.Activity)) {
		return (
			<div className="flex items-center gap-0.5 flex-wrap mb-2">
				{entry.Params.map((label: LabelType) => (
					<LabelSelector key={label.Id} label={label} disabled={true} />
				))}
			</div>
		);
	}

	return null;
};
