import { useFileManager } from '@components/FileManager';
import { Alert, Button, Input, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Row, ValidationField, Variant } from '@convoflo/ui';
import useClient from '@hooks/useClient';
import type { Fileable } from '@types';
import { type FC, type FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';
import type { WretchError, WretchResponse } from 'wretch';

type UnlinkMutation = {
	fileable: Fileable;
	accountPassword?: string;
};

type DialogUnlinkFileableProps = Omit<ModalProps, 'isOpen'> & {
	fileable: Fileable;
	onUnlinked?: () => void;
};

export const DialogUnlinkFileable: FC<DialogUnlinkFileableProps> = ({ fileable, onUnlinked = () => undefined, ...modalProps }) => {
	const { client, validation } = useClient();
	const fileManagerContext = useFileManager();

	const [isOpen, setIsOpen] = useState(true);
	const [accountPassword, setAccountPassword] = useState('');

	const { mutate: unlink, isLoading: isUnlinking } = useMutation<WretchResponse, WretchError, UnlinkMutation>(
		async ({ fileable, accountPassword }) =>
			await client
				.url(fileable.getRoute(null))
				.json({
					account_password: accountPassword
				})
				.delete()
				.res(),
		{
			onSuccess: () => {
				toast.success(<FormattedMessage id="dialog-unlink.unlinked" values={{ item: fileable.getName() }} />);
				fileManagerContext?.remove?.([fileable]);

				setIsOpen(false);
				onUnlinked();
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		unlink({ fileable, accountPassword });
	};

	return (
		<Modal isOpen={isOpen} onSubmit={onSubmit} {...modalProps}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage
					id="dialog-unlink.title"
					values={{
						item: (
							<span>
								{fileable.icon('mr-1.5')}
								{fileable.getName()}
							</span>
						)
					}}
				/>
			</ModalHeaderOnlyTitle>
			<ModalBody>
				<Alert variant={Variant.danger}>
					<FormattedMessage id="dialog-unlink.confirmation" />
				</Alert>

				{'account_password' in validation && (
					<Row>
						<Label>
							<FormattedMessage id="dialog-account-password.content" />
						</Label>
						<ValidationField fieldName="account_password" validation={validation}>
							<Input block autoFocus value={accountPassword} type="password" onChange={e => setAccountPassword(e.target.value)} />
						</ValidationField>
					</Row>
				)}
			</ModalBody>

			<ModalFooter>
				<Button disabled={isUnlinking} type="submit" className="mr-2" variant={Variant.danger}>
					{isUnlinking ? <FormattedMessage id="deleting" /> : <FormattedMessage id="unlink" />}
				</Button>
				<Button intent={Intent.secondary} variant={Variant.dark} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
