import StripeLogo from '@assets/images/Powered by Stripe - black.svg?react';
import { Button, Size, Variant } from '@convoflo/ui';
import useClient from '@hooks/useClient';
import Card from '@ui/Card';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';

export const PaymentRequestsOnboarding: FC = () => {
	const { client } = useClient();

	const { mutate: connect, isLoading } = useMutation(async () => client.url('organization/integrations/stripe-connect/connect').get().json<{ url: string }>(), {
		onSuccess: ({ url }) => {
			window.location.href = url;
		}
	});

	return (
		<div className="px-6 mt-8 lg:mt-20">
			<h1 className="mb-6 text-xl font-bold text-center text-gray-700 lg:mb-12 lg:text-4xl">
				<FormattedMessage id="payment-requests.onboarding.title" />
			</h1>
			<Card className="max-w-lg mx-auto prose">
				<h2 className="text-gray-600 border-b border-gray-200">
					<FormattedMessage id="payment-requests.onboarding.subheading" />
				</h2>
				<p>
					<FormattedMessage id="payment-requests.onboarding.text1" />
				</p>
				<p>
					<FormattedMessage id="payment-requests.onboarding.text2" />
				</p>
				<p>
					<FormattedMessage id="payment-requests.onboarding.text3" />
				</p>
				<Button disabled={isLoading} variant={Variant.success} size={Size.lg} iconEnd="arrow-right" shadow block onClick={() => connect()} loading={isLoading}>
					{isLoading ? <FormattedMessage id="payment-requests.onboarding.redirecting" /> : <FormattedMessage id="payment-requests.onboarding.connect" />}
				</Button>
				<StripeLogo className="w-32 mx-auto mt-6 text-gray-400" />
			</Card>
		</div>
	);
};
