import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import type File from '@models/File';
import type Folder from '@models/Folder';
import SignRequest from '@models/SignRequest';
import { Module, type ModuleContract, type SignRequestRole } from '@types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { type WretchError, type WretchResponse } from 'wretch';

type FileId = {
	id: number | string;
	file: File;
};

type CreateSignRequestApiRequest = {
	label: string;
	documents?: string[];
	template?: number;
	ordered?: boolean;
	roles: Partial<{
		id: number | string;
		signer: number;
		label: string;
		auth?: 'SMS';
		question?: string;
	}>[];
	reassign?: boolean;
};

type CreateSignRequestMutation = {
	folder: Folder;
	label: string;
	roles: Partial<SignRequestRole>[];
	filesId: FileId[];
	selectedTemplate: SignRequest | null;
	ordered?: boolean;
	enableReassignment?: boolean;
};

type UpdateSignRequestMutation = {
	signRequest: SignRequest;
	label: string;
	roles: Partial<SignRequestRole>[];
	filesId: FileId[];
	ordered?: boolean;
	enableReassignment?: boolean;
};

export type SignRequestDesignerGetResponse = {
	sign_request: SignRequest;
	url: string;
};

export type SignRequestDesignerQuery = {
	signRequest: SignRequest;
	url: string;
};

export const useSignRequestCreateMutation = () => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	return useMutation<SignRequest, WretchError, CreateSignRequestMutation>({
		mutationFn: async ({ folder, label, roles, filesId, selectedTemplate, ordered = false, enableReassignment = false }) => {
			let data: CreateSignRequestApiRequest = {
				reassign: enableReassignment,
				ordered,
				label,
				roles: roles
					.filter(role => role.signer || (selectedTemplate !== null && role.Id))
					.map(role => ({ id: role.Id ?? undefined, signer: role.signer?.ID!, auth: role.Auth || undefined, question: role.Question || undefined }))
			};

			if (selectedTemplate !== null) {
				data.template = selectedTemplate.Id;
			} else if (filesId && filesId.length > 0) {
				data.documents = filesId.map(fileId => fileId.file.OriginalLink);
			}

			return new SignRequest(await client.url(folder.getRoute('sign_requests')).json(data).post().json<SignRequest>());
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['sign-requests']);
		}
	});
};

export const useSignRequestUpdateMutation = () => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	return useMutation<SignRequest, WretchError, UpdateSignRequestMutation>({
		mutationFn: async ({ signRequest, label, roles, filesId, ordered = false, enableReassignment = false }) =>
			new SignRequest(
				await client
					.url(`sign_requests/${signRequest.Id}`)
					.json({
						reassign: enableReassignment,
						label,
						ordered,
						roles: roles.map(role => ({ signer: role.signer?.ID, id: role.Id, auth: role.Auth || undefined, question: role.Question || undefined })),
						documents: filesId.map(fileId => fileId.file.OriginalLink)
					})
					.put()
					.json<SignRequest>()
			),
		onSuccess: () => {
			queryClient.invalidateQueries(['sign-requests']);
		}
	});
};

export const useSignRequestDesignerUrlQuery = (signRequest: SignRequest) => {
	const { client } = useClient();

	return useQuery<string, WretchError>({
		queryKey: ['sign-requests', signRequest.Id, 'designer'],
		queryFn: async () => {
			const { url } = await client.url(`sign/${signRequest.Id}/designer`).get().json<SignRequestDesignerGetResponse>();
			return url;
		},

		retry: (_, error) => {
			return error.status === 400 && error.json.error.code === 'sign_request_not_ready';
		},
		refetchOnMount: 'always',
		staleTime: Infinity,
		retryDelay: 3000,
		onError: error => {
			if (error.status === 400 && error.json.error.code === 'sign_request_not_ready') return;
			console.error(error);
		}
	});
};

export const useSignRequestDeleteMutation = (signRequest: SignRequest) => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	const route = signRequest.folder !== null ? `sign_requests/${signRequest.Id}` : `account/sign_templates/${signRequest.Id}`;

	return useMutation<WretchResponse, WretchError, boolean>({
		mutationFn: async archive => await client.url(route).query({ archive: +archive }).delete().res(),
		onSuccess: () => {
			queryClient.invalidateQueries(['sign-requests']);
		}
	});
};

type SignRequestOverviewApiResponse = {
	IsSandbox: boolean;
	totals: Record<string, number>;
	contract: ModuleContract;
};

export const useSignRequestOverviewQuery = (scope: 'user' | 'organization' = 'user') => {
	const { client } = useClient();
	const { account } = useAccount();

	return useQuery(['sign-requests-overview', scope], async () => await client.url('sign_requests/overview').query({ scope }).get().json<SignRequestOverviewApiResponse>(), {
		enabled: account!.business.hasModule(Module.Esign)
	});
};
