import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Size } from '@convoflo/ui';
import { type Activity, ActivityIcons, ActivityItemDetails } from '@components/Activity';
import { DateTimeDisplay } from '@components/DateTime';
import { isObject } from '../../utils';
import File from '@models/File';
import Folder from '@models/Folder';
import type { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import type { Fileable } from '@types';
import UserAvatar from '@ui/UserAvatar';
import { formatDate } from '@utils/DateUtils';

type ActivityItemProps = {
	entry: Activity;
	fileable: Fileable;
	isLast?: boolean;
};

export const ActivityItem: FC<ActivityItemProps> = ({ entry, fileable, isLast = false }) => {
	const { locale } = useIntl();
	const { icon, color } =
		entry.Activity in ActivityIcons
			? ActivityIcons[entry.Activity]
			: {
					icon: 'circle',
					color: 'text-black'
			  };

	const values: Record<string, any> = {
		strong: (msg: string) => <strong className="font-semibold text-black">{msg}</strong>
	};

	// Custom values
	if (['folder.authors.updated', 'document.authors.updated'].includes(entry.Activity)) {
		values.link = (msg: string) => (
			<Link to="?open=permissions" className="text-theme-primary hover:underline">
				{msg}
			</Link>
		);
	}

	if (['property.attached'].includes(entry.Activity)) {
		values.n = entry.Params.length;
	}

	if (['property.detached'].includes(entry.Activity)) {
		values.n = entry.Params.length;
	}

	if (fileable instanceof Folder && ['folder.folder.added'].includes(entry.Activity)) {
		values.SecuredSpace = entry.folder?.SecuredSpace === true;
	}

	if (fileable instanceof Folder && ['folder.updated', 'folder.created'].includes(entry.Activity)) {
		values.SecuredSpace = fileable.SecuredSpace;
	}

	if (['comment.deleted'].includes(entry.Activity)) {
		values.creator = entry.comment?.creator.Name;
		values.created = formatDate(entry.comment?.created_at!, locale, 'PPp');
	}

	if (entry.file) {
		const file = new File(entry.file);
		values.file = (
			<>
				{file.icon()}
				{file.getName()}
			</>
		);
		if (entry.file.deleted_at === null) {
			values.file = (
				<Link to={{ pathname: file.getUrl(), state: { view: file } }} className="text-theme-primary hover:underline">
					{values.file}
				</Link>
			);
		}
	}

	if (isObject(entry.user)) {
		values.user = entry.user.Name;
	}

	if (isObject(entry.folder)) {
		const folder = new Folder(entry.folder);
		values.folder = (
			<>
				{folder.icon()}
				{folder.getName()}
			</>
		);
		if (entry?.folder?.deleted_at === null) {
			values.folder = (
				<Link to={{ pathname: folder.getUrl(), state: { view: folder } }} className="text-theme-primary hover:underline">
					{values.folder}
				</Link>
			);
		}
	}

	return (
		<div className={`relative flex items-start px-4 md:px-8 pb-8 ml-4 border-l-2 ${isLast ? 'border-transparent' : 'border-gray-400'}`}>
			<FontAwesomeIcon className={`${color} rounded-full border-2 border-gray-100 absolute bg-white -top-1 -left-[18px]`} mask="circle" transform="shrink-8" icon={icon as IconProp} size="2x" />

			<div className="flex-1 ml-4">
				<div className="flex">
					<UserAvatar user={entry.user} size={Size.sm} className="mr-2" />
					<div className="flex-1">
						<p className="mb-1 text-gray-600">
							<FormattedMessage id={`timeline.${entry.Activity}`} values={values} />
						</p>
						<ActivityItemDetails entry={entry} fileable={fileable} />
						<p className="text-xs text-gray-500">
							<DateTimeDisplay defaultFormat="full" force value={entry.created_at} />
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
