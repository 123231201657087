import { Logo } from '@components/Logo';
import type { FC, PropsWithChildren, ReactNode } from 'react';

type CheckoutOnboardingProps = PropsWithChildren<{
	sidebarCenterContents?: ReactNode;
	sidebarBottomContents?: ReactNode;
	bottomBarContents?: ReactNode;
}>;

export const OnboardingLayout: FC<CheckoutOnboardingProps> = ({ sidebarCenterContents, sidebarBottomContents, bottomBarContents, children }) => {
	return (
		<div className="h-screen bg-gradient-to-br from-gray-100 via-gray-50 to-white lg:flex">
			<div className="flex flex-col bg-theme-inverse lg:w-72 xl:w-96">
				<div className="flex flex-col h-full p-6 overflow-auto lg:px-8 lg:pt-16 lg:pb-8">
					<div className="mb-6 lg:mb-16">
						<Logo dark className="h-12" />
					</div>
					<div className="flex-1">{sidebarCenterContents}</div>
					<div>{sidebarBottomContents}</div>
				</div>
			</div>
			<div className="flex flex-col flex-1 pb-24 overflow-auto lg:h-full lg:pb-0">
				<div className="flex-1 px-6 py-6 overflow-auto lg:h-full lg:py-16 lg:px-16">{children}</div>
				{bottomBarContents}
			</div>
		</div>
	);
};
