import { Size } from '@convoflo/ui';
import { components, type OptionProps } from 'react-select';
import type { Contact } from '@types';
import UserAvatar from '@ui/UserAvatar';

export const ContactOption = ({ data: user, ...props }: OptionProps<Contact, true>) => (
	<components.Option data={user} {...props}>
		<div className="flex items-center space-x-2">
			<UserAvatar user={user} size={Size.sm} />
			<span className="font-semibold">{user.Name || user.Email}</span>
			{user.Name && <span className="text-sm text-gray-600"> {user.Email}</span>}
		</div>
	</components.Option>
);
