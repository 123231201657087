import { GuidedTour } from '@components/GuidedTour';
import { type Comment, Composer, type ComposerRef, type ComposerState, MessageListContext, type PostMessageMutation } from '@components/Message';
import { Button, Intent, Row, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import Folder from '@models/Folder';
import { Module, Permissions } from '@types';
import classNames from 'classnames';
import { type FC, type FormEvent, useContext, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Prompt } from 'react-router-dom';

type MessageFormProps = {
	message?: Comment;
	parent?: Comment;
	onClose?: () => void;
	onPosted?: (message: Comment, options: PostMessageMutation) => void;
};

export const MessageForm: FC<MessageFormProps> = ({ message, parent, onClose, onPosted = () => undefined }) => {
	const { formatMessage } = useIntl();
	const { fileable, queryKey } = useContext(MessageListContext);
	const { account } = useAccount();
	const [key, setKey] = useState(0);

	const composer = useRef<ComposerRef>(null);

	const [composerState, setComposerState] = useState<ComposerState>();
	const [isSecurityInfoExpanded, setIsSecurityInfoExpanded] = useState(false);

	const _onPosted = (postedMessage: Comment, options: PostMessageMutation) => {
		onPosted(postedMessage, options);
		onClose && onClose();
		setKey(key => key + 1);
	};

	const showRequestPaymentButton = fileable instanceof Folder && account!.hasFullAccess() && !message && fileable?.pivot?.Permissions === Permissions.ReadWrite;
	const canRequestPayment = showRequestPaymentButton && account!.business.hasModule(Module.Payments) && !message;
	const canRequestFile = fileable instanceof Folder && fileable?.pivot?.Permissions === Permissions.ReadWrite && !message;
	const canUploadFiles = fileable instanceof Folder && fileable?.canUploadFiles() === true && !message;
	const isEdit = !!message;
	const isReply = !!parent;

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		composer.current?.post();
	};

	return (
		<>
			<Prompt when={composerState?.isDirty} message={formatMessage({ id: 'unsaved_changes' })} />

			<form onSubmit={onSubmit}>
				<Row>
					<Composer
						key={key}
						ref={composer}
						className=""
						fileable={fileable!}
						queryKey={queryKey}
						source="message_list"
						message={message}
						parent={parent}
						titleInputProps={{
							className: classNames({ 'border border-gray-200 pt-2 border-b-0 rounded-t': isReply })
						}}
						inputProps={{
							placeholder: formatMessage({ id: 'comments.enter_message' }),
							className: classNames({
								'!border-0': isEdit || !isReply,
								'border-t-0 rounded-t-none !border-gray-200': isReply
							})
						}}
						plugins={{
							attachments: { enabled: canUploadFiles, folder: fileable as Folder },
							fileRequests: { enabled: canRequestFile },
							paymentRequests: { enabled: canRequestPayment, visible: showRequestPaymentButton },
							audience: { enabled: !isReply, containerClassName: 'mx-3' },
							options: { enabled: true }
						}}
						onPosted={_onPosted}
						onStateChanged={setComposerState}
					/>
				</Row>

				<div className={classNames('my-2', { 'px-4': !isReply })}>
					<div className="flex items-stretch order-2 gap-3 sm:order-1">
						<Button
							variant={Variant.primary}
							type="submit"
							disabled={composerState?.isDisabled || !composerState?.isPostable}
							icon={message ? 'save' : 'paper-plane'}
							loading={composerState?.isPosting}>
							{message ? <FormattedMessage id="save" /> : <FormattedMessage id="comments.post" />}
						</Button>
						{onClose !== undefined && (
							<Button variant={Variant.light} intent={Intent.secondary} type="button" onClick={onClose}>
								<FormattedMessage id="cancel" />
							</Button>
						)}
					</div>
				</div>

				{!message && (
					<div className={classNames('flex my-4 text-green-600', { 'px-4': !isReply })}>
						<FontAwesomeIcon icon="lock" className="mr-2" />
						<div className="flex-1 text-xs">
							<FormattedMessage id="comments.security-mention" />
							{!isSecurityInfoExpanded && (
								<button onClick={() => setIsSecurityInfoExpanded(true)} className="text-theme-primary hover:underline">
									<FormattedMessage id="comments.learn-more" />
								</button>
							)}
							{isSecurityInfoExpanded && (
								<span>
									<FormattedMessage id="comments.learn-more-expanded" />
								</span>
							)}
						</div>
					</div>
				)}
			</form>

			<GuidedTour name="messageZone" enabled={account?.hasFullAccess()} stepsToRemove={!canUploadFiles ? [1] : []} />
		</>
	);
};
