import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LinkButton from '@ui/LinkButton';
import { getSort } from '@utils';
import { type FC, type PropsWithChildren } from 'react';

type SortColumnHeaderProps = PropsWithChildren<{
	sortField: string;
	onSort?: (sort: string) => void;
	currentSort?: string;
	isDisabled?: boolean;
	activeClassName?: string;
	disabledClassName?: string;
	className?: string;
	valueType?: 'alpha' | 'size' | 'shapes' | 'numeric';
}>;

const SortColumnHeader: FC<SortColumnHeaderProps> = ({
	sortField,
	onSort = () => undefined,
	currentSort = '',
	isDisabled = false,
	activeClassName = 'active',
	disabledClassName = 'disabled',
	className = '',
	valueType = 'alpha',
	children
}) => {
	const { key, direction } = getSort(currentSort);

	const isActive = key === sortField;

	return (
		<LinkButton
			disabled={isDisabled}
			onClick={() => onSort(`${direction === 'desc' ? '' : '!'}${sortField}`)}
			className={`${className} ${isActive ? activeClassName : ''} ${isDisabled ? disabledClassName : ''} leading-4 font-medium uppercase tracking-wider text-xs`}>
			{children}
			{isActive && direction === 'asc' && <FontAwesomeIcon icon={`sort-${valueType}-down` as IconProp} className="ml-2 text-gray-400" />}
			{isActive && direction === 'desc' && <FontAwesomeIcon icon={`sort-${valueType}-down` as IconProp} className="ml-2 text-gray-400" />}
		</LinkButton>
	);
};

export default SortColumnHeader;
