import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import AppContext from '@contexts/AppContext';
import { useAccount } from '@hooks/useAccount';
import { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import ErrorLayout from '@ui/Layout/Error';
import UserAvatar from '@ui/UserAvatar';

const Page = () => {
	const { formatMessage } = useIntl();
	const { setPageTitle: setTitle } = useContext(AppContext);
	const { state } = useLocation<{ intended: Location }>();
	const history = useHistory();
	const { account, logout } = useAccount();

	useEffect(() => {
		setTitle(formatMessage({ id: 'errors.forbidden.title' }));
	}, [setTitle, formatMessage]);

	return (
		<ErrorLayout header={false} icon={<FontAwesomeIcon icon="exclamation-triangle" className="text-yellow-500" size="8x" />}>
			<p className="text-lg font-light text-gray-800">
				{account ? (
					<FormattedMessage id="errors.forbidden.text" values={{ name: account?.Name, strong: msg => <strong className="font-medium">{msg}</strong> }} />
				) : (
					<FormattedMessage id="errors.forbidden.text.logged-out" values={{ strong: msg => <strong className="font-medium">{msg}</strong> }} />
				)}
			</p>
			{account && (
				<p className="mt-6 mb-3">
					<FormattedMessage id="logged_in_as" />
				</p>
			)}
			<div className="flex flex-col items-stretch justify-between space-y-4 sm:space-y-0 sm:space-x-4 sm:flex-row">
				{account && (
					<div className="flex items-center sm:justify-end">
						<div className="mr-4">
							<UserAvatar user={account} size={Size.md} />
						</div>
						<div>
							<h6 className="text-sm font-semibold text-gray-700 ">{account.Name}</h6>
							<p className="text-xs text-gray-500">{account.Email}</p>
						</div>
					</div>
				)}
				<div className="flex flex-col justify-center sm:items-center sm:flex-row">
					{account ? (
						<Button size={Size.sm} intent={Intent.secondary} variant={Variant.primary} onClick={() => logout(state?.intended)}>
							<FormattedMessage id="change_account" />
						</Button>
					) : (
						<Button intent={Intent.primary} shadow variant={Variant.primary} onClick={() => history.push('/login', { next: state?.intended })} className="mt-3">
							<FormattedMessage id="login.login" />
						</Button>
					)}
				</div>
			</div>
		</ErrorLayout>
	);
};

export default Page;
