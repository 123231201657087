import { FetchHttpHandler, type FetchHttpHandlerOptions } from '@aws-sdk/fetch-http-handler';
import { HttpRequest, HttpResponse } from '@aws-sdk/protocol-http';
import { buildQueryString } from '@aws-sdk/querystring-builder';
import type { HeaderBag, HttpHandlerOptions } from '@aws-sdk/types';
import { Subject } from 'rxjs';

export class XmlHttpRequestHttpHandler extends FetchHttpHandler {
	private myRequestTimeout;

	onProgress$: Subject<{ path: string; progressEvent: ProgressEvent }> = new Subject();

	constructor({ requestTimeout }: FetchHttpHandlerOptions = {}) {
		super({ requestTimeout });
		this.myRequestTimeout = requestTimeout;
	}

	override handle(request: HttpRequest, { abortSignal }: HttpHandlerOptions = {}): Promise<{ response: HttpResponse }> {
		// we let XHR only handle PUT requests with body (as we want to have progress events here), the rest by fetch
		if (request.method === 'PUT' && request.body) {
			return this.handleByXhr(request, { abortSignal });
		}
		return super.handle(request, { abortSignal });
	}

	/**
	 * handles a request by XHR instead of fetch
	 * this is a copy the `handle` method of the `FetchHttpHandler` class of @aws-sdk/fetch-http-handler
	 * replacing the `Fetch`part with XHR
	 */
	private handleByXhr(request: HttpRequest, { abortSignal }: HttpHandlerOptions = {}): Promise<{ response: HttpResponse }> {
		const requestTimeoutInMs = this.myRequestTimeout;

		// if the request was already aborted, prevent doing extra work
		if (abortSignal?.aborted) {
			const abortError = new Error('Request aborted');
			abortError.name = 'AbortError';
			return Promise.reject(abortError);
		}

		let path = request.path;
		if (request.query) {
			const queryString = buildQueryString(request.query);
			if (queryString) {
				path += `?${queryString}`;
			}
		}

		const { port, method } = request;
		const url = `${request.protocol}//${request.hostname}${port ? `:${port}` : ''}${path}`;
		const body = method === 'GET' || method === 'HEAD' ? undefined : request.body;
		const headers = new Headers(request.headers);

		const xmlHttpRequest = new XMLHttpRequest();
		const xhrPromise = new Promise<{ headers: HeaderBag; body: Blob; status: number }>((resolve, reject) => {
			try {
				xmlHttpRequest.responseType = 'blob';

				// bind the events
				xmlHttpRequest.onload = progressEvent => {
					resolve({
						body: xmlHttpRequest.response,
						headers: this.parseHeaders(xmlHttpRequest.getAllResponseHeaders()),
						status: xmlHttpRequest.status
					});
				};
				xmlHttpRequest.onerror = progressEvent => reject(new Error(xmlHttpRequest.responseText));
				xmlHttpRequest.onabort = progressEvent => {
					const abortError = new Error('Request aborted');
					abortError.name = 'AbortError';
					reject(abortError);
				};

				// progress event musst be bound to the `upload` property
				if (xmlHttpRequest.upload) {
					xmlHttpRequest.upload.onprogress = progressEvent => this.onProgress$.next({ path, progressEvent });
				}

				xmlHttpRequest.open(method, url);
				// append headers
				headers.forEach((headerVal, headerKey, headers) => {
					if (['host', 'content-length'].indexOf(headerKey.toLowerCase()) >= 0) {
						// avoid "refused to set unsafe header" error message
						return;
					}

					xmlHttpRequest.setRequestHeader(headerKey, headerVal);
				});

				xmlHttpRequest.send(body);
			} catch (e) {
				console.error('S3 XHRHandler error', e);
				reject(e);
			}
		});

		const raceOfPromises = [
			xhrPromise.then(response => {
				const hasReadableStream = response.body !== undefined;

				// Return the response with buffered body
				if (!hasReadableStream) {
					return response.body.text().then(body => ({
						response: new HttpResponse({
							headers: response.headers,
							statusCode: response.status,
							body
						})
					}));
				}

				// Return the response with streaming body
				return {
					response: new HttpResponse({
						headers: response.headers,
						statusCode: response.status,
						body: response.body
					})
				};
			}),
			this.requestTimeoutFn(requestTimeoutInMs)
		];
		if (abortSignal) {
			raceOfPromises.push(
				new Promise<never>((resolve, reject) => {
					abortSignal.onabort = () => {
						xmlHttpRequest.abort();
					};
				})
			);
		}
		return Promise.race(raceOfPromises);
	}

	private parseHeaders(headersAsString: string): HeaderBag {
		// Convert the header string into an array
		// of individual headers
		const headersAsArray = headersAsString.trim().split(/[\r\n]+/);

		// Create a map of header names to values
		const headersMap: HeaderBag = {};

		headersAsArray.forEach(line => {
			var parts = line.split(': ');
			var header = parts.shift() || '';
			var value = parts.join(': ');
			headersMap[header] = value;
		});

		return headersMap;
	}

	private requestTimeoutFn(timeoutInMs = 0): Promise<never> {
		return new Promise((resolve, reject) => {
			if (timeoutInMs) {
				setTimeout(() => {
					const timeoutError = new Error(`Request did not complete within ${timeoutInMs} ms`);
					timeoutError.name = 'TimeoutError';
					reject(timeoutError);
				}, timeoutInMs);
			}
		});
	}
}
