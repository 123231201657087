import { DateTimeDisplay } from '@components/DateTime';
import { FileManagerContext } from '@components/FileManager';
import { Alert, Button, HelperText, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeader, type ModalProps, Row, Select, Size, Toggle, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useView } from '@hooks/use-view';
import useClient from '@hooks/useClient';
import File, { type Version as FileVersion } from '@models/File';
import { versionMiddleware } from '@service/Client';
import EmptyState from '@ui/EmptyState';
import LinkButton from '@ui/LinkButton';
import SortColumnHeader from '@ui/SortColumnHeader';
import { Tab } from '@ui/Tab';
import { Table, TableCell, TableHeader } from '@ui/Table';
import UserAvatar from '@ui/UserAvatar';
import { formatLocalDateToUTC } from '@utils/DateUtils';
import { filesize } from '@utils/StringUtils';
import { format, parseISO } from 'date-fns';
import React, { type FC, type FormEvent, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHotkeys } from 'react-hotkeys-hook';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import type { WretchError } from 'wretch';
import { getSort } from '../../utils';

type DialogFileSettingsProps = Omit<ModalProps, 'isOpen'> & {
	file: File;
	onUpdate?: (file: File) => void;
};

type SaveFileMutation = {
	isPublic?: boolean;
	isRestrictDownloads?: boolean;
	expiresAt?: string | null;
	editable?: boolean;
};

type SaveFileSettingsApiRequest = {
	'restrict-downloads'?: 0 | 1;
	access?: 'public' | 'private';
	expires_at?: string | null;
	editable?: boolean;
};

export const DialogFileSettings: FC<DialogFileSettingsProps> = ({ file, onUpdate = () => undefined, ...modalProps }) => {
	const { client } = useClient();
	const { update: updateItem = () => undefined } = useContext(FileManagerContext) ?? {};
	const { view, update: updateView } = useView();
	const { formatMessage } = useIntl();

	const [isOpen, setIsOpen] = useState(true);
	const [showMetadata, setShowMetadata] = useState(false);
	const [isPublic, setIsPublic] = useState(file.Access === 'public');
	const [isEditable, setIsEditable] = useState(file.Editable);
	const [isRestrictDownloads, setIsRestrictDownloads] = useState(!!file.RestrictDownloads);
	const [expiresAt, setExpiresAt] = useState<string>(file.expires_at ? format(parseISO(file.expires_at), 'yyyy-MM-dd') : '');

	const { mutate: save, isLoading: isSaving } = useMutation<File, WretchError, SaveFileMutation>(
		async ({ isPublic, isRestrictDownloads, expiresAt, editable }) => {
			const data: SaveFileSettingsApiRequest = {};

			data.editable = editable;
			data['restrict-downloads'] = isRestrictDownloads ? 1 : 0;
			data.access = isPublic ? 'public' : 'private';
			data.expires_at = expiresAt ? formatLocalDateToUTC(expiresAt).toUTCString() : null;

			return new File(
				await client
					.url(file.getRoute(null, 'files'))
					.middlewares([versionMiddleware(2)])
					.json(data)
					.put()
					.json()
			);
		},
		{
			onError: error => console.error(error),
			onSuccess: file => {
				setIsPublic(file.Access === 'public');
				setIsEditable(file.Editable);
				setIsRestrictDownloads(!!file.RestrictDownloads);
				setExpiresAt(file.expires_at ? format(parseISO(file.expires_at), 'yyyy-MM-dd') : '');
				toast.success(<FormattedMessage id="file-manager.settings_updated" values={{ item: file.getName() }} />);

				updateItem(file);

				if (view instanceof File && file.getKey() === view.getKey()) {
					updateView(file);
				}

				onUpdate(file);
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		save({ isRestrictDownloads, isPublic, expiresAt, editable: isEditable });
	};

	useHotkeys('m', () => {
		setShowMetadata(true);
	});

	return (
		<Modal isOpen={isOpen} onSubmit={onSubmit} {...modalProps}>
			<Tab.Group as={React.Fragment}>
				<ModalHeader className="flex-col !justify-normal !items-stretch">
					<h3 className="text-lg font-medium text-gray-900">
						{file.icon('mr-2')}
						{file.getName()}
					</h3>

					<Tab.List className="mt-4 -mx-8">
						<Tab>
							<FormattedMessage id="file-settings.settings" />
						</Tab>
						<Tab>
							<FormattedMessage id="file-settings.versions" />
						</Tab>
						{showMetadata && (
							<Tab>
								<FormattedMessage id="folder-settings.metadata" />
							</Tab>
						)}
					</Tab.List>
				</ModalHeader>
				<ModalBody>
					<Tab.Panels>
						<Tab.Panel>
							<div className="space-y-12">
								<section>
									<div className="flex items-center mb-4 space-x-3">
										<h3 className="text-xs font-medium text-gray-400 uppercase">
											<FormattedMessage id="file-settings.permissions_security" />
										</h3>
									</div>
									<Row>
										<Label htmlFor="file-access">
											<FormattedMessage id="file-settings.access" />
										</Label>
										<Select defaultValue={isPublic ? 'public' : 'private'} onChange={e => setIsPublic(e.target.value === 'public')}>
											<FormattedMessage id="file-settings.private">{message => <option value="private">{message}</option>}</FormattedMessage>
											<FormattedMessage id="file-settings.public">{message => <option value="public">{message}</option>}</FormattedMessage>
										</Select>
									</Row>

									<Row className="mt-4">
										<Label htmlFor="restrict-downloads">
											<FormattedMessage id="file-settings.restrict_downloads" />
											<Toggle className="ml-4" checked={isRestrictDownloads} onChange={e => setIsRestrictDownloads(e.target.checked)} />
										</Label>
									</Row>

									<Row className="mt-4">
										<Label htmlFor="editable">
											<FormattedMessage id="file-settings.editable" />
											<Toggle className="ml-4" checked={isEditable} onChange={e => setIsEditable(e.target.checked)} />
										</Label>
										<HelperText>
											<FormattedMessage id="file-settings.editable_desc" />
										</HelperText>
									</Row>
								</section>

								<section>
									<div className="flex items-center mb-4 space-x-3">
										<h3 className="text-xs font-medium text-gray-400 uppercase">
											<FormattedMessage id="file-settings.auto_delete" />
										</h3>
									</div>

									<Row>
										<p>
											<FormattedMessage
												id="file-settings.expiration_date"
												values={{
													input: (
														<input
															type="date"
															className="w-32 px-0 py-1 mx-1 text-sm text-center border-0 border-b-2 border-gray-200 rounded bg-gray-50 focus-within:border-theme-primary focus:ring-0"
															value={expiresAt}
															placeholder={formatMessage({ id: 'file-settings.select_date' })}
															onChange={e => setExpiresAt(e.target.value)}
														/>
													)
												}}
											/>
										</p>
										{!!file.expires_by && (
											<Alert variant={Variant.warning} className="mt-2">
												<FormattedMessage
													id="file-settings.expiration_date_description"
													values={{
														name: file.expires_by.Name,
														strong: msg => <span className="font-semibold">{msg}</span>
													}}
												/>
											</Alert>
										)}
									</Row>
								</section>
							</div>
						</Tab.Panel>
						<Tab.Panel>
							<VersionsTable file={file} />
						</Tab.Panel>
						{showMetadata && (
							<Tab.Panel>
								<Table>
									{file.meta?.map(metadata => (
										<tr>
											<TableCell>{metadata.key}</TableCell>
											<TableCell>{metadata.value}</TableCell>
										</tr>
									))}
								</Table>
							</Tab.Panel>
						)}
					</Tab.Panels>
				</ModalBody>
				<ModalFooter>
					<Button variant={Variant.primary} intent={Intent.primary} loading={isSaving} type="submit" disabled={isSaving}>
						<FormattedMessage id="save" />
					</Button>
					<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
						<FormattedMessage id="close" />
					</Button>
				</ModalFooter>
			</Tab.Group>
		</Modal>
	);
};

type VersionsTableProps = {
	file: File;
	onUpdate?: (file: File) => void;
	onClose?: () => void;
};

export const VersionsTable: FC<VersionsTableProps> = ({ file, onUpdate = () => undefined }) => {
	const { client } = useClient();

	const [sort, setSort] = useState('!date');

	const { data: versions = [], isLoading } = useQuery<FileVersion[]>(['view', `file:${file.URL}`, 'versions', sort], async () => {
		const _versions = await client.url(file.getRoute('versions', 'files')).query({ sort }).get().json<FileVersion[]>();
		const { direction } = getSort(sort);
		return _versions.map((version, index) => {
			version.i = direction === 'desc' ? _versions.length - index : index + 1;
			return version;
		});
	});

	return (
		<>
			{versions.length > 0 && !isLoading && (
				<table className="min-w-full">
					<thead>
						<tr>
							<TableHeader scope="col" className="w-16 text-right whitespace-nowrap">
								<SortColumnHeader onSort={setSort} sortField="date" currentSort={sort} valueType="numeric">
									<FormattedMessage id="file-settings.n" />
								</SortColumnHeader>
							</TableHeader>
							<TableHeader className="w-2/5">
								<SortColumnHeader onSort={setSort} sortField="name" currentSort={sort}>
									<FormattedMessage id="name" />
								</SortColumnHeader>
							</TableHeader>
							<TableHeader>
								<SortColumnHeader onSort={setSort} sortField="creator" currentSort={sort}>
									<FormattedMessage id="file-settings.creator" />
								</SortColumnHeader>
							</TableHeader>
							<TableHeader />
						</tr>
					</thead>
					<tbody style={{ maxHeight: 400 }}>
						{versions.map(version => (
							<VersionRow key={version.Id} file={file} version={version} onUpdate={onUpdate} />
						))}
					</tbody>
				</table>
			)}

			{isLoading && (
				<div className="flex items-center justify-center" style={{ maxHeight: '50vh', height: 100 }}>
					<p>
						<FontAwesomeIcon icon="spinner" className="mr-2" pulse />
						<FormattedMessage id="loading" />
					</p>
				</div>
			)}

			{versions.length === 0 && !isLoading && <EmptyState icon="copy" title={<FormattedMessage id="file-settings.total_versions" values={{ n: 0 }} />} />}
		</>
	);
};

type RevertVersionMutation = {
	version: FileVersion;
};

type VersionRowProps = {
	file: File;
	version: FileVersion;
	onUpdate?: (file: File) => void;
};

const VersionRow: FC<VersionRowProps> = ({ file, version, onUpdate = () => undefined }) => {
	const { client } = useClient();
	const { locale } = useIntl();
	const history = useHistory();
	const location = useLocation();
	const queryClient = useQueryClient();

	const { mutate: revert, isLoading: isReverting } = useMutation<File, WretchError, RevertVersionMutation>(
		async ({ version }) => new File(await client.url(file.getRoute('update')).post({ version: version.Id }).json()),
		{
			onError: console.error,
			onSuccess: file => {
				history.replace(location.pathname, { view: file });
				queryClient.invalidateQueries(['view', `file:${file.URL}`, 'versions']);
				queryClient.refetchQueries(['view', `file:${file.URL}`]);
				onUpdate(file);
				toast.success(<FormattedMessage id="file-settings.reverted" />);
			}
		}
	);

	return (
		<tr>
			<TableCell className="text-sm text-right text-gray-500">
				<FormattedMessage id="file-settings.number" values={{ n: version.i }} />
			</TableCell>
			<TableCell>
				<h6 className="font-semibold">{version.Name}</h6>
				<p className="text-xs text-gray-500">
					<DateTimeDisplay defaultFormat="relative" value={version.created_at} /> – {filesize(version.Size, locale)}
				</p>
			</TableCell>
			<TableCell>
				<div className="flex items-center">
					<UserAvatar user={version.creator} size={Size.xs} className="mr-2" />
					<div className="flex-1">{version.creator.Name}</div>
				</div>
			</TableCell>
			<TableCell className="text-right">
				{file.current.Id !== version.Id && (
					<>
						{!isReverting && (
							<LinkButton className="text-sm" type="button" onClick={() => revert({ version })}>
								<FormattedMessage id="file-settings.revert" />
							</LinkButton>
						)}
						{isReverting && (
							<LinkButton className="text-sm" type="button" disabled>
								<FontAwesomeIcon icon="spinner" pulse className="mr-2" />
								<FormattedMessage id="file-settings.reverting" />
							</LinkButton>
						)}
					</>
				)}
				{file.current.Id === version.Id && (
					<Button size={Size.sm} disabled type="button">
						<FormattedMessage id="file-settings.current_version" />
					</Button>
				)}
			</TableCell>
		</tr>
	);
};
