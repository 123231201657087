import Organization from '@models/Organization';
import User from '@models/User';

export enum Role {
	admin = 'admin',
	regular = 'user',
	limited = 'limited'
}

export interface OrganizationUser extends User {
	Email: string;
	FirstName: string | null;
	LastName: string | null;
	Locale: string;
	Role: Role;
	MFA: boolean;
	created_at: string;
	Locked: boolean;
	ResetPassword: boolean;
}

export type OrganizationQuota = {
	used: number;
	available: number;
	pricePerUser: number;
};

export type OrganizationInvite = {
	'@type': 'OrganizationInvitation';
	Id: number;
	Email: string;
	Role: Role;
	Locale: string;
	Token: string;
	business: Organization;
	expires_at: string;
	created_at: string;
};

export type InviteResponse = {
	Email: string;
	Token: string;
	business: Organization;
};
