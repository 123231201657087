import type { PaymentRequest } from '@components/PaymentRequest';
import { useMutation } from 'react-query';
import useClient from './useClient';

export const usePaymentRequests = () => {
	const { client } = useClient();

	const { mutate: pay, isLoading: isPaying } = useMutation(async (paymentRequest: PaymentRequest) => client.url(`payment-requests/${paymentRequest.Id}/url`).get().json<{ url: string }>(), {
		onSuccess: ({ url }) => {
			window.location.href = url;
		}
	});

	return {
		pay,
		isPaying
	};
};
