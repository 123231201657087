import { type Instruction, type ItemMode } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { DependencyContext, findNode, getPathToNode, TreeContext, treeDataReducer, TreeNode, type TreeAction, type TreeContextValue, type TreeNodeType, type TreeState } from '@ui/Tree';
import memoize from 'memoize-one';
import { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState, type FC } from 'react';

const createTreeItemRegistry = () => {
	const registry = new Map<string, { element: HTMLElement; actionMenuTrigger: HTMLElement }>();

	const registerTreeItem = ({ itemId, element, actionMenuTrigger }: { itemId: string; element: HTMLElement; actionMenuTrigger: HTMLElement }) => {
		registry.set(itemId, { element, actionMenuTrigger });
		return () => {
			registry.delete(itemId);
		};
	};

	return { registry, registerTreeItem };
};

type TreeProps = {
	data: TreeNodeType[];
	selectedNode: TreeNodeType | null;
	onNodeSelected?: (item: TreeNodeType) => void;
	renderItem: (item: TreeNodeType, actions: { toggle: () => void }) => JSX.Element;
	renderItemDragPreview: (item: TreeNodeType) => JSX.Element;
	onUpdated?: (data: TreeNodeType[]) => void;
};

export const Tree: FC<TreeProps> = ({ data: initialData, selectedNode, onUpdated = () => undefined, renderItem, renderItemDragPreview, onNodeSelected }) => {
	const [state, updateState] = useReducer(
		(state: TreeState, action: TreeAction | null) => ({
			data: treeDataReducer(state.data, action),
			lastAction: action
		}),
		{ data: initialData, lastAction: null },
		() => ({ data: initialData, lastAction: null })
	);
	const { extractInstruction } = useContext(DependencyContext);

	const [{ registerTreeItem }] = useState(createTreeItemRegistry);

	const { data } = state;

	const ref = useRef<HTMLDivElement>(null);
	const lastStateRef = useRef<TreeNodeType[]>(data);

	useEffect(() => {
		lastStateRef.current = data;
	}, [data]);

	useEffect(() => {
		if (state.lastAction?.type !== 'updateTree') {
			onUpdated(state.data);
		}
	}, [state]);

	useEffect(() => {
		updateState({
			type: 'updateTree',
			data: initialData
		});
	}, [initialData]);

	/**
	 * Returns the items that the item with `itemId` can be moved to.
	 *
	 * Uses a depth-first search (DFS) to compile a list of possible targets.
	 */
	const getMoveTargets = useCallback(({ itemId }: { itemId: string }) => {
		const data = lastStateRef.current;

		const targets = [];

		const searchStack = Array.from(data);
		while (searchStack.length > 0) {
			const node = searchStack.pop();

			if (!node) {
				continue;
			}

			/**
			 * If the current node is the item we want to move, then it is not a valid
			 * move target and neither are its children.
			 */
			if (node.id === itemId) {
				continue;
			}

			targets.push(node);

			node.children.forEach(childNode => searchStack.push(childNode));
		}

		return targets;
	}, []);

	const getChildrenOfItem = useCallback((itemId: string) => {
		const data = lastStateRef.current;

		/**
		 * An empty string is representing the root
		 */
		if (itemId === '') {
			return data;
		}

		const item = findNode(data, itemId);

		return item?.children ?? [];
	}, []);

	const context = useMemo<TreeContextValue>(
		() => ({
			dispatch: updateState,
			uniqueContextId: Symbol('unique-id'),
			// memoizing this function as it is called by all tree items repeatedly
			// An ideal refactor would be to update our data shape
			// to allow quick lookups of parents
			getPathToItem: memoize((targetId: string) => getPathToNode({ current: lastStateRef.current, targetId }) ?? []),
			getMoveTargets,
			getChildrenOfItem,
			registerTreeItem
		}),
		[getChildrenOfItem, getMoveTargets, registerTreeItem]
	);

	useEffect(() => {
		return combine(
			monitorForElements({
				canMonitor: ({ source }) => source.data.uniqueContextId === context.uniqueContextId,
				onDrop(args) {
					const { location, source } = args;
					// didn't drop on anything
					if (!location.current.dropTargets.length) {
						return;
					}

					if (source.data.type === 'tree-item') {
						const itemId = source.data.id as string;

						const target = location.current.dropTargets[0];
						if (!target) {
							return;
						}
						const targetId = target.data.id as string;

						const instruction: Instruction | null = extractInstruction(target.data);

						if (instruction !== null) {
							updateState({
								type: 'instruction',
								instruction,
								itemId,
								targetId
							});
						}
					}
				}
			})
		);
	}, [context, extractInstruction]);

	return (
		<TreeContext.Provider value={context}>
			<div ref={ref}>
				{data.map((item, index, array) => {
					const type: ItemMode = (() => {
						if (item.children.length && item.isOpen) {
							return 'expanded';
						}

						if (index === array.length - 1) {
							return 'last-in-group';
						}

						return 'standard';
					})();

					return <TreeNode item={item} level={0} key={item.id} mode={type} index={index} render={renderItem} renderDragPreview={renderItemDragPreview} onNodeSelected={onNodeSelected} />;
				})}
			</div>
		</TreeContext.Provider>
	);
};
