import { useCurrentTab } from '@components/FileManager';
import FullScreenLoading from '@components/FullScreenLoading';
import AppContext from '@contexts/AppContext';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import useUrlSearch from '@hooks/useUrlSearch';
import File from '@models/File';
import Folder from '@models/Folder';
import type { FileManagerRouteParams } from '@pages/view';
import { versionMiddleware } from '@service/Client';
import type { View } from '@types';
import { type Dispatch, type FC, type SetStateAction, createContext, useContext, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import type { WretchError } from 'wretch';

export const ViewContext = createContext<
	| {
			view: View;
			tab: string;
			key: string | null;
			fullScreenDragNDropEnabled: boolean;
			setEnableFullScreenDragNDrop: Dispatch<SetStateAction<boolean>>;
			update: (view: View) => void;
	  }
	| undefined
>(undefined);

type ViewProviderProps = {
	children: (view: View) => JSX.Element;
	onViewLoaded?: (view: View) => void;
};

export const ViewProvider: FC<ViewProviderProps> = ({ children = () => undefined, onViewLoaded }) => {
	const { formatMessage } = useIntl();
	const { account } = useAccount();
	const { client } = useClient();
	const history = useHistory();
	const queryClient = useQueryClient();
	const { setPageTitle: setTitle, setLogo, logo } = useContext(AppContext);

	let { folderParam1 = null, folderParam2 = null, fileParam1 = null, fileParam2 = null } = useParams<FileManagerRouteParams>();
	const { state: initialState = null } = useLocation<{ view: View }>();
	const { q: searchQuery = null, comment: highlightedComment = null } = useUrlSearch();

	const [fullScreenDragNDropEnabled, setEnableFullScreenDragNDrop] = useState(false);

	const key = useMemo(() => {
		// ?q=.pdf
		if (searchQuery !== null) {
			return `q:${searchQuery}`;
		}

		// /vf/{folderParam1}/{folderParam2}/{tab?}
		if (folderParam1 !== null && folderParam2 !== null) {
			return `folder:${folderParam1}/${folderParam2}`;
		}

		// /dl/{fileParam1}/{fileParam2}/{tab?}
		if (fileParam1 !== null && fileParam2 !== null) {
			return `file:${fileParam1}/${fileParam2}`;
		}

		// root
		return null;
	}, [fileParam1, fileParam2, folderParam1, folderParam2, searchQuery]);

	const loggedIn = !!account;

	const useQueryOptions = useMemo(() => {
		let initialData: View = null;

		if (searchQuery !== null && searchQuery.length > 0) {
			initialData = searchQuery;
		} else if (typeof initialState?.view === 'string' || initialState?.view instanceof File || initialState?.view instanceof Folder) {
			initialData = initialState.view;
		} else if (initialState?.view?.['@type'] === 'Folder') {
			initialData = initialState.view instanceof Folder ? initialState.view : new Folder(initialState.view);
		} else if (initialState?.view?.['@type'] === 'Document') {
			initialData = initialState.view instanceof File ? initialState.view : new File(initialState.view);
		}

		let needsToRefetch = true;

		if (initialData instanceof File) {
			needsToRefetch = (initialData.ancestors === undefined || initialData?.pivot?.Permissions === undefined) && loggedIn;
		}

		if (initialData instanceof Folder) {
			needsToRefetch = initialData?.ancestors === undefined || initialData?.pivot?.Permissions === undefined;
		}

		return {
			enabled: needsToRefetch,
			initialData: initialData
		};
	}, [loggedIn, initialState?.view, searchQuery]);

	// Load the view correctly
	const { data: view } = useQuery<View, WretchError>(
		['view', key],
		async () => {
			if (searchQuery !== null && searchQuery.length > 0) {
				return searchQuery;
			}

			if (folderParam1 !== null && folderParam2 !== null) {
				const payload = await client
					.url(`folders/${folderParam1}/${folderParam2}`)
					.middlewares([versionMiddleware(2)])
					.get()
					.json<Folder>();

				if (!payload) throw new Error();

				return new Folder(payload);
			}

			if (fileParam1 !== null && fileParam2 !== null) {
				const payload = await client
					.url(`files/${fileParam1}/${fileParam2}`)
					.middlewares([versionMiddleware(2)])
					.get()
					.json<File>();

				if (!payload) throw new Error();

				return new File(payload);
			}

			return null;
		},
		{
			...useQueryOptions,
			onError: error => {
				if (error.status === 403 && account !== null) {
					history.push('/403', { error, intended: history.location });
				} else if (error.status === 403 && account === null) {
					history.push('/login', { intended: history.location });
				}

				if (error.status === 404) {
					history.replace('/files');
					if (folderParam1 !== null && folderParam2 !== null) {
						toast.error(<FormattedMessage id="errors.folder_not_found" />);
					} else if (fileParam1 !== null && fileParam2 !== null) {
						toast.error(<FormattedMessage id="errors.file_not_found" />);
					}
				}
			},
			onSuccess: onViewLoaded
		}
	);

	const update = (view: View) => {
		queryClient.setQueryData(['view', key], view);
	};

	const defaultTab = useCurrentTab();

	const tab = useMemo(() => {
		// ?comment=<id>
		if (highlightedComment !== null) {
			return 'comments';
		}

		// ?q=.pdf
		if (searchQuery !== null) {
			return 'files';
		}

		// /vf/{folderParam1}/{folderParam2}/{tab?}
		if (folderParam1 !== null && folderParam2 !== null) {
			return defaultTab ?? 'comments';
		}

		// /dl/{fileParam1}/{fileParam2}/{tab?}
		if (fileParam1 !== null && fileParam2 !== null) {
			return defaultTab ?? 'comments';
		}

		return 'files';
	}, [defaultTab, fileParam1, fileParam2, folderParam1, folderParam2, highlightedComment, searchQuery]);

	useEffect(() => {
		if (!view) {
			setTitle(formatMessage({ id: 'file-manager.title' }));
		} else if (typeof view === 'string') {
			setTitle(formatMessage({ id: 'file-manager.title.search' }, { query: view }));
		} else if (view !== null) {
			setTitle(formatMessage({ id: `file-manager.title.${tab}` }, { file: view.getName() }));
		}
	}, [setTitle, view, formatMessage, tab]);

	useEffect(() => {
		if (view instanceof Folder || view instanceof File) {
			setLogo(view.creator.business?.LogoDark || view.creator.business?.LogoLight || logo || null);
		} else {
			setLogo(null);
		}
	}, [view, setLogo, logo]);

	if (view === undefined) {
		return <FullScreenLoading />;
	}

	return <ViewContext.Provider value={{ view, tab, key, update, fullScreenDragNDropEnabled, setEnableFullScreenDragNDrop }}>{children(view)}</ViewContext.Provider>;
};
