import { useFileableMembers } from '@components/FileManager';
import { GuidedTour } from '@components/GuidedTour';
import { Composer, type ComposerRef, type ComposerState, type OnPostingEvent } from '@components/Message';
import { Button, HelperText, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, ModalSize, Row, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useView } from '@hooks/use-view';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import Folder from '@models/Folder';
import { type Contact, Module, Permissions } from '@types';
import * as EmailValidator from 'email-validator';
import debounce from 'lodash.debounce';
import qs from 'qs';
import { type FC, type FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { OnChangeValue } from 'react-select';
import type { WretchError } from 'wretch';
import { AsyncCreatableComboBox, ContactOption, CreatableComboBox, EmailValueLabel, SecureSpaceOption } from './ComboBox';

export type SendResult = {
	secureSpaces: Folder[];
	hasFiles: boolean;
	hasMessage: boolean;
	folderForFiles: Folder | null;
};

type CreateSecureSpaceMutation = {
	name: string;
	users?: Contact[];
	message?: string;
	markAsImportant?: boolean;
	showPreviewInEmail?: boolean;
};

type DialogComposerFormProps = Omit<ModalProps, 'isOpen'> & {
	onAbort?: () => void;
	onSent?: (result: SendResult) => void;
};

export const DialogComposer: FC<DialogComposerFormProps> = ({ onAbort = () => undefined, onSent = () => undefined, ...modalProps }) => {
	const { formatMessage } = useIntl();
	const { client } = useClient();
	const { account } = useAccount();
	const queryClient = useQueryClient();
	const { view } = useView()!;
	const composer = useRef<ComposerRef>(null);

	const [isOpen, setIsOpen] = useState(true);
	const [users, setUsers] = useState<Contact[]>([]);
	const [secureSpace, setSecureSpace] = useState(view instanceof Folder ? view : undefined);
	const [composerState, setComposerState] = useState<ComposerState>();

	const { members } = useFileableMembers(secureSpace);

	const loadUsers = useMemo(
		() =>
			debounce((inputValue, callback) => {
				if (inputValue.length < 3) {
					return;
				}
				client
					.url('contacts')
					.query({ q: inputValue })
					.get()
					.json()
					.then(options => callback(options));
			}, 250),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const { data: availableSecureSpaces } = useQuery(
		['available_secure_spaces', users],
		async () => {
			const response = await client
				.url('send/securespaces')
				.query(qs.stringify({ emails: users.map((u: Contact) => u.Email) }, { arrayFormat: 'brackets' }))
				.get()
				.json<Folder[]>();
			return response.map(secureSpace => new Folder(secureSpace));
		},
		{
			enabled: users.length > 0 && !view
		}
	);

	const { mutateAsync: createSecureSpace } = useMutation<Folder, WretchError, CreateSecureSpaceMutation>(
		async ({ name, users = [], message = null, showPreviewInEmail = false, markAsImportant = false }) =>
			new Folder(
				await client
					.url('folders')
					.json({
						name,
						message,
						secured_space: true,
						scope: 'public',
						emails: users.map(user => user.Email),
						priority: markAsImportant ? 1 : 0,
						show_preview: showPreviewInEmail
					})
					.post()
					.json<Folder>()
			),
		{
			onSuccess: secureSpace => {
				setSecureSpace(secureSpace);
				queryClient.invalidateQueries('files');
			}
		}
	);

	const onUsersChanged = (users: OnChangeValue<Contact, true>) => {
		setUsers(users.filter(() => true)); // this is stupid
	};

	// Update users when we select secure spaces
	useEffect(() => {
		if (!secureSpace || !members) {
			return;
		}

		setUsers(
			members
				.map(
					collaborator =>
						({
							Id: collaborator.ID,
							Name: collaborator.Name,
							Avatar: collaborator.Avatar,
							Source: 'collaborator',
							Email: collaborator.Email
						} as Contact)
				)
				.filter(contact => contact.Email !== account?.Email)
		);
	}, [secureSpace, account?.Email, members]);

	const showRequestPaymentButton = view instanceof Folder && account!.hasFullAccess();

	//#region Policies
	const canRequestPayment = view instanceof Folder && account!.hasFullAccess() && account!.business.hasModule(Module.Payments) && secureSpace?.pivot?.Permissions === Permissions.ReadWrite;
	const canUploadFiles = secureSpace?.canUploadFiles() === true;
	const canRequestFile = view instanceof Folder && view?.pivot?.Permissions === Permissions.ReadWrite;
	//#endregion

	const onMessagePosted = () => {
		setUsers([]);
		setSecureSpace(undefined);
		setIsOpen(false);
	};

	const onBeforePosting = async ({ message, ...data }: OnPostingEvent) => {
		if (secureSpace?.getKey()) {
			return undefined;
		}

		if (!secureSpace) {
			return undefined;
		}

		const createdSecureSpace = await createSecureSpace({
			name: secureSpace.Name,
			users,
			message,
			markAsImportant: data.markAsImportant ?? false,
			showPreviewInEmail: data.showPreview ?? false
		});

		setSecureSpace(createdSecureSpace);

		return createdSecureSpace;
	};

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		composer.current?.post();
	};

	const showDestinationRows = !(view instanceof Folder);
	const fileable = secureSpace;

	return (
		<Modal isOpen={isOpen} size={ModalSize.Large} onSubmit={onSubmit} {...modalProps}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="dialog-send.title" />
			</ModalHeaderOnlyTitle>
			<ModalBody>
				{showDestinationRows && (
					<Row>
						<Label>
							<FormattedMessage id="dialog-send.step_1" />
						</Label>
						<AsyncCreatableComboBox
							value={users}
							loadOptions={loadUsers}
							getOptionValue={user => user.Email}
							components={{
								Option: ContactOption,
								MultiValueLabel: EmailValueLabel
							}}
							filterOption={({ data }, input) => (input ? data.Email.indexOf(input) >= 0 || (!!data.Name && data.Name.indexOf(input) >= 0) : true)}
							isOptionDisabled={user => users.some(u => u.Email === user.Email)}
							autoFocus
							isMulti
							onChange={onUsersChanged}
							isValidNewOption={input => EmailValidator.validate(input)}
							getNewOptionData={input => ({ Source: 'share', Email: input } as Contact)}
							placeholder={<FormattedMessage id="dialog-send.enter_email_addresses" />}
							formatCreateLabel={email => <FormattedMessage id="dialog-send.add_email" values={{ email }} />}
						/>
					</Row>
				)}
				{showDestinationRows && (
					<Row>
						<Label>
							<FormattedMessage id="dialog-send.step_2" />
						</Label>
						<CreatableComboBox
							getOptionValue={secureSpace => String(secureSpace.getKey())}
							components={{
								Option: SecureSpaceOption
							}}
							defaultValue={secureSpace instanceof Folder ? secureSpace : undefined}
							getOptionLabel={secureSpace => secureSpace.getName() || ''}
							isDisabled={users.length === 0}
							onChange={secureSpace => setSecureSpace(secureSpace as Folder)}
							options={availableSecureSpaces}
							isValidNewOption={input => input.trim().length > 0}
							getNewOptionData={input => new Folder({ SecuredSpace: true, Name: input, collaborators: users, pivot: { Permissions: Permissions.ReadWrite } })}
							placeholder={formatMessage({ id: 'dialog-send.select_secure_spaces' })}
						/>
						<HelperText>
							<FormattedMessage id="dialog-send.select_secure_spaces_hint" />
						</HelperText>
					</Row>
				)}

				{fileable && (
					<Composer
						ref={composer}
						fileable={fileable}
						queryKey={['dialog-send']}
						onPosted={onMessagePosted}
						source={view === null ? 'compose_root' : 'compose'}
						disabled={!secureSpace}
						inputProps={{
							placeholder: formatMessage({ id: 'comments.enter_message' }),
							className: 'border-t-0 rounded-t-none !border-gray-200'
						}}
						titleInputProps={{
							className: 'border border-gray-200 pt-2 border-b-0 rounded-t'
						}}
						plugins={{
							attachments: { enabled: canUploadFiles, folder: view instanceof Folder ? view : undefined },
							fileRequests: { enabled: canRequestFile },
							paymentRequests: { enabled: canRequestPayment, visible: showRequestPaymentButton },
							audience: { enabled: !!secureSpace?.getKey() },
							options: { enabled: !!secureSpace?.getName() }
						}}
						onStateChanged={setComposerState}
						onBeforePosting={onBeforePosting}
					/>
				)}
			</ModalBody>
			<ModalFooter>
				<Button type="submit" variant={Variant.primary} disabled={composerState?.isDisabled === true || !composerState?.isPostable}>
					<FontAwesomeIcon icon="paper-plane" className="mr-2" />
					<FormattedMessage id="dialog-send.send" />
				</Button>
				<Button variant={Variant.light} disabled={composerState?.isPosting === true} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>

			<GuidedTour name="messageZone" enabled={account?.hasFullAccess()} stepsToRemove={!canUploadFiles ? [1] : []} />
		</Modal>
	);
};
