import type { UserSummaryReport } from '@types';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import useClient from './useClient';

const REFETCH_INTERVAL = 60000;
const STALE_TIME = 60000;

export const useUserSummary = () => {
	const { client } = useClient();

	const {
		data: summary = EmptyUserSummary,
		error,
		status
	} = useQuery(['summary'], async () => await client.url('account/notifications/summary').get().json<UserSummaryReport>(), {
		refetchInterval: REFETCH_INTERVAL,
		refetchOnWindowFocus: false,
		staleTime: STALE_TIME
	});

	useEffect(() => {
		if (error) {
			console.error(error);
		}
	}, [error]);

	return {
		summary,
		status
	};
};

export const EmptyUserSummary: UserSummaryReport = {
	total_count: 0,
	summary: {
		files: {
			types: [],
			count: {
				internal: 0,
				external: 0
			},
			users: []
		},
		messages: {
			types: [],
			count: {
				internal: 0,
				external: 0
			},
			users: []
		},
		secure_spaces: {
			types: [],
			count: {
				internal: 0,
				external: 0
			},
			users: []
		},
		esign_completed: {
			types: [],
			count: {
				internal: 0,
				external: 0
			},
			users: []
		},
		esign_received: {
			types: [],
			count: {
				internal: 0,
				external: 0
			},
			users: []
		},
		payment_request_created: {
			types: [],
			count: {
				internal: 0,
				external: 0
			},
			users: []
		},
		payment_request_received: {
			types: [],
			count: {
				internal: 0,
				external: 0
			},
			users: []
		}
	}
};
