import { autoUpdate, flip, FloatingFocusManager, FloatingPortal, useClick, useDismiss, useFloating, type UseFloatingOptions, useInteractions, useRole } from '@floating-ui/react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { type ButtonHTMLAttributes, cloneElement, createContext, type FC, type HTMLAttributes, type MouseEvent, type PropsWithChildren, type ReactNode, useContext, useState } from 'react';

export const DropdownContext = createContext({ isOpen: false, setIsOpen: (opened: boolean) => {} });

export type DropdownProps = Omit<UseFloatingOptions, 'children'> &
	PropsWithChildren<{
		className?: string;
	}>;

const Dropdown: FC<DropdownProps> = ({ className, children, ...useFloatingOptions }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { refs, floatingStyles, context } = useFloating({
		open: isOpen,
		onOpenChange: setIsOpen,
		middleware: [flip()],
		whileElementsMounted: autoUpdate,
		...useFloatingOptions
	});

	const click = useClick(context);
	const dismiss = useDismiss(context);
	const role = useRole(context);

	// Merge all the interactions into prop getters
	const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

	const [trigger, ...menuItems] = children as JSX.Element[];

	return (
		<DropdownContext.Provider value={{ isOpen, setIsOpen }}>
			{cloneElement(trigger, {
				...trigger.props,
				ref: refs.setReference,
				role: 'button',
				'aria-label': 'Menu',
				'aria-haspopup': true,
				...getReferenceProps()
			})}
			{isOpen && (
				<FloatingFocusManager context={context} modal={false}>
					<FloatingPortal>
						<div ref={refs.setFloating} style={{ ...floatingStyles }} {...getFloatingProps()} className={classNames('z-50 w-56', className)}>
							<div
								className={classNames('mx-2 mt-2 overflow-auto bg-white border border-gray-300 rounded-md shadow-lg max-h-1/2-screen')}
								role="menu"
								aria-orientation="vertical"
								aria-labelledby="options-menu">
								{menuItems}
							</div>
						</div>
					</FloatingPortal>
				</FloatingFocusManager>
			)}
		</DropdownContext.Provider>
	);
};

export const DropdownGroup = ({ className = '', ...divProps }: HTMLAttributes<HTMLDivElement>) => <div className={`${className} py-1`} {...divProps} />;

export type DropdownItemEventHandler = {
	middleClick: boolean;
	originalEvent: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>;
};

type DropdownItemProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> & {
	closeOnClick?: boolean;
	active?: boolean;
	icon?: IconProp;
	iconElement?: ReactNode;
	onClick?: (event: DropdownItemEventHandler) => void;
};

export const DropdownItem: FC<DropdownItemProps> = ({ closeOnClick = true, active = false, icon, iconElement, onClick = () => undefined, className, children, ...buttonProps }) => {
	const { setIsOpen } = useContext(DropdownContext);

	const handleClick = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		if (closeOnClick) {
			setIsOpen(false);
		}
		onClick({ middleClick: false, originalEvent: event });
		return event;
	};

	const handleAuxClick = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		if (closeOnClick) {
			setIsOpen(false);
		}
		onClick({ middleClick: true, originalEvent: event });
	};

	const buttonClassName = classNames(
		className,
		'group leading-tight disabled:cursor-not-allowed disabled:bg-gray-100 hover:bg-gray-100 transition-colors flex w-full items-center disabled:opacity-25 text-left px-4 py-2 text-sm focus:outline-none focus:bg-gray-600 focus:text-white gap-2',
		{
			'text-theme-primary': active,
			'text-gray-800': !active
		}
	);

	const iconClassName = classNames('group-focus:text-white group-hover:text-gray-600 transition-colors', {
		'text-theme-primary': active,
		'text-gray-500': !active
	});

	return (
		<button {...buttonProps} onAuxClick={handleAuxClick} onClick={handleClick} className={buttonClassName} role="menuitem">
			{icon !== undefined && (
				<span className="w-5">
					<FontAwesomeIcon icon={icon} className={iconClassName} />
				</span>
			)}
			{iconElement !== undefined && <span className="w-4">{iconElement}</span>}
			<span className="flex-1">{children}</span>
		</button>
	);
};

export const DropdownDivider = () => <div className="border-t border-gray-100"></div>;

export default Dropdown;
