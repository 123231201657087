import IAccount from '@models/Account';
import { createContext } from 'react';

export const AccountContext = createContext<{
	account: IAccount | null;
	logout: (next?: Location) => void;
}>({
	account: null,
	logout: () => undefined
});
