import { ScopeGroupHeading, UserOption } from '@components/ComboBox';
import { type MemberType, useFileableMembers, useSecureSpace } from '@components/FileManager';
import { type ComposerPlugin, DialogPrivateMessagesTutorial, type OnPostedEvent } from '@components/Message';
import { NotificationConfirmationToastContents } from '@components/NotificationsListener';
import { Alert, Button, Intent, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import type { IUser } from '@models/User';
import Dropdown, { DropdownGroup, DropdownItem } from '@ui/Dropdown';
import { Tooltip } from '@ui/Tooltip';
import UserAvatar from '@ui/UserAvatar';
import sort from 'array-sort';
import classNames from 'classnames';
import groupBy from 'lodash.groupby';
import isEmpty from 'lodash.isempty';
import uniqBy from 'lodash.uniqby';
import uniq from 'lodash.uniqwith';
import { type MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import Select, {
	type ActionMeta,
	type ClearIndicatorProps,
	type ControlProps,
	type DropdownIndicatorProps,
	type InputProps,
	type MultiValueGenericProps,
	type MultiValueRemoveProps,
	type OnChangeValue,
	type PlaceholderProps,
	components
} from 'react-select';

export type MessageAudienceOptions = {
	users: MemberType[];
};

const emptyArray: MemberType[] = [];

export const usePluginAudience: ComposerPlugin<{ containerClassName?: string }> = ({ fileable, message, disabled, parent }, { enabled = true, containerClassName }, data) => {
	const { account } = useAccount();

	// Plugin data
	const [users, setUsers] = useState<MemberType[]>((message?.users ?? []) as unknown as MemberType[]);
	const [ignoredUsers, setIgnoredUsers] = useState<MemberType[]>([]);

	const [selectedAudience, setSelectedAudience] = useState('all');
	const [expanded, setExpanded] = useState(false);
	const [showHelpDialog, setShowHelpDialog] = useState(false);
	const { members: allMembers = emptyArray } = useFileableMembers(fileable);
	const secureSpace = useSecureSpace(fileable);
	const listenForDraft = useRef(true);

	const onReset = async () => {
		setAllMembers();
		setIgnoredUsers([]);
		setExpanded(false);
		listenForDraft.current = true;
	};

	const showBusinessName = fileable?.Scope === 'external';

	const setAllMembers = () => {
		setUsers([]);
		setSelectedAudience('all');
		setExpanded(false);
	};

	const onMembersSelected = useCallback(
		(members: MemberType[]) => {
			if (showBusinessName && members.length === 0) {
				members.push(fileable?.creator);
			}

			setUsers(members);
			setSelectedAudience('members');
			setExpanded(true);
		},
		[fileable?.creator, showBusinessName]
	);

	const onMessageUserOptionsChanged = (users: OnChangeValue<MemberType, true>, { action, removedValue }: ActionMeta<MemberType>) => {
		onMembersSelected(users.filter(() => true));
		if (action === 'clear') {
			setIgnoredUsers([]);
		} else if (action === 'remove-value' && removedValue) {
			setIgnoredUsers(users => users.filter(u => u.ID !== removedValue.ID));
		}
	};

	const setSelf = useCallback(() => {
		const self = allMembers.find(member => member.ID === account!.ID);
		setUsers(self ? [self] : []);
		setSelectedAudience('self');
	}, [account!.ID, allMembers]);

	const onAlertsToggled = (user: MemberType, selected: boolean) => {
		if (!selected) {
			setIgnoredUsers(users => uniq(users.concat(user), (a, b) => a.ID === b.ID));
		} else {
			setIgnoredUsers(users => users.filter(u => u.ID !== user.ID));
		}
	};

	const hasAlerts = (member: MemberType) => {
		return !ignoredUsers.find(user => member.ID === user.ID);
	};

	const members = useMemo(() => {
		const _members = allMembers.filter(member => member.ID !== account!.ID);

		// If the account has not full access, adds the creator at the beguining of the list
		if (showBusinessName) {
			_members.unshift(fileable?.creator);
		}

		return _members;
	}, [account!.ID, allMembers, fileable?.creator, showBusinessName]);

	const selectedUsers = (selectedAudience === 'all' ? members : users).filter(member => member.ID !== account!.ID);

	const disableAllAlerts = () => {
		setIgnoredUsers(selectedUsers);
	};

	const membersMismatchWithSecureSpace = secureSpace?.members_hash !== fileable?.members_hash;

	useEffect(() => {
		if (membersMismatchWithSecureSpace && members.length > 0) {
			onMembersSelected(members);
		} else if (membersMismatchWithSecureSpace) {
			setSelf();
		}
	}, [members, membersMismatchWithSecureSpace, onMembersSelected, setSelf]);

	// Received from draft
	useEffect(() => {
		if (!listenForDraft.current) {
			return;
		}

		if (isEmpty(data?.['audience'])) {
			return;
		}

		setIgnoredUsers(data['audience'].ignoredUsers);

		if (data['audience'].users.length === 0) {
			setAllMembers();
		} else if (data['audience'].users.length === 1 && data['audience'].users[0]!.ID === account!.ID) {
			setSelf();
		} else if (data['audience'].users.length > 0) {
			onMembersSelected(data['audience'].users);
		}

		listenForDraft.current = false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.['audience']]);

	const showHelp = (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setShowHelpDialog(true);
	};

	const groups = useMemo(() => {
		let comparator = (a: MemberType, b: MemberType) => {
			return (a.Name ?? 'zzzzz').indexOf(b.Name ?? 'zzzzz') > (b.Name ?? 'zzzzz').indexOf(a.Name ?? 'zzzzz') ? 1 : -1;
		};

		let _members = groupBy(sort(members, comparator), member => member.Scope);

		return Object.entries(_members).map(([a, b]) => ({
			label: a,
			options: b
		}));
	}, [members]);

	const onSuccess = async (event: OnPostedEvent) => {
		// User is editing message
		if (message) {
			return;
		}

		// Only get members that have notifications ON or are mentioned
		let usersInToast: MemberType[] = [];

		if (parent) {
			usersInToast = uniqBy(
				parent.replies
					.concat(parent?.replies ?? [])
					.map(reply => reply.creator as IUser)
					.concat(parent?.creator ?? event.message.creator)
					.filter(creator => creator.ID !== account!.ID)
					.filter(user => members.some(collaborator => collaborator.ID === user.ID && !!collaborator.pivot?.Notify)),
				user => user.ID
			);
		} else {
			usersInToast = allMembers.filter(u => !!u.pivot?.Notify).filter(u => u.ID !== account!.ID);
		}

		// If audience is specified, only select the one which are not ignored
		if (users.length > 0 || selectedAudience === 'members') {
			usersInToast = usersInToast.filter(member => users.some((user: MemberType) => user.ID === member.ID));
		}

		usersInToast = usersInToast.filter(member => !ignoredUsers.some((user: MemberType) => user.ID === member.ID));

		let context = null;

		// A way to check if user is external
		if (!(members ?? []).some(member => member.ID === event.message!.creator.ID)) {
			context = { organization: event.message.creator.business };
		}

		if (users.length > 0) {
			usersInToast = usersInToast.filter(user => users.some(u => u.ID === user.ID));
		}

		usersInToast = uniqBy(usersInToast, user => user.ID);

		if (usersInToast.length > 0 && context) {
			toast.success(
				<div>
					<h5 className="font-semibold">
						<FormattedMessage id="comments.posted_to" />
					</h5>
					<NotificationConfirmationToastContents users={usersInToast} context={context} />
				</div>,
				{ id: 'notification-confirmation', duration: 15000 }
			);
		} else {
			toast.success(<FormattedMessage id="comments.created" />, { id: 'notification-confirmation', duration: 15000 });
		}
	};

	return {
		id: 'audience',
		data: {
			users,
			ignoredUsers
		},
		postData() {
			if (!enabled) {
				return {};
			}

			return {
				users: users.map(u => u.ID),
				ignore_notifications_for: ignoredUsers.map(u => u.ID)
			};
		},
		isPostable: selectedAudience !== 'members' || users.length !== 0,
		onReset,
		onSuccess,
		isDirty: selectedAudience !== 'all' || ignoredUsers.length > 0,
		components: {
			before: (
				<>
					{showHelpDialog && <DialogPrivateMessagesTutorial onAfterClose={() => setShowHelpDialog(false)} />}
					{enabled && (
						<div
							className={classNames('flex items-stretch mb-4', containerClassName, {
								'gap-2': selectedAudience === 'self'
							})}>
							<Dropdown>
								<Button
									size={Size.sm}
									disabled={disabled}
									className={`${selectedAudience !== 'self' ? '!border-r-0 !rounded-r-none' : ''}`}
									variant={Variant.light}
									intent={Intent.secondary}
									iconStart={selectedAudience === 'all' ? 'users' : selectedAudience === 'members' ? ['fad', 'users'] : 'lock'}
									iconEnd="caret-down"
									type="button">
									{!expanded && (
										<span className="hidden text-xs md:inline">
											{selectedAudience === 'all' ? (
												<FormattedMessage id="comments.audience.all-members" />
											) : selectedAudience === 'members' ? (
												<FormattedMessage id="comments.audience.select-members" />
											) : (
												<FormattedMessage id="comments.audience.self" />
											)}
										</span>
									)}
								</Button>
								<DropdownGroup>
									<DropdownItem icon="users" onClick={setAllMembers} active={selectedAudience === 'all'} disabled={membersMismatchWithSecureSpace}>
										<FormattedMessage id="comments.audience.all-members" />
									</DropdownItem>
									<DropdownItem icon={['fad', 'users']} onClick={() => onMembersSelected(members)} active={selectedAudience === 'members'}>
										<FormattedMessage id="comments.audience.select-members" />
									</DropdownItem>
									<DropdownItem icon="user" onClick={setSelf} active={selectedAudience === 'self'}>
										<FormattedMessage id="comments.audience.self" />
									</DropdownItem>
								</DropdownGroup>
							</Dropdown>
							{selectedAudience === 'self' && (
								<div className="h-[42px]">
									<Alert variant={Variant.warning} size={Size.sm}>
										<FormattedMessage id="comments.audience.self_warning" />
									</Alert>
								</div>
							)}
							{!expanded && selectedAudience !== 'self' ? (
								<div className="relative flex-1">
									<button className="flex items-center justify-between w-full px-3 py-2 -space-x-1 border border-gray-200 rounded-r" type="button" onClick={() => setExpanded(true)}>
										<div>
											{showBusinessName && (
												<span className="inline-flex items-center mr-3 space-x-3 text-xs">
													<span className="px-2 py-1 text-gray-500 bg-gray-100 rounded-full ring-1 ring-gray-200">{fileable?.creator.business!.Name}</span>
													{selectedUsers.length > 1 && (
														<span>
															<FormattedMessage id="and" />
														</span>
													)}
												</span>
											)}
											{(fileable?.collaborators ?? [])
												.filter(u => u.ID !== account?.ID)
												.map(u => (
													<Tooltip tip={u.Name}>
														<span>
															<UserAvatar size={Size.xs} user={u} />
														</span>
													</Tooltip>
												))}
											{(fileable?.members_count ?? 0) > (fileable?.collaborators ?? []).length && (
												<span className="pl-2 text-sm font-bold tracking-tighter text-gray-500">
													+{(fileable?.members_count ?? 0) - (fileable?.collaborators ?? []).length}
												</span>
											)}
											<span className="inline-flex !ml-2 text-xs text-gray-400 bg-gray-200 rounded px-2 py-0.5">
												<FormattedMessage id="modify..." />
											</span>
										</div>
									</button>
									<div className="absolute -translate-y-1/2 top-1/2 right-4">
										<Button size={Size.xxs} circle variant={Variant.dark} icon="info" onClick={showHelp} />
									</div>
								</div>
							) : (
								selectedAudience !== 'self' && (
									<div className="flex-1">
										<Select<MemberType, true>
											menuPortalTarget={document.body}
											styles={{
												menuPortal: provided => ({ ...provided, zIndex: 2000 })
											}}
											value={selectedUsers}
											onChange={onMessageUserOptionsChanged}
											components={{
												Option: UserOption,
												GroupHeading: ScopeGroupHeading,
												Control,
												MultiValueLabel: props => <MultiValueLabel willAlert={hasAlerts} onAlertsToggled={onAlertsToggled} {...props} />,
												MultiValueRemove: MultiValueRemove,
												MultiValueContainer,
												Placeholder,
												Input,
												DropdownIndicator,
												ClearIndicator: props => <ClearIndicator ontoggleNotifications={disableAllAlerts} {...props} />,
												IndicatorSeparator: null
											}}
											filterOption={(member, inputValue) => {
												return (
													(member.data.Email ?? '').toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()) ||
													(member.data.Name ?? '').toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
												);
											}}
											placeholder={<FormattedMessage id="comments.audience.select_members" />}
											noOptionsMessage={() => <FormattedMessage id="comments.audience.all_members_selected" />}
											getOptionValue={user => String(user.ID)}
											isMulti={true}
											isSearchable={true}
											options={groups}
										/>
									</div>
								)
							)}
						</div>
					)}
				</>
			)
		}
	};
};

const Control = ({ ...props }: ControlProps<MemberType, true>) => {
	const className = classNames(props.className, '!border !border-gray-200 !rounded-l-none !rounded-r !shadow-none', {
		'!border-gray-400 ring-2 ring-gray-600/30': props.menuIsOpen,
		'!border-gray-200': !props.menuIsOpen
	});

	return <components.Control className={className} {...props} />;
};

const nameShortener = (name: string) => {
	const parts = name.split(' ');

	return `${parts.slice(0, -1).map(part => `${part[0]}.`)} ${parts.pop()}`;
};

type MultiValueLabelProps = MultiValueGenericProps<MemberType, true> & {
	willAlert: (member: MemberType) => boolean;
	onAlertsToggled: (member: MemberType, checked: boolean) => void;
};

const MultiValueLabel = ({ data, willAlert = () => false, onAlertsToggled = () => undefined, ...props }: MultiValueLabelProps) => {
	const { formatMessage } = useIntl();

	let status = willAlert(data) ? 'on' : 'off';

	const onTouchEnd = (e: React.TouchEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		e.preventDefault();
		onAlertsToggled(data, !willAlert(data));
	};

	const onMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		e.preventDefault();
		onAlertsToggled(data, !willAlert(data));
	};

	if (data.business) {
		return (
			<components.MultiValueLabel {...props} data={data}>
				<span className="inline-flex items-center space-x-1.5 px-1">
					<span className="text-xs text-gray-500">{data.business.Name}</span>
				</span>
			</components.MultiValueLabel>
		);
	}

	return (
		<components.MultiValueLabel {...props} data={data}>
			<span className="inline-flex items-center space-x-1.5 pl-1">
				<Tooltip tip={data.Name}>
					<span className="text-xs text-gray-500">{nameShortener(data.Name)}</span>
				</Tooltip>
				<Tooltip tip={formatMessage({ id: 'comments.notifications.status' }, { name: data.Name, status: !data.pivot.Notify ? 'disabled' : status })}>
					<span>
						<button onTouchEnd={onTouchEnd} onMouseDown={onMouseDown} className="px-0.5" type="button" disabled={!data.pivot.Notify}>
							{!data.pivot.Notify || status === 'off' ? (
								<FontAwesomeIcon fixedWidth icon="bell-slash" className="text-gray-300" />
							) : (
								<FontAwesomeIcon fixedWidth icon="bell" className="text-gray-500" />
							)}
						</button>
					</span>
				</Tooltip>
			</span>
		</components.MultiValueLabel>
	);
};

const MultiValueRemove = ({ innerProps, ...props }: MultiValueRemoveProps<MemberType, true>) => {
	const className = classNames(innerProps.className, 'hover:!bg-red-200/50 !rounded-full w-5 h-5 !p-0 justify-center self-center mr-1 transition-colors text-gray-300 hover:text-gray-500');

	//If it's a business, you shouldn't be able to remove it
	if (props.data.business) {
		return null;
	}

	return (
		<components.MultiValueRemove innerProps={{ ...innerProps, className }} {...props}>
			<FontAwesomeIcon icon="times" fixedWidth />
		</components.MultiValueRemove>
	);
};

const MultiValueContainer = ({ innerProps, ...props }: MultiValueGenericProps<MemberType, true>) => {
	const className = classNames(innerProps.className, '!bg-gray-100 !border-0 !ring-1 !ring-gray-200 !rounded-full');

	return <components.MultiValueContainer innerProps={{ ...innerProps, className }} {...props} />;
};

const Placeholder = ({ ...props }: PlaceholderProps<MemberType, true>) => {
	const className = classNames(props.className, '!text-sm !text-gray-500');

	return <components.Placeholder className={className} {...props} />;
};

const Input = ({ ...props }: InputProps<MemberType, true>) => {
	const className = classNames('!ring-0');

	return <components.Input inputClassName={className} {...props} />;
};

const DropdownIndicator = ({ ...props }: DropdownIndicatorProps<MemberType, true>) => {
	return (
		<components.DropdownIndicator {...props}>
			<FontAwesomeIcon icon="chevron-down" className="text-gray-300" fixedWidth />
		</components.DropdownIndicator>
	);
};

type ClearIndicatorCustomProps = ClearIndicatorProps<MemberType, true> & {
	ontoggleNotifications: () => void;
};

const ClearIndicator = ({ ontoggleNotifications = () => undefined, ...props }: ClearIndicatorCustomProps) => {
	const onTouchEnd = (e: React.TouchEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		e.preventDefault();
		ontoggleNotifications();
	};

	return (
		<>
			<button type="button" onMouseDown={onTouchEnd} onTouchEnd={onTouchEnd} className="px-2">
				<FontAwesomeIcon icon="bell-slash" size="sm" className="text-gray-300" />
			</button>
			<components.ClearIndicator {...props}>
				<FontAwesomeIcon icon="times" className="text-gray-300" fixedWidth />
			</components.ClearIndicator>
		</>
	);
};
