import { UploadFile, UploadStatus, useUpload, useUploadService } from '@components/Upload';
import { Checkbox } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import File from '@models/File';
import type { View } from '@types';
import { filesize } from '@utils/StringUtils';
import { type FC, useEffect } from 'react';
import { useIntl } from 'react-intl';

type UploadFileableItemProps = {
	item: UploadFile;
	folder: View;
	onCompleted?: (file: File, uploadItem: UploadFile) => void;
};

export const UploadFileableItem: FC<UploadFileableItemProps> = ({ item, folder, onCompleted = () => undefined }) => {
	const { locale } = useIntl();

	const uploadService = useUploadService(item.scope, { start: true });
	const { status, percentage, file } = useUpload(uploadService, item);

	useEffect(() => {
		if (status === UploadStatus.Completed && file !== null) {
			onCompleted(file, item);
		}
	}, [status, file, onCompleted, item]);

	// Don't render when were not in the correct folder
	if (typeof folder === 'string' || folder?.getKey() !== item.scope?.getKey()) {
		return null;
	}

	return (
		<div className="relative overflow-hidden text-sm rounded">
			<div className="absolute inset-0 transition-all bg-green-200" style={{ width: `${percentage}%` }}></div>
			<div className="relative flex">
				<Checkbox className="invisible mx-2" />

				<span className="flex items-center w-full p-2 text-left truncate">
					<div className="flex-1 min-w-0 truncate">
						{[UploadStatus.Pending, UploadStatus.Processing].includes(status) ? (
							<FontAwesomeIcon icon="spinner" pulse fixedWidth className="mr-2" />
						) : (
							File.fromName(item.file.name).icon('mr-2')
						)}
						{File.fromName(item.file.name).getName()}
					</div>
					<div className="text-xs text-black text-opacity-50">{filesize(item.file.size, locale)}</div>
				</span>
			</div>
		</div>
	);
};
