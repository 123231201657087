import untypedLocales from '@assets/locales.json';
import { FileManagerContext } from '@components/FileManager';
import { GuidedTour } from '@components/GuidedTour';
import {
	Button,
	Checkbox,
	HelperText,
	InputBlock,
	Intent,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeaderOnlyTitle,
	type ModalProps,
	ModalSize,
	Row,
	Select,
	Size,
	TextArea,
	ValidationField,
	Variant
} from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useView } from '@hooks/use-view';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { useLocalStorage } from '@hooks/useLocalStorage';
import Folder from '@models/Folder';
import type { Contact } from '@types';
import { RichTextEditor, type RichTextEditorRef } from '@ui/RichTextEditor';
import * as EmailValidator from 'email-validator';
import debounce from 'lodash.debounce';
import { type FC, type FormEvent, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { type MultiValueGenericProps, type OnChangeValue, type OptionProps, components } from 'react-select';
import type { WretchError } from 'wretch';
import { AsyncCreatableComboBox, UserOption } from '../ComboBox';
import message from 'react-intl/src/components/message';
import { useCannedMessages } from '@components/CannedMessages';

const locales: Record<string, string> = untypedLocales;

type ShareFolderApiRequest = {
	emails: string[];
	body?: string;
	question?: string;
	answer?: string;
	locale?: string;
};

type ShareMutation = {
	contacts: Contact[];
	locale: string;
	message?: string;
	question?: string;
	answer?: string;
};

type DialogShareSecureSpaceProps = Omit<ModalProps, 'isOpen'> & {
	folder: Folder;
	onShared?: (folder: Folder) => void;
};

export const DialogShareSecureSpace: FC<DialogShareSecureSpaceProps> = ({ folder, onShared = () => undefined, ...modalProps }) => {
	const { formatMessage } = useIntl();
	const { client, validation } = useClient();
	const { account } = useAccount();
	const { update: updateItem = () => undefined } = useContext(FileManagerContext) ?? {};
	const { view, update: updateView } = useView();
	const cannedMessages = useCannedMessages();

	const editor = useRef<RichTextEditorRef>(null);

	const [isOpen, setIsOpen] = useState(true);
	const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
	const [expandedQuestion, setExpandedQuestion] = useState(false);
	const [question, setQuestion] = useState('');
	const [answer, setAnswer] = useState('');
	const [locale, setLocale] = useState(account!.Locale);
	const [expandedMessage] = useLocalStorage('secure-space-share.expanded-message', true);
	const [rteLoaded, setRteLoaded] = useState(false);

	const loadUsers = useMemo(
		() =>
			debounce((inputValue, callback) => {
				client
					.url('contacts')
					.query({ q: inputValue })
					.get()
					.json()
					.then(options => callback(options));
			}, 250),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const onEmailsChange = (options: OnChangeValue<Contact, true>) => {
		setSelectedContacts(options.filter(() => true));
	};

	const { mutate: share, isLoading: isSubmitting } = useMutation<Folder, WretchError, ShareMutation>(
		async ({ contacts, locale, message, question, answer }) => {
			const data: ShareFolderApiRequest = {
				emails: contacts.map(e => e.Email),
				locale
			};

			if (message) {
				data.body = JSON.stringify(message);
			}

			if (question) {
				data.question = question;
				data.answer = answer;
			}

			return new Folder(await client.url(folder.getRoute('share')).post(data).json());
		},
		{
			onError: error => console.error(error),
			onSuccess: (folder, { contacts }) => {
				toast.success(
					<div>
						<h5 className="font-semibold">
							<FormattedMessage id="dialog.folder-share.shared" />
						</h5>
						<ul className="mt-2 space-y-1">
							{contacts.map(contact => (
								<li className="text-sm">{contact.Email}</li>
							))}
						</ul>
					</div>
				);
				onShared(folder);
				setIsOpen(false);

				updateItem(folder);

				if (view instanceof Folder && folder.getKey() === view.getKey()) {
					updateView(folder);
				}
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		share({
			contacts: selectedContacts,
			locale,
			message: expandedMessage ? editor.current?.json() : undefined,
			question: expandedQuestion ? question : undefined,
			answer: expandedQuestion ? answer : undefined
		});
	};

	useEffect(() => {
		if (cannedMessages === undefined || !rteLoaded) {
			return;
		}

		const signature = cannedMessages?.find(cannedMessage => cannedMessage.IsShareMessage);
		if (signature) {
			editor.current?.setHtmlContent('<p></p>' + signature?.Content);
		}
	}, [cannedMessages, rteLoaded]);

	return (
		<Modal isOpen={isOpen} size={ModalSize.Large} onSubmit={onSubmit} {...modalProps}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage
					id="dialog.folder-share.title"
					values={{
						folder: (
							<span>
								{folder.icon('mx-2')}
								{folder.getName()}
							</span>
						)
					}}
				/>
			</ModalHeaderOnlyTitle>
			<ModalBody disabled={isSubmitting}>
				<Row id="step-user-input">
					<AsyncCreatableComboBox
						value={selectedContacts}
						loadOptions={loadUsers}
						getOptionValue={user => user.Email}
						components={{
							// Option: ContactOptionRenderer,
							Option: UserOption,
							MultiValueLabel: EmailValueLabelRenderer
						}}
						filterOption={({ data }, input) => (input ? data.Email.indexOf(input) >= 0 || (!!data.Name && data.Name.indexOf(input) >= 0) : true)}
						isOptionDisabled={contact => selectedContacts.some(c => c.Email === contact.Email)}
						isDisabled={isSubmitting}
						autoFocus
						isMulti
						onChange={onEmailsChange}
						isValidNewOption={input => EmailValidator.validate(input)}
						getNewOptionData={input => ({ Source: 'share', Email: input } as Contact)}
						placeholder={formatMessage({ id: 'dialog.folder-share.enter_email_address' })}
						formatCreateLabel={input => formatMessage({ id: 'dialog.folder-share.add_email' }, { email: input })}
					/>
				</Row>

				<Row className="flex items-center">
					<div className="flex-1">
						<Label htmlFor="locale-field">
							<FormattedMessage id="dialog.folder-share.language" />
						</Label>
						<Select className="w-full" id="locale-field" value={locale} onChange={e => setLocale(e.target.value)} size={Size.sm}>
							{Object.keys(locales).map(locale => (
								<option key={locale} value={locale}>
									{locales[locale]}
								</option>
							))}
						</Select>
					</div>
					<FontAwesomeIcon icon="globe-americas" mask="circle" transform="shrink-8" size="3x" className="hidden ml-4 text-gray-800 sm:inline-block" />
				</Row>

				<Row id="step-message-input">
					<Label htmlFor="message">
						<FormattedMessage id="dialog.folder-share.message" />
					</Label>
					<RichTextEditor
						ref={ref => {
							editor.current = ref;
							setRteLoaded(true);
						}}
					/>
				</Row>

				<Row className="flex items-center" id="step-secret-question">
					<div className="flex-1">
						<Label htmlFor="enable-security-question">
							<FormattedMessage id="dialog.folder-share.add_security_question" />
							<small className="ml-2 text-gray-400">
								<FormattedMessage id="comments.optional" />
							</small>
						</Label>
						<label className="flex items-start">
							<Checkbox className="mt-1 mr-2" id="enable-security-question" checked={expandedQuestion} onChange={e => setExpandedQuestion(e.target.checked)} />
							<HelperText>
								<FormattedMessage id="dialog.folder-share.security_question_desc" />
							</HelperText>
						</label>
					</div>
					<FontAwesomeIcon icon="key" mask="circle" transform="shrink-10" size="3x" className="hidden ml-4 text-gray-800 sm:inline-block" />
				</Row>

				{expandedQuestion && (
					<Row>
						<Label htmlFor="security-question">
							<FormattedMessage id="dialog.folder-share.security_question" />
						</Label>
						<TextArea
							block
							value={question}
							onChange={e => setQuestion(e.target.value)}
							rows={2}
							id="security-question"
							placeholder={formatMessage({ id: 'dialog.folder-share.enter_security_question' })}
						/>
						{selectedContacts.length > 1 && (
							<HelperText>
								<FormattedMessage id="dialog.folder-share.security_question_multiple" />
							</HelperText>
						)}
					</Row>
				)}

				{expandedQuestion && (
					<Row>
						<Label htmlFor="security-answer">
							<FormattedMessage id="dialog.folder-share.answer" />
						</Label>
						<ValidationField fieldName="answer" validation={validation}>
							<InputBlock
								required
								value={answer}
								onChange={e => setAnswer(e.target.value)}
								type="text"
								id="security-answer"
								placeholder={formatMessage({ id: 'dialog.folder-share.enter_answer' })}
							/>
						</ValidationField>
					</Row>
				)}
			</ModalBody>
			<ModalFooter>
				<Button type="submit" variant={Variant.primary} disabled={isSubmitting || selectedContacts.length === 0}>
					{isSubmitting ? <FormattedMessage id="dialog.folder-share.sharing" /> : <FormattedMessage id="dialog.folder-share.share" />}
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="close" />
				</Button>
			</ModalFooter>

			<GuidedTour name="shareEs" enabled={account?.hasFullAccess()} />
		</Modal>
	);
};

export const ContactOptionRenderer = ({ data, ...props }: OptionProps<Contact, true>) => (
	<components.Option data={data} {...props}>
		<div className="flex items-center">
			<FontAwesomeIcon icon={data.Source === 'business' ? 'briefcase' : 'user'} fixedWidth className="mr-2" />
			<strong className="mr-2">{data.Name || data.Email}</strong>
			{data.Name && <small className="text-gray-400">{data.Email}</small>}
		</div>
	</components.Option>
);

export const EmailValueLabelRenderer = ({ ...props }: MultiValueGenericProps<Contact>) => <components.MultiValueLabel {...props}>{props.data.Name ?? props.data.Email}</components.MultiValueLabel>;

export default DialogShareSecureSpace;
