import type { BillingAddress } from '@components/Account';
import { useCart, useStore, type CartType } from '@components/Checkout';
import useClient from '@hooks/useClient';
import Account from '@models/Account';
import type { Plan } from '@types';
import { useMutation, useQueryClient } from 'react-query';
import type { WretchError, WretchResponse } from 'wretch';

type SubscribeMutation = {
	paymentMethodToken: string;
	billingInformation: BillingAddress;
	cart: CartType;
	agreement: true;
};

type CheckPromoCodeMutation = {
	code: string;
	frequency?: string;
};

export const useSubscriptionSubcribeMutation = () => {
	const queryClient = useQueryClient();
	const { client, authToken } = useClient();
	const { store } = useStore();
	const { getItemById } = useCart();

	return useMutation<Account, WretchError, SubscribeMutation>({
		mutationFn: async ({ paymentMethodToken, cart, billingInformation, agreement }) => {
			const selectedPlan = store!.plans.find(plan => plan.Key === getItemById('plan')!.options.key)!;

			return await client
				.url('account/subscription')
				.post({
					coupon: cart.coupon,
					token: paymentMethodToken,
					plan: selectedPlan.Id,
					frequency: cart.frequency,
					country: billingInformation.BillingCountry,
					agreement: agreement ? 1 : 0,
					...(billingInformation.BillingRegion && { region: billingInformation.BillingRegion }),
					...(getItemById('support_plan') && { support_plan: getItemById('support_plan')!.options.level }),
					...(getItemById('esigns') && { signatures: getItemById('esigns')!.options.limit }),
					...(getItemById('users') && { additional_users: getItemById('users')!.quantity }),
					...(getItemById('storage') && { additional_storage: getItemById('storage')!.quantity }),
					...(getItemById('embed') && { embed: 1 }),
					...(getItemById('payments') && { payments: 1 }),
					...(getItemById('white_labeling') && { white_labeling: getItemById('white_labeling')!.options.level })
				})
				.json<Account>();
		},
		onSuccess: account => {
			queryClient.setQueryData(['account', authToken], account);
		}
	});
};

export const useSubscriptionCheckPromoCodeMutation = () => {
	const { client } = useClient();

	return useMutation<WretchResponse, WretchError, CheckPromoCodeMutation>({
		mutationFn: async ({ code, frequency }) =>
			await client
				.url('checkout/promocode')
				.query({ coupon: code, frequency: frequency ?? 'month' })
				.get()
				.res()
	});
};

export const useSubscriptionUpgradeMutation = () => {
	const queryClient = useQueryClient();
	const { client, authToken } = useClient();

	return useMutation<Account, WretchError, Plan>({
		mutationFn: async (plan: Plan) =>
			new Account(
				await client
					.url('account/subscription')
					.json({
						plan: plan.Id
					})
					.put()
					.json<Account>()
			),

		onSuccess: account => {
			queryClient.setQueryData(['account', authToken], account);
		}
	});
};

export const useSubscriptionResumeMutation = () => {
	const queryClient = useQueryClient();
	const { client, authToken } = useClient();

	return useMutation({
		mutationFn: async () => new Account(await client.url('account/subscription/resume').put().json()),
		onSuccess: account => {
			queryClient.setQueryData(['account', authToken], account);
		}
	});
};

export const useSubscriptionDowngradeMutation = () => {
	const queryClient = useQueryClient();
	const { client, authToken } = useClient();

	return useMutation({
		mutationFn: async () => new Account(await client.url('account/subscription').delete().json()),
		onSuccess: account => {
			queryClient.setQueryData(['account', authToken], account);
		}
	});
};

export const useStartTrialMutation = () => {
	const queryClient = useQueryClient();
	const { client, authToken } = useClient();

	return useMutation<Account, WretchError, string | undefined>({
		mutationFn: async referredBy => new Account(await client.url('account/subscription/trial').post({ referred_by: referredBy }).json()),
		onSuccess: account => {
			queryClient.setQueryData(['account', authToken], account);
		}
	});
};
