import type { CannedMessage } from '@components/CannedMessages';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import Account from '@models/Account';
import { versionMiddleware } from '@service/Client';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { WretchError } from 'wretch';

const RQKEY_ROOT = 'canned-messages';
export const RQKEY = () => [RQKEY_ROOT];

type AccountPreferencesApiRequest = {
	default_share_message?: number | null;
	default_signature?: number | null;
	root_files_sort?: string;
};

export const useCannedMessagesQuery = (enabled = true) => {
	const { account } = useAccount();
	const { client } = useClient();

	return useQuery({
		queryKey: RQKEY(),
		queryFn: async () =>
			await client
				.url('account/canned-messages')
				.middlewares([versionMiddleware(2)])
				.get()
				.json<CannedMessage[]>(),

		enabled: !!account?.hasFullAccess() && enabled,
		staleTime: 1000 * 60 * 5
	});
};

export const useCannedMessagesSetAsDefaultForMutation = () => {
	const queryClient = useQueryClient();
	const { client } = useClient();

	return useMutation<Account, WretchError, { cannedMessage: CannedMessage | null; type: 'share' | 'signature' }, CannedMessage[]>({
		mutationFn: async ({ cannedMessage, type }) => {
			const data: AccountPreferencesApiRequest = {};

			if (type === 'share') {
				data.default_share_message = cannedMessage?.Id ?? null;
			} else if (type === 'signature') {
				data.default_signature = cannedMessage?.Id ?? null;
			} else {
				throw new Error('Invalid type');
			}

			return new Account(await client.url('account/preferences').json(data).put().json<Account>());
		},
		onMutate: async ({ cannedMessage, type }) => {
			await queryClient.cancelQueries(RQKEY());

			const previousMessages = queryClient.getQueryData<CannedMessage[]>(RQKEY());

			if (previousMessages === undefined) {
				return undefined;
			}

			queryClient.setQueryData(
				RQKEY(),
				previousMessages.map(_message => ({
					..._message,
					IsShareMessage: type === 'share' ? _message.Id === cannedMessage?.Id : _message.IsShareMessage,
					IsSignature: type === 'signature' ? _message.Id === cannedMessage?.Id : _message.IsSignature
				}))
			);

			return previousMessages;
		},
		onError: (_error, _variables, messages) => {
			queryClient.setQueryData(RQKEY(), messages);
		}
	});
};
