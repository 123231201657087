import type { AccountProfileType } from '@components/Account';
import useClient from '@hooks/useClient';
import Account from '@models/Account';
import { useMutation, useQueryClient } from 'react-query';
import type { WretchError } from 'wretch';
import { getSupportedLocale } from '../../AppProviders';
import { accountUpdater } from './account';

type SaveProfileMutation = {
	firstName?: string;
	lastName?: string;
	title?: string;
	country?: string;
	locale?: string;
	phone?: string;
	phoneExtension?: string;
	emailId?: boolean;
};

export const useAccountAvatarUpdateMutation = () => {
	const { client, authToken } = useClient();
	const queryClient = useQueryClient();

	return useMutation<Account, WretchError, Blob>({
		mutationFn: async avatar =>
			await client
				.url('account/profile')
				.formData({
					_method: 'PUT',
					avatar: new File([avatar], 'avatar.jpg', { lastModified: Date.now(), type: avatar.type })
				})
				.post()
				.json(),
		onMutate: async blob => {
			await queryClient.cancelQueries(['account', authToken]);

			const previousAccount = queryClient.getQueryData<Account>(['account', authToken]);

			if (previousAccount === undefined) {
				return undefined;
			}

			queryClient.setQueryData<Account | null>(['account', authToken], accountUpdater({ Avatar: URL.createObjectURL(blob) }));

			return previousAccount;
		},
		onError: (_error, _variables, account) => {
			queryClient.setQueryData(['account', authToken], account);
		}
	});
};

export const useAccountProfileUpdateMutation = () => {
	const { client, authToken } = useClient();
	const queryClient = useQueryClient();

	return useMutation<AccountProfileType, WretchError, SaveProfileMutation>({
		mutationFn: async ({ firstName, lastName, title, country, phone, phoneExtension, locale, emailId }) =>
			await client
				.url('account/profile')
				.json({
					first_name: firstName,
					last_name: lastName,
					title,
					country: country,
					locale: locale,
					phone: phone,
					phone_extension: phoneExtension,
					email_id: emailId
				})
				.put()
				.json<AccountProfileType>(),
		onMutate: async ({ country, firstName, lastName, locale, phone, phoneExtension, title }) => {
			await queryClient.cancelQueries(['account', authToken]);

			const previousAccount = queryClient.getQueryData<Account>(['account', authToken]);

			if (previousAccount === undefined) {
				return undefined;
			}

			const updates: Partial<Account> = {};

			if (locale) {
				updates.Locale = getSupportedLocale(locale);
			}

			if (firstName) {
				updates.FirstName = firstName;
			}

			if (lastName) {
				updates.LastName = lastName;
			}

			if (country) {
				updates.Country = country;
			}

			if (phone) {
				updates.PhoneNumber = phone;
			}

			if (phoneExtension) {
				updates.PhoneExtension = phoneExtension;
			}

			if (title) {
				updates.Title = title;
			}

			queryClient.setQueryData(['account', authToken], accountUpdater(updates));

			return previousAccount;
		},
		onError: (_error, _variables, account) => {
			queryClient.setQueryData(['account', authToken], account);
		},
		onSuccess: account => {
			// Sometimes there are fields that are populated only on server-side like "email_id"
			queryClient.setQueryData(['account', authToken], new Account(account));
		}
	});
};
