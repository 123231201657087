import { Size } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import User from '@models/User';
import UserAvatar from '@ui/UserAvatar';
import { useEffect, useState, type ButtonHTMLAttributes, type FC } from 'react';

type UserPillSelectorProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> & {
	user: User;
	name?: string;
	selected: boolean;
	onClick: (user: User, selected: boolean) => void;
};

const UserPillSelector: FC<UserPillSelectorProps> = ({ user, name, selected: defaultSelected = false, onClick = () => undefined, className = '', ...buttonProps }) => {
	const [selected, setSelected] = useState(defaultSelected);

	useEffect(() => {
		onClick(user, selected);
	}, [onClick, user, selected]);

	return (
		<button
			className={`${className} inline-flex items-center focus:outline-none p-1 text-sm rounded-full border-2 ${selected ? 'border-blue-500 bg-blue-500 text-white' : 'border-gray-300'}`}
			onClick={() => setSelected(!selected)}
			type="button"
			{...buttonProps}>
			{!selected && <UserAvatar user={user} size={Size.sm} />}
			{selected && <FontAwesomeIcon size="2x" fixedWidth icon="check-circle" className="w-8 h-8" />}
			<span className="mx-2 font-semibold">{user.Name}</span>
			{name !== undefined && selected && <input type="hidden" name={name} value={user.ID || undefined} />}
		</button>
	);
};

export default UserPillSelector;
