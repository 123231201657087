import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import Folder from '@models/Folder';
import Card from '@ui/Card';
import ProgressBar from '@ui/ProgressBar';
import { type FC, useEffect, useRef, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { type UseMutationOptions, useMutation } from 'react-query';
import type { WretchError } from 'wretch';
import type { DataRow } from './types';
import { useImporter } from './use-importer';

export const ResultsStep: FC = () => {
	const { data } = useImporter();
	const { reset } = useImporter();

	const [finishedCount, setFinishedCount] = useState(0);
	const [successes, setSuccesses] = useState<DataRow[]>([]);
	const [errored, setErrored] = useState<DataRow[]>([]);

	const onSuccess = (folder: Folder, row: DataRow) => {
		setSuccesses(successes => [...successes, row]);
	};

	const onError = (error: WretchError, row: DataRow) => {
		setErrored(errored => [...errored, { ...row, error }]);
	};

	const total = useRef(data.length).current;
	const finished = finishedCount === total;

	useEffect(() => {
		if (finished) {
			reset();
		}
	}, [finished, reset]);

	return (
		<Card>
			{!finished && (
				<>
					{data.map((row, index) => (
						<SecureSpaceCreator key={index} execute={finishedCount === index} data={row} onSuccess={onSuccess} onError={onError} onSettled={() => setFinishedCount(count => count + 1)} />
					))}
					<div className="p-6 text-center bg-gray-100 border rounded-lg">
						<h3 className="italic text-gray-600">
							<FormattedMessage id="importer.results.creating" />
						</h3>
						<ProgressBar reverseColors current={finishedCount} max={total} className="my-6">
							<FormattedNumber value={finishedCount / total} style="percent" />
						</ProgressBar>
						<p className="text-2xl font-bold text-center">
							<FormattedMessage id="importer.results.no_not_close_window" />
						</p>
					</div>
				</>
			)}
			{finished && (
				<>
					<div className="prose max-w-none">
						<h2>
							<FontAwesomeIcon icon="check-circle" className="mr-2 text-green-600" />
							<FormattedMessage id="importer.results.title" values={{ n: successes.length }} />
						</h2>
						{errored.length > 0 && (
							<>
								<p>
									<FormattedMessage id="importer.results.errors_found" values={{ n: errored.length }} />
								</p>
								{errored.map(row => (
									<>
										<h4>{row.name[0]}</h4>
										{row.error?.status === 422 ? (
											<>
												{Object.entries(row.error.json.errors).map(([, message]) => (
													<p className="text-red-500">{message}</p>
												))}
											</>
										) : (
											<p className="text-red-500">
												<FormattedMessage id="importer.results.generic_error" />
											</p>
										)}
									</>
								))}
							</>
						)}
					</div>
				</>
			)}
		</Card>
	);
};

type CreatorProps = Pick<UseMutationOptions<Folder, WretchError, DataRow, unknown>, 'onSuccess' | 'onError' | 'onSettled'> & {
	data: DataRow;
	execute?: boolean;
};

const SecureSpaceCreator: FC<CreatorProps> = ({ data, execute = false, ...useMutationProps }) => {
	const { client } = useClient();
	const { creator, template, folder, isInviting } = useImporter();

	const { mutate: _import } = useMutation<Folder, WretchError, DataRow>(
		async ({ name, members }) => {
			const response = await client
				.url('account/import')
				// .url('random-response-time?min=0.2&max=1')
				.json({
					name: name[0],
					creator: creator?.ID,
					parent: folder?.ID,
					template: template?.Id,
					invites: isInviting
						? members.map(member => ({
								...member
						  }))
						: []
				})
				.post()
				.json<Folder>();

			return new Folder(response);
		},
		{
			...useMutationProps
		}
	);

	useEffect(() => {
		if (execute) {
			_import(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_import, execute]);

	return null;
};
