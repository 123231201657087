import { UploadFile, type UploadService, UploadStatus, useUpload } from '@components/Upload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import File from '@models/File';
import { type FC, useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

type UploadListItemProps = {
	service?: UploadService;
	file: UploadFile;
	onFileUpdated?: (file: UploadFile) => void;
	stop?: boolean;
};

export const UploadListItem: FC<UploadListItemProps> = ({ service, file: uploadFile, onFileUpdated, stop = false }) => {
	const { status, percentage, file, bytesUploaded, abort, errorCode } = useUpload(service, uploadFile);

	const [progressBarClasses, setProgressBarClasses] = useState({
		foreground: '',
		background: ''
	});

	useEffect(() => {
		if (stop) {
			abort();
		}
	}, [stop, abort]);

	useEffect(() => {
		uploadFile.status = status;
		uploadFile.model = file;
		uploadFile.bytesUploaded = bytesUploaded;
		if (onFileUpdated !== undefined) {
			onFileUpdated(uploadFile);
		}
	}, [status, file, bytesUploaded, uploadFile, onFileUpdated]);

	useEffect(() => {
		if (status === UploadStatus.Error || status === UploadStatus.Aborted) {
			setProgressBarClasses({ foreground: 'bg-red-200', background: 'bg-red-100' });
		} else if (status === UploadStatus.Completed) {
			setProgressBarClasses({ foreground: 'bg-green-200', background: 'bg-green-100' });
		} else {
			setProgressBarClasses({ foreground: 'bg-gray-200', background: 'bg-gray-100' });
		}
	}, [status]);

	return (
		<div className="relative">
			<div className={`absolute inset-0 h-full ${progressBarClasses.background}`}>
				<div
					className={`max-w-full duration-500 ease-in-out h-full ${progressBarClasses.foreground}`}
					role="progressbar"
					aria-valuenow={percentage}
					aria-valuemin={0}
					aria-valuemax={100}
					style={{ width: percentage + '%', transitionProperty: 'width' }}
				/>
			</div>
			<div className="relative z-10 flex items-center justify-between p-2">
				{uploadFile.file && File.fromName(uploadFile.file.name).icon('text-gray-500 mr-2', true, { size: '2x' })}
				<header className="flex-1 min-w-0">
					<p className="text-sm font-medium text-black text-opacity-50">{!!errorCode ? errorCode : <FormattedMessage id={`uploader.${status}`} />}</p>
					<p className="font-semibold text-black text-opacity-75 truncate">{uploadFile.file?.name}</p>
				</header>
				<div className="p-3 ml-2 text-xl font-semibold">
					{status === UploadStatus.Uploading && <FormattedNumber value={percentage / 100} style="percent" />}
					{status === UploadStatus.Completed && <FontAwesomeIcon icon="check" fixedWidth className="text-green-600" />}
					{status === UploadStatus.Error && <FontAwesomeIcon icon="exclamation-triangle" className="text-red-600" fixedWidth />}
					{status === UploadStatus.Pending && <FontAwesomeIcon icon="spinner" pulse fixedWidth />}
					{status === UploadStatus.Aborted && <FontAwesomeIcon icon="exclamation" mask="octagon" transform="shrink-8" className="text-red-600" fixedWidth />}
				</div>
			</div>
		</div>
	);
};
