import { ComboBox, KeyValueDescriptionOption, type KeyValueDescriptionOptionType } from '@components/ComboBox';
import DialogAccountPassword from '@components/DialogAccountPassword';
import { type OrganizationInvite, type OrganizationUser, Role } from '@components/Team';
import {
	Alert,
	Button,
	Checkbox,
	HelperText,
	Select as HtmlSelect,
	InputBlock,
	Intent,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	type ModalProps,
	Row,
	Size,
	ValidationField,
	Variant
} from '@convoflo/ui';
import { usePasswordStrengthCheck } from '@hooks/use-password-strength-check';
import useClient from '@hooks/useClient';
import type { ValidationErrors } from '@types';
import ProgressBar from '@ui/ProgressBar';
import UserAvatar from '@ui/UserAvatar';
import { type ChangeEvent, type FC, type FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { type OptionProps, type SingleValueProps, components } from 'react-select';
import type { WretchError } from 'wretch';
import { defaultCurrency } from '../../constants';

type RoleOptionValue = {
	value: Role;
	label: Role;
};

type AddUserMutation = {
	email: string;
	firstName: string;
	lastName: string;
	password: string;
	role: Role;
	locale: string;
	forcePasswordReset: boolean;
	locked: boolean;
	accountPassword?: string;
};

type SendInviteMutation = {
	email: string;
	role: Role;
	locale: string;
	accountPassword?: string;
};

type SaveUserMutation = {
	user: OrganizationUser;
	email: string;
	firstName: string;
	lastName: string;
	password: string;
	role: Role;
	locale: string;
	forcePasswordReset: boolean;
	locked: boolean;
	accountPassword?: string;
};

type TeamMemberProps = Omit<ModalProps, 'isOpen'> & {
	user?: OrganizationUser | null;
	quota?: { used: number; available: number; pricePerUser: number } | null;
};

export const TeamMemberForm: FC<TeamMemberProps> = ({ user = null, quota = null, ...modalProps }) => {
	const { formatMessage, locale: userLocale } = useIntl();
	const { client } = useClient();
	const queryClient = useQueryClient();

	const [isOpen, setIsOpen] = useState(true);
	const [email, setEmail] = useState(user?.Email || '');
	const [firstName, setFirstName] = useState(user?.FirstName || '');
	const [lastName, setLastName] = useState(user?.LastName || '');
	const [password, setPassword] = useState('');
	const [role, setRole] = useState<Role>(user?.Role || Role.regular);
	const [locale, setLocale] = useState(user?.Locale || (userLocale.startsWith('fr') ? 'fr_CA' : 'en_US'));
	const [hasForceNewPassword, setHasForceNewPassword] = useState(user?.ResetPassword ?? false);
	const [locked, setLocked] = useState(user?.Locked ?? false);
	const [validation, setValidation] = useState<ValidationErrors>({});

	const passwordStrength = usePasswordStrengthCheck(password);

	const name = firstName && lastName ? `${firstName} ${lastName}` : null;

	const { mutate: sendInvite, isLoading: isSendingInvite } = useMutation<OrganizationInvite, WretchError, SendInviteMutation>(
		async ({ email, role, locale, accountPassword }) => {
			return await client
				.url(`org/users/invites`)
				.json({
					email: email,
					role: role,
					locale: locale,
					account_password: accountPassword
				})
				.post()
				.json<OrganizationInvite>();
		},
		{
			onMutate: () => {
				setValidation({});
			},
			onError: error => {
				error.status === 422 && setValidation(error.json.errors);

				if (error.status === 409) {
					toast.error(error.json.message);
				}
			},
			onSuccess: () => {
				setIsOpen(false);
				queryClient.invalidateQueries('invites');
				toast.success(<FormattedMessage id="invitation.invite_sent" values={{ email: email }} />);
			}
		}
	);

	const { mutate: save, isLoading: isSaving } = useMutation<OrganizationUser, WretchError, SaveUserMutation>(
		async ({ user, email, firstName, lastName, password, role, locale, forcePasswordReset, accountPassword, locked }) => {
			return await client
				.url(`org/users/${user.ID}`)
				.json({
					email,
					first_name: firstName,
					last_name: lastName,
					password,
					role,
					locale,
					locked,
					password_reset: forcePasswordReset ? 1 : 0,
					account_password: accountPassword
				})
				.put()
				.json<OrganizationUser>();
		},
		{
			onMutate: () => {
				setValidation({});
			},
			onError: error => {
				error.status === 422 && setValidation(error.json.errors);
			},
			onSuccess: user => {
				setIsOpen(false);
				queryClient.invalidateQueries('users');
				toast.success(<FormattedMessage id="user-manager.edited" values={{ name: user.Name }} />);
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		if (user !== null) {
			save({
				user,
				email,
				firstName,
				lastName,
				password,
				role,
				locale,
				forcePasswordReset: hasForceNewPassword,
				locked
			});
		} else {
			sendInvite({ email, role, locale });
		}
	};

	const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
	};

	const onAccountPasswordSubmitted = (accountPassword: string) => {
		if (user !== null) {
			save({
				user,
				email,
				firstName,
				lastName,
				password,
				role,
				locked,
				locale,
				forcePasswordReset: hasForceNewPassword,
				accountPassword
			});
		} else {
			sendInvite({ email, role, locale, accountPassword });
		}
	};

	const roleOptions: KeyValueDescriptionOptionType<Role>[] = [
		{
			value: Role.admin,
			label: <FormattedMessage id="role" values={{ role: Role.admin }} />,
			description: <FormattedMessage id="role-description" values={{ role: Role.admin }} />
		},
		{
			value: Role.regular,
			label: <FormattedMessage id="role" values={{ role: Role.regular }} />,
			description: <FormattedMessage id="role-description" values={{ role: Role.regular }} />
		},
		{
			value: Role.limited,
			label: <FormattedMessage id="role" values={{ role: Role.limited }} />,
			description: <FormattedMessage id="role-description" values={{ role: Role.limited }} />
		}
	];

	return (
		<>
			<Modal isOpen={isOpen} onSubmit={onSubmit} {...modalProps}>
				{user !== null && (
					<ModalHeader>
						<div className="flex items-center">
							<UserAvatar user={user} size={Size.sm} className="mr-2" />
							<div className="flex-1">
								<h3>
									{name || (
										<em className="text-gray-400">
											<FormattedMessage id="user-manager.no-name" />
										</em>
									)}
								</h3>
							</div>
						</div>
					</ModalHeader>
				)}
				<ModalBody>
					<Row>
						<Label htmlFor="email">
							<FormattedMessage id="user-manager.email" />
						</Label>
						{user !== null && (
							<>
								<p>{user.Email}</p>
								<p className="text-sm text-gray-600">
									<FormattedMessage
										id="user-manager.to_change_email_address"
										values={{
											link: msg => (
												<a href={`${import.meta.env.VITE_SITE_URL}/${locale}/contact`} target="_blank" rel="noopener noreferrer" className="text-theme-primary hover:underline">
													{msg}
												</a>
											)
										}}
									/>
								</p>
							</>
						)}
						{user === null && (
							<ValidationField validation={validation} fieldName="email">
								<InputBlock
									icon="envelope"
									type="email"
									id="email"
									value={email}
									placeholder={formatMessage({ id: 'user-manager.placeholder-email' })}
									disabled={user !== null}
									onChange={e => setEmail(e.target.value)}
								/>
							</ValidationField>
						)}
					</Row>

					{user !== null && (
						<>
							<Row>
								<Label htmlFor="firstname">
									<FormattedMessage id="name" />
								</Label>
								<div className="grid grid-cols-2 gap-x-3">
									<ValidationField validation={validation} fieldName="first_name">
										<InputBlock
											onChange={e => setFirstName(e.target.value)}
											type="text"
											id="firstname"
											placeholder={formatMessage({ id: 'user-manager.placeholder-firstname' })}
											value={firstName}
											required
										/>
									</ValidationField>
									<ValidationField validation={validation} fieldName="last_name">
										<InputBlock
											onChange={e => setLastName(e.target.value)}
											type="text"
											id="lastname"
											placeholder={formatMessage({ id: 'user-manager.placeholder-lastname' })}
											value={lastName}
											required
										/>
									</ValidationField>
								</div>
							</Row>

							<Row>
								<Label htmlFor="password">
									<FormattedMessage id="user-manager.password" />
								</Label>
								<div className="input-password-strength">
									<ValidationField validation={validation} fieldName="password">
										<InputBlock
											icon="key"
											type="password"
											value={password}
											id="password"
											placeholder={formatMessage({ id: 'user-manager.password_rules' })}
											onChange={onPasswordChange}
											minLength={8}
										/>
									</ValidationField>
									<ProgressBar max={100} current={passwordStrength ?? 0} reverseColors className="h-3 rounded-t-none" />
									{user !== null && (
										<HelperText>
											<FormattedMessage id="user-manager.type_password_to_change" />
										</HelperText>
									)}
								</div>
							</Row>

							<Row>
								<Checkbox checked={hasForceNewPassword} onChange={e => setHasForceNewPassword(e.target.checked)}>
									<span className="font-medium">
										<FormattedMessage id="user-manager.force-password-change" />
									</span>
								</Checkbox>
							</Row>

							<Row>
								<Checkbox checked={locked} onChange={e => setLocked(e.target.checked)}>
									<span className="font-medium">
										<FormattedMessage id="user-manager.locked" />
									</span>
								</Checkbox>
								<HelperText>
									<FormattedMessage id="user-manager.locked.help" />
								</HelperText>
							</Row>
						</>
					)}

					<Row>
						<Label htmlFor="role">
							<FormattedMessage id="user-manager.role" />
						</Label>
						<ComboBox
							isSearchable={false}
							value={roleOptions.find(opt => opt.value === role)}
							components={{
								Option: KeyValueDescriptionOption
							}}
							getOptionValue={({ value }) => value}
							options={roleOptions}
							onChange={role => setRole(role?.value ?? Role.regular)}
						/>
					</Row>

					<Row>
						<Label htmlFor="role">
							<FormattedMessage id="user-manager.locale" />
						</Label>
						<HtmlSelect defaultValue={locale} onChange={e => setLocale(e.target.value)}>
							<option value="en_US">{formatMessage({ id: 'english' })}</option>
							<option value="fr_CA">{formatMessage({ id: 'french' })}</option>
						</HtmlSelect>
					</Row>

					{quota !== null && quota.available <= 0 && (
						<Alert variant={Variant.warning}>
							<p>
								<FormattedMessage
									id="user-manager.add-user-price"
									values={{
										price: (
											<span className="font-semibold">
												<FormattedNumber value={quota.pricePerUser} currency={defaultCurrency} style="currency" minimumFractionDigits={2} />
											</span>
										)
									}}
								/>
							</p>
						</Alert>
					)}
				</ModalBody>
				<ModalFooter>
					{user !== null && (
						<Button type="submit" variant={Variant.primary} disabled={isSaving || !firstName || !lastName || !role} loading={isSaving}>
							<FormattedMessage id="save" />
						</Button>
					)}

					{user === null && (
						<Button type="submit" variant={Variant.primary} loading={isSendingInvite} disabled={!role || !email || !locale || isSendingInvite}>
							{isSendingInvite ? <FormattedMessage id="user-manager.adding" /> : <FormattedMessage id="user-manager.add-user" />}
						</Button>
					)}

					<Button intent={Intent.secondary} variant={Variant.light} type="button" className="ml-3" onClick={() => setIsOpen(false)} disabled={isSaving}>
						<FormattedMessage id="cancel" />
					</Button>
				</ModalFooter>
			</Modal>
			{'account_password' in validation && <DialogAccountPassword onAccountPasswordSubmitted={onAccountPasswordSubmitted} />}
		</>
	);
};

const RoleOption = ({ data, isSelected, ...props }: OptionProps<RoleOptionValue, false>) => (
	<components.Option data={data} isSelected={isSelected} {...props}>
		<h6 className={`font-semibold ${isSelected ? 'text-white' : ''}`}>
			<FormattedMessage id="role" values={{ role: data.value }} />
		</h6>
		<p className={`text-sm leading-snug ${isSelected ? 'text-white' : 'text-gray-600'}`}>
			<FormattedMessage id="role-description" values={{ role: data.value }} />
		</p>
	</components.Option>
);

const RoleValue = ({ ...props }: SingleValueProps<RoleOptionValue, false>) => (
	<components.SingleValue {...props}>
		<FormattedMessage id="role" values={{ role: props.data.value }} />
	</components.SingleValue>
);
