import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Intent, Modal, ModalBody, ModalFooter, ModalHeader, type ModalProps, Size, Variant } from '@convoflo/ui';
import { useAnnouncements } from '@components/Announcement';
import { type FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { MarkdownContent } from '@ui/MarkdownContent';
import { DateTimeDisplay } from '../DateTime';

type DialogAnnouncementsProps = Omit<ModalProps, 'isOpen'>;

export const DialogAnnouncements: FC<DialogAnnouncementsProps> = ({ ...modalProps }) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isOpen, setIsOpen] = useState(false);

	const { announcements, dismiss } = useAnnouncements('new');

	const prev = () => {
		setCurrentIndex(currentIndex => currentIndex - 1);
	};

	const next = () => {
		setCurrentIndex(currentIndex => currentIndex + 1);
	};

	const close = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		if (!announcements) {
			return;
		}

		setIsOpen(announcements.length > 0);
	}, [announcements]);

	useEffect(() => {
		if (!announcements) {
			return;
		}

		if (announcements.length > 0) {
			dismiss(announcements[currentIndex]);
		}
	}, [announcements, currentIndex, dismiss]);

	if (!announcements || announcements.length === 0) {
		return null;
	}

	const currentAnnouncement = announcements[currentIndex];

	return (
		<Modal isOpen={isOpen} {...modalProps}>
			<ModalHeader className="flex items-center justify-between pb-4 space-x-4">
				<div>
					<h2 className="flex-1 text-lg font-medium text-gray-900">{currentAnnouncement.Title}</h2>
					<p className="text-xs text-gray-400">
						<DateTimeDisplay defaultFormat="relative" value={currentAnnouncement.created_at} />
					</p>
				</div>
				<div className="mr-4 text-sm text-gray-300">
					{currentIndex + 1} / {announcements.length}
				</div>
			</ModalHeader>
			<ModalBody>
				<div className="prose">
					<MarkdownContent>{currentAnnouncement.Content}</MarkdownContent>
					{currentAnnouncement.Link !== null && (
						<p>
							<a href={currentAnnouncement.Link} target="_blank" rel="noreferrer">
								<FormattedMessage id="announcements.learn_more" />
								<FontAwesomeIcon icon="external-link" className="ml-2" />
							</a>
						</p>
					)}
				</div>
			</ModalBody>
			<ModalFooter className="justify-between">
				{announcements.length > 1 && (
					<div className="space-x-4">
						<Button disabled={currentIndex === 0} variant={Variant.light} size={Size.sm} circle onClick={prev}>
							<FontAwesomeIcon icon="chevron-left" />
						</Button>
						<Button disabled={currentIndex === announcements.length - 1} variant={Variant.light} size={Size.sm} circle onClick={next}>
							<FontAwesomeIcon icon="chevron-right" />
						</Button>
					</div>
				)}

				<Button variant={Variant.light} intent={Intent.secondary} onClick={close}>
					<FormattedMessage id="close" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
