import File from '@models/File';
import Card from '@ui/Card';
import type { FC } from 'react';
import { VersionsTable } from './DialogFileSettings';

export const VersionsManager: FC<{ file: File }> = ({ file }) => {
	return (
		<Card size={null}>
			<VersionsTable file={file} />
		</Card>
	);
};
