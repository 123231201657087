import { SimpleResourcePaginator, createResourceLengthAwarePagination } from '@components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import { downloadResponse } from '@service/Client';
import { Button, Input, InputClassNames, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Size, TextArea, ValidationField, Variant } from '@convoflo/ui';
import type { Contact } from '@types';
import Card from '@ui/Card';
import Dropdown, { DropdownGroup, DropdownItem } from '@ui/Dropdown';
import EmptyState from '@ui/EmptyState';
import SortColumnHeader from '@ui/SortColumnHeader';
import { Tab } from '@ui/Tab';
import { Table, TableCell, TableHeader } from '@ui/Table';
import { formatDate } from '@utils/DateUtils';
import { type FC, type FormEvent, useCallback, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { WretchError, WretchResponse } from 'wretch';

type ExportContactsMutation = {
	format: 'csv' | 'json';
};

export const ContactManager = ({ limit = 50, searchQuery: initialSearchQuery = '' }) => {
	const { formatMessage } = useIntl();
	const { client } = useClient();
	const queryClient = useQueryClient();

	const [sort, setSort] = useState('name');
	const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
	const [page, setPage] = useState(1);
	const [importOpened, setImportOpened] = useState(false);

	// Form states
	const [searchQueryInput, setSearchQueryInput] = useState(initialSearchQuery);

	const { data, isLoading, refetch } = useQuery(['contact-manager', page, sort, searchQuery], async () => {
		return createResourceLengthAwarePagination<Contact, {}>(await client.url('account/contacts').query({ page, q: searchQuery, sort, limit }).get().json());
	});

	const onSearch = (e: FormEvent) => {
		e.preventDefault();
		setSearchQuery(searchQueryInput);
	};

	const onContactImportedClose = (imported = false) => {
		if (imported) {
			queryClient.invalidateQueries(['contact-manager']);
		}
		setImportOpened(false);
	};

	const { mutate: _export } = useMutation<WretchResponse, WretchError, ExportContactsMutation>(async ({ format }) => await client.url(`account/contacts.${format}`).get().res(), {
		onSuccess: (response, { format }) => {
			const mimeType = format === 'csv' ? 'text/csv' : 'application/json';
			downloadResponse(`convoflo-contact-export-${formatDate(null, null, 'yMMddHHmmss')}.${format}`, mimeType)(response);
		}
	});

	const contacts = data?.data ?? [];

	useEffect(() => {
		setSearchQuery(initialSearchQuery);
	}, [initialSearchQuery]);

	return (
		<>
			{contacts.length > 0 && (
				<div className="flex justify-between mb-8" role="toolbar">
					<form onSubmit={onSearch}>
						<Input type="text" placeholder={formatMessage({ id: 'contact-manager.search_placeholder' })} onChange={e => setSearchQueryInput(e.target.value)} value={searchQueryInput} />
					</form>

					<div className="flex items-center space-x-4" role="toolbar">
						<Button variant={Variant.primary} onClick={() => setImportOpened(true)}>
							<FontAwesomeIcon icon="cloud-download" className="mr-2" />
							<FormattedMessage id="contact-manager.import" />
						</Button>

						<Dropdown placement="bottom-start">
							<Button variant={Variant.primary} disabled={contacts.length === 0}>
								<FontAwesomeIcon icon="cloud-upload" className="mr-2" />
								<FormattedMessage id="contact-manager.export" />
								<FontAwesomeIcon size="sm" icon="caret-down" className="ml-2" />
							</Button>
							<DropdownGroup>
								<DropdownItem icon="file-excel" onClick={() => _export({ format: 'csv' })}>
									<FormattedMessage id="contact-manager.as_csv" />
								</DropdownItem>
								<DropdownItem icon="brackets" onClick={() => _export({ format: 'json' })}>
									<FormattedMessage id="contact-manager.as_json" />
								</DropdownItem>
							</DropdownGroup>
						</Dropdown>
					</div>
				</div>
			)}
			<Card size={null}>
				<Table>
					{contacts.length > 0 && (
						<thead>
							<tr>
								<TableHeader>
									<SortColumnHeader sortField="name" currentSort={sort} onSort={setSort}>
										<FormattedMessage id="name" />
									</SortColumnHeader>
								</TableHeader>
								<TableHeader>
									<SortColumnHeader sortField="email" currentSort={sort} onSort={setSort}>
										<FormattedMessage id="contact-manager.email" />
									</SortColumnHeader>
								</TableHeader>
								<TableHeader />
							</tr>
						</thead>
					)}
					<tbody className="bg-white">
						{!isLoading && contacts.map(contact => <ContactRow key={contact.Id} contact={contact} />)}
						{isLoading && (
							<tr>
								<TableCell colSpan={3}>
									<div className="flex items-center justify-center h-24">
										<p>
											<FontAwesomeIcon icon="spinner" className="mr-2" pulse />
											<FormattedMessage id="loading" />
										</p>
									</div>
								</TableCell>
							</tr>
						)}
						{!isLoading && contacts.length === 0 && (
							<tr>
								<TableCell colSpan={3}>
									<EmptyState
										icon="address-card"
										title={<FormattedMessage id="contact-manager.total_contacts" values={{ n: 0 }} />}
										action={
											<Button variant={Variant.primary} onClick={() => setImportOpened(true)} icon="cloud-download">
												<FormattedMessage id="contact-manager.import" />
											</Button>
										}
									/>
								</TableCell>
							</tr>
						)}
					</tbody>
				</Table>
				{data !== undefined && contacts.length > 0 && (
					<div className="px-4 py-3 sm:px-6">
						<SimpleResourcePaginator {...data} onPage={setPage} />
					</div>
				)}
			</Card>

			{importOpened && <ModalImport onAfterClose={onContactImportedClose} onImported={refetch} />}
		</>
	);
};

type ContactRowProps = {
	contact: Contact;
};

const ContactRow: FC<ContactRowProps> = ({ contact }) => {
	const { formatMessage } = useIntl();
	const { client } = useClient();
	const queryClient = useQueryClient();

	const [mode, setMode] = useState<string | null>(null);
	const [name, setName] = useState(contact.Name ?? '');

	const { mutate: save } = useMutation(async ({ name }: { name: string }) => await client.url(`account/contacts/${contact.Id}`).put({ name }).json<Contact>(), {
		onSuccess: () => {
			setMode(null);
			queryClient.invalidateQueries(['contact-manager']);
		}
	});

	const { mutate: _delete } = useMutation(async () => await client.url(`account/contacts/${contact.Id}`).delete().res(), {
		onSuccess: () => {
			queryClient.invalidateQueries(['contact-manager']);
		}
	});

	return (
		<tr>
			<TableCell>
				{mode === 'edit' && (
					<Input
						size={Size.sm}
						type="text"
						className="text-sm"
						value={name || ''}
						onChange={e => setName(e.target.value)}
						autoFocus
						placeholder={formatMessage({ id: 'contact-manager.name-placeholder' })}
					/>
				)}
				{mode !== 'edit' &&
					(contact.Name || (
						<em className="text-gray-400">
							<FormattedMessage id="contact-manager.no-name" />
						</em>
					))}
			</TableCell>
			<TableCell>{contact.Email}</TableCell>
			<TableCell className="text-right">
				{mode === 'edit' && (
					<>
						<Button variant={Variant.success} intent={Intent.secondary} size={Size.sm} className="mr-3" type="button" onClick={() => save({ name })}>
							<FormattedMessage id="save" />
						</Button>
						<Button variant={Variant.light} intent={Intent.secondary} size={Size.sm} type="button" onClick={() => setMode(null)}>
							<FormattedMessage id="cancel" />
						</Button>
					</>
				)}
				{mode === 'delete' && (
					<>
						<Button variant={Variant.danger} intent={Intent.secondary} size={Size.sm} className="mr-3" type="button" onClick={() => _delete()}>
							<FormattedMessage id="contact-manager.confirm" />
						</Button>
						<Button variant={Variant.light} intent={Intent.secondary} size={Size.sm} type="button" onClick={() => setMode(null)}>
							<FormattedMessage id="cancel" />
						</Button>
					</>
				)}
				{mode === null && (
					<>
						<Button variant={Variant.primary} intent={Intent.secondary} size={Size.sm} className="mr-3" type="button" onClick={() => setMode('edit')}>
							<FormattedMessage id="contact-manager.edit" />
						</Button>
						<Button variant={Variant.danger} intent={Intent.secondary} size={Size.sm} type="button" onClick={() => setMode('delete')}>
							<FormattedMessage id="delete" />
						</Button>
					</>
				)}
			</TableCell>
		</tr>
	);
};

type ModalImportProps = Omit<ModalProps, 'isOpen'> & {
	onImported?: () => void;
};

const ModalImport: FC<ModalImportProps> = ({ onImported = () => undefined, ...modalProps }) => {
	const { formatMessage } = useIntl();
	const { client, validation } = useClient();

	const [isOpen, setIsOpen] = useState(true);
	const [tabIndex, setTabIndex] = useState(0);
	const [contents, setContents] = useState('');
	const [csvFile, setCsvFile] = useState<File | null>(null);

	const onDrop = useCallback(async (file: File[]) => {
		setCsvFile(file[0]!);
	}, []);

	const { mutate: importByText, isLoading: importingByText } = useMutation<WretchResponse, WretchError, string[]>(
		async emails =>
			await client
				.url('account/contacts')
				.json({
					email: emails
				})
				.post()
				.res(),
		{
			onSuccess: () => {
				toast.success(<FormattedMessage id="contact-manager.contacts_imported" />);
				setIsOpen(false);
				onImported();
			}
		}
	);

	const { mutate: importByCsv, isLoading: importingByCsv } = useMutation<WretchResponse, WretchError, File>(
		async csv =>
			await client
				.url('account/contacts')
				.formData({
					csv
				})
				.post()
				.res(),
		{
			onSuccess: () => {
				toast.success(<FormattedMessage id="contact-manager.contacts_imported" />);
				setIsOpen(false);
				onImported();
			}
		}
	);

	const close = () => {
		setIsOpen(false);
	};

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();

		const emails = contents
			.split(/\r?\n/)
			.map(address => address.trim())
			.filter(address => address !== '');

		if (tabIndex === 1 && emails.length > 0) {
			importByText(emails);
		} else if (tabIndex === 0 && csvFile !== null) {
			importByCsv(csvFile);
		}
	};

	return (
		<Modal isOpen={isOpen} {...modalProps} onSubmit={onSubmit}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="contact-manager.import_contacts" />
			</ModalHeaderOnlyTitle>
			<Tab.Group onChange={setTabIndex}>
				<Tab.List className="mt-4">
					<Tab>
						<FormattedMessage id="contact-manager.upload_csv" />
					</Tab>
					<Tab>
						<FormattedMessage id="contact-manager.enter_manually" />
					</Tab>
				</Tab.List>
				<Tab.Panels>
					<Tab.Panel>
						<ModalBody>
							<Dropzone onDrop={onDrop} multiple={false} accept={{ 'text/csv': ['csv'] }} noKeyboard>
								{({ getRootProps, getInputProps }) => (
									<>
										<div
											{...getRootProps({
												className:
													'h-64 cursor-pointer border-4 border-dashed border-gray-600 rounded flex justify-center flex-col text-center items-center bg-gray-100 p-4 text-gray-600'
											})}>
											{csvFile === null && (
												<>
													<FontAwesomeIcon icon="upload" size="3x" className="mb-3 text-secondary" />
													<p>
														<em>
															<FormattedMessage id="contact-manager.drag_n_drop" />
														</em>
													</p>
												</>
											)}
											{csvFile !== null && (
												<>
													<FontAwesomeIcon icon="file-csv" size="3x" className="mb-3 text-success" />
													<p>
														<em>{csvFile.name}</em>
													</p>
												</>
											)}
										</div>
										<input {...getInputProps()} />
									</>
								)}
							</Dropzone>
						</ModalBody>
					</Tab.Panel>
					<Tab.Panel>
						<ModalBody>
							<ValidationField validation={validation} fieldName="email.0">
								<TextArea
									block
									value={contents}
									onChange={e => setContents(e.target.value)}
									className={`${InputClassNames} w-full block`}
									rows={10}
									placeholder={formatMessage({ id: 'contact-manager.placeholder-contents' })}
								/>
							</ValidationField>
						</ModalBody>
					</Tab.Panel>
				</Tab.Panels>
			</Tab.Group>

			<ModalFooter>
				<Button
					type="submit"
					variant={Variant.primary}
					loading={importingByText || importingByCsv}
					disabled={(tabIndex === 1 && contents.trim() === '') || (tabIndex === 0 && csvFile === null)}>
					<FormattedMessage id="contact-manager.import" />
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} type="button" onClick={close}>
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
