import type { LecsorUnitDetailsType } from '@components/Lecsor';
import useClient from '@hooks/useClient';
import { useQuery } from 'react-query';

export const useUnitDetails = (associationId: string | undefined, accountId: string | undefined) => {
	const { client } = useClient();

	const { data: details, isLoading } = useQuery(
		['lecsor', 'unit-details', associationId, accountId],
		async () => await client.url(`lecsor/associations/${associationId}/units/${accountId}`).get().json<LecsorUnitDetailsType>(),
		{
			enabled: !!associationId && !!accountId
		}
	);

	return { details, isLoading };
};
