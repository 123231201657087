import { useLocalStorage as baseUseLocalStorage } from '@rehooks/local-storage';

export type LocalStorageNullableReturnValue<TValue> = [TValue | null, (newValue: TValue | null) => void, () => void];
export type LocalStorageReturnValue<TValue> = [TValue, (newValue: TValue | null) => void, () => void];

export function useLocalStorage<TValue = string>(key: string): LocalStorageNullableReturnValue<TValue>;
export function useLocalStorage<TValue = string>(key: string, defaultValue: TValue): LocalStorageReturnValue<TValue>;

export function useLocalStorage<TValue = string>(key: string, defaultValue: TValue | null = null) {
	try {
		return baseUseLocalStorage(key, defaultValue);
	} catch {
		return [defaultValue, () => {}, () => {}];
	}
}
