import type { CannedMessage, CannedMessageCategory } from '@components/CannedMessages';
import { CreatableComboBox } from '@components/ComboBox';
import { Button, Checkbox, InputBlock, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Row, Variant } from '@convoflo/ui';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { RichTextEditor, type RichTextEditorRef } from '@ui/RichTextEditor';
import { type FC, type FormEvent, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { SingleValue } from 'react-select';
import type { WretchError } from 'wretch';

type CannedMessageFormProps = Omit<ModalProps, 'isOpen'> & {
	message?: CannedMessage;
	onSave?: (message: CannedMessage) => void;
};

type CreateCannedMessageMutation = {
	title: string;
	body: RichTextEditorRef;
	category?: CannedMessageCategory;
	shared?: boolean;
};

type SaveCannedMessageMutation = CreateCannedMessageMutation & {
	id: number;
};

export const CannedMessageForm: FC<CannedMessageFormProps> = ({ message, onSave = () => undefined, ...modalProps }) => {
	const { formatMessage } = useIntl();
	const { client } = useClient();
	const queryClient = useQueryClient();
	const { account } = useAccount();

	const editor = useRef<RichTextEditorRef>(null);

	const [isOpen, setIsOpen] = useState(true);

	const [title, setTitle] = useState(message?.Title ?? '');
	const [category, setCategory] = useState<CannedMessageCategory>();
	const [shared, setShared] = useState(message?.Shared ?? false);

	const { data: categories = [] } = useQuery(['categories', 'canned-messages'], async () => await client.url('account/canned-categories').get().json<CannedMessageCategory[]>(), {
		onSuccess: categories => {
			if (!message || !message.category) {
				return;
			}
			setCategory(category => category ?? categories.find(category => message.category!.Id === category.Id));
		}
	});

	const { mutate: create, isLoading: isCreating } = useMutation<CannedMessage, WretchError, CreateCannedMessageMutation>(
		async ({ title, body, category, shared = false }) =>
			await client
				.url('account/canned-messages')
				.json({ title, body: JSON.stringify(body.json()), category_id: category?.Id, category_name: category?.Name, shared })
				.post()
				.json<CannedMessage>(),
		{
			onMutate: async ({ body, title, category, shared }) => {
				await queryClient.cancelQueries(['canned-messages']);

				const previousMessages = queryClient.getQueryData<CannedMessage[]>(['canned-messages']);

				if (previousMessages === undefined) {
					return undefined;
				}

				const tempMessage: CannedMessage = {
					category: category ? { Id: category.Id, Name: category.Name, Order: 0, messages: [] } : null,
					Content: body.html(),
					creator: account!.toUser(),
					Id: 0,
					IsShareMessage: false,
					IsSignature: false,
					Order: 0,
					Title: title,
					Shared: shared ?? false
				};

				queryClient.setQueryData(['canned-messages'], previousMessages.concat(tempMessage));

				return previousMessages;
			},
			onError: (_error, _variables, previousMessages) => {
				queryClient.setQueryData(['canned-messages'], previousMessages);
			},
			onSuccess: () => {
				queryClient.invalidateQueries('canned_message_categories');
				toast.success(<FormattedMessage id="canned-messages-crud.canned_message_created" />);
				setIsOpen(false);
			}
		}
	);

	const { mutate: save, isLoading: isSaving } = useMutation<CannedMessage, WretchError, SaveCannedMessageMutation>(
		async ({ id, title, body, category, shared = false }) =>
			await client
				.url(`account/canned-messages/${id}`)
				.json({ title, body: JSON.stringify(body.json()), category_id: category?.Id || undefined, category_name: category?.Name || undefined, shared })
				.put()
				.json<CannedMessage>(),
		{
			onMutate: async ({ id, body, title, category, shared }) => {
				await queryClient.cancelQueries(['canned-messages']);

				const previousMessages = queryClient.getQueryData<CannedMessage[]>(['canned-messages']);

				if (previousMessages === undefined) {
					return undefined;
				}

				queryClient.setQueryData(
					['canned-messages'],
					previousMessages.map(_message =>
						_message.Id === id
							? {
									..._message,
									Content: body.html(),
									Title: title,
									Shared: shared,
									category: { ..._message.category, Id: category?.Id, Name: category?.Name }
							  }
							: _message
					)
				);

				return previousMessages;
			},
			onSuccess: () => {
				toast.success(<FormattedMessage id="canned-messages-crud.canned_message_modified" />);
				setIsOpen(false);
			},
			onError: (_error, _variables, previousMessages) => {
				queryClient.setQueryData(['canned-messages'], previousMessages);
			}
		}
	);

	const onCategoryChanged = (category: SingleValue<CannedMessageCategory>) => {
		setCategory(category ?? undefined);
	};

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		const body = editor.current;

		if (!body) {
			return;
		}

		if (message) {
			save({ id: message.Id, title, body, category, shared });
		} else {
			create({ title, body, category, shared });
		}
	};

	return (
		<Modal isOpen={isOpen} {...modalProps} onSubmit={onSubmit}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id={!message ? 'canned-messages-crud.create_canned_message' : 'canned-messages-crud.edit_canned_message'} />
			</ModalHeaderOnlyTitle>
			<ModalBody>
				<Row>
					<Label htmlFor="title">
						<FormattedMessage id="canned-messages-crud.title" />
					</Label>
					<InputBlock autoFocus={!message} id="title" disabled={isSaving} type="text" value={title} onChange={e => setTitle(e.target.value)} />
				</Row>

				<Row>
					<Label htmlFor="category">
						<FormattedMessage id="canned-categories-crud.canned_category_title" />
					</Label>

					<CreatableComboBox
						value={category}
						options={categories}
						getOptionValue={category => String(category.Id)}
						getOptionLabel={category => category.Name}
						filterOption={({ data }, input) => (input ? data.Name.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0 : true)}
						onChange={onCategoryChanged}
						getNewOptionData={input => ({ Name: input, Id: 0 } as CannedMessageCategory)}
						placeholder={<FormattedMessage id="canned-messages-crud.select_or_create_category" />}
						formatCreateLabel={input => (
							<span className="italic">
								<FormattedMessage id="canned-messages-crud.create_category" values={{ input }} />
							</span>
						)}
					/>
				</Row>

				<Row>
					<Label htmlFor="content">
						<FormattedMessage id="canned-messages-crud.message" />
					</Label>
					<RichTextEditor showCannedMessages={false} autoFocus ref={editor} value={message?.Content ?? ''} placeholder={formatMessage({ id: 'canned-messages-crud.placeholder-content' })} />
				</Row>

				<Row>
					<Checkbox checked={shared} disabled={isSaving} onChange={e => setShared(e.target.checked)}>
						<FormattedMessage id="canned-messages-crud.shared-with-business" />
					</Checkbox>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button variant={Variant.primary} icon={message ? 'save' : 'plus'} disabled={isSaving || isCreating || title === ''}>
					{message ? <FormattedMessage id="save" /> : <FormattedMessage id="canned-messages-crud.add" />}
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} type="button" onClick={() => setIsOpen(false)} disabled={isSaving}>
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
