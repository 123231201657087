import { Tab as BaseTab, type TabProps as BaseTabProps } from '@headlessui/react';
import classNames from 'classnames';
import type { FC } from 'react';

type TabProps = Omit<BaseTabProps<'button'>, 'className'> & {
	className?: string;
	size?: string;
	position?: string;
};

export const Tab: FC<TabProps> = ({ className, position = 'top', size, disabled, ...props }) => {
	return (
		<BaseTab
			disabled={disabled}
			className={({ selected }) =>
				classNames('transition-opacity focus:outline-none inline-flex items-center space-x-3', {
					'font-semibold text-theme-primary border-theme-primary opacity-100': selected,
					'opacity-25 border-transparent': !selected,
					'border-b-2 -mb-px': position === 'top',
					'border-t-2 -mt-px': position === 'bottom',
					'text-sm py-3': size === undefined,
					'text-xs py-1': size === 'sm',
					'hover:opacity-50': !disabled && !selected
				})
			}
			{...props}
		/>
	);
};
