import * as React from "react";
const SvgStartTrial = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 387.78 353.556", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M328.9 77.69a70.67 70.67 0 0 1 30.73 110c-11 14-26.84 23.2-42.5 31.62-15.66 8.42-4.6-.46-17.27 12s-21.45 30.43-18.18 47.91c4 21.3 23.74 35.47 42.18 46.86s38.65 24.2 44.77 45c2.29 7.77 1.4 17.85-5.75 21.66-5.92 3.16-13.14.52-19.3-2.14l-125.2-54.1c-25.46-11-51.88-22.72-69.66-44-21.42-25.64-26.79-61.75-21.69-94.76 2.82-18.27 8.82-36.53 20.64-50.75 15.92-19.15 40.2-28.72 62.55-39.73 24.35-12 22.89-4.51 45.41-19.67 36.08-24.3 73.27-9.9 73.27-9.9z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.9
} }, /* @__PURE__ */ React.createElement("path", { d: "M328.9 77.69a70.67 70.67 0 0 1 30.73 110c-11 14-26.84 23.2-42.5 31.62-15.66 8.42-4.6-.46-17.27 12s-21.45 30.43-18.18 47.91c4 21.3 23.74 35.47 42.18 46.86s38.65 24.2 44.77 45c2.29 7.77 1.4 17.85-5.75 21.66-5.92 3.16-13.14.52-19.3-2.14l-125.2-54.1c-25.46-11-51.88-22.72-69.66-44-21.42-25.64-26.79-61.75-21.69-94.76 2.82-18.27 8.82-36.53 20.64-50.75 15.92-19.15 40.2-28.72 62.55-39.73 24.35-12 22.89-4.51 45.41-19.67 36.08-24.3 73.27-9.9 73.27-9.9z", style: {
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" })), /* @__PURE__ */ React.createElement("g", { transform: "translate(-58.25 -73.454)" }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ebebeb"
}, d: "M104.56 390.89h6.33v.25h-6.33z" }), /* @__PURE__ */ React.createElement("ellipse", { cx: 252.14, cy: 415.69, rx: 193.89, ry: 11.32, style: {
  fill: "#f5f5f5"
} })), /* @__PURE__ */ React.createElement("path", { d: "M277.56 414.88H74a20.74 20.74 0 0 1-6.25-26.94 11.68 11.68 0 0 1 4-4.46 20.53 20.53 0 0 1-1.92-14c1.06-4.47 4.76-7.5 8.58-9.94a68.66 68.66 0 0 1 9-47.38c8.58-13.94 22.73-23 38.29-27.41a42.87 42.87 0 0 1 42-47.94 29.38 29.38 0 0 1 12.65-21.18 28 28 0 0 1 23.92-3.63 20.19 20.19 0 0 1 10.8-14 19.54 19.54 0 0 1 17.49.41c8.18-2.32 17.18 2.05 20.89 9.83 3.71 7.78 1.23 18.1-5.62 23.23 7.73 11.52 7.39 29.56-.82 40.73 4.52 1.22 6.48 7.7 7.15 12.42a67.52 67.52 0 0 1-6.68 40.05c8.34-.4 16.41 5.82 21.38 12.66a26.89 26.89 0 0 1 3.53 24.24c4.84-.3 8.95 3.53 11.33 7.82a33.94 33.94 0 0 1 3.38 11.37 5.68 5.68 0 0 1 .43 1.24c2.59 11.38-1.4 24.91-9.97 32.88z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.6
} }, /* @__PURE__ */ React.createElement("path", { d: "M277.56 414.88H74a20.74 20.74 0 0 1-6.25-26.94 11.68 11.68 0 0 1 4-4.46 20.53 20.53 0 0 1-1.92-14c1.06-4.47 4.76-7.5 8.58-9.94a68.66 68.66 0 0 1 9-47.38c8.58-13.94 22.73-23 38.29-27.41a42.87 42.87 0 0 1 42-47.94 29.38 29.38 0 0 1 12.65-21.18 28 28 0 0 1 23.92-3.63 20.19 20.19 0 0 1 10.8-14 19.54 19.54 0 0 1 17.49.41c8.18-2.32 17.18 2.05 20.89 9.83 3.71 7.78 1.23 18.1-5.62 23.23 7.73 11.52 7.39 29.56-.82 40.73 4.52 1.22 6.48 7.7 7.15 12.42a67.52 67.52 0 0 1-6.68 40.05c8.34-.4 16.41 5.82 21.38 12.66a26.89 26.89 0 0 1 3.53 24.24c4.84-.3 8.95 3.53 11.33 7.82a33.94 33.94 0 0 1 3.38 11.37 5.68 5.68 0 0 1 .43 1.24c2.59 11.38-1.4 24.91-9.97 32.88z", style: {
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" })), /* @__PURE__ */ React.createElement("path", { d: "m228.72 181.62 62.91-10.21a1.27 1.27 0 0 1 1.37 1.72l-23.93 59.07a1.25 1.25 0 0 1-2.15.31l-39-48.86a1.26 1.26 0 0 1 .8-2.03z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m228.72 181.62 62.91-10.21a1.27 1.27 0 0 1 1.37 1.72l-23.93 59.07a1.25 1.25 0 0 1-2.15.31l-39-48.86a1.26 1.26 0 0 1 .8-2.03z", style: {
  opacity: 0.2,
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m233.21 205.45 3.92-5.07-3.41-4.28-5.82 2.7a11.08 11.08 0 0 0 5.31 6.65z", style: {
  fill: "#263238"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m233.72 196.1-1.37.63a11.12 11.12 0 0 0 3.86 4.84l.92-1.19z", style: {
  opacity: 0.2,
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m230.89 197.41-1.09.51a12.69 12.69 0 0 0 4.69 5.87l.73-.95a18.07 18.07 0 0 1-4.33-5.43z", style: {
  opacity: 0.4,
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M239.37 202.48a15 15 0 0 1-7.21-9l36.17-28.86a19.63 19.63 0 0 1 14.51-4.18 19.61 19.61 0 0 1-7.3 13.21z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m268.33 164.59-34.09 27.21a17.61 17.61 0 0 0 7.2 9l34.1-27.21a19.61 19.61 0 0 0 7.3-13.21 19.63 19.63 0 0 0-14.51 4.21z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m268.33 164.59-34.09 27.21a17.61 17.61 0 0 0 7.2 9l34.1-27.21a19.61 19.61 0 0 0 7.3-13.21 19.63 19.63 0 0 0-14.51 4.21z", style: {
  opacity: 0.5,
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m268.33 164.59-1.56 1.25a21 21 0 0 0 7.2 9l1.57-1.25a19.61 19.61 0 0 0 7.3-13.21 19.63 19.63 0 0 0-14.51 4.21z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m268.33 164.59-1.56 1.25a21 21 0 0 0 7.2 9l1.57-1.25a19.61 19.61 0 0 0 7.3-13.21 19.63 19.63 0 0 0-14.51 4.21z", style: {
  opacity: 0.2,
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m252.22 227.77 3.92-5.08-3.41-4.27-5.81 2.7a11.06 11.06 0 0 0 5.3 6.65z", style: {
  fill: "#263238"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m252.73 218.42-1.36.63a11 11 0 0 0 3.86 4.83l.91-1.19z", style: {
  opacity: 0.2,
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m249.91 219.73-1.09.5a12.64 12.64 0 0 0 4.68 5.88l.74-1a17.92 17.92 0 0 1-4.33-5.38z", style: {
  opacity: 0.4,
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M258.39 224.8a15.07 15.07 0 0 1-7.21-9l36.17-28.86a19.68 19.68 0 0 1 14.51-4.19 19.73 19.73 0 0 1-7.3 13.22z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m287.35 186.91-34.1 27.2a17.64 17.64 0 0 0 7.21 9l34.1-27.2a19.73 19.73 0 0 0 7.3-13.22 19.68 19.68 0 0 0-14.51 4.22z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m287.35 186.91-34.1 27.2a17.64 17.64 0 0 0 7.21 9l34.1-27.2a19.73 19.73 0 0 0 7.3-13.22 19.68 19.68 0 0 0-14.51 4.22z", style: {
  opacity: 0.5,
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m287.35 186.91-1.56 1.24a21 21 0 0 0 7.2 9l1.57-1.25a19.73 19.73 0 0 0 7.3-13.22 19.68 19.68 0 0 0-14.51 4.23z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m287.35 186.91-1.56 1.24a21 21 0 0 0 7.2 9l1.57-1.25a19.73 19.73 0 0 0 7.3-13.22 19.68 19.68 0 0 0-14.51 4.23z", style: {
  opacity: 0.2,
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m242.09 220.44 6.34-8.18-5.51-6.91-9.39 4.36a17.84 17.84 0 0 0 8.56 10.73z", style: {
  fill: "#263238"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m242.92 205.35-2.2 1a17.83 17.83 0 0 0 6.22 7.8l1.49-1.91z", style: {
  opacity: 0.2,
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m238.36 207.47-1.77.82a20.48 20.48 0 0 0 7.57 9.48l1.19-1.54a29 29 0 0 1-6.99-8.76z", style: {
  opacity: 0.4,
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M252 215.65a24.3 24.3 0 0 1-11.63-14.58l58.39-46.59a31.77 31.77 0 0 1 23.42-6.75 31.73 31.73 0 0 1-11.78 21.33z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m298.8 154.48-55 43.92a28.45 28.45 0 0 0 11.59 14.6l55.05-43.92a31.73 31.73 0 0 0 11.78-21.33 31.77 31.77 0 0 0-23.42 6.73z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m298.8 154.48-55 43.92a28.45 28.45 0 0 0 11.59 14.6l55.05-43.92a31.73 31.73 0 0 0 11.78-21.33 31.77 31.77 0 0 0-23.42 6.73z", style: {
  opacity: 0.6,
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m298.8 154.48-2.52 2a33.91 33.91 0 0 0 11.63 14.58l2.53-2a31.73 31.73 0 0 0 11.78-21.33 31.77 31.77 0 0 0-23.42 6.75z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m298.8 154.48-2.52 2a33.91 33.91 0 0 0 11.63 14.58l2.53-2a31.73 31.73 0 0 0 11.78-21.33 31.77 31.77 0 0 0-23.42 6.75z", style: {
  opacity: 0.2,
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M296.33 175.34a5.43 5.43 0 1 1 .86-7.64 5.44 5.44 0 0 1-.86 7.64z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M288.69 174.48a5.43 5.43 0 0 1 .55-7.36 3.43 3.43 0 0 0-.33.24 5.433 5.433 0 1 0 6.78 8.49l.3-.27a5.43 5.43 0 0 1-7.3-1.1z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M288.69 174.48a5.43 5.43 0 0 1 .55-7.36 3.43 3.43 0 0 0-.33.24 5.433 5.433 0 1 0 6.78 8.49l.3-.27a5.43 5.43 0 0 1-7.3-1.1z", style: {
  opacity: 0.3
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M295.33 174.09a3.84 3.84 0 1 1 .61-5.39 3.82 3.82 0 0 1-.61 5.39z", style: {
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m289.83 173.32 6.76-3.39a3.84 3.84 0 0 0-.6-1.15l-6.74 3.37a4 4 0 0 0 .58 1.17zM290.88 174.31l5.88-2.94a3.47 3.47 0 0 0 0-.72l-6.43 3.22a4 4 0 0 0 .55.44z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.7
} }, /* @__PURE__ */ React.createElement("path", { d: "m289.83 173.32 6.76-3.39a3.84 3.84 0 0 0-.6-1.15l-6.74 3.37a4 4 0 0 0 .58 1.17zM290.88 174.31l5.88-2.94a3.47 3.47 0 0 0 0-.72l-6.43 3.22a4 4 0 0 0 .55.44z", style: {
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#1b9ff1"
}, d: "M308.52 234.34h53.74v180.4h-53.74z", transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.2
}, d: "M308.39 234.32h15.18v180.32h-15.18z", transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#1b9ff1"
}, d: "M64.89 364.57h53.74v50.18H64.89z", transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.2
}, d: "M64.39 364.52h15.18v50.13H64.39z", transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#1b9ff1"
}, d: "M142.91 321.16h53.74v93.58h-53.74z", transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.2
}, d: "M142.54 321.12h15.18v93.53h-15.18z", transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#1b9ff1"
}, d: "M226.49 277.75h53.74v136.99h-53.74z", transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.2
}, d: "M226.24 277.72h15.18v136.93h-15.18z", transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#1b9ff1"
}, d: "M386.54 190.93h53.74v223.81h-53.74z", transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.2
}, d: "M386.54 190.93h15.18v223.72h-15.18z", transform: "translate(-58.25 -73.454)" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ff8b7b"
}, d: "m174.02 308.52 4.98-14.5-7.08-2.18-4.44 15.15z", transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m175.49 306.77-7.49-1.45a.64.64 0 0 0-.69.4l-2.69 6.3a1.09 1.09 0 0 0 .49 1.44l.21.07c2.72.47 5.17.77 8.55 1.42 4 .77 2.87 1.09 7.55 2.06 2.81.58 4-2.76 2.87-3.16-5.34-1.79-5.55-3.67-7.24-6.18a2.37 2.37 0 0 0-1.56-.9zM135.32 213.49l1.23-20.54 25.81 15.8z", style: {
  fill: "#263238"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m177.92 301.36-9.32-1.67s3.47-12.92 7.23-30.58c.58-2.72 8.07-23.42 8.07-23.42s-37-17.34-37.59-17.53c-.47-.15-3.5-.38-8.23-4.08-8.8-6.88-6-20.34-6-20.34l23 .55c22.45 12 41.94 29.53 44.57 36.71 3.7 10.11-21.73 60.36-21.73 60.36z", style: {
  fill: "#263238"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#ff8b7b"
}, d: "m72.11 295.16 15.06-2.84-1.6-7.23-15.37 3.62z", transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m74.35 295.56-2.46-7.26a.66.66 0 0 0-.69-.41l-6.81.78a1.08 1.08 0 0 0-1 1.14.78.78 0 0 0 0 .21c.94 2.6 1.88 4.88 3 8.14 1.31 3.85.48 3 2 7.58.88 2.74 4.39 2.14 4.16.93-1.08-5.52.45-6.63 1.8-9.34a2.36 2.36 0 0 0 0-1.77zM80.26 295l-3.15-8.92L126 258.83s-3.19-40.71-3.34-41.34c-.15-.63-1.4-3.23-.52-9.18 1.63-11 14.69-15.29 14.69-15.29L148 216.59s-5.19 47.78-10.13 53.61c-6.95 8.21-57.61 24.8-57.61 24.8z", style: {
  fill: "#263238"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.2
}, d: "m144.42 243.6 1.89-15.44-8.23-4.08z", transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M166 164.08c-4.24.91-8.5 1.72-12.76 2.51l-3.2.58-2.85.49a6.77 6.77 0 0 0-3.28 1.74l-4.49 4.6c-1.5 1.56-3 3.12-4.53 4.65-3 3.08-6.1 6.15-9.22 9.17a2.75 2.75 0 0 1-4.16-3.56c2.48-3.55 5-7 7.6-10.52 1.27-1.76 2.57-3.48 3.87-5.2l4-5.27a15.91 15.91 0 0 1 7.53-5.05l3.46-.94 3.12-.82c4.17-1.06 8.33-2.12 12.52-3.08a5.5 5.5 0 0 1 2.39 10.69z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M166 164.08c-4.24.91-8.5 1.72-12.76 2.51l-3.2.58-2.85.49a6.77 6.77 0 0 0-3.28 1.74l-4.49 4.6c-1.5 1.56-3 3.12-4.53 4.65-3 3.08-6.1 6.15-9.22 9.17a2.75 2.75 0 0 1-4.16-3.56c2.48-3.55 5-7 7.6-10.52 1.27-1.76 2.57-3.48 3.87-5.2l4-5.27a15.91 15.91 0 0 1 7.53-5.05l3.46-.94 3.12-.82c4.17-1.06 8.33-2.12 12.52-3.08a5.5 5.5 0 0 1 2.39 10.69z", style: {
  opacity: 0.1
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m120.66 184.2-6.6 2.56 6 5.78 5-4a1.31 1.31 0 0 0 .17-1.9 6.71 6.71 0 0 0-4.57-2.44zM114.06 186.76l-1 1.92a2.23 2.23 0 0 0 .58 2.81l2.12 1.66a2.22 2.22 0 0 0 2.27.29l2-.9z", style: {
  fill: "#ff8b7b"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m84.42 212.93-2.43-.4 1.51-1.83 30.41-10.92c4.84-1.74 8-5 5.5-10.92-2.31-5.5-7.51-7.13-12.34-5.41l-30.41 10.79-2.46-.46 1.53-1.78 30.42-10.77c5.62-2 11-1.54 14.69 7.1 3.69 8.64-.38 11.61-6 13.63zM93.43 179.93l-.67-1.51a6.81 6.81 0 0 0-8.18-3.76l-22.43 6.74 4 9.91z", style: {
  fill: "#263238"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m93.43 179.93-.67-1.51a6.81 6.81 0 0 0-8.18-3.76l-22.43 6.74 4 9.91z", style: {
  opacity: 0.2,
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m85.72 216.49-.93-2.23 29.49-13c4.69-2.06 7.71-5.63 5.23-11.52-2.31-5.51-7.39-6.88-12.08-4.84l-29.49 12.86-.94-2.22 29.49-12.83c5.46-2.37 10.75-2.22 14.39 6.44 3.64 8.66-.23 11.93-5.68 14.33z", style: {
  fill: "#263238"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m82.36 230.42-18.18-43.94 22-9.39a5.43 5.43 0 0 1 7.21 2.84l13.35 31.94a7.32 7.32 0 0 1-3.83 9.44z", style: {
  fill: "#263238"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.3
} }, /* @__PURE__ */ React.createElement("path", { d: "m85.72 216.49-2.37-.28 1.44-2 29.49-13c4.69-2.06 7.71-5.63 5.23-11.52-2.31-5.51-7.39-6.88-12.08-4.84l-29.49 12.91-2.4-.33 1.46-1.89 29.49-12.83c5.46-2.37 10.75-2.22 14.39 6.44 3.64 8.66-.23 11.93-5.68 14.33z", style: {
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" })), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#263238"
}, d: "m89.45 189.16 1.53 3.66-1.37.59-1.52-3.65zM80.59 193.01l1.53 3.67-1.37.6-1.53-3.67zM97.28 207.93l1.53 3.67-1.36.61-1.53-3.68zM88.42 211.83l1.53 3.68-1.37.61-1.53-3.68zM200.47 147.39c.29.14.35.61.13 1-.22.39-.62.68-.9.54s-.34-.61-.13-1.05c.21-.44.62-.64.9-.49z", transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M200.27 147.73a1.6 1.6 0 0 0 1.09-.18 1.43 1.43 0 0 1-1.05-.32.55.55 0 0 1-.04.5z", style: {
  fill: "#263238"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M197.78 151.9a2.81 2.81 0 0 0 2.28.64 17.67 17.67 0 0 1-.25-4.76z", style: {
  fill: "#ff5652"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M198.89 145.15a2.26 2.26 0 0 1 2 .5l.06.07a.29.29 0 0 0 .41-.06.28.28 0 0 0-.06-.4 2.79 2.79 0 0 0-2.49-.7.27.27 0 0 0-.17.1.3.3 0 0 0 0 .41.32.32 0 0 0 .25.08z", style: {
  fill: "#263238"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M178.93 165.59c-6.48-5-4.7-9.28-4.7-9.28 3.39-.36 6.19-3.71 8.19-7.08a9.22 9.22 0 0 0 .47 3.33c.73 2.22 5.34 3.23 5.34 3.23s-.45.57-1 1.42c-1.48 2.09-3.81 5.95-2.42 7.93-.05 0 1.25 5.96-5.88.45z", style: {
  fill: "#ff8b7b"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M187.92 156.39c-.3.24-.6.48-.89.76a.15.15 0 0 1-.1 0 7.45 7.45 0 0 1-4-4.55 9.06 9.06 0 0 1-.47-3.34c.52-.85 1-1.72 1.38-2.52l4 9.34z", style: {
  fill: "#ff8b7b"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M196.66 154.78a8.33 8.33 0 0 1-7.48 3 7 7 0 0 1-2.11-.58.15.15 0 0 1-.1 0 7.45 7.45 0 0 1-4-4.55 9.06 9.06 0 0 1-.47-3.34c.11-2.72 1.4-5.43 3.06-9.38a8.15 8.15 0 0 1 13.68-2.34c4.12 4.63.94 12.93-2.58 17.19z", style: {
  fill: "#ff8b7b"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M188.73 157.6a4.21 4.21 0 0 1-1.55-.39c.6-.85 1.06-1.41 1-1.42l1.23-1.36s-1.02 2.9-.68 3.17z", style: {
  opacity: 0.2
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M201.88 144.69c-1.17.42-3.37-.18-3.16-.32.82-.47 1.81-1.83.72-2.2-.18.72-2.61 5.24-3.31 5.46-.38.13-3 3.16-6.61 4.89-2.83 1.38-3.38 2.07-3.38 2.07l-5-2.61c-1.48-1.5-5.05-14.93 2.52-17.49 2.48-.84 4.38-2.94 8.32-1.81 2.43.7 5.28.27 7.67 1.1a7.83 7.83 0 0 1 5.24 5.07c.27 1.2-.05 4.8-3.01 5.84z", style: {
  fill: "#263238"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M197.32 145.77c1 1.46.17 4-.77 5.32-.94 1.32-2.67 1.59-3.15-.31a6.82 6.82 0 0 1 .39-4.32c.75-1.62 2.49-2.15 3.53-.69z", style: {
  fill: "#ff8b7b"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M170.56 157.17s3.43-2.29 5.7-2.07c2.27.22 8.08 6.44 8.8 7.66.72 1.22-.13 4.43-.13 4.43z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M170.56 157.17s3.43-2.29 5.7-2.07c2.27.22 8.08 6.44 8.8 7.66.72 1.22-.13 4.43-.13 4.43z", style: {
  opacity: 0.3,
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M186.68 167.77a28.39 28.39 0 0 0-2.16-2.38 117.8 117.8 0 0 0-10.72-8.29 29.65 29.65 0 0 0-4.28-2.33 6.77 6.77 0 0 0-8.44 2.34c-5.17 7.64-6.39 13.57-9.83 18.57a97.48 97.48 0 0 1-15.62 17.42l4.28 4.58a62.71 62.71 0 0 0 22.45 11.07s7.79-12.44 12.26-15.61c12.21-8.67 11.48-12.14 13.38-15.89a8.41 8.41 0 0 0-1.32-9.48z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M188 177.26c-.68 1.33-1 2.65-1.75 4.18a21.54 21.54 0 0 1-5.63 7 66.2 66.2 0 0 1-6 4.71 29 29 0 0 0-5 5.3 6.43 6.43 0 0 0-.4 7.35c3.17 5.17 8 12.55 8 12.55-3.44-.9-4.84-1.27-10.31.46-8.56 2.7-26.51.32-31.57-6.91a23.25 23.25 0 0 1 6.22-8.8 47.27 47.27 0 0 0-8.57 7.28c-2.26.47-4.55-.77-6.18-2.38-1.63-1.61-2.85-3.63-4.42-5.3-1.57-1.67-3.29-3.25-5.58-3.05 3-4 8.07-5.64 12.84-7 3.56-1 8.1-2.5 11.5-4.75a89.12 89.12 0 0 0 10.13-12.19c3.43-5 4.64-10.93 9.83-18.58a6.76 6.76 0 0 1 8.44-2.34 29.81 29.81 0 0 1 4.28 2.34 120.27 120.27 0 0 1 10.71 8.27 30.09 30.09 0 0 1 2.16 2.39 8.44 8.44 0 0 1 1.3 9.47z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m235.31 164.21 5.15-2.23a3 3 0 0 0 .6-.35l2.32-1.81a1 1 0 0 0 .35-1.08l-2.35-3.74a1.15 1.15 0 0 0-1.28-.86l-3.48 1.3a1.65 1.65 0 0 0-.68.46l-3.49 3.8a1.27 1.27 0 0 0-.17 1.28l1.54 2.44a1.23 1.23 0 0 0 1.49.79z", style: {
  fill: "#ff8b7b"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "m180.61 188.42 2.22-12.1 4.7 2.53c-1.66 4.39-3.61 6.63-6.92 9.57z", style: {
  opacity: 0.2
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M214.58 180.06c6.42-4.46 14.48-10.76 20.36-15.83.55-1.94-1.28-4.49-3.14-3.71-4.63 1.94-18.46 9.61-20.9 9.68-1.68.05-19-2.45-24.66-3.27a4.63 4.63 0 0 0-3.23.69c-3.13 2.12-3.93 4.44-3.75 6.48a6.64 6.64 0 0 0 6.21 5.86c7.91.59 19.77 1.25 24.39 1.45a7.62 7.62 0 0 0 4.72-1.35z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M192.87 149.63c-1.82 5.23-4.26 15.21-8.27 19-4.01 3.79-9.64 4-15 5.26-1.29.32-2.94 1.95-2.92 1.69a9.71 9.71 0 0 0-2.41 5.32l-5.83-4.55a4.27 4.27 0 0 1-1.4-1.53 3.58 3.58 0 0 1 .65-3.33 18.19 18.19 0 0 0 1.76-3.89 12.44 12.44 0 0 0-4.34 6c-2.84-1-6.16-3.39-6.22-6.41a8.53 8.53 0 0 1 2.71-6.39 10.79 10.79 0 0 0-4.6 4.71c-.61-1.79-2.1-3.57-4-3.51a41 41 0 0 1 5.55-9c2.34-2.63 5.53-4.7 9-4.94 2.68-.19 5.31.68 7.87 1.49 2.56.81 5.22 1.6 7.88 1.26 2.66-.34 5.4-4.42 5.9-7.06 3.8-2.18 9.14 3.18 13.67 5.88z", style: {
  fill: "#263238"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M210.59 144.87A19.62 19.62 0 0 1 190 163.46h-.07a19.43 19.43 0 0 1-2.43-.3 38.29 38.29 0 0 0-14.91-12.5 19.1 19.1 0 0 1-.68-2.3 19.61 19.61 0 1 1 38.68-3.49z", style: {
  opacity: 0.2
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M197.65 162.34a19.61 19.61 0 0 1-7.64 1.12h-.07a19.14 19.14 0 0 1-2.44-.3 38.22 38.22 0 0 0-14.91-12.5 17.44 17.44 0 0 1-.68-2.3 19.6 19.6 0 0 1 14.81-23.63 8.54 8.54 0 0 1 2.94 2.42 16.36 16.36 0 0 1 2.86 7.94 41.18 41.18 0 0 1-.3 12.92 15 15 0 0 0 .82 8.73c1.96 4.44 4.61 5.6 4.61 5.6z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M197.65 162.34a19.61 19.61 0 0 1-7.64 1.12h-.07a19.14 19.14 0 0 1-2.44-.3 38.22 38.22 0 0 0-14.91-12.5 17.44 17.44 0 0 1-.68-2.3 19.6 19.6 0 0 1 14.81-23.63 8.54 8.54 0 0 1 2.94 2.42 16.36 16.36 0 0 1 2.86 7.94 41.18 41.18 0 0 1-.3 12.92 15 15 0 0 0 .82 8.73c1.96 4.44 4.61 5.6 4.61 5.6z", style: {
  opacity: 0.4,
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M189.69 164.9a.89.89 0 0 1-1.28-.09c-.32-.52-.66-1-1-1.54a38.09 38.09 0 0 0-14.72-12.72c-.61-.31-1.22-.59-1.83-.86-.61-.27-.45-1 0-1.41a1 1 0 0 1 1.17 0 .83.83 0 0 1 .33.34 44.72 44.72 0 0 1 17.49 15 1 1 0 0 1-.16 1.28z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M193.8 145.52a1.73 1.73 0 1 1-1.73-1.73 1.73 1.73 0 0 1 1.73 1.73z", style: {
  fill: "#263238"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M194.38 146a1.74 1.74 0 1 1-1.73-1.73 1.74 1.74 0 0 1 1.73 1.73z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-58.25 -73.454)" }), /* @__PURE__ */ React.createElement("path", { d: "M194.38 146a1.74 1.74 0 1 1-1.73-1.73 1.74 1.74 0 0 1 1.73 1.73z", style: {
  opacity: 0.7,
  fill: "#fff"
}, transform: "translate(-58.25 -73.454)" })));
export default SvgStartTrial;
