import { Button, InputBlock, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Row, ValidationField, Variant } from '@convoflo/ui';
import useClient from '@hooks/useClient';
import { type FC, type FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type DialogAccountPasswordProps = Omit<ModalProps, 'isOpen'> & {
	onAccountPasswordSubmitted: (accountPassword: string) => void;
};

const DialogAccountPassword: FC<DialogAccountPasswordProps> = ({ onAccountPasswordSubmitted = () => undefined, ...modalProps }) => {
	const { formatMessage } = useIntl();
	const { validation } = useClient();

	const [isOpen, setIsOpen] = useState(true);
	const [password, setPassword] = useState<string>('');

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		onAccountPasswordSubmitted(password);
	};

	return (
		<Modal onSubmit={onSubmit} isOpen={isOpen} {...modalProps}>
			<ModalHeaderOnlyTitle icon="lock">
				<FormattedMessage id="dialog-account-password.title" />
			</ModalHeaderOnlyTitle>

			<ModalBody>
				<Row>
					<Label htmlFor="password">
						<FormattedMessage id="dialog-account-password.content" />
					</Label>
					<ValidationField validation={validation} fieldName="account_password">
						<InputBlock
							autoFocus
							id="password"
							value={password}
							onChange={e => setPassword(e.target.value)}
							type="password"
							placeholder={formatMessage({ id: 'dialog-account-password.placeholder-account-password' })}
						/>
					</ValidationField>
				</Row>
			</ModalBody>

			<ModalFooter>
				<Button variant={Variant.danger} disabled={password.length === 0}>
					<FormattedMessage id="dialog-account-password.confirm" />
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} type="button" onClick={() => setIsOpen(false)}>
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default DialogAccountPassword;
