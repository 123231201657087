import AppIcon from '@assets/images/Icon.svg?react';
import untypedNotifications from '@assets/notification-types.json';
import { DateTimeDisplay } from '@components/DateTime';
import type { ScheduledMessageType } from '@components/FileRequest';
import type { Comment, FileRequestType } from '@components/Message';
import { NotificationItemDetails, type NotificationKind, type NotificationType } from '@components/Notification';
import type { PaymentRequest } from '@components/PaymentRequest';
import { Button, Size, Variant } from '@convoflo/ui';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import File from '@models/File';
import Folder from '@models/Folder';
import SignRequest from '@models/SignRequest';
import { useMarkNotificationAsReadMutation } from '@state/queries/notifications';
import { Tooltip } from '@ui/Tooltip';
import UserAvatar from '@ui/UserAvatar';
import { formatDate } from '@utils/DateUtils';
import classNames from 'classnames';
import { type FC, type MouseEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { defaultCurrency } from '../../constants';

const notificationTypes: Record<string, NotificationKind> = untypedNotifications;

type NotificationItemProps = {
	notification: NotificationType;
	source?: string;
};

export const NotificationItem: FC<NotificationItemProps> = ({ notification, source }) => {
	const { formatMessage, formatNumber, locale } = useIntl();
	const { account } = useAccount();
	const { mutateAsync: markNotificationAsRead } = useMarkNotificationAsReadMutation();

	let detailed = true;

	const [isRead, setIsRead] = useState(notification.items.every(item => item.read_at !== null));
	const markAsRead = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		event.preventDefault();
		markNotificationAsRead({ notificationIds: [String(notification.Id)], read: true });
		setIsRead(true);
	};

	const markAsUnread = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		event.preventDefault();
		markNotificationAsRead({ notificationIds: [String(notification.Id)], read: false });
		setIsRead(false);
	};

	let prioritize = false;
	let values: Record<string, any> = {};
	let link: string | null = null;
	let externalLink: string | null = null;
	let preview: string | null = null;
	let icon = (
		<FontAwesomeIcon
			icon={notificationTypes[notification.Type].icon as IconProp}
			mask="circle"
			transform="shrink-8"
			className={classNames(notificationTypes[notification.Type].className)}
			style={!notificationTypes[notification.Type].className ? { color: notificationTypes[notification.Type].color } : {}}
		/>
	);

	const Names = (maxNames = 4, signRequest: SignRequest) => {
		const names = signRequest.roles.map(role => role.signer.Name);

		if (names.length === 0) {
			return null;
		}

		if (names.length === 1) {
			return <span className="font-semibold">{names[0]}</span>;
		}

		if (names.length <= maxNames) {
			return (
				<FormattedMessage
					id="alerts.list_complete"
					values={{
						1: names.slice(0, -1).join(', '),
						2: names.slice(-1)[0],
						strong: msg => <span className="font-semibold">{msg}</span>
					}}
				/>
			);
		}

		return (
			<FormattedMessage
				id="alerts.list_and_others"
				values={{
					1: names.slice(0, maxNames).join(', '),
					2: names.length - maxNames,
					strong: msg => <span className="font-semibold">{msg}</span>
				}}
			/>
		);
	};

	switch (notification.Type) {
		case 'payment-request.created': {
			let paymentRequest = notification.items.find(item => item.object['@type'] === 'PaymentRequest')?.object as PaymentRequest | undefined;
			if (!paymentRequest) return null;

			values.user = notification.user.Name;
			values.amount = formatNumber(paymentRequest.Amount, { currency: defaultCurrency, style: 'currency' });

			const message = notification.items.find(item => item.object['@type'] === 'Comment')?.object as Comment | undefined;

			if (message) {
				link = `${notification.container.Link}/comments?comment=${message.ID}`;
			} else {
				link = `${notification.container.Link}/payments?id=${paymentRequest.Id}`;
			}
			break;
		}

		case 'payment-request.payed': {
			let paymentRequest = notification.items.find(item => item.object['@type'] === 'PaymentRequest')?.object as PaymentRequest | undefined;
			if (!paymentRequest) return null;

			values.creator = paymentRequest.creator.Name;
			values.amount = formatNumber(paymentRequest.Amount, { currency: defaultCurrency, style: 'currency' });
			values.payedAt = formatDate(paymentRequest.payed_at, locale);
			values.createdAt = formatDate(paymentRequest.created_at, locale);
			break;
		}

		case 'payment-request.received': {
			let paymentRequest = notification.items.find(item => item.object['@type'] === 'PaymentRequest')?.object as PaymentRequest | undefined;
			if (!paymentRequest) return null;

			values.payer = paymentRequest.payed_by?.Name ?? '-';
			values.amount = formatNumber(paymentRequest.Amount, { currency: defaultCurrency, style: 'currency' });
			break;
		}

		case 'payment-request.refunded': {
			let paymentRequest = notification.items.find(item => item.object['@type'] === 'PaymentRequest')?.object as PaymentRequest | undefined;
			if (!paymentRequest) return null;

			values.creator = paymentRequest.creator.Name;
			values.createdAt = formatDate(paymentRequest.created_at, locale);
			values.amount = formatNumber(paymentRequest.Amount, { currency: defaultCurrency, style: 'currency' });
			break;
		}

		case 'file-request.created': {
			let fileRequest = notification.items.find(item => item.object['@type'] === 'FileRequest')?.object as FileRequestType | undefined;
			if (!fileRequest) return null;

			values.user = notification.user.Name;
			values.fileable = notification.container['@type'] === 'Document' ? notification.container.current.Name : notification.container.Name;

			const message = fileRequest.attachment?.model as Comment | undefined;
			if (!message) {
				return null;
			}

			link = `${notification.container.Link}/comments?comment=${message.ID}&frid=${fileRequest.Id}`;
			break;
		}

		case 'file-request.completed': {
			let fileRequest = notification.items.find(item => item.object['@type'] === 'FileRequest')?.object as FileRequestType | undefined;
			if (!fileRequest) return null;

			values.fileable = notification.container['@type'] === 'Document' ? notification.container.current.Name : notification.container.Name;
			values.count = fileRequest.files.length;

			const message = fileRequest.attachment?.model as Comment | undefined;
			if (!message) {
				return null;
			}

			link = `${notification.container.Link}/comments?comment=${message.ID}&frid=${fileRequest.Id}`;

			break;
		}

		case 'download.ready':
			externalLink = notification.Params.url;
			break;

		case 'comment.created': {
			let message = notification.items.find(item => item.object['@type'] === 'Comment')?.object as Comment | undefined;
			if (!message) return null;

			values.attachments = message.attachments_count;
			values.user = notification.user.Name;
			values.item = notification.container['@type'] === 'Document' ? notification.container.current.Name : notification.container.Name;
			values.source = message.Source ?? 'other';
			preview = message.Title;
			link = `${notification.container.Link}?comment=${message.ID}`;
			break;
		}

		case 'user.mentioned': {
			let message = notification.items.find(item => item.object['@type'] === 'Comment')?.object as Comment | undefined;
			if (!message) return null;

			values.user = notification.user.Name;
			values.fileable = notification.container['@type'] === 'Document' ? notification.container.current.Name : notification.container.Name;
			preview = message.Title;
			link = `${notification.container.Link}?comment=${message.ID}`;
			prioritize = true;
			break;
		}

		case 'comment.liked': {
			let message = notification.items.find(item => item.object['@type'] === 'Comment')?.object as Comment | undefined;
			if (!message) return null;

			values.user = notification.user.Name;
			values.item = notification.container['@type'] === 'Document' ? notification.container.current.Name : notification.container.Name;
			link = `${notification.container.Link}?comment=${message.ID}`;
			break;
		}

		case 'comment.read': {
			let message = notification.items.find(item => item.object['@type'] === 'Comment')?.object as Comment | undefined;
			if (!message) return null;

			values.user = notification.user.Name;
			values.item = notification.container['@type'] === 'Document' ? notification.container.current.Name : notification.container.Name;
			link = `${notification.container.Link}?comment=${message.ID}`;
			break;
		}

		case 'comment.replied': {
			let message = notification.items.find(item => item.object['@type'] === 'Comment')?.object as Comment | undefined;
			if (!message) return null;

			values.attachments = message.attachments_count;
			values.user = notification.user.Name;
			values.item = notification.container['@type'] === 'Document' ? notification.container.current.Name : notification.container.Name;
			preview = message.Title;
			link = `${notification.container.Link}?comment=${message.ID}`;
			break;
		}

		case 'email.bounced':
			values.email = notification.Params.Email;
			break;

		case 'file.avscanned': {
			let file = notification.items.find(item => item.object['@type'] === 'Document')?.object as File | undefined;
			if (!file) return null;

			values.file = file.current.Name;
			link = file.Link;
			break;
		}

		case 'file.branched': {
			let file = notification.items.find(item => item.object['@type'] === 'Document')?.object as File | undefined;
			if (!file) return null;

			values.user = notification.user.Name;
			values.file = file.current.Name;
			link = file.Link;
			break;
		}

		case 'file.downloaded': {
			let file = notification.items.find(item => item.object['@type'] === 'Document')?.object as File | undefined;
			if (!file) return null;

			values.user = notification.user?.Name || formatMessage({ id: 'alerts.generic_user' });
			values.file = file.current.Name;
			link = file.Link;
			break;
		}

		case 'file.requested': {
			let fileRequest = notification.items.find(item => item.object['@type'] === 'FileRequest')?.object as ScheduledMessageType | undefined;
			if (!fileRequest) return null;

			values.user = notification.user.Name;
			values.folder = notification.container.Name;
			link = `${notification.container.Link}?fr=${fileRequest.Id}`;
			break;
		}

		case 'file.saved': {
			let file = notification.items.find(item => item.object['@type'] === 'Document')?.object as File | undefined;
			if (!file) return null;

			values.user = notification.user.Name;
			values.file = file.current.Name;
			link = file.Link;
			break;
		}

		case 'file.users.added': {
			let file = notification.items.find(item => item.object['@type'] === 'Document')?.object as File | undefined;
			if (!file) return null;

			values.user = notification.user.Name;
			values.file = file.current.Name;
			link = file.Link;
			break;
		}

		case 'file.viewed': {
			let file = notification.items.find(item => item.object['@type'] === 'Document')?.object as File | undefined;
			if (!file) return null;

			values.user = notification.user?.Name || formatMessage({ id: 'alerts.generic_user' });
			values.file = file.current.Name;
			link = file.Link;
			break;
		}

		case 'file.inboxed': {
			let file = notification.items.find(item => item.object['@type'] === 'Document')?.object as File | undefined;
			if (!file) return null;

			values.n = notification.items.length;
			values.file = file.current.Name;
			link = file.Link;

			if (notification.items.length === 1) {
				detailed = false;
			}

			if (notification.items.length > 1) {
				link = null;
			}

			break;
		}

		case 'folder.items.added': {
			let file = notification.items.find(item => item.object['@type'] === 'Document')?.object as File | undefined;
			let folder = notification.items.find(item => item.object['@type'] === 'Folder')?.object as Folder | undefined;

			let type = notification.items.every(item => item.object['@type'] === 'Document') ? 'file' : notification.items.every(item => item.object['@type'] === 'Folder') ? 'folder' : 'item';
			icon = type === 'folder' ? <FontAwesomeIcon icon={'folder'} mask="circle" transform="shrink-8" style={{ color: notificationTypes[notification.Type].color }} /> : icon;

			values.user = notification.user.Name;
			values.n = notification.items.length;
			values.folder = notification.container.Name;
			values.type = type;

			if (folder) {
				values.file = folder.Name;
				link = `${notification.container.Link}${notification.items.length === 1 ? `/files?folder=${folder.ID}` : ''}`;
			} else if (file) {
				values.file = file.current.Name;
				link = `${notification.container.Link}${notification.items.length === 1 ? `/files?file=${file.Id}` : ''}`;
			}

			if (notification.items.length === 1) {
				detailed = false;
			}

			if (notification.items.length > 1) {
				link = null;
			}

			break;
		}

		case 'folder.users.added': {
			let folder = notification.items.find(item => item.object['@type'] === 'Folder')?.object as Folder | undefined;
			if (!folder) return null;

			values.user = notification.user.Name;
			values.folder = folder.Name;
			link = folder.Link;
			break;
		}

		case 'note.created':
		case 'note.read':
		case 'payment.charged':
			// Unsupported for now
			return null;

		case 'secure-space.activated': {
			let folder = notification.items.find(item => item.object['@type'] === 'Folder')?.object as Folder | undefined;
			if (!folder) return null;

			values.user = notification.user.Name;
			values.secureSpace = folder.Name;
			link = folder.Link;
			break;
		}

		case 'secure-space.activation.confirmed': {
			let folder = notification.items.find(item => item.object['@type'] === 'Folder')?.object as Folder | undefined;
			if (!folder) return null;

			values.user = notification.user.Name;
			values.secureSpace = folder.Name;
			link = folder.Link;
			break;
		}

		case 'secure-space.shared': {
			let folder = notification.items.find(item => item.object['@type'] === 'Folder')?.object as Folder | undefined;
			if (!folder) return null;

			values.user = notification.user.Name;
			values.secureSpace = folder.Name;
			link = folder.Link;
			break;
		}

		case 'sign-request.created': {
			let signRequest = notification.items.find(item => item.object['@type'] === 'SignRequest')?.object as SignRequest | undefined;
			if (!signRequest) return null;

			if (signRequest.documents.length === 1) {
				values.file = signRequest.documents[0].current.Name;
			} else {
				values.file = '';
			}
			values.user = notification.user.Name;
			values.n = signRequest.documents.length;
			link = signRequest.Link;
			break;
		}

		case 'sign-request.completed': {
			let signRequest = notification.items.find(item => item.object['@type'] === 'SignRequest')?.object as SignRequest | undefined;
			if (!signRequest) return null;

			if (signRequest.documents.length === 1) {
				values.file = signRequest.documents[0].current.Name;
			} else {
				values.file = '';
			}
			values.user = notification.user.Name;
			values.n = signRequest.documents.length;
			link = signRequest.documents.length === 1 ? signRequest.documents[0].Link : notification.container.Link + '/files';

			values.isCreator = false;
			if (account && account.ID === notification.user.ID) {
				values.isCreator = true;
			}

			values.names = Names(4, signRequest);

			break;
		}

		case 'sign-request.reminded': {
			let signRequest = notification.items.find(item => item.object['@type'] === 'SignRequest')?.object as SignRequest | undefined;
			if (!signRequest) return null;

			if (signRequest.documents.length === 1) {
				values.file = signRequest.documents[0].current.Name;
			} else {
				values.file = '';
			}
			values.user = notification.user.Name;
			values.n = signRequest.documents.length;
			link = signRequest.Link;
			break;
		}

		default:
			break;
	}

	if (link !== null) {
		link += link.indexOf('?') > 0 ? '&' : '?';
		link += `ref=notif&_nid=${notification.Id}`;

		if (source) {
			link += `&source=${source}`;
		}
	}

	if (prioritize) {
		icon = <FontAwesomeIcon icon={notificationTypes[notification.Type].icon as IconProp} mask="circle" transform="shrink-8" className="text-red-500" />;
	}

	const child = (
		<div className="relative flex border-l-2 border-transparent">
			<div className="relative w-8 h-8 mr-2">
				{!!notification.user && <UserAvatar user={notification.user} size={Size.sm} />}
				{!notification.user && (
					<div className="w-8 h-8 p-1.5 bg-theme-primary rounded-full">
						<AppIcon className="w-full h-full text-white -translate-x-0.5" />
					</div>
				)}
				<div className="absolute flex items-center justify-center w-5 h-5 leading-4 bg-white rounded-full -bottom-1 -right-1">{icon}</div>
			</div>
			<div className="flex-1 min-w-0">
				<p className="mb-1 text-sm leading-snug">
					<FormattedMessage id={`alerts.${notification.Type}`} values={{ ...values, strong: msg => <span className="font-semibold">{msg}</span> }} />
				</p>
				{preview !== null && (
					<div className="p-2 mb-2 rounded-sm bg-black/5">
						<p className="text-xs italic text-black/60">
							<FormattedMessage id="alerts.subject" /> {preview}
						</p>
					</div>
				)}
				{detailed &&
					['folder.items.added', 'file.inboxed'].includes(notification.Type) &&
					notification.items.map(item => <NotificationItemDetails key={item.Id} notification={notification} item={item} source={source} />)}
				<p className="text-xs text-black text-opacity-50">
					<DateTimeDisplay defaultFormat="relative" value={notification.created_at} />
				</p>
			</div>
			{!isRead && (
				<Tooltip tip={<FormattedMessage id="alerts.read.tooltip" />}>
					<Button
						type="button"
						size={Size.sm}
						variant={Variant.light}
						onClick={markAsRead}
						circle
						shadow
						className="absolute top-0 bottom-0 invisible my-auto transition-opacity opacity-0 right-4 group-hover:opacity-100 group-hover:visible">
						<FontAwesomeIcon icon="check" fixedWidth />
					</Button>
				</Tooltip>
			)}
			{isRead && (
				<Tooltip tip={<FormattedMessage id="alerts.unread.tooltip" />}>
					<Button
						type="button"
						size={Size.sm}
						variant={Variant.light}
						onClick={markAsUnread}
						circle
						shadow
						className="absolute top-0 bottom-0 invisible my-auto transition-opacity opacity-0 right-4 group-hover:opacity-100 group-hover:visible">
						<FontAwesomeIcon icon="eye-slash" fixedWidth />
					</Button>
				</Tooltip>
			)}
		</div>
	);

	if (link !== null) {
		return (
			<Link
				to={link}
				className={`group border-l-4 border-transparent ${prioritize ? 'border-red-500' : ''} block p-3 transition-colors ease-in-out duration-150 ${
					!isRead ? 'bg-yellow-50 hover:bg-yellow-100' : 'hover:bg-gray-100'
				}`}>
				{child}
			</Link>
		);
	}

	if (externalLink !== null) {
		return (
			<a
				href={externalLink}
				target="_blank"
				rel="noreferrer"
				className={`group block p-3 transition-colors ease-in-out duration-150 ${!isRead ? 'bg-yellow-50 hover:bg-yellow-100' : 'hover:bg-gray-100'}`}>
				{child}
			</a>
		);
	}

	return <div className={`group p-3 transition-colors ease-in-out duration-150 ${!isRead ? 'bg-yellow-50 hover:bg-yellow-100' : ''}`}>{child}</div>;
};
