import untypedDefaultRules from '@assets/default-rules.json';
import { LabelSelector, type LabelType, useLabels } from '@components/Labels';
import { Alert, Button, Input, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, ModalSize, Select, Size, Toggle, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import { useOrganizationUpdateMutation } from '@state/queries/organization';
import Card from '@ui/Card';
import { TranslatableInput } from '@ui/TranslatableInput';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import omit from 'lodash.omit';
import uniqBy from 'lodash.uniqby';
import { type FC, type FormEvent, type PropsWithChildren, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';

const RULE_SET = untypedDefaultRules;

export const OrganizationRules: FC = () => {
	const { account } = useAccount();

	const [defaults, setDefaults] = useState(account?.business.Defaults ?? {});
	const [showOverrideModal, setShowOverrideModal] = useState(false);

	const { mutateAsync: save, isLoading: isSaving } = useOrganizationUpdateMutation();

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		setShowOverrideModal(true);
	};

	const onConfirmationSubmit = async (applyToAll: boolean) => {
		try {
			setShowOverrideModal(false);
			await save({ defaults, applyToAll });
			toast.success(<FormattedMessage id="organization-settings.saved" />);
		} catch {
			// TODO: Show error to user
		}
	};

	return (
		<>
			<Card onSubmit={onSubmit}>
				<p className="mb-6 text-sm text-gray-600">
					<FormattedMessage id="rules.intro" />
				</p>
				<Alert variant={Variant.info} className="my-6">
					<p>
						<FormattedMessage id="rules.automation_tip" />
					</p>
				</Alert>
				<div className="space-y-8">
					<RuleCategory kind="secureSpace" values={defaults.secureSpace ?? {}} onUpdated={rules => setDefaults({ ...defaults, secureSpace: rules })}>
						<FontAwesomeIcon icon="shield" className="mr-2 text-green-500" />
						<FormattedMessage id="rules.new_secure_space" />
					</RuleCategory>
					<RuleCategory kind="message" values={defaults.message ?? {}} onUpdated={rules => setDefaults({ ...defaults, message: rules })}>
						<FontAwesomeIcon icon="comment-alt" className="mr-2 text-blue-500" />
						<FormattedMessage id="rules.new_message" />
					</RuleCategory>
					<RuleCategory kind="file" values={defaults.file ?? {}} onUpdated={rules => setDefaults({ ...defaults, file: rules })}>
						<FontAwesomeIcon icon="file" className="mr-2 text-purple-700" />
						<FormattedMessage id="rules.new_file" />
					</RuleCategory>
				</div>
				<div className="mt-6">
					<Button variant={Variant.primary} type="submit" disabled={isSaving} loading={isSaving} icon="save">
						<FormattedMessage id="save" />
					</Button>
				</div>
			</Card>
			{showOverrideModal && (
				<Modal isOpen={true} size={ModalSize.XSmall} closeable={true} onAfterClose={() => setShowOverrideModal(false)}>
					<ModalHeaderOnlyTitle>
						<FormattedMessage id="rules.override.title" />
					</ModalHeaderOnlyTitle>
					<ModalBody className="prose-sm prose max-w-none">
						<p>
							<FormattedMessage id="rules.override.body" />
						</p>
					</ModalBody>
					<ModalFooter>
						<Button variant={Variant.dark} intent={Intent.primary} type="button" loading={isSaving} disabled={isSaving} onClick={() => onConfirmationSubmit(true)}>
							<FormattedMessage id="yes" />
						</Button>
						<Button variant={Variant.dark} intent={Intent.secondary} type="button" loading={isSaving} disabled={isSaving} onClick={() => onConfirmationSubmit(false)}>
							<FormattedMessage id="no" />
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</>
	);
};

/**
 * @author ChatGPT
 * @param obj
 * @returns
 */
const getRuleKeys = (obj: Record<string, any>) => {
	let result = [];

	for (let key in obj) {
		if (obj.hasOwnProperty(key)) {
			if (obj[key].type) {
				result.push(key);
			} else {
				let nestedKeys = getRuleKeys(obj[key]);
				nestedKeys = nestedKeys.map(nestedKey => `${key}.${nestedKey}`);
				result.push(...nestedKeys);
			}
		}
	}

	return result;
};

/**
 * @author ChatGPT
 * @param obj
 * @returns
 */
function flattenObjectKeys(obj: any) {
	let result = {};

	function flatten(obj: any, parentKey: string = '') {
		Object.keys(obj).forEach(key => {
			let value = obj[key];
			let newKey = parentKey ? `${parentKey}.${key}` : key;

			if (value && typeof value === 'object' && !Array.isArray(value)) {
				flatten(value, newKey);
			} else {
				result[newKey] = value;
			}
		});
	}

	flatten(obj);
	return result;
}

const expandObjectKeys = (obj: Record<string, any>) => {
	let result: Record<string, any> = {};

	Object.keys(obj).forEach(key => {
		let value = obj[key];
		let current = result;

		key.split('.').forEach((part, i, parts) => {
			if (i === parts.length - 1) {
				current[part] = value;
			} else {
				current[part] = current[part] || {};
				current = current[part];
			}
		});
	});

	return result;
};

type RuleCategoryProps = PropsWithChildren<{
	kind: string;
	values: Record<string, any>;
	onUpdated?: (rules: Record<string, any>) => void;
}>;

const RuleCategory: FC<RuleCategoryProps> = ({ kind, values, children, onUpdated = () => undefined }) => {
	const [selectedProp, setSelectedProp] = useState('');
	const [rules, setRules] = useState<Record<string, any>>({});

	const createRule = (propName: string) => {
		if (!propName) return;
		setSelectedProp('');

		const _rules = { ...rules, [propName]: RULE_SET[kind][propName]?.default ?? null };
		setRules(_rules);
		onUpdated(expandObjectKeys(_rules));
	};

	const updateRule = (propName: string, value: any) => {
		const _rules = rules;
		_rules[propName] = value;
		setRules(_rules);
		onUpdated(expandObjectKeys(_rules));
	};

	const removeRule = (propName: string) => {
		const _rules = omit(rules, propName);
		setRules(_rules);
		onUpdated(expandObjectKeys(_rules));
	};

	const empty = isEmpty(rules);

	useEffect(() => {
		setRules(flattenObjectKeys(values));
	}, [values]);

	const PropDropdown = (() => {
		return (
			<Select size={Size.sm} value={selectedProp} onChange={e => createRule(e.target.value)}>
				<FormattedMessage id="rules.add_rule">{msg => <option value="">{msg}</option>}</FormattedMessage>
				{Object.entries(getRuleKeys(RULE_SET[kind])).map(([, value]) => (
					<FormattedMessage id={get(RULE_SET[kind], `${value}.labelLanguageId`) as unknown as string}>
						{msg => (
							<option disabled={rules.hasOwnProperty(value)} value={value as string}>
								{msg}
							</option>
						)}
					</FormattedMessage>
				))}
			</Select>
		);
	})();

	return (
		<div className="overflow-hidden border rounded-md">
			<header className="border-b border-gray-100">
				<h4 className="px-3 py-2 text-sm font-medium text-gray-600 bg-gray-50">{children}</h4>
			</header>
			<div className="divide-y divide-gray-50">
				{empty && (
					<div className="flex flex-col items-center my-6">
						<p className="mb-3 text-lg italic text-gray-500">
							<FormattedMessage id="rules.no_rules" />
						</p>
						{PropDropdown}
					</div>
				)}
				{Object.entries(rules).map(([propName, value]) => (
					<RuleItem key={propName} type={kind} propName={propName} value={value} onChange={value => updateRule(propName, value)} onRemove={() => removeRule(propName)} />
				))}
			</div>
			{!empty && <footer className="px-3 py-2 border-t border-gray-100">{PropDropdown}</footer>}
		</div>
	);
};

type RuleItemProps = {
	type: string;
	propName: string;
	value: any;
	onChange?: (value: any) => void;
	onRemove?: () => void;
};

const RuleItem: FC<RuleItemProps> = ({ type, propName, value: initialValue, onChange = () => undefined, onRemove = () => undefined }) => {
	const properties = RULE_SET[type];
	const property = get(properties, ['defaults', propName], get(properties, propName));
	const { formatMessage } = useIntl();
	const { props = [] } = useLabels();

	const [value, setValue] = useState<any>(initialValue ?? (property.type.endsWith('[]') ? [] : null));

	useEffect(() => {
		if (property.type === 'label[]') {
			onChange((value as LabelType[]).map(prop => prop.Id));
			return;
		}

		if (property.type === 'string' && property.translatable) {
			onChange(typeof value === 'object' ? JSON.stringify(value) : value);
			return;
		}

		onChange(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	// Just for props
	useEffect(() => {
		if (property.type === 'string' && property.translatable) {
			setValue(typeof value === 'string' ? JSON.parse(value) : value);
			return;
		}

		if (!props || props.length === 0 || !value || property.type !== 'label[]') {
			return;
		}

		if (property.type === 'label[]' && typeof value[0] !== 'string') {
			return;
		}

		const _labels: LabelType[] = [];

		props.forEach(prop => {
			prop.options?.forEach(option => {
				if (value.some((prop: string) => prop === option.Id)) {
					_labels.push(option);
				}
			});
		});

		setValue(_labels);
	}, [props, value, property.type]);

	if (!property) {
		return null;
	}

	return (
		<>
			<div className="flex items-baseline">
				<div className="flex flex-col flex-1">
					<div className="inline-flex items-baseline p-3 space-x-2">
						<span className="text-sm text-gray-500">
							<FormattedMessage id="rules.set" />
						</span>
						<span className="flex-1 px-2 py-1 mx-1 text-sm text-left border-0 border-b-2 border-gray-200 rounded bg-gray-50 focus-within:border-blue-500 focus:ring-0">
							<FormattedMessage id={property.labelLanguageId ?? `rules.type.${propName}`} />
						</span>

						<span className="text-sm text-gray-500">
							<FormattedMessage id="rules.to" />
						</span>

						<span className="flex-1">
							{property.type === 'string' && !property.translatable && (
								<Input
									size={Size.sm}
									type="text"
									placeholder={property.placeholderLanguageId ? formatMessage({ id: property.placeholderLanguageId }) : undefined}
									value={value ?? ''}
									onChange={e => setValue(e.target.value)}
								/>
							)}

							{property.type === 'label[]' && (
								<div className="flex items-center gap-0.5 flex-wrap flex-1 px-2 py-1 mx-1 text-sm text-center border-0 border-b-2 border-gray-200 rounded bg-gray-50 focus-within:border-blue-500 focus:ring-0">
									{value
										.filter((_property: any) => typeof _property !== 'string')
										.map((_label: LabelType) => (
											<LabelSelector
												key={_label.Id}
												label={_label}
												onChange={prop => setValue((props: LabelType[]) => props.map(_prop => (_prop.Id === _label.Id ? prop : _prop)))}
												onRemove={prop => setValue((props: LabelType[]) => props.filter(_prop => _prop.Id !== prop.Id))}
											/>
										))}
									<LabelSelector key={value.length} onChange={_property => setValue((props: LabelType[]) => uniqBy(props.concat(_property), (prop: LabelType) => prop.Id))} />
								</div>
							)}

							{property.type === 'string' && property.translatable && (
								<div className="inline-flex flex-col flex-1">
									<TranslatableInput
										block
										size={Size.sm}
										type="text"
										placeholder={property.placeholderLanguageId ? formatMessage({ id: property.placeholderLanguageId }) : undefined}
										values={value}
										onChange={(e, locale) => setValue({ ...value, [locale]: e.target.value })}
									/>
								</div>
							)}

							{property.type === 'number' && (
								<Input size={Size.sm} type="number" min={property.min} max={property.max} value={parseInt(value)} onChange={e => setValue(e.target.valueAsNumber)} />
							)}

							{property.type === 'bool' && <Toggle className="" size={Size.sm} checked={!!value} onChange={e => setValue(e.target.checked)} />}

							{property.type === 'enum' && (
								<select
									value={value}
									onChange={e => setValue(e.target.value)}
									className="px-2 py-1 pr-8 mx-1 text-sm text-left border-0 border-b-2 border-gray-200 rounded bg-gray-50 focus-within:border-blue-500 focus:ring-0">
									<FormattedMessage id="rules.select">{msg => <option value="">{msg}</option>}</FormattedMessage>
									{Object.entries(property.choices ?? {}).map(([key, label]) => (
										<option value={key}>{label}</option>
									))}
								</select>
							)}

							{!!property?.suffixLanguageId && (
								<span className="text-sm text-gray-500">
									<FormattedMessage id={property.suffixLanguageId} />
								</span>
							)}
						</span>
					</div>

					{!!property?.descriptionLanguageId && (
						<div className="px-3 pb-3">
							<p className="text-xs text-gray-500">
								<FormattedMessage id={property.descriptionLanguageId} />
							</p>
						</div>
					)}
				</div>

				<div className="flex items-center px-3">
					<Button variant={Variant.danger} intent={Intent.secondary} size={Size.xs} icon="times" onClick={() => onRemove()} type="button">
						<FormattedMessage id="rules.remove" />
					</Button>
				</div>
			</div>
		</>
	);
};
