import { useUnitDetails } from '@components/Lecsor';
import { Button, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Folder from '@models/Folder';
import classNames from 'classnames';
import { type FC, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { LecsorUnitTransactions } from './LecsorUnitTransactions';

type LecsorUnitDetailsProps = {
	secureSpace: Folder;
};

export const LecsorUnitDetails: FC<LecsorUnitDetailsProps> = ({ secureSpace }) => {
	const associationId = (secureSpace.meta ?? []).find(({ key }) => key === 'lecsor:association')?.value;
	const accountId = (secureSpace.meta ?? []).find(({ key }) => key === 'lecsor:account')?.value;

	const [showDialog, setShowDialog] = useState(false);
	const [showTransactionDetailsDialog, setshowTransactionDetailsDialog] = useState(false);
	const { details, isLoading } = useUnitDetails(showDialog ? associationId : undefined, showDialog ? accountId : undefined);

	if (!associationId || !accountId) {
		return null;
	}

	const balance = details?.account_balance ?? 0;

	const balanceColorClassName = classNames('font-semibold', {
		'text-green-600': balance <= 0,
		'text-red-600': balance > 0
	});

	return (
		<>
			<Button variant={Variant.secondary} intent={Intent.primary} onClick={() => setShowDialog(true)} shadow icon="file-user">
				<FormattedMessage id="lecsor.unit_details" />
			</Button>
			<Modal isOpen={showDialog} closeable onAfterClose={() => setShowDialog(false)}>
				<ModalHeaderOnlyTitle>
					<FormattedMessage id="lecsor.unit_details" />
				</ModalHeaderOnlyTitle>
				<ModalBody className="!p-0 !pt-6">
					{isLoading && (
						<div className="grid my-8 place-items-center">
							<FontAwesomeIcon size="5x" icon="spinner" pulse className="text-gray-500" />
						</div>
					)}
					{details && (
						<div className="border-t border-t-100">
							<div className="px-8 py-3 space-y-1 border-b border-b-gray-100 md:grid md:grid-cols-3 hover:bg-gray-50 md:space-y-0">
								<div>
									<p className="text-gray-600">
										<FormattedMessage id="lecsor.account_balance" />
									</p>
								</div>
								<div className="col-span-2">
									<div className="flex items-center justify-between gap-3">
										<span className={balanceColorClassName}>
											<FormattedNumber value={balance} style="currency" currency="cad" />
										</span>
										<button type="button" className="ml-2 text-sm underline text-theme-primary" onClick={() => setshowTransactionDetailsDialog(true)}>
											<FormattedMessage id="details" />
										</button>
									</div>
								</div>
							</div>
							<div className="px-8 py-3 space-y-1 border-b md:grid md:grid-cols-3 hover:bg-gray-50 md:space-y-0">
								<div>
									<p className="text-gray-600">
										<FormattedMessage id="lecsor.share_percentage" />
									</p>
								</div>
								<div className="col-span-2">
									<p>
										<FormattedNumber value={details.share_percentage / 100} style="percent" maximumFractionDigits={3} />
									</p>
								</div>
							</div>
						</div>
					)}
				</ModalBody>
				<ModalFooter>
					<Button type="button" variant={Variant.light} intent={Intent.secondary} onClick={() => setShowDialog(false)}>
						<FormattedMessage id="close" />
					</Button>
				</ModalFooter>
			</Modal>
			{showTransactionDetailsDialog && <LecsorUnitTransactions secureSpace={secureSpace} closeable={true} onAfterClose={() => setshowTransactionDetailsDialog(false)} />}
		</>
	);
};
