import { Button, Size, Variant } from '@convoflo/ui';
import { useAnnouncements } from '@components/Announcement';
import { FormattedMessage } from 'react-intl';

export const SidebarAnnouncements = () => {
	const { announcements, dismiss } = useAnnouncements('sidebar', { hideOnDismiss: true });

	if (!announcements || announcements.length === 0) {
		return null;
	}

	const announcement = announcements[0];

	if (!announcement) {
		return null;
	}

	return (
		<div className="px-3 py-2 text-white border rounded-md border-white/40 bg-white/25 ring ring-white/10">
			<h4 className="mb-2 text-sm font-semibold text-white">{announcement.Title}</h4>
			<p className="text-xs">{announcement.Content}</p>
			<div className="flex items-center mt-4 space-x-3">
				{announcement.Link && (
					<Button variant={Variant.light} size={Size.xs} onClick={() => window.open(announcement.Link ?? undefined, '_blank')?.focus()}>
						<FormattedMessage id="learn-more" />
					</Button>
				)}
				<button className="text-xs text-white hover:underline" onClick={() => dismiss(announcement)}>
					<FormattedMessage id="announcements.dismiss" />
				</button>
			</div>
		</div>
	);
};
