import untypedNotifications from '@assets/notification-types.json';
import type { NotificationKind, NotificationOption, UserNotificationOptions } from '@components/Notification';
import { Alert, Checkbox, Select, Size, Toggle, Variant } from '@convoflo/ui';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import classNames from 'classnames';
import get from 'lodash.get';
import set from 'lodash.set';
import type { FC } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import type { WretchError, WretchResponse } from 'wretch';

const notificationTypes: Record<string, NotificationKind> = untypedNotifications;

type NotificationOptionProps = {
	optionKey: string;
	option: NotificationOption;
	options?: {
		App?: boolean;
		Email?: number;
	};
};

export const NotificationOptionItem: FC<NotificationOptionProps> = ({ optionKey, option, options = { App: true, Email: 1 } }) => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	const { enabled: globalEnabled } = queryClient.getQueryData<UserNotificationOptions>(['notification_options']) ?? { enabled: true };

	const { mutate: save, isLoading } = useMutation<WretchResponse, WretchError, NotificationOption, UserNotificationOptions>(
		async option =>
			await client
				.url(`account/notifications/options/${optionKey}`)
				.json({
					App: option.App,
					Email: option.Email,
					Options: option.Options
				})
				.put()
				.res(),
		{
			onError: (_err, _newTodo, oldOptions) => {
				queryClient.setQueryData(['notification_options'], oldOptions);
			},
			onMutate: async option => {
				await queryClient.cancelQueries(['notification_options']);
				const options = queryClient.getQueryData<UserNotificationOptions>(['notification_options']);

				if (options === undefined) {
					return undefined;
				}

				options.options = { ...options.options, optionKey: option };

				queryClient.setQueryData<UserNotificationOptions>(['notification_options'], options);
				return options;
			},
			onSuccess: () => {
				toast.success(<FormattedMessage id="alert-options.changes_saved" />);
			}
		}
	);

	return (
		<div className="grid grid-cols-1 gap-2 p-4 sm:grid-cols-12 place-content-center">
			<div className={`flex flex-col sm:flex-row col-span-6 ${option.App === false ? 'opacity-25' : ''}`}>
				<FontAwesomeIcon
					style={notificationTypes[optionKey].color ? { color: get(notificationTypes, [optionKey, 'color'], 'black') } : {}}
					fixedWidth
					size="2x"
					icon={get(notificationTypes, [optionKey, 'icon'], 'bell') as IconProp}
					transform="shrink-8"
					mask="circle"
					className={classNames('mb-2 sm:mb-0 sm:mr-3', notificationTypes[optionKey].className)}
				/>
				<div className="flex-1">
					<h6 className="font-semibold">
						<FormattedMessage id={`alert-options.${optionKey}.title`} />
					</h6>
					<p className="text-sm text-gray-600">
						<FormattedMessage id={`alert-options.${optionKey}.description`} />
					</p>
					{!!option.Options && option.App && (
						<>
							<div className="flex mt-2 space-x-4">
								{option.Options.External !== undefined && (
									<label className="flex space-x-2 text-sm text-gray-600">
										<span>
											<FormattedMessage id="alert-options.external" />
										</span>
										<Checkbox
											checked={option.Options.External}
											disabled={isLoading || !globalEnabled}
											onChange={() => save(set(option, 'Options.External', !option.Options!.External))}
										/>
									</label>
								)}
								{option.Options.Internal !== undefined && (
									<label className="flex space-x-2 text-sm text-gray-600">
										<span>
											<FormattedMessage id="alert-options.internal" />
										</span>
										<Checkbox
											checked={option.Options.Internal}
											disabled={isLoading || !globalEnabled}
											onChange={() => save(set(option, 'Options.Internal', !option.Options!.Internal))}
										/>
									</label>
								)}
							</div>

							{option.Options.Internal === false && option.Options.External === false && option.App && (
								<Alert variant={Variant.warning} size={Size.xs} className="mt-2">
									<p>
										<FormattedMessage id="alert-options.warning" />
									</p>
								</Alert>
							)}
						</>
					)}
				</div>
			</div>
			{option.App !== false ? (
				<>
					<div className="col-span-12 text-center sm:col-span-3 sm:place-self-center">
						{options.App && (
							<div className="flex items-center justify-between">
								<label className="text-sm text-gray-500 sm:hidden">
									<FormattedMessage id="alert-options.in_app" />
								</label>
								<Toggle size={Size.sm} disabled={isLoading || !globalEnabled} checked={option.App} onChange={() => save(set<NotificationOption>(option, 'App', !option.App))} />
							</div>
						)}
					</div>
					<div className="col-span-12 text-center sm:col-span-3 sm:place-self-center">
						{option.Digestible && (
							<div className="flex items-center justify-between">
								<label className="text-sm text-gray-500 sm:hidden">
									<FormattedMessage id="alert-options.by_email" />
								</label>
								<Select
									size={Size.sm}
									disabled={isLoading || !globalEnabled}
									value={option.Email ?? '0'}
									onChange={e => save(set<NotificationOption>(option, 'Email', e.target.value))}>
									{options.Email !== undefined && <FormattedMessage id="alert-options.always">{msg => <option value="1">{msg}</option>}</FormattedMessage>}
									<FormattedMessage id="alert-options.use_schedule">{msg => <option value="2">{msg}</option>}</FormattedMessage>
									<FormattedMessage id="alert-options.never">{msg => <option value="0">{msg}</option>}</FormattedMessage>
								</Select>
							</div>
						)}
						{options.Email !== undefined && !option.Digestible && (
							<div className="flex items-center justify-between">
								<label className="text-sm text-gray-500 sm:hidden">
									<FormattedMessage id="alert-options.by_email" />
								</label>
								<Toggle
									size={Size.sm}
									disabled={isLoading || !globalEnabled}
									checked={option.Email === 1}
									onChange={e => save(set<NotificationOption>(option, 'Email', e.target.checked ? 1 : 0))}
								/>
							</div>
						)}
					</div>
				</>
			) : (
				<>
					<div className="col-span-6 text-center place-self-center">
						<Toggle disabled={isLoading || !globalEnabled} checked={false} onChange={() => save(set<NotificationOption>(option, 'App', !option.App))} />
						<span className="ml-4 font-medium text-gray-500">
							<FormattedMessage id="alert-options.tap_to_activate" />
						</span>
					</div>
				</>
			)}
		</div>
	);
};
