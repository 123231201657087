import AppContext from '@contexts/AppContext';
import { Button, Intent, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import { useSearchParams } from '@hooks/useSearchParams';
import ErrorLayout from '@ui/Layout/Error';
import { type FC, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

export const TooManyRequestsPage: FC = () => {
	const { locale } = useIntl();
	const { formatMessage } = useIntl();
	const { account } = useAccount();
	const { state } = useLocation<{ next: Location }>();
	const [searchParams] = useSearchParams();
	const { setPageTitle: setTitle } = useContext(AppContext);
	const history = useHistory();

	const reportProblem = () => {
		const link = `${import.meta.env.VITE_SITE_URL}/${locale}/contact`;
		window.open(link, '_blank') || window.location.replace(link);
	};

	useEffect(() => {
		setTitle(formatMessage({ id: 'too_many_requests_title' }));
	}, [setTitle, formatMessage]);

	return (
		<ErrorLayout>
			<h1 className="mb-2 text-xl font-semibold text-black">
				<FormattedMessage id="too_many_requests_heading" />
			</h1>
			<p className="my-2 leading-tight text-gray-600">
				<FormattedMessage id="too_many_requests_error" />
			</p>
			<div className="flex items-center mt-6 space-x-4">
				<Button variant={Variant.primary} size={Size.sm} onClick={reportProblem} shadow>
					<FontAwesomeIcon icon="exclamation-triangle" className="mr-2" size="sm" />
					<FormattedMessage id="support-box.contact_us" />
				</Button>
			</div>
		</ErrorLayout>
	);
};
