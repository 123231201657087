import { type ModalProps, Alert, Button, InputBlock, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, Row, ValidationField, Variant } from '@convoflo/ui';
import useClient from '@hooks/useClient';
import Folder from '@models/Folder';
import User from '@models/User';
import { type ChangeEvent, type FC, type FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export type UnnamedSigner = Omit<User, 'ID'> & {
	ID: number;
	Email: string;
	FirstName: string | null;
	LastName: string | null;
};

export type DialogSignerNamesProps = Omit<ModalProps, 'isOpen'> & {
	folder: Folder;
	signers: UnnamedSigner[];
	onSuccess: () => void;
};

export const DialogSignerNames: FC<DialogSignerNamesProps> = ({ folder, signers: initialSigners = [], onSuccess = () => undefined, ...modalProps }) => {
	const { formatMessage } = useIntl();
	const { client, validation } = useClient();

	const [isOpen, setIsOpen] = useState(true);
	const [signers, setSigners] = useState(initialSigners);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const save = useCallback(
		async (signers: UnnamedSigner[]) => {
			const data = signers.reduce((payload, signer) => ({ ...payload, [signer.ID]: { first_name: signer.FirstName, last_name: signer.LastName } }), {});
			await client.url(folder.getRoute('users')).put({ users: data }).res();
			setSigners([]);
			onSuccess();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onSuccess, folder]
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		setIsSubmitting(true);
		save(signers)
			.catch(console.error)
			.finally(() => {
				setIsSubmitting(false);
			});
	};

	useEffect(() => {
		setSigners(initialSigners);
	}, [initialSigners]);

	const updateSigner = useCallback((signer: UnnamedSigner, e: ChangeEvent<HTMLInputElement>) => {
		e.persist();
		setSigners(signers => signers.map(s => (s.ID === signer.ID ? { ...s, [e.target.name]: e.target.value } : s)));
	}, []);

	const allFilledIn = useMemo(() => signers.every(signer => signer.FirstName && signer.LastName), [signers]);

	return (
		<Modal isOpen={isOpen} onSubmit={onSubmit} {...modalProps}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="sign-requests-crud.before_continuing" />
			</ModalHeaderOnlyTitle>

			<ModalBody>
				<Alert variant={Variant.info}>
					<p>
						<FormattedMessage id="sign-requests-crud.warning-names" values={{ n: signers.length }} />
					</p>
				</Alert>
				{signers.map(signer => (
					<Row>
						<Label htmlFor={`user-name-${signer.ID}`}>{signer.Email}</Label>
						<div className="grid grid-cols-2 gap-2">
							<div>
								<ValidationField fieldName={`users.${signer.ID}.first_name`} validation={validation}>
									<InputBlock
										id={`user-name-${signer.ID}`}
										type="text"
										name="FirstName"
										value={signer.FirstName || ''}
										placeholder={formatMessage({ id: 'sign-requests-crud.first_name' })}
										onChange={e => updateSigner(signer, e)}
									/>
								</ValidationField>
							</div>
							<div>
								<ValidationField fieldName={`users.${signer.ID}.last_name`} validation={validation}>
									<InputBlock
										type="text"
										name="LastName"
										value={signer.LastName || ''}
										placeholder={formatMessage({ id: 'sign-requests-crud.last_name' })}
										onChange={e => updateSigner(signer, e)}
									/>
								</ValidationField>
							</div>
						</div>
					</Row>
				))}
			</ModalBody>

			<ModalFooter>
				<Button variant={Variant.primary} type="submit" disabled={!allFilledIn || isSubmitting}>
					<FormattedMessage id="sign-requests-crud.confirm" />
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} type="button" onClick={() => setIsOpen(false)}>
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
