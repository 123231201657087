import { DateDisplay, DateTimeDisplay } from '@components/DateTime';
import { DialogCopyFileables, DialogMoveFileables, DialogPermissions, FileLabel, FileableContextMenu, FileableItemThumbnail, useFileManager } from '@components/FileManager';
import { LabelSelector } from '@components/Labels';
import { DraggedFileables } from '@contexts/DraggedFileablesContext';
import { Badge, type BadgeProps, Button, Checkbox, Intent, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useView } from '@hooks/use-view';
import { useAccount } from '@hooks/useAccount';
import { usePaymentRequests } from '@hooks/usePaymentRequests';
import File from '@models/File';
import Folder from '@models/Folder';
import { type Fileable, FileableAccess, Permissions } from '@types';
import FontAwesomeSvg from '@ui/FontAwesomeSvg';
import { Highlighter } from '@ui/Highlighter';
import { Tooltip } from '@ui/Tooltip';
import UserAvatar from '@ui/UserAvatar';
import { createTransparentImage } from '@utils/ImageUtils';
import { filesize } from '@utils/StringUtils';
import classNames from 'classnames';
import { type BaseSyntheticEvent, type DragEvent, type FC, type MouseEvent, type ReactNode, memo, useCallback, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { mergeProps, useLongPress } from 'react-aria';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { FileSignRequestStatus } from './FileSignRequestStatus';

export type FileItemProps = {
	item: Fileable;
	highlighted?: boolean;
	onFocus?: (item: Fileable) => void;
};

export const FileItem: FC<FileItemProps> = memo(({ item, highlighted = false, onFocus }) => {
	const history = useHistory();
	const { account } = useAccount();
	const { locale } = useIntl();

	// Contexts
	const { pay, isPaying } = usePaymentRequests();
	const { setSelection, selectionMode, setSelectionTo, selection } = useFileManager()!;
	const { draggedFileables, setDraggedFileables } = useContext(DraggedFileables);
	const { view } = useView();

	// States
	const [dialogPermissionsOpened, setDialogPermissionsOpened] = useState(false);
	const [pendingMoveItem, setPendingMoveItem] = useState<{ sources: Fileable[]; destination: Folder }>();
	const [pendingCopyItem, setPendingCopyItem] = useState<{ sources: Fileable[]; destination: Folder }>();
	const [canBeDroppedHere, setCanBeDroppedHere] = useState(false);

	// Refs
	const container = useRef<HTMLDivElement>(null);

	const selected = useMemo(() => {
		if (view instanceof Folder && selection?.parent?.id() === view.id()) {
			return true;
		}

		if (selection?.fileables?.some(s => s.id() === item.id())) {
			return true;
		}

		return false;
	}, [item, selection, view]);

	const onCheckboxClick = (e: MouseEvent<HTMLDivElement>) => {
		if (!selected && selectionMode === 'multiple' && e.nativeEvent.shiftKey) {
			setSelectionTo(item);
		} else if (selected && selectionMode === 'multiple') {
			setSelection(selection => ({ ...selection, parent: undefined, fileables: (selection?.fileables ?? []).filter(s => s.getKey() !== item.getKey()) }));
		} else if (!selected && selectionMode === 'multiple') {
			setSelection(selection => ({ ...selection, parent: undefined, fileables: (selection?.fileables ?? []).concat([item]) }));
		} else if (selected && selectionMode === 'single') {
			setSelection(undefined);
		} else if (!selected && selectionMode === 'single') {
			setSelection({ fileables: [item] });
		}
	};

	const { longPressProps } = useLongPress({
		onLongPress(e) {
			if (e.pointerType === 'mouse') {
				return;
			}

			if (selected) {
				setSelection(selection => ({ ...selection, fileables: (selection?.fileables ?? []).filter(s => s.getKey() !== item.getKey()) }));
			} else {
				setSelection(selection => ({ ...selection, fileables: (selection?.fileables ?? []).concat([item]) }));
			}
		}
	});

	const navigate = (event: BaseSyntheticEvent) => {
		event.preventDefault();

		if (selection !== undefined) {
			return;
		}

		history.push(item.getUrl('files'), { view: item });
	};

	const name = (() => {
		let nameNode: ReactNode = <>{item.getName()}</>;

		if (typeof view === 'string' && item.getName() !== null) {
			nameNode = <Highlighter highlightedClassName="bg-yellow-300" highlight={view} text={item.getName() ?? ''} />;
		}

		if (item instanceof File) {
			if (item.current.AVStatus === 'infected') {
				nameNode = <span className="text-red-600 line-through">{nameNode}</span>;
			}
		}

		return nameNode;
	})();

	const canBeDroppedInto = item instanceof Folder && item.pivot!.Permissions === Permissions.ReadWrite;
	const isBeingDragged = draggedFileables.some(f => f.getKey() === item.getKey());

	const onDragStart = useCallback(
		(event: DragEvent) => {
			setDraggedFileables([item]);
			event.dataTransfer.setData('text', 'foo');
			event.dataTransfer.setDragImage(createTransparentImage(), 0, 0);
		},
		[item, setDraggedFileables]
	);

	const onDragEnd = useCallback(() => {
		setDraggedFileables([]);
	}, [setDraggedFileables]);

	const onDragOver = useCallback(
		(event: DragEvent) => {
			event.preventDefault();
			if (!isBeingDragged && canBeDroppedInto) {
				setCanBeDroppedHere(true);
			} else {
				setCanBeDroppedHere(false);
			}
		},
		[isBeingDragged, canBeDroppedInto]
	);

	const onDragLeave = useCallback(() => {
		setCanBeDroppedHere(false);
	}, []);

	const onDrop = useCallback(
		(event: DragEvent) => {
			event.preventDefault();
			if (draggedFileables.some(fileable => fileable.getKey() === item.getKey())) {
				return;
			}

			let sources = draggedFileables;

			if (selection?.fileables?.length) {
				sources = selection!.fileables!;
			}

			if (event.dataTransfer.dropEffect === 'copy') {
				setPendingCopyItem({ sources: sources, destination: item as Folder });
			} else {
				setPendingMoveItem({ sources: sources, destination: item as Folder });
			}
			setCanBeDroppedHere(false);
			setDraggedFileables([]);
		},
		[draggedFileables, setDraggedFileables, item, selection]
	);

	const blockedByPaywall = item instanceof File && item.paywall !== undefined && item.paywall.length > 0;

	useLayoutEffect(() => {
		if (highlighted && container.current !== null) {
			container.current.scrollIntoView(true);
		}
	}, [highlighted]);

	// @ts-ignore
	return (
		<div
			ref={container}
			onFocus={() => onFocus && onFocus(item)}
			onDrop={canBeDroppedInto ? onDrop : undefined}
			onDragOver={canBeDroppedInto ? onDragOver : undefined}
			onDragLeave={canBeDroppedInto ? onDragLeave : undefined}
			onDragEnd={onDragEnd}
			onDragEnter={onDragOver}
			draggable={!!item.getKey()}
			// onClick={e => (selectionMode !== undefined ? onCheckboxClick(e) : undefined)} // Doesn't work
			className={classNames('select-none flex items-center relative gap-2 px-3 py-1', {
				'animate-highlight': highlighted,
				'opacity-50': isBeingDragged,
				'bg-yellow-100': selected,
				'hover:bg-gray-50': !selected
			})}
			{...mergeProps(longPressProps, { onDragStart })}>
			{canBeDroppedHere && <div className="absolute inset-0 border-2 border-gray-300 border-dashed rounded-md pointer-events-none bg-yellow-50/50"></div>}

			{dialogPermissionsOpened && <DialogPermissions onAfterClose={() => setDialogPermissionsOpened(false)} item={item} />}

			{pendingMoveItem && (
				<DialogMoveFileables
					onMoved={() => setSelection(undefined)}
					onAfterClose={() => setPendingMoveItem(undefined)}
					fileables={pendingMoveItem.sources}
					destination={pendingMoveItem.destination}
				/>
			)}

			{pendingCopyItem && (
				<DialogCopyFileables
					onCopied={() => setSelection(undefined)}
					onAfterClose={() => setPendingCopyItem(undefined)}
					items={pendingCopyItem.sources}
					destination={pendingCopyItem.destination}
				/>
			)}

			<div className="items-center justify-center hidden w-8 h-8 -mr-2 sm:flex" onClick={onCheckboxClick}>
				<Checkbox checked={selected} />
			</div>

			<div className="relative flex items-center justify-center flex-shrink-0 w-8 h-8 sm:w-12 sm:h-12">
				<FileableItemThumbnail fileable={item} />
			</div>

			<div className="flex items-center flex-1 gap-x-3">
				<div className="flex items-center flex-1 space-x-4 group">
					<button onClick={navigate} className="flex-1 text-left">
						{/* Title */}
						<span>
							<a
								href={item.getUrl(item instanceof Folder && !item.SecuredSpace ? 'files' : undefined)}
								onClick={e => e.preventDefault()}
								className={classNames('text-sm font-semibold', {
									'opacity-50': blockedByPaywall
								})}>
								{name}
							</a>

							{blockedByPaywall && (
								<Tooltip tip={<FormattedMessage id="payment-requests.pay_to_unblock_file" />}>
									<Button
										variant={Variant.success}
										intent={Intent.secondary}
										className="sm:ml-2"
										size={Size.xs}
										disabled={isPaying}
										onClick={() => pay(item.paywall![0])}
										iconEnd="arrow-right">
										<FormattedMessage id="payment-requests.pay" />
									</Button>
								</Tooltip>
							)}
						</span>

						{/* Description */}
						<div className="hidden sm:flex items-baseline gap-1 lg:gap-3 mt-0.5 flex-col lg:flex-row">
							{item instanceof File && (
								<p className="flex-shrink-0 order-none text-xs text-gray-400 lg:order-first">
									{item.current.Size > 0 && <>{filesize(item.current.Size, locale)} &middot; </>}
									<DateTimeDisplay value={item.current.created_at} defaultFormat="relative" />
								</p>
							)}

							{item instanceof Folder && (item.files_count > 0 || item.folders_count > 0) && (
								<p className="flex-shrink-0 order-none text-xs text-gray-400 lg:order-first">
									{item.files_count > 0 && <FormattedMessage id="file-manager.total_files" values={{ n: item.files_count }} />}
									{item.files_count > 0 && item.folders_count > 0 && <>, </>}
									{item.folders_count > 0 && <FormattedMessage id="file-manager.total_folders" values={{ n: item.folders_count }} />}
								</p>
							)}

							{!!item.expires_at && (
								<Tooltip
									tip={
										<FormattedMessage
											id="file-manager.expires_at"
											values={{
												datetime: <DateDisplay value={item.expires_at} />,
												type: item instanceof File ? 'file' : item instanceof Folder && item.SecuredSpace ? 'secure_space' : 'folder'
											}}
										/>
									}>
									<span className="text-xs">
										<FontAwesomeIcon icon="history" className="text-orange-600" />
									</span>
								</Tooltip>
							)}

							{/* Labels */}
							{!!item.getKey() && (
								<div className="flex items-center gap-0.5 flex-wrap order-none lg:order-last" onClick={e => e.stopPropagation()}>
									{item instanceof File && item.signer?.map(signRequest => <FileSignRequestStatus key={signRequest.Id} file={item} signRequest={signRequest} />)}

									{account?.hasFullAccess() && (
										<>
											{/* Sometimes it's not included in the response, we'll fix that */}
											{(item.properties ?? []).map(prop => (
												<LabelSelector key={prop.Id} label={prop} model={item} disabled={selection !== undefined && !selected} />
											))}
											{(selection === undefined || selected) && (
												<span className="transition-opacity opacity-100 lg:opacity-0 group-hover:opacity-100">
													<LabelSelector model={item} />
												</span>
											)}
										</>
									)}
								</div>
							)}
						</div>
					</button>
				</div>

				{/* Members */}
				<div className="items-center justify-end flex-shrink-0 hidden sm:flex max-w-56">
					{item instanceof File && item.Access === FileableAccess.Public && (
						<Tooltip tip={<FormattedMessage id="file-manager.public_access" />}>
							<span className="mr-2">
								<FontAwesomeIcon icon="globe-americas" className="text-blue-600" />
							</span>
						</Tooltip>
					)}
					{account?.hasFullAccess() && item.pivot!.Permissions === Permissions.ReadWrite && item.collaborators.length > 0 ? (
						<button className="inline-flex items-center space-x-3 focus:outline-none" disabled={selection !== undefined} onClick={() => setDialogPermissionsOpened(true)}>
							{item instanceof Folder && item.SecuredSpace && (item.activations?.total ?? 0) > 0 && (
								<SecureSpaceActivationsBadge total={item.activations?.total ?? 0} activated={item.activations?.activated ?? 0} />
							)}
							<span className="inline-flex items-center -space-x-1">
								{item.collaborators.map(user => (
									<UserAvatar key={user.ID} user={user} size={Size.xs} />
								))}
								{item.members_count > item.collaborators.length && (
									<span className="pl-2 text-sm font-bold tracking-tighter text-gray-500">+{item.members_count - item.collaborators.length}</span>
								)}
							</span>
						</button>
					) : item.creator ? (
						<span className="text-xs leading-tight text-gray-600 truncate">{item.creator.business?.Name ?? item.creator.Name}</span>
					) : null}
				</div>
			</div>

			{/* Context menu */}
			<div className="flex items-stretch flex-shrink-0">
				{!!item.getKey() && (
					<>
						{/* <div className="hidden w-px mx-3 my-2 border-l border-gray-200 sm:flex" /> */}
						<FileableContextMenu fileable={item} placement="bottom-end">
							<Button variant={Variant.light} intent={Intent.tertiary} type="button" disabled={selection !== undefined} circle className="mr-2">
								<FontAwesomeSvg id="fa-ellipsis-v" className="text-gray-500" />
							</Button>
						</FileableContextMenu>
					</>
				)}
			</div>
		</div>
	);
});

type SecureSpaceActivationsBadgeProps = BadgeProps & {
	total: number;
	activated: number;
};

const SecureSpaceActivationsBadge: FC<SecureSpaceActivationsBadgeProps> = ({ total, activated, ...badgeProps }) => {
	return (
		<Tooltip tip={<FormattedMessage id="file-manager.total_secure_space_activations" values={{ n: activated }} />}>
			<Badge variant={total - activated > 0 ? Variant.light : Variant.success} className="items-center ml-2" {...badgeProps}>
				{total - activated ? <FormattedMessage id="x_of_n" values={{ x: activated, n: total }} /> : total}
				<FontAwesomeIcon icon={total - activated > 0 ? 'shield' : 'shield-check'} className="ml-1" />
			</Badge>
		</Tooltip>
	);
};
