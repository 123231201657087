import { autoUpdate, FloatingPortal, useClick, useDismiss, useFloating, useInteractions, useRole } from '@floating-ui/react';
import 'emoji-picker-element';
import type { EmojiClickEvent } from 'emoji-picker-element/shared';
import { Children, cloneElement, createElement, type FC, type PropsWithChildren, useCallback, useLayoutEffect, useRef, useState } from 'react';

type EmojiPickerProps = PropsWithChildren<{
	onChange?: (emoji: string) => void;
	isOpen?: boolean;
}>;

export const EmojiPicker: FC<EmojiPickerProps> = ({ onChange = () => undefined, children }) => {
	const [isOpen, setIsOpen] = useState(false);

	const { refs, floatingStyles, context, elements } = useFloating({
		open: isOpen,
		onOpenChange: setIsOpen,
		whileElementsMounted: autoUpdate
	});

	const click = useClick(context);
	const dismiss = useDismiss(context);
	const role = useRole(context);

	// Merge all the interactions into prop getters
	const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

	const emojiPicker = useRef<HTMLElement>(null);

	const onEmojiSelected = useCallback(
		({ detail }: EmojiClickEvent) => {
			onChange(detail.unicode ?? '');
		},
		[onChange]
	);

	useLayoutEffect(() => {
		if (!elements.floating) return;
		emojiPicker.current?.addEventListener('emoji-click', onEmojiSelected);
		return () => {
			emojiPicker.current?.removeEventListener('emoji-click', onEmojiSelected);
		};
	}, [onEmojiSelected, elements.floating]);

	const trigger = Children.only(children) as JSX.Element;

	return (
		<>
			{cloneElement(trigger, {
				...trigger.props,
				ref: refs.setReference,
				role: 'button',
				'aria-label': 'Menu',
				'aria-haspopup': true,
				...getReferenceProps()
			})}
			{isOpen && (
				<FloatingPortal>
					<div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
						{createElement('emoji-picker', { ref: emojiPicker })}
					</div>
				</FloatingPortal>
			)}
		</>
	);
};
