import MicrosoftLogo from '@assets/images/microsoft-logo.svg?react';
import { FormattedMessage } from 'react-intl';
export const LoginWithMicrosoftButton = () => {
	return (
		<a
			href={`${import.meta.env.VITE_API_BASE_URL}/login/AZURE_ACTIVE_DIRECTORY/redirect?next=${encodeURIComponent(window.location.href)}`}
			className="border border-[#8c8c8c] bg-white text-[#5e5e5e] font-semibold text-[15px] h-[41px] px-3 inline-flex items-center gap-3">
			<MicrosoftLogo className="size-[21px]" />
			<FormattedMessage id="login.microsoft" />
		</a>
	);
};
