import { ComboBox, UserOption } from '@components/ComboBox';
import { type MemberType, useFileableMembers } from '@components/FileManager';
import { FileSelector } from '@components/FileSelector';
import { MessageFormContext } from '@components/Message';
import { ReminderIntervalManager } from '@components/Organization';
import { Button, HelperText, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Row, Select, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import Collaborator from '@models/Collaborator';
import Folder from '@models/Folder';
import { type Fileable, Permissions } from '@types';
import uniqWith from 'lodash.uniqwith';
import { type FC, useCallback, useContext, useMemo, useState } from 'react';
import { FormattedList, FormattedMessage } from 'react-intl';
import type { OnChangeValue } from 'react-select';

export type FileRequestOptions = {
	users: Collaborator[];
	folder?: Folder;
	reminders: number[];
	shared: boolean;
};

type DialogFileRequestOptionsProps = Omit<ModalProps, 'isOpen'> &
	FileRequestOptions & {
		onChange?: (options: FileRequestOptions) => void;
	};

export const DialogFileRequestOptions: FC<DialogFileRequestOptionsProps> = ({
	folder: initialFolder,
	users: initialUsers,
	reminders: initialReminders,
	shared: initialShared,
	onChange = () => undefined,
	...modalProps
}) => {
	const { account } = useAccount();
	const [isOpen, setIsOpen] = useState(true);
	const { fileable } = useContext(MessageFormContext);
	const { members = [] } = useFileableMembers(fileable);

	const { pluginData } = useContext(MessageFormContext);

	const [showPicker, setShowPicker] = useState(false);
	const [users, setUsers] = useState(initialUsers);
	const [folder, setFolder] = useState(initialFolder);
	const [showReminders, setShowReminders] = useState(false);
	const [reminders, setReminders] = useState(initialReminders);
	const [shared, setShared] = useState(initialShared);

	const onUsersChanged = (users: OnChangeValue<Collaborator, true>) => {
		setUsers(users.filter(() => true)); // this is stupid
	};

	const onFolderSelected = useCallback((items: (Fileable | null)[]) => setFolder(items.length === 0 ? null : items[0]), []);

	const onSubmit = () => {
		onChange({ users, folder, reminders, shared });
		setIsOpen(false);
	};

	const submittable = useMemo(() => users.length > 0, [users.length]);

	const audience = useMemo(() => {
		const audience = !pluginData['audience']?.users?.length ? members : pluginData['audience'].users;

		return uniqWith<MemberType>(
			audience.filter((member: MemberType) => member.ID !== account?.ID),
			(a, b) => a.ID === b.ID
		);
	}, [pluginData['audience']?.users, members, account?.ID]);

	return (
		<Modal isOpen={isOpen} {...modalProps}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="file-requests.options.title" />
			</ModalHeaderOnlyTitle>
			<ModalBody>
				<Row>
					<Label>
						<FontAwesomeIcon icon={['fad', 'users']} className="mr-2" />
						<FormattedMessage id="payment-requests.options.users" />
					</Label>

					<ComboBox
						value={users}
						options={audience}
						getOptionValue={member => member.Email}
						components={{
							Option: UserOption
						}}
						placeholder={<FormattedMessage id="select" />}
						getOptionLabel={member => member.Name!}
						isSearchable
						isMulti
						onChange={onUsersChanged}
					/>
					<HelperText>
						<FormattedMessage id="payment-requests.options.users_desc" />
					</HelperText>
				</Row>
				{users.length > 1 && (
					<Row className="flex gap-4 ml-2">
						<FontAwesomeIcon size="lg" icon={['fal', 'level-up']} rotation={90} className="mt-0.5 text-gray-500" />
						<div>
							<Label>
								<FormattedMessage id="file-requests.options.complete_when" />
							</Label>
							<Select size={Size.sm} value={shared ? '1' : '0'} onChange={e => setShared(e.target.value === '1')}>
								<FormattedMessage id="file-requests.options.complete_when_anyone">{msg => <option value="1">{msg}</option>}</FormattedMessage>
								<FormattedMessage id="file-requests.options.complete_when_everyone">{msg => <option value="0">{msg}</option>}</FormattedMessage>
							</Select>
						</div>
					</Row>
				)}
				<Row>
					<Label>
						<FontAwesomeIcon icon={['fad', 'cloud-upload']} className="mr-2" />
						<FormattedMessage id="file-requests.folder" />
					</Label>
					<div className="flex items-center space-x-4">
						<Button type="button" size={Size.sm} variant={Variant.light} intent={Intent.secondary} onClick={() => setShowPicker(true)}>
							<FontAwesomeIcon icon="folder" className="mr-2" />
							<FormattedMessage id="dialog-move.select_destination" />
						</Button>
						<span className="text-sm italic text-gray-700">
							{(folder ?? fileable).icon('mr-2')}
							{(folder ?? fileable).getName()}
						</span>

						{showPicker && (
							<FileSelector
								startingFolder={fileable}
								onAfterClose={() => setShowPicker(false)}
								selectedItems={folder ? [folder] : undefined}
								isItemDisabled={fileable => !(fileable instanceof Folder) || fileable.pivot?.Permissions !== Permissions.ReadWrite}
								onItemsSelected={onFolderSelected}
							/>
						)}
					</div>
					<HelperText>
						<FormattedMessage id="file-requests.folder.help" />
					</HelperText>
				</Row>
				<Row>
					<Label>
						<FontAwesomeIcon icon={['fad', 'history']} className="mr-2" />
						<FormattedMessage id="file-requests.options.reminders" />
					</Label>
					{showReminders ? (
						<ReminderIntervalManager value={reminders} onChange={setReminders} />
					) : (
						<p className="text-sm">
							<FormattedMessage
								id="file-requests.options.reminders_text"
								values={{
									button: msg => (
										<button type="button" className="text-theme-primary hover:underline" onClick={() => setShowReminders(true)}>
											{msg}
										</button>
									),
									list: (
										<FormattedList
											value={reminders.map(reminder => (
												<strong className="font-semibold">
													<FormattedMessage id="organization-settings.days" values={{ n: reminder }} />
												</strong>
											))}
										/>
									)
								}}
							/>
						</p>
					)}
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button disabled={!submittable} variant={Variant.primary} intent={Intent.primary} type="button" onClick={onSubmit}>
					<FormattedMessage id="payment-requests.options.attach" />
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
