import type { ConnectedAppType } from '@components/ConnectedApps';
import useClient from '@hooks/useClient';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const RQKEY_ROOT = 'microsoft-sharepoint';
export const RQKEY = (...keys: string[]) => [RQKEY_ROOT, ...keys];

export type MicrosoftSharePointOptionsType = {
	sites?: string[];
};

export const useMicrosoftSharePointOptionsQuery = () => {
	const { client } = useClient();

	return useQuery({
		staleTime: 1000 * 60,
		queryKey: RQKEY('options'),
		queryFn: async () => await client.url('apps/sharepoint/options').get().json<MicrosoftSharePointOptionsType>()
	});
};

export const useMicrosoftSharePointSaveOptionsMutation = () => {
	const { client } = useClient();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (payload: MicrosoftSharePointOptionsType & { run?: boolean }) => await client.url(`apps/sharepoint/options`).json(payload).put().json<MicrosoftSharePointOptionsType>(),
		onMutate: async payload => {
			await queryClient.cancelQueries(RQKEY('options'));

			const previousOptions = queryClient.getQueryData<MicrosoftSharePointOptionsType>(RQKEY('options'));

			if (previousOptions === undefined) {
				return undefined;
			}

			queryClient.setQueryData<MicrosoftSharePointOptionsType>(RQKEY('options'), options => ({ ...options, ...payload }));

			return previousOptions;
		},
		onError: (_error, _variables, previousOptions) => {
			queryClient.setQueryData(RQKEY('options'), previousOptions);
		},
		onSuccess: options => {
			if (options.sites) {
				queryClient.removeQueries(RQKEY('sites'));
			}
		}
	});
};

export type SharePointSiteType = {
	id: string;
	name: string;
};

export type SharePointDriveType = {
	id: string;
	name: string;
	description: string;
};

export const useMicrosoftSharePointSitesQuery = (connection: ConnectedAppType, all: boolean = false) => {
	const { client } = useClient();

	return useQuery({
		queryKey: RQKEY('sites', connection.Id, +all),
		queryFn: async () => await client.url(`apps/sharepoint/apps/${connection.Id}/sites`).query({ all: +all }).get().json<SharePointSiteType[]>(),
		staleTime: Infinity
	});
};

export const useMicrosoftSharePointDrivesQuery = (connection: ConnectedAppType, siteId?: string, opts?: { all?: boolean; enabled?: boolean }) => {
	const { client } = useClient();

	const enabled = opts?.enabled !== false && !!siteId;
	const all = opts?.all === true;

	return useQuery({
		queryKey: RQKEY('drives', siteId ?? ''),
		queryFn: async () => await client.url(`apps/sharepoint/apps/${connection.Id}/sites/${siteId}/drives`).query({ all: +all }).get().json<SharePointDriveType[]>(),
		enabled,
		staleTime: Infinity
	});
};
