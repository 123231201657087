import { ComboBox, KeyValueDescriptionOption } from '@components/ComboBox';
import type { ConnectedAppType } from '@components/ConnectedApps';
import { DateTimeDisplay } from '@components/DateTime';
import { FileManagerContext } from '@components/FileManager';
import { Alert, Button, Intent, Label, Modal, ModalBody, ModalFooter, ModalHeader, ModalHeaderOnlyTitle, type ModalProps, ModalSize, Row, Size, ValidationField, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useView } from '@hooks/use-view';
import useClient from '@hooks/useClient';
import File from '@models/File';
import Folder from '@models/Folder';
import { versionMiddleware } from '@service/Client';
import { useMicrosoftSharePointDrivesQuery, useMicrosoftSharePointSitesQuery } from '@state/queries/microsoft-sharepoint';
import type { Fileable, ItemSyncType } from '@types';
import { Tab } from '@ui/Tab';
import classNames from 'classnames';
import { type FC, type FormEvent, Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { Redirect } from 'react-router-dom';
import { type OptionProps, components } from 'react-select';
import type { WretchError } from 'wretch';

type SyncMutation = {
	connection: ConnectedAppType;
	driveId: string;
};

type SyncedEvent = {
	fileable: Fileable;
};

type DialogSyncFileableProps = Omit<ModalProps, 'isOpen'> & {
	fileable: Fileable;
	onSynced?: (event: SyncedEvent) => void;
};

export const DialogSyncFileable: FC<DialogSyncFileableProps> = ({ fileable, onSynced = () => undefined, ...props }) => {
	const { client } = useClient();
	const { formatMessage } = useIntl();
	const { update: updateItem = () => undefined } = useContext(FileManagerContext) ?? {};
	const { view, update: updateView } = useView();

	const [isOpen, setIsOpen] = useState(true);
	const [selectedAppConnection, setSelectedAppConnection] = useState<ConnectedAppType>();
	const [selectedDriveId, setSelectedDriveId] = useState<string>();
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedUnsync, setSelectedUnsync] = useState<ItemSyncType>();

	const { data: appConnections, isLoading: isLoadingApps } = useQuery(
		['apps', 'connected', 'fs_sync'],
		async () => await client.url('apps/connected?filters[type]=fs_sync').get().json<ConnectedAppType[]>(),
		{
			staleTime: Infinity
		}
	);

	const { mutate: sync, isLoading: isSyncing } = useMutation<SyncedEvent, WretchError, SyncMutation>(
		async ({ driveId, connection }) => {
			let payload = await client.url(`apps/sharepoint/folders/${fileable.getKey()}/sync/${connection.Id}`).json({ drive_id: driveId }).post().json<Fileable>();

			if (fileable instanceof File) {
				payload = new File(payload);
			} else if (fileable instanceof Folder) {
				payload = new Folder(payload);
			}

			return { fileable: payload };
		},
		{
			onError: console.log,
			onSuccess: event => {
				toast.success(<FormattedMessage id="sync.dialog.synced" />);
				onSynced(event);
				setIsOpen(false);

				updateItem(event.fileable);

				if ((view instanceof Folder || view instanceof File) && view.getKey() === event.fileable.getKey()) {
					updateView(event.fileable);
				}
			}
		}
	);

	const { data: currentSyncs } = useQuery(
		['view', `folder:${fileable.URL}`],
		async () =>
			new Folder(
				await client
					.url(`folders/${fileable.URL}`)
					.middlewares([versionMiddleware(2)])
					.get()
					.json<Folder>()
			),
		{
			select: ({ syncs }) => syncs,
			enabled: appConnections && appConnections.length > 0
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();

		if (!selectedAppConnection || !selectedDriveId) {
			return;
		}

		sync({ connection: selectedAppConnection, driveId: selectedDriveId });
	};

	useEffect(() => {
		if (currentSyncs && currentSyncs.length === 0) {
			setSelectedTab(1);
		}
	}, [currentSyncs]);

	if (isLoadingApps) {
		return (
			<Modal isOpen={isOpen} size={ModalSize.XSmall}>
				<ModalBody>
					<div className="grid place-items-center">
						<FontAwesomeIcon icon="spinner" size="3x" className="text-gray-600" pulse />
					</div>
				</ModalBody>
			</Modal>
		);
	}

	if (appConnections?.length === 0) {
		return <Redirect to="/organization/apps" />;
	}

	return (
		<>
			{selectedUnsync !== undefined && <DialogUnsyncConnection fileable={fileable} sync={selectedUnsync} onAfterClose={() => setSelectedUnsync(undefined)} />}

			<Modal isOpen={isOpen} closeable {...props}>
				<Tab.Group as={Fragment} selectedIndex={selectedTab} onChange={setSelectedTab}>
					<ModalHeader className="flex-col !justify-normal !items-stretch">
						<h3 className="text-lg font-medium text-gray-900">
							<FormattedMessage id="sync.dialog.synced_title" values={{ fileable: fileable.getName() }} />
						</h3>
						<Tab.List className="mt-4 -mx-8">
							<Tab>
								<FormattedMessage id="sync.dialog.active" />
							</Tab>
							<Tab>
								<FormattedMessage id="sync.dialog.add" />
							</Tab>
						</Tab.List>
					</ModalHeader>

					<Tab.Panels>
						<ModalBody className="!p-0">
							<Tab.Panel>
								{currentSyncs && currentSyncs.length > 0 && (
									<table className="w-full">
										<thead>
											<tr className="bg-gray-100">
												<th />
												<th className="px-3 py-2 text-xs font-semibold text-left">
													<FormattedMessage id="sync.dialog.location" />
												</th>
												<th className="px-3 py-2 text-xs font-semibold text-left">
													<FormattedMessage id="sync.dialog.last_sync" />
												</th>
												<th />
											</tr>
										</thead>
										<tbody>
											{currentSyncs.map(sync => (
												<tr key={sync.Id}>
													<td className="w-16 py-2 pl-6 pr-3 text-sm text-right border-b border-gray-200">
														<img src={sync.Connection?.App.Icon} alt={sync.Connection?.App.Name} />
													</td>
													<td className="px-3 py-2 text-sm border-b border-gray-200">
														<h3 className="mb-1 font-semibold leading-none">{sync.Connection?.Name}</h3>
													</td>
													<td className="px-3 py-2 text-xs text-gray-600 border-b border-gray-200">
														<DateTimeDisplay value={sync.last_synced_at} />
													</td>
													<td className="pl-3 pr-6 text-right border-b border-gray-200">
														<div className="inline-flex gap-2">
															{/* <Button circle intent={Intent.secondary} variant={Variant.warning} icon="pause" size={Size.sm} /> */}
															<Button circle intent={Intent.secondary} variant={Variant.danger} icon="ban" size={Size.sm} onClick={() => setSelectedUnsync(sync)} />
														</div>
													</td>
												</tr>
											))}
											<tr>
												<td className="w-16" />
												<td colSpan={3} className="px-3 pt-3 pb-6 text-sm border-b border-gray-200">
													<Button variant={Variant.primary} intent={Intent.secondary} size={Size.sm} onClick={() => setSelectedTab(1)} iconStart="plus">
														<FormattedMessage id="sync.dialog.add_connection" />
													</Button>
												</td>
											</tr>
										</tbody>
									</table>
								)}
								{currentSyncs && currentSyncs.length === 0 && (
									<div className="flex flex-col gap-6 px-6 py-12 rounded-lg bg-gray-300/25">
										<p className="text-sm italic text-center text-gray-500">
											<FormattedMessage id="sync.dialog.no_active_connections" />
										</p>
										<div className="grid place-items-center">
											<Button variant={Variant.primary} intent={Intent.primary} size={Size.sm} onClick={() => setSelectedTab(1)} iconStart="plus">
												<FormattedMessage id="sync.dialog.add_connection" />
											</Button>
										</div>
									</div>
								)}
							</Tab.Panel>
							<Tab.Panel onSubmit={onSubmit} as="form">
								<div className="flex-1 px-6 py-6 overflow-auto sm:px-8">
									<Row>
										<Label>
											<FormattedMessage id="sync.dialog.app" />
										</Label>
										<ComboBox
											components={{
												Option: ConnectedAppOption
											}}
											isLoading={isLoadingApps}
											isDisabled={!appConnections}
											isSearchable={false}
											value={appConnections?.find(connection => connection.Id === selectedAppConnection?.Id) ?? undefined}
											onChange={value => (value ? setSelectedAppConnection(value) : void 0)}
											getOptionLabel={({ Name }) => Name}
											getOptionValue={({ Id }) => String(Id)}
											placeholder={formatMessage({ id: 'sync.dialog.choose_app' })}
											options={appConnections}
										/>
									</Row>
									{selectedAppConnection?.App.Code === 'SHAREPOINT' && <SharePointDriveSelector appConnection={selectedAppConnection} onDriveSelected={setSelectedDriveId} />}
								</div>
								<ModalFooter>
									<Button disabled={isSyncing || !selectedAppConnection || !selectedDriveId} type="submit" variant={Variant.primary} className="mr-2" loading={isSyncing}>
										<FormattedMessage id="sync.dialog.sync" />
									</Button>
									<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
										<FormattedMessage id="cancel" />
									</Button>
								</ModalFooter>
							</Tab.Panel>
						</ModalBody>
					</Tab.Panels>
				</Tab.Group>
			</Modal>
		</>
	);
};

type SharePointDriveSelectorProps = {
	appConnection: ConnectedAppType;
	onDriveSelected?: (driveId?: string) => void;
};

const SharePointDriveSelector: FC<SharePointDriveSelectorProps> = ({ appConnection, onDriveSelected = () => undefined }) => {
	const { validation } = useClient();
	const { formatMessage } = useIntl();

	const [selectedSiteId, setSelectedSiteId] = useState<string>();
	const [selectedDriveId, setSelectedDriveId] = useState<string>();

	const { data: sitesData, isLoading: isLoadingSites } = useMicrosoftSharePointSitesQuery(appConnection);
	const { data: drivesData, isLoading: isLoadingDrives } = useMicrosoftSharePointDrivesQuery(appConnection, selectedSiteId);

	useEffect(() => {
		setSelectedDriveId(undefined);
	}, [selectedSiteId]);

	useEffect(() => {
		onDriveSelected(selectedDriveId);
	}, [selectedDriveId]);

	const sites = useMemo(() => sitesData?.map(site => ({ value: site.id, label: site.name, description: '' })) ?? [], [sitesData]);

	const drives = useMemo(() => drivesData?.map(drive => ({ value: drive.id, label: drive.name, description: '' })) ?? [], [drivesData]);

	return (
		<div className="p-3 border rounded">
			<div className="flex items-center gap-2 mb-6">
				<img src={appConnection.App.Icon} className="size-8" alt={appConnection.App.Name} />
				<p className="text-lg font-bold">{appConnection.App.Name}</p>
			</div>
			<Row>
				<Label>
					<FormattedMessage id="sync.dialog.sharepoint.site" />
				</Label>
				<ComboBox
					components={{
						Option: KeyValueDescriptionOption
					}}
					isLoading={isLoadingSites}
					isDisabled={isLoadingSites}
					isSearchable={false}
					value={sites?.find(site => site.value === selectedSiteId) ?? undefined}
					onChange={value => (value ? setSelectedSiteId(value.value) : void 0)}
					getOptionLabel={({ label }) => label}
					getOptionValue={({ value }) => value}
					placeholder={formatMessage({ id: 'sync.dialog.sharepoint.choose_site' })}
					options={sites}
				/>
			</Row>
			<Row>
				<Label>
					<FormattedMessage id="sync.dialog.sharepoint.drive" />
				</Label>
				<ValidationField fieldName="drive_id" validation={validation}>
					<ComboBox
						components={{
							Option: KeyValueDescriptionOption
						}}
						isLoading={isLoadingDrives}
						isDisabled={isLoadingDrives || !selectedSiteId}
						isSearchable={true}
						value={drives?.find(drive => drive.value === selectedDriveId) ?? undefined}
						onChange={value => (value ? setSelectedDriveId(value.value) : void 0)}
						getOptionLabel={({ label }) => label}
						getOptionValue={({ value }) => value}
						placeholder={formatMessage({ id: 'sync.dialog.sharepoint.choose_drive' })}
						options={drives}
					/>
				</ValidationField>
			</Row>
		</div>
	);
};

const ConnectedAppOption = ({ data, isSelected, isFocused, innerProps, ...props }: OptionProps<ConnectedAppType, false>) => {
	const className = classNames(innerProps.className, '!flex items-center gap-3 hover:bg-gray-100', { '!bg-theme-primary': isSelected, '!bg-gray-200': isFocused });
	return (
		<components.Option innerProps={{ ...innerProps, className }} data={data} isFocused={isFocused} isSelected={isSelected} {...props}>
			<img src={data.App.Icon} className="size-8" alt={data.App.Name} />
			<div>
				<h6 className={classNames('font-semibold text-sm', { 'text-white': isSelected })}>{data.Name}</h6>
				<p className={classNames('text-xs font-light', { 'text-white': isSelected, 'text-gray-500': !isSelected })}>{data.App.Name}</p>
			</div>
		</components.Option>
	);
};

type DialogUnsyncConnectionProps = Omit<ModalProps, 'isOpen'> & {
	fileable: Fileable;
	sync: ItemSyncType;
	onUnsynced?: (event: SyncedEvent) => void;
};

type UnsyncMutation = {
	connection: ConnectedAppType;
};

const DialogUnsyncConnection: FC<DialogUnsyncConnectionProps> = ({ fileable, sync, onUnsynced = () => undefined, ...modalProps }) => {
	const { client } = useClient();
	const { update: updateItem = () => undefined } = useContext(FileManagerContext) ?? {};
	const { view, update: updateView } = useView();

	const [isOpen, setIsOpen] = useState(true);

	const { mutate: unsync, isLoading: isUnsyncing } = useMutation<SyncedEvent, WretchError, UnsyncMutation>(
		async ({ connection }) => {
			let payload = await client.url(`apps/sharepoint/folders/${fileable.getKey()}/sync/${connection.Id}`).delete().json<Fileable>();

			if (fileable instanceof File) {
				payload = new File(payload);
			} else if (fileable instanceof Folder) {
				payload = new Folder(payload);
			}

			return { fileable: payload };
		},
		{
			onError: console.log,
			onSuccess: event => {
				toast.success(<FormattedMessage id="dialog-rename.renamed" values={{ item: event.fileable.getName() }} />);
				onUnsynced(event);
				setIsOpen(false);

				updateItem(event.fileable);

				if ((view instanceof Folder || view instanceof File) && view.getKey() === event.fileable.getKey()) {
					updateView(event.fileable);
				}
			}
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		unsync({ connection: sync.Connection! });
	};

	return (
		<Modal isOpen={isOpen} onSubmit={onSubmit} {...modalProps}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage
					id="sync.dialog.unsync_title"
					values={{
						fileable: fileable.getName(),
						app: sync.Connection?.App.Name
					}}
				/>
			</ModalHeaderOnlyTitle>
			<ModalBody>
				<Alert variant={Variant.danger}>
					<p>
						<FormattedMessage id="dialog-delete.confirmation" />
					</p>
				</Alert>
			</ModalBody>

			<ModalFooter>
				<Button disabled={isUnsyncing} type="submit" variant={Variant.danger} loading={isUnsyncing}>
					<FormattedMessage id="sync.dialog.unsync" />
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
