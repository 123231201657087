import SharePointIcon from '@assets/images/sharepoint-icon.svg?react';
import {
	DialogCopyFileables,
	DialogDownloadFileables,
	DialogMoveFileables,
	DialogPermissions,
	DialogPreview,
	DialogRenameFileable,
	DialogShareSecureSpace,
	DialogSyncFileable,
	DialogUnlinkFileable,
	FileManagerContext
} from '@components/FileManager';
import { useView } from '@hooks/use-view';
import { useAccount } from '@hooks/useAccount';
import useFileUtils from '@hooks/useFileUtils';
import useUrlSearch from '@hooks/useUrlSearch';
import File from '@models/File';
import Folder from '@models/Folder';
import { type Fileable, Permissions } from '@types';
import Dropdown, { DropdownDivider, DropdownGroup, DropdownItem, type DropdownProps } from '@ui/Dropdown';
import { Children, type FC, type PropsWithChildren, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { type DeletedEvent, DialogDeleteFileables } from './DialogDeleteFileables';
import { DialogFileSettings } from './DialogFileSettings';
import { DialogFolderSettings } from './DialogFolderSettings';
import { useFileableHelpers } from './hooks';

type FileableContextMenuProps = DropdownProps &
	PropsWithChildren<{
		fileable: Fileable;
		disabled?: boolean;
	}>;

export const FileableContextMenu: FC<FileableContextMenuProps> = ({ fileable, children, disabled, ...dropdownProps }) => {
	const { account } = useAccount();
	const history = useHistory();
	const location = useLocation();
	const { download: downloadFile } = useFileUtils();
	const { toggleNotifications, toggleFavorite } = useFileableHelpers(fileable);
	const { open = null } = useUrlSearch();
	const { view } = useView()!;

	const fileManagerContext = useContext(FileManagerContext);

	const [dialogPermissionsOpened, setDialogPermissionsOpened] = useState(false);
	const [dialogMoveOpened, setDialogMoveOpened] = useState(false);
	const [dialogDeleteOpened, setDialogDeleteOpened] = useState(false);
	const [dialogUnlinkOpened, setDialogUnlinkOpened] = useState(false);
	const [dialogCopyOpened, setDialogCopyOpened] = useState(false);
	const [dialogRenameOpened, setDialogRenameOpened] = useState(false);
	const [dialogSettingsOpened, setDialogSettingsOpened] = useState(false);
	const [dialogShareSecureSpaceOpened, setDialogShareSecureSpaceOpened] = useState(false);
	const [dialogDownloadFolderOpened, setDialogDownloadFolderOpened] = useState(false);
	const [dialogPreviewOpened, setDialogPreviewOpened] = useState(false);
	const [dialogSyncFileableOpened, setDialogSyncFileableOpened] = useState(false);

	const preview = async () => {
		if (fileable instanceof File && fileable.isPreviewable()) {
			setDialogPreviewOpened(true);
		} else if (fileable instanceof File && fileable.current.ExternalLink !== null) {
			history.push(fileable.getUrl('view'), { view: fileable });
		} else if (fileable instanceof File && !fileable.isPreviewable()) {
			downloadFile(fileable);
		}
	};

	const download = async () => {
		if (fileable instanceof Folder) {
			setDialogDownloadFolderOpened(true);
		} else if (fileable instanceof File) {
			downloadFile(fileable);
		}
	};

	const onDeleted = (event: DeletedEvent) => {
		if (view instanceof File && event.sourceFiles.some(file => file.Id === view.Id)) {
			history.push('/files');
		}
		if (view instanceof Folder && event.sourceFolders.some(folder => folder.ID === view.ID)) {
			history.push('/files');
		}
	};

	const isCurrentView = (view instanceof Folder || view instanceof File) && view['@type'] === fileable['@type'] && view.getKey() === fileable.getKey();

	// Open when selected
	useEffect(() => {
		if (!isCurrentView) {
			return;
		}

		if (open === 'permissions') {
			setDialogPermissionsOpened(true);
		} else if (open === 'settings') {
			setDialogSettingsOpened(true);
		}
	}, [open, isCurrentView]);

	if (disabled) {
		return Children.only(children);
	}

	return (
		<>
			<Dropdown {...dropdownProps}>
				{Children.only(children)}
				<DropdownGroup>
					{fileable instanceof Folder && fileable.SecuredSpace && fileable.pivot?.Permissions === Permissions.ReadWrite && account?.hasFullAccess() && (
						<DropdownItem icon="share-alt" onClick={() => setDialogShareSecureSpaceOpened(true)}>
							<FormattedMessage id="file-manager.share_externally" />
						</DropdownItem>
					)}

					{fileable instanceof File && (
						<DropdownItem icon="eye" onClick={preview} disabled={!fileable.isViewableBy(account)}>
							<FormattedMessage id="file-manager.view" />
						</DropdownItem>
					)}

					<DropdownItem icon="cloud-download" onClick={download} disabled={fileable instanceof File && !fileable.isDownloadableBy(account)}>
						<FormattedMessage id="file-manager.download" />
					</DropdownItem>

					{fileable instanceof File && fileable.pivot?.Permissions === Permissions.ReadWrite && account?.hasFullAccess() && (
						<DropdownItem icon="share-alt" onClick={() => setDialogPermissionsOpened(true)}>
							<FormattedMessage id="file-manager.share" />
						</DropdownItem>
					)}
				</DropdownGroup>

				<DropdownDivider />

				{account?.hasFullAccess() && (
					<>
						<DropdownGroup>
							{fileable.pivot?.Permissions === Permissions.ReadWrite && account!.hasFullAccess() && (
								<DropdownItem icon="users" onClick={() => setDialogPermissionsOpened(true)} disabled={fileable instanceof File && fileable.current.AVStatus === 'infected'}>
									<FormattedMessage id="file-manager.permissions" />
								</DropdownItem>
							)}

							{fileable.pivot?.Permissions === Permissions.ReadWrite && account!.hasFullAccess() && (
								<DropdownItem icon="edit" onClick={() => setDialogRenameOpened(true)}>
									<FormattedMessage id="file-manager.rename" />
								</DropdownItem>
							)}

							{fileable.pivot?.Permissions === Permissions.ReadWrite && account!.hasFullAccess() && (
								<DropdownItem onClick={() => setDialogMoveOpened(true)} icon="arrow-right">
									<FormattedMessage id="file-manager.move" />
								</DropdownItem>
							)}

							{account!.hasFullAccess() && (
								<DropdownItem icon="copy" onClick={() => setDialogCopyOpened(true)}>
									<FormattedMessage id="file-manager.copy" />
								</DropdownItem>
							)}

							{fileable.pivot?.Permissions === Permissions.ReadWrite && account!.hasFullAccess() && (
								<DropdownItem icon="cog" onClick={() => setDialogSettingsOpened(true)}>
									<FormattedMessage id="file-manager.settings" />
								</DropdownItem>
							)}
						</DropdownGroup>

						{fileable.pivot?.Permissions === Permissions.ReadWrite && fileable instanceof Folder && account!.hasFullAccess() && account!.isAdmin() && (
							<>
								<DropdownDivider />
								<DropdownGroup>
									<DropdownItem onClick={() => setDialogSyncFileableOpened(true)} iconElement={<SharePointIcon />}>
										<FormattedMessage id="file-manager.sync" />
									</DropdownItem>
								</DropdownGroup>
							</>
						)}

						<DropdownDivider />
					</>
				)}

				<DropdownGroup>
					<DropdownItem icon={fileable.pivot?.Notify ? 'bell-slash' : 'bell'} onClick={() => toggleNotifications(!fileable.pivot?.Notify)}>
						{fileable.pivot?.Notify ? <FormattedMessage id="file-manager.disable_alerts" /> : <FormattedMessage id="file-manager.enable_alerts" />}
					</DropdownItem>

					<DropdownItem onClick={() => toggleFavorite(!fileable.pivot?.Favorite)} icon={fileable.pivot?.Favorite ? ['far', 'star'] : 'star'}>
						{fileable.pivot?.Favorite ? <FormattedMessage id="file-manager.remove_favorite" /> : <FormattedMessage id="file-manager.add_favorite" />}
					</DropdownItem>
				</DropdownGroup>
				<DropdownDivider />
				<DropdownGroup>
					{fileable.pivot?.Permissions === Permissions.ReadWrite && (
						<DropdownItem icon="trash" onClick={() => setDialogDeleteOpened(true)}>
							<FormattedMessage id="delete" />
						</DropdownItem>
					)}
					{fileable.pivot?.Permissions === Permissions.ReadOnly && (
						<DropdownItem icon="unlink" onClick={() => setDialogUnlinkOpened(true)}>
							<FormattedMessage id="file-manager.remove_self" />
						</DropdownItem>
					)}
				</DropdownGroup>
			</Dropdown>

			{dialogPreviewOpened && <DialogPreview file={fileable as File} onAfterClose={() => setDialogPreviewOpened(false)} showNavigation={!isCurrentView} />}

			{dialogDownloadFolderOpened && <DialogDownloadFileables onAfterClose={() => setDialogDownloadFolderOpened(false)} items={[fileable]} />}

			{dialogCopyOpened && <DialogCopyFileables onCopied={() => fileManagerContext?.setSelection(undefined)} onAfterClose={() => setDialogCopyOpened(false)} items={[fileable]} />}

			{dialogDeleteOpened && <DialogDeleteFileables onAfterClose={() => setDialogDeleteOpened(false)} items={[fileable]} onDeleted={onDeleted} />}

			{dialogUnlinkOpened && <DialogUnlinkFileable onAfterClose={() => setDialogUnlinkOpened(false)} fileable={fileable} />}

			{dialogMoveOpened && <DialogMoveFileables onMoved={() => fileManagerContext?.setSelection(undefined)} onAfterClose={() => setDialogMoveOpened(false)} fileables={[fileable]} />}

			{dialogPermissionsOpened && (
				<DialogPermissions
					onAfterClose={() => {
						setDialogPermissionsOpened(false);
						history.replace(location.pathname);
					}}
					item={fileable}
				/>
			)}

			{dialogRenameOpened && <DialogRenameFileable onAfterClose={() => setDialogRenameOpened(false)} fileable={fileable} />}

			{dialogSettingsOpened && fileable instanceof File && <DialogFileSettings onAfterClose={() => setDialogSettingsOpened(false)} file={fileable} />}

			{dialogSettingsOpened && fileable instanceof Folder && <DialogFolderSettings onAfterClose={() => setDialogSettingsOpened(false)} folder={fileable} />}

			{dialogShareSecureSpaceOpened && fileable instanceof Folder && fileable.SecuredSpace && (
				<DialogShareSecureSpace onAfterClose={() => setDialogShareSecureSpaceOpened(false)} folder={fileable} />
			)}

			{dialogSyncFileableOpened && fileable instanceof Folder && <DialogSyncFileable onAfterClose={() => setDialogSyncFileableOpened(false)} fileable={fileable} />}
		</>
	);
};
