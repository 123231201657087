import type { Fileable } from '@types';
import { createContext, type Dispatch, type SetStateAction } from 'react';
import type { QueryKey } from 'react-query';
import type { MessagesFilters } from '../types';

export const MessageListContext = createContext<{
	fileable: Fileable | null;
	order: string;
	onOrderChanged: (order: string) => void;
	filters: MessagesFilters;
	setFilters: Dispatch<SetStateAction<MessagesFilters>>;
	limit: number;
	queryKey: QueryKey;
}>({
	fileable: null,
	order: 'newest',
	onOrderChanged: () => {},
	filters: {},
	setFilters: () => {},
	limit: 10,
	queryKey: []
});
