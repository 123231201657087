import { PlanBlock } from '@components/Organization';
import { Alert, Button, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import { useSubscriptionUpgradeMutation } from '@state/queries/subscriptions';
import type { Plan } from '@types';
import { type FC, type FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';

type DialogUpgradePlanProps = Omit<ModalProps, 'isOpen'> & {
	plan: Plan;
};

export const DialogUpgradePlan: FC<DialogUpgradePlanProps> = ({ plan, ...modalProps }) => {
	const { locale } = useIntl();
	const { account } = useAccount();
	const [isOpen, setIsOpen] = useState(true);

	const { mutateAsync: upgrade, isLoading: isUpgrading } = useSubscriptionUpgradeMutation();

	const onSubmit = async (e: FormEvent) => {
		e.preventDefault();
		try {
			await upgrade(plan);
			setIsOpen(false);
			toast.success(<FormattedMessage id="plans.subscribed" />);
		} catch {
			// TODO: Show error message to user
		}
	};

	return (
		<Modal isOpen={isOpen} {...modalProps} closeable={true} onSubmit={onSubmit}>
			<ModalHeaderOnlyTitle>
				<FormattedMessage id="upgrade_to_plan" values={{ plan: plan.Name }} />
			</ModalHeaderOnlyTitle>
			<ModalBody>
				<div className="grid grid-cols-1 gap-6 mb-6 sm:grid-cols-9">
					<div className="sm:col-span-4">
						<h2 className="mb-2 text-xs font-bold text-center">
							<FormattedMessage id="current_plan" />
						</h2>
						<PlanBlock plan={account!.business.plan} interval={account!.business.subscription!.Interval} />
					</div>
					<div className="place-self-center">
						<FontAwesomeIcon icon="arrow-right" className="rotate-90 sm:rotate-0" />
					</div>
					<div className="sm:col-span-4">
						<h2 className="mb-2 text-xs font-bold text-center">
							<FormattedMessage id="new_plan" />
						</h2>
						<PlanBlock plan={plan} active={true} interval={account!.business.subscription!.Interval} />
					</div>
				</div>
				<Alert variant={Variant.info}>
					<p>
						<FormattedMessage id="upgrade_plan_downgrade_warning" values={{ a: msg => <a href={`${import.meta.env.VITE_SITE_URL}/${locale}/contact`}>{msg}</a> }} />
					</p>
				</Alert>
			</ModalBody>
			<ModalFooter>
				<Button variant={Variant.primary} type="submit" loading={isUpgrading} disabled={isUpgrading}>
					<FormattedMessage id="user-manager.upgrade-account" />
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
