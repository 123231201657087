import { ActivityList } from '@components/Activity';
import { DialogComposer } from '@components/DialogComposer';
import { DialogCreateFolder, DialogShareSecureSpace, FileList, FileManagerTabs, FileableProvider, useFileManagerPolicies, useSecureSpace } from '@components/FileManager';
import { FileRequestList } from '@components/FileRequest';
import { FileableHeader } from '@components/FileableHeader';
import { GuidedTour } from '@components/GuidedTour';
import { MfaBanner } from '@components/InAppMarketing';
import { LecsorCustomerSupportButton, LecsorOrderFormButton, LecsorPensionFundButton, LecsorUnitDetails } from '@components/Lecsor';
import { MessageList } from '@components/Message';
import { PaymentRequestDashboard } from '@components/PaymentRequest';
import { SignRequestDashboard } from '@components/SignRequest';
import { Button, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useView } from '@hooks/use-view';
import { useAccount } from '@hooks/useAccount';
import Folder from '@models/Folder';
import { MessagesMode, Permissions } from '@types';
import { type FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

type FolderViewPageProps = {
	folder: Folder;
};

export const FolderViewPage: FC<FolderViewPageProps> = ({ folder }) => {
	const { account } = useAccount();
	const { view, tab, setEnableFullScreenDragNDrop } = useView();
	const { canUploadFiles } = useFileManagerPolicies(account!, view);

	const [dialogComposerOpened, setDialogComposerOpened] = useState(false);
	const [dialogCreateSecureSpaceOpened, setDialogCreateSecureSpaceOpened] = useState(false);
	const [lastSecureSpaceCreated, setLastSecureSpaceCreated] = useState<Folder>();
	const [dialogShareSecureSpaceOpened, setDialogShareSecureSpaceOpened] = useState(false);

	const secureSpace = useSecureSpace(folder);

	const canViewMessages = secureSpace?.canViewMessages() || (secureSpace === null && folder.canViewMessages());
	const canCreateSecureSpace = account!.hasFullAccess() && folder?.pivot?.Permissions === Permissions.ReadWrite;

	const showComposeButton = useMemo(() => {
		if (!canViewMessages) {
			return false;
		}
		const messagesMode = secureSpace?.MessagesMode ?? MessagesMode.OpenToAll;
		if (messagesMode === MessagesMode.OpenToAll) {
			return true;
		}
		return secureSpace?.pivot?.Permissions === Permissions.ReadWrite;
	}, [secureSpace, canViewMessages]);

	const printTimeline = () => {
		window.open('/print?page=' + encodeURIComponent(folder.getUrl('timeline')));
	};

	useEffect(() => {
		setEnableFullScreenDragNDrop(canUploadFiles);
	}, [canUploadFiles, setEnableFullScreenDragNDrop]);

	return (
		<FileableProvider fileable={folder}>
			<div className="flex flex-col px-6 pt-6 pb-2 bg-white border-b border-gray-200">
				<MfaBanner />

				<FileableHeader fileable={folder} />
				<div className="flex items-center mb-8 space-x-4">
					{showComposeButton && (
						<Button variant={Variant.primary} shadow onClick={() => setDialogComposerOpened(true)} id="step-send">
							<FontAwesomeIcon icon={['fad', 'edit']} className="mr-3" />
							<FormattedMessage id="file-manager.compose" />
						</Button>
					)}

					{secureSpace && <LecsorCustomerSupportButton secureSpace={secureSpace} />}
					{secureSpace && <LecsorPensionFundButton secureSpace={secureSpace} />}
					{secureSpace && <LecsorOrderFormButton secureSpace={secureSpace} />}
					{secureSpace && <LecsorUnitDetails secureSpace={secureSpace} />}
				</div>
				{/* Tabs */}
				{account!.hasFullAccess() && <FileManagerTabs fileable={folder} />}
			</div>
			{account!.hasFullAccess() && (
				<div className="py-8 mx-auto sm:px-6 max-w-7xl">
					{tab === 'files' && <FileList view={folder} />}
					{tab === 'comments' && canViewMessages && (
						<div className="max-w-4xl mx-auto">
							<MessageList item={folder} />
						</div>
					)}
					{tab === 'timeline' && (
						<div className="max-w-4xl px-6 mx-auto">
							<div className="flex justify-end space-x-4">
								<Button variant={Variant.dark} shadow onClick={printTimeline} circle>
									<FontAwesomeIcon icon="print" />
								</Button>
							</div>
							<ActivityList fileable={folder} />
						</div>
					)}
					{tab === 'file-requests' && canViewMessages && <FileRequestList folder={folder} />}
					{tab === 'sign-requests' && <SignRequestDashboard view={folder} />}
					{tab === 'payments' && secureSpace && <PaymentRequestDashboard view={folder} onCreate={() => setDialogComposerOpened(true)} />}
				</div>
			)}

			{account?.hasFullAccess() === false && (
				<div className="py-8 mx-auto sm:px-6 max-w-7xl">
					<FileList view={folder} />
					{canViewMessages && (
						<div className="mx-auto mt-8">
							<MessageList item={folder} />
						</div>
					)}
				</div>
			)}

			{dialogComposerOpened && <DialogComposer onAfterClose={() => setDialogComposerOpened(false)} />}
			{dialogCreateSecureSpaceOpened && (
				<DialogCreateFolder
					onCreated={folders => {
						if (folders.length === 1) {
							setLastSecureSpaceCreated(folders[0]);
						}
					}}
					onAfterClose={() => setDialogCreateSecureSpaceOpened(false)}
					parents={[folder]}
					secureSpace
				/>
			)}
			{lastSecureSpaceCreated && <DialogShareSecureSpace onAfterClose={() => setLastSecureSpaceCreated(undefined)} folder={lastSecureSpaceCreated} />}
			{dialogShareSecureSpaceOpened && folder.SecuredSpace && <DialogShareSecureSpace onAfterClose={() => setDialogShareSecureSpaceOpened(false)} folder={folder} />}

			<GuidedTour name="messages" enabled={(canViewMessages || canCreateSecureSpace) && !account?.hasFullAccess()} />
		</FileableProvider>
	);
};
