import * as React from "react";
const SvgLabels = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 387.78 337.89", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M173.81 163c76.83-78.07 211.77-79.76 257.32 35.37 12.62 31.89 10.19 78.06-19.3 101.39-39.52 31.27-76-10.06-117.35-5.15-41.19 4.88-67.8 52.59-110.65 56.26C146.39 354 106.7 332.53 84 303.9c-24.89-31.44-40.05-72.12 1.9-95.07 22.1-12.08 51.69-6.5 65.68-14.56 10.76-6.18 13.16-22.09 22.23-31.27z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M173.81 163c76.83-78.07 211.77-79.76 257.32 35.37 12.62 31.89 10.19 78.06-19.3 101.39-39.52 31.27-76-10.06-117.35-5.15-41.19 4.88-67.8 52.59-110.65 56.26C146.39 354 106.7 332.53 84 303.9c-24.89-31.44-40.05-72.12 1.9-95.07 22.1-12.08 51.69-6.5 65.68-14.56 10.76-6.18 13.16-22.09 22.23-31.27z", style: {
  opacity: 0.9,
  fill: "#fff"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("ellipse", { cx: 250, cy: 416.24, rx: 193.89, ry: 11.32, style: {
  fill: "#f5f5f5"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { transform: "rotate(-87.46 356.49 154.434)", style: {
  fill: "#1b9ff1"
}, d: "M427.8 169.42h7.23v1.04h-7.23z" }), /* @__PURE__ */ React.createElement("path", { transform: "rotate(-87.46 357.34 135.486)", style: {
  fill: "#1b9ff1"
}, d: "M422.62 150.47h19.29v1.04h-19.29z" }), /* @__PURE__ */ React.createElement("path", { d: "M415.75 257.44H215.94a8.2 8.2 0 0 1-8.24-8.63l6.74-150.51a9.14 9.14 0 0 1 9-8.63h199.83a8.19 8.19 0 0 1 8.24 8.63l-6.74 150.51a9.15 9.15 0 0 1-9.02 8.63z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M414.28 257.44H215.94a8.2 8.2 0 0 1-8.24-8.63l6.74-150.51a9.14 9.14 0 0 1 9-8.63H421.8a8.21 8.21 0 0 1 8.25 8.63l-6.75 150.51a9.15 9.15 0 0 1-9.02 8.63z", style: {
  opacity: 0.5,
  fill: "#fff"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.4,
  fill: "#1b9ff1"
}, d: "M324.75 166.83h34.31l-3.02 50.49-35.76-.32zM238.12 110.46l35.77.16-4.24 50.17-35.77-.16zM328.42 109.7l34.54-1.46-.77 49.93-35.98 1.52zM371.61 146.66l34.43 3.6-4.16 49.28-35.15-2.76zM288.85 107.67l27.2.41-2.45 38.9-27.52-.74zM241.34 210.85l25.96-.49-1.69 39.64-27.03-.57zM387.8 102.15l27.02-.07-2.6 38.61-27.55.07zM309.27 165.36l-26-2.18-6.12 38.34 27.25 3.14z", transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#fff"
}, d: "m323.35 165.36 35.76.33-4.47 50.16-35.76-.32zM238.08 109.17l35.76.33-4.47 50.16-35.76-.32zM327.01 108.24l35.99-1.52-2.21 49.98-35.98 1.52zM370.21 145.19l35.45 3.71-4.88 50.13-35.45-3.71zM288.31 106.2l27.52.74-2.76 38.58-27.52-.74zM239.94 209.39l27.52.74-2.76 38.57-27.52-.74zM386.87 101.6l27.55-.07-3.13 38.62-27.55.07zM309.11 164.85l-27.24-3.14-6.12 38.34 27.25 3.14z", transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M290.16 218.42S290 228.89 288.5 232c4.89 1.4 14.32 1.38 14.32 1.38s2.06-10.07 1.72-14c-2.81-.67-14.38-.96-14.38-.96z", style: {
  opacity: 0.4,
  fill: "#1b9ff1"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M290.16 218.42S289 228.87 287.51 232a96.68 96.68 0 0 0 13.35 2.21s4.16-10.87 3.68-14.86c-2.81-.64-14.38-.93-14.38-.93z", style: {
  fill: "#fff"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M363.79 220.13s5.67 14.34 5.35 19.4c7.68-.86 21.05-5.27 21.05-5.27s-2.86-15.79-5.61-21c-4.3.74-20.79 6.87-20.79 6.87z", style: {
  opacity: 0.4,
  fill: "#1b9ff1"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M361.94 219.05s4.76 18.42 4.44 23.48c7.68-.87 22.5-6.81 22.5-6.81s-2-18.82-5-24c-4.26.77-21.94 7.33-21.94 7.33z", style: {
  fill: "#fff"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M380.67 216.12a1.73 1.73 0 0 1-1.72 1.64 1.55 1.55 0 0 1-1.57-1.64 1.74 1.74 0 0 1 1.72-1.65 1.56 1.56 0 0 1 1.57 1.65z", style: {
  opacity: 0.4,
  fill: "#1b9ff1"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M270.74 109.52a3 3 0 0 1-2.93 2.8 2.65 2.65 0 0 1-2.68-2.8 3 3 0 0 1 2.93-2.8 2.66 2.66 0 0 1 2.68 2.8zM289.33 162.61a2.1 2.1 0 0 1-2.08 2 1.88 1.88 0 0 1-1.9-2 2.1 2.1 0 0 1 2.08-2 1.88 1.88 0 0 1 1.9 2zM374 122.86a2.12 2.12 0 0 1-2.08 2 1.9 1.9 0 0 1-1.91-2 2.11 2.11 0 0 1 2.08-2 1.9 1.9 0 0 1 1.91 2z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M154.65 132.74c1.08 5.51 2.15 15.6-1.7 19.28a28 28 0 0 0 13.28 11.21c7.37-4.61 3.84-11.21 3.84-11.21-6.14-1.47-6-6-4.91-10.31z", style: {
  fill: "#b55b52"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M147.34 134.62c-4.53-.46-18 18.57 8.08 27.06-4.24-3.93-6.35-7.74-5.24-12.24a38.77 38.77 0 0 0 .54-10.58s1.55-3.74-3.38-4.24z", style: {
  fill: "#263238"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M150.1 157.77a11.79 11.79 0 0 1-2.16-3.6 5.42 5.42 0 0 1 5.86-7.17 32.82 32.82 0 0 0 8.47.06c6.38-.79 9.72 2.42 13.17 9.3 0 0-1.61 3-6.44 4.17s-18.9-2.76-18.9-2.76z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M178.22 127.88c-.17.73.07 1.41.55 1.52.48.11 1-.38 1.18-1.11.18-.73-.07-1.41-.55-1.52-.48-.11-1 .38-1.18 1.11z", style: {
  fill: "#263238"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "m179.18 126.76 1.87-.09s-1.22 1.15-1.87.09zM180.36 123.78a.44.44 0 0 0 .36-.72 4.36 4.36 0 0 0-3.67-1.62.44.44 0 0 0-.39.49.45.45 0 0 0 .49.39 3.47 3.47 0 0 1 2.9 1.31.46.46 0 0 0 .31.15z", style: {
  fill: "#263238"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M177.31 130.11a19.92 19.92 0 0 0 1.48 5.28 3.23 3.23 0 0 1-2.71-.14z", style: {
  fill: "#a02724"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M154.37 119c-1.6 9.08-2.47 12.88.94 18.56 5.13 8.54 16.62 8.52 20.91.11 3.85-7.57 5.83-21.14-2.07-26.8a12.57 12.57 0 0 0-19.78 8.13Z", style: {
  fill: "#b55b52"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M170.5 126.2c2.75-1.12 9.29-3.6 10.89-7.51 1.6-3.91-5-14.35-10.64-12.29-14.69-3.13-24.93 3.9-24.87 15 .06 11.6 4.67 18.2 12.23 19.92 7.56 1.72 14.13-6.2 12.39-15.12z", style: {
  fill: "#263238"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M149.39 129.39c-3-.41-4.6 2.63-3.51 5.15 1.09 2.52 4.73 6.51 6.13 6.51 1.4 0 4.17-1 4.87-2.57.7-1.57-5.47-8.8-7.49-9.09z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M168 125.36a6.68 6.68 0 0 0 .82 5.07c1.25 2 3.46 1.27 4.44-.75.88-1.81 1.28-5-.61-6.34a3 3 0 0 0-4.65 2.02zM146.93 161.28V164.39q0 1.63.15 3.27a54.6 54.6 0 0 0 .84 6.43 31.55 31.55 0 0 0 4.5 11.42c.82 1.06.5.89 1.2 1.37A11.15 11.15 0 0 0 156 188a44.18 44.18 0 0 0 6.3 1.63c4.45.84 9.16 1.42 13.78 1.89l.13 4.71a80.91 80.91 0 0 1-14.78.57 42 42 0 0 1-7.72-1.08 19.89 19.89 0 0 1-4.23-1.54 11.34 11.34 0 0 1-2.33-1.62c-.2-.18-.4-.39-.6-.6l-.37-.43-.68-.81a31.83 31.83 0 0 1-4.26-7.06 40.68 40.68 0 0 1-2.5-7.57 52.35 52.35 0 0 1-1.21-7.71 71.46 71.46 0 0 1-.17-3.88v-1.95c0-.65.05-1.26.12-2.09z", style: {
  fill: "#b55b52"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "m175.79 191.17 9.91-1.56-4.88 8.08s-6-.25-7.64-3.72z", style: {
  fill: "#b55b52"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#b55b52"
}, d: "m180.82 197.69 4.88-8.08 4.9 2.72.03 6.01z", transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M211.49 217.92h-31a1.18 1.18 0 0 1-1.23-1.63l14.31-41.69a2.6 2.6 0 0 1 2.34-1.62h31a1.17 1.17 0 0 1 1.23 1.62l-14.31 41.69a2.63 2.63 0 0 1-2.34 1.63z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M210.94 216.6H181a.65.65 0 0 1-.68-.91l14.14-41.23a1.48 1.48 0 0 1 1.31-.91h30a.66.66 0 0 1 .69.91l-14.15 41.23a1.48 1.48 0 0 1-1.37.91z", style: {
  fill: "#fff"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "m137.67 154 10 4.05 5.33 5.91.69 8.15s-11.65 8.84-17.84 8c-2.14-14.98-.8-23.22 1.82-26.11z", style: {
  fill: "#263238"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M186.86 160.61c.15 6.73-.8 20.74-5.53 49.83h-37.71c.63-15.35 1.38-25-6-56.46A103.77 103.77 0 0 1 153 152a119.16 119.16 0 0 1 17.12 0c3.69.34 7.63.93 10.63 1.45a7.39 7.39 0 0 1 6.11 7.16z", style: {
  fill: "#263238"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "m179.49 163.23 6.71 10.93c-.35 3.89-.86 8.55-1.59 14.12l-5.5-3.25c-2.25-16.54.38-21.8.38-21.8z", style: {
  opacity: 0.2
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M187.48 163.41q2.09 6.57 4 13.2l1.92 6.63 1.85 6.65.91 3.33.45 1.67a12 12 0 0 0 .38 1.26 7.53 7.53 0 0 0 .75 1.56c.07.1.06.12 0 .15s-.07.09.05.11a3.13 3.13 0 0 0 1.67-.47 15.33 15.33 0 0 0 2.34-1.56c.8-.64 1.59-1.34 2.37-2.08s1.55-1.57 2.25-2.31l4 2.52a31.45 31.45 0 0 1-1.84 3.29 26 26 0 0 1-2.23 3.1 19.21 19.21 0 0 1-2.94 2.89 10.51 10.51 0 0 1-4.68 2.16 7.64 7.64 0 0 1-3.37-.19 8.26 8.26 0 0 1-2.95-1.58 12.51 12.51 0 0 1-3.13-4.12 15.12 15.12 0 0 1-.82-2l-.56-1.65-1.12-3.29-2.19-6.6-2.11-6.63a485.44 485.44 0 0 1-4.08-13.31z", style: {
  fill: "#b55b52"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M177.24 161c1.59-3.8 6.57-6.25 6.57-6.25 5.36 3.49 7.17 5.14 10.73 22.89-5.19 4.54-13.79 4.54-13.79 4.54l-4.64-12s-.46-5.35 1.13-9.18z", style: {
  fill: "#263238"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "m205.19 193.31 3.56-8.68 4.36 8.37s-3.3 5-7.14 4.6z", style: {
  fill: "#b55b52"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M215.32 186.38a.53.53 0 0 1-.45-.23l-10.39-14.22a.57.57 0 0 1 .13-.79.56.56 0 0 1 .78.13l10.38 14.22a.55.55 0 0 1-.45.89z", style: {
  fill: "#263238"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#b55b52"
}, d: "m213.11 193-4.36-8.37 7.77-1.69 2.29 5.04zM181.33 210.44s9.42 71.29 12.64 99.43c4.75 41.56 15.36 97 15.36 97L203 408s-22.12-54.25-25.46-95.33C167.05 282.41 152 210.44 152 210.44Z", transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "m208.82 400.06-8.19 4a.74.74 0 0 0-.4.9l2.64 7.82a1.53 1.53 0 0 0 2.13.77c3.36-1.71 3.37-1.94 7.64-4 2.63-1.29 6.9-3.24 10.53-5 3.63-1.76 2.07-5.27.4-4.84-7.48 1.94-9.83.89-13 .19a2.62 2.62 0 0 0-1.75.16z", style: {
  fill: "#263238"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M171.48 210.44s-6.12 67.52-11.27 95.37c-5.41 29.25-9.45 98.95-9.51 101.08h-6.43s-8.8-78.35-.59-104.57c1.36-32-.06-91.88-.06-91.88z", style: {
  fill: "#b55b52"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M151.46 405.58h-7.32a.74.74 0 0 0-.75.64l-1.08 8.37a1.53 1.53 0 0 0 1.53 1.67c3.68-.06 2.21-.28 6.86-.28 2.86 0 7 .3 11 .3s4.11-3.91 2.46-4.26c-7.4-1.59-8.59-3.78-11.11-5.86a2.47 2.47 0 0 0-1.59-.58z", style: {
  fill: "#263238"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "m143.76 300.38 17.07 1.88c.69-4.08 1.4-8.79 2.09-13.87l-18.81-1.93c-.11 4.95-.19 9.66-.35 13.92zM191.74 291.35l-21.24-2.18c1.4 5.21 2.8 10.14 4.15 14.6l18.84 2.06c-.49-4.23-1.09-9.15-1.75-14.48z", style: {
  opacity: 0.2
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "m181.33 210.44 13.6 93.14-52.88-6.7c5.16-39.87-10.51-71.84 1.57-86.44z", style: {
  fill: "#1b9ff1"
}, transform: "translate(-56.11 -89.67)" }), /* @__PURE__ */ React.createElement("path", { d: "M148.58 229.55a58.91 58.91 0 0 0 19.74 11.33c-11.88-.13-17.39-4.35-19.74-11.33z", style: {
  opacity: 0.2
}, transform: "translate(-56.11 -89.67)" }));
export default SvgLabels;
