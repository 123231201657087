import classNames from 'classnames';
import type { FC, HTMLAttributes, SVGAttributes } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type ProgressBarProps = Omit<HTMLAttributes<HTMLDivElement>, 'role' | 'style' | 'aria-valuenow' | 'aria-valuemin' | 'aria-valuemax'> & {
	current?: number;
	max?: number;
	reverseColors?: boolean;
	unlimited?: boolean;
	customBackground?: string;
};

const ProgressBar: FC<ProgressBarProps> = ({ current = 0, max = 100, unlimited = false, className = '', customBackground = '', reverseColors = false, children, ...props }) => {
	let percentage = (current / max) * 100;

	let progressBarColorClass = reverseColors ? 'from-red-400 to-red-500 bg-gradient-to-br' : 'from-green-400 to-green-500 bg-gradient-to-br';

	if (percentage >= 50) {
		progressBarColorClass = 'from-yellow-400 to-yellow-500 bg-gradient-to-br';
	}

	if (percentage >= 75) {
		progressBarColorClass = reverseColors ? 'from-green-400 to-green-500 bg-gradient-to-br' : 'from-red-400 to-red-500 bg-gradient-to-br';
	}

	if (unlimited) {
		progressBarColorClass = 'from-green-400 to-green-500 bg-gradient-to-br';
		percentage = 100;
	}

	let properties = customBackground !== '' ? `flex overflow-hidden leading-none text-xs rounded-full ${customBackground}` : `flex overflow-hidden leading-none text-xs rounded-full bg-gray-200`;

	let innerStatus = percentage >= 33;

	return (
		<div className={`${properties} ${className}`}>
			<div
				className={`flex align-middle items-center h-4 overflow-hidden text-white whitespace-nowrap text-center ${children !== undefined ? 'px-3' : ''} ${progressBarColorClass}`}
				role="progressbar"
				style={{ width: `${percentage}%` }}
				aria-valuenow={percentage}
				aria-valuemin={0}
				aria-valuemax={max}
				{...props}>
				{innerStatus && children}
			</div>
			{!innerStatus && <span className="inline-flex items-center px-2 text-black">{children}</span>}
		</div>
	);
};

type CircleProgressBarProps = SVGAttributes<SVGElement> & {
	current?: number;
	max?: number;
	showPercent?: boolean;
	reverseColors?: boolean;
};

export const CircleProgressBar: FC<CircleProgressBarProps> = ({ current = 0, max, className, reverseColors = false, showPercent = false, ...svgProps }) => {
	const { formatNumber } = useIntl();

	let strokeDashoffset = max !== undefined ? ((100 - (current / max) * 100) / 100) * Math.PI * (90 * 2) : 0;

	let percentage = max !== undefined ? (current / max) * 100 : 100;
	let progressBarColorClass = reverseColors ? 'stroke-red-500' : 'stroke-green-500';

	if (percentage >= 50) {
		progressBarColorClass = 'stroke-yellow-500';
	}

	if (percentage >= 75) {
		progressBarColorClass = reverseColors ? 'stroke-green-500' : 'stroke-red-500';
	}

	if (max === undefined) {
		progressBarColorClass = 'stroke-green-500';
		strokeDashoffset = 0;
	} else if (percentage > 100) {
		strokeDashoffset = 0;
	}

	return (
		<div className="relative">
			{showPercent && (
				<div className="absolute inset-0 grid place-items-center z-[1]">
					{max === undefined ? (
						<span className="text-xs font-bold">
							<FormattedMessage id="usage.unlimited" />
						</span>
					) : (
						<span className="text-xs font-bold">{formatNumber(current / max, { style: 'percent' })}</span>
					)}
				</div>
			)}
			<svg
				viewBox="0 0 200 200"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				style={{ strokeDashoffset: 0, transition: 'stroke-dashoffset 1s linear' }}
				className={classNames('-rotate-90 stroke-[16]', className)}
				{...svgProps}>
				<circle className="stroke-gray-100" r="90" cx="100" cy="100" fill="transparent" stroke-dasharray="565.48" stroke-dashoffset="0" />
				<circle className={progressBarColorClass} r="90" cx="100" cy="100" fill="transparent" stroke-dasharray="565.48" stroke-dashoffset={strokeDashoffset} />
			</svg>
		</div>
	);
};

export default ProgressBar;
