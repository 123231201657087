import GateMultiFactorAuthentication from '@components/GateMultiFactorAuthentication';
import { useHistory, useLocation } from 'react-router-dom';

const Page = () => {
	const { state } = useLocation<{ next?: Location }>();
	const history = useHistory();

	const onConfirmed = () => {
		history.push(state?.next ?? '/');
	};

	return <GateMultiFactorAuthentication onConfirmed={onConfirmed} />;
};

export default Page;
