import { ContactOption, CreatableComboBox, EmailValueLabel } from '@components/ComboBox';
import { Composer, type ComposerRef, type ComposerState, type OnPostingEvent } from '@components/Message';
import { Alert, Button, Input, Intent, Row, Size, Variant } from '@convoflo/ui';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import Folder from '@models/Folder';
import type { Contact } from '@types';
import classNames from 'classnames';
import * as EmailValidator from 'email-validator';
import { useRef, useState, type FormEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { Redirect, useHistory } from 'react-router-dom';
import type { OnChangeValue } from 'react-select';
import type { WretchError } from 'wretch';

export type SendResult = {
	secureSpaces: Folder[];
	hasFiles: boolean;
	hasMessage: boolean;
	folderForFiles: Folder | null;
};

type CreateSecureSpaceMutation = {
	name: string;
	users?: Contact[];
	message?: string | null;
	markAsImportant?: boolean;
	showPreviewInEmail?: boolean;
};

export const OnboardingSecureSpacePage = () => {
	const { formatMessage } = useIntl();
	const { client } = useClient();
	const { account } = useAccount();
	const history = useHistory();

	const composer = useRef<ComposerRef>(null);

	const [tourStep, setTourStep] = useState(0);
	const [users, setUsers] = useState<Contact[]>([]);
	const [secureSpace, setSecureSpace] = useState(new Folder({ Name: formatMessage({ id: 'tour.default_es' }), SecuredSpace: true }));
	const [isLoading, setIsLoading] = useState(false);
	const [composerState, setComposerState] = useState<ComposerState>();

	const { mutateAsync: createSecureSpace } = useMutation<Folder, WretchError, CreateSecureSpaceMutation>(
		async ({ name, users = [], message = null, showPreviewInEmail = false, markAsImportant = false }) =>
			new Folder(
				await client
					.url('folders')
					.json({
						name,
						message,
						secured_space: true,
						scope: 'public',
						emails: users.map(user => user.Email),
						priority: markAsImportant ? 1 : 0,
						show_preview: showPreviewInEmail
					})
					.post()
					.json()
			),
		{
			onSuccess: () => {
				setIsLoading(false);
			}
		}
	);

	const onUsersChanged = (users: OnChangeValue<Contact, true>) => {
		setUsers(users.filter(() => true));
	};

	const onPosted = () => {
		history.replace('/onboarding/next-steps', { secureSpace });
	};

	const onBeforePosting = async ({ message, ...data }: OnPostingEvent) => {
		setIsLoading(true);

		const createdSecureSpace = await createSecureSpace({
			name: secureSpace?.Name || formatMessage({ id: 'tour.default_es' }),
			users,
			message,
			markAsImportant: data?.markAsImportant,
			showPreviewInEmail: data?.showPreview
		});

		setSecureSpace(createdSecureSpace);

		return createdSecureSpace;
	};

	const goHome = () => {
		history.replace('/onboarding/next-steps');
	};

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		composer.current?.post();
	};

	if (!account?.hasVerifiedEmail()) {
		return <Redirect to="/onboarding/verification" />;
	}

	if (!account?.hasFullAccess()) {
		return <Redirect to="/" />;
	}

	return (
		<div>
			{tourStep === 0 && (
				<>
					<header className="mb-8 space-y-2">
						<h1 className="text-3xl font-bold md:text-4xl">
							<FormattedMessage id="tour.step1.title" />
						</h1>
						<p className="text-gray-400">
							<FormattedMessage id="tour.step1.subtitle" />
						</p>
					</header>
					<Row className="mb-8">
						<Input
							size={Size.lg}
							block
							icon="shield"
							iconColor="text-green-500"
							autoComplete="password"
							placeholder={formatMessage({ id: 'tour.default_es' })}
							onChange={e => setSecureSpace(new Folder({ Name: e.target.value, SecuredSpace: true }))}
							type="text"
							id="name"
							value={secureSpace?.Name || formatMessage({ id: 'tour.default_es' })}
						/>
					</Row>
					<Alert variant={Variant.info} className="border">
						<p>
							<FormattedMessage id="tour.step1.text2" values={{ strong: msg => <strong className="text-bold">{msg}</strong> }} />
						</p>
					</Alert>
					<Row className="flex mt-12 space-x-4">
						<Button size={Size.lg} variant={Variant.primary} onClick={() => setTourStep(1)} animateIcon iconEnd="angle-right">
							<FormattedMessage id="continue" />
						</Button>

						<Button variant={Variant.light} disabled={composerState?.isDisabled || isLoading} intent={Intent.tertiary} type="button" onClick={goHome}>
							<FormattedMessage id="skip" />
						</Button>
					</Row>
				</>
			)}
			{tourStep === 1 && (
				<form onSubmit={onSubmit}>
					<header className="mb-8 space-y-2">
						<h1 className="text-3xl font-bold md:text-4xl">
							<FormattedMessage id="tour.step2.title" />
						</h1>
						<p className="text-gray-400">
							<FormattedMessage id="tour.step2.subtitle" />
						</p>
					</header>
					<Row>
						<CreatableComboBox
							className="mb-4"
							isDisabled={isLoading}
							value={users}
							getOptionValue={user => user.Email}
							components={{
								Option: ContactOption,
								MultiValueLabel: EmailValueLabel
							}}
							filterOption={({ data }, input) => (input ? data.Email.indexOf(input) >= 0 || (!!data.Name && data.Name.indexOf(input) >= 0) : true)}
							isOptionDisabled={user => users.some(u => u.Email === user.Email)}
							autoFocus
							isMulti
							onChange={onUsersChanged}
							isValidNewOption={input => EmailValidator.validate(input)}
							getNewOptionData={input => ({ Source: 'share', Email: input } as Contact)}
							placeholder={<FormattedMessage id="dialog-send.enter_email_addresses" />}
							formatCreateLabel={email => <FormattedMessage id="dialog-send.add_email" values={{ email }} />}
						/>
					</Row>
					<Composer
						ref={composer}
						fileable={secureSpace}
						queryKey={['onboarding']}
						disabled={isLoading}
						titleInputProps={{
							className: 'border border-gray-200 pt-2 border-b-0 rounded-t bg-white'
						}}
						inputProps={{
							placeholder: formatMessage({ id: 'comments.enter_message' }),
							className: 'border-t-0 rounded-t-none !border-gray-200'
						}}
						plugins={{
							audience: { enabled: false },
							paymentRequests: { enabled: false },
							fileRequests: { enabled: false },
							attachments: { enabled: true, enableFolderSelection: false },
							options: { enabled: true }
						}}
						onPosted={onPosted}
						onStateChanged={setComposerState}
						onBeforePosting={onBeforePosting}
					/>
					<Row className="flex mt-12 space-x-4">
						<Button type="submit" size={Size.lg} variant={Variant.primary} disabled={composerState?.isDisabled} animateIcon iconEnd="angle-right" loading={isLoading}>
							<FormattedMessage id="dialog-send.send" />
						</Button>

						<Button variant={Variant.light} disabled={composerState?.isPosting || isLoading} intent={Intent.tertiary} type="button" onClick={goHome}>
							<FormattedMessage id="skip" />
						</Button>
					</Row>
				</form>
			)}
		</div>
	);
};
