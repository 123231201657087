import { type Dispatch, type ReactNode, type SetStateAction, createContext } from 'react';
import type { Wretch } from 'wretch';
import type { FormDataAddon } from 'wretch/addons/formData';
import type { QueryStringAddon } from 'wretch/addons/queryString';

const ApiClient = createContext<
	| {
			client: QueryStringAddon & FormDataAddon & Wretch<FormDataAddon & QueryStringAddon, unknown, undefined>;
			validation: Record<string, ReactNode[]>;
			setValidation: Dispatch<SetStateAction<Record<string, string[]>>>;
			authToken: string | null;
			setAuthToken: (authToken: string | null) => void;
	  }
	| undefined
>(undefined);

export default ApiClient;
