import { AudienceAwareComposerPluginButton, type ComposerPlugin } from '@components/Message';
import { Intent, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import Collaborator from '@models/Collaborator';
import Folder from '@models/Folder';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DialogFileRequestOptions, type FileRequestOptions } from './DialogFileRequestOptions';
import isEmpty from 'lodash.isempty';

const emptyUsers: Collaborator[] = [];
const emptyReminders: number[] = [];

export const usePluginFileRequests: ComposerPlugin = ({ disabled }, { enabled = false } = {}, data) => {
	const { account } = useAccount();

	const [showOptions, setShowOptions] = useState(false);
	const [folder, setFolder] = useState<Folder>();
	const [users, setUsers] = useState<Collaborator[]>([]);
	const [reminders, setReminders] = useState<number[]>(account?.business.ReminderIntervals ?? emptyReminders);
	const [shared, setShared] = useState(true);

	const listenForDraft = useRef(true);

	const onOptionsChanged = ({ users, folder, reminders, shared }: FileRequestOptions) => {
		setUsers(users);
		setFolder(folder);
		setReminders(reminders);
		setShared(shared);
	};

	const onReset = async () => {
		setUsers(emptyUsers);
		setFolder(undefined);
		setReminders(account?.business.ReminderIntervals ?? emptyReminders);
		setShared(true);
		listenForDraft.current = true;
	};

	const isActive = users.length > 0;

	// From draft
	useEffect(() => {
		if (!listenForDraft.current) {
			return;
		}

		if (isEmpty(data?.['file-requests'])) {
			return;
		}

		setUsers(data['file-requests'].users);
		setFolder(new Folder(data['file-requests'].folder));
		setReminders(data['file-requests'].reminders);
		setShared(data['file-requests'].shared);
		listenForDraft.current = false;
	}, [data?.['file-requests']]);

	return {
		id: 'file-requests',
		data: {
			users,
			folder,
			reminders,
			shared
		},
		postData: () => {
			if (!enabled) {
				return {};
			}

			return {
				file_request_folder_id: folder?.ID ?? null,
				file_request_users: users.map(u => u.ID),
				file_request_reminders: reminders,
				file_request_shared: shared
			};
		},
		isDirty: isActive || folder !== undefined,
		onReset,
		components: {
			button: enabled ? (
				<>
					<AudienceAwareComposerPluginButton
						size={Size.sm}
						onClick={() => setShowOptions(true)}
						disabled={disabled}
						variant={Variant.light}
						intent={Intent.secondary}
						type="button"
						icon={isActive ? undefined : 'copy'}
						className={classNames('hidden sm:inline-flex relative rounded-full gap-2', {
							'text-theme-primary font-semibold hover:text-theme-primary !pl-1': isActive
						})}>
						{isActive && (
							<span className="inline-flex items-center gap-1 px-1.5 py-0.5 !text-xs text-white rounded-full bg-theme-primary mr-1">
								<FontAwesomeIcon icon="users" />
								<span>{users.length}</span>
							</span>
						)}
						<span>
							<FormattedMessage id="file-requests.action" />
						</span>
					</AudienceAwareComposerPluginButton>
					<AudienceAwareComposerPluginButton
						size={Size.sm}
						onClick={() => setShowOptions(true)}
						disabled={disabled}
						variant={isActive ? Variant.primary : Variant.light}
						intent={isActive ? Intent.primary : Intent.secondary}
						circle
						className="sm:hidden"
						type="button"
						icon="copy"
					/>
					{showOptions && (
						<DialogFileRequestOptions users={users} onChange={onOptionsChanged} reminders={reminders} folder={folder} shared={shared} onAfterClose={() => setShowOptions(false)} />
					)}
				</>
			) : null
		}
	};
};
