import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Folder from '@models/Folder';
import { Intent, Variant, resolveButtonClassNames } from '@convoflo/ui';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

const LINK = 'https://ress.lecsor.com';

type LecsorOrderFormButtonProps = {
	secureSpace: Folder;
};

export const LecsorOrderFormButton: FC<LecsorOrderFormButtonProps> = ({ secureSpace }) => {
	if (!secureSpace.getMetaValue('lecsor:path')?.endsWith('/Coproprietaires')) {
		return null;
	}

	return (
		<a href={LINK} target="_blank" rel="noreferrer" className={resolveButtonClassNames({ intent: Intent.primary, variant: Variant.secondary, iconStart: 'receipt', shadow: true })}>
			<FontAwesomeIcon icon="receipt" className="mr-2" />
			<FormattedMessage id="lecsor.order_form" />
		</a>
	);
};
