import type { TreeAction, TreeNodeType } from './types';

export const removeNode = (data: TreeNodeType[], id: string): TreeNodeType[] => {
	return data
		.filter(item => item.id !== id)
		.map(item => {
			if (hasChildNodes(item)) {
				return {
					...item,
					children: removeNode(item.children, id)
				};
			}
			return item;
		});
};

export const replaceNode = (data: TreeNodeType[], node: TreeNodeType): TreeNodeType[] => {
	return data.map(_node => (_node.id === node.id ? node : { ..._node, children: replaceNode(_node.children ?? [], node) }));
};

export const insertNodeBefore = (data: TreeNodeType[], targetId: string, newItem: TreeNodeType): TreeNodeType[] => {
	return data.flatMap(item => {
		if (item.id === targetId) {
			return [newItem, item];
		}
		if (hasChildNodes(item)) {
			return {
				...item,
				children: insertNodeBefore(item.children, targetId, newItem)
			};
		}
		return item;
	});
};

export const insertNodeAfter = (data: TreeNodeType[], targetId: string, newItem: TreeNodeType): TreeNodeType[] => {
	return data.flatMap(item => {
		if (item.id === targetId) {
			return [item, newItem];
		}

		if (hasChildNodes(item)) {
			return {
				...item,
				children: insertNodeAfter(item.children, targetId, newItem)
			};
		}

		return item;
	});
};

export const insertChildNode = (data: TreeNodeType[], targetId: string, newItem: TreeNodeType): TreeNodeType[] => {
	return data.flatMap(item => {
		if (item.id === targetId) {
			// already a parent: add as first child
			return {
				...item,
				// opening item so you can see where item landed
				isOpen: true,
				children: [newItem, ...item.children]
			};
		}

		if (!hasChildNodes(item)) {
			return item;
		}

		return {
			...item,
			children: insertChildNode(item.children, targetId, newItem)
		};
	});
};

export const findNode = (data: TreeNodeType[], itemId: string): TreeNodeType | undefined => {
	for (const item of data) {
		if (item.id === itemId) {
			return item;
		}

		if (hasChildNodes(item)) {
			const result = findNode(item.children, itemId);
			if (result) {
				return result;
			}
		}
	}

	return undefined;
};

export const getPathToNode = ({ current, targetId, parentIds = [] }: { current: TreeNodeType[]; targetId: string; parentIds?: string[] }): string[] | undefined => {
	for (const item of current) {
		if (item.id === targetId) {
			return parentIds;
		}
		const nested = getPathToNode({
			current: item.children,
			targetId: targetId,
			parentIds: [...parentIds, item.id]
		});
		if (nested) {
			return nested;
		}
	}
};

export const hasChildNodes = (item: TreeNodeType) => {
	return item.children.length > 0;
};

export const getChildNodes = (data: TreeNodeType[], targetId: string) => {
	/**
	 * An empty string is representing the root
	 */
	if (targetId === '') {
		return data;
	}

	return findNode(data, targetId)?.children ?? [];
};

export const treeDataReducer = (data: TreeNodeType[], action: TreeAction | null) => {
	if (action === null) {
		return data;
	}

	if (action.type === 'updateTree') {
		return action.data;
	}

	const item = findNode(data, action.itemId);

	if (!item) {
		return data;
	}

	if (action.type === 'instruction') {
		const instruction = action.instruction;

		if (instruction.type === 'reparent') {
			const path = getPathToNode({
				current: data,
				targetId: action.targetId
			});

			const desiredId = path?.[instruction.desiredLevel];
			let result = removeNode(data, action.itemId);
			result = insertNodeAfter(result, desiredId!, item);
			return result;
		}

		// the rest of the actions require you to drop on something else
		if (action.itemId === action.targetId) {
			return data;
		}

		if (instruction.type === 'reorder-above') {
			let result = removeNode(data, action.itemId);
			result = insertNodeBefore(result, action.targetId, item);
			return result;
		}

		if (instruction.type === 'reorder-below') {
			let result = removeNode(data, action.itemId);
			result = insertNodeAfter(result, action.targetId, item);
			return result;
		}

		if (instruction.type === 'make-child') {
			let result = removeNode(data, action.itemId);
			result = insertChildNode(result, action.targetId, item);
			return result;
		}

		return data;
	}

	function toggle(item: TreeNodeType): TreeNodeType {
		if (!hasChildNodes(item)) {
			return item;
		}

		if (item.id === action?.itemId) {
			return { ...item, isOpen: !item.isOpen };
		}

		return { ...item, children: item.children.map(toggle) };
	}

	if (action.type === 'toggle') {
		return data.map(toggle);
	}

	if (action.type === 'expand') {
		if (hasChildNodes(item) && !item.isOpen) {
			return data.map(toggle);
		}
		return data;
	}

	if (action.type === 'collapse') {
		if (hasChildNodes(item) && item.isOpen) {
			return data.map(toggle);
		}
		return data;
	}

	return data;
};
