import type { ServiceOutputTypes } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { useFileManager } from '@components/FileManager';
import {
	FileSizeError,
	type FinishFileRequest,
	type PrepareFileResponse,
	type ProcessFileMutation,
	UploadFile,
	type UploadFileMutation,
	type UploadService,
	UploadStatus,
	blobToReadableStream,
	isBuggedSafari
} from '@components/Upload';
import { useView } from '@hooks/use-view';
import useClient from '@hooks/useClient';
import File from '@models/File';
import Folder from '@models/Folder';
import { XmlHttpRequestHttpHandler } from '@service/XmlHttpRequestHttpHandler';
import cloneDeep from 'lodash.clonedeep';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import type { WretchError } from 'wretch';

type useUploadOptions = {
	autoStart?: boolean;
	maxFileSize?: number;
	partSize?: number;
	queueSize?: number;
	onStatusChanged?: (status: UploadStatus) => void;
};

export const useUpload = (uploadService: UploadService | undefined, uploadFile: UploadFile, { autoStart = true, maxFileSize = 0, queueSize = 4, partSize }: useUploadOptions = {}) => {
	const { client } = useClient();
	const [bytesUploaded, setBytesUploaded] = useState(0);
	const [status, setStatus] = useState<UploadStatus>(UploadStatus.Pending);
	const [file, setFile] = useState<File | null>(null);
	const [errorCode, setErrorCode] = useState<string | null>(null);
	const { add } = useFileManager() ?? {};
	const { view } = useView();

	const managedUpload = useRef<Upload>();

	const { name, size } = uploadFile.file;
	const scope = uploadFile.scope;

	// Step 1: get the id and key from server
	const { data } = useQuery<PrepareFileResponse, WretchError>(
		['upload', uploadFile.id],
		async () => {
			let url = 'files/upload/id';

			if (scope instanceof Folder) {
				url = `folders/${scope.getKey()}/upload/id`;
			} else if (scope instanceof File) {
				url = `files/${scope.getKey()}/upload/id`;
			}

			return await client.url(url).query({ name, size, x: 1 }).get().json();
		},
		{
			refetchOnWindowFocus: false,
			staleTime: Infinity,
			enabled: autoStart,
			onError: error => {
				setStatus(UploadStatus.Error);
				if (error.status === 422 && error.json.errors?.name !== undefined) {
					setErrorCode(error.json.errors?.name[0]);
				}
			}
		}
	);

	// Step 2: upload
	const { mutate: upload } = useMutation<ServiceOutputTypes, Error, UploadFileMutation>(
		async ({ key }) => {
			if (maxFileSize > 0 && uploadFile.file.size > maxFileSize) {
				throw new FileSizeError('File size is too large for your plan');
			}

			if (uploadService === undefined) {
				throw new Error('Upload service is undefined');
			}

			const newService = cloneDeep(uploadService.service);
			const __parts: Record<string, number> = {};
			const requestHandler = new XmlHttpRequestHttpHandler();

			requestHandler.onProgress$.subscribe(({ path, progressEvent: { loaded } }) => {
				__parts[path] = loaded;
				setBytesUploaded(Object.entries(__parts).reduce((total, [, bytes]) => total + bytes, 0));
			});

			newService.config.requestHandler = requestHandler;

			managedUpload.current = new Upload({
				client: newService,
				partSize,
				queueSize,
				params: {
					Bucket: uploadService.bucket,
					Key: key,
					// https://github.com/aws/aws-sdk-js-v3/issues/2365
					Body: isBuggedSafari ? blobToReadableStream(uploadFile.file) : uploadFile.file,
					ContentType: uploadFile.file.type
				}
			});

			return await managedUpload.current.done();
		},
		{
			onMutate: () => {
				setStatus(UploadStatus.Uploading);
			},
			onSuccess: (_, { version }) => {
				if (version) {
					process({ version });
				}
			},
			onError: error => {
				if (error && error.name === 'AbortError') {
					setStatus(UploadStatus.Aborted);
				} else if (error) {
					setStatus(UploadStatus.Error);
				}
			}
		}
	);

	// Step 3: process file on server
	const { mutate: process } = useMutation<File, WretchError, ProcessFileMutation>(
		async ({ version }) => {
			const data: FinishFileRequest = {
				version
			};

			if (uploadFile.scope instanceof Folder) {
				data.folder = uploadFile.scope.getKey() || 0;
				return new File(await client.url('files').post(data).json());
			} else if (uploadFile.scope instanceof File) {
				return new File(await client.url(uploadFile.scope.getRoute('versions')).post(data).json());
			}

			return new File(await client.url('files').post(data).json());
		},
		{
			onMutate: () => {
				setStatus(UploadStatus.Processing);
			},
			onError: error => {
				setStatus(UploadStatus.Error);
				setErrorCode(error.json.error.code ?? null);
			},
			onSuccess: file => {
				setFile(file);
				setStatus(UploadStatus.Completed);
				if (typeof view !== 'string' && scope?.getKey() === view?.getKey()) {
					add?.([file]);
				}
			}
		}
	);

	const abort = useCallback(() => {
		managedUpload.current?.abort();
	}, []);

	// We reset
	useEffect(() => {
		setBytesUploaded(0);
		setStatus(UploadStatus.Pending);
		setFile(null);
	}, [uploadFile?.id]);

	// When we receive the Key from the server, we can start the upload process
	useEffect(() => {
		if (data === undefined || !uploadService || status !== UploadStatus.Pending) {
			return;
		}

		upload({ version: data.version, key: data.key });
	}, [data, uploadService, status, upload]);

	const percentage = uploadFile !== null ? Math.round((bytesUploaded / uploadFile.file.size) * 100) : 0;

	return {
		status,
		percentage,
		abort,
		file,
		bytesUploaded: [UploadStatus.Aborted, UploadStatus.Error].includes(status) ? 0 : bytesUploaded,
		errorCode
	};
};
