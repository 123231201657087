import { Subtitle, Title } from '@components/Account';
import { GatewayModuleManager, OrganizationRules, OrganizationSettings } from '@components/Organization';
import AppContext from '@contexts/AppContext';
import { useAccount } from '@hooks/useAccount';
import { type FC, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Module } from '@types';
import { DataExport } from '@components/Account/DataExport';

export const OrganizationSettingsPage: FC = () => {
	const { account } = useAccount();
	const { setPageTitle } = useContext(AppContext);
	const { formatMessage } = useIntl();

	useEffect(() => {
		setPageTitle(formatMessage({ id: 'organization.settings' }));
	}, [formatMessage, setPageTitle]);

	return (
		<div className="max-w-3xl">
			<Title>
				<FormattedMessage id="account.settings" />
			</Title>
			<article className="space-y-12">
				<section>
					<Subtitle id="#settings">
						<FormattedMessage id="organization.settings" />
					</Subtitle>
					<OrganizationSettings />
				</section>
				<section>
					<Subtitle id="#rules">
						<FormattedMessage id="organization.rules" />
					</Subtitle>
					<OrganizationRules />
				</section>
				{account!.hasFullAccess() && account!.isAdmin() && (
					<section>
						<Subtitle>
							<FormattedMessage id="organization.export_data" />
						</Subtitle>
						<DataExport scope="organization" />
					</section>
				)}
				{account!.business.hasModule(Module.Gateway) && (
					<section>
						<Subtitle id="#embed">
							<FormattedMessage id="gateway.title" />
						</Subtitle>
						<GatewayModuleManager />
					</section>
				)}
			</article>
		</div>
	);
};
