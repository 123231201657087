import { useContext } from 'react';
import { CheckoutContext } from '@components/Checkout';

export const useStore = () => {
	const context = useContext(CheckoutContext);

	if (context === undefined) {
		throw new Error('useStore() must be used within a CheckoutContext');
	}

	return { store: context.store, isLoading: context.isStoreLoading };
};

export default useStore;
