import Loader from '@assets/images/Icon-Loader.svg?react';
import { LabelSelector } from '@components/Labels';
import PDFViewer from '@components/PDFViewer';
import PhotoViewer from '@components/PhotoViewer';
import { ExcelViewer } from '@components/ExcelViewer';
import AppContext from '@contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import File, { FileVersionSource } from '@models/File';
import Folder from '@models/Folder';
import { Button, Intent, Modal, ModalBody, ModalFooter, ModalHeader, type ModalProps, ModalSize, Size, Variant } from '@convoflo/ui';
import FontAwesomeSvg from '@ui/FontAwesomeSvg';
import classNames from 'classnames';
import { type FC, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { Redirect, useHistory } from 'react-router-dom';
import type { WretchError } from 'wretch';
import { FileLabel } from './FileLabel';
import { FileableContextMenu } from './FileableContextMenu';
import { useGallery } from './hooks/use-gallery';
import { FileSignRequestStatus } from './FileSignRequestStatus';

export const IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/pjpeg', 'image/bmp', 'image/gif'];
export const EXCEL_TYPES = ['text/csv'];
export const PDF_TYPES = ['application/pdf'];
export const PREVIEWABLE_TYPES = [...IMAGE_TYPES, ...PDF_TYPES, ...EXCEL_TYPES];

type DialogPreviewProps = Omit<ModalProps, 'isOpen'> & {
	file: File;
	folder?: Folder;
	showNavigation?: boolean;
};

export const DialogPreview: FC<DialogPreviewProps> = ({ file: initialFile, showNavigation = true, ...modalProps }) => {
	const { setPageTitle: setTitle } = useContext(AppContext);
	const { client } = useClient();
	const history = useHistory();
	const { account } = useAccount();

	const [file, setFile] = useState(initialFile);
	const [isOpen, setIsOpen] = useState(true);

	const { previousFile, nextFile } = useGallery(file, setFile, showNavigation);

	const { data: fileLocation, isLoading: isFileLocationLoading } = useQuery<string, WretchError>(
		['file', file.Id, 'preview'],
		async () => await client.url(`documents/${file.getKey()}/download?mode=preview`).get().text(),
		{
			staleTime: Infinity,
			refetchOnMount: 'always',
			onError: error => {
				if (error.status === 402) {
					setIsOpen(false);
				} else if (error.status === 302 && error.json.error.code === 'ongoing_sign_request') {
					history.push(error.json.error.message);
					return;
				}
			}
		}
	);

	const { mutate: download, isLoading: isDownloading } = useMutation(async () => await client.url(`documents/${file.getKey()}/download?mode=download`).get().text(), {
		onSuccess: link => {
			window.location.href = link;
		}
	});

	useEffect(() => {
		setFile(initialFile);
	}, [initialFile]);

	useEffect(() => {
		setTitle(file.getName());
	}, [file, setTitle]);

	const isPdf = file.current.Source !== FileVersionSource.External && fileLocation && PDF_TYPES.includes(file.current.MimeType);
	const isImage = file.current.Source !== FileVersionSource.External && fileLocation && IMAGE_TYPES.includes(file.current.MimeType);
	const isExcel = file.current.Source !== FileVersionSource.External && fileLocation && EXCEL_TYPES.includes(file.current.MimeType);

	return (
		<Modal isOpen={isOpen} size={ModalSize.XLarge} className="sm:!max-w-[90vw]" {...modalProps}>
			<ModalHeader className="flex items-center pb-3">
				<div className="space-y-1 group">
					<h1 className="font-medium">{file.current.Name}</h1>
					<div className="flex items-center gap-0.5 flex-wrap order-none lg:order-last" onClick={e => e.stopPropagation()}>
						{file.signer?.map(signRequest => (
							<FileSignRequestStatus key={signRequest.Id} file={file} signRequest={signRequest} />
						))}
						{file.current.Source === 'ONESPAN_SIGN' && (
							<FileLabel icon="signature" color="red">
								<FormattedMessage id="file-manager.signed" />
							</FileLabel>
						)}
						{/* Sometimes it's not included in the response, we'll fix that */}
						{(file.properties ?? []).map(prop => (
							<LabelSelector key={prop.Id} label={prop} model={file} />
						))}
						{account?.hasFullAccess() && (
							<span className="transition-opacity opacity-100 lg:opacity-0 group-hover:opacity-100">
								<LabelSelector model={file} />
							</span>
						)}
					</div>
				</div>
				{account && (
					<FileableContextMenu fileable={file} placement="bottom-end">
						<Button variant={Variant.light} intent={Intent.tertiary} type="button" circle size={Size.sm}>
							<FontAwesomeSvg id="fa-ellipsis-v" className="text-gray-500" />
						</Button>
					</FileableContextMenu>
				)}
			</ModalHeader>
			<ModalBody className={classNames('!p-0 group relative', { '!h-screen !flex-initial': isPdf || isExcel })}>
				{isFileLocationLoading ? (
					<div className="flex items-center justify-center my-32">
						<Loader className="w-6 mr-3" />
						<p className="my-auto italic text-center text-gray-500">
							<FormattedMessage id="loading" />
						</p>
					</div>
				) : (
					<>
						{isPdf && <PDFViewer file={file} location={fileLocation} />}
						{isImage && <PhotoViewer file={file} location={fileLocation} showNavigation={false} />}
						{isExcel && <ExcelViewer file={file} location={fileLocation} />}
						{!isPdf && !isImage && !isExcel && <Redirect to={file.getUrl()} />}
					</>
				)}
			</ModalBody>
			{showNavigation && (
				<div className="flex items-center justify-between p-3">
					{previousFile ? (
						<button className="relative flex w-48 h-12 overflow-hidden bg-black rounded focus:outline-none" type="button" onClick={() => setFile(previousFile)}>
							<div className="flex items-center absolute z-[1] inset-0 bg-black/50 gap-4 py-2 px-4">
								<div>
									<FontAwesomeIcon icon="arrow-left" className="text-white" />
								</div>
								<p className="flex-1 min-w-0 text-xs leading-none text-center text-white truncate">{previousFile.getName()}</p>
							</div>
							{!!previousFile.current.Thumbnail && (
								<div className="absolute inset-0 bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${'' + previousFile.current.Thumbnail}` }}></div>
							)}
						</button>
					) : (
						<div />
					)}
					{nextFile ? (
						<button className="relative flex w-48 h-12 overflow-hidden bg-black rounded focus:outline-none" type="button" onClick={() => setFile(nextFile)}>
							<div className="flex items-center absolute z-[1] inset-0 bg-black/50 gap-4 py-2 px-4">
								<p className="flex-1 min-w-0 text-xs leading-none text-center text-white truncate">{nextFile.getName()}</p>
								<div>
									<FontAwesomeIcon icon="arrow-right" className="text-white" />
								</div>
							</div>
							{!!nextFile.current.Thumbnail && (
								<div className="absolute inset-0 bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${'' + nextFile.current.Thumbnail}` }}></div>
							)}
						</button>
					) : (
						<div />
					)}
				</div>
			)}
			<ModalFooter>
				{!file.RestrictDownloads && (
					<Button
						variant={Variant.primary}
						disabled={isDownloading || !fileLocation}
						intent={Intent.primary}
						icon="download"
						loading={isDownloading}
						type="button"
						onClick={() => download()}>
						<FormattedMessage id="file.download" />
					</Button>
				)}
				<Button variant={Variant.light} intent={Intent.secondary} type="button" onClick={() => setIsOpen(false)}>
					<FormattedMessage id="close" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
