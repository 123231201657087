import type {
	LaravelLengthAwarePaginatorType,
	LaravelLengthAwareResourcePaginatorType,
	LaravelSimplePaginatorType,
	LaravelSimpleResourcePaginatorType,
	LengthAwarePaginatorType,
	ResourceLengthAwarePaginatorType,
	ResourceSimplePaginatorType,
	SimplePaginatorType
} from '@components/Pagination';

export const createSimplePagination = function <T>({ current_page, first_page_url, next_page_url, per_page, prev_page_url, ...rest }: LaravelSimplePaginatorType<T>): SimplePaginatorType<T> {
	return {
		...rest,
		currentPage: current_page,
		firstPageUrl: first_page_url,
		nextPageUrl: next_page_url,
		perPage: per_page,
		prevPageUrl: prev_page_url
	};
};

export const createLengthAwarePagination = function <T>({ last_page, last_page_url, total, ...props }: LaravelLengthAwarePaginatorType<T>): LengthAwarePaginatorType<T> {
	return {
		...createSimplePagination(props),
		total,
		lastPage: last_page,
		lastPageUrl: last_page_url
	};
};

export const createResourcePagination = function <T>({ meta, ...rest }: LaravelSimpleResourcePaginatorType<T>): ResourceSimplePaginatorType<T> {
	return {
		...rest,
		meta: {
			...meta,
			perPage: meta.per_page,
			currentPage: meta.current_page
		}
	};
};

export const createResourceLengthAwarePagination = function <T, M = {}>({ meta, ...rest }: LaravelLengthAwareResourcePaginatorType<T, M>): ResourceLengthAwarePaginatorType<T, M> {
	return {
		...rest,
		meta: {
			...meta,
			perPage: meta.per_page,
			lastPage: meta.last_page,
			currentPage: meta.current_page
		}
	};
};
