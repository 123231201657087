import AppContext from '@contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useUrlSearch from '@hooks/useUrlSearch';
import classNames from 'classnames';
import { type FC, type FormEvent, type KeyboardEvent, useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

type GlobalSearchProps = {
	onClear?: () => void;
};

export const GlobalSearch: FC<GlobalSearchProps> = ({ onClear = () => undefined }) => {
	const history = useHistory();
	const { formatMessage } = useIntl();
	const { q: queryInUrl = '' } = useUrlSearch();
	const { setShowGlobalSearch: showGlobalSearch, showGlobalSearch: globalSearchShown } = useContext(AppContext);

	const [query, setQuery] = useState('');

	const searchBar = useRef<HTMLInputElement>(null);

	useHotkeys(
		'/',
		event => {
			event.preventDefault();
			showGlobalSearch(true);
		},
		{
			enabled: !globalSearchShown
		}
	);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();

		const _query = query.trim();
		showGlobalSearch(false);

		if (_query.length === 0) {
			history.push('/files');
			return;
		}

		history.push(`/files?q=${encodeURIComponent(_query)}`, { view: _query });
	};

	const clear = () => {
		setQuery('');
		onClear();
	};

	const onKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			showGlobalSearch(false);
		}
	};

	useEffect(() => {
		if (globalSearchShown) {
			searchBar.current?.select();
			searchBar.current?.focus();
		}
	}, [globalSearchShown]);

	useEffect(() => {
		setQuery(queryInUrl);
	}, [queryInUrl]);

	return createPortal(
		<>
			<button
				type="button"
				tabIndex={-1}
				onClick={() => showGlobalSearch(false)}
				className={classNames('bg-black w-full h-full fixed inset-0 z-50 transition-all', {
					'backdrop-blur-sm bg-opacity-25': globalSearchShown,
					'backdrop-blur-0 bg-opacity-0 pointer-events-none': !globalSearchShown
				})}
			/>
			<form
				onClick={event => event.stopPropagation()}
				onSubmit={onSubmit}
				className={classNames('z-[51] absolute inset-0 grid place-items-center pointer-events-none', {
					hidden: !globalSearchShown,
					flex: globalSearchShown
				})}>
				<div className="w-full max-w-md mx-6 -translate-y-8 rounded-full shadow-xl pointer-events-auto group">
					<input
						style={{ '--tw-ring-offset-width': '-1px' }}
						onKeyDown={onKeyDown}
						ref={searchBar}
						autoFocus
						value={query}
						onChange={e => setQuery(e.target.value)}
						type="text"
						className="block w-full px-6 pl-10 text-xl text-gray-700 border-0 rounded-full shadow-none placeholder:text-base focus:outline-none ring-0 focus:border-0"
						placeholder={formatMessage({ id: 'search' })}
					/>
					<span className="absolute top-0 bottom-0 flex items-center h-full pointer-events-none left-4">
						<FontAwesomeIcon icon="search" className="text-gray-500" />
					</span>
					{query !== '' && (
						<button type="button" onClick={clear} className="absolute top-0 bottom-0 right-0 hidden px-4 py-2 sm:inline-block">
							<FontAwesomeIcon icon="times" className="text-gray-500" />
						</button>
					)}
				</div>
			</form>
		</>,
		document.body
	);
};
