import { createContext } from 'react';
import type { Fileable } from '@types';

export const DraggedFileables = createContext<{
	draggedFileables: Fileable[];
	setDraggedFileables: (fileables: Fileable[]) => void;
}>({
	draggedFileables: [],
	setDraggedFileables: () => {}
});
