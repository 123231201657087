import { PREVIEWABLE_TYPES } from '@components/FileManager';
import type { LabelType } from '@components/Labels';
import type { PaymentRequest } from '@components/PaymentRequest';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import Collaborator from '@models/Collaborator';
import User, { type IUser } from '@models/User';
import { type FilePivotType, type Fileable, FileableAccess, type ItemSyncType, MessagesMode, type Metadata, Module, Permissions } from '@types';
import Folder from './Folder';
import Organization from './Organization';
import type SignRequest from '@models/SignRequest';

enum FileAVStatus {
	Infected = 'infected',
	Clean = 'clean'
}

export enum FileVersionSource {
	Web = 'WEB',
	Word = 'WORD_ADDIN',
	Excel = 'EXCEL_ADDIN',
	PowerPoint = 'PPOINT_ADDIN',
	EmailForward = 'EMAIL_FORWARD',
	OneSpanSign = 'ONESPAN_SIGN',
	External = 'EXTERNAL'
}

export type Version = {
	Id: number | null;
	Name: string;
	AVStatus: FileAVStatus;
	Source: FileVersionSource;
	created_at: string | null;
	Size: number;
	MimeType: string;
	Thumbnail: string | null;
	creator: User;
	i?: number;
	Stored: boolean;
	ExternalLink: string;
};

class File implements Fileable {
	'@type': 'Document';
	Id: number | null = null;
	current!: Version;
	pivot?: FilePivotType;
	expires_at!: string | null;
	URL!: string;
	Link!: string;
	OriginalLink!: string;
	collaborators!: Collaborator[];
	creator!: User & {
		business?: Organization;
	};
	SecuredSpace: boolean = false;
	Access!: FileableAccess;
	created_at!: string;
	deleted_at!: string | null;
	RestrictDownloads!: boolean;
	ancestors?: Folder[];
	ReadonlyMessages!: boolean;
	MessagesMode!: MessagesMode;
	Editable!: boolean;
	Modules!: number;
	meta?: Metadata[];
	expires_by!: User | null;
	paywall?: PaymentRequest[];
	signer?: SignRequest[];
	SecureSpace?: Folder | null;
	Scope?: 'internal' | 'external';
	properties!: LabelType[];
	members_count: number = 0;
	members_hash?: string;
	syncs?: ItemSyncType[] | undefined;

	constructor(attributes: Record<string, any> | Fileable = {}) {
		Object.assign(this, attributes);
		if (this.SecureSpace) {
			this.SecureSpace = new Folder(this.SecureSpace);
		}
		if (this.ancestors) {
			this.ancestors = this.ancestors.map(ancestor => new Folder(ancestor));
		}
	}

	static fromName(name: string): File {
		return new File({ current: { Name: name } });
	}

	id() {
		return `file_${this.Id}`;
	}

	hasModule(module: Module) {
		if (this.Modules !== undefined) {
			return (this.Modules & module) === module;
		}
		return ((this.creator.business?.Modules ?? 0) & module) === module;
	}

	isInfected() {
		return this?.current?.AVStatus === FileAVStatus.Infected;
	}

	fileExists() {
		if (this.isInfected()) {
			return false;
		}

		if (!this.current.Stored) {
			return false;
		}

		return true;
	}

	isViewableBy(user: IUser | null) {
		return this.isDownloadableBy(user) || this.isPreviewable() || this.current.ExternalLink !== null;
	}

	isDownloadableBy(user: IUser | null) {
		if (!this.fileExists()) {
			return false;
		}

		if (this.RestrictDownloads) {
			if (user === null) {
				return false;
			}

			if (this.creator.ID !== user.ID) {
				return false;
			}
		}

		return true;
	}

	isPreviewable(): boolean {
		return PREVIEWABLE_TYPES.includes(this.current.MimeType);
	}

	getKey() {
		return this.Id;
	}

	getName() {
		return this.current.Name;
	}

	getRoute(suffix: string | null = null, prefix: string = 'documents') {
		return `${prefix}/${this.getKey()}${suffix !== null ? `/${suffix}` : ''}`;
	}

	getFileExtension(): string | undefined {
		return this.getName().split('.').pop();
	}

	getUrl(suffix: string | null = null): string {
		return this.OriginalLink + (suffix !== null ? `/${suffix}` : '');
	}

	private __icon(): [IconProp, string] {
		let icon = '';
		let color = '';
		let extension = (this.getFileExtension() || '').toLowerCase();

		switch (extension.toLowerCase()) {
			case 'zip':
			case 'rar':
			case '7z':
			case 'bak':
				icon = 'file-archive';
				color = 'text-purple-700';
				break;
			case 'url':
				icon = 'globe-americas';
				color = 'text-blue-700';
				break;
			case 'xml':
			case 'htm':
			case 'html':
				icon = 'file-code';
				color = 'text-blue-700';
				break;
			case 'exe':
				icon = 'walking';
				color = 'text-gray-700';
				break;
			case 'db':
			case 'baz':
			case 'dat':
			case 'sql':
				icon = 'database';
				color = 'text-orange-700';
				break;
			case 'key':
				icon = 'key';
				color = 'text-yellow-800';
				break;
			case 'bmp':
			case 'gif':
			case 'jpg':
			case 'jpeg':
			case 'png':
			case 'tiff':
			case 'tif':
				icon = 'file-image';
				color = 'text-teal-700';
				break;
			case 'doc':
			case 'dot':
			case 'docx':
			case 'docm':
			case 'dotx':
			case 'dotm':
			case 'docb':
			case 'odt':
			case 'rtf':
				icon = 'file-word';
				color = 'text-blue-700';
				break;
			case 'pdf':
				icon = 'file-pdf';
				color = 'text-red-700';
				break;
			case 'ppt':
			case 'pot':
			case 'pps':
			case 'pptx':
			case 'pptm':
			case 'potx':
			case 'potm':
			case 'ppsx':
			case 'ppsm':
			case 'odp':
				icon = 'file-powerpoint';
				color = 'text-orange-600';
				break;
			case 'xls':
			case 'xlt':
			case 'xlm':
			case 'xlsx':
			case 'xlsm':
			case 'xltx':
			case 'xlsb':
			case 'xltm':
			case 'ods':
			case 'gsheet':
				icon = 'file-excel';
				color = 'text-green-600';
				break;
			case 'dwg':
			case 'psd':
			case 'ai':
			case 'eps':
				icon = 'file-edit';
				color = 'text-yellow-600';
				break;
			case 'txt':
			case 'msg':
				icon = 'file-alt';
				color = 'text-grey-400';
				break;
			case 'csv':
				icon = 'file-csv';
				color = 'text-green-600';
				break;
			case 'cab':
				icon = 'cabinet-filing';
				color = 'text-gray-600';
				break;
			case 'wav':
			case 'mp3':
			case 'm4a':
				icon = 'file-audio';
				color = 'text-indigo-700';
				break;
			case 'mov':
			case 'mp4':
			case 'avi':
			case 'mpg':
			case 'flv':
			case 'm4v':
			case 'wmv':
				icon = 'file-video';
				color = 'text-blue-400';
				break;
			default:
				icon = 'file';
				color = 'text-gray-800';
				break;
		}

		return [icon as IconProp, color];
	}

	icon(className = '', colorize = true, { fixedWidth = true, ...props }: Partial<FontAwesomeIconProps> = {}) {
		const [icon, colorClass] = this.__icon();
		return <FontAwesomeIcon {...props} fixedWidth={fixedWidth} icon={icon} className={`${className} ${colorize ? colorClass : ''}`} />;
	}

	canUploadFiles(): boolean {
		return this.pivot?.Permissions === Permissions.ReadWrite || this.Editable;
	}

	canViewMessages(): boolean {
		return this.pivot?.Permissions !== undefined && this.hasModule(Module.Commenting);
	}

	getMetaValue(key: string) {
		return (this.meta ?? []).find(({ key: _key }) => key === _key)?.value;
	}
}

export default File;
