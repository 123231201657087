import useClient from '@hooks/useClient';
import Account from '@models/Account';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import type { WretchError, WretchResponse } from 'wretch';
import { useFlushDraftsMutation } from './drafts';

const REFETCH_INTERVAL_NORMAL = 30000;
const REFETCH_INTERVAL_MAINTENANCE = 5000;

type AppLoginMutation = {
	email: string;
	password: string;
	remember?: boolean;
};

type ResetPasswordMutation = {
	password: string;
	passwordConfirmation: string;
};

export const accountUpdater = (params: Partial<Account> | null) => (account: Account | null | undefined) => account ? new Account({ ...account, ...params }) : null;

export const useAccountQuery = () => {
	const { client, authToken } = useClient();

	return useQuery<Account | null, WretchError, Account | null>({
		queryKey: ['account', authToken],
		queryFn: async () => new Account(await client.url('account').get().json<Account>()),
		refetchInterval: (_account, query) => {
			// If the account was not loaded because of maintenance, refresh every ~5s do not let the user wait
			if (query.state.error?.status === 503) {
				return REFETCH_INTERVAL_MAINTENANCE;
			}

			// No do refresh if the user is not logged in
			if (query.state.data === null) {
				return false;
			}

			return REFETCH_INTERVAL_NORMAL;
		},
		enabled: authToken !== null,
		initialData: authToken !== null ? undefined : null,
		placeholderData: authToken !== null ? undefined : null
	});
};

export const useAccountLoginMutation = () => {
	const { client, setAuthToken } = useClient();

	return useMutation<
		{
			token: string;
		},
		WretchError,
		AppLoginMutation
	>({
		mutationFn: async ({ email, password, remember = false }) =>
			await client
				.url('login')
				.post({ email, password, remember: remember ? 1 : 0, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
				.json<{
					token: string;
				}>(),

		onSuccess: ({ token }) => {
			setAuthToken(token);
		}
	});
};

export const useLogoutMutation = () => {
	const { client, setAuthToken } = useClient();
	const queryClient = useQueryClient();

	const { mutateAsync: flushDrafts } = useFlushDraftsMutation();

	return useMutation<WretchResponse, WretchError>({
		mutationFn: async () => await client.url('logout').post().res(),
		onSuccess: () => {
			flushDrafts();
			setAuthToken(null);
			queryClient.clear();
		}
	});
};

export const useResetPasswordMutation = () => {
	const queryClient = useQueryClient();
	const { client, authToken } = useClient();

	return useMutation<Account, WretchError, ResetPasswordMutation>({
		mutationFn: async ({ password, passwordConfirmation }) => new Account(await client.url('password/new').post({ password, password_confirmation: passwordConfirmation }).json<Account>()),
		onSuccess: account => {
			queryClient.setQueryData(['account', authToken], account);
		}
	});
};

export const useAccountEmailChangeMutation = () => {
	const queryClient = useQueryClient();
	const { client, authToken } = useClient();

	return useMutation<Account, WretchError, string>({
		mutationFn: async url => new Account(await client.url(url, true).put().json<Account>()),
		onSuccess: account => {
			queryClient.setQueryData(['account', authToken], account);
		}
	});
};

export const useAccountVerificationMutation = () => {
	const queryClient = useQueryClient();
	const { client, authToken } = useClient();

	return useMutation<Account, WretchError, string>({
		mutationFn: async verificationUrl => new Account(await client.url(verificationUrl.replace('/api/1/', '')).put().json<Account>()),
		onSuccess: account => {
			queryClient.setQueryData(['account', authToken], account);
		}
	});
};
