import { DateTimeDisplay } from '@components/DateTime';
import { DialogDeleteSignRequest } from '@components/SignRequest';
import { SignRequestForm } from '@components/SignRequest/SignRequestForm';
import { Alert, Button, Intent, Modal, ModalBody, ModalFooter, type ModalProps, ModalSize, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useClient from '@hooks/useClient';
import { useSearchParams } from '@hooks/useSearchParams';
import File from '@models/File';
import SignRequest from '@models/SignRequest';
import { downloadResponse } from '@service/Client';
import { useSignRequestDeleteMutation } from '@state/queries/sign-requests';
import Card from '@ui/Card';
import { Tooltip } from '@ui/Tooltip';
import UserAvatar from '@ui/UserAvatar';
import { formatDate } from '@utils/DateUtils';
import classNames from 'classnames';
import { differenceInDays } from 'date-fns/esm';
import { type FC, type FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import type { WretchError, WretchResponse } from 'wretch';
import { DialogDesigner } from './DialogDesigner';

export type SignRequestItemProps = {
	signRequest: SignRequest;
};

export const SignRequestItem: FC<SignRequestItemProps> = ({ signRequest }) => {
	const { client } = useClient();
	const { formatList } = useIntl();
	const history = useHistory();
	const [{ sign_request_designer: signRequestDesignerId }, setSearchParams] = useSearchParams();

	const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
	const [isModifyModalOpened, setIsModifyModalOpened] = useState(false);
	const [showDialogSendReminders, setShowDialogSendReminders] = useState(false);

	const { mutateAsync: archiveMutation } = useSignRequestDeleteMutation(signRequest);

	const archive = async () => {
		try {
			await archiveMutation(true);
		} catch {
			// TODO: Show error message to user
		}
	};

	const goToDocument = (document: File) => {
		if (signRequest.completed_at !== null) {
			history.push(document.getUrl());
		}
	};

	const { mutate: goToEvidenceSummary } = useMutation<WretchResponse, WretchError>(async () => await client.url(`sign_requests/${signRequest.Id}/evidence-summary`).get().res(), {
		onError: console.error,
		onSuccess: downloadResponse(`convoflo-evidence-summary-${formatDate(null, null, 'yMMddHHmmss')}.pdf`, 'application/pdf')
	});

	const atLeastOneSignature = signRequest.roles.some(role => role.signed_at !== null);

	const showModifyButton = signRequest.archived_at === null && (signRequest.folder === null || signRequest.completed_at === null);

	const canModify = showModifyButton && !atLeastOneSignature;

	const showPlaceBoxes = false; //signRequest.completed_at === null && signRequest.archived_at === null && (signRequest.folder === null || signRequest.activated_at === null) && !atLeastOneSignature;

	const canArchive = signRequest.archived_at === null && (signRequest.folder === null || signRequest.completed_at !== null);

	const canDelete = signRequest.folder === null || signRequest.completed_at === null;

	const createdAtRelativeTime = <DateTimeDisplay value={signRequest.created_at} force defaultFormat="long" />;

	const showReminderToSendReminders = signRequest.activated_at !== null && signRequest.completed_at === null && differenceInDays(new Date(), new Date(signRequest.activated_at)) >= 1;

	const signersRemaining = signRequest.roles.filter(role => role.signed_at === null);

	return (
		<>
			<Card size={null} className="flex flex-col">
				{signRequest.completed_at !== null && (
					<div className="px-3 py-2 text-xs font-medium text-white bg-green-500">
						<FontAwesomeIcon icon="check" className="mr-1.5" />
						<FormattedMessage
							id="sign-requests-crud.status-completed"
							values={{ date: <DateTimeDisplay value={signRequest.completed_at} showTooltip={false} force defaultFormat="long" /> }}
						/>
					</div>
				)}
				{signRequest.completed_at === null && signRequest.activated_at !== null && (
					<div className="px-3 py-2 text-xs font-medium text-white bg-blue-500">
						<FontAwesomeIcon icon="paper-plane" className="mr-1.5" />
						<FormattedMessage id="sign-requests-crud.sent_at" values={{ date: <DateTimeDisplay value={signRequest.activated_at} showTooltip={false} force defaultFormat="long" /> }} />
					</div>
				)}

				{signRequest.archived_at !== null && (
					<div className="px-3 py-2 text-xs font-medium text-white bg-gray-500">
						<FontAwesomeIcon icon="inbox-in" className="mr-1.5" />
						<FormattedMessage id="sign-requests-crud.archived_on" values={{ date: <DateTimeDisplay value={signRequest.archived_at} showTooltip={false} force defaultFormat="long" /> }} />
					</div>
				)}

				<div className="flex-1 p-6">
					{showReminderToSendReminders && (
						<Alert variant={Variant.warning} className="mb-4">
							<p className="mb-3">
								<FormattedMessage
									id="sign-requests-crud.send_reminder_to"
									values={{
										n: signersRemaining.length,
										list: formatList(signersRemaining.map(({ signer }) => signer.Name))
									}}
								/>
							</p>
							<Button variant={Variant.warning} intent={Intent.primary} size={Size.xs} onClick={() => setShowDialogSendReminders(true)}>
								<FormattedMessage id="sign-requests-crud.send_reminder" />
							</Button>
						</Alert>
					)}

					<div className="space-y-8">
						<header className="space-y-1">
							{signRequest.folder && (
								<p className="text-sm">
									<Link to={signRequest.folder.getUrl()} className="text-theme-primary hover:underline" target="_blank">
										{signRequest.folder.Name}
										<FontAwesomeIcon icon="external-link" size="xs" className="ml-1.5" />
									</Link>
								</p>
							)}
							<h2 className="text-lg font-semibold">{signRequest.Label}</h2>
						</header>

						{signRequest.folder && signRequest.roles.length > 0 && (
							<div>
								<h6 className="mb-2 text-base font-medium text-gray-400 md:text-xs">
									<FormattedMessage id="sign-requests-crud.signatures" />
								</h6>
								<div className="space-y-1">
									{signRequest.roles.map(role => (
										<div key={role.Id} className="relative flex items-center justify-between mb-5 text-sm">
											<span className="flex-1 space-x-1">
												{role.signed_at && <FontAwesomeIcon icon="check" className="text-green-600" fixedWidth />}
												{!role.signed_at && <FontAwesomeIcon icon="hourglass-end" className="text-gray-400" fixedWidth />}
												{!!role.Reassign && <FontAwesomeIcon icon="people-arrows" className={role.ReassignedSigner !== null ? 'text-red-400' : 'text-gray-400'} fixedWidth />}
												<span className="relative font-medium">
													{role.signer.Name}
													{role.ReassignedSigner && (
														<div className="relative mt-1 text-xs text-gray-500 top-full left-12">
															<Tooltip tip={role.ReassignedSigner.email}>
																<span>
																	╰┈➤ {role.ReassignedSigner.first_name} {role.ReassignedSigner.last_name}
																</span>
															</Tooltip>
														</div>
													)}
												</span>
											</span>

											<span className="flex-1 text-xs text-right text-gray-500">
												{role.signed_at ? (
													<FormattedMessage
														id="sign-requests-crud.signed_at"
														values={{
															date: <DateTimeDisplay showTooltip={false} value={role.signed_at} force defaultFormat="long" />,
															user: role.signer.Name
														}}
													/>
												) : role.requested_at ? (
													<FormattedMessage
														id="sign-requests-crud.sent_at"
														values={{ date: <DateTimeDisplay showTooltip={false} value={role.requested_at} force defaultFormat="long" />, user: role.signer.Name }}
													/>
												) : null}
											</span>
										</div>
									))}
								</div>
							</div>
						)}

						<div>
							<h6 className="mb-2 text-base font-medium text-gray-400 md:text-xs">
								<FormattedMessage id="sign-requests-crud.documents" />
							</h6>
							<div className="grid grid-cols-2 gap-3 place-content-start">
								{signRequest.documents.map(document => (
									<Tooltip key={document.getKey()} tip={document.getName()}>
										<button
											onClick={() => goToDocument(document)}
											className={classNames('text-sm w-full text-left truncate', {
												'cursor-pointer text-theme-primary hover:underline': signRequest.completed_at,
												'cursor-default text-gray-600 ': !signRequest.completed_at
											})}>
											{document.icon('mr-1.5', true, { fixedWidth: false })}
											{document.getName()}
											{signRequest.completed_at && <FontAwesomeIcon icon="external-link" size="xs" className="ml-1.5" />}
										</button>
									</Tooltip>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="p-6 border-t border-gray-100">
					<div className="flex flex-wrap items-center gap-3">
						{showModifyButton && (
							<Tooltip tip={<FormattedMessage id="sign-requests-crud.cant_modify_already_signed" />}>
								<Button
									disabled={!canModify}
									size={Size.sm}
									icon="pencil"
									variant={Variant.primary}
									intent={Intent.secondary}
									onClick={() => setIsModifyModalOpened(true)}
									className="flex-nowrap">
									<FormattedMessage id="sign-requests-crud.modify" />
								</Button>
							</Tooltip>
						)}

						{showPlaceBoxes && (
							<Button
								size={Size.sm}
								icon="money-check-edit"
								variant={Variant.light}
								intent={Intent.secondary}
								onClick={() => history.push({ search: `sign_request_designer=${signRequest.Id}` })}
								className="flex-nowrap">
								<FormattedMessage id="sign-requests-crud.apply_signature" />
							</Button>
						)}

						{canDelete && (
							<Button
								icon={signRequest.folder === null ? 'trash-alt' : 'ban'}
								size={Size.sm}
								variant={Variant.danger}
								intent={Intent.secondary}
								onClick={() => setIsDeleteModalOpened(true)}
								className="flex-nowrap">
								<FormattedMessage id="sign-requests-crud.delete_sign_request" values={{ type: signRequest.folder === null ? 'template' : 'request' }} />
							</Button>
						)}
						{signRequest.completed_at !== null && (
							<Button size={Size.sm} icon="file-contract" variant={Variant.success} intent={Intent.secondary} onClick={() => goToEvidenceSummary()} className="flex-nowrap">
								<FormattedMessage id="sign-requests-crud.view_summary" />
							</Button>
						)}
						{canArchive && (
							<Button size={Size.sm} icon="inbox-in" variant={Variant.light} intent={Intent.secondary} onClick={() => archive()} className="flex-nowrap">
								<FormattedMessage id="sign-requests-crud.archive" />
							</Button>
						)}
					</div>
					<p className="mt-4 text-xs italic text-gray-500">
						<FormattedMessage
							id="sign-requests-crud.created_at_by"
							values={{
								date: createdAtRelativeTime,
								creator: signRequest.creator.Name
							}}
						/>
					</p>
				</div>
			</Card>

			{showDialogSendReminders && <DialogSignRequestSendReminders size={ModalSize.XSmall} signRequest={signRequest} onAfterClose={() => setShowDialogSendReminders(false)} />}

			{isDeleteModalOpened && <DialogDeleteSignRequest signRequest={signRequest} onAfterClose={() => setIsDeleteModalOpened(false)} />}

			{isModifyModalOpened && <SignRequestForm signRequest={signRequest} folder={signRequest.folder} onAfterClose={() => setIsModifyModalOpened(false)} />}

			{signRequestDesignerId === String(signRequest.Id) && <DialogDesigner signRequest={signRequest} onAfterClose={() => setSearchParams({ sign_request_designer: null })} />}
		</>
	);
};

type DialogSignRequestSendRemindersProps = Omit<ModalProps, 'isOpen'> & {
	signRequest: SignRequest;
	onReminderSent?: () => void;
	onDeleted?: (signRequest: SignRequest) => void;
};

const DialogSignRequestSendReminders: FC<DialogSignRequestSendRemindersProps> = ({ signRequest, onReminderSent = () => undefined, ...modalProps }) => {
	const { client } = useClient();

	const [isOpen, setIsOpen] = useState(true);

	const signersToRemind = signRequest.roles.filter(role => role.signed_at === null);

	const { mutate: sendReminders, isLoading: isSending } = useMutation<WretchResponse, WretchError, void>(async () => await client.url(`sign_requests/${signRequest.Id}/remind`).post().res(), {
		onError: console.error,
		onSuccess: () => {
			toast.success(<FormattedMessage id="sign-requests-crud.reminders_sent" values={{ n: signersToRemind.length }} />);
			setIsOpen(false);
			onReminderSent();
		}
	});

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		sendReminders();
	};

	return (
		<Modal isOpen={isOpen} onSubmit={onSubmit} {...modalProps}>
			<ModalBody className="max-w-full">
				<p className="mb-4">
					<FormattedMessage id="sign-requests-crud.send_reminders-intro" />
				</p>
				<ul className="space-y-3">
					{signersToRemind.map(role => (
						<li key={role.Id}>
							<UserAvatar user={role.signer} size={Size.xs} className="mr-2" />
							<strong className="font-semibold">{role.signer.Name}</strong>
							{role.requested_at !== null && (
								<span>
									<FormattedMessage
										id="sign-requests-crud.last_reminded_at"
										values={{
											date: <DateTimeDisplay value={role.requested_at} force defaultFormat="long" />,
											strong: msg => <span className="font-semibold">{msg}</span>
										}}
									/>
								</span>
							)}
						</li>
					))}
				</ul>
			</ModalBody>
			<ModalFooter>
				<Button type="submit" disabled={isSending} variant={Variant.primary}>
					<FormattedMessage id="sign-requests-crud.send_reminders" values={{ n: signersToRemind.length }} />
				</Button>
				<Button variant={Variant.light} intent={Intent.secondary} onClick={() => setIsOpen(false)} type="button">
					<FormattedMessage id="cancel" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};
