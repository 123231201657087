import { createResourceLengthAwarePagination } from '@components/Pagination';
import { LengthAwareResourcePaginator } from '@components/Pagination/LengthAwareResourcePaginator';
import { SignRequestItem, SignRequestsPromo, type SignRequestsResponse } from '@components/SignRequest';
import { SignRequestForm } from '@components/SignRequest/SignRequestForm';
import { Button, Intent, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import SignRequest from '@models/SignRequest';
import { Module } from '@types';
import EmptyState from '@ui/EmptyState';
import { type FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery, useQueryClient } from 'react-query';

type SignatureTemplatesDashboardProps = {
	view?: 'organization' | 'user';
};

export const SignatureTemplatesDashboard: FC<SignatureTemplatesDashboardProps> = ({ view = 'user' }) => {
	const { client } = useClient();
	const { account } = useAccount();
	const queryClient = useQueryClient();

	const [page, setPage] = useState(1);
	const [showCreateSignRequestTemplate, setShowCreateSignRequestTemplate] = useState(false);

	const { data: signRequests, isLoading } = useQuery(
		['sign-requests', 'templates', view, page],
		async () => {
			let url = 'account/sign_requests';

			if (view === 'organization') {
				url = 'organization/sign-requests';
			}

			const payload = await client.url(url).query({ filter: 'templates', page }).get().json<SignRequestsResponse>();

			const paginator = createResourceLengthAwarePagination(payload);

			paginator.data = paginator.data.map(signRequest => new SignRequest(signRequest));

			return paginator;
		},
		{
			enabled: account!.business.hasModule(Module.Esign)
		}
	);

	const onSignRequestCreated = () => {
		queryClient.invalidateQueries(['sign-requests', 'templates']);
	};

	if (!account!.business.hasModule(Module.Esign)) {
		return <SignRequestsPromo />;
	}

	return (
		<>
			<div className="flex items-center mb-4 space-x-4">
				<h3 className="text-base font-medium text-gray-400 md:text-xs">
					<FormattedMessage id="dashboard-esign.templates" />
				</h3>
				<div className="flex-1 h-px bg-gray-300" />
				<Button shadow onClick={() => setShowCreateSignRequestTemplate(true)} variant={Variant.primary} intent={Intent.primary} iconStart="layer-group">
					<FormattedMessage id="sign-requests-crud.create-type" values={{ type: 'template' }} />
				</Button>
			</div>

			<div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
				{signRequests !== undefined && signRequests.data.length === 0 && !isLoading && (
					<EmptyState className="lg:col-span-2" icon="signature" title={<FormattedMessage id="sign-requests-crud.no_results" />} />
				)}

				{signRequests?.data.map(signRequest => (
					<SignRequestItem key={signRequest.Id} signRequest={signRequest} />
				))}

				{isLoading && (
					<div className="flex items-center justify-center h-48">
						<FontAwesomeIcon icon="spinner-third" spin size="3x" className="text-gray-500" />
					</div>
				)}
			</div>

			{signRequests !== undefined && (
				<div className="mt-8">
					<LengthAwareResourcePaginator {...signRequests} onPage={setPage} />
				</div>
			)}

			{showCreateSignRequestTemplate && <SignRequestForm onSave={onSignRequestCreated} onAfterClose={() => setShowCreateSignRequestTemplate(false)} />}
		</>
	);
};
