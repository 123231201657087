import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Size, Variant } from '@convoflo/ui';
import AppContext from '@contexts/AppContext';
import { useAccount } from '@hooks/useAccount';
import Folder from '@models/Folder';
import { type FC, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';

export const OnboardingNextStepsPage: FC = () => {
	const { account } = useAccount();
	const { formatMessage } = useIntl();
	const { setPageTitle: setTitle } = useContext(AppContext);
	const history = useHistory();
	const { state: { secureSpace } = {} } = useLocation<{ secureSpace?: Folder }>();

	useEffect(() => {
		setTitle(formatMessage({ id: 'welcome.page_title' }));
	}, [setTitle, formatMessage]);

	const goHome = () => {
		history.replace('/files');
	};

	if (!account?.hasVerifiedEmail()) {
		return <Redirect to="/onboarding/verification" />;
	}

	if (!account?.hasFullAccess()) {
		return <Redirect to="/" />;
	}

	return (
		<div>
			<header className="mb-8 space-y-2">
				<h1 className="text-3xl font-bold md:text-4xl">
					<FormattedMessage id="tour.step3.title" />
				</h1>
				<p className="text-gray-400">
					<FormattedMessage id="tour.step3.subtitle" />
				</p>
			</header>

			<div className="mt-2 mb-8 overflow-hidden border border-gray-200 divide-y divide-gray-200 rounded-lg">
				<Link to="/files" replace className="flex items-center justify-between px-6 py-3 transition bg-white opacity-75 group hover:bg-gray-50 hover:opacity-100">
					<div className="flex items-center">
						<FontAwesomeIcon icon="shield" className="mr-3 text-green-600" fixedWidth />
						<p className="font-medium">
							<FormattedMessage id="tour.step3.option1" />
						</p>
					</div>
					<FontAwesomeIcon icon="angle-right" className="ml-4 text-2xl text-gray-300 transition-transform hover:text-gray-500 group-hover:translate-x-1" />
				</Link>

				{secureSpace && (
					<Link to={secureSpace.Link} replace className="flex items-center justify-between px-6 py-3 transition bg-white opacity-75 group hover:bg-gray-50 hover:opacity-100">
						<div className="flex items-center">
							<FontAwesomeIcon icon="cloud-upload" className="mr-3 text-gray-600" fixedWidth />
							<p className="font-medium">
								<FormattedMessage id="tour.step3.option2" />
							</p>
						</div>
						<FontAwesomeIcon icon="angle-right" className="ml-4 text-2xl text-gray-300 transition-transform hover:text-gray-500 group-hover:translate-x-1" />
					</Link>
				)}

				<Link
					to={{ pathname: '/account/notifications', state: { welcome: true } }}
					replace
					className="flex items-center justify-between px-6 py-3 transition bg-white opacity-75 group hover:bg-gray-50 hover:opacity-100">
					<div className="flex items-center">
						<FontAwesomeIcon icon="bell" className="mr-3 text-orange-600" fixedWidth />
						<p className="font-medium">
							<FormattedMessage id="tour.step3.option3" />
						</p>
					</div>
					<FontAwesomeIcon icon="angle-right" className="ml-4 text-2xl text-gray-300 transition-transform hover:text-gray-500 group-hover:translate-x-1" />
				</Link>

				<Link to="/organization/users" replace className="flex items-center justify-between px-6 py-3 transition bg-white opacity-75 group hover:bg-gray-50 hover:opacity-100">
					<div className="flex items-center">
						<FontAwesomeIcon icon={['fad', 'users']} className="mr-3 text-blue-600" fixedWidth />
						<p className="font-medium">
							<FormattedMessage id="tour.step3.option4" />
						</p>
					</div>
					<FontAwesomeIcon icon="angle-right" className="ml-4 text-2xl text-gray-300 transition-transform hover:text-gray-500 group-hover:translate-x-1" />
				</Link>

				<Link to="/account/multi-factor-auth" replace className="flex items-center justify-between px-6 py-3 transition bg-white opacity-75 group hover:bg-gray-50 hover:opacity-100">
					<div className="flex items-center">
						<FontAwesomeIcon icon="lock" className="mr-3 text-green-600" fixedWidth />
						<p className="font-medium">
							<FormattedMessage id="tour.step3.option5" />
						</p>
					</div>
					<FontAwesomeIcon icon="angle-right" className="ml-4 text-2xl text-gray-300 transition-transform hover:text-gray-500 group-hover:translate-x-1" />
				</Link>
			</div>

			<Button block variant={Variant.success} size={Size.xl} iconEnd="angle-right" animateIcon onClick={goHome}>
				<FormattedMessage id="tour.step3.btn" />
			</Button>
		</div>
	);
};
