import { CheckoutButton, type ModuleType, useCart, useStore } from '@components/Checkout';
import { Button, Intent, Select, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { defaultCurrency } from '../../constants';
import { Header } from './Header';

export const ModulesStep: FC = () => {
	const { store } = useStore();
	const { getItemById, addOrUpdateItem, toggleItem, removeItemById } = useCart();
	const history = useHistory();
	const { search } = useLocation();

	const modulePrice = (module: ModuleType) => {
		let price = module.amount;
		if (module.RebateType === 'percentage') {
			price = module.amount * (1 - module.Rebate);
		}
		return price;
	};

	if (!store) {
		return <Redirect to="/account/checkout" />;
	}

	const planItem = getItemById('plan');

	if (!planItem) {
		return <Redirect to="/account/checkout" />;
	}

	const esignModules: Record<number, ModuleType> = store.modules.esign || {};
	const selectedEsignModule = getItemById('esigns');

	return (
		<div className="max-w-7xl">
			<Header subtitle={<FormattedMessage id="plans.modules_subtitle" />}>
				<FormattedMessage id="plans.add_modules" />
			</Header>
			<div className="grid grid-cols-1 gap-4 mb-12 md:grid-cols-2">
				<CheckoutButton selected={selectedEsignModule !== undefined}>
					<div className="flex items-center justify-between mb-2 text-lg">
						<h3 className="font-semibold">
							<FormattedMessage id="plans.electronic_signatures" />
						</h3>
						{selectedEsignModule !== undefined && (
							<div className="text-right">
								{!!esignModules[selectedEsignModule.options.limit].Rebate && (
									<p className="text-sm text-right text-red-600">
										<del>
											<FormattedNumber
												value={esignModules[selectedEsignModule.options.limit].amount}
												style="currency"
												currencyDisplay="narrowSymbol"
												currency={defaultCurrency}
											/>
										</del>
										{esignModules[selectedEsignModule.options.limit].RebateType === 'percentage' && (
											<span className="px-2 py-1 ml-2 text-xs text-white uppercase bg-red-600 rounded-sm">
												<FormattedNumber value={-esignModules[selectedEsignModule.options.limit].Rebate} style="percent" />
											</span>
										)}
									</p>
								)}
								<span className="font-semibold">
									+<FormattedNumber value={modulePrice(esignModules[selectedEsignModule.options.limit])} style="currency" currencyDisplay="narrowSymbol" currency={defaultCurrency} />
								</span>
								<span className="ml-1 text-xs text-gray-600">
									<FormattedMessage id="plans.payment_frequency_unit" values={{ frequency: 'month' }} />
								</span>
							</div>
						)}
					</div>
					<p className="mb-2 text-sm leading-snug">
						<FormattedMessage id="plans.electronic_signatures_description" />
					</p>
					<Select
						value={selectedEsignModule?.options.limit ?? 0}
						onChange={e => {
							e.persist();
							if (e.target.value === '0') {
								removeItemById('esigns');
								return;
							}
							addOrUpdateItem({ id: 'esigns', options: { limit: parseInt(e.target.value) } });
						}}>
						<FormattedMessage id="plans.num_signatures" values={{ n: 0 }}>
							{msg => <option value={0}>{msg}</option>}
						</FormattedMessage>
						{Object.entries(esignModules).map(([amount, module]) => (
							<FormattedMessage key={amount} id="plans.num_signatures" values={{ n: amount }}>
								{msg => <option value={amount}>{msg}</option>}
							</FormattedMessage>
						))}
					</Select>
					{selectedEsignModule !== undefined && (
						<div className="flex items-center p-2 mt-3 text-xs bg-yellow-300 rounded">
							<FontAwesomeIcon icon="exclamation-triangle" className="mr-2 text-yellow-600" />
							<p className="leading-tight text-yellow-800">
								<FormattedMessage id="plans.signatures_year_commitment" />
							</p>
						</div>
					)}
					<p className="mt-3 text-xs italic leading-tight text-gray-500">
						<FormattedMessage id="plans.electronic_signatures_footnote" />
					</p>
				</CheckoutButton>

				<CheckoutButton selected={getItemById('embed') !== undefined} onClick={() => toggleItem({ id: 'embed' })}>
					<div className="flex items-center justify-between mb-2 text-lg">
						<h3 className="font-semibold">
							<FormattedMessage id="plans.embed_title" />
						</h3>

						<div className="text-right">
							{!!store.modules.embed.Rebate && (
								<p className="text-sm text-right text-red-600">
									<del>
										<FormattedNumber value={store.modules.embed.amount} style="currency" currencyDisplay="narrowSymbol" currency={defaultCurrency} />
									</del>
									{store.modules.embed.RebateType === 'percentage' && (
										<span className="px-2 py-1 ml-2 text-xs text-white uppercase bg-red-600 rounded-sm">
											<FormattedNumber value={-store.modules.embed.Rebate} style="percent" />
										</span>
									)}
								</p>
							)}
							<span className="font-semibold">
								+<FormattedNumber value={modulePrice(store.modules.embed)} style="currency" currency={defaultCurrency} currencyDisplay="narrowSymbol" />
							</span>
							<span className="ml-1 text-xs text-gray-600">
								<FormattedMessage id="plans.payment_frequency_unit" values={{ frequency: 'month' }} />
							</span>
						</div>
					</div>

					<p className="mb-2 text-sm leading-snug">
						<FormattedMessage id="plans.embed_description" />
					</p>
				</CheckoutButton>

				<CheckoutButton selected={getItemById('payments') !== undefined} onClick={() => toggleItem({ id: 'payments' })}>
					<div className="flex items-center justify-between mb-2 text-lg">
						<h3 className="font-semibold">
							<FormattedMessage id="plans.payments_title" />
						</h3>

						<div className="text-right">
							{!!store.modules.payments.Rebate && (
								<p className="text-sm text-right text-red-600">
									<del>
										<FormattedNumber value={store.modules.payments.amount} style="currency" currencyDisplay="narrowSymbol" currency={defaultCurrency} />
									</del>
									{store.modules.payments.RebateType === 'percentage' && (
										<span className="px-2 py-1 ml-2 text-xs text-white uppercase bg-red-600 rounded-sm">
											<FormattedNumber value={-store.modules.payments.Rebate} style="percent" />
										</span>
									)}
								</p>
							)}
							<span className="font-semibold">
								+<FormattedNumber value={modulePrice(store.modules.payments)} style="currency" currency={defaultCurrency} currencyDisplay="narrowSymbol" />
							</span>
							<span className="ml-1 text-xs text-gray-600">
								<FormattedMessage id="plans.payment_frequency_unit" values={{ frequency: 'month' }} />
							</span>
						</div>
					</div>

					<p className="mb-2 text-sm leading-snug">
						<FormattedMessage id="plans.payments_description" />
					</p>
				</CheckoutButton>
			</div>

			<div className="flex items-center justify-between my-12">
				<Button
					type="button"
					variant={Variant.light}
					intent={Intent.tertiary}
					iconStart="long-arrow-alt-left"
					animateIcon
					onClick={() => history.push({ pathname: '/account/checkout/extras', search })}>
					<FormattedMessage id="plans.extras" />
				</Button>
				<Button variant={Variant.primary} size={Size.lg} iconEnd="long-arrow-alt-right" animateIcon onClick={() => history.push({ pathname: '/account/checkout/support', search })}>
					<FormattedMessage id="plans.support_plans" />
				</Button>
			</div>
		</div>
	);
};
