import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type StateManagedSelect from 'react-select';
import Select, {
	type ClearIndicatorProps,
	type ControlProps,
	type DropdownIndicatorProps,
	type InputProps,
	type MultiValueGenericProps,
	type MultiValueRemoveProps,
	type PlaceholderProps,
	components
} from 'react-select';
import type AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncCreatableSelectComponent from 'react-select/async-creatable';
import type CreatableSelect from 'react-select/creatable';
import CreatableSelectComponent from 'react-select/creatable';
import { WindowedMenuList } from 'react-windowed-select';

export const ComboBox: StateManagedSelect = ({ styles = {}, components = {}, ...props }) => {
	return (
		<Select
			menuPortalTarget={document.body}
			styles={{
				menuPortal: provided => ({ ...provided, zIndex: 2000 }),
				...styles
			}}
			components={{
				Control: ComboBoxControl,
				MultiValueRemove: ComboBoxMultiValueRemove,
				MultiValueContainer: ComboBoxMultiValueContainer,
				Placeholder: ComboBoxPlaceholder,
				Input: ComboBoxInput,
				DropdownIndicator: ComboBoxDropdownIndicator,
				ClearIndicator: ComboBoxClearIndicator,
				IndicatorSeparator: null,
				...components
			}}
			{...props}
		/>
	);
};

export const WindowedComboBox: StateManagedSelect = ({ components, ...props }) => <ComboBox components={{ ...components, MenuList: WindowedMenuList }} {...props} />;

export const AsyncCreatableComboBox: AsyncCreatableSelect = ({ styles = {}, components = {}, ...props }) => {
	return (
		<AsyncCreatableSelectComponent
			menuPortalTarget={document.body}
			styles={{
				menuPortal: provided => ({ ...provided, zIndex: 2000 }),
				...styles
			}}
			components={{
				Control: ComboBoxControl,
				MultiValueRemove: ComboBoxMultiValueRemove,
				MultiValueContainer: ComboBoxMultiValueContainer,
				Placeholder: ComboBoxPlaceholder,
				Input: ComboBoxInput,
				DropdownIndicator: ComboBoxDropdownIndicator,
				ClearIndicator: ComboBoxClearIndicator,
				IndicatorSeparator: null,
				...components
			}}
			{...props}
		/>
	);
};

export const CreatableComboBox: CreatableSelect = ({ styles = {}, components = {}, ...props }) => {
	return (
		<CreatableSelectComponent
			menuPortalTarget={document.body}
			styles={{
				menuPortal: provided => ({ ...provided, zIndex: 2000 }),
				...styles
			}}
			components={{
				Control: ComboBoxControl,
				MultiValueRemove: ComboBoxMultiValueRemove,
				MultiValueContainer: ComboBoxMultiValueContainer,
				Placeholder: ComboBoxPlaceholder,
				Input: ComboBoxInput,
				DropdownIndicator: ComboBoxDropdownIndicator,
				ClearIndicator: ComboBoxClearIndicator,
				IndicatorSeparator: null,
				...components
			}}
			{...props}
		/>
	);
};

export const ComboBoxControl = (props: ControlProps) => {
	const className = classNames(props.className, '!border !rounded-r !shadow-none', {
		'!border-theme-primary-lighter ring-theme-primary-lightest ring': props.menuIsOpen,
		'!border-gray-300': !props.menuIsOpen
	});

	return <components.Control className={className} {...props} />;
};

export const ComboBoxMultiValueContainer = ({ innerProps, ...props }: MultiValueGenericProps) => {
	const className = classNames(innerProps.className, '!bg-gray-100 !border-0 !ring-1 !ring-gray-200 !rounded-full');

	return <components.MultiValueContainer innerProps={{ ...innerProps, className }} {...props} />;
};

export const ComboBoxPlaceholder = (props: PlaceholderProps) => {
	const className = classNames(props.className, '!text-sm !text-gray-500');

	return <components.Placeholder className={className} {...props} />;
};

export const ComboBoxInput = (props: InputProps) => {
	return <components.Input inputClassName={classNames('!ring-0')} {...props} />;
};

export const ComboBoxDropdownIndicator = (props: DropdownIndicatorProps) => {
	return (
		<components.DropdownIndicator {...props}>
			<FontAwesomeIcon icon="chevron-down" className="text-gray-300" fixedWidth />
		</components.DropdownIndicator>
	);
};

export const ComboBoxClearIndicator = (props: ClearIndicatorProps) => {
	return (
		<components.ClearIndicator {...props}>
			<FontAwesomeIcon icon="times" className="text-gray-300" fixedWidth />
		</components.ClearIndicator>
	);
};

export const ComboBoxMultiValueRemove = ({ innerProps, ...props }: MultiValueRemoveProps) => {
	const className = classNames(innerProps.className, 'hover:!bg-red-200/50 !rounded-full w-5 h-5 !p-0 justify-center self-center mr-1 transition-colors text-gray-300 hover:text-gray-500');

	return (
		<components.MultiValueRemove innerProps={{ ...innerProps, className }} {...props}>
			<FontAwesomeIcon icon="times" fixedWidth />
		</components.MultiValueRemove>
	);
};
