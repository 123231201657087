import useInterval from '@hooks/use-interval';
import { useAccount } from '@hooks/useAccount';
import useClient from '@hooks/useClient';
import { useAccountVerificationMutation } from '@state/queries/account';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export const useAccountVerification = (interval?: number) => {
	const { account } = useAccount();
	const { client } = useClient();
	const queryClient = useQueryClient();

	const [verified, setVerified] = useState(account?.hasVerifiedEmail() ?? false);

	const { mutate: resendActivationEmail, isSuccess: verificationEmailSent } = useMutation(async () => await client.url('email/verification-notification').post().res());

	const { mutateAsync: verifyMutation, isLoading: isVerifying } = useAccountVerificationMutation();

	const verify = useCallback(
		async (verificationUrl: string) => {
			try {
				await verifyMutation(verificationUrl);
				setVerified(true);
			} catch {}
		},
		[verifyMutation]
	);

	useEffect(() => {
		setVerified(account?.hasVerifiedEmail() ?? false);
	}, [account]);

	useInterval(
		() => {
			if (!account?.hasVerifiedEmail()) {
				queryClient.invalidateQueries(['account']);
			}
		},
		!verified ? interval : undefined
	);

	return { verified, verify, isVerifying, resendActivationEmail, verificationEmailSent };
};
