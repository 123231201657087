import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

const StarSection = ({ theme }: { theme: string }) => {
	var sectionClass = '';
	var blockClass = '';
	var starBackground = '';

	if (theme === 'light') {
		sectionClass = 'bg-blue-500';
		blockClass = 'px-6 py-5 text-white bg-blue-700 mt-6';
		starBackground = 'absolute top-0 left-0 text-5xl text-blue-500';
	} else if (theme === 'dark') {
		sectionClass = 'bg-blue-800';
		blockClass = 'px-6 py-5 text-white bg-blue-600 mt-6';
		starBackground = 'absolute top-0 left-0 text-5xl text-blue-800';
	}

	return (
		<section className={sectionClass}>
			<div className="container px-10 py-14 xl:px-56 md:px-20">
				<h1 className="mb-8 text-4xl font-bold text-white">
					<FormattedMessage id="ref.star_title" />
				</h1>
				<h2 className="mb-5 text-3xl text-white">
					<FormattedMessage id="ref.star_sub" />
				</h2>

				<div className="grid gap-6 lg:grid-cols-2">
					<div>
						<div className={blockClass}>
							<p className="text-3xl font-bold">
								<FormattedMessage id="ref.star_q1" />
							</p>
							<div className="relative inline-block my-4 overflow-hidden text-4xl">
								<span className="relative z-10 inline-block overflow-hidden text-5xl text-white whitespace-nowrap" style={{ width: '93%' }}>
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
								</span>
								<span className={starBackground}>
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
								</span>
							</div>
							<p className="ml-2 text-2xl font-bold">
								<FormattedMessage id="ref.star_r1" />
							</p>
						</div>
						<div className={blockClass}>
							<p className="text-3xl font-bold">
								<FormattedMessage id="ref.star_q2" />
							</p>
							<div className="relative inline-block my-4 overflow-hidden text-4xl">
								<span className="relative z-10 inline-block overflow-hidden text-5xl text-white whitespace-nowrap" style={{ width: '90%' }}>
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
								</span>
								<span className={starBackground}>
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
								</span>
							</div>
							<p className="ml-2 text-2xl font-bold">
								<FormattedMessage id="ref.star_r2" />
							</p>
						</div>
					</div>
					<div>
						<div className={blockClass}>
							<p className="text-3xl font-bold">
								<FormattedMessage id="ref.star_q3" />
							</p>
							<div className="relative inline-block my-4 overflow-hidden text-4xl">
								<span className="relative z-10 inline-block overflow-hidden text-5xl text-white whitespace-nowrap" style={{ width: '95%' }}>
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
								</span>
								<span className={starBackground}>
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
								</span>
							</div>
							<p className="ml-2 text-2xl font-bold">
								<FormattedMessage id="ref.star_r3" />
							</p>
						</div>
						<div className={blockClass}>
							<p className="text-3xl font-bold">
								<FormattedMessage id="ref.star_q4" />
							</p>
							<div className="relative inline-block my-4 overflow-hidden text-4xl">
								<span className="relative z-10 inline-block overflow-hidden text-5xl text-white whitespace-nowrap" style={{ width: '91%' }}>
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
								</span>
								<span className={starBackground}>
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
									<FontAwesomeIcon icon="star" className="mr-2" />
								</span>
							</div>
							<p className="ml-2 text-2xl font-bold">
								<FormattedMessage id="ref.star_r4" />
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default StarSection;
