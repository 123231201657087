import { type CannedMessage, CannedMessageConfirmDelete, CannedMessageForm } from '@components/CannedMessages';
import { Button, Checkbox, Intent, Size, Variant } from '@convoflo/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAccount } from '@hooks/useAccount';
import { useCannedMessagesSetAsDefaultForMutation } from '@state/queries/canned-messages';
import UserAvatar from '@ui/UserAvatar';
import classNames from 'classnames';
import { forwardRef, type ForwardRefRenderFunction, type HTMLAttributes, useState } from 'react';
import { FormattedMessage } from 'react-intl';

type CannedMessageRowProps = HTMLAttributes<HTMLDivElement> & {
	message: CannedMessage;
};

const CannedMessageItemRenderFn: ForwardRefRenderFunction<HTMLDivElement, CannedMessageRowProps> = ({ message, className, ...divProps }, ref) => {
	const { account } = useAccount();

	const [state, setState] = useState('view');

	const isEditable = message.creator.ID === account!.ID;

	const { mutate: setAsDefaultFor } = useCannedMessagesSetAsDefaultForMutation();

	return (
		<div ref={ref} className={classNames(className, 'bg-white md:rounded-lg shadow overflow-hidden')} {...divProps}>
			<div className="flex items-center px-4 py-2 bg-gray-50">
				{isEditable && <FontAwesomeIcon icon="bars" className="mr-3 text-gray-300" />}
				<h4 className="text-sm font-medium text-gray-600">{message.Title}</h4>
				<div className="flex-1" />

				{!isEditable && (
					<div className="flex items-center space-x-2">
						<UserAvatar user={message.creator} size={Size.xs} />
						<label className="text-xs italic text-gray-500">
							<FormattedMessage id="canned-messages-crud.shared_by" values={{ user: message.creator.Name }} />
						</label>
					</div>
				)}
				<span className="space-x-1">
					{isEditable && (
						<Button intent={Intent.tertiary} variant={Variant.light} circle size={Size.sm} onClick={() => setState('edit')}>
							<FontAwesomeIcon icon="pencil" />
						</Button>
					)}
					{isEditable && (
						<Button intent={Intent.tertiary} variant={Variant.danger} circle size={Size.sm} onClick={() => setState('delete')}>
							<FontAwesomeIcon icon="trash" />
						</Button>
					)}
				</span>
			</div>
			<div className="p-4 mt-2 prose-sm prose break-words max-w-none" dangerouslySetInnerHTML={{ __html: message.Content }} />

			<div className="p-3 border-t border-gray-100">
				<div className="flex flex-col space-y-2">
					<Checkbox checked={message.IsSignature} className="text-xs" onClick={e => setAsDefaultFor({ type: 'signature', cannedMessage: e.target.checked ? message : null })}>
						<FormattedMessage id="canned-messages-crud.use-as-signature" />
					</Checkbox>
					<Checkbox checked={message.IsShareMessage} className="text-xs" onClick={e => setAsDefaultFor({ type: 'share', cannedMessage: e.target.checked ? message : null })}>
						<FormattedMessage id="canned-messages-crud.use-as-share" />
					</Checkbox>
				</div>
			</div>

			{state === 'edit' && <CannedMessageForm message={message} onAfterClose={() => setState('view')} />}

			{state === 'delete' && <CannedMessageConfirmDelete message={message} onAfterClose={() => setState('view')} shouldCloseOnOverlayClick={false} shouldCloseOnEsc={false} />}
		</div>
	);
};

export const CannedMessageItem = forwardRef(CannedMessageItemRenderFn);
