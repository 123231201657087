import { Button, Checkbox, Intent, Modal, ModalBody, ModalFooter, ModalHeaderOnlyTitle, type ModalProps, ModalSize, Size, Variant } from '@convoflo/ui';
import type { IUser } from '@models/User';
import UserAvatar from '@ui/UserAvatar';
import classNames from 'classnames';
import uniq from 'lodash.uniqwith';
import { type FC, type FormEvent, type ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

type DialogMessageUsersProps<T> = Omit<ModalProps, 'isOpen'> & {
	users: T[];
	title?: ReactNode;
	selection?: T[];
	onChange?: (selection: T[]) => void;
};

export const DialogUserSelection = ({ users, selection: initialSelection, onChange = () => undefined, title, ...modalProps }: DialogMessageUsersProps<IUser>) => {
	const [isOpen, setIsOpen] = useState(true);
	const [selection, setSelection] = useState(initialSelection ?? []);

	const onSubmit = (e: FormEvent) => {
		e.preventDefault();
		e.stopPropagation();
		onChange(selection);
		setIsOpen(false);
	};

	const onUserSelected = (user: IUser, selected: boolean) => {
		if (selected) {
			setSelection(selection => uniq(selection.concat(user), (a, b) => a.ID === b.ID));
		} else {
			setSelection(selection => selection.filter(u => u.ID !== user.ID));
		}
	};

	useEffect(() => {
		setSelection(initialSelection ?? []);
	}, [initialSelection]);

	const submittable = selection.length > 0;

	return (
		<Modal size={ModalSize.XSmall} isOpen={isOpen} {...modalProps} onSubmit={onSubmit} closeable={true}>
			<ModalHeaderOnlyTitle icon={['fad', 'users']}>{title ?? <FormattedMessage id="comments.audience.options.title" />}</ModalHeaderOnlyTitle>
			<ModalBody>
				<div className="border divide-y rounded">
					{users.map(user => (
						<UserRow key={user.ID} user={user} selected={selection.some(u => user.ID === u.ID)} onSelected={selected => onUserSelected(user, selected)} />
					))}
				</div>
			</ModalBody>
			<ModalFooter>
				<Button variant={Variant.primary} disabled={!submittable} intent={Intent.primary} type="submit">
					<FormattedMessage id="comments.audience.options.confirm" />
				</Button>
			</ModalFooter>
		</Modal>
	);
};

type UserRowProps = {
	user: IUser;
	selected?: boolean;
	onSelected?: (selected: boolean) => void;
};

const UserRow: FC<UserRowProps> = ({ user, selected: initiallySelected = true, onSelected = () => undefined }) => {
	const [selected, setSelected] = useState(initiallySelected);

	const buttonClassName = classNames('flex items-center w-full p-3 space-x-3', {
		'bg-theme-primary-lightest/50': selected
	});

	useEffect(() => {
		onSelected(selected);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);

	useEffect(() => {
		setSelected(initiallySelected);
	}, [initiallySelected]);

	return (
		<button className={buttonClassName} onClick={() => setSelected(!selected)} type="button">
			<div>
				<Checkbox checked={selected} />
			</div>
			<div className="flex items-center flex-1">
				<UserAvatar user={user} size={Size.xs} className="mr-1.5" />
				<h5 className="text-sm text-gray-700">{user.Name}</h5>
			</div>
		</button>
	);
};
